// handles the redirection when the final success page
import { navRoutes } from '../../constants';
import { goToTopPage } from '../../utils/goToPage';
const SecondForm = () => {
  goToTopPage(navRoutes.WEBSITE.APP_SUCCESSFUL);

  return undefined;
};

export default SecondForm;
