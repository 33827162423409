export default {
  primaryMain: 'rgba(31, 13, 82, 1)',
  primaryLight: 'rgba(53, 37, 99, 0.5)',
  primarySecondary: 'rgba(180, 9, 83, 1)',
  white: '#FFFFFF',
  white50: 'rgba(250, 250, 250, 0.5)',
  neutralMain: 'rgba(5, 23, 48, 0.8)',
  tertiaryMain85: 'rgba(130, 59, 125, 0.85)',
  error: '#D83A2C',
  placeholder: '#bfbfbf',
  midnight: 'rgba(42, 25, 90, 1)',
  blue: '#098FB4'
};
