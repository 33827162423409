/* eslint-disable no-undef */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const makeError = (err, options = {}) => {
  let error = err.message || 'Something went wrong';
  let message = error;
  let statusCode = 500;
  let data;
  try {
    const res = err.response.data;
    error = res.error;
    message = res.message;
    statusCode = res.statusCode;
    data = res.data;
  } catch (e) {
    // take default values
  }

  if (process.env.NODE_ENV === 'development') console.error(error);

  return {
    error,
    message,
    statusCode,
    data
  };
};

export default makeError;
