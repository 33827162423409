import React from 'react';
import Layout from '../Layout';

const RouteWrapper = ({
  children,
  layout
}: {
  children: React.ReactNode;
  layout: string;
}) => {
  // if (isPrivate) {
  //   const authorized = authorization(user.role, allowedRoles);

  //   if (user.id) {
  //     return (
  //       <Layout
  //         layout={layout}
  //         user={user.role}
  //         {...props}
  //       >
  //         <RouterRoute path={path} props>
  //           {authorized ? (
  //             <Component {...props} />
  //           ) : (
  //             <Navigate to={navRoutes.GENERAL.UNAUTHORIZED} {...props} />
  //           )}
  //         </RouterRoute>
  //       </Layout>
  //     );
  //   }

  //   return <Navigate to={navRoutes.GENERAL.LOGIN} />;
  // }

  return children ? <Layout layout={layout}>{children}</Layout> : null;
};

export default RouteWrapper;
