export const jobRecords = [
  {
    Id: 1,
    Code: '1111',
    Name: 'Ambassador, Foreign and Commonwealth Office'
  },
  {
    Id: 2,
    Code: '1111',
    Name: 'Band 0, Health and Safety Executive'
  },
  {
    Id: 3,
    Code: '1111',
    Name: 'Band 1B, Meteorological Office'
  },
  {
    Id: 4,
    Code: '1111',
    Name: 'Band 1C, Meteorological Office'
  },
  {
    Id: 5,
    Code: '1111',
    Name: 'CEO'
  },
  {
    Id: 6,
    Code: '1111',
    Name: 'Chairman, company'
  },
  {
    Id: 7,
    Code: '1111',
    Name: 'Chairman, executive'
  },
  {
    Id: 8,
    Code: '1111',
    Name: 'Chairman, vice'
  },
  {
    Id: 9,
    Code: '1111',
    Name: 'Chairman, health authority'
  },
  {
    Id: 10,
    Code: '1111',
    Name: 'Chairman'
  },
  {
    Id: 11,
    Code: '1111',
    Name: 'Chairman and managing director'
  },
  {
    Id: 12,
    Code: '1111',
    Name: 'Chief of staff, government'
  },
  {
    Id: 13,
    Code: '1111',
    Name: 'Clerk to the commissioners, HM Revenue and Customs'
  },
  {
    Id: 14,
    Code: '1111',
    Name: 'Commissioner, government'
  },
  {
    Id: 15,
    Code: '1111',
    Name: 'Diplomat'
  },
  {
    Id: 16,
    Code: '1111',
    Name: 'Director, deputy, government'
  },
  {
    Id: 17,
    Code: '1111',
    Name: 'Director, group, government'
  },
  {
    Id: 18,
    Code: '1111',
    Name: 'Director, government'
  },
  {
    Id: 19,
    Code: '1111',
    Name: 'Director, National Assembly for Wales'
  },
  {
    Id: 20,
    Code: '1111',
    Name: 'Executive, chief, charitable organisation'
  },
  {
    Id: 21,
    Code: '1111',
    Name: 'Executive, chief, health authority: hospital service'
  },
  {
    Id: 22,
    Code: '1111',
    Name: 'Executive, chief, local government'
  },
  {
    Id: 23,
    Code: '1111',
    Name: 'Executive, chief, National Assembly for Wales'
  },
  {
    Id: 24,
    Code: '1111',
    Name: 'Executive, chief'
  },
  {
    Id: 25,
    Code: '1111',
    Name: 'Grade 1, Foreign and Commonwealth Office'
  },
  {
    Id: 26,
    Code: '1111',
    Name: 'Grade 1, government'
  },
  {
    Id: 27,
    Code: '1111',
    Name: 'Grade 2, Foreign and Commonwealth Office'
  },
  {
    Id: 28,
    Code: '1111',
    Name: 'Grade 2, government'
  },
  {
    Id: 29,
    Code: '1111',
    Name: 'Grade 3, Foreign and Commonwealth Office'
  },
  {
    Id: 30,
    Code: '1111',
    Name: 'Grade 3, government'
  },
  {
    Id: 31,
    Code: '1111',
    Name: 'Grade 4, Foreign and Commonwealth Office'
  },
  {
    Id: 32,
    Code: '1111',
    Name: 'Grade 5, Foreign and Commonwealth Office'
  },
  {
    Id: 33,
    Code: '1111',
    Name: 'Grade 5, government'
  },
  {
    Id: 34,
    Code: '1111',
    Name: 'Inspector of Constabulary, HMICFRS'
  },
  {
    Id: 35,
    Code: '1111',
    Name: 'Keeper of the Signet'
  },
  {
    Id: 36,
    Code: '1111',
    Name: 'Manager, general, major organisation'
  },
  {
    Id: 37,
    Code: '1111',
    Name: 'Officer, executive, chief'
  },
  {
    Id: 38,
    Code: '1111',
    Name: 'President, company'
  },
  {
    Id: 39,
    Code: '1111',
    Name: 'President, vice'
  },
  {
    Id: 40,
    Code: '1111',
    Name: 'President'
  },
  {
    Id: 41,
    Code: '1111',
    Name: 'Secretary, assistant, government'
  },
  {
    Id: 42,
    Code: '1111',
    Name: 'Secretary, command'
  },
  {
    Id: 43,
    Code: '1111',
    Name: 'Secretary, deputy, government'
  },
  {
    Id: 44,
    Code: '1111',
    Name: 'Secretary, first'
  },
  {
    Id: 45,
    Code: '1111',
    Name: 'Secretary, parliamentary'
  },
  {
    Id: 46,
    Code: '1111',
    Name: 'Secretary, permanent, government'
  },
  {
    Id: 47,
    Code: '1111',
    Name: 'Secretary, private, parliamentary'
  },
  {
    Id: 48,
    Code: '1111',
    Name: 'Secretary, second'
  },
  {
    Id: 49,
    Code: '1111',
    Name: 'Secretary, under, government'
  },
  {
    Id: 50,
    Code: '1111',
    Name: 'Servant, civil, assistant secretary and above'
  },
  {
    Id: 51,
    Code: '1111',
    Name: 'Servant, civil, grade 5 and above'
  },
  {
    Id: 52,
    Code: '1112',
    Name: 'AM, National Assembly for Wales'
  },
  {
    Id: 53,
    Code: '1112',
    Name: 'Councillor, borough'
  },
  {
    Id: 54,
    Code: '1112',
    Name: 'Councillor, city'
  },
  {
    Id: 55,
    Code: '1112',
    Name: 'Councillor, county'
  },
  {
    Id: 56,
    Code: '1112',
    Name: 'Councillor, district'
  },
  {
    Id: 57,
    Code: '1112',
    Name: 'Councillor, local government'
  },
  {
    Id: 58,
    Code: '1112',
    Name: 'MEP'
  },
  {
    Id: 59,
    Code: '1112',
    Name: 'MLA, Northern Ireland Assembly'
  },
  {
    Id: 60,
    Code: '1112',
    Name: 'MP'
  },
  {
    Id: 61,
    Code: '1112',
    Name: 'MSP'
  },
  {
    Id: 62,
    Code: '1112',
    Name: 'Member, assembly, National Assembly'
  },
  {
    Id: 63,
    Code: '1112',
    Name: 'Member of European Parliament'
  },
  {
    Id: 64,
    Code: '1112',
    Name: 'Member of Northern Ireland Assembly'
  },
  {
    Id: 65,
    Code: '1112',
    Name: 'Member of Parliament'
  },
  {
    Id: 66,
    Code: '1112',
    Name: 'Member of Scottish Parliament'
  },
  {
    Id: 67,
    Code: '1112',
    Name: 'Member of the Legislative Assembly'
  },
  {
    Id: 68,
    Code: '1112',
    Name: 'Minister, government'
  },
  {
    Id: 69,
    Code: '1112',
    Name: 'Secretary of state'
  },
  {
    Id: 70,
    Code: '1121',
    Name: 'Director, company, engineering'
  },
  {
    Id: 71,
    Code: '1121',
    Name: 'Director, company, manufacturing'
  },
  {
    Id: 72,
    Code: '1121',
    Name: 'Director, engineering'
  },
  {
    Id: 73,
    Code: '1121',
    Name: 'Director, managing, engineering'
  },
  {
    Id: 74,
    Code: '1121',
    Name: 'Director, managing, manufacturing'
  },
  {
    Id: 75,
    Code: '1121',
    Name: 'Director, manufacturing'
  },
  {
    Id: 76,
    Code: '1121',
    Name: 'Director, operations, manufacturing'
  },
  {
    Id: 77,
    Code: '1121',
    Name: 'Director, technical, engineering'
  },
  {
    Id: 78,
    Code: '1121',
    Name: 'Director, technical'
  },
  {
    Id: 79,
    Code: '1121',
    Name: 'Director, works, manufacturing'
  },
  {
    Id: 80,
    Code: '1121',
    Name: 'Director, engineering'
  },
  {
    Id: 81,
    Code: '1121',
    Name: 'Director, manufacturing'
  },
  {
    Id: 82,
    Code: '1121',
    Name: 'Director, printing'
  },
  {
    Id: 83,
    Code: '1121',
    Name: 'Director of engineering'
  },
  {
    Id: 84,
    Code: '1121',
    Name: 'Director of manufacturing'
  },
  {
    Id: 85,
    Code: '1121',
    Name: 'Head of manufacturing'
  },
  {
    Id: 86,
    Code: '1121',
    Name: 'Manager, abattoir'
  },
  {
    Id: 87,
    Code: '1121',
    Name: 'Manager, brewery'
  },
  {
    Id: 88,
    Code: '1121',
    Name: 'Manager, clothing, manufacturing'
  },
  {
    Id: 89,
    Code: '1121',
    Name: 'Manager, composition'
  },
  {
    Id: 90,
    Code: '1121',
    Name: 'Manager, control, production'
  },
  {
    Id: 91,
    Code: '1121',
    Name: 'Manager, dairy, food products mfr'
  },
  {
    Id: 92,
    Code: '1121',
    Name: 'Manager, district, manufacturing'
  },
  {
    Id: 93,
    Code: '1121',
    Name: 'Manager, divisional, manufacturing'
  },
  {
    Id: 94,
    Code: '1121',
    Name: 'Manager, electro-plating'
  },
  {
    Id: 95,
    Code: '1121',
    Name: 'Manager, engineering'
  },
  {
    Id: 96,
    Code: '1121',
    Name: 'Manager, fabrication'
  },
  {
    Id: 97,
    Code: '1121',
    Name: 'Manager, factory'
  },
  {
    Id: 98,
    Code: '1121',
    Name: 'Manager, floor, manufacturing'
  },
  {
    Id: 99,
    Code: '1121',
    Name: 'Manager, foundry'
  },
  {
    Id: 100,
    Code: '1121',
    Name: 'Manager, general, manufacturing'
  },
  {
    Id: 101,
    Code: '1121',
    Name: 'Manager, house, dye'
  },
  {
    Id: 102,
    Code: '1121',
    Name: 'Manager, line, front, manufacturing'
  },
  {
    Id: 103,
    Code: '1121',
    Name: 'Manager, line, production'
  },
  {
    Id: 104,
    Code: '1121',
    Name: 'Manager, manufacturing'
  },
  {
    Id: 105,
    Code: '1121',
    Name: 'Manager, mill'
  },
  {
    Id: 106,
    Code: '1121',
    Name: 'Manager, operations, manufacturing'
  },
  {
    Id: 107,
    Code: '1121',
    Name: 'Manager, plant'
  },
  {
    Id: 108,
    Code: '1121',
    Name: 'Manager, product, manufacturing'
  },
  {
    Id: 109,
    Code: '1121',
    Name: 'Manager, production, printing'
  },
  {
    Id: 110,
    Code: '1121',
    Name: 'Manager, production'
  },
  {
    Id: 111,
    Code: '1121',
    Name: 'Manager, progress'
  },
  {
    Id: 112,
    Code: '1121',
    Name: 'Manager, regional, manufacturing'
  },
  {
    Id: 113,
    Code: '1121',
    Name: 'Manager, room, composing'
  },
  {
    Id: 114,
    Code: '1121',
    Name: 'Manager, sawmill'
  },
  {
    Id: 115,
    Code: '1121',
    Name: 'Manager, service'
  },
  {
    Id: 116,
    Code: '1121',
    Name: 'Manager, services, technical'
  },
  {
    Id: 117,
    Code: '1121',
    Name: 'Manager, shift, production'
  },
  {
    Id: 118,
    Code: '1121',
    Name: 'Manager, shift, manufacturing'
  },
  {
    Id: 119,
    Code: '1121',
    Name: 'Manager, shop, colour'
  },
  {
    Id: 120,
    Code: '1121',
    Name: 'Manager, shop, manufacturing'
  },
  {
    Id: 121,
    Code: '1121',
    Name: 'Manager, shop, metal trades'
  },
  {
    Id: 122,
    Code: '1121',
    Name: 'Manager, steelworks'
  },
  {
    Id: 123,
    Code: '1121',
    Name: 'Manager, systems, printing'
  },
  {
    Id: 124,
    Code: '1121',
    Name: 'Manager, technical'
  },
  {
    Id: 125,
    Code: '1121',
    Name: 'Manager, textile'
  },
  {
    Id: 126,
    Code: '1121',
    Name: 'Manager, works'
  },
  {
    Id: 127,
    Code: '1121',
    Name: 'Manager, yard, boat'
  },
  {
    Id: 128,
    Code: '1121',
    Name: 'Manager, yard, ship'
  },
  {
    Id: 129,
    Code: '1121',
    Name: 'Manager, coke ovens'
  },
  {
    Id: 130,
    Code: '1121',
    Name: 'Manager, engineering'
  },
  {
    Id: 131,
    Code: '1121',
    Name: 'Manager, manufacturing'
  },
  {
    Id: 132,
    Code: '1121',
    Name: 'Manager, mineral oil processing'
  },
  {
    Id: 133,
    Code: '1121',
    Name: 'Manager, nuclear fuel production'
  },
  {
    Id: 134,
    Code: '1121',
    Name: 'Manager, printing'
  },
  {
    Id: 135,
    Code: '1121',
    Name: 'Manager, ship building, repairing'
  },
  {
    Id: 136,
    Code: '1121',
    Name: 'Manager, solid fuel mfr'
  },
  {
    Id: 137,
    Code: '1121',
    Name: 'Manager, steelworks'
  },
  {
    Id: 138,
    Code: '1121',
    Name: 'Owner, foundry'
  },
  {
    Id: 139,
    Code: '1121',
    Name: 'Owner, mill, textile'
  },
  {
    Id: 140,
    Code: '1121',
    Name: 'Owner, sawmill'
  },
  {
    Id: 141,
    Code: '1121',
    Name: 'Owner, yard, boat'
  },
  {
    Id: 142,
    Code: '1121',
    Name: 'Owner, engineering works'
  },
  {
    Id: 143,
    Code: '1121',
    Name: 'Owner, manufacturing'
  },
  {
    Id: 144,
    Code: '1121',
    Name: 'Owner, sawmill'
  },
  {
    Id: 145,
    Code: '1121',
    Name: 'Owner, textile mill'
  },
  {
    Id: 146,
    Code: '1122',
    Name: "Agent, builder's"
  },
  {
    Id: 147,
    Code: '1122',
    Name: "Agent, contractor's"
  },
  {
    Id: 148,
    Code: '1122',
    Name: 'Agent, engineering, civil'
  },
  {
    Id: 149,
    Code: '1122',
    Name: 'Agent, site'
  },
  {
    Id: 150,
    Code: '1122',
    Name: 'Agent, building construction'
  },
  {
    Id: 151,
    Code: '1122',
    Name: 'Director, building'
  },
  {
    Id: 152,
    Code: '1122',
    Name: 'Director, construction'
  },
  {
    Id: 153,
    Code: '1122',
    Name: 'Director, contracts, construction'
  },
  {
    Id: 154,
    Code: '1122',
    Name: 'Director, managing, building construction'
  },
  {
    Id: 155,
    Code: '1122',
    Name: 'Director, technical, building construction'
  },
  {
    Id: 156,
    Code: '1122',
    Name: 'Director, building construction'
  },
  {
    Id: 157,
    Code: '1122',
    Name: 'Director, civil engineering'
  },
  {
    Id: 158,
    Code: '1122',
    Name: 'Manager, area, construction'
  },
  {
    Id: 159,
    Code: '1122',
    Name: 'Manager, building'
  },
  {
    Id: 160,
    Code: '1122',
    Name: 'Manager, construction'
  },
  {
    Id: 161,
    Code: '1122',
    Name: 'Manager, demolition'
  },
  {
    Id: 162,
    Code: '1122',
    Name: 'Manager, development, property'
  },
  {
    Id: 163,
    Code: '1122',
    Name: 'Manager, development, building construction'
  },
  {
    Id: 164,
    Code: '1122',
    Name: 'Manager, engineering, building construction'
  },
  {
    Id: 165,
    Code: '1122',
    Name: 'Manager, highway'
  },
  {
    Id: 166,
    Code: '1122',
    Name: 'Manager, highways'
  },
  {
    Id: 167,
    Code: '1122',
    Name: 'Manager, operations, building construction'
  },
  {
    Id: 168,
    Code: '1122',
    Name: 'Manager, plant, building construction'
  },
  {
    Id: 169,
    Code: '1122',
    Name: 'Manager, production, building construction'
  },
  {
    Id: 170,
    Code: '1122',
    Name: 'Manager, scaffolding'
  },
  {
    Id: 171,
    Code: '1122',
    Name: 'Manager, service, building construction'
  },
  {
    Id: 172,
    Code: '1122',
    Name: 'Manager, services, building'
  },
  {
    Id: 173,
    Code: '1122',
    Name: 'Manager, services, site'
  },
  {
    Id: 174,
    Code: '1122',
    Name: 'Manager, services, building construction'
  },
  {
    Id: 175,
    Code: '1122',
    Name: 'Manager, site, building'
  },
  {
    Id: 176,
    Code: '1122',
    Name: 'Manager, site, construction'
  },
  {
    Id: 177,
    Code: '1122',
    Name: 'Manager, site'
  },
  {
    Id: 178,
    Code: '1122',
    Name: 'Manager, technical, building construction'
  },
  {
    Id: 179,
    Code: '1122',
    Name: 'Manager, works, building construction'
  },
  {
    Id: 180,
    Code: '1122',
    Name: 'Manager, building construction'
  },
  {
    Id: 181,
    Code: '1122',
    Name: 'Manager, civil engineering'
  },
  {
    Id: 182,
    Code: '1122',
    Name: 'Manager, coal mine: opencast'
  },
  {
    Id: 183,
    Code: '1122',
    Name: 'Manager, electrical contracting'
  },
  {
    Id: 184,
    Code: '1122',
    Name: 'Manager, local government: highways dept'
  },
  {
    Id: 185,
    Code: '1122',
    Name: 'Master of works'
  },
  {
    Id: 186,
    Code: '1122',
    Name: 'Merchant, building construction'
  },
  {
    Id: 187,
    Code: '1122',
    Name: 'Owner, building construction'
  },
  {
    Id: 188,
    Code: '1122',
    Name: 'Owner, electrical contracting'
  },
  {
    Id: 189,
    Code: '1122',
    Name: 'Owner, plumbing, heating contracting'
  },
  {
    Id: 190,
    Code: '1122',
    Name: 'Owner, property renovation'
  },
  {
    Id: 191,
    Code: '1122',
    Name: 'Sub-agent, building construction'
  },
  {
    Id: 192,
    Code: '1123',
    Name: 'Agent, colliery'
  },
  {
    Id: 193,
    Code: '1123',
    Name: 'Agent, mine'
  },
  {
    Id: 194,
    Code: '1123',
    Name: 'Agent, surface'
  },
  {
    Id: 195,
    Code: '1123',
    Name: 'Agent, mining'
  },
  {
    Id: 196,
    Code: '1123',
    Name: 'Boss, mine'
  },
  {
    Id: 197,
    Code: '1123',
    Name: 'Director, operations, mining, water and energy'
  },
  {
    Id: 198,
    Code: '1123',
    Name: 'Director, technical, water treatment'
  },
  {
    Id: 199,
    Code: '1123',
    Name: 'Manager, colliery'
  },
  {
    Id: 200,
    Code: '1123',
    Name: 'Manager, distribution, energy suppliers'
  },
  {
    Id: 201,
    Code: '1123',
    Name: 'Manager, exploration, oil'
  },
  {
    Id: 202,
    Code: '1123',
    Name: 'Manager, gas'
  },
  {
    Id: 203,
    Code: '1123',
    Name: 'Manager, installation, offshore'
  },
  {
    Id: 204,
    Code: '1123',
    Name: 'Manager, installations, offshore'
  },
  {
    Id: 205,
    Code: '1123',
    Name: 'Manager, operations, mining, water and energy'
  },
  {
    Id: 206,
    Code: '1123',
    Name: 'Manager, planning, public utilities'
  },
  {
    Id: 207,
    Code: '1123',
    Name: 'Manager, production, electricity supplier'
  },
  {
    Id: 208,
    Code: '1123',
    Name: 'Manager, production, public utilities'
  },
  {
    Id: 209,
    Code: '1123',
    Name: 'Manager, quarry'
  },
  {
    Id: 210,
    Code: '1123',
    Name: 'Manager, rig, oil'
  },
  {
    Id: 211,
    Code: '1123',
    Name: 'Manager, service, energy suppliers'
  },
  {
    Id: 212,
    Code: '1123',
    Name: 'Manager, service, public utilities'
  },
  {
    Id: 213,
    Code: '1123',
    Name: 'Manager, station, power'
  },
  {
    Id: 214,
    Code: '1123',
    Name: 'Manager, electricity supplier'
  },
  {
    Id: 215,
    Code: '1123',
    Name: 'Manager, mine not opencast: quarry'
  },
  {
    Id: 216,
    Code: '1123',
    Name: 'Manager, public utilities'
  },
  {
    Id: 217,
    Code: '1123',
    Name: 'Manager, quarrying and extraction'
  },
  {
    Id: 218,
    Code: '1123',
    Name: 'Manager, well drilling'
  },
  {
    Id: 219,
    Code: '1123',
    Name: 'Owner, quarry'
  },
  {
    Id: 220,
    Code: '1123',
    Name: 'Pusher, tool'
  },
  {
    Id: 221,
    Code: '1123',
    Name: 'Superintendent, drilling'
  },
  {
    Id: 222,
    Code: '1131',
    Name: 'Banker, business'
  },
  {
    Id: 223,
    Code: '1131',
    Name: 'Banker, international'
  },
  {
    Id: 224,
    Code: '1131',
    Name: 'Banker, investment'
  },
  {
    Id: 225,
    Code: '1131',
    Name: 'Banker, merchant'
  },
  {
    Id: 226,
    Code: '1131',
    Name: 'Banker, retail'
  },
  {
    Id: 227,
    Code: '1131',
    Name: 'Banker, finance'
  },
  {
    Id: 228,
    Code: '1131',
    Name: 'Banker'
  },
  {
    Id: 229,
    Code: '1131',
    Name: 'CFO'
  },
  {
    Id: 230,
    Code: '1131',
    Name: 'COO, banking'
  },
  {
    Id: 231,
    Code: '1131',
    Name: 'COO, building society'
  },
  {
    Id: 232,
    Code: '1131',
    Name: 'COO, financial services'
  },
  {
    Id: 233,
    Code: '1131',
    Name: 'Chamberlain, burgh'
  },
  {
    Id: 234,
    Code: '1131',
    Name: 'Chamberlain, city'
  },
  {
    Id: 235,
    Code: '1131',
    Name: 'Company secretary and director'
  },
  {
    Id: 236,
    Code: '1131',
    Name: 'Director, bank'
  },
  {
    Id: 237,
    Code: '1131',
    Name: 'Director, finance, group'
  },
  {
    Id: 238,
    Code: '1131',
    Name: 'Director, finance'
  },
  {
    Id: 239,
    Code: '1131',
    Name: 'Director, financial'
  },
  {
    Id: 240,
    Code: '1131',
    Name: 'Director, investment'
  },
  {
    Id: 241,
    Code: '1131',
    Name: 'Director, relationship, banking'
  },
  {
    Id: 242,
    Code: '1131',
    Name: 'Director, relationship, financial services'
  },
  {
    Id: 243,
    Code: '1131',
    Name: 'Director, banking: investment'
  },
  {
    Id: 244,
    Code: '1131',
    Name: 'Director, banking'
  },
  {
    Id: 245,
    Code: '1131',
    Name: 'Director, financial services'
  },
  {
    Id: 246,
    Code: '1131',
    Name: 'Director, insurance'
  },
  {
    Id: 247,
    Code: '1131',
    Name: 'Director and company secretary'
  },
  {
    Id: 248,
    Code: '1131',
    Name: 'Director and secretary'
  },
  {
    Id: 249,
    Code: '1131',
    Name: 'Director of finance'
  },
  {
    Id: 250,
    Code: '1131',
    Name: 'Director-company secretary'
  },
  {
    Id: 251,
    Code: '1131',
    Name: 'Head of commercial finance'
  },
  {
    Id: 252,
    Code: '1131',
    Name: 'Head of corporate finance'
  },
  {
    Id: 253,
    Code: '1131',
    Name: 'Head of finance'
  },
  {
    Id: 254,
    Code: '1131',
    Name: 'Head of lending'
  },
  {
    Id: 255,
    Code: '1131',
    Name: 'Manager, assessment, credit'
  },
  {
    Id: 256,
    Code: '1131',
    Name: 'Manager, bank'
  },
  {
    Id: 257,
    Code: '1131',
    Name: 'Manager, banking'
  },
  {
    Id: 258,
    Code: '1131',
    Name: 'Manager, branch, assurance company'
  },
  {
    Id: 259,
    Code: '1131',
    Name: 'Manager, branch, banking'
  },
  {
    Id: 260,
    Code: '1131',
    Name: 'Manager, branch, building society'
  },
  {
    Id: 261,
    Code: '1131',
    Name: 'Manager, branch, credit company'
  },
  {
    Id: 262,
    Code: '1131',
    Name: 'Manager, branch, financial services'
  },
  {
    Id: 263,
    Code: '1131',
    Name: 'Manager, branch, insurance'
  },
  {
    Id: 264,
    Code: '1131',
    Name: 'Manager, broking'
  },
  {
    Id: 265,
    Code: '1131',
    Name: 'Manager, card, credit'
  },
  {
    Id: 266,
    Code: '1131',
    Name: 'Manager, collateral'
  },
  {
    Id: 267,
    Code: '1131',
    Name: 'Manager, collection'
  },
  {
    Id: 268,
    Code: '1131',
    Name: 'Manager, collections'
  },
  {
    Id: 269,
    Code: '1131',
    Name: 'Manager, company, insurance'
  },
  {
    Id: 270,
    Code: '1131',
    Name: 'Manager, corporate, banking'
  },
  {
    Id: 271,
    Code: '1131',
    Name: 'Manager, corporate, building society'
  },
  {
    Id: 272,
    Code: '1131',
    Name: 'Manager, cost, banking'
  },
  {
    Id: 273,
    Code: '1131',
    Name: 'Manager, costing, banking'
  },
  {
    Id: 274,
    Code: '1131',
    Name: 'Manager, development, agency'
  },
  {
    Id: 275,
    Code: '1131',
    Name: 'Manager, district, friendly society'
  },
  {
    Id: 276,
    Code: '1131',
    Name: 'Manager, divisional, insurance'
  },
  {
    Id: 277,
    Code: '1131',
    Name: 'Manager, equity'
  },
  {
    Id: 278,
    Code: '1131',
    Name: 'Manager, exchange, foreign'
  },
  {
    Id: 279,
    Code: '1131',
    Name: 'Manager, finance, commercial'
  },
  {
    Id: 280,
    Code: '1131',
    Name: 'Manager, finance, corporate'
  },
  {
    Id: 281,
    Code: '1131',
    Name: 'Manager, finance, group'
  },
  {
    Id: 282,
    Code: '1131',
    Name: 'Manager, finance'
  },
  {
    Id: 283,
    Code: '1131',
    Name: 'Manager, finance and administration'
  },
  {
    Id: 284,
    Code: '1131',
    Name: 'Manager, financial'
  },
  {
    Id: 285,
    Code: '1131',
    Name: 'Manager, fund, pension'
  },
  {
    Id: 286,
    Code: '1131',
    Name: 'Manager, insurance'
  },
  {
    Id: 287,
    Code: '1131',
    Name: 'Manager, lending'
  },
  {
    Id: 288,
    Code: '1131',
    Name: 'Manager, market, money'
  },
  {
    Id: 289,
    Code: '1131',
    Name: 'Manager, markets, financial services'
  },
  {
    Id: 290,
    Code: '1131',
    Name: 'Manager, office, post'
  },
  {
    Id: 291,
    Code: '1131',
    Name: 'Manager, operations, bank'
  },
  {
    Id: 292,
    Code: '1131',
    Name: 'Manager, operations, banking'
  },
  {
    Id: 293,
    Code: '1131',
    Name: 'Manager, operations, building society'
  },
  {
    Id: 294,
    Code: '1131',
    Name: 'Manager, operations, financial services'
  },
  {
    Id: 295,
    Code: '1131',
    Name: 'Manager, planning, capital'
  },
  {
    Id: 296,
    Code: '1131',
    Name: 'Manager, practice, insolvency'
  },
  {
    Id: 297,
    Code: '1131',
    Name: 'Manager, product, banking'
  },
  {
    Id: 298,
    Code: '1131',
    Name: 'Manager, purchase, hire'
  },
  {
    Id: 299,
    Code: '1131',
    Name: 'Manager, reporting, financial'
  },
  {
    Id: 300,
    Code: '1131',
    Name: 'Manager, sales, banking'
  },
  {
    Id: 301,
    Code: '1131',
    Name: 'Manager, sales, building society'
  },
  {
    Id: 302,
    Code: '1131',
    Name: 'Manager, services, finance'
  },
  {
    Id: 303,
    Code: '1131',
    Name: 'Manager, settlements'
  },
  {
    Id: 304,
    Code: '1131',
    Name: 'Manager, society, building'
  },
  {
    Id: 305,
    Code: '1131',
    Name: 'Manager, society, friendly'
  },
  {
    Id: 306,
    Code: '1131',
    Name: 'Manager, systems, financial'
  },
  {
    Id: 307,
    Code: '1131',
    Name: 'Manager, technical, banking'
  },
  {
    Id: 308,
    Code: '1131',
    Name: 'Manager, technical, building society'
  },
  {
    Id: 309,
    Code: '1131',
    Name: 'Manager, technical, insurance'
  },
  {
    Id: 310,
    Code: '1131',
    Name: 'Manager, transaction, banking'
  },
  {
    Id: 311,
    Code: '1131',
    Name: 'Manager, transaction, financial services'
  },
  {
    Id: 312,
    Code: '1131',
    Name: 'Manager, treasury'
  },
  {
    Id: 313,
    Code: '1131',
    Name: 'Manager, accountancy'
  },
  {
    Id: 314,
    Code: '1131',
    Name: 'Manager, accountancy services'
  },
  {
    Id: 315,
    Code: '1131',
    Name: 'Manager, average adjusting'
  },
  {
    Id: 316,
    Code: '1131',
    Name: 'Manager, banking: investment'
  },
  {
    Id: 317,
    Code: '1131',
    Name: 'Manager, banking'
  },
  {
    Id: 318,
    Code: '1131',
    Name: 'Manager, building society'
  },
  {
    Id: 319,
    Code: '1131',
    Name: 'Manager, credit company'
  },
  {
    Id: 320,
    Code: '1131',
    Name: 'Manager, financial services'
  },
  {
    Id: 321,
    Code: '1131',
    Name: 'Manager, insurance'
  },
  {
    Id: 322,
    Code: '1131',
    Name: 'Manager, insurance brokers'
  },
  {
    Id: 323,
    Code: '1131',
    Name: 'Manager, loan company'
  },
  {
    Id: 324,
    Code: '1131',
    Name: 'Manager, money lending'
  },
  {
    Id: 325,
    Code: '1131',
    Name: 'Manager, postal services'
  },
  {
    Id: 326,
    Code: '1131',
    Name: 'Manager, stock jobbers'
  },
  {
    Id: 327,
    Code: '1131',
    Name: 'Manager, stockbrokers'
  },
  {
    Id: 328,
    Code: '1131',
    Name: 'Officer, finance, chief'
  },
  {
    Id: 329,
    Code: '1131',
    Name: 'Officer, financial, chief'
  },
  {
    Id: 330,
    Code: '1131',
    Name: 'Officer, operating, chief, banking'
  },
  {
    Id: 331,
    Code: '1131',
    Name: 'Officer, operating, chief, building society'
  },
  {
    Id: 332,
    Code: '1131',
    Name: 'Officer, operating, chief, financial services'
  },
  {
    Id: 333,
    Code: '1131',
    Name: 'Owner, loan office'
  },
  {
    Id: 334,
    Code: '1131',
    Name: 'President, vice, banking'
  },
  {
    Id: 335,
    Code: '1131',
    Name: 'President, vice, financial services'
  },
  {
    Id: 336,
    Code: '1131',
    Name: 'President, vice, insurance'
  },
  {
    Id: 337,
    Code: '1131',
    Name: 'Principal, banking'
  },
  {
    Id: 338,
    Code: '1131',
    Name: 'Registrar, company'
  },
  {
    Id: 339,
    Code: '1131',
    Name: 'Registrar'
  },
  {
    Id: 340,
    Code: '1131',
    Name: 'Registrar of stock'
  },
  {
    Id: 341,
    Code: '1131',
    Name: 'Registrar of stocks and bonds'
  },
  {
    Id: 342,
    Code: '1131',
    Name: 'Secretary, company, director'
  },
  {
    Id: 343,
    Code: '1131',
    Name: 'Secretary and company director'
  },
  {
    Id: 344,
    Code: '1131',
    Name: 'Secretary and legal adviser'
  },
  {
    Id: 345,
    Code: '1131',
    Name: 'Secretary of health authority'
  },
  {
    Id: 346,
    Code: '1131',
    Name: 'Secretary of health board'
  },
  {
    Id: 347,
    Code: '1131',
    Name: 'Secretary-accountant'
  },
  {
    Id: 348,
    Code: '1131',
    Name: 'Secretary-director'
  },
  {
    Id: 349,
    Code: '1131',
    Name: 'Treasurer, company'
  },
  {
    Id: 350,
    Code: '1131',
    Name: 'Treasurer, county'
  },
  {
    Id: 351,
    Code: '1131',
    Name: 'Treasurer, qualified'
  },
  {
    Id: 352,
    Code: '1132',
    Name: 'CMO'
  },
  {
    Id: 353,
    Code: '1132',
    Name: 'Director, account, digital'
  },
  {
    Id: 354,
    Code: '1132',
    Name: 'Director, account, media'
  },
  {
    Id: 355,
    Code: '1132',
    Name: 'Director, account, marketing'
  },
  {
    Id: 356,
    Code: '1132',
    Name: 'Director, account, sales'
  },
  {
    Id: 357,
    Code: '1132',
    Name: 'Director, account, advertising'
  },
  {
    Id: 358,
    Code: '1132',
    Name: 'Director, account'
  },
  {
    Id: 359,
    Code: '1132',
    Name: 'Director, advertising'
  },
  {
    Id: 360,
    Code: '1132',
    Name: 'Director, appeal'
  },
  {
    Id: 361,
    Code: '1132',
    Name: 'Director, appeals'
  },
  {
    Id: 362,
    Code: '1132',
    Name: 'Director, brand'
  },
  {
    Id: 363,
    Code: '1132',
    Name: 'Director, commercial'
  },
  {
    Id: 364,
    Code: '1132',
    Name: 'Director, development, business'
  },
  {
    Id: 365,
    Code: '1132',
    Name: 'Director, export'
  },
  {
    Id: 366,
    Code: '1132',
    Name: 'Director, marketing, digital'
  },
  {
    Id: 367,
    Code: '1132',
    Name: 'Director, marketing'
  },
  {
    Id: 368,
    Code: '1132',
    Name: 'Director, media, social'
  },
  {
    Id: 369,
    Code: '1132',
    Name: 'Director, media'
  },
  {
    Id: 370,
    Code: '1132',
    Name: 'Director, sales, international'
  },
  {
    Id: 371,
    Code: '1132',
    Name: 'Director, sales, regional'
  },
  {
    Id: 372,
    Code: '1132',
    Name: 'Director, sales'
  },
  {
    Id: 373,
    Code: '1132',
    Name: 'Director, sales and export'
  },
  {
    Id: 374,
    Code: '1132',
    Name: 'Director, sales and marketing'
  },
  {
    Id: 375,
    Code: '1132',
    Name: 'Director, technical, marketing'
  },
  {
    Id: 376,
    Code: '1132',
    Name: 'Director, marketing'
  },
  {
    Id: 377,
    Code: '1132',
    Name: 'Director, telephone sales'
  },
  {
    Id: 378,
    Code: '1132',
    Name: 'Director, advertising'
  },
  {
    Id: 379,
    Code: '1132',
    Name: 'Director, export agency'
  },
  {
    Id: 380,
    Code: '1132',
    Name: 'Director, import agency'
  },
  {
    Id: 381,
    Code: '1132',
    Name: 'Director, market research'
  },
  {
    Id: 382,
    Code: '1132',
    Name: 'Director, ship brokers'
  },
  {
    Id: 383,
    Code: '1132',
    Name: 'Director of business development'
  },
  {
    Id: 384,
    Code: '1132',
    Name: 'Director of fundraising'
  },
  {
    Id: 385,
    Code: '1132',
    Name: 'Director of marketing'
  },
  {
    Id: 386,
    Code: '1132',
    Name: 'Director of sales'
  },
  {
    Id: 387,
    Code: '1132',
    Name: 'Head of advertising'
  },
  {
    Id: 388,
    Code: '1132',
    Name: 'Head of brand'
  },
  {
    Id: 389,
    Code: '1132',
    Name: 'Head of business development'
  },
  {
    Id: 390,
    Code: '1132',
    Name: 'Head of commercial'
  },
  {
    Id: 391,
    Code: '1132',
    Name: 'Head of digital, marketing'
  },
  {
    Id: 392,
    Code: '1132',
    Name: 'Head of digital marketing'
  },
  {
    Id: 393,
    Code: '1132',
    Name: 'Head of digital media'
  },
  {
    Id: 394,
    Code: '1132',
    Name: 'Head of growth'
  },
  {
    Id: 395,
    Code: '1132',
    Name: 'Head of marketing'
  },
  {
    Id: 396,
    Code: '1132',
    Name: 'Head of media'
  },
  {
    Id: 397,
    Code: '1132',
    Name: 'Head of sales'
  },
  {
    Id: 398,
    Code: '1132',
    Name: 'Head of sales and marketing'
  },
  {
    Id: 399,
    Code: '1132',
    Name: 'Head of strategy, marketing'
  },
  {
    Id: 400,
    Code: '1132',
    Name: 'Officer, commercial, chief'
  },
  {
    Id: 401,
    Code: '1132',
    Name: 'Officer, marketing, chief'
  },
  {
    Id: 402,
    Code: '1132',
    Name: 'Owner, advertising agency'
  },
  {
    Id: 403,
    Code: '1132',
    Name: 'Owner, export agency'
  },
  {
    Id: 404,
    Code: '1132',
    Name: 'Owner, import agency'
  },
  {
    Id: 405,
    Code: '1133',
    Name: 'Director, communications'
  },
  {
    Id: 406,
    Code: '1133',
    Name: 'Director, communications and policy, public relations'
  },
  {
    Id: 407,
    Code: '1133',
    Name: 'Director, policy and communications, public relations'
  },
  {
    Id: 408,
    Code: '1133',
    Name: 'Director, relations, public'
  },
  {
    Id: 409,
    Code: '1133',
    Name: 'Director, public relations'
  },
  {
    Id: 410,
    Code: '1133',
    Name: 'Director of communications'
  },
  {
    Id: 411,
    Code: '1133',
    Name: 'Director of communications and policy, public relations'
  },
  {
    Id: 412,
    Code: '1133',
    Name: 'Director of external relations'
  },
  {
    Id: 413,
    Code: '1133',
    Name: 'Director of policy and communications, public relations'
  },
  {
    Id: 414,
    Code: '1133',
    Name: 'Head, public relations'
  },
  {
    Id: 415,
    Code: '1133',
    Name: 'Head of communications'
  },
  {
    Id: 416,
    Code: '1133',
    Name: 'Head of internal communications'
  },
  {
    Id: 417,
    Code: '1133',
    Name: 'Head of public affairs'
  },
  {
    Id: 418,
    Code: '1133',
    Name: 'Head of public relations'
  },
  {
    Id: 419,
    Code: '1134',
    Name: 'Director, estimating'
  },
  {
    Id: 420,
    Code: '1134',
    Name: 'Director, procurement'
  },
  {
    Id: 421,
    Code: '1134',
    Name: 'Director, purchasing'
  },
  {
    Id: 422,
    Code: '1134',
    Name: 'Director of contracts, government'
  },
  {
    Id: 423,
    Code: '1134',
    Name: 'Head of buying'
  },
  {
    Id: 424,
    Code: '1134',
    Name: 'Head of procurement'
  },
  {
    Id: 425,
    Code: '1134',
    Name: 'Head of purchasing'
  },
  {
    Id: 426,
    Code: '1134',
    Name: 'Manager, buying'
  },
  {
    Id: 427,
    Code: '1134',
    Name: 'Manager, category, procurement'
  },
  {
    Id: 428,
    Code: '1134',
    Name: 'Manager, category, purchasing'
  },
  {
    Id: 429,
    Code: '1134',
    Name: 'Manager, commissioning'
  },
  {
    Id: 430,
    Code: '1134',
    Name: 'Manager, contract, purchasing'
  },
  {
    Id: 431,
    Code: '1134',
    Name: 'Manager, contracts, purchasing'
  },
  {
    Id: 432,
    Code: '1134',
    Name: 'Manager, estimating'
  },
  {
    Id: 433,
    Code: '1134',
    Name: 'Manager, procurement'
  },
  {
    Id: 434,
    Code: '1134',
    Name: 'Manager, production, bid'
  },
  {
    Id: 435,
    Code: '1134',
    Name: 'Manager, purchase'
  },
  {
    Id: 436,
    Code: '1134',
    Name: 'Manager, purchasing'
  },
  {
    Id: 437,
    Code: '1134',
    Name: 'Manager, relationship, supplier'
  },
  {
    Id: 438,
    Code: '1134',
    Name: 'Manager, sourcing'
  },
  {
    Id: 439,
    Code: '1134',
    Name: 'Manager, supplier'
  },
  {
    Id: 440,
    Code: '1134',
    Name: 'Manager, supplies'
  },
  {
    Id: 441,
    Code: '1135',
    Name: 'Director, charity'
  },
  {
    Id: 442,
    Code: '1135',
    Name: 'Director, divisional, British Red Cross'
  },
  {
    Id: 443,
    Code: '1135',
    Name: 'Director, charitable organisation'
  },
  {
    Id: 444,
    Code: '1135',
    Name: 'Director of charity'
  },
  {
    Id: 445,
    Code: '1135',
    Name: 'Director of fundraising, charitable organisation'
  },
  {
    Id: 446,
    Code: '1135',
    Name: 'Manager, charity'
  },
  {
    Id: 447,
    Code: '1135',
    Name: 'Manager, development, donor, charitable organisation'
  },
  {
    Id: 448,
    Code: '1135',
    Name: 'Manager, relationship, charitable organisation'
  },
  {
    Id: 449,
    Code: '1135',
    Name: 'Manager, charitable organisation'
  },
  {
    Id: 450,
    Code: '1135',
    Name: 'Manager, religious organisation'
  },
  {
    Id: 451,
    Code: '1135',
    Name: 'Organiser, national, charitable organisation'
  },
  {
    Id: 452,
    Code: '1135',
    Name: 'Secretary, area, charitable organisation'
  },
  {
    Id: 453,
    Code: '1135',
    Name: 'Secretary, general, charitable organisation'
  },
  {
    Id: 454,
    Code: '1136',
    Name: 'Director, compensation and benefits'
  },
  {
    Id: 455,
    Code: '1136',
    Name: 'Director, development, management'
  },
  {
    Id: 456,
    Code: '1136',
    Name: 'Director, personnel'
  },
  {
    Id: 457,
    Code: '1136',
    Name: 'Director, recruitment'
  },
  {
    Id: 458,
    Code: '1136',
    Name: 'Director, resources, human'
  },
  {
    Id: 459,
    Code: '1136',
    Name: 'Director, training'
  },
  {
    Id: 460,
    Code: '1136',
    Name: 'Director, human resources'
  },
  {
    Id: 461,
    Code: '1136',
    Name: 'Director of communications, human resources'
  },
  {
    Id: 462,
    Code: '1136',
    Name: 'Director of human resources'
  },
  {
    Id: 463,
    Code: '1136',
    Name: 'Director of personnel'
  },
  {
    Id: 464,
    Code: '1136',
    Name: 'Director of training'
  },
  {
    Id: 465,
    Code: '1136',
    Name: 'Head of HR'
  },
  {
    Id: 466,
    Code: '1136',
    Name: 'Head of human resources'
  },
  {
    Id: 467,
    Code: '1136',
    Name: 'Head of learning and development'
  },
  {
    Id: 468,
    Code: '1136',
    Name: 'Head of personnel'
  },
  {
    Id: 469,
    Code: '1136',
    Name: 'Head of recruitment'
  },
  {
    Id: 470,
    Code: '1136',
    Name: 'Head of training'
  },
  {
    Id: 471,
    Code: '1136',
    Name: 'Manager, acquisition, talent'
  },
  {
    Id: 472,
    Code: '1136',
    Name: 'Manager, change, HR'
  },
  {
    Id: 473,
    Code: '1136',
    Name: 'Manager, compensation and benefits'
  },
  {
    Id: 474,
    Code: '1136',
    Name: 'Manager, delivery, service, HR'
  },
  {
    Id: 475,
    Code: '1136',
    Name: 'Manager, development, employee'
  },
  {
    Id: 476,
    Code: '1136',
    Name: 'Manager, development, people'
  },
  {
    Id: 477,
    Code: '1136',
    Name: 'Manager, development, self'
  },
  {
    Id: 478,
    Code: '1136',
    Name: 'Manager, development, training'
  },
  {
    Id: 479,
    Code: '1136',
    Name: 'Manager, diversity and equality'
  },
  {
    Id: 480,
    Code: '1136',
    Name: 'Manager, equality and diversity'
  },
  {
    Id: 481,
    Code: '1136',
    Name: 'Manager, HR'
  },
  {
    Id: 482,
    Code: '1136',
    Name: 'Manager, O&M'
  },
  {
    Id: 483,
    Code: '1136',
    Name: 'Manager, operations, HR'
  },
  {
    Id: 484,
    Code: '1136',
    Name: 'Manager, organisation and efficiency'
  },
  {
    Id: 485,
    Code: '1136',
    Name: 'Manager, organisation and methods'
  },
  {
    Id: 486,
    Code: '1136',
    Name: 'Manager, people'
  },
  {
    Id: 487,
    Code: '1136',
    Name: 'Manager, personnel'
  },
  {
    Id: 488,
    Code: '1136',
    Name: 'Manager, personnel and training'
  },
  {
    Id: 489,
    Code: '1136',
    Name: 'Manager, planning, resource'
  },
  {
    Id: 490,
    Code: '1136',
    Name: 'Manager, recruitment'
  },
  {
    Id: 491,
    Code: '1136',
    Name: 'Manager, relations, employee'
  },
  {
    Id: 492,
    Code: '1136',
    Name: 'Manager, relations, industrial'
  },
  {
    Id: 493,
    Code: '1136',
    Name: 'Manager, research, operational'
  },
  {
    Id: 494,
    Code: '1136',
    Name: 'Manager, resource, human'
  },
  {
    Id: 495,
    Code: '1136',
    Name: 'Manager, resources, human'
  },
  {
    Id: 496,
    Code: '1136',
    Name: 'Manager, reward'
  },
  {
    Id: 497,
    Code: '1136',
    Name: 'Manager, services, management'
  },
  {
    Id: 498,
    Code: '1136',
    Name: 'Manager, services, people, human resources'
  },
  {
    Id: 499,
    Code: '1136',
    Name: 'Manager, staff'
  },
  {
    Id: 500,
    Code: '1136',
    Name: 'Manager, study, works'
  },
  {
    Id: 501,
    Code: '1136',
    Name: 'Manager, talent'
  },
  {
    Id: 502,
    Code: '1136',
    Name: 'Manager, unit, NVQ'
  },
  {
    Id: 503,
    Code: '1136',
    Name: 'Manager, volunteer'
  },
  {
    Id: 504,
    Code: '1136',
    Name: 'Manager, training establishment'
  },
  {
    Id: 505,
    Code: '1136',
    Name: 'Manager, vocational training'
  },
  {
    Id: 506,
    Code: '1136',
    Name: 'Partner, acquisition, talent'
  },
  {
    Id: 507,
    Code: '1136',
    Name: 'Partner, business, HR'
  },
  {
    Id: 508,
    Code: '1136',
    Name: 'Partner, business, people'
  },
  {
    Id: 509,
    Code: '1136',
    Name: 'Partner, business, recruitment'
  },
  {
    Id: 510,
    Code: '1136',
    Name: 'Partner, business, resources, human'
  },
  {
    Id: 511,
    Code: '1137',
    Name: 'CIO'
  },
  {
    Id: 512,
    Code: '1137',
    Name: 'COO, computer services'
  },
  {
    Id: 513,
    Code: '1137',
    Name: 'CTO'
  },
  {
    Id: 514,
    Code: '1137',
    Name: 'Director, development, computer services'
  },
  {
    Id: 515,
    Code: '1137',
    Name: 'Director, digital'
  },
  {
    Id: 516,
    Code: '1137',
    Name: 'Director, IT'
  },
  {
    Id: 517,
    Code: '1137',
    Name: 'Director, managing, company, software'
  },
  {
    Id: 518,
    Code: '1137',
    Name: 'Director, managing, computer services'
  },
  {
    Id: 519,
    Code: '1137',
    Name: 'Director, operations, computer services'
  },
  {
    Id: 520,
    Code: '1137',
    Name: 'Director, product, computer services'
  },
  {
    Id: 521,
    Code: '1137',
    Name: 'Director, programme, computing'
  },
  {
    Id: 522,
    Code: '1137',
    Name: 'Director, technical, computer'
  },
  {
    Id: 523,
    Code: '1137',
    Name: 'Director, technical, computer services'
  },
  {
    Id: 524,
    Code: '1137',
    Name: 'Director, technical, telecoms'
  },
  {
    Id: 525,
    Code: '1137',
    Name: 'Director, technology'
  },
  {
    Id: 526,
    Code: '1137',
    Name: 'Director, telecommunications'
  },
  {
    Id: 527,
    Code: '1137',
    Name: 'Director, computing'
  },
  {
    Id: 528,
    Code: '1137',
    Name: 'Director, IT consultancy'
  },
  {
    Id: 529,
    Code: '1137',
    Name: 'Director, telecoms'
  },
  {
    Id: 530,
    Code: '1137',
    Name: 'Director of communications, computing'
  },
  {
    Id: 531,
    Code: '1137',
    Name: 'Director of information security'
  },
  {
    Id: 532,
    Code: '1137',
    Name: 'Director of IT'
  },
  {
    Id: 533,
    Code: '1137',
    Name: 'Director of software engineering'
  },
  {
    Id: 534,
    Code: '1137',
    Name: 'Head of computer services'
  },
  {
    Id: 535,
    Code: '1137',
    Name: 'Head of digital transformation'
  },
  {
    Id: 536,
    Code: '1137',
    Name: 'Head of ICT'
  },
  {
    Id: 537,
    Code: '1137',
    Name: 'Head of information security'
  },
  {
    Id: 538,
    Code: '1137',
    Name: 'Head of information technology'
  },
  {
    Id: 539,
    Code: '1137',
    Name: 'Head of IT'
  },
  {
    Id: 540,
    Code: '1137',
    Name: 'Head of IT operations'
  },
  {
    Id: 541,
    Code: '1137',
    Name: 'Head of operations, computer services'
  },
  {
    Id: 542,
    Code: '1137',
    Name: 'Head of software development'
  },
  {
    Id: 543,
    Code: '1137',
    Name: 'Head of technology'
  },
  {
    Id: 544,
    Code: '1137',
    Name: 'Manager, programme, IT'
  },
  {
    Id: 545,
    Code: '1137',
    Name: 'Manager, programme, computing'
  },
  {
    Id: 546,
    Code: '1137',
    Name: 'Manager, programme, telecoms'
  },
  {
    Id: 547,
    Code: '1137',
    Name: 'Officer, information, chief'
  },
  {
    Id: 548,
    Code: '1137',
    Name: 'Officer, operating, chief, computer services'
  },
  {
    Id: 549,
    Code: '1137',
    Name: 'Officer, technical, chief'
  },
  {
    Id: 550,
    Code: '1137',
    Name: 'Officer, technology, chief'
  },
  {
    Id: 551,
    Code: '1137',
    Name: 'Owner, computer services'
  },
  {
    Id: 552,
    Code: '1139',
    Name: 'Chief of staff'
  },
  {
    Id: 553,
    Code: '1139',
    Name: 'Clerk, chief, local government'
  },
  {
    Id: 554,
    Code: '1139',
    Name: 'Clerk, deputy, local government'
  },
  {
    Id: 555,
    Code: '1139',
    Name: 'Clerk, principal, local government'
  },
  {
    Id: 556,
    Code: '1139',
    Name: 'Clerk, staff, local government'
  },
  {
    Id: 557,
    Code: '1139',
    Name: 'Clerk, town'
  },
  {
    Id: 558,
    Code: '1139',
    Name: 'Clerk of the council'
  },
  {
    Id: 559,
    Code: '1139',
    Name: 'Clerk to the board, local government'
  },
  {
    Id: 560,
    Code: '1139',
    Name: 'Clerk to the corporation'
  },
  {
    Id: 561,
    Code: '1139',
    Name: 'Clerk to the council'
  },
  {
    Id: 562,
    Code: '1139',
    Name: 'Clerk to the county council'
  },
  {
    Id: 563,
    Code: '1139',
    Name: 'Clerk to the district council'
  },
  {
    Id: 564,
    Code: '1139',
    Name: 'Clerk to the parish council'
  },
  {
    Id: 565,
    Code: '1139',
    Name: 'Collector, rate, chief'
  },
  {
    Id: 566,
    Code: '1139',
    Name: 'Director, accounts'
  },
  {
    Id: 567,
    Code: '1139',
    Name: 'Director, admin'
  },
  {
    Id: 568,
    Code: '1139',
    Name: 'Director, administration'
  },
  {
    Id: 569,
    Code: '1139',
    Name: 'Director, audit, internal'
  },
  {
    Id: 570,
    Code: '1139',
    Name: 'Director, audit'
  },
  {
    Id: 571,
    Code: '1139',
    Name: 'Director, event, company director'
  },
  {
    Id: 572,
    Code: '1139',
    Name: 'Director, events, company director'
  },
  {
    Id: 573,
    Code: '1139',
    Name: 'Director, experience, customer'
  },
  {
    Id: 574,
    Code: '1139',
    Name: 'Director, legal'
  },
  {
    Id: 575,
    Code: '1139',
    Name: 'Director, programme'
  },
  {
    Id: 576,
    Code: '1139',
    Name: 'Director, project'
  },
  {
    Id: 577,
    Code: '1139',
    Name: 'Director, quality'
  },
  {
    Id: 578,
    Code: '1139',
    Name: 'Director, research'
  },
  {
    Id: 579,
    Code: '1139',
    Name: 'Director, scientific'
  },
  {
    Id: 580,
    Code: '1139',
    Name: 'Director, service, customer'
  },
  {
    Id: 581,
    Code: '1139',
    Name: 'Director, services, customer'
  },
  {
    Id: 582,
    Code: '1139',
    Name: 'Director, technical, research and development'
  },
  {
    Id: 583,
    Code: '1139',
    Name: 'Director, conference organisers'
  },
  {
    Id: 584,
    Code: '1139',
    Name: 'Director, corporate hospitality'
  },
  {
    Id: 585,
    Code: '1139',
    Name: 'Director, Environment Agency'
  },
  {
    Id: 586,
    Code: '1139',
    Name: 'Director, legal services'
  },
  {
    Id: 587,
    Code: '1139',
    Name: 'Director, local government'
  },
  {
    Id: 588,
    Code: '1139',
    Name: 'Director, research and development'
  },
  {
    Id: 589,
    Code: '1139',
    Name: 'Director of administration'
  },
  {
    Id: 590,
    Code: '1139',
    Name: 'Director of corporate services'
  },
  {
    Id: 591,
    Code: '1139',
    Name: 'Director of events, company director'
  },
  {
    Id: 592,
    Code: '1139',
    Name: 'Director of research and development'
  },
  {
    Id: 593,
    Code: '1139',
    Name: 'Head of accounts'
  },
  {
    Id: 594,
    Code: '1139',
    Name: 'Head of audit'
  },
  {
    Id: 595,
    Code: '1139',
    Name: 'Head of complaints'
  },
  {
    Id: 596,
    Code: '1139',
    Name: 'Head of events'
  },
  {
    Id: 597,
    Code: '1139',
    Name: 'Head of intellectual property'
  },
  {
    Id: 598,
    Code: '1139',
    Name: 'Head of internal audit'
  },
  {
    Id: 599,
    Code: '1139',
    Name: 'Head of legal'
  },
  {
    Id: 600,
    Code: '1139',
    Name: 'Head of portfolio management'
  },
  {
    Id: 601,
    Code: '1139',
    Name: 'Head of strategy'
  },
  {
    Id: 602,
    Code: '1139',
    Name: 'Manager, branch, trade association'
  },
  {
    Id: 603,
    Code: '1139',
    Name: 'Manager, operations, local government'
  },
  {
    Id: 604,
    Code: '1139',
    Name: 'Manager, planning, manufacturing'
  },
  {
    Id: 605,
    Code: '1139',
    Name: 'Manager, programme'
  },
  {
    Id: 606,
    Code: '1139',
    Name: 'Manager, property, intellectual'
  },
  {
    Id: 607,
    Code: '1139',
    Name: 'Manager, service, registration'
  },
  {
    Id: 608,
    Code: '1139',
    Name: 'Manager, professional association'
  },
  {
    Id: 609,
    Code: '1139',
    Name: 'Manager, trade association'
  },
  {
    Id: 610,
    Code: '1139',
    Name: 'Manager, trade union'
  },
  {
    Id: 611,
    Code: '1139',
    Name: 'Officer, chief, local government'
  },
  {
    Id: 612,
    Code: '1139',
    Name: 'Ombudsman'
  },
  {
    Id: 613,
    Code: '1139',
    Name: 'Organiser, national, trade union'
  },
  {
    Id: 614,
    Code: '1139',
    Name: 'President, union'
  },
  {
    Id: 615,
    Code: '1139',
    Name: 'President, trade union'
  },
  {
    Id: 616,
    Code: '1139',
    Name: 'Secretary, area, professional organisation'
  },
  {
    Id: 617,
    Code: '1139',
    Name: 'Secretary, area, trade association'
  },
  {
    Id: 618,
    Code: '1139',
    Name: 'Secretary, area, trade union'
  },
  {
    Id: 619,
    Code: '1139',
    Name: 'Secretary, diocesan'
  },
  {
    Id: 620,
    Code: '1139',
    Name: 'Secretary, general, professional association'
  },
  {
    Id: 621,
    Code: '1139',
    Name: 'Secretary, general, trade association'
  },
  {
    Id: 622,
    Code: '1139',
    Name: 'Secretary, general, trade union'
  },
  {
    Id: 623,
    Code: '1139',
    Name: 'Secretary, managing, co-operative society'
  },
  {
    Id: 624,
    Code: '1139',
    Name: 'Secretary, national, trade union'
  },
  {
    Id: 625,
    Code: '1140',
    Name: 'Commandant, airport'
  },
  {
    Id: 626,
    Code: '1140',
    Name: 'Controller, chief, railways'
  },
  {
    Id: 627,
    Code: '1140',
    Name: 'Director, airport'
  },
  {
    Id: 628,
    Code: '1140',
    Name: 'Director, chain, supply'
  },
  {
    Id: 629,
    Code: '1140',
    Name: 'Director, distribution'
  },
  {
    Id: 630,
    Code: '1140',
    Name: 'Director, logistics'
  },
  {
    Id: 631,
    Code: '1140',
    Name: 'Director, operations, transport'
  },
  {
    Id: 632,
    Code: '1140',
    Name: 'Director, technical, transport'
  },
  {
    Id: 633,
    Code: '1140',
    Name: 'Director, traffic, transport'
  },
  {
    Id: 634,
    Code: '1140',
    Name: 'Director, transport'
  },
  {
    Id: 635,
    Code: '1140',
    Name: 'Director, transport'
  },
  {
    Id: 636,
    Code: '1140',
    Name: 'Director, warehousing'
  },
  {
    Id: 637,
    Code: '1140',
    Name: 'Head of logistics'
  },
  {
    Id: 638,
    Code: '1140',
    Name: 'Head of supply chain'
  },
  {
    Id: 639,
    Code: '1140',
    Name: 'Owner, service, courier'
  },
  {
    Id: 640,
    Code: '1140',
    Name: 'Owner, courier service'
  },
  {
    Id: 641,
    Code: '1140',
    Name: 'Owner, delivery service'
  },
  {
    Id: 642,
    Code: '1140',
    Name: 'Owner, haulage contractor'
  },
  {
    Id: 643,
    Code: '1150',
    Name: 'Director, development, wholesale, retail trade'
  },
  {
    Id: 644,
    Code: '1150',
    Name: 'Director, franchise'
  },
  {
    Id: 645,
    Code: '1150',
    Name: 'Director, managing, wholesale, retail trade'
  },
  {
    Id: 646,
    Code: '1150',
    Name: 'Director, retail'
  },
  {
    Id: 647,
    Code: '1150',
    Name: 'Director, store, retail trade'
  },
  {
    Id: 648,
    Code: '1150',
    Name: 'Director, technical, wholesale, retail trade'
  },
  {
    Id: 649,
    Code: '1150',
    Name: 'Director, retail trade'
  },
  {
    Id: 650,
    Code: '1150',
    Name: 'Director, wholesale trade'
  },
  {
    Id: 651,
    Code: '1150',
    Name: 'Head of retail operations'
  },
  {
    Id: 652,
    Code: '1150',
    Name: 'Licensee, petrol station'
  },
  {
    Id: 653,
    Code: '1150',
    Name: 'Manager, area, wholesale/retail trade'
  },
  {
    Id: 654,
    Code: '1150',
    Name: 'Manager, bookshop'
  },
  {
    Id: 655,
    Code: '1150',
    Name: 'Manager, branch, sales, garage'
  },
  {
    Id: 656,
    Code: '1150',
    Name: 'Manager, branch, wholesale, retail trade'
  },
  {
    Id: 657,
    Code: '1150',
    Name: "Manager, butcher's"
  },
  {
    Id: 658,
    Code: '1150',
    Name: 'Manager, centre, garden'
  },
  {
    Id: 659,
    Code: '1150',
    Name: 'Manager, club, clothing'
  },
  {
    Id: 660,
    Code: '1150',
    Name: 'Manager, customer and trading, retail trade'
  },
  {
    Id: 661,
    Code: '1150',
    Name: 'Manager, dairy'
  },
  {
    Id: 662,
    Code: '1150',
    Name: 'Manager, district, retail trade'
  },
  {
    Id: 663,
    Code: '1150',
    Name: 'Manager, district, wholesale trade'
  },
  {
    Id: 664,
    Code: '1150',
    Name: "Manager, fishmonger's"
  },
  {
    Id: 665,
    Code: '1150',
    Name: 'Manager, florist'
  },
  {
    Id: 666,
    Code: '1150',
    Name: 'Manager, licence, off'
  },
  {
    Id: 667,
    Code: '1150',
    Name: 'Manager, operations, retail'
  },
  {
    Id: 668,
    Code: '1150',
    Name: 'Manager, operations, wholesale'
  },
  {
    Id: 669,
    Code: '1150',
    Name: 'Manager, operations, wholesale, retail trade'
  },
  {
    Id: 670,
    Code: '1150',
    Name: 'Manager, order, mail'
  },
  {
    Id: 671,
    Code: '1150',
    Name: 'Manager, planning, retail trade'
  },
  {
    Id: 672,
    Code: '1150',
    Name: 'Manager, production, wholesale, retail trade'
  },
  {
    Id: 673,
    Code: '1150',
    Name: 'Manager, retail'
  },
  {
    Id: 674,
    Code: '1150',
    Name: 'Manager, room, sales'
  },
  {
    Id: 675,
    Code: '1150',
    Name: 'Manager, room, show'
  },
  {
    Id: 676,
    Code: '1150',
    Name: 'Manager, sales, car'
  },
  {
    Id: 677,
    Code: '1150',
    Name: 'Manager, sales, fleet'
  },
  {
    Id: 678,
    Code: '1150',
    Name: 'Manager, sales, vehicles'
  },
  {
    Id: 679,
    Code: '1150',
    Name: 'Manager, sales, car sales'
  },
  {
    Id: 680,
    Code: '1150',
    Name: 'Manager, sales, retail trade'
  },
  {
    Id: 681,
    Code: '1150',
    Name: 'Manager, sales, wholesale trade'
  },
  {
    Id: 682,
    Code: '1150',
    Name: 'Manager, service, retail trade'
  },
  {
    Id: 683,
    Code: '1150',
    Name: 'Manager, shift, wholesale, retail trade'
  },
  {
    Id: 684,
    Code: '1150',
    Name: "Manager, shop, butcher's"
  },
  {
    Id: 685,
    Code: '1150',
    Name: 'Manager, shop, charity'
  },
  {
    Id: 686,
    Code: '1150',
    Name: 'Manager, shop, farm'
  },
  {
    Id: 687,
    Code: '1150',
    Name: "Manager, shop, fishmonger's"
  },
  {
    Id: 688,
    Code: '1150',
    Name: 'Manager, shop, retail'
  },
  {
    Id: 689,
    Code: '1150',
    Name: 'Manager, shop, charitable organisation'
  },
  {
    Id: 690,
    Code: '1150',
    Name: 'Manager, shop, agriculture'
  },
  {
    Id: 691,
    Code: '1150',
    Name: 'Manager, shop, horticulture'
  },
  {
    Id: 692,
    Code: '1150',
    Name: 'Manager, shop, retail trade: pharmacy'
  },
  {
    Id: 693,
    Code: '1150',
    Name: 'Manager, shop, wholesale, retail trade'
  },
  {
    Id: 694,
    Code: '1150',
    Name: 'Manager, showroom'
  },
  {
    Id: 695,
    Code: '1150',
    Name: 'Manager, site, retail trade'
  },
  {
    Id: 696,
    Code: '1150',
    Name: 'Manager, stall, book'
  },
  {
    Id: 697,
    Code: '1150',
    Name: 'Manager, station, petrol'
  },
  {
    Id: 698,
    Code: '1150',
    Name: 'Manager, station, service'
  },
  {
    Id: 699,
    Code: '1150',
    Name: 'Manager, store, retail'
  },
  {
    Id: 700,
    Code: '1150',
    Name: 'Manager, store, wholesale, retail trade'
  },
  {
    Id: 701,
    Code: '1150',
    Name: 'Manager, stores, wholesale, retail trade'
  },
  {
    Id: 702,
    Code: '1150',
    Name: 'Manager, studio, retail trade'
  },
  {
    Id: 703,
    Code: '1150',
    Name: 'Manager, supermarket'
  },
  {
    Id: 704,
    Code: '1150',
    Name: "Manager, tailor's"
  },
  {
    Id: 705,
    Code: '1150',
    Name: 'Manager, technical, wholesale, retail trade'
  },
  {
    Id: 706,
    Code: '1150',
    Name: 'Manager, trade'
  },
  {
    Id: 707,
    Code: '1150',
    Name: 'Manager, trading, customer, retail trade'
  },
  {
    Id: 708,
    Code: '1150',
    Name: 'Manager, wholesale'
  },
  {
    Id: 709,
    Code: '1150',
    Name: 'Manager, charitable organisation: retail'
  },
  {
    Id: 710,
    Code: '1150',
    Name: "Manager, builders' merchants"
  },
  {
    Id: 711,
    Code: '1150',
    Name: 'Manager, filling station'
  },
  {
    Id: 712,
    Code: '1150',
    Name: 'Manager, fuel merchant'
  },
  {
    Id: 713,
    Code: '1150',
    Name: 'Manager, sales, garage'
  },
  {
    Id: 714,
    Code: '1150',
    Name: 'Manager, garden centre'
  },
  {
    Id: 715,
    Code: '1150',
    Name: 'Manager, livestock dealing'
  },
  {
    Id: 716,
    Code: '1150',
    Name: 'Manager, mail order house'
  },
  {
    Id: 717,
    Code: '1150',
    Name: 'Manager, NAAFI: shop'
  },
  {
    Id: 718,
    Code: '1150',
    Name: 'Manager, off-licence'
  },
  {
    Id: 719,
    Code: '1150',
    Name: 'Manager, pawnbrokers'
  },
  {
    Id: 720,
    Code: '1150',
    Name: 'Manager, petrol station'
  },
  {
    Id: 721,
    Code: '1150',
    Name: 'Manager, retail trade'
  },
  {
    Id: 722,
    Code: '1150',
    Name: 'Manager, steel stockholders'
  },
  {
    Id: 723,
    Code: '1150',
    Name: 'Manager, timber merchants'
  },
  {
    Id: 724,
    Code: '1150',
    Name: 'Manager, wholesale trade'
  },
  {
    Id: 725,
    Code: '1161',
    Name: 'Adjutant'
  },
  {
    Id: 726,
    Code: '1161',
    Name: 'Adjutant-General'
  },
  {
    Id: 727,
    Code: '1161',
    Name: 'Admiral'
  },
  {
    Id: 728,
    Code: '1161',
    Name: 'Admiral of the Fleet'
  },
  {
    Id: 729,
    Code: '1161',
    Name: 'Aide-de-Camp'
  },
  {
    Id: 730,
    Code: '1161',
    Name: 'Air-Marshal'
  },
  {
    Id: 731,
    Code: '1161',
    Name: 'Brigadier'
  },
  {
    Id: 732,
    Code: '1161',
    Name: 'Cadet, officer'
  },
  {
    Id: 733,
    Code: '1161',
    Name: 'Captain, group'
  },
  {
    Id: 734,
    Code: '1161',
    Name: "Captain, ship's, armed forces"
  },
  {
    Id: 735,
    Code: '1161',
    Name: 'Captain, armed forces'
  },
  {
    Id: 736,
    Code: '1161',
    Name: 'Chaplain, armed forces'
  },
  {
    Id: 737,
    Code: '1161',
    Name: 'Colonel'
  },
  {
    Id: 738,
    Code: '1161',
    Name: 'Colonel-Commandant'
  },
  {
    Id: 739,
    Code: '1161',
    Name: 'Commandant, armed forces'
  },
  {
    Id: 740,
    Code: '1161',
    Name: 'Commander, wing'
  },
  {
    Id: 741,
    Code: '1161',
    Name: 'Commander, armed forces'
  },
  {
    Id: 742,
    Code: '1161',
    Name: 'Commodore, air'
  },
  {
    Id: 743,
    Code: '1161',
    Name: 'Commodore, armed forces'
  },
  {
    Id: 744,
    Code: '1161',
    Name: 'Director, WRNS'
  },
  {
    Id: 745,
    Code: '1161',
    Name: 'Field-Marshal'
  },
  {
    Id: 746,
    Code: '1161',
    Name: 'Flight-Lieutenant'
  },
  {
    Id: 747,
    Code: '1161',
    Name: 'General, armed forces'
  },
  {
    Id: 748,
    Code: '1161',
    Name: 'Leader, Squadron'
  },
  {
    Id: 749,
    Code: '1161',
    Name: 'Lieutenant'
  },
  {
    Id: 750,
    Code: '1161',
    Name: 'Lieutenant-Colonel'
  },
  {
    Id: 751,
    Code: '1161',
    Name: 'Lieutenant-Commander'
  },
  {
    Id: 752,
    Code: '1161',
    Name: 'Lieutenant-General'
  },
  {
    Id: 753,
    Code: '1161',
    Name: 'Major, armed forces'
  },
  {
    Id: 754,
    Code: '1161',
    Name: 'Major-General'
  },
  {
    Id: 755,
    Code: '1161',
    Name: 'Marshal, air'
  },
  {
    Id: 756,
    Code: '1161',
    Name: 'Marshal, chief, air'
  },
  {
    Id: 757,
    Code: '1161',
    Name: 'Marshal, field'
  },
  {
    Id: 758,
    Code: '1161',
    Name: 'Marshal, vice, air'
  },
  {
    Id: 759,
    Code: '1161',
    Name: 'Marshal of the RAF'
  },
  {
    Id: 760,
    Code: '1161',
    Name: 'Marshal of the Royal Air Force'
  },
  {
    Id: 761,
    Code: '1161',
    Name: 'Midshipman, armed forces'
  },
  {
    Id: 762,
    Code: '1161',
    Name: 'Officer, army'
  },
  {
    Id: 763,
    Code: '1161',
    Name: 'Officer, commanding'
  },
  {
    Id: 764,
    Code: '1161',
    Name: 'Officer, commando, Marines, Royal'
  },
  {
    Id: 765,
    Code: '1161',
    Name: 'Officer, commissioned, army'
  },
  {
    Id: 766,
    Code: '1161',
    Name: 'Officer, commissioned, Royal Air Force'
  },
  {
    Id: 767,
    Code: '1161',
    Name: 'Officer, commissioned, Royal Navy'
  },
  {
    Id: 768,
    Code: '1161',
    Name: 'Officer, commissioned'
  },
  {
    Id: 769,
    Code: '1161',
    Name: 'Officer, disposal, bomb'
  },
  {
    Id: 770,
    Code: '1161',
    Name: 'Officer, flag'
  },
  {
    Id: 771,
    Code: '1161',
    Name: 'Officer, flying'
  },
  {
    Id: 772,
    Code: '1161',
    Name: 'Officer, Force, Air, Royal'
  },
  {
    Id: 773,
    Code: '1161',
    Name: 'Officer, Marines, Royal'
  },
  {
    Id: 774,
    Code: '1161',
    Name: 'Officer, medical, armed forces'
  },
  {
    Id: 775,
    Code: '1161',
    Name: 'Officer, naval, Royal'
  },
  {
    Id: 776,
    Code: '1161',
    Name: 'Officer, Navy, Royal'
  },
  {
    Id: 777,
    Code: '1161',
    Name: 'Officer, pilot, armed forces'
  },
  {
    Id: 778,
    Code: '1161',
    Name: 'Officer, RAF'
  },
  {
    Id: 779,
    Code: '1161',
    Name: 'Officer, staff, general'
  },
  {
    Id: 780,
    Code: '1161',
    Name: 'Officer, armed forces'
  },
  {
    Id: 781,
    Code: '1161',
    Name: 'Officer, WRNS'
  },
  {
    Id: 782,
    Code: '1161',
    Name: 'Quartermaster-General'
  },
  {
    Id: 783,
    Code: '1161',
    Name: 'Rear-Admiral'
  },
  {
    Id: 784,
    Code: '1161',
    Name: 'Squadron-Leader'
  },
  {
    Id: 785,
    Code: '1161',
    Name: 'Sub-Lieutenant'
  },
  {
    Id: 786,
    Code: '1161',
    Name: 'Superintendent, WRNS'
  },
  {
    Id: 787,
    Code: '1161',
    Name: 'Vice-Admiral'
  },
  {
    Id: 788,
    Code: '1162',
    Name: 'Commander, police service'
  },
  {
    Id: 789,
    Code: '1162',
    Name: 'Commissioner, police service'
  },
  {
    Id: 790,
    Code: '1162',
    Name: 'Constable, chief, assistant'
  },
  {
    Id: 791,
    Code: '1162',
    Name: 'Constable, chief, deputy'
  },
  {
    Id: 792,
    Code: '1162',
    Name: 'Constable, chief'
  },
  {
    Id: 793,
    Code: '1162',
    Name: 'Inspector, chief, police service'
  },
  {
    Id: 794,
    Code: '1162',
    Name: 'Inspector, detective'
  },
  {
    Id: 795,
    Code: '1162',
    Name: 'Inspector, divisional, police service'
  },
  {
    Id: 796,
    Code: '1162',
    Name: 'Inspector, police'
  },
  {
    Id: 797,
    Code: '1162',
    Name: 'Inspector, police service'
  },
  {
    Id: 798,
    Code: '1162',
    Name: 'Officer, CID'
  },
  {
    Id: 799,
    Code: '1162',
    Name: 'Sub-inspector, MOD'
  },
  {
    Id: 800,
    Code: '1162',
    Name: 'Superintendent, chief, police'
  },
  {
    Id: 801,
    Code: '1162',
    Name: 'Superintendent, chief, police service'
  },
  {
    Id: 802,
    Code: '1162',
    Name: 'Superintendent, police'
  },
  {
    Id: 803,
    Code: '1162',
    Name: 'Superintendent, police service'
  },
  {
    Id: 804,
    Code: '1163',
    Name: 'Captain, fire'
  },
  {
    Id: 805,
    Code: '1163',
    Name: 'Commandant, fire service'
  },
  {
    Id: 806,
    Code: '1163',
    Name: 'Commander, station, fire service'
  },
  {
    Id: 807,
    Code: '1163',
    Name: 'Commander, ambulance service'
  },
  {
    Id: 808,
    Code: '1163',
    Name: 'Commander, fire service'
  },
  {
    Id: 809,
    Code: '1163',
    Name: 'Controller, area, fire service'
  },
  {
    Id: 810,
    Code: '1163',
    Name: 'Controller, senior, fire service'
  },
  {
    Id: 811,
    Code: '1163',
    Name: 'Controller, fire service'
  },
  {
    Id: 812,
    Code: '1163',
    Name: 'Controller, prison service'
  },
  {
    Id: 813,
    Code: '1163',
    Name: 'Firemaster, Scotland'
  },
  {
    Id: 814,
    Code: '1163',
    Name: 'Governor, prison'
  },
  {
    Id: 815,
    Code: '1163',
    Name: 'Governor, prison service'
  },
  {
    Id: 816,
    Code: '1163',
    Name: 'Inspector, fire, fire service'
  },
  {
    Id: 817,
    Code: '1163',
    Name: 'Inspector, senior, HM Revenue and Customs'
  },
  {
    Id: 818,
    Code: '1163',
    Name: 'Manager, area, fire service'
  },
  {
    Id: 819,
    Code: '1163',
    Name: 'Manager, brigade'
  },
  {
    Id: 820,
    Code: '1163',
    Name: 'Manager, group, fire service'
  },
  {
    Id: 821,
    Code: '1163',
    Name: 'Manager, shift, UK Visas and Immigration'
  },
  {
    Id: 822,
    Code: '1163',
    Name: 'Manager, station, ambulance'
  },
  {
    Id: 823,
    Code: '1163',
    Name: 'Manager, station, fire service'
  },
  {
    Id: 824,
    Code: '1163',
    Name: 'Manager, prison service'
  },
  {
    Id: 825,
    Code: '1163',
    Name: 'Officer, ambulance, chief'
  },
  {
    Id: 826,
    Code: '1163',
    Name: 'Officer, brigade, fire'
  },
  {
    Id: 827,
    Code: '1163',
    Name: 'Officer, chief, fire service'
  },
  {
    Id: 828,
    Code: '1163',
    Name: 'Officer, chief, prison service'
  },
  {
    Id: 829,
    Code: '1163',
    Name: 'Officer, company, fire service'
  },
  {
    Id: 830,
    Code: '1163',
    Name: 'Officer, control, fire, principal, fire service'
  },
  {
    Id: 831,
    Code: '1163',
    Name: 'Officer, corps, salvage'
  },
  {
    Id: 832,
    Code: '1163',
    Name: 'Officer, district, salvage corps'
  },
  {
    Id: 833,
    Code: '1163',
    Name: 'Officer, divisional, fire service'
  },
  {
    Id: 834,
    Code: '1163',
    Name: 'Officer, fire, authority, local'
  },
  {
    Id: 835,
    Code: '1163',
    Name: 'Officer, fire, chief'
  },
  {
    Id: 836,
    Code: '1163',
    Name: 'Officer, fire, divisional'
  },
  {
    Id: 837,
    Code: '1163',
    Name: 'Officer, fire, senior'
  },
  {
    Id: 838,
    Code: '1163',
    Name: 'Officer, first, fire service'
  },
  {
    Id: 839,
    Code: '1163',
    Name: 'Officer, fraud, chief, HM Revenue and Customs'
  },
  {
    Id: 840,
    Code: '1163',
    Name: 'Officer, immigration, chief'
  },
  {
    Id: 841,
    Code: '1163',
    Name: 'Officer, investigating, chief, HM Revenue and Customs'
  },
  {
    Id: 842,
    Code: '1163',
    Name: 'Officer, investigating, fraud, chief, HM Revenue and Customs'
  },
  {
    Id: 843,
    Code: '1163',
    Name: 'Officer, investigation, chief, HM Revenue and Customs'
  },
  {
    Id: 844,
    Code: '1163',
    Name: 'Officer, investigation, fraud, chief, HM Revenue and Customs'
  },
  {
    Id: 845,
    Code: '1163',
    Name: 'Officer, preventive, chief'
  },
  {
    Id: 846,
    Code: '1163',
    Name: 'Officer, principal, prison service'
  },
  {
    Id: 847,
    Code: '1163',
    Name: 'Officer, prison, chief'
  },
  {
    Id: 848,
    Code: '1163',
    Name: 'Officer, prison, principal'
  },
  {
    Id: 849,
    Code: '1163',
    Name: 'Officer, prison, senior'
  },
  {
    Id: 850,
    Code: '1163',
    Name: 'Officer, protection, fire'
  },
  {
    Id: 851,
    Code: '1163',
    Name: 'Officer, salvage, fire'
  },
  {
    Id: 852,
    Code: '1163',
    Name: 'Officer, salvage, salvage corps'
  },
  {
    Id: 853,
    Code: '1163',
    Name: 'Officer, second, fire service'
  },
  {
    Id: 854,
    Code: '1163',
    Name: 'Officer, senior, HM Revenue and Customs'
  },
  {
    Id: 855,
    Code: '1163',
    Name: 'Officer, service, fire, government'
  },
  {
    Id: 856,
    Code: '1163',
    Name: 'Officer, station, ambulance service'
  },
  {
    Id: 857,
    Code: '1163',
    Name: 'Officer, station, fire service'
  },
  {
    Id: 858,
    Code: '1163',
    Name: 'Officer, station, salvage corps'
  },
  {
    Id: 859,
    Code: '1163',
    Name: 'Officer, third, fire service'
  },
  {
    Id: 860,
    Code: '1163',
    Name: 'Superintendent, fire'
  },
  {
    Id: 861,
    Code: '1163',
    Name: 'Superintendent, ambulance service'
  },
  {
    Id: 862,
    Code: '1163',
    Name: 'Superintendent, HM Revenue and Customs'
  },
  {
    Id: 863,
    Code: '1163',
    Name: 'Superintendent, prison service'
  },
  {
    Id: 864,
    Code: '1163',
    Name: 'Superintendent, salvage corps'
  },
  {
    Id: 865,
    Code: '1163',
    Name: 'Surveyor, HM Revenue and Customs'
  },
  {
    Id: 866,
    Code: '1163',
    Name: 'Surveyor of Customs and Excise'
  },
  {
    Id: 867,
    Code: '1163',
    Name: 'Warder, chief'
  },
  {
    Id: 868,
    Code: '1171',
    Name: 'Commissioner, health authority'
  },
  {
    Id: 869,
    Code: '1171',
    Name: 'Director, clinical'
  },
  {
    Id: 870,
    Code: '1171',
    Name: 'Director, hospital'
  },
  {
    Id: 871,
    Code: '1171',
    Name: 'Director, medical, hospital service'
  },
  {
    Id: 872,
    Code: '1171',
    Name: 'Director, non-executive, health authority: hospital service'
  },
  {
    Id: 873,
    Code: '1171',
    Name: 'Director, nursing'
  },
  {
    Id: 874,
    Code: '1171',
    Name: 'Director, operations, health authority'
  },
  {
    Id: 875,
    Code: '1171',
    Name: 'Director of clinical services'
  },
  {
    Id: 876,
    Code: '1171',
    Name: 'Director of nursing'
  },
  {
    Id: 877,
    Code: '1171',
    Name: 'Director of nursing services'
  },
  {
    Id: 878,
    Code: '1171',
    Name: 'Head of nursing'
  },
  {
    Id: 879,
    Code: '1171',
    Name: 'Manager, care, health'
  },
  {
    Id: 880,
    Code: '1171',
    Name: 'Manager, care, health authority: hospital service'
  },
  {
    Id: 881,
    Code: '1171',
    Name: 'Manager, centre, postgraduate, health authority: hospital service'
  },
  {
    Id: 882,
    Code: '1171',
    Name: 'Manager, clinical'
  },
  {
    Id: 883,
    Code: '1171',
    Name: 'Manager, commissioning, hospital service'
  },
  {
    Id: 884,
    Code: '1171',
    Name: 'Manager, district, health authority: hospital service'
  },
  {
    Id: 885,
    Code: '1171',
    Name: 'Manager, general, unit, health authority: hospital service'
  },
  {
    Id: 886,
    Code: '1171',
    Name: 'Manager, governance, clinical'
  },
  {
    Id: 887,
    Code: '1171',
    Name: 'Manager, health, public'
  },
  {
    Id: 888,
    Code: '1171',
    Name: 'Manager, healthcare'
  },
  {
    Id: 889,
    Code: '1171',
    Name: 'Manager, information, health authority: hospital service'
  },
  {
    Id: 890,
    Code: '1171',
    Name: 'Manager, locality, health authority: hospital service'
  },
  {
    Id: 891,
    Code: '1171',
    Name: 'Manager, operations, hospital service'
  },
  {
    Id: 892,
    Code: '1171',
    Name: 'Manager, screening, health, health authority: hospital service'
  },
  {
    Id: 893,
    Code: '1171',
    Name: 'Manager, sector, health authority: hospital service'
  },
  {
    Id: 894,
    Code: '1171',
    Name: 'Manager, service, clinical'
  },
  {
    Id: 895,
    Code: '1171',
    Name: 'Manager, service, health'
  },
  {
    Id: 896,
    Code: '1171',
    Name: 'Manager, service, hospital'
  },
  {
    Id: 897,
    Code: '1171',
    Name: 'Manager, service, health authority'
  },
  {
    Id: 898,
    Code: '1171',
    Name: 'Manager, services, clinical'
  },
  {
    Id: 899,
    Code: '1171',
    Name: 'Manager, services, hospital, area'
  },
  {
    Id: 900,
    Code: '1171',
    Name: 'Manager, services, limb'
  },
  {
    Id: 901,
    Code: '1171',
    Name: 'Manager, site, clinical'
  },
  {
    Id: 902,
    Code: '1171',
    Name: 'Manager, support, radiology'
  },
  {
    Id: 903,
    Code: '1171',
    Name: 'Manager, vaccination'
  },
  {
    Id: 904,
    Code: '1171',
    Name: 'Manager, health authority'
  },
  {
    Id: 905,
    Code: '1171',
    Name: 'Manager, hospital service'
  },
  {
    Id: 906,
    Code: '1171',
    Name: 'Manager, nursing services'
  },
  {
    Id: 907,
    Code: '1171',
    Name: 'Superintendent, hospital service'
  },
  {
    Id: 908,
    Code: '1172',
    Name: 'Commissioner, local government: social services'
  },
  {
    Id: 909,
    Code: '1172',
    Name: 'Director of social services'
  },
  {
    Id: 910,
    Code: '1172',
    Name: "Manager, centre, children's"
  },
  {
    Id: 911,
    Code: '1172',
    Name: 'Manager, commissioning, local government: social services'
  },
  {
    Id: 912,
    Code: '1172',
    Name: 'Manager, engagement, community'
  },
  {
    Id: 913,
    Code: '1172',
    Name: 'Manager, foyer, local government: social services'
  },
  {
    Id: 914,
    Code: '1172',
    Name: 'Manager, involvement, community'
  },
  {
    Id: 915,
    Code: '1172',
    Name: 'Manager, locality, local government: social services'
  },
  {
    Id: 916,
    Code: '1172',
    Name: 'Manager, people, supporting, local government'
  },
  {
    Id: 917,
    Code: '1172',
    Name: 'Manager, service, local government: social services'
  },
  {
    Id: 918,
    Code: '1172',
    Name: 'Manager, service, welfare services'
  },
  {
    Id: 919,
    Code: '1172',
    Name: "Manager, services, children's, social, welfare services"
  },
  {
    Id: 920,
    Code: '1172',
    Name: 'Manager, services, community'
  },
  {
    Id: 921,
    Code: '1172',
    Name: 'Manager, services, social'
  },
  {
    Id: 922,
    Code: '1172',
    Name: 'Manager, support, non-residential, local government: social services'
  },
  {
    Id: 923,
    Code: '1172',
    Name: 'Manager, unit, non-residential, local government: social services'
  },
  {
    Id: 924,
    Code: '1172',
    Name: 'Manager, work, social'
  },
  {
    Id: 925,
    Code: '1172',
    Name: 'Manager, local government: social services'
  },
  {
    Id: 926,
    Code: '1172',
    Name: 'Manager, welfare services'
  },
  {
    Id: 927,
    Code: '1211',
    Name: 'Director, farm'
  },
  {
    Id: 928,
    Code: '1211',
    Name: 'Director, horticulture'
  },
  {
    Id: 929,
    Code: '1211',
    Name: 'Director, nursery: horticultural'
  },
  {
    Id: 930,
    Code: '1211',
    Name: 'Maker, wine'
  },
  {
    Id: 931,
    Code: '1211',
    Name: 'Manager, farm'
  },
  {
    Id: 932,
    Code: '1211',
    Name: 'Manager, nursery, horticulture'
  },
  {
    Id: 933,
    Code: '1211',
    Name: 'Manager, operations, horticulture'
  },
  {
    Id: 934,
    Code: '1211',
    Name: 'Manager, production, horticulture'
  },
  {
    Id: 935,
    Code: '1211',
    Name: 'Manager, stock, farm'
  },
  {
    Id: 936,
    Code: '1211',
    Name: 'Manager, stock, live'
  },
  {
    Id: 937,
    Code: '1211',
    Name: 'Manager, stock, farming'
  },
  {
    Id: 938,
    Code: '1211',
    Name: 'Manager, agricultural contracting'
  },
  {
    Id: 939,
    Code: '1211',
    Name: 'Manager, agriculture'
  },
  {
    Id: 940,
    Code: '1211',
    Name: 'Manager, fruit growing'
  },
  {
    Id: 941,
    Code: '1211',
    Name: 'Manager, horticulture'
  },
  {
    Id: 942,
    Code: '1211',
    Name: 'Manager, market gardening'
  },
  {
    Id: 943,
    Code: '1211',
    Name: 'Owner, farm'
  },
  {
    Id: 944,
    Code: '1211',
    Name: 'Owner, farm'
  },
  {
    Id: 945,
    Code: '1211',
    Name: 'Owner, horticultural nursery'
  },
  {
    Id: 946,
    Code: '1211',
    Name: 'Owner, horticulture'
  },
  {
    Id: 947,
    Code: '1211',
    Name: 'Owner, nursery: horticultural'
  },
  {
    Id: 948,
    Code: '1211',
    Name: 'Viticulturist'
  },
  {
    Id: 949,
    Code: '1212',
    Name: 'Director, landscape gardening'
  },
  {
    Id: 950,
    Code: '1212',
    Name: 'Manager, farm, fish'
  },
  {
    Id: 951,
    Code: '1212',
    Name: 'Manager, farm, stud'
  },
  {
    Id: 952,
    Code: '1212',
    Name: 'Manager, forest'
  },
  {
    Id: 953,
    Code: '1212',
    Name: 'Manager, forestry'
  },
  {
    Id: 954,
    Code: '1212',
    Name: 'Manager, grounds'
  },
  {
    Id: 955,
    Code: '1212',
    Name: 'Manager, kennel'
  },
  {
    Id: 956,
    Code: '1212',
    Name: 'Manager, maintenance, grounds'
  },
  {
    Id: 957,
    Code: '1212',
    Name: 'Manager, nursery, forest'
  },
  {
    Id: 958,
    Code: '1212',
    Name: 'Manager, stud'
  },
  {
    Id: 959,
    Code: '1212',
    Name: 'Manager, yard, livery'
  },
  {
    Id: 960,
    Code: '1212',
    Name: 'Manager, yard, livery stable'
  },
  {
    Id: 961,
    Code: '1212',
    Name: 'Manager, clam cultivation'
  },
  {
    Id: 962,
    Code: '1212',
    Name: 'Manager, fish hatchery'
  },
  {
    Id: 963,
    Code: '1212',
    Name: 'Manager, fishing company'
  },
  {
    Id: 964,
    Code: '1212',
    Name: 'Manager, forestry'
  },
  {
    Id: 965,
    Code: '1212',
    Name: 'Manager, gardening, grounds keeping services'
  },
  {
    Id: 966,
    Code: '1212',
    Name: 'Manager, livery stable'
  },
  {
    Id: 967,
    Code: '1212',
    Name: 'Manager, mussel cultivation'
  },
  {
    Id: 968,
    Code: '1212',
    Name: 'Manager, oyster cultivation'
  },
  {
    Id: 969,
    Code: '1212',
    Name: 'Manager, tree felling services and related'
  },
  {
    Id: 970,
    Code: '1212',
    Name: 'Mariner, master, fishing'
  },
  {
    Id: 971,
    Code: '1212',
    Name: 'Master, fishing'
  },
  {
    Id: 972,
    Code: '1212',
    Name: 'Owner, boat, fishing'
  },
  {
    Id: 973,
    Code: '1212',
    Name: 'Owner, cattery'
  },
  {
    Id: 974,
    Code: '1212',
    Name: 'Owner, farm, fish'
  },
  {
    Id: 975,
    Code: '1212',
    Name: 'Owner, hatchery, fish'
  },
  {
    Id: 976,
    Code: '1212',
    Name: 'Owner, kennels'
  },
  {
    Id: 977,
    Code: '1212',
    Name: 'Owner, yard, livery'
  },
  {
    Id: 978,
    Code: '1212',
    Name: 'Owner, agricultural machinery contracting'
  },
  {
    Id: 979,
    Code: '1212',
    Name: 'Owner, boat: fishing'
  },
  {
    Id: 980,
    Code: '1212',
    Name: 'Owner, cattery'
  },
  {
    Id: 981,
    Code: '1212',
    Name: 'Owner, dog walking service'
  },
  {
    Id: 982,
    Code: '1212',
    Name: 'Owner, fish hatchery'
  },
  {
    Id: 983,
    Code: '1212',
    Name: 'Owner, fishing vessel'
  },
  {
    Id: 984,
    Code: '1212',
    Name: 'Owner, kennels'
  },
  {
    Id: 985,
    Code: '1212',
    Name: 'Owner, landscape gardening'
  },
  {
    Id: 986,
    Code: '1212',
    Name: 'Owner, livery stable'
  },
  {
    Id: 987,
    Code: '1212',
    Name: 'Trainer, horse, race'
  },
  {
    Id: 988,
    Code: '1212',
    Name: 'Trainer, racehorse'
  },
  {
    Id: 989,
    Code: '1221',
    Name: 'Bursar, domestic'
  },
  {
    Id: 990,
    Code: '1221',
    Name: 'Director, operations, hotel'
  },
  {
    Id: 991,
    Code: '1221',
    Name: 'Hirer, site, caravan'
  },
  {
    Id: 992,
    Code: '1221',
    Name: 'Hotelier'
  },
  {
    Id: 993,
    Code: '1221',
    Name: 'Keeper, hotel'
  },
  {
    Id: 994,
    Code: '1221',
    Name: 'Manager, accommodation'
  },
  {
    Id: 995,
    Code: '1221',
    Name: 'Manager, accommodations'
  },
  {
    Id: 996,
    Code: '1221',
    Name: 'Manager, boatel'
  },
  {
    Id: 997,
    Code: '1221',
    Name: 'Manager, camp, holiday'
  },
  {
    Id: 998,
    Code: '1221',
    Name: 'Manager, centre, holiday'
  },
  {
    Id: 999,
    Code: '1221',
    Name: 'Manager, club, residential'
  },
  {
    Id: 1000,
    Code: '1221',
    Name: 'Manager, farm, health'
  },
  {
    Id: 1001,
    Code: '1221',
    Name: 'Manager, flats, holiday'
  },
  {
    Id: 1002,
    Code: '1221',
    Name: 'Manager, flats, service'
  },
  {
    Id: 1003,
    Code: '1221',
    Name: 'Manager, hall, higher education, university'
  },
  {
    Id: 1004,
    Code: '1221',
    Name: 'Manager, hall of residence'
  },
  {
    Id: 1005,
    Code: '1221',
    Name: 'Manager, hotel'
  },
  {
    Id: 1006,
    Code: '1221',
    Name: 'Manager, motel'
  },
  {
    Id: 1007,
    Code: '1221',
    Name: 'Manager, park, caravan'
  },
  {
    Id: 1008,
    Code: '1221',
    Name: 'Manager, park, holiday'
  },
  {
    Id: 1009,
    Code: '1221',
    Name: 'Manager, services, hotel'
  },
  {
    Id: 1010,
    Code: '1221',
    Name: 'Manager, site, camping'
  },
  {
    Id: 1011,
    Code: '1221',
    Name: 'Manager, site, caravan'
  },
  {
    Id: 1012,
    Code: '1221',
    Name: 'Manager, site, camping/caravan site'
  },
  {
    Id: 1013,
    Code: '1221',
    Name: 'Manager, camping/caravan site'
  },
  {
    Id: 1014,
    Code: '1221',
    Name: 'Manager, holiday camp'
  },
  {
    Id: 1015,
    Code: '1221',
    Name: 'Manager, holiday flats'
  },
  {
    Id: 1016,
    Code: '1221',
    Name: 'Manager, hostel'
  },
  {
    Id: 1017,
    Code: '1221',
    Name: 'Manager, hotel'
  },
  {
    Id: 1018,
    Code: '1221',
    Name: 'Manager, passenger ships'
  },
  {
    Id: 1019,
    Code: '1221',
    Name: 'Manager, residential club'
  },
  {
    Id: 1020,
    Code: '1221',
    Name: 'Operator, site, caravan'
  },
  {
    Id: 1021,
    Code: '1221',
    Name: 'Owner, flat, holiday'
  },
  {
    Id: 1022,
    Code: '1221',
    Name: 'Owner, flats, holiday'
  },
  {
    Id: 1023,
    Code: '1221',
    Name: 'Owner, hotel'
  },
  {
    Id: 1024,
    Code: '1221',
    Name: 'Owner, let, holiday'
  },
  {
    Id: 1025,
    Code: '1221',
    Name: 'Owner, park, caravan'
  },
  {
    Id: 1026,
    Code: '1221',
    Name: 'Owner, site, camping'
  },
  {
    Id: 1027,
    Code: '1221',
    Name: 'Owner, site, caravan'
  },
  {
    Id: 1028,
    Code: '1221',
    Name: 'Owner, camping/caravan site'
  },
  {
    Id: 1029,
    Code: '1221',
    Name: 'Owner, holiday camp'
  },
  {
    Id: 1030,
    Code: '1221',
    Name: 'Owner, holiday flats'
  },
  {
    Id: 1031,
    Code: '1221',
    Name: 'Owner, hotel'
  },
  {
    Id: 1032,
    Code: '1221',
    Name: 'Steward, college'
  },
  {
    Id: 1033,
    Code: '1221',
    Name: 'Steward, hostel'
  },
  {
    Id: 1034,
    Code: '1222',
    Name: 'Dealer, fish and chip'
  },
  {
    Id: 1035,
    Code: '1222',
    Name: 'Director, catering'
  },
  {
    Id: 1036,
    Code: '1222',
    Name: 'Manager, bar, sandwich'
  },
  {
    Id: 1037,
    Code: '1222',
    Name: 'Manager, bar, snack'
  },
  {
    Id: 1038,
    Code: '1222',
    Name: 'Manager, buffet'
  },
  {
    Id: 1039,
    Code: '1222',
    Name: 'Manager, café'
  },
  {
    Id: 1040,
    Code: '1222',
    Name: 'Manager, club, refreshment'
  },
  {
    Id: 1041,
    Code: '1222',
    Name: 'Manager, club, catering'
  },
  {
    Id: 1042,
    Code: '1222',
    Name: 'Manager, district, catering'
  },
  {
    Id: 1043,
    Code: '1222',
    Name: 'Manager, mess'
  },
  {
    Id: 1044,
    Code: '1222',
    Name: 'Manager, operations, catering'
  },
  {
    Id: 1045,
    Code: '1222',
    Name: 'Manager, restaurant, fast food outlet'
  },
  {
    Id: 1046,
    Code: '1222',
    Name: 'Manager, restaurant'
  },
  {
    Id: 1047,
    Code: '1222',
    Name: 'Manager, retail, catering'
  },
  {
    Id: 1048,
    Code: '1222',
    Name: 'Manager, service, food'
  },
  {
    Id: 1049,
    Code: '1222',
    Name: 'Manager, services, catering'
  },
  {
    Id: 1050,
    Code: '1222',
    Name: 'Manager, services, food'
  },
  {
    Id: 1051,
    Code: '1222',
    Name: 'Manager, shop, coffee'
  },
  {
    Id: 1052,
    Code: '1222',
    Name: 'Manager, shop, fish and chip'
  },
  {
    Id: 1053,
    Code: '1222',
    Name: 'Manager, shop, take-away food shop'
  },
  {
    Id: 1054,
    Code: '1222',
    Name: 'Manager, store, café'
  },
  {
    Id: 1055,
    Code: '1222',
    Name: 'Manager, store, coffee shop'
  },
  {
    Id: 1056,
    Code: '1222',
    Name: 'Manager, store, restaurant'
  },
  {
    Id: 1057,
    Code: '1222',
    Name: 'Manager, store, sandwich shop'
  },
  {
    Id: 1058,
    Code: '1222',
    Name: 'Manager, store, take-away food shop'
  },
  {
    Id: 1059,
    Code: '1222',
    Name: 'Manager, catering operations'
  },
  {
    Id: 1060,
    Code: '1222',
    Name: 'Manager, café'
  },
  {
    Id: 1061,
    Code: '1222',
    Name: 'Manager, fast food outlet'
  },
  {
    Id: 1062,
    Code: '1222',
    Name: 'Manager, fish and chip shop'
  },
  {
    Id: 1063,
    Code: '1222',
    Name: 'Manager, NAAFI: canteen'
  },
  {
    Id: 1064,
    Code: '1222',
    Name: 'Manager, restaurant'
  },
  {
    Id: 1065,
    Code: '1222',
    Name: 'Manager, tea room'
  },
  {
    Id: 1066,
    Code: '1222',
    Name: 'Owner, café'
  },
  {
    Id: 1067,
    Code: '1222',
    Name: 'Owner, restaurant'
  },
  {
    Id: 1068,
    Code: '1222',
    Name: 'Owner, room, tea'
  },
  {
    Id: 1069,
    Code: '1222',
    Name: 'Owner, shop, chip'
  },
  {
    Id: 1070,
    Code: '1222',
    Name: 'Owner, shop, coffee'
  },
  {
    Id: 1071,
    Code: '1222',
    Name: 'Owner, takeaway'
  },
  {
    Id: 1072,
    Code: '1222',
    Name: 'Owner, café'
  },
  {
    Id: 1073,
    Code: '1222',
    Name: 'Owner, fast food outlet'
  },
  {
    Id: 1074,
    Code: '1222',
    Name: 'Owner, fish and chip shop'
  },
  {
    Id: 1075,
    Code: '1222',
    Name: 'Owner, restaurant'
  },
  {
    Id: 1076,
    Code: '1222',
    Name: 'Owner, sandwich bar'
  },
  {
    Id: 1077,
    Code: '1222',
    Name: 'Owner, take-away food shop'
  },
  {
    Id: 1078,
    Code: '1222',
    Name: 'Restaurateur'
  },
  {
    Id: 1079,
    Code: '1222',
    Name: 'Retailer, take-away food shop'
  },
  {
    Id: 1080,
    Code: '1223',
    Name: 'Innkeeper'
  },
  {
    Id: 1081,
    Code: '1223',
    Name: 'Keeper, inn'
  },
  {
    Id: 1082,
    Code: '1223',
    Name: 'Landlady, house, public'
  },
  {
    Id: 1083,
    Code: '1223',
    Name: 'Landlady, pub'
  },
  {
    Id: 1084,
    Code: '1223',
    Name: 'Landlady, public house'
  },
  {
    Id: 1085,
    Code: '1223',
    Name: 'Landlord, house, public'
  },
  {
    Id: 1086,
    Code: '1223',
    Name: 'Landlord, public house'
  },
  {
    Id: 1087,
    Code: '1223',
    Name: 'Licensee, house, public'
  },
  {
    Id: 1088,
    Code: '1223',
    Name: 'Licensee, pub'
  },
  {
    Id: 1089,
    Code: '1223',
    Name: 'Licensee'
  },
  {
    Id: 1090,
    Code: '1223',
    Name: 'Manager, area, brewery'
  },
  {
    Id: 1091,
    Code: '1223',
    Name: 'Manager, bar, wine'
  },
  {
    Id: 1092,
    Code: '1223',
    Name: 'Manager, club, social'
  },
  {
    Id: 1093,
    Code: '1223',
    Name: 'Manager, house, licensed'
  },
  {
    Id: 1094,
    Code: '1223',
    Name: 'Manager, house, public'
  },
  {
    Id: 1095,
    Code: '1223',
    Name: 'Manager, licensee'
  },
  {
    Id: 1096,
    Code: '1223',
    Name: 'Manager, pub'
  },
  {
    Id: 1097,
    Code: '1223',
    Name: 'Manager, retail, public house'
  },
  {
    Id: 1098,
    Code: '1223',
    Name: 'Manager, shift, public house'
  },
  {
    Id: 1099,
    Code: '1223',
    Name: 'Manager, public house'
  },
  {
    Id: 1100,
    Code: '1223',
    Name: 'Manager, social club'
  },
  {
    Id: 1101,
    Code: '1223',
    Name: 'Manager, wine bar'
  },
  {
    Id: 1102,
    Code: '1223',
    Name: 'Owner, bar, wine'
  },
  {
    Id: 1103,
    Code: '1223',
    Name: 'Owner, bar'
  },
  {
    Id: 1104,
    Code: '1223',
    Name: 'Owner, club'
  },
  {
    Id: 1105,
    Code: '1223',
    Name: 'Owner, house, public'
  },
  {
    Id: 1106,
    Code: '1223',
    Name: 'Owner, club'
  },
  {
    Id: 1107,
    Code: '1223',
    Name: 'Owner, public house'
  },
  {
    Id: 1108,
    Code: '1223',
    Name: 'Owner, wine bar'
  },
  {
    Id: 1109,
    Code: '1223',
    Name: 'Publican'
  },
  {
    Id: 1110,
    Code: '1223',
    Name: 'Publican-licensee'
  },
  {
    Id: 1111,
    Code: '1223',
    Name: 'Tenant, house, public'
  },
  {
    Id: 1112,
    Code: '1223',
    Name: 'Victualler, licensed'
  },
  {
    Id: 1113,
    Code: '1224',
    Name: 'Clerk and steward'
  },
  {
    Id: 1114,
    Code: '1224',
    Name: 'Clerk of the course'
  },
  {
    Id: 1115,
    Code: '1224',
    Name: 'Director, cruise'
  },
  {
    Id: 1116,
    Code: '1224',
    Name: 'Director, museum'
  },
  {
    Id: 1117,
    Code: '1224',
    Name: 'Director, operational, bound, outward'
  },
  {
    Id: 1118,
    Code: '1224',
    Name: 'Director, zoo'
  },
  {
    Id: 1119,
    Code: '1224',
    Name: 'Director, leisure services'
  },
  {
    Id: 1120,
    Code: '1224',
    Name: 'Manager, alley, bowling'
  },
  {
    Id: 1121,
    Code: '1224',
    Name: 'Manager, arcade, amusement'
  },
  {
    Id: 1122,
    Code: '1224',
    Name: 'Manager, baths, sauna'
  },
  {
    Id: 1123,
    Code: '1224',
    Name: 'Manager, baths'
  },
  {
    Id: 1124,
    Code: '1224',
    Name: 'Manager, bound, outward'
  },
  {
    Id: 1125,
    Code: '1224',
    Name: 'Manager, centre, arts'
  },
  {
    Id: 1126,
    Code: '1224',
    Name: 'Manager, centre, bound, outward'
  },
  {
    Id: 1127,
    Code: '1224',
    Name: 'Manager, centre, equestrian'
  },
  {
    Id: 1128,
    Code: '1224',
    Name: 'Manager, centre, fitness'
  },
  {
    Id: 1129,
    Code: '1224',
    Name: 'Manager, centre, leisure'
  },
  {
    Id: 1130,
    Code: '1224',
    Name: 'Manager, centre, sports'
  },
  {
    Id: 1131,
    Code: '1224',
    Name: 'Manager, cinema'
  },
  {
    Id: 1132,
    Code: '1224',
    Name: 'Manager, circuit, entertainment'
  },
  {
    Id: 1133,
    Code: '1224',
    Name: 'Manager, circus'
  },
  {
    Id: 1134,
    Code: '1224',
    Name: 'Manager, club, health'
  },
  {
    Id: 1135,
    Code: '1224',
    Name: 'Manager, club, football club'
  },
  {
    Id: 1136,
    Code: '1224',
    Name: 'Manager, club'
  },
  {
    Id: 1137,
    Code: '1224',
    Name: 'Manager, course, golf'
  },
  {
    Id: 1138,
    Code: '1224',
    Name: 'Manager, entertainment'
  },
  {
    Id: 1139,
    Code: '1224',
    Name: 'Manager, experience, cinema'
  },
  {
    Id: 1140,
    Code: '1224',
    Name: 'Manager, fairground'
  },
  {
    Id: 1141,
    Code: '1224',
    Name: 'Manager, fitness'
  },
  {
    Id: 1142,
    Code: '1224',
    Name: 'Manager, front of house, entertainment'
  },
  {
    Id: 1143,
    Code: '1224',
    Name: 'Manager, ground, cricket'
  },
  {
    Id: 1144,
    Code: '1224',
    Name: 'Manager, ground, football'
  },
  {
    Id: 1145,
    Code: '1224',
    Name: 'Manager, gym'
  },
  {
    Id: 1146,
    Code: '1224',
    Name: 'Manager, hall, concert'
  },
  {
    Id: 1147,
    Code: '1224',
    Name: 'Manager, hall, dance'
  },
  {
    Id: 1148,
    Code: '1224',
    Name: 'Manager, health and fitness'
  },
  {
    Id: 1149,
    Code: '1224',
    Name: 'Manager, house, entertainment'
  },
  {
    Id: 1150,
    Code: '1224',
    Name: 'Manager, leisure'
  },
  {
    Id: 1151,
    Code: '1224',
    Name: 'Manager, marina'
  },
  {
    Id: 1152,
    Code: '1224',
    Name: 'Manager, museum'
  },
  {
    Id: 1153,
    Code: '1224',
    Name: 'Manager, operations, leisure services'
  },
  {
    Id: 1154,
    Code: '1224',
    Name: 'Manager, park, theme'
  },
  {
    Id: 1155,
    Code: '1224',
    Name: 'Manager, park'
  },
  {
    Id: 1156,
    Code: '1224',
    Name: 'Manager, promotions, sports'
  },
  {
    Id: 1157,
    Code: '1224',
    Name: 'Manager, pursuits, outdoor'
  },
  {
    Id: 1158,
    Code: '1224',
    Name: 'Manager, racecourse'
  },
  {
    Id: 1159,
    Code: '1224',
    Name: 'Manager, rink, skating'
  },
  {
    Id: 1160,
    Code: '1224',
    Name: 'Manager, room, ball'
  },
  {
    Id: 1161,
    Code: '1224',
    Name: 'Manager, school, riding school'
  },
  {
    Id: 1162,
    Code: '1224',
    Name: 'Manager, service, leisure services'
  },
  {
    Id: 1163,
    Code: '1224',
    Name: 'Manager, services, leisure'
  },
  {
    Id: 1164,
    Code: '1224',
    Name: 'Manager, services, recreation'
  },
  {
    Id: 1165,
    Code: '1224',
    Name: 'Manager, sports, leisure centre'
  },
  {
    Id: 1166,
    Code: '1224',
    Name: 'Manager, stadium'
  },
  {
    Id: 1167,
    Code: '1224',
    Name: 'Manager, stand, entertainment'
  },
  {
    Id: 1168,
    Code: '1224',
    Name: 'Manager, theatre'
  },
  {
    Id: 1169,
    Code: '1224',
    Name: 'Manager, leisure services'
  },
  {
    Id: 1170,
    Code: '1224',
    Name: 'Manager, amusement arcade'
  },
  {
    Id: 1171,
    Code: '1224',
    Name: 'Manager, cinema'
  },
  {
    Id: 1172,
    Code: '1224',
    Name: 'Manager, football club'
  },
  {
    Id: 1173,
    Code: '1224',
    Name: 'Manager, golf club'
  },
  {
    Id: 1174,
    Code: '1224',
    Name: 'Manager, health and fitness studio'
  },
  {
    Id: 1175,
    Code: '1224',
    Name: 'Manager, health studio'
  },
  {
    Id: 1176,
    Code: '1224',
    Name: 'Manager, leisure centre'
  },
  {
    Id: 1177,
    Code: '1224',
    Name: 'Manager, night club'
  },
  {
    Id: 1178,
    Code: '1224',
    Name: 'Manager, public baths'
  },
  {
    Id: 1179,
    Code: '1224',
    Name: 'Manager, riding school'
  },
  {
    Id: 1180,
    Code: '1224',
    Name: 'Manager, snooker, billiards hall'
  },
  {
    Id: 1181,
    Code: '1224',
    Name: 'Manager, sports activities'
  },
  {
    Id: 1182,
    Code: '1224',
    Name: 'Manager, theatre'
  },
  {
    Id: 1183,
    Code: '1224',
    Name: 'Manager, zoological gardens'
  },
  {
    Id: 1184,
    Code: '1224',
    Name: 'Master, club'
  },
  {
    Id: 1185,
    Code: '1224',
    Name: 'Owner, arcade, amusement'
  },
  {
    Id: 1186,
    Code: '1224',
    Name: 'Owner, cinema'
  },
  {
    Id: 1187,
    Code: '1224',
    Name: 'Owner, circus'
  },
  {
    Id: 1188,
    Code: '1224',
    Name: 'Owner, club, health'
  },
  {
    Id: 1189,
    Code: '1224',
    Name: 'Owner, club, night'
  },
  {
    Id: 1190,
    Code: '1224',
    Name: 'Owner, club, sports'
  },
  {
    Id: 1191,
    Code: '1224',
    Name: 'Owner, gym'
  },
  {
    Id: 1192,
    Code: '1224',
    Name: 'Owner, hall, dance'
  },
  {
    Id: 1193,
    Code: '1224',
    Name: 'Owner, museum'
  },
  {
    Id: 1194,
    Code: '1224',
    Name: 'Owner, school, riding'
  },
  {
    Id: 1195,
    Code: '1224',
    Name: 'Owner, studio, health'
  },
  {
    Id: 1196,
    Code: '1224',
    Name: 'Owner, amusement arcade'
  },
  {
    Id: 1197,
    Code: '1224',
    Name: 'Owner, boat: pleasure cruise'
  },
  {
    Id: 1198,
    Code: '1224',
    Name: 'Owner, cinema'
  },
  {
    Id: 1199,
    Code: '1224',
    Name: 'Owner, circus'
  },
  {
    Id: 1200,
    Code: '1224',
    Name: 'Owner, club: night'
  },
  {
    Id: 1201,
    Code: '1224',
    Name: 'Owner, club: sports'
  },
  {
    Id: 1202,
    Code: '1224',
    Name: 'Owner, dance hall'
  },
  {
    Id: 1203,
    Code: '1224',
    Name: 'Owner, fairground stall'
  },
  {
    Id: 1204,
    Code: '1224',
    Name: 'Owner, health and fitness studio'
  },
  {
    Id: 1205,
    Code: '1224',
    Name: 'Owner, museum'
  },
  {
    Id: 1206,
    Code: '1224',
    Name: 'Owner, riding stable'
  },
  {
    Id: 1207,
    Code: '1224',
    Name: 'Owner, school: riding'
  },
  {
    Id: 1208,
    Code: '1224',
    Name: 'Owner, skating rink'
  },
  {
    Id: 1209,
    Code: '1224',
    Name: 'Owner, skittle alley'
  },
  {
    Id: 1210,
    Code: '1224',
    Name: 'Owner, snooker, billiards hall'
  },
  {
    Id: 1211,
    Code: '1225',
    Name: 'Manager, agency, travel'
  },
  {
    Id: 1212,
    Code: '1225',
    Name: 'Manager, agent, travel'
  },
  {
    Id: 1213,
    Code: '1225',
    Name: 'Manager, branch, travel agents'
  },
  {
    Id: 1214,
    Code: '1225',
    Name: 'Manager, information, tourist'
  },
  {
    Id: 1215,
    Code: '1225',
    Name: 'Manager, operations, travel agents'
  },
  {
    Id: 1216,
    Code: '1225',
    Name: 'Manager, retail, travel agents'
  },
  {
    Id: 1217,
    Code: '1225',
    Name: 'Manager, shop, travel agents'
  },
  {
    Id: 1218,
    Code: '1225',
    Name: 'Manager, store, travel agents'
  },
  {
    Id: 1219,
    Code: '1225',
    Name: 'Manager, tourist'
  },
  {
    Id: 1220,
    Code: '1225',
    Name: 'Manager, tours'
  },
  {
    Id: 1221,
    Code: '1225',
    Name: 'Manager, travel'
  },
  {
    Id: 1222,
    Code: '1225',
    Name: 'Manager, travel agents'
  },
  {
    Id: 1223,
    Code: '1225',
    Name: 'Owner, agency, travel'
  },
  {
    Id: 1224,
    Code: '1225',
    Name: 'Owner, travel agents'
  },
  {
    Id: 1225,
    Code: '1231',
    Name: 'Manager, clinic'
  },
  {
    Id: 1226,
    Code: '1231',
    Name: 'Manager, fundholding, medical practice'
  },
  {
    Id: 1227,
    Code: '1231',
    Name: 'Manager, holding, fund, medical practice'
  },
  {
    Id: 1228,
    Code: '1231',
    Name: 'Manager, practice, dental'
  },
  {
    Id: 1229,
    Code: '1231',
    Name: 'Manager, practice, GP'
  },
  {
    Id: 1230,
    Code: '1231',
    Name: 'Manager, practice, optical'
  },
  {
    Id: 1231,
    Code: '1231',
    Name: 'Manager, practice, opticians'
  },
  {
    Id: 1232,
    Code: '1231',
    Name: 'Manager, practice, veterinary'
  },
  {
    Id: 1233,
    Code: '1231',
    Name: 'Manager, practice, dental practice'
  },
  {
    Id: 1234,
    Code: '1231',
    Name: 'Manager, practice, health services'
  },
  {
    Id: 1235,
    Code: '1231',
    Name: 'Manager, practice, medical practice'
  },
  {
    Id: 1236,
    Code: '1231',
    Name: 'Manager, practice, opticians'
  },
  {
    Id: 1237,
    Code: '1231',
    Name: 'Manager, practice, veterinary surgery'
  },
  {
    Id: 1238,
    Code: '1231',
    Name: 'Manager, services, patient'
  },
  {
    Id: 1239,
    Code: '1231',
    Name: 'Manager, medical practice'
  },
  {
    Id: 1240,
    Code: '1232',
    Name: 'Head of care, care/residential home'
  },
  {
    Id: 1241,
    Code: '1232',
    Name: 'Head of care'
  },
  {
    Id: 1242,
    Code: '1232',
    Name: 'Manager, care, community'
  },
  {
    Id: 1243,
    Code: '1232',
    Name: 'Manager, care, domiciliary'
  },
  {
    Id: 1244,
    Code: '1232',
    Name: 'Manager, care, home'
  },
  {
    Id: 1245,
    Code: '1232',
    Name: 'Manager, care, residential'
  },
  {
    Id: 1246,
    Code: '1232',
    Name: 'Manager, care, care/residential home'
  },
  {
    Id: 1247,
    Code: '1232',
    Name: 'Manager, care, local government: social services'
  },
  {
    Id: 1248,
    Code: '1232',
    Name: 'Manager, care'
  },
  {
    Id: 1249,
    Code: '1232',
    Name: 'Manager, centre, community'
  },
  {
    Id: 1250,
    Code: '1232',
    Name: 'Manager, centre, day'
  },
  {
    Id: 1251,
    Code: '1232',
    Name: 'Manager, home, care'
  },
  {
    Id: 1252,
    Code: '1232',
    Name: 'Manager, home, nursing'
  },
  {
    Id: 1253,
    Code: '1232',
    Name: 'Manager, home, registered'
  },
  {
    Id: 1254,
    Code: '1232',
    Name: 'Manager, home, welfare services'
  },
  {
    Id: 1255,
    Code: '1232',
    Name: 'Manager, house, care/residential home'
  },
  {
    Id: 1256,
    Code: '1232',
    Name: 'Manager, house, local government: social services'
  },
  {
    Id: 1257,
    Code: '1232',
    Name: 'Manager, housing, sheltered'
  },
  {
    Id: 1258,
    Code: '1232',
    Name: 'Manager, location, sheltered housing'
  },
  {
    Id: 1259,
    Code: '1232',
    Name: 'Manager, registered, home care'
  },
  {
    Id: 1260,
    Code: '1232',
    Name: 'Manager, registered, care/residential home'
  },
  {
    Id: 1261,
    Code: '1232',
    Name: 'Manager, residential, care/residential home'
  },
  {
    Id: 1262,
    Code: '1232',
    Name: 'Manager, service, care'
  },
  {
    Id: 1263,
    Code: '1232',
    Name: 'Manager, service, day'
  },
  {
    Id: 1264,
    Code: '1232',
    Name: 'Manager, service, home care'
  },
  {
    Id: 1265,
    Code: '1232',
    Name: 'Manager, service, care/residential home'
  },
  {
    Id: 1266,
    Code: '1232',
    Name: 'Manager, support, residential, local government: social services'
  },
  {
    Id: 1267,
    Code: '1232',
    Name: 'Manager, unit, rehabilitation'
  },
  {
    Id: 1268,
    Code: '1232',
    Name: 'Manager, unit, residential, local government: social services'
  },
  {
    Id: 1269,
    Code: '1232',
    Name: 'Manager, home care'
  },
  {
    Id: 1270,
    Code: '1232',
    Name: 'Manager, care/residential home'
  },
  {
    Id: 1271,
    Code: '1232',
    Name: "Manager, children's home"
  },
  {
    Id: 1272,
    Code: '1232',
    Name: 'Manager, nursing home'
  },
  {
    Id: 1273,
    Code: '1232',
    Name: 'Manager, sheltered housing'
  },
  {
    Id: 1274,
    Code: '1232',
    Name: 'Manager of residential home'
  },
  {
    Id: 1275,
    Code: '1232',
    Name: 'Officer, principal, care/residential home'
  },
  {
    Id: 1276,
    Code: '1232',
    Name: "Officer, principal, children's home"
  },
  {
    Id: 1277,
    Code: '1232',
    Name: 'Officer-in-charge, local government: social services'
  },
  {
    Id: 1278,
    Code: '1232',
    Name: 'Owner, home, care'
  },
  {
    Id: 1279,
    Code: '1232',
    Name: 'Owner, home, convalescent'
  },
  {
    Id: 1280,
    Code: '1232',
    Name: 'Owner, home, nursing'
  },
  {
    Id: 1281,
    Code: '1232',
    Name: 'Owner, home, residential'
  },
  {
    Id: 1282,
    Code: '1232',
    Name: 'Owner, home, rest'
  },
  {
    Id: 1283,
    Code: '1232',
    Name: 'Owner, home, retirement'
  },
  {
    Id: 1284,
    Code: '1232',
    Name: 'Owner, care/residential home'
  },
  {
    Id: 1285,
    Code: '1232',
    Name: 'Owner, convalescent home'
  },
  {
    Id: 1286,
    Code: '1232',
    Name: 'Owner, nursing home'
  },
  {
    Id: 1287,
    Code: '1232',
    Name: 'Owner, rest home'
  },
  {
    Id: 1288,
    Code: '1232',
    Name: 'Owner, retirement home'
  },
  {
    Id: 1289,
    Code: '1233',
    Name: "Director, nursery, children's"
  },
  {
    Id: 1290,
    Code: '1233',
    Name: "Director, children's nursery"
  },
  {
    Id: 1291,
    Code: '1233',
    Name: "Owner, nursery, children's"
  },
  {
    Id: 1292,
    Code: '1233',
    Name: "Owner, children's nursery"
  },
  {
    Id: 1293,
    Code: '1233',
    Name: "Owner, nursery: children's"
  },
  {
    Id: 1294,
    Code: '1233',
    Name: 'Owner, school: nursery'
  },
  {
    Id: 1295,
    Code: '1241',
    Name: 'Captain, port'
  },
  {
    Id: 1296,
    Code: '1241',
    Name: 'Director, taxi service'
  },
  {
    Id: 1297,
    Code: '1241',
    Name: "Husband, ship's"
  },
  {
    Id: 1298,
    Code: '1241',
    Name: 'Manager, airport'
  },
  {
    Id: 1299,
    Code: '1241',
    Name: 'Manager, area, work, postal distribution services'
  },
  {
    Id: 1300,
    Code: '1241',
    Name: 'Manager, area, transport'
  },
  {
    Id: 1301,
    Code: '1241',
    Name: 'Manager, berthing'
  },
  {
    Id: 1302,
    Code: '1241',
    Name: 'Manager, branch, furniture removals'
  },
  {
    Id: 1303,
    Code: '1241',
    Name: 'Manager, branch, transport'
  },
  {
    Id: 1304,
    Code: '1241',
    Name: 'Manager, capacity, cargo'
  },
  {
    Id: 1305,
    Code: '1241',
    Name: 'Manager, centre, mail'
  },
  {
    Id: 1306,
    Code: '1241',
    Name: 'Manager, chartering'
  },
  {
    Id: 1307,
    Code: '1241',
    Name: 'Manager, collections, postal distribution services'
  },
  {
    Id: 1308,
    Code: '1241',
    Name: 'Manager, crew, transport'
  },
  {
    Id: 1309,
    Code: '1241',
    Name: 'Manager, delivery, home'
  },
  {
    Id: 1310,
    Code: '1241',
    Name: 'Manager, delivery, operations, railways'
  },
  {
    Id: 1311,
    Code: '1241',
    Name: 'Manager, delivery, parcel'
  },
  {
    Id: 1312,
    Code: '1241',
    Name: 'Manager, delivery, postal distribution services'
  },
  {
    Id: 1313,
    Code: '1241',
    Name: 'Manager, depot'
  },
  {
    Id: 1314,
    Code: '1241',
    Name: 'Manager, despatch'
  },
  {
    Id: 1315,
    Code: '1241',
    Name: 'Manager, dispatch'
  },
  {
    Id: 1316,
    Code: '1241',
    Name: 'Manager, distribution'
  },
  {
    Id: 1317,
    Code: '1241',
    Name: 'Manager, district, transport'
  },
  {
    Id: 1318,
    Code: '1241',
    Name: 'Manager, divisional, petroleum distribution'
  },
  {
    Id: 1319,
    Code: '1241',
    Name: 'Manager, divisional, transport'
  },
  {
    Id: 1320,
    Code: '1241',
    Name: 'Manager, docks'
  },
  {
    Id: 1321,
    Code: '1241',
    Name: 'Manager, fleet, transport'
  },
  {
    Id: 1322,
    Code: '1241',
    Name: 'Manager, fleet'
  },
  {
    Id: 1323,
    Code: '1241',
    Name: 'Manager, flight'
  },
  {
    Id: 1324,
    Code: '1241',
    Name: 'Manager, freight'
  },
  {
    Id: 1325,
    Code: '1241',
    Name: 'Manager, garage, bus'
  },
  {
    Id: 1326,
    Code: '1241',
    Name: 'Manager, Mail, Royal'
  },
  {
    Id: 1327,
    Code: '1241',
    Name: 'Manager, mail, postal distribution services'
  },
  {
    Id: 1328,
    Code: '1241',
    Name: 'Manager, management, traffic, highways'
  },
  {
    Id: 1329,
    Code: '1241',
    Name: 'Manager, network and traffic, highways'
  },
  {
    Id: 1330,
    Code: '1241',
    Name: 'Manager, office, delivery, postal distribution services'
  },
  {
    Id: 1331,
    Code: '1241',
    Name: 'Manager, office, delivery'
  },
  {
    Id: 1332,
    Code: '1241',
    Name: 'Manager, office, sorting'
  },
  {
    Id: 1333,
    Code: '1241',
    Name: 'Manager, operations, flight'
  },
  {
    Id: 1334,
    Code: '1241',
    Name: 'Manager, operations, Mail, Royal'
  },
  {
    Id: 1335,
    Code: '1241',
    Name: 'Manager, operations, port'
  },
  {
    Id: 1336,
    Code: '1241',
    Name: 'Manager, operations, transport'
  },
  {
    Id: 1337,
    Code: '1241',
    Name: 'Manager, operations, distribution company'
  },
  {
    Id: 1338,
    Code: '1241',
    Name: 'Manager, operations, courier service'
  },
  {
    Id: 1339,
    Code: '1241',
    Name: 'Manager, operations, haulage contractor'
  },
  {
    Id: 1340,
    Code: '1241',
    Name: 'Manager, operations, transport'
  },
  {
    Id: 1341,
    Code: '1241',
    Name: 'Manager, pilot'
  },
  {
    Id: 1342,
    Code: '1241',
    Name: 'Manager, port'
  },
  {
    Id: 1343,
    Code: '1241',
    Name: 'Manager, process, postal distribution services'
  },
  {
    Id: 1344,
    Code: '1241',
    Name: 'Manager, processing, postal distribution services'
  },
  {
    Id: 1345,
    Code: '1241',
    Name: 'Manager, production, distribution company'
  },
  {
    Id: 1346,
    Code: '1241',
    Name: 'Manager, production, transport'
  },
  {
    Id: 1347,
    Code: '1241',
    Name: 'Manager, removals'
  },
  {
    Id: 1348,
    Code: '1241',
    Name: 'Manager, service, distribution'
  },
  {
    Id: 1349,
    Code: '1241',
    Name: 'Manager, service, transport'
  },
  {
    Id: 1350,
    Code: '1241',
    Name: 'Manager, shift, distribution company'
  },
  {
    Id: 1351,
    Code: '1241',
    Name: 'Manager, shipping'
  },
  {
    Id: 1352,
    Code: '1241',
    Name: 'Manager, site, transport'
  },
  {
    Id: 1353,
    Code: '1241',
    Name: 'Manager, station, bus'
  },
  {
    Id: 1354,
    Code: '1241',
    Name: 'Manager, station, coach'
  },
  {
    Id: 1355,
    Code: '1241',
    Name: 'Manager, station, railways'
  },
  {
    Id: 1356,
    Code: '1241',
    Name: 'Manager, station, airlines'
  },
  {
    Id: 1357,
    Code: '1241',
    Name: 'Manager, terminal, oil distribution'
  },
  {
    Id: 1358,
    Code: '1241',
    Name: 'Manager, traffic'
  },
  {
    Id: 1359,
    Code: '1241',
    Name: 'Manager, traffic and network, highways'
  },
  {
    Id: 1360,
    Code: '1241',
    Name: 'Manager, transport, road'
  },
  {
    Id: 1361,
    Code: '1241',
    Name: 'Manager, transport, fleet'
  },
  {
    Id: 1362,
    Code: '1241',
    Name: 'Manager, transport'
  },
  {
    Id: 1363,
    Code: '1241',
    Name: 'Manager, distribution company'
  },
  {
    Id: 1364,
    Code: '1241',
    Name: 'Manager, courier service'
  },
  {
    Id: 1365,
    Code: '1241',
    Name: 'Manager, freight forwarding'
  },
  {
    Id: 1366,
    Code: '1241',
    Name: 'Manager, haulage contractor'
  },
  {
    Id: 1367,
    Code: '1241',
    Name: 'Manager, minicab service'
  },
  {
    Id: 1368,
    Code: '1241',
    Name: 'Manager, postal distribution services'
  },
  {
    Id: 1369,
    Code: '1241',
    Name: 'Manager, removals company'
  },
  {
    Id: 1370,
    Code: '1241',
    Name: 'Manager, shipping and freight forwarding agency'
  },
  {
    Id: 1371,
    Code: '1241',
    Name: 'Manager, taxi service'
  },
  {
    Id: 1372,
    Code: '1241',
    Name: 'Manager, transport'
  },
  {
    Id: 1373,
    Code: '1241',
    Name: 'Master, berthing, port authority'
  },
  {
    Id: 1374,
    Code: '1241',
    Name: 'Master, dock'
  },
  {
    Id: 1375,
    Code: '1241',
    Name: 'Master, harbour'
  },
  {
    Id: 1376,
    Code: '1241',
    Name: 'Master, pier'
  },
  {
    Id: 1377,
    Code: '1241',
    Name: 'Master, port'
  },
  {
    Id: 1378,
    Code: '1241',
    Name: 'Master, quay'
  },
  {
    Id: 1379,
    Code: '1241',
    Name: 'Master, station'
  },
  {
    Id: 1380,
    Code: '1241',
    Name: 'Owner, company, taxi'
  },
  {
    Id: 1381,
    Code: '1241',
    Name: 'Owner, service, taxi'
  },
  {
    Id: 1382,
    Code: '1241',
    Name: 'Owner, ship'
  },
  {
    Id: 1383,
    Code: '1241',
    Name: 'Owner, bus service'
  },
  {
    Id: 1384,
    Code: '1241',
    Name: 'Owner, coach service'
  },
  {
    Id: 1385,
    Code: '1241',
    Name: 'Owner, removals company'
  },
  {
    Id: 1386,
    Code: '1241',
    Name: 'Owner, taxi service'
  },
  {
    Id: 1387,
    Code: '1241',
    Name: 'Stationmaster'
  },
  {
    Id: 1388,
    Code: '1242',
    Name: 'Manager, accounting, stock'
  },
  {
    Id: 1389,
    Code: '1242',
    Name: 'Manager, bond, warehousing'
  },
  {
    Id: 1390,
    Code: '1242',
    Name: 'Manager, cargo'
  },
  {
    Id: 1391,
    Code: '1242',
    Name: 'Manager, cellar, brewery'
  },
  {
    Id: 1392,
    Code: '1242',
    Name: 'Manager, cellar, wine merchants'
  },
  {
    Id: 1393,
    Code: '1242',
    Name: 'Manager, consignment'
  },
  {
    Id: 1394,
    Code: '1242',
    Name: 'Manager, contracts, warehousing'
  },
  {
    Id: 1395,
    Code: '1242',
    Name: 'Manager, control, material'
  },
  {
    Id: 1396,
    Code: '1242',
    Name: 'Manager, control, materials'
  },
  {
    Id: 1397,
    Code: '1242',
    Name: 'Manager, control, stock'
  },
  {
    Id: 1398,
    Code: '1242',
    Name: 'Manager, depot, warehousing'
  },
  {
    Id: 1399,
    Code: '1242',
    Name: 'Manager, inventory'
  },
  {
    Id: 1400,
    Code: '1242',
    Name: 'Manager, inwards, goods'
  },
  {
    Id: 1401,
    Code: '1242',
    Name: 'Manager, materials'
  },
  {
    Id: 1402,
    Code: '1242',
    Name: 'Manager, operations, depot'
  },
  {
    Id: 1403,
    Code: '1242',
    Name: 'Manager, operations, warehouse'
  },
  {
    Id: 1404,
    Code: '1242',
    Name: 'Manager, operations, warehousing'
  },
  {
    Id: 1405,
    Code: '1242',
    Name: 'Manager, out, goods'
  },
  {
    Id: 1406,
    Code: '1242',
    Name: 'Manager, packaging'
  },
  {
    Id: 1407,
    Code: '1242',
    Name: 'Manager, shift, warehouse'
  },
  {
    Id: 1408,
    Code: '1242',
    Name: 'Manager, stock'
  },
  {
    Id: 1409,
    Code: '1242',
    Name: 'Manager, store, cold'
  },
  {
    Id: 1410,
    Code: '1242',
    Name: 'Manager, store'
  },
  {
    Id: 1411,
    Code: '1242',
    Name: "Manager, stores, ship's"
  },
  {
    Id: 1412,
    Code: '1242',
    Name: 'Manager, stores'
  },
  {
    Id: 1413,
    Code: '1242',
    Name: 'Manager, systems, inventory'
  },
  {
    Id: 1414,
    Code: '1242',
    Name: 'Manager, team, warehouse'
  },
  {
    Id: 1415,
    Code: '1242',
    Name: 'Manager, team, warehousing'
  },
  {
    Id: 1416,
    Code: '1242',
    Name: 'Manager, terminal'
  },
  {
    Id: 1417,
    Code: '1242',
    Name: 'Manager, terminals, container'
  },
  {
    Id: 1418,
    Code: '1242',
    Name: 'Manager, transport and warehouse'
  },
  {
    Id: 1419,
    Code: '1242',
    Name: 'Manager, warehouse'
  },
  {
    Id: 1420,
    Code: '1242',
    Name: 'Manager, yard'
  },
  {
    Id: 1421,
    Code: '1242',
    Name: 'Manager, packaging activities'
  },
  {
    Id: 1422,
    Code: '1242',
    Name: 'Manager, storage'
  },
  {
    Id: 1423,
    Code: '1242',
    Name: 'Manager, warehousing'
  },
  {
    Id: 1424,
    Code: '1243',
    Name: 'Manager, chain, supply'
  },
  {
    Id: 1425,
    Code: '1243',
    Name: 'Manager, logistic'
  },
  {
    Id: 1426,
    Code: '1243',
    Name: 'Manager, logistics'
  },
  {
    Id: 1427,
    Code: '1243',
    Name: 'Manager, logistics and distribution'
  },
  {
    Id: 1428,
    Code: '1243',
    Name: 'Manager, operations, logistics'
  },
  {
    Id: 1429,
    Code: '1243',
    Name: 'Manager, planning, logistics'
  },
  {
    Id: 1430,
    Code: '1243',
    Name: 'Manager, support, logistics'
  },
  {
    Id: 1431,
    Code: '1251',
    Name: 'Developer, property'
  },
  {
    Id: 1432,
    Code: '1251',
    Name: 'Director, housing'
  },
  {
    Id: 1433,
    Code: '1251',
    Name: 'Director, land, building construction'
  },
  {
    Id: 1434,
    Code: '1251',
    Name: 'Director, property'
  },
  {
    Id: 1435,
    Code: '1251',
    Name: 'Director, services, property management'
  },
  {
    Id: 1436,
    Code: '1251',
    Name: 'Director, property management'
  },
  {
    Id: 1437,
    Code: '1251',
    Name: 'Factor, estate, Scotland'
  },
  {
    Id: 1438,
    Code: '1251',
    Name: 'Factor, housing, Scotland'
  },
  {
    Id: 1439,
    Code: '1251',
    Name: 'Head of facilities'
  },
  {
    Id: 1440,
    Code: '1251',
    Name: 'Head of facilities management'
  },
  {
    Id: 1441,
    Code: '1251',
    Name: 'Head of property'
  },
  {
    Id: 1442,
    Code: '1251',
    Name: 'Investor, property'
  },
  {
    Id: 1443,
    Code: '1251',
    Name: 'Landlady, property management'
  },
  {
    Id: 1444,
    Code: '1251',
    Name: 'Landlord, private'
  },
  {
    Id: 1445,
    Code: '1251',
    Name: 'Landlord, property'
  },
  {
    Id: 1446,
    Code: '1251',
    Name: 'Landlord, property management'
  },
  {
    Id: 1447,
    Code: '1251',
    Name: 'Landowner'
  },
  {
    Id: 1448,
    Code: '1251',
    Name: 'Manager, agent, estate'
  },
  {
    Id: 1449,
    Code: '1251',
    Name: 'Manager, auction'
  },
  {
    Id: 1450,
    Code: '1251',
    Name: 'Manager, branch, property letting'
  },
  {
    Id: 1451,
    Code: '1251',
    Name: 'Manager, building, property management'
  },
  {
    Id: 1452,
    Code: '1251',
    Name: 'Manager, caretaking'
  },
  {
    Id: 1453,
    Code: '1251',
    Name: 'Manager, centre, city'
  },
  {
    Id: 1454,
    Code: '1251',
    Name: 'Manager, centre, shopping'
  },
  {
    Id: 1455,
    Code: '1251',
    Name: 'Manager, centre, town'
  },
  {
    Id: 1456,
    Code: '1251',
    Name: 'Manager, contract, facilities'
  },
  {
    Id: 1457,
    Code: '1251',
    Name: 'Manager, environment, railways'
  },
  {
    Id: 1458,
    Code: '1251',
    Name: 'Manager, estate'
  },
  {
    Id: 1459,
    Code: '1251',
    Name: 'Manager, estates'
  },
  {
    Id: 1460,
    Code: '1251',
    Name: 'Manager, facilities, regional'
  },
  {
    Id: 1461,
    Code: '1251',
    Name: 'Manager, facilities'
  },
  {
    Id: 1462,
    Code: '1251',
    Name: 'Manager, facility'
  },
  {
    Id: 1463,
    Code: '1251',
    Name: 'Manager, hall, town'
  },
  {
    Id: 1464,
    Code: '1251',
    Name: 'Manager, house, property management'
  },
  {
    Id: 1465,
    Code: '1251',
    Name: 'Manager, housing'
  },
  {
    Id: 1466,
    Code: '1251',
    Name: 'Manager, land, building construction'
  },
  {
    Id: 1467,
    Code: '1251',
    Name: 'Manager, letting'
  },
  {
    Id: 1468,
    Code: '1251',
    Name: 'Manager, lettings, residential'
  },
  {
    Id: 1469,
    Code: '1251',
    Name: 'Manager, lettings'
  },
  {
    Id: 1470,
    Code: '1251',
    Name: 'Manager, market'
  },
  {
    Id: 1471,
    Code: '1251',
    Name: 'Manager, markets'
  },
  {
    Id: 1472,
    Code: '1251',
    Name: 'Manager, neighbourhood'
  },
  {
    Id: 1473,
    Code: '1251',
    Name: 'Manager, premises'
  },
  {
    Id: 1474,
    Code: '1251',
    Name: 'Manager, project, facilities'
  },
  {
    Id: 1475,
    Code: '1251',
    Name: 'Manager, property'
  },
  {
    Id: 1476,
    Code: '1251',
    Name: 'Manager, relocation'
  },
  {
    Id: 1477,
    Code: '1251',
    Name: 'Manager, resident'
  },
  {
    Id: 1478,
    Code: '1251',
    Name: 'Manager, sales, estate agents'
  },
  {
    Id: 1479,
    Code: '1251',
    Name: 'Manager, scheme, housing association'
  },
  {
    Id: 1480,
    Code: '1251',
    Name: 'Manager, service, property'
  },
  {
    Id: 1481,
    Code: '1251',
    Name: 'Manager, services, house'
  },
  {
    Id: 1482,
    Code: '1251',
    Name: 'Manager, services, housing'
  },
  {
    Id: 1483,
    Code: '1251',
    Name: 'Manager, services, soft'
  },
  {
    Id: 1484,
    Code: '1251',
    Name: 'Manager, site, property management'
  },
  {
    Id: 1485,
    Code: '1251',
    Name: 'Manager, support, facilities'
  },
  {
    Id: 1486,
    Code: '1251',
    Name: 'Manager, technical, property management'
  },
  {
    Id: 1487,
    Code: '1251',
    Name: 'Manager, voids'
  },
  {
    Id: 1488,
    Code: '1251',
    Name: 'Manager, auctioneers'
  },
  {
    Id: 1489,
    Code: '1251',
    Name: 'Manager, estate agents'
  },
  {
    Id: 1490,
    Code: '1251',
    Name: 'Manager, housing association'
  },
  {
    Id: 1491,
    Code: '1251',
    Name: 'Manager, property investment company'
  },
  {
    Id: 1492,
    Code: '1251',
    Name: 'Manager, property management'
  },
  {
    Id: 1493,
    Code: '1251',
    Name: 'Officer, estate'
  },
  {
    Id: 1494,
    Code: '1251',
    Name: 'Officer, estates'
  },
  {
    Id: 1495,
    Code: '1251',
    Name: 'Owner, agency, letting'
  },
  {
    Id: 1496,
    Code: '1251',
    Name: 'Owner, auction'
  },
  {
    Id: 1497,
    Code: '1251',
    Name: 'Owner, land'
  },
  {
    Id: 1498,
    Code: '1251',
    Name: 'Owner, property'
  },
  {
    Id: 1499,
    Code: '1251',
    Name: 'Owner, estate agents'
  },
  {
    Id: 1500,
    Code: '1251',
    Name: 'Owner, letting agency'
  },
  {
    Id: 1501,
    Code: '1251',
    Name: 'Owner, property management'
  },
  {
    Id: 1502,
    Code: '1251',
    Name: 'Surveyor, estate, property management'
  },
  {
    Id: 1503,
    Code: '1251',
    Name: 'Surveyor, estates, property management'
  },
  {
    Id: 1504,
    Code: '1252',
    Name: 'Dealer, principal'
  },
  {
    Id: 1505,
    Code: '1252',
    Name: 'Director, garage'
  },
  {
    Id: 1506,
    Code: '1252',
    Name: 'Director, vehicle repair'
  },
  {
    Id: 1507,
    Code: '1252',
    Name: 'Manager, bodyshop, vehicle trades'
  },
  {
    Id: 1508,
    Code: '1252',
    Name: 'Manager, branch, garage'
  },
  {
    Id: 1509,
    Code: '1252',
    Name: 'Manager, depot, tyre'
  },
  {
    Id: 1510,
    Code: '1252',
    Name: 'Manager, engineering, public transport'
  },
  {
    Id: 1511,
    Code: '1252',
    Name: 'Manager, garage'
  },
  {
    Id: 1512,
    Code: '1252',
    Name: 'Manager, production, garage'
  },
  {
    Id: 1513,
    Code: '1252',
    Name: 'Manager, service, motorcycles'
  },
  {
    Id: 1514,
    Code: '1252',
    Name: 'Manager, shop, body, vehicle trades'
  },
  {
    Id: 1515,
    Code: '1252',
    Name: 'Manager, garage'
  },
  {
    Id: 1516,
    Code: '1252',
    Name: 'Manager, repairing: motor vehicles'
  },
  {
    Id: 1517,
    Code: '1252',
    Name: 'Manager, tyre dealers'
  },
  {
    Id: 1518,
    Code: '1252',
    Name: 'Owner, garage'
  },
  {
    Id: 1519,
    Code: '1252',
    Name: 'Owner, garage'
  },
  {
    Id: 1520,
    Code: '1252',
    Name: 'Owner, vehicle repair'
  },
  {
    Id: 1521,
    Code: '1252',
    Name: 'Principal, dealer'
  },
  {
    Id: 1522,
    Code: '1253',
    Name: 'Director, hair and beauty salon'
  },
  {
    Id: 1523,
    Code: '1253',
    Name: "Manager, barber's"
  },
  {
    Id: 1524,
    Code: '1253',
    Name: "Manager, hairdresser's"
  },
  {
    Id: 1525,
    Code: '1253',
    Name: 'Manager, shop, hairdressing'
  },
  {
    Id: 1526,
    Code: '1253',
    Name: 'Manager, spa'
  },
  {
    Id: 1527,
    Code: '1253',
    Name: 'Manager, beautician'
  },
  {
    Id: 1528,
    Code: '1253',
    Name: 'Manager, beauty salon'
  },
  {
    Id: 1529,
    Code: '1253',
    Name: 'Manager, hair and beauty salon'
  },
  {
    Id: 1530,
    Code: '1253',
    Name: 'Manager, hairdressing'
  },
  {
    Id: 1531,
    Code: '1253',
    Name: 'Manager, nail salon'
  },
  {
    Id: 1532,
    Code: '1253',
    Name: 'Manager, pet grooming salon'
  },
  {
    Id: 1533,
    Code: '1253',
    Name: 'Owner, salon, hairdressing'
  },
  {
    Id: 1534,
    Code: '1253',
    Name: 'Owner, studio, beauty'
  },
  {
    Id: 1535,
    Code: '1253',
    Name: 'Owner, beauty, health studio'
  },
  {
    Id: 1536,
    Code: '1253',
    Name: 'Owner, beauty salon'
  },
  {
    Id: 1537,
    Code: '1253',
    Name: 'Owner, hair and beauty salon'
  },
  {
    Id: 1538,
    Code: '1253',
    Name: 'Owner, hairdressing'
  },
  {
    Id: 1539,
    Code: '1253',
    Name: 'Owner, nail salon'
  },
  {
    Id: 1540,
    Code: '1253',
    Name: 'Owner, pet grooming salon'
  },
  {
    Id: 1541,
    Code: '1253',
    Name: 'Owner, tanning studio'
  },
  {
    Id: 1542,
    Code: '1254',
    Name: 'Broker, scrap'
  },
  {
    Id: 1543,
    Code: '1254',
    Name: 'Dealer, general'
  },
  {
    Id: 1544,
    Code: '1254',
    Name: 'Dealer, metal, scrap'
  },
  {
    Id: 1545,
    Code: '1254',
    Name: 'Dealer, paper, waste'
  },
  {
    Id: 1546,
    Code: '1254',
    Name: 'Dealer, rag'
  },
  {
    Id: 1547,
    Code: '1254',
    Name: 'Dealer, rag and bone'
  },
  {
    Id: 1548,
    Code: '1254',
    Name: 'Dealer, scrap'
  },
  {
    Id: 1549,
    Code: '1254',
    Name: 'Dealer, waste'
  },
  {
    Id: 1550,
    Code: '1254',
    Name: 'Man, rag'
  },
  {
    Id: 1551,
    Code: '1254',
    Name: 'Man, rag and bone'
  },
  {
    Id: 1552,
    Code: '1254',
    Name: 'Manager, cleansing'
  },
  {
    Id: 1553,
    Code: '1254',
    Name: 'Manager, contract, waste disposal and environmental services'
  },
  {
    Id: 1554,
    Code: '1254',
    Name: 'Manager, environmental, waste disposal and environmental services'
  },
  {
    Id: 1555,
    Code: '1254',
    Name: 'Manager, incinerator'
  },
  {
    Id: 1556,
    Code: '1254',
    Name: 'Manager, landfill'
  },
  {
    Id: 1557,
    Code: '1254',
    Name: 'Manager, merchant, scrap'
  },
  {
    Id: 1558,
    Code: '1254',
    Name: 'Manager, operations, water'
  },
  {
    Id: 1559,
    Code: '1254',
    Name: 'Manager, operations, waste disposal and environmental services'
  },
  {
    Id: 1560,
    Code: '1254',
    Name: 'Manager, plant, recycling'
  },
  {
    Id: 1561,
    Code: '1254',
    Name: 'Manager, reclaim'
  },
  {
    Id: 1562,
    Code: '1254',
    Name: 'Manager, reclamation'
  },
  {
    Id: 1563,
    Code: '1254',
    Name: 'Manager, recycling'
  },
  {
    Id: 1564,
    Code: '1254',
    Name: 'Manager, services, environmental'
  },
  {
    Id: 1565,
    Code: '1254',
    Name: 'Manager, site, landfill'
  },
  {
    Id: 1566,
    Code: '1254',
    Name: 'Manager, technical, waste disposal and environmental services'
  },
  {
    Id: 1567,
    Code: '1254',
    Name: 'Manager, works, sewage'
  },
  {
    Id: 1568,
    Code: '1254',
    Name: 'Manager, yard, scrap merchants, breakers'
  },
  {
    Id: 1569,
    Code: '1254',
    Name: 'Manager, yard, waste disposal and environmental services'
  },
  {
    Id: 1570,
    Code: '1254',
    Name: 'Manager, recycling plant'
  },
  {
    Id: 1571,
    Code: '1254',
    Name: 'Manager, scrap merchants, breakers'
  },
  {
    Id: 1572,
    Code: '1254',
    Name: 'Manager, sewage works'
  },
  {
    Id: 1573,
    Code: '1254',
    Name: 'Manager, waste disposal and environmental services'
  },
  {
    Id: 1574,
    Code: '1254',
    Name: 'Merchant, metal, scrap'
  },
  {
    Id: 1575,
    Code: '1254',
    Name: 'Merchant, paper, waste'
  },
  {
    Id: 1576,
    Code: '1254',
    Name: 'Merchant, rag and bone'
  },
  {
    Id: 1577,
    Code: '1254',
    Name: 'Merchant, salvage'
  },
  {
    Id: 1578,
    Code: '1254',
    Name: 'Merchant, scrap'
  },
  {
    Id: 1579,
    Code: '1254',
    Name: 'Owner, plant, recycling'
  },
  {
    Id: 1580,
    Code: '1254',
    Name: 'Owner, yard, scrap'
  },
  {
    Id: 1581,
    Code: '1254',
    Name: 'Owner, recycling plant'
  },
  {
    Id: 1582,
    Code: '1254',
    Name: 'Owner, scrap merchants, breakers'
  },
  {
    Id: 1583,
    Code: '1255',
    Name: 'Agent, booking'
  },
  {
    Id: 1584,
    Code: '1255',
    Name: 'Agent, concert'
  },
  {
    Id: 1585,
    Code: '1255',
    Name: 'Agent, entertainment'
  },
  {
    Id: 1586,
    Code: '1255',
    Name: 'Agent, film'
  },
  {
    Id: 1587,
    Code: '1255',
    Name: 'Agent, model'
  },
  {
    Id: 1588,
    Code: '1255',
    Name: "Agent, model's"
  },
  {
    Id: 1589,
    Code: '1255',
    Name: 'Agent, talent'
  },
  {
    Id: 1590,
    Code: '1255',
    Name: 'Agent, theatrical'
  },
  {
    Id: 1591,
    Code: '1255',
    Name: 'Agent, variety'
  },
  {
    Id: 1592,
    Code: '1255',
    Name: 'Agent, talent'
  },
  {
    Id: 1593,
    Code: '1255',
    Name: 'Director, design'
  },
  {
    Id: 1594,
    Code: '1255',
    Name: 'Director, gallery, art'
  },
  {
    Id: 1595,
    Code: '1255',
    Name: 'Director, gallery'
  },
  {
    Id: 1596,
    Code: '1255',
    Name: 'Director, operations, publishing'
  },
  {
    Id: 1597,
    Code: '1255',
    Name: 'Director, publishing'
  },
  {
    Id: 1598,
    Code: '1255',
    Name: 'Director, technical, entertainment'
  },
  {
    Id: 1599,
    Code: '1255',
    Name: 'Director, design'
  },
  {
    Id: 1600,
    Code: '1255',
    Name: 'Director, graphic design'
  },
  {
    Id: 1601,
    Code: '1255',
    Name: 'Director, architectural service'
  },
  {
    Id: 1602,
    Code: '1255',
    Name: 'Director, interior design'
  },
  {
    Id: 1603,
    Code: '1255',
    Name: 'Manager, architect'
  },
  {
    Id: 1604,
    Code: '1255',
    Name: 'Manager, architecture'
  },
  {
    Id: 1605,
    Code: '1255',
    Name: 'Manager, artist'
  },
  {
    Id: 1606,
    Code: '1255',
    Name: 'Manager, arts'
  },
  {
    Id: 1607,
    Code: '1255',
    Name: "Manager, boxer's"
  },
  {
    Id: 1608,
    Code: '1255',
    Name: 'Manager, broadcasting'
  },
  {
    Id: 1609,
    Code: '1255',
    Name: 'Manager, creative'
  },
  {
    Id: 1610,
    Code: '1255',
    Name: 'Manager, design, graphic'
  },
  {
    Id: 1611,
    Code: '1255',
    Name: 'Manager, gallery'
  },
  {
    Id: 1612,
    Code: '1255',
    Name: 'Manager, general, theatrical productions'
  },
  {
    Id: 1613,
    Code: '1255',
    Name: 'Manager, personal, entertainment'
  },
  {
    Id: 1614,
    Code: '1255',
    Name: 'Manager, practice, architectural'
  },
  {
    Id: 1615,
    Code: '1255',
    Name: 'Manager, production, theatrical'
  },
  {
    Id: 1616,
    Code: '1255',
    Name: 'Manager, production, video'
  },
  {
    Id: 1617,
    Code: '1255',
    Name: 'Manager, production, entertainment'
  },
  {
    Id: 1618,
    Code: '1255',
    Name: 'Manager, production, broadcasting'
  },
  {
    Id: 1619,
    Code: '1255',
    Name: 'Manager, production, film, television production'
  },
  {
    Id: 1620,
    Code: '1255',
    Name: 'Manager, production, publishing'
  },
  {
    Id: 1621,
    Code: '1255',
    Name: "Manager, publisher's"
  },
  {
    Id: 1622,
    Code: '1255',
    Name: 'Manager, station, radio'
  },
  {
    Id: 1623,
    Code: '1255',
    Name: 'Manager, studio, design'
  },
  {
    Id: 1624,
    Code: '1255',
    Name: 'Manager, studio, design'
  },
  {
    Id: 1625,
    Code: '1255',
    Name: 'Manager, tour, entertainment'
  },
  {
    Id: 1626,
    Code: '1255',
    Name: 'Manager, touring, entertainment'
  },
  {
    Id: 1627,
    Code: '1255',
    Name: 'Manager, architectural service'
  },
  {
    Id: 1628,
    Code: '1255',
    Name: 'Manager, cable television broadcasting'
  },
  {
    Id: 1629,
    Code: '1255',
    Name: 'Manager, photographic studios'
  },
  {
    Id: 1630,
    Code: '1255',
    Name: 'Manager, radio broadcasting'
  },
  {
    Id: 1631,
    Code: '1255',
    Name: 'Manager, radio station'
  },
  {
    Id: 1632,
    Code: '1255',
    Name: 'Manager, television: production, broadcasting'
  },
  {
    Id: 1633,
    Code: '1255',
    Name: 'Manager, television: transmission station'
  },
  {
    Id: 1634,
    Code: '1255',
    Name: 'Manager, theatrical agency'
  },
  {
    Id: 1635,
    Code: '1255',
    Name: 'Owner, gallery, art'
  },
  {
    Id: 1636,
    Code: '1255',
    Name: 'Owner, studio, photographic'
  },
  {
    Id: 1637,
    Code: '1255',
    Name: 'Owner, art gallery'
  },
  {
    Id: 1638,
    Code: '1255',
    Name: 'Owner, photographic agency'
  },
  {
    Id: 1639,
    Code: '1255',
    Name: 'Owner, recording studio'
  },
  {
    Id: 1640,
    Code: '1255',
    Name: 'Owner, theatrical agency'
  },
  {
    Id: 1641,
    Code: '1255',
    Name: 'Owner-publisher'
  },
  {
    Id: 1642,
    Code: '1255',
    Name: 'Publisher'
  },
  {
    Id: 1643,
    Code: '1256',
    Name: 'CEM, bookmakers, turf accountants'
  },
  {
    Id: 1644,
    Code: '1256',
    Name: 'Manager, bingo'
  },
  {
    Id: 1645,
    Code: '1256',
    Name: "Manager, bookmaker's"
  },
  {
    Id: 1646,
    Code: '1256',
    Name: 'Manager, casino'
  },
  {
    Id: 1647,
    Code: '1256',
    Name: 'Manager, experience, customer, bookmakers, turf accountants'
  },
  {
    Id: 1648,
    Code: '1256',
    Name: 'Manager, hall, bingo'
  },
  {
    Id: 1649,
    Code: '1256',
    Name: 'Manager, office, betting'
  },
  {
    Id: 1650,
    Code: '1256',
    Name: 'Manager, office, bookmakers, turf accountants'
  },
  {
    Id: 1651,
    Code: '1256',
    Name: 'Manager, retail, betting shop'
  },
  {
    Id: 1652,
    Code: '1256',
    Name: 'Manager, shop, betting'
  },
  {
    Id: 1653,
    Code: '1256',
    Name: 'Manager, shop, bookmakers, turf accountants'
  },
  {
    Id: 1654,
    Code: '1256',
    Name: 'Manager, betting and gambling: casino, gaming club'
  },
  {
    Id: 1655,
    Code: '1256',
    Name: 'Manager, betting and gambling'
  },
  {
    Id: 1656,
    Code: '1256',
    Name: 'Manager, bingo hall'
  },
  {
    Id: 1657,
    Code: '1256',
    Name: 'Manager, bookmakers, turf accountants'
  },
  {
    Id: 1658,
    Code: '1256',
    Name: 'Owner, shop, betting'
  },
  {
    Id: 1659,
    Code: '1257',
    Name: 'Manager, branch, car hire'
  },
  {
    Id: 1660,
    Code: '1257',
    Name: 'Manager, branch, radio, television and video hire'
  },
  {
    Id: 1661,
    Code: '1257',
    Name: 'Manager, centre, hire'
  },
  {
    Id: 1662,
    Code: '1257',
    Name: 'Manager, hire, equipment'
  },
  {
    Id: 1663,
    Code: '1257',
    Name: 'Manager, hire, plant'
  },
  {
    Id: 1664,
    Code: '1257',
    Name: 'Manager, hire, skip'
  },
  {
    Id: 1665,
    Code: '1257',
    Name: 'Manager, hire, television and video, radio'
  },
  {
    Id: 1666,
    Code: '1257',
    Name: 'Manager, hire, tool'
  },
  {
    Id: 1667,
    Code: '1257',
    Name: 'Manager, hire'
  },
  {
    Id: 1668,
    Code: '1257',
    Name: 'Manager, operations, plant hire'
  },
  {
    Id: 1669,
    Code: '1257',
    Name: 'Manager, plant, hire service'
  },
  {
    Id: 1670,
    Code: '1257',
    Name: 'Manager, radio, television and video'
  },
  {
    Id: 1671,
    Code: '1257',
    Name: 'Manager, rental, vehicle'
  },
  {
    Id: 1672,
    Code: '1257',
    Name: 'Manager, rental'
  },
  {
    Id: 1673,
    Code: '1257',
    Name: 'Manager, service, rental'
  },
  {
    Id: 1674,
    Code: '1257',
    Name: 'Manager, service, rental services'
  },
  {
    Id: 1675,
    Code: '1257',
    Name: 'Manager, shop, hire'
  },
  {
    Id: 1676,
    Code: '1257',
    Name: 'Manager, shop, video'
  },
  {
    Id: 1677,
    Code: '1257',
    Name: 'Manager, shop, radio, television and video hire'
  },
  {
    Id: 1678,
    Code: '1257',
    Name: 'Manager, car hire'
  },
  {
    Id: 1679,
    Code: '1257',
    Name: 'Manager, hire shop'
  },
  {
    Id: 1680,
    Code: '1257',
    Name: 'Manager, machinery hire'
  },
  {
    Id: 1681,
    Code: '1257',
    Name: 'Manager, plant hire'
  },
  {
    Id: 1682,
    Code: '1257',
    Name: 'Manager, radio, television and video hire'
  },
  {
    Id: 1683,
    Code: '1257',
    Name: 'Manager, retail trade: video shop'
  },
  {
    Id: 1684,
    Code: '1257',
    Name: 'Owner, hire, plant'
  },
  {
    Id: 1685,
    Code: '1257',
    Name: 'Owner, service, cab'
  },
  {
    Id: 1686,
    Code: '1257',
    Name: 'Owner, shop, video'
  },
  {
    Id: 1687,
    Code: '1257',
    Name: 'Owner, boat: pleasure hire'
  },
  {
    Id: 1688,
    Code: '1257',
    Name: 'Owner, cab rental service'
  },
  {
    Id: 1689,
    Code: '1257',
    Name: 'Owner, car hire service'
  },
  {
    Id: 1690,
    Code: '1257',
    Name: 'Owner, caravan hire service'
  },
  {
    Id: 1691,
    Code: '1257',
    Name: 'Owner, marquee hire service'
  },
  {
    Id: 1692,
    Code: '1257',
    Name: 'Owner, vehicle hire service'
  },
  {
    Id: 1693,
    Code: '1257',
    Name: 'Owner, video shop'
  },
  {
    Id: 1694,
    Code: '1257',
    Name: 'Retailer, video shop'
  },
  {
    Id: 1695,
    Code: '1258',
    Name: 'Director, technical, consultancy services'
  },
  {
    Id: 1696,
    Code: '1258',
    Name: 'Director, technical, design consultancy'
  },
  {
    Id: 1697,
    Code: '1258',
    Name: 'Director, technical, engineering consultancy'
  },
  {
    Id: 1698,
    Code: '1258',
    Name: 'Director, technical, environmental consultancy'
  },
  {
    Id: 1699,
    Code: '1258',
    Name: 'Director, civil engineering consultancy'
  },
  {
    Id: 1700,
    Code: '1258',
    Name: 'Director, construction consultancy'
  },
  {
    Id: 1701,
    Code: '1258',
    Name: 'Director, consultancy services'
  },
  {
    Id: 1702,
    Code: '1258',
    Name: 'Director, design consultancy'
  },
  {
    Id: 1703,
    Code: '1258',
    Name: 'Director, engineering consultancy'
  },
  {
    Id: 1704,
    Code: '1258',
    Name: 'Director, environmental consultancy'
  },
  {
    Id: 1705,
    Code: '1258',
    Name: 'Director, management consultancy'
  },
  {
    Id: 1706,
    Code: '1258',
    Name: 'Director, marketing consultancy'
  },
  {
    Id: 1707,
    Code: '1258',
    Name: 'Director, project management consultancy'
  },
  {
    Id: 1708,
    Code: '1258',
    Name: 'Director, surveying activities'
  },
  {
    Id: 1709,
    Code: '1258',
    Name: 'Manager, consultancy services'
  },
  {
    Id: 1710,
    Code: '1258',
    Name: 'Manager, management consultancy'
  },
  {
    Id: 1711,
    Code: '1258',
    Name: 'Manager, surveying activities'
  },
  {
    Id: 1712,
    Code: '1258',
    Name: 'Owner, consultancy services'
  },
  {
    Id: 1713,
    Code: '1258',
    Name: 'Owner, design consultancy'
  },
  {
    Id: 1714,
    Code: '1258',
    Name: 'Owner, management consultancy'
  },
  {
    Id: 1715,
    Code: '1259',
    Name: 'Director, health and safety'
  },
  {
    Id: 1716,
    Code: '1259',
    Name: 'Director, security, security services'
  },
  {
    Id: 1717,
    Code: '1259',
    Name: 'Director, cleaning services'
  },
  {
    Id: 1718,
    Code: '1259',
    Name: 'Director, commission agents'
  },
  {
    Id: 1719,
    Code: '1259',
    Name: 'Director, educational establishments'
  },
  {
    Id: 1720,
    Code: '1259',
    Name: 'Director, employment agency: private'
  },
  {
    Id: 1721,
    Code: '1259',
    Name: 'Director, private detective agency'
  },
  {
    Id: 1722,
    Code: '1259',
    Name: 'Director, security services'
  },
  {
    Id: 1723,
    Code: '1259',
    Name: 'Head of health and safety'
  },
  {
    Id: 1724,
    Code: '1259',
    Name: 'Manager, branch, library'
  },
  {
    Id: 1725,
    Code: '1259',
    Name: 'Manager, cemeteries'
  },
  {
    Id: 1726,
    Code: '1259',
    Name: 'Manager, cemetery'
  },
  {
    Id: 1727,
    Code: '1259',
    Name: 'Manager, centre, assessment'
  },
  {
    Id: 1728,
    Code: '1259',
    Name: 'Manager, centre, skills'
  },
  {
    Id: 1729,
    Code: '1259',
    Name: 'Manager, centre, test, COVID'
  },
  {
    Id: 1730,
    Code: '1259',
    Name: 'Manager, centre, training'
  },
  {
    Id: 1731,
    Code: '1259',
    Name: 'Manager, crematorium'
  },
  {
    Id: 1732,
    Code: '1259',
    Name: 'Manager, DVS'
  },
  {
    Id: 1733,
    Code: '1259',
    Name: 'Manager, experience, customer'
  },
  {
    Id: 1734,
    Code: '1259',
    Name: 'Manager, furnishing'
  },
  {
    Id: 1735,
    Code: '1259',
    Name: 'Manager, hub, testing, COVID'
  },
  {
    Id: 1736,
    Code: '1259',
    Name: 'Manager, hygiene'
  },
  {
    Id: 1737,
    Code: '1259',
    Name: 'Manager, launderette'
  },
  {
    Id: 1738,
    Code: '1259',
    Name: 'Manager, laundry'
  },
  {
    Id: 1739,
    Code: '1259',
    Name: 'Manager, library'
  },
  {
    Id: 1740,
    Code: '1259',
    Name: 'Manager, operations, cleaning services'
  },
  {
    Id: 1741,
    Code: '1259',
    Name: 'Manager, operations, recruitment agency'
  },
  {
    Id: 1742,
    Code: '1259',
    Name: 'Manager, park, car'
  },
  {
    Id: 1743,
    Code: '1259',
    Name: 'Manager, parking'
  },
  {
    Id: 1744,
    Code: '1259',
    Name: 'Manager, retail, laundry, launderette, dry cleaning'
  },
  {
    Id: 1745,
    Code: '1259',
    Name: 'Manager, rig'
  },
  {
    Id: 1746,
    Code: '1259',
    Name: 'Manager, safety, community'
  },
  {
    Id: 1747,
    Code: '1259',
    Name: 'Manager, service, advice'
  },
  {
    Id: 1748,
    Code: '1259',
    Name: 'Manager, service, crane'
  },
  {
    Id: 1749,
    Code: '1259',
    Name: 'Manager, service, valet, car'
  },
  {
    Id: 1750,
    Code: '1259',
    Name: 'Manager, service, Citizens Advice'
  },
  {
    Id: 1751,
    Code: '1259',
    Name: 'Manager, service, cleaning services'
  },
  {
    Id: 1752,
    Code: '1259',
    Name: 'Manager, service, library'
  },
  {
    Id: 1753,
    Code: '1259',
    Name: 'Manager, service, motoring organisation'
  },
  {
    Id: 1754,
    Code: '1259',
    Name: 'Manager, services, parking'
  },
  {
    Id: 1755,
    Code: '1259',
    Name: 'Manager, services, violence, domestic'
  },
  {
    Id: 1756,
    Code: '1259',
    Name: 'Manager, shift, recruitment agency'
  },
  {
    Id: 1757,
    Code: '1259',
    Name: 'Manager, shop, dyeing and cleaning'
  },
  {
    Id: 1758,
    Code: '1259',
    Name: 'Manager, shop, laundry, laundrette, dry cleaning'
  },
  {
    Id: 1759,
    Code: '1259',
    Name: 'Manager, site, COVID test centre'
  },
  {
    Id: 1760,
    Code: '1259',
    Name: 'Manager, site, cleaning services'
  },
  {
    Id: 1761,
    Code: '1259',
    Name: 'Manager, store, loan company'
  },
  {
    Id: 1762,
    Code: '1259',
    Name: 'Manager, studio, printing'
  },
  {
    Id: 1763,
    Code: '1259',
    Name: 'Manager, tourism'
  },
  {
    Id: 1764,
    Code: '1259',
    Name: 'Manager, alarm, security installation'
  },
  {
    Id: 1765,
    Code: '1259',
    Name: 'Manager, car park'
  },
  {
    Id: 1766,
    Code: '1259',
    Name: 'Manager, car wash, valeting'
  },
  {
    Id: 1767,
    Code: '1259',
    Name: 'Manager, Citizens Advice'
  },
  {
    Id: 1768,
    Code: '1259',
    Name: 'Manager, dating agency'
  },
  {
    Id: 1769,
    Code: '1259',
    Name: 'Manager, driving school'
  },
  {
    Id: 1770,
    Code: '1259',
    Name: 'Manager, flying school'
  },
  {
    Id: 1771,
    Code: '1259',
    Name: 'Manager, funeral directors'
  },
  {
    Id: 1772,
    Code: '1259',
    Name: 'Manager, laundry, launderette, dry cleaning'
  },
  {
    Id: 1773,
    Code: '1259',
    Name: 'Manager, library'
  },
  {
    Id: 1774,
    Code: '1259',
    Name: 'Manager, recruitment agency'
  },
  {
    Id: 1775,
    Code: '1259',
    Name: 'Manager, repairing: consumer goods'
  },
  {
    Id: 1776,
    Code: '1259',
    Name: 'Manager, safe deposit'
  },
  {
    Id: 1777,
    Code: '1259',
    Name: 'Manager, ticket agents'
  },
  {
    Id: 1778,
    Code: '1259',
    Name: 'Manager, typewriting agency'
  },
  {
    Id: 1779,
    Code: '1259',
    Name: 'Manager and Registrar, cemetery'
  },
  {
    Id: 1780,
    Code: '1259',
    Name: 'Manager and Registrar, crematorium'
  },
  {
    Id: 1781,
    Code: '1259',
    Name: 'Owner, agency, employment'
  },
  {
    Id: 1782,
    Code: '1259',
    Name: 'Owner, agency, ticket'
  },
  {
    Id: 1783,
    Code: '1259',
    Name: 'Owner, launderette'
  },
  {
    Id: 1784,
    Code: '1259',
    Name: 'Owner, school, driving'
  },
  {
    Id: 1785,
    Code: '1259',
    Name: 'Owner, alarm, security installation'
  },
  {
    Id: 1786,
    Code: '1259',
    Name: 'Owner, chiropody practice'
  },
  {
    Id: 1787,
    Code: '1259',
    Name: 'Owner, cleaning services'
  },
  {
    Id: 1788,
    Code: '1259',
    Name: 'Owner, contract cleaning services'
  },
  {
    Id: 1789,
    Code: '1259',
    Name: 'Owner, dating agency'
  },
  {
    Id: 1790,
    Code: '1259',
    Name: 'Owner, domestic appliances repairing'
  },
  {
    Id: 1791,
    Code: '1259',
    Name: 'Owner, dry cleaning service'
  },
  {
    Id: 1792,
    Code: '1259',
    Name: 'Owner, employment agency'
  },
  {
    Id: 1793,
    Code: '1259',
    Name: 'Owner, funeral directors'
  },
  {
    Id: 1794,
    Code: '1259',
    Name: 'Owner, garden machinery repairing'
  },
  {
    Id: 1795,
    Code: '1259',
    Name: 'Owner, home care service'
  },
  {
    Id: 1796,
    Code: '1259',
    Name: 'Owner, launderette'
  },
  {
    Id: 1797,
    Code: '1259',
    Name: 'Owner, laundry'
  },
  {
    Id: 1798,
    Code: '1259',
    Name: 'Owner, newspaper'
  },
  {
    Id: 1799,
    Code: '1259',
    Name: 'Owner, pet crematorium'
  },
  {
    Id: 1800,
    Code: '1259',
    Name: 'Owner, plant machinery repairing'
  },
  {
    Id: 1801,
    Code: '1259',
    Name: 'Owner, printers'
  },
  {
    Id: 1802,
    Code: '1259',
    Name: 'Owner, radio, television and video servicing'
  },
  {
    Id: 1803,
    Code: '1259',
    Name: 'Owner, recruitment agency'
  },
  {
    Id: 1804,
    Code: '1259',
    Name: 'Owner, school: driving'
  },
  {
    Id: 1805,
    Code: '1259',
    Name: 'Owner, school: flying'
  },
  {
    Id: 1806,
    Code: '1259',
    Name: 'Owner, shoe repairing'
  },
  {
    Id: 1807,
    Code: '1259',
    Name: 'Owner, soft furnishings mfr'
  },
  {
    Id: 1808,
    Code: '1259',
    Name: 'Owner, ticket agents'
  },
  {
    Id: 1809,
    Code: '1259',
    Name: 'Superintendent, market'
  },
  {
    Id: 1810,
    Code: '2111',
    Name: 'Analyst, chemical'
  },
  {
    Id: 1811,
    Code: '2111',
    Name: 'Analyst, chief'
  },
  {
    Id: 1812,
    Code: '2111',
    Name: 'Analyst, county'
  },
  {
    Id: 1813,
    Code: '2111',
    Name: 'Analyst, laboratory'
  },
  {
    Id: 1814,
    Code: '2111',
    Name: 'Analyst, public'
  },
  {
    Id: 1815,
    Code: '2111',
    Name: 'Assistant, research, chemical'
  },
  {
    Id: 1816,
    Code: '2111',
    Name: 'Associate, research, chemical'
  },
  {
    Id: 1817,
    Code: '2111',
    Name: 'Chemist, analytical'
  },
  {
    Id: 1818,
    Code: '2111',
    Name: 'Chemist, chief'
  },
  {
    Id: 1819,
    Code: '2111',
    Name: 'Chemist, consulting'
  },
  {
    Id: 1820,
    Code: '2111',
    Name: 'Chemist, development'
  },
  {
    Id: 1821,
    Code: '2111',
    Name: 'Chemist, electroplating'
  },
  {
    Id: 1822,
    Code: '2111',
    Name: 'Chemist, government'
  },
  {
    Id: 1823,
    Code: '2111',
    Name: 'Chemist, industrial'
  },
  {
    Id: 1824,
    Code: '2111',
    Name: 'Chemist, inorganic'
  },
  {
    Id: 1825,
    Code: '2111',
    Name: 'Chemist, laboratory'
  },
  {
    Id: 1826,
    Code: '2111',
    Name: 'Chemist, managing'
  },
  {
    Id: 1827,
    Code: '2111',
    Name: 'Chemist, manufacturing'
  },
  {
    Id: 1828,
    Code: '2111',
    Name: 'Chemist, metallurgical'
  },
  {
    Id: 1829,
    Code: '2111',
    Name: 'Chemist, nuclear'
  },
  {
    Id: 1830,
    Code: '2111',
    Name: 'Chemist, organic'
  },
  {
    Id: 1831,
    Code: '2111',
    Name: 'Chemist, photographic'
  },
  {
    Id: 1832,
    Code: '2111',
    Name: 'Chemist, physical'
  },
  {
    Id: 1833,
    Code: '2111',
    Name: 'Chemist, polymer'
  },
  {
    Id: 1834,
    Code: '2111',
    Name: 'Chemist, research'
  },
  {
    Id: 1835,
    Code: '2111',
    Name: 'Chemist, research and development'
  },
  {
    Id: 1836,
    Code: '2111',
    Name: 'Chemist, shift'
  },
  {
    Id: 1837,
    Code: '2111',
    Name: 'Chemist, superintending'
  },
  {
    Id: 1838,
    Code: '2111',
    Name: 'Chemist, technical'
  },
  {
    Id: 1839,
    Code: '2111',
    Name: 'Chemist, textile'
  },
  {
    Id: 1840,
    Code: '2111',
    Name: 'Chemist, works'
  },
  {
    Id: 1841,
    Code: '2111',
    Name: 'Chemist'
  },
  {
    Id: 1842,
    Code: '2111',
    Name: 'Consultant, chemical'
  },
  {
    Id: 1843,
    Code: '2111',
    Name: 'Director of research, chemistry'
  },
  {
    Id: 1844,
    Code: '2111',
    Name: 'Doctor of chemistry'
  },
  {
    Id: 1845,
    Code: '2111',
    Name: 'Electro-chemist'
  },
  {
    Id: 1846,
    Code: '2111',
    Name: 'Flavourist'
  },
  {
    Id: 1847,
    Code: '2111',
    Name: 'Head, section, chemical mfr'
  },
  {
    Id: 1848,
    Code: '2111',
    Name: 'Officer, experimental, chief, chemist'
  },
  {
    Id: 1849,
    Code: '2111',
    Name: 'Officer, experimental, senior, chemist'
  },
  {
    Id: 1850,
    Code: '2111',
    Name: 'Officer, research, chemical'
  },
  {
    Id: 1851,
    Code: '2111',
    Name: 'Officer, sampling, milk'
  },
  {
    Id: 1852,
    Code: '2111',
    Name: 'Officer, scientific, chemical'
  },
  {
    Id: 1853,
    Code: '2111',
    Name: 'Officer, technical, chemical mfr'
  },
  {
    Id: 1854,
    Code: '2111',
    Name: 'Radiochemist'
  },
  {
    Id: 1855,
    Code: '2111',
    Name: 'Researcher, chemical'
  },
  {
    Id: 1856,
    Code: '2111',
    Name: 'Scientist, research, chemical'
  },
  {
    Id: 1857,
    Code: '2111',
    Name: 'Scientist, chemical'
  },
  {
    Id: 1858,
    Code: '2111',
    Name: 'Worker, research, chemical'
  },
  {
    Id: 1859,
    Code: '2112',
    Name: 'Adviser, agricultural'
  },
  {
    Id: 1860,
    Code: '2112',
    Name: 'Adviser, horticultural'
  },
  {
    Id: 1861,
    Code: '2112',
    Name: 'Adviser, poultry'
  },
  {
    Id: 1862,
    Code: '2112',
    Name: 'Adviser, agricultural'
  },
  {
    Id: 1863,
    Code: '2112',
    Name: 'Agroclimatologist'
  },
  {
    Id: 1864,
    Code: '2112',
    Name: 'Agronomist'
  },
  {
    Id: 1865,
    Code: '2112',
    Name: 'Agrostologist'
  },
  {
    Id: 1866,
    Code: '2112',
    Name: 'Anatomist'
  },
  {
    Id: 1867,
    Code: '2112',
    Name: 'Assistant, research, agricultural'
  },
  {
    Id: 1868,
    Code: '2112',
    Name: 'Assistant, research, biological'
  },
  {
    Id: 1869,
    Code: '2112',
    Name: 'Assistant, research, botanical'
  },
  {
    Id: 1870,
    Code: '2112',
    Name: 'Assistant, research, horticultural'
  },
  {
    Id: 1871,
    Code: '2112',
    Name: 'Assistant, research, zoological'
  },
  {
    Id: 1872,
    Code: '2112',
    Name: 'Associate, research, agricultural'
  },
  {
    Id: 1873,
    Code: '2112',
    Name: 'Associate, research, biological'
  },
  {
    Id: 1874,
    Code: '2112',
    Name: 'Associate, research, botanical'
  },
  {
    Id: 1875,
    Code: '2112',
    Name: 'Associate, research, horticultural'
  },
  {
    Id: 1876,
    Code: '2112',
    Name: 'Associate, research, zoological'
  },
  {
    Id: 1877,
    Code: '2112',
    Name: 'Bacteriologist'
  },
  {
    Id: 1878,
    Code: '2112',
    Name: 'Biologist'
  },
  {
    Id: 1879,
    Code: '2112',
    Name: 'Botanist'
  },
  {
    Id: 1880,
    Code: '2112',
    Name: 'Breeder, plant'
  },
  {
    Id: 1881,
    Code: '2112',
    Name: 'Chemist, agricultural'
  },
  {
    Id: 1882,
    Code: '2112',
    Name: 'Chemist, soil'
  },
  {
    Id: 1883,
    Code: '2112',
    Name: 'Conchologist'
  },
  {
    Id: 1884,
    Code: '2112',
    Name: 'Consultant, agricultural'
  },
  {
    Id: 1885,
    Code: '2112',
    Name: 'Consultant, arboricultural, professional'
  },
  {
    Id: 1886,
    Code: '2112',
    Name: 'Consultant, dairy'
  },
  {
    Id: 1887,
    Code: '2112',
    Name: 'Consultant, farming'
  },
  {
    Id: 1888,
    Code: '2112',
    Name: 'Consultant, fisheries'
  },
  {
    Id: 1889,
    Code: '2112',
    Name: 'Consultant, forestry'
  },
  {
    Id: 1890,
    Code: '2112',
    Name: 'Consultant, horticultural'
  },
  {
    Id: 1891,
    Code: '2112',
    Name: 'Cytotaxonomist'
  },
  {
    Id: 1892,
    Code: '2112',
    Name: 'Director of research, biological science'
  },
  {
    Id: 1893,
    Code: '2112',
    Name: 'Entomologist'
  },
  {
    Id: 1894,
    Code: '2112',
    Name: 'Fieldman, professionally qualified'
  },
  {
    Id: 1895,
    Code: '2112',
    Name: 'Fieldsman, professionally qualified'
  },
  {
    Id: 1896,
    Code: '2112',
    Name: 'Helminthologist'
  },
  {
    Id: 1897,
    Code: '2112',
    Name: 'Herpetologist'
  },
  {
    Id: 1898,
    Code: '2112',
    Name: 'Horticulturalist, professional'
  },
  {
    Id: 1899,
    Code: '2112',
    Name: 'Horticulturist, professional'
  },
  {
    Id: 1900,
    Code: '2112',
    Name: 'Hydrobiologist'
  },
  {
    Id: 1901,
    Code: '2112',
    Name: 'Ichthyologist'
  },
  {
    Id: 1902,
    Code: '2112',
    Name: 'Lepidopterist'
  },
  {
    Id: 1903,
    Code: '2112',
    Name: 'Limnologist'
  },
  {
    Id: 1904,
    Code: '2112',
    Name: 'Manager, arboricultural'
  },
  {
    Id: 1905,
    Code: '2112',
    Name: 'Microbiologist, medical'
  },
  {
    Id: 1906,
    Code: '2112',
    Name: 'Microbiologist'
  },
  {
    Id: 1907,
    Code: '2112',
    Name: 'Mycologist'
  },
  {
    Id: 1908,
    Code: '2112',
    Name: 'Naturalist'
  },
  {
    Id: 1909,
    Code: '2112',
    Name: 'Nematologist'
  },
  {
    Id: 1910,
    Code: '2112',
    Name: 'Neuroscientist, computational'
  },
  {
    Id: 1911,
    Code: '2112',
    Name: 'Nutritionist, agricultural'
  },
  {
    Id: 1912,
    Code: '2112',
    Name: 'Nutritionist, animal'
  },
  {
    Id: 1913,
    Code: '2112',
    Name: 'Nutritionist, research'
  },
  {
    Id: 1914,
    Code: '2112',
    Name: 'Nutritionist, ruminant'
  },
  {
    Id: 1915,
    Code: '2112',
    Name: 'Officer, advisory, district'
  },
  {
    Id: 1916,
    Code: '2112',
    Name: 'Officer, advisory, poultry'
  },
  {
    Id: 1917,
    Code: '2112',
    Name: 'Officer, advisory, DEFRA'
  },
  {
    Id: 1918,
    Code: '2112',
    Name: 'Officer, experimental, chief, biologist'
  },
  {
    Id: 1919,
    Code: '2112',
    Name: 'Officer, experimental, senior, biologist'
  },
  {
    Id: 1920,
    Code: '2112',
    Name: 'Officer, fatstock, DEFRA'
  },
  {
    Id: 1921,
    Code: '2112',
    Name: 'Officer, field, advisory'
  },
  {
    Id: 1922,
    Code: '2112',
    Name: 'Officer, field, professional'
  },
  {
    Id: 1923,
    Code: '2112',
    Name: 'Officer, horticultural'
  },
  {
    Id: 1924,
    Code: '2112',
    Name: 'Officer, production, milk'
  },
  {
    Id: 1925,
    Code: '2112',
    Name: 'Officer, research, agricultural'
  },
  {
    Id: 1926,
    Code: '2112',
    Name: 'Officer, research, biological'
  },
  {
    Id: 1927,
    Code: '2112',
    Name: 'Officer, research, botanical'
  },
  {
    Id: 1928,
    Code: '2112',
    Name: 'Officer, research, horticultural'
  },
  {
    Id: 1929,
    Code: '2112',
    Name: 'Officer, research, zoological'
  },
  {
    Id: 1930,
    Code: '2112',
    Name: 'Officer, scientific, agricultural'
  },
  {
    Id: 1931,
    Code: '2112',
    Name: 'Officer, scientific, biological'
  },
  {
    Id: 1932,
    Code: '2112',
    Name: 'Officer, scientific, botanical'
  },
  {
    Id: 1933,
    Code: '2112',
    Name: 'Officer, scientific, horticultural'
  },
  {
    Id: 1934,
    Code: '2112',
    Name: 'Officer, scientific, zoological'
  },
  {
    Id: 1935,
    Code: '2112',
    Name: 'Officer, stock, live'
  },
  {
    Id: 1936,
    Code: '2112',
    Name: 'Officer, technical, government: DEFRA'
  },
  {
    Id: 1937,
    Code: '2112',
    Name: 'Officer, trials, field'
  },
  {
    Id: 1938,
    Code: '2112',
    Name: 'Ornithologist'
  },
  {
    Id: 1939,
    Code: '2112',
    Name: 'Parasitologist'
  },
  {
    Id: 1940,
    Code: '2112',
    Name: 'Pathologist, plant'
  },
  {
    Id: 1941,
    Code: '2112',
    Name: 'Pathologist, veterinary'
  },
  {
    Id: 1942,
    Code: '2112',
    Name: 'Pathologist'
  },
  {
    Id: 1943,
    Code: '2112',
    Name: 'Pharmacologist'
  },
  {
    Id: 1944,
    Code: '2112',
    Name: 'Researcher, agricultural'
  },
  {
    Id: 1945,
    Code: '2112',
    Name: 'Researcher, biological'
  },
  {
    Id: 1946,
    Code: '2112',
    Name: 'Researcher, botanical'
  },
  {
    Id: 1947,
    Code: '2112',
    Name: 'Researcher, horticultural'
  },
  {
    Id: 1948,
    Code: '2112',
    Name: 'Researcher, zoological'
  },
  {
    Id: 1949,
    Code: '2112',
    Name: 'Scientist, agricultural'
  },
  {
    Id: 1950,
    Code: '2112',
    Name: 'Scientist, animal'
  },
  {
    Id: 1951,
    Code: '2112',
    Name: 'Scientist, aquatic'
  },
  {
    Id: 1952,
    Code: '2112',
    Name: 'Scientist, audiological'
  },
  {
    Id: 1953,
    Code: '2112',
    Name: 'Scientist, forensic'
  },
  {
    Id: 1954,
    Code: '2112',
    Name: 'Scientist, horticultural'
  },
  {
    Id: 1955,
    Code: '2112',
    Name: 'Scientist, marine'
  },
  {
    Id: 1956,
    Code: '2112',
    Name: 'Scientist, research, fisheries'
  },
  {
    Id: 1957,
    Code: '2112',
    Name: 'Scientist, research, agricultural'
  },
  {
    Id: 1958,
    Code: '2112',
    Name: 'Scientist, research, biological'
  },
  {
    Id: 1959,
    Code: '2112',
    Name: 'Scientist, research, botanical'
  },
  {
    Id: 1960,
    Code: '2112',
    Name: 'Scientist, research, horticultural'
  },
  {
    Id: 1961,
    Code: '2112',
    Name: 'Scientist, research, zoological'
  },
  {
    Id: 1962,
    Code: '2112',
    Name: 'Scientist, agricultural'
  },
  {
    Id: 1963,
    Code: '2112',
    Name: 'Scientist, biological'
  },
  {
    Id: 1964,
    Code: '2112',
    Name: 'Scientist, botanical'
  },
  {
    Id: 1965,
    Code: '2112',
    Name: 'Scientist, horticultural'
  },
  {
    Id: 1966,
    Code: '2112',
    Name: 'Scientist, zoological'
  },
  {
    Id: 1967,
    Code: '2112',
    Name: 'Serologist, veterinary'
  },
  {
    Id: 1968,
    Code: '2112',
    Name: 'Silviculturist, professionally qualified'
  },
  {
    Id: 1969,
    Code: '2112',
    Name: 'Surveyor, field, biological'
  },
  {
    Id: 1970,
    Code: '2112',
    Name: 'Systematist'
  },
  {
    Id: 1971,
    Code: '2112',
    Name: 'Taxonomist'
  },
  {
    Id: 1972,
    Code: '2112',
    Name: 'Toxicologist'
  },
  {
    Id: 1973,
    Code: '2112',
    Name: 'Worker, research, agricultural'
  },
  {
    Id: 1974,
    Code: '2112',
    Name: 'Worker, research, biological'
  },
  {
    Id: 1975,
    Code: '2112',
    Name: 'Worker, research, botanical'
  },
  {
    Id: 1976,
    Code: '2112',
    Name: 'Worker, research, horticultural'
  },
  {
    Id: 1977,
    Code: '2112',
    Name: 'Worker, research, zoological'
  },
  {
    Id: 1978,
    Code: '2112',
    Name: 'Zoologist'
  },
  {
    Id: 1979,
    Code: '2113',
    Name: 'Analyst, DNA'
  },
  {
    Id: 1980,
    Code: '2113',
    Name: 'Assistant, research, clinical'
  },
  {
    Id: 1981,
    Code: '2113',
    Name: 'Assistant, research, biochemical'
  },
  {
    Id: 1982,
    Code: '2113',
    Name: 'Assistant, research, medical'
  },
  {
    Id: 1983,
    Code: '2113',
    Name: 'Associate, research, clinical'
  },
  {
    Id: 1984,
    Code: '2113',
    Name: 'Associate, research, biochemical'
  },
  {
    Id: 1985,
    Code: '2113',
    Name: 'Associate, research, medical'
  },
  {
    Id: 1986,
    Code: '2113',
    Name: 'Biochemist'
  },
  {
    Id: 1987,
    Code: '2113',
    Name: 'Biotechnologist'
  },
  {
    Id: 1988,
    Code: '2113',
    Name: 'Chemist, biological'
  },
  {
    Id: 1989,
    Code: '2113',
    Name: 'Chemist, forensic'
  },
  {
    Id: 1990,
    Code: '2113',
    Name: 'Consultant, pharmaceutical'
  },
  {
    Id: 1991,
    Code: '2113',
    Name: 'Consultant, pharmaceutical'
  },
  {
    Id: 1992,
    Code: '2113',
    Name: 'Cytogeneticist'
  },
  {
    Id: 1993,
    Code: '2113',
    Name: 'Cytologist'
  },
  {
    Id: 1994,
    Code: '2113',
    Name: 'Embryologist'
  },
  {
    Id: 1995,
    Code: '2113',
    Name: 'Endocrinologist'
  },
  {
    Id: 1996,
    Code: '2113',
    Name: 'Engineer, genetic'
  },
  {
    Id: 1997,
    Code: '2113',
    Name: 'Engineer, professional, genetics'
  },
  {
    Id: 1998,
    Code: '2113',
    Name: 'Examiner, forensic'
  },
  {
    Id: 1999,
    Code: '2113',
    Name: 'Geneticist'
  },
  {
    Id: 2000,
    Code: '2113',
    Name: 'Histologist'
  },
  {
    Id: 2001,
    Code: '2113',
    Name: 'Histopathologist, consultant'
  },
  {
    Id: 2002,
    Code: '2113',
    Name: 'Histopathologist'
  },
  {
    Id: 2003,
    Code: '2113',
    Name: 'MLSO'
  },
  {
    Id: 2004,
    Code: '2113',
    Name: 'Monitor, trials, clinical'
  },
  {
    Id: 2005,
    Code: '2113',
    Name: 'Officer, research, biochemical'
  },
  {
    Id: 2006,
    Code: '2113',
    Name: 'Officer, science, laboratory, medical'
  },
  {
    Id: 2007,
    Code: '2113',
    Name: 'Officer, scientific, laboratory, medical'
  },
  {
    Id: 2008,
    Code: '2113',
    Name: 'Officer, scientific, biochemical'
  },
  {
    Id: 2009,
    Code: '2113',
    Name: 'Officer, scientific, medical'
  },
  {
    Id: 2010,
    Code: '2113',
    Name: 'Osteologist'
  },
  {
    Id: 2011,
    Code: '2113',
    Name: 'Researcher, medical'
  },
  {
    Id: 2012,
    Code: '2113',
    Name: 'Researcher, biochemical'
  },
  {
    Id: 2013,
    Code: '2113',
    Name: 'Researcher, medical'
  },
  {
    Id: 2014,
    Code: '2113',
    Name: 'Scientist, biomedical, advanced'
  },
  {
    Id: 2015,
    Code: '2113',
    Name: 'Scientist, biomedical, specialist'
  },
  {
    Id: 2016,
    Code: '2113',
    Name: 'Scientist, biomedical'
  },
  {
    Id: 2017,
    Code: '2113',
    Name: 'Scientist, clinical'
  },
  {
    Id: 2018,
    Code: '2113',
    Name: 'Scientist, laboratory, medical'
  },
  {
    Id: 2019,
    Code: '2113',
    Name: 'Scientist, laboratory, medical'
  },
  {
    Id: 2020,
    Code: '2113',
    Name: 'Scientist, research, biochemical'
  },
  {
    Id: 2021,
    Code: '2113',
    Name: 'Scientist, research, medical'
  },
  {
    Id: 2022,
    Code: '2113',
    Name: 'Scientist, biochemical'
  },
  {
    Id: 2023,
    Code: '2113',
    Name: 'Scientist, biomedical'
  },
  {
    Id: 2024,
    Code: '2113',
    Name: 'Scientist, medical'
  },
  {
    Id: 2025,
    Code: '2113',
    Name: 'Technologist, biomedical'
  },
  {
    Id: 2026,
    Code: '2113',
    Name: 'Technologist, clinical'
  },
  {
    Id: 2027,
    Code: '2113',
    Name: 'Technologist, genetic'
  },
  {
    Id: 2028,
    Code: '2113',
    Name: 'Technologist, medical'
  },
  {
    Id: 2029,
    Code: '2113',
    Name: 'Virologist'
  },
  {
    Id: 2030,
    Code: '2113',
    Name: 'Worker, research, biochemical'
  },
  {
    Id: 2031,
    Code: '2113',
    Name: 'Worker, research, medical'
  },
  {
    Id: 2032,
    Code: '2114',
    Name: 'Adviser, geological'
  },
  {
    Id: 2033,
    Code: '2114',
    Name: 'Adviser, protection, radiation'
  },
  {
    Id: 2034,
    Code: '2114',
    Name: 'Aerodynamicist'
  },
  {
    Id: 2035,
    Code: '2114',
    Name: 'Assistant, meteorological'
  },
  {
    Id: 2036,
    Code: '2114',
    Name: 'Assistant, research, geological'
  },
  {
    Id: 2037,
    Code: '2114',
    Name: 'Assistant, research, meteorological'
  },
  {
    Id: 2038,
    Code: '2114',
    Name: 'Assistant, research, physical science'
  },
  {
    Id: 2039,
    Code: '2114',
    Name: 'Associate, research, geological'
  },
  {
    Id: 2040,
    Code: '2114',
    Name: 'Associate, research, meteorological'
  },
  {
    Id: 2041,
    Code: '2114',
    Name: 'Associate, research, mining'
  },
  {
    Id: 2042,
    Code: '2114',
    Name: 'Associate, research, physical science'
  },
  {
    Id: 2043,
    Code: '2114',
    Name: 'Astronomer'
  },
  {
    Id: 2044,
    Code: '2114',
    Name: 'Astrophysicist'
  },
  {
    Id: 2045,
    Code: '2114',
    Name: 'Ballistician'
  },
  {
    Id: 2046,
    Code: '2114',
    Name: 'Biophysicist'
  },
  {
    Id: 2047,
    Code: '2114',
    Name: 'Consultant, geological'
  },
  {
    Id: 2048,
    Code: '2114',
    Name: 'Consultant, geophysical'
  },
  {
    Id: 2049,
    Code: '2114',
    Name: 'Crystallographer'
  },
  {
    Id: 2050,
    Code: '2114',
    Name: 'Director of research, physical science'
  },
  {
    Id: 2051,
    Code: '2114',
    Name: 'Engineer, geophysical'
  },
  {
    Id: 2052,
    Code: '2114',
    Name: 'Engineer, professional, geophysics'
  },
  {
    Id: 2053,
    Code: '2114',
    Name: 'Examiner, gas, Dept for Business, Energy and Industrial Strategy'
  },
  {
    Id: 2054,
    Code: '2114',
    Name: 'Forecaster, meteorological'
  },
  {
    Id: 2055,
    Code: '2114',
    Name: 'Gemmologist'
  },
  {
    Id: 2056,
    Code: '2114',
    Name: 'Geochemist'
  },
  {
    Id: 2057,
    Code: '2114',
    Name: 'Geohydrologist'
  },
  {
    Id: 2058,
    Code: '2114',
    Name: 'Geologist, engineering'
  },
  {
    Id: 2059,
    Code: '2114',
    Name: 'Geologist'
  },
  {
    Id: 2060,
    Code: '2114',
    Name: 'Geomorphologist'
  },
  {
    Id: 2061,
    Code: '2114',
    Name: 'Geophysicist'
  },
  {
    Id: 2062,
    Code: '2114',
    Name: 'Geoscientist'
  },
  {
    Id: 2063,
    Code: '2114',
    Name: 'Geotechnologist'
  },
  {
    Id: 2064,
    Code: '2114',
    Name: 'Hydrogeologist'
  },
  {
    Id: 2065,
    Code: '2114',
    Name: 'Hydrologist'
  },
  {
    Id: 2066,
    Code: '2114',
    Name: 'Hydrometrist'
  },
  {
    Id: 2067,
    Code: '2114',
    Name: 'Interpreter, seismic'
  },
  {
    Id: 2068,
    Code: '2114',
    Name: 'Logger, mud'
  },
  {
    Id: 2069,
    Code: '2114',
    Name: 'Mapper, geological'
  },
  {
    Id: 2070,
    Code: '2114',
    Name: 'Meteorologist'
  },
  {
    Id: 2071,
    Code: '2114',
    Name: 'Mineralogist'
  },
  {
    Id: 2072,
    Code: '2114',
    Name: 'Navigator, seismic'
  },
  {
    Id: 2073,
    Code: '2114',
    Name: 'Oceanographer'
  },
  {
    Id: 2074,
    Code: '2114',
    Name: 'Officer, meteorological'
  },
  {
    Id: 2075,
    Code: '2114',
    Name: 'Officer, research, geological'
  },
  {
    Id: 2076,
    Code: '2114',
    Name: 'Officer, research, meteorological'
  },
  {
    Id: 2077,
    Code: '2114',
    Name: 'Officer, research, physical science'
  },
  {
    Id: 2078,
    Code: '2114',
    Name: 'Officer, scientific, geological'
  },
  {
    Id: 2079,
    Code: '2114',
    Name: 'Officer, scientific, meteorological'
  },
  {
    Id: 2080,
    Code: '2114',
    Name: 'Officer, scientific, physical science'
  },
  {
    Id: 2081,
    Code: '2114',
    Name: 'Palaeontologist'
  },
  {
    Id: 2082,
    Code: '2114',
    Name: 'Palynologist'
  },
  {
    Id: 2083,
    Code: '2114',
    Name: 'Petrologist'
  },
  {
    Id: 2084,
    Code: '2114',
    Name: 'Petrophysicist'
  },
  {
    Id: 2085,
    Code: '2114',
    Name: 'Physicist'
  },
  {
    Id: 2086,
    Code: '2114',
    Name: 'Practitioner, protection, radiation'
  },
  {
    Id: 2087,
    Code: '2114',
    Name: 'Processor, data, geophysical'
  },
  {
    Id: 2088,
    Code: '2114',
    Name: 'Reducer, data, geophysical'
  },
  {
    Id: 2089,
    Code: '2114',
    Name: 'Researcher, geological'
  },
  {
    Id: 2090,
    Code: '2114',
    Name: 'Researcher, meteorological'
  },
  {
    Id: 2091,
    Code: '2114',
    Name: 'Researcher, physical science'
  },
  {
    Id: 2092,
    Code: '2114',
    Name: 'Rheologist'
  },
  {
    Id: 2093,
    Code: '2114',
    Name: 'Scientist, research, geological'
  },
  {
    Id: 2094,
    Code: '2114',
    Name: 'Scientist, research, meteorological'
  },
  {
    Id: 2095,
    Code: '2114',
    Name: 'Scientist, research, physical science'
  },
  {
    Id: 2096,
    Code: '2114',
    Name: 'Scientist, geological'
  },
  {
    Id: 2097,
    Code: '2114',
    Name: 'Scientist, meteorological'
  },
  {
    Id: 2098,
    Code: '2114',
    Name: 'Scientist, physical science'
  },
  {
    Id: 2099,
    Code: '2114',
    Name: 'Seismologist'
  },
  {
    Id: 2100,
    Code: '2114',
    Name: 'Spectroscopist'
  },
  {
    Id: 2101,
    Code: '2114',
    Name: 'Stratigrapher'
  },
  {
    Id: 2102,
    Code: '2114',
    Name: 'Superintendent, National Physical Laboratory'
  },
  {
    Id: 2103,
    Code: '2114',
    Name: 'Thermodynamicist'
  },
  {
    Id: 2104,
    Code: '2114',
    Name: 'Worker, research, geological'
  },
  {
    Id: 2105,
    Code: '2114',
    Name: 'Worker, research, meteorological'
  },
  {
    Id: 2106,
    Code: '2114',
    Name: 'Worker, research, mining'
  },
  {
    Id: 2107,
    Code: '2114',
    Name: 'Worker, research, physical science'
  },
  {
    Id: 2108,
    Code: '2115',
    Name: 'Analyst, geospatial, senior'
  },
  {
    Id: 2109,
    Code: '2115',
    Name: 'Analyst, GIS, senior'
  },
  {
    Id: 2110,
    Code: '2115',
    Name: 'Analyst, health, public'
  },
  {
    Id: 2111,
    Code: '2115',
    Name: 'Analyst, systems, information, geographical, senior'
  },
  {
    Id: 2112,
    Code: '2115',
    Name: 'Anthropologist'
  },
  {
    Id: 2113,
    Code: '2115',
    Name: 'Antiquary'
  },
  {
    Id: 2114,
    Code: '2115',
    Name: 'Archaeologist'
  },
  {
    Id: 2115,
    Code: '2115',
    Name: 'Assistant, research, historical'
  },
  {
    Id: 2116,
    Code: '2115',
    Name: 'Assistant, research, government'
  },
  {
    Id: 2117,
    Code: '2115',
    Name: 'Assistant, site, archaeologist'
  },
  {
    Id: 2118,
    Code: '2115',
    Name: 'Associate, research, historical'
  },
  {
    Id: 2119,
    Code: '2115',
    Name: 'Associate, research, government'
  },
  {
    Id: 2120,
    Code: '2115',
    Name: 'Caseworker, parliamentary'
  },
  {
    Id: 2121,
    Code: '2115',
    Name: 'Consultant, GIS'
  },
  {
    Id: 2122,
    Code: '2115',
    Name: 'Criminologist'
  },
  {
    Id: 2123,
    Code: '2115',
    Name: 'Egyptologist'
  },
  {
    Id: 2124,
    Code: '2115',
    Name: 'Epidemiologist'
  },
  {
    Id: 2125,
    Code: '2115',
    Name: 'Ethnologist'
  },
  {
    Id: 2126,
    Code: '2115',
    Name: 'Ethnomusicologist'
  },
  {
    Id: 2127,
    Code: '2115',
    Name: 'Etymologist'
  },
  {
    Id: 2128,
    Code: '2115',
    Name: 'Examiner, polygraph'
  },
  {
    Id: 2129,
    Code: '2115',
    Name: 'Fellow, research, university, social sciences'
  },
  {
    Id: 2130,
    Code: '2115',
    Name: 'Genealogist'
  },
  {
    Id: 2131,
    Code: '2115',
    Name: 'Geographer'
  },
  {
    Id: 2132,
    Code: '2115',
    Name: 'Historian'
  },
  {
    Id: 2133,
    Code: '2115',
    Name: 'Manager, GIS'
  },
  {
    Id: 2134,
    Code: '2115',
    Name: 'Officer, archaeological'
  },
  {
    Id: 2135,
    Code: '2115',
    Name: 'Officer, GIS'
  },
  {
    Id: 2136,
    Code: '2115',
    Name: 'Officer, research, political'
  },
  {
    Id: 2137,
    Code: '2115',
    Name: 'Officer, research, government'
  },
  {
    Id: 2138,
    Code: '2115',
    Name: 'Officer, research, historical'
  },
  {
    Id: 2139,
    Code: '2115',
    Name: 'Officer, survey, social, assistant, government'
  },
  {
    Id: 2140,
    Code: '2115',
    Name: 'Officer, survey, social, principal, government'
  },
  {
    Id: 2141,
    Code: '2115',
    Name: 'Officer, survey, social, senior, government'
  },
  {
    Id: 2142,
    Code: '2115',
    Name: 'Officer, survey, social, government'
  },
  {
    Id: 2143,
    Code: '2115',
    Name: 'Officer, systems, information, geographical'
  },
  {
    Id: 2144,
    Code: '2115',
    Name: 'Palaeographist'
  },
  {
    Id: 2145,
    Code: '2115',
    Name: 'Philologist'
  },
  {
    Id: 2146,
    Code: '2115',
    Name: 'Philosopher'
  },
  {
    Id: 2147,
    Code: '2115',
    Name: 'Researcher, historical'
  },
  {
    Id: 2148,
    Code: '2115',
    Name: 'Researcher, parliamentary'
  },
  {
    Id: 2149,
    Code: '2115',
    Name: 'Researcher, policy'
  },
  {
    Id: 2150,
    Code: '2115',
    Name: 'Researcher, political'
  },
  {
    Id: 2151,
    Code: '2115',
    Name: 'Researcher, social'
  },
  {
    Id: 2152,
    Code: '2115',
    Name: 'Researcher, historical'
  },
  {
    Id: 2153,
    Code: '2115',
    Name: 'Researcher, government'
  },
  {
    Id: 2154,
    Code: '2115',
    Name: 'Scientist, behavioural'
  },
  {
    Id: 2155,
    Code: '2115',
    Name: 'Scientist, political'
  },
  {
    Id: 2156,
    Code: '2115',
    Name: 'Scientist, social'
  },
  {
    Id: 2157,
    Code: '2115',
    Name: 'Sociologist'
  },
  {
    Id: 2158,
    Code: '2115',
    Name: 'Specialist, health, public'
  },
  {
    Id: 2159,
    Code: '2115',
    Name: 'Surveyor, archaeological'
  },
  {
    Id: 2160,
    Code: '2115',
    Name: 'Toponymist'
  },
  {
    Id: 2161,
    Code: '2115',
    Name: 'Worker, case, parliamentary'
  },
  {
    Id: 2162,
    Code: '2115',
    Name: 'Worker, research, historical'
  },
  {
    Id: 2163,
    Code: '2119',
    Name: 'Adviser, scientific'
  },
  {
    Id: 2164,
    Code: '2119',
    Name: 'Assistant, research, scientific'
  },
  {
    Id: 2165,
    Code: '2119',
    Name: 'Associate, research, scientific'
  },
  {
    Id: 2166,
    Code: '2119',
    Name: 'Biomechanist'
  },
  {
    Id: 2167,
    Code: '2119',
    Name: 'Coach, strength and conditioning'
  },
  {
    Id: 2168,
    Code: '2119',
    Name: 'Fellow, research, university, sciences'
  },
  {
    Id: 2169,
    Code: '2119',
    Name: 'Officer, experimental, chief'
  },
  {
    Id: 2170,
    Code: '2119',
    Name: 'Officer, experimental, senior'
  },
  {
    Id: 2171,
    Code: '2119',
    Name: 'Officer, experimental'
  },
  {
    Id: 2172,
    Code: '2119',
    Name: 'Officer, scientific'
  },
  {
    Id: 2173,
    Code: '2119',
    Name: 'Officer, technical, laboratory'
  },
  {
    Id: 2174,
    Code: '2119',
    Name: 'Researcher, scientific'
  },
  {
    Id: 2175,
    Code: '2119',
    Name: 'Researcher, scientific'
  },
  {
    Id: 2176,
    Code: '2119',
    Name: 'Scientist, consumer'
  },
  {
    Id: 2177,
    Code: '2119',
    Name: 'Scientist, laboratory'
  },
  {
    Id: 2178,
    Code: '2119',
    Name: 'Scientist, research, operational'
  },
  {
    Id: 2179,
    Code: '2119',
    Name: 'Scientist, research'
  },
  {
    Id: 2180,
    Code: '2119',
    Name: 'Scientist, sports'
  },
  {
    Id: 2181,
    Code: '2119',
    Name: 'Scientist, sports and exercise'
  },
  {
    Id: 2182,
    Code: '2119',
    Name: 'Scientist'
  },
  {
    Id: 2183,
    Code: '2121',
    Name: 'Consultant, engineering, civil'
  },
  {
    Id: 2184,
    Code: '2121',
    Name: 'Consultant, mining'
  },
  {
    Id: 2185,
    Code: '2121',
    Name: 'Consultant, civil engineering'
  },
  {
    Id: 2186,
    Code: '2121',
    Name: 'Engineer, aquaculture'
  },
  {
    Id: 2187,
    Code: '2121',
    Name: 'Engineer, assistant, coal mine'
  },
  {
    Id: 2188,
    Code: '2121',
    Name: 'Engineer, assistant, local government'
  },
  {
    Id: 2189,
    Code: '2121',
    Name: 'Engineer, assurance, flow'
  },
  {
    Id: 2190,
    Code: '2121',
    Name: 'Engineer, boring, professional'
  },
  {
    Id: 2191,
    Code: '2121',
    Name: 'Engineer, borough'
  },
  {
    Id: 2192,
    Code: '2121',
    Name: 'Engineer, building'
  },
  {
    Id: 2193,
    Code: '2121',
    Name: 'Engineer, building and civil'
  },
  {
    Id: 2194,
    Code: '2121',
    Name: 'Engineer, city'
  },
  {
    Id: 2195,
    Code: '2121',
    Name: 'Engineer, civil, professional'
  },
  {
    Id: 2196,
    Code: '2121',
    Name: 'Engineer, colliery'
  },
  {
    Id: 2197,
    Code: '2121',
    Name: 'Engineer, construction, professional'
  },
  {
    Id: 2198,
    Code: '2121',
    Name: 'Engineer, constructional, professional'
  },
  {
    Id: 2199,
    Code: '2121',
    Name: "Engineer, contractor's"
  },
  {
    Id: 2200,
    Code: '2121',
    Name: 'Engineer, control, strata'
  },
  {
    Id: 2201,
    Code: '2121',
    Name: 'Engineer, county'
  },
  {
    Id: 2202,
    Code: '2121',
    Name: 'Engineer, defence, flood'
  },
  {
    Id: 2203,
    Code: '2121',
    Name: 'Engineer, demolition, professional'
  },
  {
    Id: 2204,
    Code: '2121',
    Name: 'Engineer, design, highways'
  },
  {
    Id: 2205,
    Code: '2121',
    Name: 'Engineer, district, railways'
  },
  {
    Id: 2206,
    Code: '2121',
    Name: 'Engineer, dock'
  },
  {
    Id: 2207,
    Code: '2121',
    Name: 'Engineer, docks'
  },
  {
    Id: 2208,
    Code: '2121',
    Name: 'Engineer, drainage, professional'
  },
  {
    Id: 2209,
    Code: '2121',
    Name: 'Engineer, dredger, professional'
  },
  {
    Id: 2210,
    Code: '2121',
    Name: 'Engineer, drilling, mining'
  },
  {
    Id: 2211,
    Code: '2121',
    Name: 'Engineer, field, oil wells'
  },
  {
    Id: 2212,
    Code: '2121',
    Name: 'Engineer, fluids, drilling'
  },
  {
    Id: 2213,
    Code: '2121',
    Name: 'Engineer, gas, natural'
  },
  {
    Id: 2214,
    Code: '2121',
    Name: 'Engineer, geotechnical'
  },
  {
    Id: 2215,
    Code: '2121',
    Name: 'Engineer, health, public'
  },
  {
    Id: 2216,
    Code: '2121',
    Name: 'Engineer, highway'
  },
  {
    Id: 2217,
    Code: '2121',
    Name: 'Engineer, highways'
  },
  {
    Id: 2218,
    Code: '2121',
    Name: 'Engineer, incorporated, civil engineering'
  },
  {
    Id: 2219,
    Code: '2121',
    Name: 'Engineer, irrigation, professional'
  },
  {
    Id: 2220,
    Code: '2121',
    Name: 'Engineer, maintenance, highways'
  },
  {
    Id: 2221,
    Code: '2121',
    Name: 'Engineer, maintenance, local government: highways dept'
  },
  {
    Id: 2222,
    Code: '2121',
    Name: 'Engineer, mining'
  },
  {
    Id: 2223,
    Code: '2121',
    Name: 'Engineer, mud, oil wells'
  },
  {
    Id: 2224,
    Code: '2121',
    Name: 'Engineer, municipal'
  },
  {
    Id: 2225,
    Code: '2121',
    Name: 'Engineer, MWD'
  },
  {
    Id: 2226,
    Code: '2121',
    Name: 'Engineer, offshore'
  },
  {
    Id: 2227,
    Code: '2121',
    Name: 'Engineer, oil'
  },
  {
    Id: 2228,
    Code: '2121',
    Name: 'Engineer, oil and natural gas'
  },
  {
    Id: 2229,
    Code: '2121',
    Name: 'Engineer, petroleum'
  },
  {
    Id: 2230,
    Code: '2121',
    Name: 'Engineer, pipeline'
  },
  {
    Id: 2231,
    Code: '2121',
    Name: 'Engineer, quarrying'
  },
  {
    Id: 2232,
    Code: '2121',
    Name: 'Engineer, reservoir'
  },
  {
    Id: 2233,
    Code: '2121',
    Name: 'Engineer, resident'
  },
  {
    Id: 2234,
    Code: '2121',
    Name: 'Engineer, risk, flood, development'
  },
  {
    Id: 2235,
    Code: '2121',
    Name: 'Engineer, risk, flood'
  },
  {
    Id: 2236,
    Code: '2121',
    Name: 'Engineer, road'
  },
  {
    Id: 2237,
    Code: '2121',
    Name: 'Engineer, sanitary, professional'
  },
  {
    Id: 2238,
    Code: '2121',
    Name: 'Engineer, services, building'
  },
  {
    Id: 2239,
    Code: '2121',
    Name: 'Engineer, site, building construction'
  },
  {
    Id: 2240,
    Code: '2121',
    Name: 'Engineer, station, MOD'
  },
  {
    Id: 2241,
    Code: '2121',
    Name: 'Engineer, structural'
  },
  {
    Id: 2242,
    Code: '2121',
    Name: 'Engineer, subsidence'
  },
  {
    Id: 2243,
    Code: '2121',
    Name: 'Engineer, sustainability, building construction'
  },
  {
    Id: 2244,
    Code: '2121',
    Name: 'Engineer, transportation, professional'
  },
  {
    Id: 2245,
    Code: '2121',
    Name: 'Engineer, treatment, water, professional'
  },
  {
    Id: 2246,
    Code: '2121',
    Name: 'Engineer, tunnelling'
  },
  {
    Id: 2247,
    Code: '2121',
    Name: 'Engineer, utilities, professional'
  },
  {
    Id: 2248,
    Code: '2121',
    Name: 'Engineer, water, professional'
  },
  {
    Id: 2249,
    Code: '2121',
    Name: 'Engineer, well'
  },
  {
    Id: 2250,
    Code: '2121',
    Name: 'Engineer, works, public'
  },
  {
    Id: 2251,
    Code: '2121',
    Name: 'Engineer, professional, civil'
  },
  {
    Id: 2252,
    Code: '2121',
    Name: 'Engineer, professional, constructional'
  },
  {
    Id: 2253,
    Code: '2121',
    Name: 'Engineer, professional, highway'
  },
  {
    Id: 2254,
    Code: '2121',
    Name: 'Engineer, professional, highways'
  },
  {
    Id: 2255,
    Code: '2121',
    Name: 'Engineer, professional, irrigation'
  },
  {
    Id: 2256,
    Code: '2121',
    Name: 'Engineer, professional, mining'
  },
  {
    Id: 2257,
    Code: '2121',
    Name: 'Engineer, professional, municipal'
  },
  {
    Id: 2258,
    Code: '2121',
    Name: 'Engineer, professional, oil and natural gas'
  },
  {
    Id: 2259,
    Code: '2121',
    Name: 'Engineer, professional, public health'
  },
  {
    Id: 2260,
    Code: '2121',
    Name: 'Engineer, professional, public works'
  },
  {
    Id: 2261,
    Code: '2121',
    Name: 'Engineer, professional, quarrying'
  },
  {
    Id: 2262,
    Code: '2121',
    Name: 'Engineer, professional, sanitary'
  },
  {
    Id: 2263,
    Code: '2121',
    Name: 'Engineer, professional, structural'
  },
  {
    Id: 2264,
    Code: '2121',
    Name: 'Engineer, professional, water'
  },
  {
    Id: 2265,
    Code: '2121',
    Name: 'Engineer and architect'
  },
  {
    Id: 2266,
    Code: '2121',
    Name: 'Inspector, civils, local government: highways dept'
  },
  {
    Id: 2267,
    Code: '2121',
    Name: 'Inspector, engineering, DfT'
  },
  {
    Id: 2268,
    Code: '2121',
    Name: 'Inspector, highways, NRSWA, local government'
  },
  {
    Id: 2269,
    Code: '2121',
    Name: 'Inspector, network, highway, senior, local government'
  },
  {
    Id: 2270,
    Code: '2121',
    Name: 'Modeller, hydraulic'
  },
  {
    Id: 2271,
    Code: '2121',
    Name: 'Officer, works'
  },
  {
    Id: 2272,
    Code: '2121',
    Name: 'Surveyor, borough'
  },
  {
    Id: 2273,
    Code: '2121',
    Name: 'Surveyor, city'
  },
  {
    Id: 2274,
    Code: '2121',
    Name: 'Surveyor, county'
  },
  {
    Id: 2275,
    Code: '2121',
    Name: 'Surveyor, district'
  },
  {
    Id: 2276,
    Code: '2121',
    Name: 'Surveyor, divisional, county'
  },
  {
    Id: 2277,
    Code: '2122',
    Name: 'Architect, naval'
  },
  {
    Id: 2278,
    Code: '2122',
    Name: 'Assistant, research, engineering, mechanical'
  },
  {
    Id: 2279,
    Code: '2122',
    Name: 'Associate, research, engineering, mechanical'
  },
  {
    Id: 2280,
    Code: '2122',
    Name: 'Constructor, naval'
  },
  {
    Id: 2281,
    Code: '2122',
    Name: 'Consultant, engineering'
  },
  {
    Id: 2282,
    Code: '2122',
    Name: 'Designer, machinery, electrical'
  },
  {
    Id: 2283,
    Code: '2122',
    Name: 'Designer, mechanical, professional'
  },
  {
    Id: 2284,
    Code: '2122',
    Name: 'Designer, nautical'
  },
  {
    Id: 2285,
    Code: '2122',
    Name: 'Designer, naval'
  },
  {
    Id: 2286,
    Code: '2122',
    Name: 'Designer, mechanical'
  },
  {
    Id: 2287,
    Code: '2122',
    Name: 'Designer, metal trades'
  },
  {
    Id: 2288,
    Code: '2122',
    Name: 'Engineer, automobile, professional'
  },
  {
    Id: 2289,
    Code: '2122',
    Name: 'Engineer, automotive, professional'
  },
  {
    Id: 2290,
    Code: '2122',
    Name: 'Engineer, chassis'
  },
  {
    Id: 2291,
    Code: '2122',
    Name: 'Engineer, chief, area, coal mine'
  },
  {
    Id: 2292,
    Code: '2122',
    Name: 'Engineer, chief, maintenance'
  },
  {
    Id: 2293,
    Code: '2122',
    Name: 'Engineer, design, automotive'
  },
  {
    Id: 2294,
    Code: '2122',
    Name: 'Engineer, design, mechanical'
  },
  {
    Id: 2295,
    Code: '2122',
    Name: 'Engineer, design, tool'
  },
  {
    Id: 2296,
    Code: '2122',
    Name: 'Engineer, design, mechanical'
  },
  {
    Id: 2297,
    Code: '2122',
    Name: 'Engineer, development, mechanical'
  },
  {
    Id: 2298,
    Code: '2122',
    Name: 'Engineer, diesel, professional'
  },
  {
    Id: 2299,
    Code: '2122',
    Name: 'Engineer, heavy, professional'
  },
  {
    Id: 2300,
    Code: '2122',
    Name: 'Engineer, homologation, automotive'
  },
  {
    Id: 2301,
    Code: '2122',
    Name: 'Engineer, homologation, vehicle trades'
  },
  {
    Id: 2302,
    Code: '2122',
    Name: 'Engineer, hydraulic, professional'
  },
  {
    Id: 2303,
    Code: '2122',
    Name: 'Engineer, incorporated, mechanical engineering'
  },
  {
    Id: 2304,
    Code: '2122',
    Name: 'Engineer, locomotive, professional'
  },
  {
    Id: 2305,
    Code: '2122',
    Name: 'Engineer, marine, chief'
  },
  {
    Id: 2306,
    Code: '2122',
    Name: 'Engineer, marine, senior'
  },
  {
    Id: 2307,
    Code: '2122',
    Name: 'Engineer, marine, superintendent'
  },
  {
    Id: 2308,
    Code: '2122',
    Name: 'Engineer, marine, professional'
  },
  {
    Id: 2309,
    Code: '2122',
    Name: 'Engineer, mechanical, area, coal mine'
  },
  {
    Id: 2310,
    Code: '2122',
    Name: 'Engineer, mechanical, chief'
  },
  {
    Id: 2311,
    Code: '2122',
    Name: 'Engineer, mechanical, group, coal mine'
  },
  {
    Id: 2312,
    Code: '2122',
    Name: 'Engineer, mechanical, unit, coal mine'
  },
  {
    Id: 2313,
    Code: '2122',
    Name: 'Engineer, mechanical, professional'
  },
  {
    Id: 2314,
    Code: '2122',
    Name: 'Engineer, mechanical and electrical, professional'
  },
  {
    Id: 2315,
    Code: '2122',
    Name: 'Engineer, mechanisation'
  },
  {
    Id: 2316,
    Code: '2122',
    Name: 'Engineer, mill, professional'
  },
  {
    Id: 2317,
    Code: '2122',
    Name: 'Engineer, motorsport'
  },
  {
    Id: 2318,
    Code: '2122',
    Name: 'Engineer, operations, electricity supplier'
  },
  {
    Id: 2319,
    Code: '2122',
    Name: 'Engineer, piping, professional'
  },
  {
    Id: 2320,
    Code: '2122',
    Name: 'Engineer, plant, professional'
  },
  {
    Id: 2321,
    Code: '2122',
    Name: 'Engineer, salvage, marine'
  },
  {
    Id: 2322,
    Code: '2122',
    Name: 'Engineer, services, building, mechanical'
  },
  {
    Id: 2323,
    Code: '2122',
    Name: 'Engineer, stress, professional'
  },
  {
    Id: 2324,
    Code: '2122',
    Name: 'Engineer, textile, professional'
  },
  {
    Id: 2325,
    Code: '2122',
    Name: 'Engineer, tool, machine, professional'
  },
  {
    Id: 2326,
    Code: '2122',
    Name: 'Engineer, train, professional'
  },
  {
    Id: 2327,
    Code: '2122',
    Name: 'Engineer, welding, professional'
  },
  {
    Id: 2328,
    Code: '2122',
    Name: 'Engineer, works'
  },
  {
    Id: 2329,
    Code: '2122',
    Name: 'Engineer, professional, automobile'
  },
  {
    Id: 2330,
    Code: '2122',
    Name: 'Engineer, professional, hydraulic'
  },
  {
    Id: 2331,
    Code: '2122',
    Name: 'Engineer, professional, locomotive'
  },
  {
    Id: 2332,
    Code: '2122',
    Name: 'Engineer, professional, marine'
  },
  {
    Id: 2333,
    Code: '2122',
    Name: 'Engineer, professional, mechanical'
  },
  {
    Id: 2334,
    Code: '2122',
    Name: 'Engineer, professional, stress'
  },
  {
    Id: 2335,
    Code: '2122',
    Name: 'Engineer, professional, textile'
  },
  {
    Id: 2336,
    Code: '2122',
    Name: 'Engineer, professional'
  },
  {
    Id: 2337,
    Code: '2122',
    Name: 'Expert, salvage, marine'
  },
  {
    Id: 2338,
    Code: '2122',
    Name: 'Hydrodynamicist'
  },
  {
    Id: 2339,
    Code: '2122',
    Name: 'Inspector, chief, professional, engineering'
  },
  {
    Id: 2340,
    Code: '2122',
    Name: 'Inspector, engineering, chief'
  },
  {
    Id: 2341,
    Code: '2122',
    Name: 'Inspector, engineering, Jobcentre Plus'
  },
  {
    Id: 2342,
    Code: '2122',
    Name: 'Inspector of fighting vehicles'
  },
  {
    Id: 2343,
    Code: '2122',
    Name: 'Integrator, systems, engineering'
  },
  {
    Id: 2344,
    Code: '2122',
    Name: 'Officer, certifying, DfT'
  },
  {
    Id: 2345,
    Code: '2122',
    Name: 'Officer, engineering, government'
  },
  {
    Id: 2346,
    Code: '2122',
    Name: 'Officer, inspection, Dept for Business, Energy and Industrial Strategy'
  },
  {
    Id: 2347,
    Code: '2122',
    Name: 'Officer, research, engineering, mechanical'
  },
  {
    Id: 2348,
    Code: '2122',
    Name: 'Officer, scientific, engineering, mechanical'
  },
  {
    Id: 2349,
    Code: '2122',
    Name: 'Officer, technical, principal'
  },
  {
    Id: 2350,
    Code: '2122',
    Name: 'Officer, transport, mechanical, chief'
  },
  {
    Id: 2351,
    Code: '2122',
    Name: 'Researcher, engineering, mechanical'
  },
  {
    Id: 2352,
    Code: '2123',
    Name: 'Assistant, research, engineering, electrical'
  },
  {
    Id: 2353,
    Code: '2123',
    Name: 'Associate, research, engineering, electrical'
  },
  {
    Id: 2354,
    Code: '2123',
    Name: 'Designer, electrical'
  },
  {
    Id: 2355,
    Code: '2123',
    Name: 'Designer, systems, railway signalling'
  },
  {
    Id: 2356,
    Code: '2123',
    Name: 'Designer, electrical'
  },
  {
    Id: 2357,
    Code: '2123',
    Name: 'Engineer, area, technical'
  },
  {
    Id: 2358,
    Code: '2123',
    Name: 'Engineer, assistant, electricity supplier'
  },
  {
    Id: 2359,
    Code: '2123',
    Name: 'Engineer, branch, electricity supplier'
  },
  {
    Id: 2360,
    Code: '2123',
    Name: 'Engineer, charge, electricity supplier'
  },
  {
    Id: 2361,
    Code: '2123',
    Name: 'Engineer, chief, electricity supplier'
  },
  {
    Id: 2362,
    Code: '2123',
    Name: 'Engineer, control, electricity supplier'
  },
  {
    Id: 2363,
    Code: '2123',
    Name: 'Engineer, design, electrical'
  },
  {
    Id: 2364,
    Code: '2123',
    Name: 'Engineer, design, electrical'
  },
  {
    Id: 2365,
    Code: '2123',
    Name: 'Engineer, distribution, electricity supplier'
  },
  {
    Id: 2366,
    Code: '2123',
    Name: 'Engineer, district, electricity supplier'
  },
  {
    Id: 2367,
    Code: '2123',
    Name: 'Engineer, electrical, area, coal mine'
  },
  {
    Id: 2368,
    Code: '2123',
    Name: 'Engineer, electrical, charge, assistant, electricity supplier'
  },
  {
    Id: 2369,
    Code: '2123',
    Name: 'Engineer, electrical, chief'
  },
  {
    Id: 2370,
    Code: '2123',
    Name: 'Engineer, electrical, group, coal mine'
  },
  {
    Id: 2371,
    Code: '2123',
    Name: 'Engineer, electrical, head'
  },
  {
    Id: 2372,
    Code: '2123',
    Name: 'Engineer, electrical, senior'
  },
  {
    Id: 2373,
    Code: '2123',
    Name: 'Engineer, electrical, works'
  },
  {
    Id: 2374,
    Code: '2123',
    Name: 'Engineer, electrical, professional'
  },
  {
    Id: 2375,
    Code: '2123',
    Name: 'Engineer, electrical and mechanical, professional'
  },
  {
    Id: 2376,
    Code: '2123',
    Name: 'Engineer, electro-mechanical, professional'
  },
  {
    Id: 2377,
    Code: '2123',
    Name: 'Engineer, equipment'
  },
  {
    Id: 2378,
    Code: '2123',
    Name: 'Engineer, field, professional, electricity supplier'
  },
  {
    Id: 2379,
    Code: '2123',
    Name: 'Engineer, first, power station'
  },
  {
    Id: 2380,
    Code: '2123',
    Name: 'Engineer, generating, electricity supplier'
  },
  {
    Id: 2381,
    Code: '2123',
    Name: 'Engineer, illuminating, professional'
  },
  {
    Id: 2382,
    Code: '2123',
    Name: 'Engineer, incorporated, electrical'
  },
  {
    Id: 2383,
    Code: '2123',
    Name: 'Engineer, laser'
  },
  {
    Id: 2384,
    Code: '2123',
    Name: 'Engineer, lighting, professional'
  },
  {
    Id: 2385,
    Code: '2123',
    Name: 'Engineer, mechanical and electrical, electricity supplier'
  },
  {
    Id: 2386,
    Code: '2123',
    Name: 'Engineer, power'
  },
  {
    Id: 2387,
    Code: '2123',
    Name: 'Engineer, resident, electricity supplier'
  },
  {
    Id: 2388,
    Code: '2123',
    Name: 'Engineer, second, electricity supplier'
  },
  {
    Id: 2389,
    Code: '2123',
    Name: 'Engineer, second, power station'
  },
  {
    Id: 2390,
    Code: '2123',
    Name: 'Engineer, shift, electricity supplier'
  },
  {
    Id: 2391,
    Code: '2123',
    Name: 'Engineer, signal, professional, railways'
  },
  {
    Id: 2392,
    Code: '2123',
    Name: 'Engineer, station, power, electricity supplier'
  },
  {
    Id: 2393,
    Code: '2123',
    Name: 'Engineer, sub-area, electricity supplier'
  },
  {
    Id: 2394,
    Code: '2123',
    Name: 'Engineer, sub-station, electricity supplier'
  },
  {
    Id: 2395,
    Code: '2123',
    Name: 'Engineer, switchgear, professional'
  },
  {
    Id: 2396,
    Code: '2123',
    Name: 'Engineer, systems, power'
  },
  {
    Id: 2397,
    Code: '2123',
    Name: 'Engineer, telephone, professional'
  },
  {
    Id: 2398,
    Code: '2123',
    Name: 'Engineer, testing, cable'
  },
  {
    Id: 2399,
    Code: '2123',
    Name: 'Engineer, thermographic, professional'
  },
  {
    Id: 2400,
    Code: '2123',
    Name: 'Engineer, traction, electric'
  },
  {
    Id: 2401,
    Code: '2123',
    Name: 'Engineer, transmission, power'
  },
  {
    Id: 2402,
    Code: '2123',
    Name: 'Engineer, professional, electrical'
  },
  {
    Id: 2403,
    Code: '2123',
    Name: 'Engineer, professional, illuminating'
  },
  {
    Id: 2404,
    Code: '2123',
    Name: 'Engineer, professional, lighting'
  },
  {
    Id: 2405,
    Code: '2123',
    Name: 'Engineer-in-charge, electricity supplier'
  },
  {
    Id: 2406,
    Code: '2123',
    Name: 'Inspector, chief, professional, electrical engineering, engineering'
  },
  {
    Id: 2407,
    Code: '2123',
    Name: 'Officer, research, engineering, electrical'
  },
  {
    Id: 2408,
    Code: '2123',
    Name: 'Officer, scientific, engineering, electrical'
  },
  {
    Id: 2409,
    Code: '2123',
    Name: 'Researcher, engineering, electrical'
  },
  {
    Id: 2410,
    Code: '2123',
    Name: 'Superintendent, electrical, MOD'
  },
  {
    Id: 2411,
    Code: '2123',
    Name: 'Superintendent, meter, electricity supplier'
  },
  {
    Id: 2412,
    Code: '2123',
    Name: 'Surveyor, electrical'
  },
  {
    Id: 2413,
    Code: '2124',
    Name: 'Assistant, research, engineering, electronic'
  },
  {
    Id: 2414,
    Code: '2124',
    Name: 'Associate, research, engineering, electronic'
  },
  {
    Id: 2415,
    Code: '2124',
    Name: 'Consultant, communications, telecoms'
  },
  {
    Id: 2416,
    Code: '2124',
    Name: 'Controller, transmission, television: production, broadcasting'
  },
  {
    Id: 2417,
    Code: '2124',
    Name: 'Designer, circuit, telecoms'
  },
  {
    Id: 2418,
    Code: '2124',
    Name: 'Designer, electronics'
  },
  {
    Id: 2419,
    Code: '2124',
    Name: 'Designer, telecommunications'
  },
  {
    Id: 2420,
    Code: '2124',
    Name: 'Designer, electronic'
  },
  {
    Id: 2421,
    Code: '2124',
    Name: 'Engineer, area, telecoms'
  },
  {
    Id: 2422,
    Code: '2124',
    Name: 'Engineer, broadcast, professional'
  },
  {
    Id: 2423,
    Code: '2124',
    Name: 'Engineer, broadcasting, professional'
  },
  {
    Id: 2424,
    Code: '2124',
    Name: 'Engineer, communication, radio'
  },
  {
    Id: 2425,
    Code: '2124',
    Name: 'Engineer, communication, professional'
  },
  {
    Id: 2426,
    Code: '2124',
    Name: 'Engineer, communications, professional'
  },
  {
    Id: 2427,
    Code: '2124',
    Name: 'Engineer, design, electronic'
  },
  {
    Id: 2428,
    Code: '2124',
    Name: 'Engineer, design, electronics'
  },
  {
    Id: 2429,
    Code: '2124',
    Name: 'Engineer, design, hardware, computer'
  },
  {
    Id: 2430,
    Code: '2124',
    Name: 'Engineer, design, electronic'
  },
  {
    Id: 2431,
    Code: '2124',
    Name: 'Engineer, design, telecoms'
  },
  {
    Id: 2432,
    Code: '2124',
    Name: 'Engineer, electronic, professional'
  },
  {
    Id: 2433,
    Code: '2124',
    Name: 'Engineer, electronics, professional'
  },
  {
    Id: 2434,
    Code: '2124',
    Name: 'Engineer, hardware, computer, professional'
  },
  {
    Id: 2435,
    Code: '2124',
    Name: 'Engineer, microwave, professional'
  },
  {
    Id: 2436,
    Code: '2124',
    Name: 'Engineer, optic, fibre, professional'
  },
  {
    Id: 2437,
    Code: '2124',
    Name: 'Engineer, optimisation, RF'
  },
  {
    Id: 2438,
    Code: '2124',
    Name: 'Engineer, radar, professional, research'
  },
  {
    Id: 2439,
    Code: '2124',
    Name: 'Engineer, radar, professional'
  },
  {
    Id: 2440,
    Code: '2124',
    Name: 'Engineer, radio, professional'
  },
  {
    Id: 2441,
    Code: '2124',
    Name: 'Engineer, regional, telecoms'
  },
  {
    Id: 2442,
    Code: '2124',
    Name: 'Engineer, staff, telecoms'
  },
  {
    Id: 2443,
    Code: '2124',
    Name: 'Engineer, systems, combat'
  },
  {
    Id: 2444,
    Code: '2124',
    Name: 'Engineer, systems, mission'
  },
  {
    Id: 2445,
    Code: '2124',
    Name: 'Engineer, systems, telecoms'
  },
  {
    Id: 2446,
    Code: '2124',
    Name: 'Engineer, telecom, professional'
  },
  {
    Id: 2447,
    Code: '2124',
    Name: 'Engineer, telecommunications, professional'
  },
  {
    Id: 2448,
    Code: '2124',
    Name: 'Engineer, telecoms, professional'
  },
  {
    Id: 2449,
    Code: '2124',
    Name: 'Engineer, television, professional'
  },
  {
    Id: 2450,
    Code: '2124',
    Name: 'Engineer, transmission, power, television'
  },
  {
    Id: 2451,
    Code: '2124',
    Name: 'Engineer, professional, broadcasting'
  },
  {
    Id: 2452,
    Code: '2124',
    Name: 'Engineer, professional, electronic'
  },
  {
    Id: 2453,
    Code: '2124',
    Name: 'Engineer, professional, microwave'
  },
  {
    Id: 2454,
    Code: '2124',
    Name: 'Engineer, professional, radar'
  },
  {
    Id: 2455,
    Code: '2124',
    Name: 'Engineer, professional, radio'
  },
  {
    Id: 2456,
    Code: '2124',
    Name: 'Engineer, professional, telecoms'
  },
  {
    Id: 2457,
    Code: '2124',
    Name: 'Engineer, professional, television'
  },
  {
    Id: 2458,
    Code: '2124',
    Name: 'Inspector, chief, professional, electronic engineering, engineering'
  },
  {
    Id: 2459,
    Code: '2124',
    Name: 'Officer, research, engineering, electronic'
  },
  {
    Id: 2460,
    Code: '2124',
    Name: 'Officer, scientific, engineering, electronic'
  },
  {
    Id: 2461,
    Code: '2124',
    Name: 'Researcher, engineering, electronic'
  },
  {
    Id: 2462,
    Code: '2125',
    Name: 'Adviser, production'
  },
  {
    Id: 2463,
    Code: '2125',
    Name: 'Consultant, engineering, chemical'
  },
  {
    Id: 2464,
    Code: '2125',
    Name: 'Consultant, industrial'
  },
  {
    Id: 2465,
    Code: '2125',
    Name: 'Consultant, production'
  },
  {
    Id: 2466,
    Code: '2125',
    Name: 'Coordinator, project, manufacturing'
  },
  {
    Id: 2467,
    Code: '2125',
    Name: 'Engineer, chemical'
  },
  {
    Id: 2468,
    Code: '2125',
    Name: 'Engineer, control, process'
  },
  {
    Id: 2469,
    Code: '2125',
    Name: 'Engineer, control, production'
  },
  {
    Id: 2470,
    Code: '2125',
    Name: 'Engineer, control and instrument'
  },
  {
    Id: 2471,
    Code: '2125',
    Name: 'Engineer, control and instrumentation'
  },
  {
    Id: 2472,
    Code: '2125',
    Name: 'Engineer, explosive, coal mine'
  },
  {
    Id: 2473,
    Code: '2125',
    Name: 'Engineer, improvement, continuous'
  },
  {
    Id: 2474,
    Code: '2125',
    Name: 'Engineer, industrial'
  },
  {
    Id: 2475,
    Code: '2125',
    Name: 'Engineer, instrument, professional'
  },
  {
    Id: 2476,
    Code: '2125',
    Name: 'Engineer, instrumentation'
  },
  {
    Id: 2477,
    Code: '2125',
    Name: 'Engineer, petrochemical'
  },
  {
    Id: 2478,
    Code: '2125',
    Name: 'Engineer, pharmaceutical'
  },
  {
    Id: 2479,
    Code: '2125',
    Name: 'Engineer, plastics, professional'
  },
  {
    Id: 2480,
    Code: '2125',
    Name: 'Engineer, process'
  },
  {
    Id: 2481,
    Code: '2125',
    Name: 'Engineer, product, professional'
  },
  {
    Id: 2482,
    Code: '2125',
    Name: 'Engineer, production'
  },
  {
    Id: 2483,
    Code: '2125',
    Name: 'Engineer, production and planning'
  },
  {
    Id: 2484,
    Code: '2125',
    Name: 'Engineer, systems, manufacturing'
  },
  {
    Id: 2485,
    Code: '2125',
    Name: 'Engineer, professional, chemical'
  },
  {
    Id: 2486,
    Code: '2125',
    Name: 'Engineer, professional: instrument'
  },
  {
    Id: 2487,
    Code: '2125',
    Name: 'Engineer, professional: instrumentation'
  },
  {
    Id: 2488,
    Code: '2125',
    Name: 'Engineer, professional, petrochemical'
  },
  {
    Id: 2489,
    Code: '2125',
    Name: 'Engineer, professional, pharmaceutical'
  },
  {
    Id: 2490,
    Code: '2125',
    Name: 'Engineer, professional, plastics'
  },
  {
    Id: 2491,
    Code: '2125',
    Name: 'Engineer, professional, process'
  },
  {
    Id: 2492,
    Code: '2125',
    Name: 'Engineer, professional, production'
  },
  {
    Id: 2493,
    Code: '2125',
    Name: 'Leader, section, production control'
  },
  {
    Id: 2494,
    Code: '2125',
    Name: 'Officer, productivity and costs, coal mine'
  },
  {
    Id: 2495,
    Code: '2125',
    Name: 'Superintendent, production, MOD'
  },
  {
    Id: 2496,
    Code: '2126',
    Name: 'Designer, aircraft'
  },
  {
    Id: 2497,
    Code: '2126',
    Name: 'Designer, avionics'
  },
  {
    Id: 2498,
    Code: '2126',
    Name: 'Designer, aerospace'
  },
  {
    Id: 2499,
    Code: '2126',
    Name: 'Engineer, aeronautical, professional'
  },
  {
    Id: 2500,
    Code: '2126',
    Name: 'Engineer, aerospace'
  },
  {
    Id: 2501,
    Code: '2126',
    Name: 'Engineer, aviation, professional'
  },
  {
    Id: 2502,
    Code: '2126',
    Name: 'Engineer, avionics'
  },
  {
    Id: 2503,
    Code: '2126',
    Name: 'Engineer, design, aerospace'
  },
  {
    Id: 2504,
    Code: '2126',
    Name: 'Engineer, design, aircraft'
  },
  {
    Id: 2505,
    Code: '2126',
    Name: 'Engineer, design, aerospace'
  },
  {
    Id: 2506,
    Code: '2126',
    Name: 'Engineer, mechanical, professional, aircraft'
  },
  {
    Id: 2507,
    Code: '2126',
    Name: 'Engineer, mechanical, professional, avionics'
  },
  {
    Id: 2508,
    Code: '2126',
    Name: 'Engineer, stress, aeronautical'
  },
  {
    Id: 2509,
    Code: '2126',
    Name: 'Engineer, stress, aircraft'
  },
  {
    Id: 2510,
    Code: '2126',
    Name: 'Engineer, stress, aircraft mfr'
  },
  {
    Id: 2511,
    Code: '2126',
    Name: 'Engineer, systems, aerospace'
  },
  {
    Id: 2512,
    Code: '2126',
    Name: 'Engineer, systems, avionics'
  },
  {
    Id: 2513,
    Code: '2126',
    Name: 'Engineer, systems, fuel, avionics'
  },
  {
    Id: 2514,
    Code: '2126',
    Name: 'Engineer, systems, aerospace'
  },
  {
    Id: 2515,
    Code: '2126',
    Name: 'Engineer, professional, aeronautical'
  },
  {
    Id: 2516,
    Code: '2126',
    Name: 'Engineer, professional, aviation'
  },
  {
    Id: 2517,
    Code: '2126',
    Name: 'Engineer, professional, avionics'
  },
  {
    Id: 2518,
    Code: '2126',
    Name: 'Surveyor, aircraft'
  },
  {
    Id: 2519,
    Code: '2126',
    Name: 'Surveyor, design, Civil Aviation Authority'
  },
  {
    Id: 2520,
    Code: '2127',
    Name: 'Controller, project, engineering'
  },
  {
    Id: 2521,
    Code: '2127',
    Name: 'Controller, project, manufacturing'
  },
  {
    Id: 2522,
    Code: '2127',
    Name: 'Engineer, project, electrical'
  },
  {
    Id: 2523,
    Code: '2127',
    Name: 'Engineer, project, mechanical'
  },
  {
    Id: 2524,
    Code: '2127',
    Name: 'Engineer, project, aerospace'
  },
  {
    Id: 2525,
    Code: '2127',
    Name: 'Engineer, project, oil & natural gas'
  },
  {
    Id: 2526,
    Code: '2127',
    Name: 'Engineer, project, engineering'
  },
  {
    Id: 2527,
    Code: '2127',
    Name: 'Engineer, project, manufacturing'
  },
  {
    Id: 2528,
    Code: '2127',
    Name: 'Engineer, project, telecoms'
  },
  {
    Id: 2529,
    Code: '2127',
    Name: 'Engineer, project'
  },
  {
    Id: 2530,
    Code: '2127',
    Name: 'Engineer, projects, aerospace'
  },
  {
    Id: 2531,
    Code: '2127',
    Name: 'Engineer, projects, oil & natural gas'
  },
  {
    Id: 2532,
    Code: '2127',
    Name: 'Engineer, projects, engineering'
  },
  {
    Id: 2533,
    Code: '2127',
    Name: 'Engineer, projects, manufacturing'
  },
  {
    Id: 2534,
    Code: '2127',
    Name: 'Engineer, projects, telecoms'
  },
  {
    Id: 2535,
    Code: '2127',
    Name: 'Engineer, projects'
  },
  {
    Id: 2536,
    Code: '2127',
    Name: 'Manager, contract, manufacturing'
  },
  {
    Id: 2537,
    Code: '2127',
    Name: 'Manager, contracts, manufacturing'
  },
  {
    Id: 2538,
    Code: '2127',
    Name: 'Manager, engineering, project'
  },
  {
    Id: 2539,
    Code: '2127',
    Name: 'Manager, project, engineering'
  },
  {
    Id: 2540,
    Code: '2127',
    Name: 'Manager, project, manufacturing'
  },
  {
    Id: 2541,
    Code: '2129',
    Name: 'Acoustician, professional'
  },
  {
    Id: 2542,
    Code: '2129',
    Name: 'Adviser, heating, professional'
  },
  {
    Id: 2543,
    Code: '2129',
    Name: 'Adviser, textile'
  },
  {
    Id: 2544,
    Code: '2129',
    Name: 'Analyst, engineering'
  },
  {
    Id: 2545,
    Code: '2129',
    Name: 'Analyst, patent'
  },
  {
    Id: 2546,
    Code: '2129',
    Name: 'Assayer'
  },
  {
    Id: 2547,
    Code: '2129',
    Name: 'Assayist'
  },
  {
    Id: 2548,
    Code: '2129',
    Name: 'Assistant, assay'
  },
  {
    Id: 2549,
    Code: '2129',
    Name: "Assistant, assayer's"
  },
  {
    Id: 2550,
    Code: '2129',
    Name: 'Associate, patent'
  },
  {
    Id: 2551,
    Code: '2129',
    Name: 'Brewer, head'
  },
  {
    Id: 2552,
    Code: '2129',
    Name: 'Brewer, technical'
  },
  {
    Id: 2553,
    Code: '2129',
    Name: 'Brewer, under'
  },
  {
    Id: 2554,
    Code: '2129',
    Name: 'Brewer, vinegar'
  },
  {
    Id: 2555,
    Code: '2129',
    Name: 'Brewer, working'
  },
  {
    Id: 2556,
    Code: '2129',
    Name: 'Brewer, qualified'
  },
  {
    Id: 2557,
    Code: '2129',
    Name: 'Brewer, brewery'
  },
  {
    Id: 2558,
    Code: '2129',
    Name: 'Brewer, distillery'
  },
  {
    Id: 2559,
    Code: '2129',
    Name: 'Brewer, vinegar mfr'
  },
  {
    Id: 2560,
    Code: '2129',
    Name: 'Consultant, acoustics'
  },
  {
    Id: 2561,
    Code: '2129',
    Name: 'Consultant, ceramics'
  },
  {
    Id: 2562,
    Code: '2129',
    Name: 'Consultant, colour, paint mfr'
  },
  {
    Id: 2563,
    Code: '2129',
    Name: 'Consultant, design, engineering'
  },
  {
    Id: 2564,
    Code: '2129',
    Name: 'Consultant, energy, renewable, professional'
  },
  {
    Id: 2565,
    Code: '2129',
    Name: 'Consultant, energy, professional'
  },
  {
    Id: 2566,
    Code: '2129',
    Name: 'Consultant, heating, professional'
  },
  {
    Id: 2567,
    Code: '2129',
    Name: 'Consultant, metallurgical'
  },
  {
    Id: 2568,
    Code: '2129',
    Name: 'Consultant, patent'
  },
  {
    Id: 2569,
    Code: '2129',
    Name: 'Consultant, scientific'
  },
  {
    Id: 2570,
    Code: '2129',
    Name: 'Developer, product, food'
  },
  {
    Id: 2571,
    Code: '2129',
    Name: 'Dyer, technical'
  },
  {
    Id: 2572,
    Code: '2129',
    Name: 'Engineer, acoustic, professional'
  },
  {
    Id: 2573,
    Code: '2129',
    Name: 'Engineer, acoustics, professional'
  },
  {
    Id: 2574,
    Code: '2129',
    Name: 'Engineer, agricultural, professional'
  },
  {
    Id: 2575,
    Code: '2129',
    Name: 'Engineer, applications, manufacturing, additive'
  },
  {
    Id: 2576,
    Code: '2129',
    Name: 'Engineer, biomass, professional'
  },
  {
    Id: 2577,
    Code: '2129',
    Name: 'Engineer, biomedical'
  },
  {
    Id: 2578,
    Code: '2129',
    Name: 'Engineer, ceramics'
  },
  {
    Id: 2579,
    Code: '2129',
    Name: 'Engineer, chief, gas supplier'
  },
  {
    Id: 2580,
    Code: '2129',
    Name: 'Engineer, clinical'
  },
  {
    Id: 2581,
    Code: '2129',
    Name: 'Engineer, combustion'
  },
  {
    Id: 2582,
    Code: '2129',
    Name: 'Engineer, conditioning, air, professional'
  },
  {
    Id: 2583,
    Code: '2129',
    Name: 'Engineer, control, noise, professional'
  },
  {
    Id: 2584,
    Code: '2129',
    Name: 'Engineer, corrosion, professional'
  },
  {
    Id: 2585,
    Code: '2129',
    Name: 'Engineer, cryogenic'
  },
  {
    Id: 2586,
    Code: '2129',
    Name: 'Engineer, decommissioning, nuclear fuel production'
  },
  {
    Id: 2587,
    Code: '2129',
    Name: 'Engineer, design'
  },
  {
    Id: 2588,
    Code: '2129',
    Name: 'Engineer, design and development'
  },
  {
    Id: 2589,
    Code: '2129',
    Name: 'Engineer, design and test'
  },
  {
    Id: 2590,
    Code: '2129',
    Name: 'Engineer, development, product'
  },
  {
    Id: 2591,
    Code: '2129',
    Name: 'Engineer, development'
  },
  {
    Id: 2592,
    Code: '2129',
    Name: 'Engineer, efficiency, energy, professional'
  },
  {
    Id: 2593,
    Code: '2129',
    Name: 'Engineer, energy, renewable'
  },
  {
    Id: 2594,
    Code: '2129',
    Name: 'Engineer, experimental'
  },
  {
    Id: 2595,
    Code: '2129',
    Name: 'Engineer, factors, human'
  },
  {
    Id: 2596,
    Code: '2129',
    Name: 'Engineer, forensic'
  },
  {
    Id: 2597,
    Code: '2129',
    Name: 'Engineer, fuel'
  },
  {
    Id: 2598,
    Code: '2129',
    Name: 'Engineer, gas, professional'
  },
  {
    Id: 2599,
    Code: '2129',
    Name: 'Engineer, glass'
  },
  {
    Id: 2600,
    Code: '2129',
    Name: 'Engineer, heating, professional'
  },
  {
    Id: 2601,
    Code: '2129',
    Name: 'Engineer, heating and ventilating, professional'
  },
  {
    Id: 2602,
    Code: '2129',
    Name: 'Engineer, horticultural, professional'
  },
  {
    Id: 2603,
    Code: '2129',
    Name: 'Engineer, HVAC, professional'
  },
  {
    Id: 2604,
    Code: '2129',
    Name: 'Engineer, lubrication, professional'
  },
  {
    Id: 2605,
    Code: '2129',
    Name: 'Engineer, mechatronics'
  },
  {
    Id: 2606,
    Code: '2129',
    Name: 'Engineer, medical, professional'
  },
  {
    Id: 2607,
    Code: '2129',
    Name: 'Engineer, metallurgical'
  },
  {
    Id: 2608,
    Code: '2129',
    Name: 'Engineer, nuclear'
  },
  {
    Id: 2609,
    Code: '2129',
    Name: 'Engineer, packaging'
  },
  {
    Id: 2610,
    Code: '2129',
    Name: 'Engineer, patent'
  },
  {
    Id: 2611,
    Code: '2129',
    Name: 'Engineer, physics, health'
  },
  {
    Id: 2612,
    Code: '2129',
    Name: 'Engineer, printing, 3D'
  },
  {
    Id: 2613,
    Code: '2129',
    Name: 'Engineer, proposals, senior'
  },
  {
    Id: 2614,
    Code: '2129',
    Name: 'Engineer, proposals, professional'
  },
  {
    Id: 2615,
    Code: '2129',
    Name: 'Engineer, protection, cathodic'
  },
  {
    Id: 2616,
    Code: '2129',
    Name: 'Engineer, protection, fire, professional'
  },
  {
    Id: 2617,
    Code: '2129',
    Name: 'Engineer, R&D'
  },
  {
    Id: 2618,
    Code: '2129',
    Name: 'Engineer, railway, professional'
  },
  {
    Id: 2619,
    Code: '2129',
    Name: 'Engineer, refrigeration, professional'
  },
  {
    Id: 2620,
    Code: '2129',
    Name: 'Engineer, research, manufacturing, additive'
  },
  {
    Id: 2621,
    Code: '2129',
    Name: 'Engineer, research and development'
  },
  {
    Id: 2622,
    Code: '2129',
    Name: 'Engineer, robotics'
  },
  {
    Id: 2623,
    Code: '2129',
    Name: 'Engineer, scientific'
  },
  {
    Id: 2624,
    Code: '2129',
    Name: 'Engineer, supervising, government'
  },
  {
    Id: 2625,
    Code: '2129',
    Name: 'Engineer, systems, control'
  },
  {
    Id: 2626,
    Code: '2129',
    Name: 'Engineer, technical'
  },
  {
    Id: 2627,
    Code: '2129',
    Name: 'Engineer, test, professional'
  },
  {
    Id: 2628,
    Code: '2129',
    Name: 'Engineer, thermal, professional'
  },
  {
    Id: 2629,
    Code: '2129',
    Name: 'Engineer, thermal and acoustic, professional'
  },
  {
    Id: 2630,
    Code: '2129',
    Name: 'Engineer, traffic, air, professional'
  },
  {
    Id: 2631,
    Code: '2129',
    Name: 'Engineer, traffic'
  },
  {
    Id: 2632,
    Code: '2129',
    Name: 'Engineer, transport, professional'
  },
  {
    Id: 2633,
    Code: '2129',
    Name: 'Engineer, professional, acoustics'
  },
  {
    Id: 2634,
    Code: '2129',
    Name: 'Engineer, professional, agricultural'
  },
  {
    Id: 2635,
    Code: '2129',
    Name: 'Engineer, professional, ceramics'
  },
  {
    Id: 2636,
    Code: '2129',
    Name: 'Engineer, professional, combustion'
  },
  {
    Id: 2637,
    Code: '2129',
    Name: 'Engineer, professional, conditioning, air'
  },
  {
    Id: 2638,
    Code: '2129',
    Name: 'Engineer, professional, corrosion'
  },
  {
    Id: 2639,
    Code: '2129',
    Name: 'Engineer, professional, cryogenics'
  },
  {
    Id: 2640,
    Code: '2129',
    Name: 'Engineer, professional, design'
  },
  {
    Id: 2641,
    Code: '2129',
    Name: 'Engineer, professional, development'
  },
  {
    Id: 2642,
    Code: '2129',
    Name: 'Engineer, professional, fuel'
  },
  {
    Id: 2643,
    Code: '2129',
    Name: 'Engineer, professional, gas'
  },
  {
    Id: 2644,
    Code: '2129',
    Name: 'Engineer, professional, glass'
  },
  {
    Id: 2645,
    Code: '2129',
    Name: 'Engineer, professional, heating and ventilating'
  },
  {
    Id: 2646,
    Code: '2129',
    Name: 'Engineer, professional, horticultural'
  },
  {
    Id: 2647,
    Code: '2129',
    Name: 'Engineer, professional, lubrication'
  },
  {
    Id: 2648,
    Code: '2129',
    Name: 'Engineer, professional, metallurgics'
  },
  {
    Id: 2649,
    Code: '2129',
    Name: 'Engineer, professional, noise control'
  },
  {
    Id: 2650,
    Code: '2129',
    Name: 'Engineer, professional, nuclear'
  },
  {
    Id: 2651,
    Code: '2129',
    Name: 'Engineer, professional, packaging'
  },
  {
    Id: 2652,
    Code: '2129',
    Name: 'Engineer, professional, patent'
  },
  {
    Id: 2653,
    Code: '2129',
    Name: 'Engineer, professional, refrigeration'
  },
  {
    Id: 2654,
    Code: '2129',
    Name: 'Engineer, professional, thermal'
  },
  {
    Id: 2655,
    Code: '2129',
    Name: 'Engineer, professional, traffic'
  },
  {
    Id: 2656,
    Code: '2129',
    Name: 'Ergonomist'
  },
  {
    Id: 2657,
    Code: '2129',
    Name: 'Examiner, patent'
  },
  {
    Id: 2658,
    Code: '2129',
    Name: 'Examiner, Board of Trade'
  },
  {
    Id: 2659,
    Code: '2129',
    Name: 'Examiner, Intellectual Property Office'
  },
  {
    Id: 2660,
    Code: '2129',
    Name: 'Inspector, district, government: MOD'
  },
  {
    Id: 2661,
    Code: '2129',
    Name: 'Inspector, divisional, government: MOD'
  },
  {
    Id: 2662,
    Code: '2129',
    Name: 'Inspector, engineering, Dept for Business, Energy and Industrial Strategy'
  },
  {
    Id: 2663,
    Code: '2129',
    Name: 'Manager, sensory'
  },
  {
    Id: 2664,
    Code: '2129',
    Name: 'Metallographer'
  },
  {
    Id: 2665,
    Code: '2129',
    Name: 'Metallurgist'
  },
  {
    Id: 2666,
    Code: '2129',
    Name: 'Officer, carbonisation, coal mine'
  },
  {
    Id: 2667,
    Code: '2129',
    Name: 'Officer, compliance, quality'
  },
  {
    Id: 2668,
    Code: '2129',
    Name: 'Officer, patent, government'
  },
  {
    Id: 2669,
    Code: '2129',
    Name: 'Officer, patents, government'
  },
  {
    Id: 2670,
    Code: '2129',
    Name: 'Officer, technical, carbonisation, coal mine'
  },
  {
    Id: 2671,
    Code: '2129',
    Name: 'Overseer, ship, MOD'
  },
  {
    Id: 2672,
    Code: '2129',
    Name: 'Overseer, MOD'
  },
  {
    Id: 2673,
    Code: '2129',
    Name: 'Psychologist, environmental'
  },
  {
    Id: 2674,
    Code: '2129',
    Name: 'Researcher, patent'
  },
  {
    Id: 2675,
    Code: '2129',
    Name: 'Scientist, food'
  },
  {
    Id: 2676,
    Code: '2129',
    Name: 'Scientist, materials'
  },
  {
    Id: 2677,
    Code: '2129',
    Name: 'Specialist, factors, human'
  },
  {
    Id: 2678,
    Code: '2129',
    Name: 'Technician, dyeing'
  },
  {
    Id: 2679,
    Code: '2129',
    Name: 'Technician, protection, cathodic'
  },
  {
    Id: 2680,
    Code: '2129',
    Name: 'Technologist, food'
  },
  {
    Id: 2681,
    Code: '2129',
    Name: 'Technologist, scientific, coal mine'
  },
  {
    Id: 2682,
    Code: '2129',
    Name: 'Technologist, food'
  },
  {
    Id: 2683,
    Code: '2129',
    Name: 'Technologist'
  },
  {
    Id: 2684,
    Code: '2129',
    Name: 'Tribologist'
  },
  {
    Id: 2685,
    Code: '2131',
    Name: 'Head of delivery, computing: project management'
  },
  {
    Id: 2686,
    Code: '2131',
    Name: 'Leader, project, software'
  },
  {
    Id: 2687,
    Code: '2131',
    Name: 'Leader, project, software design'
  },
  {
    Id: 2688,
    Code: '2131',
    Name: 'Manager, change, IT'
  },
  {
    Id: 2689,
    Code: '2131',
    Name: 'Manager, change, computing'
  },
  {
    Id: 2690,
    Code: '2131',
    Name: 'Manager, change, telecoms'
  },
  {
    Id: 2691,
    Code: '2131',
    Name: 'Manager, delivery, project, computing'
  },
  {
    Id: 2692,
    Code: '2131',
    Name: 'Manager, implementation, computing'
  },
  {
    Id: 2693,
    Code: '2131',
    Name: 'Manager, project, development, software'
  },
  {
    Id: 2694,
    Code: '2131',
    Name: 'Manager, project, digital'
  },
  {
    Id: 2695,
    Code: '2131',
    Name: 'Manager, project, ICT'
  },
  {
    Id: 2696,
    Code: '2131',
    Name: 'Manager, project, infrastructure'
  },
  {
    Id: 2697,
    Code: '2131',
    Name: 'Manager, project, IT'
  },
  {
    Id: 2698,
    Code: '2131',
    Name: 'Manager, project, technical'
  },
  {
    Id: 2699,
    Code: '2131',
    Name: 'Manager, project, computing'
  },
  {
    Id: 2700,
    Code: '2131',
    Name: 'Manager, project, telecoms'
  },
  {
    Id: 2701,
    Code: '2131',
    Name: 'Manager, projects, IT'
  },
  {
    Id: 2702,
    Code: '2131',
    Name: 'Officer, projects, computer'
  },
  {
    Id: 2703,
    Code: '2131',
    Name: 'Planner, IP'
  },
  {
    Id: 2704,
    Code: '2132',
    Name: 'Manager, administration, computer'
  },
  {
    Id: 2705,
    Code: '2132',
    Name: 'Manager, applications, computing'
  },
  {
    Id: 2706,
    Code: '2132',
    Name: 'Manager, asset, IT'
  },
  {
    Id: 2707,
    Code: '2132',
    Name: 'Manager, assurance, quality, systems'
  },
  {
    Id: 2708,
    Code: '2132',
    Name: 'Manager, audit, IT'
  },
  {
    Id: 2709,
    Code: '2132',
    Name: 'Manager, CAD'
  },
  {
    Id: 2710,
    Code: '2132',
    Name: 'Manager, café, internet'
  },
  {
    Id: 2711,
    Code: '2132',
    Name: 'Manager, capture, data'
  },
  {
    Id: 2712,
    Code: '2132',
    Name: 'Manager, centre, data'
  },
  {
    Id: 2713,
    Code: '2132',
    Name: 'Manager, communications, data'
  },
  {
    Id: 2714,
    Code: '2132',
    Name: 'Manager, communications, computing'
  },
  {
    Id: 2715,
    Code: '2132',
    Name: 'Manager, computer'
  },
  {
    Id: 2716,
    Code: '2132',
    Name: 'Manager, delivery, IT'
  },
  {
    Id: 2717,
    Code: '2132',
    Name: 'Manager, delivery, service, IT'
  },
  {
    Id: 2718,
    Code: '2132',
    Name: 'Manager, delivery, service'
  },
  {
    Id: 2719,
    Code: '2132',
    Name: 'Manager, delivery, computing'
  },
  {
    Id: 2720,
    Code: '2132',
    Name: 'Manager, delivery, telecoms'
  },
  {
    Id: 2721,
    Code: '2132',
    Name: 'Manager, design, service, computing'
  },
  {
    Id: 2722,
    Code: '2132',
    Name: 'Manager, desk, help, computing'
  },
  {
    Id: 2723,
    Code: '2132',
    Name: 'Manager, desk, help, computer services'
  },
  {
    Id: 2724,
    Code: '2132',
    Name: 'Manager, desk, service, IT'
  },
  {
    Id: 2725,
    Code: '2132',
    Name: 'Manager, desk, service, computing'
  },
  {
    Id: 2726,
    Code: '2132',
    Name: 'Manager, desk, service, computer services'
  },
  {
    Id: 2727,
    Code: '2132',
    Name: 'Manager, development, IT'
  },
  {
    Id: 2728,
    Code: '2132',
    Name: 'Manager, development, software'
  },
  {
    Id: 2729,
    Code: '2132',
    Name: 'Manager, development, systems'
  },
  {
    Id: 2730,
    Code: '2132',
    Name: 'Manager, development, web'
  },
  {
    Id: 2731,
    Code: '2132',
    Name: 'Manager, development, computing'
  },
  {
    Id: 2732,
    Code: '2132',
    Name: 'Manager, engineering, network, computing'
  },
  {
    Id: 2733,
    Code: '2132',
    Name: 'Manager, engineering, software'
  },
  {
    Id: 2734,
    Code: '2132',
    Name: 'Manager, engineering, system, computing'
  },
  {
    Id: 2735,
    Code: '2132',
    Name: 'Manager, engineering, computer services'
  },
  {
    Id: 2736,
    Code: '2132',
    Name: 'Manager, engineering, telecoms'
  },
  {
    Id: 2737,
    Code: '2132',
    Name: 'Manager, experience, digital'
  },
  {
    Id: 2738,
    Code: '2132',
    Name: 'Manager, experience, user, computing'
  },
  {
    Id: 2739,
    Code: '2132',
    Name: 'Manager, facilities, IT'
  },
  {
    Id: 2740,
    Code: '2132',
    Name: 'Manager, field, telecoms'
  },
  {
    Id: 2741,
    Code: '2132',
    Name: 'Manager, GIS, programming'
  },
  {
    Id: 2742,
    Code: '2132',
    Name: 'Manager, helpdesk, IT'
  },
  {
    Id: 2743,
    Code: '2132',
    Name: 'Manager, helpdesk, service, IT'
  },
  {
    Id: 2744,
    Code: '2132',
    Name: 'Manager, ICT'
  },
  {
    Id: 2745,
    Code: '2132',
    Name: 'Manager, information, computing'
  },
  {
    Id: 2746,
    Code: '2132',
    Name: 'Manager, information'
  },
  {
    Id: 2747,
    Code: '2132',
    Name: 'Manager, infrastructure, IT'
  },
  {
    Id: 2748,
    Code: '2132',
    Name: 'Manager, infrastructure'
  },
  {
    Id: 2749,
    Code: '2132',
    Name: 'Manager, installation, computer'
  },
  {
    Id: 2750,
    Code: '2132',
    Name: 'Manager, installation, systems'
  },
  {
    Id: 2751,
    Code: '2132',
    Name: 'Manager, internet'
  },
  {
    Id: 2752,
    Code: '2132',
    Name: 'Manager, intranet'
  },
  {
    Id: 2753,
    Code: '2132',
    Name: 'Manager, IT, group'
  },
  {
    Id: 2754,
    Code: '2132',
    Name: 'Manager, IT'
  },
  {
    Id: 2755,
    Code: '2132',
    Name: 'Manager, MIS'
  },
  {
    Id: 2756,
    Code: '2132',
    Name: 'Manager, network'
  },
  {
    Id: 2757,
    Code: '2132',
    Name: 'Manager, networking'
  },
  {
    Id: 2758,
    Code: '2132',
    Name: 'Manager, operations, computer'
  },
  {
    Id: 2759,
    Code: '2132',
    Name: 'Manager, operations, IT'
  },
  {
    Id: 2760,
    Code: '2132',
    Name: 'Manager, operations, network, computer services'
  },
  {
    Id: 2761,
    Code: '2132',
    Name: 'Manager, operations, computer services'
  },
  {
    Id: 2762,
    Code: '2132',
    Name: 'Manager, operations, telecoms'
  },
  {
    Id: 2763,
    Code: '2132',
    Name: 'Manager, processing, data'
  },
  {
    Id: 2764,
    Code: '2132',
    Name: 'Manager, product, computer services'
  },
  {
    Id: 2765,
    Code: '2132',
    Name: 'Manager, production, digital'
  },
  {
    Id: 2766,
    Code: '2132',
    Name: 'Manager, production, computing'
  },
  {
    Id: 2767,
    Code: '2132',
    Name: 'Manager, programming'
  },
  {
    Id: 2768,
    Code: '2132',
    Name: 'Manager, quality, computing'
  },
  {
    Id: 2769,
    Code: '2132',
    Name: 'Manager, release, computing'
  },
  {
    Id: 2770,
    Code: '2132',
    Name: 'Manager, service, computer'
  },
  {
    Id: 2771,
    Code: '2132',
    Name: 'Manager, service, digital'
  },
  {
    Id: 2772,
    Code: '2132',
    Name: 'Manager, service, IT'
  },
  {
    Id: 2773,
    Code: '2132',
    Name: 'Manager, service, computing'
  },
  {
    Id: 2774,
    Code: '2132',
    Name: 'Manager, services, client, computing'
  },
  {
    Id: 2775,
    Code: '2132',
    Name: 'Manager, services, computer'
  },
  {
    Id: 2776,
    Code: '2132',
    Name: 'Manager, services, customer, IT'
  },
  {
    Id: 2777,
    Code: '2132',
    Name: 'Manager, services, IT'
  },
  {
    Id: 2778,
    Code: '2132',
    Name: 'Manager, services, network'
  },
  {
    Id: 2779,
    Code: '2132',
    Name: 'Manager, services, computing'
  },
  {
    Id: 2780,
    Code: '2132',
    Name: 'Manager, software'
  },
  {
    Id: 2781,
    Code: '2132',
    Name: 'Manager, support, desktop'
  },
  {
    Id: 2782,
    Code: '2132',
    Name: 'Manager, support, IT'
  },
  {
    Id: 2783,
    Code: '2132',
    Name: 'Manager, support, PC'
  },
  {
    Id: 2784,
    Code: '2132',
    Name: 'Manager, support, systems'
  },
  {
    Id: 2785,
    Code: '2132',
    Name: 'Manager, support, technical, computing'
  },
  {
    Id: 2786,
    Code: '2132',
    Name: 'Manager, support, computing'
  },
  {
    Id: 2787,
    Code: '2132',
    Name: 'Manager, system, network, computer'
  },
  {
    Id: 2788,
    Code: '2132',
    Name: 'Manager, systems, business'
  },
  {
    Id: 2789,
    Code: '2132',
    Name: 'Manager, systems, information'
  },
  {
    Id: 2790,
    Code: '2132',
    Name: 'Manager, systems, IT'
  },
  {
    Id: 2791,
    Code: '2132',
    Name: 'Manager, systems'
  },
  {
    Id: 2792,
    Code: '2132',
    Name: 'Manager, team, computing'
  },
  {
    Id: 2793,
    Code: '2132',
    Name: 'Manager, technical, computer'
  },
  {
    Id: 2794,
    Code: '2132',
    Name: 'Manager, technical, computing'
  },
  {
    Id: 2795,
    Code: '2132',
    Name: 'Manager, technical, telecoms'
  },
  {
    Id: 2796,
    Code: '2132',
    Name: 'Manager, technology, information'
  },
  {
    Id: 2797,
    Code: '2132',
    Name: 'Manager, Telecom, British'
  },
  {
    Id: 2798,
    Code: '2132',
    Name: 'Manager, telecommunications'
  },
  {
    Id: 2799,
    Code: '2132',
    Name: 'Manager, telecoms'
  },
  {
    Id: 2800,
    Code: '2132',
    Name: 'Manager, test, IT'
  },
  {
    Id: 2801,
    Code: '2132',
    Name: 'Manager, test, software'
  },
  {
    Id: 2802,
    Code: '2132',
    Name: 'Manager, test, computing'
  },
  {
    Id: 2803,
    Code: '2132',
    Name: 'Manager, test, telecoms'
  },
  {
    Id: 2804,
    Code: '2132',
    Name: 'Manager, testing, computing'
  },
  {
    Id: 2805,
    Code: '2132',
    Name: 'Manager, transmission, computing'
  },
  {
    Id: 2806,
    Code: '2132',
    Name: 'Manager, UX, computing'
  },
  {
    Id: 2807,
    Code: '2132',
    Name: 'Manager, validation, computing'
  },
  {
    Id: 2808,
    Code: '2132',
    Name: 'Manager, visualisation, data'
  },
  {
    Id: 2809,
    Code: '2132',
    Name: 'Manager, warehouse, data'
  },
  {
    Id: 2810,
    Code: '2132',
    Name: 'Manager, computing'
  },
  {
    Id: 2811,
    Code: '2132',
    Name: 'Manager, computer services'
  },
  {
    Id: 2812,
    Code: '2132',
    Name: 'Manager, information systems'
  },
  {
    Id: 2813,
    Code: '2132',
    Name: 'Manager, software company'
  },
  {
    Id: 2814,
    Code: '2132',
    Name: 'Manager, telecoms'
  },
  {
    Id: 2815,
    Code: '2132',
    Name: 'Owner, café, internet'
  },
  {
    Id: 2816,
    Code: '2133',
    Name: 'Accountant, systems'
  },
  {
    Id: 2817,
    Code: '2133',
    Name: 'Adviser, systems'
  },
  {
    Id: 2818,
    Code: '2133',
    Name: 'Analyst, business, IT'
  },
  {
    Id: 2819,
    Code: '2133',
    Name: 'Analyst, business, technical'
  },
  {
    Id: 2820,
    Code: '2133',
    Name: 'Analyst, business, computing'
  },
  {
    Id: 2821,
    Code: '2133',
    Name: 'Analyst, change, computing'
  },
  {
    Id: 2822,
    Code: '2133',
    Name: 'Analyst, comms, data'
  },
  {
    Id: 2823,
    Code: '2133',
    Name: 'Analyst, comms, technical'
  },
  {
    Id: 2824,
    Code: '2133',
    Name: 'Analyst, communications, data'
  },
  {
    Id: 2825,
    Code: '2133',
    Name: 'Analyst, computer'
  },
  {
    Id: 2826,
    Code: '2133',
    Name: 'Analyst, maintenance, computing: professional'
  },
  {
    Id: 2827,
    Code: '2133',
    Name: 'Analyst, operations, computing'
  },
  {
    Id: 2828,
    Code: '2133',
    Name: 'Analyst, product, computing'
  },
  {
    Id: 2829,
    Code: '2133',
    Name: 'Analyst, system, IT'
  },
  {
    Id: 2830,
    Code: '2133',
    Name: 'Analyst, system'
  },
  {
    Id: 2831,
    Code: '2133',
    Name: 'Analyst, systems, information'
  },
  {
    Id: 2832,
    Code: '2133',
    Name: 'Analyst, systems'
  },
  {
    Id: 2833,
    Code: '2133',
    Name: 'Analyst, technical, computing'
  },
  {
    Id: 2834,
    Code: '2133',
    Name: 'Analyst, telecommunications'
  },
  {
    Id: 2835,
    Code: '2133',
    Name: 'Analyst, voice, communications'
  },
  {
    Id: 2836,
    Code: '2133',
    Name: 'Analyst, warehouse, data'
  },
  {
    Id: 2837,
    Code: '2133',
    Name: 'Architect, BI'
  },
  {
    Id: 2838,
    Code: '2133',
    Name: 'Architect, chain, supply'
  },
  {
    Id: 2839,
    Code: '2133',
    Name: 'Architect, cloud'
  },
  {
    Id: 2840,
    Code: '2133',
    Name: 'Architect, data'
  },
  {
    Id: 2841,
    Code: '2133',
    Name: 'Architect, database'
  },
  {
    Id: 2842,
    Code: '2133',
    Name: 'Architect, datawarehouse'
  },
  {
    Id: 2843,
    Code: '2133',
    Name: 'Architect, digital'
  },
  {
    Id: 2844,
    Code: '2133',
    Name: 'Architect, domain'
  },
  {
    Id: 2845,
    Code: '2133',
    Name: 'Architect, enterprise'
  },
  {
    Id: 2846,
    Code: '2133',
    Name: 'Architect, experience, user'
  },
  {
    Id: 2847,
    Code: '2133',
    Name: 'Architect, functional'
  },
  {
    Id: 2848,
    Code: '2133',
    Name: 'Architect, information'
  },
  {
    Id: 2849,
    Code: '2133',
    Name: 'Architect, infrastructure'
  },
  {
    Id: 2850,
    Code: '2133',
    Name: 'Architect, integration'
  },
  {
    Id: 2851,
    Code: '2133',
    Name: 'Architect, intelligence, business'
  },
  {
    Id: 2852,
    Code: '2133',
    Name: 'Architect, interface, user'
  },
  {
    Id: 2853,
    Code: '2133',
    Name: 'Architect, IT'
  },
  {
    Id: 2854,
    Code: '2133',
    Name: 'Architect, network'
  },
  {
    Id: 2855,
    Code: '2133',
    Name: 'Architect, platform'
  },
  {
    Id: 2856,
    Code: '2133',
    Name: 'Architect, process'
  },
  {
    Id: 2857,
    Code: '2133',
    Name: 'Architect, product'
  },
  {
    Id: 2858,
    Code: '2133',
    Name: 'Architect, services, computing'
  },
  {
    Id: 2859,
    Code: '2133',
    Name: 'Architect, solution, customer'
  },
  {
    Id: 2860,
    Code: '2133',
    Name: 'Architect, solution, IT'
  },
  {
    Id: 2861,
    Code: '2133',
    Name: 'Architect, solution'
  },
  {
    Id: 2862,
    Code: '2133',
    Name: 'Architect, solutions, customer'
  },
  {
    Id: 2863,
    Code: '2133',
    Name: 'Architect, solutions, IT'
  },
  {
    Id: 2864,
    Code: '2133',
    Name: 'Architect, solutions'
  },
  {
    Id: 2865,
    Code: '2133',
    Name: 'Architect, strategic'
  },
  {
    Id: 2866,
    Code: '2133',
    Name: 'Architect, system, computing'
  },
  {
    Id: 2867,
    Code: '2133',
    Name: 'Architect, systems, computing'
  },
  {
    Id: 2868,
    Code: '2133',
    Name: 'Architect, technical, IT'
  },
  {
    Id: 2869,
    Code: '2133',
    Name: 'Architect, technical'
  },
  {
    Id: 2870,
    Code: '2133',
    Name: 'Architect, technology, SAP'
  },
  {
    Id: 2871,
    Code: '2133',
    Name: 'Architect, UX'
  },
  {
    Id: 2872,
    Code: '2133',
    Name: 'Architect, web'
  },
  {
    Id: 2873,
    Code: '2133',
    Name: 'Architect, computing'
  },
  {
    Id: 2874,
    Code: '2133',
    Name: 'Consultant, ERP'
  },
  {
    Id: 2875,
    Code: '2133',
    Name: 'Consultant, systems, IT'
  },
  {
    Id: 2876,
    Code: '2133',
    Name: 'Consultant, systems'
  },
  {
    Id: 2877,
    Code: '2133',
    Name: 'Designer, computer'
  },
  {
    Id: 2878,
    Code: '2133',
    Name: 'Designer, database'
  },
  {
    Id: 2879,
    Code: '2133',
    Name: 'Designer, service, computing'
  },
  {
    Id: 2880,
    Code: '2133',
    Name: 'Designer, systems'
  },
  {
    Id: 2881,
    Code: '2133',
    Name: 'Engineer, cloud'
  },
  {
    Id: 2882,
    Code: '2133',
    Name: 'Engineer, computer, design'
  },
  {
    Id: 2883,
    Code: '2133',
    Name: 'Engineer, data, big'
  },
  {
    Id: 2884,
    Code: '2133',
    Name: 'Engineer, data'
  },
  {
    Id: 2885,
    Code: '2133',
    Name: 'Engineer, design, computer'
  },
  {
    Id: 2886,
    Code: '2133',
    Name: 'Engineer, infrastructure, IT'
  },
  {
    Id: 2887,
    Code: '2133',
    Name: 'Engineer, infrastructure, computing'
  },
  {
    Id: 2888,
    Code: '2133',
    Name: 'Engineer, systems, computer'
  },
  {
    Id: 2889,
    Code: '2133',
    Name: 'Engineer, systems, IT'
  },
  {
    Id: 2890,
    Code: '2133',
    Name: 'Engineer, systems'
  },
  {
    Id: 2891,
    Code: '2133',
    Name: 'Integrator, systems, computing'
  },
  {
    Id: 2892,
    Code: '2133',
    Name: 'Officer, development, systems'
  },
  {
    Id: 2893,
    Code: '2133',
    Name: 'Planner, technology, information'
  },
  {
    Id: 2894,
    Code: '2133',
    Name: 'Researcher, design, service'
  },
  {
    Id: 2895,
    Code: '2133',
    Name: 'Scientist, AI'
  },
  {
    Id: 2896,
    Code: '2133',
    Name: 'Scientist, computer'
  },
  {
    Id: 2897,
    Code: '2133',
    Name: 'Scientist, data, computing'
  },
  {
    Id: 2898,
    Code: '2133',
    Name: 'Scientist, intelligence, artificial'
  },
  {
    Id: 2899,
    Code: '2133',
    Name: 'Specialist, multimedia'
  },
  {
    Id: 2900,
    Code: '2133',
    Name: 'Specialist, services, cloud'
  },
  {
    Id: 2901,
    Code: '2133',
    Name: 'Specialist, systems'
  },
  {
    Id: 2902,
    Code: '2133',
    Name: 'Specialist, technical, computer'
  },
  {
    Id: 2903,
    Code: '2133',
    Name: 'Specialist, technical, computer services'
  },
  {
    Id: 2904,
    Code: '2133',
    Name: 'Specialist, visualisation, data'
  },
  {
    Id: 2905,
    Code: '2133',
    Name: 'Strategist, IT'
  },
  {
    Id: 2906,
    Code: '2133',
    Name: 'Strategist, technology'
  },
  {
    Id: 2907,
    Code: '2133',
    Name: 'Writer, technical, computing'
  },
  {
    Id: 2908,
    Code: '2134',
    Name: 'Analyst, application'
  },
  {
    Id: 2909,
    Code: '2134',
    Name: 'Analyst, data, computing'
  },
  {
    Id: 2910,
    Code: '2134',
    Name: 'Analyst, database'
  },
  {
    Id: 2911,
    Code: '2134',
    Name: 'Analyst, implementation'
  },
  {
    Id: 2912,
    Code: '2134',
    Name: 'Analyst, information, computing'
  },
  {
    Id: 2913,
    Code: '2134',
    Name: 'Analyst, IT'
  },
  {
    Id: 2914,
    Code: '2134',
    Name: 'Analyst, project, IT'
  },
  {
    Id: 2915,
    Code: '2134',
    Name: 'Analyst, software'
  },
  {
    Id: 2916,
    Code: '2134',
    Name: 'Analyst, technology, information'
  },
  {
    Id: 2917,
    Code: '2134',
    Name: 'Analyst, technology'
  },
  {
    Id: 2918,
    Code: '2134',
    Name: 'Analyst, programming'
  },
  {
    Id: 2919,
    Code: '2134',
    Name: 'Analyst-developer'
  },
  {
    Id: 2920,
    Code: '2134',
    Name: 'Analyst-programmer'
  },
  {
    Id: 2921,
    Code: '2134',
    Name: 'Architect, software'
  },
  {
    Id: 2922,
    Code: '2134',
    Name: 'Author, software, application'
  },
  {
    Id: 2923,
    Code: '2134',
    Name: 'Author, software'
  },
  {
    Id: 2924,
    Code: '2134',
    Name: 'Author, technical, software'
  },
  {
    Id: 2925,
    Code: '2134',
    Name: 'Builder, website'
  },
  {
    Id: 2926,
    Code: '2134',
    Name: 'Consultant, application'
  },
  {
    Id: 2927,
    Code: '2134',
    Name: 'Consultant, applications'
  },
  {
    Id: 2928,
    Code: '2134',
    Name: 'Consultant, experience, user'
  },
  {
    Id: 2929,
    Code: '2134',
    Name: 'Consultant, implementation, computing'
  },
  {
    Id: 2930,
    Code: '2134',
    Name: 'Consultant, software, IT'
  },
  {
    Id: 2931,
    Code: '2134',
    Name: 'Consultant, software'
  },
  {
    Id: 2932,
    Code: '2134',
    Name: 'Consultant, support, application'
  },
  {
    Id: 2933,
    Code: '2134',
    Name: 'Consultant, support, software'
  },
  {
    Id: 2934,
    Code: '2134',
    Name: 'Consultant, technical, software design'
  },
  {
    Id: 2935,
    Code: '2134',
    Name: 'Consultant, UX'
  },
  {
    Id: 2936,
    Code: '2134',
    Name: 'Designer, computational'
  },
  {
    Id: 2937,
    Code: '2134',
    Name: 'Designer, games, computer'
  },
  {
    Id: 2938,
    Code: '2134',
    Name: 'Designer, games'
  },
  {
    Id: 2939,
    Code: '2134',
    Name: 'Designer, interaction'
  },
  {
    Id: 2940,
    Code: '2134',
    Name: 'Designer, interactive'
  },
  {
    Id: 2941,
    Code: '2134',
    Name: 'Designer, software'
  },
  {
    Id: 2942,
    Code: '2134',
    Name: 'Developer, analyst'
  },
  {
    Id: 2943,
    Code: '2134',
    Name: 'Developer, android'
  },
  {
    Id: 2944,
    Code: '2134',
    Name: 'Developer, app, mobile'
  },
  {
    Id: 2945,
    Code: '2134',
    Name: 'Developer, app, web'
  },
  {
    Id: 2946,
    Code: '2134',
    Name: 'Developer, app'
  },
  {
    Id: 2947,
    Code: '2134',
    Name: 'Developer, application, mobile'
  },
  {
    Id: 2948,
    Code: '2134',
    Name: 'Developer, application, web'
  },
  {
    Id: 2949,
    Code: '2134',
    Name: 'Developer, application'
  },
  {
    Id: 2950,
    Code: '2134',
    Name: 'Developer, applications, mobile'
  },
  {
    Id: 2951,
    Code: '2134',
    Name: 'Developer, applications, web'
  },
  {
    Id: 2952,
    Code: '2134',
    Name: 'Developer, applications'
  },
  {
    Id: 2953,
    Code: '2134',
    Name: 'Developer, apps, mobile'
  },
  {
    Id: 2954,
    Code: '2134',
    Name: 'Developer, BI'
  },
  {
    Id: 2955,
    Code: '2134',
    Name: 'Developer, computer'
  },
  {
    Id: 2956,
    Code: '2134',
    Name: 'Developer, content'
  },
  {
    Id: 2957,
    Code: '2134',
    Name: 'Developer, database'
  },
  {
    Id: 2958,
    Code: '2134',
    Name: 'Developer, digital'
  },
  {
    Id: 2959,
    Code: '2134',
    Name: 'Developer, Drupal'
  },
  {
    Id: 2960,
    Code: '2134',
    Name: 'Developer, e-learning'
  },
  {
    Id: 2961,
    Code: '2134',
    Name: 'Developer, end, front, software'
  },
  {
    Id: 2962,
    Code: '2134',
    Name: 'Developer, games'
  },
  {
    Id: 2963,
    Code: '2134',
    Name: 'Developer, intelligence, business'
  },
  {
    Id: 2964,
    Code: '2134',
    Name: 'Developer, interface, user'
  },
  {
    Id: 2965,
    Code: '2134',
    Name: 'Developer, internet'
  },
  {
    Id: 2966,
    Code: '2134',
    Name: 'Developer, iOS'
  },
  {
    Id: 2967,
    Code: '2134',
    Name: 'Developer, IT'
  },
  {
    Id: 2968,
    Code: '2134',
    Name: 'Developer, Java'
  },
  {
    Id: 2969,
    Code: '2134',
    Name: 'Developer, JavaScript'
  },
  {
    Id: 2970,
    Code: '2134',
    Name: 'Developer, mobile'
  },
  {
    Id: 2971,
    Code: '2134',
    Name: 'Developer, multimedia'
  },
  {
    Id: 2972,
    Code: '2134',
    Name: 'Developer, net'
  },
  {
    Id: 2973,
    Code: '2134',
    Name: 'Developer, PHP'
  },
  {
    Id: 2974,
    Code: '2134',
    Name: 'Developer, Python'
  },
  {
    Id: 2975,
    Code: '2134',
    Name: 'Developer, Scala'
  },
  {
    Id: 2976,
    Code: '2134',
    Name: 'Developer, services, internet'
  },
  {
    Id: 2977,
    Code: '2134',
    Name: 'Developer, SharePoint'
  },
  {
    Id: 2978,
    Code: '2134',
    Name: 'Developer, site, web'
  },
  {
    Id: 2979,
    Code: '2134',
    Name: 'Developer, software'
  },
  {
    Id: 2980,
    Code: '2134',
    Name: 'Developer, SQL'
  },
  {
    Id: 2981,
    Code: '2134',
    Name: 'Developer, stack, full'
  },
  {
    Id: 2982,
    Code: '2134',
    Name: 'Developer, systems'
  },
  {
    Id: 2983,
    Code: '2134',
    Name: 'Developer, UI'
  },
  {
    Id: 2984,
    Code: '2134',
    Name: 'Developer, web, end, front'
  },
  {
    Id: 2985,
    Code: '2134',
    Name: 'Developer, web, technical'
  },
  {
    Id: 2986,
    Code: '2134',
    Name: 'Developer, web'
  },
  {
    Id: 2987,
    Code: '2134',
    Name: 'Developer, website'
  },
  {
    Id: 2988,
    Code: '2134',
    Name: 'Developer, Xamarin'
  },
  {
    Id: 2989,
    Code: '2134',
    Name: 'Developer, computing'
  },
  {
    Id: 2990,
    Code: '2134',
    Name: 'Engineer, application, computing'
  },
  {
    Id: 2991,
    Code: '2134',
    Name: 'Engineer, design, software'
  },
  {
    Id: 2992,
    Code: '2134',
    Name: 'Engineer, development, software'
  },
  {
    Id: 2993,
    Code: '2134',
    Name: 'Engineer, firmware'
  },
  {
    Id: 2994,
    Code: '2134',
    Name: 'Engineer, hosting, web'
  },
  {
    Id: 2995,
    Code: '2134',
    Name: 'Engineer, intelligence, artificial'
  },
  {
    Id: 2996,
    Code: '2134',
    Name: 'Engineer, learning, machine'
  },
  {
    Id: 2997,
    Code: '2134',
    Name: 'Engineer, software, embedded'
  },
  {
    Id: 2998,
    Code: '2134',
    Name: 'Engineer, software, interactive'
  },
  {
    Id: 2999,
    Code: '2134',
    Name: 'Engineer, software, principal'
  },
  {
    Id: 3000,
    Code: '2134',
    Name: 'Engineer, software, research'
  },
  {
    Id: 3001,
    Code: '2134',
    Name: 'Engineer, software, senior'
  },
  {
    Id: 3002,
    Code: '2134',
    Name: 'Engineer, software'
  },
  {
    Id: 3003,
    Code: '2134',
    Name: 'Engineer, staff, software'
  },
  {
    Id: 3004,
    Code: '2134',
    Name: 'Engineer, support, software'
  },
  {
    Id: 3005,
    Code: '2134',
    Name: 'Engineer, professional, software'
  },
  {
    Id: 3006,
    Code: '2134',
    Name: 'Leader, team, analyst, computing, programming'
  },
  {
    Id: 3007,
    Code: '2134',
    Name: 'Leader, team, development, software'
  },
  {
    Id: 3008,
    Code: '2134',
    Name: 'Leader, team, development, software'
  },
  {
    Id: 3009,
    Code: '2134',
    Name: 'Leader, team, programming'
  },
  {
    Id: 3010,
    Code: '2134',
    Name: 'Leader, team, software, computing'
  },
  {
    Id: 3011,
    Code: '2134',
    Name: 'Leader, team, programming'
  },
  {
    Id: 3012,
    Code: '2134',
    Name: 'Officer, development, software'
  },
  {
    Id: 3013,
    Code: '2134',
    Name: 'Officer, development, web'
  },
  {
    Id: 3014,
    Code: '2134',
    Name: 'Programmer, analyst, computer'
  },
  {
    Id: 3015,
    Code: '2134',
    Name: 'Programmer, analyst'
  },
  {
    Id: 3016,
    Code: '2134',
    Name: 'Programmer, applications'
  },
  {
    Id: 3017,
    Code: '2134',
    Name: 'Programmer, computer'
  },
  {
    Id: 3018,
    Code: '2134',
    Name: 'Programmer, database'
  },
  {
    Id: 3019,
    Code: '2134',
    Name: 'Programmer, gameplay'
  },
  {
    Id: 3020,
    Code: '2134',
    Name: 'Programmer, games'
  },
  {
    Id: 3021,
    Code: '2134',
    Name: 'Programmer, IT'
  },
  {
    Id: 3022,
    Code: '2134',
    Name: 'Programmer, software'
  },
  {
    Id: 3023,
    Code: '2134',
    Name: 'Programmer, systems'
  },
  {
    Id: 3024,
    Code: '2134',
    Name: 'Programmer, web'
  },
  {
    Id: 3025,
    Code: '2134',
    Name: 'Programmer'
  },
  {
    Id: 3026,
    Code: '2134',
    Name: 'Programmer-analyst'
  },
  {
    Id: 3027,
    Code: '2134',
    Name: 'Specialist, application'
  },
  {
    Id: 3028,
    Code: '2134',
    Name: 'Specialist, applications'
  },
  {
    Id: 3029,
    Code: '2134',
    Name: 'Specialist, software'
  },
  {
    Id: 3030,
    Code: '2134',
    Name: 'Technician, web'
  },
  {
    Id: 3031,
    Code: '2134',
    Name: 'Writer, shader'
  },
  {
    Id: 3032,
    Code: '2134',
    Name: 'Writer, software'
  },
  {
    Id: 3033,
    Code: '2135',
    Name: 'Analyst, centre, operations, security'
  },
  {
    Id: 3034,
    Code: '2135',
    Name: 'Analyst, computer, forensic'
  },
  {
    Id: 3035,
    Code: '2135',
    Name: 'Analyst, intelligence, cyber'
  },
  {
    Id: 3036,
    Code: '2135',
    Name: 'Analyst, intelligence, threat, cyber'
  },
  {
    Id: 3037,
    Code: '2135',
    Name: 'Analyst, risk, IT'
  },
  {
    Id: 3038,
    Code: '2135',
    Name: 'Analyst, security, cyber'
  },
  {
    Id: 3039,
    Code: '2135',
    Name: 'Analyst, security, information'
  },
  {
    Id: 3040,
    Code: '2135',
    Name: 'Analyst, security, IT'
  },
  {
    Id: 3041,
    Code: '2135',
    Name: 'Analyst, security, network'
  },
  {
    Id: 3042,
    Code: '2135',
    Name: 'Analyst, security, technology'
  },
  {
    Id: 3043,
    Code: '2135',
    Name: 'Analyst, security, computing'
  },
  {
    Id: 3044,
    Code: '2135',
    Name: 'Analyst, SOC, computer services'
  },
  {
    Id: 3045,
    Code: '2135',
    Name: 'Architect, security, cyber'
  },
  {
    Id: 3046,
    Code: '2135',
    Name: 'Architect, security'
  },
  {
    Id: 3047,
    Code: '2135',
    Name: 'Auditor, cyber security and IT'
  },
  {
    Id: 3048,
    Code: '2135',
    Name: 'Auditor, security, cyber'
  },
  {
    Id: 3049,
    Code: '2135',
    Name: 'Auditor, security'
  },
  {
    Id: 3050,
    Code: '2135',
    Name: 'Consultant, cyber security and information assurance'
  },
  {
    Id: 3051,
    Code: '2135',
    Name: 'Consultant, incident response and security operations, computing'
  },
  {
    Id: 3052,
    Code: '2135',
    Name: 'Consultant, security, computer'
  },
  {
    Id: 3053,
    Code: '2135',
    Name: 'Consultant, security, cyber'
  },
  {
    Id: 3054,
    Code: '2135',
    Name: 'Consultant, security, information'
  },
  {
    Id: 3055,
    Code: '2135',
    Name: 'Consultant, security, IT'
  },
  {
    Id: 3056,
    Code: '2135',
    Name: 'Consultant, security, network'
  },
  {
    Id: 3057,
    Code: '2135',
    Name: 'Consultant, security, technical'
  },
  {
    Id: 3058,
    Code: '2135',
    Name: 'Consultant, security operations and incident response, computing'
  },
  {
    Id: 3059,
    Code: '2135',
    Name: 'Engineer, security, cyber'
  },
  {
    Id: 3060,
    Code: '2135',
    Name: 'Engineer, security, network'
  },
  {
    Id: 3061,
    Code: '2135',
    Name: 'Engineer, security, computing'
  },
  {
    Id: 3062,
    Code: '2135',
    Name: 'Hacker, ethical'
  },
  {
    Id: 3063,
    Code: '2135',
    Name: 'Hunter, threat, cyber'
  },
  {
    Id: 3064,
    Code: '2135',
    Name: 'Investigator, forensic, computer'
  },
  {
    Id: 3065,
    Code: '2135',
    Name: 'Lead, response, incident, computing'
  },
  {
    Id: 3066,
    Code: '2135',
    Name: 'Manager, cyber security risk and governance'
  },
  {
    Id: 3067,
    Code: '2135',
    Name: 'Manager, incident response and security operations, computing'
  },
  {
    Id: 3068,
    Code: '2135',
    Name: 'Manager, protection, data'
  },
  {
    Id: 3069,
    Code: '2135',
    Name: 'Manager, response, incident, cyber'
  },
  {
    Id: 3070,
    Code: '2135',
    Name: 'Manager, risk, information and cyber security'
  },
  {
    Id: 3071,
    Code: '2135',
    Name: 'Manager, risk, IT'
  },
  {
    Id: 3072,
    Code: '2135',
    Name: 'Manager, risk, security, information, computing'
  },
  {
    Id: 3073,
    Code: '2135',
    Name: 'Manager, security, assurance, information, computing'
  },
  {
    Id: 3074,
    Code: '2135',
    Name: 'Manager, security, cyber'
  },
  {
    Id: 3075,
    Code: '2135',
    Name: 'Manager, security, data'
  },
  {
    Id: 3076,
    Code: '2135',
    Name: 'Manager, security, information'
  },
  {
    Id: 3077,
    Code: '2135',
    Name: 'Manager, security, IT'
  },
  {
    Id: 3078,
    Code: '2135',
    Name: 'Manager, security, computer services'
  },
  {
    Id: 3079,
    Code: '2135',
    Name: 'Manager, security operations and incident response, computing'
  },
  {
    Id: 3080,
    Code: '2135',
    Name: 'Officer, security, information'
  },
  {
    Id: 3081,
    Code: '2135',
    Name: 'Officer, security, IT'
  },
  {
    Id: 3082,
    Code: '2135',
    Name: 'Officer, security, network, IT'
  },
  {
    Id: 3083,
    Code: '2135',
    Name: 'Officer, security and information, computing'
  },
  {
    Id: 3084,
    Code: '2135',
    Name: 'Responder, incident, security, cyber'
  },
  {
    Id: 3085,
    Code: '2135',
    Name: 'Specialist, governance, security, cyber'
  },
  {
    Id: 3086,
    Code: '2135',
    Name: 'Specialist, intelligence, cyber'
  },
  {
    Id: 3087,
    Code: '2135',
    Name: 'Specialist, operations, security, cyber'
  },
  {
    Id: 3088,
    Code: '2135',
    Name: 'Specialist, security, cyber'
  },
  {
    Id: 3089,
    Code: '2135',
    Name: 'Specialist, security, risk and assurance, computing'
  },
  {
    Id: 3090,
    Code: '2135',
    Name: 'Tester, penetration'
  },
  {
    Id: 3091,
    Code: '2136',
    Name: 'Analyst, quality, computing'
  },
  {
    Id: 3092,
    Code: '2136',
    Name: 'Analyst, test, automation'
  },
  {
    Id: 3093,
    Code: '2136',
    Name: 'Analyst, test, software'
  },
  {
    Id: 3094,
    Code: '2136',
    Name: 'Analyst, test, computing'
  },
  {
    Id: 3095,
    Code: '2136',
    Name: 'Auditor, IT'
  },
  {
    Id: 3096,
    Code: '2136',
    Name: 'Consultant, test, software'
  },
  {
    Id: 3097,
    Code: '2136',
    Name: 'Consultant, test'
  },
  {
    Id: 3098,
    Code: '2136',
    Name: 'Consultant, testing, software'
  },
  {
    Id: 3099,
    Code: '2136',
    Name: 'Consultant, testing'
  },
  {
    Id: 3100,
    Code: '2136',
    Name: 'Engineer, assurance, quality, computing'
  },
  {
    Id: 3101,
    Code: '2136',
    Name: 'Engineer, QA, computing'
  },
  {
    Id: 3102,
    Code: '2136',
    Name: 'Engineer, quality, professional, computing'
  },
  {
    Id: 3103,
    Code: '2136',
    Name: 'Engineer, test, software'
  },
  {
    Id: 3104,
    Code: '2136',
    Name: 'Engineer, test, professional, software'
  },
  {
    Id: 3105,
    Code: '2136',
    Name: 'Lead, test, computing'
  },
  {
    Id: 3106,
    Code: '2136',
    Name: 'Leader, team, QA, computing'
  },
  {
    Id: 3107,
    Code: '2136',
    Name: 'Leader, team, test, computing'
  },
  {
    Id: 3108,
    Code: '2136',
    Name: 'Tester, acceptance, user'
  },
  {
    Id: 3109,
    Code: '2136',
    Name: 'Tester, software'
  },
  {
    Id: 3110,
    Code: '2136',
    Name: 'Tester, systems, computing'
  },
  {
    Id: 3111,
    Code: '2137',
    Name: 'Analyst, network'
  },
  {
    Id: 3112,
    Code: '2137',
    Name: 'Consultant, network'
  },
  {
    Id: 3113,
    Code: '2137',
    Name: 'Consultant, services, network'
  },
  {
    Id: 3114,
    Code: '2137',
    Name: 'Designer, network'
  },
  {
    Id: 3115,
    Code: '2137',
    Name: 'Engineer, design, network, computing'
  },
  {
    Id: 3116,
    Code: '2137',
    Name: 'Engineer, installation, network, computing'
  },
  {
    Id: 3117,
    Code: '2137',
    Name: 'Engineer, integration, network'
  },
  {
    Id: 3118,
    Code: '2137',
    Name: 'Engineer, network'
  },
  {
    Id: 3119,
    Code: '2137',
    Name: 'Engineer, support, network'
  },
  {
    Id: 3120,
    Code: '2137',
    Name: 'Engineer, systems, linux'
  },
  {
    Id: 3121,
    Code: '2137',
    Name: 'Engineer, systems, network'
  },
  {
    Id: 3122,
    Code: '2137',
    Name: 'Planner, network'
  },
  {
    Id: 3123,
    Code: '2137',
    Name: 'Specialist, network'
  },
  {
    Id: 3124,
    Code: '2139',
    Name: 'Analyst, management, application'
  },
  {
    Id: 3125,
    Code: '2139',
    Name: 'Consultant, business, IT'
  },
  {
    Id: 3126,
    Code: '2139',
    Name: 'Consultant, business, technical, computing'
  },
  {
    Id: 3127,
    Code: '2139',
    Name: 'Consultant, business, computing'
  },
  {
    Id: 3128,
    Code: '2139',
    Name: 'Consultant, communications'
  },
  {
    Id: 3129,
    Code: '2139',
    Name: 'Consultant, computer'
  },
  {
    Id: 3130,
    Code: '2139',
    Name: 'Consultant, computing'
  },
  {
    Id: 3131,
    Code: '2139',
    Name: 'Consultant, data'
  },
  {
    Id: 3132,
    Code: '2139',
    Name: 'Consultant, digital'
  },
  {
    Id: 3133,
    Code: '2139',
    Name: 'Consultant, internet'
  },
  {
    Id: 3134,
    Code: '2139',
    Name: 'Consultant, IT'
  },
  {
    Id: 3135,
    Code: '2139',
    Name: 'Consultant, management, IT'
  },
  {
    Id: 3136,
    Code: '2139',
    Name: 'Consultant, managing, computing'
  },
  {
    Id: 3137,
    Code: '2139',
    Name: 'Consultant, SAP'
  },
  {
    Id: 3138,
    Code: '2139',
    Name: 'Consultant, support, technical'
  },
  {
    Id: 3139,
    Code: '2139',
    Name: 'Consultant, technical, computer'
  },
  {
    Id: 3140,
    Code: '2139',
    Name: 'Consultant, technical, computer services'
  },
  {
    Id: 3141,
    Code: '2139',
    Name: 'Consultant, technology, information'
  },
  {
    Id: 3142,
    Code: '2139',
    Name: 'Consultant, technology, computer services'
  },
  {
    Id: 3143,
    Code: '2139',
    Name: 'Consultant, telecommunications'
  },
  {
    Id: 3144,
    Code: '2139',
    Name: 'Consultant, telecoms'
  },
  {
    Id: 3145,
    Code: '2139',
    Name: 'Consultant, web'
  },
  {
    Id: 3146,
    Code: '2139',
    Name: 'Consultant, computing'
  },
  {
    Id: 3147,
    Code: '2139',
    Name: 'Contractor, IT'
  },
  {
    Id: 3148,
    Code: '2139',
    Name: 'Engineer, DevOps'
  },
  {
    Id: 3149,
    Code: '2139',
    Name: 'Engineer, internet, professional'
  },
  {
    Id: 3150,
    Code: '2139',
    Name: 'Evangelist, technical'
  },
  {
    Id: 3151,
    Code: '2139',
    Name: 'Evangelist, technology'
  },
  {
    Id: 3152,
    Code: '2139',
    Name: 'Executive, communications, web'
  },
  {
    Id: 3153,
    Code: '2139',
    Name: 'Facilitator, online'
  },
  {
    Id: 3154,
    Code: '2139',
    Name: 'Manager, account, IT'
  },
  {
    Id: 3155,
    Code: '2139',
    Name: 'Manager, account, technical'
  },
  {
    Id: 3156,
    Code: '2139',
    Name: 'Manager, account, computing'
  },
  {
    Id: 3157,
    Code: '2139',
    Name: 'Manager, site, web'
  },
  {
    Id: 3158,
    Code: '2139',
    Name: 'Manager, web'
  },
  {
    Id: 3159,
    Code: '2139',
    Name: 'Manager, website'
  },
  {
    Id: 3160,
    Code: '2139',
    Name: 'Master, web'
  },
  {
    Id: 3161,
    Code: '2139',
    Name: 'Planner, IT'
  },
  {
    Id: 3162,
    Code: '2139',
    Name: 'Planner, telecommunications'
  },
  {
    Id: 3163,
    Code: '2139',
    Name: 'Webmaster, online, education'
  },
  {
    Id: 3164,
    Code: '2139',
    Name: 'Webmaster'
  },
  {
    Id: 3165,
    Code: '2141',
    Name: 'Analyst, experience, user, computing'
  },
  {
    Id: 3166,
    Code: '2141',
    Name: 'Analyst, UX, computing'
  },
  {
    Id: 3167,
    Code: '2141',
    Name: 'Assistant, design, web'
  },
  {
    Id: 3168,
    Code: '2141',
    Name: 'Consultant, design, web'
  },
  {
    Id: 3169,
    Code: '2141',
    Name: 'Designer, app, web'
  },
  {
    Id: 3170,
    Code: '2141',
    Name: 'Designer, app'
  },
  {
    Id: 3171,
    Code: '2141',
    Name: 'Designer, application, web'
  },
  {
    Id: 3172,
    Code: '2141',
    Name: 'Designer, applications, web'
  },
  {
    Id: 3173,
    Code: '2141',
    Name: 'Designer, applications, computing'
  },
  {
    Id: 3174,
    Code: '2141',
    Name: 'Designer, experience, user, computing'
  },
  {
    Id: 3175,
    Code: '2141',
    Name: 'Designer, interface, user'
  },
  {
    Id: 3176,
    Code: '2141',
    Name: 'Designer, media, interactive'
  },
  {
    Id: 3177,
    Code: '2141',
    Name: 'Designer, media, new'
  },
  {
    Id: 3178,
    Code: '2141',
    Name: 'Designer, UI'
  },
  {
    Id: 3179,
    Code: '2141',
    Name: 'Designer, UX'
  },
  {
    Id: 3180,
    Code: '2141',
    Name: 'Designer, UX/UI'
  },
  {
    Id: 3181,
    Code: '2141',
    Name: 'Designer, visual'
  },
  {
    Id: 3182,
    Code: '2141',
    Name: 'Designer, web'
  },
  {
    Id: 3183,
    Code: '2141',
    Name: 'Designer, website'
  },
  {
    Id: 3184,
    Code: '2141',
    Name: 'Producer, digital'
  },
  {
    Id: 3185,
    Code: '2141',
    Name: 'Producer, web'
  },
  {
    Id: 3186,
    Code: '2141',
    Name: 'Producer, website'
  },
  {
    Id: 3187,
    Code: '2141',
    Name: 'Publisher, desktop'
  },
  {
    Id: 3188,
    Code: '2141',
    Name: 'Publisher, top, desk'
  },
  {
    Id: 3189,
    Code: '2141',
    Name: 'Researcher, experience, user, computing'
  },
  {
    Id: 3190,
    Code: '2141',
    Name: 'Researcher, user, computing'
  },
  {
    Id: 3191,
    Code: '2141',
    Name: 'Researcher, UX, computing'
  },
  {
    Id: 3192,
    Code: '2142',
    Name: 'Animator, 2D, computer'
  },
  {
    Id: 3193,
    Code: '2142',
    Name: 'Animator, 2D'
  },
  {
    Id: 3194,
    Code: '2142',
    Name: 'Animator, 3D, computer'
  },
  {
    Id: 3195,
    Code: '2142',
    Name: 'Animator, 3D'
  },
  {
    Id: 3196,
    Code: '2142',
    Name: 'Animator, computer'
  },
  {
    Id: 3197,
    Code: '2142',
    Name: 'Animator, effects, visual'
  },
  {
    Id: 3198,
    Code: '2142',
    Name: 'Animator, motion, stop'
  },
  {
    Id: 3199,
    Code: '2142',
    Name: 'Animator, VFX'
  },
  {
    Id: 3200,
    Code: '2142',
    Name: 'Animator, computing'
  },
  {
    Id: 3201,
    Code: '2142',
    Name: 'Artist, 3D'
  },
  {
    Id: 3202,
    Code: '2142',
    Name: 'Artist, commercial'
  },
  {
    Id: 3203,
    Code: '2142',
    Name: 'Artist, computer'
  },
  {
    Id: 3204,
    Code: '2142',
    Name: 'Artist, digital'
  },
  {
    Id: 3205,
    Code: '2142',
    Name: 'Artist, effects, digital'
  },
  {
    Id: 3206,
    Code: '2142',
    Name: 'Artist, effects, visual'
  },
  {
    Id: 3207,
    Code: '2142',
    Name: 'Artist, environment'
  },
  {
    Id: 3208,
    Code: '2142',
    Name: 'Artist, games, computer'
  },
  {
    Id: 3209,
    Code: '2142',
    Name: 'Artist, graphic'
  },
  {
    Id: 3210,
    Code: '2142',
    Name: 'Artist, layout'
  },
  {
    Id: 3211,
    Code: '2142',
    Name: 'Artist, lead, games development'
  },
  {
    Id: 3212,
    Code: '2142',
    Name: 'Artist, lettering'
  },
  {
    Id: 3213,
    Code: '2142',
    Name: 'Artist, litho'
  },
  {
    Id: 3214,
    Code: '2142',
    Name: 'Artist, lithographic'
  },
  {
    Id: 3215,
    Code: '2142',
    Name: 'Artist, MAC'
  },
  {
    Id: 3216,
    Code: '2142',
    Name: 'Artist, stereo'
  },
  {
    Id: 3217,
    Code: '2142',
    Name: 'Artist, technical'
  },
  {
    Id: 3218,
    Code: '2142',
    Name: 'Artist, texture'
  },
  {
    Id: 3219,
    Code: '2142',
    Name: 'Artist, VFX'
  },
  {
    Id: 3220,
    Code: '2142',
    Name: 'Artist, games development'
  },
  {
    Id: 3221,
    Code: '2142',
    Name: 'Artworker, creative'
  },
  {
    Id: 3222,
    Code: '2142',
    Name: 'Artworker, digital'
  },
  {
    Id: 3223,
    Code: '2142',
    Name: 'Artworker, senior'
  },
  {
    Id: 3224,
    Code: '2142',
    Name: 'Artworker'
  },
  {
    Id: 3225,
    Code: '2142',
    Name: 'Assistant, design, creative'
  },
  {
    Id: 3226,
    Code: '2142',
    Name: 'Assistant, design, graphic'
  },
  {
    Id: 3227,
    Code: '2142',
    Name: 'Assistant, design, publishing'
  },
  {
    Id: 3228,
    Code: '2142',
    Name: 'Assistant, graphics'
  },
  {
    Id: 3229,
    Code: '2142',
    Name: 'Assistant, publications, desk top publishing'
  },
  {
    Id: 3230,
    Code: '2142',
    Name: 'Assistant, publishing, desk top publishing'
  },
  {
    Id: 3231,
    Code: '2142',
    Name: 'Communicator, graphic'
  },
  {
    Id: 3232,
    Code: '2142',
    Name: 'Compositor, special effects'
  },
  {
    Id: 3233,
    Code: '2142',
    Name: 'Consultant, design, graphic'
  },
  {
    Id: 3234,
    Code: '2142',
    Name: 'Controller, display'
  },
  {
    Id: 3235,
    Code: '2142',
    Name: 'Coordinator, design, graphic'
  },
  {
    Id: 3236,
    Code: '2142',
    Name: 'Copier, design'
  },
  {
    Id: 3237,
    Code: '2142',
    Name: 'Copyist, design'
  },
  {
    Id: 3238,
    Code: '2142',
    Name: "Copyist, designer's"
  },
  {
    Id: 3239,
    Code: '2142',
    Name: 'Copyist, textile printing'
  },
  {
    Id: 3240,
    Code: '2142',
    Name: 'Decorator, display'
  },
  {
    Id: 3241,
    Code: '2142',
    Name: 'Designer, 3D'
  },
  {
    Id: 3242,
    Code: '2142',
    Name: 'Designer, advert'
  },
  {
    Id: 3243,
    Code: '2142',
    Name: 'Designer, art'
  },
  {
    Id: 3244,
    Code: '2142',
    Name: 'Designer, digital'
  },
  {
    Id: 3245,
    Code: '2142',
    Name: 'Designer, display'
  },
  {
    Id: 3246,
    Code: '2142',
    Name: 'Designer, exhibition'
  },
  {
    Id: 3247,
    Code: '2142',
    Name: 'Designer, graphic'
  },
  {
    Id: 3248,
    Code: '2142',
    Name: 'Designer, graphic and web'
  },
  {
    Id: 3249,
    Code: '2142',
    Name: 'Designer, graphics, motion'
  },
  {
    Id: 3250,
    Code: '2142',
    Name: 'Designer, infographic'
  },
  {
    Id: 3251,
    Code: '2142',
    Name: 'Designer, multimedia'
  },
  {
    Id: 3252,
    Code: '2142',
    Name: 'Designer, visualisation, data'
  },
  {
    Id: 3253,
    Code: '2142',
    Name: 'Designer, graphic design'
  },
  {
    Id: 3254,
    Code: '2142',
    Name: 'Designer, advertising'
  },
  {
    Id: 3255,
    Code: '2142',
    Name: 'Director, technical, effects'
  },
  {
    Id: 3256,
    Code: '2142',
    Name: 'Director, technical, FX'
  },
  {
    Id: 3257,
    Code: '2142',
    Name: 'Director, technical, VFX'
  },
  {
    Id: 3258,
    Code: '2142',
    Name: 'Illuminator'
  },
  {
    Id: 3259,
    Code: '2142',
    Name: 'Illustrator, digital'
  },
  {
    Id: 3260,
    Code: '2142',
    Name: 'Illustrator, technical'
  },
  {
    Id: 3261,
    Code: '2142',
    Name: 'Modeller, 3D'
  },
  {
    Id: 3262,
    Code: '2142',
    Name: 'Modeller, environment'
  },
  {
    Id: 3263,
    Code: '2142',
    Name: 'Officer, graphics'
  },
  {
    Id: 3264,
    Code: '2142',
    Name: 'Officer, services, creative'
  },
  {
    Id: 3265,
    Code: '2142',
    Name: 'Operator, DTP'
  },
  {
    Id: 3266,
    Code: '2142',
    Name: 'Operator, MAC, Apple'
  },
  {
    Id: 3267,
    Code: '2142',
    Name: 'Operator, MAC'
  },
  {
    Id: 3268,
    Code: '2142',
    Name: 'Operator, publishing, top, desk'
  },
  {
    Id: 3269,
    Code: '2142',
    Name: 'Setter-out, technical drawings'
  },
  {
    Id: 3270,
    Code: '2142',
    Name: 'Supervisor, animation'
  },
  {
    Id: 3271,
    Code: '2142',
    Name: 'Supervisor, effects, visual'
  },
  {
    Id: 3272,
    Code: '2142',
    Name: 'Supervisor, VFX'
  },
  {
    Id: 3273,
    Code: '2142',
    Name: 'Technician, effects, special'
  },
  {
    Id: 3274,
    Code: '2142',
    Name: 'Technician, effects, visual'
  },
  {
    Id: 3275,
    Code: '2142',
    Name: 'Technician, graphics'
  },
  {
    Id: 3276,
    Code: '2142',
    Name: 'Technician, VFX'
  },
  {
    Id: 3277,
    Code: '2142',
    Name: 'Visualiser, 3D'
  },
  {
    Id: 3278,
    Code: '2142',
    Name: 'Visualiser, advertising'
  },
  {
    Id: 3279,
    Code: '2151',
    Name: 'Adviser, conservation'
  },
  {
    Id: 3280,
    Code: '2151',
    Name: 'Adviser, countryside'
  },
  {
    Id: 3281,
    Code: '2151',
    Name: 'Adviser, management, land'
  },
  {
    Id: 3282,
    Code: '2151',
    Name: 'Conservationist, marine'
  },
  {
    Id: 3283,
    Code: '2151',
    Name: 'Conservationist, nature, coastal'
  },
  {
    Id: 3284,
    Code: '2151',
    Name: 'Conservationist'
  },
  {
    Id: 3285,
    Code: '2151',
    Name: 'Consultant, ecological'
  },
  {
    Id: 3286,
    Code: '2151',
    Name: 'Ecologist, marine'
  },
  {
    Id: 3287,
    Code: '2151',
    Name: 'Ecologist'
  },
  {
    Id: 3288,
    Code: '2151',
    Name: 'Head of conservation'
  },
  {
    Id: 3289,
    Code: '2151',
    Name: 'Inspector, buildings, historic'
  },
  {
    Id: 3290,
    Code: '2151',
    Name: 'Inspector, historic buildings'
  },
  {
    Id: 3291,
    Code: '2151',
    Name: 'Interpreter, heritage, conservation'
  },
  {
    Id: 3292,
    Code: '2151',
    Name: 'Manager, conservation'
  },
  {
    Id: 3293,
    Code: '2151',
    Name: 'Manager, countryside'
  },
  {
    Id: 3294,
    Code: '2151',
    Name: 'Manager, heritage'
  },
  {
    Id: 3295,
    Code: '2151',
    Name: 'Manager, park, national'
  },
  {
    Id: 3296,
    Code: '2151',
    Name: 'Manager, reserve, nature'
  },
  {
    Id: 3297,
    Code: '2151',
    Name: 'Officer, access, countryside'
  },
  {
    Id: 3298,
    Code: '2151',
    Name: 'Officer, access, conservation, local government'
  },
  {
    Id: 3299,
    Code: '2151',
    Name: 'Officer, conservancy'
  },
  {
    Id: 3300,
    Code: '2151',
    Name: 'Officer, conservation, assistant, government'
  },
  {
    Id: 3301,
    Code: '2151',
    Name: 'Officer, conservation, chief, government'
  },
  {
    Id: 3302,
    Code: '2151',
    Name: 'Officer, conservation, energy'
  },
  {
    Id: 3303,
    Code: '2151',
    Name: 'Officer, conservation, senior, government'
  },
  {
    Id: 3304,
    Code: '2151',
    Name: 'Officer, conservation, water'
  },
  {
    Id: 3305,
    Code: '2151',
    Name: 'Officer, conservation, government'
  },
  {
    Id: 3306,
    Code: '2151',
    Name: 'Officer, conservation'
  },
  {
    Id: 3307,
    Code: '2151',
    Name: 'Officer, countryside'
  },
  {
    Id: 3308,
    Code: '2151',
    Name: 'Officer, development, rural'
  },
  {
    Id: 3309,
    Code: '2151',
    Name: 'Officer, district, Forestry Commission'
  },
  {
    Id: 3310,
    Code: '2151',
    Name: 'Officer, divisional, Forestry Commission'
  },
  {
    Id: 3311,
    Code: '2151',
    Name: 'Officer, ecology'
  },
  {
    Id: 3312,
    Code: '2151',
    Name: 'Officer, field, conservation'
  },
  {
    Id: 3313,
    Code: '2151',
    Name: 'Officer, heritage'
  },
  {
    Id: 3314,
    Code: '2151',
    Name: 'Officer, park, national'
  },
  {
    Id: 3315,
    Code: '2151',
    Name: 'Scientist, landscape'
  },
  {
    Id: 3316,
    Code: '2152',
    Name: 'Adviser, environmental'
  },
  {
    Id: 3317,
    Code: '2152',
    Name: 'Campaigner, environmental'
  },
  {
    Id: 3318,
    Code: '2152',
    Name: 'Chemist, environmental'
  },
  {
    Id: 3319,
    Code: '2152',
    Name: 'Consultant, environmental'
  },
  {
    Id: 3320,
    Code: '2152',
    Name: 'Consultant, geo-environmental'
  },
  {
    Id: 3321,
    Code: '2152',
    Name: 'Consultant, sustainability'
  },
  {
    Id: 3322,
    Code: '2152',
    Name: 'Coordinator, environmental'
  },
  {
    Id: 3323,
    Code: '2152',
    Name: 'Engineer, environmental'
  },
  {
    Id: 3324,
    Code: '2152',
    Name: 'Engineer, geoenvironmental'
  },
  {
    Id: 3325,
    Code: '2152',
    Name: 'Engineer, land, contaminated'
  },
  {
    Id: 3326,
    Code: '2152',
    Name: 'Engineer, landfill'
  },
  {
    Id: 3327,
    Code: '2152',
    Name: 'Engineer, professional, environmental'
  },
  {
    Id: 3328,
    Code: '2152',
    Name: 'Environmentalist'
  },
  {
    Id: 3329,
    Code: '2152',
    Name: 'Manager, energy'
  },
  {
    Id: 3330,
    Code: '2152',
    Name: 'Manager, environmental'
  },
  {
    Id: 3331,
    Code: '2152',
    Name: 'Manager, risk, flood'
  },
  {
    Id: 3332,
    Code: '2152',
    Name: 'Manager, safety and environmental, health'
  },
  {
    Id: 3333,
    Code: '2152',
    Name: 'Manager, sustainability'
  },
  {
    Id: 3334,
    Code: '2152',
    Name: 'Manager, warning, flood'
  },
  {
    Id: 3335,
    Code: '2152',
    Name: 'Manager, Environment Agency'
  },
  {
    Id: 3336,
    Code: '2152',
    Name: 'Manager, environmental consultancy'
  },
  {
    Id: 3337,
    Code: '2152',
    Name: 'Officer, carbon and sustainability'
  },
  {
    Id: 3338,
    Code: '2152',
    Name: 'Officer, control, environmental'
  },
  {
    Id: 3339,
    Code: '2152',
    Name: 'Officer, defence, flood'
  },
  {
    Id: 3340,
    Code: '2152',
    Name: 'Officer, education, environmental'
  },
  {
    Id: 3341,
    Code: '2152',
    Name: 'Officer, environment'
  },
  {
    Id: 3342,
    Code: '2152',
    Name: 'Officer, environmental'
  },
  {
    Id: 3343,
    Code: '2152',
    Name: 'Officer, interpretation'
  },
  {
    Id: 3344,
    Code: '2152',
    Name: 'Officer, interpretative'
  },
  {
    Id: 3345,
    Code: '2152',
    Name: 'Officer, protection, environmental'
  },
  {
    Id: 3346,
    Code: '2152',
    Name: 'Officer, sustainability'
  },
  {
    Id: 3347,
    Code: '2152',
    Name: 'Officer, warning, flood'
  },
  {
    Id: 3348,
    Code: '2152',
    Name: 'Scientist, environmental'
  },
  {
    Id: 3349,
    Code: '2152',
    Name: 'Scientist, quality, water'
  },
  {
    Id: 3350,
    Code: '2152',
    Name: 'Specialist, environmental'
  },
  {
    Id: 3351,
    Code: '2152',
    Name: 'Specialist, geoenvironmental'
  },
  {
    Id: 3352,
    Code: '2152',
    Name: 'Specialist, land, contaminated'
  },
  {
    Id: 3353,
    Code: '2152',
    Name: 'Technician, environmental'
  },
  {
    Id: 3354,
    Code: '2161',
    Name: 'Head, research and development'
  },
  {
    Id: 3355,
    Code: '2161',
    Name: 'Head of design'
  },
  {
    Id: 3356,
    Code: '2161',
    Name: 'Head of product design'
  },
  {
    Id: 3357,
    Code: '2161',
    Name: 'Head of research'
  },
  {
    Id: 3358,
    Code: '2161',
    Name: 'Head of research and development'
  },
  {
    Id: 3359,
    Code: '2161',
    Name: 'Inventor'
  },
  {
    Id: 3360,
    Code: '2161',
    Name: 'Manager, analytics'
  },
  {
    Id: 3361,
    Code: '2161',
    Name: 'Manager, creative, research and development'
  },
  {
    Id: 3362,
    Code: '2161',
    Name: 'Manager, design'
  },
  {
    Id: 3363,
    Code: '2161',
    Name: 'Manager, development, process, manufacturing'
  },
  {
    Id: 3364,
    Code: '2161',
    Name: 'Manager, development, research and development'
  },
  {
    Id: 3365,
    Code: '2161',
    Name: 'Manager, information, research and development'
  },
  {
    Id: 3366,
    Code: '2161',
    Name: 'Manager, insight'
  },
  {
    Id: 3367,
    Code: '2161',
    Name: 'Manager, insights, consumer'
  },
  {
    Id: 3368,
    Code: '2161',
    Name: 'Manager, insights'
  },
  {
    Id: 3369,
    Code: '2161',
    Name: 'Manager, laboratory'
  },
  {
    Id: 3370,
    Code: '2161',
    Name: 'Manager, link, passenger, government'
  },
  {
    Id: 3371,
    Code: '2161',
    Name: 'Manager, NPD'
  },
  {
    Id: 3372,
    Code: '2161',
    Name: 'Manager, product, research and development'
  },
  {
    Id: 3373,
    Code: '2161',
    Name: 'Manager, project, research and development'
  },
  {
    Id: 3374,
    Code: '2161',
    Name: 'Manager, research, market'
  },
  {
    Id: 3375,
    Code: '2161',
    Name: 'Manager, research, agricultural'
  },
  {
    Id: 3376,
    Code: '2161',
    Name: 'Manager, research, biochemical'
  },
  {
    Id: 3377,
    Code: '2161',
    Name: 'Manager, research, biological'
  },
  {
    Id: 3378,
    Code: '2161',
    Name: 'Manager, research, botanical'
  },
  {
    Id: 3379,
    Code: '2161',
    Name: 'Manager, research, chemical'
  },
  {
    Id: 3380,
    Code: '2161',
    Name: 'Manager, research, engineering, electrical'
  },
  {
    Id: 3381,
    Code: '2161',
    Name: 'Manager, research, engineering, electronic'
  },
  {
    Id: 3382,
    Code: '2161',
    Name: 'Manager, research, engineering, mechanical'
  },
  {
    Id: 3383,
    Code: '2161',
    Name: 'Manager, research, geological'
  },
  {
    Id: 3384,
    Code: '2161',
    Name: 'Manager, research, historical'
  },
  {
    Id: 3385,
    Code: '2161',
    Name: 'Manager, research, horticultural'
  },
  {
    Id: 3386,
    Code: '2161',
    Name: 'Manager, research, medical'
  },
  {
    Id: 3387,
    Code: '2161',
    Name: 'Manager, research, meteorological'
  },
  {
    Id: 3388,
    Code: '2161',
    Name: 'Manager, research, physical science'
  },
  {
    Id: 3389,
    Code: '2161',
    Name: 'Manager, research, zoological'
  },
  {
    Id: 3390,
    Code: '2161',
    Name: 'Manager, research, broadcasting'
  },
  {
    Id: 3391,
    Code: '2161',
    Name: 'Manager, research, government'
  },
  {
    Id: 3392,
    Code: '2161',
    Name: 'Manager, research, journalism'
  },
  {
    Id: 3393,
    Code: '2161',
    Name: 'Manager, research, printing and publishing'
  },
  {
    Id: 3394,
    Code: '2161',
    Name: 'Manager, research'
  },
  {
    Id: 3395,
    Code: '2161',
    Name: 'Manager, research and development'
  },
  {
    Id: 3396,
    Code: '2161',
    Name: 'Manager, RM&G'
  },
  {
    Id: 3397,
    Code: '2161',
    Name: 'Manager, services, forensic'
  },
  {
    Id: 3398,
    Code: '2161',
    Name: 'Manager, study, clinical'
  },
  {
    Id: 3399,
    Code: '2161',
    Name: 'Manager, technical, research and development'
  },
  {
    Id: 3400,
    Code: '2161',
    Name: 'Manager, trial, clinical'
  },
  {
    Id: 3401,
    Code: '2161',
    Name: 'Manager, trials, clinical'
  },
  {
    Id: 3402,
    Code: '2161',
    Name: 'Manager, tunnel, wind'
  },
  {
    Id: 3403,
    Code: '2161',
    Name: 'Manager, research and development'
  },
  {
    Id: 3404,
    Code: '2161',
    Name: 'Manager of field trials, National Institute of Agricultural Botany'
  },
  {
    Id: 3405,
    Code: '2162',
    Name: 'Assistant, research, university'
  },
  {
    Id: 3406,
    Code: '2162',
    Name: 'Associate, research, university'
  },
  {
    Id: 3407,
    Code: '2162',
    Name: 'Fellow, research, university, nos'
  },
  {
    Id: 3408,
    Code: '2162',
    Name: 'Fellow, research, university'
  },
  {
    Id: 3409,
    Code: '2162',
    Name: 'Officer, research, university'
  },
  {
    Id: 3410,
    Code: '2162',
    Name: 'Researcher, postdoctoral'
  },
  {
    Id: 3411,
    Code: '2162',
    Name: 'Researcher, university'
  },
  {
    Id: 3412,
    Code: '2162',
    Name: 'Researcher, university'
  },
  {
    Id: 3413,
    Code: '2211',
    Name: 'Adviser, medical'
  },
  {
    Id: 3414,
    Code: '2211',
    Name: 'Assistant, clinical, qualified'
  },
  {
    Id: 3415,
    Code: '2211',
    Name: 'Doctor, health, public'
  },
  {
    Id: 3416,
    Code: '2211',
    Name: 'Doctor, hospital'
  },
  {
    Id: 3417,
    Code: '2211',
    Name: 'Doctor, medical'
  },
  {
    Id: 3418,
    Code: '2211',
    Name: 'Doctor'
  },
  {
    Id: 3419,
    Code: '2211',
    Name: 'Doctor of medicine'
  },
  {
    Id: 3420,
    Code: '2211',
    Name: 'Examiner, medical, Jobcentre Plus'
  },
  {
    Id: 3421,
    Code: '2211',
    Name: 'FRCP'
  },
  {
    Id: 3422,
    Code: '2211',
    Name: 'GP'
  },
  {
    Id: 3423,
    Code: '2211',
    Name: 'LRCP'
  },
  {
    Id: 3424,
    Code: '2211',
    Name: 'Lecturer, medicine'
  },
  {
    Id: 3425,
    Code: '2211',
    Name: 'MRCP'
  },
  {
    Id: 3426,
    Code: '2211',
    Name: 'Officer, house, hospital service'
  },
  {
    Id: 3427,
    Code: '2211',
    Name: 'Officer, medical'
  },
  {
    Id: 3428,
    Code: '2211',
    Name: 'Physician'
  },
  {
    Id: 3429,
    Code: '2211',
    Name: 'Practitioner, general'
  },
  {
    Id: 3430,
    Code: '2211',
    Name: 'Practitioner, medical, general'
  },
  {
    Id: 3431,
    Code: '2211',
    Name: 'Practitioner, medical, registered'
  },
  {
    Id: 3432,
    Code: '2211',
    Name: 'Practitioner, medical'
  },
  {
    Id: 3433,
    Code: '2211',
    Name: 'Reader, medicine'
  },
  {
    Id: 3434,
    Code: '2211',
    Name: 'Referee, medical, government'
  },
  {
    Id: 3435,
    Code: '2211',
    Name: 'Registrar, GP'
  },
  {
    Id: 3436,
    Code: '2211',
    Name: 'Registrar, practice, general'
  },
  {
    Id: 3437,
    Code: '2211',
    Name: 'Superintendent, medical'
  },
  {
    Id: 3438,
    Code: '2212',
    Name: 'Acupuncturist, medical'
  },
  {
    Id: 3439,
    Code: '2212',
    Name: 'Anaesthetist, consultant'
  },
  {
    Id: 3440,
    Code: '2212',
    Name: 'Anaesthetist'
  },
  {
    Id: 3441,
    Code: '2212',
    Name: 'Andrologist'
  },
  {
    Id: 3442,
    Code: '2212',
    Name: 'Cardiologist, consultant'
  },
  {
    Id: 3443,
    Code: '2212',
    Name: 'Cardiologist'
  },
  {
    Id: 3444,
    Code: '2212',
    Name: 'Consultant, care, palliative'
  },
  {
    Id: 3445,
    Code: '2212',
    Name: 'Consultant, haematology'
  },
  {
    Id: 3446,
    Code: '2212',
    Name: 'Consultant, hospital'
  },
  {
    Id: 3447,
    Code: '2212',
    Name: 'Consultant, medical'
  },
  {
    Id: 3448,
    Code: '2212',
    Name: 'Consultant, orthopaedic'
  },
  {
    Id: 3449,
    Code: '2212',
    Name: 'Consultant, medical'
  },
  {
    Id: 3450,
    Code: '2212',
    Name: 'Consultant, hospital service'
  },
  {
    Id: 3451,
    Code: '2212',
    Name: 'Consultant in public health medicine, health authority'
  },
  {
    Id: 3452,
    Code: '2212',
    Name: 'Dermatologist'
  },
  {
    Id: 3453,
    Code: '2212',
    Name: 'Doctor, care, palliative'
  },
  {
    Id: 3454,
    Code: '2212',
    Name: 'Doctor, paediatric'
  },
  {
    Id: 3455,
    Code: '2212',
    Name: 'Doctor, speciality'
  },
  {
    Id: 3456,
    Code: '2212',
    Name: 'Doctor, specialty'
  },
  {
    Id: 3457,
    Code: '2212',
    Name: 'Examiner, medical, forensic'
  },
  {
    Id: 3458,
    Code: '2212',
    Name: 'FRCOG'
  },
  {
    Id: 3459,
    Code: '2212',
    Name: 'FRCS'
  },
  {
    Id: 3460,
    Code: '2212',
    Name: 'Fellow, clinical'
  },
  {
    Id: 3461,
    Code: '2212',
    Name: 'Fellow, research, clinical'
  },
  {
    Id: 3462,
    Code: '2212',
    Name: 'Fellow, research, medicine'
  },
  {
    Id: 3463,
    Code: '2212',
    Name: 'Fellow, medicine'
  },
  {
    Id: 3464,
    Code: '2212',
    Name: 'Fellow, research, medicine'
  },
  {
    Id: 3465,
    Code: '2212',
    Name: 'Fellow, research, surgery'
  },
  {
    Id: 3466,
    Code: '2212',
    Name: 'Fellow, surgery'
  },
  {
    Id: 3467,
    Code: '2212',
    Name: 'Fellow, medicine, university'
  },
  {
    Id: 3468,
    Code: '2212',
    Name: 'Fellow, surgery, university'
  },
  {
    Id: 3469,
    Code: '2212',
    Name: 'Gastroenterologist, consultant'
  },
  {
    Id: 3470,
    Code: '2212',
    Name: 'Gastroenterologist'
  },
  {
    Id: 3471,
    Code: '2212',
    Name: 'Geriatrician'
  },
  {
    Id: 3472,
    Code: '2212',
    Name: 'Gynaecologist'
  },
  {
    Id: 3473,
    Code: '2212',
    Name: 'Haematologist, consultant'
  },
  {
    Id: 3474,
    Code: '2212',
    Name: 'Haematologist'
  },
  {
    Id: 3475,
    Code: '2212',
    Name: 'Homeopath, medically qualified'
  },
  {
    Id: 3476,
    Code: '2212',
    Name: 'Homoeopath, medically qualified'
  },
  {
    Id: 3477,
    Code: '2212',
    Name: 'Immunologist'
  },
  {
    Id: 3478,
    Code: '2212',
    Name: 'Intensivist, consultant'
  },
  {
    Id: 3479,
    Code: '2212',
    Name: 'Intensivist'
  },
  {
    Id: 3480,
    Code: '2212',
    Name: 'Laryngologist'
  },
  {
    Id: 3481,
    Code: '2212',
    Name: 'Lecturer, surgery'
  },
  {
    Id: 3482,
    Code: '2212',
    Name: 'MRCS'
  },
  {
    Id: 3483,
    Code: '2212',
    Name: 'Nephrologist, consultant'
  },
  {
    Id: 3484,
    Code: '2212',
    Name: 'Neurologist, consultant'
  },
  {
    Id: 3485,
    Code: '2212',
    Name: 'Neurologist'
  },
  {
    Id: 3486,
    Code: '2212',
    Name: 'Neurosurgeon, consultant'
  },
  {
    Id: 3487,
    Code: '2212',
    Name: 'Neurosurgeon'
  },
  {
    Id: 3488,
    Code: '2212',
    Name: 'Obstetrician, consultant'
  },
  {
    Id: 3489,
    Code: '2212',
    Name: 'Obstetrician'
  },
  {
    Id: 3490,
    Code: '2212',
    Name: 'Obstetrician and gynaecologist'
  },
  {
    Id: 3491,
    Code: '2212',
    Name: 'Oncologist, consultant'
  },
  {
    Id: 3492,
    Code: '2212',
    Name: 'Oncologist'
  },
  {
    Id: 3493,
    Code: '2212',
    Name: 'Otolaryngologist'
  },
  {
    Id: 3494,
    Code: '2212',
    Name: 'Otologist'
  },
  {
    Id: 3495,
    Code: '2212',
    Name: 'Otorhinolaryngologist'
  },
  {
    Id: 3496,
    Code: '2212',
    Name: 'Paediatrician'
  },
  {
    Id: 3497,
    Code: '2212',
    Name: 'Physician, consultant'
  },
  {
    Id: 3498,
    Code: '2212',
    Name: 'Physician, pharmaceutical'
  },
  {
    Id: 3499,
    Code: '2212',
    Name: 'Physician, respiratory, consultant'
  },
  {
    Id: 3500,
    Code: '2212',
    Name: 'Practitioner, homeopathic, medically qualified'
  },
  {
    Id: 3501,
    Code: '2212',
    Name: 'Practitioner, homoeopathic, medically qualified'
  },
  {
    Id: 3502,
    Code: '2212',
    Name: 'Proctologist'
  },
  {
    Id: 3503,
    Code: '2212',
    Name: 'Professor, medicine'
  },
  {
    Id: 3504,
    Code: '2212',
    Name: 'Professor, surgery'
  },
  {
    Id: 3505,
    Code: '2212',
    Name: 'Psychiatrist, consultant'
  },
  {
    Id: 3506,
    Code: '2212',
    Name: 'Psychiatrist'
  },
  {
    Id: 3507,
    Code: '2212',
    Name: 'Radiodiagnostician'
  },
  {
    Id: 3508,
    Code: '2212',
    Name: 'Radiologist, consultant'
  },
  {
    Id: 3509,
    Code: '2212',
    Name: 'Radiologist'
  },
  {
    Id: 3510,
    Code: '2212',
    Name: 'Radiotherapist'
  },
  {
    Id: 3511,
    Code: '2212',
    Name: 'Reader, surgery'
  },
  {
    Id: 3512,
    Code: '2212',
    Name: 'Registrar, cardiology'
  },
  {
    Id: 3513,
    Code: '2212',
    Name: 'Registrar, specialty'
  },
  {
    Id: 3514,
    Code: '2212',
    Name: 'Registrar, hospital service'
  },
  {
    Id: 3515,
    Code: '2212',
    Name: 'Registrar, medical practice'
  },
  {
    Id: 3516,
    Code: '2212',
    Name: 'Rheumatologist, consultant'
  },
  {
    Id: 3517,
    Code: '2212',
    Name: 'Rheumatologist'
  },
  {
    Id: 3518,
    Code: '2212',
    Name: 'Specialist, associate, hospital service'
  },
  {
    Id: 3519,
    Code: '2212',
    Name: 'Specialist, ear, nose and throat'
  },
  {
    Id: 3520,
    Code: '2212',
    Name: 'Specialist, nose and throat, ear'
  },
  {
    Id: 3521,
    Code: '2212',
    Name: 'Specialist registrar in psychiatry'
  },
  {
    Id: 3522,
    Code: '2212',
    Name: 'Specialist-registrar'
  },
  {
    Id: 3523,
    Code: '2212',
    Name: 'Surgeon, consultant'
  },
  {
    Id: 3524,
    Code: '2212',
    Name: 'Surgeon, maxillofacial'
  },
  {
    Id: 3525,
    Code: '2212',
    Name: 'Surgeon, orthopaedic, consultant'
  },
  {
    Id: 3526,
    Code: '2212',
    Name: 'Surgeon, plastic, consultant'
  },
  {
    Id: 3527,
    Code: '2212',
    Name: 'Surgeon, podiatric'
  },
  {
    Id: 3528,
    Code: '2212',
    Name: 'Surgeon, urological, consultant'
  },
  {
    Id: 3529,
    Code: '2212',
    Name: 'Surgeon'
  },
  {
    Id: 3530,
    Code: '2212',
    Name: 'Urologist'
  },
  {
    Id: 3531,
    Code: '2212',
    Name: 'Venereologist'
  },
  {
    Id: 3532,
    Code: '2221',
    Name: 'Electro-therapeutist'
  },
  {
    Id: 3533,
    Code: '2221',
    Name: 'Electro-therapist'
  },
  {
    Id: 3534,
    Code: '2221',
    Name: 'Physiotherapist, community'
  },
  {
    Id: 3535,
    Code: '2221',
    Name: 'Physiotherapist, paediatric'
  },
  {
    Id: 3536,
    Code: '2221',
    Name: 'Physiotherapist, specialist'
  },
  {
    Id: 3537,
    Code: '2221',
    Name: 'Physiotherapist, superintendent'
  },
  {
    Id: 3538,
    Code: '2221',
    Name: 'Physiotherapist, veterinary'
  },
  {
    Id: 3539,
    Code: '2221',
    Name: 'Physiotherapist'
  },
  {
    Id: 3540,
    Code: '2221',
    Name: 'Practitioner, physiotherapy'
  },
  {
    Id: 3541,
    Code: '2222',
    Name: 'Therapist, occupational, community'
  },
  {
    Id: 3542,
    Code: '2222',
    Name: 'Therapist, occupational, paediatric'
  },
  {
    Id: 3543,
    Code: '2222',
    Name: 'Therapist, occupational'
  },
  {
    Id: 3544,
    Code: '2223',
    Name: 'Therapist, communication'
  },
  {
    Id: 3545,
    Code: '2223',
    Name: 'Therapist, language'
  },
  {
    Id: 3546,
    Code: '2223',
    Name: 'Therapist, speech'
  },
  {
    Id: 3547,
    Code: '2223',
    Name: 'Therapist, speech and language, specialist'
  },
  {
    Id: 3548,
    Code: '2223',
    Name: 'Therapist, speech and language'
  },
  {
    Id: 3549,
    Code: '2224',
    Name: 'Counsellor, psychodynamic'
  },
  {
    Id: 3550,
    Code: '2224',
    Name: 'Counsellor, psychological'
  },
  {
    Id: 3551,
    Code: '2224',
    Name: 'Counsellor, psychotherapist'
  },
  {
    Id: 3552,
    Code: '2224',
    Name: 'Counsellor and psychotherapist'
  },
  {
    Id: 3553,
    Code: '2224',
    Name: 'Psycho-analyst'
  },
  {
    Id: 3554,
    Code: '2224',
    Name: 'Psychotherapist, analytical'
  },
  {
    Id: 3555,
    Code: '2224',
    Name: 'Psychotherapist, art'
  },
  {
    Id: 3556,
    Code: '2224',
    Name: 'Psychotherapist, behavioural, cognitive'
  },
  {
    Id: 3557,
    Code: '2224',
    Name: 'Psychotherapist, child'
  },
  {
    Id: 3558,
    Code: '2224',
    Name: "Psychotherapist, children's"
  },
  {
    Id: 3559,
    Code: '2224',
    Name: 'Psychotherapist, counsellor'
  },
  {
    Id: 3560,
    Code: '2224',
    Name: 'Psychotherapist'
  },
  {
    Id: 3561,
    Code: '2224',
    Name: 'Therapist, behavioural, cognitive'
  },
  {
    Id: 3562,
    Code: '2224',
    Name: 'Therapist, CBT'
  },
  {
    Id: 3563,
    Code: '2224',
    Name: 'Therapist, psychological'
  },
  {
    Id: 3564,
    Code: '2225',
    Name: 'Psychologist, clinical, consultant'
  },
  {
    Id: 3565,
    Code: '2225',
    Name: 'Psychologist, clinical'
  },
  {
    Id: 3566,
    Code: '2226',
    Name: 'Assistant, psychology'
  },
  {
    Id: 3567,
    Code: '2226',
    Name: 'Psychologist, business'
  },
  {
    Id: 3568,
    Code: '2226',
    Name: 'Psychologist, counselling'
  },
  {
    Id: 3569,
    Code: '2226',
    Name: 'Psychologist, educational'
  },
  {
    Id: 3570,
    Code: '2226',
    Name: 'Psychologist, forensic'
  },
  {
    Id: 3571,
    Code: '2226',
    Name: 'Psychologist, health'
  },
  {
    Id: 3572,
    Code: '2226',
    Name: 'Psychologist, occupational'
  },
  {
    Id: 3573,
    Code: '2226',
    Name: 'Psychologist, practitioner'
  },
  {
    Id: 3574,
    Code: '2226',
    Name: 'Psychologist, registered'
  },
  {
    Id: 3575,
    Code: '2226',
    Name: 'Psychologist, research'
  },
  {
    Id: 3576,
    Code: '2226',
    Name: 'Psychologist, sport'
  },
  {
    Id: 3577,
    Code: '2226',
    Name: 'Psychologist, sport and exercise'
  },
  {
    Id: 3578,
    Code: '2226',
    Name: 'Psychologist'
  },
  {
    Id: 3579,
    Code: '2226',
    Name: 'Psychometrician'
  },
  {
    Id: 3580,
    Code: '2226',
    Name: 'Psychometrist'
  },
  {
    Id: 3581,
    Code: '2229',
    Name: 'Acupuncturist'
  },
  {
    Id: 3582,
    Code: '2229',
    Name: 'Analyst, behaviour'
  },
  {
    Id: 3583,
    Code: '2229',
    Name: 'Behaviourist, animal'
  },
  {
    Id: 3584,
    Code: '2229',
    Name: 'Chiropractor'
  },
  {
    Id: 3585,
    Code: '2229',
    Name: 'Coach, rehabilitation'
  },
  {
    Id: 3586,
    Code: '2229',
    Name: 'Counsellor, behaviour, pet'
  },
  {
    Id: 3587,
    Code: '2229',
    Name: 'Counsellor, genetic'
  },
  {
    Id: 3588,
    Code: '2229',
    Name: 'Counsellor, practice, medical practice'
  },
  {
    Id: 3589,
    Code: '2229',
    Name: 'Dramatherapist'
  },
  {
    Id: 3590,
    Code: '2229',
    Name: 'Gymnast, remedial'
  },
  {
    Id: 3591,
    Code: '2229',
    Name: 'Herbalist, medical'
  },
  {
    Id: 3592,
    Code: '2229',
    Name: 'Hydrotherapist, colon'
  },
  {
    Id: 3593,
    Code: '2229',
    Name: 'Nutritionist'
  },
  {
    Id: 3594,
    Code: '2229',
    Name: 'Orthoptist'
  },
  {
    Id: 3595,
    Code: '2229',
    Name: 'Osteomyologist'
  },
  {
    Id: 3596,
    Code: '2229',
    Name: 'Osteopath'
  },
  {
    Id: 3597,
    Code: '2229',
    Name: 'Physiologist, exercise, hospital service'
  },
  {
    Id: 3598,
    Code: '2229',
    Name: 'Practitioner, psychological'
  },
  {
    Id: 3599,
    Code: '2229',
    Name: 'Practitioner, wellbeing, psychological'
  },
  {
    Id: 3600,
    Code: '2229',
    Name: 'Psychologist, pet'
  },
  {
    Id: 3601,
    Code: '2229',
    Name: 'Therapist, art'
  },
  {
    Id: 3602,
    Code: '2229',
    Name: 'Therapist, behavioural'
  },
  {
    Id: 3603,
    Code: '2229',
    Name: 'Therapist, child'
  },
  {
    Id: 3604,
    Code: '2229',
    Name: "Therapist, children's"
  },
  {
    Id: 3605,
    Code: '2229',
    Name: 'Therapist, drama'
  },
  {
    Id: 3606,
    Code: '2229',
    Name: 'Therapist, family'
  },
  {
    Id: 3607,
    Code: '2229',
    Name: 'Therapist, intensity, high'
  },
  {
    Id: 3608,
    Code: '2229',
    Name: 'Therapist, massage, sports, level 4/5'
  },
  {
    Id: 3609,
    Code: '2229',
    Name: 'Therapist, movement, dance'
  },
  {
    Id: 3610,
    Code: '2229',
    Name: 'Therapist, movement'
  },
  {
    Id: 3611,
    Code: '2229',
    Name: 'Therapist, music'
  },
  {
    Id: 3612,
    Code: '2229',
    Name: 'Therapist, nutritional'
  },
  {
    Id: 3613,
    Code: '2229',
    Name: 'Therapist, physical'
  },
  {
    Id: 3614,
    Code: '2229',
    Name: 'Therapist, play'
  },
  {
    Id: 3615,
    Code: '2229',
    Name: 'Therapist, rehabilitation, professional'
  },
  {
    Id: 3616,
    Code: '2229',
    Name: 'Therapist, remedial'
  },
  {
    Id: 3617,
    Code: '2229',
    Name: 'Therapist, sports'
  },
  {
    Id: 3618,
    Code: '2231',
    Name: 'Leader, team, midwifery'
  },
  {
    Id: 3619,
    Code: '2231',
    Name: 'Manager, midwife'
  },
  {
    Id: 3620,
    Code: '2231',
    Name: 'Manager, midwifery'
  },
  {
    Id: 3621,
    Code: '2231',
    Name: 'Manager, suite, delivery'
  },
  {
    Id: 3622,
    Code: '2231',
    Name: 'Midwife, registered'
  },
  {
    Id: 3623,
    Code: '2231',
    Name: 'Midwife, superintendent'
  },
  {
    Id: 3624,
    Code: '2231',
    Name: 'Midwife'
  },
  {
    Id: 3625,
    Code: '2231',
    Name: 'Midwife-tutor'
  },
  {
    Id: 3626,
    Code: '2231',
    Name: 'Sister, midwifery'
  },
  {
    Id: 3627,
    Code: '2232',
    Name: 'Leader, team, nurse, district'
  },
  {
    Id: 3628,
    Code: '2232',
    Name: 'Leader, team, visitor, health'
  },
  {
    Id: 3629,
    Code: '2232',
    Name: 'Manager, nurse, district'
  },
  {
    Id: 3630,
    Code: '2232',
    Name: 'Matron, community'
  },
  {
    Id: 3631,
    Code: '2232',
    Name: 'Nurse, care, community'
  },
  {
    Id: 3632,
    Code: '2232',
    Name: 'Nurse, charge, community'
  },
  {
    Id: 3633,
    Code: '2232',
    Name: 'Nurse, community'
  },
  {
    Id: 3634,
    Code: '2232',
    Name: 'Nurse, disability, learning, community'
  },
  {
    Id: 3635,
    Code: '2232',
    Name: 'Nurse, district'
  },
  {
    Id: 3636,
    Code: '2232',
    Name: 'Nurse, health, public, community'
  },
  {
    Id: 3637,
    Code: '2232',
    Name: 'Nurse, liaison, community'
  },
  {
    Id: 3638,
    Code: '2232',
    Name: 'Nurse, Macmillan, community'
  },
  {
    Id: 3639,
    Code: '2232',
    Name: 'Nurse, practice'
  },
  {
    Id: 3640,
    Code: '2232',
    Name: 'Nurse, staff, community'
  },
  {
    Id: 3641,
    Code: '2232',
    Name: 'Sister, community'
  },
  {
    Id: 3642,
    Code: '2232',
    Name: 'Sister, nursing, community'
  },
  {
    Id: 3643,
    Code: '2232',
    Name: 'Sister, nursing, district'
  },
  {
    Id: 3644,
    Code: '2232',
    Name: 'Visitor, district'
  },
  {
    Id: 3645,
    Code: '2232',
    Name: 'Visitor, health, superintendent'
  },
  {
    Id: 3646,
    Code: '2232',
    Name: 'Visitor, health'
  },
  {
    Id: 3647,
    Code: '2232',
    Name: 'Visitor, home'
  },
  {
    Id: 3648,
    Code: '2233',
    Name: 'Consultant, nurse'
  },
  {
    Id: 3649,
    Code: '2233',
    Name: 'Manager, theatre, operating'
  },
  {
    Id: 3650,
    Code: '2233',
    Name: 'Manager, theatre, hospital service'
  },
  {
    Id: 3651,
    Code: '2233',
    Name: 'Nurse, care, intensive'
  },
  {
    Id: 3652,
    Code: '2233',
    Name: 'Nurse, care, primary, advanced'
  },
  {
    Id: 3653,
    Code: '2233',
    Name: 'Nurse, control, infection'
  },
  {
    Id: 3654,
    Code: '2233',
    Name: 'Nurse, ITU'
  },
  {
    Id: 3655,
    Code: '2233',
    Name: 'Nurse, scrub'
  },
  {
    Id: 3656,
    Code: '2233',
    Name: 'Nurse, specialist, diabetes'
  },
  {
    Id: 3657,
    Code: '2233',
    Name: 'Nurse, specialist'
  },
  {
    Id: 3658,
    Code: '2233',
    Name: 'Nurse, theatre, operating'
  },
  {
    Id: 3659,
    Code: '2233',
    Name: 'Nurse, theatre'
  },
  {
    Id: 3660,
    Code: '2233',
    Name: 'Sister, theatre'
  },
  {
    Id: 3661,
    Code: '2233',
    Name: 'Specialist, nurse, clinical'
  },
  {
    Id: 3662,
    Code: '2233',
    Name: 'Specialist, nurse, diabetes'
  },
  {
    Id: 3663,
    Code: '2233',
    Name: 'Specialist, nurse, respiratory'
  },
  {
    Id: 3664,
    Code: '2233',
    Name: 'Specialist, nurse'
  },
  {
    Id: 3665,
    Code: '2234',
    Name: 'Nurse-ECP'
  },
  {
    Id: 3666,
    Code: '2234',
    Name: 'Nurse-practitioner'
  },
  {
    Id: 3667,
    Code: '2234',
    Name: 'Practitioner, care, health'
  },
  {
    Id: 3668,
    Code: '2234',
    Name: 'Practitioner, clinical, advanced'
  },
  {
    Id: 3669,
    Code: '2234',
    Name: 'Practitioner, health, mental, community'
  },
  {
    Id: 3670,
    Code: '2234',
    Name: 'Practitioner, health, mental'
  },
  {
    Id: 3671,
    Code: '2234',
    Name: 'Practitioner, midwifery'
  },
  {
    Id: 3672,
    Code: '2234',
    Name: 'Practitioner, nurse, advanced'
  },
  {
    Id: 3673,
    Code: '2234',
    Name: 'Practitioner, nurse, emergency'
  },
  {
    Id: 3674,
    Code: '2234',
    Name: 'Practitioner, nurse, health, mental'
  },
  {
    Id: 3675,
    Code: '2234',
    Name: 'Practitioner, nurse, neonatal, advanced'
  },
  {
    Id: 3676,
    Code: '2234',
    Name: 'Practitioner, nurse, specialist'
  },
  {
    Id: 3677,
    Code: '2234',
    Name: 'Practitioner, nurse'
  },
  {
    Id: 3678,
    Code: '2234',
    Name: 'Practitioner, screening, specialist'
  },
  {
    Id: 3679,
    Code: '2234',
    Name: 'Practitioner, nursing'
  },
  {
    Id: 3680,
    Code: '2235',
    Name: 'Nurse, health, mental, community'
  },
  {
    Id: 3681,
    Code: '2235',
    Name: 'Nurse, health, mental'
  },
  {
    Id: 3682,
    Code: '2235',
    Name: 'Nurse, psychiatric, community'
  },
  {
    Id: 3683,
    Code: '2235',
    Name: 'Nurse, psychiatric'
  },
  {
    Id: 3684,
    Code: '2235',
    Name: 'RMN'
  },
  {
    Id: 3685,
    Code: '2236',
    Name: "Nurse, children's, community"
  },
  {
    Id: 3686,
    Code: '2236',
    Name: "Nurse, children's, registered"
  },
  {
    Id: 3687,
    Code: '2236',
    Name: "Nurse, children's, sick, registered"
  },
  {
    Id: 3688,
    Code: '2236',
    Name: "Nurse, children's"
  },
  {
    Id: 3689,
    Code: '2236',
    Name: 'Nurse, health, school'
  },
  {
    Id: 3690,
    Code: '2236',
    Name: 'Nurse, neonatal'
  },
  {
    Id: 3691,
    Code: '2236',
    Name: 'Nurse, paediatric'
  },
  {
    Id: 3692,
    Code: '2236',
    Name: 'Nurse, school'
  },
  {
    Id: 3693,
    Code: '2236',
    Name: 'Nurse, staff, neonatal'
  },
  {
    Id: 3694,
    Code: '2236',
    Name: 'Nurse, staff, paediatric'
  },
  {
    Id: 3695,
    Code: '2236',
    Name: 'RSCN'
  },
  {
    Id: 3696,
    Code: '2236',
    Name: 'Sister, neonatal'
  },
  {
    Id: 3697,
    Code: '2236',
    Name: 'Sister, paediatric'
  },
  {
    Id: 3698,
    Code: '2237',
    Name: 'Adviser, health, school'
  },
  {
    Id: 3699,
    Code: '2237',
    Name: 'Adviser, nurse'
  },
  {
    Id: 3700,
    Code: '2237',
    Name: 'Cadet, nursing'
  },
  {
    Id: 3701,
    Code: '2237',
    Name: 'Companion-nurse'
  },
  {
    Id: 3702,
    Code: '2237',
    Name: 'ENG'
  },
  {
    Id: 3703,
    Code: '2237',
    Name: 'Educator, clinical, health authority'
  },
  {
    Id: 3704,
    Code: '2237',
    Name: 'Educator, nurse, clinical'
  },
  {
    Id: 3705,
    Code: '2237',
    Name: 'Educator, nurse'
  },
  {
    Id: 3706,
    Code: '2237',
    Name: 'Leader, team, clinical, health authority'
  },
  {
    Id: 3707,
    Code: '2237',
    Name: 'Leader, team, nurse'
  },
  {
    Id: 3708,
    Code: '2237',
    Name: 'Leader, team, nursing'
  },
  {
    Id: 3709,
    Code: '2237',
    Name: 'Manager, bank, nurse'
  },
  {
    Id: 3710,
    Code: '2237',
    Name: 'Manager, nurse'
  },
  {
    Id: 3711,
    Code: '2237',
    Name: 'Manager, unit, hospital service'
  },
  {
    Id: 3712,
    Code: '2237',
    Name: 'Manager, ward'
  },
  {
    Id: 3713,
    Code: '2237',
    Name: 'Matron, modern'
  },
  {
    Id: 3714,
    Code: '2237',
    Name: 'Matron, care/residential home'
  },
  {
    Id: 3715,
    Code: '2237',
    Name: 'Matron, medical services'
  },
  {
    Id: 3716,
    Code: '2237',
    Name: 'Matron, nursing home'
  },
  {
    Id: 3717,
    Code: '2237',
    Name: 'Nun, nursing'
  },
  {
    Id: 3718,
    Code: '2237',
    Name: 'Nurse, adult'
  },
  {
    Id: 3719,
    Code: '2237',
    Name: 'Nurse, aesthetic, registered nurse'
  },
  {
    Id: 3720,
    Code: '2237',
    Name: 'Nurse, care, ambulatory'
  },
  {
    Id: 3721,
    Code: '2237',
    Name: 'Nurse, care, breast'
  },
  {
    Id: 3722,
    Code: '2237',
    Name: 'Nurse, care, critical'
  },
  {
    Id: 3723,
    Code: '2237',
    Name: 'Nurse, care, palliative, specialist'
  },
  {
    Id: 3724,
    Code: '2237',
    Name: 'Nurse, care, palliative'
  },
  {
    Id: 3725,
    Code: '2237',
    Name: 'Nurse, care, stoma'
  },
  {
    Id: 3726,
    Code: '2237',
    Name: 'Nurse, charge'
  },
  {
    Id: 3727,
    Code: '2237',
    Name: 'Nurse, enrolled, state'
  },
  {
    Id: 3728,
    Code: '2237',
    Name: 'Nurse, enrolled'
  },
  {
    Id: 3729,
    Code: '2237',
    Name: 'Nurse, health, occupational'
  },
  {
    Id: 3730,
    Code: '2237',
    Name: 'Nurse, lead, clinical'
  },
  {
    Id: 3731,
    Code: '2237',
    Name: 'Nurse, Macmillan'
  },
  {
    Id: 3732,
    Code: '2237',
    Name: 'Nurse, male, chief'
  },
  {
    Id: 3733,
    Code: '2237',
    Name: 'Nurse, misuse, substance'
  },
  {
    Id: 3734,
    Code: '2237',
    Name: 'Nurse, recovery'
  },
  {
    Id: 3735,
    Code: '2237',
    Name: 'Nurse, registered, state'
  },
  {
    Id: 3736,
    Code: '2237',
    Name: 'Nurse, registered'
  },
  {
    Id: 3737,
    Code: '2237',
    Name: 'Nurse, research, clinical'
  },
  {
    Id: 3738,
    Code: '2237',
    Name: 'Nurse, research'
  },
  {
    Id: 3739,
    Code: '2237',
    Name: 'Nurse, staff'
  },
  {
    Id: 3740,
    Code: '2237',
    Name: 'Nurse, student'
  },
  {
    Id: 3741,
    Code: '2237',
    Name: 'Nurse'
  },
  {
    Id: 3742,
    Code: '2237',
    Name: 'Nurse-companion'
  },
  {
    Id: 3743,
    Code: '2237',
    Name: 'Nurse-teacher'
  },
  {
    Id: 3744,
    Code: '2237',
    Name: 'Nurse-tutor'
  },
  {
    Id: 3745,
    Code: '2237',
    Name: 'Officer, nursing'
  },
  {
    Id: 3746,
    Code: '2237',
    Name: 'Officer, resuscitation'
  },
  {
    Id: 3747,
    Code: '2237',
    Name: 'Practitioner, nurse, aesthetic, registered nurse'
  },
  {
    Id: 3748,
    Code: '2237',
    Name: 'RGN'
  },
  {
    Id: 3749,
    Code: '2237',
    Name: 'SEN'
  },
  {
    Id: 3750,
    Code: '2237',
    Name: 'SRN'
  },
  {
    Id: 3751,
    Code: '2237',
    Name: 'Sister, nursing, religious order'
  },
  {
    Id: 3752,
    Code: '2237',
    Name: 'Sister, nursing'
  },
  {
    Id: 3753,
    Code: '2237',
    Name: 'Sister, ward'
  },
  {
    Id: 3754,
    Code: '2237',
    Name: 'Sister'
  },
  {
    Id: 3755,
    Code: '2237',
    Name: 'Sister-tutor'
  },
  {
    Id: 3756,
    Code: '2237',
    Name: 'Superintendent, night, hospital service'
  },
  {
    Id: 3757,
    Code: '2237',
    Name: 'Supervisor, nurse'
  },
  {
    Id: 3758,
    Code: '2237',
    Name: 'Teacher, nurse'
  },
  {
    Id: 3759,
    Code: '2237',
    Name: 'Tutor, hospital service'
  },
  {
    Id: 3760,
    Code: '2240',
    Name: 'Consultant, veterinary'
  },
  {
    Id: 3761,
    Code: '2240',
    Name: 'Inspector, veterinary'
  },
  {
    Id: 3762,
    Code: '2240',
    Name: 'MRCVS'
  },
  {
    Id: 3763,
    Code: '2240',
    Name: 'Officer, field, veterinary'
  },
  {
    Id: 3764,
    Code: '2240',
    Name: 'Officer, investigating, veterinary'
  },
  {
    Id: 3765,
    Code: '2240',
    Name: 'Officer, investigation, veterinary'
  },
  {
    Id: 3766,
    Code: '2240',
    Name: 'Officer, research, veterinary'
  },
  {
    Id: 3767,
    Code: '2240',
    Name: 'Officer, veterinary'
  },
  {
    Id: 3768,
    Code: '2240',
    Name: 'Practitioner, veterinary'
  },
  {
    Id: 3769,
    Code: '2240',
    Name: 'Scientist, veterinary'
  },
  {
    Id: 3770,
    Code: '2240',
    Name: 'Surgeon, veterinary'
  },
  {
    Id: 3771,
    Code: '2240',
    Name: 'Vet'
  },
  {
    Id: 3772,
    Code: '2240',
    Name: 'Veterinarian'
  },
  {
    Id: 3773,
    Code: '2251',
    Name: 'Adviser, prescribing'
  },
  {
    Id: 3774,
    Code: '2251',
    Name: 'Associate, pharmacovigilance'
  },
  {
    Id: 3775,
    Code: '2251',
    Name: 'Associate, safety, drug'
  },
  {
    Id: 3776,
    Code: '2251',
    Name: 'Chemist, homeopathic'
  },
  {
    Id: 3777,
    Code: '2251',
    Name: 'Chemist, homoeopathic'
  },
  {
    Id: 3778,
    Code: '2251',
    Name: 'Chemist, pharmaceutical'
  },
  {
    Id: 3779,
    Code: '2251',
    Name: 'Chemist, nos'
  },
  {
    Id: 3780,
    Code: '2251',
    Name: 'Chemist, pharmaceutical'
  },
  {
    Id: 3781,
    Code: '2251',
    Name: 'Chemist, retail trade'
  },
  {
    Id: 3782,
    Code: '2251',
    Name: 'Chemist and druggist, retail trade'
  },
  {
    Id: 3783,
    Code: '2251',
    Name: 'Druggist'
  },
  {
    Id: 3784,
    Code: '2251',
    Name: 'Manager, dispensary'
  },
  {
    Id: 3785,
    Code: '2251',
    Name: 'Manager, pharmacist'
  },
  {
    Id: 3786,
    Code: '2251',
    Name: "Manager, pharmacist's"
  },
  {
    Id: 3787,
    Code: '2251',
    Name: 'Manager, pharmacy'
  },
  {
    Id: 3788,
    Code: '2251',
    Name: 'Manager, hospital pharmacists'
  },
  {
    Id: 3789,
    Code: '2251',
    Name: 'Manager, professional, pharmacist, retail trade: pharmacy'
  },
  {
    Id: 3790,
    Code: '2251',
    Name: 'Officer, pharmaceutical, health authority'
  },
  {
    Id: 3791,
    Code: '2251',
    Name: 'Owner, professional, pharmacist, retail pharmacy'
  },
  {
    Id: 3792,
    Code: '2251',
    Name: 'Pharmaceutist'
  },
  {
    Id: 3793,
    Code: '2251',
    Name: 'Pharmacist, PCN'
  },
  {
    Id: 3794,
    Code: '2251',
    Name: 'Pharmacist'
  },
  {
    Id: 3795,
    Code: '2251',
    Name: 'Pharmacoepidemiologist'
  },
  {
    Id: 3796,
    Code: '2252',
    Name: 'Doctor of optometry'
  },
  {
    Id: 3797,
    Code: '2252',
    Name: 'Ophthalmologist, consultant'
  },
  {
    Id: 3798,
    Code: '2252',
    Name: 'Ophthalmologist'
  },
  {
    Id: 3799,
    Code: '2252',
    Name: 'Optician, ophthalmic'
  },
  {
    Id: 3800,
    Code: '2252',
    Name: 'Optician'
  },
  {
    Id: 3801,
    Code: '2252',
    Name: 'Optometrist'
  },
  {
    Id: 3802,
    Code: '2253',
    Name: 'Consultant, dental'
  },
  {
    Id: 3803,
    Code: '2253',
    Name: 'Dentist'
  },
  {
    Id: 3804,
    Code: '2253',
    Name: 'Endodontist'
  },
  {
    Id: 3805,
    Code: '2253',
    Name: 'Fellow, dentistry'
  },
  {
    Id: 3806,
    Code: '2253',
    Name: 'Fellow, research, dentistry'
  },
  {
    Id: 3807,
    Code: '2253',
    Name: 'Fellow, dentistry, university'
  },
  {
    Id: 3808,
    Code: '2253',
    Name: 'Lecturer, dentistry'
  },
  {
    Id: 3809,
    Code: '2253',
    Name: 'Officer, dental'
  },
  {
    Id: 3810,
    Code: '2253',
    Name: 'Orthodontist'
  },
  {
    Id: 3811,
    Code: '2253',
    Name: 'Periodontist'
  },
  {
    Id: 3812,
    Code: '2253',
    Name: 'Practitioner, dental, general'
  },
  {
    Id: 3813,
    Code: '2253',
    Name: 'Practitioner, dental'
  },
  {
    Id: 3814,
    Code: '2253',
    Name: 'Professor, dentistry'
  },
  {
    Id: 3815,
    Code: '2253',
    Name: 'Reader, dentistry'
  },
  {
    Id: 3816,
    Code: '2253',
    Name: 'Surgeon, dental'
  },
  {
    Id: 3817,
    Code: '2254',
    Name: 'Mammographer'
  },
  {
    Id: 3818,
    Code: '2254',
    Name: 'Operator, x-ray'
  },
  {
    Id: 3819,
    Code: '2254',
    Name: 'Photographer, clinical'
  },
  {
    Id: 3820,
    Code: '2254',
    Name: 'Photographer, medical'
  },
  {
    Id: 3821,
    Code: '2254',
    Name: 'Practitioner, advanced, radiography'
  },
  {
    Id: 3822,
    Code: '2254',
    Name: 'Practitioner, advanced, radiology'
  },
  {
    Id: 3823,
    Code: '2254',
    Name: 'Practitioner, medicine, nuclear'
  },
  {
    Id: 3824,
    Code: '2254',
    Name: 'Practitioner, physics, radiotherapy'
  },
  {
    Id: 3825,
    Code: '2254',
    Name: 'Radiographer, diagnostic'
  },
  {
    Id: 3826,
    Code: '2254',
    Name: 'Radiographer, medical'
  },
  {
    Id: 3827,
    Code: '2254',
    Name: 'Radiographer, superintendent'
  },
  {
    Id: 3828,
    Code: '2254',
    Name: 'Radiographer, therapeutic'
  },
  {
    Id: 3829,
    Code: '2254',
    Name: 'Radiographer, therapy'
  },
  {
    Id: 3830,
    Code: '2254',
    Name: 'Radiographer'
  },
  {
    Id: 3831,
    Code: '2254',
    Name: 'Scientist, physics, radiotherapy'
  },
  {
    Id: 3832,
    Code: '2254',
    Name: 'Scientist, vascular, clinical'
  },
  {
    Id: 3833,
    Code: '2254',
    Name: 'Sonographer'
  },
  {
    Id: 3834,
    Code: '2254',
    Name: 'Technologist, vascular'
  },
  {
    Id: 3835,
    Code: '2254',
    Name: 'Ultrasonographer'
  },
  {
    Id: 3836,
    Code: '2255',
    Name: 'Paramedic, ambulance'
  },
  {
    Id: 3837,
    Code: '2255',
    Name: 'Paramedic'
  },
  {
    Id: 3838,
    Code: '2255',
    Name: 'Paramedic-ECP'
  },
  {
    Id: 3839,
    Code: '2255',
    Name: 'Practitioner, care, emergency'
  },
  {
    Id: 3840,
    Code: '2255',
    Name: 'Technician, paramedic'
  },
  {
    Id: 3841,
    Code: '2256',
    Name: 'Chiropodist'
  },
  {
    Id: 3842,
    Code: '2256',
    Name: 'Chiropodist-podiatrist'
  },
  {
    Id: 3843,
    Code: '2256',
    Name: 'Podiatrist'
  },
  {
    Id: 3844,
    Code: '2256',
    Name: 'Practitioner, health, foot, qualified'
  },
  {
    Id: 3845,
    Code: '2256',
    Name: 'Professional, health, foot, qualified'
  },
  {
    Id: 3846,
    Code: '2259',
    Name: 'Adviser, health, occupational'
  },
  {
    Id: 3847,
    Code: '2259',
    Name: 'Adviser, health, public'
  },
  {
    Id: 3848,
    Code: '2259',
    Name: 'Adviser, health, sexual'
  },
  {
    Id: 3849,
    Code: '2259',
    Name: 'Adviser, nurse, health, occupational'
  },
  {
    Id: 3850,
    Code: '2259',
    Name: 'Adviser, occupational health and wellbeing'
  },
  {
    Id: 3851,
    Code: '2259',
    Name: 'Analyst, disability'
  },
  {
    Id: 3852,
    Code: '2259',
    Name: 'Assessor, care, health'
  },
  {
    Id: 3853,
    Code: '2259',
    Name: 'Assessor, disability'
  },
  {
    Id: 3854,
    Code: '2259',
    Name: 'Assessor, healthcare'
  },
  {
    Id: 3855,
    Code: '2259',
    Name: 'Associate, physician'
  },
  {
    Id: 3856,
    Code: '2259',
    Name: 'Audiologist, aid, hearing'
  },
  {
    Id: 3857,
    Code: '2259',
    Name: 'Audiologist'
  },
  {
    Id: 3858,
    Code: '2259',
    Name: 'Consultant, health, public'
  },
  {
    Id: 3859,
    Code: '2259',
    Name: 'Consultant, nutrition'
  },
  {
    Id: 3860,
    Code: '2259',
    Name: 'Coordinator, transplant'
  },
  {
    Id: 3861,
    Code: '2259',
    Name: 'Dietician'
  },
  {
    Id: 3862,
    Code: '2259',
    Name: 'Dietician-nutritionist'
  },
  {
    Id: 3863,
    Code: '2259',
    Name: 'Dietitian'
  },
  {
    Id: 3864,
    Code: '2259',
    Name: 'Dietitian-nutritionist'
  },
  {
    Id: 3865,
    Code: '2259',
    Name: 'Dosimetrist, medical'
  },
  {
    Id: 3866,
    Code: '2259',
    Name: 'Dosimetrist'
  },
  {
    Id: 3867,
    Code: '2259',
    Name: 'Educator, health'
  },
  {
    Id: 3868,
    Code: '2259',
    Name: 'Facilitator, care, primary'
  },
  {
    Id: 3869,
    Code: '2259',
    Name: 'Facilitator, medical practice'
  },
  {
    Id: 3870,
    Code: '2259',
    Name: 'Leader, team, clinical, health'
  },
  {
    Id: 3871,
    Code: '2259',
    Name: 'Manager, control, infection'
  },
  {
    Id: 3872,
    Code: '2259',
    Name: 'Manager, immunisation'
  },
  {
    Id: 3873,
    Code: '2259',
    Name: 'Manager, prosthetic, senior'
  },
  {
    Id: 3874,
    Code: '2259',
    Name: 'Manager, resource, community'
  },
  {
    Id: 3875,
    Code: '2259',
    Name: 'Manager, services, sterile'
  },
  {
    Id: 3876,
    Code: '2259',
    Name: 'Neurophysiologist'
  },
  {
    Id: 3877,
    Code: '2259',
    Name: 'ODP'
  },
  {
    Id: 3878,
    Code: '2259',
    Name: 'Officer, education, health'
  },
  {
    Id: 3879,
    Code: '2259',
    Name: 'Officer, education, community health'
  },
  {
    Id: 3880,
    Code: '2259',
    Name: 'Officer, promotion, health'
  },
  {
    Id: 3881,
    Code: '2259',
    Name: 'Orthotist'
  },
  {
    Id: 3882,
    Code: '2259',
    Name: 'Orthotist and prosthetist'
  },
  {
    Id: 3883,
    Code: '2259',
    Name: 'Perfusionist'
  },
  {
    Id: 3884,
    Code: '2259',
    Name: 'Physicist, medical'
  },
  {
    Id: 3885,
    Code: '2259',
    Name: 'Physiologist, cardiac, senior'
  },
  {
    Id: 3886,
    Code: '2259',
    Name: 'Physiologist, clinical'
  },
  {
    Id: 3887,
    Code: '2259',
    Name: 'Physiologist, respiratory, clinical'
  },
  {
    Id: 3888,
    Code: '2259',
    Name: 'Physiologist, respiratory'
  },
  {
    Id: 3889,
    Code: '2259',
    Name: 'Physiologist'
  },
  {
    Id: 3890,
    Code: '2259',
    Name: 'Planner, family'
  },
  {
    Id: 3891,
    Code: '2259',
    Name: 'Practitioner, anaesthetic'
  },
  {
    Id: 3892,
    Code: '2259',
    Name: 'Practitioner, department, operating'
  },
  {
    Id: 3893,
    Code: '2259',
    Name: 'Practitioner, improvement, health'
  },
  {
    Id: 3894,
    Code: '2259',
    Name: 'Practitioner, neurophysiology'
  },
  {
    Id: 3895,
    Code: '2259',
    Name: 'Practitioner, paramedic'
  },
  {
    Id: 3896,
    Code: '2259',
    Name: 'Practitioner, paramedical'
  },
  {
    Id: 3897,
    Code: '2259',
    Name: 'Practitioner, perioperative'
  },
  {
    Id: 3898,
    Code: '2259',
    Name: 'Practitioner, scrub, theatre'
  },
  {
    Id: 3899,
    Code: '2259',
    Name: 'Practitioner, theatre, operating'
  },
  {
    Id: 3900,
    Code: '2259',
    Name: 'Practitioner, theatre'
  },
  {
    Id: 3901,
    Code: '2259',
    Name: 'Prosthetist, maxillofacial'
  },
  {
    Id: 3902,
    Code: '2259',
    Name: 'Prosthetist'
  },
  {
    Id: 3903,
    Code: '2259',
    Name: 'Prosthetist and orthotist'
  },
  {
    Id: 3904,
    Code: '2259',
    Name: 'Registrar, health, public'
  },
  {
    Id: 3905,
    Code: '2259',
    Name: 'Scientist, healthcare, neurophysiology'
  },
  {
    Id: 3906,
    Code: '2259',
    Name: 'Scientist, healthcare'
  },
  {
    Id: 3907,
    Code: '2259',
    Name: 'Scientist, medicine, nuclear'
  },
  {
    Id: 3908,
    Code: '2259',
    Name: 'Scientist, perfusion, clinical'
  },
  {
    Id: 3909,
    Code: '2259',
    Name: 'Specialist, education, health'
  },
  {
    Id: 3910,
    Code: '2259',
    Name: 'Specialist, perioperative'
  },
  {
    Id: 3911,
    Code: '2259',
    Name: 'Specialist, promotion, health'
  },
  {
    Id: 3912,
    Code: '2259',
    Name: 'Technician, measurement, physiological'
  },
  {
    Id: 3913,
    Code: '2259',
    Name: 'Technologist, care, critical'
  },
  {
    Id: 3914,
    Code: '2259',
    Name: 'Technologist, renal'
  },
  {
    Id: 3915,
    Code: '2259',
    Name: 'Therapist, hygiene, dental'
  },
  {
    Id: 3916,
    Code: '2259',
    Name: 'Therapist, respiratory'
  },
  {
    Id: 3917,
    Code: '2259',
    Name: 'Worker, health, mental'
  },
  {
    Id: 3918,
    Code: '2311',
    Name: 'Academic, higher education, university'
  },
  {
    Id: 3919,
    Code: '2311',
    Name: 'Assistant, teaching, graduate'
  },
  {
    Id: 3920,
    Code: '2311',
    Name: 'Assistant, teaching, postgraduate'
  },
  {
    Id: 3921,
    Code: '2311',
    Name: 'Assistant, teaching, university'
  },
  {
    Id: 3922,
    Code: '2311',
    Name: 'Assistant, teaching, higher education, university'
  },
  {
    Id: 3923,
    Code: '2311',
    Name: 'Assistant, tutorial, higher education, university'
  },
  {
    Id: 3924,
    Code: '2311',
    Name: 'Associate, teaching, higher education, university'
  },
  {
    Id: 3925,
    Code: '2311',
    Name: 'Demonstrator, laboratory, higher education, university'
  },
  {
    Id: 3926,
    Code: '2311',
    Name: 'Demonstrator, university'
  },
  {
    Id: 3927,
    Code: '2311',
    Name: 'Demonstrator, higher education, university'
  },
  {
    Id: 3928,
    Code: '2311',
    Name: 'Director, course, higher education, university'
  },
  {
    Id: 3929,
    Code: '2311',
    Name: 'Director of studies, higher education, university'
  },
  {
    Id: 3930,
    Code: '2311',
    Name: 'Fellow, university'
  },
  {
    Id: 3931,
    Code: '2311',
    Name: 'Head, assistant, higher education, university'
  },
  {
    Id: 3932,
    Code: '2311',
    Name: 'Head, deputy, higher education, university'
  },
  {
    Id: 3933,
    Code: '2311',
    Name: 'Head of department, higher education, university'
  },
  {
    Id: 3934,
    Code: '2311',
    Name: 'Head of faculty, higher education, university'
  },
  {
    Id: 3935,
    Code: '2311',
    Name: 'Instructor, higher education, university'
  },
  {
    Id: 3936,
    Code: '2311',
    Name: 'Leader, course, higher education, university'
  },
  {
    Id: 3937,
    Code: '2311',
    Name: 'Lecturer, clinical'
  },
  {
    Id: 3938,
    Code: '2311',
    Name: 'Lecturer, college, higher education, university'
  },
  {
    Id: 3939,
    Code: '2311',
    Name: 'Lecturer, dance, higher education, university'
  },
  {
    Id: 3940,
    Code: '2311',
    Name: 'Lecturer, midwifery'
  },
  {
    Id: 3941,
    Code: '2311',
    Name: 'Lecturer, nurse'
  },
  {
    Id: 3942,
    Code: '2311',
    Name: 'Lecturer, nursing'
  },
  {
    Id: 3943,
    Code: '2311',
    Name: 'Lecturer, political'
  },
  {
    Id: 3944,
    Code: '2311',
    Name: 'Lecturer, polytechnic'
  },
  {
    Id: 3945,
    Code: '2311',
    Name: 'Lecturer, principal, higher education, university'
  },
  {
    Id: 3946,
    Code: '2311',
    Name: 'Lecturer, university'
  },
  {
    Id: 3947,
    Code: '2311',
    Name: 'Lecturer, higher education, university'
  },
  {
    Id: 3948,
    Code: '2311',
    Name: 'Lecturer, nursing, midwifery'
  },
  {
    Id: 3949,
    Code: '2311',
    Name: 'Lecturer, nursing'
  },
  {
    Id: 3950,
    Code: '2311',
    Name: 'Lecturer'
  },
  {
    Id: 3951,
    Code: '2311',
    Name: 'Professor, university'
  },
  {
    Id: 3952,
    Code: '2311',
    Name: 'Professor, higher education, university'
  },
  {
    Id: 3953,
    Code: '2311',
    Name: 'Reader, university'
  },
  {
    Id: 3954,
    Code: '2311',
    Name: 'Reader, university'
  },
  {
    Id: 3955,
    Code: '2311',
    Name: 'TA, higher education, university'
  },
  {
    Id: 3956,
    Code: '2311',
    Name: 'Teacher, clinical'
  },
  {
    Id: 3957,
    Code: '2311',
    Name: 'Teacher, dance, higher education, university'
  },
  {
    Id: 3958,
    Code: '2311',
    Name: 'Teacher, dancing, higher education, university'
  },
  {
    Id: 3959,
    Code: '2311',
    Name: 'Teacher, university'
  },
  {
    Id: 3960,
    Code: '2311',
    Name: 'Teacher, higher education, university'
  },
  {
    Id: 3961,
    Code: '2311',
    Name: 'Tutor, clinical'
  },
  {
    Id: 3962,
    Code: '2311',
    Name: 'Tutor, course, higher education, university'
  },
  {
    Id: 3963,
    Code: '2311',
    Name: 'Tutor, midwife'
  },
  {
    Id: 3964,
    Code: '2311',
    Name: 'Tutor, skills, clinical'
  },
  {
    Id: 3965,
    Code: '2311',
    Name: 'Tutor, higher education, university'
  },
  {
    Id: 3966,
    Code: '2312',
    Name: 'Coach, learning, further education'
  },
  {
    Id: 3967,
    Code: '2312',
    Name: 'Director of studies, further education'
  },
  {
    Id: 3968,
    Code: '2312',
    Name: 'Head, assistant, further education'
  },
  {
    Id: 3969,
    Code: '2312',
    Name: 'Head, deputy, further education'
  },
  {
    Id: 3970,
    Code: '2312',
    Name: 'Head of department, further education'
  },
  {
    Id: 3971,
    Code: '2312',
    Name: 'Head of faculties, further education'
  },
  {
    Id: 3972,
    Code: '2312',
    Name: 'Head of faculty, further education'
  },
  {
    Id: 3973,
    Code: '2312',
    Name: 'Instructor, woodwork, further education'
  },
  {
    Id: 3974,
    Code: '2312',
    Name: 'Instructor, further education'
  },
  {
    Id: 3975,
    Code: '2312',
    Name: 'Leader, course, further education'
  },
  {
    Id: 3976,
    Code: '2312',
    Name: 'Leader, curriculum, further education'
  },
  {
    Id: 3977,
    Code: '2312',
    Name: 'Lecturer, college, FE'
  },
  {
    Id: 3978,
    Code: '2312',
    Name: 'Lecturer, college, further education'
  },
  {
    Id: 3979,
    Code: '2312',
    Name: 'Lecturer, dance, further education'
  },
  {
    Id: 3980,
    Code: '2312',
    Name: 'Lecturer, therapy, beauty'
  },
  {
    Id: 3981,
    Code: '2312',
    Name: 'Lecturer, further education'
  },
  {
    Id: 3982,
    Code: '2312',
    Name: 'Professor, further education'
  },
  {
    Id: 3983,
    Code: '2312',
    Name: 'Teacher, dance, further education'
  },
  {
    Id: 3984,
    Code: '2312',
    Name: 'Teacher, dancing, further education'
  },
  {
    Id: 3985,
    Code: '2312',
    Name: 'Teacher, music, further education'
  },
  {
    Id: 3986,
    Code: '2312',
    Name: 'Teacher, further education'
  },
  {
    Id: 3987,
    Code: '2312',
    Name: 'Teacher, tertiary college'
  },
  {
    Id: 3988,
    Code: '2312',
    Name: 'Technician, further education'
  },
  {
    Id: 3989,
    Code: '2312',
    Name: 'Tutor, further education'
  },
  {
    Id: 3990,
    Code: '2313',
    Name: 'Director of music, secondary school'
  },
  {
    Id: 3991,
    Code: '2313',
    Name: 'Director of sport, secondary school'
  },
  {
    Id: 3992,
    Code: '2313',
    Name: 'Director of studies, secondary school'
  },
  {
    Id: 3993,
    Code: '2313',
    Name: 'Director of studies, sixth form college'
  },
  {
    Id: 3994,
    Code: '2313',
    Name: 'Head, assistant, secondary school'
  },
  {
    Id: 3995,
    Code: '2313',
    Name: 'Head, assistant, sixth form college'
  },
  {
    Id: 3996,
    Code: '2313',
    Name: 'Head, deputy, secondary school'
  },
  {
    Id: 3997,
    Code: '2313',
    Name: 'Head, deputy, sixth form college'
  },
  {
    Id: 3998,
    Code: '2313',
    Name: 'Head of art, secondary school'
  },
  {
    Id: 3999,
    Code: '2313',
    Name: 'Head of biology, secondary school'
  },
  {
    Id: 4000,
    Code: '2313',
    Name: 'Head of chemistry, secondary school'
  },
  {
    Id: 4001,
    Code: '2313',
    Name: 'Head of department, secondary school'
  },
  {
    Id: 4002,
    Code: '2313',
    Name: 'Head of department, sixth form college'
  },
  {
    Id: 4003,
    Code: '2313',
    Name: 'Head of design and technology, secondary school'
  },
  {
    Id: 4004,
    Code: '2313',
    Name: 'Head of drama, secondary school'
  },
  {
    Id: 4005,
    Code: '2313',
    Name: 'Head of English, secondary school'
  },
  {
    Id: 4006,
    Code: '2313',
    Name: 'Head of faculty, secondary school'
  },
  {
    Id: 4007,
    Code: '2313',
    Name: 'Head of faculty, sixth form college'
  },
  {
    Id: 4008,
    Code: '2313',
    Name: 'Head of geography, secondary school'
  },
  {
    Id: 4009,
    Code: '2313',
    Name: 'Head of history, secondary school'
  },
  {
    Id: 4010,
    Code: '2313',
    Name: 'Head of humanities, secondary school'
  },
  {
    Id: 4011,
    Code: '2313',
    Name: 'Head of mathematics, secondary school'
  },
  {
    Id: 4012,
    Code: '2313',
    Name: 'Head of MFL, secondary school'
  },
  {
    Id: 4013,
    Code: '2313',
    Name: 'Head of modern foreign languages, secondary school'
  },
  {
    Id: 4014,
    Code: '2313',
    Name: 'Head of music, secondary school'
  },
  {
    Id: 4015,
    Code: '2313',
    Name: 'Head of performing arts, secondary school'
  },
  {
    Id: 4016,
    Code: '2313',
    Name: 'Head of physical education, secondary school'
  },
  {
    Id: 4017,
    Code: '2313',
    Name: 'Head of science, secondary school'
  },
  {
    Id: 4018,
    Code: '2313',
    Name: 'Head of year, secondary school'
  },
  {
    Id: 4019,
    Code: '2313',
    Name: 'Headteacher, assistant, secondary school'
  },
  {
    Id: 4020,
    Code: '2313',
    Name: 'Headteacher, assistant, sixth form college'
  },
  {
    Id: 4021,
    Code: '2313',
    Name: 'Headteacher, deputy, secondary school'
  },
  {
    Id: 4022,
    Code: '2313',
    Name: 'Headteacher, deputy, sixth form college'
  },
  {
    Id: 4023,
    Code: '2313',
    Name: 'Instructor, training, physical, secondary school'
  },
  {
    Id: 4024,
    Code: '2313',
    Name: 'Instructor, woodwork, secondary school'
  },
  {
    Id: 4025,
    Code: '2313',
    Name: 'Instructor, woodwork, sixth form college'
  },
  {
    Id: 4026,
    Code: '2313',
    Name: 'Instructor, physical training, secondary school'
  },
  {
    Id: 4027,
    Code: '2313',
    Name: 'Instructor, secondary school'
  },
  {
    Id: 4028,
    Code: '2313',
    Name: 'Instructor, sixth form college'
  },
  {
    Id: 4029,
    Code: '2313',
    Name: 'Leader, curriculum, secondary school'
  },
  {
    Id: 4030,
    Code: '2313',
    Name: 'Lecturer, dance, secondary school'
  },
  {
    Id: 4031,
    Code: '2313',
    Name: 'Lecturer, secondary school'
  },
  {
    Id: 4032,
    Code: '2313',
    Name: 'Lecturer, sixth form college'
  },
  {
    Id: 4033,
    Code: '2313',
    Name: 'Master, head, assistant, secondary school'
  },
  {
    Id: 4034,
    Code: '2313',
    Name: 'Master, head, assistant, sixth form college'
  },
  {
    Id: 4035,
    Code: '2313',
    Name: 'Master, head, deputy, secondary school'
  },
  {
    Id: 4036,
    Code: '2313',
    Name: 'Master, head, deputy, sixth form college'
  },
  {
    Id: 4037,
    Code: '2313',
    Name: 'Master, house, secondary school'
  },
  {
    Id: 4038,
    Code: '2313',
    Name: 'Master, house, sixth form college'
  },
  {
    Id: 4039,
    Code: '2313',
    Name: 'Master, school, secondary school'
  },
  {
    Id: 4040,
    Code: '2313',
    Name: 'Master, school, sixth form college'
  },
  {
    Id: 4041,
    Code: '2313',
    Name: 'Master, secondary school'
  },
  {
    Id: 4042,
    Code: '2313',
    Name: 'Master, sixth form college'
  },
  {
    Id: 4043,
    Code: '2313',
    Name: 'Mistress, head, assistant, secondary school'
  },
  {
    Id: 4044,
    Code: '2313',
    Name: 'Mistress, head, assistant, sixth form college'
  },
  {
    Id: 4045,
    Code: '2313',
    Name: 'Mistress, head, deputy, secondary school'
  },
  {
    Id: 4046,
    Code: '2313',
    Name: 'Mistress, head, deputy, sixth form college'
  },
  {
    Id: 4047,
    Code: '2313',
    Name: 'Mistress, house, secondary school'
  },
  {
    Id: 4048,
    Code: '2313',
    Name: 'Mistress, house, sixth form college'
  },
  {
    Id: 4049,
    Code: '2313',
    Name: 'Mistress, school, secondary school'
  },
  {
    Id: 4050,
    Code: '2313',
    Name: 'Mistress, school, sixth form college'
  },
  {
    Id: 4051,
    Code: '2313',
    Name: 'Mistress, secondary school'
  },
  {
    Id: 4052,
    Code: '2313',
    Name: 'Mistress, sixth form college'
  },
  {
    Id: 4053,
    Code: '2313',
    Name: 'Monk, teaching, secondary school'
  },
  {
    Id: 4054,
    Code: '2313',
    Name: 'Nun, teaching, secondary school'
  },
  {
    Id: 4055,
    Code: '2313',
    Name: 'Principal teacher of guidance'
  },
  {
    Id: 4056,
    Code: '2313',
    Name: 'Teacher, art, secondary school'
  },
  {
    Id: 4057,
    Code: '2313',
    Name: 'Teacher, art and design, secondary school'
  },
  {
    Id: 4058,
    Code: '2313',
    Name: 'Teacher, biology, secondary school'
  },
  {
    Id: 4059,
    Code: '2313',
    Name: 'Teacher, chemistry, secondary school'
  },
  {
    Id: 4060,
    Code: '2313',
    Name: 'Teacher, college, form, sixth'
  },
  {
    Id: 4061,
    Code: '2313',
    Name: 'Teacher, dance, secondary school'
  },
  {
    Id: 4062,
    Code: '2313',
    Name: 'Teacher, dancing, secondary school'
  },
  {
    Id: 4063,
    Code: '2313',
    Name: 'Teacher, design and technology, secondary school'
  },
  {
    Id: 4064,
    Code: '2313',
    Name: 'Teacher, drama, secondary school'
  },
  {
    Id: 4065,
    Code: '2313',
    Name: 'Teacher, economics, home, secondary school'
  },
  {
    Id: 4066,
    Code: '2313',
    Name: 'Teacher, economics, secondary school'
  },
  {
    Id: 4067,
    Code: '2313',
    Name: 'Teacher, education, physical, secondary school'
  },
  {
    Id: 4068,
    Code: '2313',
    Name: 'Teacher, education, religious, secondary school'
  },
  {
    Id: 4069,
    Code: '2313',
    Name: 'Teacher, English, secondary school'
  },
  {
    Id: 4070,
    Code: '2313',
    Name: 'Teacher, form, sixth'
  },
  {
    Id: 4071,
    Code: '2313',
    Name: 'Teacher, French, secondary school'
  },
  {
    Id: 4072,
    Code: '2313',
    Name: 'Teacher, geography, secondary school'
  },
  {
    Id: 4073,
    Code: '2313',
    Name: 'Teacher, German, secondary school'
  },
  {
    Id: 4074,
    Code: '2313',
    Name: 'Teacher, head, assistant, secondary school'
  },
  {
    Id: 4075,
    Code: '2313',
    Name: 'Teacher, head, assistant, sixth form college'
  },
  {
    Id: 4076,
    Code: '2313',
    Name: 'Teacher, head, deputy, secondary school'
  },
  {
    Id: 4077,
    Code: '2313',
    Name: 'Teacher, head, deputy, sixth form college'
  },
  {
    Id: 4078,
    Code: '2313',
    Name: 'Teacher, history, secondary school'
  },
  {
    Id: 4079,
    Code: '2313',
    Name: 'Teacher, humanities, secondary school'
  },
  {
    Id: 4080,
    Code: '2313',
    Name: 'Teacher, ICT, secondary school'
  },
  {
    Id: 4081,
    Code: '2313',
    Name: 'Teacher, IT, secondary school'
  },
  {
    Id: 4082,
    Code: '2313',
    Name: 'Teacher, Italian, secondary school'
  },
  {
    Id: 4083,
    Code: '2313',
    Name: 'Teacher, language, secondary school'
  },
  {
    Id: 4084,
    Code: '2313',
    Name: 'Teacher, languages, foreign, modern, secondary school'
  },
  {
    Id: 4085,
    Code: '2313',
    Name: 'Teacher, mathematics, secondary school'
  },
  {
    Id: 4086,
    Code: '2313',
    Name: 'Teacher, MFL, secondary school'
  },
  {
    Id: 4087,
    Code: '2313',
    Name: 'Teacher, music, secondary school'
  },
  {
    Id: 4088,
    Code: '2313',
    Name: 'Teacher, PE, secondary school'
  },
  {
    Id: 4089,
    Code: '2313',
    Name: 'Teacher, physics, sixth form college'
  },
  {
    Id: 4090,
    Code: '2313',
    Name: 'Teacher, psychology, sixth form college'
  },
  {
    Id: 4091,
    Code: '2313',
    Name: 'Teacher, RE, secondary school'
  },
  {
    Id: 4092,
    Code: '2313',
    Name: 'Teacher, school, comprehensive'
  },
  {
    Id: 4093,
    Code: '2313',
    Name: 'Teacher, school, secondary'
  },
  {
    Id: 4094,
    Code: '2313',
    Name: 'Teacher, school, upper'
  },
  {
    Id: 4095,
    Code: '2313',
    Name: 'Teacher, science, computer, secondary school'
  },
  {
    Id: 4096,
    Code: '2313',
    Name: 'Teacher, science, secondary school'
  },
  {
    Id: 4097,
    Code: '2313',
    Name: 'Teacher, secondary'
  },
  {
    Id: 4098,
    Code: '2313',
    Name: 'Teacher, sociology, sixth form college'
  },
  {
    Id: 4099,
    Code: '2313',
    Name: 'Teacher, Spanish, secondary school'
  },
  {
    Id: 4100,
    Code: '2313',
    Name: 'Teacher, sports, secondary school'
  },
  {
    Id: 4101,
    Code: '2313',
    Name: 'Teacher, technology, information, secondary school'
  },
  {
    Id: 4102,
    Code: '2313',
    Name: 'Teacher, technology, information and communication, secondary school'
  },
  {
    Id: 4103,
    Code: '2313',
    Name: 'Teacher, Welsh, secondary school'
  },
  {
    Id: 4104,
    Code: '2313',
    Name: 'Teacher, art, secondary school'
  },
  {
    Id: 4105,
    Code: '2313',
    Name: 'Teacher, biology, secondary school'
  },
  {
    Id: 4106,
    Code: '2313',
    Name: 'Teacher, chemistry, secondary school'
  },
  {
    Id: 4107,
    Code: '2313',
    Name: 'Teacher, design technology, secondary school'
  },
  {
    Id: 4108,
    Code: '2313',
    Name: 'Teacher, drama, secondary school'
  },
  {
    Id: 4109,
    Code: '2313',
    Name: 'Teacher, economics, secondary school'
  },
  {
    Id: 4110,
    Code: '2313',
    Name: 'Teacher, English, secondary school'
  },
  {
    Id: 4111,
    Code: '2313',
    Name: 'Teacher, French, secondary school'
  },
  {
    Id: 4112,
    Code: '2313',
    Name: 'Teacher, geography, secondary school'
  },
  {
    Id: 4113,
    Code: '2313',
    Name: 'Teacher, German, secondary school'
  },
  {
    Id: 4114,
    Code: '2313',
    Name: 'Teacher, history, secondary school'
  },
  {
    Id: 4115,
    Code: '2313',
    Name: 'Teacher, ICT and computer science, secondary school'
  },
  {
    Id: 4116,
    Code: '2313',
    Name: 'Teacher, Italian, secondary school'
  },
  {
    Id: 4117,
    Code: '2313',
    Name: 'Teacher, mathematics, secondary school'
  },
  {
    Id: 4118,
    Code: '2313',
    Name: 'Teacher, modern foreign languages, secondary school'
  },
  {
    Id: 4119,
    Code: '2313',
    Name: 'Teacher, music, secondary school'
  },
  {
    Id: 4120,
    Code: '2313',
    Name: 'Teacher, physical education, secondary school'
  },
  {
    Id: 4121,
    Code: '2313',
    Name: 'Teacher, physics, secondary school'
  },
  {
    Id: 4122,
    Code: '2313',
    Name: 'Teacher, psychology, secondary school'
  },
  {
    Id: 4123,
    Code: '2313',
    Name: 'Teacher, religious education, secondary school'
  },
  {
    Id: 4124,
    Code: '2313',
    Name: 'Teacher, science, secondary school'
  },
  {
    Id: 4125,
    Code: '2313',
    Name: 'Teacher, sociology, secondary school'
  },
  {
    Id: 4126,
    Code: '2313',
    Name: 'Teacher, Spanish, secondary school'
  },
  {
    Id: 4127,
    Code: '2313',
    Name: 'Teacher, Welsh, secondary school'
  },
  {
    Id: 4128,
    Code: '2313',
    Name: 'Teacher, secondary school'
  },
  {
    Id: 4129,
    Code: '2313',
    Name: 'Teacher, sixth form college'
  },
  {
    Id: 4130,
    Code: '2313',
    Name: 'Tutor, secondary school'
  },
  {
    Id: 4131,
    Code: '2313',
    Name: 'Tutor, sixth form college'
  },
  {
    Id: 4132,
    Code: '2314',
    Name: 'Director of music, primary school'
  },
  {
    Id: 4133,
    Code: '2314',
    Name: 'Head, assistant, primary school'
  },
  {
    Id: 4134,
    Code: '2314',
    Name: 'Head, deputy, primary school'
  },
  {
    Id: 4135,
    Code: '2314',
    Name: 'Headteacher, assistant, primary school'
  },
  {
    Id: 4136,
    Code: '2314',
    Name: 'Headteacher, deputy, primary school'
  },
  {
    Id: 4137,
    Code: '2314',
    Name: 'Instructor, primary school'
  },
  {
    Id: 4138,
    Code: '2314',
    Name: 'Leader, curriculum, primary school'
  },
  {
    Id: 4139,
    Code: '2314',
    Name: 'Lecturer, dance, primary school'
  },
  {
    Id: 4140,
    Code: '2314',
    Name: 'Master, head, assistant, primary school'
  },
  {
    Id: 4141,
    Code: '2314',
    Name: 'Master, head, deputy, primary school'
  },
  {
    Id: 4142,
    Code: '2314',
    Name: 'Master, house, primary school'
  },
  {
    Id: 4143,
    Code: '2314',
    Name: 'Master, school, primary school'
  },
  {
    Id: 4144,
    Code: '2314',
    Name: 'Master, primary school'
  },
  {
    Id: 4145,
    Code: '2314',
    Name: 'Mistress, head, assistant, primary school'
  },
  {
    Id: 4146,
    Code: '2314',
    Name: 'Mistress, head, deputy, primary school'
  },
  {
    Id: 4147,
    Code: '2314',
    Name: 'Mistress, house, primary school'
  },
  {
    Id: 4148,
    Code: '2314',
    Name: 'Mistress, school, primary school'
  },
  {
    Id: 4149,
    Code: '2314',
    Name: 'Mistress, primary school'
  },
  {
    Id: 4150,
    Code: '2314',
    Name: 'Monk, teaching, primary school'
  },
  {
    Id: 4151,
    Code: '2314',
    Name: 'Nun, teaching, primary school'
  },
  {
    Id: 4152,
    Code: '2314',
    Name: 'Teacher, dance, primary school'
  },
  {
    Id: 4153,
    Code: '2314',
    Name: 'Teacher, dancing, primary school'
  },
  {
    Id: 4154,
    Code: '2314',
    Name: 'Teacher, head, assistant, primary school'
  },
  {
    Id: 4155,
    Code: '2314',
    Name: 'Teacher, head, deputy, primary school'
  },
  {
    Id: 4156,
    Code: '2314',
    Name: 'Teacher, infant'
  },
  {
    Id: 4157,
    Code: '2314',
    Name: 'Teacher, music, primary school'
  },
  {
    Id: 4158,
    Code: '2314',
    Name: 'Teacher, primary'
  },
  {
    Id: 4159,
    Code: '2314',
    Name: 'Teacher, school, infant'
  },
  {
    Id: 4160,
    Code: '2314',
    Name: 'Teacher, school, junior'
  },
  {
    Id: 4161,
    Code: '2314',
    Name: 'Teacher, school, preparatory'
  },
  {
    Id: 4162,
    Code: '2314',
    Name: 'Teacher, school, primary'
  },
  {
    Id: 4163,
    Code: '2314',
    Name: 'Teacher, junior school'
  },
  {
    Id: 4164,
    Code: '2314',
    Name: 'Teacher, primary school'
  },
  {
    Id: 4165,
    Code: '2315',
    Name: 'Consultant, stage, foundation'
  },
  {
    Id: 4166,
    Code: '2315',
    Name: 'Coordinator, stage, foundation'
  },
  {
    Id: 4167,
    Code: '2315',
    Name: 'Head, assistant, nursery school'
  },
  {
    Id: 4168,
    Code: '2315',
    Name: 'Head, deputy, nursery school'
  },
  {
    Id: 4169,
    Code: '2315',
    Name: 'Headteacher, assistant, nursery school'
  },
  {
    Id: 4170,
    Code: '2315',
    Name: 'Headteacher, deputy, nursery school'
  },
  {
    Id: 4171,
    Code: '2315',
    Name: 'Instructor, nursery school'
  },
  {
    Id: 4172,
    Code: '2315',
    Name: 'Master, head, assistant, nursery school'
  },
  {
    Id: 4173,
    Code: '2315',
    Name: 'Master, head, deputy, nursery school'
  },
  {
    Id: 4174,
    Code: '2315',
    Name: 'Master, house, nursery school'
  },
  {
    Id: 4175,
    Code: '2315',
    Name: 'Master, school, nursery school'
  },
  {
    Id: 4176,
    Code: '2315',
    Name: 'Master, nursery school'
  },
  {
    Id: 4177,
    Code: '2315',
    Name: 'Mistress, head, assistant, nursery school'
  },
  {
    Id: 4178,
    Code: '2315',
    Name: 'Mistress, head, deputy, nursery school'
  },
  {
    Id: 4179,
    Code: '2315',
    Name: 'Mistress, house, nursery school'
  },
  {
    Id: 4180,
    Code: '2315',
    Name: 'Mistress, school, nursery school'
  },
  {
    Id: 4181,
    Code: '2315',
    Name: 'Mistress, nursery school'
  },
  {
    Id: 4182,
    Code: '2315',
    Name: "Pedagogue, centre, children's"
  },
  {
    Id: 4183,
    Code: '2315',
    Name: 'Teacher, head, assistant, nursery school'
  },
  {
    Id: 4184,
    Code: '2315',
    Name: 'Teacher, head, deputy, nursery school'
  },
  {
    Id: 4185,
    Code: '2315',
    Name: 'Teacher, kindergarten, professional'
  },
  {
    Id: 4186,
    Code: '2315',
    Name: 'Teacher, Montessori'
  },
  {
    Id: 4187,
    Code: '2315',
    Name: 'Teacher, nursery, professional'
  },
  {
    Id: 4188,
    Code: '2315',
    Name: 'Teacher, pre-school, professional'
  },
  {
    Id: 4189,
    Code: '2315',
    Name: 'Teacher, school, nursery'
  },
  {
    Id: 4190,
    Code: '2315',
    Name: 'Teacher, years, early'
  },
  {
    Id: 4191,
    Code: '2315',
    Name: 'Teacher, kindergarten'
  },
  {
    Id: 4192,
    Code: '2315',
    Name: 'Teacher, nursery school'
  },
  {
    Id: 4193,
    Code: '2316',
    Name: 'Advisory teacher of the deaf'
  },
  {
    Id: 4194,
    Code: '2316',
    Name: 'ALNCo'
  },
  {
    Id: 4195,
    Code: '2316',
    Name: 'Coordinator, needs, educational, special'
  },
  {
    Id: 4196,
    Code: '2316',
    Name: 'Coordinator, needs, learning, additional'
  },
  {
    Id: 4197,
    Code: '2316',
    Name: 'Coordinator, needs, special'
  },
  {
    Id: 4198,
    Code: '2316',
    Name: 'Educationalist, needs, special'
  },
  {
    Id: 4199,
    Code: '2316',
    Name: 'Head, assistant, special/additional needs school'
  },
  {
    Id: 4200,
    Code: '2316',
    Name: 'Head, deputy, special/additional needs school'
  },
  {
    Id: 4201,
    Code: '2316',
    Name: 'Headteacher, assistant, special/additional needs school'
  },
  {
    Id: 4202,
    Code: '2316',
    Name: 'Headteacher, deputy, special/additional needs school'
  },
  {
    Id: 4203,
    Code: '2316',
    Name: 'Instructor, special/additional needs school'
  },
  {
    Id: 4204,
    Code: '2316',
    Name: 'Lecturer, dance, special/additional needs school'
  },
  {
    Id: 4205,
    Code: '2316',
    Name: 'Lecturer, special/additional needs school'
  },
  {
    Id: 4206,
    Code: '2316',
    Name: 'Master, head, assistant, special/additional needs school'
  },
  {
    Id: 4207,
    Code: '2316',
    Name: 'Master, head, deputy, special/additional needs school'
  },
  {
    Id: 4208,
    Code: '2316',
    Name: 'Master, house, special/additional needs school'
  },
  {
    Id: 4209,
    Code: '2316',
    Name: 'Master, school, special/additional needs school'
  },
  {
    Id: 4210,
    Code: '2316',
    Name: 'Master, special/additional needs school'
  },
  {
    Id: 4211,
    Code: '2316',
    Name: 'Mistress, head, assistant, special/additional needs school'
  },
  {
    Id: 4212,
    Code: '2316',
    Name: 'Mistress, head, deputy, special/additional needs school'
  },
  {
    Id: 4213,
    Code: '2316',
    Name: 'Mistress, house, special/additional needs school'
  },
  {
    Id: 4214,
    Code: '2316',
    Name: 'Mistress, school, special/additional needs school'
  },
  {
    Id: 4215,
    Code: '2316',
    Name: 'Mistress, special/additional needs school'
  },
  {
    Id: 4216,
    Code: '2316',
    Name: 'Officer, needs, educational, special'
  },
  {
    Id: 4217,
    Code: '2316',
    Name: 'Officer, needs, learning, additional'
  },
  {
    Id: 4218,
    Code: '2316',
    Name: 'Owner, school: special/additional needs school'
  },
  {
    Id: 4219,
    Code: '2316',
    Name: 'SENCo'
  },
  {
    Id: 4220,
    Code: '2316',
    Name: 'SENDco'
  },
  {
    Id: 4221,
    Code: '2316',
    Name: 'Teacher, behaviour'
  },
  {
    Id: 4222,
    Code: '2316',
    Name: 'Teacher, dance, special/additional needs school'
  },
  {
    Id: 4223,
    Code: '2316',
    Name: 'Teacher, dancing, special/additional needs school'
  },
  {
    Id: 4224,
    Code: '2316',
    Name: 'Teacher, head, assistant, special/additional needs school'
  },
  {
    Id: 4225,
    Code: '2316',
    Name: 'Teacher, head, deputy, special/additional needs school'
  },
  {
    Id: 4226,
    Code: '2316',
    Name: 'Teacher, music, special/additional needs school'
  },
  {
    Id: 4227,
    Code: '2316',
    Name: 'Teacher, needs, educational, special'
  },
  {
    Id: 4228,
    Code: '2316',
    Name: 'Teacher, needs, special'
  },
  {
    Id: 4229,
    Code: '2316',
    Name: 'Teacher, portage, educational establishments'
  },
  {
    Id: 4230,
    Code: '2316',
    Name: 'Teacher, SEN'
  },
  {
    Id: 4231,
    Code: '2316',
    Name: 'Teacher, support, behaviour'
  },
  {
    Id: 4232,
    Code: '2316',
    Name: 'Teacher, support, learning'
  },
  {
    Id: 4233,
    Code: '2316',
    Name: 'Teacher, special/additional needs'
  },
  {
    Id: 4234,
    Code: '2316',
    Name: 'Teacher, special/additional needs school'
  },
  {
    Id: 4235,
    Code: '2316',
    Name: 'Teacher of the deaf'
  },
  {
    Id: 4236,
    Code: '2316',
    Name: 'Teacher of the hearing impaired'
  },
  {
    Id: 4237,
    Code: '2316',
    Name: 'Teacher of the visually impaired'
  },
  {
    Id: 4238,
    Code: '2316',
    Name: 'Tutor, needs, special'
  },
  {
    Id: 4239,
    Code: '2316',
    Name: 'Tutor, support, learning'
  },
  {
    Id: 4240,
    Code: '2316',
    Name: 'Tutor, special/additional needs'
  },
  {
    Id: 4241,
    Code: '2317',
    Name: 'Lecturer, ESOL'
  },
  {
    Id: 4242,
    Code: '2317',
    Name: 'TEFL'
  },
  {
    Id: 4243,
    Code: '2317',
    Name: 'Teacher, EFL'
  },
  {
    Id: 4244,
    Code: '2317',
    Name: 'Teacher, English'
  },
  {
    Id: 4245,
    Code: '2317',
    Name: 'Teacher, English as a second language'
  },
  {
    Id: 4246,
    Code: '2317',
    Name: 'Teacher, English for specific purposes'
  },
  {
    Id: 4247,
    Code: '2317',
    Name: 'Teacher, ESL'
  },
  {
    Id: 4248,
    Code: '2317',
    Name: 'Teacher, ESOL'
  },
  {
    Id: 4249,
    Code: '2317',
    Name: 'Teacher, ESP'
  },
  {
    Id: 4250,
    Code: '2317',
    Name: 'Teacher, language, English'
  },
  {
    Id: 4251,
    Code: '2317',
    Name: 'Teacher, language, TEFL'
  },
  {
    Id: 4252,
    Code: '2317',
    Name: 'Teacher, TEFL'
  },
  {
    Id: 4253,
    Code: '2317',
    Name: 'Teacher, TEFL'
  },
  {
    Id: 4254,
    Code: '2317',
    Name: 'Teacher of English as a foreign language'
  },
  {
    Id: 4255,
    Code: '2317',
    Name: 'Tutor, ESOL'
  },
  {
    Id: 4256,
    Code: '2319',
    Name: 'Assistant, language'
  },
  {
    Id: 4257,
    Code: '2319',
    Name: 'Coach, vocal'
  },
  {
    Id: 4258,
    Code: '2319',
    Name: 'Coach, voice'
  },
  {
    Id: 4259,
    Code: '2319',
    Name: 'Communicator, science'
  },
  {
    Id: 4260,
    Code: '2319',
    Name: 'Instructor, music'
  },
  {
    Id: 4261,
    Code: '2319',
    Name: 'Instructor, piano'
  },
  {
    Id: 4262,
    Code: '2319',
    Name: 'Instructor, staff, senior'
  },
  {
    Id: 4263,
    Code: '2319',
    Name: 'Instructor, violin'
  },
  {
    Id: 4264,
    Code: '2319',
    Name: 'Lecturer, dance'
  },
  {
    Id: 4265,
    Code: '2319',
    Name: 'Mentor, academic, qualified'
  },
  {
    Id: 4266,
    Code: '2319',
    Name: 'Owner, school, dancing'
  },
  {
    Id: 4267,
    Code: '2319',
    Name: 'Owner, school, language'
  },
  {
    Id: 4268,
    Code: '2319',
    Name: 'Owner, school: dancing'
  },
  {
    Id: 4269,
    Code: '2319',
    Name: 'Owner, school: language'
  },
  {
    Id: 4270,
    Code: '2319',
    Name: 'Owner, school: primary'
  },
  {
    Id: 4271,
    Code: '2319',
    Name: 'Owner, school: secondary'
  },
  {
    Id: 4272,
    Code: '2319',
    Name: 'Principal, school, dancing, private'
  },
  {
    Id: 4273,
    Code: '2319',
    Name: 'Principal, school, music, private'
  },
  {
    Id: 4274,
    Code: '2319',
    Name: 'Principal, adult education centre'
  },
  {
    Id: 4275,
    Code: '2319',
    Name: 'Principal, dancing school'
  },
  {
    Id: 4276,
    Code: '2319',
    Name: 'Principal, training establishment'
  },
  {
    Id: 4277,
    Code: '2319',
    Name: 'Teacher, ballet, qualified'
  },
  {
    Id: 4278,
    Code: '2319',
    Name: 'Teacher, BSL'
  },
  {
    Id: 4279,
    Code: '2319',
    Name: 'Teacher, centre, education, adult'
  },
  {
    Id: 4280,
    Code: '2319',
    Name: 'Teacher, education, adult'
  },
  {
    Id: 4281,
    Code: '2319',
    Name: 'Teacher, instrumental'
  },
  {
    Id: 4282,
    Code: '2319',
    Name: 'Teacher, language, assistant'
  },
  {
    Id: 4283,
    Code: '2319',
    Name: 'Teacher, language, sign, British'
  },
  {
    Id: 4284,
    Code: '2319',
    Name: 'Teacher, language'
  },
  {
    Id: 4285,
    Code: '2319',
    Name: 'Teacher, music, instrumental'
  },
  {
    Id: 4286,
    Code: '2319',
    Name: 'Teacher, music, peripatetic'
  },
  {
    Id: 4287,
    Code: '2319',
    Name: 'Teacher, music, private'
  },
  {
    Id: 4288,
    Code: '2319',
    Name: 'Teacher, music'
  },
  {
    Id: 4289,
    Code: '2319',
    Name: 'Teacher, religious'
  },
  {
    Id: 4290,
    Code: '2319',
    Name: 'Teacher, singing'
  },
  {
    Id: 4291,
    Code: '2319',
    Name: 'Teacher, musical instruments'
  },
  {
    Id: 4292,
    Code: '2319',
    Name: 'Teacher, private'
  },
  {
    Id: 4293,
    Code: '2319',
    Name: 'Teacher, adult education centre'
  },
  {
    Id: 4294,
    Code: '2319',
    Name: 'Teacher, hospital service'
  },
  {
    Id: 4295,
    Code: '2319',
    Name: 'Tutor, adult'
  },
  {
    Id: 4296,
    Code: '2319',
    Name: 'Tutor, education, adult'
  },
  {
    Id: 4297,
    Code: '2319',
    Name: 'Tutor, home'
  },
  {
    Id: 4298,
    Code: '2319',
    Name: 'Tutor, learning, adult'
  },
  {
    Id: 4299,
    Code: '2319',
    Name: 'Tutor, personal'
  },
  {
    Id: 4300,
    Code: '2319',
    Name: 'Tutor, piano'
  },
  {
    Id: 4301,
    Code: '2319',
    Name: 'Tutor, private'
  },
  {
    Id: 4302,
    Code: '2319',
    Name: 'Tutor, skills, functional'
  },
  {
    Id: 4303,
    Code: '2319',
    Name: 'Tutor, private'
  },
  {
    Id: 4304,
    Code: '2319',
    Name: 'Tutor, adult education centre'
  },
  {
    Id: 4305,
    Code: '2319',
    Name: 'Worker, drama'
  },
  {
    Id: 4306,
    Code: '2321',
    Name: 'Head, further education'
  },
  {
    Id: 4307,
    Code: '2321',
    Name: 'Head, higher education, university'
  },
  {
    Id: 4308,
    Code: '2321',
    Name: 'Head, nursery school'
  },
  {
    Id: 4309,
    Code: '2321',
    Name: 'Head, primary school'
  },
  {
    Id: 4310,
    Code: '2321',
    Name: 'Head, secondary school'
  },
  {
    Id: 4311,
    Code: '2321',
    Name: 'Head, sixth form college'
  },
  {
    Id: 4312,
    Code: '2321',
    Name: 'Head, special/additional needs school'
  },
  {
    Id: 4313,
    Code: '2321',
    Name: 'Head of school, further education'
  },
  {
    Id: 4314,
    Code: '2321',
    Name: 'Head of school, higher education, university'
  },
  {
    Id: 4315,
    Code: '2321',
    Name: 'Head of school, primary school'
  },
  {
    Id: 4316,
    Code: '2321',
    Name: 'Head of school, secondary school'
  },
  {
    Id: 4317,
    Code: '2321',
    Name: 'Headteacher, nursery school'
  },
  {
    Id: 4318,
    Code: '2321',
    Name: 'Headteacher, primary school'
  },
  {
    Id: 4319,
    Code: '2321',
    Name: 'Headteacher, secondary school'
  },
  {
    Id: 4320,
    Code: '2321',
    Name: 'Headteacher, sixth form college'
  },
  {
    Id: 4321,
    Code: '2321',
    Name: 'Headteacher, special/additional needs school'
  },
  {
    Id: 4322,
    Code: '2321',
    Name: 'Master, head, nursery school'
  },
  {
    Id: 4323,
    Code: '2321',
    Name: 'Master, head, primary school'
  },
  {
    Id: 4324,
    Code: '2321',
    Name: 'Master, head, secondary school'
  },
  {
    Id: 4325,
    Code: '2321',
    Name: 'Master, head, sixth form college'
  },
  {
    Id: 4326,
    Code: '2321',
    Name: 'Master, head, special/additional needs school'
  },
  {
    Id: 4327,
    Code: '2321',
    Name: 'Mistress, head, nursery school'
  },
  {
    Id: 4328,
    Code: '2321',
    Name: 'Mistress, head, primary school'
  },
  {
    Id: 4329,
    Code: '2321',
    Name: 'Mistress, head, secondary school'
  },
  {
    Id: 4330,
    Code: '2321',
    Name: 'Mistress, head, sixth form college'
  },
  {
    Id: 4331,
    Code: '2321',
    Name: 'Mistress, head, special/additional needs school'
  },
  {
    Id: 4332,
    Code: '2321',
    Name: 'President, college'
  },
  {
    Id: 4333,
    Code: '2321',
    Name: 'Principal, further education'
  },
  {
    Id: 4334,
    Code: '2321',
    Name: 'Principal, higher education, university'
  },
  {
    Id: 4335,
    Code: '2321',
    Name: 'Principal, primary school'
  },
  {
    Id: 4336,
    Code: '2321',
    Name: 'Principal, secondary school'
  },
  {
    Id: 4337,
    Code: '2321',
    Name: 'Principal, sixth form college'
  },
  {
    Id: 4338,
    Code: '2321',
    Name: 'Principal, special/additional needs school'
  },
  {
    Id: 4339,
    Code: '2321',
    Name: 'Rector, further education'
  },
  {
    Id: 4340,
    Code: '2321',
    Name: 'Rector, secondary school'
  },
  {
    Id: 4341,
    Code: '2321',
    Name: 'Rector, university'
  },
  {
    Id: 4342,
    Code: '2321',
    Name: 'Teacher, head, nursery school'
  },
  {
    Id: 4343,
    Code: '2321',
    Name: 'Teacher, head, primary school'
  },
  {
    Id: 4344,
    Code: '2321',
    Name: 'Teacher, head, secondary school'
  },
  {
    Id: 4345,
    Code: '2321',
    Name: 'Teacher, head, sixth form college'
  },
  {
    Id: 4346,
    Code: '2321',
    Name: 'Teacher, head, special/additional needs school'
  },
  {
    Id: 4347,
    Code: '2321',
    Name: 'Teacher, head'
  },
  {
    Id: 4348,
    Code: '2322',
    Name: 'COO, higher education, university'
  },
  {
    Id: 4349,
    Code: '2322',
    Name: 'Chancellor, vice, university'
  },
  {
    Id: 4350,
    Code: '2322',
    Name: 'Dean, further education'
  },
  {
    Id: 4351,
    Code: '2322',
    Name: 'Dean, higher education, university'
  },
  {
    Id: 4352,
    Code: '2322',
    Name: 'Director, academic'
  },
  {
    Id: 4353,
    Code: '2322',
    Name: 'Director of education'
  },
  {
    Id: 4354,
    Code: '2322',
    Name: 'Head of administration, educational establishments'
  },
  {
    Id: 4355,
    Code: '2322',
    Name: 'Manager, academic'
  },
  {
    Id: 4356,
    Code: '2322',
    Name: 'Manager, administrative, further education'
  },
  {
    Id: 4357,
    Code: '2322',
    Name: 'Manager, admissions, educational establishments'
  },
  {
    Id: 4358,
    Code: '2322',
    Name: 'Manager, business, school'
  },
  {
    Id: 4359,
    Code: '2322',
    Name: 'Manager, centre, higher education, university'
  },
  {
    Id: 4360,
    Code: '2322',
    Name: 'Manager, curriculum'
  },
  {
    Id: 4361,
    Code: '2322',
    Name: 'Manager, education'
  },
  {
    Id: 4362,
    Code: '2322',
    Name: 'Manager, learning'
  },
  {
    Id: 4363,
    Code: '2322',
    Name: 'Manager, programme, educational establishments'
  },
  {
    Id: 4364,
    Code: '2322',
    Name: 'Manager, school, language'
  },
  {
    Id: 4365,
    Code: '2322',
    Name: 'Manager, school, educational establishments'
  },
  {
    Id: 4366,
    Code: '2322',
    Name: 'Manager, services, student'
  },
  {
    Id: 4367,
    Code: '2322',
    Name: 'Manager, support, learning'
  },
  {
    Id: 4368,
    Code: '2322',
    Name: 'Manager, educational establishments'
  },
  {
    Id: 4369,
    Code: '2322',
    Name: 'Officer, operating, chief, higher education, university'
  },
  {
    Id: 4370,
    Code: '2322',
    Name: 'Principal, vice'
  },
  {
    Id: 4371,
    Code: '2322',
    Name: 'Provost, further education'
  },
  {
    Id: 4372,
    Code: '2322',
    Name: 'Provost, higher education, university'
  },
  {
    Id: 4373,
    Code: '2322',
    Name: 'Registrar, academic'
  },
  {
    Id: 4374,
    Code: '2322',
    Name: 'Registrar, educational establishments'
  },
  {
    Id: 4375,
    Code: '2322',
    Name: 'Vice-Chancellor, university'
  },
  {
    Id: 4376,
    Code: '2323',
    Name: 'Adviser, academic'
  },
  {
    Id: 4377,
    Code: '2323',
    Name: 'Adviser, curriculum'
  },
  {
    Id: 4378,
    Code: '2323',
    Name: 'Adviser, development, curriculum'
  },
  {
    Id: 4379,
    Code: '2323',
    Name: 'Adviser, education'
  },
  {
    Id: 4380,
    Code: '2323',
    Name: 'Adviser, educational'
  },
  {
    Id: 4381,
    Code: '2323',
    Name: 'Adviser, improvement, school'
  },
  {
    Id: 4382,
    Code: '2323',
    Name: 'Adviser, skills, academic'
  },
  {
    Id: 4383,
    Code: '2323',
    Name: 'Adviser, teaching, years, early, local government: social inclusion dept'
  },
  {
    Id: 4384,
    Code: '2323',
    Name: 'Adviser, training, teacher'
  },
  {
    Id: 4385,
    Code: '2323',
    Name: 'Adviser, years, early'
  },
  {
    Id: 4386,
    Code: '2323',
    Name: 'Adviser, local government: education authority'
  },
  {
    Id: 4387,
    Code: '2323',
    Name: 'Assessor, education'
  },
  {
    Id: 4388,
    Code: '2323',
    Name: 'Assessor, educational'
  },
  {
    Id: 4389,
    Code: '2323',
    Name: 'Assessor, further, higher education'
  },
  {
    Id: 4390,
    Code: '2323',
    Name: 'Consultant, education'
  },
  {
    Id: 4391,
    Code: '2323',
    Name: 'Consultant, educational'
  },
  {
    Id: 4392,
    Code: '2323',
    Name: 'Consultant, languages, primary'
  },
  {
    Id: 4393,
    Code: '2323',
    Name: 'Consultant, years, early'
  },
  {
    Id: 4394,
    Code: '2323',
    Name: 'Convenor, module'
  },
  {
    Id: 4395,
    Code: '2323',
    Name: 'Coordinator, music'
  },
  {
    Id: 4396,
    Code: '2323',
    Name: 'HMIS'
  },
  {
    Id: 4397,
    Code: '2323',
    Name: 'Inspector, education'
  },
  {
    Id: 4398,
    Code: '2323',
    Name: 'Inspector, OFSTED'
  },
  {
    Id: 4399,
    Code: '2323',
    Name: 'Inspector, school'
  },
  {
    Id: 4400,
    Code: '2323',
    Name: 'Inspector, years, early'
  },
  {
    Id: 4401,
    Code: '2323',
    Name: 'Inspector, government: OFSTED'
  },
  {
    Id: 4402,
    Code: '2323',
    Name: 'Inspector, local government: education authority'
  },
  {
    Id: 4403,
    Code: '2323',
    Name: 'Inspector of schools'
  },
  {
    Id: 4404,
    Code: '2323',
    Name: 'Manager, inclusion, local government: education authority'
  },
  {
    Id: 4405,
    Code: '2323',
    Name: 'Officer, development, curriculum'
  },
  {
    Id: 4406,
    Code: '2323',
    Name: 'Officer, education'
  },
  {
    Id: 4407,
    Code: '2323',
    Name: 'Officer, improvement, school'
  },
  {
    Id: 4408,
    Code: '2323',
    Name: 'Officer, inclusion, local government: education authority'
  },
  {
    Id: 4409,
    Code: '2323',
    Name: 'Teacher, advisory, years, early'
  },
  {
    Id: 4410,
    Code: '2323',
    Name: 'Teacher, advisory'
  },
  {
    Id: 4411,
    Code: '2324',
    Name: 'Manager, care, child, day nursery'
  },
  {
    Id: 4412,
    Code: '2324',
    Name: 'Manager, childcare'
  },
  {
    Id: 4413,
    Code: '2324',
    Name: 'Manager, club, out of school'
  },
  {
    Id: 4414,
    Code: '2324',
    Name: 'Manager, club, school, after'
  },
  {
    Id: 4415,
    Code: '2324',
    Name: 'Manager, club, before and after school club'
  },
  {
    Id: 4416,
    Code: '2324',
    Name: 'Manager, crèche'
  },
  {
    Id: 4417,
    Code: '2324',
    Name: 'Manager, group, play'
  },
  {
    Id: 4418,
    Code: '2324',
    Name: 'Manager, nursery, day nursery'
  },
  {
    Id: 4419,
    Code: '2324',
    Name: 'Manager, playgroup'
  },
  {
    Id: 4420,
    Code: '2324',
    Name: 'Manager, pre-school'
  },
  {
    Id: 4421,
    Code: '2324',
    Name: "Manager, room, base, children's nursery"
  },
  {
    Id: 4422,
    Code: '2324',
    Name: 'Manager, school, after'
  },
  {
    Id: 4423,
    Code: '2324',
    Name: 'Manager, school, nursery'
  },
  {
    Id: 4424,
    Code: '2324',
    Name: 'Manager, years, early'
  },
  {
    Id: 4425,
    Code: '2324',
    Name: 'Manager, before and after school club'
  },
  {
    Id: 4426,
    Code: '2324',
    Name: "Manager, children's nursery"
  },
  {
    Id: 4427,
    Code: '2329',
    Name: 'Administrator, college'
  },
  {
    Id: 4428,
    Code: '2329',
    Name: 'Administrator, education, further, higher education'
  },
  {
    Id: 4429,
    Code: '2329',
    Name: 'Administrator, university'
  },
  {
    Id: 4430,
    Code: '2329',
    Name: 'Administrator, higher education, university'
  },
  {
    Id: 4431,
    Code: '2329',
    Name: 'Bursar'
  },
  {
    Id: 4432,
    Code: '2329',
    Name: 'Coordinator, academic'
  },
  {
    Id: 4433,
    Code: '2329',
    Name: 'Coordinator, project, educational establishments'
  },
  {
    Id: 4434,
    Code: '2329',
    Name: 'Coordinator, schools, active'
  },
  {
    Id: 4435,
    Code: '2329',
    Name: 'Coordinator, teaching'
  },
  {
    Id: 4436,
    Code: '2329',
    Name: 'Examiner, examination board'
  },
  {
    Id: 4437,
    Code: '2329',
    Name: 'Executive, marketing, international, university'
  },
  {
    Id: 4438,
    Code: '2329',
    Name: 'Governess'
  },
  {
    Id: 4439,
    Code: '2329',
    Name: 'Manager, recruitment, student, international'
  },
  {
    Id: 4440,
    Code: '2329',
    Name: 'Marker, exam'
  },
  {
    Id: 4441,
    Code: '2329',
    Name: 'Moderator, examination board'
  },
  {
    Id: 4442,
    Code: '2329',
    Name: 'Officer, examinations, examination board'
  },
  {
    Id: 4443,
    Code: '2329',
    Name: 'Officer, exams, examination board'
  },
  {
    Id: 4444,
    Code: '2329',
    Name: 'Officer, executive, university'
  },
  {
    Id: 4445,
    Code: '2329',
    Name: 'Officer, marketing, international, university'
  },
  {
    Id: 4446,
    Code: '2329',
    Name: 'Officer, recruitment, international, university'
  },
  {
    Id: 4447,
    Code: '2329',
    Name: 'Officer, support, learning, further, higher education'
  },
  {
    Id: 4448,
    Code: '2329',
    Name: 'Officer, support, teaching'
  },
  {
    Id: 4449,
    Code: '2329',
    Name: 'Organiser, education, adult'
  },
  {
    Id: 4450,
    Code: '2329',
    Name: 'Organiser, adult education centre'
  },
  {
    Id: 4451,
    Code: '2329',
    Name: 'Tutor, academic, personal'
  },
  {
    Id: 4452,
    Code: '2329',
    Name: 'Tutor, academic'
  },
  {
    Id: 4453,
    Code: '2329',
    Name: 'Verifier, educational establishments'
  },
  {
    Id: 4454,
    Code: '2411',
    Name: 'Advocate, crown'
  },
  {
    Id: 4455,
    Code: '2411',
    Name: 'Advocate, legal'
  },
  {
    Id: 4456,
    Code: '2411',
    Name: 'Advocate, solicitor'
  },
  {
    Id: 4457,
    Code: '2411',
    Name: 'Advocate'
  },
  {
    Id: 4458,
    Code: '2411',
    Name: 'Barrister'
  },
  {
    Id: 4459,
    Code: '2411',
    Name: 'Barrister-at-law'
  },
  {
    Id: 4460,
    Code: '2411',
    Name: 'Chairman, appeals tribunal, inquiry'
  },
  {
    Id: 4461,
    Code: '2411',
    Name: 'Clerk, sheriff, Scotland'
  },
  {
    Id: 4462,
    Code: '2411',
    Name: 'Coroner'
  },
  {
    Id: 4463,
    Code: '2411',
    Name: "Counsel, King's"
  },
  {
    Id: 4464,
    Code: '2411',
    Name: "Counsel, Queen's"
  },
  {
    Id: 4465,
    Code: '2411',
    Name: 'JP, stipendiary'
  },
  {
    Id: 4466,
    Code: '2411',
    Name: 'Judge, circuit'
  },
  {
    Id: 4467,
    Code: '2411',
    Name: 'Judge, district'
  },
  {
    Id: 4468,
    Code: '2411',
    Name: 'Judge, legal services'
  },
  {
    Id: 4469,
    Code: '2411',
    Name: 'Justice of the Peace, stipendiary'
  },
  {
    Id: 4470,
    Code: '2411',
    Name: 'KC'
  },
  {
    Id: 4471,
    Code: '2411',
    Name: "King's Counsel"
  },
  {
    Id: 4472,
    Code: '2411',
    Name: 'Magistrate'
  },
  {
    Id: 4473,
    Code: '2411',
    Name: 'Master, high courts'
  },
  {
    Id: 4474,
    Code: '2411',
    Name: 'Member of the Inner Temple'
  },
  {
    Id: 4475,
    Code: '2411',
    Name: 'Procurator fiscal'
  },
  {
    Id: 4476,
    Code: '2411',
    Name: 'Prosecutor, Crown'
  },
  {
    Id: 4477,
    Code: '2411',
    Name: 'QC'
  },
  {
    Id: 4478,
    Code: '2411',
    Name: "Queen's Counsel"
  },
  {
    Id: 4479,
    Code: '2411',
    Name: 'Recorder, legal services'
  },
  {
    Id: 4480,
    Code: '2411',
    Name: 'Referee, official, legal services'
  },
  {
    Id: 4481,
    Code: '2411',
    Name: 'Registrar, court, county'
  },
  {
    Id: 4482,
    Code: '2411',
    Name: 'Registrar, probate'
  },
  {
    Id: 4483,
    Code: '2411',
    Name: 'Registrar, legal services'
  },
  {
    Id: 4484,
    Code: '2411',
    Name: 'Sheriff, Scottish Courts and Tribunals Service'
  },
  {
    Id: 4485,
    Code: '2411',
    Name: 'Sheriff-substitute, Scottish Courts and Tribunals Service'
  },
  {
    Id: 4486,
    Code: '2411',
    Name: 'Solicitor-advocate'
  },
  {
    Id: 4487,
    Code: '2412',
    Name: 'Associate, legal, qualified solicitor'
  },
  {
    Id: 4488,
    Code: '2412',
    Name: 'Clerk, managing, qualified solicitor'
  },
  {
    Id: 4489,
    Code: '2412',
    Name: 'Lawyer, commercial'
  },
  {
    Id: 4490,
    Code: '2412',
    Name: 'Lawyer, criminal'
  },
  {
    Id: 4491,
    Code: '2412',
    Name: 'Lawyer, family'
  },
  {
    Id: 4492,
    Code: '2412',
    Name: 'Lawyer, property'
  },
  {
    Id: 4493,
    Code: '2412',
    Name: 'Lawyer, commercial law'
  },
  {
    Id: 4494,
    Code: '2412',
    Name: 'Lawyer, criminal law'
  },
  {
    Id: 4495,
    Code: '2412',
    Name: 'Lawyer, family law'
  },
  {
    Id: 4496,
    Code: '2412',
    Name: 'Lawyer, property law'
  },
  {
    Id: 4497,
    Code: '2412',
    Name: 'Lawyer'
  },
  {
    Id: 4498,
    Code: '2412',
    Name: 'Solicitor, commercial'
  },
  {
    Id: 4499,
    Code: '2412',
    Name: 'Solicitor, criminal'
  },
  {
    Id: 4500,
    Code: '2412',
    Name: 'Solicitor, family'
  },
  {
    Id: 4501,
    Code: '2412',
    Name: 'Solicitor, property'
  },
  {
    Id: 4502,
    Code: '2412',
    Name: 'Solicitor, commercial law'
  },
  {
    Id: 4503,
    Code: '2412',
    Name: 'Solicitor, criminal law'
  },
  {
    Id: 4504,
    Code: '2412',
    Name: 'Solicitor, family law'
  },
  {
    Id: 4505,
    Code: '2412',
    Name: 'Solicitor, property law'
  },
  {
    Id: 4506,
    Code: '2412',
    Name: 'Solicitor'
  },
  {
    Id: 4507,
    Code: '2412',
    Name: 'Solicitor of the Supreme Court'
  },
  {
    Id: 4508,
    Code: '2412',
    Name: 'Solicitor to the Council'
  },
  {
    Id: 4509,
    Code: '2412',
    Name: 'Solicitor-partner'
  },
  {
    Id: 4510,
    Code: '2412',
    Name: 'Writer to the Signet'
  },
  {
    Id: 4511,
    Code: '2419',
    Name: 'Accountant of Court, Scotland'
  },
  {
    Id: 4512,
    Code: '2419',
    Name: 'Adjudicator, parking'
  },
  {
    Id: 4513,
    Code: '2419',
    Name: 'Adjudicator, Home Office'
  },
  {
    Id: 4514,
    Code: '2419',
    Name: 'Adviser, legal, court'
  },
  {
    Id: 4515,
    Code: '2419',
    Name: 'Adviser, legal, protection, data'
  },
  {
    Id: 4516,
    Code: '2419',
    Name: 'Adviser, legal'
  },
  {
    Id: 4517,
    Code: '2419',
    Name: 'Adviser, protection, data'
  },
  {
    Id: 4518,
    Code: '2419',
    Name: 'Adviser, law'
  },
  {
    Id: 4519,
    Code: '2419',
    Name: 'Agent, law'
  },
  {
    Id: 4520,
    Code: '2419',
    Name: 'Agent, parliamentary'
  },
  {
    Id: 4521,
    Code: '2419',
    Name: 'Agent, patent'
  },
  {
    Id: 4522,
    Code: '2419',
    Name: 'Arbitrator, legal services'
  },
  {
    Id: 4523,
    Code: '2419',
    Name: 'Assistant, court, qualified'
  },
  {
    Id: 4524,
    Code: '2419',
    Name: 'Attorney, mark, trade'
  },
  {
    Id: 4525,
    Code: '2419',
    Name: 'Attorney, patent'
  },
  {
    Id: 4526,
    Code: '2419',
    Name: 'Attorney'
  },
  {
    Id: 4527,
    Code: '2419',
    Name: 'Auditor of Court, Scotland'
  },
  {
    Id: 4528,
    Code: '2419',
    Name: 'Clerk, articled'
  },
  {
    Id: 4529,
    Code: '2419',
    Name: 'Clerk, chief, courts of justice'
  },
  {
    Id: 4530,
    Code: '2419',
    Name: 'Clerk, court, qualified'
  },
  {
    Id: 4531,
    Code: '2419',
    Name: "Clerk, justice's"
  },
  {
    Id: 4532,
    Code: '2419',
    Name: 'Clerk, law, articled'
  },
  {
    Id: 4533,
    Code: '2419',
    Name: "Clerk, magistrate's"
  },
  {
    Id: 4534,
    Code: '2419',
    Name: 'Clerk, sessions, quarter'
  },
  {
    Id: 4535,
    Code: '2419',
    Name: "Clerk, solicitor's, articled"
  },
  {
    Id: 4536,
    Code: '2419',
    Name: 'Clerk of arraigns'
  },
  {
    Id: 4537,
    Code: '2419',
    Name: 'Clerk of the court'
  },
  {
    Id: 4538,
    Code: '2419',
    Name: 'Clerk of the peace'
  },
  {
    Id: 4539,
    Code: '2419',
    Name: 'Clerk to the justices'
  },
  {
    Id: 4540,
    Code: '2419',
    Name: 'Commissioner, legal services'
  },
  {
    Id: 4541,
    Code: '2419',
    Name: 'Commissioner of oaths'
  },
  {
    Id: 4542,
    Code: '2419',
    Name: 'Consultant, immigration'
  },
  {
    Id: 4543,
    Code: '2419',
    Name: 'Consultant, legal'
  },
  {
    Id: 4544,
    Code: '2419',
    Name: 'Conveyancer, licensed'
  },
  {
    Id: 4545,
    Code: '2419',
    Name: 'Conveyancer'
  },
  {
    Id: 4546,
    Code: '2419',
    Name: 'Coordinator, legal'
  },
  {
    Id: 4547,
    Code: '2419',
    Name: 'Counsel, general'
  },
  {
    Id: 4548,
    Code: '2419',
    Name: 'Counsel, legal'
  },
  {
    Id: 4549,
    Code: '2419',
    Name: 'Counsellor, legal services'
  },
  {
    Id: 4550,
    Code: '2419',
    Name: 'Draftsman, parliamentary'
  },
  {
    Id: 4551,
    Code: '2419',
    Name: 'Executive, legal, chartered'
  },
  {
    Id: 4552,
    Code: '2419',
    Name: 'Executive, legal, professional'
  },
  {
    Id: 4553,
    Code: '2419',
    Name: 'Executive, legal'
  },
  {
    Id: 4554,
    Code: '2419',
    Name: 'Executive, litigation'
  },
  {
    Id: 4555,
    Code: '2419',
    Name: 'Manager, team, legal'
  },
  {
    Id: 4556,
    Code: '2419',
    Name: 'Notary'
  },
  {
    Id: 4557,
    Code: '2419',
    Name: 'Officer, legal'
  },
  {
    Id: 4558,
    Code: '2419',
    Name: 'Officer, services, legal, professional'
  },
  {
    Id: 4559,
    Code: '2419',
    Name: 'Owner, legal services'
  },
  {
    Id: 4560,
    Code: '2419',
    Name: 'Paralegal'
  },
  {
    Id: 4561,
    Code: '2419',
    Name: 'Reporter, children’s, Scotland'
  },
  {
    Id: 4562,
    Code: '2419',
    Name: "Reporter to the Children's Panel"
  },
  {
    Id: 4563,
    Code: '2421',
    Name: 'Accountant, bank'
  },
  {
    Id: 4564,
    Code: '2421',
    Name: 'Accountant, borough'
  },
  {
    Id: 4565,
    Code: '2421',
    Name: 'Accountant, branch'
  },
  {
    Id: 4566,
    Code: '2421',
    Name: 'Accountant, certified'
  },
  {
    Id: 4567,
    Code: '2421',
    Name: 'Accountant, chartered'
  },
  {
    Id: 4568,
    Code: '2421',
    Name: 'Accountant, chief, group'
  },
  {
    Id: 4569,
    Code: '2421',
    Name: 'Accountant, chief'
  },
  {
    Id: 4570,
    Code: '2421',
    Name: 'Accountant, company'
  },
  {
    Id: 4571,
    Code: '2421',
    Name: 'Accountant, cost, qualified'
  },
  {
    Id: 4572,
    Code: '2421',
    Name: 'Accountant, cost and management, qualified'
  },
  {
    Id: 4573,
    Code: '2421',
    Name: 'Accountant, cost and works, qualified'
  },
  {
    Id: 4574,
    Code: '2421',
    Name: 'Accountant, district'
  },
  {
    Id: 4575,
    Code: '2421',
    Name: 'Accountant, financial, group'
  },
  {
    Id: 4576,
    Code: '2421',
    Name: 'Accountant, financial, qualified'
  },
  {
    Id: 4577,
    Code: '2421',
    Name: 'Accountant, forensic'
  },
  {
    Id: 4578,
    Code: '2421',
    Name: 'Accountant, fund'
  },
  {
    Id: 4579,
    Code: '2421',
    Name: 'Accountant, group, qualified'
  },
  {
    Id: 4580,
    Code: '2421',
    Name: 'Accountant, incorporated'
  },
  {
    Id: 4581,
    Code: '2421',
    Name: 'Accountant, management, chartered'
  },
  {
    Id: 4582,
    Code: '2421',
    Name: 'Accountant, management, qualified'
  },
  {
    Id: 4583,
    Code: '2421',
    Name: 'Accountant, principal'
  },
  {
    Id: 4584,
    Code: '2421',
    Name: 'Accountant, treasury'
  },
  {
    Id: 4585,
    Code: '2421',
    Name: 'Accountant, works, qualified'
  },
  {
    Id: 4586,
    Code: '2421',
    Name: 'Accountant, qualified, cost and works accountancy'
  },
  {
    Id: 4587,
    Code: '2421',
    Name: 'Accountant, qualified, management accountancy'
  },
  {
    Id: 4588,
    Code: '2421',
    Name: 'Accountant, qualified'
  },
  {
    Id: 4589,
    Code: '2421',
    Name: 'Accountant, government'
  },
  {
    Id: 4590,
    Code: '2421',
    Name: 'Accountant-secretary, coal mine'
  },
  {
    Id: 4591,
    Code: '2421',
    Name: 'Administrator, insolvency, qualified'
  },
  {
    Id: 4592,
    Code: '2421',
    Name: 'Adviser, technical, accountancy'
  },
  {
    Id: 4593,
    Code: '2421',
    Name: 'Associate, audit, qualified'
  },
  {
    Id: 4594,
    Code: '2421',
    Name: 'Auditor, chief, coal mine'
  },
  {
    Id: 4595,
    Code: '2421',
    Name: 'Auditor, compliance, qualified'
  },
  {
    Id: 4596,
    Code: '2421',
    Name: 'Auditor, internal, qualified'
  },
  {
    Id: 4597,
    Code: '2421',
    Name: 'Auditor, qualified'
  },
  {
    Id: 4598,
    Code: '2421',
    Name: 'Auditor, government'
  },
  {
    Id: 4599,
    Code: '2421',
    Name: 'Auditor, local government'
  },
  {
    Id: 4600,
    Code: '2421',
    Name: 'Auditor and accountant, qualified'
  },
  {
    Id: 4601,
    Code: '2421',
    Name: 'Clerk, articled, accountancy'
  },
  {
    Id: 4602,
    Code: '2421',
    Name: 'Clerk, audit, qualified'
  },
  {
    Id: 4603,
    Code: '2421',
    Name: 'Consultant, accountancy'
  },
  {
    Id: 4604,
    Code: '2421',
    Name: 'Consultant, accounting'
  },
  {
    Id: 4605,
    Code: '2421',
    Name: 'Consultant, audit'
  },
  {
    Id: 4606,
    Code: '2421',
    Name: 'Consultant, cost, qualified'
  },
  {
    Id: 4607,
    Code: '2421',
    Name: 'Controller, financial, group, qualified accountant'
  },
  {
    Id: 4608,
    Code: '2421',
    Name: 'Controller, financial, qualified accountant'
  },
  {
    Id: 4609,
    Code: '2421',
    Name: 'Controller, product, qualified, financial services'
  },
  {
    Id: 4610,
    Code: '2421',
    Name: 'Controller, unit, business'
  },
  {
    Id: 4611,
    Code: '2421',
    Name: 'Examiner, audit, DfT'
  },
  {
    Id: 4612,
    Code: '2421',
    Name: 'Examiner, bankruptcy'
  },
  {
    Id: 4613,
    Code: '2421',
    Name: 'Examiner, insolvency'
  },
  {
    Id: 4614,
    Code: '2421',
    Name: 'Examiner, insolvency'
  },
  {
    Id: 4615,
    Code: '2421',
    Name: 'Executive, audit'
  },
  {
    Id: 4616,
    Code: '2421',
    Name: 'Executive, finance, corporate, qualified accountant'
  },
  {
    Id: 4617,
    Code: '2421',
    Name: 'FCA'
  },
  {
    Id: 4618,
    Code: '2421',
    Name: 'FSAA'
  },
  {
    Id: 4619,
    Code: '2421',
    Name: 'Inspector of audits, DfT'
  },
  {
    Id: 4620,
    Code: '2421',
    Name: 'Liquidator, company'
  },
  {
    Id: 4621,
    Code: '2421',
    Name: 'Manager, audit, internal'
  },
  {
    Id: 4622,
    Code: '2421',
    Name: 'Manager, insolvency'
  },
  {
    Id: 4623,
    Code: '2421',
    Name: 'Officer, development, cost, coal mine'
  },
  {
    Id: 4624,
    Code: '2421',
    Name: 'Officer, expenditure, coal mine'
  },
  {
    Id: 4625,
    Code: '2421',
    Name: 'Partner, business, finance, qualified accountant'
  },
  {
    Id: 4626,
    Code: '2421',
    Name: 'Partner, finance, business, qualified accountant'
  },
  {
    Id: 4627,
    Code: '2421',
    Name: 'Partner, finance, qualified accountant'
  },
  {
    Id: 4628,
    Code: '2421',
    Name: 'Practitioner, insolvency'
  },
  {
    Id: 4629,
    Code: '2421',
    Name: 'Receiver, official'
  },
  {
    Id: 4630,
    Code: '2421',
    Name: 'Receiver, Board of Trade'
  },
  {
    Id: 4631,
    Code: '2422',
    Name: 'Adviser, finance'
  },
  {
    Id: 4632,
    Code: '2422',
    Name: 'Adviser, financial, independent'
  },
  {
    Id: 4633,
    Code: '2422',
    Name: 'Adviser, financial'
  },
  {
    Id: 4634,
    Code: '2422',
    Name: 'Adviser, investment'
  },
  {
    Id: 4635,
    Code: '2422',
    Name: 'Adviser, loans, financial'
  },
  {
    Id: 4636,
    Code: '2422',
    Name: 'Adviser, management, wealth'
  },
  {
    Id: 4637,
    Code: '2422',
    Name: 'Adviser, mortgage'
  },
  {
    Id: 4638,
    Code: '2422',
    Name: 'Adviser, mortgage and protection'
  },
  {
    Id: 4639,
    Code: '2422',
    Name: 'Adviser, pension'
  },
  {
    Id: 4640,
    Code: '2422',
    Name: 'Adviser, pensions'
  },
  {
    Id: 4641,
    Code: '2422',
    Name: 'Adviser, planning, financial'
  },
  {
    Id: 4642,
    Code: '2422',
    Name: 'Adviser, service, financial'
  },
  {
    Id: 4643,
    Code: '2422',
    Name: 'Adviser, services, financial'
  },
  {
    Id: 4644,
    Code: '2422',
    Name: 'Adviser, technical, insurance'
  },
  {
    Id: 4645,
    Code: '2422',
    Name: 'Agent, finance'
  },
  {
    Id: 4646,
    Code: '2422',
    Name: 'Agent, financial'
  },
  {
    Id: 4647,
    Code: '2422',
    Name: 'Agent, money'
  },
  {
    Id: 4648,
    Code: '2422',
    Name: 'Agent, mortgage'
  },
  {
    Id: 4649,
    Code: '2422',
    Name: 'Analyst, accounting'
  },
  {
    Id: 4650,
    Code: '2422',
    Name: 'Analyst, actions, corporate, banking'
  },
  {
    Id: 4651,
    Code: '2422',
    Name: 'Analyst, banking, investment'
  },
  {
    Id: 4652,
    Code: '2422',
    Name: 'Analyst, collateral'
  },
  {
    Id: 4653,
    Code: '2422',
    Name: 'Analyst, control, product'
  },
  {
    Id: 4654,
    Code: '2422',
    Name: 'Analyst, credit'
  },
  {
    Id: 4655,
    Code: '2422',
    Name: 'Analyst, equity'
  },
  {
    Id: 4656,
    Code: '2422',
    Name: 'Analyst, finance'
  },
  {
    Id: 4657,
    Code: '2422',
    Name: 'Analyst, financial'
  },
  {
    Id: 4658,
    Code: '2422',
    Name: 'Analyst, insurance'
  },
  {
    Id: 4659,
    Code: '2422',
    Name: 'Analyst, investment'
  },
  {
    Id: 4660,
    Code: '2422',
    Name: 'Analyst, operations, investment'
  },
  {
    Id: 4661,
    Code: '2422',
    Name: 'Analyst, pricing'
  },
  {
    Id: 4662,
    Code: '2422',
    Name: 'Analyst, research, credit'
  },
  {
    Id: 4663,
    Code: '2422',
    Name: 'Analyst, research, equity'
  },
  {
    Id: 4664,
    Code: '2422',
    Name: 'Analyst, risk, credit'
  },
  {
    Id: 4665,
    Code: '2422',
    Name: 'Analyst, systems, finance'
  },
  {
    Id: 4666,
    Code: '2422',
    Name: 'Analyst, treasury'
  },
  {
    Id: 4667,
    Code: '2422',
    Name: 'Analyst, banking'
  },
  {
    Id: 4668,
    Code: '2422',
    Name: 'Analyst, financial services'
  },
  {
    Id: 4669,
    Code: '2422',
    Name: 'Arranger, mortgage'
  },
  {
    Id: 4670,
    Code: '2422',
    Name: 'Assistant, investment'
  },
  {
    Id: 4671,
    Code: '2422',
    Name: 'Associate, bank'
  },
  {
    Id: 4672,
    Code: '2422',
    Name: 'Associate, banking, investment'
  },
  {
    Id: 4673,
    Code: '2422',
    Name: 'Associate, banking'
  },
  {
    Id: 4674,
    Code: '2422',
    Name: 'Associate, finance'
  },
  {
    Id: 4675,
    Code: '2422',
    Name: 'Associate, insurance'
  },
  {
    Id: 4676,
    Code: '2422',
    Name: 'Associate, sales, insurance'
  },
  {
    Id: 4677,
    Code: '2422',
    Name: 'Associate, banking'
  },
  {
    Id: 4678,
    Code: '2422',
    Name: 'Associate, financial services'
  },
  {
    Id: 4679,
    Code: '2422',
    Name: 'Capitalist, venture'
  },
  {
    Id: 4680,
    Code: '2422',
    Name: 'Consultant, banking'
  },
  {
    Id: 4681,
    Code: '2422',
    Name: 'Consultant, benefit, employee'
  },
  {
    Id: 4682,
    Code: '2422',
    Name: 'Consultant, care, health, insurance'
  },
  {
    Id: 4683,
    Code: '2422',
    Name: 'Consultant, finance'
  },
  {
    Id: 4684,
    Code: '2422',
    Name: 'Consultant, financial'
  },
  {
    Id: 4685,
    Code: '2422',
    Name: 'Consultant, insurance'
  },
  {
    Id: 4686,
    Code: '2422',
    Name: 'Consultant, investment'
  },
  {
    Id: 4687,
    Code: '2422',
    Name: 'Consultant, management, wealth'
  },
  {
    Id: 4688,
    Code: '2422',
    Name: 'Consultant, mortgage'
  },
  {
    Id: 4689,
    Code: '2422',
    Name: 'Consultant, mortgage and protection'
  },
  {
    Id: 4690,
    Code: '2422',
    Name: 'Consultant, pension'
  },
  {
    Id: 4691,
    Code: '2422',
    Name: 'Consultant, pensions'
  },
  {
    Id: 4692,
    Code: '2422',
    Name: 'Consultant, planning, financial'
  },
  {
    Id: 4693,
    Code: '2422',
    Name: 'Consultant, services, financial'
  },
  {
    Id: 4694,
    Code: '2422',
    Name: 'Consultant, technical, pensions'
  },
  {
    Id: 4695,
    Code: '2422',
    Name: 'Consultant, technical, insurance'
  },
  {
    Id: 4696,
    Code: '2422',
    Name: 'Consultant, financial services'
  },
  {
    Id: 4697,
    Code: '2422',
    Name: 'Counsellor, investment'
  },
  {
    Id: 4698,
    Code: '2422',
    Name: 'Executive, account, insurance'
  },
  {
    Id: 4699,
    Code: '2422',
    Name: 'Executive, client, insurance'
  },
  {
    Id: 4700,
    Code: '2422',
    Name: 'Financier'
  },
  {
    Id: 4701,
    Code: '2422',
    Name: 'IFA'
  },
  {
    Id: 4702,
    Code: '2422',
    Name: 'Inspector, pensions'
  },
  {
    Id: 4703,
    Code: '2422',
    Name: 'Interviewer, banking'
  },
  {
    Id: 4704,
    Code: '2422',
    Name: 'Interviewer, building society'
  },
  {
    Id: 4705,
    Code: '2422',
    Name: 'Investor, private'
  },
  {
    Id: 4706,
    Code: '2422',
    Name: 'Manager, pension'
  },
  {
    Id: 4707,
    Code: '2422',
    Name: 'Manager, pensions'
  },
  {
    Id: 4708,
    Code: '2422',
    Name: 'Manager, wealth'
  },
  {
    Id: 4709,
    Code: '2422',
    Name: 'Officer, investment, banking'
  },
  {
    Id: 4710,
    Code: '2422',
    Name: 'Officer, investment, finance'
  },
  {
    Id: 4711,
    Code: '2422',
    Name: 'Officer, planning, financial'
  },
  {
    Id: 4712,
    Code: '2422',
    Name: 'Officer, schemes, insurance'
  },
  {
    Id: 4713,
    Code: '2422',
    Name: 'Paraplanner'
  },
  {
    Id: 4714,
    Code: '2422',
    Name: 'Partner, business, finance'
  },
  {
    Id: 4715,
    Code: '2422',
    Name: 'Partner, finance, business'
  },
  {
    Id: 4716,
    Code: '2422',
    Name: 'Partner, finance'
  },
  {
    Id: 4717,
    Code: '2422',
    Name: 'Planner, financial, chartered'
  },
  {
    Id: 4718,
    Code: '2422',
    Name: 'Planner, financial'
  },
  {
    Id: 4719,
    Code: '2422',
    Name: 'Specialist, mortgage'
  },
  {
    Id: 4720,
    Code: '2422',
    Name: 'Structurer, financial services'
  },
  {
    Id: 4721,
    Code: '2423',
    Name: 'Accountant, tax'
  },
  {
    Id: 4722,
    Code: '2423',
    Name: 'Accountant, taxation'
  },
  {
    Id: 4723,
    Code: '2423',
    Name: 'Adviser, tax'
  },
  {
    Id: 4724,
    Code: '2423',
    Name: 'Adviser, taxation'
  },
  {
    Id: 4725,
    Code: '2423',
    Name: 'Adviser, VAT'
  },
  {
    Id: 4726,
    Code: '2423',
    Name: 'Analyst, tax'
  },
  {
    Id: 4727,
    Code: '2423',
    Name: 'Consultant, tax'
  },
  {
    Id: 4728,
    Code: '2423',
    Name: 'Consultant, taxation'
  },
  {
    Id: 4729,
    Code: '2423',
    Name: 'Consultant, VAT'
  },
  {
    Id: 4730,
    Code: '2423',
    Name: 'Director, tax'
  },
  {
    Id: 4731,
    Code: '2423',
    Name: 'Inspector, district, HM Revenue and Customs'
  },
  {
    Id: 4732,
    Code: '2423',
    Name: 'Inspector, tax'
  },
  {
    Id: 4733,
    Code: '2423',
    Name: 'Inspector, VAT, HM Revenue and Customs'
  },
  {
    Id: 4734,
    Code: '2423',
    Name: 'Inspector, government: HM Revenue and Customs'
  },
  {
    Id: 4735,
    Code: '2423',
    Name: 'Inspector of taxes'
  },
  {
    Id: 4736,
    Code: '2423',
    Name: 'Investigator, HM Revenue and Customs'
  },
  {
    Id: 4737,
    Code: '2423',
    Name: 'Manager, tax'
  },
  {
    Id: 4738,
    Code: '2423',
    Name: 'Manager, taxation'
  },
  {
    Id: 4739,
    Code: '2423',
    Name: 'Manager, VAT'
  },
  {
    Id: 4740,
    Code: '2423',
    Name: 'Officer, compliance, HM Revenue and Customs'
  },
  {
    Id: 4741,
    Code: '2423',
    Name: 'Partner, tax'
  },
  {
    Id: 4742,
    Code: '2423',
    Name: 'Practitioner, tax'
  },
  {
    Id: 4743,
    Code: '2423',
    Name: 'Practitioner, taxation'
  },
  {
    Id: 4744,
    Code: '2423',
    Name: 'Senior, tax'
  },
  {
    Id: 4745,
    Code: '2423',
    Name: 'Senior, taxation'
  },
  {
    Id: 4746,
    Code: '2423',
    Name: 'Specialist, tax'
  },
  {
    Id: 4747,
    Code: '2423',
    Name: 'Specialist, taxation'
  },
  {
    Id: 4748,
    Code: '2423',
    Name: 'Supervisor, tax'
  },
  {
    Id: 4749,
    Code: '2423',
    Name: 'Supervisor, taxation'
  },
  {
    Id: 4750,
    Code: '2423',
    Name: 'Technician, tax'
  },
  {
    Id: 4751,
    Code: '2423',
    Name: 'Technician, taxation'
  },
  {
    Id: 4752,
    Code: '2431',
    Name: 'Adviser, business'
  },
  {
    Id: 4753,
    Code: '2431',
    Name: 'Adviser, development, management'
  },
  {
    Id: 4754,
    Code: '2431',
    Name: 'Adviser, management'
  },
  {
    Id: 4755,
    Code: '2431',
    Name: 'Adviser, services, management'
  },
  {
    Id: 4756,
    Code: '2431',
    Name: 'Analyst, business, digital'
  },
  {
    Id: 4757,
    Code: '2431',
    Name: 'Analyst, business'
  },
  {
    Id: 4758,
    Code: '2431',
    Name: 'Analyst, catastrophe'
  },
  {
    Id: 4759,
    Code: '2431',
    Name: 'Analyst, change, business'
  },
  {
    Id: 4760,
    Code: '2431',
    Name: 'Analyst, commercial'
  },
  {
    Id: 4761,
    Code: '2431',
    Name: 'Analyst, energy'
  },
  {
    Id: 4762,
    Code: '2431',
    Name: 'Analyst, intelligence, business'
  },
  {
    Id: 4763,
    Code: '2431',
    Name: 'Analyst, process, business'
  },
  {
    Id: 4764,
    Code: '2431',
    Name: 'Analyst, reporting, business'
  },
  {
    Id: 4765,
    Code: '2431',
    Name: 'Analyst, reporting'
  },
  {
    Id: 4766,
    Code: '2431',
    Name: 'Analyst, risk, financial'
  },
  {
    Id: 4767,
    Code: '2431',
    Name: 'Analyst, risk'
  },
  {
    Id: 4768,
    Code: '2431',
    Name: 'Analyst, trading'
  },
  {
    Id: 4769,
    Code: '2431',
    Name: 'Architect, business'
  },
  {
    Id: 4770,
    Code: '2431',
    Name: 'Coach, business'
  },
  {
    Id: 4771,
    Code: '2431',
    Name: 'Coach, quality'
  },
  {
    Id: 4772,
    Code: '2431',
    Name: 'Consultant, business'
  },
  {
    Id: 4773,
    Code: '2431',
    Name: 'Consultant, change, business'
  },
  {
    Id: 4774,
    Code: '2431',
    Name: 'Consultant, development, organisational'
  },
  {
    Id: 4775,
    Code: '2431',
    Name: 'Consultant, factors, human'
  },
  {
    Id: 4776,
    Code: '2431',
    Name: 'Consultant, intelligence, business'
  },
  {
    Id: 4777,
    Code: '2431',
    Name: 'Consultant, management, project'
  },
  {
    Id: 4778,
    Code: '2431',
    Name: 'Consultant, management'
  },
  {
    Id: 4779,
    Code: '2431',
    Name: 'Consultant, managing'
  },
  {
    Id: 4780,
    Code: '2431',
    Name: 'Consultant, organisational'
  },
  {
    Id: 4781,
    Code: '2431',
    Name: 'Consultant, process, business'
  },
  {
    Id: 4782,
    Code: '2431',
    Name: 'Consultant, risk'
  },
  {
    Id: 4783,
    Code: '2431',
    Name: 'Consultant, services, management'
  },
  {
    Id: 4784,
    Code: '2431',
    Name: 'Consultant, strategy'
  },
  {
    Id: 4785,
    Code: '2431',
    Name: 'Consultant, management consultancy'
  },
  {
    Id: 4786,
    Code: '2431',
    Name: 'Controller, services, management'
  },
  {
    Id: 4787,
    Code: '2431',
    Name: 'Coordinator, trade, ethical'
  },
  {
    Id: 4788,
    Code: '2431',
    Name: 'Coordinator, trading, ethical'
  },
  {
    Id: 4789,
    Code: '2431',
    Name: 'Developer, quant'
  },
  {
    Id: 4790,
    Code: '2431',
    Name: 'Developer, quantitative'
  },
  {
    Id: 4791,
    Code: '2431',
    Name: 'Executive, intelligence, business'
  },
  {
    Id: 4792,
    Code: '2431',
    Name: 'Facilitator, effectiveness, clinical'
  },
  {
    Id: 4793,
    Code: '2431',
    Name: 'Facilitator, governance, clinical'
  },
  {
    Id: 4794,
    Code: '2431',
    Name: 'Head of business management'
  },
  {
    Id: 4795,
    Code: '2431',
    Name: 'Leader, team, consultancy, management'
  },
  {
    Id: 4796,
    Code: '2431',
    Name: 'Leader, team, management consultancy'
  },
  {
    Id: 4797,
    Code: '2431',
    Name: 'Manager, continuity, business'
  },
  {
    Id: 4798,
    Code: '2431',
    Name: 'Manager, improvement, business'
  },
  {
    Id: 4799,
    Code: '2431',
    Name: 'Manager, improvement, continuous'
  },
  {
    Id: 4800,
    Code: '2431',
    Name: 'Manager, intelligence, business'
  },
  {
    Id: 4801,
    Code: '2431',
    Name: 'Modeller, financial'
  },
  {
    Id: 4802,
    Code: '2431',
    Name: 'Modeller, financial services'
  },
  {
    Id: 4803,
    Code: '2431',
    Name: 'Officer, intelligence, trade'
  },
  {
    Id: 4804,
    Code: '2431',
    Name: 'Officer, services, management'
  },
  {
    Id: 4805,
    Code: '2431',
    Name: 'Surveyor, risk, insurance'
  },
  {
    Id: 4806,
    Code: '2432',
    Name: 'Manager, account, marketing'
  },
  {
    Id: 4807,
    Code: '2432',
    Name: 'Manager, campaign, marketing'
  },
  {
    Id: 4808,
    Code: '2432',
    Name: 'Manager, campaign, marketing'
  },
  {
    Id: 4809,
    Code: '2432',
    Name: 'Manager, campaigns, marketing'
  },
  {
    Id: 4810,
    Code: '2432',
    Name: 'Manager, campaigns, marketing'
  },
  {
    Id: 4811,
    Code: '2432',
    Name: 'Manager, category, marketing'
  },
  {
    Id: 4812,
    Code: '2432',
    Name: 'Manager, client, marketing'
  },
  {
    Id: 4813,
    Code: '2432',
    Name: 'Manager, commercial'
  },
  {
    Id: 4814,
    Code: '2432',
    Name: 'Manager, communications, marketing'
  },
  {
    Id: 4815,
    Code: '2432',
    Name: 'Manager, communications, marketing'
  },
  {
    Id: 4816,
    Code: '2432',
    Name: 'Manager, community, marketing'
  },
  {
    Id: 4817,
    Code: '2432',
    Name: 'Manager, contract, marketing'
  },
  {
    Id: 4818,
    Code: '2432',
    Name: 'Manager, development, marketing'
  },
  {
    Id: 4819,
    Code: '2432',
    Name: 'Manager, market, marketing'
  },
  {
    Id: 4820,
    Code: '2432',
    Name: 'Manager, marketing, brand'
  },
  {
    Id: 4821,
    Code: '2432',
    Name: 'Manager, marketing, content'
  },
  {
    Id: 4822,
    Code: '2432',
    Name: 'Manager, marketing, digital'
  },
  {
    Id: 4823,
    Code: '2432',
    Name: 'Manager, marketing, online'
  },
  {
    Id: 4824,
    Code: '2432',
    Name: 'Manager, marketing, product'
  },
  {
    Id: 4825,
    Code: '2432',
    Name: 'Manager, marketing'
  },
  {
    Id: 4826,
    Code: '2432',
    Name: 'Manager, marketing and communications'
  },
  {
    Id: 4827,
    Code: '2432',
    Name: 'Manager, operations, marketing'
  },
  {
    Id: 4828,
    Code: '2432',
    Name: 'Manager, operations, marketing'
  },
  {
    Id: 4829,
    Code: '2432',
    Name: 'Manager, pay-per-click, marketing'
  },
  {
    Id: 4830,
    Code: '2432',
    Name: 'Manager, PPC, marketing'
  },
  {
    Id: 4831,
    Code: '2432',
    Name: 'Manager, production, marketing'
  },
  {
    Id: 4832,
    Code: '2432',
    Name: 'Manager, promotions, marketing'
  },
  {
    Id: 4833,
    Code: '2432',
    Name: 'Manager, service, client, marketing'
  },
  {
    Id: 4834,
    Code: '2432',
    Name: 'Manager, services, client, marketing'
  },
  {
    Id: 4835,
    Code: '2432',
    Name: 'Manager, services, marketing'
  },
  {
    Id: 4836,
    Code: '2432',
    Name: 'Manager, support, marketing'
  },
  {
    Id: 4837,
    Code: '2432',
    Name: 'Manager, marketing'
  },
  {
    Id: 4838,
    Code: '2433',
    Name: 'Actuary'
  },
  {
    Id: 4839,
    Code: '2433',
    Name: 'Adviser, economic'
  },
  {
    Id: 4840,
    Code: '2433',
    Name: 'Adviser, statistical'
  },
  {
    Id: 4841,
    Code: '2433',
    Name: 'Analyst, actuarial'
  },
  {
    Id: 4842,
    Code: '2433',
    Name: 'Analyst, campaign'
  },
  {
    Id: 4843,
    Code: '2433',
    Name: 'Analyst, digital'
  },
  {
    Id: 4844,
    Code: '2433',
    Name: 'Analyst, economic'
  },
  {
    Id: 4845,
    Code: '2433',
    Name: 'Analyst, political'
  },
  {
    Id: 4846,
    Code: '2433',
    Name: 'Analyst, quantitative'
  },
  {
    Id: 4847,
    Code: '2433',
    Name: 'Analyst, statistical'
  },
  {
    Id: 4848,
    Code: '2433',
    Name: 'Analyst, web'
  },
  {
    Id: 4849,
    Code: '2433',
    Name: 'Assistant, actuarial'
  },
  {
    Id: 4850,
    Code: '2433',
    Name: 'Assistant, economic'
  },
  {
    Id: 4851,
    Code: '2433',
    Name: 'Assistant, statistical'
  },
  {
    Id: 4852,
    Code: '2433',
    Name: 'Associate, research, economic'
  },
  {
    Id: 4853,
    Code: '2433',
    Name: 'Bioinformatician'
  },
  {
    Id: 4854,
    Code: '2433',
    Name: 'Biostatistician'
  },
  {
    Id: 4855,
    Code: '2433',
    Name: 'Consultant, actuarial'
  },
  {
    Id: 4856,
    Code: '2433',
    Name: 'Consultant, economic'
  },
  {
    Id: 4857,
    Code: '2433',
    Name: 'Consultant, statistical'
  },
  {
    Id: 4858,
    Code: '2433',
    Name: 'Controller, economics'
  },
  {
    Id: 4859,
    Code: '2433',
    Name: 'Controller, statistical'
  },
  {
    Id: 4860,
    Code: '2433',
    Name: 'Demographer'
  },
  {
    Id: 4861,
    Code: '2433',
    Name: 'Economist'
  },
  {
    Id: 4862,
    Code: '2433',
    Name: 'Engineer, financial'
  },
  {
    Id: 4863,
    Code: '2433',
    Name: 'Forecaster, economic'
  },
  {
    Id: 4864,
    Code: '2433',
    Name: 'Head of statistics'
  },
  {
    Id: 4865,
    Code: '2433',
    Name: 'Informatician'
  },
  {
    Id: 4866,
    Code: '2433',
    Name: 'Manager, analytics, data'
  },
  {
    Id: 4867,
    Code: '2433',
    Name: 'Manager, analytics, digital'
  },
  {
    Id: 4868,
    Code: '2433',
    Name: 'Manager, analytics, web'
  },
  {
    Id: 4869,
    Code: '2433',
    Name: 'Mathematician'
  },
  {
    Id: 4870,
    Code: '2433',
    Name: 'Modeller, statistical'
  },
  {
    Id: 4871,
    Code: '2433',
    Name: 'Officer, development, economic'
  },
  {
    Id: 4872,
    Code: '2433',
    Name: 'Officer, statistical, coal mine'
  },
  {
    Id: 4873,
    Code: '2433',
    Name: 'Officer, statistical, government'
  },
  {
    Id: 4874,
    Code: '2433',
    Name: 'Officer, support, bioinformatics'
  },
  {
    Id: 4875,
    Code: '2433',
    Name: 'Planner, economic'
  },
  {
    Id: 4876,
    Code: '2433',
    Name: 'Scientist, data'
  },
  {
    Id: 4877,
    Code: '2433',
    Name: 'Statistician'
  },
  {
    Id: 4878,
    Code: '2433',
    Name: 'Strategist, quantitative'
  },
  {
    Id: 4879,
    Code: '2433',
    Name: 'Worker, research, economic'
  },
  {
    Id: 4880,
    Code: '2434',
    Name: 'Analyst, crime, financial'
  },
  {
    Id: 4881,
    Code: '2434',
    Name: 'Analyst, crime'
  },
  {
    Id: 4882,
    Code: '2434',
    Name: 'Analyst, forensic, digital'
  },
  {
    Id: 4883,
    Code: '2434',
    Name: 'Analyst, forensic'
  },
  {
    Id: 4884,
    Code: '2434',
    Name: 'Analyst, information'
  },
  {
    Id: 4885,
    Code: '2434',
    Name: 'Analyst, intelligence, criminal'
  },
  {
    Id: 4886,
    Code: '2434',
    Name: 'Analyst, intelligence'
  },
  {
    Id: 4887,
    Code: '2434',
    Name: 'Analyst, marketing, digital'
  },
  {
    Id: 4888,
    Code: '2434',
    Name: 'Analyst, marketing'
  },
  {
    Id: 4889,
    Code: '2434',
    Name: 'Analyst, media, social'
  },
  {
    Id: 4890,
    Code: '2434',
    Name: 'Analyst, media'
  },
  {
    Id: 4891,
    Code: '2434',
    Name: 'Analyst, research, market'
  },
  {
    Id: 4892,
    Code: '2434',
    Name: 'Analyst, sports'
  },
  {
    Id: 4893,
    Code: '2434',
    Name: 'Analyst, market research'
  },
  {
    Id: 4894,
    Code: '2434',
    Name: 'Assistant, research, broadcasting'
  },
  {
    Id: 4895,
    Code: '2434',
    Name: 'Assistant, research, journalism'
  },
  {
    Id: 4896,
    Code: '2434',
    Name: 'Assistant, research, printing and publishing'
  },
  {
    Id: 4897,
    Code: '2434',
    Name: 'Assistant, research'
  },
  {
    Id: 4898,
    Code: '2434',
    Name: 'Associate, research, broadcasting'
  },
  {
    Id: 4899,
    Code: '2434',
    Name: 'Associate, research, journalism'
  },
  {
    Id: 4900,
    Code: '2434',
    Name: 'Associate, research, printing and publishing'
  },
  {
    Id: 4901,
    Code: '2434',
    Name: 'Associate, research'
  },
  {
    Id: 4902,
    Code: '2434',
    Name: 'Consultant, research, market'
  },
  {
    Id: 4903,
    Code: '2434',
    Name: 'Consultant, research'
  },
  {
    Id: 4904,
    Code: '2434',
    Name: 'Examiner, forensic, digital'
  },
  {
    Id: 4905,
    Code: '2434',
    Name: 'Executive, client'
  },
  {
    Id: 4906,
    Code: '2434',
    Name: 'Executive, research, market'
  },
  {
    Id: 4907,
    Code: '2434',
    Name: 'Executive, research, market research'
  },
  {
    Id: 4908,
    Code: '2434',
    Name: 'Fellow, research'
  },
  {
    Id: 4909,
    Code: '2434',
    Name: 'Officer, intelligence, grade II, MOD'
  },
  {
    Id: 4910,
    Code: '2434',
    Name: 'Officer, intelligence, government'
  },
  {
    Id: 4911,
    Code: '2434',
    Name: 'Officer, intelligence, police service'
  },
  {
    Id: 4912,
    Code: '2434',
    Name: 'Officer, research, broadcasting'
  },
  {
    Id: 4913,
    Code: '2434',
    Name: 'Officer, research, journalism'
  },
  {
    Id: 4914,
    Code: '2434',
    Name: 'Officer, research, printing and publishing'
  },
  {
    Id: 4915,
    Code: '2434',
    Name: 'Officer, research'
  },
  {
    Id: 4916,
    Code: '2434',
    Name: 'Officer, support, intelligence, police service'
  },
  {
    Id: 4917,
    Code: '2434',
    Name: 'Practitioner, forensic, police service'
  },
  {
    Id: 4918,
    Code: '2434',
    Name: 'Researcher, experience, user'
  },
  {
    Id: 4919,
    Code: '2434',
    Name: 'Researcher, games, entertainment'
  },
  {
    Id: 4920,
    Code: '2434',
    Name: 'Researcher, games, broadcasting'
  },
  {
    Id: 4921,
    Code: '2434',
    Name: 'Researcher, intelligence, police service'
  },
  {
    Id: 4922,
    Code: '2434',
    Name: 'Researcher, picture'
  },
  {
    Id: 4923,
    Code: '2434',
    Name: 'Researcher, user'
  },
  {
    Id: 4924,
    Code: '2434',
    Name: 'Researcher, UX'
  },
  {
    Id: 4925,
    Code: '2434',
    Name: 'Researcher, broadcasting'
  },
  {
    Id: 4926,
    Code: '2434',
    Name: 'Researcher, journalism'
  },
  {
    Id: 4927,
    Code: '2434',
    Name: 'Researcher, printing and publishing'
  },
  {
    Id: 4928,
    Code: '2434',
    Name: 'Researcher'
  },
  {
    Id: 4929,
    Code: '2434',
    Name: 'Technician, R&D'
  },
  {
    Id: 4930,
    Code: '2434',
    Name: 'Technician, research'
  },
  {
    Id: 4931,
    Code: '2434',
    Name: 'Technician, research and development'
  },
  {
    Id: 4932,
    Code: '2434',
    Name: 'Worker, research, fire protection'
  },
  {
    Id: 4933,
    Code: '2434',
    Name: 'Worker, research, fuel'
  },
  {
    Id: 4934,
    Code: '2434',
    Name: 'Worker, research, photographic'
  },
  {
    Id: 4935,
    Code: '2434',
    Name: 'Worker, research, plastics'
  },
  {
    Id: 4936,
    Code: '2434',
    Name: 'Worker, research, textile'
  },
  {
    Id: 4937,
    Code: '2434',
    Name: 'Worker, research'
  },
  {
    Id: 4938,
    Code: '2435',
    Name: 'ACG'
  },
  {
    Id: 4939,
    Code: '2435',
    Name: 'ACIS'
  },
  {
    Id: 4940,
    Code: '2435',
    Name: 'Assistant, secretarial, company, qualified'
  },
  {
    Id: 4941,
    Code: '2435',
    Name: 'FCG'
  },
  {
    Id: 4942,
    Code: '2435',
    Name: 'FCIS'
  },
  {
    Id: 4943,
    Code: '2435',
    Name: 'Secretary, chartered'
  },
  {
    Id: 4944,
    Code: '2435',
    Name: 'Secretary, company, assistant, qualified'
  },
  {
    Id: 4945,
    Code: '2435',
    Name: 'Secretary, company, qualified'
  },
  {
    Id: 4946,
    Code: '2439',
    Name: 'A, Cabinet Office'
  },
  {
    Id: 4947,
    Code: '2439',
    Name: 'A, Northern Ireland Office'
  },
  {
    Id: 4948,
    Code: '2439',
    Name: 'A1, Dept for International Development'
  },
  {
    Id: 4949,
    Code: '2439',
    Name: 'A2, Dept for International Development'
  },
  {
    Id: 4950,
    Code: '2439',
    Name: 'Administrator, arts'
  },
  {
    Id: 4951,
    Code: '2439',
    Name: 'Administrator, registration, senior, HM Land Registry'
  },
  {
    Id: 4952,
    Code: '2439',
    Name: 'Adviser, conflict'
  },
  {
    Id: 4953,
    Code: '2439',
    Name: 'Adviser, policy, government'
  },
  {
    Id: 4954,
    Code: '2439',
    Name: 'Adviser, policy, local government'
  },
  {
    Id: 4955,
    Code: '2439',
    Name: 'Adviser, political'
  },
  {
    Id: 4956,
    Code: '2439',
    Name: 'Advocate, policy'
  },
  {
    Id: 4957,
    Code: '2439',
    Name: 'Agent, political'
  },
  {
    Id: 4958,
    Code: '2439',
    Name: 'Assistant, parliamentary'
  },
  {
    Id: 4959,
    Code: '2439',
    Name: 'Assistant, political'
  },
  {
    Id: 4960,
    Code: '2439',
    Name: "Assistant, politician's"
  },
  {
    Id: 4961,
    Code: '2439',
    Name: 'Band 1, Health and Safety Executive'
  },
  {
    Id: 4962,
    Code: '2439',
    Name: 'Band 2, Health and Safety Executive'
  },
  {
    Id: 4963,
    Code: '2439',
    Name: 'Band 2C, Meteorological Office'
  },
  {
    Id: 4964,
    Code: '2439',
    Name: 'Band F, Ministry of Justice'
  },
  {
    Id: 4965,
    Code: '2439',
    Name: 'Band F, National Assembly for Wales'
  },
  {
    Id: 4966,
    Code: '2439',
    Name: 'Band G, Ministry of Justice'
  },
  {
    Id: 4967,
    Code: '2439',
    Name: 'Band G, National Assembly for Wales'
  },
  {
    Id: 4968,
    Code: '2439',
    Name: 'C1, Scottish Government'
  },
  {
    Id: 4969,
    Code: '2439',
    Name: 'C2, Scottish Government'
  },
  {
    Id: 4970,
    Code: '2439',
    Name: 'C3, Scottish Government'
  },
  {
    Id: 4971,
    Code: '2439',
    Name: 'Consul'
  },
  {
    Id: 4972,
    Code: '2439',
    Name: 'Consultant, political'
  },
  {
    Id: 4973,
    Code: '2439',
    Name: 'D1, Jobcentre Plus'
  },
  {
    Id: 4974,
    Code: '2439',
    Name: 'D2, Jobcentre Plus'
  },
  {
    Id: 4975,
    Code: '2439',
    Name: 'D2, Office for National Statistics'
  },
  {
    Id: 4976,
    Code: '2439',
    Name: 'D3, Jobcentre Plus'
  },
  {
    Id: 4977,
    Code: '2439',
    Name: 'D3, Office for National Statistics'
  },
  {
    Id: 4978,
    Code: '2439',
    Name: 'D4, Jobcentre Plus'
  },
  {
    Id: 4979,
    Code: '2439',
    Name: 'D4, Office for National Statistics'
  },
  {
    Id: 4980,
    Code: '2439',
    Name: 'Director, regional, government'
  },
  {
    Id: 4981,
    Code: '2439',
    Name: 'Executive, registration, senior, HM Land Registry'
  },
  {
    Id: 4982,
    Code: '2439',
    Name: 'Grade 6, Foreign and Commonwealth Office'
  },
  {
    Id: 4983,
    Code: '2439',
    Name: 'Grade 6, government'
  },
  {
    Id: 4984,
    Code: '2439',
    Name: 'Grade 7, Foreign and Commonwealth Office'
  },
  {
    Id: 4985,
    Code: '2439',
    Name: 'Grade 7, government'
  },
  {
    Id: 4986,
    Code: '2439',
    Name: 'Grade A, DCMS'
  },
  {
    Id: 4987,
    Code: '2439',
    Name: 'Manager, group, accounting, local government'
  },
  {
    Id: 4988,
    Code: '2439',
    Name: 'Manager, information, management consultancy'
  },
  {
    Id: 4989,
    Code: '2439',
    Name: 'Manager, policy'
  },
  {
    Id: 4990,
    Code: '2439',
    Name: 'Manager, protection, public'
  },
  {
    Id: 4991,
    Code: '2439',
    Name: 'Manager, regeneration, local government'
  },
  {
    Id: 4992,
    Code: '2439',
    Name: 'Manager, service, government'
  },
  {
    Id: 4993,
    Code: '2439',
    Name: 'Manager, service, local government'
  },
  {
    Id: 4994,
    Code: '2439',
    Name: 'Officer, area, local government'
  },
  {
    Id: 4995,
    Code: '2439',
    Name: 'Officer, committee, principal, local government'
  },
  {
    Id: 4996,
    Code: '2439',
    Name: 'Officer, committee'
  },
  {
    Id: 4997,
    Code: '2439',
    Name: 'Officer, compliance, information'
  },
  {
    Id: 4998,
    Code: '2439',
    Name: 'Officer, consular'
  },
  {
    Id: 4999,
    Code: '2439',
    Name: 'Officer, divisional, local government'
  },
  {
    Id: 5000,
    Code: '2439',
    Name: 'Officer, executive, chief, government'
  },
  {
    Id: 5001,
    Code: '2439',
    Name: 'Officer, executive, chief, PO'
  },
  {
    Id: 5002,
    Code: '2439',
    Name: 'Officer, governance'
  },
  {
    Id: 5003,
    Code: '2439',
    Name: 'Officer, group, chief, MOD'
  },
  {
    Id: 5004,
    Code: '2439',
    Name: 'Officer, heritage and culture, principal, local government'
  },
  {
    Id: 5005,
    Code: '2439',
    Name: 'Officer, policy'
  },
  {
    Id: 5006,
    Code: '2439',
    Name: 'Officer, principal, government'
  },
  {
    Id: 5007,
    Code: '2439',
    Name: 'Officer, regeneration, senior, local government'
  },
  {
    Id: 5008,
    Code: '2439',
    Name: 'Officer, registration, local government'
  },
  {
    Id: 5009,
    Code: '2439',
    Name: 'Payband 4, Dept of Health & Social Care'
  },
  {
    Id: 5010,
    Code: '2439',
    Name: 'Principal, government'
  },
  {
    Id: 5011,
    Code: '2439',
    Name: 'RBDM'
  },
  {
    Id: 5012,
    Code: '2439',
    Name: 'Registrar, additional'
  },
  {
    Id: 5013,
    Code: '2439',
    Name: 'Registrar, superintendent'
  },
  {
    Id: 5014,
    Code: '2439',
    Name: 'Registrar, births, deaths, and marriages'
  },
  {
    Id: 5015,
    Code: '2439',
    Name: 'Registrar, government'
  },
  {
    Id: 5016,
    Code: '2439',
    Name: 'Registrar, local government'
  },
  {
    Id: 5017,
    Code: '2439',
    Name: 'Registrar of births, deaths, marriages and civil partnerships'
  },
  {
    Id: 5018,
    Code: '2439',
    Name: 'Registrar of births, deaths and marriages'
  },
  {
    Id: 5019,
    Code: '2439',
    Name: 'Registrar of deeds'
  },
  {
    Id: 5020,
    Code: '2439',
    Name: 'Registrar of marriages'
  },
  {
    Id: 5021,
    Code: '2439',
    Name: 'SRA1, HM Land Registry'
  },
  {
    Id: 5022,
    Code: '2439',
    Name: 'SRA2, HM Land Registry'
  },
  {
    Id: 5023,
    Code: '2439',
    Name: 'SRE, HM Land Registry'
  },
  {
    Id: 5024,
    Code: '2439',
    Name: 'Secretary, assistant, local government'
  },
  {
    Id: 5025,
    Code: '2439',
    Name: 'Secretary, private, assistant, government'
  },
  {
    Id: 5026,
    Code: '2439',
    Name: 'Secretary, private, principal'
  },
  {
    Id: 5027,
    Code: '2439',
    Name: 'Secretary, private, government'
  },
  {
    Id: 5028,
    Code: '2439',
    Name: 'Secretary, third'
  },
  {
    Id: 5029,
    Code: '2439',
    Name: 'Servant, civil, grade 6, 7'
  },
  {
    Id: 5030,
    Code: '2440',
    Name: 'Coach, Agile'
  },
  {
    Id: 5031,
    Code: '2440',
    Name: 'Controller, project'
  },
  {
    Id: 5032,
    Code: '2440',
    Name: 'Coordinator, programme'
  },
  {
    Id: 5033,
    Code: '2440',
    Name: 'Coordinator, project, clinical'
  },
  {
    Id: 5034,
    Code: '2440',
    Name: 'Coordinator, study, clinical'
  },
  {
    Id: 5035,
    Code: '2440',
    Name: 'Coordinator, trial, clinical'
  },
  {
    Id: 5036,
    Code: '2440',
    Name: 'Coordinator, trials, clinical'
  },
  {
    Id: 5037,
    Code: '2440',
    Name: 'Head of delivery, project management'
  },
  {
    Id: 5038,
    Code: '2440',
    Name: 'Head of PMO'
  },
  {
    Id: 5039,
    Code: '2440',
    Name: 'Head of programme management office'
  },
  {
    Id: 5040,
    Code: '2440',
    Name: 'Manager, analysis, risk'
  },
  {
    Id: 5041,
    Code: '2440',
    Name: 'Manager, assurance, risk'
  },
  {
    Id: 5042,
    Code: '2440',
    Name: 'Manager, business, support, project'
  },
  {
    Id: 5043,
    Code: '2440',
    Name: 'Manager, change, business'
  },
  {
    Id: 5044,
    Code: '2440',
    Name: 'Manager, change'
  },
  {
    Id: 5045,
    Code: '2440',
    Name: 'Manager, contract, programme management'
  },
  {
    Id: 5046,
    Code: '2440',
    Name: 'Manager, contract, cleaning services'
  },
  {
    Id: 5047,
    Code: '2440',
    Name: 'Manager, contract'
  },
  {
    Id: 5048,
    Code: '2440',
    Name: 'Manager, contracts, cleaning services'
  },
  {
    Id: 5049,
    Code: '2440',
    Name: 'Manager, contracts, security services'
  },
  {
    Id: 5050,
    Code: '2440',
    Name: 'Manager, contracts'
  },
  {
    Id: 5051,
    Code: '2440',
    Name: 'Manager, delivery, client, project management'
  },
  {
    Id: 5052,
    Code: '2440',
    Name: 'Manager, delivery, project'
  },
  {
    Id: 5053,
    Code: '2440',
    Name: 'Manager, development, investor'
  },
  {
    Id: 5054,
    Code: '2440',
    Name: 'Manager, office, programme'
  },
  {
    Id: 5055,
    Code: '2440',
    Name: 'Manager, office, project'
  },
  {
    Id: 5056,
    Code: '2440',
    Name: 'Manager, package, work, professional'
  },
  {
    Id: 5057,
    Code: '2440',
    Name: 'Manager, PMO'
  },
  {
    Id: 5058,
    Code: '2440',
    Name: 'Manager, portfolio'
  },
  {
    Id: 5059,
    Code: '2440',
    Name: 'Manager, project, HR'
  },
  {
    Id: 5060,
    Code: '2440',
    Name: 'Manager, project, financial services'
  },
  {
    Id: 5061,
    Code: '2440',
    Name: 'Manager, project, residential, local government: social services'
  },
  {
    Id: 5062,
    Code: '2440',
    Name: 'Manager, project, local government: social services'
  },
  {
    Id: 5063,
    Code: '2440',
    Name: 'Manager, project, local government'
  },
  {
    Id: 5064,
    Code: '2440',
    Name: 'Manager, project, publishing'
  },
  {
    Id: 5065,
    Code: '2440',
    Name: 'Manager, project, university'
  },
  {
    Id: 5066,
    Code: '2440',
    Name: 'Manager, project'
  },
  {
    Id: 5067,
    Code: '2440',
    Name: 'Manager, projects'
  },
  {
    Id: 5068,
    Code: '2440',
    Name: 'Manager, risk, credit'
  },
  {
    Id: 5069,
    Code: '2440',
    Name: 'Manager, risk, operational'
  },
  {
    Id: 5070,
    Code: '2440',
    Name: 'Manager, risk'
  },
  {
    Id: 5071,
    Code: '2440',
    Name: 'Manager, risk and assurance'
  },
  {
    Id: 5072,
    Code: '2440',
    Name: 'Manager, risk and control'
  },
  {
    Id: 5073,
    Code: '2440',
    Name: 'Manager, support, project'
  },
  {
    Id: 5074,
    Code: '2440',
    Name: 'Master, scrum'
  },
  {
    Id: 5075,
    Code: '2440',
    Name: 'Officer, delivery, project'
  },
  {
    Id: 5076,
    Code: '2440',
    Name: 'Officer, knowledge, chief'
  },
  {
    Id: 5077,
    Code: '2440',
    Name: 'Officer, programme'
  },
  {
    Id: 5078,
    Code: '2440',
    Name: 'Officer, risk, chief'
  },
  {
    Id: 5079,
    Code: '2440',
    Name: 'Officer, support, research'
  },
  {
    Id: 5080,
    Code: '2451',
    Name: 'Architect, chartered'
  },
  {
    Id: 5081,
    Code: '2451',
    Name: 'Architect, landscape'
  },
  {
    Id: 5082,
    Code: '2451',
    Name: 'Architect, project'
  },
  {
    Id: 5083,
    Code: '2451',
    Name: 'Architect'
  },
  {
    Id: 5084,
    Code: '2451',
    Name: 'Craftsman, landscape'
  },
  {
    Id: 5085,
    Code: '2451',
    Name: 'Designer, concrete, reinforced'
  },
  {
    Id: 5086,
    Code: '2451',
    Name: 'Designer, formwork'
  },
  {
    Id: 5087,
    Code: '2451',
    Name: 'Designer, landscape'
  },
  {
    Id: 5088,
    Code: '2451',
    Name: 'Designer, structural'
  },
  {
    Id: 5089,
    Code: '2452',
    Name: 'Adviser, planning, county'
  },
  {
    Id: 5090,
    Code: '2452',
    Name: 'Associate, architectural'
  },
  {
    Id: 5091,
    Code: '2452',
    Name: 'Consultant, architectural'
  },
  {
    Id: 5092,
    Code: '2452',
    Name: 'Consultant, planning, town'
  },
  {
    Id: 5093,
    Code: '2452',
    Name: 'Consultant, planning'
  },
  {
    Id: 5094,
    Code: '2452',
    Name: 'Designer, architectural'
  },
  {
    Id: 5095,
    Code: '2452',
    Name: 'Designer, building'
  },
  {
    Id: 5096,
    Code: '2452',
    Name: 'Designer, urban'
  },
  {
    Id: 5097,
    Code: '2452',
    Name: 'Designer, architectural service'
  },
  {
    Id: 5098,
    Code: '2452',
    Name: 'Officer, control, development'
  },
  {
    Id: 5099,
    Code: '2452',
    Name: 'Officer, enforcement, planning'
  },
  {
    Id: 5100,
    Code: '2452',
    Name: 'Officer, enforcement, town planning'
  },
  {
    Id: 5101,
    Code: '2452',
    Name: 'Officer, planning, town'
  },
  {
    Id: 5102,
    Code: '2452',
    Name: 'Officer, planning, building construction'
  },
  {
    Id: 5103,
    Code: '2452',
    Name: 'Officer, planning, local government: town planning'
  },
  {
    Id: 5104,
    Code: '2452',
    Name: 'Planner, architectural'
  },
  {
    Id: 5105,
    Code: '2452',
    Name: 'Planner, construction'
  },
  {
    Id: 5106,
    Code: '2452',
    Name: 'Planner, enforcement'
  },
  {
    Id: 5107,
    Code: '2452',
    Name: 'Planner, town'
  },
  {
    Id: 5108,
    Code: '2452',
    Name: 'Planner, town and country'
  },
  {
    Id: 5109,
    Code: '2452',
    Name: 'Planner, town planning'
  },
  {
    Id: 5110,
    Code: '2452',
    Name: 'Technician, planning, town'
  },
  {
    Id: 5111,
    Code: '2452',
    Name: 'Technologist, architectural, Chartered'
  },
  {
    Id: 5112,
    Code: '2452',
    Name: 'Technologist, architectural'
  },
  {
    Id: 5113,
    Code: '2453',
    Name: "Assistant, surveyor's, quantity"
  },
  {
    Id: 5114,
    Code: '2453',
    Name: "Assistant, technical, surveyor's, quantity"
  },
  {
    Id: 5115,
    Code: '2453',
    Name: 'Assistant, technical, quantity surveying'
  },
  {
    Id: 5116,
    Code: '2453',
    Name: 'Surveyor, bonus'
  },
  {
    Id: 5117,
    Code: '2453',
    Name: 'Surveyor, measuring'
  },
  {
    Id: 5118,
    Code: '2453',
    Name: 'Surveyor, quantity, chartered'
  },
  {
    Id: 5119,
    Code: '2453',
    Name: 'Surveyor, quantity, managing'
  },
  {
    Id: 5120,
    Code: '2453',
    Name: 'Surveyor, quantity'
  },
  {
    Id: 5121,
    Code: '2453',
    Name: 'Surveyor, quantity surveying'
  },
  {
    Id: 5122,
    Code: '2453',
    Name: 'Taker-off, quantity surveying'
  },
  {
    Id: 5123,
    Code: '2453',
    Name: 'Technician, survey, quantity surveying'
  },
  {
    Id: 5124,
    Code: '2453',
    Name: 'Technician, surveying, quantity'
  },
  {
    Id: 5125,
    Code: '2453',
    Name: 'Worker-up, quantity surveying'
  },
  {
    Id: 5126,
    Code: '2454',
    Name: "Assistant, professional, local government: surveyor's dept"
  },
  {
    Id: 5127,
    Code: '2454',
    Name: 'Commissioner, land'
  },
  {
    Id: 5128,
    Code: '2454',
    Name: 'Estimator and surveyor'
  },
  {
    Id: 5129,
    Code: '2454',
    Name: 'Hydrographer'
  },
  {
    Id: 5130,
    Code: '2454',
    Name: "Inspector, local government: surveyor's dept"
  },
  {
    Id: 5131,
    Code: '2454',
    Name: 'Officer, hydrographic'
  },
  {
    Id: 5132,
    Code: '2454',
    Name: 'Officer, land and minerals, coal mine'
  },
  {
    Id: 5133,
    Code: '2454',
    Name: 'Officer, survey, government'
  },
  {
    Id: 5134,
    Code: '2454',
    Name: 'Surveyor, agricultural'
  },
  {
    Id: 5135,
    Code: '2454',
    Name: 'Surveyor, architectural'
  },
  {
    Id: 5136,
    Code: '2454',
    Name: 'Surveyor, building, chartered'
  },
  {
    Id: 5137,
    Code: '2454',
    Name: 'Surveyor, building'
  },
  {
    Id: 5138,
    Code: '2454',
    Name: 'Surveyor, cartographic'
  },
  {
    Id: 5139,
    Code: '2454',
    Name: 'Surveyor, chartered'
  },
  {
    Id: 5140,
    Code: '2454',
    Name: 'Surveyor, colliery'
  },
  {
    Id: 5141,
    Code: '2454',
    Name: 'Surveyor, control, building'
  },
  {
    Id: 5142,
    Code: '2454',
    Name: 'Surveyor, estate'
  },
  {
    Id: 5143,
    Code: '2454',
    Name: 'Surveyor, group, coal mine'
  },
  {
    Id: 5144,
    Code: '2454',
    Name: 'Surveyor, hydrographic'
  },
  {
    Id: 5145,
    Code: '2454',
    Name: 'Surveyor, land'
  },
  {
    Id: 5146,
    Code: '2454',
    Name: 'Surveyor, mineral'
  },
  {
    Id: 5147,
    Code: '2454',
    Name: 'Surveyor, mining'
  },
  {
    Id: 5148,
    Code: '2454',
    Name: 'Surveyor, photogrammetric'
  },
  {
    Id: 5149,
    Code: '2454',
    Name: 'Surveyor, photographic'
  },
  {
    Id: 5150,
    Code: '2454',
    Name: 'Surveyor, planning and development'
  },
  {
    Id: 5151,
    Code: '2454',
    Name: 'Surveyor, project, professional'
  },
  {
    Id: 5152,
    Code: '2454',
    Name: 'Surveyor, property'
  },
  {
    Id: 5153,
    Code: '2454',
    Name: 'Surveyor, river'
  },
  {
    Id: 5154,
    Code: '2454',
    Name: 'Surveyor, rural'
  },
  {
    Id: 5155,
    Code: '2454',
    Name: 'Surveyor, topographic'
  },
  {
    Id: 5156,
    Code: '2454',
    Name: 'Surveyor, utilities'
  },
  {
    Id: 5157,
    Code: '2454',
    Name: 'Surveyor, utility'
  },
  {
    Id: 5158,
    Code: '2454',
    Name: 'Surveyor, professional'
  },
  {
    Id: 5159,
    Code: '2454',
    Name: 'Surveyor and estimator'
  },
  {
    Id: 5160,
    Code: '2454',
    Name: 'Surveyor and water engineer'
  },
  {
    Id: 5161,
    Code: '2454',
    Name: 'Surveyor-engineer'
  },
  {
    Id: 5162,
    Code: '2454',
    Name: 'Topographer'
  },
  {
    Id: 5163,
    Code: '2455',
    Name: 'Clerk of works'
  },
  {
    Id: 5164,
    Code: '2455',
    Name: 'Designer, principal, building construction'
  },
  {
    Id: 5165,
    Code: '2455',
    Name: 'Engineer, civil, consulting'
  },
  {
    Id: 5166,
    Code: '2455',
    Name: 'Manager, commercial, building construction'
  },
  {
    Id: 5167,
    Code: '2455',
    Name: 'Manager, contract, building construction'
  },
  {
    Id: 5168,
    Code: '2455',
    Name: 'Manager, contracts, building'
  },
  {
    Id: 5169,
    Code: '2455',
    Name: 'Manager, contracts, electrical, building construction'
  },
  {
    Id: 5170,
    Code: '2455',
    Name: 'Manager, contracts, building construction'
  },
  {
    Id: 5171,
    Code: '2455',
    Name: 'Manager, infrastructure, railways'
  },
  {
    Id: 5172,
    Code: '2455',
    Name: 'Manager, project, building'
  },
  {
    Id: 5173,
    Code: '2455',
    Name: 'Manager, project, construction'
  },
  {
    Id: 5174,
    Code: '2455',
    Name: 'Manager, project, electrical, building construction'
  },
  {
    Id: 5175,
    Code: '2455',
    Name: 'Manager, project, building construction'
  },
  {
    Id: 5176,
    Code: '2455',
    Name: 'Manager, projects, building construction'
  },
  {
    Id: 5177,
    Code: '2455',
    Name: 'Manager, proposals'
  },
  {
    Id: 5178,
    Code: '2455',
    Name: 'Modeller, transport'
  },
  {
    Id: 5179,
    Code: '2455',
    Name: 'Planner, traffic'
  },
  {
    Id: 5180,
    Code: '2455',
    Name: 'Planner, transport'
  },
  {
    Id: 5181,
    Code: '2455',
    Name: 'Planner, transportation'
  },
  {
    Id: 5182,
    Code: '2461',
    Name: 'Almoner'
  },
  {
    Id: 5183,
    Code: '2461',
    Name: 'Coordinator, protection, adult, social worker'
  },
  {
    Id: 5184,
    Code: '2461',
    Name: 'IRO, local government: social services'
  },
  {
    Id: 5185,
    Code: '2461',
    Name: 'Leader, team, local government: social services'
  },
  {
    Id: 5186,
    Code: '2461',
    Name: 'Leader, social worker'
  },
  {
    Id: 5187,
    Code: '2461',
    Name: 'Manager, practice, local government: social services'
  },
  {
    Id: 5188,
    Code: '2461',
    Name: 'Manager, team, work, social'
  },
  {
    Id: 5189,
    Code: '2461',
    Name: 'Manager, team, local government: social services'
  },
  {
    Id: 5190,
    Code: '2461',
    Name: 'Officer, fostering'
  },
  {
    Id: 5191,
    Code: '2461',
    Name: 'Officer, review, independent, social worker'
  },
  {
    Id: 5192,
    Code: '2461',
    Name: 'Officer, reviewing, independent, social worker'
  },
  {
    Id: 5193,
    Code: '2461',
    Name: 'Officer, reviewing, local government: social services'
  },
  {
    Id: 5194,
    Code: '2461',
    Name: 'Practitioner, advanced, local government: social services'
  },
  {
    Id: 5195,
    Code: '2461',
    Name: 'Practitioner, senior, local government: social services'
  },
  {
    Id: 5196,
    Code: '2461',
    Name: 'Practitioner, work, social, adoptions'
  },
  {
    Id: 5197,
    Code: '2461',
    Name: 'Practitioner, work, social'
  },
  {
    Id: 5198,
    Code: '2461',
    Name: 'Worker, case, family'
  },
  {
    Id: 5199,
    Code: '2461',
    Name: 'Worker, field, local government: social services'
  },
  {
    Id: 5200,
    Code: '2461',
    Name: 'Worker, social, adoption'
  },
  {
    Id: 5201,
    Code: '2461',
    Name: 'Worker, social, adult'
  },
  {
    Id: 5202,
    Code: '2461',
    Name: 'Worker, social, education'
  },
  {
    Id: 5203,
    Code: '2461',
    Name: 'Worker, social, forensic'
  },
  {
    Id: 5204,
    Code: '2461',
    Name: 'Worker, social, fostering'
  },
  {
    Id: 5205,
    Code: '2461',
    Name: 'Worker, social, health, mental'
  },
  {
    Id: 5206,
    Code: '2461',
    Name: 'Worker, social, justice, criminal'
  },
  {
    Id: 5207,
    Code: '2461',
    Name: 'Worker, social, medical'
  },
  {
    Id: 5208,
    Code: '2461',
    Name: 'Worker, social, psychiatric'
  },
  {
    Id: 5209,
    Code: '2461',
    Name: 'Worker, social, supervising'
  },
  {
    Id: 5210,
    Code: '2461',
    Name: 'Worker, social, adoptions'
  },
  {
    Id: 5211,
    Code: '2461',
    Name: 'Worker, social, adults'
  },
  {
    Id: 5212,
    Code: '2461',
    Name: 'Worker, social, children and families'
  },
  {
    Id: 5213,
    Code: '2461',
    Name: 'Worker, social, fostering'
  },
  {
    Id: 5214,
    Code: '2461',
    Name: 'Worker, social, mental health'
  },
  {
    Id: 5215,
    Code: '2461',
    Name: 'Worker, social, schools'
  },
  {
    Id: 5216,
    Code: '2461',
    Name: 'Worker, social'
  },
  {
    Id: 5217,
    Code: '2462',
    Name: 'Inspector, government: National Probation Service'
  },
  {
    Id: 5218,
    Code: '2462',
    Name: 'Officer, justice, youth'
  },
  {
    Id: 5219,
    Code: '2462',
    Name: 'Officer, payback, community, probation service'
  },
  {
    Id: 5220,
    Code: '2462',
    Name: 'Officer, probation, chief'
  },
  {
    Id: 5221,
    Code: '2462',
    Name: 'Officer, probation'
  },
  {
    Id: 5222,
    Code: '2462',
    Name: 'Officer, service, community, probation service'
  },
  {
    Id: 5223,
    Code: '2463',
    Name: 'Abbot'
  },
  {
    Id: 5224,
    Code: '2463',
    Name: 'Archbishop'
  },
  {
    Id: 5225,
    Code: '2463',
    Name: 'Archdeacon'
  },
  {
    Id: 5226,
    Code: '2463',
    Name: 'Bishop'
  },
  {
    Id: 5227,
    Code: '2463',
    Name: 'Brother'
  },
  {
    Id: 5228,
    Code: '2463',
    Name: 'Canon'
  },
  {
    Id: 5229,
    Code: '2463',
    Name: 'Captain, Salvation Army'
  },
  {
    Id: 5230,
    Code: '2463',
    Name: 'Chaplain, hospital'
  },
  {
    Id: 5231,
    Code: '2463',
    Name: 'Chaplain'
  },
  {
    Id: 5232,
    Code: '2463',
    Name: 'Clergyman'
  },
  {
    Id: 5233,
    Code: '2463',
    Name: 'Clerk in holy orders'
  },
  {
    Id: 5234,
    Code: '2463',
    Name: 'Curate'
  },
  {
    Id: 5235,
    Code: '2463',
    Name: 'Deacon'
  },
  {
    Id: 5236,
    Code: '2463',
    Name: 'Dean'
  },
  {
    Id: 5237,
    Code: '2463',
    Name: 'Ecclesiastic'
  },
  {
    Id: 5238,
    Code: '2463',
    Name: 'Friar'
  },
  {
    Id: 5239,
    Code: '2463',
    Name: 'Imam'
  },
  {
    Id: 5240,
    Code: '2463',
    Name: 'Incumbent'
  },
  {
    Id: 5241,
    Code: '2463',
    Name: 'Leader, church'
  },
  {
    Id: 5242,
    Code: '2463',
    Name: 'Major, Salvation Army'
  },
  {
    Id: 5243,
    Code: '2463',
    Name: 'Member of Religious Community'
  },
  {
    Id: 5244,
    Code: '2463',
    Name: 'Minister, church'
  },
  {
    Id: 5245,
    Code: '2463',
    Name: 'Minister, religious organisation'
  },
  {
    Id: 5246,
    Code: '2463',
    Name: 'Minister of religion'
  },
  {
    Id: 5247,
    Code: '2463',
    Name: 'Moderator, Presbyterian Church'
  },
  {
    Id: 5248,
    Code: '2463',
    Name: 'Monk'
  },
  {
    Id: 5249,
    Code: '2463',
    Name: 'Nun'
  },
  {
    Id: 5250,
    Code: '2463',
    Name: 'Officer, Army, Church'
  },
  {
    Id: 5251,
    Code: '2463',
    Name: 'Officer, Army, Salvation'
  },
  {
    Id: 5252,
    Code: '2463',
    Name: 'Officer, commanding, Salvation Army'
  },
  {
    Id: 5253,
    Code: '2463',
    Name: 'Pastor'
  },
  {
    Id: 5254,
    Code: '2463',
    Name: 'Practitioner, science, christian'
  },
  {
    Id: 5255,
    Code: '2463',
    Name: 'Practitioner, shamanic'
  },
  {
    Id: 5256,
    Code: '2463',
    Name: 'Prebendary'
  },
  {
    Id: 5257,
    Code: '2463',
    Name: 'Priest'
  },
  {
    Id: 5258,
    Code: '2463',
    Name: 'Prior'
  },
  {
    Id: 5259,
    Code: '2463',
    Name: 'Rabbi'
  },
  {
    Id: 5260,
    Code: '2463',
    Name: 'Rector'
  },
  {
    Id: 5261,
    Code: '2463',
    Name: 'Reverend'
  },
  {
    Id: 5262,
    Code: '2463',
    Name: 'Sister, religious order'
  },
  {
    Id: 5263,
    Code: '2463',
    Name: 'Spiritualist'
  },
  {
    Id: 5264,
    Code: '2463',
    Name: 'Superior, lady'
  },
  {
    Id: 5265,
    Code: '2463',
    Name: 'Superior, mother'
  },
  {
    Id: 5266,
    Code: '2463',
    Name: 'Theologian'
  },
  {
    Id: 5267,
    Code: '2463',
    Name: 'Vicar'
  },
  {
    Id: 5268,
    Code: '2464',
    Name: 'Officer, offending, youth'
  },
  {
    Id: 5269,
    Code: '2464',
    Name: 'Officer, team, offending, youth'
  },
  {
    Id: 5270,
    Code: '2464',
    Name: 'Worker, support, youth, professional'
  },
  {
    Id: 5271,
    Code: '2464',
    Name: 'Worker, youth, professional'
  },
  {
    Id: 5272,
    Code: '2464',
    Name: 'Worker, youth, senior'
  },
  {
    Id: 5273,
    Code: '2464',
    Name: 'Worker, youth, professional'
  },
  {
    Id: 5274,
    Code: '2464',
    Name: 'Worker, youth and community, professional'
  },
  {
    Id: 5275,
    Code: '2464',
    Name: 'Youth worker in charge, professional'
  },
  {
    Id: 5276,
    Code: '2469',
    Name: 'Adviser, court, family'
  },
  {
    Id: 5277,
    Code: '2469',
    Name: 'Adviser, health, mental'
  },
  {
    Id: 5278,
    Code: '2469',
    Name: 'Adviser, protection, child'
  },
  {
    Id: 5279,
    Code: '2469',
    Name: 'Aid, family'
  },
  {
    Id: 5280,
    Code: '2469',
    Name: 'Aide, family'
  },
  {
    Id: 5281,
    Code: '2469',
    Name: 'Caseworker, family'
  },
  {
    Id: 5282,
    Code: '2469',
    Name: 'Consultant, protection, child'
  },
  {
    Id: 5283,
    Code: '2469',
    Name: 'Coordinator, disability'
  },
  {
    Id: 5284,
    Code: '2469',
    Name: 'Coordinator, inclusion'
  },
  {
    Id: 5285,
    Code: '2469',
    Name: "Guardian, children's"
  },
  {
    Id: 5286,
    Code: '2469',
    Name: 'Guardian ad litem'
  },
  {
    Id: 5287,
    Code: '2469',
    Name: 'Instructor, mobility, dog, guide'
  },
  {
    Id: 5288,
    Code: '2469',
    Name: 'Manager, justice, restorative'
  },
  {
    Id: 5289,
    Code: '2469',
    Name: 'Mediator, family'
  },
  {
    Id: 5290,
    Code: '2469',
    Name: 'Mentor, health, mental'
  },
  {
    Id: 5291,
    Code: '2469',
    Name: 'Officer, adoption'
  },
  {
    Id: 5292,
    Code: '2469',
    Name: 'Officer, commission, care'
  },
  {
    Id: 5293,
    Code: '2469',
    Name: 'Officer, guardian ad litem and report'
  },
  {
    Id: 5294,
    Code: '2469',
    Name: 'Officer, health, mental'
  },
  {
    Id: 5295,
    Code: '2469',
    Name: 'Officer, rehabilitation'
  },
  {
    Id: 5296,
    Code: '2469',
    Name: 'Officer, services, social'
  },
  {
    Id: 5297,
    Code: '2469',
    Name: 'Organiser, district, community services'
  },
  {
    Id: 5298,
    Code: '2469',
    Name: 'Professional, health, mental, approved'
  },
  {
    Id: 5299,
    Code: '2469',
    Name: 'Professional, years, early'
  },
  {
    Id: 5300,
    Code: '2471',
    Name: 'Librarian, branch'
  },
  {
    Id: 5301,
    Code: '2471',
    Name: 'Librarian, chartered'
  },
  {
    Id: 5302,
    Code: '2471',
    Name: 'Librarian, film'
  },
  {
    Id: 5303,
    Code: '2471',
    Name: 'Librarian, hospital'
  },
  {
    Id: 5304,
    Code: '2471',
    Name: 'Librarian, magazine'
  },
  {
    Id: 5305,
    Code: '2471',
    Name: 'Librarian, media'
  },
  {
    Id: 5306,
    Code: '2471',
    Name: 'Librarian, newspaper'
  },
  {
    Id: 5307,
    Code: '2471',
    Name: 'Librarian, picture'
  },
  {
    Id: 5308,
    Code: '2471',
    Name: 'Librarian, tape, computer'
  },
  {
    Id: 5309,
    Code: '2471',
    Name: 'Librarian, tape, magnetic'
  },
  {
    Id: 5310,
    Code: '2471',
    Name: 'Librarian, technical'
  },
  {
    Id: 5311,
    Code: '2471',
    Name: 'Librarian, university'
  },
  {
    Id: 5312,
    Code: '2471',
    Name: 'Librarian, visual, audio'
  },
  {
    Id: 5313,
    Code: '2471',
    Name: 'Librarian'
  },
  {
    Id: 5314,
    Code: '2471',
    Name: 'Manager, resource, learning'
  },
  {
    Id: 5315,
    Code: '2471',
    Name: 'Scientist, information'
  },
  {
    Id: 5316,
    Code: '2471',
    Name: 'Superintendent, library'
  },
  {
    Id: 5317,
    Code: '2472',
    Name: 'Archivist'
  },
  {
    Id: 5318,
    Code: '2472',
    Name: 'Assistant, curatorial'
  },
  {
    Id: 5319,
    Code: '2472',
    Name: 'Conservator'
  },
  {
    Id: 5320,
    Code: '2472',
    Name: 'Curator'
  },
  {
    Id: 5321,
    Code: '2472',
    Name: 'Educator, museum'
  },
  {
    Id: 5322,
    Code: '2472',
    Name: 'Inspector of ancient monuments'
  },
  {
    Id: 5323,
    Code: '2472',
    Name: 'Investigator, Historic England'
  },
  {
    Id: 5324,
    Code: '2472',
    Name: 'Keeper, museum'
  },
  {
    Id: 5325,
    Code: '2472',
    Name: 'Keeper, art gallery'
  },
  {
    Id: 5326,
    Code: '2472',
    Name: 'Keeper, museum'
  },
  {
    Id: 5327,
    Code: '2472',
    Name: 'Keeper, Public Record Office'
  },
  {
    Id: 5328,
    Code: '2472',
    Name: 'Manager, collections, museum'
  },
  {
    Id: 5329,
    Code: '2472',
    Name: 'Officer, collection, museum'
  },
  {
    Id: 5330,
    Code: '2472',
    Name: 'Officer, conservation, museum'
  },
  {
    Id: 5331,
    Code: '2472',
    Name: 'Officer, education, arts'
  },
  {
    Id: 5332,
    Code: '2472',
    Name: 'Officer, education, museum'
  },
  {
    Id: 5333,
    Code: '2472',
    Name: 'Officer, education, art gallery, museum'
  },
  {
    Id: 5334,
    Code: '2472',
    Name: 'Officer, museum'
  },
  {
    Id: 5335,
    Code: '2472',
    Name: 'Officer, museums'
  },
  {
    Id: 5336,
    Code: '2472',
    Name: 'Technician, conservation, art gallery, museum'
  },
  {
    Id: 5337,
    Code: '2481',
    Name: 'Analyst, control, quality'
  },
  {
    Id: 5338,
    Code: '2481',
    Name: 'Analyst, QC'
  },
  {
    Id: 5339,
    Code: '2481',
    Name: 'Consultant, assurance, quality'
  },
  {
    Id: 5340,
    Code: '2481',
    Name: 'Consultant, management, quality'
  },
  {
    Id: 5341,
    Code: '2481',
    Name: 'Consultant, quality'
  },
  {
    Id: 5342,
    Code: '2481',
    Name: 'Controller, maintenance, planned, coal mine'
  },
  {
    Id: 5343,
    Code: '2481',
    Name: 'Controller, project, metal trades'
  },
  {
    Id: 5344,
    Code: '2481',
    Name: 'Controller, quality, professional'
  },
  {
    Id: 5345,
    Code: '2481',
    Name: 'Coordinator, control, quality'
  },
  {
    Id: 5346,
    Code: '2481',
    Name: 'Engineer, assurance, quality'
  },
  {
    Id: 5347,
    Code: '2481',
    Name: 'Engineer, control, quality'
  },
  {
    Id: 5348,
    Code: '2481',
    Name: 'Engineer, planning, windfarm'
  },
  {
    Id: 5349,
    Code: '2481',
    Name: 'Engineer, planning'
  },
  {
    Id: 5350,
    Code: '2481',
    Name: 'Engineer, progress'
  },
  {
    Id: 5351,
    Code: '2481',
    Name: 'Engineer, quality, professional'
  },
  {
    Id: 5352,
    Code: '2481',
    Name: 'Engineer, systems, quality'
  },
  {
    Id: 5353,
    Code: '2481',
    Name: 'Engineer, professional, quality control'
  },
  {
    Id: 5354,
    Code: '2481',
    Name: 'Inspector, chief, professional, quality control, engineering'
  },
  {
    Id: 5355,
    Code: '2481',
    Name: 'Inspector, civil engineering'
  },
  {
    Id: 5356,
    Code: '2481',
    Name: 'Manager, engineering, quality'
  },
  {
    Id: 5357,
    Code: '2481',
    Name: 'Officer, assurance, quality, professional'
  },
  {
    Id: 5358,
    Code: '2481',
    Name: 'Officer, control, quality, professional'
  },
  {
    Id: 5359,
    Code: '2481',
    Name: 'Officer, planning, manufacturing'
  },
  {
    Id: 5360,
    Code: '2481',
    Name: 'Planner, group, coal mine'
  },
  {
    Id: 5361,
    Code: '2481',
    Name: 'Planner, mine, coal mine'
  },
  {
    Id: 5362,
    Code: '2481',
    Name: 'Planner, offshore'
  },
  {
    Id: 5363,
    Code: '2481',
    Name: 'Planner, process'
  },
  {
    Id: 5364,
    Code: '2481',
    Name: 'Planner, engineering'
  },
  {
    Id: 5365,
    Code: '2481',
    Name: 'Radiologist, industrial'
  },
  {
    Id: 5366,
    Code: '2481',
    Name: 'Radiologist, industrial'
  },
  {
    Id: 5367,
    Code: '2481',
    Name: 'Technologist, clothing'
  },
  {
    Id: 5368,
    Code: '2481',
    Name: 'Technologist, garment'
  },
  {
    Id: 5369,
    Code: '2482',
    Name: 'Accreditor'
  },
  {
    Id: 5370,
    Code: '2482',
    Name: 'Adjudicator, national insurance regulations'
  },
  {
    Id: 5371,
    Code: '2482',
    Name: 'Adviser, regulatory'
  },
  {
    Id: 5372,
    Code: '2482',
    Name: 'Analyst, affairs, regulatory'
  },
  {
    Id: 5373,
    Code: '2482',
    Name: 'Analyst, compliance'
  },
  {
    Id: 5374,
    Code: '2482',
    Name: 'Analyst, surveillance, compliance'
  },
  {
    Id: 5375,
    Code: '2482',
    Name: 'Analyst, surveillance, market'
  },
  {
    Id: 5376,
    Code: '2482',
    Name: 'Associate, affairs, regulatory'
  },
  {
    Id: 5377,
    Code: '2482',
    Name: 'Associate, regulatory'
  },
  {
    Id: 5378,
    Code: '2482',
    Name: 'Auditor, clinical'
  },
  {
    Id: 5379,
    Code: '2482',
    Name: 'Consultant, accreditation'
  },
  {
    Id: 5380,
    Code: '2482',
    Name: 'Coordinator, governance, clinical'
  },
  {
    Id: 5381,
    Code: '2482',
    Name: 'Director, compliance'
  },
  {
    Id: 5382,
    Code: '2482',
    Name: 'Executive, affairs, regulatory'
  },
  {
    Id: 5383,
    Code: '2482',
    Name: 'Executive, regulatory'
  },
  {
    Id: 5384,
    Code: '2482',
    Name: 'Facilitator, audit, clinical'
  },
  {
    Id: 5385,
    Code: '2482',
    Name: 'Head of compliance'
  },
  {
    Id: 5386,
    Code: '2482',
    Name: 'Head of quality'
  },
  {
    Id: 5387,
    Code: '2482',
    Name: 'Head of quality assurance'
  },
  {
    Id: 5388,
    Code: '2482',
    Name: 'Manager, accreditation'
  },
  {
    Id: 5389,
    Code: '2482',
    Name: 'Manager, affairs, regulatory'
  },
  {
    Id: 5390,
    Code: '2482',
    Name: 'Manager, asset'
  },
  {
    Id: 5391,
    Code: '2482',
    Name: 'Manager, assurance, quality'
  },
  {
    Id: 5392,
    Code: '2482',
    Name: 'Manager, client, British Standards Institution'
  },
  {
    Id: 5393,
    Code: '2482',
    Name: 'Manager, coding, clinical'
  },
  {
    Id: 5394,
    Code: '2482',
    Name: 'Manager, compliance'
  },
  {
    Id: 5395,
    Code: '2482',
    Name: 'Manager, control, quality'
  },
  {
    Id: 5396,
    Code: '2482',
    Name: 'Manager, finishing'
  },
  {
    Id: 5397,
    Code: '2482',
    Name: 'Manager, governance, information'
  },
  {
    Id: 5398,
    Code: '2482',
    Name: 'Manager, health and safety, quality'
  },
  {
    Id: 5399,
    Code: '2482',
    Name: 'Manager, improvement, quality'
  },
  {
    Id: 5400,
    Code: '2482',
    Name: 'Manager, performance and planning'
  },
  {
    Id: 5401,
    Code: '2482',
    Name: 'Manager, pharmacovigilance'
  },
  {
    Id: 5402,
    Code: '2482',
    Name: 'Manager, QA'
  },
  {
    Id: 5403,
    Code: '2482',
    Name: 'Manager, QHSE'
  },
  {
    Id: 5404,
    Code: '2482',
    Name: 'Manager, quality'
  },
  {
    Id: 5405,
    Code: '2482',
    Name: 'Manager, quality and compliance'
  },
  {
    Id: 5406,
    Code: '2482',
    Name: 'Manager, quality and performance'
  },
  {
    Id: 5407,
    Code: '2482',
    Name: 'Manager, rigour'
  },
  {
    Id: 5408,
    Code: '2482',
    Name: 'Manager, standards, operations'
  },
  {
    Id: 5409,
    Code: '2482',
    Name: 'Manager, systems, quality'
  },
  {
    Id: 5410,
    Code: '2482',
    Name: 'Officer, affairs, regulatory'
  },
  {
    Id: 5411,
    Code: '2482',
    Name: 'Officer, audit, clinical'
  },
  {
    Id: 5412,
    Code: '2482',
    Name: 'Officer, compliance, chief'
  },
  {
    Id: 5413,
    Code: '2482',
    Name: 'Officer, compliance'
  },
  {
    Id: 5414,
    Code: '2482',
    Name: 'Officer, pharmacovigilance'
  },
  {
    Id: 5415,
    Code: '2482',
    Name: 'Officer, quality and compliance'
  },
  {
    Id: 5416,
    Code: '2482',
    Name: 'Officer, safety, drug'
  },
  {
    Id: 5417,
    Code: '2482',
    Name: 'Person, qualified, pharmaceutical mfr'
  },
  {
    Id: 5418,
    Code: '2482',
    Name: 'QP, pharmaceutical mfr'
  },
  {
    Id: 5419,
    Code: '2482',
    Name: 'Regulator, financial, government'
  },
  {
    Id: 5420,
    Code: '2482',
    Name: 'Regulator, financial'
  },
  {
    Id: 5421,
    Code: '2482',
    Name: 'Scientist, pharmacovigilance'
  },
  {
    Id: 5422,
    Code: '2482',
    Name: 'Specialist, affairs, regulatory'
  },
  {
    Id: 5423,
    Code: '2483',
    Name: 'Inspector, food'
  },
  {
    Id: 5424,
    Code: '2483',
    Name: 'Inspector, health, environmental'
  },
  {
    Id: 5425,
    Code: '2483',
    Name: 'Inspector, health, port'
  },
  {
    Id: 5426,
    Code: '2483',
    Name: 'Inspector, health, public'
  },
  {
    Id: 5427,
    Code: '2483',
    Name: 'Inspector, pollution, air'
  },
  {
    Id: 5428,
    Code: '2483',
    Name: 'Inspector, sanitary'
  },
  {
    Id: 5429,
    Code: '2483',
    Name: 'Inspector, shops, local government'
  },
  {
    Id: 5430,
    Code: '2483',
    Name: 'Inspector, smoke, local government'
  },
  {
    Id: 5431,
    Code: '2483',
    Name: 'Manager, health, environmental'
  },
  {
    Id: 5432,
    Code: '2483',
    Name: 'Officer, control, smoke, local government'
  },
  {
    Id: 5433,
    Code: '2483',
    Name: 'Officer, enforcement, professional, environmental health'
  },
  {
    Id: 5434,
    Code: '2483',
    Name: 'Officer, health, environmental'
  },
  {
    Id: 5435,
    Code: '2483',
    Name: 'Officer, health, port'
  },
  {
    Id: 5436,
    Code: '2483',
    Name: 'Officer, health, public'
  },
  {
    Id: 5437,
    Code: '2483',
    Name: 'Officer, safety, food'
  },
  {
    Id: 5438,
    Code: '2483',
    Name: 'Officer, sanitary'
  },
  {
    Id: 5439,
    Code: '2483',
    Name: 'Officer, technical, environmental health'
  },
  {
    Id: 5440,
    Code: '2491',
    Name: 'Director, editorial'
  },
  {
    Id: 5441,
    Code: '2491',
    Name: 'Editor, art'
  },
  {
    Id: 5442,
    Code: '2491',
    Name: 'Editor, commissioning, newspapers, magazines'
  },
  {
    Id: 5443,
    Code: '2491',
    Name: 'Editor, content, newspapers, magazines'
  },
  {
    Id: 5444,
    Code: '2491',
    Name: 'Editor, copy'
  },
  {
    Id: 5445,
    Code: '2491',
    Name: 'Editor, desk, multimedia'
  },
  {
    Id: 5446,
    Code: '2491',
    Name: 'Editor, development, publishing'
  },
  {
    Id: 5447,
    Code: '2491',
    Name: 'Editor, digital'
  },
  {
    Id: 5448,
    Code: '2491',
    Name: 'Editor, listings'
  },
  {
    Id: 5449,
    Code: '2491',
    Name: 'Editor, magazine'
  },
  {
    Id: 5450,
    Code: '2491',
    Name: 'Editor, managing, newspapers, magazines'
  },
  {
    Id: 5451,
    Code: '2491',
    Name: 'Editor, news, newspaper'
  },
  {
    Id: 5452,
    Code: '2491',
    Name: 'Editor, newspaper'
  },
  {
    Id: 5453,
    Code: '2491',
    Name: 'Editor, online'
  },
  {
    Id: 5454,
    Code: '2491',
    Name: 'Editor, photo'
  },
  {
    Id: 5455,
    Code: '2491',
    Name: 'Editor, picture'
  },
  {
    Id: 5456,
    Code: '2491',
    Name: 'Editor, production'
  },
  {
    Id: 5457,
    Code: '2491',
    Name: 'Editor, publishing'
  },
  {
    Id: 5458,
    Code: '2491',
    Name: 'Editor, sub'
  },
  {
    Id: 5459,
    Code: '2491',
    Name: 'Editor, newspapers, magazines'
  },
  {
    Id: 5460,
    Code: '2491',
    Name: 'Editor'
  },
  {
    Id: 5461,
    Code: '2491',
    Name: 'Executive, editorial, newspaper'
  },
  {
    Id: 5462,
    Code: '2491',
    Name: 'Manager, editorial'
  },
  {
    Id: 5463,
    Code: '2491',
    Name: 'Officer, publications'
  },
  {
    Id: 5464,
    Code: '2491',
    Name: 'Sub-editor'
  },
  {
    Id: 5465,
    Code: '2492',
    Name: 'Columnist'
  },
  {
    Id: 5466,
    Code: '2492',
    Name: 'Commentator'
  },
  {
    Id: 5467,
    Code: '2492',
    Name: 'Compiler, crossword'
  },
  {
    Id: 5468,
    Code: '2492',
    Name: 'Correspondent, foreign'
  },
  {
    Id: 5469,
    Code: '2492',
    Name: 'Correspondent, newspaper'
  },
  {
    Id: 5470,
    Code: '2492',
    Name: 'Correspondent, political'
  },
  {
    Id: 5471,
    Code: '2492',
    Name: 'Correspondent, technical'
  },
  {
    Id: 5472,
    Code: '2492',
    Name: 'Correspondent, turf'
  },
  {
    Id: 5473,
    Code: '2492',
    Name: 'Correspondent, newspaper publishing'
  },
  {
    Id: 5474,
    Code: '2492',
    Name: 'Critic'
  },
  {
    Id: 5475,
    Code: '2492',
    Name: 'Diarist'
  },
  {
    Id: 5476,
    Code: '2492',
    Name: 'Journalist, broadcast'
  },
  {
    Id: 5477,
    Code: '2492',
    Name: 'Journalist, data'
  },
  {
    Id: 5478,
    Code: '2492',
    Name: 'Journalist, digital'
  },
  {
    Id: 5479,
    Code: '2492',
    Name: 'Journalist, multimedia'
  },
  {
    Id: 5480,
    Code: '2492',
    Name: 'Journalist, online'
  },
  {
    Id: 5481,
    Code: '2492',
    Name: 'Journalist, radio'
  },
  {
    Id: 5482,
    Code: '2492',
    Name: 'Journalist, broadcasting'
  },
  {
    Id: 5483,
    Code: '2492',
    Name: 'Journalist, newspaper'
  },
  {
    Id: 5484,
    Code: '2492',
    Name: 'Journalist'
  },
  {
    Id: 5485,
    Code: '2492',
    Name: 'Journalist and broadcaster'
  },
  {
    Id: 5486,
    Code: '2492',
    Name: 'Reporter, court, newspaper'
  },
  {
    Id: 5487,
    Code: '2492',
    Name: 'Reporter'
  },
  {
    Id: 5488,
    Code: '2492',
    Name: 'Representative, press'
  },
  {
    Id: 5489,
    Code: '2492',
    Name: 'Writer, feature'
  },
  {
    Id: 5490,
    Code: '2492',
    Name: 'Writer, features'
  },
  {
    Id: 5491,
    Code: '2492',
    Name: 'Writer, freelance, newspaper, magazine'
  },
  {
    Id: 5492,
    Code: '2492',
    Name: 'Writer, leader'
  },
  {
    Id: 5493,
    Code: '2492',
    Name: 'Writer, news'
  },
  {
    Id: 5494,
    Code: '2492',
    Name: 'Writer, sports, newspaper'
  },
  {
    Id: 5495,
    Code: '2492',
    Name: 'Writer, staff'
  },
  {
    Id: 5496,
    Code: '2492',
    Name: 'Writer, travel, newspaper, magazine'
  },
  {
    Id: 5497,
    Code: '2492',
    Name: 'Writer, journalism'
  },
  {
    Id: 5498,
    Code: '2492',
    Name: 'Writer, newspaper publishing'
  },
  {
    Id: 5499,
    Code: '2492',
    Name: 'Writer and editor'
  },
  {
    Id: 5500,
    Code: '2493',
    Name: 'Adviser, PR'
  },
  {
    Id: 5501,
    Code: '2493',
    Name: 'Adviser, relations, public'
  },
  {
    Id: 5502,
    Code: '2493',
    Name: 'Agent, press'
  },
  {
    Id: 5503,
    Code: '2493',
    Name: 'Agent, publicity'
  },
  {
    Id: 5504,
    Code: '2493',
    Name: 'Aide, communications'
  },
  {
    Id: 5505,
    Code: '2493',
    Name: 'Assistant, communications, digital'
  },
  {
    Id: 5506,
    Code: '2493',
    Name: 'Assistant, communications, public relations'
  },
  {
    Id: 5507,
    Code: '2493',
    Name: 'Assistant, PR'
  },
  {
    Id: 5508,
    Code: '2493',
    Name: 'Assistant, publications'
  },
  {
    Id: 5509,
    Code: '2493',
    Name: 'Assistant, publicity'
  },
  {
    Id: 5510,
    Code: '2493',
    Name: 'Assistant, publishing'
  },
  {
    Id: 5511,
    Code: '2493',
    Name: 'Assistant, relations, public'
  },
  {
    Id: 5512,
    Code: '2493',
    Name: 'Consultant, affairs, public'
  },
  {
    Id: 5513,
    Code: '2493',
    Name: 'Consultant, communications, internal'
  },
  {
    Id: 5514,
    Code: '2493',
    Name: 'Consultant, communications, public relations'
  },
  {
    Id: 5515,
    Code: '2493',
    Name: 'Consultant, media, social'
  },
  {
    Id: 5516,
    Code: '2493',
    Name: 'Consultant, media'
  },
  {
    Id: 5517,
    Code: '2493',
    Name: 'Consultant, PR'
  },
  {
    Id: 5518,
    Code: '2493',
    Name: 'Consultant, publicity'
  },
  {
    Id: 5519,
    Code: '2493',
    Name: 'Consultant, relations, public'
  },
  {
    Id: 5520,
    Code: '2493',
    Name: 'Coordinator, media'
  },
  {
    Id: 5521,
    Code: '2493',
    Name: 'Executive, account, public relations'
  },
  {
    Id: 5522,
    Code: '2493',
    Name: 'Executive, liaison, media'
  },
  {
    Id: 5523,
    Code: '2493',
    Name: 'Executive, media, social'
  },
  {
    Id: 5524,
    Code: '2493',
    Name: 'Executive, PR'
  },
  {
    Id: 5525,
    Code: '2493',
    Name: 'Executive, relations, public'
  },
  {
    Id: 5526,
    Code: '2493',
    Name: 'Interviewer, press'
  },
  {
    Id: 5527,
    Code: '2493',
    Name: 'Lobbyist'
  },
  {
    Id: 5528,
    Code: '2493',
    Name: 'Manager, account, public relations'
  },
  {
    Id: 5529,
    Code: '2493',
    Name: 'Manager, affairs, corporate'
  },
  {
    Id: 5530,
    Code: '2493',
    Name: 'Manager, affairs, public'
  },
  {
    Id: 5531,
    Code: '2493',
    Name: 'Manager, communications, corporate'
  },
  {
    Id: 5532,
    Code: '2493',
    Name: 'Manager, communications, internal'
  },
  {
    Id: 5533,
    Code: '2493',
    Name: 'Manager, communications, public relations'
  },
  {
    Id: 5534,
    Code: '2493',
    Name: 'Manager, engagement, public'
  },
  {
    Id: 5535,
    Code: '2493',
    Name: 'Manager, media, social'
  },
  {
    Id: 5536,
    Code: '2493',
    Name: 'Manager, media'
  },
  {
    Id: 5537,
    Code: '2493',
    Name: 'Manager, PR'
  },
  {
    Id: 5538,
    Code: '2493',
    Name: 'Manager, publicity'
  },
  {
    Id: 5539,
    Code: '2493',
    Name: 'Manager, relations, alumni'
  },
  {
    Id: 5540,
    Code: '2493',
    Name: 'Manager, relations, media'
  },
  {
    Id: 5541,
    Code: '2493',
    Name: 'Manager, relations, public'
  },
  {
    Id: 5542,
    Code: '2493',
    Name: 'Manager, responsibility, social, corporate'
  },
  {
    Id: 5543,
    Code: '2493',
    Name: 'Manager, public relations'
  },
  {
    Id: 5544,
    Code: '2493',
    Name: 'Officer, communications, public relations'
  },
  {
    Id: 5545,
    Code: '2493',
    Name: 'Officer, information, research'
  },
  {
    Id: 5546,
    Code: '2493',
    Name: 'Officer, information, public relations'
  },
  {
    Id: 5547,
    Code: '2493',
    Name: 'Officer, liaison, press'
  },
  {
    Id: 5548,
    Code: '2493',
    Name: 'Officer, liaison, railways'
  },
  {
    Id: 5549,
    Code: '2493',
    Name: 'Officer, media'
  },
  {
    Id: 5550,
    Code: '2493',
    Name: 'Officer, PR'
  },
  {
    Id: 5551,
    Code: '2493',
    Name: 'Officer, press'
  },
  {
    Id: 5552,
    Code: '2493',
    Name: 'Officer, press and information'
  },
  {
    Id: 5553,
    Code: '2493',
    Name: 'Officer, publicity'
  },
  {
    Id: 5554,
    Code: '2493',
    Name: 'Officer, relations, alumni'
  },
  {
    Id: 5555,
    Code: '2493',
    Name: 'Officer, relations, media'
  },
  {
    Id: 5556,
    Code: '2493',
    Name: 'Officer, relations, public'
  },
  {
    Id: 5557,
    Code: '2493',
    Name: 'Organiser, publicity'
  },
  {
    Id: 5558,
    Code: '2493',
    Name: 'Publicist'
  },
  {
    Id: 5559,
    Code: '2493',
    Name: 'Representative, press, public relations'
  },
  {
    Id: 5560,
    Code: '2493',
    Name: 'Secretary, press'
  },
  {
    Id: 5561,
    Code: '2493',
    Name: 'Specialist, media, social'
  },
  {
    Id: 5562,
    Code: '2494',
    Name: 'Consultant, creative, advertising'
  },
  {
    Id: 5563,
    Code: '2494',
    Name: 'Director, art, digital'
  },
  {
    Id: 5564,
    Code: '2494',
    Name: 'Director, art'
  },
  {
    Id: 5565,
    Code: '2494',
    Name: 'Director, creative, digital'
  },
  {
    Id: 5566,
    Code: '2494',
    Name: 'Director, creative'
  },
  {
    Id: 5567,
    Code: '2494',
    Name: 'Director, design, advertising'
  },
  {
    Id: 5568,
    Code: '2494',
    Name: 'Head of digital design'
  },
  {
    Id: 5569,
    Code: '2494',
    Name: 'Head of fundraising'
  },
  {
    Id: 5570,
    Code: '2494',
    Name: 'Manager, account, advertising'
  },
  {
    Id: 5571,
    Code: '2494',
    Name: 'Manager, account, advertising'
  },
  {
    Id: 5572,
    Code: '2494',
    Name: 'Manager, accounts, advertising'
  },
  {
    Id: 5573,
    Code: '2494',
    Name: 'Manager, advertisement'
  },
  {
    Id: 5574,
    Code: '2494',
    Name: 'Manager, advertising, online'
  },
  {
    Id: 5575,
    Code: '2494',
    Name: 'Manager, advertising, sales'
  },
  {
    Id: 5576,
    Code: '2494',
    Name: 'Manager, advertising'
  },
  {
    Id: 5577,
    Code: '2494',
    Name: 'Manager, appeal'
  },
  {
    Id: 5578,
    Code: '2494',
    Name: 'Manager, appeals'
  },
  {
    Id: 5579,
    Code: '2494',
    Name: 'Manager, campaign'
  },
  {
    Id: 5580,
    Code: '2494',
    Name: 'Manager, campaigns'
  },
  {
    Id: 5581,
    Code: '2494',
    Name: 'Manager, fundraising'
  },
  {
    Id: 5582,
    Code: '2494',
    Name: 'Manager, lottery'
  },
  {
    Id: 5583,
    Code: '2494',
    Name: 'Manager, portfolio, advertising'
  },
  {
    Id: 5584,
    Code: '2494',
    Name: 'Manager, press, advertising'
  },
  {
    Id: 5585,
    Code: '2494',
    Name: 'Manager, production, advertising'
  },
  {
    Id: 5586,
    Code: '2494',
    Name: 'Manager, projects, advertising'
  },
  {
    Id: 5587,
    Code: '2494',
    Name: 'Manager, raising, fund'
  },
  {
    Id: 5588,
    Code: '2494',
    Name: 'Manager, traffic, advertising'
  },
  {
    Id: 5589,
    Code: '2494',
    Name: 'Manager, advertising'
  },
  {
    Id: 5590,
    Code: '2494',
    Name: 'Planner, creative'
  },
  {
    Id: 5591,
    Code: '3111',
    Name: 'Aid, laboratory'
  },
  {
    Id: 5592,
    Code: '3111',
    Name: 'Aide, laboratory'
  },
  {
    Id: 5593,
    Code: '3111',
    Name: 'Analyst, material'
  },
  {
    Id: 5594,
    Code: '3111',
    Name: 'Analyst, materials'
  },
  {
    Id: 5595,
    Code: '3111',
    Name: 'Analyst, pharmaceutical'
  },
  {
    Id: 5596,
    Code: '3111',
    Name: 'Analyst, seed'
  },
  {
    Id: 5597,
    Code: '3111',
    Name: 'Assistant, biomedical'
  },
  {
    Id: 5598,
    Code: '3111',
    Name: 'Assistant, chemical'
  },
  {
    Id: 5599,
    Code: '3111',
    Name: 'Assistant, control, quality, chemical mfr'
  },
  {
    Id: 5600,
    Code: '3111',
    Name: 'Assistant, lab, medical'
  },
  {
    Id: 5601,
    Code: '3111',
    Name: 'Assistant, lab'
  },
  {
    Id: 5602,
    Code: '3111',
    Name: 'Assistant, laboratory, medical'
  },
  {
    Id: 5603,
    Code: '3111',
    Name: 'Assistant, laboratory'
  },
  {
    Id: 5604,
    Code: '3111',
    Name: 'Assistant, science, healthcare'
  },
  {
    Id: 5605,
    Code: '3111',
    Name: 'Assistant, scientific'
  },
  {
    Id: 5606,
    Code: '3111',
    Name: 'Associate, trial, clinical'
  },
  {
    Id: 5607,
    Code: '3111',
    Name: 'Attendant, laboratory'
  },
  {
    Id: 5608,
    Code: '3111',
    Name: 'Cytoscreener'
  },
  {
    Id: 5609,
    Code: '3111',
    Name: 'Geotechnician'
  },
  {
    Id: 5610,
    Code: '3111',
    Name: 'Leader, team, laboratory'
  },
  {
    Id: 5611,
    Code: '3111',
    Name: 'MLA'
  },
  {
    Id: 5612,
    Code: '3111',
    Name: 'Man, bench, laboratory'
  },
  {
    Id: 5613,
    Code: '3111',
    Name: 'Man, laboratory'
  },
  {
    Id: 5614,
    Code: '3111',
    Name: 'Metrologist'
  },
  {
    Id: 5615,
    Code: '3111',
    Name: 'Microscopist, electron'
  },
  {
    Id: 5616,
    Code: '3111',
    Name: 'Monitor, physics, health'
  },
  {
    Id: 5617,
    Code: '3111',
    Name: 'Monitor, radiation'
  },
  {
    Id: 5618,
    Code: '3111',
    Name: 'Monitor, atomic energy establishment'
  },
  {
    Id: 5619,
    Code: '3111',
    Name: 'Officer, laboratory'
  },
  {
    Id: 5620,
    Code: '3111',
    Name: 'Officer, science, laboratory, medical, junior'
  },
  {
    Id: 5621,
    Code: '3111',
    Name: 'Officer, science, laboratory'
  },
  {
    Id: 5622,
    Code: '3111',
    Name: 'Officer, scientific, assistant'
  },
  {
    Id: 5623,
    Code: '3111',
    Name: 'Officer, scientific, laboratory, medical, junior'
  },
  {
    Id: 5624,
    Code: '3111',
    Name: 'Officer, scientific, laboratory'
  },
  {
    Id: 5625,
    Code: '3111',
    Name: 'Officer, scientific, laboratory'
  },
  {
    Id: 5626,
    Code: '3111',
    Name: 'Officer, technical, assistant, chemical mfr'
  },
  {
    Id: 5627,
    Code: '3111',
    Name: 'Officer, technical, National Institute of Agricultural Botany'
  },
  {
    Id: 5628,
    Code: '3111',
    Name: 'Practitioner, associate, laboratory'
  },
  {
    Id: 5629,
    Code: '3111',
    Name: 'Scientist, assistant'
  },
  {
    Id: 5630,
    Code: '3111',
    Name: 'Screener, cytology'
  },
  {
    Id: 5631,
    Code: '3111',
    Name: 'Steward, laboratory'
  },
  {
    Id: 5632,
    Code: '3111',
    Name: 'Superintendent, laboratory'
  },
  {
    Id: 5633,
    Code: '3111',
    Name: 'Supervisor, laboratory'
  },
  {
    Id: 5634,
    Code: '3111',
    Name: 'Technician, analytical'
  },
  {
    Id: 5635,
    Code: '3111',
    Name: 'Technician, anatomy'
  },
  {
    Id: 5636,
    Code: '3111',
    Name: 'Technician, chemistry'
  },
  {
    Id: 5637,
    Code: '3111',
    Name: 'Technician, food'
  },
  {
    Id: 5638,
    Code: '3111',
    Name: 'Technician, geological'
  },
  {
    Id: 5639,
    Code: '3111',
    Name: 'Technician, geology'
  },
  {
    Id: 5640,
    Code: '3111',
    Name: 'Technician, histology'
  },
  {
    Id: 5641,
    Code: '3111',
    Name: 'Technician, hospital'
  },
  {
    Id: 5642,
    Code: '3111',
    Name: 'Technician, lab'
  },
  {
    Id: 5643,
    Code: '3111',
    Name: 'Technician, laboratory, chemistry'
  },
  {
    Id: 5644,
    Code: '3111',
    Name: 'Technician, laboratory, medical'
  },
  {
    Id: 5645,
    Code: '3111',
    Name: 'Technician, laboratory, microbiology'
  },
  {
    Id: 5646,
    Code: '3111',
    Name: 'Technician, laboratory, school'
  },
  {
    Id: 5647,
    Code: '3111',
    Name: 'Technician, laboratory, science'
  },
  {
    Id: 5648,
    Code: '3111',
    Name: 'Technician, laboratory, teaching'
  },
  {
    Id: 5649,
    Code: '3111',
    Name: 'Technician, laboratory'
  },
  {
    Id: 5650,
    Code: '3111',
    Name: 'Technician, microbiology'
  },
  {
    Id: 5651,
    Code: '3111',
    Name: 'Technician, physics, hospital service'
  },
  {
    Id: 5652,
    Code: '3111',
    Name: 'Technician, scientific'
  },
  {
    Id: 5653,
    Code: '3111',
    Name: 'Technician, laboratory'
  },
  {
    Id: 5654,
    Code: '3111',
    Name: 'Technician, textile'
  },
  {
    Id: 5655,
    Code: '3111',
    Name: 'Technician, hospital service'
  },
  {
    Id: 5656,
    Code: '3111',
    Name: 'Tester, water'
  },
  {
    Id: 5657,
    Code: '3111',
    Name: 'Worker, laboratory'
  },
  {
    Id: 5658,
    Code: '3111',
    Name: 'Worker, support, biomedical'
  },
  {
    Id: 5659,
    Code: '3112',
    Name: 'Assistant, electronics'
  },
  {
    Id: 5660,
    Code: '3112',
    Name: 'Engineer, assistant, broadcasting'
  },
  {
    Id: 5661,
    Code: '3112',
    Name: 'Engineer, executive, telecoms'
  },
  {
    Id: 5662,
    Code: '3112',
    Name: 'Engineer, installation, electricity supplier'
  },
  {
    Id: 5663,
    Code: '3112',
    Name: 'Engineer, simulator, flight'
  },
  {
    Id: 5664,
    Code: '3112',
    Name: 'Engineer, testing, cable, assistant'
  },
  {
    Id: 5665,
    Code: '3112',
    Name: 'Officer, signals, MOD'
  },
  {
    Id: 5666,
    Code: '3112',
    Name: 'Specialist, telecommunications'
  },
  {
    Id: 5667,
    Code: '3112',
    Name: 'Technician, avionics'
  },
  {
    Id: 5668,
    Code: '3112',
    Name: 'Technician, craft, public utilities'
  },
  {
    Id: 5669,
    Code: '3112',
    Name: 'Technician, electrical'
  },
  {
    Id: 5670,
    Code: '3112',
    Name: 'Technician, electronic'
  },
  {
    Id: 5671,
    Code: '3112',
    Name: 'Technician, electronics'
  },
  {
    Id: 5672,
    Code: '3112',
    Name: 'Technician, maintenance, electrical'
  },
  {
    Id: 5673,
    Code: '3112',
    Name: 'Technician, telemetry'
  },
  {
    Id: 5674,
    Code: '3112',
    Name: 'Technician, electrical equipment'
  },
  {
    Id: 5675,
    Code: '3112',
    Name: 'Technician, electronic equipment'
  },
  {
    Id: 5676,
    Code: '3113',
    Name: 'Adviser, aeronautical'
  },
  {
    Id: 5677,
    Code: '3113',
    Name: 'Adviser, technical'
  },
  {
    Id: 5678,
    Code: '3113',
    Name: 'Analyst, maintenance, engineering'
  },
  {
    Id: 5679,
    Code: '3113',
    Name: 'Artificer, armed forces'
  },
  {
    Id: 5680,
    Code: '3113',
    Name: 'Assessor, technical'
  },
  {
    Id: 5681,
    Code: '3113',
    Name: "Assistant, engineer's"
  },
  {
    Id: 5682,
    Code: '3113',
    Name: 'Assistant, engineering'
  },
  {
    Id: 5683,
    Code: '3113',
    Name: 'Controller, gas, steelworks'
  },
  {
    Id: 5684,
    Code: '3113',
    Name: 'Coordinator, engineering'
  },
  {
    Id: 5685,
    Code: '3113',
    Name: 'ETGII'
  },
  {
    Id: 5686,
    Code: '3113',
    Name: 'Engineer, aircraft, licensed'
  },
  {
    Id: 5687,
    Code: '3113',
    Name: 'Engineer, assistant, gas supplier'
  },
  {
    Id: 5688,
    Code: '3113',
    Name: 'Engineer, assistant, government'
  },
  {
    Id: 5689,
    Code: '3113',
    Name: 'Engineer, assistant, manufacturing'
  },
  {
    Id: 5690,
    Code: '3113',
    Name: 'Engineer, BMS'
  },
  {
    Id: 5691,
    Code: '3113',
    Name: 'Engineer, commissioning'
  },
  {
    Id: 5692,
    Code: '3113',
    Name: 'Engineer, contract'
  },
  {
    Id: 5693,
    Code: '3113',
    Name: 'Engineer, contracts'
  },
  {
    Id: 5694,
    Code: '3113',
    Name: 'Engineer, distribution, voltage, high'
  },
  {
    Id: 5695,
    Code: '3113',
    Name: 'Engineer, distribution'
  },
  {
    Id: 5696,
    Code: '3113',
    Name: 'Engineer, manufacturing'
  },
  {
    Id: 5697,
    Code: '3113',
    Name: 'Engineer, prevention, fire'
  },
  {
    Id: 5698,
    Code: '3113',
    Name: 'Engineer, rig, test'
  },
  {
    Id: 5699,
    Code: '3113',
    Name: 'Engineer, systems, management, building'
  },
  {
    Id: 5700,
    Code: '3113',
    Name: 'Engineer, technician'
  },
  {
    Id: 5701,
    Code: '3113',
    Name: 'Engineer, test, technician'
  },
  {
    Id: 5702,
    Code: '3113',
    Name: 'Engineer, track and catenary'
  },
  {
    Id: 5703,
    Code: '3113',
    Name: 'Officer, design, control, traffic, air'
  },
  {
    Id: 5704,
    Code: '3113',
    Name: 'Officer, mechanisation'
  },
  {
    Id: 5705,
    Code: '3113',
    Name: 'Officer, professional and technical, government'
  },
  {
    Id: 5706,
    Code: '3113',
    Name: 'Officer, technical, gas supplier'
  },
  {
    Id: 5707,
    Code: '3113',
    Name: 'Officer, transport, mechanical'
  },
  {
    Id: 5708,
    Code: '3113',
    Name: 'Officer, transport, DfT'
  },
  {
    Id: 5709,
    Code: '3113',
    Name: 'PTO, government'
  },
  {
    Id: 5710,
    Code: '3113',
    Name: 'Radiographer, industrial'
  },
  {
    Id: 5711,
    Code: '3113',
    Name: 'Radiographer, engineering'
  },
  {
    Id: 5712,
    Code: '3113',
    Name: 'Radiographer, manufacturing'
  },
  {
    Id: 5713,
    Code: '3113',
    Name: 'Shooter, trouble'
  },
  {
    Id: 5714,
    Code: '3113',
    Name: 'Technician, aircraft'
  },
  {
    Id: 5715,
    Code: '3113',
    Name: 'Technician, airframe'
  },
  {
    Id: 5716,
    Code: '3113',
    Name: 'Technician, engineering, aerospace'
  },
  {
    Id: 5717,
    Code: '3113',
    Name: 'Technician, engineering, agricultural'
  },
  {
    Id: 5718,
    Code: '3113',
    Name: 'Technician, engineering, automotive'
  },
  {
    Id: 5719,
    Code: '3113',
    Name: 'Technician, engineering, chemical'
  },
  {
    Id: 5720,
    Code: '3113',
    Name: 'Technician, engineering, electrical'
  },
  {
    Id: 5721,
    Code: '3113',
    Name: 'Technician, engineering, marine'
  },
  {
    Id: 5722,
    Code: '3113',
    Name: 'Technician, engineering, mechanical'
  },
  {
    Id: 5723,
    Code: '3113',
    Name: 'Technician, engineering'
  },
  {
    Id: 5724,
    Code: '3113',
    Name: 'Technician, installation'
  },
  {
    Id: 5725,
    Code: '3113',
    Name: 'Technician, instrument, steelworks'
  },
  {
    Id: 5726,
    Code: '3113',
    Name: 'Technician, maintenance, mechanical'
  },
  {
    Id: 5727,
    Code: '3113',
    Name: 'Technician, mechanical'
  },
  {
    Id: 5728,
    Code: '3113',
    Name: 'Technician, traffic'
  },
  {
    Id: 5729,
    Code: '3113',
    Name: 'Technician, turbine, wind'
  },
  {
    Id: 5730,
    Code: '3113',
    Name: 'Technician, aircraft'
  },
  {
    Id: 5731,
    Code: '3113',
    Name: 'Technician, engineering, mechanical'
  },
  {
    Id: 5732,
    Code: '3113',
    Name: 'Technician, engineering'
  },
  {
    Id: 5733,
    Code: '3113',
    Name: 'Technician, flight test'
  },
  {
    Id: 5734,
    Code: '3113',
    Name: 'Technician, wind tunnel'
  },
  {
    Id: 5735,
    Code: '3113',
    Name: 'Technician, instrument, steelworks'
  },
  {
    Id: 5736,
    Code: '3114',
    Name: 'Analyst, stress, construction, engineering'
  },
  {
    Id: 5737,
    Code: '3114',
    Name: 'Assistant, building'
  },
  {
    Id: 5738,
    Code: '3114',
    Name: 'Assistant, engineering, civil'
  },
  {
    Id: 5739,
    Code: '3114',
    Name: 'Assistant, technical, civil engineering'
  },
  {
    Id: 5740,
    Code: '3114',
    Name: 'Consultant, building'
  },
  {
    Id: 5741,
    Code: '3114',
    Name: 'Consultant, construction'
  },
  {
    Id: 5742,
    Code: '3114',
    Name: 'Consultant, services, building'
  },
  {
    Id: 5743,
    Code: '3114',
    Name: 'Controller, network, water treatment'
  },
  {
    Id: 5744,
    Code: '3114',
    Name: 'Controller, production, building construction'
  },
  {
    Id: 5745,
    Code: '3114',
    Name: 'Coordinator, project, construction'
  },
  {
    Id: 5746,
    Code: '3114',
    Name: 'Officer, liaison, resident'
  },
  {
    Id: 5747,
    Code: '3114',
    Name: 'Officer, technical, civil engineering'
  },
  {
    Id: 5748,
    Code: '3114',
    Name: 'Technician, building'
  },
  {
    Id: 5749,
    Code: '3114',
    Name: 'Technician, drainage'
  },
  {
    Id: 5750,
    Code: '3114',
    Name: 'Technician, engineering, civil'
  },
  {
    Id: 5751,
    Code: '3114',
    Name: 'Technician, engineering, structural'
  },
  {
    Id: 5752,
    Code: '3114',
    Name: 'Technician, highway'
  },
  {
    Id: 5753,
    Code: '3114',
    Name: 'Technician, survey'
  },
  {
    Id: 5754,
    Code: '3114',
    Name: 'Technician, surveying'
  },
  {
    Id: 5755,
    Code: '3114',
    Name: 'Technician, engineering, civil'
  },
  {
    Id: 5756,
    Code: '3114',
    Name: 'Technician, engineering, structural'
  },
  {
    Id: 5757,
    Code: '3115',
    Name: 'Analyst, quality'
  },
  {
    Id: 5758,
    Code: '3115',
    Name: 'Auditor, assurance, quality'
  },
  {
    Id: 5759,
    Code: '3115',
    Name: 'Auditor, technical'
  },
  {
    Id: 5760,
    Code: '3115',
    Name: 'Coordinator, assurance, quality'
  },
  {
    Id: 5761,
    Code: '3115',
    Name: 'Coordinator, QA'
  },
  {
    Id: 5762,
    Code: '3115',
    Name: 'Coordinator, quality'
  },
  {
    Id: 5763,
    Code: '3115',
    Name: 'Engineer, evaluation'
  },
  {
    Id: 5764,
    Code: '3115',
    Name: 'Engineer, quality'
  },
  {
    Id: 5765,
    Code: '3115',
    Name: 'Officer, assurance, quality'
  },
  {
    Id: 5766,
    Code: '3115',
    Name: 'Officer, quality'
  },
  {
    Id: 5767,
    Code: '3115',
    Name: 'Technician, assurance, quality'
  },
  {
    Id: 5768,
    Code: '3115',
    Name: 'Technician, control, quality'
  },
  {
    Id: 5769,
    Code: '3115',
    Name: 'Technician, materials'
  },
  {
    Id: 5770,
    Code: '3115',
    Name: 'Technician, NDT'
  },
  {
    Id: 5771,
    Code: '3115',
    Name: 'Technician, QA'
  },
  {
    Id: 5772,
    Code: '3115',
    Name: 'Technician, quality, water'
  },
  {
    Id: 5773,
    Code: '3115',
    Name: 'Technician, quality'
  },
  {
    Id: 5774,
    Code: '3115',
    Name: 'Technician, test'
  },
  {
    Id: 5775,
    Code: '3115',
    Name: 'Technician, testing, non-destructive'
  },
  {
    Id: 5776,
    Code: '3116',
    Name: 'Controller, production'
  },
  {
    Id: 5777,
    Code: '3116',
    Name: 'Controller, technical'
  },
  {
    Id: 5778,
    Code: '3116',
    Name: 'Coordinator, production'
  },
  {
    Id: 5779,
    Code: '3116',
    Name: 'Planner, maintenance'
  },
  {
    Id: 5780,
    Code: '3116',
    Name: 'Planner, production'
  },
  {
    Id: 5781,
    Code: '3116',
    Name: 'Technician, dyeing, textile'
  },
  {
    Id: 5782,
    Code: '3116',
    Name: 'Technician, process'
  },
  {
    Id: 5783,
    Code: '3116',
    Name: 'Technician, production'
  },
  {
    Id: 5784,
    Code: '3116',
    Name: 'Technician, scheduling'
  },
  {
    Id: 5785,
    Code: '3119',
    Name: 'Assistant, experimental'
  },
  {
    Id: 5786,
    Code: '3119',
    Name: 'Assistant, metallurgical'
  },
  {
    Id: 5787,
    Code: '3119',
    Name: 'Assistant, technical'
  },
  {
    Id: 5788,
    Code: '3119',
    Name: 'Assistant, test'
  },
  {
    Id: 5789,
    Code: '3119',
    Name: 'Consultant, technical, aviation'
  },
  {
    Id: 5790,
    Code: '3119',
    Name: 'Consultant, textile'
  },
  {
    Id: 5791,
    Code: '3119',
    Name: 'Controller, oxygen'
  },
  {
    Id: 5792,
    Code: '3119',
    Name: 'Engineer, investigating, technical'
  },
  {
    Id: 5793,
    Code: '3119',
    Name: 'Grade I Technical Class, government'
  },
  {
    Id: 5794,
    Code: '3119',
    Name: 'Grade II Technical Class, government'
  },
  {
    Id: 5795,
    Code: '3119',
    Name: 'Officer, liaison, technical'
  },
  {
    Id: 5796,
    Code: '3119',
    Name: 'Officer, technical, scientific, coal mine'
  },
  {
    Id: 5797,
    Code: '3119',
    Name: 'Officer, technical, nos'
  },
  {
    Id: 5798,
    Code: '3119',
    Name: 'Officer, technical, government'
  },
  {
    Id: 5799,
    Code: '3119',
    Name: 'Overseer, assistant, MOD'
  },
  {
    Id: 5800,
    Code: '3119',
    Name: 'Technical Class, grade I, government'
  },
  {
    Id: 5801,
    Code: '3119',
    Name: 'Technical Class, grade II, government'
  },
  {
    Id: 5802,
    Code: '3119',
    Name: 'Technical grade, government'
  },
  {
    Id: 5803,
    Code: '3119',
    Name: 'Technician, art, schools'
  },
  {
    Id: 5804,
    Code: '3119',
    Name: 'Technician, biology, schools'
  },
  {
    Id: 5805,
    Code: '3119',
    Name: 'Technician, composite'
  },
  {
    Id: 5806,
    Code: '3119',
    Name: 'Technician, design and technology'
  },
  {
    Id: 5807,
    Code: '3119',
    Name: 'Technician, development'
  },
  {
    Id: 5808,
    Code: '3119',
    Name: 'Technician, DT'
  },
  {
    Id: 5809,
    Code: '3119',
    Name: 'Technician, ICT, schools'
  },
  {
    Id: 5810,
    Code: '3119',
    Name: 'Technician, IT, schools'
  },
  {
    Id: 5811,
    Code: '3119',
    Name: 'Technician, meteorological'
  },
  {
    Id: 5812,
    Code: '3119',
    Name: 'Technician, physics'
  },
  {
    Id: 5813,
    Code: '3119',
    Name: 'Technician, school'
  },
  {
    Id: 5814,
    Code: '3119',
    Name: 'Technician, science, school'
  },
  {
    Id: 5815,
    Code: '3119',
    Name: 'Technician, science'
  },
  {
    Id: 5816,
    Code: '3119',
    Name: 'Technician, service'
  },
  {
    Id: 5817,
    Code: '3119',
    Name: 'Technician, technology, design'
  },
  {
    Id: 5818,
    Code: '3119',
    Name: 'Technician, technology'
  },
  {
    Id: 5819,
    Code: '3119',
    Name: 'Technician, workshop'
  },
  {
    Id: 5820,
    Code: '3119',
    Name: 'Technician, plastics'
  },
  {
    Id: 5821,
    Code: '3119',
    Name: 'Technician, schools'
  },
  {
    Id: 5822,
    Code: '3119',
    Name: 'Technician'
  },
  {
    Id: 5823,
    Code: '3119',
    Name: 'Worker, experimental'
  },
  {
    Id: 5824,
    Code: '3120',
    Name: 'Administrator, local government: town planning'
  },
  {
    Id: 5825,
    Code: '3120',
    Name: 'Analyst, tool'
  },
  {
    Id: 5826,
    Code: '3120',
    Name: "Assistant, architect's"
  },
  {
    Id: 5827,
    Code: '3120',
    Name: 'Assistant, architectural'
  },
  {
    Id: 5828,
    Code: '3120',
    Name: 'Assistant, cartographic'
  },
  {
    Id: 5829,
    Code: '3120',
    Name: 'Assistant, design, architectural'
  },
  {
    Id: 5830,
    Code: '3120',
    Name: 'Assistant, design, technical'
  },
  {
    Id: 5831,
    Code: '3120',
    Name: 'Assistant, design, urban'
  },
  {
    Id: 5832,
    Code: '3120',
    Name: 'Assistant, design, architectural service'
  },
  {
    Id: 5833,
    Code: '3120',
    Name: 'Assistant, design, construction'
  },
  {
    Id: 5834,
    Code: '3120',
    Name: "Assistant, draughtsman's"
  },
  {
    Id: 5835,
    Code: '3120',
    Name: 'Assistant, drawing, technical'
  },
  {
    Id: 5836,
    Code: '3120',
    Name: 'Assistant, office, drawing'
  },
  {
    Id: 5837,
    Code: '3120',
    Name: 'Assistant, planning, town'
  },
  {
    Id: 5838,
    Code: '3120',
    Name: 'Assistant, planning, local government'
  },
  {
    Id: 5839,
    Code: '3120',
    Name: 'Cartographer, digital'
  },
  {
    Id: 5840,
    Code: '3120',
    Name: 'Cartographer'
  },
  {
    Id: 5841,
    Code: '3120',
    Name: 'Cartographer-draughtsman'
  },
  {
    Id: 5842,
    Code: '3120',
    Name: 'Checker, drawing'
  },
  {
    Id: 5843,
    Code: '3120',
    Name: 'Checker, map'
  },
  {
    Id: 5844,
    Code: '3120',
    Name: 'Checker, drawing office'
  },
  {
    Id: 5845,
    Code: '3120',
    Name: 'Coordinator, CAD'
  },
  {
    Id: 5846,
    Code: '3120',
    Name: 'Corrector, chart, Trinity House'
  },
  {
    Id: 5847,
    Code: '3120',
    Name: 'Designer, aided, computer'
  },
  {
    Id: 5848,
    Code: '3120',
    Name: 'Designer, CAD'
  },
  {
    Id: 5849,
    Code: '3120',
    Name: 'Designer, chief'
  },
  {
    Id: 5850,
    Code: '3120',
    Name: 'Designer, design, aided, computer'
  },
  {
    Id: 5851,
    Code: '3120',
    Name: 'Designer, engineering'
  },
  {
    Id: 5852,
    Code: '3120',
    Name: 'Designer, mechanical'
  },
  {
    Id: 5853,
    Code: '3120',
    Name: 'Designer, piping'
  },
  {
    Id: 5854,
    Code: '3120',
    Name: 'Designer, tool'
  },
  {
    Id: 5855,
    Code: '3120',
    Name: 'Designer, construction'
  },
  {
    Id: 5856,
    Code: '3120',
    Name: 'Designer-detailer'
  },
  {
    Id: 5857,
    Code: '3120',
    Name: 'Designer-draughtsman'
  },
  {
    Id: 5858,
    Code: '3120',
    Name: 'Detailer, concrete'
  },
  {
    Id: 5859,
    Code: '3120',
    Name: 'Detailer, structural'
  },
  {
    Id: 5860,
    Code: '3120',
    Name: 'Digitiser'
  },
  {
    Id: 5861,
    Code: '3120',
    Name: 'Draughtsman, autocad'
  },
  {
    Id: 5862,
    Code: '3120',
    Name: 'Draughtsman, CAD'
  },
  {
    Id: 5863,
    Code: '3120',
    Name: 'Draughtsman, cartographic'
  },
  {
    Id: 5864,
    Code: '3120',
    Name: 'Draughtsman, cartographical'
  },
  {
    Id: 5865,
    Code: '3120',
    Name: 'Draughtsman, chief'
  },
  {
    Id: 5866,
    Code: '3120',
    Name: 'Draughtsman, design, aided, computer'
  },
  {
    Id: 5867,
    Code: '3120',
    Name: 'Draughtsman, design'
  },
  {
    Id: 5868,
    Code: '3120',
    Name: 'Draughtsman, electrical'
  },
  {
    Id: 5869,
    Code: '3120',
    Name: 'Draughtsman, engineering'
  },
  {
    Id: 5870,
    Code: '3120',
    Name: 'Draughtsman'
  },
  {
    Id: 5871,
    Code: '3120',
    Name: 'Draughtsman-engineer'
  },
  {
    Id: 5872,
    Code: '3120',
    Name: 'Draughtsman-surveyor'
  },
  {
    Id: 5873,
    Code: '3120',
    Name: 'Engineer, autocad'
  },
  {
    Id: 5874,
    Code: '3120',
    Name: 'Engineer, CAD'
  },
  {
    Id: 5875,
    Code: '3120',
    Name: 'Engineer, control, weight'
  },
  {
    Id: 5876,
    Code: '3120',
    Name: 'Engineer, design, aided, computer'
  },
  {
    Id: 5877,
    Code: '3120',
    Name: 'Engineer, design, assisted, computer'
  },
  {
    Id: 5878,
    Code: '3120',
    Name: 'Engineer, design, CAD'
  },
  {
    Id: 5879,
    Code: '3120',
    Name: 'Engineer, stress'
  },
  {
    Id: 5880,
    Code: '3120',
    Name: 'Engineer-draughtsman'
  },
  {
    Id: 5881,
    Code: '3120',
    Name: 'Estimator-draughtsman'
  },
  {
    Id: 5882,
    Code: '3120',
    Name: 'Examiner, boundary, Ordnance Survey'
  },
  {
    Id: 5883,
    Code: '3120',
    Name: 'Examiner, map, Ordnance Survey'
  },
  {
    Id: 5884,
    Code: '3120',
    Name: 'Leader, section, drawing office'
  },
  {
    Id: 5885,
    Code: '3120',
    Name: 'Loftsman, engineering'
  },
  {
    Id: 5886,
    Code: '3120',
    Name: 'Manager, design, construction'
  },
  {
    Id: 5887,
    Code: '3120',
    Name: 'Manager, office, drawing'
  },
  {
    Id: 5888,
    Code: '3120',
    Name: 'Officer, mapping and charting'
  },
  {
    Id: 5889,
    Code: '3120',
    Name: 'Operator, CAD'
  },
  {
    Id: 5890,
    Code: '3120',
    Name: 'Painter, design'
  },
  {
    Id: 5891,
    Code: '3120',
    Name: 'Photogrammetrist'
  },
  {
    Id: 5892,
    Code: '3120',
    Name: 'Planner, space'
  },
  {
    Id: 5893,
    Code: '3120',
    Name: 'Reviser, field, Ordnance Survey'
  },
  {
    Id: 5894,
    Code: '3120',
    Name: 'Sketcher, design'
  },
  {
    Id: 5895,
    Code: '3120',
    Name: 'Technician, architectural'
  },
  {
    Id: 5896,
    Code: '3120',
    Name: 'Technician, autocad'
  },
  {
    Id: 5897,
    Code: '3120',
    Name: 'Technician, BIM'
  },
  {
    Id: 5898,
    Code: '3120',
    Name: 'Technician, CAD'
  },
  {
    Id: 5899,
    Code: '3120',
    Name: 'Technician, cartographic'
  },
  {
    Id: 5900,
    Code: '3120',
    Name: 'Technician, design'
  },
  {
    Id: 5901,
    Code: '3120',
    Name: 'Technician, draughting'
  },
  {
    Id: 5902,
    Code: '3120',
    Name: 'Technician, printing, 3D'
  },
  {
    Id: 5903,
    Code: '3120',
    Name: 'Tracer'
  },
  {
    Id: 5904,
    Code: '3120',
    Name: 'Weightsman'
  },
  {
    Id: 5905,
    Code: '3131',
    Name: 'Administrator, computer'
  },
  {
    Id: 5906,
    Code: '3131',
    Name: 'Administrator, digital'
  },
  {
    Id: 5907,
    Code: '3131',
    Name: 'Administrator, IT'
  },
  {
    Id: 5908,
    Code: '3131',
    Name: 'Administrator, network'
  },
  {
    Id: 5909,
    Code: '3131',
    Name: 'Administrator, system'
  },
  {
    Id: 5910,
    Code: '3131',
    Name: 'Administrator, systems, IT'
  },
  {
    Id: 5911,
    Code: '3131',
    Name: 'Administrator, systems, unix'
  },
  {
    Id: 5912,
    Code: '3131',
    Name: 'Administrator, systems'
  },
  {
    Id: 5913,
    Code: '3131',
    Name: 'Analyst, maintenance, computing'
  },
  {
    Id: 5914,
    Code: '3131',
    Name: 'Assistant, IT'
  },
  {
    Id: 5915,
    Code: '3131',
    Name: 'Auditor, computer, technical'
  },
  {
    Id: 5916,
    Code: '3131',
    Name: 'Controller, computer'
  },
  {
    Id: 5917,
    Code: '3131',
    Name: 'Controller, IT'
  },
  {
    Id: 5918,
    Code: '3131',
    Name: 'Controller, network'
  },
  {
    Id: 5919,
    Code: '3131',
    Name: 'Controller, operations, computer'
  },
  {
    Id: 5920,
    Code: '3131',
    Name: 'Controller, systems, computing'
  },
  {
    Id: 5921,
    Code: '3131',
    Name: 'Controller, telecommunications'
  },
  {
    Id: 5922,
    Code: '3131',
    Name: 'Controller, computing'
  },
  {
    Id: 5923,
    Code: '3131',
    Name: 'Coordinator, computer'
  },
  {
    Id: 5924,
    Code: '3131',
    Name: 'Coordinator, IT'
  },
  {
    Id: 5925,
    Code: '3131',
    Name: 'Coordinator, project, computer'
  },
  {
    Id: 5926,
    Code: '3131',
    Name: 'Coordinator, project, IT'
  },
  {
    Id: 5927,
    Code: '3131',
    Name: 'Coordinator, project, software'
  },
  {
    Id: 5928,
    Code: '3131',
    Name: 'Coordinator, project, computing'
  },
  {
    Id: 5929,
    Code: '3131',
    Name: 'Coordinator, systems, information'
  },
  {
    Id: 5930,
    Code: '3131',
    Name: 'Coordinator, systems, computing'
  },
  {
    Id: 5931,
    Code: '3131',
    Name: 'Coordinator, technology, information'
  },
  {
    Id: 5932,
    Code: '3131',
    Name: 'Executive, systems, computing'
  },
  {
    Id: 5933,
    Code: '3131',
    Name: 'Leader, team, operations, computer'
  },
  {
    Id: 5934,
    Code: '3131',
    Name: 'Leader, team, operations, network'
  },
  {
    Id: 5935,
    Code: '3131',
    Name: 'Leader, team, computer operations'
  },
  {
    Id: 5936,
    Code: '3131',
    Name: 'Leader, computing'
  },
  {
    Id: 5937,
    Code: '3131',
    Name: 'Manager, configuration, computing'
  },
  {
    Id: 5938,
    Code: '3131',
    Name: 'Networker, computing'
  },
  {
    Id: 5939,
    Code: '3131',
    Name: 'Officer, network, computer services'
  },
  {
    Id: 5940,
    Code: '3131',
    Name: 'Officer, system, information'
  },
  {
    Id: 5941,
    Code: '3131',
    Name: 'Officer, system'
  },
  {
    Id: 5942,
    Code: '3131',
    Name: 'Officer, systems, information'
  },
  {
    Id: 5943,
    Code: '3131',
    Name: 'Officer, systems'
  },
  {
    Id: 5944,
    Code: '3131',
    Name: 'Officer, technical, IT'
  },
  {
    Id: 5945,
    Code: '3131',
    Name: 'Operator, centre, data'
  },
  {
    Id: 5946,
    Code: '3131',
    Name: 'Specialist, IT'
  },
  {
    Id: 5947,
    Code: '3131',
    Name: 'Specialist, networking, data'
  },
  {
    Id: 5948,
    Code: '3131',
    Name: 'Specialist, technology, information'
  },
  {
    Id: 5949,
    Code: '3131',
    Name: 'Technician, centre, data'
  },
  {
    Id: 5950,
    Code: '3131',
    Name: 'Technician, computer'
  },
  {
    Id: 5951,
    Code: '3131',
    Name: 'Technician, computing'
  },
  {
    Id: 5952,
    Code: '3131',
    Name: 'Technician, ICT'
  },
  {
    Id: 5953,
    Code: '3131',
    Name: 'Technician, IS'
  },
  {
    Id: 5954,
    Code: '3131',
    Name: 'Technician, IT'
  },
  {
    Id: 5955,
    Code: '3131',
    Name: 'Technician, network'
  },
  {
    Id: 5956,
    Code: '3131',
    Name: 'Technician, software'
  },
  {
    Id: 5957,
    Code: '3131',
    Name: 'Technician, systems, information'
  },
  {
    Id: 5958,
    Code: '3131',
    Name: 'Technician, systems'
  },
  {
    Id: 5959,
    Code: '3131',
    Name: 'Tester, assurance, quality, computing'
  },
  {
    Id: 5960,
    Code: '3131',
    Name: 'Tester, games, computer'
  },
  {
    Id: 5961,
    Code: '3131',
    Name: 'Tester, QA, computing'
  },
  {
    Id: 5962,
    Code: '3132',
    Name: 'Administrator, desk, help'
  },
  {
    Id: 5963,
    Code: '3132',
    Name: 'Administrator, helpdesk'
  },
  {
    Id: 5964,
    Code: '3132',
    Name: 'Adviser, technical, computing'
  },
  {
    Id: 5965,
    Code: '3132',
    Name: 'Agent, delivery, service, computing'
  },
  {
    Id: 5966,
    Code: '3132',
    Name: 'Agent, intelligence, counter, telecoms, retail trade'
  },
  {
    Id: 5967,
    Code: '3132',
    Name: 'Analyst, desk, service, IT'
  },
  {
    Id: 5968,
    Code: '3132',
    Name: 'Analyst, desk, service'
  },
  {
    Id: 5969,
    Code: '3132',
    Name: 'Analyst, helpdesk'
  },
  {
    Id: 5970,
    Code: '3132',
    Name: 'Analyst, support, application'
  },
  {
    Id: 5971,
    Code: '3132',
    Name: 'Analyst, support, applications'
  },
  {
    Id: 5972,
    Code: '3132',
    Name: 'Analyst, support, business, computing'
  },
  {
    Id: 5973,
    Code: '3132',
    Name: 'Analyst, support, customer'
  },
  {
    Id: 5974,
    Code: '3132',
    Name: 'Analyst, support, desktop'
  },
  {
    Id: 5975,
    Code: '3132',
    Name: 'Analyst, support, IT'
  },
  {
    Id: 5976,
    Code: '3132',
    Name: 'Analyst, support, PC'
  },
  {
    Id: 5977,
    Code: '3132',
    Name: 'Analyst, support, software'
  },
  {
    Id: 5978,
    Code: '3132',
    Name: 'Analyst, support, systems'
  },
  {
    Id: 5979,
    Code: '3132',
    Name: 'Analyst, support, technical'
  },
  {
    Id: 5980,
    Code: '3132',
    Name: 'Analyst, support, computing'
  },
  {
    Id: 5981,
    Code: '3132',
    Name: 'Assistant, helpdesk, IT'
  },
  {
    Id: 5982,
    Code: '3132',
    Name: 'Assistant, helpdesk'
  },
  {
    Id: 5983,
    Code: '3132',
    Name: 'Assistant, support, computer'
  },
  {
    Id: 5984,
    Code: '3132',
    Name: 'Assistant, support, IT'
  },
  {
    Id: 5985,
    Code: '3132',
    Name: 'Assistant, technical, computing'
  },
  {
    Id: 5986,
    Code: '3132',
    Name: 'Associate, IT'
  },
  {
    Id: 5987,
    Code: '3132',
    Name: 'Associate, services, client, computing'
  },
  {
    Id: 5988,
    Code: '3132',
    Name: 'Consultant, support, computing'
  },
  {
    Id: 5989,
    Code: '3132',
    Name: 'Controller, services, data'
  },
  {
    Id: 5990,
    Code: '3132',
    Name: 'Engineer, desk, service, IT'
  },
  {
    Id: 5991,
    Code: '3132',
    Name: 'Engineer, desktop'
  },
  {
    Id: 5992,
    Code: '3132',
    Name: 'Engineer, support, application'
  },
  {
    Id: 5993,
    Code: '3132',
    Name: 'Engineer, support, desktop'
  },
  {
    Id: 5994,
    Code: '3132',
    Name: 'Engineer, support, IT'
  },
  {
    Id: 5995,
    Code: '3132',
    Name: 'Engineer, support, technical, computing'
  },
  {
    Id: 5996,
    Code: '3132',
    Name: 'Engineer, support, computer'
  },
  {
    Id: 5997,
    Code: '3132',
    Name: 'Engineer, technical, computing'
  },
  {
    Id: 5998,
    Code: '3132',
    Name: 'Executive, support, computing'
  },
  {
    Id: 5999,
    Code: '3132',
    Name: 'Genius, computing, retail trade'
  },
  {
    Id: 6000,
    Code: '3132',
    Name: 'Guru, telecoms, retail trade'
  },
  {
    Id: 6001,
    Code: '3132',
    Name: 'Leader, team, desk, help, computing'
  },
  {
    Id: 6002,
    Code: '3132',
    Name: 'Leader, team, IT'
  },
  {
    Id: 6003,
    Code: '3132',
    Name: 'Leader, team, technology, information'
  },
  {
    Id: 6004,
    Code: '3132',
    Name: 'Officer, computer'
  },
  {
    Id: 6005,
    Code: '3132',
    Name: 'Officer, computing'
  },
  {
    Id: 6006,
    Code: '3132',
    Name: 'Officer, IT'
  },
  {
    Id: 6007,
    Code: '3132',
    Name: 'Officer, support, helpline, IT'
  },
  {
    Id: 6008,
    Code: '3132',
    Name: 'Officer, support, IT'
  },
  {
    Id: 6009,
    Code: '3132',
    Name: 'Officer, support, system'
  },
  {
    Id: 6010,
    Code: '3132',
    Name: 'Officer, support, systems'
  },
  {
    Id: 6011,
    Code: '3132',
    Name: 'Officer, support, technical'
  },
  {
    Id: 6012,
    Code: '3132',
    Name: 'Officer, support, computing'
  },
  {
    Id: 6013,
    Code: '3132',
    Name: 'Operator, desk, help, computing'
  },
  {
    Id: 6014,
    Code: '3132',
    Name: 'Operator, helpdesk, computing'
  },
  {
    Id: 6015,
    Code: '3132',
    Name: 'Operator, helpline, computing'
  },
  {
    Id: 6016,
    Code: '3132',
    Name: 'Operator, support, IT'
  },
  {
    Id: 6017,
    Code: '3132',
    Name: 'Reviewer, app'
  },
  {
    Id: 6018,
    Code: '3132',
    Name: 'Specialist, support, application'
  },
  {
    Id: 6019,
    Code: '3132',
    Name: 'Specialist, support, IT'
  },
  {
    Id: 6020,
    Code: '3132',
    Name: 'Specialist, support, system'
  },
  {
    Id: 6021,
    Code: '3132',
    Name: 'Specialist, support, technical, computing'
  },
  {
    Id: 6022,
    Code: '3132',
    Name: 'Specialist, support, computing'
  },
  {
    Id: 6023,
    Code: '3132',
    Name: 'Supervisor, desk, help'
  },
  {
    Id: 6024,
    Code: '3132',
    Name: 'Supervisor, helpdesk, IT'
  },
  {
    Id: 6025,
    Code: '3132',
    Name: 'Supervisor, IT'
  },
  {
    Id: 6026,
    Code: '3132',
    Name: 'Supervisor, network, computing'
  },
  {
    Id: 6027,
    Code: '3132',
    Name: 'Supervisor, support, technical'
  },
  {
    Id: 6028,
    Code: '3132',
    Name: 'Supervisor, technology, information'
  },
  {
    Id: 6029,
    Code: '3132',
    Name: 'Technician, support, computer'
  },
  {
    Id: 6030,
    Code: '3132',
    Name: 'Technician, support, IT'
  },
  {
    Id: 6031,
    Code: '3132',
    Name: 'Technician, support, computing'
  },
  {
    Id: 6032,
    Code: '3132',
    Name: 'Technician, support, computer services'
  },
  {
    Id: 6033,
    Code: '3132',
    Name: 'Technologist, learning, IT support'
  },
  {
    Id: 6034,
    Code: '3132',
    Name: 'Worker, desk, help, computer'
  },
  {
    Id: 6035,
    Code: '3133',
    Name: 'Administrator, database'
  },
  {
    Id: 6036,
    Code: '3133',
    Name: 'Administrator, Hadoop'
  },
  {
    Id: 6037,
    Code: '3133',
    Name: 'Administrator, internet'
  },
  {
    Id: 6038,
    Code: '3133',
    Name: 'Administrator, system, Hadoop'
  },
  {
    Id: 6039,
    Code: '3133',
    Name: 'Administrator, systems, Hadoop'
  },
  {
    Id: 6040,
    Code: '3133',
    Name: 'Administrator, website'
  },
  {
    Id: 6041,
    Code: '3133',
    Name: 'Analyst, content'
  },
  {
    Id: 6042,
    Code: '3133',
    Name: 'Assistant, content, digital'
  },
  {
    Id: 6043,
    Code: '3133',
    Name: 'Assistant, content, e-commerce'
  },
  {
    Id: 6044,
    Code: '3133',
    Name: 'Assistant, content, web'
  },
  {
    Id: 6045,
    Code: '3133',
    Name: 'Assistant, management, content'
  },
  {
    Id: 6046,
    Code: '3133',
    Name: 'Assistant, production, online'
  },
  {
    Id: 6047,
    Code: '3133',
    Name: 'Assistant, website'
  },
  {
    Id: 6048,
    Code: '3133',
    Name: 'Coordinator, content'
  },
  {
    Id: 6049,
    Code: '3133',
    Name: 'Coordinator, database'
  },
  {
    Id: 6050,
    Code: '3133',
    Name: 'Coordinator, intranet'
  },
  {
    Id: 6051,
    Code: '3133',
    Name: 'Creator, content'
  },
  {
    Id: 6052,
    Code: '3133',
    Name: 'DBA'
  },
  {
    Id: 6053,
    Code: '3133',
    Name: 'Designer, content, digital'
  },
  {
    Id: 6054,
    Code: '3133',
    Name: 'Editor, content, digital'
  },
  {
    Id: 6055,
    Code: '3133',
    Name: 'Editor, content, online'
  },
  {
    Id: 6056,
    Code: '3133',
    Name: 'Editor, content, web'
  },
  {
    Id: 6057,
    Code: '3133',
    Name: 'Editor, content, website'
  },
  {
    Id: 6058,
    Code: '3133',
    Name: 'Editor, web'
  },
  {
    Id: 6059,
    Code: '3133',
    Name: 'Executive, content'
  },
  {
    Id: 6060,
    Code: '3133',
    Name: 'Manager, base, data'
  },
  {
    Id: 6061,
    Code: '3133',
    Name: 'Manager, content, digital'
  },
  {
    Id: 6062,
    Code: '3133',
    Name: 'Manager, content, web'
  },
  {
    Id: 6063,
    Code: '3133',
    Name: 'Manager, content, website'
  },
  {
    Id: 6064,
    Code: '3133',
    Name: 'Manager, data, clinical'
  },
  {
    Id: 6065,
    Code: '3133',
    Name: 'Manager, data'
  },
  {
    Id: 6066,
    Code: '3133',
    Name: 'Manager, database'
  },
  {
    Id: 6067,
    Code: '3133',
    Name: 'Merchandiser, online'
  },
  {
    Id: 6068,
    Code: '3133',
    Name: 'Moderator, chat'
  },
  {
    Id: 6069,
    Code: '3133',
    Name: 'Moderator, content, online'
  },
  {
    Id: 6070,
    Code: '3133',
    Name: 'Moderator, web'
  },
  {
    Id: 6071,
    Code: '3133',
    Name: 'Moderator, website'
  },
  {
    Id: 6072,
    Code: '3133',
    Name: 'Moderator'
  },
  {
    Id: 6073,
    Code: '3133',
    Name: 'Officer, content'
  },
  {
    Id: 6074,
    Code: '3133',
    Name: 'Officer, support, web'
  },
  {
    Id: 6075,
    Code: '3133',
    Name: 'Operator, ingest'
  },
  {
    Id: 6076,
    Code: '3133',
    Name: 'Producer, content, digital'
  },
  {
    Id: 6077,
    Code: '3133',
    Name: 'Producer, content, digital'
  },
  {
    Id: 6078,
    Code: '3133',
    Name: 'Supervisor, web'
  },
  {
    Id: 6079,
    Code: '3133',
    Name: 'Writer, content, digital'
  },
  {
    Id: 6080,
    Code: '3211',
    Name: 'Dispenser, optical'
  },
  {
    Id: 6081,
    Code: '3211',
    Name: 'Dispenser, opticians'
  },
  {
    Id: 6082,
    Code: '3211',
    Name: 'Fitter, lens, contact'
  },
  {
    Id: 6083,
    Code: '3211',
    Name: 'Optician, dispensing'
  },
  {
    Id: 6084,
    Code: '3211',
    Name: 'Optician, lens, contact'
  },
  {
    Id: 6085,
    Code: '3211',
    Name: 'Technician, ophthalmic'
  },
  {
    Id: 6086,
    Code: '3212',
    Name: 'Technician, checking, accredited'
  },
  {
    Id: 6087,
    Code: '3212',
    Name: 'Technician, checking, accuracy'
  },
  {
    Id: 6088,
    Code: '3212',
    Name: 'Technician, checking, pharmacy'
  },
  {
    Id: 6089,
    Code: '3212',
    Name: 'Technician, dispensing'
  },
  {
    Id: 6090,
    Code: '3212',
    Name: 'Technician, management, medicines'
  },
  {
    Id: 6091,
    Code: '3212',
    Name: 'Technician, pharmaceutical'
  },
  {
    Id: 6092,
    Code: '3212',
    Name: 'Technician, pharmacy, checking, accuracy'
  },
  {
    Id: 6093,
    Code: '3212',
    Name: 'Technician, pharmacy'
  },
  {
    Id: 6094,
    Code: '3212',
    Name: 'Technician, pharmaceutical mfr'
  },
  {
    Id: 6095,
    Code: '3213',
    Name: 'Assistant, laboratory, dental'
  },
  {
    Id: 6096,
    Code: '3213',
    Name: 'Associate, nurse'
  },
  {
    Id: 6097,
    Code: '3213',
    Name: 'Associate, nursing'
  },
  {
    Id: 6098,
    Code: '3213',
    Name: 'Audiometrician'
  },
  {
    Id: 6099,
    Code: '3213',
    Name: 'Cardiographer'
  },
  {
    Id: 6100,
    Code: '3213',
    Name: 'Ceramist, dental'
  },
  {
    Id: 6101,
    Code: '3213',
    Name: 'Consultant, aid, hearing'
  },
  {
    Id: 6102,
    Code: '3213',
    Name: 'Coordinator, implantology'
  },
  {
    Id: 6103,
    Code: '3213',
    Name: 'Curator, instrument'
  },
  {
    Id: 6104,
    Code: '3213',
    Name: 'Dispenser, aid, hearing'
  },
  {
    Id: 6105,
    Code: '3213',
    Name: 'Educator, health, dental'
  },
  {
    Id: 6106,
    Code: '3213',
    Name: 'Electro-encephalographer'
  },
  {
    Id: 6107,
    Code: '3213',
    Name: 'Engineer, dental'
  },
  {
    Id: 6108,
    Code: '3213',
    Name: 'Engineer, medical'
  },
  {
    Id: 6109,
    Code: '3213',
    Name: 'Engineer, rehabilitation'
  },
  {
    Id: 6110,
    Code: '3213',
    Name: 'Fitter, appliance, surgical'
  },
  {
    Id: 6111,
    Code: '3213',
    Name: 'Fitter, dental'
  },
  {
    Id: 6112,
    Code: '3213',
    Name: 'Fitter, limb'
  },
  {
    Id: 6113,
    Code: '3213',
    Name: 'Foreman, denture mfr'
  },
  {
    Id: 6114,
    Code: '3213',
    Name: 'Hygienist, dental'
  },
  {
    Id: 6115,
    Code: '3213',
    Name: 'Hygienist, oral'
  },
  {
    Id: 6116,
    Code: '3213',
    Name: 'Hygienist, dental practice'
  },
  {
    Id: 6117,
    Code: '3213',
    Name: 'Maker, denture'
  },
  {
    Id: 6118,
    Code: '3213',
    Name: 'Mechanic, dental'
  },
  {
    Id: 6119,
    Code: '3213',
    Name: "Mechanic, dentist's"
  },
  {
    Id: 6120,
    Code: '3213',
    Name: 'Ocularist'
  },
  {
    Id: 6121,
    Code: '3213',
    Name: 'Officer, appliance'
  },
  {
    Id: 6122,
    Code: '3213',
    Name: 'Officer, technical, medical'
  },
  {
    Id: 6123,
    Code: '3213',
    Name: 'Officer, technical, medical'
  },
  {
    Id: 6124,
    Code: '3213',
    Name: 'Physiologist, cardiac'
  },
  {
    Id: 6125,
    Code: '3213',
    Name: 'Physiologist, gastroenterology'
  },
  {
    Id: 6126,
    Code: '3213',
    Name: 'Practitioner, assistant, radiography'
  },
  {
    Id: 6127,
    Code: '3213',
    Name: 'Practitioner, assistant, radiology'
  },
  {
    Id: 6128,
    Code: '3213',
    Name: 'Practitioner, orthopaedic'
  },
  {
    Id: 6129,
    Code: '3213',
    Name: 'Radiographer, dental'
  },
  {
    Id: 6130,
    Code: '3213',
    Name: 'Recordist, electroencephalographic'
  },
  {
    Id: 6131,
    Code: '3213',
    Name: 'Screener, hearing, newborn'
  },
  {
    Id: 6132,
    Code: '3213',
    Name: 'Screener, retinal'
  },
  {
    Id: 6133,
    Code: '3213',
    Name: 'Technician, aid, hearing'
  },
  {
    Id: 6134,
    Code: '3213',
    Name: 'Technician, audiologist'
  },
  {
    Id: 6135,
    Code: '3213',
    Name: 'Technician, cardiac'
  },
  {
    Id: 6136,
    Code: '3213',
    Name: 'Technician, cardiological'
  },
  {
    Id: 6137,
    Code: '3213',
    Name: 'Technician, cephalographic'
  },
  {
    Id: 6138,
    Code: '3213',
    Name: 'Technician, clinical'
  },
  {
    Id: 6139,
    Code: '3213',
    Name: 'Technician, dental'
  },
  {
    Id: 6140,
    Code: '3213',
    Name: 'Technician, ECG'
  },
  {
    Id: 6141,
    Code: '3213',
    Name: 'Technician, EEG'
  },
  {
    Id: 6142,
    Code: '3213',
    Name: 'Technician, endoscopy'
  },
  {
    Id: 6143,
    Code: '3213',
    Name: 'Technician, gastroenterology'
  },
  {
    Id: 6144,
    Code: '3213',
    Name: 'Technician, hospital, audiology'
  },
  {
    Id: 6145,
    Code: '3213',
    Name: 'Technician, hospital, cardiography'
  },
  {
    Id: 6146,
    Code: '3213',
    Name: 'Technician, hospital, encephalography'
  },
  {
    Id: 6147,
    Code: '3213',
    Name: 'Technician, limb, artificial'
  },
  {
    Id: 6148,
    Code: '3213',
    Name: 'Technician, maxillofacial'
  },
  {
    Id: 6149,
    Code: '3213',
    Name: 'Technician, medical'
  },
  {
    Id: 6150,
    Code: '3213',
    Name: 'Technician, orthodontic'
  },
  {
    Id: 6151,
    Code: '3213',
    Name: 'Technician, orthopaedic'
  },
  {
    Id: 6152,
    Code: '3213',
    Name: 'Technician, orthotic'
  },
  {
    Id: 6153,
    Code: '3213',
    Name: 'Technician, pathology, anatomical'
  },
  {
    Id: 6154,
    Code: '3213',
    Name: 'Technician, pathology'
  },
  {
    Id: 6155,
    Code: '3213',
    Name: 'Technician, physics, medical'
  },
  {
    Id: 6156,
    Code: '3213',
    Name: 'Technician, plaster, hospital service'
  },
  {
    Id: 6157,
    Code: '3213',
    Name: 'Technician, prosthetic'
  },
  {
    Id: 6158,
    Code: '3213',
    Name: 'Technician, support, clinical'
  },
  {
    Id: 6159,
    Code: '3213',
    Name: 'Technician, support, life, hospital service'
  },
  {
    Id: 6160,
    Code: '3213',
    Name: 'Technician, surgical'
  },
  {
    Id: 6161,
    Code: '3213',
    Name: 'Technician, theatre, hospital'
  },
  {
    Id: 6162,
    Code: '3213',
    Name: 'Technician, theatre, operating'
  },
  {
    Id: 6163,
    Code: '3213',
    Name: 'Technician, theatre, hospital service'
  },
  {
    Id: 6164,
    Code: '3213',
    Name: 'Technician, trauma'
  },
  {
    Id: 6165,
    Code: '3213',
    Name: 'Technician, dental appliances'
  },
  {
    Id: 6166,
    Code: '3213',
    Name: 'Technician, laboratory, dental'
  },
  {
    Id: 6167,
    Code: '3213',
    Name: 'Technician, medical'
  },
  {
    Id: 6168,
    Code: '3213',
    Name: 'Technician, surgical appliances'
  },
  {
    Id: 6169,
    Code: '3213',
    Name: 'Technician, dental practice'
  },
  {
    Id: 6170,
    Code: '3213',
    Name: 'Technologist, dental'
  },
  {
    Id: 6171,
    Code: '3213',
    Name: 'Therapist, dental'
  },
  {
    Id: 6172,
    Code: '3213',
    Name: 'Therapist, hearing'
  },
  {
    Id: 6173,
    Code: '3213',
    Name: 'Therapist, orthodontic'
  },
  {
    Id: 6174,
    Code: '3213',
    Name: 'Trichologist'
  },
  {
    Id: 6175,
    Code: '3214',
    Name: 'Aromatherapist'
  },
  {
    Id: 6176,
    Code: '3214',
    Name: 'Consultant, ayurvedic'
  },
  {
    Id: 6177,
    Code: '3214',
    Name: 'Healer, intuitive'
  },
  {
    Id: 6178,
    Code: '3214',
    Name: 'Healer, reiki'
  },
  {
    Id: 6179,
    Code: '3214',
    Name: 'Healer, spiritual'
  },
  {
    Id: 6180,
    Code: '3214',
    Name: 'Healer'
  },
  {
    Id: 6181,
    Code: '3214',
    Name: 'Herbalist'
  },
  {
    Id: 6182,
    Code: '3214',
    Name: 'Homeopath'
  },
  {
    Id: 6183,
    Code: '3214',
    Name: 'Homoeopath'
  },
  {
    Id: 6184,
    Code: '3214',
    Name: 'Hydrotherapist'
  },
  {
    Id: 6185,
    Code: '3214',
    Name: 'Hypnotherapist'
  },
  {
    Id: 6186,
    Code: '3214',
    Name: 'Kinesiologist'
  },
  {
    Id: 6187,
    Code: '3214',
    Name: 'Masseur, ayurvedic'
  },
  {
    Id: 6188,
    Code: '3214',
    Name: 'Masseur'
  },
  {
    Id: 6189,
    Code: '3214',
    Name: 'Masseuse'
  },
  {
    Id: 6190,
    Code: '3214',
    Name: 'Master, reiki'
  },
  {
    Id: 6191,
    Code: '3214',
    Name: 'Naturopath'
  },
  {
    Id: 6192,
    Code: '3214',
    Name: 'Practitioner, ayurvedic'
  },
  {
    Id: 6193,
    Code: '3214',
    Name: 'Practitioner, health, complementary'
  },
  {
    Id: 6194,
    Code: '3214',
    Name: 'Practitioner, homeopathic'
  },
  {
    Id: 6195,
    Code: '3214',
    Name: 'Practitioner, homoeopathic'
  },
  {
    Id: 6196,
    Code: '3214',
    Name: 'Practitioner, hydropathic'
  },
  {
    Id: 6197,
    Code: '3214',
    Name: 'Practitioner, massage'
  },
  {
    Id: 6198,
    Code: '3214',
    Name: 'Practitioner, medicine, complementary'
  },
  {
    Id: 6199,
    Code: '3214',
    Name: 'Practitioner, radionic'
  },
  {
    Id: 6200,
    Code: '3214',
    Name: 'Practitioner, reiki'
  },
  {
    Id: 6201,
    Code: '3214',
    Name: 'Practitioner, shiatsu'
  },
  {
    Id: 6202,
    Code: '3214',
    Name: 'Practitioner, technique, alexander'
  },
  {
    Id: 6203,
    Code: '3214',
    Name: 'Reflexologist'
  },
  {
    Id: 6204,
    Code: '3214',
    Name: 'Specialist, play, hospital'
  },
  {
    Id: 6205,
    Code: '3214',
    Name: 'Teacher, technique, Alexander'
  },
  {
    Id: 6206,
    Code: '3214',
    Name: 'Therapist, ayurvedic'
  },
  {
    Id: 6207,
    Code: '3214',
    Name: 'Therapist, Bowen'
  },
  {
    Id: 6208,
    Code: '3214',
    Name: 'Therapist, colour'
  },
  {
    Id: 6209,
    Code: '3214',
    Name: 'Therapist, complementary'
  },
  {
    Id: 6210,
    Code: '3214',
    Name: 'Therapist, diversional'
  },
  {
    Id: 6211,
    Code: '3214',
    Name: 'Therapist, holistic'
  },
  {
    Id: 6212,
    Code: '3214',
    Name: 'Therapist, horticultural'
  },
  {
    Id: 6213,
    Code: '3214',
    Name: 'Therapist, massage, ayurvedic'
  },
  {
    Id: 6214,
    Code: '3214',
    Name: 'Therapist, massage, sports'
  },
  {
    Id: 6215,
    Code: '3214',
    Name: 'Therapist, massage'
  },
  {
    Id: 6216,
    Code: '3214',
    Name: 'Therapist, reiki'
  },
  {
    Id: 6217,
    Code: '3219',
    Name: 'Adviser, diet'
  },
  {
    Id: 6218,
    Code: '3219',
    Name: 'Assistant, dietetic'
  },
  {
    Id: 6219,
    Code: '3219',
    Name: 'Dentist, equine'
  },
  {
    Id: 6220,
    Code: '3219',
    Name: 'Economist, home'
  },
  {
    Id: 6221,
    Code: '3219',
    Name: 'Orthotist, equine'
  },
  {
    Id: 6222,
    Code: '3219',
    Name: 'Practitioner, assistant, health services'
  },
  {
    Id: 6223,
    Code: '3219',
    Name: 'Practitioner, health, foot'
  },
  {
    Id: 6224,
    Code: '3219',
    Name: 'Professional, health, foot'
  },
  {
    Id: 6225,
    Code: '3219',
    Name: 'Teacher, antenatal'
  },
  {
    Id: 6226,
    Code: '3219',
    Name: 'Technician, dental, equine'
  },
  {
    Id: 6227,
    Code: '3219',
    Name: 'Therapist, social'
  },
  {
    Id: 6228,
    Code: '3219',
    Name: 'Therapist'
  },
  {
    Id: 6229,
    Code: '3219',
    Name: 'Trainer, health, senior'
  },
  {
    Id: 6230,
    Code: '3219',
    Name: 'Trainer, health'
  },
  {
    Id: 6231,
    Code: '3221',
    Name: 'Assessor, care, community'
  },
  {
    Id: 6232,
    Code: '3221',
    Name: 'Assessor, community care'
  },
  {
    Id: 6233,
    Code: '3221',
    Name: 'Assistant, justice, youth'
  },
  {
    Id: 6234,
    Code: '3221',
    Name: 'Builder, community, community engagement'
  },
  {
    Id: 6235,
    Code: '3221',
    Name: 'Coordinator, assessor, community care'
  },
  {
    Id: 6236,
    Code: '3221',
    Name: 'Coordinator, education and community'
  },
  {
    Id: 6237,
    Code: '3221',
    Name: 'Coordinator, project, community'
  },
  {
    Id: 6238,
    Code: '3221',
    Name: 'Coordinator, project, youth'
  },
  {
    Id: 6239,
    Code: '3221',
    Name: 'Coordinator, project, community, youth work'
  },
  {
    Id: 6240,
    Code: '3221',
    Name: 'Coordinator, projects, community, youth work'
  },
  {
    Id: 6241,
    Code: '3221',
    Name: 'Coordinator, schools, extended, educational establishments'
  },
  {
    Id: 6242,
    Code: '3221',
    Name: 'Coordinator, services, extended, educational establishments'
  },
  {
    Id: 6243,
    Code: '3221',
    Name: 'Coordinator, support, family'
  },
  {
    Id: 6244,
    Code: '3221',
    Name: 'Leader, club'
  },
  {
    Id: 6245,
    Code: '3221',
    Name: 'Leader, team, district, local government: youth services'
  },
  {
    Id: 6246,
    Code: '3221',
    Name: 'Leader, youth'
  },
  {
    Id: 6247,
    Code: '3221',
    Name: 'Manager, service, youth'
  },
  {
    Id: 6248,
    Code: '3221',
    Name: 'Manager, team, community care'
  },
  {
    Id: 6249,
    Code: '3221',
    Name: 'Officer, behaviour, anti-social'
  },
  {
    Id: 6250,
    Code: '3221',
    Name: 'Officer, community'
  },
  {
    Id: 6251,
    Code: '3221',
    Name: 'Officer, development, community'
  },
  {
    Id: 6252,
    Code: '3221',
    Name: 'Officer, engagement, community'
  },
  {
    Id: 6253,
    Code: '3221',
    Name: 'Officer, inclusion, social'
  },
  {
    Id: 6254,
    Code: '3221',
    Name: 'Officer, involvement, community'
  },
  {
    Id: 6255,
    Code: '3221',
    Name: 'Officer, neighbourhood'
  },
  {
    Id: 6256,
    Code: '3221',
    Name: 'Officer, relations, community'
  },
  {
    Id: 6257,
    Code: '3221',
    Name: 'Officer, safety, community'
  },
  {
    Id: 6258,
    Code: '3221',
    Name: 'Officer, services, neighbourhood'
  },
  {
    Id: 6259,
    Code: '3221',
    Name: 'Officer, support, family'
  },
  {
    Id: 6260,
    Code: '3221',
    Name: 'Officer, youth'
  },
  {
    Id: 6261,
    Code: '3221',
    Name: 'Organiser, youth'
  },
  {
    Id: 6262,
    Code: '3221',
    Name: 'Practitioner, care, community'
  },
  {
    Id: 6263,
    Code: '3221',
    Name: 'Practitioner, help, early'
  },
  {
    Id: 6264,
    Code: '3221',
    Name: 'Practitioner, support, family'
  },
  {
    Id: 6265,
    Code: '3221',
    Name: 'Ranger, play, senior'
  },
  {
    Id: 6266,
    Code: '3221',
    Name: 'Warden, centre, community'
  },
  {
    Id: 6267,
    Code: '3221',
    Name: 'Warden, club, youth'
  },
  {
    Id: 6268,
    Code: '3221',
    Name: 'Warden, youth club'
  },
  {
    Id: 6269,
    Code: '3221',
    Name: 'Worker, aid, family'
  },
  {
    Id: 6270,
    Code: '3221',
    Name: 'Worker, arts, community'
  },
  {
    Id: 6271,
    Code: '3221',
    Name: 'Worker, centre, family'
  },
  {
    Id: 6272,
    Code: '3221',
    Name: 'Worker, community'
  },
  {
    Id: 6273,
    Code: '3221',
    Name: 'Worker, development, community'
  },
  {
    Id: 6274,
    Code: '3221',
    Name: 'Worker, development, youth'
  },
  {
    Id: 6275,
    Code: '3221',
    Name: 'Worker, education, community'
  },
  {
    Id: 6276,
    Code: '3221',
    Name: 'Worker, family and children'
  },
  {
    Id: 6277,
    Code: '3221',
    Name: 'Worker, intervention, family'
  },
  {
    Id: 6278,
    Code: '3221',
    Name: 'Worker, link, community'
  },
  {
    Id: 6279,
    Code: '3221',
    Name: 'Worker, link, family'
  },
  {
    Id: 6280,
    Code: '3221',
    Name: 'Worker, outreach, community'
  },
  {
    Id: 6281,
    Code: '3221',
    Name: 'Worker, outreach, family'
  },
  {
    Id: 6282,
    Code: '3221',
    Name: 'Worker, outreach'
  },
  {
    Id: 6283,
    Code: '3221',
    Name: 'Worker, parish'
  },
  {
    Id: 6284,
    Code: '3221',
    Name: 'Worker, parochial'
  },
  {
    Id: 6285,
    Code: '3221',
    Name: 'Worker, project, intervention, family'
  },
  {
    Id: 6286,
    Code: '3221',
    Name: "Worker, support, centre, children's"
  },
  {
    Id: 6287,
    Code: '3221',
    Name: 'Worker, support, family'
  },
  {
    Id: 6288,
    Code: '3221',
    Name: 'Worker, support, outreach'
  },
  {
    Id: 6289,
    Code: '3221',
    Name: 'Worker, support, youth'
  },
  {
    Id: 6290,
    Code: '3221',
    Name: 'Worker, youth'
  },
  {
    Id: 6291,
    Code: '3221',
    Name: 'Worker, youth and community'
  },
  {
    Id: 6292,
    Code: '3221',
    Name: 'Youth worker in charge'
  },
  {
    Id: 6293,
    Code: '3222',
    Name: 'Adviser, support, parent'
  },
  {
    Id: 6294,
    Code: '3222',
    Name: 'Assistant, pastoral, educational establishments'
  },
  {
    Id: 6295,
    Code: '3222',
    Name: 'Assistant, support, pastoral, educational establishments'
  },
  {
    Id: 6296,
    Code: '3222',
    Name: 'Caseworker, educational establishments'
  },
  {
    Id: 6297,
    Code: '3222',
    Name: 'Lead, pastoral, educational establishments'
  },
  {
    Id: 6298,
    Code: '3222',
    Name: 'Leader, pastoral, educational establishments'
  },
  {
    Id: 6299,
    Code: '3222',
    Name: 'Manager, attendance, educational establishments'
  },
  {
    Id: 6300,
    Code: '3222',
    Name: 'Manager, behaviour'
  },
  {
    Id: 6301,
    Code: '3222',
    Name: 'Manager, pastoral, school'
  },
  {
    Id: 6302,
    Code: '3222',
    Name: 'Manager, pastoral, educational establishments'
  },
  {
    Id: 6303,
    Code: '3222',
    Name: 'Manager, support, behaviour'
  },
  {
    Id: 6304,
    Code: '3222',
    Name: 'Mentor, academic, child and early years'
  },
  {
    Id: 6305,
    Code: '3222',
    Name: 'Mentor, behaviour, child and early years'
  },
  {
    Id: 6306,
    Code: '3222',
    Name: 'Mentor, learning, child and early years'
  },
  {
    Id: 6307,
    Code: '3222',
    Name: 'Mentor, support, learning, child and early years'
  },
  {
    Id: 6308,
    Code: '3222',
    Name: 'Officer, access, education, local government'
  },
  {
    Id: 6309,
    Code: '3222',
    Name: 'Officer, access, education, local government'
  },
  {
    Id: 6310,
    Code: '3222',
    Name: 'Officer, attendance, school'
  },
  {
    Id: 6311,
    Code: '3222',
    Name: 'Officer, attendance, educational establishments'
  },
  {
    Id: 6312,
    Code: '3222',
    Name: 'Officer, care, child, residential'
  },
  {
    Id: 6313,
    Code: '3222',
    Name: 'Officer, care, child'
  },
  {
    Id: 6314,
    Code: '3222',
    Name: 'Officer, care, pastoral, educational establishments'
  },
  {
    Id: 6315,
    Code: '3222',
    Name: 'Officer, enquiry, school'
  },
  {
    Id: 6316,
    Code: '3222',
    Name: 'Officer, information, family'
  },
  {
    Id: 6317,
    Code: '3222',
    Name: 'Officer, liaison, family'
  },
  {
    Id: 6318,
    Code: '3222',
    Name: 'Officer, liaison, school, home'
  },
  {
    Id: 6319,
    Code: '3222',
    Name: 'Officer, liaison, school'
  },
  {
    Id: 6320,
    Code: '3222',
    Name: 'Officer, pastoral, educational establishments'
  },
  {
    Id: 6321,
    Code: '3222',
    Name: 'Officer, protection, child'
  },
  {
    Id: 6322,
    Code: '3222',
    Name: 'Officer, safeguarding, child'
  },
  {
    Id: 6323,
    Code: '3222',
    Name: 'Officer, safeguarding'
  },
  {
    Id: 6324,
    Code: '3222',
    Name: 'Officer, schools'
  },
  {
    Id: 6325,
    Code: '3222',
    Name: 'Officer, support, behaviour'
  },
  {
    Id: 6326,
    Code: '3222',
    Name: 'Officer, support, child'
  },
  {
    Id: 6327,
    Code: '3222',
    Name: 'Officer, support, pastoral, educational establishments'
  },
  {
    Id: 6328,
    Code: '3222',
    Name: 'Officer, support, pupil'
  },
  {
    Id: 6329,
    Code: '3222',
    Name: 'Officer, welfare, education'
  },
  {
    Id: 6330,
    Code: '3222',
    Name: 'Practitioner, parenting'
  },
  {
    Id: 6331,
    Code: '3222',
    Name: 'Supervisor, contact, local government'
  },
  {
    Id: 6332,
    Code: '3222',
    Name: 'Visitor, home, portage'
  },
  {
    Id: 6333,
    Code: '3222',
    Name: 'Visitor, welfare'
  },
  {
    Id: 6334,
    Code: '3222',
    Name: 'Worker, case, educational establishments'
  },
  {
    Id: 6335,
    Code: '3222',
    Name: "Worker, development, outreach, children's services"
  },
  {
    Id: 6336,
    Code: '3222',
    Name: 'Worker, link, home, schools'
  },
  {
    Id: 6337,
    Code: '3222',
    Name: 'Worker, pastoral, educational establishments'
  },
  {
    Id: 6338,
    Code: '3222',
    Name: 'Worker, portage, educational establishments'
  },
  {
    Id: 6339,
    Code: '3222',
    Name: 'Worker, support, pastoral'
  },
  {
    Id: 6340,
    Code: '3222',
    Name: 'Worker, support, pupil'
  },
  {
    Id: 6341,
    Code: '3223',
    Name: 'Adviser, housing'
  },
  {
    Id: 6342,
    Code: '3223',
    Name: 'Coordinator, homes, empty'
  },
  {
    Id: 6343,
    Code: '3223',
    Name: 'Coordinator, neighbourhood, housing services'
  },
  {
    Id: 6344,
    Code: '3223',
    Name: 'Coordinator, project, housing'
  },
  {
    Id: 6345,
    Code: '3223',
    Name: 'Coordinator, project, housing, welfare'
  },
  {
    Id: 6346,
    Code: '3223',
    Name: 'Coordinator, support, housing'
  },
  {
    Id: 6347,
    Code: '3223',
    Name: 'Enabler, housing, rural'
  },
  {
    Id: 6348,
    Code: '3223',
    Name: 'Factor, housing, Scotland, local government'
  },
  {
    Id: 6349,
    Code: '3223',
    Name: 'Officer, accommodation'
  },
  {
    Id: 6350,
    Code: '3223',
    Name: 'Officer, accommodations'
  },
  {
    Id: 6351,
    Code: '3223',
    Name: 'Officer, advisory, housing'
  },
  {
    Id: 6352,
    Code: '3223',
    Name: 'Officer, development, housing, local government'
  },
  {
    Id: 6353,
    Code: '3223',
    Name: 'Officer, enforcement, tenancy'
  },
  {
    Id: 6354,
    Code: '3223',
    Name: 'Officer, hostel, senior'
  },
  {
    Id: 6355,
    Code: '3223',
    Name: 'Officer, hostel'
  },
  {
    Id: 6356,
    Code: '3223',
    Name: 'Officer, housing, sheltered'
  },
  {
    Id: 6357,
    Code: '3223',
    Name: 'Officer, housing, supported'
  },
  {
    Id: 6358,
    Code: '3223',
    Name: 'Officer, housing'
  },
  {
    Id: 6359,
    Code: '3223',
    Name: 'Officer, liaison, tenant'
  },
  {
    Id: 6360,
    Code: '3223',
    Name: 'Officer, management, housing'
  },
  {
    Id: 6361,
    Code: '3223',
    Name: 'Officer, needs, housing'
  },
  {
    Id: 6362,
    Code: '3223',
    Name: 'Officer, options, housing'
  },
  {
    Id: 6363,
    Code: '3223',
    Name: 'Officer, participation, tenants'
  },
  {
    Id: 6364,
    Code: '3223',
    Name: 'Officer, relations, tenancy, housing'
  },
  {
    Id: 6365,
    Code: '3223',
    Name: 'Officer, service, resident, housing services'
  },
  {
    Id: 6366,
    Code: '3223',
    Name: 'Officer, services, resident, housing services'
  },
  {
    Id: 6367,
    Code: '3223',
    Name: 'Officer, support, floating, housing services'
  },
  {
    Id: 6368,
    Code: '3223',
    Name: 'Officer, support, housing'
  },
  {
    Id: 6369,
    Code: '3223',
    Name: 'Officer, support, housing services'
  },
  {
    Id: 6370,
    Code: '3223',
    Name: 'Worker, support, floating, housing services'
  },
  {
    Id: 6371,
    Code: '3223',
    Name: 'Worker, support, housing'
  },
  {
    Id: 6372,
    Code: '3223',
    Name: 'Worker, support, tenancy'
  },
  {
    Id: 6373,
    Code: '3223',
    Name: 'Worker, support, housing services'
  },
  {
    Id: 6374,
    Code: '3224',
    Name: 'Adviser, debt'
  },
  {
    Id: 6375,
    Code: '3224',
    Name: 'Coach, life'
  },
  {
    Id: 6376,
    Code: '3224',
    Name: 'Coordinator, recovery, welfare services'
  },
  {
    Id: 6377,
    Code: '3224',
    Name: 'Counsellor, bereavement'
  },
  {
    Id: 6378,
    Code: '3224',
    Name: 'Counsellor, care, primary'
  },
  {
    Id: 6379,
    Code: '3224',
    Name: 'Counsellor, cessation, smoking'
  },
  {
    Id: 6380,
    Code: '3224',
    Name: 'Counsellor, debt'
  },
  {
    Id: 6381,
    Code: '3224',
    Name: 'Counsellor, drugs and alcohol'
  },
  {
    Id: 6382,
    Code: '3224',
    Name: 'Counsellor, health, mental'
  },
  {
    Id: 6383,
    Code: '3224',
    Name: 'Counsellor, Relate'
  },
  {
    Id: 6384,
    Code: '3224',
    Name: 'Counsellor, relationship'
  },
  {
    Id: 6385,
    Code: '3224',
    Name: 'Counsellor, school'
  },
  {
    Id: 6386,
    Code: '3224',
    Name: 'Counsellor, smoking, stop'
  },
  {
    Id: 6387,
    Code: '3224',
    Name: 'Counsellor, student'
  },
  {
    Id: 6388,
    Code: '3224',
    Name: 'Counsellor, therapeutic'
  },
  {
    Id: 6389,
    Code: '3224',
    Name: 'Counsellor, further education'
  },
  {
    Id: 6390,
    Code: '3224',
    Name: 'Counsellor, higher education, university'
  },
  {
    Id: 6391,
    Code: '3224',
    Name: 'Counsellor, welfare services'
  },
  {
    Id: 6392,
    Code: '3224',
    Name: 'Facilitator, interventions, prison service'
  },
  {
    Id: 6393,
    Code: '3224',
    Name: 'Practitioner, misuse, substance'
  },
  {
    Id: 6394,
    Code: '3224',
    Name: 'Practitioner, NLP'
  },
  {
    Id: 6395,
    Code: '3224',
    Name: 'Worker, drug'
  },
  {
    Id: 6396,
    Code: '3224',
    Name: 'Worker, misuse, substance'
  },
  {
    Id: 6397,
    Code: '3224',
    Name: 'Worker, support, drug and alcohol'
  },
  {
    Id: 6398,
    Code: '3224',
    Name: 'Worker, support, recovery, addiction'
  },
  {
    Id: 6399,
    Code: '3224',
    Name: 'Worker, youth, drugs and alcohol'
  },
  {
    Id: 6400,
    Code: '3229',
    Name: 'Administrator, circuit'
  },
  {
    Id: 6401,
    Code: '3229',
    Name: 'Adviser, advice, citizens'
  },
  {
    Id: 6402,
    Code: '3229',
    Name: 'Adviser, benefits'
  },
  {
    Id: 6403,
    Code: '3229',
    Name: 'Adviser, cessation, smoking'
  },
  {
    Id: 6404,
    Code: '3229',
    Name: 'Adviser, disability'
  },
  {
    Id: 6405,
    Code: '3229',
    Name: 'Adviser, health'
  },
  {
    Id: 6406,
    Code: '3229',
    Name: 'Adviser, living, independent'
  },
  {
    Id: 6407,
    Code: '3229',
    Name: 'Adviser, personal, social, welfare services'
  },
  {
    Id: 6408,
    Code: '3229',
    Name: 'Adviser, promotion, health'
  },
  {
    Id: 6409,
    Code: '3229',
    Name: 'Adviser, rights, welfare'
  },
  {
    Id: 6410,
    Code: '3229',
    Name: 'Adviser, services, student'
  },
  {
    Id: 6411,
    Code: '3229',
    Name: 'Adviser, smoking, stop'
  },
  {
    Id: 6412,
    Code: '3229',
    Name: 'Adviser, student'
  },
  {
    Id: 6413,
    Code: '3229',
    Name: 'Adviser, violence, domestic, independent'
  },
  {
    Id: 6414,
    Code: '3229',
    Name: 'Adviser, welfare'
  },
  {
    Id: 6415,
    Code: '3229',
    Name: 'Adviser, Citizens Advice'
  },
  {
    Id: 6416,
    Code: '3229',
    Name: 'Advocate, carers'
  },
  {
    Id: 6417,
    Code: '3229',
    Name: 'Advocate, community'
  },
  {
    Id: 6418,
    Code: '3229',
    Name: 'Advocate, health, mental'
  },
  {
    Id: 6419,
    Code: '3229',
    Name: 'Advocate, home, care'
  },
  {
    Id: 6420,
    Code: '3229',
    Name: 'Advocate, IMCA'
  },
  {
    Id: 6421,
    Code: '3229',
    Name: 'Advocate, student'
  },
  {
    Id: 6422,
    Code: '3229',
    Name: 'Advocate, violence, domestic, independent'
  },
  {
    Id: 6423,
    Code: '3229',
    Name: 'Advocate, welfare services'
  },
  {
    Id: 6424,
    Code: '3229',
    Name: 'Ancillary, service, probation'
  },
  {
    Id: 6425,
    Code: '3229',
    Name: 'Arranger, care, local government'
  },
  {
    Id: 6426,
    Code: '3229',
    Name: 'Assessor, care, home'
  },
  {
    Id: 6427,
    Code: '3229',
    Name: 'Assessor, care, social'
  },
  {
    Id: 6428,
    Code: '3229',
    Name: 'Assessor, case, local government: social services'
  },
  {
    Id: 6429,
    Code: '3229',
    Name: 'Assessor, needs'
  },
  {
    Id: 6430,
    Code: '3229',
    Name: 'Assistant, justice, criminal, Scotland'
  },
  {
    Id: 6431,
    Code: '3229',
    Name: 'Assistant, pastoral'
  },
  {
    Id: 6432,
    Code: '3229',
    Name: 'Assistant, probation'
  },
  {
    Id: 6433,
    Code: '3229',
    Name: 'Assistant, service, probation'
  },
  {
    Id: 6434,
    Code: '3229',
    Name: 'Assistant, services, probation'
  },
  {
    Id: 6435,
    Code: '3229',
    Name: 'Assistant, welfare'
  },
  {
    Id: 6436,
    Code: '3229',
    Name: 'Assistant, work, social'
  },
  {
    Id: 6437,
    Code: '3229',
    Name: 'Attendant, welfare'
  },
  {
    Id: 6438,
    Code: '3229',
    Name: 'Broker, care'
  },
  {
    Id: 6439,
    Code: '3229',
    Name: 'Broker, independent, social, welfare services'
  },
  {
    Id: 6440,
    Code: '3229',
    Name: 'Broker, personal, social, welfare services'
  },
  {
    Id: 6441,
    Code: '3229',
    Name: 'Broker, support, care'
  },
  {
    Id: 6442,
    Code: '3229',
    Name: 'Broker, support, social, welfare services'
  },
  {
    Id: 6443,
    Code: '3229',
    Name: 'Broker, social, welfare services'
  },
  {
    Id: 6444,
    Code: '3229',
    Name: 'Campaigner, charity'
  },
  {
    Id: 6445,
    Code: '3229',
    Name: 'Captain, religious organisation'
  },
  {
    Id: 6446,
    Code: '3229',
    Name: 'Caseworker, advice, money'
  },
  {
    Id: 6447,
    Code: '3229',
    Name: 'Caseworker, advice'
  },
  {
    Id: 6448,
    Code: '3229',
    Name: 'Caseworker, local government: social services'
  },
  {
    Id: 6449,
    Code: '3229',
    Name: 'Caseworker, social, welfare services'
  },
  {
    Id: 6450,
    Code: '3229',
    Name: 'Celebrant, funeral'
  },
  {
    Id: 6451,
    Code: '3229',
    Name: 'Celebrant'
  },
  {
    Id: 6452,
    Code: '3229',
    Name: 'Consultant, broker, care'
  },
  {
    Id: 6453,
    Code: '3229',
    Name: 'Coordinator, befriending'
  },
  {
    Id: 6454,
    Code: '3229',
    Name: 'Coordinator, charity'
  },
  {
    Id: 6455,
    Code: '3229',
    Name: 'Coordinator, health'
  },
  {
    Id: 6456,
    Code: '3229',
    Name: 'Coordinator, justice, restorative'
  },
  {
    Id: 6457,
    Code: '3229',
    Name: 'Coordinator, prevent, educational establishments'
  },
  {
    Id: 6458,
    Code: '3229',
    Name: 'Coordinator, project, welfare'
  },
  {
    Id: 6459,
    Code: '3229',
    Name: 'Coordinator, project, charitable, welfare services'
  },
  {
    Id: 6460,
    Code: '3229',
    Name: 'Coordinator, projects, charitable, welfare services'
  },
  {
    Id: 6461,
    Code: '3229',
    Name: 'Coordinator, smoking, stop'
  },
  {
    Id: 6462,
    Code: '3229',
    Name: 'Coordinator, support, student'
  },
  {
    Id: 6463,
    Code: '3229',
    Name: 'Coordinator, volunteer'
  },
  {
    Id: 6464,
    Code: '3229',
    Name: 'Coordinator, charitable, welfare services'
  },
  {
    Id: 6465,
    Code: '3229',
    Name: 'Counsellor, breastfeeding'
  },
  {
    Id: 6466,
    Code: '3229',
    Name: 'Counsellor, feeding, breast'
  },
  {
    Id: 6467,
    Code: '3229',
    Name: 'Elder, church'
  },
  {
    Id: 6468,
    Code: '3229',
    Name: 'Enabler, mission'
  },
  {
    Id: 6469,
    Code: '3229',
    Name: 'Evangelist'
  },
  {
    Id: 6470,
    Code: '3229',
    Name: 'Facilitator, programme, prison service'
  },
  {
    Id: 6471,
    Code: '3229',
    Name: 'IDVA'
  },
  {
    Id: 6472,
    Code: '3229',
    Name: 'Manager, services, IMCA'
  },
  {
    Id: 6473,
    Code: '3229',
    Name: 'Mediator'
  },
  {
    Id: 6474,
    Code: '3229',
    Name: 'Mentor, behaviour'
  },
  {
    Id: 6475,
    Code: '3229',
    Name: 'Mentor, learning'
  },
  {
    Id: 6476,
    Code: '3229',
    Name: 'Mentor, pastoral, educational establishments'
  },
  {
    Id: 6477,
    Code: '3229',
    Name: 'Mentor, student'
  },
  {
    Id: 6478,
    Code: '3229',
    Name: 'Mentor, support, learning'
  },
  {
    Id: 6479,
    Code: '3229',
    Name: 'Mentor, transition, educational establishments'
  },
  {
    Id: 6480,
    Code: '3229',
    Name: 'Minister, youth'
  },
  {
    Id: 6481,
    Code: '3229',
    Name: 'Missionary'
  },
  {
    Id: 6482,
    Code: '3229',
    Name: 'Missioner'
  },
  {
    Id: 6483,
    Code: '3229',
    Name: 'Navigator, care'
  },
  {
    Id: 6484,
    Code: '3229',
    Name: 'Officer, access, disability, local government'
  },
  {
    Id: 6485,
    Code: '3229',
    Name: 'Officer, access, disability, local government'
  },
  {
    Id: 6486,
    Code: '3229',
    Name: 'Officer, advisory, welfare'
  },
  {
    Id: 6487,
    Code: '3229',
    Name: 'Officer, bereavement'
  },
  {
    Id: 6488,
    Code: '3229',
    Name: 'Officer, brokerage, social, welfare services'
  },
  {
    Id: 6489,
    Code: '3229',
    Name: 'Officer, care, community'
  },
  {
    Id: 6490,
    Code: '3229',
    Name: 'Officer, care, day'
  },
  {
    Id: 6491,
    Code: '3229',
    Name: 'Officer, care, secure'
  },
  {
    Id: 6492,
    Code: '3229',
    Name: 'Officer, care, social'
  },
  {
    Id: 6493,
    Code: '3229',
    Name: 'Officer, care, victim'
  },
  {
    Id: 6494,
    Code: '3229',
    Name: 'Officer, care'
  },
  {
    Id: 6495,
    Code: '3229',
    Name: 'Officer, development, welfare services'
  },
  {
    Id: 6496,
    Code: '3229',
    Name: 'Officer, disability'
  },
  {
    Id: 6497,
    Code: '3229',
    Name: 'Officer, homeless'
  },
  {
    Id: 6498,
    Code: '3229',
    Name: 'Officer, homelessness'
  },
  {
    Id: 6499,
    Code: '3229',
    Name: 'Officer, information and advice'
  },
  {
    Id: 6500,
    Code: '3229',
    Name: 'Officer, justice, restorative'
  },
  {
    Id: 6501,
    Code: '3229',
    Name: 'Officer, liaison, student'
  },
  {
    Id: 6502,
    Code: '3229',
    Name: 'Officer, liaison, union, student'
  },
  {
    Id: 6503,
    Code: '3229',
    Name: 'Officer, liaison, victim'
  },
  {
    Id: 6504,
    Code: '3229',
    Name: 'Officer, living, independent'
  },
  {
    Id: 6505,
    Code: '3229',
    Name: 'Officer, persons, homeless'
  },
  {
    Id: 6506,
    Code: '3229',
    Name: 'Officer, placement, local government: social services'
  },
  {
    Id: 6507,
    Code: '3229',
    Name: 'Officer, prevention, homeless'
  },
  {
    Id: 6508,
    Code: '3229',
    Name: 'Officer, reablement'
  },
  {
    Id: 6509,
    Code: '3229',
    Name: 'Officer, relieving'
  },
  {
    Id: 6510,
    Code: '3229',
    Name: 'Officer, resettlement'
  },
  {
    Id: 6511,
    Code: '3229',
    Name: 'Officer, rights, welfare'
  },
  {
    Id: 6512,
    Code: '3229',
    Name: 'Officer, sabbatical'
  },
  {
    Id: 6513,
    Code: '3229',
    Name: 'Officer, safety, women'
  },
  {
    Id: 6514,
    Code: '3229',
    Name: "Officer, safety, women's"
  },
  {
    Id: 6515,
    Code: '3229',
    Name: 'Officer, service, day'
  },
  {
    Id: 6516,
    Code: '3229',
    Name: 'Officer, service, probation'
  },
  {
    Id: 6517,
    Code: '3229',
    Name: 'Officer, services, day'
  },
  {
    Id: 6518,
    Code: '3229',
    Name: 'Officer, services, probation'
  },
  {
    Id: 6519,
    Code: '3229',
    Name: 'Officer, solutions, housing'
  },
  {
    Id: 6520,
    Code: '3229',
    Name: 'Officer, support, assessment, review'
  },
  {
    Id: 6521,
    Code: '3229',
    Name: 'Officer, support, bail'
  },
  {
    Id: 6522,
    Code: '3229',
    Name: 'Officer, support, care, social'
  },
  {
    Id: 6523,
    Code: '3229',
    Name: 'Officer, support, probation'
  },
  {
    Id: 6524,
    Code: '3229',
    Name: 'Officer, support, student'
  },
  {
    Id: 6525,
    Code: '3229',
    Name: 'Officer, support, welfare services'
  },
  {
    Id: 6526,
    Code: '3229',
    Name: 'Officer, welfare, chief'
  },
  {
    Id: 6527,
    Code: '3229',
    Name: 'Officer, welfare, student'
  },
  {
    Id: 6528,
    Code: '3229',
    Name: 'Officer, welfare'
  },
  {
    Id: 6529,
    Code: '3229',
    Name: 'Officiant'
  },
  {
    Id: 6530,
    Code: '3229',
    Name: 'Organiser, care, day'
  },
  {
    Id: 6531,
    Code: '3229',
    Name: 'Organiser, care, home'
  },
  {
    Id: 6532,
    Code: '3229',
    Name: 'Organiser, care'
  },
  {
    Id: 6533,
    Code: '3229',
    Name: 'Organiser, centre, day'
  },
  {
    Id: 6534,
    Code: '3229',
    Name: 'Organiser, help, home'
  },
  {
    Id: 6535,
    Code: '3229',
    Name: 'Organiser, project, welfare services'
  },
  {
    Id: 6536,
    Code: '3229',
    Name: 'Organiser, welfare'
  },
  {
    Id: 6537,
    Code: '3229',
    Name: 'Organiser, work, volunteer'
  },
  {
    Id: 6538,
    Code: '3229',
    Name: 'Organiser, welfare services'
  },
  {
    Id: 6539,
    Code: '3229',
    Name: 'Pastor, youth'
  },
  {
    Id: 6540,
    Code: '3229',
    Name: 'Practitioner, reablement'
  },
  {
    Id: 6541,
    Code: '3229',
    Name: 'Preacher, lay'
  },
  {
    Id: 6542,
    Code: '3229',
    Name: 'Preacher, local'
  },
  {
    Id: 6543,
    Code: '3229',
    Name: 'Prescriber, social'
  },
  {
    Id: 6544,
    Code: '3229',
    Name: 'President, sabbatical'
  },
  {
    Id: 6545,
    Code: '3229',
    Name: 'Reader, lay'
  },
  {
    Id: 6546,
    Code: '3229',
    Name: 'Recruiter, charitable organisation'
  },
  {
    Id: 6547,
    Code: '3229',
    Name: 'Specialist, cessation, smoking'
  },
  {
    Id: 6548,
    Code: '3229',
    Name: 'Superintendent, welfare'
  },
  {
    Id: 6549,
    Code: '3229',
    Name: 'Supervisor, care, day'
  },
  {
    Id: 6550,
    Code: '3229',
    Name: 'Supervisor, centre, day'
  },
  {
    Id: 6551,
    Code: '3229',
    Name: 'Supervisor, session, advice, Citizens Advice'
  },
  {
    Id: 6552,
    Code: '3229',
    Name: 'Supervisor, welfare'
  },
  {
    Id: 6553,
    Code: '3229',
    Name: 'Technologist, assistive'
  },
  {
    Id: 6554,
    Code: '3229',
    Name: 'Worker, advice'
  },
  {
    Id: 6555,
    Code: '3229',
    Name: 'Worker, advocacy'
  },
  {
    Id: 6556,
    Code: '3229',
    Name: 'Worker, ancillary, probation service'
  },
  {
    Id: 6557,
    Code: '3229',
    Name: 'Worker, army, Church'
  },
  {
    Id: 6558,
    Code: '3229',
    Name: 'Worker, care, leaving'
  },
  {
    Id: 6559,
    Code: '3229',
    Name: 'Worker, case, advice'
  },
  {
    Id: 6560,
    Code: '3229',
    Name: 'Worker, case, local government: social services'
  },
  {
    Id: 6561,
    Code: '3229',
    Name: 'Worker, case, social, welfare services'
  },
  {
    Id: 6562,
    Code: '3229',
    Name: 'Worker, cessation, smoking'
  },
  {
    Id: 6563,
    Code: '3229',
    Name: 'Worker, church'
  },
  {
    Id: 6564,
    Code: '3229',
    Name: 'Worker, crisis'
  },
  {
    Id: 6565,
    Code: '3229',
    Name: 'Worker, development, outreach'
  },
  {
    Id: 6566,
    Code: '3229',
    Name: 'Worker, development, welfare services'
  },
  {
    Id: 6567,
    Code: '3229',
    Name: 'Worker, field, charitable, welfare services'
  },
  {
    Id: 6568,
    Code: '3229',
    Name: 'Worker, group, local government: social services'
  },
  {
    Id: 6569,
    Code: '3229',
    Name: 'Worker, justice, restorative'
  },
  {
    Id: 6570,
    Code: '3229',
    Name: 'Worker, key, welfare services'
  },
  {
    Id: 6571,
    Code: '3229',
    Name: 'Worker, lay'
  },
  {
    Id: 6572,
    Code: '3229',
    Name: 'Worker, link, prescribing, social'
  },
  {
    Id: 6573,
    Code: '3229',
    Name: 'Worker, outreach, welfare services'
  },
  {
    Id: 6574,
    Code: '3229',
    Name: 'Worker, participation and rights'
  },
  {
    Id: 6575,
    Code: '3229',
    Name: 'Worker, pastoral'
  },
  {
    Id: 6576,
    Code: '3229',
    Name: 'Worker, project, health, mental'
  },
  {
    Id: 6577,
    Code: '3229',
    Name: 'Worker, project, welfare services'
  },
  {
    Id: 6578,
    Code: '3229',
    Name: 'Worker, recovery, health, mental'
  },
  {
    Id: 6579,
    Code: '3229',
    Name: 'Worker, recovery, time, support, social, welfare services'
  },
  {
    Id: 6580,
    Code: '3229',
    Name: 'Worker, recovery, social, welfare services'
  },
  {
    Id: 6581,
    Code: '3229',
    Name: 'Worker, refuge, welfare services'
  },
  {
    Id: 6582,
    Code: '3229',
    Name: 'Worker, rehabilitation'
  },
  {
    Id: 6583,
    Code: '3229',
    Name: 'Worker, rescue'
  },
  {
    Id: 6584,
    Code: '3229',
    Name: 'Worker, resettlement, welfare services'
  },
  {
    Id: 6585,
    Code: '3229',
    Name: 'Worker, sessional'
  },
  {
    Id: 6586,
    Code: '3229',
    Name: 'Worker, settlement'
  },
  {
    Id: 6587,
    Code: '3229',
    Name: 'Worker, STR, social, welfare services'
  },
  {
    Id: 6588,
    Code: '3229',
    Name: 'Worker, support, breastfeeding'
  },
  {
    Id: 6589,
    Code: '3229',
    Name: 'Worker, support, crisis'
  },
  {
    Id: 6590,
    Code: '3229',
    Name: 'Worker, support, hostel'
  },
  {
    Id: 6591,
    Code: '3229',
    Name: 'Worker, support, outreach, welfare services'
  },
  {
    Id: 6592,
    Code: '3229',
    Name: 'Worker, support, peer'
  },
  {
    Id: 6593,
    Code: '3229',
    Name: 'Worker, support, placement, local government: social services'
  },
  {
    Id: 6594,
    Code: '3229',
    Name: 'Worker, support, project, welfare services'
  },
  {
    Id: 6595,
    Code: '3229',
    Name: 'Worker, support, refuge, welfare services'
  },
  {
    Id: 6596,
    Code: '3229',
    Name: 'Worker, welfare'
  },
  {
    Id: 6597,
    Code: '3231',
    Name: 'Assistant, teaching, advanced'
  },
  {
    Id: 6598,
    Code: '3231',
    Name: 'Assistant, teaching, level, high'
  },
  {
    Id: 6599,
    Code: '3231',
    Name: 'Assistant, teaching, level, higher'
  },
  {
    Id: 6600,
    Code: '3231',
    Name: 'Assistant, teaching, qualified'
  },
  {
    Id: 6601,
    Code: '3231',
    Name: 'HLTA, SEN'
  },
  {
    Id: 6602,
    Code: '3231',
    Name: 'HLTA'
  },
  {
    Id: 6603,
    Code: '3231',
    Name: 'Practitioner, support, learning'
  },
  {
    Id: 6604,
    Code: '3231',
    Name: 'TA, qualified'
  },
  {
    Id: 6605,
    Code: '3232',
    Name: 'Educator, years, early'
  },
  {
    Id: 6606,
    Code: '3232',
    Name: 'Officer, development, child, early years'
  },
  {
    Id: 6607,
    Code: '3232',
    Name: 'Officer, education, nursery, qualified'
  },
  {
    Id: 6608,
    Code: '3232',
    Name: 'Officer, nursery, qualified'
  },
  {
    Id: 6609,
    Code: '3232',
    Name: 'Practitioner, care, child'
  },
  {
    Id: 6610,
    Code: '3232',
    Name: 'Practitioner, childcare'
  },
  {
    Id: 6611,
    Code: '3232',
    Name: 'Practitioner, nurse, nursery'
  },
  {
    Id: 6612,
    Code: '3232',
    Name: 'Practitioner, nursery'
  },
  {
    Id: 6613,
    Code: '3232',
    Name: 'Practitioner, pre-school'
  },
  {
    Id: 6614,
    Code: '3232',
    Name: 'Practitioner, school, nursery'
  },
  {
    Id: 6615,
    Code: '3232',
    Name: 'Practitioner, years, early'
  },
  {
    Id: 6616,
    Code: '3232',
    Name: 'Practitioner, early years'
  },
  {
    Id: 6617,
    Code: '3240',
    Name: 'Nurse, animal'
  },
  {
    Id: 6618,
    Code: '3240',
    Name: 'Nurse, canine'
  },
  {
    Id: 6619,
    Code: '3240',
    Name: 'Nurse, veterinary, equine'
  },
  {
    Id: 6620,
    Code: '3240',
    Name: 'Nurse, veterinary'
  },
  {
    Id: 6621,
    Code: '3311',
    Name: 'AB, armed forces'
  },
  {
    Id: 6622,
    Code: '3311',
    Name: 'Aircraftman'
  },
  {
    Id: 6623,
    Code: '3311',
    Name: 'Aircraftwoman'
  },
  {
    Id: 6624,
    Code: '3311',
    Name: 'Aircrew, master, armed forces'
  },
  {
    Id: 6625,
    Code: '3311',
    Name: 'Aircrewman'
  },
  {
    Id: 6626,
    Code: '3311',
    Name: 'Airman, Force, Air, Royal'
  },
  {
    Id: 6627,
    Code: '3311',
    Name: 'Airman, armed forces'
  },
  {
    Id: 6628,
    Code: '3311',
    Name: 'Airwoman, Force, Air, Royal'
  },
  {
    Id: 6629,
    Code: '3311',
    Name: 'Assistant, medical, armed forces'
  },
  {
    Id: 6630,
    Code: '3311',
    Name: 'Bombardier'
  },
  {
    Id: 6631,
    Code: '3311',
    Name: 'Commando, Marines, Royal'
  },
  {
    Id: 6632,
    Code: '3311',
    Name: 'Corporal, lance'
  },
  {
    Id: 6633,
    Code: '3311',
    Name: 'Corporal'
  },
  {
    Id: 6634,
    Code: '3311',
    Name: 'Engineer, combat'
  },
  {
    Id: 6635,
    Code: '3311',
    Name: 'Engineer, disposal, bomb, armed forces'
  },
  {
    Id: 6636,
    Code: '3311',
    Name: 'Engineer, systems, weapons, armed forces'
  },
  {
    Id: 6637,
    Code: '3311',
    Name: 'Engineer, weapons, armed forces'
  },
  {
    Id: 6638,
    Code: '3311',
    Name: 'Fitter, equipment, survival, armed forces'
  },
  {
    Id: 6639,
    Code: '3311',
    Name: 'Flight-Sergeant'
  },
  {
    Id: 6640,
    Code: '3311',
    Name: 'Fusilier'
  },
  {
    Id: 6641,
    Code: '3311',
    Name: 'GI'
  },
  {
    Id: 6642,
    Code: '3311',
    Name: 'Guardsman'
  },
  {
    Id: 6643,
    Code: '3311',
    Name: 'Gunner'
  },
  {
    Id: 6644,
    Code: '3311',
    Name: 'Hand, leading, armed forces'
  },
  {
    Id: 6645,
    Code: '3311',
    Name: 'Infantryman'
  },
  {
    Id: 6646,
    Code: '3311',
    Name: 'Lance-Bombardier'
  },
  {
    Id: 6647,
    Code: '3311',
    Name: 'Lance-Corporal'
  },
  {
    Id: 6648,
    Code: '3311',
    Name: 'Lance-Sergeant'
  },
  {
    Id: 6649,
    Code: '3311',
    Name: 'Loadmaster, armed forces'
  },
  {
    Id: 6650,
    Code: '3311',
    Name: 'Marine'
  },
  {
    Id: 6651,
    Code: '3311',
    Name: 'Master, quarter, armed forces'
  },
  {
    Id: 6652,
    Code: '3311',
    Name: 'Master at Arms, armed forces'
  },
  {
    Id: 6653,
    Code: '3311',
    Name: 'Officer, non-commissioned'
  },
  {
    Id: 6654,
    Code: '3311',
    Name: 'Officer, petty, armed forces'
  },
  {
    Id: 6655,
    Code: '3311',
    Name: 'Officer, warrant, armed forces'
  },
  {
    Id: 6656,
    Code: '3311',
    Name: 'Paratrooper'
  },
  {
    Id: 6657,
    Code: '3311',
    Name: 'Private, armed forces'
  },
  {
    Id: 6658,
    Code: '3311',
    Name: 'Quartermaster, armed forces'
  },
  {
    Id: 6659,
    Code: '3311',
    Name: 'Quartermaster-Corporal'
  },
  {
    Id: 6660,
    Code: '3311',
    Name: 'Quartermaster-Sergeant'
  },
  {
    Id: 6661,
    Code: '3311',
    Name: 'Rate, able'
  },
  {
    Id: 6662,
    Code: '3311',
    Name: 'Rating, Navy, Royal'
  },
  {
    Id: 6663,
    Code: '3311',
    Name: 'Rating'
  },
  {
    Id: 6664,
    Code: '3311',
    Name: 'Rifleman'
  },
  {
    Id: 6665,
    Code: '3311',
    Name: 'SAC, armed forces'
  },
  {
    Id: 6666,
    Code: '3311',
    Name: 'Sailor, armed forces'
  },
  {
    Id: 6667,
    Code: '3311',
    Name: 'Sapper'
  },
  {
    Id: 6668,
    Code: '3311',
    Name: 'Seaman, armed forces'
  },
  {
    Id: 6669,
    Code: '3311',
    Name: 'Sergeant, staff, armed forces'
  },
  {
    Id: 6670,
    Code: '3311',
    Name: 'Sergeant, armed forces'
  },
  {
    Id: 6671,
    Code: '3311',
    Name: 'Sergeant-Major'
  },
  {
    Id: 6672,
    Code: '3311',
    Name: 'Soldier, infantry'
  },
  {
    Id: 6673,
    Code: '3311',
    Name: 'Soldier'
  },
  {
    Id: 6674,
    Code: '3311',
    Name: 'Technician, aircraft, armed forces'
  },
  {
    Id: 6675,
    Code: '3311',
    Name: 'Technician, weapons, armed forces'
  },
  {
    Id: 6676,
    Code: '3311',
    Name: 'Technician, armed forces'
  },
  {
    Id: 6677,
    Code: '3311',
    Name: 'Wren'
  },
  {
    Id: 6678,
    Code: '3312',
    Name: 'Armourer, force'
  },
  {
    Id: 6679,
    Code: '3312',
    Name: 'Cadet, police'
  },
  {
    Id: 6680,
    Code: '3312',
    Name: 'Constable, detective'
  },
  {
    Id: 6681,
    Code: '3312',
    Name: 'Constable, police'
  },
  {
    Id: 6682,
    Code: '3312',
    Name: 'Constable, government'
  },
  {
    Id: 6683,
    Code: '3312',
    Name: 'Constable, MOD'
  },
  {
    Id: 6684,
    Code: '3312',
    Name: 'Constable, police service'
  },
  {
    Id: 6685,
    Code: '3312',
    Name: 'Constable'
  },
  {
    Id: 6686,
    Code: '3312',
    Name: 'Detective, police'
  },
  {
    Id: 6687,
    Code: '3312',
    Name: 'Detective, airport'
  },
  {
    Id: 6688,
    Code: '3312',
    Name: 'Detective, docks'
  },
  {
    Id: 6689,
    Code: '3312',
    Name: 'Detective, government'
  },
  {
    Id: 6690,
    Code: '3312',
    Name: 'Detective, police service'
  },
  {
    Id: 6691,
    Code: '3312',
    Name: 'Detective, railways'
  },
  {
    Id: 6692,
    Code: '3312',
    Name: 'Handler, dog, police'
  },
  {
    Id: 6693,
    Code: '3312',
    Name: 'Investigator, staff, police'
  },
  {
    Id: 6694,
    Code: '3312',
    Name: 'Investigator, police service'
  },
  {
    Id: 6695,
    Code: '3312',
    Name: 'MP, armed forces'
  },
  {
    Id: 6696,
    Code: '3312',
    Name: 'Manager, beat, community'
  },
  {
    Id: 6697,
    Code: '3312',
    Name: 'Officer, fingerprint'
  },
  {
    Id: 6698,
    Code: '3312',
    Name: 'Officer, liaison, family, police service'
  },
  {
    Id: 6699,
    Code: '3312',
    Name: 'Officer, neighbourhood, police service'
  },
  {
    Id: 6700,
    Code: '3312',
    Name: 'Officer, police, transport'
  },
  {
    Id: 6701,
    Code: '3312',
    Name: 'Officer, police'
  },
  {
    Id: 6702,
    Code: '3312',
    Name: 'Officer, traffic, police service'
  },
  {
    Id: 6703,
    Code: '3312',
    Name: 'Officer, uniformed, police service'
  },
  {
    Id: 6704,
    Code: '3312',
    Name: 'Officer, warrant, police service'
  },
  {
    Id: 6705,
    Code: '3312',
    Name: 'Officer, police service'
  },
  {
    Id: 6706,
    Code: '3312',
    Name: 'PC'
  },
  {
    Id: 6707,
    Code: '3312',
    Name: 'Police, military'
  },
  {
    Id: 6708,
    Code: '3312',
    Name: 'Policeman'
  },
  {
    Id: 6709,
    Code: '3312',
    Name: 'Sergeant, detective'
  },
  {
    Id: 6710,
    Code: '3312',
    Name: 'Sergeant, police'
  },
  {
    Id: 6711,
    Code: '3312',
    Name: 'Sergeant'
  },
  {
    Id: 6712,
    Code: '3312',
    Name: 'WPC'
  },
  {
    Id: 6713,
    Code: '3313',
    Name: 'Adviser, prevention, fire'
  },
  {
    Id: 6714,
    Code: '3313',
    Name: "Assistant, fireman's"
  },
  {
    Id: 6715,
    Code: '3313',
    Name: 'Commander, crew, fire service'
  },
  {
    Id: 6716,
    Code: '3313',
    Name: 'Commander, watch'
  },
  {
    Id: 6717,
    Code: '3313',
    Name: 'Engineer, fire'
  },
  {
    Id: 6718,
    Code: '3313',
    Name: 'Fighter, fire'
  },
  {
    Id: 6719,
    Code: '3313',
    Name: 'Firefighter, leading'
  },
  {
    Id: 6720,
    Code: '3313',
    Name: 'Firefighter'
  },
  {
    Id: 6721,
    Code: '3313',
    Name: 'Fireman, industrial'
  },
  {
    Id: 6722,
    Code: '3313',
    Name: 'Fireman, private'
  },
  {
    Id: 6723,
    Code: '3313',
    Name: 'Fireman, security'
  },
  {
    Id: 6724,
    Code: '3313',
    Name: 'Fireman, works, nos'
  },
  {
    Id: 6725,
    Code: '3313',
    Name: 'Fireman, airport'
  },
  {
    Id: 6726,
    Code: '3313',
    Name: 'Fireman'
  },
  {
    Id: 6727,
    Code: '3313',
    Name: 'Guard, fire'
  },
  {
    Id: 6728,
    Code: '3313',
    Name: 'Inspector, safety, fire'
  },
  {
    Id: 6729,
    Code: '3313',
    Name: 'Leader, section, fire service'
  },
  {
    Id: 6730,
    Code: '3313',
    Name: 'Man, corps, salvage'
  },
  {
    Id: 6731,
    Code: '3313',
    Name: 'Man, prevention, fire'
  },
  {
    Id: 6732,
    Code: '3313',
    Name: 'Manager, crew, fire service'
  },
  {
    Id: 6733,
    Code: '3313',
    Name: 'Manager, watch'
  },
  {
    Id: 6734,
    Code: '3313',
    Name: 'Officer, fire, leading'
  },
  {
    Id: 6735,
    Code: '3313',
    Name: 'Officer, fire, coal mine'
  },
  {
    Id: 6736,
    Code: '3313',
    Name: 'Officer, fire'
  },
  {
    Id: 6737,
    Code: '3313',
    Name: 'Officer, prevention, fire'
  },
  {
    Id: 6738,
    Code: '3313',
    Name: 'Officer, safety, fire'
  },
  {
    Id: 6739,
    Code: '3313',
    Name: 'Officer, security, fire'
  },
  {
    Id: 6740,
    Code: '3313',
    Name: 'Officer, sub, fire service'
  },
  {
    Id: 6741,
    Code: '3313',
    Name: 'Officer, watch, fire service'
  },
  {
    Id: 6742,
    Code: '3313',
    Name: 'Sub-officer, fire service'
  },
  {
    Id: 6743,
    Code: '3314',
    Name: 'Auxiliary, prison'
  },
  {
    Id: 6744,
    Code: '3314',
    Name: 'Grade, support, operational'
  },
  {
    Id: 6745,
    Code: '3314',
    Name: 'Manager, custodial'
  },
  {
    Id: 6746,
    Code: '3314',
    Name: 'OSG'
  },
  {
    Id: 6747,
    Code: '3314',
    Name: 'Officer, auxiliary, prison service'
  },
  {
    Id: 6748,
    Code: '3314',
    Name: 'Officer, borstal'
  },
  {
    Id: 6749,
    Code: '3314',
    Name: 'Officer, custodial, prison'
  },
  {
    Id: 6750,
    Code: '3314',
    Name: 'Officer, custody, prison'
  },
  {
    Id: 6751,
    Code: '3314',
    Name: 'Officer, custody, prisoner'
  },
  {
    Id: 6752,
    Code: '3314',
    Name: 'Officer, custody'
  },
  {
    Id: 6753,
    Code: '3314',
    Name: 'Officer, discipline, borstal'
  },
  {
    Id: 6754,
    Code: '3314',
    Name: 'Officer, discipline, prison service'
  },
  {
    Id: 6755,
    Code: '3314',
    Name: 'Officer, escort, prison'
  },
  {
    Id: 6756,
    Code: '3314',
    Name: 'Officer, grade, support, operational'
  },
  {
    Id: 6757,
    Code: '3314',
    Name: 'Officer, prison'
  },
  {
    Id: 6758,
    Code: '3314',
    Name: 'Supervisor, custody, prison'
  },
  {
    Id: 6759,
    Code: '3314',
    Name: 'Warden, prison'
  },
  {
    Id: 6760,
    Code: '3314',
    Name: 'Warder, prison service'
  },
  {
    Id: 6761,
    Code: '3319',
    Name: 'Adviser, security'
  },
  {
    Id: 6762,
    Code: '3319',
    Name: 'Agent, trace'
  },
  {
    Id: 6763,
    Code: '3319',
    Name: 'Analyst, fraud'
  },
  {
    Id: 6764,
    Code: '3319',
    Name: 'Analyst, handwriting'
  },
  {
    Id: 6765,
    Code: '3319',
    Name: 'Analyst, strategy, fraud'
  },
  {
    Id: 6766,
    Code: '3319',
    Name: 'Assistant, watch, coastguard'
  },
  {
    Id: 6767,
    Code: '3319',
    Name: 'Assistant, watch, coastguard service'
  },
  {
    Id: 6768,
    Code: '3319',
    Name: 'Coastguard'
  },
  {
    Id: 6769,
    Code: '3319',
    Name: 'Consultant, security, maritime'
  },
  {
    Id: 6770,
    Code: '3319',
    Name: 'Consultant, security'
  },
  {
    Id: 6771,
    Code: '3319',
    Name: 'Coordinator, security'
  },
  {
    Id: 6772,
    Code: '3319',
    Name: 'Detective, private'
  },
  {
    Id: 6773,
    Code: '3319',
    Name: 'Detective, private detective agency'
  },
  {
    Id: 6774,
    Code: '3319',
    Name: 'Examiner, handwriting, forensic'
  },
  {
    Id: 6775,
    Code: '3319',
    Name: 'Examiner, scene, crime'
  },
  {
    Id: 6776,
    Code: '3319',
    Name: 'Examiner, scene'
  },
  {
    Id: 6777,
    Code: '3319',
    Name: 'Executive, security'
  },
  {
    Id: 6778,
    Code: '3319',
    Name: 'Expert, fingerprint'
  },
  {
    Id: 6779,
    Code: '3319',
    Name: 'Expert, print, finger'
  },
  {
    Id: 6780,
    Code: '3319',
    Name: 'Guard, coast'
  },
  {
    Id: 6781,
    Code: '3319',
    Name: 'Guard, water, HM Revenue and Customs'
  },
  {
    Id: 6782,
    Code: '3319',
    Name: 'Head of security'
  },
  {
    Id: 6783,
    Code: '3319',
    Name: 'Inspector, fraud'
  },
  {
    Id: 6784,
    Code: '3319',
    Name: 'Instructor, defence, civil'
  },
  {
    Id: 6785,
    Code: '3319',
    Name: 'Investigator, civil'
  },
  {
    Id: 6786,
    Code: '3319',
    Name: 'Investigator, credit'
  },
  {
    Id: 6787,
    Code: '3319',
    Name: 'Investigator, crime, financial'
  },
  {
    Id: 6788,
    Code: '3319',
    Name: 'Investigator, financial'
  },
  {
    Id: 6789,
    Code: '3319',
    Name: 'Investigator, fraud, banking'
  },
  {
    Id: 6790,
    Code: '3319',
    Name: 'Investigator, fraud, building society'
  },
  {
    Id: 6791,
    Code: '3319',
    Name: 'Investigator, fraud, insurance'
  },
  {
    Id: 6792,
    Code: '3319',
    Name: 'Investigator, fraud'
  },
  {
    Id: 6793,
    Code: '3319',
    Name: 'Investigator, private'
  },
  {
    Id: 6794,
    Code: '3319',
    Name: 'Investigator, purchase, hire'
  },
  {
    Id: 6795,
    Code: '3319',
    Name: 'Investigator, purchase, test'
  },
  {
    Id: 6796,
    Code: '3319',
    Name: 'Investigator, scene, crime'
  },
  {
    Id: 6797,
    Code: '3319',
    Name: 'Investigator, security'
  },
  {
    Id: 6798,
    Code: '3319',
    Name: 'Investigator, security services'
  },
  {
    Id: 6799,
    Code: '3319',
    Name: 'Leader, team, crime, financial'
  },
  {
    Id: 6800,
    Code: '3319',
    Name: 'Manager, branch, security services'
  },
  {
    Id: 6801,
    Code: '3319',
    Name: 'Manager, CCTV'
  },
  {
    Id: 6802,
    Code: '3319',
    Name: 'Manager, contract, security services'
  },
  {
    Id: 6803,
    Code: '3319',
    Name: 'Manager, crime, financial'
  },
  {
    Id: 6804,
    Code: '3319',
    Name: 'Manager, fraud'
  },
  {
    Id: 6805,
    Code: '3319',
    Name: 'Manager, investigations'
  },
  {
    Id: 6806,
    Code: '3319',
    Name: 'Manager, operations, CCTV'
  },
  {
    Id: 6807,
    Code: '3319',
    Name: 'Manager, operations, security services'
  },
  {
    Id: 6808,
    Code: '3319',
    Name: 'Manager, prevention, loss'
  },
  {
    Id: 6809,
    Code: '3319',
    Name: 'Manager, protection, profit'
  },
  {
    Id: 6810,
    Code: '3319',
    Name: 'Manager, security'
  },
  {
    Id: 6811,
    Code: '3319',
    Name: 'Manager, private detective agency'
  },
  {
    Id: 6812,
    Code: '3319',
    Name: 'Manager, security services'
  },
  {
    Id: 6813,
    Code: '3319',
    Name: 'Officer, CCTV'
  },
  {
    Id: 6814,
    Code: '3319',
    Name: 'Officer, coastguard'
  },
  {
    Id: 6815,
    Code: '3319',
    Name: 'Officer, control, port'
  },
  {
    Id: 6816,
    Code: '3319',
    Name: 'Officer, customs'
  },
  {
    Id: 6817,
    Code: '3319',
    Name: 'Officer, Customs and Excise'
  },
  {
    Id: 6818,
    Code: '3319',
    Name: 'Officer, defence, civil'
  },
  {
    Id: 6819,
    Code: '3319',
    Name: 'Officer, district, coastguard service'
  },
  {
    Id: 6820,
    Code: '3319',
    Name: 'Officer, enforcement, law'
  },
  {
    Id: 6821,
    Code: '3319',
    Name: 'Officer, enquiry, firearms'
  },
  {
    Id: 6822,
    Code: '3319',
    Name: 'Officer, excise, HM Revenue and Customs'
  },
  {
    Id: 6823,
    Code: '3319',
    Name: 'Officer, force, border'
  },
  {
    Id: 6824,
    Code: '3319',
    Name: 'Officer, fraud, banking'
  },
  {
    Id: 6825,
    Code: '3319',
    Name: 'Officer, fraud, building society'
  },
  {
    Id: 6826,
    Code: '3319',
    Name: 'Officer, fraud'
  },
  {
    Id: 6827,
    Code: '3319',
    Name: 'Officer, higher, force, border'
  },
  {
    Id: 6828,
    Code: '3319',
    Name: 'Officer, immigration'
  },
  {
    Id: 6829,
    Code: '3319',
    Name: 'Officer, investigating, fraud, HM Revenue and Customs'
  },
  {
    Id: 6830,
    Code: '3319',
    Name: 'Officer, investigating, fraud'
  },
  {
    Id: 6831,
    Code: '3319',
    Name: 'Officer, investigating, HM Revenue and Customs'
  },
  {
    Id: 6832,
    Code: '3319',
    Name: 'Officer, investigating, police service'
  },
  {
    Id: 6833,
    Code: '3319',
    Name: 'Officer, investigation, fraud'
  },
  {
    Id: 6834,
    Code: '3319',
    Name: 'Officer, investigation, HM Revenue and Customs'
  },
  {
    Id: 6835,
    Code: '3319',
    Name: 'Officer, investigation, police service'
  },
  {
    Id: 6836,
    Code: '3319',
    Name: 'Officer, planning, emergency'
  },
  {
    Id: 6837,
    Code: '3319',
    Name: 'Officer, prevention, crime'
  },
  {
    Id: 6838,
    Code: '3319',
    Name: 'Officer, preventive'
  },
  {
    Id: 6839,
    Code: '3319',
    Name: 'Officer, scenes of crime'
  },
  {
    Id: 6840,
    Code: '3319',
    Name: 'Officer, security, maritime'
  },
  {
    Id: 6841,
    Code: '3319',
    Name: 'Officer, station, coastguard service'
  },
  {
    Id: 6842,
    Code: '3319',
    Name: 'Officer, submissions, forensic'
  },
  {
    Id: 6843,
    Code: '3319',
    Name: 'Officer, traffic, Highways Agency'
  },
  {
    Id: 6844,
    Code: '3319',
    Name: 'Officer, watch, coastguard'
  },
  {
    Id: 6845,
    Code: '3319',
    Name: 'Owner, agency, detective'
  },
  {
    Id: 6846,
    Code: '3319',
    Name: 'Owner, private detective agency'
  },
  {
    Id: 6847,
    Code: '3319',
    Name: 'Owner, security services'
  },
  {
    Id: 6848,
    Code: '3319',
    Name: 'Security, maritime'
  },
  {
    Id: 6849,
    Code: '3319',
    Name: 'Specialist, fraud, counter'
  },
  {
    Id: 6850,
    Code: '3319',
    Name: 'Steward, route, highways'
  },
  {
    Id: 6851,
    Code: '3319',
    Name: 'Superintendent, rescue, coal mine'
  },
  {
    Id: 6852,
    Code: '3319',
    Name: 'Superintendent, station, rescue'
  },
  {
    Id: 6853,
    Code: '3319',
    Name: 'Supervisor, payback, community, probation service'
  },
  {
    Id: 6854,
    Code: '3319',
    Name: 'Technician, camera, safety'
  },
  {
    Id: 6855,
    Code: '3319',
    Name: 'Waterguard, HM Revenue and Customs'
  },
  {
    Id: 6856,
    Code: '3411',
    Name: 'Animator, hand drawn'
  },
  {
    Id: 6857,
    Code: '3411',
    Name: 'Artist, fashion'
  },
  {
    Id: 6858,
    Code: '3411',
    Name: 'Artist, henna'
  },
  {
    Id: 6859,
    Code: '3411',
    Name: 'Artist, medical, hospital service'
  },
  {
    Id: 6860,
    Code: '3411',
    Name: 'Artist, press'
  },
  {
    Id: 6861,
    Code: '3411',
    Name: 'Artist, scenic'
  },
  {
    Id: 6862,
    Code: '3411',
    Name: 'Artist, tattoo, henna'
  },
  {
    Id: 6863,
    Code: '3411',
    Name: 'Artist, tattoo'
  },
  {
    Id: 6864,
    Code: '3411',
    Name: 'Artist, textile'
  },
  {
    Id: 6865,
    Code: '3411',
    Name: 'Artist, visual'
  },
  {
    Id: 6866,
    Code: '3411',
    Name: 'Artist, tattoo'
  },
  {
    Id: 6867,
    Code: '3411',
    Name: 'Artist'
  },
  {
    Id: 6868,
    Code: '3411',
    Name: 'Artist and designer, fashion'
  },
  {
    Id: 6869,
    Code: '3411',
    Name: 'Artist-designer'
  },
  {
    Id: 6870,
    Code: '3411',
    Name: 'Artist-illustrator'
  },
  {
    Id: 6871,
    Code: '3411',
    Name: "Assistant, sculptor's"
  },
  {
    Id: 6872,
    Code: '3411',
    Name: 'Calligrapher'
  },
  {
    Id: 6873,
    Code: '3411',
    Name: 'Cartoonist'
  },
  {
    Id: 6874,
    Code: '3411',
    Name: 'Cleaner, picture'
  },
  {
    Id: 6875,
    Code: '3411',
    Name: 'Colourist, copy'
  },
  {
    Id: 6876,
    Code: '3411',
    Name: 'Engraver, portrait'
  },
  {
    Id: 6877,
    Code: '3411',
    Name: 'Etcher, black and white'
  },
  {
    Id: 6878,
    Code: '3411',
    Name: 'Illustrator, architectural'
  },
  {
    Id: 6879,
    Code: '3411',
    Name: 'Illustrator, book'
  },
  {
    Id: 6880,
    Code: '3411',
    Name: 'Illustrator, chief'
  },
  {
    Id: 6881,
    Code: '3411',
    Name: 'Illustrator, fashion'
  },
  {
    Id: 6882,
    Code: '3411',
    Name: 'Illustrator, graphic'
  },
  {
    Id: 6883,
    Code: '3411',
    Name: 'Illustrator, leading'
  },
  {
    Id: 6884,
    Code: '3411',
    Name: 'Illustrator, medical'
  },
  {
    Id: 6885,
    Code: '3411',
    Name: 'Illustrator, senior'
  },
  {
    Id: 6886,
    Code: '3411',
    Name: 'Illustrator'
  },
  {
    Id: 6887,
    Code: '3411',
    Name: 'Modeller, artistic'
  },
  {
    Id: 6888,
    Code: '3411',
    Name: 'Painter, artistic'
  },
  {
    Id: 6889,
    Code: '3411',
    Name: 'Painter, colour, water'
  },
  {
    Id: 6890,
    Code: '3411',
    Name: 'Painter, landscape'
  },
  {
    Id: 6891,
    Code: '3411',
    Name: 'Painter, marine'
  },
  {
    Id: 6892,
    Code: '3411',
    Name: 'Painter, miniature'
  },
  {
    Id: 6893,
    Code: '3411',
    Name: 'Painter, portrait'
  },
  {
    Id: 6894,
    Code: '3411',
    Name: 'Restorer, art'
  },
  {
    Id: 6895,
    Code: '3411',
    Name: 'Restorer, picture'
  },
  {
    Id: 6896,
    Code: '3411',
    Name: 'Sculptor'
  },
  {
    Id: 6897,
    Code: '3411',
    Name: 'Tattooist'
  },
  {
    Id: 6898,
    Code: '3411',
    Name: 'Technician, art'
  },
  {
    Id: 6899,
    Code: '3412',
    Name: 'Adviser, literary'
  },
  {
    Id: 6900,
    Code: '3412',
    Name: 'Agent, literary'
  },
  {
    Id: 6901,
    Code: '3412',
    Name: 'Assistant, editorial'
  },
  {
    Id: 6902,
    Code: '3412',
    Name: 'Author, technical'
  },
  {
    Id: 6903,
    Code: '3412',
    Name: 'Author'
  },
  {
    Id: 6904,
    Code: '3412',
    Name: 'Bibliographer'
  },
  {
    Id: 6905,
    Code: '3412',
    Name: 'Biographer'
  },
  {
    Id: 6906,
    Code: '3412',
    Name: 'Blogger'
  },
  {
    Id: 6907,
    Code: '3412',
    Name: 'Communicator for the deaf'
  },
  {
    Id: 6908,
    Code: '3412',
    Name: 'Compiler, technical'
  },
  {
    Id: 6909,
    Code: '3412',
    Name: 'Consultant, editorial'
  },
  {
    Id: 6910,
    Code: '3412',
    Name: 'Consultant, language'
  },
  {
    Id: 6911,
    Code: '3412',
    Name: 'Consultant, publishing'
  },
  {
    Id: 6912,
    Code: '3412',
    Name: 'Copywriter'
  },
  {
    Id: 6913,
    Code: '3412',
    Name: 'Describer, audio'
  },
  {
    Id: 6914,
    Code: '3412',
    Name: 'Dramatist'
  },
  {
    Id: 6915,
    Code: '3412',
    Name: 'Editor, book'
  },
  {
    Id: 6916,
    Code: '3412',
    Name: 'Editor, cartographic'
  },
  {
    Id: 6917,
    Code: '3412',
    Name: 'Editor, commissioning, books'
  },
  {
    Id: 6918,
    Code: '3412',
    Name: 'Editor, managing, books'
  },
  {
    Id: 6919,
    Code: '3412',
    Name: 'Editor, sales'
  },
  {
    Id: 6920,
    Code: '3412',
    Name: 'Editor, technical'
  },
  {
    Id: 6921,
    Code: '3412',
    Name: 'Editor, books'
  },
  {
    Id: 6922,
    Code: '3412',
    Name: 'Editor-in-chief'
  },
  {
    Id: 6923,
    Code: '3412',
    Name: 'Interpreter, BSL'
  },
  {
    Id: 6924,
    Code: '3412',
    Name: 'Interpreter, language, sign, British'
  },
  {
    Id: 6925,
    Code: '3412',
    Name: 'Interpreter, service, public'
  },
  {
    Id: 6926,
    Code: '3412',
    Name: 'Interpreter'
  },
  {
    Id: 6927,
    Code: '3412',
    Name: 'Interpreter and translator'
  },
  {
    Id: 6928,
    Code: '3412',
    Name: 'Lexicographer'
  },
  {
    Id: 6929,
    Code: '3412',
    Name: 'Linguist'
  },
  {
    Id: 6930,
    Code: '3412',
    Name: 'Logger, broadcasting'
  },
  {
    Id: 6931,
    Code: '3412',
    Name: 'Manager, publications, technical'
  },
  {
    Id: 6932,
    Code: '3412',
    Name: 'Manager, publications, technical'
  },
  {
    Id: 6933,
    Code: '3412',
    Name: 'Novelist'
  },
  {
    Id: 6934,
    Code: '3412',
    Name: 'Playwright'
  },
  {
    Id: 6935,
    Code: '3412',
    Name: 'Poet'
  },
  {
    Id: 6936,
    Code: '3412',
    Name: 'Reader, lip'
  },
  {
    Id: 6937,
    Code: '3412',
    Name: 'Reader, literary'
  },
  {
    Id: 6938,
    Code: '3412',
    Name: "Reader, publisher's"
  },
  {
    Id: 6939,
    Code: '3412',
    Name: 'Screenwriter'
  },
  {
    Id: 6940,
    Code: '3412',
    Name: 'Speechwriter'
  },
  {
    Id: 6941,
    Code: '3412',
    Name: 'Subtitler'
  },
  {
    Id: 6942,
    Code: '3412',
    Name: 'Translator, technical'
  },
  {
    Id: 6943,
    Code: '3412',
    Name: 'Translator'
  },
  {
    Id: 6944,
    Code: '3412',
    Name: 'Translator and interpreter'
  },
  {
    Id: 6945,
    Code: '3412',
    Name: 'Writer, communications, corporate'
  },
  {
    Id: 6946,
    Code: '3412',
    Name: 'Writer, copy'
  },
  {
    Id: 6947,
    Code: '3412',
    Name: 'Writer, creative'
  },
  {
    Id: 6948,
    Code: '3412',
    Name: 'Writer, freelance'
  },
  {
    Id: 6949,
    Code: '3412',
    Name: 'Writer, lyric'
  },
  {
    Id: 6950,
    Code: '3412',
    Name: 'Writer, medical'
  },
  {
    Id: 6951,
    Code: '3412',
    Name: 'Writer, report'
  },
  {
    Id: 6952,
    Code: '3412',
    Name: 'Writer, reports, senior, broadcasting'
  },
  {
    Id: 6953,
    Code: '3412',
    Name: 'Writer, screen'
  },
  {
    Id: 6954,
    Code: '3412',
    Name: 'Writer, script'
  },
  {
    Id: 6955,
    Code: '3412',
    Name: 'Writer, specialist'
  },
  {
    Id: 6956,
    Code: '3412',
    Name: 'Writer, specifications'
  },
  {
    Id: 6957,
    Code: '3412',
    Name: 'Writer, subtitle'
  },
  {
    Id: 6958,
    Code: '3412',
    Name: 'Writer, technical, senior'
  },
  {
    Id: 6959,
    Code: '3412',
    Name: 'Writer, technical, patent'
  },
  {
    Id: 6960,
    Code: '3412',
    Name: 'Writer, technical'
  },
  {
    Id: 6961,
    Code: '3412',
    Name: 'Writer, travel'
  },
  {
    Id: 6962,
    Code: '3412',
    Name: 'Writer'
  },
  {
    Id: 6963,
    Code: '3412',
    Name: 'Writer and creator'
  },
  {
    Id: 6964,
    Code: '3413',
    Name: 'Acrobat'
  },
  {
    Id: 6965,
    Code: '3413',
    Name: 'Actor'
  },
  {
    Id: 6966,
    Code: '3413',
    Name: 'Actor-manager'
  },
  {
    Id: 6967,
    Code: '3413',
    Name: 'Aerialist'
  },
  {
    Id: 6968,
    Code: '3413',
    Name: 'Announcer, entertainment'
  },
  {
    Id: 6969,
    Code: '3413',
    Name: 'Announcer, broadcasting'
  },
  {
    Id: 6970,
    Code: '3413',
    Name: 'Artist, film'
  },
  {
    Id: 6971,
    Code: '3413',
    Name: 'Artist, over, voice'
  },
  {
    Id: 6972,
    Code: '3413',
    Name: 'Artist, recording'
  },
  {
    Id: 6973,
    Code: '3413',
    Name: 'Artist, supporting'
  },
  {
    Id: 6974,
    Code: '3413',
    Name: 'Artist, entertainment'
  },
  {
    Id: 6975,
    Code: '3413',
    Name: 'Artiste, supporting'
  },
  {
    Id: 6976,
    Code: '3413',
    Name: 'Artiste'
  },
  {
    Id: 6977,
    Code: '3413',
    Name: "Assistant, showman's"
  },
  {
    Id: 6978,
    Code: '3413',
    Name: 'Broadcaster'
  },
  {
    Id: 6979,
    Code: '3413',
    Name: 'Choirboy'
  },
  {
    Id: 6980,
    Code: '3413',
    Name: 'Chorister'
  },
  {
    Id: 6981,
    Code: '3413',
    Name: 'Clerk, lay'
  },
  {
    Id: 6982,
    Code: '3413',
    Name: 'Clown'
  },
  {
    Id: 6983,
    Code: '3413',
    Name: 'Coach, singing'
  },
  {
    Id: 6984,
    Code: '3413',
    Name: 'Comedian'
  },
  {
    Id: 6985,
    Code: '3413',
    Name: 'Commentator, broadcasting'
  },
  {
    Id: 6986,
    Code: '3413',
    Name: 'Compere'
  },
  {
    Id: 6987,
    Code: '3413',
    Name: 'Conjurer'
  },
  {
    Id: 6988,
    Code: '3413',
    Name: 'Correspondent, broadcasting'
  },
  {
    Id: 6989,
    Code: '3413',
    Name: 'DJ'
  },
  {
    Id: 6990,
    Code: '3413',
    Name: "Entertainer, children's"
  },
  {
    Id: 6991,
    Code: '3413',
    Name: 'Entertainer'
  },
  {
    Id: 6992,
    Code: '3413',
    Name: 'Extra, entertainment'
  },
  {
    Id: 6993,
    Code: '3413',
    Name: 'Girl, chorus'
  },
  {
    Id: 6994,
    Code: '3413',
    Name: 'Girl, show'
  },
  {
    Id: 6995,
    Code: '3413',
    Name: 'Hypnotist, entertainment'
  },
  {
    Id: 6996,
    Code: '3413',
    Name: 'Illusionist'
  },
  {
    Id: 6997,
    Code: '3413',
    Name: 'Impresario'
  },
  {
    Id: 6998,
    Code: '3413',
    Name: 'Impressionist'
  },
  {
    Id: 6999,
    Code: '3413',
    Name: 'Influencer, media, social'
  },
  {
    Id: 7000,
    Code: '3413',
    Name: 'Interpreter, costumed'
  },
  {
    Id: 7001,
    Code: '3413',
    Name: 'Interviewer, television'
  },
  {
    Id: 7002,
    Code: '3413',
    Name: 'Jockey, disc, broadcasting'
  },
  {
    Id: 7003,
    Code: '3413',
    Name: 'Jockey, disc'
  },
  {
    Id: 7004,
    Code: '3413',
    Name: 'Judge, entertainment'
  },
  {
    Id: 7005,
    Code: '3413',
    Name: 'Juggler'
  },
  {
    Id: 7006,
    Code: '3413',
    Name: 'Livestreamer'
  },
  {
    Id: 7007,
    Code: '3413',
    Name: 'Magician'
  },
  {
    Id: 7008,
    Code: '3413',
    Name: 'Man, stunt'
  },
  {
    Id: 7009,
    Code: '3413',
    Name: 'Mannequin'
  },
  {
    Id: 7010,
    Code: '3413',
    Name: 'Model, fashion'
  },
  {
    Id: 7011,
    Code: '3413',
    Name: 'Model'
  },
  {
    Id: 7012,
    Code: '3413',
    Name: 'Newscaster, broadcasting'
  },
  {
    Id: 7013,
    Code: '3413',
    Name: 'Newsreader'
  },
  {
    Id: 7014,
    Code: '3413',
    Name: 'Officer, entertainments'
  },
  {
    Id: 7015,
    Code: '3413',
    Name: 'Performer, circus'
  },
  {
    Id: 7016,
    Code: '3413',
    Name: 'Performer'
  },
  {
    Id: 7017,
    Code: '3413',
    Name: 'Podcaster'
  },
  {
    Id: 7018,
    Code: '3413',
    Name: 'Practitioner, theatre, entertainment'
  },
  {
    Id: 7019,
    Code: '3413',
    Name: 'Presenter, radio'
  },
  {
    Id: 7020,
    Code: '3413',
    Name: 'Presenter, television'
  },
  {
    Id: 7021,
    Code: '3413',
    Name: 'Presenter, broadcasting'
  },
  {
    Id: 7022,
    Code: '3413',
    Name: 'Puppeteer'
  },
  {
    Id: 7023,
    Code: '3413',
    Name: 'Reader, news, broadcasting'
  },
  {
    Id: 7024,
    Code: '3413',
    Name: 'Reporter, broadcasting'
  },
  {
    Id: 7025,
    Code: '3413',
    Name: 'Rider, entertainment'
  },
  {
    Id: 7026,
    Code: '3413',
    Name: 'Showman'
  },
  {
    Id: 7027,
    Code: '3413',
    Name: 'Singer'
  },
  {
    Id: 7028,
    Code: '3413',
    Name: 'Skater, ice'
  },
  {
    Id: 7029,
    Code: '3413',
    Name: 'Tamer, animal'
  },
  {
    Id: 7030,
    Code: '3413',
    Name: 'Trainer, animal, circus'
  },
  {
    Id: 7031,
    Code: '3413',
    Name: 'Trainer, animal, performing animals'
  },
  {
    Id: 7032,
    Code: '3413',
    Name: 'Understudy'
  },
  {
    Id: 7033,
    Code: '3413',
    Name: 'Ventriloquist'
  },
  {
    Id: 7034,
    Code: '3413',
    Name: 'Vlogger'
  },
  {
    Id: 7035,
    Code: '3413',
    Name: 'Vocalist'
  },
  {
    Id: 7036,
    Code: '3413',
    Name: 'YouTuber'
  },
  {
    Id: 7037,
    Code: '3414',
    Name: 'Artist, dance'
  },
  {
    Id: 7038,
    Code: '3414',
    Name: 'Ballerina'
  },
  {
    Id: 7039,
    Code: '3414',
    Name: 'Choreographer'
  },
  {
    Id: 7040,
    Code: '3414',
    Name: 'Coach, dance'
  },
  {
    Id: 7041,
    Code: '3414',
    Name: 'Dancer, ballet'
  },
  {
    Id: 7042,
    Code: '3414',
    Name: 'Dancer'
  },
  {
    Id: 7043,
    Code: '3414',
    Name: 'Instructor, dance'
  },
  {
    Id: 7044,
    Code: '3414',
    Name: 'Master, ballet'
  },
  {
    Id: 7045,
    Code: '3414',
    Name: 'Partner, dancing'
  },
  {
    Id: 7046,
    Code: '3414',
    Name: 'Teacher, ballet'
  },
  {
    Id: 7047,
    Code: '3414',
    Name: 'Teacher, dance'
  },
  {
    Id: 7048,
    Code: '3414',
    Name: 'Teacher, dancing'
  },
  {
    Id: 7049,
    Code: '3415',
    Name: 'Accompanist'
  },
  {
    Id: 7050,
    Code: '3415',
    Name: 'Arranger, music'
  },
  {
    Id: 7051,
    Code: '3415',
    Name: 'Bandmaster'
  },
  {
    Id: 7052,
    Code: '3415',
    Name: 'Bandsman'
  },
  {
    Id: 7053,
    Code: '3415',
    Name: 'Bassoonist'
  },
  {
    Id: 7054,
    Code: '3415',
    Name: 'Campanologist'
  },
  {
    Id: 7055,
    Code: '3415',
    Name: 'Cellist'
  },
  {
    Id: 7056,
    Code: '3415',
    Name: 'Clarinettist'
  },
  {
    Id: 7057,
    Code: '3415',
    Name: 'Composer'
  },
  {
    Id: 7058,
    Code: '3415',
    Name: 'Conductor, music'
  },
  {
    Id: 7059,
    Code: '3415',
    Name: 'Conductor, musical'
  },
  {
    Id: 7060,
    Code: '3415',
    Name: 'Conductor, entertainment'
  },
  {
    Id: 7061,
    Code: '3415',
    Name: 'Copyist, music'
  },
  {
    Id: 7062,
    Code: '3415',
    Name: 'Cornetist'
  },
  {
    Id: 7063,
    Code: '3415',
    Name: 'Director, musical'
  },
  {
    Id: 7064,
    Code: '3415',
    Name: 'Director of music, entertainment'
  },
  {
    Id: 7065,
    Code: '3415',
    Name: 'Drummer, entertainment'
  },
  {
    Id: 7066,
    Code: '3415',
    Name: 'Flautist'
  },
  {
    Id: 7067,
    Code: '3415',
    Name: 'Guitarist'
  },
  {
    Id: 7068,
    Code: '3415',
    Name: 'Harpist'
  },
  {
    Id: 7069,
    Code: '3415',
    Name: 'Instrumentalist'
  },
  {
    Id: 7070,
    Code: '3415',
    Name: 'Leader, band'
  },
  {
    Id: 7071,
    Code: '3415',
    Name: 'Leader, orchestra'
  },
  {
    Id: 7072,
    Code: '3415',
    Name: 'Major, pipe'
  },
  {
    Id: 7073,
    Code: '3415',
    Name: 'Master, band'
  },
  {
    Id: 7074,
    Code: '3415',
    Name: 'Musician, classical'
  },
  {
    Id: 7075,
    Code: '3415',
    Name: 'Musician'
  },
  {
    Id: 7076,
    Code: '3415',
    Name: 'Oboist'
  },
  {
    Id: 7077,
    Code: '3415',
    Name: 'Orchestrator'
  },
  {
    Id: 7078,
    Code: '3415',
    Name: 'Organiser, music'
  },
  {
    Id: 7079,
    Code: '3415',
    Name: 'Organist, church'
  },
  {
    Id: 7080,
    Code: '3415',
    Name: 'Organist'
  },
  {
    Id: 7081,
    Code: '3415',
    Name: 'Percussionist'
  },
  {
    Id: 7082,
    Code: '3415',
    Name: 'Pianist'
  },
  {
    Id: 7083,
    Code: '3415',
    Name: 'Player, musical instruments'
  },
  {
    Id: 7084,
    Code: '3415',
    Name: 'Ringer, bell'
  },
  {
    Id: 7085,
    Code: '3415',
    Name: 'Transcriber, music'
  },
  {
    Id: 7086,
    Code: '3415',
    Name: 'Trombonist'
  },
  {
    Id: 7087,
    Code: '3415',
    Name: 'Tympanist'
  },
  {
    Id: 7088,
    Code: '3415',
    Name: 'Violinist'
  },
  {
    Id: 7089,
    Code: '3415',
    Name: 'Violoncellist'
  },
  {
    Id: 7090,
    Code: '3415',
    Name: 'Writer, music'
  },
  {
    Id: 7091,
    Code: '3415',
    Name: 'Writer, song'
  },
  {
    Id: 7092,
    Code: '3416',
    Name: 'Adviser, art'
  },
  {
    Id: 7093,
    Code: '3416',
    Name: 'Adviser, arts'
  },
  {
    Id: 7094,
    Code: '3416',
    Name: 'Arranger, fight'
  },
  {
    Id: 7095,
    Code: '3416',
    Name: 'Assistant, broadcast, radio'
  },
  {
    Id: 7096,
    Code: '3416',
    Name: 'Assistant, broadcast'
  },
  {
    Id: 7097,
    Code: '3416',
    Name: 'Assistant, edit, film, television production'
  },
  {
    Id: 7098,
    Code: '3416',
    Name: "Assistant, producer's, entertainment"
  },
  {
    Id: 7099,
    Code: '3416',
    Name: "Assistant, producer's, broadcasting"
  },
  {
    Id: 7100,
    Code: '3416',
    Name: 'Assistant, production, television'
  },
  {
    Id: 7101,
    Code: '3416',
    Name: 'Assistant, production, video'
  },
  {
    Id: 7102,
    Code: '3416',
    Name: 'Assistant, production, broadcasting'
  },
  {
    Id: 7103,
    Code: '3416',
    Name: 'Assistant, programme, broadcasting'
  },
  {
    Id: 7104,
    Code: '3416',
    Name: 'Consultant, art'
  },
  {
    Id: 7105,
    Code: '3416',
    Name: 'Consultant, creative'
  },
  {
    Id: 7106,
    Code: '3416',
    Name: 'Consultant, entertainment'
  },
  {
    Id: 7107,
    Code: '3416',
    Name: 'Consultant, production, broadcasting'
  },
  {
    Id: 7108,
    Code: '3416',
    Name: 'Controller, programme, broadcasting'
  },
  {
    Id: 7109,
    Code: '3416',
    Name: 'Coordinator, facilities, editing'
  },
  {
    Id: 7110,
    Code: '3416',
    Name: 'Coordinator, production, broadcasting'
  },
  {
    Id: 7111,
    Code: '3416',
    Name: 'Director, animation'
  },
  {
    Id: 7112,
    Code: '3416',
    Name: 'Director, artistic'
  },
  {
    Id: 7113,
    Code: '3416',
    Name: 'Director, casting, entertainment'
  },
  {
    Id: 7114,
    Code: '3416',
    Name: 'Director, casting, broadcasting'
  },
  {
    Id: 7115,
    Code: '3416',
    Name: 'Director, film'
  },
  {
    Id: 7116,
    Code: '3416',
    Name: 'Director, network, broadcasting'
  },
  {
    Id: 7117,
    Code: '3416',
    Name: 'Director, presentation, broadcasting'
  },
  {
    Id: 7118,
    Code: '3416',
    Name: 'Director, programme, television'
  },
  {
    Id: 7119,
    Code: '3416',
    Name: 'Director, stage'
  },
  {
    Id: 7120,
    Code: '3416',
    Name: 'Director, technical, broadcasting'
  },
  {
    Id: 7121,
    Code: '3416',
    Name: 'Director, television'
  },
  {
    Id: 7122,
    Code: '3416',
    Name: 'Director, theatre'
  },
  {
    Id: 7123,
    Code: '3416',
    Name: 'Director, TV'
  },
  {
    Id: 7124,
    Code: '3416',
    Name: 'Director, video'
  },
  {
    Id: 7125,
    Code: '3416',
    Name: 'Director, visual artist and creative'
  },
  {
    Id: 7126,
    Code: '3416',
    Name: 'Director, broadcasting'
  },
  {
    Id: 7127,
    Code: '3416',
    Name: 'Director of production, entertainment'
  },
  {
    Id: 7128,
    Code: '3416',
    Name: 'Director of production, broadcasting'
  },
  {
    Id: 7129,
    Code: '3416',
    Name: 'Director of programmes'
  },
  {
    Id: 7130,
    Code: '3416',
    Name: 'Editor, broadcast'
  },
  {
    Id: 7131,
    Code: '3416',
    Name: 'Editor, film'
  },
  {
    Id: 7132,
    Code: '3416',
    Name: 'Editor, picture, broadcasting'
  },
  {
    Id: 7133,
    Code: '3416',
    Name: 'Editor, tape, video'
  },
  {
    Id: 7134,
    Code: '3416',
    Name: 'Editor, TV'
  },
  {
    Id: 7135,
    Code: '3416',
    Name: 'Editor, video'
  },
  {
    Id: 7136,
    Code: '3416',
    Name: 'Editor, broadcasting'
  },
  {
    Id: 7137,
    Code: '3416',
    Name: 'Editor-in-charge, broadcasting'
  },
  {
    Id: 7138,
    Code: '3416',
    Name: 'Engineer, production, post'
  },
  {
    Id: 7139,
    Code: '3416',
    Name: 'Filmaker'
  },
  {
    Id: 7140,
    Code: '3416',
    Name: 'Filmmaker, documentary'
  },
  {
    Id: 7141,
    Code: '3416',
    Name: 'Maker, film'
  },
  {
    Id: 7142,
    Code: '3416',
    Name: 'Manager, floor, entertainment'
  },
  {
    Id: 7143,
    Code: '3416',
    Name: 'Manager, floor, broadcasting'
  },
  {
    Id: 7144,
    Code: '3416',
    Name: 'Manager, location'
  },
  {
    Id: 7145,
    Code: '3416',
    Name: 'Manager, stage, entertainment'
  },
  {
    Id: 7146,
    Code: '3416',
    Name: 'Manager, stage, broadcasting'
  },
  {
    Id: 7147,
    Code: '3416',
    Name: 'Manager, studio, entertainment'
  },
  {
    Id: 7148,
    Code: '3416',
    Name: 'Manager, studio, broadcasting'
  },
  {
    Id: 7149,
    Code: '3416',
    Name: 'Manager, studio'
  },
  {
    Id: 7150,
    Code: '3416',
    Name: 'Master, choir'
  },
  {
    Id: 7151,
    Code: '3416',
    Name: 'Monitor, broadcasting'
  },
  {
    Id: 7152,
    Code: '3416',
    Name: 'Officer, arts'
  },
  {
    Id: 7153,
    Code: '3416',
    Name: 'Officer, development, arts'
  },
  {
    Id: 7154,
    Code: '3416',
    Name: 'Operator, autocue'
  },
  {
    Id: 7155,
    Code: '3416',
    Name: 'Operator, television, broadcasting'
  },
  {
    Id: 7156,
    Code: '3416',
    Name: 'Organiser, drama'
  },
  {
    Id: 7157,
    Code: '3416',
    Name: 'Organiser, programme, broadcasting'
  },
  {
    Id: 7158,
    Code: '3416',
    Name: 'Producer, animation'
  },
  {
    Id: 7159,
    Code: '3416',
    Name: 'Producer, content, broadcasting'
  },
  {
    Id: 7160,
    Code: '3416',
    Name: 'Producer, film'
  },
  {
    Id: 7161,
    Code: '3416',
    Name: 'Producer, line'
  },
  {
    Id: 7162,
    Code: '3416',
    Name: 'Producer, multimedia'
  },
  {
    Id: 7163,
    Code: '3416',
    Name: 'Producer, music'
  },
  {
    Id: 7164,
    Code: '3416',
    Name: 'Producer, online, broadcasting'
  },
  {
    Id: 7165,
    Code: '3416',
    Name: 'Producer, radio'
  },
  {
    Id: 7166,
    Code: '3416',
    Name: 'Producer, record'
  },
  {
    Id: 7167,
    Code: '3416',
    Name: 'Producer, series'
  },
  {
    Id: 7168,
    Code: '3416',
    Name: 'Producer, television'
  },
  {
    Id: 7169,
    Code: '3416',
    Name: 'Producer, theatre'
  },
  {
    Id: 7170,
    Code: '3416',
    Name: 'Producer, TV'
  },
  {
    Id: 7171,
    Code: '3416',
    Name: 'Producer, video'
  },
  {
    Id: 7172,
    Code: '3416',
    Name: 'Producer, entertainment'
  },
  {
    Id: 7173,
    Code: '3416',
    Name: 'Producer, broadcasting'
  },
  {
    Id: 7174,
    Code: '3416',
    Name: 'Prompter'
  },
  {
    Id: 7175,
    Code: '3416',
    Name: 'Scheduler, presentation, broadcasting'
  },
  {
    Id: 7176,
    Code: '3416',
    Name: 'Scheduler, television'
  },
  {
    Id: 7177,
    Code: '3416',
    Name: 'Scheduler, TV'
  },
  {
    Id: 7178,
    Code: '3416',
    Name: 'Supervisor, production, post, film, television production'
  },
  {
    Id: 7179,
    Code: '3416',
    Name: 'Supervisor, script'
  },
  {
    Id: 7180,
    Code: '3416',
    Name: 'Technician, production, video'
  },
  {
    Id: 7181,
    Code: '3417',
    Name: 'Artist, photographic'
  },
  {
    Id: 7182,
    Code: '3417',
    Name: 'Assistant, camera, first'
  },
  {
    Id: 7183,
    Code: '3417',
    Name: 'Assistant, camera, second'
  },
  {
    Id: 7184,
    Code: '3417',
    Name: "Assistant, cameraman's"
  },
  {
    Id: 7185,
    Code: '3417',
    Name: 'Assistant, film'
  },
  {
    Id: 7186,
    Code: '3417',
    Name: "Assistant, photographer's"
  },
  {
    Id: 7187,
    Code: '3417',
    Name: 'Assistant, photographic'
  },
  {
    Id: 7188,
    Code: '3417',
    Name: 'Assistant, sound'
  },
  {
    Id: 7189,
    Code: '3417',
    Name: 'Assistant, studio'
  },
  {
    Id: 7190,
    Code: '3417',
    Name: 'Balancer, sound'
  },
  {
    Id: 7191,
    Code: '3417',
    Name: 'Cameraman, chief, films'
  },
  {
    Id: 7192,
    Code: '3417',
    Name: 'Cameraman'
  },
  {
    Id: 7193,
    Code: '3417',
    Name: 'Cinematographer'
  },
  {
    Id: 7194,
    Code: '3417',
    Name: 'Controller, sound'
  },
  {
    Id: 7195,
    Code: '3417',
    Name: 'Designer, lighting'
  },
  {
    Id: 7196,
    Code: '3417',
    Name: 'Designer, sound'
  },
  {
    Id: 7197,
    Code: '3417',
    Name: 'Director, lighting, technical'
  },
  {
    Id: 7198,
    Code: '3417',
    Name: 'Director, lighting, theatre'
  },
  {
    Id: 7199,
    Code: '3417',
    Name: 'Director of photography'
  },
  {
    Id: 7200,
    Code: '3417',
    Name: 'Editor, camera, video'
  },
  {
    Id: 7201,
    Code: '3417',
    Name: 'Editor, dubbing'
  },
  {
    Id: 7202,
    Code: '3417',
    Name: 'Editor, sound'
  },
  {
    Id: 7203,
    Code: '3417',
    Name: 'Engineer, audio, recording'
  },
  {
    Id: 7204,
    Code: '3417',
    Name: 'Engineer, camera, video'
  },
  {
    Id: 7205,
    Code: '3417',
    Name: 'Engineer, control, vision'
  },
  {
    Id: 7206,
    Code: '3417',
    Name: 'Engineer, recording'
  },
  {
    Id: 7207,
    Code: '3417',
    Name: 'Engineer, sound'
  },
  {
    Id: 7208,
    Code: '3417',
    Name: 'Engineer, studio, music'
  },
  {
    Id: 7209,
    Code: '3417',
    Name: 'Engineer, vision, broadcasting'
  },
  {
    Id: 7210,
    Code: '3417',
    Name: 'Manager, audio-visual'
  },
  {
    Id: 7211,
    Code: '3417',
    Name: 'Manager, lighting, film, television production'
  },
  {
    Id: 7212,
    Code: '3417',
    Name: 'Manager, lighting, television service'
  },
  {
    Id: 7213,
    Code: '3417',
    Name: 'Manager, sound, theatre'
  },
  {
    Id: 7214,
    Code: '3417',
    Name: 'Manager, technical, lighting'
  },
  {
    Id: 7215,
    Code: '3417',
    Name: 'Manager, visual, audio'
  },
  {
    Id: 7216,
    Code: '3417',
    Name: 'Mixer, dubbing'
  },
  {
    Id: 7217,
    Code: '3417',
    Name: 'Mixer, sound'
  },
  {
    Id: 7218,
    Code: '3417',
    Name: 'Mixer, vision, television service'
  },
  {
    Id: 7219,
    Code: '3417',
    Name: 'Mixer, film, television production'
  },
  {
    Id: 7220,
    Code: '3417',
    Name: 'Officer, aids, visual'
  },
  {
    Id: 7221,
    Code: '3417',
    Name: 'Officer, resources, media'
  },
  {
    Id: 7222,
    Code: '3417',
    Name: 'Operator, aids, audio-visual'
  },
  {
    Id: 7223,
    Code: '3417',
    Name: 'Operator, boom, film, television production'
  },
  {
    Id: 7224,
    Code: '3417',
    Name: 'Operator, camera, video'
  },
  {
    Id: 7225,
    Code: '3417',
    Name: 'Operator, camera, film, television production'
  },
  {
    Id: 7226,
    Code: '3417',
    Name: 'Operator, camera, printing'
  },
  {
    Id: 7227,
    Code: '3417',
    Name: 'Operator, camera, process engraving'
  },
  {
    Id: 7228,
    Code: '3417',
    Name: 'Operator, camera, television service'
  },
  {
    Id: 7229,
    Code: '3417',
    Name: 'Operator, cinema'
  },
  {
    Id: 7230,
    Code: '3417',
    Name: 'Operator, cinematograph'
  },
  {
    Id: 7231,
    Code: '3417',
    Name: 'Operator, control, sound'
  },
  {
    Id: 7232,
    Code: '3417',
    Name: 'Operator, equipment, video'
  },
  {
    Id: 7233,
    Code: '3417',
    Name: 'Operator, limelight'
  },
  {
    Id: 7234,
    Code: '3417',
    Name: 'Operator, photo, lithography'
  },
  {
    Id: 7235,
    Code: '3417',
    Name: 'Operator, photo-litho'
  },
  {
    Id: 7236,
    Code: '3417',
    Name: 'Operator, photographic'
  },
  {
    Id: 7237,
    Code: '3417',
    Name: 'Operator, recorder, film'
  },
  {
    Id: 7238,
    Code: '3417',
    Name: 'Operator, recorder, videotape'
  },
  {
    Id: 7239,
    Code: '3417',
    Name: 'Operator, studio, photogravure'
  },
  {
    Id: 7240,
    Code: '3417',
    Name: 'Operator, tape'
  },
  {
    Id: 7241,
    Code: '3417',
    Name: 'Operator, technical, broadcasting'
  },
  {
    Id: 7242,
    Code: '3417',
    Name: 'Operator, telecine'
  },
  {
    Id: 7243,
    Code: '3417',
    Name: 'Operator, video'
  },
  {
    Id: 7244,
    Code: '3417',
    Name: 'Operator, VT'
  },
  {
    Id: 7245,
    Code: '3417',
    Name: 'Photographer'
  },
  {
    Id: 7246,
    Code: '3417',
    Name: 'Pilot, drone, commercial'
  },
  {
    Id: 7247,
    Code: '3417',
    Name: 'Projectionist'
  },
  {
    Id: 7248,
    Code: '3417',
    Name: 'Puller, focus'
  },
  {
    Id: 7249,
    Code: '3417',
    Name: 'Recorder, sound recording'
  },
  {
    Id: 7250,
    Code: '3417',
    Name: 'Recordist, sound'
  },
  {
    Id: 7251,
    Code: '3417',
    Name: 'Rigger, animation'
  },
  {
    Id: 7252,
    Code: '3417',
    Name: 'Soundman'
  },
  {
    Id: 7253,
    Code: '3417',
    Name: 'Supervisor, vision, broadcasting'
  },
  {
    Id: 7254,
    Code: '3417',
    Name: 'Technician, aids, visual'
  },
  {
    Id: 7255,
    Code: '3417',
    Name: 'Technician, arts, performing'
  },
  {
    Id: 7256,
    Code: '3417',
    Name: 'Technician, AV'
  },
  {
    Id: 7257,
    Code: '3417',
    Name: 'Technician, backline'
  },
  {
    Id: 7258,
    Code: '3417',
    Name: 'Technician, backstage'
  },
  {
    Id: 7259,
    Code: '3417',
    Name: 'Technician, broadcast'
  },
  {
    Id: 7260,
    Code: '3417',
    Name: 'Technician, camera, video'
  },
  {
    Id: 7261,
    Code: '3417',
    Name: 'Technician, cine'
  },
  {
    Id: 7262,
    Code: '3417',
    Name: 'Technician, film'
  },
  {
    Id: 7263,
    Code: '3417',
    Name: 'Technician, imaging, digital'
  },
  {
    Id: 7264,
    Code: '3417',
    Name: 'Technician, media'
  },
  {
    Id: 7265,
    Code: '3417',
    Name: 'Technician, photographic'
  },
  {
    Id: 7266,
    Code: '3417',
    Name: 'Technician, sound'
  },
  {
    Id: 7267,
    Code: '3417',
    Name: 'Technician, stage, theatre'
  },
  {
    Id: 7268,
    Code: '3417',
    Name: 'Technician, stage, theatre'
  },
  {
    Id: 7269,
    Code: '3417',
    Name: 'Technician, theatre, entertainment'
  },
  {
    Id: 7270,
    Code: '3417',
    Name: 'Technician, visual, audio'
  },
  {
    Id: 7271,
    Code: '3417',
    Name: 'Technician, film studio'
  },
  {
    Id: 7272,
    Code: '3417',
    Name: 'Videographer'
  },
  {
    Id: 7273,
    Code: '3421',
    Name: 'Assistant, design, interior'
  },
  {
    Id: 7274,
    Code: '3421',
    Name: 'Assistant, design, interior design'
  },
  {
    Id: 7275,
    Code: '3421',
    Name: 'Consultant, design, interior'
  },
  {
    Id: 7276,
    Code: '3421',
    Name: 'Consultant, design, interior design'
  },
  {
    Id: 7277,
    Code: '3421',
    Name: 'Decorator, interior'
  },
  {
    Id: 7278,
    Code: '3421',
    Name: 'Designer, bathroom'
  },
  {
    Id: 7279,
    Code: '3421',
    Name: 'Designer, décor'
  },
  {
    Id: 7280,
    Code: '3421',
    Name: 'Designer, furnishing, soft'
  },
  {
    Id: 7281,
    Code: '3421',
    Name: 'Designer, furniture'
  },
  {
    Id: 7282,
    Code: '3421',
    Name: 'Designer, interior'
  },
  {
    Id: 7283,
    Code: '3421',
    Name: 'Designer, interior and spatial'
  },
  {
    Id: 7284,
    Code: '3421',
    Name: 'Designer, kitchen'
  },
  {
    Id: 7285,
    Code: '3421',
    Name: 'Designer, sales, kitchen'
  },
  {
    Id: 7286,
    Code: '3421',
    Name: 'Designer, shopfitting'
  },
  {
    Id: 7287,
    Code: '3421',
    Name: 'Designer, wallpaper'
  },
  {
    Id: 7288,
    Code: '3421',
    Name: 'Designer, wallpaper'
  },
  {
    Id: 7289,
    Code: '3421',
    Name: 'Designer, interior design'
  },
  {
    Id: 7290,
    Code: '3421',
    Name: 'Designer, soft furnishings mfr'
  },
  {
    Id: 7291,
    Code: '3421',
    Name: 'Leader, team, interior design'
  },
  {
    Id: 7292,
    Code: '3421',
    Name: 'Planner, kitchen'
  },
  {
    Id: 7293,
    Code: '3421',
    Name: 'Stylist, interior'
  },
  {
    Id: 7294,
    Code: '3422',
    Name: 'Adviser, fashion'
  },
  {
    Id: 7295,
    Code: '3422',
    Name: 'Artist, boot and shoe'
  },
  {
    Id: 7296,
    Code: '3422',
    Name: 'Artist, shoe'
  },
  {
    Id: 7297,
    Code: '3422',
    Name: 'Assistant, design, clothing'
  },
  {
    Id: 7298,
    Code: '3422',
    Name: 'Assistant, design, fashion'
  },
  {
    Id: 7299,
    Code: '3422',
    Name: 'Assistant, design, print, textile designing'
  },
  {
    Id: 7300,
    Code: '3422',
    Name: 'Assistant, design, set'
  },
  {
    Id: 7301,
    Code: '3422',
    Name: 'Assistant, design, textile'
  },
  {
    Id: 7302,
    Code: '3422',
    Name: 'Assistant, design, textile'
  },
  {
    Id: 7303,
    Code: '3422',
    Name: 'Assistant, design, clothing, fashion & accessories'
  },
  {
    Id: 7304,
    Code: '3422',
    Name: 'Consultant, bridal'
  },
  {
    Id: 7305,
    Code: '3422',
    Name: 'Consultant, fashion'
  },
  {
    Id: 7306,
    Code: '3422',
    Name: 'Couturier'
  },
  {
    Id: 7307,
    Code: '3422',
    Name: 'Designer, accessory'
  },
  {
    Id: 7308,
    Code: '3422',
    Name: 'Designer, apparel'
  },
  {
    Id: 7309,
    Code: '3422',
    Name: 'Designer, bridalwear'
  },
  {
    Id: 7310,
    Code: '3422',
    Name: 'Designer, clothes'
  },
  {
    Id: 7311,
    Code: '3422',
    Name: 'Designer, clothing'
  },
  {
    Id: 7312,
    Code: '3422',
    Name: 'Designer, costume'
  },
  {
    Id: 7313,
    Code: '3422',
    Name: 'Designer, dress'
  },
  {
    Id: 7314,
    Code: '3422',
    Name: 'Designer, embroidery'
  },
  {
    Id: 7315,
    Code: '3422',
    Name: 'Designer, fashion'
  },
  {
    Id: 7316,
    Code: '3422',
    Name: 'Designer, footwear'
  },
  {
    Id: 7317,
    Code: '3422',
    Name: 'Designer, gem'
  },
  {
    Id: 7318,
    Code: '3422',
    Name: 'Designer, handbag'
  },
  {
    Id: 7319,
    Code: '3422',
    Name: 'Designer, hat'
  },
  {
    Id: 7320,
    Code: '3422',
    Name: 'Designer, jewellery'
  },
  {
    Id: 7321,
    Code: '3422',
    Name: 'Designer, knitwear'
  },
  {
    Id: 7322,
    Code: '3422',
    Name: 'Designer, pattern, textile printing'
  },
  {
    Id: 7323,
    Code: '3422',
    Name: 'Designer, silversmith'
  },
  {
    Id: 7324,
    Code: '3422',
    Name: 'Designer, textile'
  },
  {
    Id: 7325,
    Code: '3422',
    Name: 'Designer, clothing'
  },
  {
    Id: 7326,
    Code: '3422',
    Name: 'Designer, fashion'
  },
  {
    Id: 7327,
    Code: '3422',
    Name: 'Designer, footwear'
  },
  {
    Id: 7328,
    Code: '3422',
    Name: 'Designer, jewellery'
  },
  {
    Id: 7329,
    Code: '3422',
    Name: 'Designer, fur goods mfr'
  },
  {
    Id: 7330,
    Code: '3422',
    Name: 'Designer, millinery mfr'
  },
  {
    Id: 7331,
    Code: '3422',
    Name: 'Designer, textile mfr'
  },
  {
    Id: 7332,
    Code: '3422',
    Name: 'Jeweller, design'
  },
  {
    Id: 7333,
    Code: '3422',
    Name: 'Jeweller, design, retail trade'
  },
  {
    Id: 7334,
    Code: '3422',
    Name: 'Planner, footwear'
  },
  {
    Id: 7335,
    Code: '3422',
    Name: 'Predictor, fashion'
  },
  {
    Id: 7336,
    Code: '3429',
    Name: 'Artist, make-up, films'
  },
  {
    Id: 7337,
    Code: '3429',
    Name: 'Artist, make-up, theatre'
  },
  {
    Id: 7338,
    Code: '3429',
    Name: 'Assistant, design'
  },
  {
    Id: 7339,
    Code: '3429',
    Name: 'Assistant, studio, design'
  },
  {
    Id: 7340,
    Code: '3429',
    Name: 'Consultant, design'
  },
  {
    Id: 7341,
    Code: '3429',
    Name: 'Consultant, packaging'
  },
  {
    Id: 7342,
    Code: '3429',
    Name: 'Designer, body, vehicle mfr'
  },
  {
    Id: 7343,
    Code: '3429',
    Name: 'Designer, book'
  },
  {
    Id: 7344,
    Code: '3429',
    Name: 'Designer, card'
  },
  {
    Id: 7345,
    Code: '3429',
    Name: 'Designer, ceramic'
  },
  {
    Id: 7346,
    Code: '3429',
    Name: 'Designer, cloth'
  },
  {
    Id: 7347,
    Code: '3429',
    Name: 'Designer, commercial'
  },
  {
    Id: 7348,
    Code: '3429',
    Name: 'Designer, craft'
  },
  {
    Id: 7349,
    Code: '3429',
    Name: 'Designer, games, board games'
  },
  {
    Id: 7350,
    Code: '3429',
    Name: 'Designer, glass'
  },
  {
    Id: 7351,
    Code: '3429',
    Name: 'Designer, industrial'
  },
  {
    Id: 7352,
    Code: '3429',
    Name: 'Designer, instrument'
  },
  {
    Id: 7353,
    Code: '3429',
    Name: 'Designer, instrumentation'
  },
  {
    Id: 7354,
    Code: '3429',
    Name: 'Designer, lithographic'
  },
  {
    Id: 7355,
    Code: '3429',
    Name: 'Designer, merchandise, visual'
  },
  {
    Id: 7356,
    Code: '3429',
    Name: 'Designer, metalwork'
  },
  {
    Id: 7357,
    Code: '3429',
    Name: 'Designer, packaging'
  },
  {
    Id: 7358,
    Code: '3429',
    Name: 'Designer, pottery'
  },
  {
    Id: 7359,
    Code: '3429',
    Name: "Designer, printer's"
  },
  {
    Id: 7360,
    Code: '3429',
    Name: 'Designer, product'
  },
  {
    Id: 7361,
    Code: '3429',
    Name: 'Designer, production, film, television production'
  },
  {
    Id: 7362,
    Code: '3429',
    Name: 'Designer, set'
  },
  {
    Id: 7363,
    Code: '3429',
    Name: 'Designer, stage'
  },
  {
    Id: 7364,
    Code: '3429',
    Name: 'Designer, toy'
  },
  {
    Id: 7365,
    Code: '3429',
    Name: 'Designer, window, retail trade'
  },
  {
    Id: 7366,
    Code: '3429',
    Name: 'Designer, ceramics'
  },
  {
    Id: 7367,
    Code: '3429',
    Name: 'Designer, glassware'
  },
  {
    Id: 7368,
    Code: '3429',
    Name: 'Designer, leather goods'
  },
  {
    Id: 7369,
    Code: '3429',
    Name: 'Designer, plastics goods'
  },
  {
    Id: 7370,
    Code: '3429',
    Name: 'Designer, scenery'
  },
  {
    Id: 7371,
    Code: '3429',
    Name: 'Designer, vehicles'
  },
  {
    Id: 7372,
    Code: '3429',
    Name: 'Designer, wood products'
  },
  {
    Id: 7373,
    Code: '3429',
    Name: 'Designer, broadcasting'
  },
  {
    Id: 7374,
    Code: '3429',
    Name: 'Designer, publishing'
  },
  {
    Id: 7375,
    Code: '3429',
    Name: 'Designer, rubber goods mfr'
  },
  {
    Id: 7376,
    Code: '3429',
    Name: 'Designer, soft toy mfr'
  },
  {
    Id: 7377,
    Code: '3429',
    Name: 'Designer'
  },
  {
    Id: 7378,
    Code: '3429',
    Name: 'Developer, product, design'
  },
  {
    Id: 7379,
    Code: '3429',
    Name: 'Dresser, set'
  },
  {
    Id: 7380,
    Code: '3429',
    Name: 'Manager, display, visual'
  },
  {
    Id: 7381,
    Code: '3429',
    Name: 'Manager, display'
  },
  {
    Id: 7382,
    Code: '3429',
    Name: 'Manager, merchandise, visual'
  },
  {
    Id: 7383,
    Code: '3429',
    Name: 'Manager, merchandising, visual'
  },
  {
    Id: 7384,
    Code: '3429',
    Name: 'Merchandiser, visual, design'
  },
  {
    Id: 7385,
    Code: '3429',
    Name: 'Stylist, colour, vehicle mfr'
  },
  {
    Id: 7386,
    Code: '3429',
    Name: 'Stylist, vehicle mfr'
  },
  {
    Id: 7387,
    Code: '3429',
    Name: 'Technologist, packaging'
  },
  {
    Id: 7388,
    Code: '3431',
    Name: 'Athlete'
  },
  {
    Id: 7389,
    Code: '3431',
    Name: 'Boxer, sports'
  },
  {
    Id: 7390,
    Code: '3431',
    Name: 'Cricketer'
  },
  {
    Id: 7391,
    Code: '3431',
    Name: 'Cyclist'
  },
  {
    Id: 7392,
    Code: '3431',
    Name: 'Driver, racing'
  },
  {
    Id: 7393,
    Code: '3431',
    Name: 'Equestrian'
  },
  {
    Id: 7394,
    Code: '3431',
    Name: 'Equestrienne'
  },
  {
    Id: 7395,
    Code: '3431',
    Name: 'Footballer'
  },
  {
    Id: 7396,
    Code: '3431',
    Name: 'Freerunner'
  },
  {
    Id: 7397,
    Code: '3431',
    Name: 'Golfer'
  },
  {
    Id: 7398,
    Code: '3431',
    Name: 'Gymnast'
  },
  {
    Id: 7399,
    Code: '3431',
    Name: 'Jockey'
  },
  {
    Id: 7400,
    Code: '3431',
    Name: 'Jumper, show'
  },
  {
    Id: 7401,
    Code: '3431',
    Name: 'Player, sports'
  },
  {
    Id: 7402,
    Code: '3431',
    Name: 'Professional, sports'
  },
  {
    Id: 7403,
    Code: '3431',
    Name: 'Rider, event'
  },
  {
    Id: 7404,
    Code: '3431',
    Name: 'Rider, scramble'
  },
  {
    Id: 7405,
    Code: '3431',
    Name: 'Rider, speedway'
  },
  {
    Id: 7406,
    Code: '3431',
    Name: 'Sportsman, professional'
  },
  {
    Id: 7407,
    Code: '3431',
    Name: 'Wrestler'
  },
  {
    Id: 7408,
    Code: '3432',
    Name: 'Agent, sports'
  },
  {
    Id: 7409,
    Code: '3432',
    Name: 'Coach, basketball'
  },
  {
    Id: 7410,
    Code: '3432',
    Name: 'Coach, cricket'
  },
  {
    Id: 7411,
    Code: '3432',
    Name: 'Coach, football'
  },
  {
    Id: 7412,
    Code: '3432',
    Name: 'Coach, golf'
  },
  {
    Id: 7413,
    Code: '3432',
    Name: 'Coach, gymnastics'
  },
  {
    Id: 7414,
    Code: '3432',
    Name: 'Coach, netball'
  },
  {
    Id: 7415,
    Code: '3432',
    Name: 'Coach, rugby'
  },
  {
    Id: 7416,
    Code: '3432',
    Name: 'Coach, shooting'
  },
  {
    Id: 7417,
    Code: '3432',
    Name: 'Coach, skating, ice'
  },
  {
    Id: 7418,
    Code: '3432',
    Name: 'Coach, sports'
  },
  {
    Id: 7419,
    Code: '3432',
    Name: 'Coach, swim'
  },
  {
    Id: 7420,
    Code: '3432',
    Name: 'Coach, swimming'
  },
  {
    Id: 7421,
    Code: '3432',
    Name: 'Coach, tennis'
  },
  {
    Id: 7422,
    Code: '3432',
    Name: 'Coach, sports'
  },
  {
    Id: 7423,
    Code: '3432',
    Name: 'Coordinator, sports'
  },
  {
    Id: 7424,
    Code: '3432',
    Name: 'Handicapper'
  },
  {
    Id: 7425,
    Code: '3432',
    Name: 'Instructor, activities, outdoor'
  },
  {
    Id: 7426,
    Code: '3432',
    Name: 'Instructor, activity, outdoors'
  },
  {
    Id: 7427,
    Code: '3432',
    Name: 'Instructor, arts, martial'
  },
  {
    Id: 7428,
    Code: '3432',
    Name: 'Instructor, climbing'
  },
  {
    Id: 7429,
    Code: '3432',
    Name: 'Instructor, driving, carriage'
  },
  {
    Id: 7430,
    Code: '3432',
    Name: 'Instructor, education, outdoor'
  },
  {
    Id: 7431,
    Code: '3432',
    Name: 'Instructor, gliding'
  },
  {
    Id: 7432,
    Code: '3432',
    Name: 'Instructor, karate'
  },
  {
    Id: 7433,
    Code: '3432',
    Name: 'Instructor, outdoor'
  },
  {
    Id: 7434,
    Code: '3432',
    Name: 'Instructor, pursuits, outdoor'
  },
  {
    Id: 7435,
    Code: '3432',
    Name: 'Instructor, riding, horse'
  },
  {
    Id: 7436,
    Code: '3432',
    Name: 'Instructor, riding'
  },
  {
    Id: 7437,
    Code: '3432',
    Name: 'Instructor, sailing'
  },
  {
    Id: 7438,
    Code: '3432',
    Name: 'Instructor, scuba'
  },
  {
    Id: 7439,
    Code: '3432',
    Name: 'Instructor, shooting'
  },
  {
    Id: 7440,
    Code: '3432',
    Name: 'Instructor, ski'
  },
  {
    Id: 7441,
    Code: '3432',
    Name: 'Instructor, swim'
  },
  {
    Id: 7442,
    Code: '3432',
    Name: 'Instructor, swimming'
  },
  {
    Id: 7443,
    Code: '3432',
    Name: 'Instructor, sports'
  },
  {
    Id: 7444,
    Code: '3432',
    Name: 'Instructor, outdoor pursuits'
  },
  {
    Id: 7445,
    Code: '3432',
    Name: 'Judge, sports'
  },
  {
    Id: 7446,
    Code: '3432',
    Name: 'Leader, pursuits, outdoor'
  },
  {
    Id: 7447,
    Code: '3432',
    Name: 'Leader, school, forest'
  },
  {
    Id: 7448,
    Code: '3432',
    Name: 'Manager, team, sports'
  },
  {
    Id: 7449,
    Code: '3432',
    Name: 'Manager, sports coaching'
  },
  {
    Id: 7450,
    Code: '3432',
    Name: 'Master, riding'
  },
  {
    Id: 7451,
    Code: '3432',
    Name: 'Officer, development, sport'
  },
  {
    Id: 7452,
    Code: '3432',
    Name: 'Officer, development, sports'
  },
  {
    Id: 7453,
    Code: '3432',
    Name: 'Officer, development, sports'
  },
  {
    Id: 7454,
    Code: '3432',
    Name: 'Officer, leisure'
  },
  {
    Id: 7455,
    Code: '3432',
    Name: 'Officer, recreation'
  },
  {
    Id: 7456,
    Code: '3432',
    Name: 'Official, racecourse'
  },
  {
    Id: 7457,
    Code: '3432',
    Name: 'Official, sports'
  },
  {
    Id: 7458,
    Code: '3432',
    Name: 'Pilot, balloon'
  },
  {
    Id: 7459,
    Code: '3432',
    Name: 'Practitioner, school, forest'
  },
  {
    Id: 7460,
    Code: '3432',
    Name: 'Referee, football'
  },
  {
    Id: 7461,
    Code: '3432',
    Name: 'Referee'
  },
  {
    Id: 7462,
    Code: '3432',
    Name: 'Scorer, cricket'
  },
  {
    Id: 7463,
    Code: '3432',
    Name: 'Scout, football'
  },
  {
    Id: 7464,
    Code: '3432',
    Name: 'Signalman, yacht club'
  },
  {
    Id: 7465,
    Code: '3432',
    Name: 'Steward, horse racing'
  },
  {
    Id: 7466,
    Code: '3432',
    Name: 'Steward, race course'
  },
  {
    Id: 7467,
    Code: '3432',
    Name: 'Teacher, riding'
  },
  {
    Id: 7468,
    Code: '3432',
    Name: 'Teacher, swim'
  },
  {
    Id: 7469,
    Code: '3432',
    Name: 'Teacher, swimming'
  },
  {
    Id: 7470,
    Code: '3432',
    Name: 'Teacher, outdoor recreational activities'
  },
  {
    Id: 7471,
    Code: '3432',
    Name: 'Trainer, development, outdoors'
  },
  {
    Id: 7472,
    Code: '3432',
    Name: 'Trainer, sports'
  },
  {
    Id: 7473,
    Code: '3432',
    Name: 'Tutor, bound, outward'
  },
  {
    Id: 7474,
    Code: '3432',
    Name: 'Umpire, sports'
  },
  {
    Id: 7475,
    Code: '3432',
    Name: 'Worker, education, outdoor'
  },
  {
    Id: 7476,
    Code: '3433',
    Name: 'Biokineticist'
  },
  {
    Id: 7477,
    Code: '3433',
    Name: 'Coach, fitness'
  },
  {
    Id: 7478,
    Code: '3433',
    Name: 'Consultant, fitness'
  },
  {
    Id: 7479,
    Code: '3433',
    Name: 'Consultant, lifestyle'
  },
  {
    Id: 7480,
    Code: '3433',
    Name: 'Instructor, aerobics'
  },
  {
    Id: 7481,
    Code: '3433',
    Name: 'Instructor, chi, tai'
  },
  {
    Id: 7482,
    Code: '3433',
    Name: 'Instructor, fit, keep'
  },
  {
    Id: 7483,
    Code: '3433',
    Name: 'Instructor, fitness'
  },
  {
    Id: 7484,
    Code: '3433',
    Name: 'Instructor, gym'
  },
  {
    Id: 7485,
    Code: '3433',
    Name: 'Instructor, health and fitness'
  },
  {
    Id: 7486,
    Code: '3433',
    Name: 'Instructor, Pilates'
  },
  {
    Id: 7487,
    Code: '3433',
    Name: 'Instructor, training, physical'
  },
  {
    Id: 7488,
    Code: '3433',
    Name: 'Instructor, yoga'
  },
  {
    Id: 7489,
    Code: '3433',
    Name: 'Instructor, zumba'
  },
  {
    Id: 7490,
    Code: '3433',
    Name: 'Instructor, physical training'
  },
  {
    Id: 7491,
    Code: '3433',
    Name: 'Leader, fit, keep'
  },
  {
    Id: 7492,
    Code: '3433',
    Name: 'Professional, fitness'
  },
  {
    Id: 7493,
    Code: '3433',
    Name: 'Supervisor, health and fitness'
  },
  {
    Id: 7494,
    Code: '3433',
    Name: 'Teacher, aerobics'
  },
  {
    Id: 7495,
    Code: '3433',
    Name: 'Teacher, fit, keep'
  },
  {
    Id: 7496,
    Code: '3433',
    Name: 'Teacher, Pilates'
  },
  {
    Id: 7497,
    Code: '3433',
    Name: 'Teacher, yoga'
  },
  {
    Id: 7498,
    Code: '3433',
    Name: 'Therapist, yoga'
  },
  {
    Id: 7499,
    Code: '3433',
    Name: 'Trainer, fitness'
  },
  {
    Id: 7500,
    Code: '3433',
    Name: 'Trainer, personal'
  },
  {
    Id: 7501,
    Code: '3433',
    Name: 'Tutor, yoga'
  },
  {
    Id: 7502,
    Code: '3511',
    Name: 'Assistant, service, traffic, air'
  },
  {
    Id: 7503,
    Code: '3511',
    Name: 'Assistant, services, traffic, air'
  },
  {
    Id: 7504,
    Code: '3511',
    Name: 'Aviator'
  },
  {
    Id: 7505,
    Code: '3511',
    Name: 'Captain, airline'
  },
  {
    Id: 7506,
    Code: '3511',
    Name: 'Captain, airlines'
  },
  {
    Id: 7507,
    Code: '3511',
    Name: 'Controller, movement, ground, airport'
  },
  {
    Id: 7508,
    Code: '3511',
    Name: 'Controller, radar, area'
  },
  {
    Id: 7509,
    Code: '3511',
    Name: 'Controller, traffic, air'
  },
  {
    Id: 7510,
    Code: '3511',
    Name: 'Controller of aircraft'
  },
  {
    Id: 7511,
    Code: '3511',
    Name: 'Engineer, flight'
  },
  {
    Id: 7512,
    Code: '3511',
    Name: 'Engineer, nos, airlines'
  },
  {
    Id: 7513,
    Code: '3511',
    Name: 'Instructor, flight'
  },
  {
    Id: 7514,
    Code: '3511',
    Name: 'Instructor, flying'
  },
  {
    Id: 7515,
    Code: '3511',
    Name: 'Instructor, simulation, flight'
  },
  {
    Id: 7516,
    Code: '3511',
    Name: 'Instructor, flying school'
  },
  {
    Id: 7517,
    Code: '3511',
    Name: 'Navigator, aircraft'
  },
  {
    Id: 7518,
    Code: '3511',
    Name: 'Officer, airlines'
  },
  {
    Id: 7519,
    Code: '3511',
    Name: 'Officer, briefing, civil aviation'
  },
  {
    Id: 7520,
    Code: '3511',
    Name: 'Officer, control, space, airport'
  },
  {
    Id: 7521,
    Code: '3511',
    Name: 'Officer, control, traffic, air'
  },
  {
    Id: 7522,
    Code: '3511',
    Name: 'Officer, deck, flight, aircraft'
  },
  {
    Id: 7523,
    Code: '3511',
    Name: 'Officer, engineer, airlines'
  },
  {
    Id: 7524,
    Code: '3511',
    Name: 'Officer, first, airlines'
  },
  {
    Id: 7525,
    Code: '3511',
    Name: 'Officer, navigating, airlines'
  },
  {
    Id: 7526,
    Code: '3511',
    Name: 'Officer, second, airlines'
  },
  {
    Id: 7527,
    Code: '3511',
    Name: 'Officer, airlines'
  },
  {
    Id: 7528,
    Code: '3511',
    Name: 'Pilot, aeroplane'
  },
  {
    Id: 7529,
    Code: '3511',
    Name: 'Pilot, airline'
  },
  {
    Id: 7530,
    Code: '3511',
    Name: 'Pilot, commercial'
  },
  {
    Id: 7531,
    Code: '3511',
    Name: 'Pilot, helicopter'
  },
  {
    Id: 7532,
    Code: '3511',
    Name: 'Pilot, test'
  },
  {
    Id: 7533,
    Code: '3511',
    Name: 'Pilot, aircraft'
  },
  {
    Id: 7534,
    Code: '3511',
    Name: 'Pilot, airlines'
  },
  {
    Id: 7535,
    Code: '3511',
    Name: 'Pilot, armed forces'
  },
  {
    Id: 7536,
    Code: '3511',
    Name: 'Planner, flight'
  },
  {
    Id: 7537,
    Code: '3511',
    Name: 'Planner, traffic, air'
  },
  {
    Id: 7538,
    Code: '3511',
    Name: 'Trainer, flight'
  },
  {
    Id: 7539,
    Code: '3512',
    Name: 'Cadet, shipping'
  },
  {
    Id: 7540,
    Code: '3512',
    Name: 'Captain, barge'
  },
  {
    Id: 7541,
    Code: '3512',
    Name: 'Captain, dredger'
  },
  {
    Id: 7542,
    Code: '3512',
    Name: 'Captain, ferry'
  },
  {
    Id: 7543,
    Code: '3512',
    Name: 'Captain, lighter'
  },
  {
    Id: 7544,
    Code: '3512',
    Name: "Captain, ship's"
  },
  {
    Id: 7545,
    Code: '3512',
    Name: 'Captain, tug, shipping'
  },
  {
    Id: 7546,
    Code: '3512',
    Name: 'Captain, yacht'
  },
  {
    Id: 7547,
    Code: '3512',
    Name: 'Captain, boat, barge'
  },
  {
    Id: 7548,
    Code: '3512',
    Name: 'Captain, hovercraft'
  },
  {
    Id: 7549,
    Code: '3512',
    Name: 'Captain, shipping'
  },
  {
    Id: 7550,
    Code: '3512',
    Name: 'Commander, catamaran, hovercraft, hydrofoil'
  },
  {
    Id: 7551,
    Code: '3512',
    Name: 'Commander, shipping'
  },
  {
    Id: 7552,
    Code: '3512',
    Name: 'Commodore, shipping'
  },
  {
    Id: 7553,
    Code: '3512',
    Name: 'Coxswain'
  },
  {
    Id: 7554,
    Code: '3512',
    Name: 'Engineer, assistant, shipping'
  },
  {
    Id: 7555,
    Code: '3512',
    Name: 'Engineer, chief, boat, barge'
  },
  {
    Id: 7556,
    Code: '3512',
    Name: 'Engineer, chief, catamaran, hovercraft, hydrofoil'
  },
  {
    Id: 7557,
    Code: '3512',
    Name: 'Engineer, chief, shipping'
  },
  {
    Id: 7558,
    Code: '3512',
    Name: 'Engineer, electrical, nos, shipping'
  },
  {
    Id: 7559,
    Code: '3512',
    Name: 'Engineer, electrical, nos, telecoms: cable ship'
  },
  {
    Id: 7560,
    Code: '3512',
    Name: 'Engineer, first, fishing'
  },
  {
    Id: 7561,
    Code: '3512',
    Name: 'Engineer, first'
  },
  {
    Id: 7562,
    Code: '3512',
    Name: 'Engineer, flight, hovercraft'
  },
  {
    Id: 7563,
    Code: '3512',
    Name: 'Engineer, hydraulic, shipping'
  },
  {
    Id: 7564,
    Code: '3512',
    Name: 'Engineer, marine, chief, shipping'
  },
  {
    Id: 7565,
    Code: '3512',
    Name: 'Engineer, marine, boat, barge'
  },
  {
    Id: 7566,
    Code: '3512',
    Name: 'Engineer, marine, shipping'
  },
  {
    Id: 7567,
    Code: '3512',
    Name: 'Engineer, refrigerating, shipping'
  },
  {
    Id: 7568,
    Code: '3512',
    Name: 'Engineer, refrigeration, shipping'
  },
  {
    Id: 7569,
    Code: '3512',
    Name: 'Engineer, sea-going'
  },
  {
    Id: 7570,
    Code: '3512',
    Name: 'Engineer, second'
  },
  {
    Id: 7571,
    Code: '3512',
    Name: "Engineer, ship's, fishing"
  },
  {
    Id: 7572,
    Code: '3512',
    Name: "Engineer, ship's"
  },
  {
    Id: 7573,
    Code: '3512',
    Name: 'Engineer, third'
  },
  {
    Id: 7574,
    Code: '3512',
    Name: 'Engineer, tug'
  },
  {
    Id: 7575,
    Code: '3512',
    Name: 'Engineer, nos, shipping'
  },
  {
    Id: 7576,
    Code: '3512',
    Name: 'Mariner, master'
  },
  {
    Id: 7577,
    Code: '3512',
    Name: 'Master, barge'
  },
  {
    Id: 7578,
    Code: '3512',
    Name: 'Master, ship'
  },
  {
    Id: 7579,
    Code: '3512',
    Name: 'Master, ships'
  },
  {
    Id: 7580,
    Code: '3512',
    Name: 'Master, tug'
  },
  {
    Id: 7581,
    Code: '3512',
    Name: 'Master, yacht'
  },
  {
    Id: 7582,
    Code: '3512',
    Name: 'Master, shipping'
  },
  {
    Id: 7583,
    Code: '3512',
    Name: 'Master, Trinity House'
  },
  {
    Id: 7584,
    Code: '3512',
    Name: 'Master of lightship'
  },
  {
    Id: 7585,
    Code: '3512',
    Name: 'Mate, chief'
  },
  {
    Id: 7586,
    Code: '3512',
    Name: 'Mate, first'
  },
  {
    Id: 7587,
    Code: '3512',
    Name: 'Mate, fourth'
  },
  {
    Id: 7588,
    Code: '3512',
    Name: 'Mate, second'
  },
  {
    Id: 7589,
    Code: '3512',
    Name: 'Mate, third'
  },
  {
    Id: 7590,
    Code: '3512',
    Name: 'Mate, tug'
  },
  {
    Id: 7591,
    Code: '3512',
    Name: 'Mate, shipping'
  },
  {
    Id: 7592,
    Code: '3512',
    Name: 'Midshipman, shipping'
  },
  {
    Id: 7593,
    Code: '3512',
    Name: 'Navigator, hovercraft'
  },
  {
    Id: 7594,
    Code: '3512',
    Name: 'Navigator, ship'
  },
  {
    Id: 7595,
    Code: '3512',
    Name: 'Officer, chief, shipping'
  },
  {
    Id: 7596,
    Code: '3512',
    Name: 'Officer, deck, Navy, Merchant'
  },
  {
    Id: 7597,
    Code: '3512',
    Name: 'Officer, deck'
  },
  {
    Id: 7598,
    Code: '3512',
    Name: 'Officer, electrical, shipping'
  },
  {
    Id: 7599,
    Code: '3512',
    Name: 'Officer, electronics, shipping'
  },
  {
    Id: 7600,
    Code: '3512',
    Name: 'Officer, engineer, hovercraft'
  },
  {
    Id: 7601,
    Code: '3512',
    Name: 'Officer, engineer, shipping'
  },
  {
    Id: 7602,
    Code: '3512',
    Name: 'Officer, engineering, shipping'
  },
  {
    Id: 7603,
    Code: '3512',
    Name: 'Officer, first, hovercraft'
  },
  {
    Id: 7604,
    Code: '3512',
    Name: 'Officer, first, shipping'
  },
  {
    Id: 7605,
    Code: '3512',
    Name: 'Officer, House, Trinity'
  },
  {
    Id: 7606,
    Code: '3512',
    Name: 'Officer, navigating, shipping'
  },
  {
    Id: 7607,
    Code: '3512',
    Name: 'Officer, Navy, Merchant'
  },
  {
    Id: 7608,
    Code: '3512',
    Name: 'Officer, petty'
  },
  {
    Id: 7609,
    Code: '3512',
    Name: 'Officer, radio'
  },
  {
    Id: 7610,
    Code: '3512',
    Name: 'Officer, refrigeration, shipping'
  },
  {
    Id: 7611,
    Code: '3512',
    Name: 'Officer, relieving, shipping'
  },
  {
    Id: 7612,
    Code: '3512',
    Name: 'Officer, salvage, marine'
  },
  {
    Id: 7613,
    Code: '3512',
    Name: 'Officer, second, hovercraft'
  },
  {
    Id: 7614,
    Code: '3512',
    Name: 'Officer, second, shipping'
  },
  {
    Id: 7615,
    Code: '3512',
    Name: "Officer, ship's"
  },
  {
    Id: 7616,
    Code: '3512',
    Name: 'Officer, third, shipping'
  },
  {
    Id: 7617,
    Code: '3512',
    Name: 'Officer, hovercraft'
  },
  {
    Id: 7618,
    Code: '3512',
    Name: 'Officer, shipping'
  },
  {
    Id: 7619,
    Code: '3512',
    Name: 'Operator, positioning, dynamic'
  },
  {
    Id: 7620,
    Code: '3512',
    Name: 'Pilot, canal'
  },
  {
    Id: 7621,
    Code: '3512',
    Name: 'Pilot, dock'
  },
  {
    Id: 7622,
    Code: '3512',
    Name: 'Pilot, harbour'
  },
  {
    Id: 7623,
    Code: '3512',
    Name: 'Pilot, hovercraft'
  },
  {
    Id: 7624,
    Code: '3512',
    Name: 'Pilot, marine'
  },
  {
    Id: 7625,
    Code: '3512',
    Name: 'Pilot, river'
  },
  {
    Id: 7626,
    Code: '3512',
    Name: 'Pilot, shipping'
  },
  {
    Id: 7627,
    Code: '3512',
    Name: "Purser, ship's"
  },
  {
    Id: 7628,
    Code: '3512',
    Name: 'Purser, shipping'
  },
  {
    Id: 7629,
    Code: '3512',
    Name: 'Skipper, rig, oil'
  },
  {
    Id: 7630,
    Code: '3512',
    Name: 'Skipper, tug, shipping'
  },
  {
    Id: 7631,
    Code: '3512',
    Name: 'Skipper, yacht'
  },
  {
    Id: 7632,
    Code: '3512',
    Name: 'Skipper, boat, barge'
  },
  {
    Id: 7633,
    Code: '3512',
    Name: 'Skipper, shipping'
  },
  {
    Id: 7634,
    Code: '3512',
    Name: 'Superintendent, marine, qualified'
  },
  {
    Id: 7635,
    Code: '3512',
    Name: 'Superintendent, pilot'
  },
  {
    Id: 7636,
    Code: '3512',
    Name: 'Superintendent of pilots'
  },
  {
    Id: 7637,
    Code: '3520',
    Name: 'Adjudicator, Financial Ombudsman'
  },
  {
    Id: 7638,
    Code: '3520',
    Name: 'Adviser, immigration'
  },
  {
    Id: 7639,
    Code: '3520',
    Name: 'Agent, precognition'
  },
  {
    Id: 7640,
    Code: '3520',
    Name: 'Assistant, legal'
  },
  {
    Id: 7641,
    Code: '3520',
    Name: 'Assistant, paralegal'
  },
  {
    Id: 7642,
    Code: '3520',
    Name: 'Associate, legal'
  },
  {
    Id: 7643,
    Code: '3520',
    Name: 'Caseworker, immigration'
  },
  {
    Id: 7644,
    Code: '3520',
    Name: 'Caseworker, legal'
  },
  {
    Id: 7645,
    Code: '3520',
    Name: 'Caseworker, legal services'
  },
  {
    Id: 7646,
    Code: '3520',
    Name: "Clerk, advocates'"
  },
  {
    Id: 7647,
    Code: '3520',
    Name: "Clerk, barrister's"
  },
  {
    Id: 7648,
    Code: '3520',
    Name: "Clerk, judge's"
  },
  {
    Id: 7649,
    Code: '3520',
    Name: 'Clerk, managing'
  },
  {
    Id: 7650,
    Code: '3520',
    Name: 'Consultant, cost, law'
  },
  {
    Id: 7651,
    Code: '3520',
    Name: 'Consultant, cost, legal'
  },
  {
    Id: 7652,
    Code: '3520',
    Name: 'Consultant, costs, law'
  },
  {
    Id: 7653,
    Code: '3520',
    Name: 'Consultant, costs, legal'
  },
  {
    Id: 7654,
    Code: '3520',
    Name: 'Draftsman, cost, law'
  },
  {
    Id: 7655,
    Code: '3520',
    Name: 'Draftsman, cost'
  },
  {
    Id: 7656,
    Code: '3520',
    Name: 'Draftsman, costs, law'
  },
  {
    Id: 7657,
    Code: '3520',
    Name: 'Draftsman, costs, legal'
  },
  {
    Id: 7658,
    Code: '3520',
    Name: 'Draftsman, costs'
  },
  {
    Id: 7659,
    Code: '3520',
    Name: 'Draughtsman, costs, law'
  },
  {
    Id: 7660,
    Code: '3520',
    Name: 'Earner, fee, legal services'
  },
  {
    Id: 7661,
    Code: '3520',
    Name: 'Examiner, legal services'
  },
  {
    Id: 7662,
    Code: '3520',
    Name: 'Executive, conveyancing'
  },
  {
    Id: 7663,
    Code: '3520',
    Name: 'Lawyer, costs'
  },
  {
    Id: 7664,
    Code: '3520',
    Name: 'Litigator'
  },
  {
    Id: 7665,
    Code: '3520',
    Name: 'Manager, conveyancing'
  },
  {
    Id: 7666,
    Code: '3520',
    Name: 'Manager, legal'
  },
  {
    Id: 7667,
    Code: '3520',
    Name: 'Manager, litigation'
  },
  {
    Id: 7668,
    Code: '3520',
    Name: 'Manager, operations, legal services'
  },
  {
    Id: 7669,
    Code: '3520',
    Name: 'Manager, practice, legal'
  },
  {
    Id: 7670,
    Code: '3520',
    Name: 'Manager, practice, legal services'
  },
  {
    Id: 7671,
    Code: '3520',
    Name: 'Manager, probate'
  },
  {
    Id: 7672,
    Code: '3520',
    Name: 'Manager, services, legal'
  },
  {
    Id: 7673,
    Code: '3520',
    Name: "Manager, solicitor's"
  },
  {
    Id: 7674,
    Code: '3520',
    Name: 'Manager, legal services'
  },
  {
    Id: 7675,
    Code: '3520',
    Name: 'Member, panel, industrial tribunal'
  },
  {
    Id: 7676,
    Code: '3520',
    Name: 'Member, tribunal'
  },
  {
    Id: 7677,
    Code: '3520',
    Name: 'Member, appeals tribunal, inquiry'
  },
  {
    Id: 7678,
    Code: '3520',
    Name: 'Negotiator, costs, legal services'
  },
  {
    Id: 7679,
    Code: '3520',
    Name: 'Officer, compliance, legal services'
  },
  {
    Id: 7680,
    Code: '3520',
    Name: "Officer, coroner's"
  },
  {
    Id: 7681,
    Code: '3520',
    Name: 'Officer, litigation'
  },
  {
    Id: 7682,
    Code: '3520',
    Name: 'Officer, paralegal'
  },
  {
    Id: 7683,
    Code: '3520',
    Name: 'Officer, precognition'
  },
  {
    Id: 7684,
    Code: '3520',
    Name: 'Officer, protection, data'
  },
  {
    Id: 7685,
    Code: '3520',
    Name: 'Officer, services, legal'
  },
  {
    Id: 7686,
    Code: '3520',
    Name: 'Registrar, land'
  },
  {
    Id: 7687,
    Code: '3520',
    Name: 'Specialist, document, legal services'
  },
  {
    Id: 7688,
    Code: '3520',
    Name: 'Translator, legal'
  },
  {
    Id: 7689,
    Code: '3520',
    Name: 'Tribunalist'
  },
  {
    Id: 7690,
    Code: '3520',
    Name: 'Worker, case, immigration'
  },
  {
    Id: 7691,
    Code: '3520',
    Name: 'Worker, case, legal'
  },
  {
    Id: 7692,
    Code: '3520',
    Name: 'Writer, will'
  },
  {
    Id: 7693,
    Code: '3531',
    Name: 'Administrator, investment'
  },
  {
    Id: 7694,
    Code: '3531',
    Name: 'Administrator, swaps'
  },
  {
    Id: 7695,
    Code: '3531',
    Name: "Agent, Lloyd's"
  },
  {
    Id: 7696,
    Code: '3531',
    Name: 'Agent, metal'
  },
  {
    Id: 7697,
    Code: '3531',
    Name: 'Arbitragist'
  },
  {
    Id: 7698,
    Code: '3531',
    Name: 'Assistant, trader, banking'
  },
  {
    Id: 7699,
    Code: '3531',
    Name: 'Associate, securities'
  },
  {
    Id: 7700,
    Code: '3531',
    Name: 'Associate, support, trade, banking'
  },
  {
    Id: 7701,
    Code: '3531',
    Name: 'Broker, air'
  },
  {
    Id: 7702,
    Code: '3531',
    Name: 'Broker, bill'
  },
  {
    Id: 7703,
    Code: '3531',
    Name: 'Broker, bullion'
  },
  {
    Id: 7704,
    Code: '3531',
    Name: 'Broker, business'
  },
  {
    Id: 7705,
    Code: '3531',
    Name: 'Broker, commodities'
  },
  {
    Id: 7706,
    Code: '3531',
    Name: 'Broker, commodity'
  },
  {
    Id: 7707,
    Code: '3531',
    Name: 'Broker, corporate'
  },
  {
    Id: 7708,
    Code: '3531',
    Name: 'Broker, diamond'
  },
  {
    Id: 7709,
    Code: '3531',
    Name: 'Broker, discount'
  },
  {
    Id: 7710,
    Code: '3531',
    Name: 'Broker, energy'
  },
  {
    Id: 7711,
    Code: '3531',
    Name: 'Broker, exchange, foreign'
  },
  {
    Id: 7712,
    Code: '3531',
    Name: 'Broker, exchange'
  },
  {
    Id: 7713,
    Code: '3531',
    Name: 'Broker, financial'
  },
  {
    Id: 7714,
    Code: '3531',
    Name: 'Broker, insurance, credit'
  },
  {
    Id: 7715,
    Code: '3531',
    Name: 'Broker, insurance'
  },
  {
    Id: 7716,
    Code: '3531',
    Name: 'Broker, investment'
  },
  {
    Id: 7717,
    Code: '3531',
    Name: 'Broker, jewel'
  },
  {
    Id: 7718,
    Code: '3531',
    Name: 'Broker, licensed'
  },
  {
    Id: 7719,
    Code: '3531',
    Name: "Broker, Lloyd's"
  },
  {
    Id: 7720,
    Code: '3531',
    Name: 'Broker, marine'
  },
  {
    Id: 7721,
    Code: '3531',
    Name: 'Broker, money'
  },
  {
    Id: 7722,
    Code: '3531',
    Name: 'Broker, mortgage'
  },
  {
    Id: 7723,
    Code: '3531',
    Name: 'Broker, oil'
  },
  {
    Id: 7724,
    Code: '3531',
    Name: 'Broker, produce'
  },
  {
    Id: 7725,
    Code: '3531',
    Name: 'Broker, reinsurance'
  },
  {
    Id: 7726,
    Code: '3531',
    Name: 'Broker, share'
  },
  {
    Id: 7727,
    Code: '3531',
    Name: 'Broker, ship'
  },
  {
    Id: 7728,
    Code: '3531',
    Name: 'Broker, stock'
  },
  {
    Id: 7729,
    Code: '3531',
    Name: 'Broker, stocks and shares'
  },
  {
    Id: 7730,
    Code: '3531',
    Name: 'Broker, tea'
  },
  {
    Id: 7731,
    Code: '3531',
    Name: 'Broker, yacht'
  },
  {
    Id: 7732,
    Code: '3531',
    Name: 'Broker, commodities'
  },
  {
    Id: 7733,
    Code: '3531',
    Name: 'Broker, commodity'
  },
  {
    Id: 7734,
    Code: '3531',
    Name: 'Broker, transport'
  },
  {
    Id: 7735,
    Code: '3531',
    Name: 'Broker, financial services'
  },
  {
    Id: 7736,
    Code: '3531',
    Name: 'Broker, insurance'
  },
  {
    Id: 7737,
    Code: '3531',
    Name: 'Broker, wholesale, retail trade'
  },
  {
    Id: 7738,
    Code: '3531',
    Name: 'Consultant, commodity'
  },
  {
    Id: 7739,
    Code: '3531',
    Name: 'Dealer, bond'
  },
  {
    Id: 7740,
    Code: '3531',
    Name: 'Dealer, exchange, foreign'
  },
  {
    Id: 7741,
    Code: '3531',
    Name: 'Dealer, investment'
  },
  {
    Id: 7742,
    Code: '3531',
    Name: 'Dealer, money'
  },
  {
    Id: 7743,
    Code: '3531',
    Name: 'Dealer, share'
  },
  {
    Id: 7744,
    Code: '3531',
    Name: 'Dealer, stock and share'
  },
  {
    Id: 7745,
    Code: '3531',
    Name: 'Dealer, finance'
  },
  {
    Id: 7746,
    Code: '3531',
    Name: 'Executive, trading, commodities'
  },
  {
    Id: 7747,
    Code: '3531',
    Name: 'Jobber, stock'
  },
  {
    Id: 7748,
    Code: '3531',
    Name: 'Jobber, stock and share'
  },
  {
    Id: 7749,
    Code: '3531',
    Name: 'Keeper, position'
  },
  {
    Id: 7750,
    Code: '3531',
    Name: 'Member of the Stock Exchange'
  },
  {
    Id: 7751,
    Code: '3531',
    Name: 'Salesman, investment broking'
  },
  {
    Id: 7752,
    Code: '3531',
    Name: 'Shipbroker'
  },
  {
    Id: 7753,
    Code: '3531',
    Name: 'Stockbroker'
  },
  {
    Id: 7754,
    Code: '3531',
    Name: 'Supervisor, swaps'
  },
  {
    Id: 7755,
    Code: '3531',
    Name: 'Trader, bond'
  },
  {
    Id: 7756,
    Code: '3531',
    Name: 'Trader, commodity'
  },
  {
    Id: 7757,
    Code: '3531',
    Name: 'Trader, derivatives'
  },
  {
    Id: 7758,
    Code: '3531',
    Name: 'Trader, energy'
  },
  {
    Id: 7759,
    Code: '3531',
    Name: 'Trader, equity'
  },
  {
    Id: 7760,
    Code: '3531',
    Name: 'Trader, exchange, foreign'
  },
  {
    Id: 7761,
    Code: '3531',
    Name: 'Trader, farm'
  },
  {
    Id: 7762,
    Code: '3531',
    Name: 'Trader, financial'
  },
  {
    Id: 7763,
    Code: '3531',
    Name: 'Trader, futures'
  },
  {
    Id: 7764,
    Code: '3531',
    Name: 'Trader, FX'
  },
  {
    Id: 7765,
    Code: '3531',
    Name: 'Trader, market, financial'
  },
  {
    Id: 7766,
    Code: '3531',
    Name: 'Trader, market, money, banking'
  },
  {
    Id: 7767,
    Code: '3531',
    Name: 'Trader, options'
  },
  {
    Id: 7768,
    Code: '3531',
    Name: 'Trader, securities'
  },
  {
    Id: 7769,
    Code: '3531',
    Name: 'Trader, stock'
  },
  {
    Id: 7770,
    Code: '3531',
    Name: 'Trader, stock exchange'
  },
  {
    Id: 7771,
    Code: '3531',
    Name: 'Trader, stockbrokers'
  },
  {
    Id: 7772,
    Code: '3532',
    Name: "Agent, underwriter's"
  },
  {
    Id: 7773,
    Code: '3532',
    Name: 'Controller, underwriting, insurance'
  },
  {
    Id: 7774,
    Code: '3532',
    Name: 'Executive, underwriting'
  },
  {
    Id: 7775,
    Code: '3532',
    Name: 'Handler, account, insurance'
  },
  {
    Id: 7776,
    Code: '3532',
    Name: 'Handler, account, insurance'
  },
  {
    Id: 7777,
    Code: '3532',
    Name: 'Handler, accounts, insurance'
  },
  {
    Id: 7778,
    Code: '3532',
    Name: 'Handler, insurance'
  },
  {
    Id: 7779,
    Code: '3532',
    Name: 'Inspector, business, new, insurance'
  },
  {
    Id: 7780,
    Code: '3532',
    Name: 'Inspector, chief, insurance'
  },
  {
    Id: 7781,
    Code: '3532',
    Name: 'Inspector, district, insurance'
  },
  {
    Id: 7782,
    Code: '3532',
    Name: 'Inspector, insurance'
  },
  {
    Id: 7783,
    Code: '3532',
    Name: 'Inspector, life, insurance'
  },
  {
    Id: 7784,
    Code: '3532',
    Name: 'Inspector, insurance'
  },
  {
    Id: 7785,
    Code: '3532',
    Name: 'Leader, team, insurance'
  },
  {
    Id: 7786,
    Code: '3532',
    Name: 'Member of Lloyds'
  },
  {
    Id: 7787,
    Code: '3532',
    Name: 'Underwriter, commercial'
  },
  {
    Id: 7788,
    Code: '3532',
    Name: 'Underwriter, insurance'
  },
  {
    Id: 7789,
    Code: '3532',
    Name: 'Underwriter, medical'
  },
  {
    Id: 7790,
    Code: '3532',
    Name: 'Underwriter, mortgage'
  },
  {
    Id: 7791,
    Code: '3532',
    Name: 'Underwriter'
  },
  {
    Id: 7792,
    Code: '3533',
    Name: 'Administrator, fund'
  },
  {
    Id: 7793,
    Code: '3533',
    Name: 'Administrator, insolvency'
  },
  {
    Id: 7794,
    Code: '3533',
    Name: 'Administrator, trust, banking'
  },
  {
    Id: 7795,
    Code: '3533',
    Name: 'Associate, business, banking'
  },
  {
    Id: 7796,
    Code: '3533',
    Name: 'Clerk, managing, accountancy'
  },
  {
    Id: 7797,
    Code: '3533',
    Name: 'Controller, collateral'
  },
  {
    Id: 7798,
    Code: '3533',
    Name: 'Controller, financial, group'
  },
  {
    Id: 7799,
    Code: '3533',
    Name: 'Controller, financial'
  },
  {
    Id: 7800,
    Code: '3533',
    Name: 'Controller, product, financial services'
  },
  {
    Id: 7801,
    Code: '3533',
    Name: 'Controller, banking'
  },
  {
    Id: 7802,
    Code: '3533',
    Name: 'Officer, trust'
  },
  {
    Id: 7803,
    Code: '3533',
    Name: 'Senior, audit'
  },
  {
    Id: 7804,
    Code: '3533',
    Name: 'Senior, trust'
  },
  {
    Id: 7805,
    Code: '3533',
    Name: 'Technician, account'
  },
  {
    Id: 7806,
    Code: '3533',
    Name: 'Technician, accounting'
  },
  {
    Id: 7807,
    Code: '3533',
    Name: 'Technician, accounts'
  },
  {
    Id: 7808,
    Code: '3533',
    Name: 'Technician, audit'
  },
  {
    Id: 7809,
    Code: '3533',
    Name: 'Technician, accountancy'
  },
  {
    Id: 7810,
    Code: '3534',
    Name: 'Banker, private'
  },
  {
    Id: 7811,
    Code: '3534',
    Name: 'Executive, banking, private'
  },
  {
    Id: 7812,
    Code: '3534',
    Name: 'Manager, account, client, financial services'
  },
  {
    Id: 7813,
    Code: '3534',
    Name: 'Manager, account, customer, financial services'
  },
  {
    Id: 7814,
    Code: '3534',
    Name: 'Manager, account, banking'
  },
  {
    Id: 7815,
    Code: '3534',
    Name: 'Manager, account, building society'
  },
  {
    Id: 7816,
    Code: '3534',
    Name: 'Manager, account, insurance'
  },
  {
    Id: 7817,
    Code: '3534',
    Name: 'Manager, accounts, financial'
  },
  {
    Id: 7818,
    Code: '3534',
    Name: 'Manager, accounts, national'
  },
  {
    Id: 7819,
    Code: '3534',
    Name: 'Manager, accounts'
  },
  {
    Id: 7820,
    Code: '3534',
    Name: 'Manager, accounts and office'
  },
  {
    Id: 7821,
    Code: '3534',
    Name: 'Manager, asset, financial services'
  },
  {
    Id: 7822,
    Code: '3534',
    Name: 'Manager, audit'
  },
  {
    Id: 7823,
    Code: '3534',
    Name: 'Manager, banking, personal'
  },
  {
    Id: 7824,
    Code: '3534',
    Name: 'Manager, banking, private'
  },
  {
    Id: 7825,
    Code: '3534',
    Name: 'Manager, business, banking'
  },
  {
    Id: 7826,
    Code: '3534',
    Name: 'Manager, claims'
  },
  {
    Id: 7827,
    Code: '3534',
    Name: 'Manager, client, banking'
  },
  {
    Id: 7828,
    Code: '3534',
    Name: 'Manager, client, financial services'
  },
  {
    Id: 7829,
    Code: '3534',
    Name: 'Manager, control, credit'
  },
  {
    Id: 7830,
    Code: '3534',
    Name: 'Manager, credit'
  },
  {
    Id: 7831,
    Code: '3534',
    Name: 'Manager, customer, banking'
  },
  {
    Id: 7832,
    Code: '3534',
    Name: 'Manager, customer, building society'
  },
  {
    Id: 7833,
    Code: '3534',
    Name: 'Manager, customer, financial services'
  },
  {
    Id: 7834,
    Code: '3534',
    Name: 'Manager, department, banking'
  },
  {
    Id: 7835,
    Code: '3534',
    Name: 'Manager, department, building society'
  },
  {
    Id: 7836,
    Code: '3534',
    Name: 'Manager, department, insurance'
  },
  {
    Id: 7837,
    Code: '3534',
    Name: 'Manager, departmental, banking'
  },
  {
    Id: 7838,
    Code: '3534',
    Name: 'Manager, departmental, building society'
  },
  {
    Id: 7839,
    Code: '3534',
    Name: 'Manager, departmental, insurance'
  },
  {
    Id: 7840,
    Code: '3534',
    Name: 'Manager, derivatives'
  },
  {
    Id: 7841,
    Code: '3534',
    Name: 'Manager, fund'
  },
  {
    Id: 7842,
    Code: '3534',
    Name: 'Manager, house, acceptance'
  },
  {
    Id: 7843,
    Code: '3534',
    Name: 'Manager, incident, personal'
  },
  {
    Id: 7844,
    Code: '3534',
    Name: 'Manager, investment'
  },
  {
    Id: 7845,
    Code: '3534',
    Name: 'Manager, line, front, banking'
  },
  {
    Id: 7846,
    Code: '3534',
    Name: 'Manager, market, mortgages'
  },
  {
    Id: 7847,
    Code: '3534',
    Name: 'Manager, mortgage'
  },
  {
    Id: 7848,
    Code: '3534',
    Name: 'Manager, mortgage and protection'
  },
  {
    Id: 7849,
    Code: '3534',
    Name: 'Manager, payable, accounts'
  },
  {
    Id: 7850,
    Code: '3534',
    Name: 'Manager, planning, commercial'
  },
  {
    Id: 7851,
    Code: '3534',
    Name: 'Manager, planning, financial'
  },
  {
    Id: 7852,
    Code: '3534',
    Name: 'Manager, relations, client, financial services'
  },
  {
    Id: 7853,
    Code: '3534',
    Name: 'Manager, relations, customer, banking'
  },
  {
    Id: 7854,
    Code: '3534',
    Name: 'Manager, relations, customer, financial services'
  },
  {
    Id: 7855,
    Code: '3534',
    Name: 'Manager, relations, customer, insurance'
  },
  {
    Id: 7856,
    Code: '3534',
    Name: 'Manager, relationship, client, financial services'
  },
  {
    Id: 7857,
    Code: '3534',
    Name: 'Manager, relationship, banking'
  },
  {
    Id: 7858,
    Code: '3534',
    Name: 'Manager, relationship, insurance'
  },
  {
    Id: 7859,
    Code: '3534',
    Name: 'Manager, service, client, financial services'
  },
  {
    Id: 7860,
    Code: '3534',
    Name: 'Manager, services, client, financial services'
  },
  {
    Id: 7861,
    Code: '3534',
    Name: 'Manager, support, finance'
  },
  {
    Id: 7862,
    Code: '3534',
    Name: 'Manager, team, claims'
  },
  {
    Id: 7863,
    Code: '3534',
    Name: 'Manager, team, insurance'
  },
  {
    Id: 7864,
    Code: '3534',
    Name: 'Manager, team, banking'
  },
  {
    Id: 7865,
    Code: '3534',
    Name: 'Manager, team, insurance'
  },
  {
    Id: 7866,
    Code: '3534',
    Name: 'Manager, underwriting'
  },
  {
    Id: 7867,
    Code: '3534',
    Name: 'Manager, unit, mortgage'
  },
  {
    Id: 7868,
    Code: '3534',
    Name: 'Officer, vetting, credit'
  },
  {
    Id: 7869,
    Code: '3541',
    Name: 'Adjudicator, claims'
  },
  {
    Id: 7870,
    Code: '3541',
    Name: 'Adjudicator, insurance'
  },
  {
    Id: 7871,
    Code: '3541',
    Name: 'Adjuster, average'
  },
  {
    Id: 7872,
    Code: '3541',
    Name: 'Adjuster, claims, insurance'
  },
  {
    Id: 7873,
    Code: '3541',
    Name: 'Adjuster, claims'
  },
  {
    Id: 7874,
    Code: '3541',
    Name: 'Adjuster, loss'
  },
  {
    Id: 7875,
    Code: '3541',
    Name: 'Adviser, energy'
  },
  {
    Id: 7876,
    Code: '3541',
    Name: 'Analyst, scoring, credit'
  },
  {
    Id: 7877,
    Code: '3541',
    Name: 'Appraiser and valuer'
  },
  {
    Id: 7878,
    Code: '3541',
    Name: 'Arbitrator'
  },
  {
    Id: 7879,
    Code: '3541',
    Name: 'Assessor, claims'
  },
  {
    Id: 7880,
    Code: '3541',
    Name: 'Assessor, damage, vehicle'
  },
  {
    Id: 7881,
    Code: '3541',
    Name: 'Assessor, energy, domestic'
  },
  {
    Id: 7882,
    Code: '3541',
    Name: 'Assessor, energy'
  },
  {
    Id: 7883,
    Code: '3541',
    Name: 'Assessor, HIP'
  },
  {
    Id: 7884,
    Code: '3541',
    Name: 'Assessor, insurance'
  },
  {
    Id: 7885,
    Code: '3541',
    Name: 'Assessor, risk'
  },
  {
    Id: 7886,
    Code: '3541',
    Name: 'Assessor, insurance'
  },
  {
    Id: 7887,
    Code: '3541',
    Name: 'Auditor, energy'
  },
  {
    Id: 7888,
    Code: '3541',
    Name: 'Consultant, claims, insurance'
  },
  {
    Id: 7889,
    Code: '3541',
    Name: 'Controller, claims, insurance'
  },
  {
    Id: 7890,
    Code: '3541',
    Name: 'Designer, systems, fire'
  },
  {
    Id: 7891,
    Code: '3541',
    Name: 'Engineer, assessing, insurance'
  },
  {
    Id: 7892,
    Code: '3541',
    Name: 'Engineer, cost'
  },
  {
    Id: 7893,
    Code: '3541',
    Name: 'Engineer, costing'
  },
  {
    Id: 7894,
    Code: '3541',
    Name: 'Engineer, estimating'
  },
  {
    Id: 7895,
    Code: '3541',
    Name: 'Engineer, inspection, insurance'
  },
  {
    Id: 7896,
    Code: '3541',
    Name: 'Engineer, insurance'
  },
  {
    Id: 7897,
    Code: '3541',
    Name: 'Engineer, staff, insurance'
  },
  {
    Id: 7898,
    Code: '3541',
    Name: 'Engineer-estimator'
  },
  {
    Id: 7899,
    Code: '3541',
    Name: 'Engineer-surveyor, insurance'
  },
  {
    Id: 7900,
    Code: '3541',
    Name: 'Estimator, building'
  },
  {
    Id: 7901,
    Code: '3541',
    Name: 'Estimator, chief'
  },
  {
    Id: 7902,
    Code: '3541',
    Name: 'Estimator, commercial'
  },
  {
    Id: 7903,
    Code: '3541',
    Name: 'Estimator, cost'
  },
  {
    Id: 7904,
    Code: '3541',
    Name: 'Estimator, electrical'
  },
  {
    Id: 7905,
    Code: '3541',
    Name: 'Estimator, mechanical'
  },
  {
    Id: 7906,
    Code: '3541',
    Name: 'Estimator, planning'
  },
  {
    Id: 7907,
    Code: '3541',
    Name: 'Estimator, print'
  },
  {
    Id: 7908,
    Code: '3541',
    Name: 'Estimator, printing'
  },
  {
    Id: 7909,
    Code: '3541',
    Name: 'Estimator, sales'
  },
  {
    Id: 7910,
    Code: '3541',
    Name: 'Estimator, technical'
  },
  {
    Id: 7911,
    Code: '3541',
    Name: 'Estimator'
  },
  {
    Id: 7912,
    Code: '3541',
    Name: 'Estimator-engineer'
  },
  {
    Id: 7913,
    Code: '3541',
    Name: 'Examiner, HM Revenue and Customs'
  },
  {
    Id: 7914,
    Code: '3541',
    Name: 'Executive, claims'
  },
  {
    Id: 7915,
    Code: '3541',
    Name: 'Expert, art'
  },
  {
    Id: 7916,
    Code: '3541',
    Name: 'Handler, case, PPI'
  },
  {
    Id: 7917,
    Code: '3541',
    Name: 'Inspector, claims, insurance'
  },
  {
    Id: 7918,
    Code: '3541',
    Name: 'Inspector, home'
  },
  {
    Id: 7919,
    Code: '3541',
    Name: 'Investigator, claims'
  },
  {
    Id: 7920,
    Code: '3541',
    Name: 'Investigator, insurance'
  },
  {
    Id: 7921,
    Code: '3541',
    Name: 'Manager, cost'
  },
  {
    Id: 7922,
    Code: '3541',
    Name: 'Manager, costing'
  },
  {
    Id: 7923,
    Code: '3541',
    Name: 'Manager, valuation'
  },
  {
    Id: 7924,
    Code: '3541',
    Name: 'Negotiator, claims'
  },
  {
    Id: 7925,
    Code: '3541',
    Name: 'Negotiator, insurance'
  },
  {
    Id: 7926,
    Code: '3541',
    Name: 'Officer, claims'
  },
  {
    Id: 7927,
    Code: '3541',
    Name: 'Officer, costs, technical'
  },
  {
    Id: 7928,
    Code: '3541',
    Name: 'Officer, rates and charges, transport'
  },
  {
    Id: 7929,
    Code: '3541',
    Name: 'Officer, rating'
  },
  {
    Id: 7930,
    Code: '3541',
    Name: 'Officer, rating and valuation'
  },
  {
    Id: 7931,
    Code: '3541',
    Name: 'Officer, rent'
  },
  {
    Id: 7932,
    Code: '3541',
    Name: 'Officer, valuation'
  },
  {
    Id: 7933,
    Code: '3541',
    Name: 'Official, claims, marine'
  },
  {
    Id: 7934,
    Code: '3541',
    Name: 'Planner-estimator'
  },
  {
    Id: 7935,
    Code: '3541',
    Name: 'Referencer, valuation'
  },
  {
    Id: 7936,
    Code: '3541',
    Name: 'Representative, claims, insurance'
  },
  {
    Id: 7937,
    Code: '3541',
    Name: 'Settler, claims, insurance'
  },
  {
    Id: 7938,
    Code: '3541',
    Name: 'Superintendent, branch, insurance'
  },
  {
    Id: 7939,
    Code: '3541',
    Name: 'Superintendent, claims'
  },
  {
    Id: 7940,
    Code: '3541',
    Name: 'Superintendent, district, insurance'
  },
  {
    Id: 7941,
    Code: '3541',
    Name: 'Superintendent, fire, insurance'
  },
  {
    Id: 7942,
    Code: '3541',
    Name: 'Superintendent, insurance'
  },
  {
    Id: 7943,
    Code: '3541',
    Name: 'Superintendent, motor, insurance'
  },
  {
    Id: 7944,
    Code: '3541',
    Name: 'Superintendent, insurance'
  },
  {
    Id: 7945,
    Code: '3541',
    Name: 'Surveyor, boiler and engine, insurance'
  },
  {
    Id: 7946,
    Code: '3541',
    Name: 'Surveyor, engineering'
  },
  {
    Id: 7947,
    Code: '3541',
    Name: 'Surveyor, fire'
  },
  {
    Id: 7948,
    Code: '3541',
    Name: 'Surveyor, insulation'
  },
  {
    Id: 7949,
    Code: '3541',
    Name: 'Surveyor, insurance'
  },
  {
    Id: 7950,
    Code: '3541',
    Name: 'Surveyor, marine'
  },
  {
    Id: 7951,
    Code: '3541',
    Name: 'Surveyor, nautical'
  },
  {
    Id: 7952,
    Code: '3541',
    Name: 'Surveyor, naval'
  },
  {
    Id: 7953,
    Code: '3541',
    Name: 'Surveyor, rating'
  },
  {
    Id: 7954,
    Code: '3541',
    Name: 'Surveyor, valuation'
  },
  {
    Id: 7955,
    Code: '3541',
    Name: 'Surveyor, window'
  },
  {
    Id: 7956,
    Code: '3541',
    Name: 'Surveyor, double glazing'
  },
  {
    Id: 7957,
    Code: '3541',
    Name: 'Surveyor, insurance'
  },
  {
    Id: 7958,
    Code: '3541',
    Name: 'Surveyor'
  },
  {
    Id: 7959,
    Code: '3541',
    Name: 'Surveyor and valuer'
  },
  {
    Id: 7960,
    Code: '3541',
    Name: 'Technician, valuation'
  },
  {
    Id: 7961,
    Code: '3541',
    Name: 'Valuer'
  },
  {
    Id: 7962,
    Code: '3541',
    Name: 'Valuer and auctioneer'
  },
  {
    Id: 7963,
    Code: '3542',
    Name: 'Agent, export'
  },
  {
    Id: 7964,
    Code: '3542',
    Name: 'Agent, import'
  },
  {
    Id: 7965,
    Code: '3542',
    Name: 'Agent, import-export'
  },
  {
    Id: 7966,
    Code: '3542',
    Name: "Agent, ship's"
  },
  {
    Id: 7967,
    Code: '3542',
    Name: 'Agent, shipping'
  },
  {
    Id: 7968,
    Code: '3542',
    Name: 'Consultant, export'
  },
  {
    Id: 7969,
    Code: '3542',
    Name: 'Controller, export'
  },
  {
    Id: 7970,
    Code: '3542',
    Name: 'Coordinator, export'
  },
  {
    Id: 7971,
    Code: '3542',
    Name: 'Coordinator, import'
  },
  {
    Id: 7972,
    Code: '3542',
    Name: 'Executive, import'
  },
  {
    Id: 7973,
    Code: '3542',
    Name: 'Exporter'
  },
  {
    Id: 7974,
    Code: '3542',
    Name: 'Importer'
  },
  {
    Id: 7975,
    Code: '3542',
    Name: 'Importer-exporter'
  },
  {
    Id: 7976,
    Code: '3542',
    Name: 'Officer, export'
  },
  {
    Id: 7977,
    Code: '3542',
    Name: 'Officer, export and import'
  },
  {
    Id: 7978,
    Code: '3542',
    Name: 'Shipper, wholesale trade'
  },
  {
    Id: 7979,
    Code: '3542',
    Name: 'Supervisor, export-import'
  },
  {
    Id: 7980,
    Code: '3543',
    Name: 'Administrator, PMO'
  },
  {
    Id: 7981,
    Code: '3543',
    Name: 'Administrator, project'
  },
  {
    Id: 7982,
    Code: '3543',
    Name: 'Analyst, project'
  },
  {
    Id: 7983,
    Code: '3543',
    Name: 'Assistant, management, project'
  },
  {
    Id: 7984,
    Code: '3543',
    Name: 'Assistant, planning'
  },
  {
    Id: 7985,
    Code: '3543',
    Name: 'Assistant, project, research'
  },
  {
    Id: 7986,
    Code: '3543',
    Name: 'Assistant, project'
  },
  {
    Id: 7987,
    Code: '3543',
    Name: 'Coordinator, delivery, service'
  },
  {
    Id: 7988,
    Code: '3543',
    Name: 'Coordinator, project'
  },
  {
    Id: 7989,
    Code: '3543',
    Name: 'Coordinator, projects'
  },
  {
    Id: 7990,
    Code: '3543',
    Name: 'Leader, team, project management'
  },
  {
    Id: 7991,
    Code: '3543',
    Name: 'Manager, package, work'
  },
  {
    Id: 7992,
    Code: '3543',
    Name: 'Officer, management, project'
  },
  {
    Id: 7993,
    Code: '3543',
    Name: 'Officer, project'
  },
  {
    Id: 7994,
    Code: '3543',
    Name: 'Officer, support, programme'
  },
  {
    Id: 7995,
    Code: '3543',
    Name: 'Officer, support, project'
  },
  {
    Id: 7996,
    Code: '3544',
    Name: 'Analyst, coding, clinical'
  },
  {
    Id: 7997,
    Code: '3544',
    Name: 'Analyst, data'
  },
  {
    Id: 7998,
    Code: '3544',
    Name: 'Analyst, forecast'
  },
  {
    Id: 7999,
    Code: '3544',
    Name: 'Analyst, information, management'
  },
  {
    Id: 8000,
    Code: '3544',
    Name: 'Analyst, insight'
  },
  {
    Id: 8001,
    Code: '3544',
    Name: 'Analyst, logistics'
  },
  {
    Id: 8002,
    Code: '3544',
    Name: 'Analyst, management, data'
  },
  {
    Id: 8003,
    Code: '3544',
    Name: 'Analyst, management'
  },
  {
    Id: 8004,
    Code: '3544',
    Name: 'Analyst, market'
  },
  {
    Id: 8005,
    Code: '3544',
    Name: 'Analyst, methods'
  },
  {
    Id: 8006,
    Code: '3544',
    Name: 'Analyst, MI'
  },
  {
    Id: 8007,
    Code: '3544',
    Name: 'Analyst, O&M'
  },
  {
    Id: 8008,
    Code: '3544',
    Name: 'Analyst, operations, sales'
  },
  {
    Id: 8009,
    Code: '3544',
    Name: 'Analyst, organisation and methods'
  },
  {
    Id: 8010,
    Code: '3544',
    Name: 'Analyst, performance'
  },
  {
    Id: 8011,
    Code: '3544',
    Name: 'Analyst, quality, data'
  },
  {
    Id: 8012,
    Code: '3544',
    Name: 'Analyst, research, operational'
  },
  {
    Id: 8013,
    Code: '3544',
    Name: 'Analyst, sales'
  },
  {
    Id: 8014,
    Code: '3544',
    Name: 'Analyst, study, work'
  },
  {
    Id: 8015,
    Code: '3544',
    Name: 'Analyst, support, business'
  },
  {
    Id: 8016,
    Code: '3544',
    Name: 'Analyst, systems, information, geographical'
  },
  {
    Id: 8017,
    Code: '3544',
    Name: 'Analyst, tachograph'
  },
  {
    Id: 8018,
    Code: '3544',
    Name: 'Analyst, time and motion'
  },
  {
    Id: 8019,
    Code: '3544',
    Name: 'Analyst, value'
  },
  {
    Id: 8020,
    Code: '3544',
    Name: 'Analyst, data'
  },
  {
    Id: 8021,
    Code: '3544',
    Name: 'Analyst, work study'
  },
  {
    Id: 8022,
    Code: '3544',
    Name: 'Analyst, Dstl'
  },
  {
    Id: 8023,
    Code: '3544',
    Name: 'Officer, data'
  },
  {
    Id: 8024,
    Code: '3549',
    Name: 'Administrator, committee'
  },
  {
    Id: 8025,
    Code: '3549',
    Name: 'Administrator, trial, clinical'
  },
  {
    Id: 8026,
    Code: '3549',
    Name: 'Administrator, trials, clinical'
  },
  {
    Id: 8027,
    Code: '3549',
    Name: 'Adviser, ballot'
  },
  {
    Id: 8028,
    Code: '3549',
    Name: 'Adviser, cellar, catering'
  },
  {
    Id: 8029,
    Code: '3549',
    Name: 'Adviser, contract'
  },
  {
    Id: 8030,
    Code: '3549',
    Name: 'Adviser, marine'
  },
  {
    Id: 8031,
    Code: '3549',
    Name: 'Adviser, traffic'
  },
  {
    Id: 8032,
    Code: '3549',
    Name: 'Adviser, transport'
  },
  {
    Id: 8033,
    Code: '3549',
    Name: 'Agent, bloodstock'
  },
  {
    Id: 8034,
    Code: '3549',
    Name: 'Agent, commercial'
  },
  {
    Id: 8035,
    Code: '3549',
    Name: 'Agent, commission, farm produce'
  },
  {
    Id: 8036,
    Code: '3549',
    Name: 'Agent, commission, raw materials'
  },
  {
    Id: 8037,
    Code: '3549',
    Name: 'Agent, election'
  },
  {
    Id: 8038,
    Code: '3549',
    Name: 'Agent, party, political party'
  },
  {
    Id: 8039,
    Code: '3549',
    Name: 'Agent, trademark'
  },
  {
    Id: 8040,
    Code: '3549',
    Name: 'Analyst, geospatial'
  },
  {
    Id: 8041,
    Code: '3549',
    Name: 'Analyst, GIS'
  },
  {
    Id: 8042,
    Code: '3549',
    Name: 'Analyst, system, business'
  },
  {
    Id: 8043,
    Code: '3549',
    Name: 'Analyst, systems, business'
  },
  {
    Id: 8044,
    Code: '3549',
    Name: 'Analyst, systems, office'
  },
  {
    Id: 8045,
    Code: '3549',
    Name: 'Appraiser'
  },
  {
    Id: 8046,
    Code: '3549',
    Name: 'Assessor, study, work'
  },
  {
    Id: 8047,
    Code: '3549',
    Name: 'Assessor, work, time and motion'
  },
  {
    Id: 8048,
    Code: '3549',
    Name: 'Assistant, compliance, banking'
  },
  {
    Id: 8049,
    Code: '3549',
    Name: 'Assistant, GIS'
  },
  {
    Id: 8050,
    Code: '3549',
    Name: 'Assistant, measurement, work'
  },
  {
    Id: 8051,
    Code: '3549',
    Name: 'Assistant, methods'
  },
  {
    Id: 8052,
    Code: '3549',
    Name: 'Assistant, systems, information, geographical'
  },
  {
    Id: 8053,
    Code: '3549',
    Name: 'Assistant, trial, clinical'
  },
  {
    Id: 8054,
    Code: '3549',
    Name: 'Assistant, trials, clinical'
  },
  {
    Id: 8055,
    Code: '3549',
    Name: 'Associate, licensing'
  },
  {
    Id: 8056,
    Code: '3549',
    Name: 'Attaché'
  },
  {
    Id: 8057,
    Code: '3549',
    Name: 'Coder, clinical'
  },
  {
    Id: 8058,
    Code: '3549',
    Name: 'Coder, medical'
  },
  {
    Id: 8059,
    Code: '3549',
    Name: 'Consultant, aviation'
  },
  {
    Id: 8060,
    Code: '3549',
    Name: 'Consultant, bloodstock'
  },
  {
    Id: 8061,
    Code: '3549',
    Name: 'Consultant, chain, supply'
  },
  {
    Id: 8062,
    Code: '3549',
    Name: 'Consultant, contracts'
  },
  {
    Id: 8063,
    Code: '3549',
    Name: 'Consultant, defence'
  },
  {
    Id: 8064,
    Code: '3549',
    Name: 'Consultant, leisure'
  },
  {
    Id: 8065,
    Code: '3549',
    Name: 'Consultant, logistics'
  },
  {
    Id: 8066,
    Code: '3549',
    Name: 'Consultant, marine'
  },
  {
    Id: 8067,
    Code: '3549',
    Name: 'Consultant, O&M'
  },
  {
    Id: 8068,
    Code: '3549',
    Name: 'Consultant, organisation and methods'
  },
  {
    Id: 8069,
    Code: '3549',
    Name: 'Consultant, printing'
  },
  {
    Id: 8070,
    Code: '3549',
    Name: 'Consultant, research, operational'
  },
  {
    Id: 8071,
    Code: '3549',
    Name: 'Consultant, study, works'
  },
  {
    Id: 8072,
    Code: '3549',
    Name: 'Consultant, transport'
  },
  {
    Id: 8073,
    Code: '3549',
    Name: 'Consultant, transportation'
  },
  {
    Id: 8074,
    Code: '3549',
    Name: 'Controller, site'
  },
  {
    Id: 8075,
    Code: '3549',
    Name: 'Controller, sub-contracts, production'
  },
  {
    Id: 8076,
    Code: '3549',
    Name: 'Controller, sub-contracts, building construction'
  },
  {
    Id: 8077,
    Code: '3549',
    Name: 'Coordinator, business'
  },
  {
    Id: 8078,
    Code: '3549',
    Name: 'Coordinator, contract'
  },
  {
    Id: 8079,
    Code: '3549',
    Name: 'Coordinator, contracts'
  },
  {
    Id: 8080,
    Code: '3549',
    Name: 'Coordinator, improvement, continuous'
  },
  {
    Id: 8081,
    Code: '3549',
    Name: 'Coordinator, licensing'
  },
  {
    Id: 8082,
    Code: '3549',
    Name: 'Coordinator, quality, data'
  },
  {
    Id: 8083,
    Code: '3549',
    Name: 'Coordinator, relations, trade'
  },
  {
    Id: 8084,
    Code: '3549',
    Name: 'Coordinator, research'
  },
  {
    Id: 8085,
    Code: '3549',
    Name: 'Coordinator, translation'
  },
  {
    Id: 8086,
    Code: '3549',
    Name: 'Counsellor, business'
  },
  {
    Id: 8087,
    Code: '3549',
    Name: 'Engineer, measurement, work'
  },
  {
    Id: 8088,
    Code: '3549',
    Name: 'Engineer, methods'
  },
  {
    Id: 8089,
    Code: '3549',
    Name: 'Engineer, standards'
  },
  {
    Id: 8090,
    Code: '3549',
    Name: 'Engineer, study, method'
  },
  {
    Id: 8091,
    Code: '3549',
    Name: 'Engineer, study, time'
  },
  {
    Id: 8092,
    Code: '3549',
    Name: 'Engineer, study, work'
  },
  {
    Id: 8093,
    Code: '3549',
    Name: 'Engineer, time and study'
  },
  {
    Id: 8094,
    Code: '3549',
    Name: 'Engineer, value'
  },
  {
    Id: 8095,
    Code: '3549',
    Name: 'Evaluator, job'
  },
  {
    Id: 8096,
    Code: '3549',
    Name: 'Executive, company, oil'
  },
  {
    Id: 8097,
    Code: '3549',
    Name: 'Executive, management, yield'
  },
  {
    Id: 8098,
    Code: '3549',
    Name: 'Executive, registration'
  },
  {
    Id: 8099,
    Code: '3549',
    Name: 'Expert, efficiency, business'
  },
  {
    Id: 8100,
    Code: '3549',
    Name: 'Expert, study, time'
  },
  {
    Id: 8101,
    Code: '3549',
    Name: 'Fixer, price'
  },
  {
    Id: 8102,
    Code: '3549',
    Name: 'Fixer, rate'
  },
  {
    Id: 8103,
    Code: '3549',
    Name: 'Forecaster, network'
  },
  {
    Id: 8104,
    Code: '3549',
    Name: 'Graphologist'
  },
  {
    Id: 8105,
    Code: '3549',
    Name: 'Inspector, practice, standard'
  },
  {
    Id: 8106,
    Code: '3549',
    Name: 'Inspector, room, standards'
  },
  {
    Id: 8107,
    Code: '3549',
    Name: 'Investigator, O&M'
  },
  {
    Id: 8108,
    Code: '3549',
    Name: 'Investigator, organisation and method'
  },
  {
    Id: 8109,
    Code: '3549',
    Name: 'Investigator, study, work'
  },
  {
    Id: 8110,
    Code: '3549',
    Name: 'Man, study, work'
  },
  {
    Id: 8111,
    Code: '3549',
    Name: 'Manager, services, corporate'
  },
  {
    Id: 8112,
    Code: '3549',
    Name: 'Manager, success, customer'
  },
  {
    Id: 8113,
    Code: '3549',
    Name: 'Officer, coding, clinical'
  },
  {
    Id: 8114,
    Code: '3549',
    Name: 'Officer, contract, building construction'
  },
  {
    Id: 8115,
    Code: '3549',
    Name: 'Officer, contract, manufacturing'
  },
  {
    Id: 8116,
    Code: '3549',
    Name: 'Officer, contracts, building construction'
  },
  {
    Id: 8117,
    Code: '3549',
    Name: 'Officer, contracts, manufacturing'
  },
  {
    Id: 8118,
    Code: '3549',
    Name: 'Officer, data, GIS'
  },
  {
    Id: 8119,
    Code: '3549',
    Name: 'Officer, development, business'
  },
  {
    Id: 8120,
    Code: '3549',
    Name: 'Officer, evaluation, job'
  },
  {
    Id: 8121,
    Code: '3549',
    Name: 'Officer, GIS, assistant'
  },
  {
    Id: 8122,
    Code: '3549',
    Name: 'Officer, governance, clinical'
  },
  {
    Id: 8123,
    Code: '3549',
    Name: 'Officer, improvement, continuous'
  },
  {
    Id: 8124,
    Code: '3549',
    Name: 'Officer, information, management'
  },
  {
    Id: 8125,
    Code: '3549',
    Name: 'Officer, investment, local government'
  },
  {
    Id: 8126,
    Code: '3549',
    Name: 'Officer, knowledge'
  },
  {
    Id: 8127,
    Code: '3549',
    Name: 'Officer, liaison, manufacturing'
  },
  {
    Id: 8128,
    Code: '3549',
    Name: 'Officer, lottery'
  },
  {
    Id: 8129,
    Code: '3549',
    Name: 'Officer, management, knowledge'
  },
  {
    Id: 8130,
    Code: '3549',
    Name: 'Officer, management, performance'
  },
  {
    Id: 8131,
    Code: '3549',
    Name: 'Officer, management, time'
  },
  {
    Id: 8132,
    Code: '3549',
    Name: 'Officer, measurement, work'
  },
  {
    Id: 8133,
    Code: '3549',
    Name: 'Officer, methods'
  },
  {
    Id: 8134,
    Code: '3549',
    Name: 'Officer, O&M'
  },
  {
    Id: 8135,
    Code: '3549',
    Name: 'Officer, organisation, government'
  },
  {
    Id: 8136,
    Code: '3549',
    Name: 'Officer, organisation and methods'
  },
  {
    Id: 8137,
    Code: '3549',
    Name: 'Officer, patents'
  },
  {
    Id: 8138,
    Code: '3549',
    Name: 'Officer, performance'
  },
  {
    Id: 8139,
    Code: '3549',
    Name: 'Officer, planning, strategic'
  },
  {
    Id: 8140,
    Code: '3549',
    Name: 'Officer, production, technical'
  },
  {
    Id: 8141,
    Code: '3549',
    Name: 'Officer, quality, data'
  },
  {
    Id: 8142,
    Code: '3549',
    Name: 'Officer, research, operational'
  },
  {
    Id: 8143,
    Code: '3549',
    Name: 'Officer, review, performance'
  },
  {
    Id: 8144,
    Code: '3549',
    Name: 'Officer, risk'
  },
  {
    Id: 8145,
    Code: '3549',
    Name: 'Officer, services, productivity, gas supplier'
  },
  {
    Id: 8146,
    Code: '3549',
    Name: 'Officer, studies, clinical'
  },
  {
    Id: 8147,
    Code: '3549',
    Name: 'Officer, study, work'
  },
  {
    Id: 8148,
    Code: '3549',
    Name: 'Officer, support, business'
  },
  {
    Id: 8149,
    Code: '3549',
    Name: 'Officer, support, risk'
  },
  {
    Id: 8150,
    Code: '3549',
    Name: 'Officer, technical, work study'
  },
  {
    Id: 8151,
    Code: '3549',
    Name: 'Officer, trial, clinical'
  },
  {
    Id: 8152,
    Code: '3549',
    Name: 'Officer, trials, clinical'
  },
  {
    Id: 8153,
    Code: '3549',
    Name: 'Officer, workshops, MOD'
  },
  {
    Id: 8154,
    Code: '3549',
    Name: 'Planner, business'
  },
  {
    Id: 8155,
    Code: '3549',
    Name: 'Planner, strategic'
  },
  {
    Id: 8156,
    Code: '3549',
    Name: 'Practitioner, study, works'
  },
  {
    Id: 8157,
    Code: '3549',
    Name: 'Setter, rate'
  },
  {
    Id: 8158,
    Code: '3549',
    Name: 'Specialist, O&M'
  },
  {
    Id: 8159,
    Code: '3549',
    Name: 'Specialist, organisation and methods'
  },
  {
    Id: 8160,
    Code: '3549',
    Name: 'Strategist'
  },
  {
    Id: 8161,
    Code: '3549',
    Name: 'Technician, reinsurance'
  },
  {
    Id: 8162,
    Code: '3551',
    Name: 'Administrator, contracts, purchasing'
  },
  {
    Id: 8163,
    Code: '3551',
    Name: 'Agent, buying'
  },
  {
    Id: 8164,
    Code: '3551',
    Name: 'Agent, purchasing'
  },
  {
    Id: 8165,
    Code: '3551',
    Name: 'Assistant, buying, wholesale, retail trade'
  },
  {
    Id: 8166,
    Code: '3551',
    Name: 'Assistant, purchasing, wholesale, retail trade'
  },
  {
    Id: 8167,
    Code: '3551',
    Name: 'Assistant, trading, retail trade'
  },
  {
    Id: 8168,
    Code: '3551',
    Name: 'Buyer, advertising'
  },
  {
    Id: 8169,
    Code: '3551',
    Name: 'Buyer, fashion'
  },
  {
    Id: 8170,
    Code: '3551',
    Name: 'Buyer, media'
  },
  {
    Id: 8171,
    Code: '3551',
    Name: 'Buyer, print'
  },
  {
    Id: 8172,
    Code: '3551',
    Name: 'Buyer, space'
  },
  {
    Id: 8173,
    Code: '3551',
    Name: 'Buyer, store'
  },
  {
    Id: 8174,
    Code: '3551',
    Name: 'Buyer'
  },
  {
    Id: 8175,
    Code: '3551',
    Name: 'Buyer and estimator'
  },
  {
    Id: 8176,
    Code: '3551',
    Name: 'Consultant, procurement'
  },
  {
    Id: 8177,
    Code: '3551',
    Name: 'Consultant, purchasing'
  },
  {
    Id: 8178,
    Code: '3551',
    Name: 'Consultant, wine'
  },
  {
    Id: 8179,
    Code: '3551',
    Name: 'Controller, buying'
  },
  {
    Id: 8180,
    Code: '3551',
    Name: 'Controller, purchasing'
  },
  {
    Id: 8181,
    Code: '3551',
    Name: 'Coordinator, purchasing'
  },
  {
    Id: 8182,
    Code: '3551',
    Name: 'Coordinator, supplies'
  },
  {
    Id: 8183,
    Code: '3551',
    Name: 'Engineer, procurement'
  },
  {
    Id: 8184,
    Code: '3551',
    Name: 'Engineer, purchasing'
  },
  {
    Id: 8185,
    Code: '3551',
    Name: 'Estimator and buyer, retail trade'
  },
  {
    Id: 8186,
    Code: '3551',
    Name: 'Executive, purchasing'
  },
  {
    Id: 8187,
    Code: '3551',
    Name: 'Manciple'
  },
  {
    Id: 8188,
    Code: '3551',
    Name: 'Oenologist'
  },
  {
    Id: 8189,
    Code: '3551',
    Name: 'Officer, contract, purchasing'
  },
  {
    Id: 8190,
    Code: '3551',
    Name: 'Officer, contract, government'
  },
  {
    Id: 8191,
    Code: '3551',
    Name: 'Officer, contracts, purchasing'
  },
  {
    Id: 8192,
    Code: '3551',
    Name: 'Officer, contracts, government'
  },
  {
    Id: 8193,
    Code: '3551',
    Name: 'Officer, procurement'
  },
  {
    Id: 8194,
    Code: '3551',
    Name: 'Officer, purchasing'
  },
  {
    Id: 8195,
    Code: '3551',
    Name: 'Officer, supplies, chief'
  },
  {
    Id: 8196,
    Code: '3551',
    Name: 'Officer, supplies'
  },
  {
    Id: 8197,
    Code: '3551',
    Name: 'Officer, supply, armament'
  },
  {
    Id: 8198,
    Code: '3551',
    Name: 'Officer, supply, MOD'
  },
  {
    Id: 8199,
    Code: '3551',
    Name: 'Officer, support, procurement'
  },
  {
    Id: 8200,
    Code: '3551',
    Name: 'Planner, chain, supply'
  },
  {
    Id: 8201,
    Code: '3551',
    Name: 'Planner, supply'
  },
  {
    Id: 8202,
    Code: '3551',
    Name: 'Purchaser'
  },
  {
    Id: 8203,
    Code: '3551',
    Name: 'Salesman-buyer'
  },
  {
    Id: 8204,
    Code: '3551',
    Name: 'Selector, range, retail trade'
  },
  {
    Id: 8205,
    Code: '3551',
    Name: 'Specialist, chain, supply'
  },
  {
    Id: 8206,
    Code: '3551',
    Name: 'Specialist, procurement'
  },
  {
    Id: 8207,
    Code: '3551',
    Name: 'Writer, bid'
  },
  {
    Id: 8208,
    Code: '3552',
    Name: 'Administrator, e-commerce'
  },
  {
    Id: 8209,
    Code: '3552',
    Name: 'Adviser, sales, technical'
  },
  {
    Id: 8210,
    Code: '3552',
    Name: 'Adviser, service, home, gas supplier'
  },
  {
    Id: 8211,
    Code: '3552',
    Name: 'Adviser, technical, sales'
  },
  {
    Id: 8212,
    Code: '3552',
    Name: 'Agent, agricultural'
  },
  {
    Id: 8213,
    Code: '3552',
    Name: 'Agent, business'
  },
  {
    Id: 8214,
    Code: '3552',
    Name: 'Agent, commission, manufactured goods'
  },
  {
    Id: 8215,
    Code: '3552',
    Name: 'Agent, commission, commission agents'
  },
  {
    Id: 8216,
    Code: '3552',
    Name: 'Agent, commission, manufacturing'
  },
  {
    Id: 8217,
    Code: '3552',
    Name: 'Agent, commission, wholesale, retail trade'
  },
  {
    Id: 8218,
    Code: '3552',
    Name: "Agent, engineer's"
  },
  {
    Id: 8219,
    Code: '3552',
    Name: 'Agent, fashion'
  },
  {
    Id: 8220,
    Code: '3552',
    Name: "Agent, manufacturer's"
  },
  {
    Id: 8221,
    Code: '3552',
    Name: "Agent, publisher's"
  },
  {
    Id: 8222,
    Code: '3552',
    Name: 'Agent, sales'
  },
  {
    Id: 8223,
    Code: '3552',
    Name: 'Agent, textile'
  },
  {
    Id: 8224,
    Code: '3552',
    Name: "Agent, manufacturer's"
  },
  {
    Id: 8225,
    Code: '3552',
    Name: 'Agent, manufacturing'
  },
  {
    Id: 8226,
    Code: '3552',
    Name: 'Agent, wholesale, retail trade'
  },
  {
    Id: 8227,
    Code: '3552',
    Name: 'Assistant, gallery, art, retail trade'
  },
  {
    Id: 8228,
    Code: '3552',
    Name: 'Assistant, sales and marketing'
  },
  {
    Id: 8229,
    Code: '3552',
    Name: 'Consultant, development, business, sales'
  },
  {
    Id: 8230,
    Code: '3552',
    Name: 'Consultant, leasing'
  },
  {
    Id: 8231,
    Code: '3552',
    Name: 'Consultant, sales'
  },
  {
    Id: 8232,
    Code: '3552',
    Name: 'Consultant, sales and marketing'
  },
  {
    Id: 8233,
    Code: '3552',
    Name: 'Consultant, shipping'
  },
  {
    Id: 8234,
    Code: '3552',
    Name: 'Consultant, technical, sales'
  },
  {
    Id: 8235,
    Code: '3552',
    Name: 'Controller, area, retail trade'
  },
  {
    Id: 8236,
    Code: '3552',
    Name: 'Controller, divisional, wholesale, retail trade'
  },
  {
    Id: 8237,
    Code: '3552',
    Name: 'Controller, retail'
  },
  {
    Id: 8238,
    Code: '3552',
    Name: 'Controller, sales'
  },
  {
    Id: 8239,
    Code: '3552',
    Name: 'Developer, business, sales'
  },
  {
    Id: 8240,
    Code: '3552',
    Name: 'Engineer, commercial'
  },
  {
    Id: 8241,
    Code: '3552',
    Name: 'Engineer, consulting, sales'
  },
  {
    Id: 8242,
    Code: '3552',
    Name: 'Engineer, consumers, electricity supplier'
  },
  {
    Id: 8243,
    Code: '3552',
    Name: 'Engineer, development, business'
  },
  {
    Id: 8244,
    Code: '3552',
    Name: 'Engineer, marketing, product'
  },
  {
    Id: 8245,
    Code: '3552',
    Name: 'Engineer, sales, technical'
  },
  {
    Id: 8246,
    Code: '3552',
    Name: 'Engineer, sales'
  },
  {
    Id: 8247,
    Code: '3552',
    Name: 'Engineer, service, sales'
  },
  {
    Id: 8248,
    Code: '3552',
    Name: 'Engineer, support, sales'
  },
  {
    Id: 8249,
    Code: '3552',
    Name: 'Executive, account, corporate'
  },
  {
    Id: 8250,
    Code: '3552',
    Name: 'Executive, account, enterprise'
  },
  {
    Id: 8251,
    Code: '3552',
    Name: 'Executive, account, sales'
  },
  {
    Id: 8252,
    Code: '3552',
    Name: 'Executive, accounts, national'
  },
  {
    Id: 8253,
    Code: '3552',
    Name: 'Executive, development, business, sales'
  },
  {
    Id: 8254,
    Code: '3552',
    Name: 'Executive, development, sales'
  },
  {
    Id: 8255,
    Code: '3552',
    Name: 'Executive, e-commerce'
  },
  {
    Id: 8256,
    Code: '3552',
    Name: 'Executive, sales, advertising'
  },
  {
    Id: 8257,
    Code: '3552',
    Name: 'Executive, sales, internal'
  },
  {
    Id: 8258,
    Code: '3552',
    Name: 'Executive, sales'
  },
  {
    Id: 8259,
    Code: '3552',
    Name: 'Executive, sales and marketing'
  },
  {
    Id: 8260,
    Code: '3552',
    Name: 'Handler, account, sales'
  },
  {
    Id: 8261,
    Code: '3552',
    Name: 'Manager, territory'
  },
  {
    Id: 8262,
    Code: '3552',
    Name: 'Merchant, manufacturing'
  },
  {
    Id: 8263,
    Code: '3552',
    Name: 'Merchant, wholesale, retail trade'
  },
  {
    Id: 8264,
    Code: '3552',
    Name: 'Officer, development, business, sales'
  },
  {
    Id: 8265,
    Code: '3552',
    Name: 'Officer, liaison, medical'
  },
  {
    Id: 8266,
    Code: '3552',
    Name: 'Officer, sales'
  },
  {
    Id: 8267,
    Code: '3552',
    Name: 'Officer, support, sales'
  },
  {
    Id: 8268,
    Code: '3552',
    Name: 'Organiser, sales'
  },
  {
    Id: 8269,
    Code: '3552',
    Name: 'Planner, sales'
  },
  {
    Id: 8270,
    Code: '3552',
    Name: 'Representative, agricultural'
  },
  {
    Id: 8271,
    Code: '3552',
    Name: 'Representative, architectural'
  },
  {
    Id: 8272,
    Code: '3552',
    Name: 'Representative, company'
  },
  {
    Id: 8273,
    Code: '3552',
    Name: 'Representative, development, business, sales'
  },
  {
    Id: 8274,
    Code: '3552',
    Name: 'Representative, educational'
  },
  {
    Id: 8275,
    Code: '3552',
    Name: 'Representative, heating'
  },
  {
    Id: 8276,
    Code: '3552',
    Name: 'Representative, liaison'
  },
  {
    Id: 8277,
    Code: '3552',
    Name: 'Representative, medical'
  },
  {
    Id: 8278,
    Code: '3552',
    Name: "Representative, publisher's"
  },
  {
    Id: 8279,
    Code: '3552',
    Name: 'Representative, sales, technical'
  },
  {
    Id: 8280,
    Code: '3552',
    Name: 'Representative, sales'
  },
  {
    Id: 8281,
    Code: '3552',
    Name: 'Representative, technical'
  },
  {
    Id: 8282,
    Code: '3552',
    Name: 'Representative, electricity supplier'
  },
  {
    Id: 8283,
    Code: '3552',
    Name: 'Representative, gas supplier'
  },
  {
    Id: 8284,
    Code: '3552',
    Name: 'Representative, manufacturing'
  },
  {
    Id: 8285,
    Code: '3552',
    Name: 'Representative, wholesale trade'
  },
  {
    Id: 8286,
    Code: '3552',
    Name: 'Salesman, cattle'
  },
  {
    Id: 8287,
    Code: '3552',
    Name: 'Salesman, commercial'
  },
  {
    Id: 8288,
    Code: '3552',
    Name: 'Salesman, commission'
  },
  {
    Id: 8289,
    Code: '3552',
    Name: 'Salesman, computer'
  },
  {
    Id: 8290,
    Code: '3552',
    Name: 'Salesman, delivery'
  },
  {
    Id: 8291,
    Code: '3552',
    Name: 'Salesman, export'
  },
  {
    Id: 8292,
    Code: '3552',
    Name: 'Salesman, fish, wholesale trade'
  },
  {
    Id: 8293,
    Code: '3552',
    Name: 'Salesman, glazing, double'
  },
  {
    Id: 8294,
    Code: '3552',
    Name: 'Salesman, meat, wholesale trade'
  },
  {
    Id: 8295,
    Code: '3552',
    Name: 'Salesman, outside'
  },
  {
    Id: 8296,
    Code: '3552',
    Name: 'Salesman, tea'
  },
  {
    Id: 8297,
    Code: '3552',
    Name: 'Salesman, technical'
  },
  {
    Id: 8298,
    Code: '3552',
    Name: 'Salesman, travelling'
  },
  {
    Id: 8299,
    Code: '3552',
    Name: 'Salesman, tyre'
  },
  {
    Id: 8300,
    Code: '3552',
    Name: 'Salesman, van, manufacturing'
  },
  {
    Id: 8301,
    Code: '3552',
    Name: 'Salesman, van, wholesale trade'
  },
  {
    Id: 8302,
    Code: '3552',
    Name: 'Salesman, water, mineral'
  },
  {
    Id: 8303,
    Code: '3552',
    Name: 'Salesman, wool, scrap merchants, breakers'
  },
  {
    Id: 8304,
    Code: '3552',
    Name: 'Salesman, double glazing'
  },
  {
    Id: 8305,
    Code: '3552',
    Name: "Salesman, manufacturers' agents"
  },
  {
    Id: 8306,
    Code: '3552',
    Name: 'Salesman, manufacturing'
  },
  {
    Id: 8307,
    Code: '3552',
    Name: 'Salesman, wholesale trade'
  },
  {
    Id: 8308,
    Code: '3552',
    Name: 'Salesman-mechanic'
  },
  {
    Id: 8309,
    Code: '3552',
    Name: 'Specialist, computer, sales'
  },
  {
    Id: 8310,
    Code: '3552',
    Name: 'Specialist, product'
  },
  {
    Id: 8311,
    Code: '3552',
    Name: 'Specialist, products'
  },
  {
    Id: 8312,
    Code: '3552',
    Name: 'Specialist, sales'
  },
  {
    Id: 8313,
    Code: '3552',
    Name: 'Specialist, animal feeds mfr'
  },
  {
    Id: 8314,
    Code: '3552',
    Name: 'Superintendent, sales'
  },
  {
    Id: 8315,
    Code: '3552',
    Name: 'Superintendent, sales force'
  },
  {
    Id: 8316,
    Code: '3552',
    Name: 'Supervisor, area, retail trade'
  },
  {
    Id: 8317,
    Code: '3552',
    Name: 'Supervisor, sales, technical sales'
  },
  {
    Id: 8318,
    Code: '3552',
    Name: 'Trader, grain'
  },
  {
    Id: 8319,
    Code: '3552',
    Name: 'Traveller, commercial'
  },
  {
    Id: 8320,
    Code: '3552',
    Name: 'Traveller'
  },
  {
    Id: 8321,
    Code: '3553',
    Name: 'Analyst, chain, supply'
  },
  {
    Id: 8322,
    Code: '3553',
    Name: 'Assistant, merchandising'
  },
  {
    Id: 8323,
    Code: '3553',
    Name: 'Associate, merchandise'
  },
  {
    Id: 8324,
    Code: '3553',
    Name: 'Executive, merchandising'
  },
  {
    Id: 8325,
    Code: '3553',
    Name: 'Forecaster, sales'
  },
  {
    Id: 8326,
    Code: '3553',
    Name: 'Leader, team, merchandising'
  },
  {
    Id: 8327,
    Code: '3553',
    Name: 'Merchandiser, retail'
  },
  {
    Id: 8328,
    Code: '3553',
    Name: 'Merchandiser, sales'
  },
  {
    Id: 8329,
    Code: '3553',
    Name: 'Merchandiser'
  },
  {
    Id: 8330,
    Code: '3553',
    Name: 'Planner, demand'
  },
  {
    Id: 8331,
    Code: '3553',
    Name: 'Planner, merchandise'
  },
  {
    Id: 8332,
    Code: '3553',
    Name: 'Supervisor, display'
  },
  {
    Id: 8333,
    Code: '3554',
    Name: 'Advertiser'
  },
  {
    Id: 8334,
    Code: '3554',
    Name: 'Adviser, marketing'
  },
  {
    Id: 8335,
    Code: '3554',
    Name: 'Adviser, marketing'
  },
  {
    Id: 8336,
    Code: '3554',
    Name: 'Agent, advertising'
  },
  {
    Id: 8337,
    Code: '3554',
    Name: 'Agent, leasing, car leasing'
  },
  {
    Id: 8338,
    Code: '3554',
    Name: 'Agent, marketing'
  },
  {
    Id: 8339,
    Code: '3554',
    Name: 'Agent, advertising'
  },
  {
    Id: 8340,
    Code: '3554',
    Name: 'Ambassador, brand'
  },
  {
    Id: 8341,
    Code: '3554',
    Name: 'Analyst, development, business'
  },
  {
    Id: 8342,
    Code: '3554',
    Name: 'Analyst, product, marketing'
  },
  {
    Id: 8343,
    Code: '3554',
    Name: 'Analyst, support, sales'
  },
  {
    Id: 8344,
    Code: '3554',
    Name: 'Assistant, development, business'
  },
  {
    Id: 8345,
    Code: '3554',
    Name: 'Assistant, marketing, digital'
  },
  {
    Id: 8346,
    Code: '3554',
    Name: 'Assistant, marketing, online'
  },
  {
    Id: 8347,
    Code: '3554',
    Name: 'Assistant, marketing, sales'
  },
  {
    Id: 8348,
    Code: '3554',
    Name: 'Assistant, marketing'
  },
  {
    Id: 8349,
    Code: '3554',
    Name: 'Assistant, media, digital'
  },
  {
    Id: 8350,
    Code: '3554',
    Name: 'Assistant, media, social'
  },
  {
    Id: 8351,
    Code: '3554',
    Name: 'Assistant, production, advertising'
  },
  {
    Id: 8352,
    Code: '3554',
    Name: 'Associate, marketing, digital'
  },
  {
    Id: 8353,
    Code: '3554',
    Name: 'Associate, marketing'
  },
  {
    Id: 8354,
    Code: '3554',
    Name: 'Campaigner'
  },
  {
    Id: 8355,
    Code: '3554',
    Name: 'Consultant, advertising'
  },
  {
    Id: 8356,
    Code: '3554',
    Name: 'Consultant, brand'
  },
  {
    Id: 8357,
    Code: '3554',
    Name: 'Consultant, client, market research'
  },
  {
    Id: 8358,
    Code: '3554',
    Name: 'Consultant, development, business, marketing'
  },
  {
    Id: 8359,
    Code: '3554',
    Name: 'Consultant, fundraising'
  },
  {
    Id: 8360,
    Code: '3554',
    Name: 'Consultant, marketing, digital'
  },
  {
    Id: 8361,
    Code: '3554',
    Name: 'Consultant, marketing, internet'
  },
  {
    Id: 8362,
    Code: '3554',
    Name: 'Consultant, marketing'
  },
  {
    Id: 8363,
    Code: '3554',
    Name: 'Consultant, telemarketing'
  },
  {
    Id: 8364,
    Code: '3554',
    Name: 'Consultant, tourism'
  },
  {
    Id: 8365,
    Code: '3554',
    Name: 'Contractor, advertisement'
  },
  {
    Id: 8366,
    Code: '3554',
    Name: 'Contractor, advertising'
  },
  {
    Id: 8367,
    Code: '3554',
    Name: 'Controller, information, market'
  },
  {
    Id: 8368,
    Code: '3554',
    Name: 'Controller, marketing'
  },
  {
    Id: 8369,
    Code: '3554',
    Name: 'Controller, merchandise'
  },
  {
    Id: 8370,
    Code: '3554',
    Name: 'Controller, promotions'
  },
  {
    Id: 8371,
    Code: '3554',
    Name: 'Coordinator, account, advertising'
  },
  {
    Id: 8372,
    Code: '3554',
    Name: 'Coordinator, appeals'
  },
  {
    Id: 8373,
    Code: '3554',
    Name: 'Coordinator, digital'
  },
  {
    Id: 8374,
    Code: '3554',
    Name: 'Coordinator, marketing, CRM'
  },
  {
    Id: 8375,
    Code: '3554',
    Name: 'Coordinator, marketing, digital'
  },
  {
    Id: 8376,
    Code: '3554',
    Name: 'Coordinator, marketing, management, relationship, customer'
  },
  {
    Id: 8377,
    Code: '3554',
    Name: 'Coordinator, marketing'
  },
  {
    Id: 8378,
    Code: '3554',
    Name: 'Coordinator, marketing and events'
  },
  {
    Id: 8379,
    Code: '3554',
    Name: 'Coordinator, marketing and sales'
  },
  {
    Id: 8380,
    Code: '3554',
    Name: 'Coordinator, national, appeals, telephone'
  },
  {
    Id: 8381,
    Code: '3554',
    Name: 'Coordinator, promotion'
  },
  {
    Id: 8382,
    Code: '3554',
    Name: 'Coordinator, promotions'
  },
  {
    Id: 8383,
    Code: '3554',
    Name: 'Copywriter, advertising'
  },
  {
    Id: 8384,
    Code: '3554',
    Name: 'Copywriter, creative, advertising'
  },
  {
    Id: 8385,
    Code: '3554',
    Name: 'Copywriter, marketing'
  },
  {
    Id: 8386,
    Code: '3554',
    Name: 'Creator, content, marketing'
  },
  {
    Id: 8387,
    Code: '3554',
    Name: 'Designer, service, marketing'
  },
  {
    Id: 8388,
    Code: '3554',
    Name: 'Developer, product, marketing'
  },
  {
    Id: 8389,
    Code: '3554',
    Name: 'Evangelist, game, marketing'
  },
  {
    Id: 8390,
    Code: '3554',
    Name: 'Executive, account, digital, marketing'
  },
  {
    Id: 8391,
    Code: '3554',
    Name: 'Executive, account, marketing'
  },
  {
    Id: 8392,
    Code: '3554',
    Name: 'Executive, account, advertising'
  },
  {
    Id: 8393,
    Code: '3554',
    Name: 'Executive, accounts, advertising'
  },
  {
    Id: 8394,
    Code: '3554',
    Name: 'Executive, advertising'
  },
  {
    Id: 8395,
    Code: '3554',
    Name: 'Executive, campaign, marketing'
  },
  {
    Id: 8396,
    Code: '3554',
    Name: 'Executive, commercial'
  },
  {
    Id: 8397,
    Code: '3554',
    Name: 'Executive, development, business, marketing'
  },
  {
    Id: 8398,
    Code: '3554',
    Name: 'Executive, insights, consumer'
  },
  {
    Id: 8399,
    Code: '3554',
    Name: 'Executive, liaison, client'
  },
  {
    Id: 8400,
    Code: '3554',
    Name: 'Executive, marketing, content'
  },
  {
    Id: 8401,
    Code: '3554',
    Name: 'Executive, marketing, digital'
  },
  {
    Id: 8402,
    Code: '3554',
    Name: 'Executive, marketing, online'
  },
  {
    Id: 8403,
    Code: '3554',
    Name: 'Executive, marketing'
  },
  {
    Id: 8404,
    Code: '3554',
    Name: 'Executive, media, digital'
  },
  {
    Id: 8405,
    Code: '3554',
    Name: 'Executive, media'
  },
  {
    Id: 8406,
    Code: '3554',
    Name: 'Executive, pay-per-click, marketing'
  },
  {
    Id: 8407,
    Code: '3554',
    Name: 'Executive, PPC, marketing'
  },
  {
    Id: 8408,
    Code: '3554',
    Name: 'Executive, production, marketing'
  },
  {
    Id: 8409,
    Code: '3554',
    Name: 'Executive, project, advertising'
  },
  {
    Id: 8410,
    Code: '3554',
    Name: 'Executive, promotion, sales'
  },
  {
    Id: 8411,
    Code: '3554',
    Name: 'Executive, promotions'
  },
  {
    Id: 8412,
    Code: '3554',
    Name: 'Executive, relationship, client'
  },
  {
    Id: 8413,
    Code: '3554',
    Name: 'Fundraiser, community'
  },
  {
    Id: 8414,
    Code: '3554',
    Name: 'Fundraiser, telephone'
  },
  {
    Id: 8415,
    Code: '3554',
    Name: 'Fundraiser'
  },
  {
    Id: 8416,
    Code: '3554',
    Name: 'Hacker, growth, marketing'
  },
  {
    Id: 8417,
    Code: '3554',
    Name: 'Handler, account'
  },
  {
    Id: 8418,
    Code: '3554',
    Name: 'Marketer, digital'
  },
  {
    Id: 8419,
    Code: '3554',
    Name: 'Marketer, internet'
  },
  {
    Id: 8420,
    Code: '3554',
    Name: 'Marketer, music, digital'
  },
  {
    Id: 8421,
    Code: '3554',
    Name: 'Marketer, network'
  },
  {
    Id: 8422,
    Code: '3554',
    Name: 'Marketer, online'
  },
  {
    Id: 8423,
    Code: '3554',
    Name: 'Marketer'
  },
  {
    Id: 8424,
    Code: '3554',
    Name: 'Officer, appeal'
  },
  {
    Id: 8425,
    Code: '3554',
    Name: 'Officer, appeals'
  },
  {
    Id: 8426,
    Code: '3554',
    Name: 'Officer, commercial, railways'
  },
  {
    Id: 8427,
    Code: '3554',
    Name: 'Officer, communications, marketing'
  },
  {
    Id: 8428,
    Code: '3554',
    Name: 'Officer, communications, marketing'
  },
  {
    Id: 8429,
    Code: '3554',
    Name: 'Officer, contract'
  },
  {
    Id: 8430,
    Code: '3554',
    Name: 'Officer, contracts'
  },
  {
    Id: 8431,
    Code: '3554',
    Name: 'Officer, development, business, marketing'
  },
  {
    Id: 8432,
    Code: '3554',
    Name: 'Officer, development, donor, charitable organisation'
  },
  {
    Id: 8433,
    Code: '3554',
    Name: 'Officer, development, marketing'
  },
  {
    Id: 8434,
    Code: '3554',
    Name: 'Officer, development, tourist'
  },
  {
    Id: 8435,
    Code: '3554',
    Name: 'Officer, development, tourism'
  },
  {
    Id: 8436,
    Code: '3554',
    Name: 'Officer, fundraising'
  },
  {
    Id: 8437,
    Code: '3554',
    Name: 'Officer, marketing, digital'
  },
  {
    Id: 8438,
    Code: '3554',
    Name: 'Officer, marketing'
  },
  {
    Id: 8439,
    Code: '3554',
    Name: 'Officer, promotion, sales'
  },
  {
    Id: 8440,
    Code: '3554',
    Name: 'Officer, research, market'
  },
  {
    Id: 8441,
    Code: '3554',
    Name: 'Organiser, appeals'
  },
  {
    Id: 8442,
    Code: '3554',
    Name: 'Planner, account, advertising'
  },
  {
    Id: 8443,
    Code: '3554',
    Name: 'Planner, advertising'
  },
  {
    Id: 8444,
    Code: '3554',
    Name: 'Planner, media'
  },
  {
    Id: 8445,
    Code: '3554',
    Name: 'Promoter, club, marketing'
  },
  {
    Id: 8446,
    Code: '3554',
    Name: 'Promoter, entertainment'
  },
  {
    Id: 8447,
    Code: '3554',
    Name: 'Raiser, fund'
  },
  {
    Id: 8448,
    Code: '3554',
    Name: 'Representative, account, advertising'
  },
  {
    Id: 8449,
    Code: '3554',
    Name: 'Representative, development, business, marketing'
  },
  {
    Id: 8450,
    Code: '3554',
    Name: 'Researcher, market'
  },
  {
    Id: 8451,
    Code: '3554',
    Name: 'Specialist, marketing, digital'
  },
  {
    Id: 8452,
    Code: '3554',
    Name: 'Specialist, marketing'
  },
  {
    Id: 8453,
    Code: '3554',
    Name: 'Specialist, product, marketing'
  },
  {
    Id: 8454,
    Code: '3554',
    Name: 'Specialist, products, marketing'
  },
  {
    Id: 8455,
    Code: '3554',
    Name: 'Storyteller, brand'
  },
  {
    Id: 8456,
    Code: '3554',
    Name: 'Storyteller, marketing'
  },
  {
    Id: 8457,
    Code: '3554',
    Name: 'Strategist, brand'
  },
  {
    Id: 8458,
    Code: '3554',
    Name: 'Strategist, marketing'
  },
  {
    Id: 8459,
    Code: '3554',
    Name: 'Superintendent, marketing'
  },
  {
    Id: 8460,
    Code: '3554',
    Name: 'Supervisor, account, advertising'
  },
  {
    Id: 8461,
    Code: '3554',
    Name: 'Supervisor, development, sales'
  },
  {
    Id: 8462,
    Code: '3554',
    Name: 'Supervisor, research, market'
  },
  {
    Id: 8463,
    Code: '3554',
    Name: 'Writer, advertisement'
  },
  {
    Id: 8464,
    Code: '3554',
    Name: 'Writer, copy, creative, advertising'
  },
  {
    Id: 8465,
    Code: '3554',
    Name: 'Writer, publicity'
  },
  {
    Id: 8466,
    Code: '3555',
    Name: 'Adviser, sales, estate agents'
  },
  {
    Id: 8467,
    Code: '3555',
    Name: 'Agent, estate'
  },
  {
    Id: 8468,
    Code: '3555',
    Name: 'Agent, house'
  },
  {
    Id: 8469,
    Code: '3555',
    Name: 'Agent, land'
  },
  {
    Id: 8470,
    Code: '3555',
    Name: 'Agent, land and estate'
  },
  {
    Id: 8471,
    Code: '3555',
    Name: 'Agent, letting'
  },
  {
    Id: 8472,
    Code: '3555',
    Name: 'Agent, lettings'
  },
  {
    Id: 8473,
    Code: '3555',
    Name: 'Agent, management, property'
  },
  {
    Id: 8474,
    Code: '3555',
    Name: 'Agent, property'
  },
  {
    Id: 8475,
    Code: '3555',
    Name: 'Agent, relocation'
  },
  {
    Id: 8476,
    Code: '3555',
    Name: 'Agent, search, property'
  },
  {
    Id: 8477,
    Code: '3555',
    Name: 'Agent, site, agricultural estate'
  },
  {
    Id: 8478,
    Code: '3555',
    Name: 'Agent, transfer, business'
  },
  {
    Id: 8479,
    Code: '3555',
    Name: 'Agent, viewing'
  },
  {
    Id: 8480,
    Code: '3555',
    Name: 'Agent, agricultural estate'
  },
  {
    Id: 8481,
    Code: '3555',
    Name: 'Agent, estate agents'
  },
  {
    Id: 8482,
    Code: '3555',
    Name: 'Agent and valuer, land'
  },
  {
    Id: 8483,
    Code: '3555',
    Name: "Assistant, agent's, estate"
  },
  {
    Id: 8484,
    Code: '3555',
    Name: 'Assistant, sales, estate agents'
  },
  {
    Id: 8485,
    Code: '3555',
    Name: 'Assistant, viewing, estate agents'
  },
  {
    Id: 8486,
    Code: '3555',
    Name: 'Associate, sales, estate agents'
  },
  {
    Id: 8487,
    Code: '3555',
    Name: 'Auctioneer, antiques'
  },
  {
    Id: 8488,
    Code: '3555',
    Name: 'Auctioneer, livestock'
  },
  {
    Id: 8489,
    Code: '3555',
    Name: 'Auctioneer'
  },
  {
    Id: 8490,
    Code: '3555',
    Name: 'Auctioneer and valuer'
  },
  {
    Id: 8491,
    Code: '3555',
    Name: 'Consultant, letting'
  },
  {
    Id: 8492,
    Code: '3555',
    Name: 'Consultant, lettings'
  },
  {
    Id: 8493,
    Code: '3555',
    Name: 'Consultant, property'
  },
  {
    Id: 8494,
    Code: '3555',
    Name: 'Consultant, relocation'
  },
  {
    Id: 8495,
    Code: '3555',
    Name: 'Consultant, sales, estate agents'
  },
  {
    Id: 8496,
    Code: '3555',
    Name: 'Coordinator, lettings'
  },
  {
    Id: 8497,
    Code: '3555',
    Name: 'Dealer, property'
  },
  {
    Id: 8498,
    Code: '3555',
    Name: 'Inspector, property'
  },
  {
    Id: 8499,
    Code: '3555',
    Name: 'Negotiator, letting'
  },
  {
    Id: 8500,
    Code: '3555',
    Name: 'Negotiator, lettings'
  },
  {
    Id: 8501,
    Code: '3555',
    Name: 'Negotiator, sales, estate agents'
  },
  {
    Id: 8502,
    Code: '3555',
    Name: 'Negotiator, estate agents'
  },
  {
    Id: 8503,
    Code: '3555',
    Name: 'Salesman, land, estate agents'
  },
  {
    Id: 8504,
    Code: '3555',
    Name: 'Valuer, property'
  },
  {
    Id: 8505,
    Code: '3556',
    Name: 'BDM'
  },
  {
    Id: 8506,
    Code: '3556',
    Name: 'Controller, account, national'
  },
  {
    Id: 8507,
    Code: '3556',
    Name: 'Head of product development'
  },
  {
    Id: 8508,
    Code: '3556',
    Name: 'Manager, account, customer, wholesale, retail trade'
  },
  {
    Id: 8509,
    Code: '3556',
    Name: 'Manager, account, digital'
  },
  {
    Id: 8510,
    Code: '3556',
    Name: 'Manager, account, key, sales'
  },
  {
    Id: 8511,
    Code: '3556',
    Name: 'Manager, account, key'
  },
  {
    Id: 8512,
    Code: '3556',
    Name: 'Manager, account, national'
  },
  {
    Id: 8513,
    Code: '3556',
    Name: 'Manager, account, sales'
  },
  {
    Id: 8514,
    Code: '3556',
    Name: 'Manager, account, telesales'
  },
  {
    Id: 8515,
    Code: '3556',
    Name: 'Manager, account, sales'
  },
  {
    Id: 8516,
    Code: '3556',
    Name: 'Manager, account, wholesale, retail trade'
  },
  {
    Id: 8517,
    Code: '3556',
    Name: 'Manager, account'
  },
  {
    Id: 8518,
    Code: '3556',
    Name: 'Manager, accounts, sales'
  },
  {
    Id: 8519,
    Code: '3556',
    Name: 'Manager, agents, commission'
  },
  {
    Id: 8520,
    Code: '3556',
    Name: 'Manager, area, sales'
  },
  {
    Id: 8521,
    Code: '3556',
    Name: 'Manager, area, pools promoters'
  },
  {
    Id: 8522,
    Code: '3556',
    Name: 'Manager, area, sales force'
  },
  {
    Id: 8523,
    Code: '3556',
    Name: 'Manager, area, market research'
  },
  {
    Id: 8524,
    Code: '3556',
    Name: 'Manager, bid'
  },
  {
    Id: 8525,
    Code: '3556',
    Name: 'Manager, brand'
  },
  {
    Id: 8526,
    Code: '3556',
    Name: 'Manager, business, new'
  },
  {
    Id: 8527,
    Code: '3556',
    Name: 'Manager, channel'
  },
  {
    Id: 8528,
    Code: '3556',
    Name: 'Manager, circulation'
  },
  {
    Id: 8529,
    Code: '3556',
    Name: 'Manager, communications'
  },
  {
    Id: 8530,
    Code: '3556',
    Name: 'Manager, development, account'
  },
  {
    Id: 8531,
    Code: '3556',
    Name: 'Manager, development, business'
  },
  {
    Id: 8532,
    Code: '3556',
    Name: 'Manager, development, corporate'
  },
  {
    Id: 8533,
    Code: '3556',
    Name: 'Manager, development, market'
  },
  {
    Id: 8534,
    Code: '3556',
    Name: 'Manager, development, product'
  },
  {
    Id: 8535,
    Code: '3556',
    Name: 'Manager, development, sales'
  },
  {
    Id: 8536,
    Code: '3556',
    Name: 'Manager, development, strategic'
  },
  {
    Id: 8537,
    Code: '3556',
    Name: 'Manager, district, assurance company'
  },
  {
    Id: 8538,
    Code: '3556',
    Name: 'Manager, district, insurance'
  },
  {
    Id: 8539,
    Code: '3556',
    Name: 'Manager, e-commerce'
  },
  {
    Id: 8540,
    Code: '3556',
    Name: 'Manager, export'
  },
  {
    Id: 8541,
    Code: '3556',
    Name: 'Manager, field'
  },
  {
    Id: 8542,
    Code: '3556',
    Name: 'Manager, force, sales'
  },
  {
    Id: 8543,
    Code: '3556',
    Name: 'Manager, import'
  },
  {
    Id: 8544,
    Code: '3556',
    Name: 'Manager, label, music publishing'
  },
  {
    Id: 8545,
    Code: '3556',
    Name: 'Manager, licensing'
  },
  {
    Id: 8546,
    Code: '3556',
    Name: 'Manager, merchandise'
  },
  {
    Id: 8547,
    Code: '3556',
    Name: 'Manager, merchandising'
  },
  {
    Id: 8548,
    Code: '3556',
    Name: 'Manager, operations, commercial'
  },
  {
    Id: 8549,
    Code: '3556',
    Name: 'Manager, pricing'
  },
  {
    Id: 8550,
    Code: '3556',
    Name: 'Manager, product, sales'
  },
  {
    Id: 8551,
    Code: '3556',
    Name: 'Manager, product'
  },
  {
    Id: 8552,
    Code: '3556',
    Name: 'Manager, production, import agency'
  },
  {
    Id: 8553,
    Code: '3556',
    Name: 'Manager, products'
  },
  {
    Id: 8554,
    Code: '3556',
    Name: 'Manager, promotion, music'
  },
  {
    Id: 8555,
    Code: '3556',
    Name: 'Manager, promotion, sales'
  },
  {
    Id: 8556,
    Code: '3556',
    Name: 'Manager, regional, sales force'
  },
  {
    Id: 8557,
    Code: '3556',
    Name: 'Manager, revenue, onboard'
  },
  {
    Id: 8558,
    Code: '3556',
    Name: 'Manager, rights, publishing company'
  },
  {
    Id: 8559,
    Code: '3556',
    Name: 'Manager, sales, advertisement'
  },
  {
    Id: 8560,
    Code: '3556',
    Name: 'Manager, sales, advertising'
  },
  {
    Id: 8561,
    Code: '3556',
    Name: 'Manager, sales, area'
  },
  {
    Id: 8562,
    Code: '3556',
    Name: 'Manager, sales, district'
  },
  {
    Id: 8563,
    Code: '3556',
    Name: 'Manager, sales, export'
  },
  {
    Id: 8564,
    Code: '3556',
    Name: 'Manager, sales, field'
  },
  {
    Id: 8565,
    Code: '3556',
    Name: 'Manager, sales, internal'
  },
  {
    Id: 8566,
    Code: '3556',
    Name: 'Manager, sales, international'
  },
  {
    Id: 8567,
    Code: '3556',
    Name: 'Manager, sales, national'
  },
  {
    Id: 8568,
    Code: '3556',
    Name: 'Manager, sales, regional'
  },
  {
    Id: 8569,
    Code: '3556',
    Name: 'Manager, sales, residential'
  },
  {
    Id: 8570,
    Code: '3556',
    Name: 'Manager, sales, technical'
  },
  {
    Id: 8571,
    Code: '3556',
    Name: 'Manager, sales, telephone'
  },
  {
    Id: 8572,
    Code: '3556',
    Name: 'Manager, sales, territory'
  },
  {
    Id: 8573,
    Code: '3556',
    Name: 'Manager, sales'
  },
  {
    Id: 8574,
    Code: '3556',
    Name: 'Manager, sales and accounts'
  },
  {
    Id: 8575,
    Code: '3556',
    Name: 'Manager, sales and advertising'
  },
  {
    Id: 8576,
    Code: '3556',
    Name: 'Manager, sales and commercial'
  },
  {
    Id: 8577,
    Code: '3556',
    Name: 'Manager, sales and logistics'
  },
  {
    Id: 8578,
    Code: '3556',
    Name: 'Manager, sales and marketing'
  },
  {
    Id: 8579,
    Code: '3556',
    Name: 'Manager, sales and service'
  },
  {
    Id: 8580,
    Code: '3556',
    Name: 'Manager, services, client, advertising'
  },
  {
    Id: 8581,
    Code: '3556',
    Name: 'Manager, strategy, business'
  },
  {
    Id: 8582,
    Code: '3556',
    Name: 'Manager, strategy'
  },
  {
    Id: 8583,
    Code: '3556',
    Name: 'Manager, support, sales'
  },
  {
    Id: 8584,
    Code: '3556',
    Name: 'Manager, team, sales force'
  },
  {
    Id: 8585,
    Code: '3556',
    Name: 'Manager, tele-marketing'
  },
  {
    Id: 8586,
    Code: '3556',
    Name: 'Manager, telemarketing'
  },
  {
    Id: 8587,
    Code: '3556',
    Name: 'Manager, telesales'
  },
  {
    Id: 8588,
    Code: '3556',
    Name: 'Manager, telephone sales'
  },
  {
    Id: 8589,
    Code: '3556',
    Name: 'Manager, commission agents'
  },
  {
    Id: 8590,
    Code: '3556',
    Name: 'Manager, export agency'
  },
  {
    Id: 8591,
    Code: '3556',
    Name: 'Manager, import agency'
  },
  {
    Id: 8592,
    Code: '3556',
    Name: 'Manager, market research'
  },
  {
    Id: 8593,
    Code: '3556',
    Name: 'Manager, ship brokers'
  },
  {
    Id: 8594,
    Code: '3557',
    Name: 'Assistant, event'
  },
  {
    Id: 8595,
    Code: '3557',
    Name: 'Assistant, events'
  },
  {
    Id: 8596,
    Code: '3557',
    Name: 'Consultant, conference'
  },
  {
    Id: 8597,
    Code: '3557',
    Name: 'Consultant, exhibition'
  },
  {
    Id: 8598,
    Code: '3557',
    Name: 'Consultant, exhibitions'
  },
  {
    Id: 8599,
    Code: '3557',
    Name: 'Consultant, wedding'
  },
  {
    Id: 8600,
    Code: '3557',
    Name: 'Coordinator, banqueting'
  },
  {
    Id: 8601,
    Code: '3557',
    Name: 'Coordinator, call, conference'
  },
  {
    Id: 8602,
    Code: '3557',
    Name: 'Coordinator, conference'
  },
  {
    Id: 8603,
    Code: '3557',
    Name: 'Coordinator, conference and events'
  },
  {
    Id: 8604,
    Code: '3557',
    Name: 'Coordinator, event'
  },
  {
    Id: 8605,
    Code: '3557',
    Name: 'Coordinator, events'
  },
  {
    Id: 8606,
    Code: '3557',
    Name: 'Coordinator, exhibition'
  },
  {
    Id: 8607,
    Code: '3557',
    Name: 'Coordinator, function'
  },
  {
    Id: 8608,
    Code: '3557',
    Name: 'Coordinator, seminars'
  },
  {
    Id: 8609,
    Code: '3557',
    Name: 'Coordinator, wedding'
  },
  {
    Id: 8610,
    Code: '3557',
    Name: 'Director, event'
  },
  {
    Id: 8611,
    Code: '3557',
    Name: 'Director, events'
  },
  {
    Id: 8612,
    Code: '3557',
    Name: 'Director of events'
  },
  {
    Id: 8613,
    Code: '3557',
    Name: 'Executive, event'
  },
  {
    Id: 8614,
    Code: '3557',
    Name: 'Executive, events'
  },
  {
    Id: 8615,
    Code: '3557',
    Name: 'Manager, banqueting'
  },
  {
    Id: 8616,
    Code: '3557',
    Name: 'Manager, centre, conference'
  },
  {
    Id: 8617,
    Code: '3557',
    Name: 'Manager, conference'
  },
  {
    Id: 8618,
    Code: '3557',
    Name: 'Manager, conference and banqueting'
  },
  {
    Id: 8619,
    Code: '3557',
    Name: 'Manager, event'
  },
  {
    Id: 8620,
    Code: '3557',
    Name: 'Manager, events'
  },
  {
    Id: 8621,
    Code: '3557',
    Name: 'Manager, exhibition'
  },
  {
    Id: 8622,
    Code: '3557',
    Name: 'Manager, hospitality'
  },
  {
    Id: 8623,
    Code: '3557',
    Name: 'Manager, production, conference organisers'
  },
  {
    Id: 8624,
    Code: '3557',
    Name: 'Manager, production, corporate hospitality'
  },
  {
    Id: 8625,
    Code: '3557',
    Name: 'Manager, production, exhibition contracting'
  },
  {
    Id: 8626,
    Code: '3557',
    Name: 'Manager, seminar'
  },
  {
    Id: 8627,
    Code: '3557',
    Name: 'Manager, conference organisers'
  },
  {
    Id: 8628,
    Code: '3557',
    Name: 'Manager, corporate hospitality'
  },
  {
    Id: 8629,
    Code: '3557',
    Name: 'Officer, conference'
  },
  {
    Id: 8630,
    Code: '3557',
    Name: 'Officer, events'
  },
  {
    Id: 8631,
    Code: '3557',
    Name: 'Officer, exhibition'
  },
  {
    Id: 8632,
    Code: '3557',
    Name: 'Organiser, catering'
  },
  {
    Id: 8633,
    Code: '3557',
    Name: 'Organiser, conference'
  },
  {
    Id: 8634,
    Code: '3557',
    Name: 'Organiser, conference and exhibition'
  },
  {
    Id: 8635,
    Code: '3557',
    Name: 'Organiser, entertainment'
  },
  {
    Id: 8636,
    Code: '3557',
    Name: 'Organiser, entertainments'
  },
  {
    Id: 8637,
    Code: '3557',
    Name: 'Organiser, event'
  },
  {
    Id: 8638,
    Code: '3557',
    Name: 'Organiser, events'
  },
  {
    Id: 8639,
    Code: '3557',
    Name: 'Organiser, exhibition'
  },
  {
    Id: 8640,
    Code: '3557',
    Name: 'Organiser, exhibitions'
  },
  {
    Id: 8641,
    Code: '3557',
    Name: 'Organiser, festival'
  },
  {
    Id: 8642,
    Code: '3557',
    Name: 'Owner, exhibition contracting'
  },
  {
    Id: 8643,
    Code: '3557',
    Name: 'Planner, event'
  },
  {
    Id: 8644,
    Code: '3557',
    Name: 'Planner, events'
  },
  {
    Id: 8645,
    Code: '3557',
    Name: 'Planner, wedding'
  },
  {
    Id: 8646,
    Code: '3557',
    Name: 'Producer, conference'
  },
  {
    Id: 8647,
    Code: '3557',
    Name: 'Promoter, sports activities'
  },
  {
    Id: 8648,
    Code: '3557',
    Name: 'Representative, conference'
  },
  {
    Id: 8649,
    Code: '3557',
    Name: 'Supervisor, banqueting'
  },
  {
    Id: 8650,
    Code: '3560',
    Name: 'A3, Dept for International Development'
  },
  {
    Id: 8651,
    Code: '3560',
    Name: 'AP(T), grade 4,5, local government'
  },
  {
    Id: 8652,
    Code: '3560',
    Name: 'Administrator, act, health, mental'
  },
  {
    Id: 8653,
    Code: '3560',
    Name: 'Administrator, hospital'
  },
  {
    Id: 8654,
    Code: '3560',
    Name: 'Administrator, hospital service'
  },
  {
    Id: 8655,
    Code: '3560',
    Name: 'Assistant, services, democratic'
  },
  {
    Id: 8656,
    Code: '3560',
    Name: 'B1, Dept for International Development'
  },
  {
    Id: 8657,
    Code: '3560',
    Name: 'B1, Northern Ireland Office'
  },
  {
    Id: 8658,
    Code: '3560',
    Name: 'B2, Cabinet Office'
  },
  {
    Id: 8659,
    Code: '3560',
    Name: 'B2, Northern Ireland Office'
  },
  {
    Id: 8660,
    Code: '3560',
    Name: 'B2, Scottish Government'
  },
  {
    Id: 8661,
    Code: '3560',
    Name: 'B3, Scottish Government'
  },
  {
    Id: 8662,
    Code: '3560',
    Name: 'Band 3, Health and Safety Executive'
  },
  {
    Id: 8663,
    Code: '3560',
    Name: 'Band 3A, Meteorological Office'
  },
  {
    Id: 8664,
    Code: '3560',
    Name: 'Band 3B, Meteorological Office'
  },
  {
    Id: 8665,
    Code: '3560',
    Name: 'Band 3C, Meteorological Office'
  },
  {
    Id: 8666,
    Code: '3560',
    Name: 'Band 4, Health and Safety Executive'
  },
  {
    Id: 8667,
    Code: '3560',
    Name: 'Band D, Ministry of Justice'
  },
  {
    Id: 8668,
    Code: '3560',
    Name: 'Band D, National Assembly for Wales'
  },
  {
    Id: 8669,
    Code: '3560',
    Name: 'Band E, Ministry of Justice'
  },
  {
    Id: 8670,
    Code: '3560',
    Name: 'Band E, National Assembly for Wales'
  },
  {
    Id: 8671,
    Code: '3560',
    Name: 'C1, Office for National Statistics'
  },
  {
    Id: 8672,
    Code: '3560',
    Name: 'C2, Jobcentre Plus'
  },
  {
    Id: 8673,
    Code: '3560',
    Name: 'C2, Office for National Statistics'
  },
  {
    Id: 8674,
    Code: '3560',
    Name: 'C3, Jobcentre Plus'
  },
  {
    Id: 8675,
    Code: '3560',
    Name: 'C3, Office for National Statistics'
  },
  {
    Id: 8676,
    Code: '3560',
    Name: 'C4, Jobcentre Plus'
  },
  {
    Id: 8677,
    Code: '3560',
    Name: 'C4, Office for National Statistics'
  },
  {
    Id: 8678,
    Code: '3560',
    Name: 'Caseworker, senior, government'
  },
  {
    Id: 8679,
    Code: '3560',
    Name: 'Cashier, chief, government'
  },
  {
    Id: 8680,
    Code: '3560',
    Name: 'Clerk, chief, government'
  },
  {
    Id: 8681,
    Code: '3560',
    Name: 'Clerk, principal, government'
  },
  {
    Id: 8682,
    Code: '3560',
    Name: 'Collector, chief, HM Revenue and Customs'
  },
  {
    Id: 8683,
    Code: '3560',
    Name: 'Collector, grade, higher, HM Revenue and Customs'
  },
  {
    Id: 8684,
    Code: '3560',
    Name: 'Collector, regional'
  },
  {
    Id: 8685,
    Code: '3560',
    Name: 'Collector, senior, government'
  },
  {
    Id: 8686,
    Code: '3560',
    Name: 'Collector, HM Revenue and Customs'
  },
  {
    Id: 8687,
    Code: '3560',
    Name: 'Controller, regional, government'
  },
  {
    Id: 8688,
    Code: '3560',
    Name: 'Controller, government'
  },
  {
    Id: 8689,
    Code: '3560',
    Name: 'Coordinator, care, hospital service'
  },
  {
    Id: 8690,
    Code: '3560',
    Name: 'Coordinator, discharge'
  },
  {
    Id: 8691,
    Code: '3560',
    Name: 'Coordinator, experience, work'
  },
  {
    Id: 8692,
    Code: '3560',
    Name: 'Coordinator, MDT, hospital service'
  },
  {
    Id: 8693,
    Code: '3560',
    Name: 'Coordinator, project, local government'
  },
  {
    Id: 8694,
    Code: '3560',
    Name: 'Coordinator, team, multidisciplinary, hospital service'
  },
  {
    Id: 8695,
    Code: '3560',
    Name: 'Counsellor, government'
  },
  {
    Id: 8696,
    Code: '3560',
    Name: 'Executive, postal, grade A, PO'
  },
  {
    Id: 8697,
    Code: '3560',
    Name: 'Executive, postal, grade B, PO'
  },
  {
    Id: 8698,
    Code: '3560',
    Name: 'Facilitator, experience, patient'
  },
  {
    Id: 8699,
    Code: '3560',
    Name: 'Grade 8, Foreign and Commonwealth Office'
  },
  {
    Id: 8700,
    Code: '3560',
    Name: 'Grade 9, Foreign and Commonwealth Office'
  },
  {
    Id: 8701,
    Code: '3560',
    Name: 'Grade 10, Foreign and Commonwealth Office'
  },
  {
    Id: 8702,
    Code: '3560',
    Name: 'Grade B, DCMS'
  },
  {
    Id: 8703,
    Code: '3560',
    Name: 'HEO, government'
  },
  {
    Id: 8704,
    Code: '3560',
    Name: 'Inspector, staff, government'
  },
  {
    Id: 8705,
    Code: '3560',
    Name: 'Leader, team, benefit, local government'
  },
  {
    Id: 8706,
    Code: '3560',
    Name: 'Leader, team, coach, work, government'
  },
  {
    Id: 8707,
    Code: '3560',
    Name: 'Leader, team, performance, Jobcentre Plus'
  },
  {
    Id: 8708,
    Code: '3560',
    Name: 'Leader, team, local government'
  },
  {
    Id: 8709,
    Code: '3560',
    Name: 'Manager, amenity, area'
  },
  {
    Id: 8710,
    Code: '3560',
    Name: 'Manager, bed'
  },
  {
    Id: 8711,
    Code: '3560',
    Name: 'Manager, branch, government'
  },
  {
    Id: 8712,
    Code: '3560',
    Name: 'Manager, elections'
  },
  {
    Id: 8713,
    Code: '3560',
    Name: 'Manager, Jobcentre'
  },
  {
    Id: 8714,
    Code: '3560',
    Name: 'Manager, office, insurance, government'
  },
  {
    Id: 8715,
    Code: '3560',
    Name: 'Manager, protection, environmental'
  },
  {
    Id: 8716,
    Code: '3560',
    Name: 'Manager, services, electoral'
  },
  {
    Id: 8717,
    Code: '3560',
    Name: 'Manager, tax, council'
  },
  {
    Id: 8718,
    Code: '3560',
    Name: 'Manager, team, HM Revenue and Customs'
  },
  {
    Id: 8719,
    Code: '3560',
    Name: 'Manager, Jobcentre Plus'
  },
  {
    Id: 8720,
    Code: '3560',
    Name: 'Officer, agricultural, government'
  },
  {
    Id: 8721,
    Code: '3560',
    Name: 'Officer, appointments, government'
  },
  {
    Id: 8722,
    Code: '3560',
    Name: 'Officer, area, government'
  },
  {
    Id: 8723,
    Code: '3560',
    Name: 'Officer, assurance, VAT, government'
  },
  {
    Id: 8724,
    Code: '3560',
    Name: 'Officer, awareness, waste'
  },
  {
    Id: 8725,
    Code: '3560',
    Name: 'Officer, chief, community health'
  },
  {
    Id: 8726,
    Code: '3560',
    Name: 'Officer, clerical, higher, government'
  },
  {
    Id: 8727,
    Code: '3560',
    Name: 'Officer, cohesion, community'
  },
  {
    Id: 8728,
    Code: '3560',
    Name: 'Officer, commissioning, local government'
  },
  {
    Id: 8729,
    Code: '3560',
    Name: 'Officer, control, senior, HM Revenue and Customs'
  },
  {
    Id: 8730,
    Code: '3560',
    Name: 'Officer, determination, local government'
  },
  {
    Id: 8731,
    Code: '3560',
    Name: 'Officer, development, policy, local government'
  },
  {
    Id: 8732,
    Code: '3560',
    Name: 'Officer, division, Ordnance Survey'
  },
  {
    Id: 8733,
    Code: '3560',
    Name: 'Officer, equipment, local government'
  },
  {
    Id: 8734,
    Code: '3560',
    Name: 'Officer, establishment, government'
  },
  {
    Id: 8735,
    Code: '3560',
    Name: 'Officer, European, local government'
  },
  {
    Id: 8736,
    Code: '3560',
    Name: 'Officer, executive, higher, government'
  },
  {
    Id: 8737,
    Code: '3560',
    Name: 'Officer, executive, higher, PO'
  },
  {
    Id: 8738,
    Code: '3560',
    Name: 'Officer, executive, higher (D), fast stream, government'
  },
  {
    Id: 8739,
    Code: '3560',
    Name: 'Officer, executive, senior, government'
  },
  {
    Id: 8740,
    Code: '3560',
    Name: 'Officer, executive, senior, PO'
  },
  {
    Id: 8741,
    Code: '3560',
    Name: 'Officer, experience, patient'
  },
  {
    Id: 8742,
    Code: '3560',
    Name: 'Officer, finance, regional'
  },
  {
    Id: 8743,
    Code: '3560',
    Name: 'Officer, freedom of information'
  },
  {
    Id: 8744,
    Code: '3560',
    Name: 'Officer, group, senior, MOD'
  },
  {
    Id: 8745,
    Code: '3560',
    Name: 'Officer, higher, HM Revenue and Customs'
  },
  {
    Id: 8746,
    Code: '3560',
    Name: 'Officer, information'
  },
  {
    Id: 8747,
    Code: '3560',
    Name: 'Officer, management, waste'
  },
  {
    Id: 8748,
    Code: '3560',
    Name: 'Officer, monitoring, environmental'
  },
  {
    Id: 8749,
    Code: '3560',
    Name: 'Officer, PALS'
  },
  {
    Id: 8750,
    Code: '3560',
    Name: 'Officer, patient advice and liaison service'
  },
  {
    Id: 8751,
    Code: '3560',
    Name: 'Officer, presenting, government'
  },
  {
    Id: 8752,
    Code: '3560',
    Name: 'Officer, principal, local government'
  },
  {
    Id: 8753,
    Code: '3560',
    Name: 'Officer, project, regeneration, local government'
  },
  {
    Id: 8754,
    Code: '3560',
    Name: 'Officer, recycling, local government'
  },
  {
    Id: 8755,
    Code: '3560',
    Name: 'Officer, regeneration, local government'
  },
  {
    Id: 8756,
    Code: '3560',
    Name: 'Officer, regional, government'
  },
  {
    Id: 8757,
    Code: '3560',
    Name: 'Officer, revenue, principal, local government'
  },
  {
    Id: 8758,
    Code: '3560',
    Name: 'Officer, revenue, senior, government'
  },
  {
    Id: 8759,
    Code: '3560',
    Name: 'Officer, securities, government'
  },
  {
    Id: 8760,
    Code: '3560',
    Name: 'Officer, service, foreign, grade A1-A8, B1-B4'
  },
  {
    Id: 8761,
    Code: '3560',
    Name: 'Officer, services, democratic'
  },
  {
    Id: 8762,
    Code: '3560',
    Name: 'Officer, staff, government'
  },
  {
    Id: 8763,
    Code: '3560',
    Name: 'Officer, substitution, grade I, MOD'
  },
  {
    Id: 8764,
    Code: '3560',
    Name: 'Officer, support, budget'
  },
  {
    Id: 8765,
    Code: '3560',
    Name: 'Officer, support, waste'
  },
  {
    Id: 8766,
    Code: '3560',
    Name: 'Officer, value, best, local government'
  },
  {
    Id: 8767,
    Code: '3560',
    Name: 'Officer, VAT, government'
  },
  {
    Id: 8768,
    Code: '3560',
    Name: 'Officer-in-charge, HM Revenue and Customs'
  },
  {
    Id: 8769,
    Code: '3560',
    Name: 'PO, nos, local government'
  },
  {
    Id: 8770,
    Code: '3560',
    Name: 'Payband 3, Dept of Health & Social Care'
  },
  {
    Id: 8771,
    Code: '3560',
    Name: 'RE1, HM Land Registry'
  },
  {
    Id: 8772,
    Code: '3560',
    Name: 'SEO, government'
  },
  {
    Id: 8773,
    Code: '3560',
    Name: 'SO, nos, local government'
  },
  {
    Id: 8774,
    Code: '3560',
    Name: 'Servant, civil, HEO, SEO'
  },
  {
    Id: 8775,
    Code: '3560',
    Name: 'Superintendent, grain, DEFRA'
  },
  {
    Id: 8776,
    Code: '3560',
    Name: 'Superintendent, highways, local government'
  },
  {
    Id: 8777,
    Code: '3560',
    Name: 'Superintendent, rents, local government'
  },
  {
    Id: 8778,
    Code: '3560',
    Name: 'Superintendent, HM Land Registry'
  },
  {
    Id: 8779,
    Code: '3560',
    Name: 'Superintendent of Stamping, 1st Class'
  },
  {
    Id: 8780,
    Code: '3560',
    Name: 'Supervisor, Jobcentre Plus'
  },
  {
    Id: 8781,
    Code: '3560',
    Name: 'Worker, case, senior, government'
  },
  {
    Id: 8782,
    Code: '3571',
    Name: 'Adviser, diversity and equality'
  },
  {
    Id: 8783,
    Code: '3571',
    Name: 'Adviser, employment'
  },
  {
    Id: 8784,
    Code: '3571',
    Name: 'Adviser, equalities'
  },
  {
    Id: 8785,
    Code: '3571',
    Name: 'Adviser, equality'
  },
  {
    Id: 8786,
    Code: '3571',
    Name: 'Adviser, equality and diversity'
  },
  {
    Id: 8787,
    Code: '3571',
    Name: 'Adviser, HR'
  },
  {
    Id: 8788,
    Code: '3571',
    Name: 'Adviser, people'
  },
  {
    Id: 8789,
    Code: '3571',
    Name: 'Adviser, personnel'
  },
  {
    Id: 8790,
    Code: '3571',
    Name: 'Adviser, policy, equalities'
  },
  {
    Id: 8791,
    Code: '3571',
    Name: 'Adviser, recruitment'
  },
  {
    Id: 8792,
    Code: '3571',
    Name: 'Adviser, relations, employee'
  },
  {
    Id: 8793,
    Code: '3571',
    Name: 'Adviser, relations, industrial'
  },
  {
    Id: 8794,
    Code: '3571',
    Name: 'Adviser, resources, human'
  },
  {
    Id: 8795,
    Code: '3571',
    Name: 'Agent, recruitment'
  },
  {
    Id: 8796,
    Code: '3571',
    Name: 'Analyst, compensation and benefits'
  },
  {
    Id: 8797,
    Code: '3571',
    Name: 'Analyst, evaluation, job'
  },
  {
    Id: 8798,
    Code: '3571',
    Name: 'Analyst, HR'
  },
  {
    Id: 8799,
    Code: '3571',
    Name: 'Analyst, personnel'
  },
  {
    Id: 8800,
    Code: '3571',
    Name: 'Analyst, resources, human'
  },
  {
    Id: 8801,
    Code: '3571',
    Name: 'Analyst, reward'
  },
  {
    Id: 8802,
    Code: '3571',
    Name: 'Assistant, evaluation, job'
  },
  {
    Id: 8803,
    Code: '3571',
    Name: 'Assistant, HR'
  },
  {
    Id: 8804,
    Code: '3571',
    Name: 'Assistant, people'
  },
  {
    Id: 8805,
    Code: '3571',
    Name: 'Assistant, personnel'
  },
  {
    Id: 8806,
    Code: '3571',
    Name: 'Assistant, recruitment'
  },
  {
    Id: 8807,
    Code: '3571',
    Name: 'Assistant, relations, employee'
  },
  {
    Id: 8808,
    Code: '3571',
    Name: 'Assistant, resource, human'
  },
  {
    Id: 8809,
    Code: '3571',
    Name: 'Assistant, resources, human'
  },
  {
    Id: 8810,
    Code: '3571',
    Name: 'Assistant, staff, railways'
  },
  {
    Id: 8811,
    Code: '3571',
    Name: 'Associate, recruitment'
  },
  {
    Id: 8812,
    Code: '3571',
    Name: 'Conciliator, Acas'
  },
  {
    Id: 8813,
    Code: '3571',
    Name: 'Consultant, acquisition, talent'
  },
  {
    Id: 8814,
    Code: '3571',
    Name: 'Consultant, attraction, talent'
  },
  {
    Id: 8815,
    Code: '3571',
    Name: 'Consultant, employment'
  },
  {
    Id: 8816,
    Code: '3571',
    Name: 'Consultant, HR'
  },
  {
    Id: 8817,
    Code: '3571',
    Name: 'Consultant, personnel'
  },
  {
    Id: 8818,
    Code: '3571',
    Name: 'Consultant, recruitment, permanents'
  },
  {
    Id: 8819,
    Code: '3571',
    Name: 'Consultant, recruitment'
  },
  {
    Id: 8820,
    Code: '3571',
    Name: 'Consultant, relations, employer'
  },
  {
    Id: 8821,
    Code: '3571',
    Name: 'Consultant, resource, human'
  },
  {
    Id: 8822,
    Code: '3571',
    Name: 'Consultant, resources, human'
  },
  {
    Id: 8823,
    Code: '3571',
    Name: 'Controller, staff'
  },
  {
    Id: 8824,
    Code: '3571',
    Name: 'Coordinator, HR'
  },
  {
    Id: 8825,
    Code: '3571',
    Name: 'Coordinator, recruitment'
  },
  {
    Id: 8826,
    Code: '3571',
    Name: 'Coordinator, resource'
  },
  {
    Id: 8827,
    Code: '3571',
    Name: 'Coordinator, resourcing'
  },
  {
    Id: 8828,
    Code: '3571',
    Name: 'Executive, development, client'
  },
  {
    Id: 8829,
    Code: '3571',
    Name: 'Executive, personnel'
  },
  {
    Id: 8830,
    Code: '3571',
    Name: 'Executive, recruitment'
  },
  {
    Id: 8831,
    Code: '3571',
    Name: 'Generalist, HR'
  },
  {
    Id: 8832,
    Code: '3571',
    Name: 'Generalist, resources, human'
  },
  {
    Id: 8833,
    Code: '3571',
    Name: 'Headhunter'
  },
  {
    Id: 8834,
    Code: '3571',
    Name: 'Hunter, head'
  },
  {
    Id: 8835,
    Code: '3571',
    Name: 'Inspector, employment, railway'
  },
  {
    Id: 8836,
    Code: '3571',
    Name: 'Leader, team, recruitment'
  },
  {
    Id: 8837,
    Code: '3571',
    Name: 'Manager, account, recruitment agency'
  },
  {
    Id: 8838,
    Code: '3571',
    Name: 'Officer, conciliation'
  },
  {
    Id: 8839,
    Code: '3571',
    Name: 'Officer, consultation, coal mine'
  },
  {
    Id: 8840,
    Code: '3571',
    Name: 'Officer, development, career'
  },
  {
    Id: 8841,
    Code: '3571',
    Name: 'Officer, diversity and equality'
  },
  {
    Id: 8842,
    Code: '3571',
    Name: 'Officer, employment'
  },
  {
    Id: 8843,
    Code: '3571',
    Name: 'Officer, equalities'
  },
  {
    Id: 8844,
    Code: '3571',
    Name: 'Officer, equality'
  },
  {
    Id: 8845,
    Code: '3571',
    Name: 'Officer, equality and diversity'
  },
  {
    Id: 8846,
    Code: '3571',
    Name: 'Officer, establishment'
  },
  {
    Id: 8847,
    Code: '3571',
    Name: 'Officer, HR'
  },
  {
    Id: 8848,
    Code: '3571',
    Name: 'Officer, labour'
  },
  {
    Id: 8849,
    Code: '3571',
    Name: 'Officer, liaison, labour'
  },
  {
    Id: 8850,
    Code: '3571',
    Name: 'Officer, management, labour'
  },
  {
    Id: 8851,
    Code: '3571',
    Name: 'Officer, manpower, coal mine'
  },
  {
    Id: 8852,
    Code: '3571',
    Name: 'Officer, opportunities, equal'
  },
  {
    Id: 8853,
    Code: '3571',
    Name: 'Officer, personnel'
  },
  {
    Id: 8854,
    Code: '3571',
    Name: 'Officer, recruiting'
  },
  {
    Id: 8855,
    Code: '3571',
    Name: 'Officer, recruitment'
  },
  {
    Id: 8856,
    Code: '3571',
    Name: 'Officer, relations, employee'
  },
  {
    Id: 8857,
    Code: '3571',
    Name: 'Officer, relations, industrial'
  },
  {
    Id: 8858,
    Code: '3571',
    Name: 'Officer, relations, labour'
  },
  {
    Id: 8859,
    Code: '3571',
    Name: 'Officer, resource, human'
  },
  {
    Id: 8860,
    Code: '3571',
    Name: 'Officer, resources, human'
  },
  {
    Id: 8861,
    Code: '3571',
    Name: 'Officer, services, personnel'
  },
  {
    Id: 8862,
    Code: '3571',
    Name: 'Officer, staff, gas supplier'
  },
  {
    Id: 8863,
    Code: '3571',
    Name: 'Officer, staff, local government'
  },
  {
    Id: 8864,
    Code: '3571',
    Name: 'Officer, staff, railways'
  },
  {
    Id: 8865,
    Code: '3571',
    Name: 'Officer, staffing'
  },
  {
    Id: 8866,
    Code: '3571',
    Name: 'Officer, vetting, positive'
  },
  {
    Id: 8867,
    Code: '3571',
    Name: 'Partner, talent, recruitment'
  },
  {
    Id: 8868,
    Code: '3571',
    Name: 'Planner, resource'
  },
  {
    Id: 8869,
    Code: '3571',
    Name: 'Recruiter, staff'
  },
  {
    Id: 8870,
    Code: '3571',
    Name: 'Recruiter, human resources'
  },
  {
    Id: 8871,
    Code: '3571',
    Name: 'Researcher, recruitment'
  },
  {
    Id: 8872,
    Code: '3571',
    Name: 'Searcher, job'
  },
  {
    Id: 8873,
    Code: '3571',
    Name: 'Specialist, acquisition, talent'
  },
  {
    Id: 8874,
    Code: '3571',
    Name: 'Specialist, attraction, talent'
  },
  {
    Id: 8875,
    Code: '3571',
    Name: 'Specialist, HR'
  },
  {
    Id: 8876,
    Code: '3571',
    Name: 'Specialist, recruitment'
  },
  {
    Id: 8877,
    Code: '3571',
    Name: 'Specialist, resource'
  },
  {
    Id: 8878,
    Code: '3571',
    Name: 'Specialist, resources, human'
  },
  {
    Id: 8879,
    Code: '3571',
    Name: 'Superintendent, staff'
  },
  {
    Id: 8880,
    Code: '3572',
    Name: 'Adviser, career'
  },
  {
    Id: 8881,
    Code: '3572',
    Name: 'Adviser, careers'
  },
  {
    Id: 8882,
    Code: '3572',
    Name: 'Adviser, personal, careers service'
  },
  {
    Id: 8883,
    Code: '3572',
    Name: 'Assistant, careers'
  },
  {
    Id: 8884,
    Code: '3572',
    Name: 'Broker, employment'
  },
  {
    Id: 8885,
    Code: '3572',
    Name: 'Coach, career'
  },
  {
    Id: 8886,
    Code: '3572',
    Name: 'Coach, employment'
  },
  {
    Id: 8887,
    Code: '3572',
    Name: 'Coach, job'
  },
  {
    Id: 8888,
    Code: '3572',
    Name: 'Consultant, career'
  },
  {
    Id: 8889,
    Code: '3572',
    Name: 'Consultant, careers'
  },
  {
    Id: 8890,
    Code: '3572',
    Name: 'Consultant, engagement, employer, education'
  },
  {
    Id: 8891,
    Code: '3572',
    Name: 'Consultant, outplacement'
  },
  {
    Id: 8892,
    Code: '3572',
    Name: 'Coordinator, education, careers'
  },
  {
    Id: 8893,
    Code: '3572',
    Name: 'Coordinator, placement'
  },
  {
    Id: 8894,
    Code: '3572',
    Name: 'Counsellor, outplacement'
  },
  {
    Id: 8895,
    Code: '3572',
    Name: 'Counsellor, redundancy'
  },
  {
    Id: 8896,
    Code: '3572',
    Name: 'Officer, abroad, year'
  },
  {
    Id: 8897,
    Code: '3572',
    Name: 'Officer, advisory, careers'
  },
  {
    Id: 8898,
    Code: '3572',
    Name: 'Officer, careers'
  },
  {
    Id: 8899,
    Code: '3572',
    Name: 'Officer, employment, youth'
  },
  {
    Id: 8900,
    Code: '3572',
    Name: 'Officer, employment, careers service'
  },
  {
    Id: 8901,
    Code: '3572',
    Name: 'Officer, placement'
  },
  {
    Id: 8902,
    Code: '3572',
    Name: 'Supervisor, placement'
  },
  {
    Id: 8903,
    Code: '3572',
    Name: 'Teacher, careers'
  },
  {
    Id: 8904,
    Code: '3572',
    Name: 'Tutor, guidance, graduate'
  },
  {
    Id: 8905,
    Code: '3573',
    Name: 'Coordinator, e-learning'
  },
  {
    Id: 8906,
    Code: '3573',
    Name: 'Coordinator, eLearning'
  },
  {
    Id: 8907,
    Code: '3573',
    Name: 'Instructor, computer'
  },
  {
    Id: 8908,
    Code: '3573',
    Name: 'Manager, e-learning'
  },
  {
    Id: 8909,
    Code: '3573',
    Name: 'Manager, learning, digital'
  },
  {
    Id: 8910,
    Code: '3573',
    Name: 'Trainer, computer'
  },
  {
    Id: 8911,
    Code: '3573',
    Name: 'Trainer, IT'
  },
  {
    Id: 8912,
    Code: '3573',
    Name: 'Trainer, software'
  },
  {
    Id: 8913,
    Code: '3573',
    Name: 'Trainer, technology, information'
  },
  {
    Id: 8914,
    Code: '3573',
    Name: 'Tutor, IT'
  },
  {
    Id: 8915,
    Code: '3574',
    Name: 'Adviser, care, back'
  },
  {
    Id: 8916,
    Code: '3574',
    Name: 'Adviser, development, employee'
  },
  {
    Id: 8917,
    Code: '3574',
    Name: 'Adviser, development, training'
  },
  {
    Id: 8918,
    Code: '3574',
    Name: 'Adviser, learning, vocational'
  },
  {
    Id: 8919,
    Code: '3574',
    Name: 'Adviser, learning and development'
  },
  {
    Id: 8920,
    Code: '3574',
    Name: 'Adviser, training'
  },
  {
    Id: 8921,
    Code: '3574',
    Name: 'Advocate, learning'
  },
  {
    Id: 8922,
    Code: '3574',
    Name: 'Agent, training'
  },
  {
    Id: 8923,
    Code: '3574',
    Name: 'Analyst, training'
  },
  {
    Id: 8924,
    Code: '3574',
    Name: 'Assessor, childcare'
  },
  {
    Id: 8925,
    Code: '3574',
    Name: 'Assessor, competency'
  },
  {
    Id: 8926,
    Code: '3574',
    Name: 'Assessor, driving'
  },
  {
    Id: 8927,
    Code: '3574',
    Name: 'Assessor, NVQ'
  },
  {
    Id: 8928,
    Code: '3574',
    Name: 'Assessor, training'
  },
  {
    Id: 8929,
    Code: '3574',
    Name: 'Assessor, vocational'
  },
  {
    Id: 8930,
    Code: '3574',
    Name: 'Assessor, training'
  },
  {
    Id: 8931,
    Code: '3574',
    Name: 'Assessor'
  },
  {
    Id: 8932,
    Code: '3574',
    Name: 'Associate, training'
  },
  {
    Id: 8933,
    Code: '3574',
    Name: 'Coach, executive'
  },
  {
    Id: 8934,
    Code: '3574',
    Name: 'Coach, retail'
  },
  {
    Id: 8935,
    Code: '3574',
    Name: 'Coach, service, customer'
  },
  {
    Id: 8936,
    Code: '3574',
    Name: 'Coach, technical'
  },
  {
    Id: 8937,
    Code: '3574',
    Name: 'Consultant, development, training'
  },
  {
    Id: 8938,
    Code: '3574',
    Name: 'Consultant, learning and development'
  },
  {
    Id: 8939,
    Code: '3574',
    Name: 'Consultant, scheme, training'
  },
  {
    Id: 8940,
    Code: '3574',
    Name: 'Consultant, training'
  },
  {
    Id: 8941,
    Code: '3574',
    Name: 'Coordinator, course, training'
  },
  {
    Id: 8942,
    Code: '3574',
    Name: 'Coordinator, learning and development'
  },
  {
    Id: 8943,
    Code: '3574',
    Name: 'Coordinator, TEC'
  },
  {
    Id: 8944,
    Code: '3574',
    Name: 'Coordinator, training'
  },
  {
    Id: 8945,
    Code: '3574',
    Name: 'Instructor, apprentice'
  },
  {
    Id: 8946,
    Code: '3574',
    Name: 'Instructor, civilian, government'
  },
  {
    Id: 8947,
    Code: '3574',
    Name: 'Instructor, craft'
  },
  {
    Id: 8948,
    Code: '3574',
    Name: 'Instructor, craftsman, apprentice, coal mine'
  },
  {
    Id: 8949,
    Code: '3574',
    Name: 'Instructor, handicraft'
  },
  {
    Id: 8950,
    Code: '3574',
    Name: 'Instructor, handicrafts'
  },
  {
    Id: 8951,
    Code: '3574',
    Name: 'Instructor, manual'
  },
  {
    Id: 8952,
    Code: '3574',
    Name: 'Instructor, prison'
  },
  {
    Id: 8953,
    Code: '3574',
    Name: 'Instructor, staff, school'
  },
  {
    Id: 8954,
    Code: '3574',
    Name: 'Instructor, supervising'
  },
  {
    Id: 8955,
    Code: '3574',
    Name: 'Instructor, technical'
  },
  {
    Id: 8956,
    Code: '3574',
    Name: 'Instructor, trainee'
  },
  {
    Id: 8957,
    Code: '3574',
    Name: 'Instructor, training'
  },
  {
    Id: 8958,
    Code: '3574',
    Name: 'Instructor, apprentice school'
  },
  {
    Id: 8959,
    Code: '3574',
    Name: 'Instructor, coal mine'
  },
  {
    Id: 8960,
    Code: '3574',
    Name: 'Instructor, learning disabilities adult training'
  },
  {
    Id: 8961,
    Code: '3574',
    Name: 'Instructor, training establishment'
  },
  {
    Id: 8962,
    Code: '3574',
    Name: 'Instructor, training provider'
  },
  {
    Id: 8963,
    Code: '3574',
    Name: 'Lecturer, industrial training'
  },
  {
    Id: 8964,
    Code: '3574',
    Name: 'Manager, delivery, training'
  },
  {
    Id: 8965,
    Code: '3574',
    Name: 'Manager, learning and development'
  },
  {
    Id: 8966,
    Code: '3574',
    Name: 'Manager, NVQ'
  },
  {
    Id: 8967,
    Code: '3574',
    Name: 'Manager, training'
  },
  {
    Id: 8968,
    Code: '3574',
    Name: 'Manager, training and development'
  },
  {
    Id: 8969,
    Code: '3574',
    Name: 'Officer, development, staff'
  },
  {
    Id: 8970,
    Code: '3574',
    Name: 'Officer, development, training'
  },
  {
    Id: 8971,
    Code: '3574',
    Name: 'Officer, development, youth'
  },
  {
    Id: 8972,
    Code: '3574',
    Name: 'Officer, instructional'
  },
  {
    Id: 8973,
    Code: '3574',
    Name: 'Officer, safety and training, coal mine: above ground'
  },
  {
    Id: 8974,
    Code: '3574',
    Name: 'Officer, service, training'
  },
  {
    Id: 8975,
    Code: '3574',
    Name: 'Officer, training, colliery'
  },
  {
    Id: 8976,
    Code: '3574',
    Name: 'Officer, training, sales'
  },
  {
    Id: 8977,
    Code: '3574',
    Name: 'Officer, training'
  },
  {
    Id: 8978,
    Code: '3574',
    Name: 'Officer, training and compliance'
  },
  {
    Id: 8979,
    Code: '3574',
    Name: 'Officer, training and development'
  },
  {
    Id: 8980,
    Code: '3574',
    Name: 'Officer, training and education'
  },
  {
    Id: 8981,
    Code: '3574',
    Name: 'Provider, training'
  },
  {
    Id: 8982,
    Code: '3574',
    Name: 'Specialist, learning and development'
  },
  {
    Id: 8983,
    Code: '3574',
    Name: 'Specialist, training'
  },
  {
    Id: 8984,
    Code: '3574',
    Name: 'Superintendent, apprenticeship'
  },
  {
    Id: 8985,
    Code: '3574',
    Name: 'Supervisor, apprenticeship'
  },
  {
    Id: 8986,
    Code: '3574',
    Name: 'Supervisor, trainee, coal mine'
  },
  {
    Id: 8987,
    Code: '3574',
    Name: 'Supervisor, training'
  },
  {
    Id: 8988,
    Code: '3574',
    Name: 'Supervisor, training'
  },
  {
    Id: 8989,
    Code: '3574',
    Name: 'Supervisor-instructor, government'
  },
  {
    Id: 8990,
    Code: '3574',
    Name: 'Teacher, sales'
  },
  {
    Id: 8991,
    Code: '3574',
    Name: 'Trainer, business'
  },
  {
    Id: 8992,
    Code: '3574',
    Name: 'Trainer, industrial'
  },
  {
    Id: 8993,
    Code: '3574',
    Name: 'Trainer, regional'
  },
  {
    Id: 8994,
    Code: '3574',
    Name: 'Trainer, service, customer'
  },
  {
    Id: 8995,
    Code: '3574',
    Name: 'Trainer, skills'
  },
  {
    Id: 8996,
    Code: '3574',
    Name: 'Trainer, staff'
  },
  {
    Id: 8997,
    Code: '3574',
    Name: 'Trainer, technical'
  },
  {
    Id: 8998,
    Code: '3574',
    Name: 'Trainer'
  },
  {
    Id: 8999,
    Code: '3574',
    Name: 'Trainer-assessor'
  },
  {
    Id: 9000,
    Code: '3574',
    Name: 'Tutor, training establishment'
  },
  {
    Id: 9001,
    Code: '3574',
    Name: 'Tutor, training provider'
  },
  {
    Id: 9002,
    Code: '3581',
    Name: 'Adviser, consumer, local government: trading standards'
  },
  {
    Id: 9003,
    Code: '3581',
    Name: 'Adviser, relations, race'
  },
  {
    Id: 9004,
    Code: '3581',
    Name: 'Bailiff, river'
  },
  {
    Id: 9005,
    Code: '3581',
    Name: 'Bailiff, water'
  },
  {
    Id: 9006,
    Code: '3581',
    Name: 'Controller, building, local government'
  },
  {
    Id: 9007,
    Code: '3581',
    Name: 'Examiner, driving'
  },
  {
    Id: 9008,
    Code: '3581',
    Name: 'Examiner, flight'
  },
  {
    Id: 9009,
    Code: '3581',
    Name: 'Examiner, traffic, DVSA'
  },
  {
    Id: 9010,
    Code: '3581',
    Name: 'Examiner, traffic and driving, DVSA'
  },
  {
    Id: 9011,
    Code: '3581',
    Name: 'Examiner, vehicle, DVSA'
  },
  {
    Id: 9012,
    Code: '3581',
    Name: 'HMFI'
  },
  {
    Id: 9013,
    Code: '3581',
    Name: 'Inspector, agricultural'
  },
  {
    Id: 9014,
    Code: '3581',
    Name: 'Inspector, alkali'
  },
  {
    Id: 9015,
    Code: '3581',
    Name: 'Inspector, bank'
  },
  {
    Id: 9016,
    Code: '3581',
    Name: 'Inspector, building'
  },
  {
    Id: 9017,
    Code: '3581',
    Name: 'Inspector, care, social'
  },
  {
    Id: 9018,
    Code: '3581',
    Name: 'Inspector, casino'
  },
  {
    Id: 9019,
    Code: '3581',
    Name: 'Inspector, chemical'
  },
  {
    Id: 9020,
    Code: '3581',
    Name: 'Inspector, chief, banking'
  },
  {
    Id: 9021,
    Code: '3581',
    Name: 'Inspector, chief, local government'
  },
  {
    Id: 9022,
    Code: '3581',
    Name: 'Inspector, chief, railways'
  },
  {
    Id: 9023,
    Code: '3581',
    Name: 'Inspector, chief, RSPCA'
  },
  {
    Id: 9024,
    Code: '3581',
    Name: 'Inspector, chief, water company'
  },
  {
    Id: 9025,
    Code: '3581',
    Name: 'Inspector, coal, coal mine: opencast'
  },
  {
    Id: 9026,
    Code: '3581',
    Name: 'Inspector, compliance, building construction'
  },
  {
    Id: 9027,
    Code: '3581',
    Name: 'Inspector, compliance'
  },
  {
    Id: 9028,
    Code: '3581',
    Name: 'Inspector, control, building'
  },
  {
    Id: 9029,
    Code: '3581',
    Name: 'Inspector, control, quality, river, water authority'
  },
  {
    Id: 9030,
    Code: '3581',
    Name: 'Inspector, crane'
  },
  {
    Id: 9031,
    Code: '3581',
    Name: 'Inspector, depot, chief, transport'
  },
  {
    Id: 9032,
    Code: '3581',
    Name: 'Inspector, district, gas supplier'
  },
  {
    Id: 9033,
    Code: '3581',
    Name: 'Inspector, divisional, local government'
  },
  {
    Id: 9034,
    Code: '3581',
    Name: 'Inspector, divisional, transport'
  },
  {
    Id: 9035,
    Code: '3581',
    Name: 'Inspector, drug'
  },
  {
    Id: 9036,
    Code: '3581',
    Name: 'Inspector, effluent'
  },
  {
    Id: 9037,
    Code: '3581',
    Name: 'Inspector, electrical, coal mine'
  },
  {
    Id: 9038,
    Code: '3581',
    Name: 'Inspector, electrical, government'
  },
  {
    Id: 9039,
    Code: '3581',
    Name: 'Inspector, electrical and mechanical, coal mine'
  },
  {
    Id: 9040,
    Code: '3581',
    Name: 'Inspector, explosives'
  },
  {
    Id: 9041,
    Code: '3581',
    Name: 'Inspector, fire'
  },
  {
    Id: 9042,
    Code: '3581',
    Name: 'Inspector, fisheries, DEFRA'
  },
  {
    Id: 9043,
    Code: '3581',
    Name: 'Inspector, fisheries, Environment Agency'
  },
  {
    Id: 9044,
    Code: '3581',
    Name: 'Inspector, fishery'
  },
  {
    Id: 9045,
    Code: '3581',
    Name: 'Inspector, fitting, gas supplier'
  },
  {
    Id: 9046,
    Code: '3581',
    Name: 'Inspector, fitting, water company'
  },
  {
    Id: 9047,
    Code: '3581',
    Name: 'Inspector, fittings, gas supplier'
  },
  {
    Id: 9048,
    Code: '3581',
    Name: 'Inspector, fittings, water company'
  },
  {
    Id: 9049,
    Code: '3581',
    Name: 'Inspector, fuel, local government'
  },
  {
    Id: 9050,
    Code: '3581',
    Name: 'Inspector, gaming, gaming club'
  },
  {
    Id: 9051,
    Code: '3581',
    Name: 'Inspector, gas'
  },
  {
    Id: 9052,
    Code: '3581',
    Name: 'Inspector, health, animal'
  },
  {
    Id: 9053,
    Code: '3581',
    Name: 'Inspector, health and safety'
  },
  {
    Id: 9054,
    Code: '3581',
    Name: 'Inspector, hotel'
  },
  {
    Id: 9055,
    Code: '3581',
    Name: 'Inspector, houses, public'
  },
  {
    Id: 9056,
    Code: '3581',
    Name: 'Inspector, housing'
  },
  {
    Id: 9057,
    Code: '3581',
    Name: 'Inspector, housing and planning'
  },
  {
    Id: 9058,
    Code: '3581',
    Name: 'Inspector, hygiene, meat'
  },
  {
    Id: 9059,
    Code: '3581',
    Name: 'Inspector, industrial, gas supplier'
  },
  {
    Id: 9060,
    Code: '3581',
    Name: 'Inspector, installation, electricity supplier'
  },
  {
    Id: 9061,
    Code: '3581',
    Name: 'Inspector, installation, gas supplier'
  },
  {
    Id: 9062,
    Code: '3581',
    Name: 'Inspector, insurance, national'
  },
  {
    Id: 9063,
    Code: '3581',
    Name: 'Inspector, lift, DfT'
  },
  {
    Id: 9064,
    Code: '3581',
    Name: 'Inspector, lighting, public'
  },
  {
    Id: 9065,
    Code: '3581',
    Name: 'Inspector, livestock, area'
  },
  {
    Id: 9066,
    Code: '3581',
    Name: 'Inspector, machine, weighing machines, docks'
  },
  {
    Id: 9067,
    Code: '3581',
    Name: 'Inspector, machine, weighing machines, railways'
  },
  {
    Id: 9068,
    Code: '3581',
    Name: 'Inspector, maintenance, gas supplier'
  },
  {
    Id: 9069,
    Code: '3581',
    Name: 'Inspector, market, local government'
  },
  {
    Id: 9070,
    Code: '3581',
    Name: 'Inspector, markets, local government'
  },
  {
    Id: 9071,
    Code: '3581',
    Name: 'Inspector, material'
  },
  {
    Id: 9072,
    Code: '3581',
    Name: 'Inspector, materials'
  },
  {
    Id: 9073,
    Code: '3581',
    Name: 'Inspector, meat, authorised'
  },
  {
    Id: 9074,
    Code: '3581',
    Name: 'Inspector, meat'
  },
  {
    Id: 9075,
    Code: '3581',
    Name: 'Inspector, mines'
  },
  {
    Id: 9076,
    Code: '3581',
    Name: 'Inspector, NRSWA'
  },
  {
    Id: 9077,
    Code: '3581',
    Name: 'Inspector, operations, flight'
  },
  {
    Id: 9078,
    Code: '3581',
    Name: 'Inspector, planning'
  },
  {
    Id: 9079,
    Code: '3581',
    Name: 'Inspector, plumbing'
  },
  {
    Id: 9080,
    Code: '3581',
    Name: 'Inspector, pollution, river, water authority'
  },
  {
    Id: 9081,
    Code: '3581',
    Name: 'Inspector, portal, DAERA'
  },
  {
    Id: 9082,
    Code: '3581',
    Name: 'Inspector, refuse'
  },
  {
    Id: 9083,
    Code: '3581',
    Name: 'Inspector, reinstatement, gas supplier'
  },
  {
    Id: 9084,
    Code: '3581',
    Name: 'Inspector, river, river, water authority'
  },
  {
    Id: 9085,
    Code: '3581',
    Name: 'Inspector, RSPCA'
  },
  {
    Id: 9086,
    Code: '3581',
    Name: 'Inspector, safety, nuclear'
  },
  {
    Id: 9087,
    Code: '3581',
    Name: 'Inspector, safety, plant, power, nuclear'
  },
  {
    Id: 9088,
    Code: '3581',
    Name: 'Inspector, safety, station, power, nuclear'
  },
  {
    Id: 9089,
    Code: '3581',
    Name: 'Inspector, safety, coal mine'
  },
  {
    Id: 9090,
    Code: '3581',
    Name: 'Inspector, service, gas supplier'
  },
  {
    Id: 9091,
    Code: '3581',
    Name: 'Inspector, site, construction'
  },
  {
    Id: 9092,
    Code: '3581',
    Name: 'Inspector, SSPCA'
  },
  {
    Id: 9093,
    Code: '3581',
    Name: 'Inspector, standards, nuclear'
  },
  {
    Id: 9094,
    Code: '3581',
    Name: 'Inspector, street, electricity supplier'
  },
  {
    Id: 9095,
    Code: '3581',
    Name: 'Inspector, telecommunications'
  },
  {
    Id: 9096,
    Code: '3581',
    Name: 'Inspector, traffic, chief, PO'
  },
  {
    Id: 9097,
    Code: '3581',
    Name: 'Inspector, traffic, chief, telecoms'
  },
  {
    Id: 9098,
    Code: '3581',
    Name: 'Inspector, value, best, local government'
  },
  {
    Id: 9099,
    Code: '3581',
    Name: 'Inspector, vehicle, government'
  },
  {
    Id: 9100,
    Code: '3581',
    Name: 'Inspector, VOSA'
  },
  {
    Id: 9101,
    Code: '3581',
    Name: 'Inspector, wages'
  },
  {
    Id: 9102,
    Code: '3581',
    Name: 'Inspector, waste, water company'
  },
  {
    Id: 9103,
    Code: '3581',
    Name: 'Inspector, water, chief'
  },
  {
    Id: 9104,
    Code: '3581',
    Name: 'Inspector, water'
  },
  {
    Id: 9105,
    Code: '3581',
    Name: 'Inspector, works, public, local government'
  },
  {
    Id: 9106,
    Code: '3581',
    Name: 'Inspector, works, street'
  },
  {
    Id: 9107,
    Code: '3581',
    Name: 'Inspector, banking'
  },
  {
    Id: 9108,
    Code: '3581',
    Name: 'Inspector, brewery'
  },
  {
    Id: 9109,
    Code: '3581',
    Name: 'Inspector, casino'
  },
  {
    Id: 9110,
    Code: '3581',
    Name: 'Inspector, coal mine: opencast'
  },
  {
    Id: 9111,
    Code: '3581',
    Name: 'Inspector, electricity supplier'
  },
  {
    Id: 9112,
    Code: '3581',
    Name: 'Inspector, gas supplier'
  },
  {
    Id: 9113,
    Code: '3581',
    Name: 'Inspector, government: Accident Investigation Branch'
  },
  {
    Id: 9114,
    Code: '3581',
    Name: 'Inspector, government: CQC'
  },
  {
    Id: 9115,
    Code: '3581',
    Name: 'Inspector, government: Dept for Business, Energy and Industrial Strategy'
  },
  {
    Id: 9116,
    Code: '3581',
    Name: 'Inspector, government: DfT'
  },
  {
    Id: 9117,
    Code: '3581',
    Name: 'Inspector, government: Environment Agency'
  },
  {
    Id: 9118,
    Code: '3581',
    Name: 'Inspector, government: Maritime and Coastguard Agency'
  },
  {
    Id: 9119,
    Code: '3581',
    Name: 'Inspector, government: Office of Rail Regulation'
  },
  {
    Id: 9120,
    Code: '3581',
    Name: 'Inspector, government: Planning Inspectorate'
  },
  {
    Id: 9121,
    Code: '3581',
    Name: 'Inspector, government'
  },
  {
    Id: 9122,
    Code: '3581',
    Name: 'Inspector, local government: works dept'
  },
  {
    Id: 9123,
    Code: '3581',
    Name: 'Inspector, local government'
  },
  {
    Id: 9124,
    Code: '3581',
    Name: 'Inspector, mine: not coal'
  },
  {
    Id: 9125,
    Code: '3581',
    Name: 'Inspector, National House Building Council'
  },
  {
    Id: 9126,
    Code: '3581',
    Name: 'Inspector, NSPCC'
  },
  {
    Id: 9127,
    Code: '3581',
    Name: 'Inspector, RSPCA'
  },
  {
    Id: 9128,
    Code: '3581',
    Name: 'Inspector, water company'
  },
  {
    Id: 9129,
    Code: '3581',
    Name: 'Inspector of accidents, Board of Trade'
  },
  {
    Id: 9130,
    Code: '3581',
    Name: 'Inspector of factories, government'
  },
  {
    Id: 9131,
    Code: '3581',
    Name: 'Inspector of fair trading'
  },
  {
    Id: 9132,
    Code: '3581',
    Name: 'Inspector of health and safety'
  },
  {
    Id: 9133,
    Code: '3581',
    Name: 'Inspector of mines, Dept for Business, Energy and Industrial Strategy'
  },
  {
    Id: 9134,
    Code: '3581',
    Name: 'Inspector of postal services'
  },
  {
    Id: 9135,
    Code: '3581',
    Name: 'Inspector of shipping'
  },
  {
    Id: 9136,
    Code: '3581',
    Name: 'Inspector of special subjects'
  },
  {
    Id: 9137,
    Code: '3581',
    Name: 'Inspector of trading standards'
  },
  {
    Id: 9138,
    Code: '3581',
    Name: 'Inspector of weights and measures'
  },
  {
    Id: 9139,
    Code: '3581',
    Name: 'Inspector of works'
  },
  {
    Id: 9140,
    Code: '3581',
    Name: 'Investigator, licence, television'
  },
  {
    Id: 9141,
    Code: '3581',
    Name: 'Manager, standards, trading'
  },
  {
    Id: 9142,
    Code: '3581',
    Name: 'Officer, authorised, meat inspection'
  },
  {
    Id: 9143,
    Code: '3581',
    Name: 'Officer, chief, local government: weights and measures dept'
  },
  {
    Id: 9144,
    Code: '3581',
    Name: 'Officer, control, building'
  },
  {
    Id: 9145,
    Code: '3581',
    Name: 'Officer, control, pollution'
  },
  {
    Id: 9146,
    Code: '3581',
    Name: 'Officer, district, river, water authority'
  },
  {
    Id: 9147,
    Code: '3581',
    Name: 'Officer, effluent, water company, sewage works'
  },
  {
    Id: 9148,
    Code: '3581',
    Name: 'Officer, employment, child'
  },
  {
    Id: 9149,
    Code: '3581',
    Name: 'Officer, enforcement, standards, trading'
  },
  {
    Id: 9150,
    Code: '3581',
    Name: 'Officer, enforcement, local government: trading standards'
  },
  {
    Id: 9151,
    Code: '3581',
    Name: 'Officer, enforcement, local government: transport'
  },
  {
    Id: 9152,
    Code: '3581',
    Name: 'Officer, field, DEFRA'
  },
  {
    Id: 9153,
    Code: '3581',
    Name: 'Officer, fisheries'
  },
  {
    Id: 9154,
    Code: '3581',
    Name: 'Officer, fishery'
  },
  {
    Id: 9155,
    Code: '3581',
    Name: 'Officer, health, animal'
  },
  {
    Id: 9156,
    Code: '3581',
    Name: 'Officer, improvements, local government'
  },
  {
    Id: 9157,
    Code: '3581',
    Name: 'Officer, inspecting, DVSA'
  },
  {
    Id: 9158,
    Code: '3581',
    Name: 'Officer, investigating, DVSA'
  },
  {
    Id: 9159,
    Code: '3581',
    Name: 'Officer, investigation, DVSA'
  },
  {
    Id: 9160,
    Code: '3581',
    Name: 'Officer, licensing'
  },
  {
    Id: 9161,
    Code: '3581',
    Name: 'Officer, livestock'
  },
  {
    Id: 9162,
    Code: '3581',
    Name: 'Officer, markets, DEFRA'
  },
  {
    Id: 9163,
    Code: '3581',
    Name: 'Officer, pollution, water company'
  },
  {
    Id: 9164,
    Code: '3581',
    Name: 'Officer, protection, child, NSPCC'
  },
  {
    Id: 9165,
    Code: '3581',
    Name: 'Officer, protection, consumer'
  },
  {
    Id: 9166,
    Code: '3581',
    Name: 'Officer, quality, water'
  },
  {
    Id: 9167,
    Code: '3581',
    Name: 'Officer, regional, RSPCA'
  },
  {
    Id: 9168,
    Code: '3581',
    Name: 'Officer, relations, race'
  },
  {
    Id: 9169,
    Code: '3581',
    Name: 'Officer, repairs, local government'
  },
  {
    Id: 9170,
    Code: '3581',
    Name: 'Officer, RSPCA'
  },
  {
    Id: 9171,
    Code: '3581',
    Name: 'Officer, service, carcass'
  },
  {
    Id: 9172,
    Code: '3581',
    Name: 'Officer, standards, driving'
  },
  {
    Id: 9173,
    Code: '3581',
    Name: 'Officer, standards, trading'
  },
  {
    Id: 9174,
    Code: '3581',
    Name: 'Officer, trading, fair'
  },
  {
    Id: 9175,
    Code: '3581',
    Name: 'Superintendent, RSPCA'
  },
  {
    Id: 9176,
    Code: '3581',
    Name: 'Supervisor, planning, construction'
  },
  {
    Id: 9177,
    Code: '3581',
    Name: "Surveyor, Lloyd's"
  },
  {
    Id: 9178,
    Code: '3581',
    Name: 'Surveyor, ship'
  },
  {
    Id: 9179,
    Code: '3581',
    Name: 'Technician, health, environmental'
  },
  {
    Id: 9180,
    Code: '3581',
    Name: 'Tester, gear, chain and suspension'
  },
  {
    Id: 9181,
    Code: '3581',
    Name: 'Tester, vehicle, DVSA'
  },
  {
    Id: 9182,
    Code: '3581',
    Name: 'Worker, advice, rights, consumer'
  },
  {
    Id: 9183,
    Code: '3582',
    Name: 'Adviser, EHS'
  },
  {
    Id: 9184,
    Code: '3582',
    Name: 'Adviser, environmental, health and safety'
  },
  {
    Id: 9185,
    Code: '3582',
    Name: 'Adviser, health and safety'
  },
  {
    Id: 9186,
    Code: '3582',
    Name: 'Adviser, HSE'
  },
  {
    Id: 9187,
    Code: '3582',
    Name: 'Adviser, safety, fire'
  },
  {
    Id: 9188,
    Code: '3582',
    Name: 'Adviser, safety'
  },
  {
    Id: 9189,
    Code: '3582',
    Name: 'Adviser, toxicology'
  },
  {
    Id: 9190,
    Code: '3582',
    Name: 'Analyst, asbestos'
  },
  {
    Id: 9191,
    Code: '3582',
    Name: 'Assessor, risk, fire'
  },
  {
    Id: 9192,
    Code: '3582',
    Name: 'Attendant, safety, chemical works'
  },
  {
    Id: 9193,
    Code: '3582',
    Name: 'Consultant, asbestos'
  },
  {
    Id: 9194,
    Code: '3582',
    Name: 'Consultant, fire'
  },
  {
    Id: 9195,
    Code: '3582',
    Name: 'Consultant, health and safety'
  },
  {
    Id: 9196,
    Code: '3582',
    Name: 'Consultant, protection, fire'
  },
  {
    Id: 9197,
    Code: '3582',
    Name: 'Consultant, safety, fire'
  },
  {
    Id: 9198,
    Code: '3582',
    Name: 'Consultant, safety'
  },
  {
    Id: 9199,
    Code: '3582',
    Name: 'Coordinator, health and safety'
  },
  {
    Id: 9200,
    Code: '3582',
    Name: 'Engineer, safety, fire'
  },
  {
    Id: 9201,
    Code: '3582',
    Name: 'Engineer, safety'
  },
  {
    Id: 9202,
    Code: '3582',
    Name: 'Hygienist, occupational'
  },
  {
    Id: 9203,
    Code: '3582',
    Name: 'Inspector, safety'
  },
  {
    Id: 9204,
    Code: '3582',
    Name: 'Inspector, catering'
  },
  {
    Id: 9205,
    Code: '3582',
    Name: 'Inspector, government: HSE'
  },
  {
    Id: 9206,
    Code: '3582',
    Name: 'Investigator, accident'
  },
  {
    Id: 9207,
    Code: '3582',
    Name: 'Investigator, fire, forensic'
  },
  {
    Id: 9208,
    Code: '3582',
    Name: 'Man, safety, steel mfr'
  },
  {
    Id: 9209,
    Code: '3582',
    Name: 'Manager, EHS'
  },
  {
    Id: 9210,
    Code: '3582',
    Name: 'Manager, health, occupational'
  },
  {
    Id: 9211,
    Code: '3582',
    Name: 'Manager, health and environment, safety'
  },
  {
    Id: 9212,
    Code: '3582',
    Name: 'Manager, health and safety'
  },
  {
    Id: 9213,
    Code: '3582',
    Name: 'Manager, HSE'
  },
  {
    Id: 9214,
    Code: '3582',
    Name: 'Manager, safety, crowd'
  },
  {
    Id: 9215,
    Code: '3582',
    Name: 'Manager, safety, fire'
  },
  {
    Id: 9216,
    Code: '3582',
    Name: 'Manager, safety'
  },
  {
    Id: 9217,
    Code: '3582',
    Name: 'Manager, safety and hygiene'
  },
  {
    Id: 9218,
    Code: '3582',
    Name: 'Marshall, fire'
  },
  {
    Id: 9219,
    Code: '3582',
    Name: 'Officer, control, dust, coal mine'
  },
  {
    Id: 9220,
    Code: '3582',
    Name: 'Officer, environmental, health and safety, quality'
  },
  {
    Id: 9221,
    Code: '3582',
    Name: 'Officer, environmental, health and safety'
  },
  {
    Id: 9222,
    Code: '3582',
    Name: 'Officer, health and safety, environmental'
  },
  {
    Id: 9223,
    Code: '3582',
    Name: 'Officer, health and safety'
  },
  {
    Id: 9224,
    Code: '3582',
    Name: 'Officer, prevention, accident'
  },
  {
    Id: 9225,
    Code: '3582',
    Name: 'Officer, safety, road'
  },
  {
    Id: 9226,
    Code: '3582',
    Name: 'Officer, safety'
  },
  {
    Id: 9227,
    Code: '3582',
    Name: 'Officer, safety and training, coal mine'
  },
  {
    Id: 9228,
    Code: '3582',
    Name: 'Officer, SHEQ'
  },
  {
    Id: 9229,
    Code: '3582',
    Name: 'Officer, suppression, dust, coal mine'
  },
  {
    Id: 9230,
    Code: '3582',
    Name: 'Officer, ventilation, coal mine'
  },
  {
    Id: 9231,
    Code: '3582',
    Name: 'Organiser, safety, road'
  },
  {
    Id: 9232,
    Code: '3582',
    Name: 'Practitioner, health and safety'
  },
  {
    Id: 9233,
    Code: '3582',
    Name: 'Supervisor, safety'
  },
  {
    Id: 9234,
    Code: '3582',
    Name: 'Surveyor, asbestos'
  },
  {
    Id: 9235,
    Code: '3582',
    Name: 'Surveyor, physics, health'
  },
  {
    Id: 9236,
    Code: '3582',
    Name: 'Worker, safety, UKAEA'
  },
  {
    Id: 9237,
    Code: '4111',
    Name: 'A1, Jobcentre Plus'
  },
  {
    Id: 9238,
    Code: '4111',
    Name: 'A1, Office for National Statistics'
  },
  {
    Id: 9239,
    Code: '4111',
    Name: 'A2, Jobcentre Plus'
  },
  {
    Id: 9240,
    Code: '4111',
    Name: 'A2, Office for National Statistics'
  },
  {
    Id: 9241,
    Code: '4111',
    Name: 'A3, Jobcentre Plus'
  },
  {
    Id: 9242,
    Code: '4111',
    Name: 'A3, Office for National Statistics'
  },
  {
    Id: 9243,
    Code: '4111',
    Name: 'A3, Scottish Government'
  },
  {
    Id: 9244,
    Code: '4111',
    Name: 'A4, Jobcentre Plus'
  },
  {
    Id: 9245,
    Code: '4111',
    Name: 'A4, Scottish Government'
  },
  {
    Id: 9246,
    Code: '4111',
    Name: 'A5, Jobcentre Plus'
  },
  {
    Id: 9247,
    Code: '4111',
    Name: 'A6, Jobcentre Plus'
  },
  {
    Id: 9248,
    Code: '4111',
    Name: 'A7, Jobcentre Plus'
  },
  {
    Id: 9249,
    Code: '4111',
    Name: 'AA, government'
  },
  {
    Id: 9250,
    Code: '4111',
    Name: 'AO, government'
  },
  {
    Id: 9251,
    Code: '4111',
    Name: 'Administrator, case, probation service'
  },
  {
    Id: 9252,
    Code: '4111',
    Name: 'Administrator, armed forces'
  },
  {
    Id: 9253,
    Code: '4111',
    Name: 'Administrator, government'
  },
  {
    Id: 9254,
    Code: '4111',
    Name: 'Adviser, benefits, government'
  },
  {
    Id: 9255,
    Code: '4111',
    Name: 'Adviser, claimant'
  },
  {
    Id: 9256,
    Code: '4111',
    Name: 'Adviser, claims, government'
  },
  {
    Id: 9257,
    Code: '4111',
    Name: 'Adviser, deal, new'
  },
  {
    Id: 9258,
    Code: '4111',
    Name: 'Adviser, employment, disability, government'
  },
  {
    Id: 9259,
    Code: '4111',
    Name: 'Adviser, employment, government'
  },
  {
    Id: 9260,
    Code: '4111',
    Name: 'Adviser, personal, deal, new'
  },
  {
    Id: 9261,
    Code: '4111',
    Name: 'Adviser, personal, Jobcentre Plus'
  },
  {
    Id: 9262,
    Code: '4111',
    Name: 'Adviser, security, social'
  },
  {
    Id: 9263,
    Code: '4111',
    Name: 'Adviser, service, customer, benefits, government'
  },
  {
    Id: 9264,
    Code: '4111',
    Name: 'Adviser, service, customer, government'
  },
  {
    Id: 9265,
    Code: '4111',
    Name: 'Adviser, service, customer, HM Revenue and Customs'
  },
  {
    Id: 9266,
    Code: '4111',
    Name: 'Adviser, Jobcentre Plus'
  },
  {
    Id: 9267,
    Code: '4111',
    Name: 'Assessor, benefit, government'
  },
  {
    Id: 9268,
    Code: '4111',
    Name: 'Assessor, benefits, government'
  },
  {
    Id: 9269,
    Code: '4111',
    Name: 'Assessor, claims, government'
  },
  {
    Id: 9270,
    Code: '4111',
    Name: 'Assessor, financial, government'
  },
  {
    Id: 9271,
    Code: '4111',
    Name: 'Assessor, student loans, government'
  },
  {
    Id: 9272,
    Code: '4111',
    Name: 'Assistant, admin, armed forces'
  },
  {
    Id: 9273,
    Code: '4111',
    Name: 'Assistant, admin, courts of justice'
  },
  {
    Id: 9274,
    Code: '4111',
    Name: 'Assistant, admin, government'
  },
  {
    Id: 9275,
    Code: '4111',
    Name: 'Assistant, administration, armed forces'
  },
  {
    Id: 9276,
    Code: '4111',
    Name: 'Assistant, administration, courts of justice'
  },
  {
    Id: 9277,
    Code: '4111',
    Name: 'Assistant, administration, government'
  },
  {
    Id: 9278,
    Code: '4111',
    Name: 'Assistant, administrative, armed forces'
  },
  {
    Id: 9279,
    Code: '4111',
    Name: 'Assistant, administrative, courts of justice'
  },
  {
    Id: 9280,
    Code: '4111',
    Name: 'Assistant, administrative, government'
  },
  {
    Id: 9281,
    Code: '4111',
    Name: 'Assistant, benefit, government'
  },
  {
    Id: 9282,
    Code: '4111',
    Name: 'Assistant, benefits, government'
  },
  {
    Id: 9283,
    Code: '4111',
    Name: 'Assistant, clerical, government'
  },
  {
    Id: 9284,
    Code: '4111',
    Name: 'Assistant, court'
  },
  {
    Id: 9285,
    Code: '4111',
    Name: 'Assistant, registration, HM Land Registry'
  },
  {
    Id: 9286,
    Code: '4111',
    Name: 'Assistant, Revenue, Inland'
  },
  {
    Id: 9287,
    Code: '4111',
    Name: 'Assistant, revenue, government'
  },
  {
    Id: 9288,
    Code: '4111',
    Name: 'Assistant, tax, government'
  },
  {
    Id: 9289,
    Code: '4111',
    Name: 'Assistant, taxation, government'
  },
  {
    Id: 9290,
    Code: '4111',
    Name: 'B1, Cabinet Office'
  },
  {
    Id: 9291,
    Code: '4111',
    Name: 'B1, Jobcentre Plus'
  },
  {
    Id: 9292,
    Code: '4111',
    Name: 'B1, Office for National Statistics'
  },
  {
    Id: 9293,
    Code: '4111',
    Name: 'B1, Scottish Government'
  },
  {
    Id: 9294,
    Code: '4111',
    Name: 'B2, Dept for International Development'
  },
  {
    Id: 9295,
    Code: '4111',
    Name: 'B2, Jobcentre Plus'
  },
  {
    Id: 9296,
    Code: '4111',
    Name: 'B2, Office for National Statistics'
  },
  {
    Id: 9297,
    Code: '4111',
    Name: 'B3, Jobcentre Plus'
  },
  {
    Id: 9298,
    Code: '4111',
    Name: 'B4, Jobcentre Plus'
  },
  {
    Id: 9299,
    Code: '4111',
    Name: 'B5, Jobcentre Plus'
  },
  {
    Id: 9300,
    Code: '4111',
    Name: 'Band 3D, Meteorological Office'
  },
  {
    Id: 9301,
    Code: '4111',
    Name: 'Band 4A, Meteorological Office'
  },
  {
    Id: 9302,
    Code: '4111',
    Name: 'Band 4B, Meteorological Office'
  },
  {
    Id: 9303,
    Code: '4111',
    Name: 'Band 4C, Meteorological Office'
  },
  {
    Id: 9304,
    Code: '4111',
    Name: 'Band 5, Health and Safety Executive'
  },
  {
    Id: 9305,
    Code: '4111',
    Name: 'Band 6, Health and Safety Executive'
  },
  {
    Id: 9306,
    Code: '4111',
    Name: 'Band A, Ministry of Justice'
  },
  {
    Id: 9307,
    Code: '4111',
    Name: 'Band B, Ministry of Justice'
  },
  {
    Id: 9308,
    Code: '4111',
    Name: 'Band C, Ministry of Justice'
  },
  {
    Id: 9309,
    Code: '4111',
    Name: 'Band C, National Assembly for Wales'
  },
  {
    Id: 9310,
    Code: '4111',
    Name: 'Band D, Inland Revenue'
  },
  {
    Id: 9311,
    Code: '4111',
    Name: 'Broker, job, Jobcentre Plus'
  },
  {
    Id: 9312,
    Code: '4111',
    Name: 'C, Northern Ireland Office'
  },
  {
    Id: 9313,
    Code: '4111',
    Name: 'C1, Cabinet Office'
  },
  {
    Id: 9314,
    Code: '4111',
    Name: 'C1, Dept for International Development'
  },
  {
    Id: 9315,
    Code: '4111',
    Name: 'C2, Cabinet Office'
  },
  {
    Id: 9316,
    Code: '4111',
    Name: 'C2, Dept for International Development'
  },
  {
    Id: 9317,
    Code: '4111',
    Name: 'Caseworker, compliance, HM Revenue and Customs'
  },
  {
    Id: 9318,
    Code: '4111',
    Name: 'Caseworker, government'
  },
  {
    Id: 9319,
    Code: '4111',
    Name: 'Clerk, admin, government'
  },
  {
    Id: 9320,
    Code: '4111',
    Name: 'Clerk, administration, government'
  },
  {
    Id: 9321,
    Code: '4111',
    Name: 'Clerk, court'
  },
  {
    Id: 9322,
    Code: '4111',
    Name: 'Clerk, support, passport'
  },
  {
    Id: 9323,
    Code: '4111',
    Name: 'Clerk, support, HM Passport Office'
  },
  {
    Id: 9324,
    Code: '4111',
    Name: 'Clerk, valuation, grade, higher, HM Revenue and Customs'
  },
  {
    Id: 9325,
    Code: '4111',
    Name: 'Clerk, government'
  },
  {
    Id: 9326,
    Code: '4111',
    Name: 'Clerk, law courts'
  },
  {
    Id: 9327,
    Code: '4111',
    Name: 'Clerk, prison service'
  },
  {
    Id: 9328,
    Code: '4111',
    Name: 'Clerk-typist, government'
  },
  {
    Id: 9329,
    Code: '4111',
    Name: 'Coach, work, government'
  },
  {
    Id: 9330,
    Code: '4111',
    Name: 'Collector, assistant, HM Revenue and Customs'
  },
  {
    Id: 9331,
    Code: '4111',
    Name: 'Collector, tax, assistant'
  },
  {
    Id: 9332,
    Code: '4111',
    Name: 'Collector, tax'
  },
  {
    Id: 9333,
    Code: '4111',
    Name: 'Collector of taxes, assistant'
  },
  {
    Id: 9334,
    Code: '4111',
    Name: 'Collector of taxes'
  },
  {
    Id: 9335,
    Code: '4111',
    Name: 'Coordinator, deal, new'
  },
  {
    Id: 9336,
    Code: '4111',
    Name: 'D1, Northern Ireland Office'
  },
  {
    Id: 9337,
    Code: '4111',
    Name: 'D2, Northern Ireland Office'
  },
  {
    Id: 9338,
    Code: '4111',
    Name: 'EO, government'
  },
  {
    Id: 9339,
    Code: '4111',
    Name: 'Examiner, assistant, government'
  },
  {
    Id: 9340,
    Code: '4111',
    Name: 'Executive, registration, HM Land Registry'
  },
  {
    Id: 9341,
    Code: '4111',
    Name: 'Executive, Revenue, Inland'
  },
  {
    Id: 9342,
    Code: '4111',
    Name: 'Executive, revenue, government'
  },
  {
    Id: 9343,
    Code: '4111',
    Name: 'Grade 11, Foreign and Commonwealth Office'
  },
  {
    Id: 9344,
    Code: '4111',
    Name: 'Grade 12, Foreign and Commonwealth Office'
  },
  {
    Id: 9345,
    Code: '4111',
    Name: 'Grade 13, Foreign and Commonwealth Office'
  },
  {
    Id: 9346,
    Code: '4111',
    Name: 'Grade C, DCMS'
  },
  {
    Id: 9347,
    Code: '4111',
    Name: 'Grade D, DCMS'
  },
  {
    Id: 9348,
    Code: '4111',
    Name: 'Inspector, assistant, government'
  },
  {
    Id: 9349,
    Code: '4111',
    Name: 'Inspector, fraud, government'
  },
  {
    Id: 9350,
    Code: '4111',
    Name: 'Inspector, fund, social, government'
  },
  {
    Id: 9351,
    Code: '4111',
    Name: 'Investigator, fraud, benefit'
  },
  {
    Id: 9352,
    Code: '4111',
    Name: 'Investigator, fraud, benefits, government'
  },
  {
    Id: 9353,
    Code: '4111',
    Name: 'Investigator, fraud, government'
  },
  {
    Id: 9354,
    Code: '4111',
    Name: 'Investigator, Jobcentre Plus'
  },
  {
    Id: 9355,
    Code: '4111',
    Name: 'LOI, Jobcentre Plus'
  },
  {
    Id: 9356,
    Code: '4111',
    Name: 'LOII, Jobcentre Plus'
  },
  {
    Id: 9357,
    Code: '4111',
    Name: 'Maker, decision, sector, government'
  },
  {
    Id: 9358,
    Code: '4111',
    Name: 'Maker, decision, benefits, government'
  },
  {
    Id: 9359,
    Code: '4111',
    Name: 'Maker, decision, Jobcentre Plus'
  },
  {
    Id: 9360,
    Code: '4111',
    Name: 'Manager, case, credit, universal'
  },
  {
    Id: 9361,
    Code: '4111',
    Name: 'Manager, case, benefits, government'
  },
  {
    Id: 9362,
    Code: '4111',
    Name: 'Manager, case, government'
  },
  {
    Id: 9363,
    Code: '4111',
    Name: 'Officer, adjudication, government'
  },
  {
    Id: 9364,
    Code: '4111',
    Name: 'Officer, admin, armed forces'
  },
  {
    Id: 9365,
    Code: '4111',
    Name: 'Officer, admin, courts of justice'
  },
  {
    Id: 9366,
    Code: '4111',
    Name: 'Officer, admin, government'
  },
  {
    Id: 9367,
    Code: '4111',
    Name: 'Officer, administration, armed forces'
  },
  {
    Id: 9368,
    Code: '4111',
    Name: 'Officer, administration, courts of justice'
  },
  {
    Id: 9369,
    Code: '4111',
    Name: 'Officer, administration, government'
  },
  {
    Id: 9370,
    Code: '4111',
    Name: 'Officer, administrative, court'
  },
  {
    Id: 9371,
    Code: '4111',
    Name: 'Officer, administrative, armed forces'
  },
  {
    Id: 9372,
    Code: '4111',
    Name: 'Officer, administrative, courts of justice'
  },
  {
    Id: 9373,
    Code: '4111',
    Name: 'Officer, administrative, benefits, government'
  },
  {
    Id: 9374,
    Code: '4111',
    Name: 'Officer, administrative, government'
  },
  {
    Id: 9375,
    Code: '4111',
    Name: 'Officer, appeals, government'
  },
  {
    Id: 9376,
    Code: '4111',
    Name: 'Officer, assessment'
  },
  {
    Id: 9377,
    Code: '4111',
    Name: 'Officer, benefit, government'
  },
  {
    Id: 9378,
    Code: '4111',
    Name: 'Officer, benefits, government'
  },
  {
    Id: 9379,
    Code: '4111',
    Name: 'Officer, care, witness'
  },
  {
    Id: 9380,
    Code: '4111',
    Name: 'Officer, case, passport'
  },
  {
    Id: 9381,
    Code: '4111',
    Name: 'Officer, clerical, benefits, government'
  },
  {
    Id: 9382,
    Code: '4111',
    Name: 'Officer, clerical, government'
  },
  {
    Id: 9383,
    Code: '4111',
    Name: 'Officer, clerical, prison service'
  },
  {
    Id: 9384,
    Code: '4111',
    Name: 'Officer, communications, government'
  },
  {
    Id: 9385,
    Code: '4111',
    Name: 'Officer, compliance, benefits, government'
  },
  {
    Id: 9386,
    Code: '4111',
    Name: 'Officer, cypher, Foreign and Commonwealth Office'
  },
  {
    Id: 9387,
    Code: '4111',
    Name: 'Officer, determining, court service'
  },
  {
    Id: 9388,
    Code: '4111',
    Name: 'Officer, disposals, government'
  },
  {
    Id: 9389,
    Code: '4111',
    Name: 'Officer, executive, benefits, government'
  },
  {
    Id: 9390,
    Code: '4111',
    Name: 'Officer, executive, government'
  },
  {
    Id: 9391,
    Code: '4111',
    Name: 'Officer, fraud, government'
  },
  {
    Id: 9392,
    Code: '4111',
    Name: 'Officer, fund, social'
  },
  {
    Id: 9393,
    Code: '4111',
    Name: 'Officer, government'
  },
  {
    Id: 9394,
    Code: '4111',
    Name: 'Officer, group, MOD'
  },
  {
    Id: 9395,
    Code: '4111',
    Name: 'Officer, investigating, fraud, government'
  },
  {
    Id: 9396,
    Code: '4111',
    Name: 'Officer, investigating, government'
  },
  {
    Id: 9397,
    Code: '4111',
    Name: 'Officer, investigation, fraud, government'
  },
  {
    Id: 9398,
    Code: '4111',
    Name: 'Officer, investigation, government'
  },
  {
    Id: 9399,
    Code: '4111',
    Name: 'Officer, liaison, government'
  },
  {
    Id: 9400,
    Code: '4111',
    Name: 'Officer, local I, Jobcentre Plus'
  },
  {
    Id: 9401,
    Code: '4111',
    Name: 'Officer, local II, Jobcentre Plus'
  },
  {
    Id: 9402,
    Code: '4111',
    Name: 'Officer, management, debt, government'
  },
  {
    Id: 9403,
    Code: '4111',
    Name: 'Officer, passport'
  },
  {
    Id: 9404,
    Code: '4111',
    Name: 'Officer, presenting, appeals, government'
  },
  {
    Id: 9405,
    Code: '4111',
    Name: 'Officer, processing, benefits, government'
  },
  {
    Id: 9406,
    Code: '4111',
    Name: 'Officer, registration, HM Land Registry'
  },
  {
    Id: 9407,
    Code: '4111',
    Name: 'Officer, Revenue, Inland'
  },
  {
    Id: 9408,
    Code: '4111',
    Name: 'Officer, revenue, government'
  },
  {
    Id: 9409,
    Code: '4111',
    Name: 'Officer, security, social, Jobcentre Plus'
  },
  {
    Id: 9410,
    Code: '4111',
    Name: 'Officer, service, foreign, grade B5'
  },
  {
    Id: 9411,
    Code: '4111',
    Name: 'Officer, service, foreign'
  },
  {
    Id: 9412,
    Code: '4111',
    Name: 'Officer, statistical, grade D, MOD'
  },
  {
    Id: 9413,
    Code: '4111',
    Name: 'Officer, substitution, MOD'
  },
  {
    Id: 9414,
    Code: '4111',
    Name: 'Officer, supply, assistant, MOD'
  },
  {
    Id: 9415,
    Code: '4111',
    Name: 'Officer, supply, chart, MOD'
  },
  {
    Id: 9416,
    Code: '4111',
    Name: 'Officer, support, income, government'
  },
  {
    Id: 9417,
    Code: '4111',
    Name: 'Officer, tax, grade, higher, HM Revenue and Customs'
  },
  {
    Id: 9418,
    Code: '4111',
    Name: 'Officer, tax, HM Revenue and Customs'
  },
  {
    Id: 9419,
    Code: '4111',
    Name: 'Officer, taxation, grade, higher, HM Revenue and Customs'
  },
  {
    Id: 9420,
    Code: '4111',
    Name: 'Officer, taxation, HM Revenue and Customs'
  },
  {
    Id: 9421,
    Code: '4111',
    Name: 'Officer, visiting, government'
  },
  {
    Id: 9422,
    Code: '4111',
    Name: 'Official, government'
  },
  {
    Id: 9423,
    Code: '4111',
    Name: 'Official, tax, HM Revenue and Customs'
  },
  {
    Id: 9424,
    Code: '4111',
    Name: 'Payband 1, Dept of Health & Social Care'
  },
  {
    Id: 9425,
    Code: '4111',
    Name: 'Payband 2, Dept of Health & Social Care'
  },
  {
    Id: 9426,
    Code: '4111',
    Name: 'Processor, claims, government'
  },
  {
    Id: 9427,
    Code: '4111',
    Name: 'RA1, HM Land Registry'
  },
  {
    Id: 9428,
    Code: '4111',
    Name: 'RA2, HM Land Registry'
  },
  {
    Id: 9429,
    Code: '4111',
    Name: 'RE2, HM Land Registry'
  },
  {
    Id: 9430,
    Code: '4111',
    Name: 'RO, HM Land Registry'
  },
  {
    Id: 9431,
    Code: '4111',
    Name: 'SGB, DEFRA'
  },
  {
    Id: 9432,
    Code: '4111',
    Name: 'Servant, civil, AA'
  },
  {
    Id: 9433,
    Code: '4111',
    Name: 'Servant, civil, AO'
  },
  {
    Id: 9434,
    Code: '4111',
    Name: 'Servant, civil, EO'
  },
  {
    Id: 9435,
    Code: '4111',
    Name: 'Servant, civil, museum service'
  },
  {
    Id: 9436,
    Code: '4111',
    Name: 'Servant, civil, nos'
  },
  {
    Id: 9437,
    Code: '4111',
    Name: 'Stamper, HM Revenue and Customs'
  },
  {
    Id: 9438,
    Code: '4111',
    Name: 'Transcriber, communications, government'
  },
  {
    Id: 9439,
    Code: '4111',
    Name: 'Watcher, customs'
  },
  {
    Id: 9440,
    Code: '4111',
    Name: 'Watcher, HM Revenue and Customs'
  },
  {
    Id: 9441,
    Code: '4111',
    Name: 'Worker, case, passport'
  },
  {
    Id: 9442,
    Code: '4111',
    Name: 'Worker, case, government'
  },
  {
    Id: 9443,
    Code: '4111',
    Name: 'Worker, office, government'
  },
  {
    Id: 9444,
    Code: '4111',
    Name: 'Writer, armed forces'
  },
  {
    Id: 9445,
    Code: '4111',
    Name: 'Writer, MOD'
  },
  {
    Id: 9446,
    Code: '4112',
    Name: 'AP(T), grade 1,2,3, local government'
  },
  {
    Id: 9447,
    Code: '4112',
    Name: 'Administrator, electoral'
  },
  {
    Id: 9448,
    Code: '4112',
    Name: 'Administrator, MAPPA'
  },
  {
    Id: 9449,
    Code: '4112',
    Name: 'Administrator, local government'
  },
  {
    Id: 9450,
    Code: '4112',
    Name: 'Administrator, police service'
  },
  {
    Id: 9451,
    Code: '4112',
    Name: 'Adviser, benefit, local government'
  },
  {
    Id: 9452,
    Code: '4112',
    Name: 'Adviser, benefits, local government'
  },
  {
    Id: 9453,
    Code: '4112',
    Name: 'Adviser, employment, local government'
  },
  {
    Id: 9454,
    Code: '4112',
    Name: 'Adviser, service, customer, local government: housing dept'
  },
  {
    Id: 9455,
    Code: '4112',
    Name: 'Adviser, service, customer, benefits, local government'
  },
  {
    Id: 9456,
    Code: '4112',
    Name: 'Adviser, service, customer, local government'
  },
  {
    Id: 9457,
    Code: '4112',
    Name: 'Adviser, services, cultural'
  },
  {
    Id: 9458,
    Code: '4112',
    Name: 'Assessor, benefit, housing'
  },
  {
    Id: 9459,
    Code: '4112',
    Name: 'Assessor, benefit, local government'
  },
  {
    Id: 9460,
    Code: '4112',
    Name: 'Assessor, benefits, local government'
  },
  {
    Id: 9461,
    Code: '4112',
    Name: 'Assistant, admin, local government'
  },
  {
    Id: 9462,
    Code: '4112',
    Name: 'Assistant, admin, police service'
  },
  {
    Id: 9463,
    Code: '4112',
    Name: 'Assistant, administration, local government'
  },
  {
    Id: 9464,
    Code: '4112',
    Name: 'Assistant, administration, police service'
  },
  {
    Id: 9465,
    Code: '4112',
    Name: 'Assistant, administrative, local government'
  },
  {
    Id: 9466,
    Code: '4112',
    Name: 'Assistant, administrative, police service'
  },
  {
    Id: 9467,
    Code: '4112',
    Name: 'Assistant, benefit, local government'
  },
  {
    Id: 9468,
    Code: '4112',
    Name: 'Assistant, benefits, local government'
  },
  {
    Id: 9469,
    Code: '4112',
    Name: 'Assistant, civic'
  },
  {
    Id: 9470,
    Code: '4112',
    Name: 'Assistant, civilian, police service'
  },
  {
    Id: 9471,
    Code: '4112',
    Name: 'Assistant, clerical, local government'
  },
  {
    Id: 9472,
    Code: '4112',
    Name: 'Assistant, clerical, police service'
  },
  {
    Id: 9473,
    Code: '4112',
    Name: "Assistant, collector's, rate"
  },
  {
    Id: 9474,
    Code: '4112',
    Name: 'Assistant, communications, local government'
  },
  {
    Id: 9475,
    Code: '4112',
    Name: 'Assistant, fixing, rate'
  },
  {
    Id: 9476,
    Code: '4112',
    Name: 'Assistant, housing, local government'
  },
  {
    Id: 9477,
    Code: '4112',
    Name: "Assistant, inspector's, local government"
  },
  {
    Id: 9478,
    Code: '4112',
    Name: 'Assistant, lettings, local government'
  },
  {
    Id: 9479,
    Code: '4112',
    Name: 'Assistant, rating'
  },
  {
    Id: 9480,
    Code: '4112',
    Name: 'Assistant, recovery, local government'
  },
  {
    Id: 9481,
    Code: '4112',
    Name: 'Assistant, senior, local government'
  },
  {
    Id: 9482,
    Code: '4112',
    Name: 'Assistant, services, support, local government'
  },
  {
    Id: 9483,
    Code: '4112',
    Name: 'Assistant, standards, trading'
  },
  {
    Id: 9484,
    Code: '4112',
    Name: 'Assistant, support, business, local government'
  },
  {
    Id: 9485,
    Code: '4112',
    Name: 'Assistant, survey, local government'
  },
  {
    Id: 9486,
    Code: '4112',
    Name: 'Assistant, tax, local government'
  },
  {
    Id: 9487,
    Code: '4112',
    Name: 'Assistant, taxation, local government'
  },
  {
    Id: 9488,
    Code: '4112',
    Name: 'Attendant, health, local government'
  },
  {
    Id: 9489,
    Code: '4112',
    Name: 'Builder, case, police service'
  },
  {
    Id: 9490,
    Code: '4112',
    Name: 'Caseworker, police service'
  },
  {
    Id: 9491,
    Code: '4112',
    Name: 'Clerk, charge, community'
  },
  {
    Id: 9492,
    Code: '4112',
    Name: 'Clerk, control, local government'
  },
  {
    Id: 9493,
    Code: '4112',
    Name: 'Clerk, council, parish'
  },
  {
    Id: 9494,
    Code: '4112',
    Name: 'Clerk, parish'
  },
  {
    Id: 9495,
    Code: '4112',
    Name: 'Clerk, property'
  },
  {
    Id: 9496,
    Code: '4112',
    Name: 'Clerk, rating'
  },
  {
    Id: 9497,
    Code: '4112',
    Name: 'Clerk, senior, local government'
  },
  {
    Id: 9498,
    Code: '4112',
    Name: 'Clerk, tax, poll'
  },
  {
    Id: 9499,
    Code: '4112',
    Name: 'Clerk, team, local government'
  },
  {
    Id: 9500,
    Code: '4112',
    Name: 'Clerk, health authority'
  },
  {
    Id: 9501,
    Code: '4112',
    Name: 'Clerk, local government'
  },
  {
    Id: 9502,
    Code: '4112',
    Name: 'Clerk, police service'
  },
  {
    Id: 9503,
    Code: '4112',
    Name: 'Clerk-typist, health authority'
  },
  {
    Id: 9504,
    Code: '4112',
    Name: 'Clerk-typist, local government'
  },
  {
    Id: 9505,
    Code: '4112',
    Name: 'Clerk-typist, police service'
  },
  {
    Id: 9506,
    Code: '4112',
    Name: 'Collector, rate'
  },
  {
    Id: 9507,
    Code: '4112',
    Name: 'Collector, senior, local government'
  },
  {
    Id: 9508,
    Code: '4112',
    Name: 'Collector, local government'
  },
  {
    Id: 9509,
    Code: '4112',
    Name: 'Collector-driver, local government'
  },
  {
    Id: 9510,
    Code: '4112',
    Name: 'Controller, watch, neighbourhood'
  },
  {
    Id: 9511,
    Code: '4112',
    Name: 'Inspector, employment'
  },
  {
    Id: 9512,
    Code: '4112',
    Name: 'Inspector, fraud, local government'
  },
  {
    Id: 9513,
    Code: '4112',
    Name: 'Inspector of rates and rentals'
  },
  {
    Id: 9514,
    Code: '4112',
    Name: 'Investigator, fraud, benefit, local government'
  },
  {
    Id: 9515,
    Code: '4112',
    Name: 'Investigator, fraud, benefits, local government'
  },
  {
    Id: 9516,
    Code: '4112',
    Name: 'Leader, team, services, data, local government'
  },
  {
    Id: 9517,
    Code: '4112',
    Name: 'Officer, admin, local government'
  },
  {
    Id: 9518,
    Code: '4112',
    Name: 'Officer, admin, police service'
  },
  {
    Id: 9519,
    Code: '4112',
    Name: 'Officer, administration, local government'
  },
  {
    Id: 9520,
    Code: '4112',
    Name: 'Officer, administration, police service'
  },
  {
    Id: 9521,
    Code: '4112',
    Name: 'Officer, administrative, local government'
  },
  {
    Id: 9522,
    Code: '4112',
    Name: 'Officer, administrative, police service'
  },
  {
    Id: 9523,
    Code: '4112',
    Name: 'Officer, amenity, area'
  },
  {
    Id: 9524,
    Code: '4112',
    Name: 'Officer, arrears, local government'
  },
  {
    Id: 9525,
    Code: '4112',
    Name: 'Officer, assessment, local government'
  },
  {
    Id: 9526,
    Code: '4112',
    Name: 'Officer, authorised, local government'
  },
  {
    Id: 9527,
    Code: '4112',
    Name: 'Officer, benefit, housing'
  },
  {
    Id: 9528,
    Code: '4112',
    Name: 'Officer, benefit, senior, local government'
  },
  {
    Id: 9529,
    Code: '4112',
    Name: 'Officer, benefit, local government'
  },
  {
    Id: 9530,
    Code: '4112',
    Name: 'Officer, benefits, housing'
  },
  {
    Id: 9531,
    Code: '4112',
    Name: 'Officer, benefits, senior, local government'
  },
  {
    Id: 9532,
    Code: '4112',
    Name: 'Officer, benefits, local government'
  },
  {
    Id: 9533,
    Code: '4112',
    Name: 'Officer, billing, local government'
  },
  {
    Id: 9534,
    Code: '4112',
    Name: 'Officer, business, local government'
  },
  {
    Id: 9535,
    Code: '4112',
    Name: 'Officer, charges, land'
  },
  {
    Id: 9536,
    Code: '4112',
    Name: 'Officer, clerical, higher, local government'
  },
  {
    Id: 9537,
    Code: '4112',
    Name: 'Officer, clerical, health authority'
  },
  {
    Id: 9538,
    Code: '4112',
    Name: 'Officer, clerical, local government'
  },
  {
    Id: 9539,
    Code: '4112',
    Name: 'Officer, clerical, police service'
  },
  {
    Id: 9540,
    Code: '4112',
    Name: 'Officer, collection, local government'
  },
  {
    Id: 9541,
    Code: '4112',
    Name: 'Officer, communications, local government'
  },
  {
    Id: 9542,
    Code: '4112',
    Name: 'Officer, elections'
  },
  {
    Id: 9543,
    Code: '4112',
    Name: 'Officer, enquiry, station, police'
  },
  {
    Id: 9544,
    Code: '4112',
    Name: 'Officer, enquiry, police service'
  },
  {
    Id: 9545,
    Code: '4112',
    Name: 'Officer, executive, local government'
  },
  {
    Id: 9546,
    Code: '4112',
    Name: 'Officer, fraud, local government'
  },
  {
    Id: 9547,
    Code: '4112',
    Name: 'Officer, government, local, benefits'
  },
  {
    Id: 9548,
    Code: '4112',
    Name: 'Officer, government, local, revenue'
  },
  {
    Id: 9549,
    Code: '4112',
    Name: 'Officer, government, local, local government: housing dept'
  },
  {
    Id: 9550,
    Code: '4112',
    Name: 'Officer, government, local'
  },
  {
    Id: 9551,
    Code: '4112',
    Name: 'Officer, income, local government'
  },
  {
    Id: 9552,
    Code: '4112',
    Name: 'Officer, investigation, fraud, local government'
  },
  {
    Id: 9553,
    Code: '4112',
    Name: 'Officer, lettings, local government: housing dept'
  },
  {
    Id: 9554,
    Code: '4112',
    Name: 'Officer, memorials'
  },
  {
    Id: 9555,
    Code: '4112',
    Name: 'Officer, monitoring, local government'
  },
  {
    Id: 9556,
    Code: '4112',
    Name: 'Officer, rates'
  },
  {
    Id: 9557,
    Code: '4112',
    Name: 'Officer, rebate, local government'
  },
  {
    Id: 9558,
    Code: '4112',
    Name: 'Officer, revenue, local government'
  },
  {
    Id: 9559,
    Code: '4112',
    Name: 'Officer, revenues, local government'
  },
  {
    Id: 9560,
    Code: '4112',
    Name: 'Officer, rights of way'
  },
  {
    Id: 9561,
    Code: '4112',
    Name: 'Officer, service, customer, local government'
  },
  {
    Id: 9562,
    Code: '4112',
    Name: 'Officer, services, electoral'
  },
  {
    Id: 9563,
    Code: '4112',
    Name: 'Officer, support, management, local government'
  },
  {
    Id: 9564,
    Code: '4112',
    Name: 'Officer, support, planning, local government'
  },
  {
    Id: 9565,
    Code: '4112',
    Name: 'Officer, support, local government'
  },
  {
    Id: 9566,
    Code: '4112',
    Name: 'Officer, tax, council'
  },
  {
    Id: 9567,
    Code: '4112',
    Name: 'Officer, technical, local government'
  },
  {
    Id: 9568,
    Code: '4112',
    Name: 'Officer, transport, local government'
  },
  {
    Id: 9569,
    Code: '4112',
    Name: 'Officer, visiting, local government'
  },
  {
    Id: 9570,
    Code: '4112',
    Name: 'Official, government, local'
  },
  {
    Id: 9571,
    Code: '4112',
    Name: 'Operator, bureau, reporting, crime'
  },
  {
    Id: 9572,
    Code: '4112',
    Name: 'Planner, duties, police service'
  },
  {
    Id: 9573,
    Code: '4112',
    Name: 'Processor, claims, local government'
  },
  {
    Id: 9574,
    Code: '4112',
    Name: 'Worker, case, police service'
  },
  {
    Id: 9575,
    Code: '4112',
    Name: 'Worker, clerical, local government'
  },
  {
    Id: 9576,
    Code: '4112',
    Name: 'Worker, office, local government'
  },
  {
    Id: 9577,
    Code: '4113',
    Name: 'Administrator, charity'
  },
  {
    Id: 9578,
    Code: '4113',
    Name: 'Administrator, covenant'
  },
  {
    Id: 9579,
    Code: '4113',
    Name: 'Administrator, curriculum'
  },
  {
    Id: 9580,
    Code: '4113',
    Name: 'Administrator, faculty'
  },
  {
    Id: 9581,
    Code: '4113',
    Name: 'Administrator, fundraising'
  },
  {
    Id: 9582,
    Code: '4113',
    Name: 'Administrator, membership'
  },
  {
    Id: 9583,
    Code: '4113',
    Name: 'Administrator, charitable organisation'
  },
  {
    Id: 9584,
    Code: '4113',
    Name: 'Administrator, clerical, higher education, university'
  },
  {
    Id: 9585,
    Code: '4113',
    Name: 'Administrator, trade union'
  },
  {
    Id: 9586,
    Code: '4113',
    Name: 'Assistant, admin, charitable organisation'
  },
  {
    Id: 9587,
    Code: '4113',
    Name: 'Assistant, admin, trade union'
  },
  {
    Id: 9588,
    Code: '4113',
    Name: 'Assistant, administration, charitable organisation'
  },
  {
    Id: 9589,
    Code: '4113',
    Name: 'Assistant, administration, trade union'
  },
  {
    Id: 9590,
    Code: '4113',
    Name: 'Assistant, administrative, charitable organisation'
  },
  {
    Id: 9591,
    Code: '4113',
    Name: 'Assistant, administrative, trade union'
  },
  {
    Id: 9592,
    Code: '4113',
    Name: 'Assistant, fundraising'
  },
  {
    Id: 9593,
    Code: '4113',
    Name: 'Assistant, membership'
  },
  {
    Id: 9594,
    Code: '4113',
    Name: 'Assistant, support, business, higher education, university'
  },
  {
    Id: 9595,
    Code: '4113',
    Name: 'Clerk to governing body'
  },
  {
    Id: 9596,
    Code: '4113',
    Name: 'Clerk to governors'
  },
  {
    Id: 9597,
    Code: '4113',
    Name: 'Clerk to school governors'
  },
  {
    Id: 9598,
    Code: '4113',
    Name: 'Clerk to the governors'
  },
  {
    Id: 9599,
    Code: '4113',
    Name: 'Clerk to the trustees, charitable organisation'
  },
  {
    Id: 9600,
    Code: '4113',
    Name: 'Convenor, works'
  },
  {
    Id: 9601,
    Code: '4113',
    Name: 'Delegate, union, trade'
  },
  {
    Id: 9602,
    Code: '4113',
    Name: 'Officer, administration, charitable organisation'
  },
  {
    Id: 9603,
    Code: '4113',
    Name: 'Officer, Council, British'
  },
  {
    Id: 9604,
    Code: '4113',
    Name: 'Officer, development, branch, trade union'
  },
  {
    Id: 9605,
    Code: '4113',
    Name: 'Officer, development, charitable organisation'
  },
  {
    Id: 9606,
    Code: '4113',
    Name: 'Officer, regional, public boards'
  },
  {
    Id: 9607,
    Code: '4113',
    Name: 'Officer, support, governor'
  },
  {
    Id: 9608,
    Code: '4113',
    Name: 'Officer, union, trade'
  },
  {
    Id: 9609,
    Code: '4113',
    Name: 'Officer, union'
  },
  {
    Id: 9610,
    Code: '4113',
    Name: 'Officer, British Council'
  },
  {
    Id: 9611,
    Code: '4113',
    Name: 'Official, union, trade'
  },
  {
    Id: 9612,
    Code: '4113',
    Name: 'Official, union'
  },
  {
    Id: 9613,
    Code: '4113',
    Name: 'Official, charitable organisation'
  },
  {
    Id: 9614,
    Code: '4113',
    Name: "Official, employers' association"
  },
  {
    Id: 9615,
    Code: '4113',
    Name: 'Official, professional organisation'
  },
  {
    Id: 9616,
    Code: '4113',
    Name: 'Official, trade union'
  },
  {
    Id: 9617,
    Code: '4113',
    Name: 'Organiser, constituency'
  },
  {
    Id: 9618,
    Code: '4113',
    Name: 'Organiser, political'
  },
  {
    Id: 9619,
    Code: '4113',
    Name: 'Organiser, political party'
  },
  {
    Id: 9620,
    Code: '4113',
    Name: 'Organiser, trade union'
  },
  {
    Id: 9621,
    Code: '4113',
    Name: 'President, vice, student union'
  },
  {
    Id: 9622,
    Code: '4113',
    Name: 'Representative, trade union'
  },
  {
    Id: 9623,
    Code: '4113',
    Name: 'Secretary, appeals, hospital'
  },
  {
    Id: 9624,
    Code: '4113',
    Name: 'Secretary, area, coal mine'
  },
  {
    Id: 9625,
    Code: '4113',
    Name: 'Secretary, assistant, charitable organisation'
  },
  {
    Id: 9626,
    Code: '4113',
    Name: 'Secretary, assistant, professional association'
  },
  {
    Id: 9627,
    Code: '4113',
    Name: 'Secretary, assistant, trade association'
  },
  {
    Id: 9628,
    Code: '4113',
    Name: 'Secretary, assistant, trade union'
  },
  {
    Id: 9629,
    Code: '4113',
    Name: 'Secretary, association'
  },
  {
    Id: 9630,
    Code: '4113',
    Name: 'Secretary, branch, charitable organisation'
  },
  {
    Id: 9631,
    Code: '4113',
    Name: 'Secretary, branch, insurance'
  },
  {
    Id: 9632,
    Code: '4113',
    Name: 'Secretary, branch, trade union'
  },
  {
    Id: 9633,
    Code: '4113',
    Name: 'Secretary, district, insurance'
  },
  {
    Id: 9634,
    Code: '4113',
    Name: 'Secretary, financial'
  },
  {
    Id: 9635,
    Code: '4113',
    Name: 'Secretary, fund'
  },
  {
    Id: 9636,
    Code: '4113',
    Name: 'Secretary, group, trade union'
  },
  {
    Id: 9637,
    Code: '4113',
    Name: 'Secretary, organising, charitable organisation'
  },
  {
    Id: 9638,
    Code: '4113',
    Name: 'Secretary, organising, professional association'
  },
  {
    Id: 9639,
    Code: '4113',
    Name: 'Secretary, organising, trade association'
  },
  {
    Id: 9640,
    Code: '4113',
    Name: 'Secretary, organising, trade union'
  },
  {
    Id: 9641,
    Code: '4113',
    Name: 'Secretary, political'
  },
  {
    Id: 9642,
    Code: '4113',
    Name: 'Secretary, chamber of commerce'
  },
  {
    Id: 9643,
    Code: '4113',
    Name: 'Secretary, joint industrial council'
  },
  {
    Id: 9644,
    Code: '4113',
    Name: 'Secretary, research association'
  },
  {
    Id: 9645,
    Code: '4113',
    Name: 'Secretary of charitable organisation'
  },
  {
    Id: 9646,
    Code: '4113',
    Name: 'Secretary of political association'
  },
  {
    Id: 9647,
    Code: '4113',
    Name: 'Secretary of professional association'
  },
  {
    Id: 9648,
    Code: '4113',
    Name: 'Secretary of trade association'
  },
  {
    Id: 9649,
    Code: '4113',
    Name: 'Secretary of trade union'
  },
  {
    Id: 9650,
    Code: '4113',
    Name: 'Steward, shop'
  },
  {
    Id: 9651,
    Code: '4121',
    Name: 'Administrator, control, credit'
  },
  {
    Id: 9652,
    Code: '4121',
    Name: 'Administrator, credit'
  },
  {
    Id: 9653,
    Code: '4121',
    Name: 'Administrator, loans'
  },
  {
    Id: 9654,
    Code: '4121',
    Name: 'Adviser, difficulties, financial'
  },
  {
    Id: 9655,
    Code: '4121',
    Name: 'Adviser, management, debt'
  },
  {
    Id: 9656,
    Code: '4121',
    Name: 'Assistant, control, credit'
  },
  {
    Id: 9657,
    Code: '4121',
    Name: 'Associate, management, debt'
  },
  {
    Id: 9658,
    Code: '4121',
    Name: 'Clerk, authorisation, banking'
  },
  {
    Id: 9659,
    Code: '4121',
    Name: 'Clerk, authorisation, building society'
  },
  {
    Id: 9660,
    Code: '4121',
    Name: 'Clerk, control, credit'
  },
  {
    Id: 9661,
    Code: '4121',
    Name: 'Clerk, recovery, debt'
  },
  {
    Id: 9662,
    Code: '4121',
    Name: 'Controller, credit'
  },
  {
    Id: 9663,
    Code: '4121',
    Name: 'Coordinator, claim, credit'
  },
  {
    Id: 9664,
    Code: '4121',
    Name: 'Officer, collections, banking'
  },
  {
    Id: 9665,
    Code: '4121',
    Name: 'Officer, collections, building society'
  },
  {
    Id: 9666,
    Code: '4121',
    Name: 'Officer, control, credit'
  },
  {
    Id: 9667,
    Code: '4121',
    Name: 'Officer, recovery, income'
  },
  {
    Id: 9668,
    Code: '4121',
    Name: 'Officer, recovery, debt'
  },
  {
    Id: 9669,
    Code: '4121',
    Name: 'Processor, loan'
  },
  {
    Id: 9670,
    Code: '4122',
    Name: 'Accountant, barrack'
  },
  {
    Id: 9671,
    Code: '4122',
    Name: 'Accountant, barracks'
  },
  {
    Id: 9672,
    Code: '4122',
    Name: 'Accountant, cost'
  },
  {
    Id: 9673,
    Code: '4122',
    Name: 'Accountant, cost and works'
  },
  {
    Id: 9674,
    Code: '4122',
    Name: 'Accountant, financial'
  },
  {
    Id: 9675,
    Code: '4122',
    Name: 'Accountant, group'
  },
  {
    Id: 9676,
    Code: '4122',
    Name: 'Accountant, management'
  },
  {
    Id: 9677,
    Code: '4122',
    Name: 'Accountant, works'
  },
  {
    Id: 9678,
    Code: '4122',
    Name: 'Accountant'
  },
  {
    Id: 9679,
    Code: '4122',
    Name: 'Accountant and auditor'
  },
  {
    Id: 9680,
    Code: '4122',
    Name: 'Administrator, accounts'
  },
  {
    Id: 9681,
    Code: '4122',
    Name: 'Administrator, billing'
  },
  {
    Id: 9682,
    Code: '4122',
    Name: 'Administrator, holding, fund'
  },
  {
    Id: 9683,
    Code: '4122',
    Name: 'Administrator, ledger, purchase'
  },
  {
    Id: 9684,
    Code: '4122',
    Name: 'Administrator, ledger'
  },
  {
    Id: 9685,
    Code: '4122',
    Name: 'Administrator, pay'
  },
  {
    Id: 9686,
    Code: '4122',
    Name: 'Administrator, payable, accounts'
  },
  {
    Id: 9687,
    Code: '4122',
    Name: 'Administrator, payroll'
  },
  {
    Id: 9688,
    Code: '4122',
    Name: 'Administrator, wages'
  },
  {
    Id: 9689,
    Code: '4122',
    Name: 'Adviser, payroll'
  },
  {
    Id: 9690,
    Code: '4122',
    Name: 'Assistant, account'
  },
  {
    Id: 9691,
    Code: '4122',
    Name: 'Assistant, accountancy'
  },
  {
    Id: 9692,
    Code: '4122',
    Name: "Assistant, accountant's"
  },
  {
    Id: 9693,
    Code: '4122',
    Name: 'Assistant, accounting'
  },
  {
    Id: 9694,
    Code: '4122',
    Name: 'Assistant, accounts'
  },
  {
    Id: 9695,
    Code: '4122',
    Name: 'Assistant, clerical, accounts'
  },
  {
    Id: 9696,
    Code: '4122',
    Name: 'Assistant, clerical, accountancy'
  },
  {
    Id: 9697,
    Code: '4122',
    Name: 'Assistant, ledger, purchase'
  },
  {
    Id: 9698,
    Code: '4122',
    Name: 'Assistant, ledger'
  },
  {
    Id: 9699,
    Code: '4122',
    Name: 'Assistant, payable, accounts'
  },
  {
    Id: 9700,
    Code: '4122',
    Name: 'Assistant, payroll'
  },
  {
    Id: 9701,
    Code: '4122',
    Name: 'Assistant, salaries'
  },
  {
    Id: 9702,
    Code: '4122',
    Name: 'Assistant, services, payroll'
  },
  {
    Id: 9703,
    Code: '4122',
    Name: 'Assistant, wages'
  },
  {
    Id: 9704,
    Code: '4122',
    Name: 'Associate, accounts'
  },
  {
    Id: 9705,
    Code: '4122',
    Name: 'Associate, audit'
  },
  {
    Id: 9706,
    Code: '4122',
    Name: 'Auditor, compliance'
  },
  {
    Id: 9707,
    Code: '4122',
    Name: 'Auditor, internal'
  },
  {
    Id: 9708,
    Code: '4122',
    Name: 'Auditor, night'
  },
  {
    Id: 9709,
    Code: '4122',
    Name: 'Auditor'
  },
  {
    Id: 9710,
    Code: '4122',
    Name: 'Bookkeeper, chief'
  },
  {
    Id: 9711,
    Code: '4122',
    Name: 'Bookkeeper'
  },
  {
    Id: 9712,
    Code: '4122',
    Name: 'Bookkeeper-accountant'
  },
  {
    Id: 9713,
    Code: '4122',
    Name: 'Bookkeeper-cashier'
  },
  {
    Id: 9714,
    Code: '4122',
    Name: 'Bookkeeper-secretary'
  },
  {
    Id: 9715,
    Code: '4122',
    Name: 'Bookkeeper-typist'
  },
  {
    Id: 9716,
    Code: '4122',
    Name: 'Checker, invoice'
  },
  {
    Id: 9717,
    Code: '4122',
    Name: 'Clerk, account'
  },
  {
    Id: 9718,
    Code: '4122',
    Name: 'Clerk, accountancy'
  },
  {
    Id: 9719,
    Code: '4122',
    Name: "Clerk, accountant's"
  },
  {
    Id: 9720,
    Code: '4122',
    Name: 'Clerk, accounts'
  },
  {
    Id: 9721,
    Code: '4122',
    Name: 'Clerk, audit'
  },
  {
    Id: 9722,
    Code: '4122',
    Name: 'Clerk, book-keeping'
  },
  {
    Id: 9723,
    Code: '4122',
    Name: 'Clerk, control, accounts'
  },
  {
    Id: 9724,
    Code: '4122',
    Name: 'Clerk, invoice'
  },
  {
    Id: 9725,
    Code: '4122',
    Name: 'Clerk, invoicing'
  },
  {
    Id: 9726,
    Code: '4122',
    Name: 'Clerk, keeping, book'
  },
  {
    Id: 9727,
    Code: '4122',
    Name: 'Clerk, ledger, sales'
  },
  {
    Id: 9728,
    Code: '4122',
    Name: 'Clerk, ledger'
  },
  {
    Id: 9729,
    Code: '4122',
    Name: 'Clerk, pay'
  },
  {
    Id: 9730,
    Code: '4122',
    Name: 'Clerk, payable, accounts'
  },
  {
    Id: 9731,
    Code: '4122',
    Name: 'Clerk, payroll'
  },
  {
    Id: 9732,
    Code: '4122',
    Name: 'Clerk, salaries'
  },
  {
    Id: 9733,
    Code: '4122',
    Name: 'Clerk, wage'
  },
  {
    Id: 9734,
    Code: '4122',
    Name: 'Clerk, wages'
  },
  {
    Id: 9735,
    Code: '4122',
    Name: 'Clerk, wages and accounts'
  },
  {
    Id: 9736,
    Code: '4122',
    Name: 'Clerk, accountancy'
  },
  {
    Id: 9737,
    Code: '4122',
    Name: 'Clerk-bookkeeper'
  },
  {
    Id: 9738,
    Code: '4122',
    Name: 'Controller, account'
  },
  {
    Id: 9739,
    Code: '4122',
    Name: 'Controller, accounts'
  },
  {
    Id: 9740,
    Code: '4122',
    Name: 'Controller, invoice'
  },
  {
    Id: 9741,
    Code: '4122',
    Name: 'Controller, ledger, bought'
  },
  {
    Id: 9742,
    Code: '4122',
    Name: 'Controller, ledger, purchase'
  },
  {
    Id: 9743,
    Code: '4122',
    Name: 'Controller, ledger, sales'
  },
  {
    Id: 9744,
    Code: '4122',
    Name: 'Controller, ledger'
  },
  {
    Id: 9745,
    Code: '4122',
    Name: 'Controller, payable, accounts'
  },
  {
    Id: 9746,
    Code: '4122',
    Name: 'Controller, payroll'
  },
  {
    Id: 9747,
    Code: '4122',
    Name: 'Controller, wages'
  },
  {
    Id: 9748,
    Code: '4122',
    Name: 'Executive, payroll'
  },
  {
    Id: 9749,
    Code: '4122',
    Name: 'Inspector, accounts'
  },
  {
    Id: 9750,
    Code: '4122',
    Name: 'Investigator, accounts'
  },
  {
    Id: 9751,
    Code: '4122',
    Name: 'Invoicer'
  },
  {
    Id: 9752,
    Code: '4122',
    Name: 'Keeper, book, chief'
  },
  {
    Id: 9753,
    Code: '4122',
    Name: 'Keeper, book'
  },
  {
    Id: 9754,
    Code: '4122',
    Name: 'Keeper, ledger'
  },
  {
    Id: 9755,
    Code: '4122',
    Name: 'Leader, team, audit, financial services'
  },
  {
    Id: 9756,
    Code: '4122',
    Name: 'Leader, team, payroll'
  },
  {
    Id: 9757,
    Code: '4122',
    Name: 'Manager, invoice'
  },
  {
    Id: 9758,
    Code: '4122',
    Name: 'Manager, ledger, purchase'
  },
  {
    Id: 9759,
    Code: '4122',
    Name: 'Manager, ledger, sales'
  },
  {
    Id: 9760,
    Code: '4122',
    Name: 'Manager, ledger'
  },
  {
    Id: 9761,
    Code: '4122',
    Name: 'Manager, payroll'
  },
  {
    Id: 9762,
    Code: '4122',
    Name: 'Manager, payroll and pensions'
  },
  {
    Id: 9763,
    Code: '4122',
    Name: 'Manager, wages'
  },
  {
    Id: 9764,
    Code: '4122',
    Name: 'Master, pay'
  },
  {
    Id: 9765,
    Code: '4122',
    Name: 'Officer, accounts'
  },
  {
    Id: 9766,
    Code: '4122',
    Name: 'Officer, control, payroll'
  },
  {
    Id: 9767,
    Code: '4122',
    Name: 'Officer, payable, accounts'
  },
  {
    Id: 9768,
    Code: '4122',
    Name: 'Officer, payroll'
  },
  {
    Id: 9769,
    Code: '4122',
    Name: 'Officer, salaries'
  },
  {
    Id: 9770,
    Code: '4122',
    Name: 'Officer, support, accounts'
  },
  {
    Id: 9771,
    Code: '4122',
    Name: 'Officer, wages'
  },
  {
    Id: 9772,
    Code: '4122',
    Name: 'Officer, wages and control, coal mine'
  },
  {
    Id: 9773,
    Code: '4122',
    Name: 'Paymaster'
  },
  {
    Id: 9774,
    Code: '4122',
    Name: 'Specialist, payroll'
  },
  {
    Id: 9775,
    Code: '4122',
    Name: 'Supervisor, accounting'
  },
  {
    Id: 9776,
    Code: '4122',
    Name: 'Supervisor, accounts'
  },
  {
    Id: 9777,
    Code: '4122',
    Name: 'Supervisor, audit'
  },
  {
    Id: 9778,
    Code: '4122',
    Name: 'Supervisor, ledger'
  },
  {
    Id: 9779,
    Code: '4122',
    Name: 'Supervisor, payable, accounts'
  },
  {
    Id: 9780,
    Code: '4122',
    Name: 'Supervisor, payroll'
  },
  {
    Id: 9781,
    Code: '4122',
    Name: 'Supervisor, wages'
  },
  {
    Id: 9782,
    Code: '4122',
    Name: 'Supervisor, accountancy'
  },
  {
    Id: 9783,
    Code: '4123',
    Name: 'Administrator, banking'
  },
  {
    Id: 9784,
    Code: '4123',
    Name: 'Adviser, bank'
  },
  {
    Id: 9785,
    Code: '4123',
    Name: 'Adviser, banking, telephone'
  },
  {
    Id: 9786,
    Code: '4123',
    Name: 'Adviser, banking'
  },
  {
    Id: 9787,
    Code: '4123',
    Name: 'Adviser, branch, banking'
  },
  {
    Id: 9788,
    Code: '4123',
    Name: 'Adviser, branch, building society'
  },
  {
    Id: 9789,
    Code: '4123',
    Name: 'Adviser, customer, banking'
  },
  {
    Id: 9790,
    Code: '4123',
    Name: 'Adviser, customer, building society'
  },
  {
    Id: 9791,
    Code: '4123',
    Name: 'Adviser, personal, banking'
  },
  {
    Id: 9792,
    Code: '4123',
    Name: 'Adviser, phonebank'
  },
  {
    Id: 9793,
    Code: '4123',
    Name: 'Adviser, sales, banking'
  },
  {
    Id: 9794,
    Code: '4123',
    Name: 'Adviser, sales, building society'
  },
  {
    Id: 9795,
    Code: '4123',
    Name: 'Adviser, service, customer, banking'
  },
  {
    Id: 9796,
    Code: '4123',
    Name: 'Adviser, service, customer, building society'
  },
  {
    Id: 9797,
    Code: '4123',
    Name: 'Adviser, services, customer, banking'
  },
  {
    Id: 9798,
    Code: '4123',
    Name: 'Adviser, services, customer, building society'
  },
  {
    Id: 9799,
    Code: '4123',
    Name: 'Adviser, telebanking'
  },
  {
    Id: 9800,
    Code: '4123',
    Name: 'Agent, service, customer, banking'
  },
  {
    Id: 9801,
    Code: '4123',
    Name: 'Assistant, bank'
  },
  {
    Id: 9802,
    Code: '4123',
    Name: 'Assistant, branch, banking'
  },
  {
    Id: 9803,
    Code: '4123',
    Name: 'Assistant, branch, building society'
  },
  {
    Id: 9804,
    Code: '4123',
    Name: 'Assistant, clerical, banking'
  },
  {
    Id: 9805,
    Code: '4123',
    Name: 'Assistant, clerical, building society'
  },
  {
    Id: 9806,
    Code: '4123',
    Name: 'Assistant, controller, banking'
  },
  {
    Id: 9807,
    Code: '4123',
    Name: 'Assistant, counter, office, post'
  },
  {
    Id: 9808,
    Code: '4123',
    Name: 'Assistant, counter, PO'
  },
  {
    Id: 9809,
    Code: '4123',
    Name: 'Assistant, customer, banking'
  },
  {
    Id: 9810,
    Code: '4123',
    Name: 'Assistant, customer, building society'
  },
  {
    Id: 9811,
    Code: '4123',
    Name: "Assistant, inspector's, banking"
  },
  {
    Id: 9812,
    Code: '4123',
    Name: 'Assistant, office, post'
  },
  {
    Id: 9813,
    Code: '4123',
    Name: 'Assistant, postal, PO'
  },
  {
    Id: 9814,
    Code: '4123',
    Name: 'Assistant, principal, banking'
  },
  {
    Id: 9815,
    Code: '4123',
    Name: 'Assistant, sales, banking'
  },
  {
    Id: 9816,
    Code: '4123',
    Name: 'Assistant, service, customer, banking'
  },
  {
    Id: 9817,
    Code: '4123',
    Name: 'Assistant, service, customer, building society'
  },
  {
    Id: 9818,
    Code: '4123',
    Name: 'Assistant, services, client, banking'
  },
  {
    Id: 9819,
    Code: '4123',
    Name: 'Assistant, services, client, building society'
  },
  {
    Id: 9820,
    Code: '4123',
    Name: 'Assistant, services, customer, banking'
  },
  {
    Id: 9821,
    Code: '4123',
    Name: 'Assistant, services, customer, building society'
  },
  {
    Id: 9822,
    Code: '4123',
    Name: 'Assistant, services, financial, banking'
  },
  {
    Id: 9823,
    Code: '4123',
    Name: 'Assistant, services, financial, building society'
  },
  {
    Id: 9824,
    Code: '4123',
    Name: 'Associate, sales, banking'
  },
  {
    Id: 9825,
    Code: '4123',
    Name: 'Associate, service, customer, banking'
  },
  {
    Id: 9826,
    Code: '4123',
    Name: 'Banker, community'
  },
  {
    Id: 9827,
    Code: '4123',
    Name: 'Banker, essential'
  },
  {
    Id: 9828,
    Code: '4123',
    Name: 'Banker, local'
  },
  {
    Id: 9829,
    Code: '4123',
    Name: 'Banker, moment'
  },
  {
    Id: 9830,
    Code: '4123',
    Name: 'Banker, personal'
  },
  {
    Id: 9831,
    Code: '4123',
    Name: 'Banker, telephone'
  },
  {
    Id: 9832,
    Code: '4123',
    Name: 'Banker, universal'
  },
  {
    Id: 9833,
    Code: '4123',
    Name: 'CSA, banking'
  },
  {
    Id: 9834,
    Code: '4123',
    Name: 'Cashier, bank'
  },
  {
    Id: 9835,
    Code: '4123',
    Name: 'Cashier, chief, banking'
  },
  {
    Id: 9836,
    Code: '4123',
    Name: 'Cashier, chief'
  },
  {
    Id: 9837,
    Code: '4123',
    Name: 'Cashier, office, banking'
  },
  {
    Id: 9838,
    Code: '4123',
    Name: 'Cashier, office, building society'
  },
  {
    Id: 9839,
    Code: '4123',
    Name: 'Cashier, office, PO'
  },
  {
    Id: 9840,
    Code: '4123',
    Name: 'Cashier, society, building'
  },
  {
    Id: 9841,
    Code: '4123',
    Name: 'Cashier, banking'
  },
  {
    Id: 9842,
    Code: '4123',
    Name: 'Cashier, building society'
  },
  {
    Id: 9843,
    Code: '4123',
    Name: 'Cashier, PO'
  },
  {
    Id: 9844,
    Code: '4123',
    Name: 'Cashier-clerk, banking'
  },
  {
    Id: 9845,
    Code: '4123',
    Name: 'Clerk, admin, banking'
  },
  {
    Id: 9846,
    Code: '4123',
    Name: 'Clerk, administration, banking'
  },
  {
    Id: 9847,
    Code: '4123',
    Name: 'Clerk, bank'
  },
  {
    Id: 9848,
    Code: '4123',
    Name: 'Clerk, banking, banking'
  },
  {
    Id: 9849,
    Code: '4123',
    Name: 'Clerk, banking, building society'
  },
  {
    Id: 9850,
    Code: '4123',
    Name: 'Clerk, chief, banking'
  },
  {
    Id: 9851,
    Code: '4123',
    Name: 'Clerk, chief, building society'
  },
  {
    Id: 9852,
    Code: '4123',
    Name: 'Clerk, counter, office, post'
  },
  {
    Id: 9853,
    Code: '4123',
    Name: 'Clerk, counter, banking'
  },
  {
    Id: 9854,
    Code: '4123',
    Name: 'Clerk, counter, building society'
  },
  {
    Id: 9855,
    Code: '4123',
    Name: 'Clerk, counter, PO'
  },
  {
    Id: 9856,
    Code: '4123',
    Name: 'Clerk, office, post'
  },
  {
    Id: 9857,
    Code: '4123',
    Name: 'Clerk, service, customer, banking'
  },
  {
    Id: 9858,
    Code: '4123',
    Name: 'Clerk, service, customer, building society'
  },
  {
    Id: 9859,
    Code: '4123',
    Name: 'Clerk, services, customer, banking'
  },
  {
    Id: 9860,
    Code: '4123',
    Name: 'Clerk, services, customer, building society'
  },
  {
    Id: 9861,
    Code: '4123',
    Name: 'Clerk, society, building'
  },
  {
    Id: 9862,
    Code: '4123',
    Name: 'Clerk, banking'
  },
  {
    Id: 9863,
    Code: '4123',
    Name: 'Clerk, building society'
  },
  {
    Id: 9864,
    Code: '4123',
    Name: 'Clerk, PO'
  },
  {
    Id: 9865,
    Code: '4123',
    Name: 'Controller, lending, banking'
  },
  {
    Id: 9866,
    Code: '4123',
    Name: 'Controller, lending, building society'
  },
  {
    Id: 9867,
    Code: '4123',
    Name: 'Counsellor, banking'
  },
  {
    Id: 9868,
    Code: '4123',
    Name: 'Counsellor, building society'
  },
  {
    Id: 9869,
    Code: '4123',
    Name: 'Employee, bank'
  },
  {
    Id: 9870,
    Code: '4123',
    Name: 'Leader, team, banking'
  },
  {
    Id: 9871,
    Code: '4123',
    Name: 'Leader, team, building society'
  },
  {
    Id: 9872,
    Code: '4123',
    Name: 'Manager, counter, banking'
  },
  {
    Id: 9873,
    Code: '4123',
    Name: 'Manager, counter, PO'
  },
  {
    Id: 9874,
    Code: '4123',
    Name: 'Master, post, sub'
  },
  {
    Id: 9875,
    Code: '4123',
    Name: 'Master, post'
  },
  {
    Id: 9876,
    Code: '4123',
    Name: 'Master, sub-post'
  },
  {
    Id: 9877,
    Code: '4123',
    Name: 'Officer, bank'
  },
  {
    Id: 9878,
    Code: '4123',
    Name: 'Officer, clerical, banking'
  },
  {
    Id: 9879,
    Code: '4123',
    Name: 'Officer, clerical, building society'
  },
  {
    Id: 9880,
    Code: '4123',
    Name: 'Officer, clerical, PO'
  },
  {
    Id: 9881,
    Code: '4123',
    Name: 'Officer, commercial, banking'
  },
  {
    Id: 9882,
    Code: '4123',
    Name: 'Officer, commercial, building society'
  },
  {
    Id: 9883,
    Code: '4123',
    Name: 'Officer, lending, banking'
  },
  {
    Id: 9884,
    Code: '4123',
    Name: 'Officer, lending, building society'
  },
  {
    Id: 9885,
    Code: '4123',
    Name: 'Officer, loans, banking'
  },
  {
    Id: 9886,
    Code: '4123',
    Name: 'Officer, loans, building society'
  },
  {
    Id: 9887,
    Code: '4123',
    Name: 'Officer, postal, PO'
  },
  {
    Id: 9888,
    Code: '4123',
    Name: 'Officer, relations, customer, banking'
  },
  {
    Id: 9889,
    Code: '4123',
    Name: 'Officer, relations, customer, building society'
  },
  {
    Id: 9890,
    Code: '4123',
    Name: 'Officer, second, banking'
  },
  {
    Id: 9891,
    Code: '4123',
    Name: 'Officer, securities, banking'
  },
  {
    Id: 9892,
    Code: '4123',
    Name: 'Officer, service, customer, banking'
  },
  {
    Id: 9893,
    Code: '4123',
    Name: 'Officer, service, customer, building society'
  },
  {
    Id: 9894,
    Code: '4123',
    Name: 'Officer, services, customer, banking'
  },
  {
    Id: 9895,
    Code: '4123',
    Name: 'Officer, services, customer, building society'
  },
  {
    Id: 9896,
    Code: '4123',
    Name: 'Officer, settlements, banking'
  },
  {
    Id: 9897,
    Code: '4123',
    Name: 'Officer-in-charge, sub-post office'
  },
  {
    Id: 9898,
    Code: '4123',
    Name: 'Official, bank'
  },
  {
    Id: 9899,
    Code: '4123',
    Name: 'Postmaster, sub'
  },
  {
    Id: 9900,
    Code: '4123',
    Name: 'Postmaster'
  },
  {
    Id: 9901,
    Code: '4123',
    Name: 'Postmistress'
  },
  {
    Id: 9902,
    Code: '4123',
    Name: 'Processor, cash'
  },
  {
    Id: 9903,
    Code: '4123',
    Name: 'Processor, claims'
  },
  {
    Id: 9904,
    Code: '4123',
    Name: 'Representative, customer, banking'
  },
  {
    Id: 9905,
    Code: '4123',
    Name: 'Representative, member'
  },
  {
    Id: 9906,
    Code: '4123',
    Name: 'Representative, service, client, banking'
  },
  {
    Id: 9907,
    Code: '4123',
    Name: 'Representative, service, customer, banking'
  },
  {
    Id: 9908,
    Code: '4123',
    Name: 'Representative, service, customer, building society'
  },
  {
    Id: 9909,
    Code: '4123',
    Name: 'Representative, services, client, banking'
  },
  {
    Id: 9910,
    Code: '4123',
    Name: 'Representative, services, customer, banking'
  },
  {
    Id: 9911,
    Code: '4123',
    Name: 'Representative, services, customer, building society'
  },
  {
    Id: 9912,
    Code: '4123',
    Name: 'Sub-postmaster'
  },
  {
    Id: 9913,
    Code: '4123',
    Name: 'Superintendent, banking'
  },
  {
    Id: 9914,
    Code: '4123',
    Name: 'Superintendent, PO'
  },
  {
    Id: 9915,
    Code: '4123',
    Name: 'Supervisor, bank'
  },
  {
    Id: 9916,
    Code: '4123',
    Name: 'Supervisor, banking'
  },
  {
    Id: 9917,
    Code: '4123',
    Name: 'Supervisor, claims'
  },
  {
    Id: 9918,
    Code: '4123',
    Name: 'Supervisor, service, customer, banking'
  },
  {
    Id: 9919,
    Code: '4123',
    Name: 'Supervisor, service, customer, building society'
  },
  {
    Id: 9920,
    Code: '4123',
    Name: 'Supervisor, services, customer, banking'
  },
  {
    Id: 9921,
    Code: '4123',
    Name: 'Supervisor, services, customer, building society'
  },
  {
    Id: 9922,
    Code: '4123',
    Name: 'Supervisor, banking'
  },
  {
    Id: 9923,
    Code: '4123',
    Name: 'Supervisor, building society'
  },
  {
    Id: 9924,
    Code: '4123',
    Name: 'Teller, bank'
  },
  {
    Id: 9925,
    Code: '4123',
    Name: 'Teller, banking'
  },
  {
    Id: 9926,
    Code: '4124',
    Name: 'Officer, finance, deputy'
  },
  {
    Id: 9927,
    Code: '4124',
    Name: 'Officer, finance, regional, PO'
  },
  {
    Id: 9928,
    Code: '4124',
    Name: 'Officer, finance'
  },
  {
    Id: 9929,
    Code: '4124',
    Name: 'Officer, financial, local government'
  },
  {
    Id: 9930,
    Code: '4124',
    Name: 'Officer, recovery, local government'
  },
  {
    Id: 9931,
    Code: '4129',
    Name: 'Administrator, account'
  },
  {
    Id: 9932,
    Code: '4129',
    Name: 'Administrator, bonus'
  },
  {
    Id: 9933,
    Code: '4129',
    Name: 'Administrator, claims'
  },
  {
    Id: 9934,
    Code: '4129',
    Name: 'Administrator, cost'
  },
  {
    Id: 9935,
    Code: '4129',
    Name: 'Administrator, finance'
  },
  {
    Id: 9936,
    Code: '4129',
    Name: 'Administrator, financial'
  },
  {
    Id: 9937,
    Code: '4129',
    Name: 'Administrator, grants'
  },
  {
    Id: 9938,
    Code: '4129',
    Name: 'Administrator, mortgage'
  },
  {
    Id: 9939,
    Code: '4129',
    Name: 'Administrator, office, cash'
  },
  {
    Id: 9940,
    Code: '4129',
    Name: 'Administrator, service, client, financial services'
  },
  {
    Id: 9941,
    Code: '4129',
    Name: 'Administrator, services, client, financial services'
  },
  {
    Id: 9942,
    Code: '4129',
    Name: 'Administrator, services, financial'
  },
  {
    Id: 9943,
    Code: '4129',
    Name: 'Administrator, wrap'
  },
  {
    Id: 9944,
    Code: '4129',
    Name: 'Administrator, financial services'
  },
  {
    Id: 9945,
    Code: '4129',
    Name: 'Adviser, customer, financial services'
  },
  {
    Id: 9946,
    Code: '4129',
    Name: 'Adviser, money, travel'
  },
  {
    Id: 9947,
    Code: '4129',
    Name: 'Adviser, service, client, financial services'
  },
  {
    Id: 9948,
    Code: '4129',
    Name: 'Adviser, service, customer, financial services'
  },
  {
    Id: 9949,
    Code: '4129',
    Name: 'Agent, ticket'
  },
  {
    Id: 9950,
    Code: '4129',
    Name: 'Analyst, billing'
  },
  {
    Id: 9951,
    Code: '4129',
    Name: 'Analyst, cost'
  },
  {
    Id: 9952,
    Code: '4129',
    Name: 'Assessor, bonus'
  },
  {
    Id: 9953,
    Code: '4129',
    Name: 'Assessor, finance, student'
  },
  {
    Id: 9954,
    Code: '4129',
    Name: 'Assessor, financial'
  },
  {
    Id: 9955,
    Code: '4129',
    Name: "Assistant, accountant's, turf"
  },
  {
    Id: 9956,
    Code: '4129',
    Name: "Assistant, agent's, commission"
  },
  {
    Id: 9957,
    Code: '4129',
    Name: 'Assistant, audit'
  },
  {
    Id: 9958,
    Code: '4129',
    Name: 'Assistant, betting'
  },
  {
    Id: 9959,
    Code: '4129',
    Name: 'Assistant, budget'
  },
  {
    Id: 9960,
    Code: '4129',
    Name: "Assistant, bursar's"
  },
  {
    Id: 9961,
    Code: '4129',
    Name: "Assistant, cashier's"
  },
  {
    Id: 9962,
    Code: '4129',
    Name: 'Assistant, control, investment company'
  },
  {
    Id: 9963,
    Code: '4129',
    Name: 'Assistant, cost'
  },
  {
    Id: 9964,
    Code: '4129',
    Name: 'Assistant, costing'
  },
  {
    Id: 9965,
    Code: '4129',
    Name: 'Assistant, counter, bookmakers, turf accountants'
  },
  {
    Id: 9966,
    Code: '4129',
    Name: 'Assistant, dealer’s, financial services'
  },
  {
    Id: 9967,
    Code: '4129',
    Name: 'Assistant, estimating'
  },
  {
    Id: 9968,
    Code: '4129',
    Name: "Assistant, estimator's"
  },
  {
    Id: 9969,
    Code: '4129',
    Name: 'Assistant, exchequer'
  },
  {
    Id: 9970,
    Code: '4129',
    Name: 'Assistant, experience, customer, bookmakers, turf accountants'
  },
  {
    Id: 9971,
    Code: '4129',
    Name: 'Assistant, finance'
  },
  {
    Id: 9972,
    Code: '4129',
    Name: 'Assistant, financial'
  },
  {
    Id: 9973,
    Code: '4129',
    Name: 'Assistant, fund'
  },
  {
    Id: 9974,
    Code: '4129',
    Name: 'Assistant, holding, fund'
  },
  {
    Id: 9975,
    Code: '4129',
    Name: 'Assistant, income'
  },
  {
    Id: 9976,
    Code: '4129',
    Name: "Assistant, manager's, betting shop"
  },
  {
    Id: 9977,
    Code: '4129',
    Name: 'Assistant, mortgage'
  },
  {
    Id: 9978,
    Code: '4129',
    Name: 'Assistant, office, box'
  },
  {
    Id: 9979,
    Code: '4129',
    Name: 'Assistant, office, cash'
  },
  {
    Id: 9980,
    Code: '4129',
    Name: 'Assistant, office, ticket'
  },
  {
    Id: 9981,
    Code: '4129',
    Name: 'Assistant, payments'
  },
  {
    Id: 9982,
    Code: '4129',
    Name: 'Assistant, planning, financial'
  },
  {
    Id: 9983,
    Code: '4129',
    Name: 'Assistant, revenue'
  },
  {
    Id: 9984,
    Code: '4129',
    Name: 'Assistant, rights, broadcasting'
  },
  {
    Id: 9985,
    Code: '4129',
    Name: 'Assistant, rights, publishing'
  },
  {
    Id: 9986,
    Code: '4129',
    Name: 'Assistant, service, customer, betting and gambling'
  },
  {
    Id: 9987,
    Code: '4129',
    Name: 'Assistant, settlement'
  },
  {
    Id: 9988,
    Code: '4129',
    Name: 'Assistant, shop, betting'
  },
  {
    Id: 9989,
    Code: '4129',
    Name: 'Assistant, tax'
  },
  {
    Id: 9990,
    Code: '4129',
    Name: 'Assistant, taxation'
  },
  {
    Id: 9991,
    Code: '4129',
    Name: "Assistant, timekeeper's"
  },
  {
    Id: 9992,
    Code: '4129',
    Name: "Assistant, treasurer's"
  },
  {
    Id: 9993,
    Code: '4129',
    Name: 'Assistant, treasury'
  },
  {
    Id: 9994,
    Code: '4129',
    Name: 'Assistant, valuation'
  },
  {
    Id: 9995,
    Code: '4129',
    Name: 'Cashier, chief, local government'
  },
  {
    Id: 9996,
    Code: '4129',
    Name: 'Cashier, exchange, foreign'
  },
  {
    Id: 9997,
    Code: '4129',
    Name: 'Cashier, hospital'
  },
  {
    Id: 9998,
    Code: '4129',
    Name: 'Cashier, legal'
  },
  {
    Id: 9999,
    Code: '4129',
    Name: 'Cashier, office, bookmakers, turf accountants'
  },
  {
    Id: 10000,
    Code: '4129',
    Name: 'Cashier, office, local government'
  },
  {
    Id: 10001,
    Code: '4129',
    Name: 'Cashier, office'
  },
  {
    Id: 10002,
    Code: '4129',
    Name: 'Cashier, school'
  },
  {
    Id: 10003,
    Code: '4129',
    Name: 'Cashier, shop, betting'
  },
  {
    Id: 10004,
    Code: '4129',
    Name: 'Cashier, bookmakers, turf accountants'
  },
  {
    Id: 10005,
    Code: '4129',
    Name: 'Cashier, estate agents'
  },
  {
    Id: 10006,
    Code: '4129',
    Name: 'Cashier, government'
  },
  {
    Id: 10007,
    Code: '4129',
    Name: 'Cashier, hospital service'
  },
  {
    Id: 10008,
    Code: '4129',
    Name: 'Cashier, insurance'
  },
  {
    Id: 10009,
    Code: '4129',
    Name: 'Cashier, legal services'
  },
  {
    Id: 10010,
    Code: '4129',
    Name: 'Cashier, local government'
  },
  {
    Id: 10011,
    Code: '4129',
    Name: 'Cashier'
  },
  {
    Id: 10012,
    Code: '4129',
    Name: 'Cashier-receptionist'
  },
  {
    Id: 10013,
    Code: '4129',
    Name: 'Checker, time'
  },
  {
    Id: 10014,
    Code: '4129',
    Name: "Clerk, accountant's, turf"
  },
  {
    Id: 10015,
    Code: '4129',
    Name: 'Clerk, allocation, cash'
  },
  {
    Id: 10016,
    Code: '4129',
    Name: 'Clerk, banking'
  },
  {
    Id: 10017,
    Code: '4129',
    Name: 'Clerk, bill'
  },
  {
    Id: 10018,
    Code: '4129',
    Name: 'Clerk, billing'
  },
  {
    Id: 10019,
    Code: '4129',
    Name: 'Clerk, bonus'
  },
  {
    Id: 10020,
    Code: '4129',
    Name: "Clerk, bookmaker's"
  },
  {
    Id: 10021,
    Code: '4129',
    Name: 'Clerk, cash'
  },
  {
    Id: 10022,
    Code: '4129',
    Name: "Clerk, cashier's"
  },
  {
    Id: 10023,
    Code: '4129',
    Name: 'Clerk, claims'
  },
  {
    Id: 10024,
    Code: '4129',
    Name: 'Clerk, cost'
  },
  {
    Id: 10025,
    Code: '4129',
    Name: 'Clerk, costing'
  },
  {
    Id: 10026,
    Code: '4129',
    Name: 'Clerk, counter'
  },
  {
    Id: 10027,
    Code: '4129',
    Name: 'Clerk, course, betting'
  },
  {
    Id: 10028,
    Code: '4129',
    Name: 'Clerk, exchange, foreign, financial services'
  },
  {
    Id: 10029,
    Code: '4129',
    Name: 'Clerk, fees'
  },
  {
    Id: 10030,
    Code: '4129',
    Name: 'Clerk, finance'
  },
  {
    Id: 10031,
    Code: '4129',
    Name: 'Clerk, financial'
  },
  {
    Id: 10032,
    Code: '4129',
    Name: 'Clerk, mortgage'
  },
  {
    Id: 10033,
    Code: '4129',
    Name: 'Clerk, office, box'
  },
  {
    Id: 10034,
    Code: '4129',
    Name: 'Clerk, office, cash'
  },
  {
    Id: 10035,
    Code: '4129',
    Name: 'Clerk, office, ticket'
  },
  {
    Id: 10036,
    Code: '4129',
    Name: 'Clerk, payments'
  },
  {
    Id: 10037,
    Code: '4129',
    Name: 'Clerk, reconciliation, bank'
  },
  {
    Id: 10038,
    Code: '4129',
    Name: 'Clerk, security'
  },
  {
    Id: 10039,
    Code: '4129',
    Name: 'Clerk, settlements, exchange, foreign'
  },
  {
    Id: 10040,
    Code: '4129',
    Name: 'Clerk, settlements'
  },
  {
    Id: 10041,
    Code: '4129',
    Name: "Clerk, stockbroker's"
  },
  {
    Id: 10042,
    Code: '4129',
    Name: 'Clerk, time'
  },
  {
    Id: 10043,
    Code: '4129',
    Name: 'Clerk, valuation'
  },
  {
    Id: 10044,
    Code: '4129',
    Name: 'Clerk, credit card company'
  },
  {
    Id: 10045,
    Code: '4129',
    Name: 'Clerk, financial services'
  },
  {
    Id: 10046,
    Code: '4129',
    Name: 'Clerk, stockbrokers'
  },
  {
    Id: 10047,
    Code: '4129',
    Name: 'Clerk-cashier'
  },
  {
    Id: 10048,
    Code: '4129',
    Name: 'Collector, entertainment'
  },
  {
    Id: 10049,
    Code: '4129',
    Name: 'Consultant, cost'
  },
  {
    Id: 10050,
    Code: '4129',
    Name: 'Consultant, sales, exchange, foreign'
  },
  {
    Id: 10051,
    Code: '4129',
    Name: 'Consultant, sales, bureau de change'
  },
  {
    Id: 10052,
    Code: '4129',
    Name: 'Controller, budget'
  },
  {
    Id: 10053,
    Code: '4129',
    Name: 'Controller, cash'
  },
  {
    Id: 10054,
    Code: '4129',
    Name: 'Controller, cost'
  },
  {
    Id: 10055,
    Code: '4129',
    Name: 'Controller, mortgage'
  },
  {
    Id: 10056,
    Code: '4129',
    Name: 'Controller, price'
  },
  {
    Id: 10057,
    Code: '4129',
    Name: 'Coordinator, account'
  },
  {
    Id: 10058,
    Code: '4129',
    Name: 'Coordinator, accounts'
  },
  {
    Id: 10059,
    Code: '4129',
    Name: 'Counterhand, bookmakers, turf accountants'
  },
  {
    Id: 10060,
    Code: '4129',
    Name: 'Counterman, turf accountants'
  },
  {
    Id: 10061,
    Code: '4129',
    Name: 'Handler, cash'
  },
  {
    Id: 10062,
    Code: '4129',
    Name: 'Inspector, revenue, water company'
  },
  {
    Id: 10063,
    Code: '4129',
    Name: 'Keeper, time'
  },
  {
    Id: 10064,
    Code: '4129',
    Name: 'Leader, team, income'
  },
  {
    Id: 10065,
    Code: '4129',
    Name: 'Measurer, coal mine'
  },
  {
    Id: 10066,
    Code: '4129',
    Name: 'Officer, administration, finance'
  },
  {
    Id: 10067,
    Code: '4129',
    Name: 'Officer, banking, corporate'
  },
  {
    Id: 10068,
    Code: '4129',
    Name: 'Officer, budget'
  },
  {
    Id: 10069,
    Code: '4129',
    Name: 'Officer, compensation, coal mine'
  },
  {
    Id: 10070,
    Code: '4129',
    Name: 'Officer, control, budget'
  },
  {
    Id: 10071,
    Code: '4129',
    Name: 'Officer, grants'
  },
  {
    Id: 10072,
    Code: '4129',
    Name: 'Officer, income'
  },
  {
    Id: 10073,
    Code: '4129',
    Name: 'Officer, loans, student'
  },
  {
    Id: 10074,
    Code: '4129',
    Name: 'Officer, mortgage'
  },
  {
    Id: 10075,
    Code: '4129',
    Name: 'Officer, payments'
  },
  {
    Id: 10076,
    Code: '4129',
    Name: 'Officer, pricing'
  },
  {
    Id: 10077,
    Code: '4129',
    Name: 'Officer, revenue'
  },
  {
    Id: 10078,
    Code: '4129',
    Name: 'Officer, rights, broadcasting'
  },
  {
    Id: 10079,
    Code: '4129',
    Name: 'Officer, rights, publishing'
  },
  {
    Id: 10080,
    Code: '4129',
    Name: 'Officer, stamping'
  },
  {
    Id: 10081,
    Code: '4129',
    Name: 'Operator, totalisator'
  },
  {
    Id: 10082,
    Code: '4129',
    Name: 'Operator, tote'
  },
  {
    Id: 10083,
    Code: '4129',
    Name: 'Processor, mortgage'
  },
  {
    Id: 10084,
    Code: '4129',
    Name: 'Receptionist-cashier'
  },
  {
    Id: 10085,
    Code: '4129',
    Name: 'Recorder, time'
  },
  {
    Id: 10086,
    Code: '4129',
    Name: "Representative, banker's"
  },
  {
    Id: 10087,
    Code: '4129',
    Name: 'Representative, banking'
  },
  {
    Id: 10088,
    Code: '4129',
    Name: 'Salesman, ticket'
  },
  {
    Id: 10089,
    Code: '4129',
    Name: 'Seller, ticket'
  },
  {
    Id: 10090,
    Code: '4129',
    Name: 'Seller, totalisator, betting and gaming'
  },
  {
    Id: 10091,
    Code: '4129',
    Name: 'Settler, betting'
  },
  {
    Id: 10092,
    Code: '4129',
    Name: 'Timekeeper'
  },
  {
    Id: 10093,
    Code: '4129',
    Name: 'Treasurer'
  },
  {
    Id: 10094,
    Code: '4129',
    Name: 'Verifier, stock'
  },
  {
    Id: 10095,
    Code: '4129',
    Name: 'Worker, shop, turf accountants'
  },
  {
    Id: 10096,
    Code: '4131',
    Name: 'Administrator, buying'
  },
  {
    Id: 10097,
    Code: '4131',
    Name: 'Administrator, compliance'
  },
  {
    Id: 10098,
    Code: '4131',
    Name: 'Administrator, conference'
  },
  {
    Id: 10099,
    Code: '4131',
    Name: 'Administrator, contract'
  },
  {
    Id: 10100,
    Code: '4131',
    Name: 'Administrator, contracts'
  },
  {
    Id: 10101,
    Code: '4131',
    Name: 'Administrator, conveyancing'
  },
  {
    Id: 10102,
    Code: '4131',
    Name: 'Administrator, court'
  },
  {
    Id: 10103,
    Code: '4131',
    Name: 'Administrator, courts'
  },
  {
    Id: 10104,
    Code: '4131',
    Name: 'Administrator, events'
  },
  {
    Id: 10105,
    Code: '4131',
    Name: 'Administrator, examinations'
  },
  {
    Id: 10106,
    Code: '4131',
    Name: 'Administrator, exams'
  },
  {
    Id: 10107,
    Code: '4131',
    Name: 'Administrator, experience, patient'
  },
  {
    Id: 10108,
    Code: '4131',
    Name: 'Administrator, export'
  },
  {
    Id: 10109,
    Code: '4131',
    Name: 'Administrator, hire'
  },
  {
    Id: 10110,
    Code: '4131',
    Name: 'Administrator, letter, PO'
  },
  {
    Id: 10111,
    Code: '4131',
    Name: 'Administrator, lettings'
  },
  {
    Id: 10112,
    Code: '4131',
    Name: 'Administrator, patient'
  },
  {
    Id: 10113,
    Code: '4131',
    Name: 'Administrator, practice'
  },
  {
    Id: 10114,
    Code: '4131',
    Name: 'Administrator, prescription'
  },
  {
    Id: 10115,
    Code: '4131',
    Name: 'Administrator, procurement'
  },
  {
    Id: 10116,
    Code: '4131',
    Name: 'Administrator, purchase'
  },
  {
    Id: 10117,
    Code: '4131',
    Name: 'Administrator, purchasing'
  },
  {
    Id: 10118,
    Code: '4131',
    Name: 'Administrator, records, medical'
  },
  {
    Id: 10119,
    Code: '4131',
    Name: 'Administrator, technical'
  },
  {
    Id: 10120,
    Code: '4131',
    Name: 'Administrator, ward'
  },
  {
    Id: 10121,
    Code: '4131',
    Name: 'Adviser, care, patient'
  },
  {
    Id: 10122,
    Code: '4131',
    Name: 'Allocator'
  },
  {
    Id: 10123,
    Code: '4131',
    Name: "Assistant, admin, buyer's"
  },
  {
    Id: 10124,
    Code: '4131',
    Name: 'Assistant, admin, buying'
  },
  {
    Id: 10125,
    Code: '4131',
    Name: "Assistant, administration, buyer's"
  },
  {
    Id: 10126,
    Code: '4131',
    Name: 'Assistant, administration, buying'
  },
  {
    Id: 10127,
    Code: '4131',
    Name: 'Assistant, admissions'
  },
  {
    Id: 10128,
    Code: '4131',
    Name: 'Assistant, assurance, quality, clerical'
  },
  {
    Id: 10129,
    Code: '4131',
    Name: "Assistant, buyer's"
  },
  {
    Id: 10130,
    Code: '4131',
    Name: 'Assistant, buying'
  },
  {
    Id: 10131,
    Code: '4131',
    Name: "Assistant, clerk's, justices"
  },
  {
    Id: 10132,
    Code: '4131',
    Name: 'Assistant, contracts'
  },
  {
    Id: 10133,
    Code: '4131',
    Name: 'Assistant, control, production'
  },
  {
    Id: 10134,
    Code: '4131',
    Name: 'Assistant, control, quality, clerical'
  },
  {
    Id: 10135,
    Code: '4131',
    Name: 'Assistant, conveyancing'
  },
  {
    Id: 10136,
    Code: '4131',
    Name: 'Assistant, correspondence'
  },
  {
    Id: 10137,
    Code: '4131',
    Name: 'Assistant, employment'
  },
  {
    Id: 10138,
    Code: '4131',
    Name: 'Assistant, establishment'
  },
  {
    Id: 10139,
    Code: '4131',
    Name: 'Assistant, export'
  },
  {
    Id: 10140,
    Code: '4131',
    Name: 'Assistant, fingerprint'
  },
  {
    Id: 10141,
    Code: '4131',
    Name: 'Assistant, lettings'
  },
  {
    Id: 10142,
    Code: '4131',
    Name: 'Assistant, litigation'
  },
  {
    Id: 10143,
    Code: '4131',
    Name: 'Assistant, lottery'
  },
  {
    Id: 10144,
    Code: '4131',
    Name: 'Assistant, procurement'
  },
  {
    Id: 10145,
    Code: '4131',
    Name: 'Assistant, production, publishing'
  },
  {
    Id: 10146,
    Code: '4131',
    Name: 'Assistant, progress'
  },
  {
    Id: 10147,
    Code: '4131',
    Name: 'Assistant, purchasing'
  },
  {
    Id: 10148,
    Code: '4131',
    Name: 'Assistant, records'
  },
  {
    Id: 10149,
    Code: '4131',
    Name: "Assistant, registrar's"
  },
  {
    Id: 10150,
    Code: '4131',
    Name: 'Assistant, room, publishing'
  },
  {
    Id: 10151,
    Code: '4131',
    Name: "Assistant, solicitor's"
  },
  {
    Id: 10152,
    Code: '4131',
    Name: 'Assistant, staff'
  },
  {
    Id: 10153,
    Code: '4131',
    Name: 'Assistant, study, time'
  },
  {
    Id: 10154,
    Code: '4131',
    Name: 'Assistant, study, work'
  },
  {
    Id: 10155,
    Code: '4131',
    Name: 'Canvasser, elections'
  },
  {
    Id: 10156,
    Code: '4131',
    Name: 'Cataloguer'
  },
  {
    Id: 10157,
    Code: '4131',
    Name: 'Chartist'
  },
  {
    Id: 10158,
    Code: '4131',
    Name: 'Checker, coupon, competitions'
  },
  {
    Id: 10159,
    Code: '4131',
    Name: 'Checker, progress'
  },
  {
    Id: 10160,
    Code: '4131',
    Name: 'Checker, building construction'
  },
  {
    Id: 10161,
    Code: '4131',
    Name: 'Checker, docks'
  },
  {
    Id: 10162,
    Code: '4131',
    Name: 'Checker, oil refining'
  },
  {
    Id: 10163,
    Code: '4131',
    Name: 'Checker, wholesale, retail trade'
  },
  {
    Id: 10164,
    Code: '4131',
    Name: 'Clerk, actuarial'
  },
  {
    Id: 10165,
    Code: '4131',
    Name: 'Clerk, admin, hospital service'
  },
  {
    Id: 10166,
    Code: '4131',
    Name: 'Clerk, administration, hospital service'
  },
  {
    Id: 10167,
    Code: '4131',
    Name: 'Clerk, admissions'
  },
  {
    Id: 10168,
    Code: '4131',
    Name: 'Clerk, advertising'
  },
  {
    Id: 10169,
    Code: '4131',
    Name: 'Clerk, allocator'
  },
  {
    Id: 10170,
    Code: '4131',
    Name: 'Clerk, appointments'
  },
  {
    Id: 10171,
    Code: '4131',
    Name: 'Clerk, assurance, quality'
  },
  {
    Id: 10172,
    Code: '4131',
    Name: 'Clerk, booking'
  },
  {
    Id: 10173,
    Code: '4131',
    Name: 'Clerk, charge'
  },
  {
    Id: 10174,
    Code: '4131',
    Name: 'Clerk, clearance'
  },
  {
    Id: 10175,
    Code: '4131',
    Name: 'Clerk, clinic'
  },
  {
    Id: 10176,
    Code: '4131',
    Name: 'Clerk, coding'
  },
  {
    Id: 10177,
    Code: '4131',
    Name: 'Clerk, compilation'
  },
  {
    Id: 10178,
    Code: '4131',
    Name: 'Clerk, control, production'
  },
  {
    Id: 10179,
    Code: '4131',
    Name: 'Clerk, control, quality'
  },
  {
    Id: 10180,
    Code: '4131',
    Name: 'Clerk, conveyancing'
  },
  {
    Id: 10181,
    Code: '4131',
    Name: 'Clerk, correspondence'
  },
  {
    Id: 10182,
    Code: '4131',
    Name: 'Clerk, filing'
  },
  {
    Id: 10183,
    Code: '4131',
    Name: 'Clerk, fingerprint'
  },
  {
    Id: 10184,
    Code: '4131',
    Name: 'Clerk, hire'
  },
  {
    Id: 10185,
    Code: '4131',
    Name: 'Clerk, hospital'
  },
  {
    Id: 10186,
    Code: '4131',
    Name: 'Clerk, intake'
  },
  {
    Id: 10187,
    Code: '4131',
    Name: 'Clerk, law'
  },
  {
    Id: 10188,
    Code: '4131',
    Name: 'Clerk, list, waiting, hospital service'
  },
  {
    Id: 10189,
    Code: '4131',
    Name: 'Clerk, litigation'
  },
  {
    Id: 10190,
    Code: '4131',
    Name: 'Clerk, manifest'
  },
  {
    Id: 10191,
    Code: '4131',
    Name: 'Clerk, office, booking'
  },
  {
    Id: 10192,
    Code: '4131',
    Name: 'Clerk, office, buying'
  },
  {
    Id: 10193,
    Code: '4131',
    Name: 'Clerk, planning'
  },
  {
    Id: 10194,
    Code: '4131',
    Name: 'Clerk, pool'
  },
  {
    Id: 10195,
    Code: '4131',
    Name: 'Clerk, pools'
  },
  {
    Id: 10196,
    Code: '4131',
    Name: 'Clerk, prescription'
  },
  {
    Id: 10197,
    Code: '4131',
    Name: 'Clerk, purchase'
  },
  {
    Id: 10198,
    Code: '4131',
    Name: 'Clerk, purchasing'
  },
  {
    Id: 10199,
    Code: '4131',
    Name: 'Clerk, records, health'
  },
  {
    Id: 10200,
    Code: '4131',
    Name: 'Clerk, records, medical'
  },
  {
    Id: 10201,
    Code: '4131',
    Name: 'Clerk, records'
  },
  {
    Id: 10202,
    Code: '4131',
    Name: 'Clerk, schedule'
  },
  {
    Id: 10203,
    Code: '4131',
    Name: "Clerk, solicitor's"
  },
  {
    Id: 10204,
    Code: '4131',
    Name: 'Clerk, staff'
  },
  {
    Id: 10205,
    Code: '4131',
    Name: 'Clerk, statistical'
  },
  {
    Id: 10206,
    Code: '4131',
    Name: 'Clerk, statistics'
  },
  {
    Id: 10207,
    Code: '4131',
    Name: 'Clerk, tally'
  },
  {
    Id: 10208,
    Code: '4131',
    Name: 'Clerk, technical'
  },
  {
    Id: 10209,
    Code: '4131',
    Name: 'Clerk, ward'
  },
  {
    Id: 10210,
    Code: '4131',
    Name: 'Clerk, warranty'
  },
  {
    Id: 10211,
    Code: '4131',
    Name: 'Clerk, advertising, publicity'
  },
  {
    Id: 10212,
    Code: '4131',
    Name: 'Clerk, college'
  },
  {
    Id: 10213,
    Code: '4131',
    Name: 'Clerk, hospital service'
  },
  {
    Id: 10214,
    Code: '4131',
    Name: 'Clerk, university'
  },
  {
    Id: 10215,
    Code: '4131',
    Name: 'Clerk-buyer'
  },
  {
    Id: 10216,
    Code: '4131',
    Name: 'Clerk-typist, college'
  },
  {
    Id: 10217,
    Code: '4131',
    Name: 'Clerk-typist, university'
  },
  {
    Id: 10218,
    Code: '4131',
    Name: 'Coder'
  },
  {
    Id: 10219,
    Code: '4131',
    Name: 'Collector, census'
  },
  {
    Id: 10220,
    Code: '4131',
    Name: 'Compiler, catalogue'
  },
  {
    Id: 10221,
    Code: '4131',
    Name: 'Compiler, directory'
  },
  {
    Id: 10222,
    Code: '4131',
    Name: 'Compiler, index'
  },
  {
    Id: 10223,
    Code: '4131',
    Name: 'Controller, contract'
  },
  {
    Id: 10224,
    Code: '4131',
    Name: 'Controller, contracts'
  },
  {
    Id: 10225,
    Code: '4131',
    Name: 'Controller, document'
  },
  {
    Id: 10226,
    Code: '4131',
    Name: 'Controller, documentation'
  },
  {
    Id: 10227,
    Code: '4131',
    Name: 'Controller, progress'
  },
  {
    Id: 10228,
    Code: '4131',
    Name: 'Controller, schedule'
  },
  {
    Id: 10229,
    Code: '4131',
    Name: 'Controller, waste'
  },
  {
    Id: 10230,
    Code: '4131',
    Name: 'Coordinator, appointment'
  },
  {
    Id: 10231,
    Code: '4131',
    Name: 'Coordinator, pathway, patient'
  },
  {
    Id: 10232,
    Code: '4131',
    Name: 'Coordinator, pathway, hospital service'
  },
  {
    Id: 10233,
    Code: '4131',
    Name: 'Coordinator, patient'
  },
  {
    Id: 10234,
    Code: '4131',
    Name: 'Coordinator, practice'
  },
  {
    Id: 10235,
    Code: '4131',
    Name: 'Coordinator, records'
  },
  {
    Id: 10236,
    Code: '4131',
    Name: 'Correspondent, banking'
  },
  {
    Id: 10237,
    Code: '4131',
    Name: 'Detailer, duty'
  },
  {
    Id: 10238,
    Code: '4131',
    Name: 'Detailer, staff'
  },
  {
    Id: 10239,
    Code: '4131',
    Name: 'Enumerator, census'
  },
  {
    Id: 10240,
    Code: '4131',
    Name: 'Enumerator, census'
  },
  {
    Id: 10241,
    Code: '4131',
    Name: 'Expeditor, procurement'
  },
  {
    Id: 10242,
    Code: '4131',
    Name: 'Expeditor, purchasing'
  },
  {
    Id: 10243,
    Code: '4131',
    Name: 'Expeditor'
  },
  {
    Id: 10244,
    Code: '4131',
    Name: 'Indexer'
  },
  {
    Id: 10245,
    Code: '4131',
    Name: 'Interviewer, agency, employment'
  },
  {
    Id: 10246,
    Code: '4131',
    Name: 'Linesman, progress'
  },
  {
    Id: 10247,
    Code: '4131',
    Name: 'Logger, court'
  },
  {
    Id: 10248,
    Code: '4131',
    Name: 'Measurer, docks'
  },
  {
    Id: 10249,
    Code: '4131',
    Name: 'Officer, access, patient'
  },
  {
    Id: 10250,
    Code: '4131',
    Name: 'Officer, admission'
  },
  {
    Id: 10251,
    Code: '4131',
    Name: 'Officer, admissions'
  },
  {
    Id: 10252,
    Code: '4131',
    Name: 'Officer, allocation'
  },
  {
    Id: 10253,
    Code: '4131',
    Name: 'Officer, clerical, hospital service'
  },
  {
    Id: 10254,
    Code: '4131',
    Name: 'Officer, control, production'
  },
  {
    Id: 10255,
    Code: '4131',
    Name: 'Officer, court'
  },
  {
    Id: 10256,
    Code: '4131',
    Name: 'Officer, examinations, education'
  },
  {
    Id: 10257,
    Code: '4131',
    Name: 'Officer, exams, education'
  },
  {
    Id: 10258,
    Code: '4131',
    Name: 'Officer, exhibits, criminal'
  },
  {
    Id: 10259,
    Code: '4131',
    Name: 'Officer, fingerprint, civilian'
  },
  {
    Id: 10260,
    Code: '4131',
    Name: 'Officer, lettings'
  },
  {
    Id: 10261,
    Code: '4131',
    Name: 'Officer, magisterial'
  },
  {
    Id: 10262,
    Code: '4131',
    Name: 'Officer, progress'
  },
  {
    Id: 10263,
    Code: '4131',
    Name: 'Officer, properties, police service'
  },
  {
    Id: 10264,
    Code: '4131',
    Name: 'Officer, property, evidential, police service'
  },
  {
    Id: 10265,
    Code: '4131',
    Name: 'Officer, property, police service'
  },
  {
    Id: 10266,
    Code: '4131',
    Name: 'Officer, records, medical'
  },
  {
    Id: 10267,
    Code: '4131',
    Name: 'Officer, records'
  },
  {
    Id: 10268,
    Code: '4131',
    Name: 'Officer, search'
  },
  {
    Id: 10269,
    Code: '4131',
    Name: 'Officer, statistical, electricity supplier'
  },
  {
    Id: 10270,
    Code: '4131',
    Name: 'Officer, statistical, gas supplier'
  },
  {
    Id: 10271,
    Code: '4131',
    Name: 'Officer, warranty'
  },
  {
    Id: 10272,
    Code: '4131',
    Name: 'Official, court'
  },
  {
    Id: 10273,
    Code: '4131',
    Name: 'Official, PO'
  },
  {
    Id: 10274,
    Code: '4131',
    Name: 'Planner, work'
  },
  {
    Id: 10275,
    Code: '4131',
    Name: 'Planner, works'
  },
  {
    Id: 10276,
    Code: '4131',
    Name: 'Progressor'
  },
  {
    Id: 10277,
    Code: '4131',
    Name: 'Receptionist, ward'
  },
  {
    Id: 10278,
    Code: '4131',
    Name: 'Receptionist, ward, hospital service'
  },
  {
    Id: 10279,
    Code: '4131',
    Name: 'Recorder, milk'
  },
  {
    Id: 10280,
    Code: '4131',
    Name: 'Recorder, progress'
  },
  {
    Id: 10281,
    Code: '4131',
    Name: 'Recorder, milk testing'
  },
  {
    Id: 10282,
    Code: '4131',
    Name: 'Recorder of work'
  },
  {
    Id: 10283,
    Code: '4131',
    Name: 'Representative, dock'
  },
  {
    Id: 10284,
    Code: '4131',
    Name: 'Scheduler, cell'
  },
  {
    Id: 10285,
    Code: '4131',
    Name: 'Scheduler, production'
  },
  {
    Id: 10286,
    Code: '4131',
    Name: 'Scheduler'
  },
  {
    Id: 10287,
    Code: '4131',
    Name: 'Summariser, tape, police service'
  },
  {
    Id: 10288,
    Code: '4131',
    Name: 'Supporter, court'
  },
  {
    Id: 10289,
    Code: '4132',
    Name: 'Administrator, claim, insurance'
  },
  {
    Id: 10290,
    Code: '4132',
    Name: 'Administrator, claims, insurance'
  },
  {
    Id: 10291,
    Code: '4132',
    Name: 'Administrator, insurance'
  },
  {
    Id: 10292,
    Code: '4132',
    Name: 'Administrator, life, insurance'
  },
  {
    Id: 10293,
    Code: '4132',
    Name: 'Administrator, pension'
  },
  {
    Id: 10294,
    Code: '4132',
    Name: 'Administrator, pensions'
  },
  {
    Id: 10295,
    Code: '4132',
    Name: 'Administrator, SIPP'
  },
  {
    Id: 10296,
    Code: '4132',
    Name: 'Administrator, surrenders, insurance'
  },
  {
    Id: 10297,
    Code: '4132',
    Name: 'Administrator, insurance'
  },
  {
    Id: 10298,
    Code: '4132',
    Name: 'Adviser, claim, insurance'
  },
  {
    Id: 10299,
    Code: '4132',
    Name: 'Adviser, claims, personal'
  },
  {
    Id: 10300,
    Code: '4132',
    Name: 'Adviser, claims, insurance'
  },
  {
    Id: 10301,
    Code: '4132',
    Name: 'Adviser, customer, insurance'
  },
  {
    Id: 10302,
    Code: '4132',
    Name: 'Adviser, insurance'
  },
  {
    Id: 10303,
    Code: '4132',
    Name: 'Adviser, personal, insurance'
  },
  {
    Id: 10304,
    Code: '4132',
    Name: 'Adviser, service, customer, insurance'
  },
  {
    Id: 10305,
    Code: '4132',
    Name: 'Assistant, admin, insurance'
  },
  {
    Id: 10306,
    Code: '4132',
    Name: 'Assistant, administration, insurance'
  },
  {
    Id: 10307,
    Code: '4132',
    Name: 'Assistant, administrative, insurance'
  },
  {
    Id: 10308,
    Code: '4132',
    Name: 'Assistant, claims'
  },
  {
    Id: 10309,
    Code: '4132',
    Name: 'Assistant, clerical, insurance'
  },
  {
    Id: 10310,
    Code: '4132',
    Name: 'Assistant, divisional, insurance'
  },
  {
    Id: 10311,
    Code: '4132',
    Name: 'Assistant, executive, insurance'
  },
  {
    Id: 10312,
    Code: '4132',
    Name: 'Assistant, insurance'
  },
  {
    Id: 10313,
    Code: '4132',
    Name: 'Assistant, pensions'
  },
  {
    Id: 10314,
    Code: '4132',
    Name: 'Assistant, registration, insurance'
  },
  {
    Id: 10315,
    Code: '4132',
    Name: "Assistant, underwriter's"
  },
  {
    Id: 10316,
    Code: '4132',
    Name: 'Assistant, underwriting'
  },
  {
    Id: 10317,
    Code: '4132',
    Name: 'Auditor, insurance'
  },
  {
    Id: 10318,
    Code: '4132',
    Name: "Clerk, adjuster's, average"
  },
  {
    Id: 10319,
    Code: '4132',
    Name: 'Clerk, admin, insurance'
  },
  {
    Id: 10320,
    Code: '4132',
    Name: 'Clerk, administration, insurance'
  },
  {
    Id: 10321,
    Code: '4132',
    Name: 'Clerk, assurance'
  },
  {
    Id: 10322,
    Code: '4132',
    Name: 'Clerk, chief, insurance'
  },
  {
    Id: 10323,
    Code: '4132',
    Name: 'Clerk, claims, insurance'
  },
  {
    Id: 10324,
    Code: '4132',
    Name: 'Clerk, estimating, insurance'
  },
  {
    Id: 10325,
    Code: '4132',
    Name: 'Clerk, insurance'
  },
  {
    Id: 10326,
    Code: '4132',
    Name: 'Clerk, office, insurance'
  },
  {
    Id: 10327,
    Code: '4132',
    Name: 'Clerk, office, insurance'
  },
  {
    Id: 10328,
    Code: '4132',
    Name: 'Clerk, pensions'
  },
  {
    Id: 10329,
    Code: '4132',
    Name: 'Clerk, assurance company'
  },
  {
    Id: 10330,
    Code: '4132',
    Name: 'Clerk, insurance'
  },
  {
    Id: 10331,
    Code: '4132',
    Name: 'Correspondent, claims'
  },
  {
    Id: 10332,
    Code: '4132',
    Name: 'Examiner, policy, insurance'
  },
  {
    Id: 10333,
    Code: '4132',
    Name: 'Handler, claim'
  },
  {
    Id: 10334,
    Code: '4132',
    Name: 'Handler, claims, insurance'
  },
  {
    Id: 10335,
    Code: '4132',
    Name: 'Handler, claims'
  },
  {
    Id: 10336,
    Code: '4132',
    Name: 'Negotiator, pensions'
  },
  {
    Id: 10337,
    Code: '4132',
    Name: 'Officer, admin, insurance'
  },
  {
    Id: 10338,
    Code: '4132',
    Name: 'Officer, administration, insurance'
  },
  {
    Id: 10339,
    Code: '4132',
    Name: 'Officer, administrative, insurance'
  },
  {
    Id: 10340,
    Code: '4132',
    Name: 'Officer, clerical, insurance'
  },
  {
    Id: 10341,
    Code: '4132',
    Name: 'Officer, executive, insurance'
  },
  {
    Id: 10342,
    Code: '4132',
    Name: 'Officer, insurance'
  },
  {
    Id: 10343,
    Code: '4132',
    Name: 'Officer, pension'
  },
  {
    Id: 10344,
    Code: '4132',
    Name: 'Officer, pensions'
  },
  {
    Id: 10345,
    Code: '4132',
    Name: 'Officer, superannuation'
  },
  {
    Id: 10346,
    Code: '4132',
    Name: 'Officer, insurance'
  },
  {
    Id: 10347,
    Code: '4132',
    Name: 'Official, insurance'
  },
  {
    Id: 10348,
    Code: '4132',
    Name: 'Processor, claims, insurance'
  },
  {
    Id: 10349,
    Code: '4132',
    Name: 'Superintendent, administrative, insurance'
  },
  {
    Id: 10350,
    Code: '4132',
    Name: 'Supervisor, insurance'
  },
  {
    Id: 10351,
    Code: '4132',
    Name: 'Technician, claims'
  },
  {
    Id: 10352,
    Code: '4132',
    Name: 'Technician, insurance'
  },
  {
    Id: 10353,
    Code: '4132',
    Name: 'Technician, motor, insurance'
  },
  {
    Id: 10354,
    Code: '4132',
    Name: 'Technician, pensions'
  },
  {
    Id: 10355,
    Code: '4132',
    Name: 'Technician, review, pensions'
  },
  {
    Id: 10356,
    Code: '4132',
    Name: 'Technician, insurance'
  },
  {
    Id: 10357,
    Code: '4132',
    Name: 'Worker, clerical, insurance'
  },
  {
    Id: 10358,
    Code: '4133',
    Name: 'Administrator, inventory'
  },
  {
    Id: 10359,
    Code: '4133',
    Name: 'Administrator, parts'
  },
  {
    Id: 10360,
    Code: '4133',
    Name: 'Administrator, production'
  },
  {
    Id: 10361,
    Code: '4133',
    Name: 'Administrator, stock'
  },
  {
    Id: 10362,
    Code: '4133',
    Name: 'Administrator, stores'
  },
  {
    Id: 10363,
    Code: '4133',
    Name: 'Administrator, warehouse'
  },
  {
    Id: 10364,
    Code: '4133',
    Name: 'Analyst, inventory'
  },
  {
    Id: 10365,
    Code: '4133',
    Name: 'Assistant, chain, supply'
  },
  {
    Id: 10366,
    Code: '4133',
    Name: 'Assistant, control, stock'
  },
  {
    Id: 10367,
    Code: '4133',
    Name: 'Assistant, library, equipment, medical'
  },
  {
    Id: 10368,
    Code: '4133',
    Name: 'Assistant, materials'
  },
  {
    Id: 10369,
    Code: '4133',
    Name: 'Assistant, merchandise'
  },
  {
    Id: 10370,
    Code: '4133',
    Name: 'Assistant, operations, stock control'
  },
  {
    Id: 10371,
    Code: '4133',
    Name: 'Assistant, pharmacy, hospital service'
  },
  {
    Id: 10372,
    Code: '4133',
    Name: "Assistant, publisher's"
  },
  {
    Id: 10373,
    Code: '4133',
    Name: 'Assistant, room, stock'
  },
  {
    Id: 10374,
    Code: '4133',
    Name: 'Assistant, room, store'
  },
  {
    Id: 10375,
    Code: '4133',
    Name: 'Assistant, stock'
  },
  {
    Id: 10376,
    Code: '4133',
    Name: 'Auditor, stock'
  },
  {
    Id: 10377,
    Code: '4133',
    Name: 'Auditor, stock control'
  },
  {
    Id: 10378,
    Code: '4133',
    Name: 'Chaser, production'
  },
  {
    Id: 10379,
    Code: '4133',
    Name: 'Chaser, progress'
  },
  {
    Id: 10380,
    Code: '4133',
    Name: 'Chaser, stock'
  },
  {
    Id: 10381,
    Code: '4133',
    Name: 'Chaser, manufacturing'
  },
  {
    Id: 10382,
    Code: '4133',
    Name: 'Clerk, booking, stores'
  },
  {
    Id: 10383,
    Code: '4133',
    Name: 'Clerk, booking, warehouse'
  },
  {
    Id: 10384,
    Code: '4133',
    Name: 'Clerk, control, inventory'
  },
  {
    Id: 10385,
    Code: '4133',
    Name: 'Clerk, control, material'
  },
  {
    Id: 10386,
    Code: '4133',
    Name: 'Clerk, control, stock'
  },
  {
    Id: 10387,
    Code: '4133',
    Name: 'Clerk, depot'
  },
  {
    Id: 10388,
    Code: '4133',
    Name: 'Clerk, despatch'
  },
  {
    Id: 10389,
    Code: '4133',
    Name: 'Clerk, goods-in'
  },
  {
    Id: 10390,
    Code: '4133',
    Name: 'Clerk, in, goods'
  },
  {
    Id: 10391,
    Code: '4133',
    Name: 'Clerk, inventory'
  },
  {
    Id: 10392,
    Code: '4133',
    Name: 'Clerk, inward, goods'
  },
  {
    Id: 10393,
    Code: '4133',
    Name: 'Clerk, inwards, goods'
  },
  {
    Id: 10394,
    Code: '4133',
    Name: 'Clerk, order'
  },
  {
    Id: 10395,
    Code: '4133',
    Name: 'Clerk, pricing'
  },
  {
    Id: 10396,
    Code: '4133',
    Name: 'Clerk, processing, order'
  },
  {
    Id: 10397,
    Code: '4133',
    Name: 'Clerk, production'
  },
  {
    Id: 10398,
    Code: '4133',
    Name: 'Clerk, progress'
  },
  {
    Id: 10399,
    Code: '4133',
    Name: 'Clerk, records, stock'
  },
  {
    Id: 10400,
    Code: '4133',
    Name: 'Clerk, records, stores'
  },
  {
    Id: 10401,
    Code: '4133',
    Name: 'Clerk, stock'
  },
  {
    Id: 10402,
    Code: '4133',
    Name: 'Clerk, stockroom'
  },
  {
    Id: 10403,
    Code: '4133',
    Name: "Clerk, storekeeper's"
  },
  {
    Id: 10404,
    Code: '4133',
    Name: 'Clerk, stores'
  },
  {
    Id: 10405,
    Code: '4133',
    Name: 'Clerk, tally, timber'
  },
  {
    Id: 10406,
    Code: '4133',
    Name: 'Clerk, warehouse'
  },
  {
    Id: 10407,
    Code: '4133',
    Name: 'Clerk-storekeeper'
  },
  {
    Id: 10408,
    Code: '4133',
    Name: 'Clerk-storeman'
  },
  {
    Id: 10409,
    Code: '4133',
    Name: 'Compiler, order'
  },
  {
    Id: 10410,
    Code: '4133',
    Name: 'Controller, accounting, stock'
  },
  {
    Id: 10411,
    Code: '4133',
    Name: 'Controller, depot'
  },
  {
    Id: 10412,
    Code: '4133',
    Name: 'Controller, despatch'
  },
  {
    Id: 10413,
    Code: '4133',
    Name: 'Controller, goods'
  },
  {
    Id: 10414,
    Code: '4133',
    Name: 'Controller, inventory'
  },
  {
    Id: 10415,
    Code: '4133',
    Name: 'Controller, manufacturing'
  },
  {
    Id: 10416,
    Code: '4133',
    Name: 'Controller, material'
  },
  {
    Id: 10417,
    Code: '4133',
    Name: 'Controller, materials'
  },
  {
    Id: 10418,
    Code: '4133',
    Name: 'Controller, MRP'
  },
  {
    Id: 10419,
    Code: '4133',
    Name: 'Controller, order'
  },
  {
    Id: 10420,
    Code: '4133',
    Name: 'Controller, pallet'
  },
  {
    Id: 10421,
    Code: '4133',
    Name: 'Controller, parts'
  },
  {
    Id: 10422,
    Code: '4133',
    Name: 'Controller, planning'
  },
  {
    Id: 10423,
    Code: '4133',
    Name: 'Controller, programme'
  },
  {
    Id: 10424,
    Code: '4133',
    Name: 'Controller, room, stock'
  },
  {
    Id: 10425,
    Code: '4133',
    Name: 'Controller, sabre'
  },
  {
    Id: 10426,
    Code: '4133',
    Name: 'Controller, shift'
  },
  {
    Id: 10427,
    Code: '4133',
    Name: 'Controller, shop, metal trades'
  },
  {
    Id: 10428,
    Code: '4133',
    Name: 'Controller, spares'
  },
  {
    Id: 10429,
    Code: '4133',
    Name: 'Controller, stock'
  },
  {
    Id: 10430,
    Code: '4133',
    Name: 'Controller, stores'
  },
  {
    Id: 10431,
    Code: '4133',
    Name: 'Controller, supplies'
  },
  {
    Id: 10432,
    Code: '4133',
    Name: 'Controller, supply'
  },
  {
    Id: 10433,
    Code: '4133',
    Name: 'Controller, warehouse'
  },
  {
    Id: 10434,
    Code: '4133',
    Name: 'Controller, works'
  },
  {
    Id: 10435,
    Code: '4133',
    Name: 'Coordinator, chain, supply'
  },
  {
    Id: 10436,
    Code: '4133',
    Name: 'Coordinator, goods-in'
  },
  {
    Id: 10437,
    Code: '4133',
    Name: 'Coordinator, materials'
  },
  {
    Id: 10438,
    Code: '4133',
    Name: 'Coordinator, spares'
  },
  {
    Id: 10439,
    Code: '4133',
    Name: 'Coordinator, stores'
  },
  {
    Id: 10440,
    Code: '4133',
    Name: 'Coordinator, warehouse'
  },
  {
    Id: 10441,
    Code: '4133',
    Name: 'Engineer, spares'
  },
  {
    Id: 10442,
    Code: '4133',
    Name: 'Inspector, cargo'
  },
  {
    Id: 10443,
    Code: '4133',
    Name: 'Inspector, depot'
  },
  {
    Id: 10444,
    Code: '4133',
    Name: 'Inspector, despatch'
  },
  {
    Id: 10445,
    Code: '4133',
    Name: 'Inspector, inwards, goods'
  },
  {
    Id: 10446,
    Code: '4133',
    Name: 'Inspector, receiving, goods'
  },
  {
    Id: 10447,
    Code: '4133',
    Name: 'Inspector, stores, chief'
  },
  {
    Id: 10448,
    Code: '4133',
    Name: 'Inspector, stores'
  },
  {
    Id: 10449,
    Code: '4133',
    Name: 'Inspector of storehousemen'
  },
  {
    Id: 10450,
    Code: '4133',
    Name: 'Keeper, store, chief'
  },
  {
    Id: 10451,
    Code: '4133',
    Name: 'Leader, team, control, stock'
  },
  {
    Id: 10452,
    Code: '4133',
    Name: 'Man, sample'
  },
  {
    Id: 10453,
    Code: '4133',
    Name: 'Manager, line, front, warehousing'
  },
  {
    Id: 10454,
    Code: '4133',
    Name: 'Manager, room, pattern'
  },
  {
    Id: 10455,
    Code: '4133',
    Name: 'Manager, room, stock'
  },
  {
    Id: 10456,
    Code: '4133',
    Name: 'Manager, spares'
  },
  {
    Id: 10457,
    Code: '4133',
    Name: 'Master, quarter'
  },
  {
    Id: 10458,
    Code: '4133',
    Name: 'Officer, control, materials, coal mine'
  },
  {
    Id: 10459,
    Code: '4133',
    Name: 'Officer, control, stock'
  },
  {
    Id: 10460,
    Code: '4133',
    Name: 'Officer, distribution, coal mine'
  },
  {
    Id: 10461,
    Code: '4133',
    Name: 'Officer, fuel'
  },
  {
    Id: 10462,
    Code: '4133',
    Name: 'Officer, loans, medical'
  },
  {
    Id: 10463,
    Code: '4133',
    Name: 'Officer, materials'
  },
  {
    Id: 10464,
    Code: '4133',
    Name: 'Officer, store, assistant, MOD'
  },
  {
    Id: 10465,
    Code: '4133',
    Name: 'Officer, store, MOD'
  },
  {
    Id: 10466,
    Code: '4133',
    Name: 'Officer, stores, grade I, MOD'
  },
  {
    Id: 10467,
    Code: '4133',
    Name: 'Officer, stores, MOD'
  },
  {
    Id: 10468,
    Code: '4133',
    Name: 'Officer, stores'
  },
  {
    Id: 10469,
    Code: '4133',
    Name: 'Officer, supply, fuel'
  },
  {
    Id: 10470,
    Code: '4133',
    Name: 'Officer, supply, chemical mfr'
  },
  {
    Id: 10471,
    Code: '4133',
    Name: 'Officer, supply, engineering'
  },
  {
    Id: 10472,
    Code: '4133',
    Name: 'Operator, depot'
  },
  {
    Id: 10473,
    Code: '4133',
    Name: 'Operator, entry, order'
  },
  {
    Id: 10474,
    Code: '4133',
    Name: 'Planner, material'
  },
  {
    Id: 10475,
    Code: '4133',
    Name: 'Planner, materials'
  },
  {
    Id: 10476,
    Code: '4133',
    Name: 'Planner, progress'
  },
  {
    Id: 10477,
    Code: '4133',
    Name: 'Processor, order, sales'
  },
  {
    Id: 10478,
    Code: '4133',
    Name: 'Processor, order'
  },
  {
    Id: 10479,
    Code: '4133',
    Name: 'Processor, stock'
  },
  {
    Id: 10480,
    Code: '4133',
    Name: 'Quartermaster'
  },
  {
    Id: 10481,
    Code: '4133',
    Name: 'Recorder, stock'
  },
  {
    Id: 10482,
    Code: '4133',
    Name: 'Salesman, powder, mining'
  },
  {
    Id: 10483,
    Code: '4133',
    Name: 'Scheduler, materials'
  },
  {
    Id: 10484,
    Code: '4133',
    Name: 'Stocktaker, chief, steelworks'
  },
  {
    Id: 10485,
    Code: '4133',
    Name: 'Stocktaker'
  },
  {
    Id: 10486,
    Code: '4133',
    Name: 'Superintendent, cargo'
  },
  {
    Id: 10487,
    Code: '4133',
    Name: 'Superintendent, depot'
  },
  {
    Id: 10488,
    Code: '4133',
    Name: 'Superintendent, progress'
  },
  {
    Id: 10489,
    Code: '4133',
    Name: 'Superintendent, store'
  },
  {
    Id: 10490,
    Code: '4133',
    Name: 'Superintendent, stores'
  },
  {
    Id: 10491,
    Code: '4133',
    Name: 'Superintendent, supplies, chief, PO'
  },
  {
    Id: 10492,
    Code: '4133',
    Name: 'Superintendent, supplies, PO'
  },
  {
    Id: 10493,
    Code: '4133',
    Name: 'Superintendent, warehouse'
  },
  {
    Id: 10494,
    Code: '4133',
    Name: 'Superintendent, storage'
  },
  {
    Id: 10495,
    Code: '4133',
    Name: 'Supervisor, control, stock'
  },
  {
    Id: 10496,
    Code: '4133',
    Name: 'Supervisor, goods-in'
  },
  {
    Id: 10497,
    Code: '4133',
    Name: 'Supervisor, inwards, goods'
  },
  {
    Id: 10498,
    Code: '4133',
    Name: 'Supervisor, order'
  },
  {
    Id: 10499,
    Code: '4133',
    Name: 'Supervisor, parts'
  },
  {
    Id: 10500,
    Code: '4133',
    Name: 'Supervisor, room, stock'
  },
  {
    Id: 10501,
    Code: '4133',
    Name: 'Supervisor, stock'
  },
  {
    Id: 10502,
    Code: '4133',
    Name: 'Supervisor, tool'
  },
  {
    Id: 10503,
    Code: '4133',
    Name: 'Supplier, material'
  },
  {
    Id: 10504,
    Code: '4133',
    Name: 'Supplier, parts'
  },
  {
    Id: 10505,
    Code: '4133',
    Name: 'Taker, stock, chief, steelworks'
  },
  {
    Id: 10506,
    Code: '4133',
    Name: 'Taker, stock'
  },
  {
    Id: 10507,
    Code: '4134',
    Name: 'Administrator, distribution'
  },
  {
    Id: 10508,
    Code: '4134',
    Name: 'Administrator, fleet, car'
  },
  {
    Id: 10509,
    Code: '4134',
    Name: 'Administrator, fleet'
  },
  {
    Id: 10510,
    Code: '4134',
    Name: 'Administrator, freight'
  },
  {
    Id: 10511,
    Code: '4134',
    Name: 'Administrator, logistics'
  },
  {
    Id: 10512,
    Code: '4134',
    Name: 'Administrator, services, fleet'
  },
  {
    Id: 10513,
    Code: '4134',
    Name: 'Administrator, shipping'
  },
  {
    Id: 10514,
    Code: '4134',
    Name: 'Administrator, transport'
  },
  {
    Id: 10515,
    Code: '4134',
    Name: 'Agent, cargo, airport'
  },
  {
    Id: 10516,
    Code: '4134',
    Name: 'Agent, cargo'
  },
  {
    Id: 10517,
    Code: '4134',
    Name: 'Agent, forwarding'
  },
  {
    Id: 10518,
    Code: '4134',
    Name: 'Agent, operations, cargo'
  },
  {
    Id: 10519,
    Code: '4134',
    Name: 'Assistant, logistics'
  },
  {
    Id: 10520,
    Code: '4134',
    Name: 'Assistant, shipping'
  },
  {
    Id: 10521,
    Code: '4134',
    Name: 'Assistant, traffic'
  },
  {
    Id: 10522,
    Code: '4134',
    Name: 'Assistant, transport'
  },
  {
    Id: 10523,
    Code: '4134',
    Name: 'Checker, petroleum distribution'
  },
  {
    Id: 10524,
    Code: '4134',
    Name: 'Checker, transport'
  },
  {
    Id: 10525,
    Code: '4134',
    Name: 'Checker-loader'
  },
  {
    Id: 10526,
    Code: '4134',
    Name: 'Clerk, agency, ships'
  },
  {
    Id: 10527,
    Code: '4134',
    Name: 'Clerk, cargo'
  },
  {
    Id: 10528,
    Code: '4134',
    Name: 'Clerk, chartering, sea transport'
  },
  {
    Id: 10529,
    Code: '4134',
    Name: 'Clerk, control, load, aircraft'
  },
  {
    Id: 10530,
    Code: '4134',
    Name: 'Clerk, distribution'
  },
  {
    Id: 10531,
    Code: '4134',
    Name: 'Clerk, documentation'
  },
  {
    Id: 10532,
    Code: '4134',
    Name: 'Clerk, export'
  },
  {
    Id: 10533,
    Code: '4134',
    Name: 'Clerk, forwarding'
  },
  {
    Id: 10534,
    Code: '4134',
    Name: 'Clerk, freight'
  },
  {
    Id: 10535,
    Code: '4134',
    Name: 'Clerk, import'
  },
  {
    Id: 10536,
    Code: '4134',
    Name: 'Clerk, logistics'
  },
  {
    Id: 10537,
    Code: '4134',
    Name: 'Clerk, office, traffic'
  },
  {
    Id: 10538,
    Code: '4134',
    Name: 'Clerk, operations, transport, distribution'
  },
  {
    Id: 10539,
    Code: '4134',
    Name: 'Clerk, planning, route, transport, distribution'
  },
  {
    Id: 10540,
    Code: '4134',
    Name: 'Clerk, principal, PLA'
  },
  {
    Id: 10541,
    Code: '4134',
    Name: 'Clerk, routeing'
  },
  {
    Id: 10542,
    Code: '4134',
    Name: 'Clerk, schedules, transport services'
  },
  {
    Id: 10543,
    Code: '4134',
    Name: 'Clerk, shipping'
  },
  {
    Id: 10544,
    Code: '4134',
    Name: 'Clerk, systems, warehousing'
  },
  {
    Id: 10545,
    Code: '4134',
    Name: 'Clerk, traffic'
  },
  {
    Id: 10546,
    Code: '4134',
    Name: 'Clerk, transport'
  },
  {
    Id: 10547,
    Code: '4134',
    Name: 'Clerk, transportation'
  },
  {
    Id: 10548,
    Code: '4134',
    Name: 'Controller, cargo'
  },
  {
    Id: 10549,
    Code: '4134',
    Name: 'Controller, container'
  },
  {
    Id: 10550,
    Code: '4134',
    Name: 'Controller, distribution'
  },
  {
    Id: 10551,
    Code: '4134',
    Name: 'Controller, fleet'
  },
  {
    Id: 10552,
    Code: '4134',
    Name: 'Controller, freight'
  },
  {
    Id: 10553,
    Code: '4134',
    Name: 'Controller, load, aircraft'
  },
  {
    Id: 10554,
    Code: '4134',
    Name: 'Controller, logistics'
  },
  {
    Id: 10555,
    Code: '4134',
    Name: 'Controller, operations, transport'
  },
  {
    Id: 10556,
    Code: '4134',
    Name: 'Controller, ship'
  },
  {
    Id: 10557,
    Code: '4134',
    Name: 'Controller, shipping'
  },
  {
    Id: 10558,
    Code: '4134',
    Name: 'Controller, traffic'
  },
  {
    Id: 10559,
    Code: '4134',
    Name: 'Controller, transport'
  },
  {
    Id: 10560,
    Code: '4134',
    Name: 'Controller, truck'
  },
  {
    Id: 10561,
    Code: '4134',
    Name: 'Controller of distribution'
  },
  {
    Id: 10562,
    Code: '4134',
    Name: 'Coordinator, despatch'
  },
  {
    Id: 10563,
    Code: '4134',
    Name: 'Coordinator, distribution'
  },
  {
    Id: 10564,
    Code: '4134',
    Name: 'Coordinator, logistics'
  },
  {
    Id: 10565,
    Code: '4134',
    Name: 'Coordinator, operations, shipping'
  },
  {
    Id: 10566,
    Code: '4134',
    Name: 'Coordinator, shipping'
  },
  {
    Id: 10567,
    Code: '4134',
    Name: 'Coordinator, traffic'
  },
  {
    Id: 10568,
    Code: '4134',
    Name: 'Coordinator, transport'
  },
  {
    Id: 10569,
    Code: '4134',
    Name: 'Dispatcher, traffic'
  },
  {
    Id: 10570,
    Code: '4134',
    Name: 'Foreman, distribution, warehousing'
  },
  {
    Id: 10571,
    Code: '4134',
    Name: 'Forwarder, freight'
  },
  {
    Id: 10572,
    Code: '4134',
    Name: 'Handler, traffic'
  },
  {
    Id: 10573,
    Code: '4134',
    Name: 'Officer, cargo'
  },
  {
    Id: 10574,
    Code: '4134',
    Name: 'Officer, control, traffic'
  },
  {
    Id: 10575,
    Code: '4134',
    Name: 'Officer, crewing'
  },
  {
    Id: 10576,
    Code: '4134',
    Name: 'Officer, distribution'
  },
  {
    Id: 10577,
    Code: '4134',
    Name: 'Officer, duty, cargo'
  },
  {
    Id: 10578,
    Code: '4134',
    Name: 'Officer, duty, airport'
  },
  {
    Id: 10579,
    Code: '4134',
    Name: 'Officer, executive, PLA'
  },
  {
    Id: 10580,
    Code: '4134',
    Name: 'Officer, freight, air transport'
  },
  {
    Id: 10581,
    Code: '4134',
    Name: 'Officer, logistics'
  },
  {
    Id: 10582,
    Code: '4134',
    Name: 'Officer, planning, route, airlines'
  },
  {
    Id: 10583,
    Code: '4134',
    Name: 'Officer, port'
  },
  {
    Id: 10584,
    Code: '4134',
    Name: 'Officer, regional, railways'
  },
  {
    Id: 10585,
    Code: '4134',
    Name: 'Officer, shipping, coal mine'
  },
  {
    Id: 10586,
    Code: '4134',
    Name: 'Officer, station, airport'
  },
  {
    Id: 10587,
    Code: '4134',
    Name: 'Officer, traffic, airlines'
  },
  {
    Id: 10588,
    Code: '4134',
    Name: 'Officer, traffic, port authority'
  },
  {
    Id: 10589,
    Code: '4134',
    Name: 'Officer, traffic, road haulage'
  },
  {
    Id: 10590,
    Code: '4134',
    Name: 'Officer, transport'
  },
  {
    Id: 10591,
    Code: '4134',
    Name: 'Operator, traffic'
  },
  {
    Id: 10592,
    Code: '4134',
    Name: 'Organiser, meals-on-wheels'
  },
  {
    Id: 10593,
    Code: '4134',
    Name: 'Organiser, transport'
  },
  {
    Id: 10594,
    Code: '4134',
    Name: 'Planner, load, haulage, road'
  },
  {
    Id: 10595,
    Code: '4134',
    Name: 'Planner, logistics, transport, distribution'
  },
  {
    Id: 10596,
    Code: '4134',
    Name: 'Planner, route, transport, distribution'
  },
  {
    Id: 10597,
    Code: '4134',
    Name: 'Planner, ship'
  },
  {
    Id: 10598,
    Code: '4134',
    Name: 'Router, bus, public transport'
  },
  {
    Id: 10599,
    Code: '4134',
    Name: 'Router, freight transport'
  },
  {
    Id: 10600,
    Code: '4134',
    Name: 'Scheduler, transport'
  },
  {
    Id: 10601,
    Code: '4134',
    Name: 'Superintendent, flight'
  },
  {
    Id: 10602,
    Code: '4134',
    Name: 'Superintendent, shipping'
  },
  {
    Id: 10603,
    Code: '4134',
    Name: 'Supervisor, cargo'
  },
  {
    Id: 10604,
    Code: '4134',
    Name: 'Supervisor, freight'
  },
  {
    Id: 10605,
    Code: '4134',
    Name: 'Supervisor, logistics'
  },
  {
    Id: 10606,
    Code: '4134',
    Name: 'Supervisor, meals-on-wheels'
  },
  {
    Id: 10607,
    Code: '4134',
    Name: 'Supervisor, shipping'
  },
  {
    Id: 10608,
    Code: '4134',
    Name: 'Supervisor, traffic'
  },
  {
    Id: 10609,
    Code: '4135',
    Name: 'Abstractor, press cuttings'
  },
  {
    Id: 10610,
    Code: '4135',
    Name: 'Agent, cutting, press'
  },
  {
    Id: 10611,
    Code: '4135',
    Name: 'Assistant, archive'
  },
  {
    Id: 10612,
    Code: '4135',
    Name: 'Assistant, archives'
  },
  {
    Id: 10613,
    Code: '4135',
    Name: 'Assistant, bibliographic'
  },
  {
    Id: 10614,
    Code: '4135',
    Name: 'Assistant, chief, library'
  },
  {
    Id: 10615,
    Code: '4135',
    Name: 'Assistant, clerical, library'
  },
  {
    Id: 10616,
    Code: '4135',
    Name: 'Assistant, counter, library'
  },
  {
    Id: 10617,
    Code: '4135',
    Name: 'Assistant, information, educational establishments'
  },
  {
    Id: 10618,
    Code: '4135',
    Name: 'Assistant, information, library'
  },
  {
    Id: 10619,
    Code: '4135',
    Name: 'Assistant, library'
  },
  {
    Id: 10620,
    Code: '4135',
    Name: 'Assistant, resource, learning'
  },
  {
    Id: 10621,
    Code: '4135',
    Name: 'Assistant, services, bibliographic'
  },
  {
    Id: 10622,
    Code: '4135',
    Name: 'Assistant, shelving, library'
  },
  {
    Id: 10623,
    Code: '4135',
    Name: 'Assistant, shelving, library'
  },
  {
    Id: 10624,
    Code: '4135',
    Name: 'Assistant, library'
  },
  {
    Id: 10625,
    Code: '4135',
    Name: 'Attendant, library'
  },
  {
    Id: 10626,
    Code: '4135',
    Name: 'Clerk, cuttings, press'
  },
  {
    Id: 10627,
    Code: '4135',
    Name: 'Clerk, library'
  },
  {
    Id: 10628,
    Code: '4135',
    Name: 'Clerk, library'
  },
  {
    Id: 10629,
    Code: '4135',
    Name: 'Clipper, press, press cutting agency'
  },
  {
    Id: 10630,
    Code: '4135',
    Name: 'Facilitator, resource, learning, library'
  },
  {
    Id: 10631,
    Code: '4135',
    Name: 'Facilitator, resources, learning, library'
  },
  {
    Id: 10632,
    Code: '4135',
    Name: 'Looker-out, book'
  },
  {
    Id: 10633,
    Code: '4135',
    Name: 'Reader, press cutting agency'
  },
  {
    Id: 10634,
    Code: '4135',
    Name: 'Supervisor, library'
  },
  {
    Id: 10635,
    Code: '4136',
    Name: 'Administrator, course'
  },
  {
    Id: 10636,
    Code: '4136',
    Name: 'Administrator, education, health authority'
  },
  {
    Id: 10637,
    Code: '4136',
    Name: 'Administrator, HR'
  },
  {
    Id: 10638,
    Code: '4136',
    Name: 'Administrator, people'
  },
  {
    Id: 10639,
    Code: '4136',
    Name: 'Administrator, personnel'
  },
  {
    Id: 10640,
    Code: '4136',
    Name: 'Administrator, recruitment'
  },
  {
    Id: 10641,
    Code: '4136',
    Name: 'Administrator, resources, human'
  },
  {
    Id: 10642,
    Code: '4136',
    Name: 'Administrator, services, HR'
  },
  {
    Id: 10643,
    Code: '4136',
    Name: 'Administrator, training'
  },
  {
    Id: 10644,
    Code: '4136',
    Name: 'Administrator, human resources'
  },
  {
    Id: 10645,
    Code: '4136',
    Name: 'Administrator, recruitment agency'
  },
  {
    Id: 10646,
    Code: '4136',
    Name: 'Administrator, training agency'
  },
  {
    Id: 10647,
    Code: '4136',
    Name: 'Assistant, admin, HR'
  },
  {
    Id: 10648,
    Code: '4136',
    Name: 'Assistant, admin, human resources'
  },
  {
    Id: 10649,
    Code: '4136',
    Name: 'Assistant, admin, recruitment agency'
  },
  {
    Id: 10650,
    Code: '4136',
    Name: 'Assistant, admin, training agency'
  },
  {
    Id: 10651,
    Code: '4136',
    Name: 'Assistant, administration, HR'
  },
  {
    Id: 10652,
    Code: '4136',
    Name: 'Assistant, administration, human resources'
  },
  {
    Id: 10653,
    Code: '4136',
    Name: 'Assistant, administration, recruitment agency'
  },
  {
    Id: 10654,
    Code: '4136',
    Name: 'Assistant, administration, training agency'
  },
  {
    Id: 10655,
    Code: '4136',
    Name: 'Assistant, administrative, human resources'
  },
  {
    Id: 10656,
    Code: '4136',
    Name: 'Assistant, administrative, recruitment agency'
  },
  {
    Id: 10657,
    Code: '4136',
    Name: 'Assistant, administrative, training agency'
  },
  {
    Id: 10658,
    Code: '4136',
    Name: 'Assistant, training'
  },
  {
    Id: 10659,
    Code: '4136',
    Name: 'Clerk, office, personnel'
  },
  {
    Id: 10660,
    Code: '4136',
    Name: 'Clerk, people'
  },
  {
    Id: 10661,
    Code: '4136',
    Name: 'Clerk, personnel'
  },
  {
    Id: 10662,
    Code: '4136',
    Name: 'Officer, admin, human resources'
  },
  {
    Id: 10663,
    Code: '4136',
    Name: 'Officer, admin, recruitment agency'
  },
  {
    Id: 10664,
    Code: '4136',
    Name: 'Officer, admin, training agency'
  },
  {
    Id: 10665,
    Code: '4136',
    Name: 'Officer, administration, staff'
  },
  {
    Id: 10666,
    Code: '4136',
    Name: 'Officer, administration, human resources'
  },
  {
    Id: 10667,
    Code: '4136',
    Name: 'Officer, administration, recruitment agency'
  },
  {
    Id: 10668,
    Code: '4136',
    Name: 'Officer, administration, training agency'
  },
  {
    Id: 10669,
    Code: '4136',
    Name: 'Officer, administrative, human resources'
  },
  {
    Id: 10670,
    Code: '4136',
    Name: 'Officer, administrative, recruitment agency'
  },
  {
    Id: 10671,
    Code: '4136',
    Name: 'Officer, administrative, training agency'
  },
  {
    Id: 10672,
    Code: '4136',
    Name: 'Officer, support, HR'
  },
  {
    Id: 10673,
    Code: '4136',
    Name: 'Organiser, vocational training'
  },
  {
    Id: 10674,
    Code: '4141',
    Name: 'Head of administration'
  },
  {
    Id: 10675,
    Code: '4141',
    Name: 'Manager, admin'
  },
  {
    Id: 10676,
    Code: '4141',
    Name: 'Manager, administration, sales'
  },
  {
    Id: 10677,
    Code: '4141',
    Name: 'Manager, administration'
  },
  {
    Id: 10678,
    Code: '4141',
    Name: 'Manager, administrative'
  },
  {
    Id: 10679,
    Code: '4141',
    Name: 'Manager, agency, ticket'
  },
  {
    Id: 10680,
    Code: '4141',
    Name: 'Manager, booking'
  },
  {
    Id: 10681,
    Code: '4141',
    Name: 'Manager, branch, entertainment ticket agency'
  },
  {
    Id: 10682,
    Code: '4141',
    Name: 'Manager, branch, private employment agency'
  },
  {
    Id: 10683,
    Code: '4141',
    Name: 'Manager, copyright'
  },
  {
    Id: 10684,
    Code: '4141',
    Name: 'Manager, court'
  },
  {
    Id: 10685,
    Code: '4141',
    Name: 'Manager, crews, train'
  },
  {
    Id: 10686,
    Code: '4141',
    Name: 'Manager, district, census'
  },
  {
    Id: 10687,
    Code: '4141',
    Name: 'Manager, district, electricity supplier'
  },
  {
    Id: 10688,
    Code: '4141',
    Name: 'Manager, district, gas supplier'
  },
  {
    Id: 10689,
    Code: '4141',
    Name: 'Manager, document'
  },
  {
    Id: 10690,
    Code: '4141',
    Name: 'Manager, documentation'
  },
  {
    Id: 10691,
    Code: '4141',
    Name: 'Manager, hall, church'
  },
  {
    Id: 10692,
    Code: '4141',
    Name: 'Manager, mailroom'
  },
  {
    Id: 10693,
    Code: '4141',
    Name: 'Manager, office, box'
  },
  {
    Id: 10694,
    Code: '4141',
    Name: 'Manager, office, front, hotel'
  },
  {
    Id: 10695,
    Code: '4141',
    Name: 'Manager, office, front, hotel'
  },
  {
    Id: 10696,
    Code: '4141',
    Name: 'Manager, office, insurance'
  },
  {
    Id: 10697,
    Code: '4141',
    Name: 'Manager, office, printing, PO'
  },
  {
    Id: 10698,
    Code: '4141',
    Name: 'Manager, office, sales'
  },
  {
    Id: 10699,
    Code: '4141',
    Name: 'Manager, office, school, educational establishments'
  },
  {
    Id: 10700,
    Code: '4141',
    Name: 'Manager, office, credit control'
  },
  {
    Id: 10701,
    Code: '4141',
    Name: 'Manager, office'
  },
  {
    Id: 10702,
    Code: '4141',
    Name: 'Manager, practice'
  },
  {
    Id: 10703,
    Code: '4141',
    Name: 'Manager, processing, shift, postal distribution services'
  },
  {
    Id: 10704,
    Code: '4141',
    Name: 'Manager, records'
  },
  {
    Id: 10705,
    Code: '4141',
    Name: 'Manager, registry'
  },
  {
    Id: 10706,
    Code: '4141',
    Name: 'Manager, reprographics'
  },
  {
    Id: 10707,
    Code: '4141',
    Name: 'Manager, room, post'
  },
  {
    Id: 10708,
    Code: '4141',
    Name: 'Manager, service, witness'
  },
  {
    Id: 10709,
    Code: '4141',
    Name: 'Manager, services, office'
  },
  {
    Id: 10710,
    Code: '4141',
    Name: 'Manager, support, business'
  },
  {
    Id: 10711,
    Code: '4141',
    Name: 'Manager, telephone'
  },
  {
    Id: 10712,
    Code: '4141',
    Name: 'Manager, typing'
  },
  {
    Id: 10713,
    Code: '4141',
    Name: 'Manager, warranty'
  },
  {
    Id: 10714,
    Code: '4141',
    Name: 'Manager, employment agency: private'
  },
  {
    Id: 10715,
    Code: '4142',
    Name: 'Administrator, lead'
  },
  {
    Id: 10716,
    Code: '4142',
    Name: 'Assistant, senior'
  },
  {
    Id: 10717,
    Code: '4142',
    Name: 'Clerk, chief'
  },
  {
    Id: 10718,
    Code: '4142',
    Name: 'Clerk, grade, higher'
  },
  {
    Id: 10719,
    Code: '4142',
    Name: 'Clerk, head'
  },
  {
    Id: 10720,
    Code: '4142',
    Name: 'Clerk, supervising'
  },
  {
    Id: 10721,
    Code: '4142',
    Name: 'Clerk-in-charge'
  },
  {
    Id: 10722,
    Code: '4142',
    Name: 'Controller, office'
  },
  {
    Id: 10723,
    Code: '4142',
    Name: 'Controller of typists'
  },
  {
    Id: 10724,
    Code: '4142',
    Name: 'Coordinator, office'
  },
  {
    Id: 10725,
    Code: '4142',
    Name: 'Executive, postal, grade C, PO'
  },
  {
    Id: 10726,
    Code: '4142',
    Name: 'Executive, support, sales'
  },
  {
    Id: 10727,
    Code: '4142',
    Name: 'Foreman, progress'
  },
  {
    Id: 10728,
    Code: '4142',
    Name: 'Head, section, clerical'
  },
  {
    Id: 10729,
    Code: '4142',
    Name: 'Leader, section, clerical'
  },
  {
    Id: 10730,
    Code: '4142',
    Name: 'Leader, section, progress'
  },
  {
    Id: 10731,
    Code: '4142',
    Name: 'Leader, shift, computer'
  },
  {
    Id: 10732,
    Code: '4142',
    Name: 'Leader, team, admin'
  },
  {
    Id: 10733,
    Code: '4142',
    Name: 'Leader, team, administration, sales'
  },
  {
    Id: 10734,
    Code: '4142',
    Name: 'Leader, team, administration'
  },
  {
    Id: 10735,
    Code: '4142',
    Name: 'Leader, team, administrator, sales'
  },
  {
    Id: 10736,
    Code: '4142',
    Name: 'Leader, team, census'
  },
  {
    Id: 10737,
    Code: '4142',
    Name: 'Leader, team, facilities'
  },
  {
    Id: 10738,
    Code: '4142',
    Name: 'Leader, team, finance'
  },
  {
    Id: 10739,
    Code: '4142',
    Name: 'Leader, team, payable, accounts'
  },
  {
    Id: 10740,
    Code: '4142',
    Name: 'Leader, team, preparation, data'
  },
  {
    Id: 10741,
    Code: '4142',
    Name: 'Leader, team, receivable, accounts'
  },
  {
    Id: 10742,
    Code: '4142',
    Name: 'Leader, team, support, business'
  },
  {
    Id: 10743,
    Code: '4142',
    Name: 'Manager, route'
  },
  {
    Id: 10744,
    Code: '4142',
    Name: 'Officer, executive, PO'
  },
  {
    Id: 10745,
    Code: '4142',
    Name: 'Overseer, PO'
  },
  {
    Id: 10746,
    Code: '4142',
    Name: 'Superintendent, office'
  },
  {
    Id: 10747,
    Code: '4142',
    Name: 'Superintendent, motoring organisation'
  },
  {
    Id: 10748,
    Code: '4142',
    Name: 'Supervisor, admin'
  },
  {
    Id: 10749,
    Code: '4142',
    Name: 'Supervisor, administration'
  },
  {
    Id: 10750,
    Code: '4142',
    Name: 'Supervisor, administrative'
  },
  {
    Id: 10751,
    Code: '4142',
    Name: 'Supervisor, betting'
  },
  {
    Id: 10752,
    Code: '4142',
    Name: 'Supervisor, clerical'
  },
  {
    Id: 10753,
    Code: '4142',
    Name: 'Supervisor, coding, clinical'
  },
  {
    Id: 10754,
    Code: '4142',
    Name: 'Supervisor, commercial'
  },
  {
    Id: 10755,
    Code: '4142',
    Name: 'Supervisor, control, credit'
  },
  {
    Id: 10756,
    Code: '4142',
    Name: 'Supervisor, credit'
  },
  {
    Id: 10757,
    Code: '4142',
    Name: 'Supervisor, distribution'
  },
  {
    Id: 10758,
    Code: '4142',
    Name: 'Supervisor, district, betting'
  },
  {
    Id: 10759,
    Code: '4142',
    Name: 'Supervisor, facilities'
  },
  {
    Id: 10760,
    Code: '4142',
    Name: 'Supervisor, finance'
  },
  {
    Id: 10761,
    Code: '4142',
    Name: 'Supervisor, import'
  },
  {
    Id: 10762,
    Code: '4142',
    Name: 'Supervisor, office, box'
  },
  {
    Id: 10763,
    Code: '4142',
    Name: 'Supervisor, office, cash'
  },
  {
    Id: 10764,
    Code: '4142',
    Name: 'Supervisor, office'
  },
  {
    Id: 10765,
    Code: '4142',
    Name: 'Supervisor, pools, football'
  },
  {
    Id: 10766,
    Code: '4142',
    Name: 'Supervisor, pricing'
  },
  {
    Id: 10767,
    Code: '4142',
    Name: 'Supervisor, progress'
  },
  {
    Id: 10768,
    Code: '4142',
    Name: 'Supervisor, purchasing'
  },
  {
    Id: 10769,
    Code: '4142',
    Name: 'Supervisor, QA'
  },
  {
    Id: 10770,
    Code: '4142',
    Name: 'Supervisor, receivable, accounts'
  },
  {
    Id: 10771,
    Code: '4142',
    Name: 'Supervisor, team, preparation, data'
  },
  {
    Id: 10772,
    Code: '4142',
    Name: 'Supervisor, warranty'
  },
  {
    Id: 10773,
    Code: '4142',
    Name: 'Supervisor, betting'
  },
  {
    Id: 10774,
    Code: '4143',
    Name: 'CSM'
  },
  {
    Id: 10775,
    Code: '4143',
    Name: 'Head of customer quality'
  },
  {
    Id: 10776,
    Code: '4143',
    Name: 'Head of customer services'
  },
  {
    Id: 10777,
    Code: '4143',
    Name: 'Manager, aftersales'
  },
  {
    Id: 10778,
    Code: '4143',
    Name: 'Manager, area, telephone'
  },
  {
    Id: 10779,
    Code: '4143',
    Name: 'Manager, business, customer service'
  },
  {
    Id: 10780,
    Code: '4143',
    Name: 'Manager, care, customer'
  },
  {
    Id: 10781,
    Code: '4143',
    Name: 'Manager, centre, call'
  },
  {
    Id: 10782,
    Code: '4143',
    Name: 'Manager, centre, contact'
  },
  {
    Id: 10783,
    Code: '4143',
    Name: 'Manager, complaints'
  },
  {
    Id: 10784,
    Code: '4143',
    Name: 'Manager, desk, help'
  },
  {
    Id: 10785,
    Code: '4143',
    Name: 'Manager, development, services, customer'
  },
  {
    Id: 10786,
    Code: '4143',
    Name: 'Manager, helpdesk'
  },
  {
    Id: 10787,
    Code: '4143',
    Name: 'Manager, hotline, regional'
  },
  {
    Id: 10788,
    Code: '4143',
    Name: 'Manager, liaison, customer'
  },
  {
    Id: 10789,
    Code: '4143',
    Name: 'Manager, relations, customer'
  },
  {
    Id: 10790,
    Code: '4143',
    Name: 'Manager, reservations, hotel'
  },
  {
    Id: 10791,
    Code: '4143',
    Name: 'Manager, sales, after'
  },
  {
    Id: 10792,
    Code: '4143',
    Name: 'Manager, service, customer'
  },
  {
    Id: 10793,
    Code: '4143',
    Name: 'Manager, service, passenger'
  },
  {
    Id: 10794,
    Code: '4143',
    Name: 'Manager, services, customer'
  },
  {
    Id: 10795,
    Code: '4143',
    Name: 'Manager, services, passenger'
  },
  {
    Id: 10796,
    Code: '4143',
    Name: 'Manager, support, customer'
  },
  {
    Id: 10797,
    Code: '4143',
    Name: 'Manager, team, service, customer'
  },
  {
    Id: 10798,
    Code: '4143',
    Name: 'Manager, team, call centre'
  },
  {
    Id: 10799,
    Code: '4143',
    Name: 'Manager of product support'
  },
  {
    Id: 10800,
    Code: '4151',
    Name: 'Administrator, contracts, sales'
  },
  {
    Id: 10801,
    Code: '4151',
    Name: 'Administrator, marketing'
  },
  {
    Id: 10802,
    Code: '4151',
    Name: 'Administrator, office, sales'
  },
  {
    Id: 10803,
    Code: '4151',
    Name: 'Administrator, order, sales'
  },
  {
    Id: 10804,
    Code: '4151',
    Name: 'Administrator, retail'
  },
  {
    Id: 10805,
    Code: '4151',
    Name: 'Administrator, sales, export'
  },
  {
    Id: 10806,
    Code: '4151',
    Name: 'Administrator, sales'
  },
  {
    Id: 10807,
    Code: '4151',
    Name: 'Administrator, sales and accounts'
  },
  {
    Id: 10808,
    Code: '4151',
    Name: 'Administrator, sales and marketing'
  },
  {
    Id: 10809,
    Code: '4151',
    Name: 'Administrator, support, sales'
  },
  {
    Id: 10810,
    Code: '4151',
    Name: 'Administrator, sales'
  },
  {
    Id: 10811,
    Code: '4151',
    Name: 'Assistant, admin, sales'
  },
  {
    Id: 10812,
    Code: '4151',
    Name: 'Assistant, administration, sales'
  },
  {
    Id: 10813,
    Code: '4151',
    Name: 'Assistant, administration, sales'
  },
  {
    Id: 10814,
    Code: '4151',
    Name: 'Assistant, administrative, sales'
  },
  {
    Id: 10815,
    Code: '4151',
    Name: 'Assistant, commercial, sales'
  },
  {
    Id: 10816,
    Code: '4151',
    Name: 'Assistant, sales, online'
  },
  {
    Id: 10817,
    Code: '4151',
    Name: 'Assistant, trading, eBay'
  },
  {
    Id: 10818,
    Code: '4151',
    Name: 'Clerk, administration, sales'
  },
  {
    Id: 10819,
    Code: '4151',
    Name: 'Clerk, office, sales'
  },
  {
    Id: 10820,
    Code: '4151',
    Name: 'Clerk, order, sales'
  },
  {
    Id: 10821,
    Code: '4151',
    Name: 'Clerk, sales'
  },
  {
    Id: 10822,
    Code: '4151',
    Name: 'Coordinator, sales'
  },
  {
    Id: 10823,
    Code: '4151',
    Name: 'Coordinator, sales and marketing'
  },
  {
    Id: 10824,
    Code: '4151',
    Name: 'Officer, admin, sales'
  },
  {
    Id: 10825,
    Code: '4151',
    Name: 'Officer, administration, sales'
  },
  {
    Id: 10826,
    Code: '4151',
    Name: 'Officer, administrative, sales'
  },
  {
    Id: 10827,
    Code: '4152',
    Name: 'Administrator, data'
  },
  {
    Id: 10828,
    Code: '4152',
    Name: 'Administrator, entry, data'
  },
  {
    Id: 10829,
    Code: '4152',
    Name: 'Assistant, computer'
  },
  {
    Id: 10830,
    Code: '4152',
    Name: 'Assistant, data'
  },
  {
    Id: 10831,
    Code: '4152',
    Name: 'Assistant, database'
  },
  {
    Id: 10832,
    Code: '4152',
    Name: 'Assistant, entry, data'
  },
  {
    Id: 10833,
    Code: '4152',
    Name: 'Assistant, input'
  },
  {
    Id: 10834,
    Code: '4152',
    Name: 'Capturer, data'
  },
  {
    Id: 10835,
    Code: '4152',
    Name: 'Clerk, capture, data'
  },
  {
    Id: 10836,
    Code: '4152',
    Name: 'Clerk, computer'
  },
  {
    Id: 10837,
    Code: '4152',
    Name: 'Clerk, control, data'
  },
  {
    Id: 10838,
    Code: '4152',
    Name: 'Clerk, entry, data'
  },
  {
    Id: 10839,
    Code: '4152',
    Name: 'Clerk, entry, order'
  },
  {
    Id: 10840,
    Code: '4152',
    Name: 'Clerk, input, data'
  },
  {
    Id: 10841,
    Code: '4152',
    Name: 'Clerk, input'
  },
  {
    Id: 10842,
    Code: '4152',
    Name: 'Clerk, processing, data'
  },
  {
    Id: 10843,
    Code: '4152',
    Name: 'Clerk, VDU'
  },
  {
    Id: 10844,
    Code: '4152',
    Name: 'Clerk-VDU operator'
  },
  {
    Id: 10845,
    Code: '4152',
    Name: 'Controller, data, computer'
  },
  {
    Id: 10846,
    Code: '4152',
    Name: 'Coordinator, data'
  },
  {
    Id: 10847,
    Code: '4152',
    Name: 'Inputter, computer'
  },
  {
    Id: 10848,
    Code: '4152',
    Name: 'Inputter, copy'
  },
  {
    Id: 10849,
    Code: '4152',
    Name: 'Inputter, data'
  },
  {
    Id: 10850,
    Code: '4152',
    Name: 'Inputter, database'
  },
  {
    Id: 10851,
    Code: '4152',
    Name: 'Inputter, text'
  },
  {
    Id: 10852,
    Code: '4152',
    Name: 'Inputter, data'
  },
  {
    Id: 10853,
    Code: '4152',
    Name: 'Keyer, entry, data'
  },
  {
    Id: 10854,
    Code: '4152',
    Name: 'Machinist, accounting'
  },
  {
    Id: 10855,
    Code: '4152',
    Name: 'Machinist, accounts'
  },
  {
    Id: 10856,
    Code: '4152',
    Name: 'Machinist, adding'
  },
  {
    Id: 10857,
    Code: '4152',
    Name: 'Machinist, addressing'
  },
  {
    Id: 10858,
    Code: '4152',
    Name: 'Machinist, book-keeping'
  },
  {
    Id: 10859,
    Code: '4152',
    Name: 'Machinist, calculating'
  },
  {
    Id: 10860,
    Code: '4152',
    Name: 'Officer, data, clinical'
  },
  {
    Id: 10861,
    Code: '4152',
    Name: 'Officer, entry, data'
  },
  {
    Id: 10862,
    Code: '4152',
    Name: 'Officer, preparation, data'
  },
  {
    Id: 10863,
    Code: '4152',
    Name: 'Officer, processing, data'
  },
  {
    Id: 10864,
    Code: '4152',
    Name: 'Operative, computer'
  },
  {
    Id: 10865,
    Code: '4152',
    Name: 'Operator, addressograph'
  },
  {
    Id: 10866,
    Code: '4152',
    Name: 'Operator, banda'
  },
  {
    Id: 10867,
    Code: '4152',
    Name: 'Operator, burster'
  },
  {
    Id: 10868,
    Code: '4152',
    Name: 'Operator, capture, data'
  },
  {
    Id: 10869,
    Code: '4152',
    Name: 'Operator, card, punch'
  },
  {
    Id: 10870,
    Code: '4152',
    Name: 'Operator, comptometer'
  },
  {
    Id: 10871,
    Code: '4152',
    Name: 'Operator, computer'
  },
  {
    Id: 10872,
    Code: '4152',
    Name: 'Operator, display, visual'
  },
  {
    Id: 10873,
    Code: '4152',
    Name: 'Operator, edit, tape'
  },
  {
    Id: 10874,
    Code: '4152',
    Name: 'Operator, entry, data'
  },
  {
    Id: 10875,
    Code: '4152',
    Name: 'Operator, flexowriter'
  },
  {
    Id: 10876,
    Code: '4152',
    Name: 'Operator, IBM'
  },
  {
    Id: 10877,
    Code: '4152',
    Name: 'Operator, ICL'
  },
  {
    Id: 10878,
    Code: '4152',
    Name: 'Operator, input, data'
  },
  {
    Id: 10879,
    Code: '4152',
    Name: 'Operator, kardex'
  },
  {
    Id: 10880,
    Code: '4152',
    Name: 'Operator, key, punch'
  },
  {
    Id: 10881,
    Code: '4152',
    Name: 'Operator, key-punch'
  },
  {
    Id: 10882,
    Code: '4152',
    Name: 'Operator, key-time'
  },
  {
    Id: 10883,
    Code: '4152',
    Name: 'Operator, key-to-disc'
  },
  {
    Id: 10884,
    Code: '4152',
    Name: 'Operator, keyboard'
  },
  {
    Id: 10885,
    Code: '4152',
    Name: 'Operator, NCR'
  },
  {
    Id: 10886,
    Code: '4152',
    Name: 'Operator, preparation, data'
  },
  {
    Id: 10887,
    Code: '4152',
    Name: 'Operator, punch, key'
  },
  {
    Id: 10888,
    Code: '4152',
    Name: 'Operator, punch'
  },
  {
    Id: 10889,
    Code: '4152',
    Name: 'Operator, rotaprint'
  },
  {
    Id: 10890,
    Code: '4152',
    Name: 'Operator, screen'
  },
  {
    Id: 10891,
    Code: '4152',
    Name: 'Operator, tabulator'
  },
  {
    Id: 10892,
    Code: '4152',
    Name: 'Operator, VDU'
  },
  {
    Id: 10893,
    Code: '4152',
    Name: 'Processor, data'
  },
  {
    Id: 10894,
    Code: '4152',
    Name: 'Supervisor, control, computer'
  },
  {
    Id: 10895,
    Code: '4152',
    Name: 'Supervisor, processing, data'
  },
  {
    Id: 10896,
    Code: '4152',
    Name: 'Supervisor, processing, word'
  },
  {
    Id: 10897,
    Code: '4152',
    Name: 'Technician, data'
  },
  {
    Id: 10898,
    Code: '4159',
    Name: 'Administrator, bank, hospital service'
  },
  {
    Id: 10899,
    Code: '4159',
    Name: 'Administrator, business'
  },
  {
    Id: 10900,
    Code: '4159',
    Name: 'Administrator, catering'
  },
  {
    Id: 10901,
    Code: '4159',
    Name: 'Administrator, centre, test'
  },
  {
    Id: 10902,
    Code: '4159',
    Name: 'Administrator, church'
  },
  {
    Id: 10903,
    Code: '4159',
    Name: 'Administrator, clerical'
  },
  {
    Id: 10904,
    Code: '4159',
    Name: 'Administrator, commercial'
  },
  {
    Id: 10905,
    Code: '4159',
    Name: 'Administrator, dental'
  },
  {
    Id: 10906,
    Code: '4159',
    Name: 'Administrator, engineering'
  },
  {
    Id: 10907,
    Code: '4159',
    Name: 'Administrator, facilities'
  },
  {
    Id: 10908,
    Code: '4159',
    Name: 'Administrator, farm'
  },
  {
    Id: 10909,
    Code: '4159',
    Name: 'Administrator, health and safety'
  },
  {
    Id: 10910,
    Code: '4159',
    Name: 'Administrator, home, care'
  },
  {
    Id: 10911,
    Code: '4159',
    Name: 'Administrator, home, care/residential home'
  },
  {
    Id: 10912,
    Code: '4159',
    Name: 'Administrator, housing'
  },
  {
    Id: 10913,
    Code: '4159',
    Name: 'Administrator, maintenance'
  },
  {
    Id: 10914,
    Code: '4159',
    Name: 'Administrator, NHS, clerical'
  },
  {
    Id: 10915,
    Code: '4159',
    Name: 'Administrator, nursery'
  },
  {
    Id: 10916,
    Code: '4159',
    Name: 'Administrator, office'
  },
  {
    Id: 10917,
    Code: '4159',
    Name: 'Administrator, parish'
  },
  {
    Id: 10918,
    Code: '4159',
    Name: 'Administrator, planning'
  },
  {
    Id: 10919,
    Code: '4159',
    Name: 'Administrator, property'
  },
  {
    Id: 10920,
    Code: '4159',
    Name: 'Administrator, QA'
  },
  {
    Id: 10921,
    Code: '4159',
    Name: 'Administrator, quality'
  },
  {
    Id: 10922,
    Code: '4159',
    Name: 'Administrator, service'
  },
  {
    Id: 10923,
    Code: '4159',
    Name: 'Administrator, services'
  },
  {
    Id: 10924,
    Code: '4159',
    Name: 'Administrator, support, business'
  },
  {
    Id: 10925,
    Code: '4159',
    Name: 'Administrator, warranty'
  },
  {
    Id: 10926,
    Code: '4159',
    Name: 'Administrator, workshop'
  },
  {
    Id: 10927,
    Code: '4159',
    Name: 'Administrator'
  },
  {
    Id: 10928,
    Code: '4159',
    Name: 'Administrator-receptionist'
  },
  {
    Id: 10929,
    Code: '4159',
    Name: 'Allocator, chalet'
  },
  {
    Id: 10930,
    Code: '4159',
    Name: 'Apprentice, commercial'
  },
  {
    Id: 10931,
    Code: '4159',
    Name: 'Assistant, admin, hospital service'
  },
  {
    Id: 10932,
    Code: '4159',
    Name: 'Assistant, admin'
  },
  {
    Id: 10933,
    Code: '4159',
    Name: 'Assistant, administration, hospital service'
  },
  {
    Id: 10934,
    Code: '4159',
    Name: 'Assistant, administration'
  },
  {
    Id: 10935,
    Code: '4159',
    Name: 'Assistant, administrative, hospital service'
  },
  {
    Id: 10936,
    Code: '4159',
    Name: 'Assistant, administrative'
  },
  {
    Id: 10937,
    Code: '4159',
    Name: 'Assistant, advertising'
  },
  {
    Id: 10938,
    Code: '4159',
    Name: 'Assistant, arts'
  },
  {
    Id: 10939,
    Code: '4159',
    Name: 'Assistant, bureau'
  },
  {
    Id: 10940,
    Code: '4159',
    Name: 'Assistant, circulation'
  },
  {
    Id: 10941,
    Code: '4159',
    Name: 'Assistant, civil'
  },
  {
    Id: 10942,
    Code: '4159',
    Name: 'Assistant, clerical'
  },
  {
    Id: 10943,
    Code: '4159',
    Name: "Assistant, clerk's"
  },
  {
    Id: 10944,
    Code: '4159',
    Name: 'Assistant, commercial'
  },
  {
    Id: 10945,
    Code: '4159',
    Name: 'Assistant, conference'
  },
  {
    Id: 10946,
    Code: '4159',
    Name: 'Assistant, consular'
  },
  {
    Id: 10947,
    Code: '4159',
    Name: "Assistant, director's"
  },
  {
    Id: 10948,
    Code: '4159',
    Name: 'Assistant, e-business'
  },
  {
    Id: 10949,
    Code: '4159',
    Name: "Assistant, editor's, newspaper"
  },
  {
    Id: 10950,
    Code: '4159',
    Name: 'Assistant, estates'
  },
  {
    Id: 10951,
    Code: '4159',
    Name: 'Assistant, facilities, clerical'
  },
  {
    Id: 10952,
    Code: '4159',
    Name: 'Assistant, housing'
  },
  {
    Id: 10953,
    Code: '4159',
    Name: 'Assistant, information'
  },
  {
    Id: 10954,
    Code: '4159',
    Name: 'Assistant, management, facilities'
  },
  {
    Id: 10955,
    Code: '4159',
    Name: 'Assistant, management, housing'
  },
  {
    Id: 10956,
    Code: '4159',
    Name: "Assistant, manager's"
  },
  {
    Id: 10957,
    Code: '4159',
    Name: 'Assistant, office, general'
  },
  {
    Id: 10958,
    Code: '4159',
    Name: 'Assistant, office, receiving'
  },
  {
    Id: 10959,
    Code: '4159',
    Name: 'Assistant, office'
  },
  {
    Id: 10960,
    Code: '4159',
    Name: 'Assistant, operations, clerical'
  },
  {
    Id: 10961,
    Code: '4159',
    Name: "Assistant, reader's"
  },
  {
    Id: 10962,
    Code: '4159',
    Name: 'Assistant, registry, educational establishments'
  },
  {
    Id: 10963,
    Code: '4159',
    Name: "Assistant, secretary's"
  },
  {
    Id: 10964,
    Code: '4159',
    Name: 'Assistant, services, business'
  },
  {
    Id: 10965,
    Code: '4159',
    Name: 'Assistant, services, patient, clerical'
  },
  {
    Id: 10966,
    Code: '4159',
    Name: 'Assistant, studio, clerical'
  },
  {
    Id: 10967,
    Code: '4159',
    Name: 'Assistant, support, business'
  },
  {
    Id: 10968,
    Code: '4159',
    Name: 'Assistant, support, clerical'
  },
  {
    Id: 10969,
    Code: '4159',
    Name: 'Assistant, support, facilities'
  },
  {
    Id: 10970,
    Code: '4159',
    Name: 'Clerk, admin'
  },
  {
    Id: 10971,
    Code: '4159',
    Name: 'Clerk, administration'
  },
  {
    Id: 10972,
    Code: '4159',
    Name: 'Clerk, committee'
  },
  {
    Id: 10973,
    Code: '4159',
    Name: 'Clerk, continuity, film, television production'
  },
  {
    Id: 10974,
    Code: '4159',
    Name: 'Clerk, office, receiving'
  },
  {
    Id: 10975,
    Code: '4159',
    Name: 'Clerk, office'
  },
  {
    Id: 10976,
    Code: '4159',
    Name: 'Clerk, registration'
  },
  {
    Id: 10977,
    Code: '4159',
    Name: 'Clerk'
  },
  {
    Id: 10978,
    Code: '4159',
    Name: 'Clerk-telephonist'
  },
  {
    Id: 10979,
    Code: '4159',
    Name: 'Clerk-typist'
  },
  {
    Id: 10980,
    Code: '4159',
    Name: 'Controller, proof, newspaper'
  },
  {
    Id: 10981,
    Code: '4159',
    Name: 'Coordinator, administrative'
  },
  {
    Id: 10982,
    Code: '4159',
    Name: 'Coordinator, estate'
  },
  {
    Id: 10983,
    Code: '4159',
    Name: 'Coordinator, estates'
  },
  {
    Id: 10984,
    Code: '4159',
    Name: 'Coordinator, facilities'
  },
  {
    Id: 10985,
    Code: '4159',
    Name: 'Coordinator, NVQ'
  },
  {
    Id: 10986,
    Code: '4159',
    Name: 'Coordinator, services, business'
  },
  {
    Id: 10987,
    Code: '4159',
    Name: 'Coordinator, support, business'
  },
  {
    Id: 10988,
    Code: '4159',
    Name: 'Copyholder'
  },
  {
    Id: 10989,
    Code: '4159',
    Name: 'Corrector, press'
  },
  {
    Id: 10990,
    Code: '4159',
    Name: 'Corrector, proof, newspaper'
  },
  {
    Id: 10991,
    Code: '4159',
    Name: 'Executive, support, business'
  },
  {
    Id: 10992,
    Code: '4159',
    Name: 'Holder, copy'
  },
  {
    Id: 10993,
    Code: '4159',
    Name: 'Inspector, office, nos'
  },
  {
    Id: 10994,
    Code: '4159',
    Name: 'Loggist'
  },
  {
    Id: 10995,
    Code: '4159',
    Name: 'Officer, admin, hospital service'
  },
  {
    Id: 10996,
    Code: '4159',
    Name: 'Officer, admin'
  },
  {
    Id: 10997,
    Code: '4159',
    Name: 'Officer, administration, hospital service'
  },
  {
    Id: 10998,
    Code: '4159',
    Name: 'Officer, administration'
  },
  {
    Id: 10999,
    Code: '4159',
    Name: 'Officer, administrative, hospital service'
  },
  {
    Id: 11000,
    Code: '4159',
    Name: 'Officer, administrative'
  },
  {
    Id: 11001,
    Code: '4159',
    Name: 'Officer, church'
  },
  {
    Id: 11002,
    Code: '4159',
    Name: 'Officer, clerical, higher'
  },
  {
    Id: 11003,
    Code: '4159',
    Name: 'Officer, clerical'
  },
  {
    Id: 11004,
    Code: '4159',
    Name: 'Officer, distribution, gas supplier'
  },
  {
    Id: 11005,
    Code: '4159',
    Name: 'Officer, enquiry'
  },
  {
    Id: 11006,
    Code: '4159',
    Name: 'Officer, facilities'
  },
  {
    Id: 11007,
    Code: '4159',
    Name: 'Officer, liaison'
  },
  {
    Id: 11008,
    Code: '4159',
    Name: 'Officer, mining'
  },
  {
    Id: 11009,
    Code: '4159',
    Name: 'Officer, operations'
  },
  {
    Id: 11010,
    Code: '4159',
    Name: 'Officer, registration'
  },
  {
    Id: 11011,
    Code: '4159',
    Name: 'Officer, section'
  },
  {
    Id: 11012,
    Code: '4159',
    Name: 'Officer, service, health'
  },
  {
    Id: 11013,
    Code: '4159',
    Name: 'Officer, settlement'
  },
  {
    Id: 11014,
    Code: '4159',
    Name: 'Official, board, water'
  },
  {
    Id: 11015,
    Code: '4159',
    Name: 'Official, brewery'
  },
  {
    Id: 11016,
    Code: '4159',
    Name: 'Pressureman, gas supplier'
  },
  {
    Id: 11017,
    Code: '4159',
    Name: 'Pricer, prescription'
  },
  {
    Id: 11018,
    Code: '4159',
    Name: 'Reader, newspaper'
  },
  {
    Id: 11019,
    Code: '4159',
    Name: 'Reader, proof'
  },
  {
    Id: 11020,
    Code: '4159',
    Name: 'Reader, printing'
  },
  {
    Id: 11021,
    Code: '4159',
    Name: 'Recorder, temperature'
  },
  {
    Id: 11022,
    Code: '4159',
    Name: 'Recorder, wagon'
  },
  {
    Id: 11023,
    Code: '4159',
    Name: 'Recorder, HM Dockyard'
  },
  {
    Id: 11024,
    Code: '4159',
    Name: 'Recorder, steelworks'
  },
  {
    Id: 11025,
    Code: '4159',
    Name: 'Reporter, train'
  },
  {
    Id: 11026,
    Code: '4159',
    Name: 'Reviser, printing'
  },
  {
    Id: 11027,
    Code: '4159',
    Name: 'Scrutineer'
  },
  {
    Id: 11028,
    Code: '4159',
    Name: 'Secretary, committee'
  },
  {
    Id: 11029,
    Code: '4159',
    Name: 'Secretary-receptionist'
  },
  {
    Id: 11030,
    Code: '4159',
    Name: 'Worker, admin'
  },
  {
    Id: 11031,
    Code: '4159',
    Name: 'Worker, administration'
  },
  {
    Id: 11032,
    Code: '4159',
    Name: 'Worker, administrative'
  },
  {
    Id: 11033,
    Code: '4159',
    Name: 'Worker, clerical'
  },
  {
    Id: 11034,
    Code: '4159',
    Name: 'Worker, support, clerical'
  },
  {
    Id: 11035,
    Code: '4211',
    Name: 'Administrator, clinic'
  },
  {
    Id: 11036,
    Code: '4211',
    Name: 'Administrator, clinical'
  },
  {
    Id: 11037,
    Code: '4211',
    Name: 'Administrator, medical'
  },
  {
    Id: 11038,
    Code: '4211',
    Name: 'Administrator, surgery, GP'
  },
  {
    Id: 11039,
    Code: '4211',
    Name: 'Clerk, admin, medical practice'
  },
  {
    Id: 11040,
    Code: '4211',
    Name: 'Clerk, administration, medical practice'
  },
  {
    Id: 11041,
    Code: '4211',
    Name: 'Clerk, medical practice'
  },
  {
    Id: 11042,
    Code: '4211',
    Name: 'Coordinator, clinic'
  },
  {
    Id: 11043,
    Code: '4211',
    Name: 'Officer, clerical, medical practice'
  },
  {
    Id: 11044,
    Code: '4211',
    Name: 'Secretary, clinical'
  },
  {
    Id: 11045,
    Code: '4211',
    Name: "Secretary, doctor's"
  },
  {
    Id: 11046,
    Code: '4211',
    Name: 'Secretary, hospital'
  },
  {
    Id: 11047,
    Code: '4211',
    Name: 'Secretary, medical'
  },
  {
    Id: 11048,
    Code: '4211',
    Name: 'Secretary, hospital service'
  },
  {
    Id: 11049,
    Code: '4211',
    Name: 'Secretary, medical practice'
  },
  {
    Id: 11050,
    Code: '4211',
    Name: 'Summariser, notes, medical, medical practice'
  },
  {
    Id: 11051,
    Code: '4211',
    Name: 'Worker, clerical, medical practice'
  },
  {
    Id: 11052,
    Code: '4212',
    Name: 'Administrator, legal'
  },
  {
    Id: 11053,
    Code: '4212',
    Name: 'Administrator, legal services'
  },
  {
    Id: 11054,
    Code: '4212',
    Name: 'Assistant, admin, legal services'
  },
  {
    Id: 11055,
    Code: '4212',
    Name: 'Assistant, administration, legal services'
  },
  {
    Id: 11056,
    Code: '4212',
    Name: 'Assistant, administrative, legal services'
  },
  {
    Id: 11057,
    Code: '4212',
    Name: 'Assistant, personal, legal'
  },
  {
    Id: 11058,
    Code: '4212',
    Name: 'Assistant, personal, legal services'
  },
  {
    Id: 11059,
    Code: '4212',
    Name: 'Clerk, aid, legal'
  },
  {
    Id: 11060,
    Code: '4212',
    Name: 'Clerk, legal'
  },
  {
    Id: 11061,
    Code: '4212',
    Name: 'Clerk, probate'
  },
  {
    Id: 11062,
    Code: '4212',
    Name: 'Clerk, legal services'
  },
  {
    Id: 11063,
    Code: '4212',
    Name: 'Clerk, legal services'
  },
  {
    Id: 11064,
    Code: '4212',
    Name: 'Officer, admin, legal services'
  },
  {
    Id: 11065,
    Code: '4212',
    Name: 'Officer, administratiive, legal services'
  },
  {
    Id: 11066,
    Code: '4212',
    Name: 'Officer, administration, legal services'
  },
  {
    Id: 11067,
    Code: '4212',
    Name: 'PA, legal'
  },
  {
    Id: 11068,
    Code: '4212',
    Name: 'Secretary, conveyancing'
  },
  {
    Id: 11069,
    Code: '4212',
    Name: 'Secretary, legal'
  },
  {
    Id: 11070,
    Code: '4212',
    Name: 'Secretary, litigation'
  },
  {
    Id: 11071,
    Code: '4212',
    Name: 'Secretary, legal services'
  },
  {
    Id: 11072,
    Code: '4213',
    Name: 'Administrator, office, school'
  },
  {
    Id: 11073,
    Code: '4213',
    Name: 'Administrator, office, schools'
  },
  {
    Id: 11074,
    Code: '4213',
    Name: 'Administrator, school'
  },
  {
    Id: 11075,
    Code: '4213',
    Name: 'Administrator, schools'
  },
  {
    Id: 11076,
    Code: '4213',
    Name: 'Assistant, admin, schools'
  },
  {
    Id: 11077,
    Code: '4213',
    Name: 'Assistant, administration, schools'
  },
  {
    Id: 11078,
    Code: '4213',
    Name: 'Assistant, administrative, schools'
  },
  {
    Id: 11079,
    Code: '4213',
    Name: 'Assistant, clerical, schools'
  },
  {
    Id: 11080,
    Code: '4213',
    Name: 'Assistant, registry, schools'
  },
  {
    Id: 11081,
    Code: '4213',
    Name: 'Clerk, school'
  },
  {
    Id: 11082,
    Code: '4213',
    Name: 'Clerk, schools'
  },
  {
    Id: 11083,
    Code: '4213',
    Name: 'Clerk-typist, schools'
  },
  {
    Id: 11084,
    Code: '4213',
    Name: 'Officer, admin, schools'
  },
  {
    Id: 11085,
    Code: '4213',
    Name: 'Officer, administration, schools'
  },
  {
    Id: 11086,
    Code: '4213',
    Name: 'Officer, administrative, schools'
  },
  {
    Id: 11087,
    Code: '4213',
    Name: 'Officer, clerical, schools'
  },
  {
    Id: 11088,
    Code: '4213',
    Name: 'Officer, support, school'
  },
  {
    Id: 11089,
    Code: '4213',
    Name: 'Secretary, school'
  },
  {
    Id: 11090,
    Code: '4213',
    Name: 'Secretary, schools'
  },
  {
    Id: 11091,
    Code: '4213',
    Name: 'Secretary, primary school'
  },
  {
    Id: 11092,
    Code: '4213',
    Name: 'Secretary, secondary school'
  },
  {
    Id: 11093,
    Code: '4214',
    Name: 'Administrator, company'
  },
  {
    Id: 11094,
    Code: '4214',
    Name: 'Secretary, assistant'
  },
  {
    Id: 11095,
    Code: '4214',
    Name: 'Secretary, club'
  },
  {
    Id: 11096,
    Code: '4214',
    Name: 'Secretary, company'
  },
  {
    Id: 11097,
    Code: '4214',
    Name: 'Secretary, corporation'
  },
  {
    Id: 11098,
    Code: '4214',
    Name: 'Secretary, resident'
  },
  {
    Id: 11099,
    Code: '4214',
    Name: 'Secretary, sports'
  },
  {
    Id: 11100,
    Code: '4215',
    Name: 'Administrator-secretary'
  },
  {
    Id: 11101,
    Code: '4215',
    Name: "Assistant, director's, film, television production"
  },
  {
    Id: 11102,
    Code: '4215',
    Name: 'Assistant, executive'
  },
  {
    Id: 11103,
    Code: '4215',
    Name: 'Assistant, personal, executive'
  },
  {
    Id: 11104,
    Code: '4215',
    Name: "Assistant, personal, manager's"
  },
  {
    Id: 11105,
    Code: '4215',
    Name: 'Assistant, personal, managerial'
  },
  {
    Id: 11106,
    Code: '4215',
    Name: 'Assistant, personal, medical'
  },
  {
    Id: 11107,
    Code: '4215',
    Name: 'Assistant, personal, virtual'
  },
  {
    Id: 11108,
    Code: '4215',
    Name: 'Assistant, personal'
  },
  {
    Id: 11109,
    Code: '4215',
    Name: 'Assistant, production, post, film, television production'
  },
  {
    Id: 11110,
    Code: '4215',
    Name: 'Assistant, secretarial'
  },
  {
    Id: 11111,
    Code: '4215',
    Name: 'Assistant, virtual'
  },
  {
    Id: 11112,
    Code: '4215',
    Name: 'Clerk, confidential'
  },
  {
    Id: 11113,
    Code: '4215',
    Name: 'EA'
  },
  {
    Id: 11114,
    Code: '4215',
    Name: 'Manager, diary'
  },
  {
    Id: 11115,
    Code: '4215',
    Name: 'PA, executive'
  },
  {
    Id: 11116,
    Code: '4215',
    Name: 'PA, medical'
  },
  {
    Id: 11117,
    Code: '4215',
    Name: 'PA, virtual'
  },
  {
    Id: 11118,
    Code: '4215',
    Name: 'PA'
  },
  {
    Id: 11119,
    Code: '4215',
    Name: 'PA-Secretary'
  },
  {
    Id: 11120,
    Code: '4215',
    Name: 'Personal assistant-secretary'
  },
  {
    Id: 11121,
    Code: '4215',
    Name: 'Secretary, admin'
  },
  {
    Id: 11122,
    Code: '4215',
    Name: 'Secretary, administration'
  },
  {
    Id: 11123,
    Code: '4215',
    Name: 'Secretary, administrative'
  },
  {
    Id: 11124,
    Code: '4215',
    Name: 'Secretary, assistant, hospital service'
  },
  {
    Id: 11125,
    Code: '4215',
    Name: 'Secretary, bilingual'
  },
  {
    Id: 11126,
    Code: '4215',
    Name: 'Secretary, branch, banking'
  },
  {
    Id: 11127,
    Code: '4215',
    Name: 'Secretary, branch, building society'
  },
  {
    Id: 11128,
    Code: '4215',
    Name: 'Secretary, club, youth club'
  },
  {
    Id: 11129,
    Code: '4215',
    Name: 'Secretary, commercial'
  },
  {
    Id: 11130,
    Code: '4215',
    Name: 'Secretary, confidential'
  },
  {
    Id: 11131,
    Code: '4215',
    Name: 'Secretary, county, youth club'
  },
  {
    Id: 11132,
    Code: '4215',
    Name: "Secretary, director's"
  },
  {
    Id: 11133,
    Code: '4215',
    Name: 'Secretary, farm'
  },
  {
    Id: 11134,
    Code: '4215',
    Name: 'Secretary, group, hospital service'
  },
  {
    Id: 11135,
    Code: '4215',
    Name: 'Secretary, membership, football club'
  },
  {
    Id: 11136,
    Code: '4215',
    Name: 'Secretary, personal'
  },
  {
    Id: 11137,
    Code: '4215',
    Name: 'Secretary, private'
  },
  {
    Id: 11138,
    Code: '4215',
    Name: 'Secretary, technical'
  },
  {
    Id: 11139,
    Code: '4215',
    Name: 'Secretary, farm'
  },
  {
    Id: 11140,
    Code: '4215',
    Name: 'Secretary, personal'
  },
  {
    Id: 11141,
    Code: '4215',
    Name: 'Secretary'
  },
  {
    Id: 11142,
    Code: '4215',
    Name: 'Secretary-administrator'
  },
  {
    Id: 11143,
    Code: '4215',
    Name: 'Secretary-bookkeeper'
  },
  {
    Id: 11144,
    Code: '4215',
    Name: 'Secretary-PA'
  },
  {
    Id: 11145,
    Code: '4215',
    Name: 'Secretary-personal assistant'
  },
  {
    Id: 11146,
    Code: '4215',
    Name: 'Secretary-typist'
  },
  {
    Id: 11147,
    Code: '4216',
    Name: 'Adviser, service, garage'
  },
  {
    Id: 11148,
    Code: '4216',
    Name: 'Assistant, service, guest'
  },
  {
    Id: 11149,
    Code: '4216',
    Name: 'Assistant, services, guest'
  },
  {
    Id: 11150,
    Code: '4216',
    Name: 'Assistant, surgery, general medical service'
  },
  {
    Id: 11151,
    Code: '4216',
    Name: 'Manager, front of house, hotel'
  },
  {
    Id: 11152,
    Code: '4216',
    Name: 'Manager, reception'
  },
  {
    Id: 11153,
    Code: '4216',
    Name: 'Officer, reception'
  },
  {
    Id: 11154,
    Code: '4216',
    Name: 'Officer, services, guest'
  },
  {
    Id: 11155,
    Code: '4216',
    Name: 'Receptionist, A&E'
  },
  {
    Id: 11156,
    Code: '4216',
    Name: 'Receptionist, dental'
  },
  {
    Id: 11157,
    Code: '4216',
    Name: "Receptionist, doctor's"
  },
  {
    Id: 11158,
    Code: '4216',
    Name: 'Receptionist, hospital'
  },
  {
    Id: 11159,
    Code: '4216',
    Name: 'Receptionist, hotel'
  },
  {
    Id: 11160,
    Code: '4216',
    Name: 'Receptionist, legal'
  },
  {
    Id: 11161,
    Code: '4216',
    Name: 'Receptionist, medical'
  },
  {
    Id: 11162,
    Code: '4216',
    Name: 'Receptionist, optical'
  },
  {
    Id: 11163,
    Code: '4216',
    Name: 'Receptionist, school'
  },
  {
    Id: 11164,
    Code: '4216',
    Name: 'Receptionist, veterinary'
  },
  {
    Id: 11165,
    Code: '4216',
    Name: 'Receptionist'
  },
  {
    Id: 11166,
    Code: '4216',
    Name: 'Receptionist-administrator'
  },
  {
    Id: 11167,
    Code: '4216',
    Name: 'Receptionist-bookkeeper'
  },
  {
    Id: 11168,
    Code: '4216',
    Name: 'Receptionist-clerk'
  },
  {
    Id: 11169,
    Code: '4216',
    Name: 'Receptionist-secretary'
  },
  {
    Id: 11170,
    Code: '4216',
    Name: 'Receptionist-telephonist'
  },
  {
    Id: 11171,
    Code: '4216',
    Name: 'Receptionist-typist'
  },
  {
    Id: 11172,
    Code: '4216',
    Name: 'Supervisor, reception'
  },
  {
    Id: 11173,
    Code: '4217',
    Name: 'Clerk, secretarial'
  },
  {
    Id: 11174,
    Code: '4217',
    Name: 'Operator, dictaphone'
  },
  {
    Id: 11175,
    Code: '4217',
    Name: 'Operator, processor, word'
  },
  {
    Id: 11176,
    Code: '4217',
    Name: 'Operator, WP'
  },
  {
    Id: 11177,
    Code: '4217',
    Name: 'Processor, text'
  },
  {
    Id: 11178,
    Code: '4217',
    Name: 'Processor, word'
  },
  {
    Id: 11179,
    Code: '4217',
    Name: 'Reporter, court'
  },
  {
    Id: 11180,
    Code: '4217',
    Name: 'Reporter, price, financial services'
  },
  {
    Id: 11181,
    Code: '4217',
    Name: 'Reporter, verbatim'
  },
  {
    Id: 11182,
    Code: '4217',
    Name: 'Stenographer'
  },
  {
    Id: 11183,
    Code: '4217',
    Name: 'Superintendent, typing'
  },
  {
    Id: 11184,
    Code: '4217',
    Name: "Superintendent, typist's"
  },
  {
    Id: 11185,
    Code: '4217',
    Name: 'Supervisor, pool, typing'
  },
  {
    Id: 11186,
    Code: '4217',
    Name: 'Supervisor, typing'
  },
  {
    Id: 11187,
    Code: '4217',
    Name: 'Transcriber'
  },
  {
    Id: 11188,
    Code: '4217',
    Name: 'Typist, audio'
  },
  {
    Id: 11189,
    Code: '4217',
    Name: 'Typist, copy'
  },
  {
    Id: 11190,
    Code: '4217',
    Name: 'Typist, hand, short'
  },
  {
    Id: 11191,
    Code: '4217',
    Name: 'Typist, shorthand'
  },
  {
    Id: 11192,
    Code: '4217',
    Name: 'Typist, superintendent'
  },
  {
    Id: 11193,
    Code: '4217',
    Name: 'Typist'
  },
  {
    Id: 11194,
    Code: '4217',
    Name: 'Typist-clerk'
  },
  {
    Id: 11195,
    Code: '4217',
    Name: 'Typist-receptionist'
  },
  {
    Id: 11196,
    Code: '4217',
    Name: 'Writer, shorthand'
  },
  {
    Id: 11197,
    Code: '5111',
    Name: 'Agent, farm'
  },
  {
    Id: 11198,
    Code: '5111',
    Name: 'Agent, livestock'
  },
  {
    Id: 11199,
    Code: '5111',
    Name: 'Agriculturist'
  },
  {
    Id: 11200,
    Code: '5111',
    Name: 'Bailiff, estate'
  },
  {
    Id: 11201,
    Code: '5111',
    Name: 'Bailiff, farm'
  },
  {
    Id: 11202,
    Code: '5111',
    Name: 'Bailiff, land'
  },
  {
    Id: 11203,
    Code: '5111',
    Name: 'Breeder, livestock'
  },
  {
    Id: 11204,
    Code: '5111',
    Name: 'Breeder, pig'
  },
  {
    Id: 11205,
    Code: '5111',
    Name: 'Contractor, agricultural'
  },
  {
    Id: 11206,
    Code: '5111',
    Name: 'Contractor, farm'
  },
  {
    Id: 11207,
    Code: '5111',
    Name: 'Contractor, ploughing'
  },
  {
    Id: 11208,
    Code: '5111',
    Name: 'Contractor, agricultural contracting'
  },
  {
    Id: 11209,
    Code: '5111',
    Name: 'Crofter, farming'
  },
  {
    Id: 11210,
    Code: '5111',
    Name: 'Farmer, beef'
  },
  {
    Id: 11211,
    Code: '5111',
    Name: 'Farmer, dairy'
  },
  {
    Id: 11212,
    Code: '5111',
    Name: 'Farmer, fish'
  },
  {
    Id: 11213,
    Code: '5111',
    Name: 'Farmer, game'
  },
  {
    Id: 11214,
    Code: '5111',
    Name: 'Farmer, salmon'
  },
  {
    Id: 11215,
    Code: '5111',
    Name: 'Farmer, sheep'
  },
  {
    Id: 11216,
    Code: '5111',
    Name: 'Farmer, trout'
  },
  {
    Id: 11217,
    Code: '5111',
    Name: 'Farmer, arable'
  },
  {
    Id: 11218,
    Code: '5111',
    Name: 'Farmer, dairy'
  },
  {
    Id: 11219,
    Code: '5111',
    Name: 'Farmer, livestock'
  },
  {
    Id: 11220,
    Code: '5111',
    Name: 'Farmer, fish farm, hatchery'
  },
  {
    Id: 11221,
    Code: '5111',
    Name: 'Farmer'
  },
  {
    Id: 11222,
    Code: '5111',
    Name: 'Fieldman'
  },
  {
    Id: 11223,
    Code: '5111',
    Name: 'Fieldsman'
  },
  {
    Id: 11224,
    Code: '5111',
    Name: 'Foreman, farm'
  },
  {
    Id: 11225,
    Code: '5111',
    Name: 'Foreman, agriculture'
  },
  {
    Id: 11226,
    Code: '5111',
    Name: 'Foreman-ganger, agriculture'
  },
  {
    Id: 11227,
    Code: '5111',
    Name: 'Grieve'
  },
  {
    Id: 11228,
    Code: '5111',
    Name: 'Holder, small'
  },
  {
    Id: 11229,
    Code: '5111',
    Name: 'Hwsmyn'
  },
  {
    Id: 11230,
    Code: '5111',
    Name: 'Keeper, pig'
  },
  {
    Id: 11231,
    Code: '5111',
    Name: 'Keeper, poultry'
  },
  {
    Id: 11232,
    Code: '5111',
    Name: 'Landholder'
  },
  {
    Id: 11233,
    Code: '5111',
    Name: 'Manager, dairy, farming'
  },
  {
    Id: 11234,
    Code: '5111',
    Name: 'Manager, herd'
  },
  {
    Id: 11235,
    Code: '5111',
    Name: 'Manager, livestock'
  },
  {
    Id: 11236,
    Code: '5111',
    Name: 'Manager, pig'
  },
  {
    Id: 11237,
    Code: '5111',
    Name: 'Manager, piggery'
  },
  {
    Id: 11238,
    Code: '5111',
    Name: 'Manager, poultry'
  },
  {
    Id: 11239,
    Code: '5111',
    Name: 'Manager, unit, pig'
  },
  {
    Id: 11240,
    Code: '5111',
    Name: 'Producer, egg'
  },
  {
    Id: 11241,
    Code: '5111',
    Name: 'Rearer, calf'
  },
  {
    Id: 11242,
    Code: '5111',
    Name: 'Rearer, cattle'
  },
  {
    Id: 11243,
    Code: '5111',
    Name: 'Rearer, poultry'
  },
  {
    Id: 11244,
    Code: '5111',
    Name: 'Smallholder'
  },
  {
    Id: 11245,
    Code: '5111',
    Name: 'Sprayer, crop'
  },
  {
    Id: 11246,
    Code: '5111',
    Name: 'Steward, estate'
  },
  {
    Id: 11247,
    Code: '5111',
    Name: 'Steward, farm'
  },
  {
    Id: 11248,
    Code: '5111',
    Name: 'Technician, agricultural, farming'
  },
  {
    Id: 11249,
    Code: '5111',
    Name: 'Technician, farm'
  },
  {
    Id: 11250,
    Code: '5112',
    Name: "Assistant, gardener's, horticultural nursery"
  },
  {
    Id: 11251,
    Code: '5112',
    Name: "Assistant, gardener's, market gardening"
  },
  {
    Id: 11252,
    Code: '5112',
    Name: 'Assistant, gardening, horticultural nursery'
  },
  {
    Id: 11253,
    Code: '5112',
    Name: 'Assistant, horticultural'
  },
  {
    Id: 11254,
    Code: '5112',
    Name: 'Assistant, house, green'
  },
  {
    Id: 11255,
    Code: '5112',
    Name: 'Assistant, nursery, garden'
  },
  {
    Id: 11256,
    Code: '5112',
    Name: 'Assistant, nursery, agriculture'
  },
  {
    Id: 11257,
    Code: '5112',
    Name: 'Assistant, nursery, horticultural nursery'
  },
  {
    Id: 11258,
    Code: '5112',
    Name: 'Cultivator, watercress'
  },
  {
    Id: 11259,
    Code: '5112',
    Name: 'Foreman, house, glass'
  },
  {
    Id: 11260,
    Code: '5112',
    Name: 'Foreman, horticultural nursery'
  },
  {
    Id: 11261,
    Code: '5112',
    Name: 'Foreman, horticulture'
  },
  {
    Id: 11262,
    Code: '5112',
    Name: 'Fruitman, head'
  },
  {
    Id: 11263,
    Code: '5112',
    Name: 'Fruitman'
  },
  {
    Id: 11264,
    Code: '5112',
    Name: 'Gardener, fruit'
  },
  {
    Id: 11265,
    Code: '5112',
    Name: 'Gardener, market'
  },
  {
    Id: 11266,
    Code: '5112',
    Name: 'Gardener, nursery'
  },
  {
    Id: 11267,
    Code: '5112',
    Name: 'Gardener, fruit growing'
  },
  {
    Id: 11268,
    Code: '5112',
    Name: 'Gardener, horticultural nursery'
  },
  {
    Id: 11269,
    Code: '5112',
    Name: 'Gardener, market gardening'
  },
  {
    Id: 11270,
    Code: '5112',
    Name: 'Grafter, agriculture'
  },
  {
    Id: 11271,
    Code: '5112',
    Name: 'Grower, fruit trees'
  },
  {
    Id: 11272,
    Code: '5112',
    Name: 'Grower, ornamental tree nursery'
  },
  {
    Id: 11273,
    Code: '5112',
    Name: 'Grower'
  },
  {
    Id: 11274,
    Code: '5112',
    Name: 'Horticulturalist'
  },
  {
    Id: 11275,
    Code: '5112',
    Name: 'Horticulturist'
  },
  {
    Id: 11276,
    Code: '5112',
    Name: 'Landscaper, interior'
  },
  {
    Id: 11277,
    Code: '5112',
    Name: 'Manufacturer, seed'
  },
  {
    Id: 11278,
    Code: '5112',
    Name: 'Nurseryman'
  },
  {
    Id: 11279,
    Code: '5112',
    Name: 'Planter, coffee'
  },
  {
    Id: 11280,
    Code: '5112',
    Name: 'Planter, rubber'
  },
  {
    Id: 11281,
    Code: '5112',
    Name: 'Planter, tea'
  },
  {
    Id: 11282,
    Code: '5112',
    Name: 'Planter, tobacco'
  },
  {
    Id: 11283,
    Code: '5112',
    Name: 'Planter, tree'
  },
  {
    Id: 11284,
    Code: '5112',
    Name: 'Propagator'
  },
  {
    Id: 11285,
    Code: '5112',
    Name: 'Pruner, tree, fruit growing'
  },
  {
    Id: 11286,
    Code: '5112',
    Name: 'Pruner, fruit growing'
  },
  {
    Id: 11287,
    Code: '5112',
    Name: 'Pruner, horticultural nursery'
  },
  {
    Id: 11288,
    Code: '5112',
    Name: 'Supervisor, nursery, horticultural'
  },
  {
    Id: 11289,
    Code: '5112',
    Name: 'Technician, horticultural'
  },
  {
    Id: 11290,
    Code: '5112',
    Name: 'Worker, seed'
  },
  {
    Id: 11291,
    Code: '5113',
    Name: "Assistant, gardener's"
  },
  {
    Id: 11292,
    Code: '5113',
    Name: 'Assistant, gardening'
  },
  {
    Id: 11293,
    Code: '5113',
    Name: 'Contractor, gardening'
  },
  {
    Id: 11294,
    Code: '5113',
    Name: 'Contractor, landscape'
  },
  {
    Id: 11295,
    Code: '5113',
    Name: 'Designer, garden'
  },
  {
    Id: 11296,
    Code: '5113',
    Name: 'Foreman, landscape'
  },
  {
    Id: 11297,
    Code: '5113',
    Name: 'Foreman, local government: parks dept'
  },
  {
    Id: 11298,
    Code: '5113',
    Name: 'Gardener, landscape'
  },
  {
    Id: 11299,
    Code: '5113',
    Name: 'Gardener, maintenance'
  },
  {
    Id: 11300,
    Code: '5113',
    Name: 'Gardener, landscape'
  },
  {
    Id: 11301,
    Code: '5113',
    Name: 'Gardener'
  },
  {
    Id: 11302,
    Code: '5113',
    Name: 'Gardener-caretaker'
  },
  {
    Id: 11303,
    Code: '5113',
    Name: 'Gardener-groundsman'
  },
  {
    Id: 11304,
    Code: '5113',
    Name: 'Gardener-handyman'
  },
  {
    Id: 11305,
    Code: '5113',
    Name: 'Handyman-gardener'
  },
  {
    Id: 11306,
    Code: '5113',
    Name: 'Installer, roof, green'
  },
  {
    Id: 11307,
    Code: '5113',
    Name: 'Landscaper'
  },
  {
    Id: 11308,
    Code: '5113',
    Name: 'Pruner, tree, local government'
  },
  {
    Id: 11309,
    Code: '5113',
    Name: 'Pruner, park'
  },
  {
    Id: 11310,
    Code: '5113',
    Name: 'Roofer, green'
  },
  {
    Id: 11311,
    Code: '5113',
    Name: 'Technician, care, lawn'
  },
  {
    Id: 11312,
    Code: '5114',
    Name: 'Attendant, ground'
  },
  {
    Id: 11313,
    Code: '5114',
    Name: 'Contractor, turf'
  },
  {
    Id: 11314,
    Code: '5114',
    Name: 'Cutter, turf'
  },
  {
    Id: 11315,
    Code: '5114',
    Name: 'Digger, turf'
  },
  {
    Id: 11316,
    Code: '5114',
    Name: 'Greenkeeper'
  },
  {
    Id: 11317,
    Code: '5114',
    Name: 'Greensman'
  },
  {
    Id: 11318,
    Code: '5114',
    Name: 'Groundskeeper'
  },
  {
    Id: 11319,
    Code: '5114',
    Name: 'Groundsman'
  },
  {
    Id: 11320,
    Code: '5114',
    Name: 'Groundsperson'
  },
  {
    Id: 11321,
    Code: '5114',
    Name: 'Groundsworker'
  },
  {
    Id: 11322,
    Code: '5114',
    Name: 'Improver, green'
  },
  {
    Id: 11323,
    Code: '5114',
    Name: 'Keeper, grass'
  },
  {
    Id: 11324,
    Code: '5114',
    Name: 'Keeper, green'
  },
  {
    Id: 11325,
    Code: '5114',
    Name: 'Keeper, ground'
  },
  {
    Id: 11326,
    Code: '5114',
    Name: 'Keeper, grounds'
  },
  {
    Id: 11327,
    Code: '5114',
    Name: 'Layer, turf'
  },
  {
    Id: 11328,
    Code: '5114',
    Name: 'Man, course, golf'
  },
  {
    Id: 11329,
    Code: '5114',
    Name: 'Operative, grounds'
  },
  {
    Id: 11330,
    Code: '5114',
    Name: 'Operative, maintenance, grounds'
  },
  {
    Id: 11331,
    Code: '5114',
    Name: 'Supplier, turf'
  },
  {
    Id: 11332,
    Code: '5119',
    Name: 'Apiarist'
  },
  {
    Id: 11333,
    Code: '5119',
    Name: 'Arboriculturist'
  },
  {
    Id: 11334,
    Code: '5119',
    Name: 'Arborist'
  },
  {
    Id: 11335,
    Code: '5119',
    Name: 'Beekeeper'
  },
  {
    Id: 11336,
    Code: '5119',
    Name: 'Breeder, game'
  },
  {
    Id: 11337,
    Code: '5119',
    Name: 'Captain, fishing'
  },
  {
    Id: 11338,
    Code: '5119',
    Name: 'Consultant, arboricultural'
  },
  {
    Id: 11339,
    Code: '5119',
    Name: 'Contractor, timber'
  },
  {
    Id: 11340,
    Code: '5119',
    Name: 'Falconer'
  },
  {
    Id: 11341,
    Code: '5119',
    Name: 'Farmer, bee'
  },
  {
    Id: 11342,
    Code: '5119',
    Name: 'Fisherman, share'
  },
  {
    Id: 11343,
    Code: '5119',
    Name: 'Fisherman'
  },
  {
    Id: 11344,
    Code: '5119',
    Name: 'Fisherman-crofter'
  },
  {
    Id: 11345,
    Code: '5119',
    Name: 'Gamekeeper'
  },
  {
    Id: 11346,
    Code: '5119',
    Name: 'Ghillie'
  },
  {
    Id: 11347,
    Code: '5119',
    Name: 'Gillie'
  },
  {
    Id: 11348,
    Code: '5119',
    Name: 'Guide, angling'
  },
  {
    Id: 11349,
    Code: '5119',
    Name: 'Inspector, bee'
  },
  {
    Id: 11350,
    Code: '5119',
    Name: 'Keeper, bee'
  },
  {
    Id: 11351,
    Code: '5119',
    Name: 'Keeper, fish'
  },
  {
    Id: 11352,
    Code: '5119',
    Name: 'Keeper, game'
  },
  {
    Id: 11353,
    Code: '5119',
    Name: 'Keeper, river'
  },
  {
    Id: 11354,
    Code: '5119',
    Name: 'Officer, acquisition, forestry'
  },
  {
    Id: 11355,
    Code: '5119',
    Name: 'Officer, amenities'
  },
  {
    Id: 11356,
    Code: '5119',
    Name: 'Officer, arboricultural'
  },
  {
    Id: 11357,
    Code: '5119',
    Name: 'Officer, estate, forestry'
  },
  {
    Id: 11358,
    Code: '5119',
    Name: 'Officer, estates, forestry'
  },
  {
    Id: 11359,
    Code: '5119',
    Name: 'Officer, forest'
  },
  {
    Id: 11360,
    Code: '5119',
    Name: 'Officer, forestry'
  },
  {
    Id: 11361,
    Code: '5119',
    Name: 'Officer, park, chief'
  },
  {
    Id: 11362,
    Code: '5119',
    Name: 'Officer, park, country'
  },
  {
    Id: 11363,
    Code: '5119',
    Name: 'Officer, parks'
  },
  {
    Id: 11364,
    Code: '5119',
    Name: 'Officer, tree'
  },
  {
    Id: 11365,
    Code: '5119',
    Name: 'Officer, woodland'
  },
  {
    Id: 11366,
    Code: '5119',
    Name: 'Ranger, area'
  },
  {
    Id: 11367,
    Code: '5119',
    Name: 'Ranger, community'
  },
  {
    Id: 11368,
    Code: '5119',
    Name: 'Ranger, countryside'
  },
  {
    Id: 11369,
    Code: '5119',
    Name: 'Ranger, education'
  },
  {
    Id: 11370,
    Code: '5119',
    Name: 'Ranger, estate'
  },
  {
    Id: 11371,
    Code: '5119',
    Name: 'Ranger, park, national'
  },
  {
    Id: 11372,
    Code: '5119',
    Name: 'Ranger, park'
  },
  {
    Id: 11373,
    Code: '5119',
    Name: 'Ranger, recreation'
  },
  {
    Id: 11374,
    Code: '5119',
    Name: 'Ranger, urban'
  },
  {
    Id: 11375,
    Code: '5119',
    Name: 'Ranger'
  },
  {
    Id: 11376,
    Code: '5119',
    Name: 'Skipper, trawler'
  },
  {
    Id: 11377,
    Code: '5119',
    Name: 'Skipper, fishing'
  },
  {
    Id: 11378,
    Code: '5119',
    Name: 'Stalker, deer'
  },
  {
    Id: 11379,
    Code: '5119',
    Name: 'Supervisor, arboricultural'
  },
  {
    Id: 11380,
    Code: '5119',
    Name: 'Surgeon, tree'
  },
  {
    Id: 11381,
    Code: '5119',
    Name: 'Technician, arboricultural'
  },
  {
    Id: 11382,
    Code: '5119',
    Name: 'Warden, countryside'
  },
  {
    Id: 11383,
    Code: '5119',
    Name: 'Warden, environmental'
  },
  {
    Id: 11384,
    Code: '5119',
    Name: 'Warden, estate'
  },
  {
    Id: 11385,
    Code: '5119',
    Name: 'Warden, game'
  },
  {
    Id: 11386,
    Code: '5119',
    Name: 'Warden, park, national'
  },
  {
    Id: 11387,
    Code: '5119',
    Name: 'Warden, range'
  },
  {
    Id: 11388,
    Code: '5119',
    Name: 'Warden, reserve, nature'
  },
  {
    Id: 11389,
    Code: '5119',
    Name: 'Worker, conservation'
  },
  {
    Id: 11390,
    Code: '5211',
    Name: 'Beater, panel, metal trades'
  },
  {
    Id: 11391,
    Code: '5211',
    Name: 'Beater, metal trades'
  },
  {
    Id: 11392,
    Code: '5211',
    Name: 'Bruiser, enamel sign mfr'
  },
  {
    Id: 11393,
    Code: '5211',
    Name: 'Builder, radiator, car'
  },
  {
    Id: 11394,
    Code: '5211',
    Name: 'Coppersmith'
  },
  {
    Id: 11395,
    Code: '5211',
    Name: 'Cutter, stencil, metal trades'
  },
  {
    Id: 11396,
    Code: '5211',
    Name: 'Dingman'
  },
  {
    Id: 11397,
    Code: '5211',
    Name: 'Engineer, metal, sheet'
  },
  {
    Id: 11398,
    Code: '5211',
    Name: 'Fabricator, metal, sheet'
  },
  {
    Id: 11399,
    Code: '5211',
    Name: 'Fabricator, metal'
  },
  {
    Id: 11400,
    Code: '5211',
    Name: 'Finisher, metal'
  },
  {
    Id: 11401,
    Code: '5211',
    Name: 'Maker, box, tin'
  },
  {
    Id: 11402,
    Code: '5211',
    Name: 'Maker, box, metal'
  },
  {
    Id: 11403,
    Code: '5211',
    Name: 'Maker, bucket, metal'
  },
  {
    Id: 11404,
    Code: '5211',
    Name: 'Maker, canister'
  },
  {
    Id: 11405,
    Code: '5211',
    Name: 'Maker, case, metal'
  },
  {
    Id: 11406,
    Code: '5211',
    Name: 'Maker, case, meter'
  },
  {
    Id: 11407,
    Code: '5211',
    Name: 'Maker, drawer, safes'
  },
  {
    Id: 11408,
    Code: '5211',
    Name: 'Maker, drum, metal'
  },
  {
    Id: 11409,
    Code: '5211',
    Name: 'Maker, drum and keg'
  },
  {
    Id: 11410,
    Code: '5211',
    Name: 'Maker, hollow-ware'
  },
  {
    Id: 11411,
    Code: '5211',
    Name: 'Maker, keg, metal'
  },
  {
    Id: 11412,
    Code: '5211',
    Name: 'Maker, kettle'
  },
  {
    Id: 11413,
    Code: '5211',
    Name: 'Maker, lamp, oil'
  },
  {
    Id: 11414,
    Code: '5211',
    Name: 'Maker, pail'
  },
  {
    Id: 11415,
    Code: '5211',
    Name: 'Maker, panel'
  },
  {
    Id: 11416,
    Code: '5211',
    Name: 'Maker, pattern, metal, footwear mfr'
  },
  {
    Id: 11417,
    Code: '5211',
    Name: 'Maker, pipe, organ'
  },
  {
    Id: 11418,
    Code: '5211',
    Name: 'Maker, plate, stencil'
  },
  {
    Id: 11419,
    Code: '5211',
    Name: 'Maker, pot, metal'
  },
  {
    Id: 11420,
    Code: '5211',
    Name: 'Maker, shim'
  },
  {
    Id: 11421,
    Code: '5211',
    Name: 'Maker, tray, metal'
  },
  {
    Id: 11422,
    Code: '5211',
    Name: 'Maker, trunk, metal'
  },
  {
    Id: 11423,
    Code: '5211',
    Name: 'Maker, wing, motor cars'
  },
  {
    Id: 11424,
    Code: '5211',
    Name: 'Man, radiator, vehicles'
  },
  {
    Id: 11425,
    Code: '5211',
    Name: 'Man, tin, sheet metal working'
  },
  {
    Id: 11426,
    Code: '5211',
    Name: 'Manufacturer, sheet metal goods mfr'
  },
  {
    Id: 11427,
    Code: '5211',
    Name: 'Marker, sheet metal working'
  },
  {
    Id: 11428,
    Code: '5211',
    Name: 'Mender, tank'
  },
  {
    Id: 11429,
    Code: '5211',
    Name: 'Pewterer'
  },
  {
    Id: 11430,
    Code: '5211',
    Name: 'Planisher, iron'
  },
  {
    Id: 11431,
    Code: '5211',
    Name: 'Planisher, sheet metal goods mfr'
  },
  {
    Id: 11432,
    Code: '5211',
    Name: 'Plater, last'
  },
  {
    Id: 11433,
    Code: '5211',
    Name: 'Plater, metal boot last mfr'
  },
  {
    Id: 11434,
    Code: '5211',
    Name: 'Repairer, container, freight'
  },
  {
    Id: 11435,
    Code: '5211',
    Name: 'Repairer, radiator, vehicles'
  },
  {
    Id: 11436,
    Code: '5211',
    Name: 'Smith, copper'
  },
  {
    Id: 11437,
    Code: '5211',
    Name: 'Smith, iron, sheet'
  },
  {
    Id: 11438,
    Code: '5211',
    Name: 'Smith, metal, gas meter mfr'
  },
  {
    Id: 11439,
    Code: '5211',
    Name: 'Smith, pan, copper'
  },
  {
    Id: 11440,
    Code: '5211',
    Name: 'Smith, tin'
  },
  {
    Id: 11441,
    Code: '5211',
    Name: 'Smith, white'
  },
  {
    Id: 11442,
    Code: '5211',
    Name: 'Snagger'
  },
  {
    Id: 11443,
    Code: '5211',
    Name: 'Straightener, sheet metal working'
  },
  {
    Id: 11444,
    Code: '5211',
    Name: 'Straightener, vehicle mfr'
  },
  {
    Id: 11445,
    Code: '5211',
    Name: 'Tinker'
  },
  {
    Id: 11446,
    Code: '5211',
    Name: 'Tinsmith'
  },
  {
    Id: 11447,
    Code: '5211',
    Name: 'Wheeler, sheet metal working'
  },
  {
    Id: 11448,
    Code: '5211',
    Name: 'Whitesmith'
  },
  {
    Id: 11449,
    Code: '5211',
    Name: 'Worker, iron, sheet'
  },
  {
    Id: 11450,
    Code: '5211',
    Name: 'Worker, metal, sheet'
  },
  {
    Id: 11451,
    Code: '5211',
    Name: 'Worker, plate, tin'
  },
  {
    Id: 11452,
    Code: '5211',
    Name: 'Worker, plate, zinc'
  },
  {
    Id: 11453,
    Code: '5211',
    Name: 'Worker, sheet, metal'
  },
  {
    Id: 11454,
    Code: '5211',
    Name: 'Worker, tin, sheet metal working'
  },
  {
    Id: 11455,
    Code: '5211',
    Name: 'Worker, zinc'
  },
  {
    Id: 11456,
    Code: '5212',
    Name: 'Assembler, core, foundry'
  },
  {
    Id: 11457,
    Code: '5212',
    Name: 'Beamer, shipbuilding'
  },
  {
    Id: 11458,
    Code: '5212',
    Name: 'Bender, frame, shipyard'
  },
  {
    Id: 11459,
    Code: '5212',
    Name: 'Bender, iron'
  },
  {
    Id: 11460,
    Code: '5212',
    Name: 'Bender, plate'
  },
  {
    Id: 11461,
    Code: '5212',
    Name: 'Bender, spring, spring mfr'
  },
  {
    Id: 11462,
    Code: '5212',
    Name: 'Blacksmith'
  },
  {
    Id: 11463,
    Code: '5212',
    Name: 'Blacksmith-engineer'
  },
  {
    Id: 11464,
    Code: '5212',
    Name: 'Blower, core'
  },
  {
    Id: 11465,
    Code: '5212',
    Name: 'Bolter, metal trades'
  },
  {
    Id: 11466,
    Code: '5212',
    Name: 'Borer, shipbuilding'
  },
  {
    Id: 11467,
    Code: '5212',
    Name: 'Caster, die'
  },
  {
    Id: 11468,
    Code: '5212',
    Name: 'Caster at machine'
  },
  {
    Id: 11469,
    Code: '5212',
    Name: 'Caulker'
  },
  {
    Id: 11470,
    Code: '5212',
    Name: 'Caulker-burner'
  },
  {
    Id: 11471,
    Code: '5212',
    Name: 'Chipper, shipbuilding'
  },
  {
    Id: 11472,
    Code: '5212',
    Name: 'Chipper and scraper'
  },
  {
    Id: 11473,
    Code: '5212',
    Name: 'Constructor, rig, oil'
  },
  {
    Id: 11474,
    Code: '5212',
    Name: 'Corer, foundry'
  },
  {
    Id: 11475,
    Code: '5212',
    Name: 'Drawer, pick'
  },
  {
    Id: 11476,
    Code: '5212',
    Name: 'Drawer, forging'
  },
  {
    Id: 11477,
    Code: '5212',
    Name: 'Driller, hand'
  },
  {
    Id: 11478,
    Code: '5212',
    Name: 'Driller, hydraulic'
  },
  {
    Id: 11479,
    Code: '5212',
    Name: 'Driller, plate'
  },
  {
    Id: 11480,
    Code: '5212',
    Name: 'Driller, portable'
  },
  {
    Id: 11481,
    Code: '5212',
    Name: 'Driller, sample, steelworks'
  },
  {
    Id: 11482,
    Code: '5212',
    Name: 'Driller, test, steel'
  },
  {
    Id: 11483,
    Code: '5212',
    Name: 'Driller, boiler mfr'
  },
  {
    Id: 11484,
    Code: '5212',
    Name: 'Driller, metal, constructional engineering'
  },
  {
    Id: 11485,
    Code: '5212',
    Name: 'Erector, beam and frame'
  },
  {
    Id: 11486,
    Code: '5212',
    Name: 'Erector, boiler'
  },
  {
    Id: 11487,
    Code: '5212',
    Name: 'Erector, frame, shipbuilding'
  },
  {
    Id: 11488,
    Code: '5212',
    Name: 'Expander, boiler mfr'
  },
  {
    Id: 11489,
    Code: '5212',
    Name: 'Farrier'
  },
  {
    Id: 11490,
    Code: '5212',
    Name: 'Filer, core'
  },
  {
    Id: 11491,
    Code: '5212',
    Name: 'Fitter, gasholder'
  },
  {
    Id: 11492,
    Code: '5212',
    Name: 'Fitter, gate, iron'
  },
  {
    Id: 11493,
    Code: '5212',
    Name: 'Fitter, gate and railings, iron'
  },
  {
    Id: 11494,
    Code: '5212',
    Name: 'Fitter, plate'
  },
  {
    Id: 11495,
    Code: '5212',
    Name: 'Fitter, spring, forging'
  },
  {
    Id: 11496,
    Code: '5212',
    Name: 'Fitter, tube, boiler mfr'
  },
  {
    Id: 11497,
    Code: '5212',
    Name: 'Fixer, ship-door and collar'
  },
  {
    Id: 11498,
    Code: '5212',
    Name: 'Forger'
  },
  {
    Id: 11499,
    Code: '5212',
    Name: 'Gouger'
  },
  {
    Id: 11500,
    Code: '5212',
    Name: 'Hammerman'
  },
  {
    Id: 11501,
    Code: '5212',
    Name: 'Hand, tub, foundry'
  },
  {
    Id: 11502,
    Code: '5212',
    Name: 'Hooper, wheel'
  },
  {
    Id: 11503,
    Code: '5212',
    Name: 'Legger, flyer'
  },
  {
    Id: 11504,
    Code: '5212',
    Name: 'Machinist, casting, die'
  },
  {
    Id: 11505,
    Code: '5212',
    Name: 'Machinist, constructional'
  },
  {
    Id: 11506,
    Code: '5212',
    Name: 'Machinist, spring, coach'
  },
  {
    Id: 11507,
    Code: '5212',
    Name: 'Maker, boiler'
  },
  {
    Id: 11508,
    Code: '5212',
    Name: 'Maker, bolster, cutlery'
  },
  {
    Id: 11509,
    Code: '5212',
    Name: 'Maker, bolt, forged'
  },
  {
    Id: 11510,
    Code: '5212',
    Name: 'Maker, cable, chain'
  },
  {
    Id: 11511,
    Code: '5212',
    Name: 'Maker, chain, forging'
  },
  {
    Id: 11512,
    Code: '5212',
    Name: 'Maker, chair, foundry'
  },
  {
    Id: 11513,
    Code: '5212',
    Name: 'Maker, core, foundry'
  },
  {
    Id: 11514,
    Code: '5212',
    Name: 'Maker, core, coal mine'
  },
  {
    Id: 11515,
    Code: '5212',
    Name: 'Maker, core, metal trades'
  },
  {
    Id: 11516,
    Code: '5212',
    Name: 'Maker, coupling'
  },
  {
    Id: 11517,
    Code: '5212',
    Name: 'Maker, file'
  },
  {
    Id: 11518,
    Code: '5212',
    Name: 'Maker, knife'
  },
  {
    Id: 11519,
    Code: '5212',
    Name: 'Maker, mould, foundry'
  },
  {
    Id: 11520,
    Code: '5212',
    Name: 'Maker, pattern, foundry'
  },
  {
    Id: 11521,
    Code: '5212',
    Name: 'Maker, pick'
  },
  {
    Id: 11522,
    Code: '5212',
    Name: 'Maker, pipe, foundry'
  },
  {
    Id: 11523,
    Code: '5212',
    Name: 'Maker, scythe'
  },
  {
    Id: 11524,
    Code: '5212',
    Name: 'Maker, shoe, horse'
  },
  {
    Id: 11525,
    Code: '5212',
    Name: 'Maker, shovel, steel'
  },
  {
    Id: 11526,
    Code: '5212',
    Name: 'Maker, spade'
  },
  {
    Id: 11527,
    Code: '5212',
    Name: 'Maker, spar, metal'
  },
  {
    Id: 11528,
    Code: '5212',
    Name: 'Maker, spring, laminated'
  },
  {
    Id: 11529,
    Code: '5212',
    Name: 'Maker, spring, leaf'
  },
  {
    Id: 11530,
    Code: '5212',
    Name: 'Maker, spring, railway'
  },
  {
    Id: 11531,
    Code: '5212',
    Name: 'Maker, spring, carriage, wagon mfr'
  },
  {
    Id: 11532,
    Code: '5212',
    Name: 'Maker, spring, railway locomotive mfr'
  },
  {
    Id: 11533,
    Code: '5212',
    Name: 'Maker, tank'
  },
  {
    Id: 11534,
    Code: '5212',
    Name: 'Maker, tool, chasing'
  },
  {
    Id: 11535,
    Code: '5212',
    Name: 'Maker, tool, edge'
  },
  {
    Id: 11536,
    Code: '5212',
    Name: 'Maker, tool, hand'
  },
  {
    Id: 11537,
    Code: '5212',
    Name: 'Maker, wedge'
  },
  {
    Id: 11538,
    Code: '5212',
    Name: 'Man, mould, steelworks'
  },
  {
    Id: 11539,
    Code: '5212',
    Name: 'Marker, boiler mfr'
  },
  {
    Id: 11540,
    Code: '5212',
    Name: 'Marker, shipbuilding'
  },
  {
    Id: 11541,
    Code: '5212',
    Name: 'Marker-off, boiler mfr'
  },
  {
    Id: 11542,
    Code: '5212',
    Name: 'Marker-off, shipbuilding'
  },
  {
    Id: 11543,
    Code: '5212',
    Name: 'Moulder, aluminium'
  },
  {
    Id: 11544,
    Code: '5212',
    Name: 'Moulder, bench'
  },
  {
    Id: 11545,
    Code: '5212',
    Name: 'Moulder, brass'
  },
  {
    Id: 11546,
    Code: '5212',
    Name: 'Moulder, butyl'
  },
  {
    Id: 11547,
    Code: '5212',
    Name: 'Moulder, connection'
  },
  {
    Id: 11548,
    Code: '5212',
    Name: 'Moulder, copper'
  },
  {
    Id: 11549,
    Code: '5212',
    Name: 'Moulder, core'
  },
  {
    Id: 11550,
    Code: '5212',
    Name: 'Moulder, cylinder'
  },
  {
    Id: 11551,
    Code: '5212',
    Name: 'Moulder, floor'
  },
  {
    Id: 11552,
    Code: '5212',
    Name: "Moulder, founder's, pipe"
  },
  {
    Id: 11553,
    Code: '5212',
    Name: 'Moulder, foundry'
  },
  {
    Id: 11554,
    Code: '5212',
    Name: 'Moulder, grate, stove'
  },
  {
    Id: 11555,
    Code: '5212',
    Name: 'Moulder, gutter'
  },
  {
    Id: 11556,
    Code: '5212',
    Name: 'Moulder, hand, metal trades'
  },
  {
    Id: 11557,
    Code: '5212',
    Name: 'Moulder, iron'
  },
  {
    Id: 11558,
    Code: '5212',
    Name: 'Moulder, loam'
  },
  {
    Id: 11559,
    Code: '5212',
    Name: 'Moulder, machine'
  },
  {
    Id: 11560,
    Code: '5212',
    Name: 'Moulder, metal, gun'
  },
  {
    Id: 11561,
    Code: '5212',
    Name: 'Moulder, pattern'
  },
  {
    Id: 11562,
    Code: '5212',
    Name: 'Moulder, pipe, iron'
  },
  {
    Id: 11563,
    Code: '5212',
    Name: 'Moulder, pipe, metal'
  },
  {
    Id: 11564,
    Code: '5212',
    Name: 'Moulder, plate, metal'
  },
  {
    Id: 11565,
    Code: '5212',
    Name: 'Moulder, roll'
  },
  {
    Id: 11566,
    Code: '5212',
    Name: 'Moulder, sand'
  },
  {
    Id: 11567,
    Code: '5212',
    Name: 'Moulder, shell'
  },
  {
    Id: 11568,
    Code: '5212',
    Name: 'Moulder, spray'
  },
  {
    Id: 11569,
    Code: '5212',
    Name: 'Moulder, steel'
  },
  {
    Id: 11570,
    Code: '5212',
    Name: 'Moulder, stove'
  },
  {
    Id: 11571,
    Code: '5212',
    Name: 'Moulder, wheel, metal trades'
  },
  {
    Id: 11572,
    Code: '5212',
    Name: 'Moulder, chemical mfr'
  },
  {
    Id: 11573,
    Code: '5212',
    Name: 'Moulder, coal mine'
  },
  {
    Id: 11574,
    Code: '5212',
    Name: 'Moulder, metal trades'
  },
  {
    Id: 11575,
    Code: '5212',
    Name: 'Moulder and coremaker, foundry'
  },
  {
    Id: 11576,
    Code: '5212',
    Name: 'Necker, fly'
  },
  {
    Id: 11577,
    Code: '5212',
    Name: 'Necker, flyer'
  },
  {
    Id: 11578,
    Code: '5212',
    Name: 'Operator, cast, die'
  },
  {
    Id: 11579,
    Code: '5212',
    Name: 'Pewtersmith'
  },
  {
    Id: 11580,
    Code: '5212',
    Name: 'Plater, boiler'
  },
  {
    Id: 11581,
    Code: '5212',
    Name: 'Plater, bridge'
  },
  {
    Id: 11582,
    Code: '5212',
    Name: 'Plater, constructional'
  },
  {
    Id: 11583,
    Code: '5212',
    Name: "Plater, engineer's, gas"
  },
  {
    Id: 11584,
    Code: '5212',
    Name: 'Plater, framing'
  },
  {
    Id: 11585,
    Code: '5212',
    Name: 'Plater, heavy'
  },
  {
    Id: 11586,
    Code: '5212',
    Name: 'Plater, hoe'
  },
  {
    Id: 11587,
    Code: '5212',
    Name: 'Plater, iron'
  },
  {
    Id: 11588,
    Code: '5212',
    Name: 'Plater, light'
  },
  {
    Id: 11589,
    Code: '5212',
    Name: 'Plater, roof'
  },
  {
    Id: 11590,
    Code: '5212',
    Name: 'Plater, shell'
  },
  {
    Id: 11591,
    Code: '5212',
    Name: 'Plater, shovel'
  },
  {
    Id: 11592,
    Code: '5212',
    Name: 'Plater, steel'
  },
  {
    Id: 11593,
    Code: '5212',
    Name: 'Plater, stem'
  },
  {
    Id: 11594,
    Code: '5212',
    Name: 'Plater, structural'
  },
  {
    Id: 11595,
    Code: '5212',
    Name: 'Plater, tank'
  },
  {
    Id: 11596,
    Code: '5212',
    Name: 'Plater, tool, edge'
  },
  {
    Id: 11597,
    Code: '5212',
    Name: 'Plater, chemical mfr'
  },
  {
    Id: 11598,
    Code: '5212',
    Name: 'Plater, coal mine'
  },
  {
    Id: 11599,
    Code: '5212',
    Name: 'Plater, construction'
  },
  {
    Id: 11600,
    Code: '5212',
    Name: 'Plater, edge tool mfr'
  },
  {
    Id: 11601,
    Code: '5212',
    Name: 'Plater, metal cutlery mfr'
  },
  {
    Id: 11602,
    Code: '5212',
    Name: 'Plater, metal trades'
  },
  {
    Id: 11603,
    Code: '5212',
    Name: 'Plater'
  },
  {
    Id: 11604,
    Code: '5212',
    Name: 'Plater-welder'
  },
  {
    Id: 11605,
    Code: '5212',
    Name: 'Potman, metal mfr: die casting'
  },
  {
    Id: 11606,
    Code: '5212',
    Name: 'Presser, fly, forging'
  },
  {
    Id: 11607,
    Code: '5212',
    Name: 'Presser, forge'
  },
  {
    Id: 11608,
    Code: '5212',
    Name: 'Presser, hot, metal trades'
  },
  {
    Id: 11609,
    Code: '5212',
    Name: 'Presser, hydraulic, forging'
  },
  {
    Id: 11610,
    Code: '5212',
    Name: 'Presser, steel'
  },
  {
    Id: 11611,
    Code: '5212',
    Name: 'Presser, forging'
  },
  {
    Id: 11612,
    Code: '5212',
    Name: 'Presser, rolling mill'
  },
  {
    Id: 11613,
    Code: '5212',
    Name: 'Puncher, boiler mfr'
  },
  {
    Id: 11614,
    Code: '5212',
    Name: 'Puncher, shipbuilding'
  },
  {
    Id: 11615,
    Code: '5212',
    Name: 'Rammer, chair'
  },
  {
    Id: 11616,
    Code: '5212',
    Name: 'Rammer, foundry'
  },
  {
    Id: 11617,
    Code: '5212',
    Name: 'Rammer-up'
  },
  {
    Id: 11618,
    Code: '5212',
    Name: 'Repairer, boiler'
  },
  {
    Id: 11619,
    Code: '5212',
    Name: 'Repairer, chain'
  },
  {
    Id: 11620,
    Code: '5212',
    Name: 'Repairer, propeller'
  },
  {
    Id: 11621,
    Code: '5212',
    Name: 'Repairer, spring'
  },
  {
    Id: 11622,
    Code: '5212',
    Name: 'Repairer, tube, boiler mfr'
  },
  {
    Id: 11623,
    Code: '5212',
    Name: 'Riddler, sand, foundry'
  },
  {
    Id: 11624,
    Code: '5212',
    Name: 'Riveter'
  },
  {
    Id: 11625,
    Code: '5212',
    Name: 'Setter, axle'
  },
  {
    Id: 11626,
    Code: '5212',
    Name: 'Setter, metal cutlery mfr'
  },
  {
    Id: 11627,
    Code: '5212',
    Name: 'Shaper, clog iron mfr'
  },
  {
    Id: 11628,
    Code: '5212',
    Name: 'Sharpener, gear, mining'
  },
  {
    Id: 11629,
    Code: '5212',
    Name: 'Sheerer, metal'
  },
  {
    Id: 11630,
    Code: '5212',
    Name: 'Sheeter, steel mfr'
  },
  {
    Id: 11631,
    Code: '5212',
    Name: 'Shoer, horse'
  },
  {
    Id: 11632,
    Code: '5212',
    Name: 'Smith, boiler'
  },
  {
    Id: 11633,
    Code: '5212',
    Name: 'Smith, coach'
  },
  {
    Id: 11634,
    Code: '5212',
    Name: 'Smith, coil, spring mfr'
  },
  {
    Id: 11635,
    Code: '5212',
    Name: 'Smith, engineering'
  },
  {
    Id: 11636,
    Code: '5212',
    Name: 'Smith, pan, salt'
  },
  {
    Id: 11637,
    Code: '5212',
    Name: 'Smith, pan, salt mfr'
  },
  {
    Id: 11638,
    Code: '5212',
    Name: 'Smith, plate'
  },
  {
    Id: 11639,
    Code: '5212',
    Name: 'Smith, tool'
  },
  {
    Id: 11640,
    Code: '5212',
    Name: 'Smith'
  },
  {
    Id: 11641,
    Code: '5212',
    Name: 'Smither'
  },
  {
    Id: 11642,
    Code: '5212',
    Name: 'Splitter, fork, digging fork mfr'
  },
  {
    Id: 11643,
    Code: '5212',
    Name: 'Stamper, brass, hot'
  },
  {
    Id: 11644,
    Code: '5212',
    Name: 'Stamper, drop, hot'
  },
  {
    Id: 11645,
    Code: '5212',
    Name: 'Stamper, drop, forging'
  },
  {
    Id: 11646,
    Code: '5212',
    Name: 'Stamper, hammer'
  },
  {
    Id: 11647,
    Code: '5212',
    Name: 'Stamper, hot'
  },
  {
    Id: 11648,
    Code: '5212',
    Name: 'Stamper, metal, hot'
  },
  {
    Id: 11649,
    Code: '5212',
    Name: 'Stamper, metal, forging'
  },
  {
    Id: 11650,
    Code: '5212',
    Name: 'Stamper, tool, edge'
  },
  {
    Id: 11651,
    Code: '5212',
    Name: 'Stamper, forging'
  },
  {
    Id: 11652,
    Code: '5212',
    Name: 'Stamper, galvanised sheet mfr'
  },
  {
    Id: 11653,
    Code: '5212',
    Name: 'Stamper, metal tube fittings mfr'
  },
  {
    Id: 11654,
    Code: '5212',
    Name: 'Stamper, rolling mill'
  },
  {
    Id: 11655,
    Code: '5212',
    Name: 'Straightener, axle'
  },
  {
    Id: 11656,
    Code: '5212',
    Name: 'Tapper, stay'
  },
  {
    Id: 11657,
    Code: '5212',
    Name: 'Tuber, boiler mfr'
  },
  {
    Id: 11658,
    Code: '5212',
    Name: 'Tyreman, railways'
  },
  {
    Id: 11659,
    Code: '5212',
    Name: 'Worker, iron, ornamental'
  },
  {
    Id: 11660,
    Code: '5212',
    Name: 'Worker, iron, wrought'
  },
  {
    Id: 11661,
    Code: '5212',
    Name: 'Worker, plate, iron'
  },
  {
    Id: 11662,
    Code: '5212',
    Name: 'Worker, plate, metal'
  },
  {
    Id: 11663,
    Code: '5212',
    Name: 'Worker, repair, coal mine: above ground'
  },
  {
    Id: 11664,
    Code: '5212',
    Name: 'Worker, rig, oil, oil rig construction'
  },
  {
    Id: 11665,
    Code: '5212',
    Name: 'Worker, shop, core, metal trades'
  },
  {
    Id: 11666,
    Code: '5213',
    Name: "Assistant, welder's"
  },
  {
    Id: 11667,
    Code: '5213',
    Name: 'Attendant, plant, welding'
  },
  {
    Id: 11668,
    Code: '5213',
    Name: 'Brazer'
  },
  {
    Id: 11669,
    Code: '5213',
    Name: 'Brazier'
  },
  {
    Id: 11670,
    Code: '5213',
    Name: 'Builder, chassis'
  },
  {
    Id: 11671,
    Code: '5213',
    Name: 'Burner, acetylene'
  },
  {
    Id: 11672,
    Code: '5213',
    Name: 'Burner, gas, building construction'
  },
  {
    Id: 11673,
    Code: '5213',
    Name: 'Burner, lead'
  },
  {
    Id: 11674,
    Code: '5213',
    Name: 'Burner, oxy-acetylene'
  },
  {
    Id: 11675,
    Code: '5213',
    Name: 'Burner, profile'
  },
  {
    Id: 11676,
    Code: '5213',
    Name: 'Burner, scrap, steelworks'
  },
  {
    Id: 11677,
    Code: '5213',
    Name: 'Burner, scrap metal'
  },
  {
    Id: 11678,
    Code: '5213',
    Name: 'Burner, coal mine'
  },
  {
    Id: 11679,
    Code: '5213',
    Name: 'Burner, demolition'
  },
  {
    Id: 11680,
    Code: '5213',
    Name: 'Burner, metal trades'
  },
  {
    Id: 11681,
    Code: '5213',
    Name: 'Burner, railways'
  },
  {
    Id: 11682,
    Code: '5213',
    Name: 'Cutter, acetylene'
  },
  {
    Id: 11683,
    Code: '5213',
    Name: 'Cutter, flame'
  },
  {
    Id: 11684,
    Code: '5213',
    Name: 'Cutter, gas'
  },
  {
    Id: 11685,
    Code: '5213',
    Name: 'Cutter, metal, scrap'
  },
  {
    Id: 11686,
    Code: '5213',
    Name: 'Cutter, oxy-acetylene'
  },
  {
    Id: 11687,
    Code: '5213',
    Name: 'Cutter, scrap'
  },
  {
    Id: 11688,
    Code: '5213',
    Name: 'Cutter, steel'
  },
  {
    Id: 11689,
    Code: '5213',
    Name: 'Cutter, boiler mfr'
  },
  {
    Id: 11690,
    Code: '5213',
    Name: 'Cutter, shipbuilding'
  },
  {
    Id: 11691,
    Code: '5213',
    Name: 'Deseamer, steelworks'
  },
  {
    Id: 11692,
    Code: '5213',
    Name: 'Dresser, flame, rolling mill'
  },
  {
    Id: 11693,
    Code: '5213',
    Name: 'Engineer, welding'
  },
  {
    Id: 11694,
    Code: '5213',
    Name: 'Fabricator-welder'
  },
  {
    Id: 11695,
    Code: '5213',
    Name: 'Fitter-welder'
  },
  {
    Id: 11696,
    Code: '5213',
    Name: 'Jointer, chain'
  },
  {
    Id: 11697,
    Code: '5213',
    Name: 'Lancer, thermic'
  },
  {
    Id: 11698,
    Code: '5213',
    Name: 'Machinist, brazing'
  },
  {
    Id: 11699,
    Code: '5213',
    Name: 'Machinist, soldering'
  },
  {
    Id: 11700,
    Code: '5213',
    Name: 'Operator, deseaming, steel'
  },
  {
    Id: 11701,
    Code: '5213',
    Name: 'Scarfer, steel mfr'
  },
  {
    Id: 11702,
    Code: '5213',
    Name: 'Solderer, jewellery, plate mfr'
  },
  {
    Id: 11703,
    Code: '5213',
    Name: 'Solderer, metal trades'
  },
  {
    Id: 11704,
    Code: '5213',
    Name: 'Solderer and jointer, case'
  },
  {
    Id: 11705,
    Code: '5213',
    Name: 'Sweater, metal trades'
  },
  {
    Id: 11706,
    Code: '5213',
    Name: 'Technician, welding'
  },
  {
    Id: 11707,
    Code: '5213',
    Name: 'Welder, arc'
  },
  {
    Id: 11708,
    Code: '5213',
    Name: 'Welder, blacksmith'
  },
  {
    Id: 11709,
    Code: '5213',
    Name: 'Welder, chain'
  },
  {
    Id: 11710,
    Code: '5213',
    Name: 'Welder, CO2'
  },
  {
    Id: 11711,
    Code: '5213',
    Name: 'Welder, coded'
  },
  {
    Id: 11712,
    Code: '5213',
    Name: 'Welder, electric'
  },
  {
    Id: 11713,
    Code: '5213',
    Name: 'Welder, fabrication'
  },
  {
    Id: 11714,
    Code: '5213',
    Name: 'Welder, fabricator'
  },
  {
    Id: 11715,
    Code: '5213',
    Name: 'Welder, maintenance'
  },
  {
    Id: 11716,
    Code: '5213',
    Name: 'Welder, mig'
  },
  {
    Id: 11717,
    Code: '5213',
    Name: 'Welder, pipe'
  },
  {
    Id: 11718,
    Code: '5213',
    Name: 'Welder, spot'
  },
  {
    Id: 11719,
    Code: '5213',
    Name: 'Welder, fabrication'
  },
  {
    Id: 11720,
    Code: '5213',
    Name: 'Welder'
  },
  {
    Id: 11721,
    Code: '5213',
    Name: 'Welder and cutter'
  },
  {
    Id: 11722,
    Code: '5213',
    Name: 'Welder-fabricator'
  },
  {
    Id: 11723,
    Code: '5213',
    Name: 'Welder-fitter'
  },
  {
    Id: 11724,
    Code: '5214',
    Name: 'Engineer, distribution, gas, pipe fitting'
  },
  {
    Id: 11725,
    Code: '5214',
    Name: 'Engineer, pipe'
  },
  {
    Id: 11726,
    Code: '5214',
    Name: 'Engineer, pipefitting'
  },
  {
    Id: 11727,
    Code: '5214',
    Name: 'Erector, pipe'
  },
  {
    Id: 11728,
    Code: '5214',
    Name: 'Fitter, pipe, boiler'
  },
  {
    Id: 11729,
    Code: '5214',
    Name: 'Fitter, pipe'
  },
  {
    Id: 11730,
    Code: '5214',
    Name: 'Fitter, tube'
  },
  {
    Id: 11731,
    Code: '5214',
    Name: 'Fitter-welder, pipe'
  },
  {
    Id: 11732,
    Code: '5214',
    Name: 'Layer, pipe, coal mine'
  },
  {
    Id: 11733,
    Code: '5214',
    Name: 'Lineman, pump'
  },
  {
    Id: 11734,
    Code: '5214',
    Name: 'Linesman, pump'
  },
  {
    Id: 11735,
    Code: '5214',
    Name: 'Man, pipe, brine'
  },
  {
    Id: 11736,
    Code: '5214',
    Name: 'Man, pipe, coal mine'
  },
  {
    Id: 11737,
    Code: '5214',
    Name: 'Turner, pipe'
  },
  {
    Id: 11738,
    Code: '5214',
    Name: 'Welder-fitter, pipe'
  },
  {
    Id: 11739,
    Code: '5221',
    Name: 'Auto-setter, metal trades'
  },
  {
    Id: 11740,
    Code: '5221',
    Name: 'Borer, cylinder'
  },
  {
    Id: 11741,
    Code: '5221',
    Name: 'Borer, fine, barrel'
  },
  {
    Id: 11742,
    Code: '5221',
    Name: 'Borer, horizontal'
  },
  {
    Id: 11743,
    Code: '5221',
    Name: 'Borer, iron'
  },
  {
    Id: 11744,
    Code: '5221',
    Name: 'Borer, jig'
  },
  {
    Id: 11745,
    Code: '5221',
    Name: 'Borer, room, tool'
  },
  {
    Id: 11746,
    Code: '5221',
    Name: 'Borer, scissors'
  },
  {
    Id: 11747,
    Code: '5221',
    Name: 'Borer, spill, barrel, small arms'
  },
  {
    Id: 11748,
    Code: '5221',
    Name: 'Borer, tong, tubes'
  },
  {
    Id: 11749,
    Code: '5221',
    Name: 'Borer, tyre'
  },
  {
    Id: 11750,
    Code: '5221',
    Name: 'Borer, universal'
  },
  {
    Id: 11751,
    Code: '5221',
    Name: 'Borer, vertical'
  },
  {
    Id: 11752,
    Code: '5221',
    Name: 'Borer, wheel'
  },
  {
    Id: 11753,
    Code: '5221',
    Name: 'Borer, metal trades'
  },
  {
    Id: 11754,
    Code: '5221',
    Name: 'Cutter, flyer'
  },
  {
    Id: 11755,
    Code: '5221',
    Name: 'Cutter, gear'
  },
  {
    Id: 11756,
    Code: '5221',
    Name: 'Cutter, pin, vice'
  },
  {
    Id: 11757,
    Code: '5221',
    Name: 'Cutter, profile'
  },
  {
    Id: 11758,
    Code: '5221',
    Name: 'Cutter, screw'
  },
  {
    Id: 11759,
    Code: '5221',
    Name: 'Cutter, worm'
  },
  {
    Id: 11760,
    Code: '5221',
    Name: 'Cutter and grinder, tool'
  },
  {
    Id: 11761,
    Code: '5221',
    Name: 'Cutter-grinder, metal trades'
  },
  {
    Id: 11762,
    Code: '5221',
    Name: 'Driller, air'
  },
  {
    Id: 11763,
    Code: '5221',
    Name: 'Driller, arm, radial'
  },
  {
    Id: 11764,
    Code: '5221',
    Name: 'Driller, barrel'
  },
  {
    Id: 11765,
    Code: '5221',
    Name: 'Driller, box, axle'
  },
  {
    Id: 11766,
    Code: '5221',
    Name: 'Driller, burner, gas'
  },
  {
    Id: 11767,
    Code: '5221',
    Name: 'Driller, casement, metal'
  },
  {
    Id: 11768,
    Code: '5221',
    Name: 'Driller, circle, textile machinery mfr'
  },
  {
    Id: 11769,
    Code: '5221',
    Name: 'Driller, faller'
  },
  {
    Id: 11770,
    Code: '5221',
    Name: 'Driller, frame'
  },
  {
    Id: 11771,
    Code: '5221',
    Name: 'Driller, hackle'
  },
  {
    Id: 11772,
    Code: '5221',
    Name: 'Driller, machine, metal trades'
  },
  {
    Id: 11773,
    Code: '5221',
    Name: 'Driller, pin'
  },
  {
    Id: 11774,
    Code: '5221',
    Name: 'Driller, radial'
  },
  {
    Id: 11775,
    Code: '5221',
    Name: 'Driller, rail'
  },
  {
    Id: 11776,
    Code: '5221',
    Name: 'Driller, rim'
  },
  {
    Id: 11777,
    Code: '5221',
    Name: 'Driller, ring, gas'
  },
  {
    Id: 11778,
    Code: '5221',
    Name: 'Driller, room, tool'
  },
  {
    Id: 11779,
    Code: '5221',
    Name: 'Driller, tip'
  },
  {
    Id: 11780,
    Code: '5221',
    Name: 'Driller, vertical'
  },
  {
    Id: 11781,
    Code: '5221',
    Name: 'Driller, wheel'
  },
  {
    Id: 11782,
    Code: '5221',
    Name: 'Driller, coal mine: workshops'
  },
  {
    Id: 11783,
    Code: '5221',
    Name: 'Driller, metal trades'
  },
  {
    Id: 11784,
    Code: '5221',
    Name: 'Engineer, capstan'
  },
  {
    Id: 11785,
    Code: '5221',
    Name: 'Engineer, CNC'
  },
  {
    Id: 11786,
    Code: '5221',
    Name: 'Engineer, control, numerically, computer'
  },
  {
    Id: 11787,
    Code: '5221',
    Name: 'Engineer, grinding'
  },
  {
    Id: 11788,
    Code: '5221',
    Name: 'Engineer, lathe'
  },
  {
    Id: 11789,
    Code: '5221',
    Name: 'Engineer, milling'
  },
  {
    Id: 11790,
    Code: '5221',
    Name: 'Engineer, planer, steel'
  },
  {
    Id: 11791,
    Code: '5221',
    Name: 'Engineer, planing'
  },
  {
    Id: 11792,
    Code: '5221',
    Name: 'Engineer, turner, lathe'
  },
  {
    Id: 11793,
    Code: '5221',
    Name: 'Engineer, turner'
  },
  {
    Id: 11794,
    Code: '5221',
    Name: 'Engineer-driller'
  },
  {
    Id: 11795,
    Code: '5221',
    Name: 'Engineer-machinist'
  },
  {
    Id: 11796,
    Code: '5221',
    Name: 'Facer, metal trades'
  },
  {
    Id: 11797,
    Code: '5221',
    Name: 'Finisher, bush, axle'
  },
  {
    Id: 11798,
    Code: '5221',
    Name: 'Finisher, disc, wheel'
  },
  {
    Id: 11799,
    Code: '5221',
    Name: 'Grinder, axle'
  },
  {
    Id: 11800,
    Code: '5221',
    Name: 'Grinder, bits, drill'
  },
  {
    Id: 11801,
    Code: '5221',
    Name: 'Grinder, bolster'
  },
  {
    Id: 11802,
    Code: '5221',
    Name: 'Grinder, cam-bowl'
  },
  {
    Id: 11803,
    Code: '5221',
    Name: 'Grinder, camshaft'
  },
  {
    Id: 11804,
    Code: '5221',
    Name: 'Grinder, centreless'
  },
  {
    Id: 11805,
    Code: '5221',
    Name: 'Grinder, CNC'
  },
  {
    Id: 11806,
    Code: '5221',
    Name: 'Grinder, crankshaft'
  },
  {
    Id: 11807,
    Code: '5221',
    Name: 'Grinder, cutlery'
  },
  {
    Id: 11808,
    Code: '5221',
    Name: 'Grinder, cutter, metal trades'
  },
  {
    Id: 11809,
    Code: '5221',
    Name: 'Grinder, cylinder, metal trades'
  },
  {
    Id: 11810,
    Code: '5221',
    Name: 'Grinder, cylindrical'
  },
  {
    Id: 11811,
    Code: '5221',
    Name: 'Grinder, disc, metal trades'
  },
  {
    Id: 11812,
    Code: '5221',
    Name: 'Grinder, drill'
  },
  {
    Id: 11813,
    Code: '5221',
    Name: 'Grinder, engineering'
  },
  {
    Id: 11814,
    Code: '5221',
    Name: 'Grinder, external'
  },
  {
    Id: 11815,
    Code: '5221',
    Name: 'Grinder, face'
  },
  {
    Id: 11816,
    Code: '5221',
    Name: 'Grinder, file'
  },
  {
    Id: 11817,
    Code: '5221',
    Name: 'Grinder, gear'
  },
  {
    Id: 11818,
    Code: '5221',
    Name: 'Grinder, hob'
  },
  {
    Id: 11819,
    Code: '5221',
    Name: 'Grinder, instrument'
  },
  {
    Id: 11820,
    Code: '5221',
    Name: 'Grinder, internal'
  },
  {
    Id: 11821,
    Code: '5221',
    Name: 'Grinder, jig'
  },
  {
    Id: 11822,
    Code: '5221',
    Name: 'Grinder, knife'
  },
  {
    Id: 11823,
    Code: '5221',
    Name: 'Grinder, machine, metal trades'
  },
  {
    Id: 11824,
    Code: '5221',
    Name: 'Grinder, mower, lawn'
  },
  {
    Id: 11825,
    Code: '5221',
    Name: 'Grinder, precision'
  },
  {
    Id: 11826,
    Code: '5221',
    Name: 'Grinder, profile, optical'
  },
  {
    Id: 11827,
    Code: '5221',
    Name: 'Grinder, race'
  },
  {
    Id: 11828,
    Code: '5221',
    Name: 'Grinder, razor'
  },
  {
    Id: 11829,
    Code: '5221',
    Name: 'Grinder, roll'
  },
  {
    Id: 11830,
    Code: '5221',
    Name: 'Grinder, roller'
  },
  {
    Id: 11831,
    Code: '5221',
    Name: 'Grinder, room, tool'
  },
  {
    Id: 11832,
    Code: '5221',
    Name: 'Grinder, saw'
  },
  {
    Id: 11833,
    Code: '5221',
    Name: 'Grinder, segmental'
  },
  {
    Id: 11834,
    Code: '5221',
    Name: 'Grinder, shaft'
  },
  {
    Id: 11835,
    Code: '5221',
    Name: 'Grinder, shears'
  },
  {
    Id: 11836,
    Code: '5221',
    Name: 'Grinder, spindle'
  },
  {
    Id: 11837,
    Code: '5221',
    Name: 'Grinder, spline'
  },
  {
    Id: 11838,
    Code: '5221',
    Name: 'Grinder, spring'
  },
  {
    Id: 11839,
    Code: '5221',
    Name: 'Grinder, surface'
  },
  {
    Id: 11840,
    Code: '5221',
    Name: 'Grinder, tool, universal'
  },
  {
    Id: 11841,
    Code: '5221',
    Name: 'Grinder, tool'
  },
  {
    Id: 11842,
    Code: '5221',
    Name: 'Grinder, tool and cutter'
  },
  {
    Id: 11843,
    Code: '5221',
    Name: 'Grinder, universal'
  },
  {
    Id: 11844,
    Code: '5221',
    Name: 'Grinder, valve'
  },
  {
    Id: 11845,
    Code: '5221',
    Name: 'Grinder, metal trades'
  },
  {
    Id: 11846,
    Code: '5221',
    Name: 'Grinder'
  },
  {
    Id: 11847,
    Code: '5221',
    Name: 'Grinder and finisher, spring'
  },
  {
    Id: 11848,
    Code: '5221',
    Name: 'Grinder-setter, metal trades'
  },
  {
    Id: 11849,
    Code: '5221',
    Name: 'Machinist, boring, metal'
  },
  {
    Id: 11850,
    Code: '5221',
    Name: 'Machinist, CNC'
  },
  {
    Id: 11851,
    Code: '5221',
    Name: 'Machinist, facing'
  },
  {
    Id: 11852,
    Code: '5221',
    Name: 'Machinist, grinding, metal trades'
  },
  {
    Id: 11853,
    Code: '5221',
    Name: 'Machinist, lathe, metal trades'
  },
  {
    Id: 11854,
    Code: '5221',
    Name: 'Machinist, milling, metal trades'
  },
  {
    Id: 11855,
    Code: '5221',
    Name: 'Maker, bush'
  },
  {
    Id: 11856,
    Code: '5221',
    Name: 'Maker, case, clock'
  },
  {
    Id: 11857,
    Code: '5221',
    Name: 'Maker, case, watch'
  },
  {
    Id: 11858,
    Code: '5221',
    Name: 'Maker, case, watch, clock mfr'
  },
  {
    Id: 11859,
    Code: '5221',
    Name: 'Maker, ferrule, boiler ferrules'
  },
  {
    Id: 11860,
    Code: '5221',
    Name: 'Miller, band, space'
  },
  {
    Id: 11861,
    Code: '5221',
    Name: 'Miller, bayonet'
  },
  {
    Id: 11862,
    Code: '5221',
    Name: 'Miller, broaching'
  },
  {
    Id: 11863,
    Code: '5221',
    Name: 'Miller, CNC'
  },
  {
    Id: 11864,
    Code: '5221',
    Name: 'Miller, concave, needles'
  },
  {
    Id: 11865,
    Code: '5221',
    Name: 'Miller, die'
  },
  {
    Id: 11866,
    Code: '5221',
    Name: 'Miller, engineer'
  },
  {
    Id: 11867,
    Code: '5221',
    Name: "Miller, engineer's"
  },
  {
    Id: 11868,
    Code: '5221',
    Name: 'Miller, engineering'
  },
  {
    Id: 11869,
    Code: '5221',
    Name: 'Miller, horizontal'
  },
  {
    Id: 11870,
    Code: '5221',
    Name: 'Miller, machine'
  },
  {
    Id: 11871,
    Code: '5221',
    Name: 'Miller, metal'
  },
  {
    Id: 11872,
    Code: '5221',
    Name: 'Miller, NC'
  },
  {
    Id: 11873,
    Code: '5221',
    Name: 'Miller, profile'
  },
  {
    Id: 11874,
    Code: '5221',
    Name: 'Miller, room, tool'
  },
  {
    Id: 11875,
    Code: '5221',
    Name: 'Miller, tool'
  },
  {
    Id: 11876,
    Code: '5221',
    Name: 'Miller, universal'
  },
  {
    Id: 11877,
    Code: '5221',
    Name: 'Miller, vertical'
  },
  {
    Id: 11878,
    Code: '5221',
    Name: 'Miller, metal trades'
  },
  {
    Id: 11879,
    Code: '5221',
    Name: 'Miller and turner'
  },
  {
    Id: 11880,
    Code: '5221',
    Name: 'Operative, CNC'
  },
  {
    Id: 11881,
    Code: '5221',
    Name: 'Operator, brake, press'
  },
  {
    Id: 11882,
    Code: '5221',
    Name: 'Operator, CNC'
  },
  {
    Id: 11883,
    Code: '5221',
    Name: 'Operator, lathe, coal mine'
  },
  {
    Id: 11884,
    Code: '5221',
    Name: 'Operator, machine, CNC'
  },
  {
    Id: 11885,
    Code: '5221',
    Name: 'Operator, robot, engineering'
  },
  {
    Id: 11886,
    Code: '5221',
    Name: 'Operator, setter, CNC'
  },
  {
    Id: 11887,
    Code: '5221',
    Name: 'Operator, setter, machine, CNC'
  },
  {
    Id: 11888,
    Code: '5221',
    Name: 'Planer, die'
  },
  {
    Id: 11889,
    Code: '5221',
    Name: 'Planer, metal'
  },
  {
    Id: 11890,
    Code: '5221',
    Name: 'Planer, coal mine'
  },
  {
    Id: 11891,
    Code: '5221',
    Name: 'Planer, metal trades'
  },
  {
    Id: 11892,
    Code: '5221',
    Name: 'Planer and slotter, wall'
  },
  {
    Id: 11893,
    Code: '5221',
    Name: 'Programmer, CNC'
  },
  {
    Id: 11894,
    Code: '5221',
    Name: 'Programmer, control, numerical'
  },
  {
    Id: 11895,
    Code: '5221',
    Name: 'Programmer, machine, metal trades'
  },
  {
    Id: 11896,
    Code: '5221',
    Name: 'Programmer, NC'
  },
  {
    Id: 11897,
    Code: '5221',
    Name: 'Programmer, robot'
  },
  {
    Id: 11898,
    Code: '5221',
    Name: 'Setter, auto, metal trades'
  },
  {
    Id: 11899,
    Code: '5221',
    Name: 'Setter, automatic, metal trades'
  },
  {
    Id: 11900,
    Code: '5221',
    Name: 'Setter, capstan'
  },
  {
    Id: 11901,
    Code: '5221',
    Name: 'Setter, cast, die'
  },
  {
    Id: 11902,
    Code: '5221',
    Name: 'Setter, CNC'
  },
  {
    Id: 11903,
    Code: '5221',
    Name: 'Setter, die'
  },
  {
    Id: 11904,
    Code: '5221',
    Name: 'Setter, drill'
  },
  {
    Id: 11905,
    Code: '5221',
    Name: 'Setter, driller'
  },
  {
    Id: 11906,
    Code: '5221',
    Name: "Setter, engineer's"
  },
  {
    Id: 11907,
    Code: '5221',
    Name: 'Setter, jig'
  },
  {
    Id: 11908,
    Code: '5221',
    Name: 'Setter, lathe, capstan'
  },
  {
    Id: 11909,
    Code: '5221',
    Name: 'Setter, lathe, centre'
  },
  {
    Id: 11910,
    Code: '5221',
    Name: 'Setter, lathe, turret'
  },
  {
    Id: 11911,
    Code: '5221',
    Name: 'Setter, lathe'
  },
  {
    Id: 11912,
    Code: '5221',
    Name: 'Setter, machine, automatic'
  },
  {
    Id: 11913,
    Code: '5221',
    Name: 'Setter, machine, CNC'
  },
  {
    Id: 11914,
    Code: '5221',
    Name: 'Setter, machine, coiling'
  },
  {
    Id: 11915,
    Code: '5221',
    Name: 'Setter, machine, heading, bolts, rivets'
  },
  {
    Id: 11916,
    Code: '5221',
    Name: 'Setter, machine, man-made fibre mfr'
  },
  {
    Id: 11917,
    Code: '5221',
    Name: 'Setter, machine, metal trades'
  },
  {
    Id: 11918,
    Code: '5221',
    Name: 'Setter, milling'
  },
  {
    Id: 11919,
    Code: '5221',
    Name: 'Setter, press, CNC'
  },
  {
    Id: 11920,
    Code: '5221',
    Name: 'Setter, press, NC'
  },
  {
    Id: 11921,
    Code: '5221',
    Name: 'Setter, press, power'
  },
  {
    Id: 11922,
    Code: '5221',
    Name: 'Setter, press, metal trades'
  },
  {
    Id: 11923,
    Code: '5221',
    Name: 'Setter, roll, steelworks'
  },
  {
    Id: 11924,
    Code: '5221',
    Name: 'Setter, spindle'
  },
  {
    Id: 11925,
    Code: '5221',
    Name: 'Setter, tool, machine'
  },
  {
    Id: 11926,
    Code: '5221',
    Name: 'Setter, tool, press'
  },
  {
    Id: 11927,
    Code: '5221',
    Name: 'Setter, tool'
  },
  {
    Id: 11928,
    Code: '5221',
    Name: 'Setter, metal trades'
  },
  {
    Id: 11929,
    Code: '5221',
    Name: 'Setter and turner, lathe'
  },
  {
    Id: 11930,
    Code: '5221',
    Name: 'Setter-operator, capstan'
  },
  {
    Id: 11931,
    Code: '5221',
    Name: 'Setter-operator, CNC'
  },
  {
    Id: 11932,
    Code: '5221',
    Name: 'Setter-operator, drill'
  },
  {
    Id: 11933,
    Code: '5221',
    Name: 'Setter-operator, lathe, capstan'
  },
  {
    Id: 11934,
    Code: '5221',
    Name: 'Setter-operator, lathe, centre'
  },
  {
    Id: 11935,
    Code: '5221',
    Name: 'Setter-operator, lathe, turning, roll'
  },
  {
    Id: 11936,
    Code: '5221',
    Name: 'Setter-operator, lathe, turret'
  },
  {
    Id: 11937,
    Code: '5221',
    Name: 'Setter-operator, lathe'
  },
  {
    Id: 11938,
    Code: '5221',
    Name: 'Setter-operator, machine, CNC'
  },
  {
    Id: 11939,
    Code: '5221',
    Name: 'Setter-operator, machine, die-sinking'
  },
  {
    Id: 11940,
    Code: '5221',
    Name: 'Setter-operator, machine, NC'
  },
  {
    Id: 11941,
    Code: '5221',
    Name: 'Setter-operator, machine, metal trades'
  },
  {
    Id: 11942,
    Code: '5221',
    Name: 'Setter-operator, press, brake'
  },
  {
    Id: 11943,
    Code: '5221',
    Name: 'Setter-operator, press, metal trades'
  },
  {
    Id: 11944,
    Code: '5221',
    Name: 'Setter-operator, tool, machine'
  },
  {
    Id: 11945,
    Code: '5221',
    Name: 'Setter-operator, tool, press'
  },
  {
    Id: 11946,
    Code: '5221',
    Name: 'Setter-operator, tool'
  },
  {
    Id: 11947,
    Code: '5221',
    Name: 'Setter-operator, turret'
  },
  {
    Id: 11948,
    Code: '5221',
    Name: 'Setter-operator, metal trades'
  },
  {
    Id: 11949,
    Code: '5221',
    Name: 'Setter-up, metal trades'
  },
  {
    Id: 11950,
    Code: '5221',
    Name: 'Shaper, room, tool'
  },
  {
    Id: 11951,
    Code: '5221',
    Name: 'Shaper, tool, machine'
  },
  {
    Id: 11952,
    Code: '5221',
    Name: 'Slider, metal trades'
  },
  {
    Id: 11953,
    Code: '5221',
    Name: 'Spinner, metal'
  },
  {
    Id: 11954,
    Code: '5221',
    Name: 'Tackler, wire weaving'
  },
  {
    Id: 11955,
    Code: '5221',
    Name: 'Technician, CNC'
  },
  {
    Id: 11956,
    Code: '5221',
    Name: 'Toolsetter'
  },
  {
    Id: 11957,
    Code: '5221',
    Name: 'Turner, axle'
  },
  {
    Id: 11958,
    Code: '5221',
    Name: 'Turner, band, copper'
  },
  {
    Id: 11959,
    Code: '5221',
    Name: 'Turner, bobbin, metal'
  },
  {
    Id: 11960,
    Code: '5221',
    Name: 'Turner, boss, centre'
  },
  {
    Id: 11961,
    Code: '5221',
    Name: 'Turner, bush, axle'
  },
  {
    Id: 11962,
    Code: '5221',
    Name: 'Turner, capstan'
  },
  {
    Id: 11963,
    Code: '5221',
    Name: 'Turner, CNC'
  },
  {
    Id: 11964,
    Code: '5221',
    Name: 'Turner, commutator'
  },
  {
    Id: 11965,
    Code: '5221',
    Name: 'Turner, crank'
  },
  {
    Id: 11966,
    Code: '5221',
    Name: 'Turner, crankshaft'
  },
  {
    Id: 11967,
    Code: '5221',
    Name: 'Turner, cutter'
  },
  {
    Id: 11968,
    Code: '5221',
    Name: 'Turner, die'
  },
  {
    Id: 11969,
    Code: '5221',
    Name: 'Turner, engine'
  },
  {
    Id: 11970,
    Code: '5221',
    Name: "Turner, engraver's"
  },
  {
    Id: 11971,
    Code: '5221',
    Name: 'Turner, general'
  },
  {
    Id: 11972,
    Code: '5221',
    Name: 'Turner, gun'
  },
  {
    Id: 11973,
    Code: '5221',
    Name: 'Turner, hand, metal trades'
  },
  {
    Id: 11974,
    Code: '5221',
    Name: 'Turner, lathe, capstan'
  },
  {
    Id: 11975,
    Code: '5221',
    Name: 'Turner, lathe, centre'
  },
  {
    Id: 11976,
    Code: '5221',
    Name: 'Turner, lathe, CNC'
  },
  {
    Id: 11977,
    Code: '5221',
    Name: 'Turner, lathe, NC'
  },
  {
    Id: 11978,
    Code: '5221',
    Name: 'Turner, lathe, turret'
  },
  {
    Id: 11979,
    Code: '5221',
    Name: 'Turner, lathe, metal trades'
  },
  {
    Id: 11980,
    Code: '5221',
    Name: 'Turner, locomotive'
  },
  {
    Id: 11981,
    Code: '5221',
    Name: 'Turner, loom'
  },
  {
    Id: 11982,
    Code: '5221',
    Name: 'Turner, maintenance'
  },
  {
    Id: 11983,
    Code: '5221',
    Name: 'Turner, marine'
  },
  {
    Id: 11984,
    Code: '5221',
    Name: 'Turner, mould, glass mfr'
  },
  {
    Id: 11985,
    Code: '5221',
    Name: 'Turner, ring'
  },
  {
    Id: 11986,
    Code: '5221',
    Name: 'Turner, roll'
  },
  {
    Id: 11987,
    Code: '5221',
    Name: 'Turner, roller, metal'
  },
  {
    Id: 11988,
    Code: '5221',
    Name: 'Turner, roller, textile machinery mfr'
  },
  {
    Id: 11989,
    Code: '5221',
    Name: 'Turner, room, tool'
  },
  {
    Id: 11990,
    Code: '5221',
    Name: 'Turner, rough'
  },
  {
    Id: 11991,
    Code: '5221',
    Name: 'Turner, textile'
  },
  {
    Id: 11992,
    Code: '5221',
    Name: 'Turner, tool'
  },
  {
    Id: 11993,
    Code: '5221',
    Name: 'Turner, tube, steel'
  },
  {
    Id: 11994,
    Code: '5221',
    Name: 'Turner, tyre, metal'
  },
  {
    Id: 11995,
    Code: '5221',
    Name: 'Turner, valve'
  },
  {
    Id: 11996,
    Code: '5221',
    Name: 'Turner, wheel, metal trades'
  },
  {
    Id: 11997,
    Code: '5221',
    Name: 'Turner, metal'
  },
  {
    Id: 11998,
    Code: '5221',
    Name: 'Turner'
  },
  {
    Id: 11999,
    Code: '5221',
    Name: 'Turner-engineer'
  },
  {
    Id: 12000,
    Code: '5222',
    Name: 'Corrector, die'
  },
  {
    Id: 12001,
    Code: '5222',
    Name: 'Cutter, mould'
  },
  {
    Id: 12002,
    Code: '5222',
    Name: 'Cutter, pattern, metal'
  },
  {
    Id: 12003,
    Code: '5222',
    Name: 'Cutter, tool, metal trades'
  },
  {
    Id: 12004,
    Code: '5222',
    Name: 'Doctor, tool'
  },
  {
    Id: 12005,
    Code: '5222',
    Name: 'Engineer, making, tool'
  },
  {
    Id: 12006,
    Code: '5222',
    Name: 'Engineer, room, tool'
  },
  {
    Id: 12007,
    Code: '5222',
    Name: 'Engineer, tool'
  },
  {
    Id: 12008,
    Code: '5222',
    Name: 'Engineer, tooling'
  },
  {
    Id: 12009,
    Code: '5222',
    Name: 'Engineer-toolmaker'
  },
  {
    Id: 12010,
    Code: '5222',
    Name: 'Fettler, tool'
  },
  {
    Id: 12011,
    Code: '5222',
    Name: 'Fitter, die'
  },
  {
    Id: 12012,
    Code: '5222',
    Name: 'Fitter, hire, tool'
  },
  {
    Id: 12013,
    Code: '5222',
    Name: 'Fitter, jig and tool'
  },
  {
    Id: 12014,
    Code: '5222',
    Name: 'Fitter, pattern, engineering'
  },
  {
    Id: 12015,
    Code: '5222',
    Name: 'Fitter, room, tool'
  },
  {
    Id: 12016,
    Code: '5222',
    Name: 'Fitter, tool, edge'
  },
  {
    Id: 12017,
    Code: '5222',
    Name: 'Fitter, tool, press'
  },
  {
    Id: 12018,
    Code: '5222',
    Name: 'Fitter, tool'
  },
  {
    Id: 12019,
    Code: '5222',
    Name: 'Fitter and marker-off'
  },
  {
    Id: 12020,
    Code: '5222',
    Name: 'Liner, safe mfr'
  },
  {
    Id: 12021,
    Code: '5222',
    Name: 'Liner-off, engineering'
  },
  {
    Id: 12022,
    Code: '5222',
    Name: 'Liner-out, engineering'
  },
  {
    Id: 12023,
    Code: '5222',
    Name: 'Liner-up, engineering'
  },
  {
    Id: 12024,
    Code: '5222',
    Name: 'Maker, die'
  },
  {
    Id: 12025,
    Code: '5222',
    Name: 'Maker, die and tool'
  },
  {
    Id: 12026,
    Code: '5222',
    Name: 'Maker, forme, paper box mfr'
  },
  {
    Id: 12027,
    Code: '5222',
    Name: 'Maker, gauge, metal trades'
  },
  {
    Id: 12028,
    Code: '5222',
    Name: 'Maker, jig'
  },
  {
    Id: 12029,
    Code: '5222',
    Name: 'Maker, jig and gauge'
  },
  {
    Id: 12030,
    Code: '5222',
    Name: 'Maker, mould, tool, press'
  },
  {
    Id: 12031,
    Code: '5222',
    Name: 'Maker, mould, fibreglass mfr'
  },
  {
    Id: 12032,
    Code: '5222',
    Name: 'Maker, mould, plastics goods mfr'
  },
  {
    Id: 12033,
    Code: '5222',
    Name: 'Maker, template'
  },
  {
    Id: 12034,
    Code: '5222',
    Name: 'Maker, tool, diamond'
  },
  {
    Id: 12035,
    Code: '5222',
    Name: 'Maker, tool, machine'
  },
  {
    Id: 12036,
    Code: '5222',
    Name: 'Maker, tool, press'
  },
  {
    Id: 12037,
    Code: '5222',
    Name: 'Maker, tool'
  },
  {
    Id: 12038,
    Code: '5222',
    Name: 'Man, table, surface'
  },
  {
    Id: 12039,
    Code: '5222',
    Name: 'Marker, part'
  },
  {
    Id: 12040,
    Code: '5222',
    Name: 'Marker, metal scales mfr'
  },
  {
    Id: 12041,
    Code: '5222',
    Name: 'Marker, metal trades: engineering'
  },
  {
    Id: 12042,
    Code: '5222',
    Name: 'Marker-off, engineering'
  },
  {
    Id: 12043,
    Code: '5222',
    Name: 'Marker-out, engineering'
  },
  {
    Id: 12044,
    Code: '5222',
    Name: 'Repairer, die'
  },
  {
    Id: 12045,
    Code: '5222',
    Name: 'Repairer, tool'
  },
  {
    Id: 12046,
    Code: '5222',
    Name: 'Scriber'
  },
  {
    Id: 12047,
    Code: '5222',
    Name: 'Setter, gauge'
  },
  {
    Id: 12048,
    Code: '5222',
    Name: 'Setter-out, engineering'
  },
  {
    Id: 12049,
    Code: '5222',
    Name: 'Setter-out, metal trades'
  },
  {
    Id: 12050,
    Code: '5222',
    Name: 'Sinker, die, metal trades'
  },
  {
    Id: 12051,
    Code: '5222',
    Name: 'Templater'
  },
  {
    Id: 12052,
    Code: '5222',
    Name: 'Toolmaker, press'
  },
  {
    Id: 12053,
    Code: '5222',
    Name: 'Toolmaker'
  },
  {
    Id: 12054,
    Code: '5223',
    Name: 'Adjuster, brake'
  },
  {
    Id: 12055,
    Code: '5223',
    Name: 'Adjuster, machine, textile mfr'
  },
  {
    Id: 12056,
    Code: '5223',
    Name: 'Adjuster, spring, set'
  },
  {
    Id: 12057,
    Code: '5223',
    Name: 'Adjuster, scales'
  },
  {
    Id: 12058,
    Code: '5223',
    Name: 'Adjuster, weighing machines'
  },
  {
    Id: 12059,
    Code: '5223',
    Name: 'Aligner, typewriters'
  },
  {
    Id: 12060,
    Code: '5223',
    Name: 'Armourer'
  },
  {
    Id: 12061,
    Code: '5223',
    Name: 'Balancer, flyer'
  },
  {
    Id: 12062,
    Code: '5223',
    Name: 'Blader, turbines'
  },
  {
    Id: 12063,
    Code: '5223',
    Name: 'Blocker, lifting tackle mfr'
  },
  {
    Id: 12064,
    Code: '5223',
    Name: 'Builder, conveyor'
  },
  {
    Id: 12065,
    Code: '5223',
    Name: 'Builder, cycle'
  },
  {
    Id: 12066,
    Code: '5223',
    Name: 'Builder, engine'
  },
  {
    Id: 12067,
    Code: '5223',
    Name: 'Builder, frame, cycle mfr'
  },
  {
    Id: 12068,
    Code: '5223',
    Name: 'Builder, lathe'
  },
  {
    Id: 12069,
    Code: '5223',
    Name: 'Builder, loom'
  },
  {
    Id: 12070,
    Code: '5223',
    Name: 'Builder, machine'
  },
  {
    Id: 12071,
    Code: '5223',
    Name: 'Builder, motor'
  },
  {
    Id: 12072,
    Code: '5223',
    Name: 'Builder, table, sewing machine'
  },
  {
    Id: 12073,
    Code: '5223',
    Name: 'Builder, tool, machine'
  },
  {
    Id: 12074,
    Code: '5223',
    Name: 'Cleaner and balancer, weighing machines'
  },
  {
    Id: 12075,
    Code: '5223',
    Name: 'Controller, maintenance, planned'
  },
  {
    Id: 12076,
    Code: '5223',
    Name: 'Controller, maintenance, plant'
  },
  {
    Id: 12077,
    Code: '5223',
    Name: 'Controller, plant'
  },
  {
    Id: 12078,
    Code: '5223',
    Name: 'Corrector, spring, vehicle mfr'
  },
  {
    Id: 12079,
    Code: '5223',
    Name: 'Craftsman, engineering'
  },
  {
    Id: 12080,
    Code: '5223',
    Name: 'Craftsman, enhanced, power station'
  },
  {
    Id: 12081,
    Code: '5223',
    Name: 'Craftsman, maintenance, mechanical'
  },
  {
    Id: 12082,
    Code: '5223',
    Name: 'Craftsman, mechanical'
  },
  {
    Id: 12083,
    Code: '5223',
    Name: 'Craftsman, underground, coal mine'
  },
  {
    Id: 12084,
    Code: '5223',
    Name: 'Engineer, agricultural'
  },
  {
    Id: 12085,
    Code: '5223',
    Name: 'Engineer, armament'
  },
  {
    Id: 12086,
    Code: '5223',
    Name: 'Engineer, assembly, vehicle mfr'
  },
  {
    Id: 12087,
    Code: '5223',
    Name: 'Engineer, assistant, mechanical'
  },
  {
    Id: 12088,
    Code: '5223',
    Name: 'Engineer, assistant, unit, coal mine'
  },
  {
    Id: 12089,
    Code: '5223',
    Name: 'Engineer, bakery'
  },
  {
    Id: 12090,
    Code: '5223',
    Name: "Engineer, brewer's"
  },
  {
    Id: 12091,
    Code: '5223',
    Name: 'Engineer, carding'
  },
  {
    Id: 12092,
    Code: '5223',
    Name: 'Engineer, catering'
  },
  {
    Id: 12093,
    Code: '5223',
    Name: 'Engineer, corrosion'
  },
  {
    Id: 12094,
    Code: '5223',
    Name: 'Engineer, crane'
  },
  {
    Id: 12095,
    Code: '5223',
    Name: 'Engineer, cycle'
  },
  {
    Id: 12096,
    Code: '5223',
    Name: 'Engineer, dairy'
  },
  {
    Id: 12097,
    Code: '5223',
    Name: 'Engineer, depot, transport'
  },
  {
    Id: 12098,
    Code: '5223',
    Name: 'Engineer, diesel, vehicles, vehicle mfr'
  },
  {
    Id: 12099,
    Code: '5223',
    Name: 'Engineer, district'
  },
  {
    Id: 12100,
    Code: '5223',
    Name: 'Engineer, dockyard'
  },
  {
    Id: 12101,
    Code: '5223',
    Name: 'Engineer, elevators, grain'
  },
  {
    Id: 12102,
    Code: '5223',
    Name: 'Engineer, equipment, catering'
  },
  {
    Id: 12103,
    Code: '5223',
    Name: 'Engineer, erection'
  },
  {
    Id: 12104,
    Code: '5223',
    Name: 'Engineer, estate'
  },
  {
    Id: 12105,
    Code: '5223',
    Name: 'Engineer, excavator'
  },
  {
    Id: 12106,
    Code: '5223',
    Name: 'Engineer, extinguisher, fire'
  },
  {
    Id: 12107,
    Code: '5223',
    Name: 'Engineer, fabrication'
  },
  {
    Id: 12108,
    Code: '5223',
    Name: 'Engineer, field, mechanical'
  },
  {
    Id: 12109,
    Code: '5223',
    Name: 'Engineer, filter'
  },
  {
    Id: 12110,
    Code: '5223',
    Name: 'Engineer, forklift'
  },
  {
    Id: 12111,
    Code: '5223',
    Name: 'Engineer, foundry'
  },
  {
    Id: 12112,
    Code: '5223',
    Name: 'Engineer, furnace'
  },
  {
    Id: 12113,
    Code: '5223',
    Name: 'Engineer, ground'
  },
  {
    Id: 12114,
    Code: '5223',
    Name: 'Engineer, heavy'
  },
  {
    Id: 12115,
    Code: '5223',
    Name: 'Engineer, horticultural'
  },
  {
    Id: 12116,
    Code: '5223',
    Name: 'Engineer, hosiery'
  },
  {
    Id: 12117,
    Code: '5223',
    Name: 'Engineer, hospital'
  },
  {
    Id: 12118,
    Code: '5223',
    Name: 'Engineer, House, Trinity'
  },
  {
    Id: 12119,
    Code: '5223',
    Name: 'Engineer, hovercraft'
  },
  {
    Id: 12120,
    Code: '5223',
    Name: 'Engineer, hydraulic'
  },
  {
    Id: 12121,
    Code: '5223',
    Name: 'Engineer, installation'
  },
  {
    Id: 12122,
    Code: '5223',
    Name: 'Engineer, investigation, defect'
  },
  {
    Id: 12123,
    Code: '5223',
    Name: 'Engineer, laundry'
  },
  {
    Id: 12124,
    Code: '5223',
    Name: 'Engineer, lift'
  },
  {
    Id: 12125,
    Code: '5223',
    Name: 'Engineer, light'
  },
  {
    Id: 12126,
    Code: '5223',
    Name: 'Engineer, line, oil refining'
  },
  {
    Id: 12127,
    Code: '5223',
    Name: 'Engineer, machine'
  },
  {
    Id: 12128,
    Code: '5223',
    Name: 'Engineer, machinery'
  },
  {
    Id: 12129,
    Code: '5223',
    Name: 'Engineer, machines'
  },
  {
    Id: 12130,
    Code: '5223',
    Name: 'Engineer, maintenance, mechanical'
  },
  {
    Id: 12131,
    Code: '5223',
    Name: 'Engineer, maintenance, plant'
  },
  {
    Id: 12132,
    Code: '5223',
    Name: 'Engineer, maintenance'
  },
  {
    Id: 12133,
    Code: '5223',
    Name: 'Engineer, mechanical'
  },
  {
    Id: 12134,
    Code: '5223',
    Name: 'Engineer, mechanical and electrical'
  },
  {
    Id: 12135,
    Code: '5223',
    Name: 'Engineer, mill'
  },
  {
    Id: 12136,
    Code: '5223',
    Name: 'Engineer, model'
  },
  {
    Id: 12137,
    Code: '5223',
    Name: 'Engineer, monotype'
  },
  {
    Id: 12138,
    Code: '5223',
    Name: 'Engineer, mower'
  },
  {
    Id: 12139,
    Code: '5223',
    Name: 'Engineer, paper'
  },
  {
    Id: 12140,
    Code: '5223',
    Name: 'Engineer, plant'
  },
  {
    Id: 12141,
    Code: '5223',
    Name: 'Engineer, pneumatic'
  },
  {
    Id: 12142,
    Code: '5223',
    Name: "Engineer, potter's"
  },
  {
    Id: 12143,
    Code: '5223',
    Name: 'Engineer, press, rotary'
  },
  {
    Id: 12144,
    Code: '5223',
    Name: "Engineer, printer's"
  },
  {
    Id: 12145,
    Code: '5223',
    Name: 'Engineer, protection, fire'
  },
  {
    Id: 12146,
    Code: '5223',
    Name: 'Engineer, prototype'
  },
  {
    Id: 12147,
    Code: '5223',
    Name: 'Engineer, pump'
  },
  {
    Id: 12148,
    Code: '5223',
    Name: 'Engineer, retort'
  },
  {
    Id: 12149,
    Code: '5223',
    Name: 'Engineer, safe'
  },
  {
    Id: 12150,
    Code: '5223',
    Name: 'Engineer, scribbling'
  },
  {
    Id: 12151,
    Code: '5223',
    Name: 'Engineer, second, textile mfr'
  },
  {
    Id: 12152,
    Code: '5223',
    Name: 'Engineer, service, extinguisher, fire'
  },
  {
    Id: 12153,
    Code: '5223',
    Name: 'Engineer, service, hydraulic'
  },
  {
    Id: 12154,
    Code: '5223',
    Name: 'Engineer, service, lift'
  },
  {
    Id: 12155,
    Code: '5223',
    Name: 'Engineer, service, mechanical'
  },
  {
    Id: 12156,
    Code: '5223',
    Name: 'Engineer, service, protection, fire'
  },
  {
    Id: 12157,
    Code: '5223',
    Name: 'Engineer, service, truck, lift, fork'
  },
  {
    Id: 12158,
    Code: '5223',
    Name: 'Engineer, service, engineering'
  },
  {
    Id: 12159,
    Code: '5223',
    Name: 'Engineer, service, oil company'
  },
  {
    Id: 12160,
    Code: '5223',
    Name: 'Engineer, services, mechanical'
  },
  {
    Id: 12161,
    Code: '5223',
    Name: 'Engineer, shafting'
  },
  {
    Id: 12162,
    Code: '5223',
    Name: 'Engineer, shift'
  },
  {
    Id: 12163,
    Code: '5223',
    Name: 'Engineer, shop, machine'
  },
  {
    Id: 12164,
    Code: '5223',
    Name: 'Engineer, station, pumping'
  },
  {
    Id: 12165,
    Code: '5223',
    Name: 'Engineer, station, oil refining'
  },
  {
    Id: 12166,
    Code: '5223',
    Name: 'Engineer, textile'
  },
  {
    Id: 12167,
    Code: '5223',
    Name: 'Engineer, tool, machine'
  },
  {
    Id: 12168,
    Code: '5223',
    Name: 'Engineer, traffic, air'
  },
  {
    Id: 12169,
    Code: '5223',
    Name: 'Engineer, truck, lift, fork'
  },
  {
    Id: 12170,
    Code: '5223',
    Name: 'Engineer, works, sewage'
  },
  {
    Id: 12171,
    Code: '5223',
    Name: 'Engineer, works, water'
  },
  {
    Id: 12172,
    Code: '5223',
    Name: 'Engineer, workshop'
  },
  {
    Id: 12173,
    Code: '5223',
    Name: 'Engineer, nos, coal mine: below ground'
  },
  {
    Id: 12174,
    Code: '5223',
    Name: 'Engineer-fitter'
  },
  {
    Id: 12175,
    Code: '5223',
    Name: 'Enginewright'
  },
  {
    Id: 12176,
    Code: '5223',
    Name: 'Erector, chassis'
  },
  {
    Id: 12177,
    Code: '5223',
    Name: 'Erector, conveyor'
  },
  {
    Id: 12178,
    Code: '5223',
    Name: 'Erector, engine'
  },
  {
    Id: 12179,
    Code: '5223',
    Name: "Erector, engineer's"
  },
  {
    Id: 12180,
    Code: '5223',
    Name: 'Erector, frame, vehicle mfr'
  },
  {
    Id: 12181,
    Code: '5223',
    Name: 'Erector, lift'
  },
  {
    Id: 12182,
    Code: '5223',
    Name: 'Erector, locomotive'
  },
  {
    Id: 12183,
    Code: '5223',
    Name: 'Erector, loom'
  },
  {
    Id: 12184,
    Code: '5223',
    Name: 'Erector, machine'
  },
  {
    Id: 12185,
    Code: '5223',
    Name: 'Erector, plant'
  },
  {
    Id: 12186,
    Code: '5223',
    Name: 'Erector, pump'
  },
  {
    Id: 12187,
    Code: '5223',
    Name: 'Erector, machinery'
  },
  {
    Id: 12188,
    Code: '5223',
    Name: 'Erector, coal mine'
  },
  {
    Id: 12189,
    Code: '5223',
    Name: 'Erector, engineering'
  },
  {
    Id: 12190,
    Code: '5223',
    Name: 'Erector-fitter'
  },
  {
    Id: 12191,
    Code: '5223',
    Name: 'Fabricator, aluminium'
  },
  {
    Id: 12192,
    Code: '5223',
    Name: 'Fabricator'
  },
  {
    Id: 12193,
    Code: '5223',
    Name: 'Finisher, chassis'
  },
  {
    Id: 12194,
    Code: '5223',
    Name: 'Finisher, cycle'
  },
  {
    Id: 12195,
    Code: '5223',
    Name: 'Finisher, spring, car, motor'
  },
  {
    Id: 12196,
    Code: '5223',
    Name: 'Finisher, spring, coach'
  },
  {
    Id: 12197,
    Code: '5223',
    Name: 'Fitter, agricultural'
  },
  {
    Id: 12198,
    Code: '5223',
    Name: 'Fitter, anchor'
  },
  {
    Id: 12199,
    Code: '5223',
    Name: 'Fitter, armament'
  },
  {
    Id: 12200,
    Code: '5223',
    Name: 'Fitter, assembly'
  },
  {
    Id: 12201,
    Code: '5223',
    Name: 'Fitter, axle'
  },
  {
    Id: 12202,
    Code: '5223',
    Name: 'Fitter, battery'
  },
  {
    Id: 12203,
    Code: '5223',
    Name: 'Fitter, beam'
  },
  {
    Id: 12204,
    Code: '5223',
    Name: 'Fitter, bearing, brass'
  },
  {
    Id: 12205,
    Code: '5223',
    Name: 'Fitter, bench'
  },
  {
    Id: 12206,
    Code: '5223',
    Name: 'Fitter, blade, turbines'
  },
  {
    Id: 12207,
    Code: '5223',
    Name: 'Fitter, boiler'
  },
  {
    Id: 12208,
    Code: '5223',
    Name: 'Fitter, box, axle'
  },
  {
    Id: 12209,
    Code: '5223',
    Name: 'Fitter, box, cam'
  },
  {
    Id: 12210,
    Code: '5223',
    Name: 'Fitter, box, gear'
  },
  {
    Id: 12211,
    Code: '5223',
    Name: 'Fitter, box, iron'
  },
  {
    Id: 12212,
    Code: '5223',
    Name: 'Fitter, brake, vacuum'
  },
  {
    Id: 12213,
    Code: '5223',
    Name: 'Fitter, brake, Westinghouse'
  },
  {
    Id: 12214,
    Code: '5223',
    Name: 'Fitter, brake'
  },
  {
    Id: 12215,
    Code: '5223',
    Name: 'Fitter, brass'
  },
  {
    Id: 12216,
    Code: '5223',
    Name: 'Fitter, break-off'
  },
  {
    Id: 12217,
    Code: '5223',
    Name: 'Fitter, cabinet, iron'
  },
  {
    Id: 12218,
    Code: '5223',
    Name: 'Fitter, car, vehicle mfr'
  },
  {
    Id: 12219,
    Code: '5223',
    Name: 'Fitter, casement, metal'
  },
  {
    Id: 12220,
    Code: '5223',
    Name: 'Fitter, chain'
  },
  {
    Id: 12221,
    Code: '5223',
    Name: 'Fitter, chassis'
  },
  {
    Id: 12222,
    Code: '5223',
    Name: 'Fitter, coach'
  },
  {
    Id: 12223,
    Code: '5223',
    Name: 'Fitter, cock'
  },
  {
    Id: 12224,
    Code: '5223',
    Name: 'Fitter, colliery'
  },
  {
    Id: 12225,
    Code: '5223',
    Name: 'Fitter, component, cycle'
  },
  {
    Id: 12226,
    Code: '5223',
    Name: 'Fitter, constructional'
  },
  {
    Id: 12227,
    Code: '5223',
    Name: 'Fitter, conveyor'
  },
  {
    Id: 12228,
    Code: '5223',
    Name: 'Fitter, crane'
  },
  {
    Id: 12229,
    Code: '5223',
    Name: 'Fitter, cycle'
  },
  {
    Id: 12230,
    Code: '5223',
    Name: 'Fitter, detail'
  },
  {
    Id: 12231,
    Code: '5223',
    Name: 'Fitter, development'
  },
  {
    Id: 12232,
    Code: '5223',
    Name: 'Fitter, diesel, vehicles, vehicle mfr'
  },
  {
    Id: 12233,
    Code: '5223',
    Name: 'Fitter, dock'
  },
  {
    Id: 12234,
    Code: '5223',
    Name: 'Fitter, door, car'
  },
  {
    Id: 12235,
    Code: '5223',
    Name: 'Fitter, door, garage'
  },
  {
    Id: 12236,
    Code: '5223',
    Name: 'Fitter, door, industrial'
  },
  {
    Id: 12237,
    Code: '5223',
    Name: 'Fitter, door, steel'
  },
  {
    Id: 12238,
    Code: '5223',
    Name: 'Fitter, duct'
  },
  {
    Id: 12239,
    Code: '5223',
    Name: 'Fitter, engine'
  },
  {
    Id: 12240,
    Code: '5223',
    Name: "Fitter, engineer's"
  },
  {
    Id: 12241,
    Code: '5223',
    Name: 'Fitter, engineering'
  },
  {
    Id: 12242,
    Code: '5223',
    Name: 'Fitter, erection'
  },
  {
    Id: 12243,
    Code: '5223',
    Name: 'Fitter, excavator'
  },
  {
    Id: 12244,
    Code: '5223',
    Name: 'Fitter, experimental'
  },
  {
    Id: 12245,
    Code: '5223',
    Name: 'Fitter, fabrication'
  },
  {
    Id: 12246,
    Code: '5223',
    Name: 'Fitter, frame, door, metal'
  },
  {
    Id: 12247,
    Code: '5223',
    Name: 'Fitter, frame, ring'
  },
  {
    Id: 12248,
    Code: '5223',
    Name: 'Fitter, frame, cycle mfr'
  },
  {
    Id: 12249,
    Code: '5223',
    Name: 'Fitter, frame, textile machinery mfr'
  },
  {
    Id: 12250,
    Code: '5223',
    Name: 'Fitter, furnace'
  },
  {
    Id: 12251,
    Code: '5223',
    Name: 'Fitter, furniture, metal'
  },
  {
    Id: 12252,
    Code: '5223',
    Name: 'Fitter, gear'
  },
  {
    Id: 12253,
    Code: '5223',
    Name: 'Fitter, general'
  },
  {
    Id: 12254,
    Code: '5223',
    Name: 'Fitter, grate'
  },
  {
    Id: 12255,
    Code: '5223',
    Name: 'Fitter, grindstone'
  },
  {
    Id: 12256,
    Code: '5223',
    Name: 'Fitter, ground, below, coal mine'
  },
  {
    Id: 12257,
    Code: '5223',
    Name: 'Fitter, gun'
  },
  {
    Id: 12258,
    Code: '5223',
    Name: 'Fitter, house, light'
  },
  {
    Id: 12259,
    Code: '5223',
    Name: 'Fitter, house, power'
  },
  {
    Id: 12260,
    Code: '5223',
    Name: 'Fitter, hydraulic'
  },
  {
    Id: 12261,
    Code: '5223',
    Name: 'Fitter, industrial'
  },
  {
    Id: 12262,
    Code: '5223',
    Name: 'Fitter, injection, fuel'
  },
  {
    Id: 12263,
    Code: '5223',
    Name: 'Fitter, inspector'
  },
  {
    Id: 12264,
    Code: '5223',
    Name: 'Fitter, installation'
  },
  {
    Id: 12265,
    Code: '5223',
    Name: "Fitter, ironmonger's"
  },
  {
    Id: 12266,
    Code: '5223',
    Name: 'Fitter, ironmongery'
  },
  {
    Id: 12267,
    Code: '5223',
    Name: 'Fitter, ironwork'
  },
  {
    Id: 12268,
    Code: '5223',
    Name: 'Fitter, keg'
  },
  {
    Id: 12269,
    Code: '5223',
    Name: 'Fitter, knife'
  },
  {
    Id: 12270,
    Code: '5223',
    Name: 'Fitter, laboratory'
  },
  {
    Id: 12271,
    Code: '5223',
    Name: 'Fitter, lift'
  },
  {
    Id: 12272,
    Code: '5223',
    Name: 'Fitter, lino, linotype machine'
  },
  {
    Id: 12273,
    Code: '5223',
    Name: 'Fitter, linotype'
  },
  {
    Id: 12274,
    Code: '5223',
    Name: 'Fitter, loom'
  },
  {
    Id: 12275,
    Code: '5223',
    Name: 'Fitter, machine'
  },
  {
    Id: 12276,
    Code: '5223',
    Name: 'Fitter, maintenance'
  },
  {
    Id: 12277,
    Code: '5223',
    Name: 'Fitter, mattress, wire'
  },
  {
    Id: 12278,
    Code: '5223',
    Name: 'Fitter, mechanical, vehicles, vehicle mfr'
  },
  {
    Id: 12279,
    Code: '5223',
    Name: 'Fitter, mechanical'
  },
  {
    Id: 12280,
    Code: '5223',
    Name: 'Fitter, metal'
  },
  {
    Id: 12281,
    Code: '5223',
    Name: 'Fitter, motor, vehicle mfr'
  },
  {
    Id: 12282,
    Code: '5223',
    Name: 'Fitter, mould, bottle'
  },
  {
    Id: 12283,
    Code: '5223',
    Name: 'Fitter, mount, boiler'
  },
  {
    Id: 12284,
    Code: '5223',
    Name: 'Fitter, mule'
  },
  {
    Id: 12285,
    Code: '5223',
    Name: "Fitter, nicker and turner's"
  },
  {
    Id: 12286,
    Code: '5223',
    Name: 'Fitter, ordnance'
  },
  {
    Id: 12287,
    Code: '5223',
    Name: 'Fitter, oven'
  },
  {
    Id: 12288,
    Code: '5223',
    Name: 'Fitter, plant'
  },
  {
    Id: 12289,
    Code: '5223',
    Name: "Fitter, potter's"
  },
  {
    Id: 12290,
    Code: '5223',
    Name: 'Fitter, precision'
  },
  {
    Id: 12291,
    Code: '5223',
    Name: 'Fitter, printing'
  },
  {
    Id: 12292,
    Code: '5223',
    Name: 'Fitter, production, mechanical'
  },
  {
    Id: 12293,
    Code: '5223',
    Name: 'Fitter, pump'
  },
  {
    Id: 12294,
    Code: '5223',
    Name: 'Fitter, range'
  },
  {
    Id: 12295,
    Code: '5223',
    Name: 'Fitter, rectification'
  },
  {
    Id: 12296,
    Code: '5223',
    Name: 'Fitter, repair, engine'
  },
  {
    Id: 12297,
    Code: '5223',
    Name: 'Fitter, research'
  },
  {
    Id: 12298,
    Code: '5223',
    Name: 'Fitter, retort'
  },
  {
    Id: 12299,
    Code: '5223',
    Name: 'Fitter, rough'
  },
  {
    Id: 12300,
    Code: '5223',
    Name: 'Fitter, safe'
  },
  {
    Id: 12301,
    Code: '5223',
    Name: 'Fitter, scissors'
  },
  {
    Id: 12302,
    Code: '5223',
    Name: 'Fitter, screen, coal mine'
  },
  {
    Id: 12303,
    Code: '5223',
    Name: 'Fitter, service'
  },
  {
    Id: 12304,
    Code: '5223',
    Name: 'Fitter, shop, machine'
  },
  {
    Id: 12305,
    Code: '5223',
    Name: 'Fitter, shop, metal trades'
  },
  {
    Id: 12306,
    Code: '5223',
    Name: 'Fitter, skip, coal mine'
  },
  {
    Id: 12307,
    Code: '5223',
    Name: 'Fitter, speed'
  },
  {
    Id: 12308,
    Code: '5223',
    Name: 'Fitter, spring'
  },
  {
    Id: 12309,
    Code: '5223',
    Name: 'Fitter, steel'
  },
  {
    Id: 12310,
    Code: '5223',
    Name: 'Fitter, steelyard'
  },
  {
    Id: 12311,
    Code: '5223',
    Name: 'Fitter, structural'
  },
  {
    Id: 12312,
    Code: '5223',
    Name: 'Fitter, surface'
  },
  {
    Id: 12313,
    Code: '5223',
    Name: 'Fitter, tank'
  },
  {
    Id: 12314,
    Code: '5223',
    Name: 'Fitter, tender'
  },
  {
    Id: 12315,
    Code: '5223',
    Name: 'Fitter, textile'
  },
  {
    Id: 12316,
    Code: '5223',
    Name: 'Fitter, tool, machine'
  },
  {
    Id: 12317,
    Code: '5223',
    Name: 'Fitter, torpedo'
  },
  {
    Id: 12318,
    Code: '5223',
    Name: 'Fitter, tractor'
  },
  {
    Id: 12319,
    Code: '5223',
    Name: 'Fitter, truck, lift, fork'
  },
  {
    Id: 12320,
    Code: '5223',
    Name: 'Fitter, try-out'
  },
  {
    Id: 12321,
    Code: '5223',
    Name: 'Fitter, turbine'
  },
  {
    Id: 12322,
    Code: '5223',
    Name: 'Fitter, turning'
  },
  {
    Id: 12323,
    Code: '5223',
    Name: 'Fitter, valve, engineering'
  },
  {
    Id: 12324,
    Code: '5223',
    Name: 'Fitter, vehicles, vehicle mfr'
  },
  {
    Id: 12325,
    Code: '5223',
    Name: 'Fitter, weighbridge'
  },
  {
    Id: 12326,
    Code: '5223',
    Name: 'Fitter, wheel'
  },
  {
    Id: 12327,
    Code: '5223',
    Name: 'Fitter, machinery'
  },
  {
    Id: 12328,
    Code: '5223',
    Name: 'Fitter, nos'
  },
  {
    Id: 12329,
    Code: '5223',
    Name: 'Fitter, vehicles, vehicle mfr'
  },
  {
    Id: 12330,
    Code: '5223',
    Name: 'Fitter, iron foundry'
  },
  {
    Id: 12331,
    Code: '5223',
    Name: 'Fitter, metal trades'
  },
  {
    Id: 12332,
    Code: '5223',
    Name: 'Fitter, mining'
  },
  {
    Id: 12333,
    Code: '5223',
    Name: 'Fitter and assembler'
  },
  {
    Id: 12334,
    Code: '5223',
    Name: 'Fitter and erector'
  },
  {
    Id: 12335,
    Code: '5223',
    Name: 'Fitter and examiner'
  },
  {
    Id: 12336,
    Code: '5223',
    Name: 'Fitter and tester'
  },
  {
    Id: 12337,
    Code: '5223',
    Name: 'Fitter and turner'
  },
  {
    Id: 12338,
    Code: '5223',
    Name: 'Fitter-assembler'
  },
  {
    Id: 12339,
    Code: '5223',
    Name: 'Fitter-driver'
  },
  {
    Id: 12340,
    Code: '5223',
    Name: 'Fitter-engineer'
  },
  {
    Id: 12341,
    Code: '5223',
    Name: 'Fitter-erector'
  },
  {
    Id: 12342,
    Code: '5223',
    Name: 'Fitter-inspector'
  },
  {
    Id: 12343,
    Code: '5223',
    Name: 'Fitter-machinist'
  },
  {
    Id: 12344,
    Code: '5223',
    Name: 'Fitter-mechanic'
  },
  {
    Id: 12345,
    Code: '5223',
    Name: 'Fitter-operator, capstan'
  },
  {
    Id: 12346,
    Code: '5223',
    Name: 'Fitter-operator, tool, machine'
  },
  {
    Id: 12347,
    Code: '5223',
    Name: 'Fitter-tester'
  },
  {
    Id: 12348,
    Code: '5223',
    Name: 'Fitter-turner'
  },
  {
    Id: 12349,
    Code: '5223',
    Name: 'Fitter-up, foundry'
  },
  {
    Id: 12350,
    Code: '5223',
    Name: 'Fixer, motor'
  },
  {
    Id: 12351,
    Code: '5223',
    Name: 'Flanger, beam'
  },
  {
    Id: 12352,
    Code: '5223',
    Name: 'Framer, aluminium'
  },
  {
    Id: 12353,
    Code: '5223',
    Name: 'Gaiter, harness'
  },
  {
    Id: 12354,
    Code: '5223',
    Name: 'Gaiter, textile weaving'
  },
  {
    Id: 12355,
    Code: '5223',
    Name: 'Gunsmith'
  },
  {
    Id: 12356,
    Code: '5223',
    Name: 'Hand, maintenance, machine, textile mfr'
  },
  {
    Id: 12357,
    Code: '5223',
    Name: 'Installer, lift'
  },
  {
    Id: 12358,
    Code: '5223',
    Name: 'Jobber, back'
  },
  {
    Id: 12359,
    Code: '5223',
    Name: 'Jobber, comb'
  },
  {
    Id: 12360,
    Code: '5223',
    Name: 'Jobber, combing'
  },
  {
    Id: 12361,
    Code: '5223',
    Name: 'Jobber, doubling, ring'
  },
  {
    Id: 12362,
    Code: '5223',
    Name: 'Jobber, loom'
  },
  {
    Id: 12363,
    Code: '5223',
    Name: 'Jobber, ring'
  },
  {
    Id: 12364,
    Code: '5223',
    Name: 'Jobber, twisting'
  },
  {
    Id: 12365,
    Code: '5223',
    Name: 'Jobber, textile mfr'
  },
  {
    Id: 12366,
    Code: '5223',
    Name: 'Locksmith'
  },
  {
    Id: 12367,
    Code: '5223',
    Name: 'Machinist, electrochemical'
  },
  {
    Id: 12368,
    Code: '5223',
    Name: 'Machinist, engineering'
  },
  {
    Id: 12369,
    Code: '5223',
    Name: 'Machinist, maintenance'
  },
  {
    Id: 12370,
    Code: '5223',
    Name: 'Maker, barrel, gun mfr'
  },
  {
    Id: 12371,
    Code: '5223',
    Name: 'Maker, beam, textile machinery mfr'
  },
  {
    Id: 12372,
    Code: '5223',
    Name: 'Maker, bedstead'
  },
  {
    Id: 12373,
    Code: '5223',
    Name: 'Maker, body, safe, strong room mfr'
  },
  {
    Id: 12374,
    Code: '5223',
    Name: 'Maker, bridge, weigh'
  },
  {
    Id: 12375,
    Code: '5223',
    Name: 'Maker, cabinet, metal'
  },
  {
    Id: 12376,
    Code: '5223',
    Name: 'Maker, cabinet, metal'
  },
  {
    Id: 12377,
    Code: '5223',
    Name: 'Maker, case, safe, strong room mfr'
  },
  {
    Id: 12378,
    Code: '5223',
    Name: 'Maker, casement, metal'
  },
  {
    Id: 12379,
    Code: '5223',
    Name: 'Maker, comb, textile machinery mfr'
  },
  {
    Id: 12380,
    Code: '5223',
    Name: 'Maker, door, steel'
  },
  {
    Id: 12381,
    Code: '5223',
    Name: 'Maker, door, safe, strong room mfr'
  },
  {
    Id: 12382,
    Code: '5223',
    Name: 'Maker, fittings, safe, strong room mfr'
  },
  {
    Id: 12383,
    Code: '5223',
    Name: 'Maker, fly, textile machinery mfr'
  },
  {
    Id: 12384,
    Code: '5223',
    Name: 'Maker, frame, cycle mfr'
  },
  {
    Id: 12385,
    Code: '5223',
    Name: 'Maker, furniture, metal'
  },
  {
    Id: 12386,
    Code: '5223',
    Name: 'Maker, gear, sighting, gun'
  },
  {
    Id: 12387,
    Code: '5223',
    Name: 'Maker, gig'
  },
  {
    Id: 12388,
    Code: '5223',
    Name: 'Maker, gun'
  },
  {
    Id: 12389,
    Code: '5223',
    Name: 'Maker, ladder, metal'
  },
  {
    Id: 12390,
    Code: '5223',
    Name: 'Maker, lock'
  },
  {
    Id: 12391,
    Code: '5223',
    Name: 'Maker, loom'
  },
  {
    Id: 12392,
    Code: '5223',
    Name: 'Maker, machine, weighing'
  },
  {
    Id: 12393,
    Code: '5223',
    Name: 'Maker, machinery, textile'
  },
  {
    Id: 12394,
    Code: '5223',
    Name: 'Maker, mould, glass mfr'
  },
  {
    Id: 12395,
    Code: '5223',
    Name: 'Maker, pump'
  },
  {
    Id: 12396,
    Code: '5223',
    Name: 'Maker, rifle'
  },
  {
    Id: 12397,
    Code: '5223',
    Name: 'Maker, safe'
  },
  {
    Id: 12398,
    Code: '5223',
    Name: 'Maker, scale'
  },
  {
    Id: 12399,
    Code: '5223',
    Name: 'Maker, scale and balance'
  },
  {
    Id: 12400,
    Code: '5223',
    Name: 'Maker, spindle, textile machinery mfr'
  },
  {
    Id: 12401,
    Code: '5223',
    Name: 'Maker, spindle and flyer'
  },
  {
    Id: 12402,
    Code: '5223',
    Name: 'Maker, spring, balance'
  },
  {
    Id: 12403,
    Code: '5223',
    Name: 'Maker, steelyard'
  },
  {
    Id: 12404,
    Code: '5223',
    Name: 'Maker, stove'
  },
  {
    Id: 12405,
    Code: '5223',
    Name: 'Maker, temple'
  },
  {
    Id: 12406,
    Code: '5223',
    Name: 'Maker, typewriter'
  },
  {
    Id: 12407,
    Code: '5223',
    Name: 'Maker-up, metal trades'
  },
  {
    Id: 12408,
    Code: '5223',
    Name: 'Man, maintenance, appliances, mechanical, coal mine'
  },
  {
    Id: 12409,
    Code: '5223',
    Name: 'Man, maintenance, battery'
  },
  {
    Id: 12410,
    Code: '5223',
    Name: 'Man, maintenance, conveyor, coal mine'
  },
  {
    Id: 12411,
    Code: '5223',
    Name: 'Man, maintenance, machinery'
  },
  {
    Id: 12412,
    Code: '5223',
    Name: 'Man, maintenance, weighing machines'
  },
  {
    Id: 12413,
    Code: '5223',
    Name: 'Man, rectifying, metal trades'
  },
  {
    Id: 12414,
    Code: '5223',
    Name: 'Man, service, ground'
  },
  {
    Id: 12415,
    Code: '5223',
    Name: 'Man, service, metal trades'
  },
  {
    Id: 12416,
    Code: '5223',
    Name: 'Man, test, final, vehicle mfr'
  },
  {
    Id: 12417,
    Code: '5223',
    Name: 'Manager, maintenance'
  },
  {
    Id: 12418,
    Code: '5223',
    Name: 'Manufacturer, car'
  },
  {
    Id: 12419,
    Code: '5223',
    Name: 'Manufacturer, machinery mfr'
  },
  {
    Id: 12420,
    Code: '5223',
    Name: 'Manufacturer, vehicle mfr'
  },
  {
    Id: 12421,
    Code: '5223',
    Name: 'Mechanic, agricultural'
  },
  {
    Id: 12422,
    Code: '5223',
    Name: 'Mechanic, battery'
  },
  {
    Id: 12423,
    Code: '5223',
    Name: 'Mechanic, bicycle'
  },
  {
    Id: 12424,
    Code: '5223',
    Name: 'Mechanic, bike'
  },
  {
    Id: 12425,
    Code: '5223',
    Name: "Mechanic, builder's"
  },
  {
    Id: 12426,
    Code: '5223',
    Name: 'Mechanic, colliery'
  },
  {
    Id: 12427,
    Code: '5223',
    Name: 'Mechanic, crane'
  },
  {
    Id: 12428,
    Code: '5223',
    Name: 'Mechanic, cycle'
  },
  {
    Id: 12429,
    Code: '5223',
    Name: 'Mechanic, experimental'
  },
  {
    Id: 12430,
    Code: '5223',
    Name: 'Mechanic, farm'
  },
  {
    Id: 12431,
    Code: '5223',
    Name: 'Mechanic, forklift'
  },
  {
    Id: 12432,
    Code: '5223',
    Name: 'Mechanic, hosiery'
  },
  {
    Id: 12433,
    Code: '5223',
    Name: 'Mechanic, laboratory'
  },
  {
    Id: 12434,
    Code: '5223',
    Name: 'Mechanic, lift'
  },
  {
    Id: 12435,
    Code: '5223',
    Name: 'Mechanic, loom'
  },
  {
    Id: 12436,
    Code: '5223',
    Name: 'Mechanic, machine, hosiery'
  },
  {
    Id: 12437,
    Code: '5223',
    Name: 'Mechanic, machine, knitting'
  },
  {
    Id: 12438,
    Code: '5223',
    Name: 'Mechanic, machine, sewing'
  },
  {
    Id: 12439,
    Code: '5223',
    Name: 'Mechanic, machine, vending'
  },
  {
    Id: 12440,
    Code: '5223',
    Name: 'Mechanic, machine, weighing'
  },
  {
    Id: 12441,
    Code: '5223',
    Name: 'Mechanic, machine'
  },
  {
    Id: 12442,
    Code: '5223',
    Name: 'Mechanic, mower'
  },
  {
    Id: 12443,
    Code: '5223',
    Name: 'Mechanic, plant'
  },
  {
    Id: 12444,
    Code: '5223',
    Name: 'Mechanic, research and experimental'
  },
  {
    Id: 12445,
    Code: '5223',
    Name: 'Mechanic, technical'
  },
  {
    Id: 12446,
    Code: '5223',
    Name: 'Mechanic'
  },
  {
    Id: 12447,
    Code: '5223',
    Name: 'Mechanic of the mine'
  },
  {
    Id: 12448,
    Code: '5223',
    Name: 'Mechanic-examiner'
  },
  {
    Id: 12449,
    Code: '5223',
    Name: 'Mechanic-fitter'
  },
  {
    Id: 12450,
    Code: '5223',
    Name: 'Mender, comb'
  },
  {
    Id: 12451,
    Code: '5223',
    Name: 'Millwright'
  },
  {
    Id: 12452,
    Code: '5223',
    Name: 'Mounter, boiler'
  },
  {
    Id: 12453,
    Code: '5223',
    Name: 'Mounter, engine'
  },
  {
    Id: 12454,
    Code: '5223',
    Name: 'Overlooker, frame, maintenance'
  },
  {
    Id: 12455,
    Code: '5223',
    Name: 'Overlooker, loom, maintenance'
  },
  {
    Id: 12456,
    Code: '5223',
    Name: 'Overlooker, weaving, maintenance'
  },
  {
    Id: 12457,
    Code: '5223',
    Name: 'Overlooker, maintenance, textile mfr'
  },
  {
    Id: 12458,
    Code: '5223',
    Name: 'Pumpwright'
  },
  {
    Id: 12459,
    Code: '5223',
    Name: 'Railer, bedstead mfr'
  },
  {
    Id: 12460,
    Code: '5223',
    Name: 'Reconditioner, machine'
  },
  {
    Id: 12461,
    Code: '5223',
    Name: 'Rectifier, metal trades'
  },
  {
    Id: 12462,
    Code: '5223',
    Name: 'Repairer, battery'
  },
  {
    Id: 12463,
    Code: '5223',
    Name: 'Repairer, beam, textile mfr'
  },
  {
    Id: 12464,
    Code: '5223',
    Name: 'Repairer, bobbin'
  },
  {
    Id: 12465,
    Code: '5223',
    Name: 'Repairer, brake'
  },
  {
    Id: 12466,
    Code: '5223',
    Name: 'Repairer, conveyor'
  },
  {
    Id: 12467,
    Code: '5223',
    Name: 'Repairer, cycle'
  },
  {
    Id: 12468,
    Code: '5223',
    Name: 'Repairer, hydraulic'
  },
  {
    Id: 12469,
    Code: '5223',
    Name: 'Repairer, implements, farm'
  },
  {
    Id: 12470,
    Code: '5223',
    Name: 'Repairer, lamp'
  },
  {
    Id: 12471,
    Code: '5223',
    Name: 'Repairer, machine'
  },
  {
    Id: 12472,
    Code: '5223',
    Name: 'Repairer, mower'
  },
  {
    Id: 12473,
    Code: '5223',
    Name: 'Repairer, revolver'
  },
  {
    Id: 12474,
    Code: '5223',
    Name: 'Repairer, syphon'
  },
  {
    Id: 12475,
    Code: '5223',
    Name: 'Repairer, tube, carpet, rug mfr'
  },
  {
    Id: 12476,
    Code: '5223',
    Name: 'Repairer, wagon'
  },
  {
    Id: 12477,
    Code: '5223',
    Name: 'Repairer, machinery'
  },
  {
    Id: 12478,
    Code: '5223',
    Name: 'Rigger, rolling mill'
  },
  {
    Id: 12479,
    Code: '5223',
    Name: 'Setter, card, textile accessories mfr'
  },
  {
    Id: 12480,
    Code: '5223',
    Name: 'Setter, handle, textile mfr'
  },
  {
    Id: 12481,
    Code: '5223',
    Name: 'Setter, loom'
  },
  {
    Id: 12482,
    Code: '5223',
    Name: 'Setter, pattern'
  },
  {
    Id: 12483,
    Code: '5223',
    Name: 'Smith, gun'
  },
  {
    Id: 12484,
    Code: '5223',
    Name: 'Smith, lock'
  },
  {
    Id: 12485,
    Code: '5223',
    Name: 'Smith, padlock'
  },
  {
    Id: 12486,
    Code: '5223',
    Name: 'Specialist, manufacturing, vehicle mfr'
  },
  {
    Id: 12487,
    Code: '5223',
    Name: 'Springer, carriage'
  },
  {
    Id: 12488,
    Code: '5223',
    Name: 'Straightener, carriage, textile machinery mfr'
  },
  {
    Id: 12489,
    Code: '5223',
    Name: 'Straightener, textile machinery mfr'
  },
  {
    Id: 12490,
    Code: '5223',
    Name: 'Stripper, brake'
  },
  {
    Id: 12491,
    Code: '5223',
    Name: 'Stripper, engine'
  },
  {
    Id: 12492,
    Code: '5223',
    Name: 'Stripper, spring'
  },
  {
    Id: 12493,
    Code: '5223',
    Name: 'Tackler, dobby'
  },
  {
    Id: 12494,
    Code: '5223',
    Name: 'Tackler, jacquard'
  },
  {
    Id: 12495,
    Code: '5223',
    Name: 'Tackler, loom'
  },
  {
    Id: 12496,
    Code: '5223',
    Name: 'Tackler, machine, braid'
  },
  {
    Id: 12497,
    Code: '5223',
    Name: 'Tackler, textile weaving'
  },
  {
    Id: 12498,
    Code: '5223',
    Name: 'Technician, agricultural, machinery servicing and repair'
  },
  {
    Id: 12499,
    Code: '5223',
    Name: 'Technician, crane'
  },
  {
    Id: 12500,
    Code: '5223',
    Name: 'Technician, cycle'
  },
  {
    Id: 12501,
    Code: '5223',
    Name: 'Technician, equipment, fire service'
  },
  {
    Id: 12502,
    Code: '5223',
    Name: 'Technician, estates, maintenance'
  },
  {
    Id: 12503,
    Code: '5223',
    Name: 'Technician, hydraulic'
  },
  {
    Id: 12504,
    Code: '5223',
    Name: 'Technician, maintenance'
  },
  {
    Id: 12505,
    Code: '5223',
    Name: 'Technician, warehouse'
  },
  {
    Id: 12506,
    Code: '5223',
    Name: 'Technician, weighbridge'
  },
  {
    Id: 12507,
    Code: '5223',
    Name: 'Tenter, conant'
  },
  {
    Id: 12508,
    Code: '5223',
    Name: 'Tenter, frame, jacquard'
  },
  {
    Id: 12509,
    Code: '5223',
    Name: 'Tenter, hosepipe'
  },
  {
    Id: 12510,
    Code: '5223',
    Name: "Tenter, weaver's"
  },
  {
    Id: 12511,
    Code: '5223',
    Name: 'Tenter, carpet, rug mfr'
  },
  {
    Id: 12512,
    Code: '5223',
    Name: 'Tenter, lace mfr'
  },
  {
    Id: 12513,
    Code: '5223',
    Name: 'Tenter, textile weaving'
  },
  {
    Id: 12514,
    Code: '5223',
    Name: 'Tester-fitter'
  },
  {
    Id: 12515,
    Code: '5223',
    Name: 'Tester-mechanic'
  },
  {
    Id: 12516,
    Code: '5223',
    Name: 'Tester-rectifier, engine, combustion, internal'
  },
  {
    Id: 12517,
    Code: '5223',
    Name: 'Tuner, loom'
  },
  {
    Id: 12518,
    Code: '5223',
    Name: 'Tuner, looms'
  },
  {
    Id: 12519,
    Code: '5223',
    Name: 'Tuner, textile mfr'
  },
  {
    Id: 12520,
    Code: '5223',
    Name: 'Turner and fitter'
  },
  {
    Id: 12521,
    Code: '5223',
    Name: 'Turner-fitter'
  },
  {
    Id: 12522,
    Code: '5223',
    Name: 'Valveman, hydraulic'
  },
  {
    Id: 12523,
    Code: '5223',
    Name: 'Valveman, steelworks'
  },
  {
    Id: 12524,
    Code: '5223',
    Name: 'Worker, metal, hospital service'
  },
  {
    Id: 12525,
    Code: '5224',
    Name: 'Adjuster, compass'
  },
  {
    Id: 12526,
    Code: '5224',
    Name: 'Adjuster, instruments'
  },
  {
    Id: 12527,
    Code: '5224',
    Name: 'Adjuster, watches, clocks'
  },
  {
    Id: 12528,
    Code: '5224',
    Name: 'Boxer-in'
  },
  {
    Id: 12529,
    Code: '5224',
    Name: 'Calibrator, instruments'
  },
  {
    Id: 12530,
    Code: '5224',
    Name: 'Cleaner, clock'
  },
  {
    Id: 12531,
    Code: '5224',
    Name: 'Cleaner, meter'
  },
  {
    Id: 12532,
    Code: '5224',
    Name: 'Cleaner, watch'
  },
  {
    Id: 12533,
    Code: '5224',
    Name: 'Craftsman, instrument'
  },
  {
    Id: 12534,
    Code: '5224',
    Name: 'Craftsman, instrument mfr'
  },
  {
    Id: 12535,
    Code: '5224',
    Name: 'Divider, hand'
  },
  {
    Id: 12536,
    Code: '5224',
    Name: 'Divider, instrument, mathematical'
  },
  {
    Id: 12537,
    Code: '5224',
    Name: 'Divider, thermometer'
  },
  {
    Id: 12538,
    Code: '5224',
    Name: 'Engineer, calibration'
  },
  {
    Id: 12539,
    Code: '5224',
    Name: 'Engineer, horological'
  },
  {
    Id: 12540,
    Code: '5224',
    Name: 'Engineer, instrument'
  },
  {
    Id: 12541,
    Code: '5224',
    Name: 'Engineer, maintenance, instruments'
  },
  {
    Id: 12542,
    Code: '5224',
    Name: 'Engineer, meter, meter making'
  },
  {
    Id: 12543,
    Code: '5224',
    Name: 'Engineer, photographic'
  },
  {
    Id: 12544,
    Code: '5224',
    Name: 'Engineer, precision'
  },
  {
    Id: 12545,
    Code: '5224',
    Name: 'Engineer, tachograph'
  },
  {
    Id: 12546,
    Code: '5224',
    Name: 'Finisher, camera'
  },
  {
    Id: 12547,
    Code: '5224',
    Name: 'Fitter, camera'
  },
  {
    Id: 12548,
    Code: '5224',
    Name: 'Fitter, clock'
  },
  {
    Id: 12549,
    Code: '5224',
    Name: 'Fitter, gauge'
  },
  {
    Id: 12550,
    Code: '5224',
    Name: 'Fitter, instrument'
  },
  {
    Id: 12551,
    Code: '5224',
    Name: 'Fitter, meter, meter making'
  },
  {
    Id: 12552,
    Code: '5224',
    Name: 'Fitter, optical'
  },
  {
    Id: 12553,
    Code: '5224',
    Name: 'Fitter, precision, instrument mfr'
  },
  {
    Id: 12554,
    Code: '5224',
    Name: 'Fitter, scale'
  },
  {
    Id: 12555,
    Code: '5224',
    Name: 'Fitter, service, instruments'
  },
  {
    Id: 12556,
    Code: '5224',
    Name: 'Fitter, speedometer'
  },
  {
    Id: 12557,
    Code: '5224',
    Name: 'Fitter, taximeter'
  },
  {
    Id: 12558,
    Code: '5224',
    Name: "Fitter, telegraph, ship's"
  },
  {
    Id: 12559,
    Code: '5224',
    Name: 'Fitter, metal instrument mfr'
  },
  {
    Id: 12560,
    Code: '5224',
    Name: 'Framer, binocular'
  },
  {
    Id: 12561,
    Code: '5224',
    Name: 'Graduator, thermometer'
  },
  {
    Id: 12562,
    Code: '5224',
    Name: 'Hand, bench, metal instrument mfr'
  },
  {
    Id: 12563,
    Code: '5224',
    Name: 'Hand, meter'
  },
  {
    Id: 12564,
    Code: '5224',
    Name: 'Horologist'
  },
  {
    Id: 12565,
    Code: '5224',
    Name: 'Jeweller and watch repairer'
  },
  {
    Id: 12566,
    Code: '5224',
    Name: 'Jeweller and watchmaker'
  },
  {
    Id: 12567,
    Code: '5224',
    Name: 'Maker, apparatus, photographic'
  },
  {
    Id: 12568,
    Code: '5224',
    Name: 'Maker, balance, scales mfr'
  },
  {
    Id: 12569,
    Code: '5224',
    Name: 'Maker, barometer'
  },
  {
    Id: 12570,
    Code: '5224',
    Name: 'Maker, camera'
  },
  {
    Id: 12571,
    Code: '5224',
    Name: 'Maker, chronometer'
  },
  {
    Id: 12572,
    Code: '5224',
    Name: 'Maker, clock'
  },
  {
    Id: 12573,
    Code: '5224',
    Name: 'Maker, cog, clock mfr'
  },
  {
    Id: 12574,
    Code: '5224',
    Name: 'Maker, compass'
  },
  {
    Id: 12575,
    Code: '5224',
    Name: 'Maker, dial'
  },
  {
    Id: 12576,
    Code: '5224',
    Name: 'Maker, frame, watch mfr'
  },
  {
    Id: 12577,
    Code: '5224',
    Name: 'Maker, galvanometer'
  },
  {
    Id: 12578,
    Code: '5224',
    Name: 'Maker, gauge, pressure'
  },
  {
    Id: 12579,
    Code: '5224',
    Name: 'Maker, gauge, steam'
  },
  {
    Id: 12580,
    Code: '5224',
    Name: 'Maker, glass, field'
  },
  {
    Id: 12581,
    Code: '5224',
    Name: 'Maker, glass, opera'
  },
  {
    Id: 12582,
    Code: '5224',
    Name: 'Maker, gong, clock'
  },
  {
    Id: 12583,
    Code: '5224',
    Name: 'Maker, hydrometer'
  },
  {
    Id: 12584,
    Code: '5224',
    Name: 'Maker, instrument, precision'
  },
  {
    Id: 12585,
    Code: '5224',
    Name: 'Maker, instrument'
  },
  {
    Id: 12586,
    Code: '5224',
    Name: 'Maker, level, spirit'
  },
  {
    Id: 12587,
    Code: '5224',
    Name: 'Maker, meter'
  },
  {
    Id: 12588,
    Code: '5224',
    Name: 'Maker, micrometer'
  },
  {
    Id: 12589,
    Code: '5224',
    Name: 'Maker, microscope'
  },
  {
    Id: 12590,
    Code: '5224',
    Name: 'Maker, movement'
  },
  {
    Id: 12591,
    Code: '5224',
    Name: 'Maker, pedometer'
  },
  {
    Id: 12592,
    Code: '5224',
    Name: 'Maker, rule, mathematical'
  },
  {
    Id: 12593,
    Code: '5224',
    Name: 'Maker, rule, instrument mfr'
  },
  {
    Id: 12594,
    Code: '5224',
    Name: 'Maker, screw, balance'
  },
  {
    Id: 12595,
    Code: '5224',
    Name: 'Maker, speedometer'
  },
  {
    Id: 12596,
    Code: '5224',
    Name: 'Maker, taximeter'
  },
  {
    Id: 12597,
    Code: '5224',
    Name: 'Maker, telescope'
  },
  {
    Id: 12598,
    Code: '5224',
    Name: 'Maker, watch'
  },
  {
    Id: 12599,
    Code: '5224',
    Name: 'Maker, watch and clock'
  },
  {
    Id: 12600,
    Code: '5224',
    Name: 'Maker, wheel, clock mfr'
  },
  {
    Id: 12601,
    Code: '5224',
    Name: 'Man, maintenance, scientific instruments'
  },
  {
    Id: 12602,
    Code: '5224',
    Name: 'Manufacturer, instrument'
  },
  {
    Id: 12603,
    Code: '5224',
    Name: 'Manufacturer, instruments'
  },
  {
    Id: 12604,
    Code: '5224',
    Name: 'Manufacturer, watch, clock mfr'
  },
  {
    Id: 12605,
    Code: '5224',
    Name: 'Mechanic, camera'
  },
  {
    Id: 12606,
    Code: '5224',
    Name: 'Mechanic, clock'
  },
  {
    Id: 12607,
    Code: '5224',
    Name: 'Mechanic, compass'
  },
  {
    Id: 12608,
    Code: '5224',
    Name: 'Mechanic, instrument, optical'
  },
  {
    Id: 12609,
    Code: '5224',
    Name: 'Mechanic, instrument'
  },
  {
    Id: 12610,
    Code: '5224',
    Name: 'Mechanic, recorder, time'
  },
  {
    Id: 12611,
    Code: '5224',
    Name: 'Mechanic, service, instruments'
  },
  {
    Id: 12612,
    Code: '5224',
    Name: 'Mechanic, instruments'
  },
  {
    Id: 12613,
    Code: '5224',
    Name: 'Mounter, barometer'
  },
  {
    Id: 12614,
    Code: '5224',
    Name: 'Mounter, thermometer'
  },
  {
    Id: 12615,
    Code: '5224',
    Name: 'Mounter, wheel'
  },
  {
    Id: 12616,
    Code: '5224',
    Name: 'Mounter, instrument mfr'
  },
  {
    Id: 12617,
    Code: '5224',
    Name: 'Polisher, optical'
  },
  {
    Id: 12618,
    Code: '5224',
    Name: 'Repairer, camera'
  },
  {
    Id: 12619,
    Code: '5224',
    Name: 'Repairer, chronometer'
  },
  {
    Id: 12620,
    Code: '5224',
    Name: 'Repairer, clock'
  },
  {
    Id: 12621,
    Code: '5224',
    Name: 'Repairer, clockwork'
  },
  {
    Id: 12622,
    Code: '5224',
    Name: 'Repairer, instrument'
  },
  {
    Id: 12623,
    Code: '5224',
    Name: 'Repairer, meter'
  },
  {
    Id: 12624,
    Code: '5224',
    Name: 'Repairer, scale'
  },
  {
    Id: 12625,
    Code: '5224',
    Name: 'Repairer, watch'
  },
  {
    Id: 12626,
    Code: '5224',
    Name: 'Repairer, watch and clock'
  },
  {
    Id: 12627,
    Code: '5224',
    Name: 'Repairer, dental and surgical instruments'
  },
  {
    Id: 12628,
    Code: '5224',
    Name: 'Repairer, instruments'
  },
  {
    Id: 12629,
    Code: '5224',
    Name: 'Repairer, watches, clocks'
  },
  {
    Id: 12630,
    Code: '5224',
    Name: 'Repairer and jeweller, watch'
  },
  {
    Id: 12631,
    Code: '5224',
    Name: 'Restorer, clock'
  },
  {
    Id: 12632,
    Code: '5224',
    Name: 'Setter, watch, clock mfr'
  },
  {
    Id: 12633,
    Code: '5224',
    Name: 'Technician, calibration'
  },
  {
    Id: 12634,
    Code: '5224',
    Name: 'Technician, camera'
  },
  {
    Id: 12635,
    Code: '5224',
    Name: 'Technician, instrument'
  },
  {
    Id: 12636,
    Code: '5224',
    Name: 'Technician, measurement and control'
  },
  {
    Id: 12637,
    Code: '5224',
    Name: 'Technician, optical'
  },
  {
    Id: 12638,
    Code: '5224',
    Name: 'Technician, watch'
  },
  {
    Id: 12639,
    Code: '5224',
    Name: 'Tester, weight, balance mfr'
  },
  {
    Id: 12640,
    Code: '5224',
    Name: 'Tester, metal trades: balance mfr'
  },
  {
    Id: 12641,
    Code: '5224',
    Name: 'Tester-rectifier, instrument, precision'
  },
  {
    Id: 12642,
    Code: '5224',
    Name: 'Timer'
  },
  {
    Id: 12643,
    Code: '5224',
    Name: 'Watchmaker'
  },
  {
    Id: 12644,
    Code: '5224',
    Name: 'Watchmaker and jeweller'
  },
  {
    Id: 12645,
    Code: '5224',
    Name: 'Worker, bench, instrument mfr'
  },
  {
    Id: 12646,
    Code: '5225',
    Name: 'Engineer, conditioning, air'
  },
  {
    Id: 12647,
    Code: '5225',
    Name: 'Engineer, refrigerating'
  },
  {
    Id: 12648,
    Code: '5225',
    Name: 'Engineer, refrigeration'
  },
  {
    Id: 12649,
    Code: '5225',
    Name: 'Engineer, repair, refrigeration'
  },
  {
    Id: 12650,
    Code: '5225',
    Name: 'Engineer, service, refrigeration'
  },
  {
    Id: 12651,
    Code: '5225',
    Name: 'Engineer, service, refrigeration'
  },
  {
    Id: 12652,
    Code: '5225',
    Name: 'Fitter, conditioning, air'
  },
  {
    Id: 12653,
    Code: '5225',
    Name: 'Installer, conditioning, air'
  },
  {
    Id: 12654,
    Code: '5225',
    Name: 'Maker, refrigerator'
  },
  {
    Id: 12655,
    Code: '5225',
    Name: 'Mechanic, refrigeration'
  },
  {
    Id: 12656,
    Code: '5225',
    Name: 'Technician, conditioning, air'
  },
  {
    Id: 12657,
    Code: '5225',
    Name: 'Technician, refrigeration'
  },
  {
    Id: 12658,
    Code: '5231',
    Name: 'Adjuster, unit'
  },
  {
    Id: 12659,
    Code: '5231',
    Name: 'Adjuster, vehicles'
  },
  {
    Id: 12660,
    Code: '5231',
    Name: 'Adviser, technical, garage'
  },
  {
    Id: 12661,
    Code: '5231',
    Name: 'Attendant, bay, lubrication'
  },
  {
    Id: 12662,
    Code: '5231',
    Name: 'Attendant, garage'
  },
  {
    Id: 12663,
    Code: '5231',
    Name: 'Attendant, lubrication, vehicles'
  },
  {
    Id: 12664,
    Code: '5231',
    Name: 'Bayman, service'
  },
  {
    Id: 12665,
    Code: '5231',
    Name: 'Electrician, auto'
  },
  {
    Id: 12666,
    Code: '5231',
    Name: 'Electrician, automobile'
  },
  {
    Id: 12667,
    Code: '5231',
    Name: 'Electrician, maintenance, motor vehicle repair'
  },
  {
    Id: 12668,
    Code: '5231',
    Name: 'Electrician, vehicle'
  },
  {
    Id: 12669,
    Code: '5231',
    Name: 'Engineer, automobile'
  },
  {
    Id: 12670,
    Code: '5231',
    Name: 'Engineer, automotive'
  },
  {
    Id: 12671,
    Code: '5231',
    Name: 'Engineer, breakdown, vehicle'
  },
  {
    Id: 12672,
    Code: '5231',
    Name: 'Engineer, bus'
  },
  {
    Id: 12673,
    Code: '5231',
    Name: 'Engineer, diesel, vehicles'
  },
  {
    Id: 12674,
    Code: '5231',
    Name: 'Engineer, diesel, vehicles'
  },
  {
    Id: 12675,
    Code: '5231',
    Name: 'Engineer, diesel'
  },
  {
    Id: 12676,
    Code: '5231',
    Name: 'Engineer, electrical, auto'
  },
  {
    Id: 12677,
    Code: '5231',
    Name: 'Engineer, fleet'
  },
  {
    Id: 12678,
    Code: '5231',
    Name: 'Engineer, garage'
  },
  {
    Id: 12679,
    Code: '5231',
    Name: 'Engineer, HGV'
  },
  {
    Id: 12680,
    Code: '5231',
    Name: 'Engineer, injection, fuel'
  },
  {
    Id: 12681,
    Code: '5231',
    Name: 'Engineer, LGV'
  },
  {
    Id: 12682,
    Code: '5231',
    Name: 'Engineer, maintenance, vehicles'
  },
  {
    Id: 12683,
    Code: '5231',
    Name: 'Engineer, mechanical, vehicles'
  },
  {
    Id: 12684,
    Code: '5231',
    Name: 'Engineer, motor, staff'
  },
  {
    Id: 12685,
    Code: '5231',
    Name: 'Engineer, motor'
  },
  {
    Id: 12686,
    Code: '5231',
    Name: 'Engineer, motorcycle'
  },
  {
    Id: 12687,
    Code: '5231',
    Name: 'Engineer, reception, garage'
  },
  {
    Id: 12688,
    Code: '5231',
    Name: 'Engineer, repair, motor'
  },
  {
    Id: 12689,
    Code: '5231',
    Name: 'Engineer, service, vehicle'
  },
  {
    Id: 12690,
    Code: '5231',
    Name: 'Engineer, service, vehicles'
  },
  {
    Id: 12691,
    Code: '5231',
    Name: 'Engineer, technical, vehicle trades'
  },
  {
    Id: 12692,
    Code: '5231',
    Name: 'Engineer, transmission, vehicles'
  },
  {
    Id: 12693,
    Code: '5231',
    Name: 'Engineer, transport'
  },
  {
    Id: 12694,
    Code: '5231',
    Name: 'Engineer, vehicle'
  },
  {
    Id: 12695,
    Code: '5231',
    Name: 'Examiner, MOT'
  },
  {
    Id: 12696,
    Code: '5231',
    Name: 'Fitter, automobile'
  },
  {
    Id: 12697,
    Code: '5231',
    Name: 'Fitter, car'
  },
  {
    Id: 12698,
    Code: '5231',
    Name: 'Fitter, diesel, vehicles'
  },
  {
    Id: 12699,
    Code: '5231',
    Name: 'Fitter, diesel'
  },
  {
    Id: 12700,
    Code: '5231',
    Name: 'Fitter, engine, diesel'
  },
  {
    Id: 12701,
    Code: '5231',
    Name: 'Fitter, garage'
  },
  {
    Id: 12702,
    Code: '5231',
    Name: 'Fitter, HGV'
  },
  {
    Id: 12703,
    Code: '5231',
    Name: 'Fitter, LGV'
  },
  {
    Id: 12704,
    Code: '5231',
    Name: 'Fitter, lining, brake'
  },
  {
    Id: 12705,
    Code: '5231',
    Name: 'Fitter, maintenance, vehicle servicing'
  },
  {
    Id: 12706,
    Code: '5231',
    Name: 'Fitter, mechanical, vehicle'
  },
  {
    Id: 12707,
    Code: '5231',
    Name: 'Fitter, mechanical, vehicles'
  },
  {
    Id: 12708,
    Code: '5231',
    Name: 'Fitter, motor'
  },
  {
    Id: 12709,
    Code: '5231',
    Name: 'Fitter, PSV'
  },
  {
    Id: 12710,
    Code: '5231',
    Name: 'Fitter, trailer'
  },
  {
    Id: 12711,
    Code: '5231',
    Name: 'Fitter, transport'
  },
  {
    Id: 12712,
    Code: '5231',
    Name: 'Fitter, truck, electric'
  },
  {
    Id: 12713,
    Code: '5231',
    Name: 'Fitter, van'
  },
  {
    Id: 12714,
    Code: '5231',
    Name: 'Fitter, vehicle, motor'
  },
  {
    Id: 12715,
    Code: '5231',
    Name: 'Fitter, vehicles'
  },
  {
    Id: 12716,
    Code: '5231',
    Name: 'Fitter, vehicles'
  },
  {
    Id: 12717,
    Code: '5231',
    Name: 'Fitter, garage'
  },
  {
    Id: 12718,
    Code: '5231',
    Name: 'Fitter-mechanic, garage'
  },
  {
    Id: 12719,
    Code: '5231',
    Name: 'Greaser, vehicles'
  },
  {
    Id: 12720,
    Code: '5231',
    Name: 'Hand, maintenance, vehicles'
  },
  {
    Id: 12721,
    Code: '5231',
    Name: 'Hand, maintenance, transport'
  },
  {
    Id: 12722,
    Code: '5231',
    Name: 'Hand, service, garage'
  },
  {
    Id: 12723,
    Code: '5231',
    Name: 'Handyman, garage'
  },
  {
    Id: 12724,
    Code: '5231',
    Name: 'Inspector, MOT'
  },
  {
    Id: 12725,
    Code: '5231',
    Name: 'Man, maintenance, vehicles'
  },
  {
    Id: 12726,
    Code: '5231',
    Name: 'Man, maintenance, transport'
  },
  {
    Id: 12727,
    Code: '5231',
    Name: 'Man, service, garage'
  },
  {
    Id: 12728,
    Code: '5231',
    Name: 'Mechanic, auto, car/light vehicles'
  },
  {
    Id: 12729,
    Code: '5231',
    Name: 'Mechanic, auto'
  },
  {
    Id: 12730,
    Code: '5231',
    Name: 'Mechanic, bike, motor'
  },
  {
    Id: 12731,
    Code: '5231',
    Name: 'Mechanic, bus'
  },
  {
    Id: 12732,
    Code: '5231',
    Name: 'Mechanic, car'
  },
  {
    Id: 12733,
    Code: '5231',
    Name: 'Mechanic, cycle, motor'
  },
  {
    Id: 12734,
    Code: '5231',
    Name: 'Mechanic, diesel'
  },
  {
    Id: 12735,
    Code: '5231',
    Name: 'Mechanic, garage'
  },
  {
    Id: 12736,
    Code: '5231',
    Name: 'Mechanic, HGV'
  },
  {
    Id: 12737,
    Code: '5231',
    Name: 'Mechanic, lorry'
  },
  {
    Id: 12738,
    Code: '5231',
    Name: 'Mechanic, mobile, vehicles'
  },
  {
    Id: 12739,
    Code: '5231',
    Name: 'Mechanic, motor'
  },
  {
    Id: 12740,
    Code: '5231',
    Name: 'Mechanic, motorcycle'
  },
  {
    Id: 12741,
    Code: '5231',
    Name: 'Mechanic, patrol, vehicle'
  },
  {
    Id: 12742,
    Code: '5231',
    Name: 'Mechanic, reception'
  },
  {
    Id: 12743,
    Code: '5231',
    Name: 'Mechanic, roadside'
  },
  {
    Id: 12744,
    Code: '5231',
    Name: 'Mechanic, semi-skilled'
  },
  {
    Id: 12745,
    Code: '5231',
    Name: 'Mechanic, service, garage'
  },
  {
    Id: 12746,
    Code: '5231',
    Name: 'Mechanic, van'
  },
  {
    Id: 12747,
    Code: '5231',
    Name: 'Mechanic, vehicle'
  },
  {
    Id: 12748,
    Code: '5231',
    Name: 'Mechanic, auto-engines'
  },
  {
    Id: 12749,
    Code: '5231',
    Name: 'Mechanic, car/light vehicles'
  },
  {
    Id: 12750,
    Code: '5231',
    Name: 'Mechanic, HGV/LGV'
  },
  {
    Id: 12751,
    Code: '5231',
    Name: 'Mechanic, MOT testing'
  },
  {
    Id: 12752,
    Code: '5231',
    Name: 'Mechanic, motor bike/cycle'
  },
  {
    Id: 12753,
    Code: '5231',
    Name: 'Mechanic, vehicles'
  },
  {
    Id: 12754,
    Code: '5231',
    Name: 'Mechanic, garage'
  },
  {
    Id: 12755,
    Code: '5231',
    Name: 'Mechanic and driver, motor'
  },
  {
    Id: 12756,
    Code: '5231',
    Name: 'Mechanic and MOT tester'
  },
  {
    Id: 12757,
    Code: '5231',
    Name: 'Mechanic-fitter, motor'
  },
  {
    Id: 12758,
    Code: '5231',
    Name: 'Oiler and greaser, vehicles'
  },
  {
    Id: 12759,
    Code: '5231',
    Name: 'Operator, assistance, roadside'
  },
  {
    Id: 12760,
    Code: '5231',
    Name: 'Operator, bay, garage'
  },
  {
    Id: 12761,
    Code: '5231',
    Name: 'Overhauler, vehicles'
  },
  {
    Id: 12762,
    Code: '5231',
    Name: 'Patrol, road, motoring organisation'
  },
  {
    Id: 12763,
    Code: '5231',
    Name: 'Patrol, roadside'
  },
  {
    Id: 12764,
    Code: '5231',
    Name: 'Patrol, motoring organisation'
  },
  {
    Id: 12765,
    Code: '5231',
    Name: 'Patrolman, road, motoring organisation'
  },
  {
    Id: 12766,
    Code: '5231',
    Name: 'Patrolman, motoring organisation'
  },
  {
    Id: 12767,
    Code: '5231',
    Name: 'Reconditioner, engine'
  },
  {
    Id: 12768,
    Code: '5231',
    Name: 'Reconditioner, gearbox'
  },
  {
    Id: 12769,
    Code: '5231',
    Name: 'Repairer, cycle, motor'
  },
  {
    Id: 12770,
    Code: '5231',
    Name: 'Repairer, motor'
  },
  {
    Id: 12771,
    Code: '5231',
    Name: 'Repairer, tractor'
  },
  {
    Id: 12772,
    Code: '5231',
    Name: 'Repairer, vehicles'
  },
  {
    Id: 12773,
    Code: '5231',
    Name: 'Scout, motoring organisation'
  },
  {
    Id: 12774,
    Code: '5231',
    Name: 'Servicer, motor mfr'
  },
  {
    Id: 12775,
    Code: '5231',
    Name: 'Stripper, car'
  },
  {
    Id: 12776,
    Code: '5231',
    Name: 'Stripper, motor'
  },
  {
    Id: 12777,
    Code: '5231',
    Name: 'Technician, auto, car/light vehicles'
  },
  {
    Id: 12778,
    Code: '5231',
    Name: 'Technician, auto'
  },
  {
    Id: 12779,
    Code: '5231',
    Name: 'Technician, automotive'
  },
  {
    Id: 12780,
    Code: '5231',
    Name: 'Technician, car'
  },
  {
    Id: 12781,
    Code: '5231',
    Name: 'Technician, diagnostic, vehicles'
  },
  {
    Id: 12782,
    Code: '5231',
    Name: 'Technician, electrical, auto'
  },
  {
    Id: 12783,
    Code: '5231',
    Name: 'Technician, HGV'
  },
  {
    Id: 12784,
    Code: '5231',
    Name: 'Technician, installation, electrical, vehicles'
  },
  {
    Id: 12785,
    Code: '5231',
    Name: 'Technician, maintenance, vehicle'
  },
  {
    Id: 12786,
    Code: '5231',
    Name: 'Technician, maintenance, vehicles'
  },
  {
    Id: 12787,
    Code: '5231',
    Name: 'Technician, MET'
  },
  {
    Id: 12788,
    Code: '5231',
    Name: 'Technician, mobile, vehicles'
  },
  {
    Id: 12789,
    Code: '5231',
    Name: 'Technician, motor'
  },
  {
    Id: 12790,
    Code: '5231',
    Name: 'Technician, motorbike'
  },
  {
    Id: 12791,
    Code: '5231',
    Name: 'Technician, motorcycle'
  },
  {
    Id: 12792,
    Code: '5231',
    Name: 'Technician, motorsport'
  },
  {
    Id: 12793,
    Code: '5231',
    Name: 'Technician, roadside, motor vehicles'
  },
  {
    Id: 12794,
    Code: '5231',
    Name: 'Technician, service, car/light vehicles'
  },
  {
    Id: 12795,
    Code: '5231',
    Name: 'Technician, service, vehicles'
  },
  {
    Id: 12796,
    Code: '5231',
    Name: 'Technician, vehicle, light'
  },
  {
    Id: 12797,
    Code: '5231',
    Name: 'Technician, vehicle, motor'
  },
  {
    Id: 12798,
    Code: '5231',
    Name: 'Technician, vehicle'
  },
  {
    Id: 12799,
    Code: '5231',
    Name: 'Technician, car/light vehicles'
  },
  {
    Id: 12800,
    Code: '5231',
    Name: 'Technician, vehicles'
  },
  {
    Id: 12801,
    Code: '5231',
    Name: 'Technician-mechanic, vehicles'
  },
  {
    Id: 12802,
    Code: '5231',
    Name: 'Tester, car'
  },
  {
    Id: 12803,
    Code: '5231',
    Name: 'Tester, MOT'
  },
  {
    Id: 12804,
    Code: '5231',
    Name: 'Tuner, vehicles'
  },
  {
    Id: 12805,
    Code: '5232',
    Name: 'Beader, coach building'
  },
  {
    Id: 12806,
    Code: '5232',
    Name: 'Beater, panel'
  },
  {
    Id: 12807,
    Code: '5232',
    Name: 'Beater and sprayer, paint'
  },
  {
    Id: 12808,
    Code: '5232',
    Name: 'Bodyworker, garage'
  },
  {
    Id: 12809,
    Code: '5232',
    Name: 'Builder, ambulance'
  },
  {
    Id: 12810,
    Code: '5232',
    Name: 'Builder, body, vehicles'
  },
  {
    Id: 12811,
    Code: '5232',
    Name: 'Builder, car'
  },
  {
    Id: 12812,
    Code: '5232',
    Name: 'Builder, caravan'
  },
  {
    Id: 12813,
    Code: '5232',
    Name: 'Builder, carriage'
  },
  {
    Id: 12814,
    Code: '5232',
    Name: 'Builder, coach'
  },
  {
    Id: 12815,
    Code: '5232',
    Name: 'Builder, frame, vehicle mfr'
  },
  {
    Id: 12816,
    Code: '5232',
    Name: 'Builder, vehicle'
  },
  {
    Id: 12817,
    Code: '5232',
    Name: 'Builder, wagon'
  },
  {
    Id: 12818,
    Code: '5232',
    Name: 'Engineer, caravan'
  },
  {
    Id: 12819,
    Code: '5232',
    Name: 'Finisher, body, vehicle mfr'
  },
  {
    Id: 12820,
    Code: '5232',
    Name: 'Finisher, car'
  },
  {
    Id: 12821,
    Code: '5232',
    Name: 'Finisher, coach'
  },
  {
    Id: 12822,
    Code: '5232',
    Name: 'Finisher, coach building'
  },
  {
    Id: 12823,
    Code: '5232',
    Name: 'Finisher, vehicle mfr'
  },
  {
    Id: 12824,
    Code: '5232',
    Name: 'Fitter, body, vehicles'
  },
  {
    Id: 12825,
    Code: '5232',
    Name: 'Fitter, caravan'
  },
  {
    Id: 12826,
    Code: '5232',
    Name: 'Joiner, coach'
  },
  {
    Id: 12827,
    Code: '5232',
    Name: 'Maker, body, coach'
  },
  {
    Id: 12828,
    Code: '5232',
    Name: 'Maker, body, motor'
  },
  {
    Id: 12829,
    Code: '5232',
    Name: 'Maker, body, vehicles'
  },
  {
    Id: 12830,
    Code: '5232',
    Name: 'Maker, body, vehicle mfr'
  },
  {
    Id: 12831,
    Code: '5232',
    Name: 'Maker, coach'
  },
  {
    Id: 12832,
    Code: '5232',
    Name: 'Maker, wagon'
  },
  {
    Id: 12833,
    Code: '5232',
    Name: 'Maker and repairer, body'
  },
  {
    Id: 12834,
    Code: '5232',
    Name: 'Man, timber, vehicle mfr'
  },
  {
    Id: 12835,
    Code: '5232',
    Name: 'Renovator, car'
  },
  {
    Id: 12836,
    Code: '5232',
    Name: 'Repairer, body, car'
  },
  {
    Id: 12837,
    Code: '5232',
    Name: 'Repairer, body, vehicle'
  },
  {
    Id: 12838,
    Code: '5232',
    Name: 'Repairer, body, vehicles'
  },
  {
    Id: 12839,
    Code: '5232',
    Name: 'Repairer, box, horse'
  },
  {
    Id: 12840,
    Code: '5232',
    Name: 'Repairer, coach'
  },
  {
    Id: 12841,
    Code: '5232',
    Name: 'Repairer, smart, vehicles'
  },
  {
    Id: 12842,
    Code: '5232',
    Name: 'Repairer, van'
  },
  {
    Id: 12843,
    Code: '5232',
    Name: 'Restorer, car'
  },
  {
    Id: 12844,
    Code: '5232',
    Name: 'Restorer, motorcycle'
  },
  {
    Id: 12845,
    Code: '5232',
    Name: 'Restorer, vehicle'
  },
  {
    Id: 12846,
    Code: '5232',
    Name: 'Technician, body, vehicle'
  },
  {
    Id: 12847,
    Code: '5232',
    Name: 'Technician, bodyshop'
  },
  {
    Id: 12848,
    Code: '5232',
    Name: 'Technician, caravan'
  },
  {
    Id: 12849,
    Code: '5232',
    Name: 'Technician, panel, vehicles'
  },
  {
    Id: 12850,
    Code: '5232',
    Name: 'Technician, repair, smart'
  },
  {
    Id: 12851,
    Code: '5232',
    Name: 'Technician, restoration, vehicles'
  },
  {
    Id: 12852,
    Code: '5232',
    Name: 'Technician, trim, mechanical and electrical'
  },
  {
    Id: 12853,
    Code: '5232',
    Name: 'Wagonwright'
  },
  {
    Id: 12854,
    Code: '5233',
    Name: 'Applicator, film, window'
  },
  {
    Id: 12855,
    Code: '5233',
    Name: 'Applicator, graphic, vehicles'
  },
  {
    Id: 12856,
    Code: '5233',
    Name: 'Operator, shop, paint, vehicle mfr'
  },
  {
    Id: 12857,
    Code: '5233',
    Name: 'Painter, aircraft'
  },
  {
    Id: 12858,
    Code: '5233',
    Name: 'Painter, car'
  },
  {
    Id: 12859,
    Code: '5233',
    Name: 'Painter, coach'
  },
  {
    Id: 12860,
    Code: '5233',
    Name: 'Painter, spray, vehicle trades'
  },
  {
    Id: 12861,
    Code: '5233',
    Name: 'Painter, wagon'
  },
  {
    Id: 12862,
    Code: '5233',
    Name: 'Painter, garage'
  },
  {
    Id: 12863,
    Code: '5233',
    Name: 'Painter, vehicle mfr'
  },
  {
    Id: 12864,
    Code: '5233',
    Name: 'Polisher, spray'
  },
  {
    Id: 12865,
    Code: '5233',
    Name: 'Refinisher, vehicle'
  },
  {
    Id: 12866,
    Code: '5233',
    Name: 'Refinisher'
  },
  {
    Id: 12867,
    Code: '5233',
    Name: 'Repairer, paint-work, vehicle mfr'
  },
  {
    Id: 12868,
    Code: '5233',
    Name: 'Sprayer, body'
  },
  {
    Id: 12869,
    Code: '5233',
    Name: 'Sprayer, car'
  },
  {
    Id: 12870,
    Code: '5233',
    Name: 'Sprayer, cellulose, vehicle trades'
  },
  {
    Id: 12871,
    Code: '5233',
    Name: 'Sprayer, paint, car'
  },
  {
    Id: 12872,
    Code: '5233',
    Name: 'Sprayer, paint, vehicle'
  },
  {
    Id: 12873,
    Code: '5233',
    Name: 'Sprayer, paint, vehicle trades'
  },
  {
    Id: 12874,
    Code: '5233',
    Name: 'Sprayer, vehicle trades'
  },
  {
    Id: 12875,
    Code: '5233',
    Name: 'Technician, paint, vehicles'
  },
  {
    Id: 12876,
    Code: '5233',
    Name: 'Technician, refinishing, vehicles'
  },
  {
    Id: 12877,
    Code: '5233',
    Name: 'Undersealer, vehicles'
  },
  {
    Id: 12878,
    Code: '5233',
    Name: 'Worker, paint, vehicle mfr'
  },
  {
    Id: 12879,
    Code: '5233',
    Name: 'Worker, shop, paint, vehicle mfr'
  },
  {
    Id: 12880,
    Code: '5233',
    Name: 'Wrapper, vehicle'
  },
  {
    Id: 12881,
    Code: '5234',
    Name: 'Dismantler, engine, aircraft'
  },
  {
    Id: 12882,
    Code: '5234',
    Name: 'Electrician, aircraft'
  },
  {
    Id: 12883,
    Code: '5234',
    Name: 'Engineer, aero'
  },
  {
    Id: 12884,
    Code: '5234',
    Name: 'Engineer, aeronautical'
  },
  {
    Id: 12885,
    Code: '5234',
    Name: 'Engineer, aircraft'
  },
  {
    Id: 12886,
    Code: '5234',
    Name: 'Engineer, airline'
  },
  {
    Id: 12887,
    Code: '5234',
    Name: 'Engineer, aviation'
  },
  {
    Id: 12888,
    Code: '5234',
    Name: 'Engineer, helicopter'
  },
  {
    Id: 12889,
    Code: '5234',
    Name: 'Engineer, maintenance, aircraft'
  },
  {
    Id: 12890,
    Code: '5234',
    Name: 'Engineer, maintenance, aircraft'
  },
  {
    Id: 12891,
    Code: '5234',
    Name: 'Engineer, service, aircraft'
  },
  {
    Id: 12892,
    Code: '5234',
    Name: 'Engineer, nos: aircraft maintenance'
  },
  {
    Id: 12893,
    Code: '5234',
    Name: 'Etcher, aircraft mfr'
  },
  {
    Id: 12894,
    Code: '5234',
    Name: 'Finisher, aircraft mfr'
  },
  {
    Id: 12895,
    Code: '5234',
    Name: 'Fitter, aircraft'
  },
  {
    Id: 12896,
    Code: '5234',
    Name: 'Fitter, airframe'
  },
  {
    Id: 12897,
    Code: '5234',
    Name: 'Fitter, engine, aero'
  },
  {
    Id: 12898,
    Code: '5234',
    Name: 'Fitter, engine, aircraft, maintenance'
  },
  {
    Id: 12899,
    Code: '5234',
    Name: 'Fitter, frame, air'
  },
  {
    Id: 12900,
    Code: '5234',
    Name: 'Fitter, instrument, aircraft'
  },
  {
    Id: 12901,
    Code: '5234',
    Name: 'Fitter, instrument, aircraft'
  },
  {
    Id: 12902,
    Code: '5234',
    Name: 'Fitter, maintenance, aircraft engines'
  },
  {
    Id: 12903,
    Code: '5234',
    Name: 'Fitter, mechanical, aircraft'
  },
  {
    Id: 12904,
    Code: '5234',
    Name: 'Fitter, service, aircraft, electronic and related equipment'
  },
  {
    Id: 12905,
    Code: '5234',
    Name: 'Fitter, service, aircraft'
  },
  {
    Id: 12906,
    Code: '5234',
    Name: 'Fitter, support, aircraft'
  },
  {
    Id: 12907,
    Code: '5234',
    Name: 'Fitter, aircraft'
  },
  {
    Id: 12908,
    Code: '5234',
    Name: 'Hand, maintenance, aircraft'
  },
  {
    Id: 12909,
    Code: '5234',
    Name: 'Man, maintenance, aircraft'
  },
  {
    Id: 12910,
    Code: '5234',
    Name: 'Mechanic, aircraft'
  },
  {
    Id: 12911,
    Code: '5234',
    Name: 'Mechanic, aircraft'
  },
  {
    Id: 12912,
    Code: '5234',
    Name: 'Refurbisher, aircraft'
  },
  {
    Id: 12913,
    Code: '5234',
    Name: 'TTO, Civil Aviation Authority'
  },
  {
    Id: 12914,
    Code: '5234',
    Name: 'Tester-rectifier, engine, jet'
  },
  {
    Id: 12915,
    Code: '5235',
    Name: 'Boatwright'
  },
  {
    Id: 12916,
    Code: '5235',
    Name: 'Builder, barge'
  },
  {
    Id: 12917,
    Code: '5235',
    Name: 'Builder, boat'
  },
  {
    Id: 12918,
    Code: '5235',
    Name: 'Builder, ship'
  },
  {
    Id: 12919,
    Code: '5235',
    Name: 'Builder, yacht'
  },
  {
    Id: 12920,
    Code: '5235',
    Name: 'Builder and repairer, boat'
  },
  {
    Id: 12921,
    Code: '5235',
    Name: 'Cutter and caulker, shipbuilding'
  },
  {
    Id: 12922,
    Code: '5235',
    Name: "Driller, shipwright's"
  },
  {
    Id: 12923,
    Code: '5235',
    Name: 'Driller, shipbuilding'
  },
  {
    Id: 12924,
    Code: '5235',
    Name: 'Engineer, barge'
  },
  {
    Id: 12925,
    Code: '5235',
    Name: 'Engineer, marine'
  },
  {
    Id: 12926,
    Code: '5235',
    Name: 'Engineer, trawler'
  },
  {
    Id: 12927,
    Code: '5235',
    Name: 'Engineer, nos, boat building and repairing'
  },
  {
    Id: 12928,
    Code: '5235',
    Name: 'Engineer, nos, shipbuilding'
  },
  {
    Id: 12929,
    Code: '5235',
    Name: 'Engineer-mechanic, shipping'
  },
  {
    Id: 12930,
    Code: '5235',
    Name: 'Erector, beam, shipbuilding'
  },
  {
    Id: 12931,
    Code: '5235',
    Name: 'Fitter, deck'
  },
  {
    Id: 12932,
    Code: '5235',
    Name: 'Fitter, marine'
  },
  {
    Id: 12933,
    Code: '5235',
    Name: 'Fitter, ship'
  },
  {
    Id: 12934,
    Code: '5235',
    Name: 'Fitter, boat building and repairing'
  },
  {
    Id: 12935,
    Code: '5235',
    Name: 'Fitter, shipbuilding'
  },
  {
    Id: 12936,
    Code: '5235',
    Name: 'Framer, shipbuilding'
  },
  {
    Id: 12937,
    Code: '5235',
    Name: 'Holder-up, shipbuilding'
  },
  {
    Id: 12938,
    Code: '5235',
    Name: "Joiner, ship's"
  },
  {
    Id: 12939,
    Code: '5235',
    Name: 'Laminator, boat building and repairing'
  },
  {
    Id: 12940,
    Code: '5235',
    Name: 'Loftsman, shipbuilding'
  },
  {
    Id: 12941,
    Code: '5235',
    Name: 'Machinist, metal, shipyard'
  },
  {
    Id: 12942,
    Code: '5235',
    Name: 'Machinist, shipbuilding'
  },
  {
    Id: 12943,
    Code: '5235',
    Name: 'Maker, boat'
  },
  {
    Id: 12944,
    Code: '5235',
    Name: 'Maker, mast, shipbuilding'
  },
  {
    Id: 12945,
    Code: '5235',
    Name: 'Maker, thimble, shipbuilding'
  },
  {
    Id: 12946,
    Code: '5235',
    Name: 'Packer, shipbuilding'
  },
  {
    Id: 12947,
    Code: '5235',
    Name: 'Plater, ship'
  },
  {
    Id: 12948,
    Code: '5235',
    Name: "Plater, ship's"
  },
  {
    Id: 12949,
    Code: '5235',
    Name: 'Repairer, barge'
  },
  {
    Id: 12950,
    Code: '5235',
    Name: 'Repairer, boat'
  },
  {
    Id: 12951,
    Code: '5235',
    Name: 'Repairer, ship'
  },
  {
    Id: 12952,
    Code: '5235',
    Name: 'Repairer and builder, boat'
  },
  {
    Id: 12953,
    Code: '5235',
    Name: 'Shipbuilder'
  },
  {
    Id: 12954,
    Code: '5235',
    Name: 'Shipsmith'
  },
  {
    Id: 12955,
    Code: '5235',
    Name: 'Shipwright'
  },
  {
    Id: 12956,
    Code: '5235',
    Name: 'Shipwright-liner'
  },
  {
    Id: 12957,
    Code: '5235',
    Name: 'Steelworker, shipbuilding'
  },
  {
    Id: 12958,
    Code: '5235',
    Name: 'Technician, boat'
  },
  {
    Id: 12959,
    Code: '5235',
    Name: 'Turner, frame, shipbuilding'
  },
  {
    Id: 12960,
    Code: '5235',
    Name: 'Worker, blowlamp, shipbuilding'
  },
  {
    Id: 12961,
    Code: '5235',
    Name: 'Worker, steel, shipbuilding'
  },
  {
    Id: 12962,
    Code: '5236',
    Name: 'Engineer, fleet, railways'
  },
  {
    Id: 12963,
    Code: '5236',
    Name: 'Engineer, locomotive'
  },
  {
    Id: 12964,
    Code: '5236',
    Name: 'Engineer, railway'
  },
  {
    Id: 12965,
    Code: '5236',
    Name: 'Engineer, service, railway, rolling stock maintenance'
  },
  {
    Id: 12966,
    Code: '5236',
    Name: 'Engineer, stock, rolling'
  },
  {
    Id: 12967,
    Code: '5236',
    Name: 'Engineer, train'
  },
  {
    Id: 12968,
    Code: '5236',
    Name: 'Engineer, nos, railway, rolling stock maintenance'
  },
  {
    Id: 12969,
    Code: '5236',
    Name: 'Examiner, brake, railways'
  },
  {
    Id: 12970,
    Code: '5236',
    Name: 'Examiner, carriage, railways'
  },
  {
    Id: 12971,
    Code: '5236',
    Name: 'Examiner, carriage and wagon'
  },
  {
    Id: 12972,
    Code: '5236',
    Name: 'Examiner, coach, railways'
  },
  {
    Id: 12973,
    Code: '5236',
    Name: 'Examiner, wagon'
  },
  {
    Id: 12974,
    Code: '5236',
    Name: 'Finisher, railway workshops'
  },
  {
    Id: 12975,
    Code: '5236',
    Name: 'Fitter, carriage'
  },
  {
    Id: 12976,
    Code: '5236',
    Name: 'Fitter, carriage and wagon'
  },
  {
    Id: 12977,
    Code: '5236',
    Name: 'Fitter, frame, loco and rolling stock mfr'
  },
  {
    Id: 12978,
    Code: '5236',
    Name: 'Fitter, locking, signals'
  },
  {
    Id: 12979,
    Code: '5236',
    Name: 'Fitter, locomotive'
  },
  {
    Id: 12980,
    Code: '5236',
    Name: 'Fitter, maintenance, railway and rolling stock'
  },
  {
    Id: 12981,
    Code: '5236',
    Name: 'Fitter, mechanical, railway and rolling stock'
  },
  {
    Id: 12982,
    Code: '5236',
    Name: 'Fitter, tube, locomotive mfr'
  },
  {
    Id: 12983,
    Code: '5236',
    Name: 'Fitter, wagon, railway workshops'
  },
  {
    Id: 12984,
    Code: '5236',
    Name: 'Fitter, railways'
  },
  {
    Id: 12985,
    Code: '5236',
    Name: 'Fixer, railways'
  },
  {
    Id: 12986,
    Code: '5236',
    Name: 'Lifter, railway workshops'
  },
  {
    Id: 12987,
    Code: '5236',
    Name: 'Maintainer, train'
  },
  {
    Id: 12988,
    Code: '5236',
    Name: 'Maker, body, carriage'
  },
  {
    Id: 12989,
    Code: '5236',
    Name: 'Maker, body, railways'
  },
  {
    Id: 12990,
    Code: '5236',
    Name: 'Mounter, wheel and axle'
  },
  {
    Id: 12991,
    Code: '5236',
    Name: 'Repairer, carriage'
  },
  {
    Id: 12992,
    Code: '5236',
    Name: 'Repairer, coach, railways'
  },
  {
    Id: 12993,
    Code: '5236',
    Name: 'Stripper, boiler, locomotive'
  },
  {
    Id: 12994,
    Code: '5236',
    Name: 'Stripper, carriage and wagon'
  },
  {
    Id: 12995,
    Code: '5236',
    Name: 'Stripper, locomotive'
  },
  {
    Id: 12996,
    Code: '5236',
    Name: 'Stripper, railway workshops'
  },
  {
    Id: 12997,
    Code: '5236',
    Name: 'Technician, stock, rolling'
  },
  {
    Id: 12998,
    Code: '5236',
    Name: 'Tuber, engine, railways'
  },
  {
    Id: 12999,
    Code: '5236',
    Name: 'Tuber, locomotive mfr'
  },
  {
    Id: 13000,
    Code: '5236',
    Name: 'Wheeler, railway rolling stock mfr'
  },
  {
    Id: 13001,
    Code: '5241',
    Name: 'Attendant, light and bell'
  },
  {
    Id: 13002,
    Code: '5241',
    Name: 'Attendant, light and power'
  },
  {
    Id: 13003,
    Code: '5241',
    Name: 'Balancer, armature'
  },
  {
    Id: 13004,
    Code: '5241',
    Name: 'Builder, armature'
  },
  {
    Id: 13005,
    Code: '5241',
    Name: 'Builder, commutator'
  },
  {
    Id: 13006,
    Code: '5241',
    Name: 'Builder, transformer'
  },
  {
    Id: 13007,
    Code: '5241',
    Name: 'Constructor, switchboard'
  },
  {
    Id: 13008,
    Code: '5241',
    Name: 'Consultant, electrical'
  },
  {
    Id: 13009,
    Code: '5241',
    Name: 'Contractor, electrical'
  },
  {
    Id: 13010,
    Code: '5241',
    Name: 'Controller, electrical'
  },
  {
    Id: 13011,
    Code: '5241',
    Name: 'Craftsman, electrical'
  },
  {
    Id: 13012,
    Code: '5241',
    Name: 'Craftsman, transmission, electricity supplier'
  },
  {
    Id: 13013,
    Code: '5241',
    Name: 'Electrician, approved'
  },
  {
    Id: 13014,
    Code: '5241',
    Name: 'Electrician, domestic'
  },
  {
    Id: 13015,
    Code: '5241',
    Name: 'Electrician, lighting'
  },
  {
    Id: 13016,
    Code: '5241',
    Name: 'Electrician'
  },
  {
    Id: 13017,
    Code: '5241',
    Name: 'Engineer, appliance, domestic electrical'
  },
  {
    Id: 13018,
    Code: '5241',
    Name: 'Engineer, cinema'
  },
  {
    Id: 13019,
    Code: '5241',
    Name: 'Engineer, circuit, cinema'
  },
  {
    Id: 13020,
    Code: '5241',
    Name: 'Engineer, electrical, assistant'
  },
  {
    Id: 13021,
    Code: '5241',
    Name: 'Engineer, electrical, unit'
  },
  {
    Id: 13022,
    Code: '5241',
    Name: 'Engineer, electrical, nos, coal mine'
  },
  {
    Id: 13023,
    Code: '5241',
    Name: 'Engineer, electrical'
  },
  {
    Id: 13024,
    Code: '5241',
    Name: 'Engineer, electrical and mechanical'
  },
  {
    Id: 13025,
    Code: '5241',
    Name: 'Engineer, electro-mechanical'
  },
  {
    Id: 13026,
    Code: '5241',
    Name: 'Engineer, film'
  },
  {
    Id: 13027,
    Code: '5241',
    Name: 'Engineer, fuel, dual'
  },
  {
    Id: 13028,
    Code: '5241',
    Name: 'Engineer, illuminating'
  },
  {
    Id: 13029,
    Code: '5241',
    Name: 'Engineer, installation, electrical contracting'
  },
  {
    Id: 13030,
    Code: '5241',
    Name: 'Engineer, light, street'
  },
  {
    Id: 13031,
    Code: '5241',
    Name: 'Engineer, lighting, street'
  },
  {
    Id: 13032,
    Code: '5241',
    Name: 'Engineer, lighting'
  },
  {
    Id: 13033,
    Code: '5241',
    Name: 'Engineer, machine, domestic electrical appliances'
  },
  {
    Id: 13034,
    Code: '5241',
    Name: 'Engineer, meter, smart'
  },
  {
    Id: 13035,
    Code: '5241',
    Name: 'Engineer, meter, installation'
  },
  {
    Id: 13036,
    Code: '5241',
    Name: 'Engineer, office, post'
  },
  {
    Id: 13037,
    Code: '5241',
    Name: 'Engineer, powerhouse'
  },
  {
    Id: 13038,
    Code: '5241',
    Name: 'Engineer, relay'
  },
  {
    Id: 13039,
    Code: '5241',
    Name: 'Engineer, switchboard'
  },
  {
    Id: 13040,
    Code: '5241',
    Name: 'Engineer, switchgear'
  },
  {
    Id: 13041,
    Code: '5241',
    Name: 'Engineer, wiring'
  },
  {
    Id: 13042,
    Code: '5241',
    Name: 'Erector, switchgear'
  },
  {
    Id: 13043,
    Code: '5241',
    Name: 'Erector, transformer'
  },
  {
    Id: 13044,
    Code: '5241',
    Name: 'Erector, machinery, electrical'
  },
  {
    Id: 13045,
    Code: '5241',
    Name: 'Expert, energy, smart'
  },
  {
    Id: 13046,
    Code: '5241',
    Name: 'Expert, metering, smart'
  },
  {
    Id: 13047,
    Code: '5241',
    Name: 'Faultman, electricity supplier'
  },
  {
    Id: 13048,
    Code: '5241',
    Name: 'Finisher, armature'
  },
  {
    Id: 13049,
    Code: '5241',
    Name: 'Fitter, alternator'
  },
  {
    Id: 13050,
    Code: '5241',
    Name: 'Fitter, bench, electrical'
  },
  {
    Id: 13051,
    Code: '5241',
    Name: 'Fitter, controller'
  },
  {
    Id: 13052,
    Code: '5241',
    Name: 'Fitter, dynamo'
  },
  {
    Id: 13053,
    Code: '5241',
    Name: 'Fitter, electrical'
  },
  {
    Id: 13054,
    Code: '5241',
    Name: 'Fitter, electronic'
  },
  {
    Id: 13055,
    Code: '5241',
    Name: "Fitter, engineer's, electrical"
  },
  {
    Id: 13056,
    Code: '5241',
    Name: 'Fitter, engineering, electrical'
  },
  {
    Id: 13057,
    Code: '5241',
    Name: 'Fitter, fan'
  },
  {
    Id: 13058,
    Code: '5241',
    Name: 'Fitter, installation, electrical'
  },
  {
    Id: 13059,
    Code: '5241',
    Name: 'Fitter, lamp, arc'
  },
  {
    Id: 13060,
    Code: '5241',
    Name: 'Fitter, light, electric'
  },
  {
    Id: 13061,
    Code: '5241',
    Name: 'Fitter, machine, electrical machinery'
  },
  {
    Id: 13062,
    Code: '5241',
    Name: 'Fitter, mains, electricity supplier'
  },
  {
    Id: 13063,
    Code: '5241',
    Name: 'Fitter, meter, installation'
  },
  {
    Id: 13064,
    Code: '5241',
    Name: 'Fitter, motor, starter'
  },
  {
    Id: 13065,
    Code: '5241',
    Name: 'Fitter, motor, electric'
  },
  {
    Id: 13066,
    Code: '5241',
    Name: 'Fitter, panel, solar'
  },
  {
    Id: 13067,
    Code: '5241',
    Name: 'Fitter, pillar'
  },
  {
    Id: 13068,
    Code: '5241',
    Name: 'Fitter, production, electrical, electronic'
  },
  {
    Id: 13069,
    Code: '5241',
    Name: 'Fitter, sign, electric signs'
  },
  {
    Id: 13070,
    Code: '5241',
    Name: 'Fitter, switch'
  },
  {
    Id: 13071,
    Code: '5241',
    Name: 'Fitter, switchboard'
  },
  {
    Id: 13072,
    Code: '5241',
    Name: 'Fitter, switchgear'
  },
  {
    Id: 13073,
    Code: '5241',
    Name: 'Fitter, transformer'
  },
  {
    Id: 13074,
    Code: '5241',
    Name: 'Fitter, domestic appliances'
  },
  {
    Id: 13075,
    Code: '5241',
    Name: 'Fitter, machinery, electrical'
  },
  {
    Id: 13076,
    Code: '5241',
    Name: 'Fitter, electricity supplier'
  },
  {
    Id: 13077,
    Code: '5241',
    Name: 'Fixer, meter, electricity'
  },
  {
    Id: 13078,
    Code: '5241',
    Name: 'Fixer, motor, electric'
  },
  {
    Id: 13079,
    Code: '5241',
    Name: 'Fixer, sign, electric signs'
  },
  {
    Id: 13080,
    Code: '5241',
    Name: 'Hand, bench, electrical engineering'
  },
  {
    Id: 13081,
    Code: '5241',
    Name: 'Hand, commutator'
  },
  {
    Id: 13082,
    Code: '5241',
    Name: 'Improver, electrical'
  },
  {
    Id: 13083,
    Code: '5241',
    Name: 'Inspector, box, electricity supplier'
  },
  {
    Id: 13084,
    Code: '5241',
    Name: 'Installer, electrical'
  },
  {
    Id: 13085,
    Code: '5241',
    Name: 'Installer, meter, smart'
  },
  {
    Id: 13086,
    Code: '5241',
    Name: 'Installer, meter, electricity'
  },
  {
    Id: 13087,
    Code: '5241',
    Name: 'Installer, panel, solar'
  },
  {
    Id: 13088,
    Code: '5241',
    Name: 'Installer, point, charging, EV'
  },
  {
    Id: 13089,
    Code: '5241',
    Name: 'Installer, point, charging, vehicle, electric'
  },
  {
    Id: 13090,
    Code: '5241',
    Name: 'Installer, sign, electric signs'
  },
  {
    Id: 13091,
    Code: '5241',
    Name: 'Installer, electrical contracting'
  },
  {
    Id: 13092,
    Code: '5241',
    Name: 'Maker, components, telephone mfr'
  },
  {
    Id: 13093,
    Code: '5241',
    Name: 'Maker, magneto'
  },
  {
    Id: 13094,
    Code: '5241',
    Name: 'Maker, part, commutator'
  },
  {
    Id: 13095,
    Code: '5241',
    Name: 'Maker, rotor'
  },
  {
    Id: 13096,
    Code: '5241',
    Name: 'Maker, switchboard'
  },
  {
    Id: 13097,
    Code: '5241',
    Name: 'Maker, switchgear'
  },
  {
    Id: 13098,
    Code: '5241',
    Name: 'Maker, transformer'
  },
  {
    Id: 13099,
    Code: '5241',
    Name: 'Man, bell, mining'
  },
  {
    Id: 13100,
    Code: '5241',
    Name: 'Man, circuit, light, electric'
  },
  {
    Id: 13101,
    Code: '5241',
    Name: 'Mechanic, electrical'
  },
  {
    Id: 13102,
    Code: '5241',
    Name: 'Mechanic, electro'
  },
  {
    Id: 13103,
    Code: '5241',
    Name: 'Mechanic, meter, electricity supplier'
  },
  {
    Id: 13104,
    Code: '5241',
    Name: 'Operative, lighting, street'
  },
  {
    Id: 13105,
    Code: '5241',
    Name: 'Technician, lighting, stage'
  },
  {
    Id: 13106,
    Code: '5241',
    Name: 'Technician, lighting, street'
  },
  {
    Id: 13107,
    Code: '5241',
    Name: 'Technician, lighting'
  },
  {
    Id: 13108,
    Code: '5241',
    Name: 'Technician, meter, smart'
  },
  {
    Id: 13109,
    Code: '5241',
    Name: 'Technician, meter, electricity'
  },
  {
    Id: 13110,
    Code: '5241',
    Name: 'Tester, installation, electrical'
  },
  {
    Id: 13111,
    Code: '5241',
    Name: 'Tester, electrical contracting'
  },
  {
    Id: 13112,
    Code: '5241',
    Name: 'Wireman, electrical'
  },
  {
    Id: 13113,
    Code: '5241',
    Name: 'Wirer, electrical'
  },
  {
    Id: 13114,
    Code: '5241',
    Name: 'Wirer, electrical engineering'
  },
  {
    Id: 13115,
    Code: '5242',
    Name: 'Connector, cable'
  },
  {
    Id: 13116,
    Code: '5242',
    Name: 'Controller, network, telecoms'
  },
  {
    Id: 13117,
    Code: '5242',
    Name: 'Engineer, broadband'
  },
  {
    Id: 13118,
    Code: '5242',
    Name: 'Engineer, BSC, telecoms'
  },
  {
    Id: 13119,
    Code: '5242',
    Name: 'Engineer, BSS, telecoms'
  },
  {
    Id: 13120,
    Code: '5242',
    Name: 'Engineer, BT'
  },
  {
    Id: 13121,
    Code: '5242',
    Name: 'Engineer, cable'
  },
  {
    Id: 13122,
    Code: '5242',
    Name: 'Engineer, data, telecoms'
  },
  {
    Id: 13123,
    Code: '5242',
    Name: 'Engineer, exchange, telephone'
  },
  {
    Id: 13124,
    Code: '5242',
    Name: 'Engineer, field, service, home, broadband'
  },
  {
    Id: 13125,
    Code: '5242',
    Name: 'Engineer, field, telecoms'
  },
  {
    Id: 13126,
    Code: '5242',
    Name: 'Engineer, IN'
  },
  {
    Id: 13127,
    Code: '5242',
    Name: 'Engineer, installation, cable'
  },
  {
    Id: 13128,
    Code: '5242',
    Name: 'Engineer, installation, telephone'
  },
  {
    Id: 13129,
    Code: '5242',
    Name: 'Engineer, installation, telecoms'
  },
  {
    Id: 13130,
    Code: '5242',
    Name: 'Engineer, internet'
  },
  {
    Id: 13131,
    Code: '5242',
    Name: 'Engineer, line, telecoms'
  },
  {
    Id: 13132,
    Code: '5242',
    Name: 'Engineer, maintenance, telecoms'
  },
  {
    Id: 13133,
    Code: '5242',
    Name: 'Engineer, network, core'
  },
  {
    Id: 13134,
    Code: '5242',
    Name: 'Engineer, NOC, telecoms'
  },
  {
    Id: 13135,
    Code: '5242',
    Name: 'Engineer, NSS, telecoms'
  },
  {
    Id: 13136,
    Code: '5242',
    Name: 'Engineer, optic, fibre'
  },
  {
    Id: 13137,
    Code: '5242',
    Name: 'Engineer, provisioning, telecoms'
  },
  {
    Id: 13138,
    Code: '5242',
    Name: 'Engineer, service, customer, telecoms'
  },
  {
    Id: 13139,
    Code: '5242',
    Name: 'Engineer, service, telephone'
  },
  {
    Id: 13140,
    Code: '5242',
    Name: 'Engineer, Sky'
  },
  {
    Id: 13141,
    Code: '5242',
    Name: 'Engineer, support, telecoms'
  },
  {
    Id: 13142,
    Code: '5242',
    Name: 'Engineer, switch, telecoms'
  },
  {
    Id: 13143,
    Code: '5242',
    Name: 'Engineer, technical, telecoms'
  },
  {
    Id: 13144,
    Code: '5242',
    Name: 'Engineer, telecom'
  },
  {
    Id: 13145,
    Code: '5242',
    Name: 'Engineer, telecommunications'
  },
  {
    Id: 13146,
    Code: '5242',
    Name: 'Engineer, telecoms'
  },
  {
    Id: 13147,
    Code: '5242',
    Name: 'Engineer, telegraph'
  },
  {
    Id: 13148,
    Code: '5242',
    Name: 'Engineer, telephone'
  },
  {
    Id: 13149,
    Code: '5242',
    Name: 'Engineer, telephony and IT communications'
  },
  {
    Id: 13150,
    Code: '5242',
    Name: 'Engineer, transmission, telecoms'
  },
  {
    Id: 13151,
    Code: '5242',
    Name: 'Engineer, VAS'
  },
  {
    Id: 13152,
    Code: '5242',
    Name: 'Engineer, nos, telecoms'
  },
  {
    Id: 13153,
    Code: '5242',
    Name: 'Faultsman, telecoms'
  },
  {
    Id: 13154,
    Code: '5242',
    Name: 'Fitter, cable'
  },
  {
    Id: 13155,
    Code: '5242',
    Name: 'Fitter, installation, telecoms'
  },
  {
    Id: 13156,
    Code: '5242',
    Name: 'Fitter, maintenance, telecoms'
  },
  {
    Id: 13157,
    Code: '5242',
    Name: 'Fitter, telephone'
  },
  {
    Id: 13158,
    Code: '5242',
    Name: 'Hand, maintenance, telecoms'
  },
  {
    Id: 13159,
    Code: '5242',
    Name: 'Inspector, flight, radio, airport'
  },
  {
    Id: 13160,
    Code: '5242',
    Name: 'Installer, cable'
  },
  {
    Id: 13161,
    Code: '5242',
    Name: 'Installer, telecommunications'
  },
  {
    Id: 13162,
    Code: '5242',
    Name: 'Installer, telephone'
  },
  {
    Id: 13163,
    Code: '5242',
    Name: 'Installer, telephone service'
  },
  {
    Id: 13164,
    Code: '5242',
    Name: 'Jointer, cable'
  },
  {
    Id: 13165,
    Code: '5242',
    Name: 'Jointer, conduit, electric'
  },
  {
    Id: 13166,
    Code: '5242',
    Name: 'Jointer, electric'
  },
  {
    Id: 13167,
    Code: '5242',
    Name: 'Jointer, electrical'
  },
  {
    Id: 13168,
    Code: '5242',
    Name: 'Jointer, wire'
  },
  {
    Id: 13169,
    Code: '5242',
    Name: 'Jointer, cable laying'
  },
  {
    Id: 13170,
    Code: '5242',
    Name: 'Jointer, electricity supplier'
  },
  {
    Id: 13171,
    Code: '5242',
    Name: 'Jointer, telecoms'
  },
  {
    Id: 13172,
    Code: '5242',
    Name: 'Lineman, traction'
  },
  {
    Id: 13173,
    Code: '5242',
    Name: 'Linesman, telegraph'
  },
  {
    Id: 13174,
    Code: '5242',
    Name: 'Linesman, telephone'
  },
  {
    Id: 13175,
    Code: '5242',
    Name: 'Linesman, radio relay service'
  },
  {
    Id: 13176,
    Code: '5242',
    Name: 'Linesman, telecoms'
  },
  {
    Id: 13177,
    Code: '5242',
    Name: 'Mechanic, telecommunications'
  },
  {
    Id: 13178,
    Code: '5242',
    Name: 'Mechanic, telephone'
  },
  {
    Id: 13179,
    Code: '5242',
    Name: 'Mechanic, telephone'
  },
  {
    Id: 13180,
    Code: '5242',
    Name: 'Officer, engineering, PO'
  },
  {
    Id: 13181,
    Code: '5242',
    Name: 'Officer, maintenance, telecoms'
  },
  {
    Id: 13182,
    Code: '5242',
    Name: 'Officer, network, telecoms'
  },
  {
    Id: 13183,
    Code: '5242',
    Name: 'Officer, repeater, telecoms'
  },
  {
    Id: 13184,
    Code: '5242',
    Name: 'Officer, survey, telecoms'
  },
  {
    Id: 13185,
    Code: '5242',
    Name: 'Officer, technical, telecommunication, Civil Aviation Authority'
  },
  {
    Id: 13186,
    Code: '5242',
    Name: 'Officer, technical, telecoms'
  },
  {
    Id: 13187,
    Code: '5242',
    Name: 'Officer, testing, diagnostic'
  },
  {
    Id: 13188,
    Code: '5242',
    Name: 'Planner, band, wide, telecoms'
  },
  {
    Id: 13189,
    Code: '5242',
    Name: 'Planner, transmission, telecoms'
  },
  {
    Id: 13190,
    Code: '5242',
    Name: 'Plumber and jointer'
  },
  {
    Id: 13191,
    Code: '5242',
    Name: 'Probationer, railways'
  },
  {
    Id: 13192,
    Code: '5242',
    Name: 'Puller, cable'
  },
  {
    Id: 13193,
    Code: '5242',
    Name: 'Repairer, cable, electric'
  },
  {
    Id: 13194,
    Code: '5242',
    Name: 'Repairer, cord, telephone'
  },
  {
    Id: 13195,
    Code: '5242',
    Name: 'Repairer, wire'
  },
  {
    Id: 13196,
    Code: '5242',
    Name: 'Repairer, telephone'
  },
  {
    Id: 13197,
    Code: '5242',
    Name: 'T1, telecoms'
  },
  {
    Id: 13198,
    Code: '5242',
    Name: 'T2A, telecoms'
  },
  {
    Id: 13199,
    Code: '5242',
    Name: 'T2B, telecoms'
  },
  {
    Id: 13200,
    Code: '5242',
    Name: 'Technician, 1, telecoms'
  },
  {
    Id: 13201,
    Code: '5242',
    Name: 'Technician, 2A, telecoms'
  },
  {
    Id: 13202,
    Code: '5242',
    Name: 'Technician, 2B, telecoms'
  },
  {
    Id: 13203,
    Code: '5242',
    Name: 'Technician, electrical, telecoms'
  },
  {
    Id: 13204,
    Code: '5242',
    Name: 'Technician, factory, telecoms'
  },
  {
    Id: 13205,
    Code: '5242',
    Name: 'Technician, field, access, telecoms'
  },
  {
    Id: 13206,
    Code: '5242',
    Name: 'Technician, telecommunications'
  },
  {
    Id: 13207,
    Code: '5242',
    Name: 'Technician, telephone'
  },
  {
    Id: 13208,
    Code: '5242',
    Name: 'Technician, telephone'
  },
  {
    Id: 13209,
    Code: '5242',
    Name: 'Technician, 2B, telecoms'
  },
  {
    Id: 13210,
    Code: '5242',
    Name: 'Technician, telecoms'
  },
  {
    Id: 13211,
    Code: '5242',
    Name: 'Wireman, overhead'
  },
  {
    Id: 13212,
    Code: '5242',
    Name: 'Wireman, telephone'
  },
  {
    Id: 13213,
    Code: '5242',
    Name: 'Wireman, railways'
  },
  {
    Id: 13214,
    Code: '5242',
    Name: 'Wireman, telecoms'
  },
  {
    Id: 13215,
    Code: '5242',
    Name: 'Wirer, metal trades: telephone mfr'
  },
  {
    Id: 13216,
    Code: '5242',
    Name: 'Workman, skilled, telecoms'
  },
  {
    Id: 13217,
    Code: '5243',
    Name: 'Electrician, radio'
  },
  {
    Id: 13218,
    Code: '5243',
    Name: 'Engineer, audio, servicing'
  },
  {
    Id: 13219,
    Code: '5243',
    Name: 'Engineer, AV'
  },
  {
    Id: 13220,
    Code: '5243',
    Name: 'Engineer, electronics, television, video, audio repairing'
  },
  {
    Id: 13221,
    Code: '5243',
    Name: 'Engineer, field, radio, television and video servicing'
  },
  {
    Id: 13222,
    Code: '5243',
    Name: 'Engineer, installation, satellite'
  },
  {
    Id: 13223,
    Code: '5243',
    Name: 'Engineer, installation, radio, television and video servicing'
  },
  {
    Id: 13224,
    Code: '5243',
    Name: 'Engineer, maintenance, radio, television and video servicing'
  },
  {
    Id: 13225,
    Code: '5243',
    Name: 'Engineer, radio'
  },
  {
    Id: 13226,
    Code: '5243',
    Name: 'Engineer, radio and television'
  },
  {
    Id: 13227,
    Code: '5243',
    Name: 'Engineer, satellite'
  },
  {
    Id: 13228,
    Code: '5243',
    Name: 'Engineer, service, radio, television and video servicing'
  },
  {
    Id: 13229,
    Code: '5243',
    Name: 'Engineer, television'
  },
  {
    Id: 13230,
    Code: '5243',
    Name: 'Engineer, transmitter, radio'
  },
  {
    Id: 13231,
    Code: '5243',
    Name: 'Engineer, TV'
  },
  {
    Id: 13232,
    Code: '5243',
    Name: 'Engineer, video'
  },
  {
    Id: 13233,
    Code: '5243',
    Name: 'Engineer, visual, audio'
  },
  {
    Id: 13234,
    Code: '5243',
    Name: 'Engineer, workshop, radio, television and video servicing'
  },
  {
    Id: 13235,
    Code: '5243',
    Name: 'Fitter, maintenance, radio, television and video servicing'
  },
  {
    Id: 13236,
    Code: '5243',
    Name: 'Fitter, radio'
  },
  {
    Id: 13237,
    Code: '5243',
    Name: 'Fitter, television'
  },
  {
    Id: 13238,
    Code: '5243',
    Name: 'Installer, satellite'
  },
  {
    Id: 13239,
    Code: '5243',
    Name: 'Installer, television'
  },
  {
    Id: 13240,
    Code: '5243',
    Name: 'Man, service, sales, radio, television and video servicing'
  },
  {
    Id: 13241,
    Code: '5243',
    Name: 'Man, service, radio, television and video servicing'
  },
  {
    Id: 13242,
    Code: '5243',
    Name: 'Mechanic, radio'
  },
  {
    Id: 13243,
    Code: '5243',
    Name: 'Mechanic, service, radio, television and video servicing'
  },
  {
    Id: 13244,
    Code: '5243',
    Name: 'Mechanic, television'
  },
  {
    Id: 13245,
    Code: '5243',
    Name: 'Mechanic, radio, television and video servicing'
  },
  {
    Id: 13246,
    Code: '5243',
    Name: 'Mender, radio, television and video servicing'
  },
  {
    Id: 13247,
    Code: '5243',
    Name: 'Repairer, radio, television and video servicing'
  },
  {
    Id: 13248,
    Code: '5243',
    Name: 'Retuner, television'
  },
  {
    Id: 13249,
    Code: '5243',
    Name: 'Technician, radio'
  },
  {
    Id: 13250,
    Code: '5243',
    Name: 'Technician, service, radio, television and video servicing'
  },
  {
    Id: 13251,
    Code: '5243',
    Name: 'Technician, television'
  },
  {
    Id: 13252,
    Code: '5243',
    Name: 'Technician, television and video'
  },
  {
    Id: 13253,
    Code: '5243',
    Name: 'Technician, video'
  },
  {
    Id: 13254,
    Code: '5243',
    Name: 'Technician, radio, television and video servicing'
  },
  {
    Id: 13255,
    Code: '5243',
    Name: 'Tester, radio'
  },
  {
    Id: 13256,
    Code: '5243',
    Name: 'Tuner, television'
  },
  {
    Id: 13257,
    Code: '5244',
    Name: 'Engineer, computer'
  },
  {
    Id: 13258,
    Code: '5244',
    Name: 'Engineer, configuration'
  },
  {
    Id: 13259,
    Code: '5244',
    Name: 'Engineer, customer, computing'
  },
  {
    Id: 13260,
    Code: '5244',
    Name: 'Engineer, field, computer servicing'
  },
  {
    Id: 13261,
    Code: '5244',
    Name: 'Engineer, hardware, computer'
  },
  {
    Id: 13262,
    Code: '5244',
    Name: 'Engineer, installation, computer'
  },
  {
    Id: 13263,
    Code: '5244',
    Name: 'Engineer, IT'
  },
  {
    Id: 13264,
    Code: '5244',
    Name: 'Engineer, maintenance, computer'
  },
  {
    Id: 13265,
    Code: '5244',
    Name: 'Engineer, maintenance, computer servicing'
  },
  {
    Id: 13266,
    Code: '5244',
    Name: 'Engineer, service, computer'
  },
  {
    Id: 13267,
    Code: '5244',
    Name: 'Engineer, service, computer equipment'
  },
  {
    Id: 13268,
    Code: '5244',
    Name: 'Fitter, maintenance, computer'
  },
  {
    Id: 13269,
    Code: '5244',
    Name: 'Installer, systems, computer'
  },
  {
    Id: 13270,
    Code: '5244',
    Name: 'Repairer, computer'
  },
  {
    Id: 13271,
    Code: '5244',
    Name: 'Technician, service, computer'
  },
  {
    Id: 13272,
    Code: '5245',
    Name: 'Engineer, alarm'
  },
  {
    Id: 13273,
    Code: '5245',
    Name: 'Engineer, CCTV'
  },
  {
    Id: 13274,
    Code: '5245',
    Name: 'Engineer, fire and security'
  },
  {
    Id: 13275,
    Code: '5245',
    Name: 'Engineer, installation, alarms'
  },
  {
    Id: 13276,
    Code: '5245',
    Name: 'Engineer, maintenance, alarms'
  },
  {
    Id: 13277,
    Code: '5245',
    Name: 'Engineer, prevention, crime'
  },
  {
    Id: 13278,
    Code: '5245',
    Name: 'Engineer, security'
  },
  {
    Id: 13279,
    Code: '5245',
    Name: 'Engineer, service, alarm, fire'
  },
  {
    Id: 13280,
    Code: '5245',
    Name: 'Engineer, service, alarms'
  },
  {
    Id: 13281,
    Code: '5245',
    Name: 'Engineer, systems, security'
  },
  {
    Id: 13282,
    Code: '5245',
    Name: 'Fitter, alarm'
  },
  {
    Id: 13283,
    Code: '5245',
    Name: 'Installer, alarm'
  },
  {
    Id: 13284,
    Code: '5245',
    Name: 'Installer, security'
  },
  {
    Id: 13285,
    Code: '5245',
    Name: 'Installer, systems, security'
  },
  {
    Id: 13286,
    Code: '5245',
    Name: 'Technician, alarm, fire'
  },
  {
    Id: 13287,
    Code: '5246',
    Name: 'Craftsman, maintenance, electrical'
  },
  {
    Id: 13288,
    Code: '5246',
    Name: 'Electrician, maintenance, office machinery, electrical'
  },
  {
    Id: 13289,
    Code: '5246',
    Name: 'Engineer, appliance, domestic'
  },
  {
    Id: 13290,
    Code: '5246',
    Name: 'Engineer, copier'
  },
  {
    Id: 13291,
    Code: '5246',
    Name: 'Engineer, customer, office machinery'
  },
  {
    Id: 13292,
    Code: '5246',
    Name: 'Engineer, domestic, domestic appliances repairing'
  },
  {
    Id: 13293,
    Code: '5246',
    Name: 'Engineer, electrical, maintenance'
  },
  {
    Id: 13294,
    Code: '5246',
    Name: 'Engineer, fax'
  },
  {
    Id: 13295,
    Code: '5246',
    Name: 'Engineer, goods, white'
  },
  {
    Id: 13296,
    Code: '5246',
    Name: 'Engineer, machine, office machinery'
  },
  {
    Id: 13297,
    Code: '5246',
    Name: 'Engineer, maintenance, electrical'
  },
  {
    Id: 13298,
    Code: '5246',
    Name: 'Engineer, maintenance, electronics'
  },
  {
    Id: 13299,
    Code: '5246',
    Name: 'Engineer, maintenance, office machinery'
  },
  {
    Id: 13300,
    Code: '5246',
    Name: 'Engineer, microwave'
  },
  {
    Id: 13301,
    Code: '5246',
    Name: 'Engineer, photocopier'
  },
  {
    Id: 13302,
    Code: '5246',
    Name: 'Engineer, service, electrical'
  },
  {
    Id: 13303,
    Code: '5246',
    Name: 'Engineer, service, electronic'
  },
  {
    Id: 13304,
    Code: '5246',
    Name: 'Engineer, service, domestic electrical appliances'
  },
  {
    Id: 13305,
    Code: '5246',
    Name: 'Engineer, service'
  },
  {
    Id: 13306,
    Code: '5246',
    Name: 'Engineer, support, electrical, electronic equipment'
  },
  {
    Id: 13307,
    Code: '5246',
    Name: 'Engineer, typewriter'
  },
  {
    Id: 13308,
    Code: '5246',
    Name: 'Fitter, maintenance, electrical'
  },
  {
    Id: 13309,
    Code: '5246',
    Name: 'Fitter, maintenance, office machinery servicing'
  },
  {
    Id: 13310,
    Code: '5246',
    Name: 'Fitter, service, office machinery'
  },
  {
    Id: 13311,
    Code: '5246',
    Name: 'Hand, maintenance, typewriter'
  },
  {
    Id: 13312,
    Code: '5246',
    Name: 'Hand, maintenance, electricity supplier'
  },
  {
    Id: 13313,
    Code: '5246',
    Name: 'Inspector, machine, office machinery'
  },
  {
    Id: 13314,
    Code: '5246',
    Name: 'Man, maintenance, electrical'
  },
  {
    Id: 13315,
    Code: '5246',
    Name: 'Man, maintenance, machinery, electrical machines'
  },
  {
    Id: 13316,
    Code: '5246',
    Name: 'Man, maintenance, office machinery'
  },
  {
    Id: 13317,
    Code: '5246',
    Name: 'Man, maintenance, electricity supplier'
  },
  {
    Id: 13318,
    Code: '5246',
    Name: 'Man, service, sales, domestic appliances'
  },
  {
    Id: 13319,
    Code: '5246',
    Name: 'Man, service, sales, office machinery'
  },
  {
    Id: 13320,
    Code: '5246',
    Name: 'Man, service, automatic vending machines'
  },
  {
    Id: 13321,
    Code: '5246',
    Name: 'Man, service, domestic appliances'
  },
  {
    Id: 13322,
    Code: '5246',
    Name: 'Man, service, office machinery'
  },
  {
    Id: 13323,
    Code: '5246',
    Name: 'Man, service, radio relay service'
  },
  {
    Id: 13324,
    Code: '5246',
    Name: 'Mechanic, machine, adding'
  },
  {
    Id: 13325,
    Code: '5246',
    Name: 'Mechanic, machine, calculating'
  },
  {
    Id: 13326,
    Code: '5246',
    Name: 'Mechanic, machine, office machinery'
  },
  {
    Id: 13327,
    Code: '5246',
    Name: 'Mechanic, service, domestic electrical appliances'
  },
  {
    Id: 13328,
    Code: '5246',
    Name: 'Mechanic, service, office machinery'
  },
  {
    Id: 13329,
    Code: '5246',
    Name: 'Mechanic, service'
  },
  {
    Id: 13330,
    Code: '5246',
    Name: 'Mechanic, teleprinter'
  },
  {
    Id: 13331,
    Code: '5246',
    Name: 'Mechanic, totalisator'
  },
  {
    Id: 13332,
    Code: '5246',
    Name: 'Mechanic, typewriter'
  },
  {
    Id: 13333,
    Code: '5246',
    Name: 'Mechanic, office machinery'
  },
  {
    Id: 13334,
    Code: '5246',
    Name: 'Mender, domestic appliances'
  },
  {
    Id: 13335,
    Code: '5246',
    Name: 'Repairer, appliance, domestic'
  },
  {
    Id: 13336,
    Code: '5246',
    Name: 'Repairer, blanket, electric'
  },
  {
    Id: 13337,
    Code: '5246',
    Name: 'Repairer, controller'
  },
  {
    Id: 13338,
    Code: '5246',
    Name: 'Repairer, machine, office machinery'
  },
  {
    Id: 13339,
    Code: '5246',
    Name: 'Repairer, magneto'
  },
  {
    Id: 13340,
    Code: '5246',
    Name: 'Repairer, typewriter'
  },
  {
    Id: 13341,
    Code: '5246',
    Name: 'Repairer, domestic appliances'
  },
  {
    Id: 13342,
    Code: '5246',
    Name: 'Repairer, electrical machinery'
  },
  {
    Id: 13343,
    Code: '5246',
    Name: 'Repairer, office machinery'
  },
  {
    Id: 13344,
    Code: '5246',
    Name: 'Technician, service, office machinery'
  },
  {
    Id: 13345,
    Code: '5246',
    Name: 'Technician, service, domestic appliances repairing'
  },
  {
    Id: 13346,
    Code: '5246',
    Name: 'Technician, electronic equipment, maintenance'
  },
  {
    Id: 13347,
    Code: '5249',
    Name: 'Adjuster, relay'
  },
  {
    Id: 13348,
    Code: '5249',
    Name: 'Craftsman, electricity supplier'
  },
  {
    Id: 13349,
    Code: '5249',
    Name: 'Diagnostician, electrical, HM Dockyard'
  },
  {
    Id: 13350,
    Code: '5249',
    Name: 'Engineer, broadcast'
  },
  {
    Id: 13351,
    Code: '5249',
    Name: 'Engineer, broadcasting'
  },
  {
    Id: 13352,
    Code: '5249',
    Name: 'Engineer, communication'
  },
  {
    Id: 13353,
    Code: '5249',
    Name: 'Engineer, communications'
  },
  {
    Id: 13354,
    Code: '5249',
    Name: 'Engineer, electronic'
  },
  {
    Id: 13355,
    Code: '5249',
    Name: 'Engineer, electronics'
  },
  {
    Id: 13356,
    Code: '5249',
    Name: 'Engineer, field'
  },
  {
    Id: 13357,
    Code: '5249',
    Name: 'Engineer, light, traffic'
  },
  {
    Id: 13358,
    Code: '5249',
    Name: 'Engineer, mains, electrical'
  },
  {
    Id: 13359,
    Code: '5249',
    Name: 'Engineer, mains, electricity supplier'
  },
  {
    Id: 13360,
    Code: '5249',
    Name: 'Engineer, maintenance, electricity supplier'
  },
  {
    Id: 13361,
    Code: '5249',
    Name: 'Engineer, radar'
  },
  {
    Id: 13362,
    Code: '5249',
    Name: 'Engineer, signal, traffic'
  },
  {
    Id: 13363,
    Code: '5249',
    Name: 'Engineer, signal'
  },
  {
    Id: 13364,
    Code: '5249',
    Name: 'Engineer, x-ray'
  },
  {
    Id: 13365,
    Code: '5249',
    Name: 'Engineer, nos, broadcasting'
  },
  {
    Id: 13366,
    Code: '5249',
    Name: 'Fitter, interlocking'
  },
  {
    Id: 13367,
    Code: '5249',
    Name: 'Fitter, radar'
  },
  {
    Id: 13368,
    Code: '5249',
    Name: 'Fitter, signal, railway'
  },
  {
    Id: 13369,
    Code: '5249',
    Name: 'Fitter, telegraph'
  },
  {
    Id: 13370,
    Code: '5249',
    Name: 'Inspector, cable'
  },
  {
    Id: 13371,
    Code: '5249',
    Name: 'Installer, equipment, x-ray'
  },
  {
    Id: 13372,
    Code: '5249',
    Name: 'Installer, signal, railways'
  },
  {
    Id: 13373,
    Code: '5249',
    Name: 'Installer, signal and telecommunications, railways'
  },
  {
    Id: 13374,
    Code: '5249',
    Name: 'Installer, railways'
  },
  {
    Id: 13375,
    Code: '5249',
    Name: 'Lineman, power'
  },
  {
    Id: 13376,
    Code: '5249',
    Name: 'Lineman, signal, power'
  },
  {
    Id: 13377,
    Code: '5249',
    Name: 'Lineman'
  },
  {
    Id: 13378,
    Code: '5249',
    Name: 'Linesman, electrical'
  },
  {
    Id: 13379,
    Code: '5249',
    Name: 'Linesman, instrument, railways'
  },
  {
    Id: 13380,
    Code: '5249',
    Name: 'Linesman, overhead'
  },
  {
    Id: 13381,
    Code: '5249',
    Name: 'Linesman, power'
  },
  {
    Id: 13382,
    Code: '5249',
    Name: 'Linesman, railway'
  },
  {
    Id: 13383,
    Code: '5249',
    Name: 'Linesman, signal'
  },
  {
    Id: 13384,
    Code: '5249',
    Name: 'Linesman, coal mine: above ground'
  },
  {
    Id: 13385,
    Code: '5249',
    Name: 'Linesman, railways'
  },
  {
    Id: 13386,
    Code: '5249',
    Name: 'Linesman'
  },
  {
    Id: 13387,
    Code: '5249',
    Name: 'Linesman-erector'
  },
  {
    Id: 13388,
    Code: '5249',
    Name: 'Mechanic, electronic'
  },
  {
    Id: 13389,
    Code: '5249',
    Name: 'Mechanic, instrument, electronic'
  },
  {
    Id: 13390,
    Code: '5249',
    Name: 'Mechanic, radar'
  },
  {
    Id: 13391,
    Code: '5249',
    Name: 'Operator, board, test'
  },
  {
    Id: 13392,
    Code: '5249',
    Name: 'Technician, assistant, railway signalling'
  },
  {
    Id: 13393,
    Code: '5249',
    Name: 'Technician, installation, electrical, electronic'
  },
  {
    Id: 13394,
    Code: '5249',
    Name: 'Technician, radar'
  },
  {
    Id: 13395,
    Code: '5249',
    Name: 'Technician, signal, railways'
  },
  {
    Id: 13396,
    Code: '5249',
    Name: 'Technician, signalling'
  },
  {
    Id: 13397,
    Code: '5249',
    Name: 'Wireman, electronic'
  },
  {
    Id: 13398,
    Code: '5250',
    Name: 'Controller, workshop, garage'
  },
  {
    Id: 13399,
    Code: '5250',
    Name: 'Coordinator, maintenance, plant machinery repairing'
  },
  {
    Id: 13400,
    Code: '5250',
    Name: 'Electrician, chief'
  },
  {
    Id: 13401,
    Code: '5250',
    Name: 'Engineer, assistant, telecoms'
  },
  {
    Id: 13402,
    Code: '5250',
    Name: 'Engineer, charge, coal mine'
  },
  {
    Id: 13403,
    Code: '5250',
    Name: 'Engineer, electrical, colliery'
  },
  {
    Id: 13404,
    Code: '5250',
    Name: 'Engineer, mechanical, colliery'
  },
  {
    Id: 13405,
    Code: '5250',
    Name: 'Engineer, superintendent'
  },
  {
    Id: 13406,
    Code: '5250',
    Name: 'Fitter, superintendent'
  },
  {
    Id: 13407,
    Code: '5250',
    Name: 'Fitter-in-charge'
  },
  {
    Id: 13408,
    Code: '5250',
    Name: 'Foreman, bodyshop'
  },
  {
    Id: 13409,
    Code: '5250',
    Name: 'Foreman, building, coach'
  },
  {
    Id: 13410,
    Code: '5250',
    Name: 'Foreman, cable'
  },
  {
    Id: 13411,
    Code: '5250',
    Name: 'Foreman, electrical'
  },
  {
    Id: 13412,
    Code: '5250',
    Name: 'Foreman, electronics'
  },
  {
    Id: 13413,
    Code: '5250',
    Name: 'Foreman, engineering'
  },
  {
    Id: 13414,
    Code: '5250',
    Name: 'Foreman, factory, telecoms'
  },
  {
    Id: 13415,
    Code: '5250',
    Name: 'Foreman, forging'
  },
  {
    Id: 13416,
    Code: '5250',
    Name: 'Foreman, foundry'
  },
  {
    Id: 13417,
    Code: '5250',
    Name: 'Foreman, garage'
  },
  {
    Id: 13418,
    Code: '5250',
    Name: 'Foreman, general, manufacturing'
  },
  {
    Id: 13419,
    Code: '5250',
    Name: 'Foreman, installation, electrical'
  },
  {
    Id: 13420,
    Code: '5250',
    Name: 'Foreman, instrument'
  },
  {
    Id: 13421,
    Code: '5250',
    Name: 'Foreman, lime, foundry'
  },
  {
    Id: 13422,
    Code: '5250',
    Name: 'Foreman, lines, overhead'
  },
  {
    Id: 13423,
    Code: '5250',
    Name: 'Foreman, mains, electricity supplier'
  },
  {
    Id: 13424,
    Code: '5250',
    Name: 'Foreman, maintenance, electrical'
  },
  {
    Id: 13425,
    Code: '5250',
    Name: 'Foreman, maintenance, loco'
  },
  {
    Id: 13426,
    Code: '5250',
    Name: 'Foreman, maintenance, electricity supplier'
  },
  {
    Id: 13427,
    Code: '5250',
    Name: 'Foreman, maintenance, manufacturing'
  },
  {
    Id: 13428,
    Code: '5250',
    Name: 'Foreman, maintenance, telecoms'
  },
  {
    Id: 13429,
    Code: '5250',
    Name: 'Foreman, maintenance, transport'
  },
  {
    Id: 13430,
    Code: '5250',
    Name: 'Foreman, mechanical'
  },
  {
    Id: 13431,
    Code: '5250',
    Name: 'Foreman, overhauling, vehicle'
  },
  {
    Id: 13432,
    Code: '5250',
    Name: 'Foreman, piercing'
  },
  {
    Id: 13433,
    Code: '5250',
    Name: 'Foreman, press, metal trades'
  },
  {
    Id: 13434,
    Code: '5250',
    Name: 'Foreman, room, tool'
  },
  {
    Id: 13435,
    Code: '5250',
    Name: 'Foreman, senior, manufacturing'
  },
  {
    Id: 13436,
    Code: '5250',
    Name: 'Foreman, shop, auto'
  },
  {
    Id: 13437,
    Code: '5250',
    Name: 'Foreman, shop, body, vehicles'
  },
  {
    Id: 13438,
    Code: '5250',
    Name: 'Foreman, shop, casting'
  },
  {
    Id: 13439,
    Code: '5250',
    Name: 'Foreman, shop, erecting, engineering'
  },
  {
    Id: 13440,
    Code: '5250',
    Name: 'Foreman, shop, machine'
  },
  {
    Id: 13441,
    Code: '5250',
    Name: 'Foreman, shop, panel'
  },
  {
    Id: 13442,
    Code: '5250',
    Name: 'Foreman, shop, press, metal trades'
  },
  {
    Id: 13443,
    Code: '5250',
    Name: 'Foreman, shop, coal mine'
  },
  {
    Id: 13444,
    Code: '5250',
    Name: 'Foreman, shop, engineering'
  },
  {
    Id: 13445,
    Code: '5250',
    Name: 'Foreman, stamping'
  },
  {
    Id: 13446,
    Code: '5250',
    Name: 'Foreman, tool, press'
  },
  {
    Id: 13447,
    Code: '5250',
    Name: 'Foreman, welding'
  },
  {
    Id: 13448,
    Code: '5250',
    Name: 'Foreman, works, manufacturing'
  },
  {
    Id: 13449,
    Code: '5250',
    Name: 'Foreman, workshop, vehicles'
  },
  {
    Id: 13450,
    Code: '5250',
    Name: 'Foreman, yard, ship'
  },
  {
    Id: 13451,
    Code: '5250',
    Name: 'Foreman, aero-engine mfr'
  },
  {
    Id: 13452,
    Code: '5250',
    Name: 'Foreman, coach building'
  },
  {
    Id: 13453,
    Code: '5250',
    Name: 'Foreman, electrical contracting'
  },
  {
    Id: 13454,
    Code: '5250',
    Name: 'Foreman, electricity supplier'
  },
  {
    Id: 13455,
    Code: '5250',
    Name: 'Foreman, electronic equipment mfr'
  },
  {
    Id: 13456,
    Code: '5250',
    Name: 'Foreman, forging'
  },
  {
    Id: 13457,
    Code: '5250',
    Name: 'Foreman, foundry'
  },
  {
    Id: 13458,
    Code: '5250',
    Name: 'Foreman, garage'
  },
  {
    Id: 13459,
    Code: '5250',
    Name: 'Foreman, hydraulic pump mfr'
  },
  {
    Id: 13460,
    Code: '5250',
    Name: 'Foreman, internal combustion engine mfr'
  },
  {
    Id: 13461,
    Code: '5250',
    Name: 'Foreman, lamp, valve mfr'
  },
  {
    Id: 13462,
    Code: '5250',
    Name: 'Foreman, machine shop'
  },
  {
    Id: 13463,
    Code: '5250',
    Name: 'Foreman, metal box mfr'
  },
  {
    Id: 13464,
    Code: '5250',
    Name: 'Foreman, metal instrument mfr'
  },
  {
    Id: 13465,
    Code: '5250',
    Name: 'Foreman, metal pressing'
  },
  {
    Id: 13466,
    Code: '5250',
    Name: 'Foreman, metal smallwares mfr'
  },
  {
    Id: 13467,
    Code: '5250',
    Name: 'Foreman, metal stamping, piercing'
  },
  {
    Id: 13468,
    Code: '5250',
    Name: 'Foreman, metal trades: assembling'
  },
  {
    Id: 13469,
    Code: '5250',
    Name: 'Foreman, office machinery mfr'
  },
  {
    Id: 13470,
    Code: '5250',
    Name: 'Foreman, power tools mfr'
  },
  {
    Id: 13471,
    Code: '5250',
    Name: 'Foreman, press tool mfr'
  },
  {
    Id: 13472,
    Code: '5250',
    Name: 'Foreman, radio, television and video mfr'
  },
  {
    Id: 13473,
    Code: '5250',
    Name: 'Foreman, railways: carriage, wagon dept'
  },
  {
    Id: 13474,
    Code: '5250',
    Name: 'Foreman, railways: locomotive shop'
  },
  {
    Id: 13475,
    Code: '5250',
    Name: 'Foreman, railways: motive power dept'
  },
  {
    Id: 13476,
    Code: '5250',
    Name: 'Foreman, railways: signal and telecoms'
  },
  {
    Id: 13477,
    Code: '5250',
    Name: 'Foreman, sheet metal working'
  },
  {
    Id: 13478,
    Code: '5250',
    Name: 'Foreman, shipbuilding'
  },
  {
    Id: 13479,
    Code: '5250',
    Name: 'Foreman, signalling equipment'
  },
  {
    Id: 13480,
    Code: '5250',
    Name: 'Foreman, small tools mfr'
  },
  {
    Id: 13481,
    Code: '5250',
    Name: 'Foreman, steel fabrication'
  },
  {
    Id: 13482,
    Code: '5250',
    Name: 'Foreman, telecoms'
  },
  {
    Id: 13483,
    Code: '5250',
    Name: 'Foreman, tool room'
  },
  {
    Id: 13484,
    Code: '5250',
    Name: 'Foreman, transformers and switchgear mfr'
  },
  {
    Id: 13485,
    Code: '5250',
    Name: 'Foreman, welding'
  },
  {
    Id: 13486,
    Code: '5250',
    Name: 'Foreman-ganger, telecoms'
  },
  {
    Id: 13487,
    Code: '5250',
    Name: 'Gaffer, film, television production'
  },
  {
    Id: 13488,
    Code: '5250',
    Name: 'Inspector, civils, telecoms'
  },
  {
    Id: 13489,
    Code: '5250',
    Name: 'Inspector, engineering, telecoms'
  },
  {
    Id: 13490,
    Code: '5250',
    Name: 'Inspector, field, telecoms'
  },
  {
    Id: 13491,
    Code: '5250',
    Name: 'Inspector, installation, telecoms'
  },
  {
    Id: 13492,
    Code: '5250',
    Name: 'Inspector, maintenance, electricity supplier'
  },
  {
    Id: 13493,
    Code: '5250',
    Name: 'Inspector, signal, railways'
  },
  {
    Id: 13494,
    Code: '5250',
    Name: 'Inspector, signal and telecommunications, railways'
  },
  {
    Id: 13495,
    Code: '5250',
    Name: 'Inspector, telephones'
  },
  {
    Id: 13496,
    Code: '5250',
    Name: 'Inspector, telecoms: engineering dept'
  },
  {
    Id: 13497,
    Code: '5250',
    Name: 'Inspector, telecoms: telephone dept'
  },
  {
    Id: 13498,
    Code: '5250',
    Name: 'Inspector of electrical fitters'
  },
  {
    Id: 13499,
    Code: '5250',
    Name: 'Inspector of electricians'
  },
  {
    Id: 13500,
    Code: '5250',
    Name: 'Inspector of fitters'
  },
  {
    Id: 13501,
    Code: '5250',
    Name: 'Inspector of mechanics'
  },
  {
    Id: 13502,
    Code: '5250',
    Name: 'Inspector of shipwrights'
  },
  {
    Id: 13503,
    Code: '5250',
    Name: 'Leader, team, electrical'
  },
  {
    Id: 13504,
    Code: '5250',
    Name: 'Leader, team, engineering'
  },
  {
    Id: 13505,
    Code: '5250',
    Name: 'Manager, installation, security systems'
  },
  {
    Id: 13506,
    Code: '5250',
    Name: 'Manager, room, tool'
  },
  {
    Id: 13507,
    Code: '5250',
    Name: 'Manager, service, garage'
  },
  {
    Id: 13508,
    Code: '5250',
    Name: 'Manager, workshop, garage'
  },
  {
    Id: 13509,
    Code: '5250',
    Name: 'Manager, workshop'
  },
  {
    Id: 13510,
    Code: '5250',
    Name: 'Manager, workshops'
  },
  {
    Id: 13511,
    Code: '5250',
    Name: 'Mechanic, electro, chief'
  },
  {
    Id: 13512,
    Code: '5250',
    Name: 'Mechanic, electro, district'
  },
  {
    Id: 13513,
    Code: '5250',
    Name: 'Mechanic-in-charge'
  },
  {
    Id: 13514,
    Code: '5250',
    Name: 'Observer, teeming'
  },
  {
    Id: 13515,
    Code: '5250',
    Name: 'Superintendent, electrical'
  },
  {
    Id: 13516,
    Code: '5250',
    Name: 'Superintendent, engineering'
  },
  {
    Id: 13517,
    Code: '5250',
    Name: 'Superintendent, maintenance, manufacturing'
  },
  {
    Id: 13518,
    Code: '5250',
    Name: 'Superintendent, engineering'
  },
  {
    Id: 13519,
    Code: '5250',
    Name: 'Superintendent, garage'
  },
  {
    Id: 13520,
    Code: '5250',
    Name: 'Superintendent, metal trades'
  },
  {
    Id: 13521,
    Code: '5250',
    Name: 'Supervisor, chief, senior, telecoms'
  },
  {
    Id: 13522,
    Code: '5250',
    Name: 'Supervisor, craft, oil refining'
  },
  {
    Id: 13523,
    Code: '5250',
    Name: 'Supervisor, electrical'
  },
  {
    Id: 13524,
    Code: '5250',
    Name: 'Supervisor, engineering'
  },
  {
    Id: 13525,
    Code: '5250',
    Name: 'Supervisor, maintenance, manufacturing'
  },
  {
    Id: 13526,
    Code: '5250',
    Name: 'Supervisor, mechanical'
  },
  {
    Id: 13527,
    Code: '5250',
    Name: 'Supervisor, service, instruments'
  },
  {
    Id: 13528,
    Code: '5250',
    Name: 'Supervisor, service, electrical engineering'
  },
  {
    Id: 13529,
    Code: '5250',
    Name: 'Supervisor, shop, machine'
  },
  {
    Id: 13530,
    Code: '5250',
    Name: 'Supervisor, welding'
  },
  {
    Id: 13531,
    Code: '5250',
    Name: 'Tuner, head'
  },
  {
    Id: 13532,
    Code: '5311',
    Name: 'Climber, constructional engineering'
  },
  {
    Id: 13533,
    Code: '5311',
    Name: 'Constructor, steel'
  },
  {
    Id: 13534,
    Code: '5311',
    Name: 'Engineer, constructional'
  },
  {
    Id: 13535,
    Code: '5311',
    Name: 'Erector, chimney, metal'
  },
  {
    Id: 13536,
    Code: '5311',
    Name: 'Erector, furnace'
  },
  {
    Id: 13537,
    Code: '5311',
    Name: 'Erector, girder'
  },
  {
    Id: 13538,
    Code: '5311',
    Name: 'Erector, ironwork'
  },
  {
    Id: 13539,
    Code: '5311',
    Name: 'Erector, plate, steel'
  },
  {
    Id: 13540,
    Code: '5311',
    Name: 'Erector, sheeter'
  },
  {
    Id: 13541,
    Code: '5311',
    Name: 'Erector, staircase, iron'
  },
  {
    Id: 13542,
    Code: '5311',
    Name: 'Erector, steel'
  },
  {
    Id: 13543,
    Code: '5311',
    Name: 'Erector, steelwork'
  },
  {
    Id: 13544,
    Code: '5311',
    Name: 'Erector, structural'
  },
  {
    Id: 13545,
    Code: '5311',
    Name: 'Erector, tower'
  },
  {
    Id: 13546,
    Code: '5311',
    Name: 'Erector, steel'
  },
  {
    Id: 13547,
    Code: '5311',
    Name: 'Erector, coal mine: above ground'
  },
  {
    Id: 13548,
    Code: '5311',
    Name: 'Erector, engineering: structural'
  },
  {
    Id: 13549,
    Code: '5311',
    Name: 'Fabricator, steel'
  },
  {
    Id: 13550,
    Code: '5311',
    Name: 'Fighter, iron'
  },
  {
    Id: 13551,
    Code: '5311',
    Name: 'Fitter and erector, constructional engineering'
  },
  {
    Id: 13552,
    Code: '5311',
    Name: 'Spiderman'
  },
  {
    Id: 13553,
    Code: '5311',
    Name: 'Steelworker, structural engineering'
  },
  {
    Id: 13554,
    Code: '5311',
    Name: 'Worker, iron, constructional engineering'
  },
  {
    Id: 13555,
    Code: '5311',
    Name: 'Worker, steel, structural engineering'
  },
  {
    Id: 13556,
    Code: '5312',
    Name: 'Banker, stone working'
  },
  {
    Id: 13557,
    Code: '5312',
    Name: 'Blaster, sand, monumental masons'
  },
  {
    Id: 13558,
    Code: '5312',
    Name: 'Blockman, mine: not coal'
  },
  {
    Id: 13559,
    Code: '5312',
    Name: 'Builder, millstone'
  },
  {
    Id: 13560,
    Code: '5312',
    Name: 'Carver, architectural'
  },
  {
    Id: 13561,
    Code: '5312',
    Name: 'Carver, monumental'
  },
  {
    Id: 13562,
    Code: '5312',
    Name: 'Carver, stone'
  },
  {
    Id: 13563,
    Code: '5312',
    Name: 'Cutter, letter, monumental masons'
  },
  {
    Id: 13564,
    Code: '5312',
    Name: 'Cutter, sett'
  },
  {
    Id: 13565,
    Code: '5312',
    Name: 'Cutter, slate'
  },
  {
    Id: 13566,
    Code: '5312',
    Name: 'Cutter, stone'
  },
  {
    Id: 13567,
    Code: '5312',
    Name: 'Ditcher, stone'
  },
  {
    Id: 13568,
    Code: '5312',
    Name: 'Drafter, stone'
  },
  {
    Id: 13569,
    Code: '5312',
    Name: 'Dresser, kerb'
  },
  {
    Id: 13570,
    Code: '5312',
    Name: 'Dresser, sett'
  },
  {
    Id: 13571,
    Code: '5312',
    Name: 'Dresser, slate'
  },
  {
    Id: 13572,
    Code: '5312',
    Name: 'Dresser, stone'
  },
  {
    Id: 13573,
    Code: '5312',
    Name: 'Dresser, wallstone'
  },
  {
    Id: 13574,
    Code: '5312',
    Name: 'Dresser, mine: not coal'
  },
  {
    Id: 13575,
    Code: '5312',
    Name: 'Dresser, stone dressing'
  },
  {
    Id: 13576,
    Code: '5312',
    Name: 'Engraver, letter'
  },
  {
    Id: 13577,
    Code: '5312',
    Name: 'Engraver, monumental'
  },
  {
    Id: 13578,
    Code: '5312',
    Name: 'Engraver, stone'
  },
  {
    Id: 13579,
    Code: '5312',
    Name: 'Engraver, monumental masons'
  },
  {
    Id: 13580,
    Code: '5312',
    Name: 'Facer, stone dressing'
  },
  {
    Id: 13581,
    Code: '5312',
    Name: 'Fixer, marble'
  },
  {
    Id: 13582,
    Code: '5312',
    Name: "Fixer, mason's, stone"
  },
  {
    Id: 13583,
    Code: '5312',
    Name: 'Fixer-mason'
  },
  {
    Id: 13584,
    Code: '5312',
    Name: 'Grinder, stone, lithographic'
  },
  {
    Id: 13585,
    Code: '5312',
    Name: 'Grinder, stone'
  },
  {
    Id: 13586,
    Code: '5312',
    Name: 'Hand, banker'
  },
  {
    Id: 13587,
    Code: '5312',
    Name: 'Hand, mason'
  },
  {
    Id: 13588,
    Code: '5312',
    Name: 'Hedger, stone'
  },
  {
    Id: 13589,
    Code: '5312',
    Name: 'Machinist, splitting, stone'
  },
  {
    Id: 13590,
    Code: '5312',
    Name: 'Machinist, stone'
  },
  {
    Id: 13591,
    Code: '5312',
    Name: 'Maker, gravestone'
  },
  {
    Id: 13592,
    Code: '5312',
    Name: 'Maker, kerb'
  },
  {
    Id: 13593,
    Code: '5312',
    Name: 'Maker, segment'
  },
  {
    Id: 13594,
    Code: '5312',
    Name: 'Maker, sett'
  },
  {
    Id: 13595,
    Code: '5312',
    Name: 'Maker, slab, mine: not coal'
  },
  {
    Id: 13596,
    Code: '5312',
    Name: 'Maker, slate'
  },
  {
    Id: 13597,
    Code: '5312',
    Name: 'Maker, stone, grave'
  },
  {
    Id: 13598,
    Code: '5312',
    Name: 'Maker, stone, kerb, mine: not coal'
  },
  {
    Id: 13599,
    Code: '5312',
    Name: 'Maker, stone, oil'
  },
  {
    Id: 13600,
    Code: '5312',
    Name: 'Maker, stone, pulp'
  },
  {
    Id: 13601,
    Code: '5312',
    Name: 'Maker, tank, slate'
  },
  {
    Id: 13602,
    Code: '5312',
    Name: 'Man, saw, stone'
  },
  {
    Id: 13603,
    Code: '5312',
    Name: 'Man, slab'
  },
  {
    Id: 13604,
    Code: '5312',
    Name: 'Mason, banker'
  },
  {
    Id: 13605,
    Code: '5312',
    Name: 'Mason, fixer'
  },
  {
    Id: 13606,
    Code: '5312',
    Name: 'Mason, monumental'
  },
  {
    Id: 13607,
    Code: '5312',
    Name: 'Mason, quarry'
  },
  {
    Id: 13608,
    Code: '5312',
    Name: 'Mason, stone'
  },
  {
    Id: 13609,
    Code: '5312',
    Name: 'Mason, walling'
  },
  {
    Id: 13610,
    Code: '5312',
    Name: 'Mason'
  },
  {
    Id: 13611,
    Code: '5312',
    Name: 'Miner, wall'
  },
  {
    Id: 13612,
    Code: '5312',
    Name: 'Planer, slate'
  },
  {
    Id: 13613,
    Code: '5312',
    Name: 'Planer, stone'
  },
  {
    Id: 13614,
    Code: '5312',
    Name: 'Polisher, granite'
  },
  {
    Id: 13615,
    Code: '5312',
    Name: 'Polisher, lathe, stone dressing'
  },
  {
    Id: 13616,
    Code: '5312',
    Name: 'Polisher, marble'
  },
  {
    Id: 13617,
    Code: '5312',
    Name: 'Polisher, slate'
  },
  {
    Id: 13618,
    Code: '5312',
    Name: 'Polisher, stone'
  },
  {
    Id: 13619,
    Code: '5312',
    Name: 'Polisher, stone dressing'
  },
  {
    Id: 13620,
    Code: '5312',
    Name: 'River, mine: not coal'
  },
  {
    Id: 13621,
    Code: '5312',
    Name: 'Sawyer, slate'
  },
  {
    Id: 13622,
    Code: '5312',
    Name: 'Sawyer, stone'
  },
  {
    Id: 13623,
    Code: '5312',
    Name: 'Scabbler, stone'
  },
  {
    Id: 13624,
    Code: '5312',
    Name: 'Scapler'
  },
  {
    Id: 13625,
    Code: '5312',
    Name: 'Scappler'
  },
  {
    Id: 13626,
    Code: '5312',
    Name: 'Setter, retort'
  },
  {
    Id: 13627,
    Code: '5312',
    Name: 'Splitter, slate'
  },
  {
    Id: 13628,
    Code: '5312',
    Name: 'Splitter, mine: not coal: above ground'
  },
  {
    Id: 13629,
    Code: '5312',
    Name: 'Stoneman, stone dressing'
  },
  {
    Id: 13630,
    Code: '5312',
    Name: 'Stonemason'
  },
  {
    Id: 13631,
    Code: '5312',
    Name: 'Tooler, stone'
  },
  {
    Id: 13632,
    Code: '5312',
    Name: 'Trimmer, mine: not coal'
  },
  {
    Id: 13633,
    Code: '5312',
    Name: 'Turner, stone'
  },
  {
    Id: 13634,
    Code: '5312',
    Name: 'Turner, stone dressing'
  },
  {
    Id: 13635,
    Code: '5312',
    Name: 'Waller, dry'
  },
  {
    Id: 13636,
    Code: '5312',
    Name: 'Waller, stone, dry'
  },
  {
    Id: 13637,
    Code: '5312',
    Name: 'Waller'
  },
  {
    Id: 13638,
    Code: '5312',
    Name: 'Worker, marble'
  },
  {
    Id: 13639,
    Code: '5312',
    Name: 'Worker, mill, slate'
  },
  {
    Id: 13640,
    Code: '5312',
    Name: 'Worker, serpentine'
  },
  {
    Id: 13641,
    Code: '5312',
    Name: 'Worker, slate, enamelled'
  },
  {
    Id: 13642,
    Code: '5313',
    Name: 'Archer, brick'
  },
  {
    Id: 13643,
    Code: '5313',
    Name: 'Archman, brick'
  },
  {
    Id: 13644,
    Code: '5313',
    Name: 'Bricker, ladles, iron works'
  },
  {
    Id: 13645,
    Code: '5313',
    Name: 'Bricker, mould'
  },
  {
    Id: 13646,
    Code: '5313',
    Name: 'Bricklayer'
  },
  {
    Id: 13647,
    Code: '5313',
    Name: 'Bricky'
  },
  {
    Id: 13648,
    Code: '5313',
    Name: 'Builder, arch, brick'
  },
  {
    Id: 13649,
    Code: '5313',
    Name: 'Builder, box, PO'
  },
  {
    Id: 13650,
    Code: '5313',
    Name: 'Builder, chimney'
  },
  {
    Id: 13651,
    Code: '5313',
    Name: 'Builder, cupola'
  },
  {
    Id: 13652,
    Code: '5313',
    Name: 'Builder, furnace'
  },
  {
    Id: 13653,
    Code: '5313',
    Name: 'Builder, kiln, brick'
  },
  {
    Id: 13654,
    Code: '5313',
    Name: 'Builder, manhole'
  },
  {
    Id: 13655,
    Code: '5313',
    Name: 'Builder, oven'
  },
  {
    Id: 13656,
    Code: '5313',
    Name: 'Builder, retort'
  },
  {
    Id: 13657,
    Code: '5313',
    Name: 'Builder, sewer'
  },
  {
    Id: 13658,
    Code: '5313',
    Name: 'Builder, stove'
  },
  {
    Id: 13659,
    Code: '5313',
    Name: 'Contractor, bricklaying'
  },
  {
    Id: 13660,
    Code: '5313',
    Name: 'Contractor, bricklaying'
  },
  {
    Id: 13661,
    Code: '5313',
    Name: 'Dyker, drystone'
  },
  {
    Id: 13662,
    Code: '5313',
    Name: 'Engineer, refractory'
  },
  {
    Id: 13663,
    Code: '5313',
    Name: 'Fettler, cupola'
  },
  {
    Id: 13664,
    Code: '5313',
    Name: 'Fixer, boiler'
  },
  {
    Id: 13665,
    Code: '5313',
    Name: 'Fixer, range'
  },
  {
    Id: 13666,
    Code: '5313',
    Name: 'Installer, refractory'
  },
  {
    Id: 13667,
    Code: '5313',
    Name: 'Layer, block, blast furnace'
  },
  {
    Id: 13668,
    Code: '5313',
    Name: 'Layer, brick'
  },
  {
    Id: 13669,
    Code: '5313',
    Name: 'Layer, floor, stone'
  },
  {
    Id: 13670,
    Code: '5313',
    Name: 'Liner, furnace'
  },
  {
    Id: 13671,
    Code: '5313',
    Name: 'Liner, ladle'
  },
  {
    Id: 13672,
    Code: '5313',
    Name: 'Maker, trumpet, steelworks'
  },
  {
    Id: 13673,
    Code: '5313',
    Name: 'Man, trumpet'
  },
  {
    Id: 13674,
    Code: '5313',
    Name: 'Mason, coal mine'
  },
  {
    Id: 13675,
    Code: '5313',
    Name: 'Patcher, cupola, steelworks'
  },
  {
    Id: 13676,
    Code: '5313',
    Name: 'Patcher, oven'
  },
  {
    Id: 13677,
    Code: '5313',
    Name: 'Patcher, vessel'
  },
  {
    Id: 13678,
    Code: '5313',
    Name: 'Pointer, brick'
  },
  {
    Id: 13679,
    Code: '5313',
    Name: 'Pointer, building construction'
  },
  {
    Id: 13680,
    Code: '5313',
    Name: 'Reliner, steelworks'
  },
  {
    Id: 13681,
    Code: '5313',
    Name: 'Repairer, chimney'
  },
  {
    Id: 13682,
    Code: '5313',
    Name: 'Repairer, cupola'
  },
  {
    Id: 13683,
    Code: '5313',
    Name: 'Repairer, furnace'
  },
  {
    Id: 13684,
    Code: '5313',
    Name: 'Repairer, kiln'
  },
  {
    Id: 13685,
    Code: '5313',
    Name: 'Repairer, oven'
  },
  {
    Id: 13686,
    Code: '5313',
    Name: 'Setter, block'
  },
  {
    Id: 13687,
    Code: '5313',
    Name: 'Setter, brick'
  },
  {
    Id: 13688,
    Code: '5313',
    Name: 'Setter, fixture'
  },
  {
    Id: 13689,
    Code: '5313',
    Name: 'Worker, trowel'
  },
  {
    Id: 13690,
    Code: '5314',
    Name: 'Caster, parchester'
  },
  {
    Id: 13691,
    Code: '5314',
    Name: 'Cladder'
  },
  {
    Id: 13692,
    Code: '5314',
    Name: 'Constructor, roof, building construction'
  },
  {
    Id: 13693,
    Code: '5314',
    Name: 'Constructor, roofing'
  },
  {
    Id: 13694,
    Code: '5314',
    Name: 'Contractor, roofing'
  },
  {
    Id: 13695,
    Code: '5314',
    Name: 'Coverer, roof'
  },
  {
    Id: 13696,
    Code: '5314',
    Name: 'Engineer, roofing'
  },
  {
    Id: 13697,
    Code: '5314',
    Name: 'Erector, roof'
  },
  {
    Id: 13698,
    Code: '5314',
    Name: 'Erector, roofing, galvanised'
  },
  {
    Id: 13699,
    Code: '5314',
    Name: 'Felter, building construction'
  },
  {
    Id: 13700,
    Code: '5314',
    Name: 'Fitter, asbestos'
  },
  {
    Id: 13701,
    Code: '5314',
    Name: 'Fixer, asbestos'
  },
  {
    Id: 13702,
    Code: '5314',
    Name: 'Fixer, felt'
  },
  {
    Id: 13703,
    Code: '5314',
    Name: 'Fixer, roof, building construction'
  },
  {
    Id: 13704,
    Code: '5314',
    Name: 'Fixer, roofing'
  },
  {
    Id: 13705,
    Code: '5314',
    Name: 'Layer, felt, roofing'
  },
  {
    Id: 13706,
    Code: '5314',
    Name: 'Liner, roof'
  },
  {
    Id: 13707,
    Code: '5314',
    Name: 'Mechanic, roof'
  },
  {
    Id: 13708,
    Code: '5314',
    Name: 'Repairer, roof'
  },
  {
    Id: 13709,
    Code: '5314',
    Name: 'Roofer, felt'
  },
  {
    Id: 13710,
    Code: '5314',
    Name: 'Roofer, flat'
  },
  {
    Id: 13711,
    Code: '5314',
    Name: 'Roofer, industrial'
  },
  {
    Id: 13712,
    Code: '5314',
    Name: 'Roofer'
  },
  {
    Id: 13713,
    Code: '5314',
    Name: 'Roofer-cladder'
  },
  {
    Id: 13714,
    Code: '5314',
    Name: 'Sheeter, asbestos'
  },
  {
    Id: 13715,
    Code: '5314',
    Name: 'Sheeter, cement, asbestos'
  },
  {
    Id: 13716,
    Code: '5314',
    Name: 'Sheeter, constructional'
  },
  {
    Id: 13717,
    Code: '5314',
    Name: 'Sheeter, iron, corrugated'
  },
  {
    Id: 13718,
    Code: '5314',
    Name: 'Sheeter, roof'
  },
  {
    Id: 13719,
    Code: '5314',
    Name: 'Sheeter, building construction'
  },
  {
    Id: 13720,
    Code: '5314',
    Name: 'Sheeter, chemical mfr'
  },
  {
    Id: 13721,
    Code: '5314',
    Name: 'Slater'
  },
  {
    Id: 13722,
    Code: '5314',
    Name: 'Slater and tiler'
  },
  {
    Id: 13723,
    Code: '5314',
    Name: 'Spreader, asphalt, mastic'
  },
  {
    Id: 13724,
    Code: '5314',
    Name: 'Thatcher'
  },
  {
    Id: 13725,
    Code: '5314',
    Name: 'Tiler, roof'
  },
  {
    Id: 13726,
    Code: '5315',
    Name: 'Adviser, heating'
  },
  {
    Id: 13727,
    Code: '5315',
    Name: 'Connector, coupling'
  },
  {
    Id: 13728,
    Code: '5315',
    Name: 'Consultant, heating'
  },
  {
    Id: 13729,
    Code: '5315',
    Name: 'Contractor, plumbing'
  },
  {
    Id: 13730,
    Code: '5315',
    Name: 'Converter, gas supplier'
  },
  {
    Id: 13731,
    Code: '5315',
    Name: 'Craftsman, distribution, gas supplier'
  },
  {
    Id: 13732,
    Code: '5315',
    Name: 'Craftsman, governor, gas supplier'
  },
  {
    Id: 13733,
    Code: '5315',
    Name: 'Craftsman, transmission, gas supplier'
  },
  {
    Id: 13734,
    Code: '5315',
    Name: 'Craftsman, gas supplier'
  },
  {
    Id: 13735,
    Code: '5315',
    Name: 'Engineer, biomass'
  },
  {
    Id: 13736,
    Code: '5315',
    Name: 'Engineer, domestic, plumbing'
  },
  {
    Id: 13737,
    Code: '5315',
    Name: 'Engineer, field, heating, ventilating'
  },
  {
    Id: 13738,
    Code: '5315',
    Name: 'Engineer, Gas, British'
  },
  {
    Id: 13739,
    Code: '5315',
    Name: 'Engineer, gas, domestic'
  },
  {
    Id: 13740,
    Code: '5315',
    Name: 'Engineer, gas, emergency'
  },
  {
    Id: 13741,
    Code: '5315',
    Name: 'Engineer, gas, technical'
  },
  {
    Id: 13742,
    Code: '5315',
    Name: 'Engineer, gas'
  },
  {
    Id: 13743,
    Code: '5315',
    Name: 'Engineer, gas and heating'
  },
  {
    Id: 13744,
    Code: '5315',
    Name: 'Engineer, gas and water'
  },
  {
    Id: 13745,
    Code: '5315',
    Name: 'Engineer, heat and domestic'
  },
  {
    Id: 13746,
    Code: '5315',
    Name: 'Engineer, heating, central'
  },
  {
    Id: 13747,
    Code: '5315',
    Name: 'Engineer, heating, gas'
  },
  {
    Id: 13748,
    Code: '5315',
    Name: 'Engineer, heating'
  },
  {
    Id: 13749,
    Code: '5315',
    Name: 'Engineer, heating and lighting'
  },
  {
    Id: 13750,
    Code: '5315',
    Name: 'Engineer, heating and plumbing'
  },
  {
    Id: 13751,
    Code: '5315',
    Name: 'Engineer, heating and ventilating'
  },
  {
    Id: 13752,
    Code: '5315',
    Name: 'Engineer, HVAC'
  },
  {
    Id: 13753,
    Code: '5315',
    Name: 'Engineer, installation, gas'
  },
  {
    Id: 13754,
    Code: '5315',
    Name: 'Engineer, installation, heating and ventilating'
  },
  {
    Id: 13755,
    Code: '5315',
    Name: 'Engineer, mains, gas supplier'
  },
  {
    Id: 13756,
    Code: '5315',
    Name: 'Engineer, maintenance, heating and ventilating'
  },
  {
    Id: 13757,
    Code: '5315',
    Name: 'Engineer, metering, gas'
  },
  {
    Id: 13758,
    Code: '5315',
    Name: 'Engineer, plumber and gas'
  },
  {
    Id: 13759,
    Code: '5315',
    Name: 'Engineer, plumbing'
  },
  {
    Id: 13760,
    Code: '5315',
    Name: 'Engineer, plumbing and heating'
  },
  {
    Id: 13761,
    Code: '5315',
    Name: 'Engineer, pump, heat'
  },
  {
    Id: 13762,
    Code: '5315',
    Name: 'Engineer, safe, gas'
  },
  {
    Id: 13763,
    Code: '5315',
    Name: 'Engineer, sanitary'
  },
  {
    Id: 13764,
    Code: '5315',
    Name: 'Engineer, service, gas'
  },
  {
    Id: 13765,
    Code: '5315',
    Name: 'Engineer, service, gas'
  },
  {
    Id: 13766,
    Code: '5315',
    Name: 'Engineer, service, heating and ventilating'
  },
  {
    Id: 13767,
    Code: '5315',
    Name: 'Engineer, service, heating contracting'
  },
  {
    Id: 13768,
    Code: '5315',
    Name: 'Engineer, technical, gas'
  },
  {
    Id: 13769,
    Code: '5315',
    Name: 'Engineer, technical, gas supplier'
  },
  {
    Id: 13770,
    Code: '5315',
    Name: 'Engineer, thermal'
  },
  {
    Id: 13771,
    Code: '5315',
    Name: 'Engineer, thermal and acoustic'
  },
  {
    Id: 13772,
    Code: '5315',
    Name: 'Engineer, ventilating'
  },
  {
    Id: 13773,
    Code: '5315',
    Name: 'Engineer, ventilation, coal mine'
  },
  {
    Id: 13774,
    Code: '5315',
    Name: 'Engineer, ventilation'
  },
  {
    Id: 13775,
    Code: '5315',
    Name: 'Engineer, water, hot'
  },
  {
    Id: 13776,
    Code: '5315',
    Name: 'Erector, mains, gas'
  },
  {
    Id: 13777,
    Code: '5315',
    Name: 'Fitter, bathroom'
  },
  {
    Id: 13778,
    Code: '5315',
    Name: 'Fitter, burner, gas works'
  },
  {
    Id: 13779,
    Code: '5315',
    Name: 'Fitter, district, gas supplier'
  },
  {
    Id: 13780,
    Code: '5315',
    Name: "Fitter, engineer's, heating"
  },
  {
    Id: 13781,
    Code: '5315',
    Name: "Fitter, engineer's, sanitary"
  },
  {
    Id: 13782,
    Code: '5315',
    Name: 'Fitter, fire, gas'
  },
  {
    Id: 13783,
    Code: '5315',
    Name: 'Fitter, gas'
  },
  {
    Id: 13784,
    Code: '5315',
    Name: 'Fitter, governor, gas supplier'
  },
  {
    Id: 13785,
    Code: '5315',
    Name: 'Fitter, heating'
  },
  {
    Id: 13786,
    Code: '5315',
    Name: 'Fitter, heating and ventilation'
  },
  {
    Id: 13787,
    Code: '5315',
    Name: 'Fitter, kitchen and bathroom'
  },
  {
    Id: 13788,
    Code: '5315',
    Name: 'Fitter, maintenance, gas supplier: gas works'
  },
  {
    Id: 13789,
    Code: '5315',
    Name: 'Fitter, maintenance, gas supplier'
  },
  {
    Id: 13790,
    Code: '5315',
    Name: 'Fitter, maintenance, heating, ventilating'
  },
  {
    Id: 13791,
    Code: '5315',
    Name: 'Fitter, sanitary'
  },
  {
    Id: 13792,
    Code: '5315',
    Name: 'Fitter, sprinkler'
  },
  {
    Id: 13793,
    Code: '5315',
    Name: 'Fitter, steam'
  },
  {
    Id: 13794,
    Code: '5315',
    Name: 'Fitter, steam and hot water'
  },
  {
    Id: 13795,
    Code: '5315',
    Name: 'Fitter, stove, building construction'
  },
  {
    Id: 13796,
    Code: '5315',
    Name: 'Fitter, ventilation'
  },
  {
    Id: 13797,
    Code: '5315',
    Name: 'Fitter, water'
  },
  {
    Id: 13798,
    Code: '5315',
    Name: 'Fitter, domestic appliances, gas appliances'
  },
  {
    Id: 13799,
    Code: '5315',
    Name: 'Fitter, gas supplier'
  },
  {
    Id: 13800,
    Code: '5315',
    Name: 'Fitter, heating contracting'
  },
  {
    Id: 13801,
    Code: '5315',
    Name: 'Fitter-welder, heating and ventilating'
  },
  {
    Id: 13802,
    Code: '5315',
    Name: 'Fixer, appliances, gas supplier'
  },
  {
    Id: 13803,
    Code: '5315',
    Name: 'Fixer, meter, gas'
  },
  {
    Id: 13804,
    Code: '5315',
    Name: 'Fixer, meter, water'
  },
  {
    Id: 13805,
    Code: '5315',
    Name: 'Fixer, ventilator'
  },
  {
    Id: 13806,
    Code: '5315',
    Name: 'Inserter, ferrule, water works'
  },
  {
    Id: 13807,
    Code: '5315',
    Name: 'Installer, bathroom'
  },
  {
    Id: 13808,
    Code: '5315',
    Name: 'Installer, heating'
  },
  {
    Id: 13809,
    Code: '5315',
    Name: 'Installer, meter, gas'
  },
  {
    Id: 13810,
    Code: '5315',
    Name: 'Installer, meter, water'
  },
  {
    Id: 13811,
    Code: '5315',
    Name: 'Installer, pump, heat'
  },
  {
    Id: 13812,
    Code: '5315',
    Name: 'Installer, heating contracting'
  },
  {
    Id: 13813,
    Code: '5315',
    Name: 'Jointer, pipe, sprinkler'
  },
  {
    Id: 13814,
    Code: '5315',
    Name: 'Linesman, gas'
  },
  {
    Id: 13815,
    Code: '5315',
    Name: 'Man, maintenance, gas supplier'
  },
  {
    Id: 13816,
    Code: '5315',
    Name: 'Man, service, sales, domestic appliances, gas appliances'
  },
  {
    Id: 13817,
    Code: '5315',
    Name: 'Man, service, domestic appliances, gas appliances'
  },
  {
    Id: 13818,
    Code: '5315',
    Name: 'Man, service, gas supplier'
  },
  {
    Id: 13819,
    Code: '5315',
    Name: 'Mender, domestic appliances, gas appliances'
  },
  {
    Id: 13820,
    Code: '5315',
    Name: 'Pewterer, brewery'
  },
  {
    Id: 13821,
    Code: '5315',
    Name: 'Plumber'
  },
  {
    Id: 13822,
    Code: '5315',
    Name: 'Plumber and decorator'
  },
  {
    Id: 13823,
    Code: '5315',
    Name: 'Plumber and gasfitter'
  },
  {
    Id: 13824,
    Code: '5315',
    Name: 'Plumber-welder'
  },
  {
    Id: 13825,
    Code: '5315',
    Name: 'Repairer, stove, gas stoves'
  },
  {
    Id: 13826,
    Code: '5315',
    Name: 'Repairer, domestic appliances, gas appliances'
  },
  {
    Id: 13827,
    Code: '5315',
    Name: 'Technician, gas'
  },
  {
    Id: 13828,
    Code: '5315',
    Name: 'Technician, HVAC'
  },
  {
    Id: 13829,
    Code: '5315',
    Name: 'Technician, network, gas supplier'
  },
  {
    Id: 13830,
    Code: '5315',
    Name: 'Technician, network, water company'
  },
  {
    Id: 13831,
    Code: '5315',
    Name: 'Technician, plumbing'
  },
  {
    Id: 13832,
    Code: '5315',
    Name: 'Technician, service, gas supplier'
  },
  {
    Id: 13833,
    Code: '5315',
    Name: 'Worker, gas, maintenance'
  },
  {
    Id: 13834,
    Code: '5316',
    Name: 'Builder, staircase'
  },
  {
    Id: 13835,
    Code: '5316',
    Name: 'Carpenter'
  },
  {
    Id: 13836,
    Code: '5316',
    Name: 'Carpenter and builder'
  },
  {
    Id: 13837,
    Code: '5316',
    Name: 'Carpenter and joiner'
  },
  {
    Id: 13838,
    Code: '5316',
    Name: 'Carpenter-builder'
  },
  {
    Id: 13839,
    Code: '5316',
    Name: 'Carpenter-diver'
  },
  {
    Id: 13840,
    Code: '5316',
    Name: 'Carpenter-joiner'
  },
  {
    Id: 13841,
    Code: '5316',
    Name: 'Contractor, carpentry'
  },
  {
    Id: 13842,
    Code: '5316',
    Name: 'Erector, exhibition'
  },
  {
    Id: 13843,
    Code: '5316',
    Name: 'Erector, hoarding'
  },
  {
    Id: 13844,
    Code: '5316',
    Name: 'Erector, partitioning, office'
  },
  {
    Id: 13845,
    Code: '5316',
    Name: 'Erector, shuttering'
  },
  {
    Id: 13846,
    Code: '5316',
    Name: 'Fitter, bank'
  },
  {
    Id: 13847,
    Code: '5316',
    Name: 'Fitter, bar, hotels, public houses fitting'
  },
  {
    Id: 13848,
    Code: '5316',
    Name: 'Fitter, bedroom'
  },
  {
    Id: 13849,
    Code: '5316',
    Name: 'Fitter, exhibition'
  },
  {
    Id: 13850,
    Code: '5316',
    Name: 'Fitter, kitchen'
  },
  {
    Id: 13851,
    Code: '5316',
    Name: 'Fitter, office'
  },
  {
    Id: 13852,
    Code: '5316',
    Name: 'Fitter, shop'
  },
  {
    Id: 13853,
    Code: '5316',
    Name: 'Fitter, shop and office'
  },
  {
    Id: 13854,
    Code: '5316',
    Name: 'Fitter, stand, exhibition stand'
  },
  {
    Id: 13855,
    Code: '5316',
    Name: 'Fixer, carpentry and joinery'
  },
  {
    Id: 13856,
    Code: '5316',
    Name: 'Foreman, joiner'
  },
  {
    Id: 13857,
    Code: '5316',
    Name: 'Hand, bench, joinery mfr'
  },
  {
    Id: 13858,
    Code: '5316',
    Name: 'Installer, bedroom'
  },
  {
    Id: 13859,
    Code: '5316',
    Name: 'Installer, kitchen'
  },
  {
    Id: 13860,
    Code: '5316',
    Name: 'Joiner, aircraft'
  },
  {
    Id: 13861,
    Code: '5316',
    Name: 'Joiner, bench'
  },
  {
    Id: 13862,
    Code: '5316',
    Name: "Joiner, builder's"
  },
  {
    Id: 13863,
    Code: '5316',
    Name: "Joiner, fitter's, shop"
  },
  {
    Id: 13864,
    Code: '5316',
    Name: 'Joiner, maintenance'
  },
  {
    Id: 13865,
    Code: '5316',
    Name: 'Joiner, shuttering'
  },
  {
    Id: 13866,
    Code: '5316',
    Name: 'Joiner'
  },
  {
    Id: 13867,
    Code: '5316',
    Name: 'Joiner and carpenter'
  },
  {
    Id: 13868,
    Code: '5316',
    Name: 'Joiner-builder'
  },
  {
    Id: 13869,
    Code: '5316',
    Name: 'Joiner-carpenter'
  },
  {
    Id: 13870,
    Code: '5316',
    Name: 'Joiner-machinist'
  },
  {
    Id: 13871,
    Code: '5316',
    Name: 'Loftsman and scriever'
  },
  {
    Id: 13872,
    Code: '5316',
    Name: 'Maker, casement, wood'
  },
  {
    Id: 13873,
    Code: '5316',
    Name: 'Maker, door'
  },
  {
    Id: 13874,
    Code: '5316',
    Name: 'Maker, frame, bed'
  },
  {
    Id: 13875,
    Code: '5316',
    Name: 'Maker, frame, window, wood'
  },
  {
    Id: 13876,
    Code: '5316',
    Name: 'Maker, frame, box spring mattress mfr'
  },
  {
    Id: 13877,
    Code: '5316',
    Name: 'Maker, model, architectural'
  },
  {
    Id: 13878,
    Code: '5316',
    Name: 'Maker, model, exhibition'
  },
  {
    Id: 13879,
    Code: '5316',
    Name: "Maker, model, ship's"
  },
  {
    Id: 13880,
    Code: '5316',
    Name: 'Maker, model, wood'
  },
  {
    Id: 13881,
    Code: '5316',
    Name: 'Maker, model, architectural'
  },
  {
    Id: 13882,
    Code: '5316',
    Name: 'Maker, model, toy mfr'
  },
  {
    Id: 13883,
    Code: '5316',
    Name: 'Maker, pattern, plastics goods mfr'
  },
  {
    Id: 13884,
    Code: '5316',
    Name: 'Maker, shutter, wood'
  },
  {
    Id: 13885,
    Code: '5316',
    Name: 'Maker, step'
  },
  {
    Id: 13886,
    Code: '5316',
    Name: 'Maker, template, wooden'
  },
  {
    Id: 13887,
    Code: '5316',
    Name: 'Maker, template, wood'
  },
  {
    Id: 13888,
    Code: '5316',
    Name: 'Maker, truss, joinery mfr'
  },
  {
    Id: 13889,
    Code: '5316',
    Name: 'Man, bench, joinery mfr'
  },
  {
    Id: 13890,
    Code: '5316',
    Name: 'Manufacturer, bedroom'
  },
  {
    Id: 13891,
    Code: '5316',
    Name: 'Manufacturer, door'
  },
  {
    Id: 13892,
    Code: '5316',
    Name: 'Manufacturer, joinery'
  },
  {
    Id: 13893,
    Code: '5316',
    Name: 'Manufacturer, kitchen'
  },
  {
    Id: 13894,
    Code: '5316',
    Name: 'Manufacturer, model'
  },
  {
    Id: 13895,
    Code: '5316',
    Name: 'Manufacturer, joinery mfr'
  },
  {
    Id: 13896,
    Code: '5316',
    Name: 'Modeller, architectural'
  },
  {
    Id: 13897,
    Code: '5316',
    Name: 'Scriever'
  },
  {
    Id: 13898,
    Code: '5316',
    Name: 'Scriever-in'
  },
  {
    Id: 13899,
    Code: '5316',
    Name: 'Shutterer, building construction'
  },
  {
    Id: 13900,
    Code: '5316',
    Name: 'Woodworker, aircraft'
  },
  {
    Id: 13901,
    Code: '5317',
    Name: 'Beader, double glazing'
  },
  {
    Id: 13902,
    Code: '5317',
    Name: 'Contractor, glazing, double'
  },
  {
    Id: 13903,
    Code: '5317',
    Name: 'Contractor, double glazing'
  },
  {
    Id: 13904,
    Code: '5317',
    Name: 'Craftsman, light, leaded'
  },
  {
    Id: 13905,
    Code: '5317',
    Name: 'Cutter, glass'
  },
  {
    Id: 13906,
    Code: '5317',
    Name: 'Engineer, installation, windows'
  },
  {
    Id: 13907,
    Code: '5317',
    Name: 'Engineer, service, windows'
  },
  {
    Id: 13908,
    Code: '5317',
    Name: 'Erector, conservatory'
  },
  {
    Id: 13909,
    Code: '5317',
    Name: 'Fabricator, glass'
  },
  {
    Id: 13910,
    Code: '5317',
    Name: 'Fabricator, glazing, double'
  },
  {
    Id: 13911,
    Code: '5317',
    Name: 'Fabricator, UPVC'
  },
  {
    Id: 13912,
    Code: '5317',
    Name: 'Fabricator, window'
  },
  {
    Id: 13913,
    Code: '5317',
    Name: 'Fabricator, window and door'
  },
  {
    Id: 13914,
    Code: '5317',
    Name: 'Fabricator, double glazing'
  },
  {
    Id: 13915,
    Code: '5317',
    Name: 'Fitter, gasket, window mfr'
  },
  {
    Id: 13916,
    Code: '5317',
    Name: 'Fitter, glass'
  },
  {
    Id: 13917,
    Code: '5317',
    Name: 'Fitter, glazing, double'
  },
  {
    Id: 13918,
    Code: '5317',
    Name: 'Fitter, window, UPVC'
  },
  {
    Id: 13919,
    Code: '5317',
    Name: 'Fitter, window'
  },
  {
    Id: 13920,
    Code: '5317',
    Name: 'Fitter, window and door'
  },
  {
    Id: 13921,
    Code: '5317',
    Name: 'Fitter, double glazing'
  },
  {
    Id: 13922,
    Code: '5317',
    Name: 'Fixer, glazing, patent'
  },
  {
    Id: 13923,
    Code: '5317',
    Name: 'Fixer, light, lead'
  },
  {
    Id: 13924,
    Code: '5317',
    Name: 'Fixer, vitrolite'
  },
  {
    Id: 13925,
    Code: '5317',
    Name: 'Fixer, window'
  },
  {
    Id: 13926,
    Code: '5317',
    Name: 'Fixer, window and door'
  },
  {
    Id: 13927,
    Code: '5317',
    Name: 'Glazer, double'
  },
  {
    Id: 13928,
    Code: '5317',
    Name: 'Glazer, patent'
  },
  {
    Id: 13929,
    Code: '5317',
    Name: 'Glazer, windows'
  },
  {
    Id: 13930,
    Code: '5317',
    Name: 'Glazier'
  },
  {
    Id: 13931,
    Code: '5317',
    Name: 'Glazier and decorator'
  },
  {
    Id: 13932,
    Code: '5317',
    Name: 'Installer, glazing, double'
  },
  {
    Id: 13933,
    Code: '5317',
    Name: 'Installer, window'
  },
  {
    Id: 13934,
    Code: '5317',
    Name: 'Installer, double glazing'
  },
  {
    Id: 13935,
    Code: '5317',
    Name: 'Leader, window'
  },
  {
    Id: 13936,
    Code: '5317',
    Name: 'Maker, frame, window, metal'
  },
  {
    Id: 13937,
    Code: '5317',
    Name: 'Maker, light, lead'
  },
  {
    Id: 13938,
    Code: '5317',
    Name: 'Maker, light, leaded'
  },
  {
    Id: 13939,
    Code: '5317',
    Name: 'Maker, unit, window mfr'
  },
  {
    Id: 13940,
    Code: '5317',
    Name: 'Maker, window, glass, stained'
  },
  {
    Id: 13941,
    Code: '5317',
    Name: 'Maker, window, lead'
  },
  {
    Id: 13942,
    Code: '5317',
    Name: 'Maker, window'
  },
  {
    Id: 13943,
    Code: '5317',
    Name: 'Manufacturer, glazing, double'
  },
  {
    Id: 13944,
    Code: '5317',
    Name: 'Manufacturer, window'
  },
  {
    Id: 13945,
    Code: '5317',
    Name: 'Manufacturer, window mfr'
  },
  {
    Id: 13946,
    Code: '5317',
    Name: 'Sealer, double glazing'
  },
  {
    Id: 13947,
    Code: '5317',
    Name: 'Setter-out, light, lead'
  },
  {
    Id: 13948,
    Code: '5317',
    Name: 'Welder, double glazing units'
  },
  {
    Id: 13949,
    Code: '5317',
    Name: 'Worker, glass, decorative'
  },
  {
    Id: 13950,
    Code: '5317',
    Name: 'Worker, lead, stained glass'
  },
  {
    Id: 13951,
    Code: '5319',
    Name: 'Abseiler'
  },
  {
    Id: 13952,
    Code: '5319',
    Name: 'Acoustician'
  },
  {
    Id: 13953,
    Code: '5319',
    Name: 'Bender, bar'
  },
  {
    Id: 13954,
    Code: '5319',
    Name: 'Bender, steel'
  },
  {
    Id: 13955,
    Code: '5319',
    Name: 'Bender and fixer, bar'
  },
  {
    Id: 13956,
    Code: '5319',
    Name: 'Builder, box, building construction'
  },
  {
    Id: 13957,
    Code: '5319',
    Name: 'Builder, contractor'
  },
  {
    Id: 13958,
    Code: '5319',
    Name: 'Builder, fireplace'
  },
  {
    Id: 13959,
    Code: '5319',
    Name: 'Builder, garage'
  },
  {
    Id: 13960,
    Code: '5319',
    Name: 'Builder, general'
  },
  {
    Id: 13961,
    Code: '5319',
    Name: 'Builder, house'
  },
  {
    Id: 13962,
    Code: '5319',
    Name: 'Builder, jobbing'
  },
  {
    Id: 13963,
    Code: '5319',
    Name: 'Builder, maintenance'
  },
  {
    Id: 13964,
    Code: '5319',
    Name: 'Builder, master'
  },
  {
    Id: 13965,
    Code: '5319',
    Name: 'Builder'
  },
  {
    Id: 13966,
    Code: '5319',
    Name: 'Builder and contractor'
  },
  {
    Id: 13967,
    Code: '5319',
    Name: 'Builder and decorator'
  },
  {
    Id: 13968,
    Code: '5319',
    Name: 'Builder and joiner'
  },
  {
    Id: 13969,
    Code: '5319',
    Name: "Contractor, builder's"
  },
  {
    Id: 13970,
    Code: '5319',
    Name: 'Contractor, building'
  },
  {
    Id: 13971,
    Code: '5319',
    Name: 'Contractor, fencing'
  },
  {
    Id: 13972,
    Code: '5319',
    Name: 'Craftsman, general, building construction'
  },
  {
    Id: 13973,
    Code: '5319',
    Name: 'Dealer, estate'
  },
  {
    Id: 13974,
    Code: '5319',
    Name: 'Developer, estate'
  },
  {
    Id: 13975,
    Code: '5319',
    Name: 'Developer, property, building construction'
  },
  {
    Id: 13976,
    Code: '5319',
    Name: 'Diver, commercial'
  },
  {
    Id: 13977,
    Code: '5319',
    Name: 'Diver, construction'
  },
  {
    Id: 13978,
    Code: '5319',
    Name: 'Driller, diamond, construction'
  },
  {
    Id: 13979,
    Code: '5319',
    Name: 'Engineer, acoustic'
  },
  {
    Id: 13980,
    Code: '5319',
    Name: 'Engineer, acoustics'
  },
  {
    Id: 13981,
    Code: '5319',
    Name: 'Engineer, civil'
  },
  {
    Id: 13982,
    Code: '5319',
    Name: 'Engineer, conductor, lightning'
  },
  {
    Id: 13983,
    Code: '5319',
    Name: 'Engineer, construction'
  },
  {
    Id: 13984,
    Code: '5319',
    Name: 'Engineer, control, noise'
  },
  {
    Id: 13985,
    Code: '5319',
    Name: 'Engineer, fabric, building maintenance'
  },
  {
    Id: 13986,
    Code: '5319',
    Name: 'Engineer, irrigation'
  },
  {
    Id: 13987,
    Code: '5319',
    Name: 'Engineer, maintenance, buildings and other structures'
  },
  {
    Id: 13988,
    Code: '5319',
    Name: 'Engineer, pool, swimming, construction'
  },
  {
    Id: 13989,
    Code: '5319',
    Name: 'Erector, fence'
  },
  {
    Id: 13990,
    Code: '5319',
    Name: 'Erector, fencing'
  },
  {
    Id: 13991,
    Code: '5319',
    Name: 'Erector, shed'
  },
  {
    Id: 13992,
    Code: '5319',
    Name: 'Fencer'
  },
  {
    Id: 13993,
    Code: '5319',
    Name: 'Fitter, fireplace'
  },
  {
    Id: 13994,
    Code: '5319',
    Name: 'Fitter, maintenance, buildings and other structures'
  },
  {
    Id: 13995,
    Code: '5319',
    Name: 'Fixer, fireplace'
  },
  {
    Id: 13996,
    Code: '5319',
    Name: 'Fixer, grate'
  },
  {
    Id: 13997,
    Code: '5319',
    Name: 'Fixer, reinforcement, building construction'
  },
  {
    Id: 13998,
    Code: '5319',
    Name: 'Fixer, steel'
  },
  {
    Id: 13999,
    Code: '5319',
    Name: 'Fixer and bender, steel'
  },
  {
    Id: 14000,
    Code: '5319',
    Name: 'Frogman, construction'
  },
  {
    Id: 14001,
    Code: '5319',
    Name: 'Hand, chimney, building construction'
  },
  {
    Id: 14002,
    Code: '5319',
    Name: 'Installer, fence'
  },
  {
    Id: 14003,
    Code: '5319',
    Name: 'Installer, playground'
  },
  {
    Id: 14004,
    Code: '5319',
    Name: 'Jack, steeple'
  },
  {
    Id: 14005,
    Code: '5319',
    Name: 'Manager, building, building maintenance'
  },
  {
    Id: 14006,
    Code: '5319',
    Name: 'Manager, maintenance, property'
  },
  {
    Id: 14007,
    Code: '5319',
    Name: 'Manager, maintenance, buildings and other structures'
  },
  {
    Id: 14008,
    Code: '5319',
    Name: 'Officer, building'
  },
  {
    Id: 14009,
    Code: '5319',
    Name: 'Officer, buildings'
  },
  {
    Id: 14010,
    Code: '5319',
    Name: 'Operator, submersible, construction'
  },
  {
    Id: 14011,
    Code: '5319',
    Name: 'Peter, steeple'
  },
  {
    Id: 14012,
    Code: '5319',
    Name: 'Pilot, ROV'
  },
  {
    Id: 14013,
    Code: '5319',
    Name: 'Pilot, submersible'
  },
  {
    Id: 14014,
    Code: '5319',
    Name: 'Proofer, sound'
  },
  {
    Id: 14015,
    Code: '5319',
    Name: 'Renovator, property'
  },
  {
    Id: 14016,
    Code: '5319',
    Name: 'Steeplejack'
  },
  {
    Id: 14017,
    Code: '5319',
    Name: 'Steeplepeter'
  },
  {
    Id: 14018,
    Code: '5319',
    Name: 'Superintendent, estate'
  },
  {
    Id: 14019,
    Code: '5319',
    Name: 'Supervisor, ROV'
  },
  {
    Id: 14020,
    Code: '5319',
    Name: 'Supervisor, vehicle, operated, remotely'
  },
  {
    Id: 14021,
    Code: '5319',
    Name: 'Surveyor, drainage'
  },
  {
    Id: 14022,
    Code: '5319',
    Name: 'Technician, access, rope'
  },
  {
    Id: 14023,
    Code: '5319',
    Name: 'Technician, concrete'
  },
  {
    Id: 14024,
    Code: '5319',
    Name: 'Technician, maintenance, buildings'
  },
  {
    Id: 14025,
    Code: '5319',
    Name: 'Technician, support, life, diving, construction'
  },
  {
    Id: 14026,
    Code: '5319',
    Name: 'Underpinner'
  },
  {
    Id: 14027,
    Code: '5319',
    Name: 'Waller, curtain'
  },
  {
    Id: 14028,
    Code: '5321',
    Name: 'Caster, rough, building construction'
  },
  {
    Id: 14029,
    Code: '5321',
    Name: 'Contractor, plastering'
  },
  {
    Id: 14030,
    Code: '5321',
    Name: 'Contractor, plastering'
  },
  {
    Id: 14031,
    Code: '5321',
    Name: 'Fixer, dryliner'
  },
  {
    Id: 14032,
    Code: '5321',
    Name: 'Fixer, fibrous'
  },
  {
    Id: 14033,
    Code: '5321',
    Name: 'Fixer, dry lining'
  },
  {
    Id: 14034,
    Code: '5321',
    Name: 'Liner, dry'
  },
  {
    Id: 14035,
    Code: '5321',
    Name: 'Liner, wall, dry'
  },
  {
    Id: 14036,
    Code: '5321',
    Name: 'Plasterer, fibrous'
  },
  {
    Id: 14037,
    Code: '5321',
    Name: 'Plasterer'
  },
  {
    Id: 14038,
    Code: '5321',
    Name: 'Plasterer-tiler'
  },
  {
    Id: 14039,
    Code: '5321',
    Name: 'Renderer'
  },
  {
    Id: 14040,
    Code: '5322',
    Name: 'Cementer, ship'
  },
  {
    Id: 14041,
    Code: '5322',
    Name: 'Concreter, granolithic'
  },
  {
    Id: 14042,
    Code: '5322',
    Name: 'Contractor, flooring'
  },
  {
    Id: 14043,
    Code: '5322',
    Name: 'Coverer, floor'
  },
  {
    Id: 14044,
    Code: '5322',
    Name: 'Cutter, carpet'
  },
  {
    Id: 14045,
    Code: '5322',
    Name: 'Estimator, carpet'
  },
  {
    Id: 14046,
    Code: '5322',
    Name: 'Estimator, flooring'
  },
  {
    Id: 14047,
    Code: '5322',
    Name: 'Fitter, carpet'
  },
  {
    Id: 14048,
    Code: '5322',
    Name: 'Fitter, floor'
  },
  {
    Id: 14049,
    Code: '5322',
    Name: 'Fitter, flooring'
  },
  {
    Id: 14050,
    Code: '5322',
    Name: 'Fitter, lino'
  },
  {
    Id: 14051,
    Code: '5322',
    Name: 'Fitter, linoleum'
  },
  {
    Id: 14052,
    Code: '5322',
    Name: 'Fitter, tile'
  },
  {
    Id: 14053,
    Code: '5322',
    Name: 'Fixer, carpet'
  },
  {
    Id: 14054,
    Code: '5322',
    Name: 'Fixer, faience and mosaic'
  },
  {
    Id: 14055,
    Code: '5322',
    Name: 'Fixer, mosaic'
  },
  {
    Id: 14056,
    Code: '5322',
    Name: 'Fixer, terracotta'
  },
  {
    Id: 14057,
    Code: '5322',
    Name: 'Fixer, tile'
  },
  {
    Id: 14058,
    Code: '5322',
    Name: 'Floorer'
  },
  {
    Id: 14059,
    Code: '5322',
    Name: 'Hand, terrazzo'
  },
  {
    Id: 14060,
    Code: '5322',
    Name: 'Installer, flooring'
  },
  {
    Id: 14061,
    Code: '5322',
    Name: 'Layer, carpet'
  },
  {
    Id: 14062,
    Code: '5322',
    Name: 'Layer, covering, floor'
  },
  {
    Id: 14063,
    Code: '5322',
    Name: 'Layer, felt, flooring'
  },
  {
    Id: 14064,
    Code: '5322',
    Name: 'Layer, floor, block'
  },
  {
    Id: 14065,
    Code: '5322',
    Name: 'Layer, floor, composition'
  },
  {
    Id: 14066,
    Code: '5322',
    Name: 'Layer, floor, decorative'
  },
  {
    Id: 14067,
    Code: '5322',
    Name: 'Layer, floor, granolithic'
  },
  {
    Id: 14068,
    Code: '5322',
    Name: 'Layer, floor, jointless'
  },
  {
    Id: 14069,
    Code: '5322',
    Name: 'Layer, floor, mosaic'
  },
  {
    Id: 14070,
    Code: '5322',
    Name: 'Layer, floor, parquet'
  },
  {
    Id: 14071,
    Code: '5322',
    Name: 'Layer, floor, patent'
  },
  {
    Id: 14072,
    Code: '5322',
    Name: 'Layer, floor, plastic'
  },
  {
    Id: 14073,
    Code: '5322',
    Name: 'Layer, floor, rubber'
  },
  {
    Id: 14074,
    Code: '5322',
    Name: 'Layer, floor, terrazzo'
  },
  {
    Id: 14075,
    Code: '5322',
    Name: 'Layer, floor, tile'
  },
  {
    Id: 14076,
    Code: '5322',
    Name: 'Layer, floor, wood'
  },
  {
    Id: 14077,
    Code: '5322',
    Name: 'Layer, floor'
  },
  {
    Id: 14078,
    Code: '5322',
    Name: 'Layer, lino'
  },
  {
    Id: 14079,
    Code: '5322',
    Name: 'Layer, linoleum'
  },
  {
    Id: 14080,
    Code: '5322',
    Name: 'Layer, mosaic'
  },
  {
    Id: 14081,
    Code: '5322',
    Name: 'Layer, terrazzo'
  },
  {
    Id: 14082,
    Code: '5322',
    Name: 'Layer, tile'
  },
  {
    Id: 14083,
    Code: '5322',
    Name: 'Maker, fireplace, tiled'
  },
  {
    Id: 14084,
    Code: '5322',
    Name: 'Maker, grate, tile'
  },
  {
    Id: 14085,
    Code: '5322',
    Name: 'Manufacturer, fireplace'
  },
  {
    Id: 14086,
    Code: '5322',
    Name: 'Manufacturer, fireplace mfr'
  },
  {
    Id: 14087,
    Code: '5322',
    Name: 'Paver, tile'
  },
  {
    Id: 14088,
    Code: '5322',
    Name: 'Pavior, tile'
  },
  {
    Id: 14089,
    Code: '5322',
    Name: 'Paviour, tile'
  },
  {
    Id: 14090,
    Code: '5322',
    Name: 'Planner, carpet'
  },
  {
    Id: 14091,
    Code: '5322',
    Name: 'Planner, lino'
  },
  {
    Id: 14092,
    Code: '5322',
    Name: 'Planner, linoleum'
  },
  {
    Id: 14093,
    Code: '5322',
    Name: 'Polisher, terrazzo'
  },
  {
    Id: 14094,
    Code: '5322',
    Name: 'Polisher, terrazzo floor laying'
  },
  {
    Id: 14095,
    Code: '5322',
    Name: 'Screeder, floor'
  },
  {
    Id: 14096,
    Code: '5322',
    Name: 'Setter, mosaic'
  },
  {
    Id: 14097,
    Code: '5322',
    Name: 'Setter, tile, building construction'
  },
  {
    Id: 14098,
    Code: '5322',
    Name: 'Slabber, tile'
  },
  {
    Id: 14099,
    Code: '5322',
    Name: "Slabber, builders' merchants"
  },
  {
    Id: 14100,
    Code: '5322',
    Name: 'Slabber, fireplace mfr'
  },
  {
    Id: 14101,
    Code: '5322',
    Name: 'Specialist, flooring'
  },
  {
    Id: 14102,
    Code: '5322',
    Name: 'Spreader, lay, cold'
  },
  {
    Id: 14103,
    Code: '5322',
    Name: 'Surveyor, carpet'
  },
  {
    Id: 14104,
    Code: '5322',
    Name: 'Technician, carpet'
  },
  {
    Id: 14105,
    Code: '5322',
    Name: 'Technician, flooring'
  },
  {
    Id: 14106,
    Code: '5322',
    Name: 'Technician, laying, floor'
  },
  {
    Id: 14107,
    Code: '5322',
    Name: 'Tiler, ceramic'
  },
  {
    Id: 14108,
    Code: '5322',
    Name: 'Tiler, cork'
  },
  {
    Id: 14109,
    Code: '5322',
    Name: 'Tiler, floor'
  },
  {
    Id: 14110,
    Code: '5322',
    Name: 'Tiler, floor and wall'
  },
  {
    Id: 14111,
    Code: '5322',
    Name: 'Tiler, glaze'
  },
  {
    Id: 14112,
    Code: '5322',
    Name: 'Tiler, range'
  },
  {
    Id: 14113,
    Code: '5322',
    Name: 'Tiler, wall'
  },
  {
    Id: 14114,
    Code: '5322',
    Name: 'Tiler, wall and floor'
  },
  {
    Id: 14115,
    Code: '5322',
    Name: 'Tiler'
  },
  {
    Id: 14116,
    Code: '5322',
    Name: 'Tiler and plasterer'
  },
  {
    Id: 14117,
    Code: '5322',
    Name: 'Worker, faience'
  },
  {
    Id: 14118,
    Code: '5322',
    Name: 'Worker, mosaic'
  },
  {
    Id: 14119,
    Code: '5322',
    Name: 'Worker, terrazzo'
  },
  {
    Id: 14120,
    Code: '5323',
    Name: 'Artexer'
  },
  {
    Id: 14121,
    Code: '5323',
    Name: 'Chipper and painter'
  },
  {
    Id: 14122,
    Code: '5323',
    Name: 'Coater, prime'
  },
  {
    Id: 14123,
    Code: '5323',
    Name: 'Contractor, decorating'
  },
  {
    Id: 14124,
    Code: '5323',
    Name: 'Contractor, painting'
  },
  {
    Id: 14125,
    Code: '5323',
    Name: 'Contractor, painting, decorating'
  },
  {
    Id: 14126,
    Code: '5323',
    Name: 'Decorator, house'
  },
  {
    Id: 14127,
    Code: '5323',
    Name: 'Decorator, interior, building construction'
  },
  {
    Id: 14128,
    Code: '5323',
    Name: 'Decorator'
  },
  {
    Id: 14129,
    Code: '5323',
    Name: 'Finisher, briar pipe mfr'
  },
  {
    Id: 14130,
    Code: '5323',
    Name: 'Gilder, painting, decorating'
  },
  {
    Id: 14131,
    Code: '5323',
    Name: 'Grainer, painting, decorating'
  },
  {
    Id: 14132,
    Code: '5323',
    Name: 'Grainer and marbler'
  },
  {
    Id: 14133,
    Code: '5323',
    Name: 'Hand, brush'
  },
  {
    Id: 14134,
    Code: '5323',
    Name: 'Hanger, paper'
  },
  {
    Id: 14135,
    Code: '5323',
    Name: 'Leader, red'
  },
  {
    Id: 14136,
    Code: '5323',
    Name: 'Painter, bridge'
  },
  {
    Id: 14137,
    Code: '5323',
    Name: 'Painter, buildings'
  },
  {
    Id: 14138,
    Code: '5323',
    Name: 'Painter, house'
  },
  {
    Id: 14139,
    Code: '5323',
    Name: 'Painter, industrial'
  },
  {
    Id: 14140,
    Code: '5323',
    Name: 'Painter, marine, boat building and repairing'
  },
  {
    Id: 14141,
    Code: '5323',
    Name: 'Painter, scenic'
  },
  {
    Id: 14142,
    Code: '5323',
    Name: 'Painter, ship'
  },
  {
    Id: 14143,
    Code: '5323',
    Name: 'Painter, spray, painting, decorating'
  },
  {
    Id: 14144,
    Code: '5323',
    Name: 'Painter'
  },
  {
    Id: 14145,
    Code: '5323',
    Name: 'Painter and decorator'
  },
  {
    Id: 14146,
    Code: '5323',
    Name: 'Painter and glazier'
  },
  {
    Id: 14147,
    Code: '5323',
    Name: 'Painter-decorator'
  },
  {
    Id: 14148,
    Code: '5323',
    Name: 'Paperhanger'
  },
  {
    Id: 14149,
    Code: '5323',
    Name: 'Polisher, french'
  },
  {
    Id: 14150,
    Code: '5323',
    Name: 'Polisher, furniture'
  },
  {
    Id: 14151,
    Code: '5323',
    Name: 'Polisher, piano'
  },
  {
    Id: 14152,
    Code: '5323',
    Name: 'Polisher, wood'
  },
  {
    Id: 14153,
    Code: '5323',
    Name: 'Polisher, furniture mfr'
  },
  {
    Id: 14154,
    Code: '5323',
    Name: 'Specialist, ceiling'
  },
  {
    Id: 14155,
    Code: '5323',
    Name: 'Sprayer, bridge'
  },
  {
    Id: 14156,
    Code: '5323',
    Name: 'Sprayer, buildings'
  },
  {
    Id: 14157,
    Code: '5323',
    Name: 'Sprayer, ship'
  },
  {
    Id: 14158,
    Code: '5323',
    Name: 'Stainer, wood'
  },
  {
    Id: 14159,
    Code: '5323',
    Name: 'Stainer, furniture mfr'
  },
  {
    Id: 14160,
    Code: '5323',
    Name: 'Stainer, tobacco pipe mfr'
  },
  {
    Id: 14161,
    Code: '5323',
    Name: 'Texturer, ceiling'
  },
  {
    Id: 14162,
    Code: '5323',
    Name: 'Worker, artex'
  },
  {
    Id: 14163,
    Code: '5330',
    Name: 'Chargeman, track, railway maintenance and repair'
  },
  {
    Id: 14164,
    Code: '5330',
    Name: 'Chargeman, track, railways'
  },
  {
    Id: 14165,
    Code: '5330',
    Name: 'Coordinator, maintenance, property management'
  },
  {
    Id: 14166,
    Code: '5330',
    Name: 'Foreman, bridge'
  },
  {
    Id: 14167,
    Code: '5330',
    Name: "Foreman, builder's"
  },
  {
    Id: 14168,
    Code: '5330',
    Name: 'Foreman, building'
  },
  {
    Id: 14169,
    Code: '5330',
    Name: 'Foreman, carpenter'
  },
  {
    Id: 14170,
    Code: '5330',
    Name: 'Foreman, carpentry'
  },
  {
    Id: 14171,
    Code: '5330',
    Name: 'Foreman, civilian, government'
  },
  {
    Id: 14172,
    Code: '5330',
    Name: 'Foreman, concrete'
  },
  {
    Id: 14173,
    Code: '5330',
    Name: 'Foreman, construction'
  },
  {
    Id: 14174,
    Code: '5330',
    Name: "Foreman, contractor's"
  },
  {
    Id: 14175,
    Code: '5330',
    Name: 'Foreman, demolition'
  },
  {
    Id: 14176,
    Code: '5330',
    Name: 'Foreman, distribution, gas supplier'
  },
  {
    Id: 14177,
    Code: '5330',
    Name: 'Foreman, district'
  },
  {
    Id: 14178,
    Code: '5330',
    Name: 'Foreman, drainage'
  },
  {
    Id: 14179,
    Code: '5330',
    Name: 'Foreman, engineering, civil'
  },
  {
    Id: 14180,
    Code: '5330',
    Name: 'Foreman, engineering, constructional'
  },
  {
    Id: 14181,
    Code: '5330',
    Name: 'Foreman, estate'
  },
  {
    Id: 14182,
    Code: '5330',
    Name: 'Foreman, estates'
  },
  {
    Id: 14183,
    Code: '5330',
    Name: 'Foreman, fittings, gas supplier'
  },
  {
    Id: 14184,
    Code: '5330',
    Name: 'Foreman, general, building construction'
  },
  {
    Id: 14185,
    Code: '5330',
    Name: 'Foreman, highways'
  },
  {
    Id: 14186,
    Code: '5330',
    Name: 'Foreman, maintenance, gas supplier'
  },
  {
    Id: 14187,
    Code: '5330',
    Name: 'Foreman, maintenance'
  },
  {
    Id: 14188,
    Code: '5330',
    Name: 'Foreman, management, traffic, construction'
  },
  {
    Id: 14189,
    Code: '5330',
    Name: 'Foreman, painting'
  },
  {
    Id: 14190,
    Code: '5330',
    Name: 'Foreman, pipe, main'
  },
  {
    Id: 14191,
    Code: '5330',
    Name: 'Foreman, plumbing'
  },
  {
    Id: 14192,
    Code: '5330',
    Name: 'Foreman, road'
  },
  {
    Id: 14193,
    Code: '5330',
    Name: 'Foreman, roofing'
  },
  {
    Id: 14194,
    Code: '5330',
    Name: 'Foreman, scaffolding'
  },
  {
    Id: 14195,
    Code: '5330',
    Name: 'Foreman, site'
  },
  {
    Id: 14196,
    Code: '5330',
    Name: 'Foreman, way, permanent'
  },
  {
    Id: 14197,
    Code: '5330',
    Name: 'Foreman, woodwork'
  },
  {
    Id: 14198,
    Code: '5330',
    Name: 'Foreman, building construction'
  },
  {
    Id: 14199,
    Code: '5330',
    Name: 'Foreman, civil engineering'
  },
  {
    Id: 14200,
    Code: '5330',
    Name: 'Foreman, construction'
  },
  {
    Id: 14201,
    Code: '5330',
    Name: 'Foreman, metal, constructional engineering'
  },
  {
    Id: 14202,
    Code: '5330',
    Name: 'Foreman, DfT'
  },
  {
    Id: 14203,
    Code: '5330',
    Name: 'Foreman, flooring contracting'
  },
  {
    Id: 14204,
    Code: '5330',
    Name: 'Foreman, gas supplier'
  },
  {
    Id: 14205,
    Code: '5330',
    Name: 'Foreman, heating contracting'
  },
  {
    Id: 14206,
    Code: '5330',
    Name: 'Foreman, joinery mfr'
  },
  {
    Id: 14207,
    Code: '5330',
    Name: "Foreman, local government: engineer's dept"
  },
  {
    Id: 14208,
    Code: '5330',
    Name: 'Foreman, local government: highways dept'
  },
  {
    Id: 14209,
    Code: '5330',
    Name: 'Foreman, local government: housing dept'
  },
  {
    Id: 14210,
    Code: '5330',
    Name: 'Foreman, local government: public works'
  },
  {
    Id: 14211,
    Code: '5330',
    Name: "Foreman, local government: surveyor's dept"
  },
  {
    Id: 14212,
    Code: '5330',
    Name: 'Foreman, local government'
  },
  {
    Id: 14213,
    Code: '5330',
    Name: 'Foreman, railway maintenance and repair'
  },
  {
    Id: 14214,
    Code: '5330',
    Name: "Foreman, railways: district engineer's dept"
  },
  {
    Id: 14215,
    Code: '5330',
    Name: 'Foreman, woodware mfr'
  },
  {
    Id: 14216,
    Code: '5330',
    Name: 'Foreman of works'
  },
  {
    Id: 14217,
    Code: '5330',
    Name: 'Foreman-ganger, maintenance'
  },
  {
    Id: 14218,
    Code: '5330',
    Name: 'Foreman-ganger, building construction'
  },
  {
    Id: 14219,
    Code: '5330',
    Name: 'Foreman-ganger, cable laying'
  },
  {
    Id: 14220,
    Code: '5330',
    Name: 'Foreman-ganger, electricity supplier'
  },
  {
    Id: 14221,
    Code: '5330',
    Name: 'Foreman-ganger, gas supplier'
  },
  {
    Id: 14222,
    Code: '5330',
    Name: 'Foreman-ganger, local government'
  },
  {
    Id: 14223,
    Code: '5330',
    Name: 'Foreman-ganger, railway maintenance and repair'
  },
  {
    Id: 14224,
    Code: '5330',
    Name: 'Foreman-ganger, railways'
  },
  {
    Id: 14225,
    Code: '5330',
    Name: 'Foreman-ganger, water works'
  },
  {
    Id: 14226,
    Code: '5330',
    Name: 'Ganger, highways'
  },
  {
    Id: 14227,
    Code: '5330',
    Name: 'Ganger, navvy'
  },
  {
    Id: 14228,
    Code: '5330',
    Name: 'Ganger, electricity supplier'
  },
  {
    Id: 14229,
    Code: '5330',
    Name: 'Ganger, gas supplier'
  },
  {
    Id: 14230,
    Code: '5330',
    Name: 'Ganger, local government'
  },
  {
    Id: 14231,
    Code: '5330',
    Name: 'Ganger, railway maintenance and repair'
  },
  {
    Id: 14232,
    Code: '5330',
    Name: 'Ganger, railways'
  },
  {
    Id: 14233,
    Code: '5330',
    Name: 'Ganger, water works'
  },
  {
    Id: 14234,
    Code: '5330',
    Name: 'Hand, leading, building construction'
  },
  {
    Id: 14235,
    Code: '5330',
    Name: 'Inspector, ballast'
  },
  {
    Id: 14236,
    Code: '5330',
    Name: 'Inspector, bridge'
  },
  {
    Id: 14237,
    Code: '5330',
    Name: 'Inspector, district, building construction'
  },
  {
    Id: 14238,
    Code: '5330',
    Name: 'Inspector, drain'
  },
  {
    Id: 14239,
    Code: '5330',
    Name: 'Inspector, drainage'
  },
  {
    Id: 14240,
    Code: '5330',
    Name: 'Inspector, excavating, electricity supplier'
  },
  {
    Id: 14241,
    Code: '5330',
    Name: 'Inspector, highways'
  },
  {
    Id: 14242,
    Code: '5330',
    Name: 'Inspector, line, pipe'
  },
  {
    Id: 14243,
    Code: '5330',
    Name: 'Inspector, line, railway maintenance and repair'
  },
  {
    Id: 14244,
    Code: '5330',
    Name: 'Inspector, mains'
  },
  {
    Id: 14245,
    Code: '5330',
    Name: 'Inspector, maintenance, local government: housing dept'
  },
  {
    Id: 14246,
    Code: '5330',
    Name: 'Inspector, paving and extension'
  },
  {
    Id: 14247,
    Code: '5330',
    Name: 'Inspector, trench'
  },
  {
    Id: 14248,
    Code: '5330',
    Name: 'Inspector, tunnel'
  },
  {
    Id: 14249,
    Code: '5330',
    Name: 'Inspector, way, permanent'
  },
  {
    Id: 14250,
    Code: '5330',
    Name: 'Inspector, works, district, railway maintenance and repair'
  },
  {
    Id: 14251,
    Code: '5330',
    Name: 'Inspector, works, district, railways'
  },
  {
    Id: 14252,
    Code: '5330',
    Name: 'Inspector, works, new, railway maintenance and repair'
  },
  {
    Id: 14253,
    Code: '5330',
    Name: 'Inspector, works, new, railways'
  },
  {
    Id: 14254,
    Code: '5330',
    Name: 'Inspector, works, railway maintenance and repair'
  },
  {
    Id: 14255,
    Code: '5330',
    Name: 'Inspector, works, railways'
  },
  {
    Id: 14256,
    Code: '5330',
    Name: 'Inspector, local government: highways dept'
  },
  {
    Id: 14257,
    Code: '5330',
    Name: 'Manager, section, track'
  },
  {
    Id: 14258,
    Code: '5330',
    Name: 'Master, gang, drainage board'
  },
  {
    Id: 14259,
    Code: '5330',
    Name: 'Officer, maintenance, local government'
  },
  {
    Id: 14260,
    Code: '5330',
    Name: 'Skipper, building construction'
  },
  {
    Id: 14261,
    Code: '5330',
    Name: 'Superintendent, board, gas'
  },
  {
    Id: 14262,
    Code: '5330',
    Name: 'Superintendent, distribution, gas supplier'
  },
  {
    Id: 14263,
    Code: '5330',
    Name: 'Superintendent, district, gas supplier'
  },
  {
    Id: 14264,
    Code: '5330',
    Name: 'Superintendent, mains'
  },
  {
    Id: 14265,
    Code: '5330',
    Name: 'Superintendent, maintenance'
  },
  {
    Id: 14266,
    Code: '5330',
    Name: 'Superintendent, repairs'
  },
  {
    Id: 14267,
    Code: '5330',
    Name: 'Superintendent, works, building construction'
  },
  {
    Id: 14268,
    Code: '5330',
    Name: 'Superintendent, works, local government'
  },
  {
    Id: 14269,
    Code: '5330',
    Name: 'Superintendent, building construction'
  },
  {
    Id: 14270,
    Code: '5330',
    Name: 'Superintendent, engineering: civil'
  },
  {
    Id: 14271,
    Code: '5330',
    Name: 'Superintendent, engineering: structural'
  },
  {
    Id: 14272,
    Code: '5330',
    Name: 'Superintendent, local government: highways dept'
  },
  {
    Id: 14273,
    Code: '5330',
    Name: 'Superintendent of works'
  },
  {
    Id: 14274,
    Code: '5330',
    Name: 'Supervisor, area, building construction'
  },
  {
    Id: 14275,
    Code: '5330',
    Name: 'Supervisor, area, gas supplier'
  },
  {
    Id: 14276,
    Code: '5330',
    Name: 'Supervisor, asbestos'
  },
  {
    Id: 14277,
    Code: '5330',
    Name: 'Supervisor, building'
  },
  {
    Id: 14278,
    Code: '5330',
    Name: "Supervisor, carpenter's"
  },
  {
    Id: 14279,
    Code: '5330',
    Name: 'Supervisor, carpentry'
  },
  {
    Id: 14280,
    Code: '5330',
    Name: 'Supervisor, construction'
  },
  {
    Id: 14281,
    Code: '5330',
    Name: 'Supervisor, contract, building construction'
  },
  {
    Id: 14282,
    Code: '5330',
    Name: 'Supervisor, contracts, building construction'
  },
  {
    Id: 14283,
    Code: '5330',
    Name: 'Supervisor, demolition'
  },
  {
    Id: 14284,
    Code: '5330',
    Name: 'Supervisor, distribution, water company'
  },
  {
    Id: 14285,
    Code: '5330',
    Name: 'Supervisor, erection, building construction'
  },
  {
    Id: 14286,
    Code: '5330',
    Name: 'Supervisor, maintenance'
  },
  {
    Id: 14287,
    Code: '5330',
    Name: 'Supervisor, painting'
  },
  {
    Id: 14288,
    Code: '5330',
    Name: 'Supervisor, plumbing'
  },
  {
    Id: 14289,
    Code: '5330',
    Name: 'Supervisor, protection, fire, construction'
  },
  {
    Id: 14290,
    Code: '5330',
    Name: 'Supervisor, roofing'
  },
  {
    Id: 14291,
    Code: '5330',
    Name: 'Supervisor, scaffold'
  },
  {
    Id: 14292,
    Code: '5330',
    Name: 'Supervisor, scaffolding'
  },
  {
    Id: 14293,
    Code: '5330',
    Name: 'Supervisor, service, gas supplier'
  },
  {
    Id: 14294,
    Code: '5330',
    Name: 'Supervisor, services, building, building maintenance'
  },
  {
    Id: 14295,
    Code: '5330',
    Name: 'Supervisor, gas supplier'
  },
  {
    Id: 14296,
    Code: '5411',
    Name: 'Coverer, Rexine'
  },
  {
    Id: 14297,
    Code: '5411',
    Name: 'Edger, mattress mfr'
  },
  {
    Id: 14298,
    Code: '5411',
    Name: 'Filler, mattress, upholstery mfr'
  },
  {
    Id: 14299,
    Code: '5411',
    Name: 'Finisher, quilt'
  },
  {
    Id: 14300,
    Code: '5411',
    Name: 'Finisher, coach trimming'
  },
  {
    Id: 14301,
    Code: '5411',
    Name: 'Fitter, curtain'
  },
  {
    Id: 14302,
    Code: '5411',
    Name: 'Fitter, furnishing, soft'
  },
  {
    Id: 14303,
    Code: '5411',
    Name: "Fitter, upholsterer's"
  },
  {
    Id: 14304,
    Code: '5411',
    Name: 'Foreman, shop, trim'
  },
  {
    Id: 14305,
    Code: '5411',
    Name: 'Foreman, mattress mfr'
  },
  {
    Id: 14306,
    Code: '5411',
    Name: 'Foreman, upholstering'
  },
  {
    Id: 14307,
    Code: '5411',
    Name: 'Furnisher, soft'
  },
  {
    Id: 14308,
    Code: '5411',
    Name: 'Hand, bench, coach trimming'
  },
  {
    Id: 14309,
    Code: '5411',
    Name: 'Maker, bed, mattress mfr'
  },
  {
    Id: 14310,
    Code: '5411',
    Name: 'Maker, case, mattress, upholstery mfr'
  },
  {
    Id: 14311,
    Code: '5411',
    Name: 'Maker, cover, mattress'
  },
  {
    Id: 14312,
    Code: '5411',
    Name: 'Maker, curtain'
  },
  {
    Id: 14313,
    Code: '5411',
    Name: 'Maker, cushion'
  },
  {
    Id: 14314,
    Code: '5411',
    Name: 'Maker, eiderdown'
  },
  {
    Id: 14315,
    Code: '5411',
    Name: 'Maker, furnishing, soft'
  },
  {
    Id: 14316,
    Code: '5411',
    Name: 'Maker, hassock'
  },
  {
    Id: 14317,
    Code: '5411',
    Name: 'Maker, mattress, interior, spring'
  },
  {
    Id: 14318,
    Code: '5411',
    Name: 'Maker, mattress'
  },
  {
    Id: 14319,
    Code: '5411',
    Name: 'Maker, pad, upholstery mfr'
  },
  {
    Id: 14320,
    Code: '5411',
    Name: 'Manufacturer, mattress mfr'
  },
  {
    Id: 14321,
    Code: '5411',
    Name: 'Packer, flock, mattress mfr'
  },
  {
    Id: 14322,
    Code: '5411',
    Name: 'Stitcher, mattress'
  },
  {
    Id: 14323,
    Code: '5411',
    Name: 'Trimmer, board, floor, coach trimming'
  },
  {
    Id: 14324,
    Code: '5411',
    Name: 'Trimmer, body, car'
  },
  {
    Id: 14325,
    Code: '5411',
    Name: 'Trimmer, car'
  },
  {
    Id: 14326,
    Code: '5411',
    Name: 'Trimmer, carriage'
  },
  {
    Id: 14327,
    Code: '5411',
    Name: 'Trimmer, coach'
  },
  {
    Id: 14328,
    Code: '5411',
    Name: 'Trimmer, leather, vehicle mfr'
  },
  {
    Id: 14329,
    Code: '5411',
    Name: 'Trimmer, motor'
  },
  {
    Id: 14330,
    Code: '5411',
    Name: 'Trimmer, seat, car'
  },
  {
    Id: 14331,
    Code: '5411',
    Name: "Trimmer, upholsterer's"
  },
  {
    Id: 14332,
    Code: '5411',
    Name: 'Trimmer, aircraft mfr'
  },
  {
    Id: 14333,
    Code: '5411',
    Name: 'Trimmer, furniture mfr'
  },
  {
    Id: 14334,
    Code: '5411',
    Name: 'Trimmer, railway workshops'
  },
  {
    Id: 14335,
    Code: '5411',
    Name: 'Trimmer, vehicle mfr'
  },
  {
    Id: 14336,
    Code: '5411',
    Name: 'Upholsterer'
  },
  {
    Id: 14337,
    Code: '5412',
    Name: 'Assembler, footwear'
  },
  {
    Id: 14338,
    Code: '5412',
    Name: 'Assembler, leather goods mfr'
  },
  {
    Id: 14339,
    Code: '5412',
    Name: "Assistant, maker's, shoe"
  },
  {
    Id: 14340,
    Code: '5412',
    Name: 'Barrer, footwear mfr'
  },
  {
    Id: 14341,
    Code: '5412',
    Name: 'Beader, footwear mfr'
  },
  {
    Id: 14342,
    Code: '5412',
    Name: 'Beater, footwear mfr'
  },
  {
    Id: 14343,
    Code: '5412',
    Name: 'Beltman, machine'
  },
  {
    Id: 14344,
    Code: '5412',
    Name: 'Beltman, engineering'
  },
  {
    Id: 14345,
    Code: '5412',
    Name: 'Beveller, footwear mfr'
  },
  {
    Id: 14346,
    Code: '5412',
    Name: 'Binder, slipper'
  },
  {
    Id: 14347,
    Code: '5412',
    Name: 'Binder, footwear mfr'
  },
  {
    Id: 14348,
    Code: '5412',
    Name: 'Blocker, footwear mfr'
  },
  {
    Id: 14349,
    Code: '5412',
    Name: 'Blocker, leather goods mfr'
  },
  {
    Id: 14350,
    Code: '5412',
    Name: 'Bottomer, surgical footwear mfr'
  },
  {
    Id: 14351,
    Code: '5412',
    Name: 'Breaster, heel'
  },
  {
    Id: 14352,
    Code: '5412',
    Name: 'Brusher, footwear mfr'
  },
  {
    Id: 14353,
    Code: '5412',
    Name: 'Brusher-up, footwear mfr'
  },
  {
    Id: 14354,
    Code: '5412',
    Name: 'Builder-up, last'
  },
  {
    Id: 14355,
    Code: '5412',
    Name: 'Burnisher, footwear mfr'
  },
  {
    Id: 14356,
    Code: '5412',
    Name: 'Butter and tacker, welt'
  },
  {
    Id: 14357,
    Code: '5412',
    Name: 'Cementer, outsole'
  },
  {
    Id: 14358,
    Code: '5412',
    Name: 'Cementer, upper'
  },
  {
    Id: 14359,
    Code: '5412',
    Name: 'Cementer, footwear mfr'
  },
  {
    Id: 14360,
    Code: '5412',
    Name: 'Channeller, footwear mfr'
  },
  {
    Id: 14361,
    Code: '5412',
    Name: 'Cleaner, footwear mfr'
  },
  {
    Id: 14362,
    Code: '5412',
    Name: 'Clicker, machine'
  },
  {
    Id: 14363,
    Code: '5412',
    Name: 'Clicker, press'
  },
  {
    Id: 14364,
    Code: '5412',
    Name: 'Clicker, footwear mfr'
  },
  {
    Id: 14365,
    Code: '5412',
    Name: 'Clicker, leather goods mfr'
  },
  {
    Id: 14366,
    Code: '5412',
    Name: 'Clogger'
  },
  {
    Id: 14367,
    Code: '5412',
    Name: 'Closer, repairs'
  },
  {
    Id: 14368,
    Code: '5412',
    Name: 'Closer, footwear mfr'
  },
  {
    Id: 14369,
    Code: '5412',
    Name: 'Closer, toy mfr'
  },
  {
    Id: 14370,
    Code: '5412',
    Name: 'Cobbler'
  },
  {
    Id: 14371,
    Code: '5412',
    Name: 'Compressor, heel'
  },
  {
    Id: 14372,
    Code: '5412',
    Name: 'Corder, footwear mfr'
  },
  {
    Id: 14373,
    Code: '5412',
    Name: 'Cordwinder'
  },
  {
    Id: 14374,
    Code: '5412',
    Name: 'Coverer, roller, textile mfr'
  },
  {
    Id: 14375,
    Code: '5412',
    Name: 'Coverer, leather goods mfr'
  },
  {
    Id: 14376,
    Code: '5412',
    Name: 'Creaser, vamp'
  },
  {
    Id: 14377,
    Code: '5412',
    Name: 'Creaser, footwear mfr'
  },
  {
    Id: 14378,
    Code: '5412',
    Name: 'Crimper, vamp'
  },
  {
    Id: 14379,
    Code: '5412',
    Name: 'Crimper, footwear mfr'
  },
  {
    Id: 14380,
    Code: '5412',
    Name: 'Crowner'
  },
  {
    Id: 14381,
    Code: '5412',
    Name: 'Cutter, bag'
  },
  {
    Id: 14382,
    Code: '5412',
    Name: 'Cutter, belt'
  },
  {
    Id: 14383,
    Code: '5412',
    Name: 'Cutter, brace'
  },
  {
    Id: 14384,
    Code: '5412',
    Name: 'Cutter, bridle'
  },
  {
    Id: 14385,
    Code: '5412',
    Name: 'Cutter, die, footwear mfr'
  },
  {
    Id: 14386,
    Code: '5412',
    Name: 'Cutter, fittings'
  },
  {
    Id: 14387,
    Code: '5412',
    Name: 'Cutter, glove, boxing'
  },
  {
    Id: 14388,
    Code: '5412',
    Name: 'Cutter, guillotine, leather goods mfr'
  },
  {
    Id: 14389,
    Code: '5412',
    Name: 'Cutter, leather'
  },
  {
    Id: 14390,
    Code: '5412',
    Name: 'Cutter, lining, footwear mfr'
  },
  {
    Id: 14391,
    Code: '5412',
    Name: 'Cutter, machine, leather goods mfr'
  },
  {
    Id: 14392,
    Code: '5412',
    Name: 'Cutter, pattern, iron, footwear mfr'
  },
  {
    Id: 14393,
    Code: '5412',
    Name: 'Cutter, pattern, footwear mfr'
  },
  {
    Id: 14394,
    Code: '5412',
    Name: 'Cutter, pattern, leather goods mfr'
  },
  {
    Id: 14395,
    Code: '5412',
    Name: 'Cutter, press, footwear mfr'
  },
  {
    Id: 14396,
    Code: '5412',
    Name: 'Cutter, press, leather goods mfr'
  },
  {
    Id: 14397,
    Code: '5412',
    Name: 'Cutter, profile, footwear mfr'
  },
  {
    Id: 14398,
    Code: '5412',
    Name: 'Cutter, puff'
  },
  {
    Id: 14399,
    Code: '5412',
    Name: 'Cutter, saddle'
  },
  {
    Id: 14400,
    Code: '5412',
    Name: 'Cutter, sample, footwear mfr'
  },
  {
    Id: 14401,
    Code: '5412',
    Name: 'Cutter, shoe'
  },
  {
    Id: 14402,
    Code: '5412',
    Name: 'Cutter, slipper'
  },
  {
    Id: 14403,
    Code: '5412',
    Name: 'Cutter, sole'
  },
  {
    Id: 14404,
    Code: '5412',
    Name: 'Cutter, stiffening'
  },
  {
    Id: 14405,
    Code: '5412',
    Name: 'Cutter, strap, leather goods mfr'
  },
  {
    Id: 14406,
    Code: '5412',
    Name: 'Cutter, footwear mfr'
  },
  {
    Id: 14407,
    Code: '5412',
    Name: 'Cutter, leather goods mfr'
  },
  {
    Id: 14408,
    Code: '5412',
    Name: 'Cutter, powder puff mfr'
  },
  {
    Id: 14409,
    Code: '5412',
    Name: 'Dresser, footwear mfr'
  },
  {
    Id: 14410,
    Code: '5412',
    Name: 'Eyeletter, footwear mfr'
  },
  {
    Id: 14411,
    Code: '5412',
    Name: 'Eyeletter, leather goods mfr'
  },
  {
    Id: 14412,
    Code: '5412',
    Name: 'Filler, bottom, boot mfr'
  },
  {
    Id: 14413,
    Code: '5412',
    Name: 'Finisher, boot and shoe'
  },
  {
    Id: 14414,
    Code: '5412',
    Name: 'Finisher, bottom'
  },
  {
    Id: 14415,
    Code: '5412',
    Name: 'Finisher, shoe'
  },
  {
    Id: 14416,
    Code: '5412',
    Name: 'Finisher, footwear mfr'
  },
  {
    Id: 14417,
    Code: '5412',
    Name: 'Finisher, leather goods mfr'
  },
  {
    Id: 14418,
    Code: '5412',
    Name: 'Fitter, belt, coal mine'
  },
  {
    Id: 14419,
    Code: '5412',
    Name: 'Fitter, belting'
  },
  {
    Id: 14420,
    Code: '5412',
    Name: 'Fitter, heel'
  },
  {
    Id: 14421,
    Code: '5412',
    Name: 'Fitter, last, bespoke'
  },
  {
    Id: 14422,
    Code: '5412',
    Name: 'Fitter, spring, elastic'
  },
  {
    Id: 14423,
    Code: '5412',
    Name: 'Fitter, footwear mfr'
  },
  {
    Id: 14424,
    Code: '5412',
    Name: 'Fitter, leather goods mfr'
  },
  {
    Id: 14425,
    Code: '5412',
    Name: 'Fitter-up, footwear mfr'
  },
  {
    Id: 14426,
    Code: '5412',
    Name: 'Folder, footwear mfr'
  },
  {
    Id: 14427,
    Code: '5412',
    Name: 'Foreman, footwear mfr'
  },
  {
    Id: 14428,
    Code: '5412',
    Name: 'Foreman, leather goods mfr'
  },
  {
    Id: 14429,
    Code: '5412',
    Name: 'Framer, leather goods mfr'
  },
  {
    Id: 14430,
    Code: '5412',
    Name: 'Glosser, footwear mfr'
  },
  {
    Id: 14431,
    Code: '5412',
    Name: 'Hand, alteration, footwear'
  },
  {
    Id: 14432,
    Code: '5412',
    Name: 'Hand, bench, footwear mfr'
  },
  {
    Id: 14433,
    Code: '5412',
    Name: 'Hand, bench, leather goods mfr'
  },
  {
    Id: 14434,
    Code: '5412',
    Name: 'Hand, room, footwear mfr'
  },
  {
    Id: 14435,
    Code: '5412',
    Name: 'Hand, sample, footwear mfr'
  },
  {
    Id: 14436,
    Code: '5412',
    Name: 'Hand, shoe'
  },
  {
    Id: 14437,
    Code: '5412',
    Name: 'Heeler, footwear'
  },
  {
    Id: 14438,
    Code: '5412',
    Name: 'Holer, eyelet'
  },
  {
    Id: 14439,
    Code: '5412',
    Name: 'Inker, footwear mfr'
  },
  {
    Id: 14440,
    Code: '5412',
    Name: 'Interlacer, shoe'
  },
  {
    Id: 14441,
    Code: '5412',
    Name: 'Ironer, boot'
  },
  {
    Id: 14442,
    Code: '5412',
    Name: 'Ironer, footwear mfr'
  },
  {
    Id: 14443,
    Code: '5412',
    Name: 'Lacer, footwear mfr'
  },
  {
    Id: 14444,
    Code: '5412',
    Name: 'Laster'
  },
  {
    Id: 14445,
    Code: '5412',
    Name: 'Layer, sole'
  },
  {
    Id: 14446,
    Code: '5412',
    Name: 'Leveller, footwear mfr'
  },
  {
    Id: 14447,
    Code: '5412',
    Name: 'Looker-over, footwear mfr'
  },
  {
    Id: 14448,
    Code: '5412',
    Name: 'Machinist, bag, leather goods mfr'
  },
  {
    Id: 14449,
    Code: '5412',
    Name: 'Machinist, bagging'
  },
  {
    Id: 14450,
    Code: '5412',
    Name: 'Machinist, beading'
  },
  {
    Id: 14451,
    Code: '5412',
    Name: 'Machinist, boot'
  },
  {
    Id: 14452,
    Code: '5412',
    Name: 'Machinist, brush'
  },
  {
    Id: 14453,
    Code: '5412',
    Name: 'Machinist, closing, footwear mfr'
  },
  {
    Id: 14454,
    Code: '5412',
    Name: 'Machinist, flat, footwear mfr'
  },
  {
    Id: 14455,
    Code: '5412',
    Name: 'Machinist, lasting'
  },
  {
    Id: 14456,
    Code: '5412',
    Name: 'Machinist, leather'
  },
  {
    Id: 14457,
    Code: '5412',
    Name: 'Machinist, levelling'
  },
  {
    Id: 14458,
    Code: '5412',
    Name: 'Machinist, lining, footwear mfr'
  },
  {
    Id: 14459,
    Code: '5412',
    Name: 'Machinist, perforating, footwear mfr'
  },
  {
    Id: 14460,
    Code: '5412',
    Name: 'Machinist, post, footwear mfr'
  },
  {
    Id: 14461,
    Code: '5412',
    Name: 'Machinist, pressure'
  },
  {
    Id: 14462,
    Code: '5412',
    Name: 'Machinist, riveting, leather goods mfr'
  },
  {
    Id: 14463,
    Code: '5412',
    Name: 'Machinist, shoe'
  },
  {
    Id: 14464,
    Code: '5412',
    Name: 'Machinist, skiver'
  },
  {
    Id: 14465,
    Code: '5412',
    Name: 'Machinist, skiving, footwear mfr'
  },
  {
    Id: 14466,
    Code: '5412',
    Name: 'Machinist, slipper'
  },
  {
    Id: 14467,
    Code: '5412',
    Name: 'Machinist, stapling, footwear mfr'
  },
  {
    Id: 14468,
    Code: '5412',
    Name: 'Machinist, taping, footwear mfr'
  },
  {
    Id: 14469,
    Code: '5412',
    Name: 'Machinist, welt'
  },
  {
    Id: 14470,
    Code: '5412',
    Name: 'Machinist, zigzag, footwear mfr'
  },
  {
    Id: 14471,
    Code: '5412',
    Name: 'Machinist, footwear mfr'
  },
  {
    Id: 14472,
    Code: '5412',
    Name: 'Machinist, leather goods mfr'
  },
  {
    Id: 14473,
    Code: '5412',
    Name: 'Maker, bag, hand'
  },
  {
    Id: 14474,
    Code: '5412',
    Name: "Maker, bag, lady's"
  },
  {
    Id: 14475,
    Code: '5412',
    Name: 'Maker, bag, travelling'
  },
  {
    Id: 14476,
    Code: '5412',
    Name: 'Maker, bag, leather goods mfr'
  },
  {
    Id: 14477,
    Code: '5412',
    Name: 'Maker, ball, cricket'
  },
  {
    Id: 14478,
    Code: '5412',
    Name: 'Maker, ball, foot'
  },
  {
    Id: 14479,
    Code: '5412',
    Name: 'Maker, bellows, pipe organ'
  },
  {
    Id: 14480,
    Code: '5412',
    Name: 'Maker, belt, leather'
  },
  {
    Id: 14481,
    Code: '5412',
    Name: 'Maker, belting, leather'
  },
  {
    Id: 14482,
    Code: '5412',
    Name: 'Maker, boot, surgical'
  },
  {
    Id: 14483,
    Code: '5412',
    Name: 'Maker, boot'
  },
  {
    Id: 14484,
    Code: '5412',
    Name: 'Maker, boot and shoe'
  },
  {
    Id: 14485,
    Code: '5412',
    Name: 'Maker, braces'
  },
  {
    Id: 14486,
    Code: '5412',
    Name: 'Maker, bridle'
  },
  {
    Id: 14487,
    Code: '5412',
    Name: 'Maker, buffer'
  },
  {
    Id: 14488,
    Code: '5412',
    Name: 'Maker, case, attaché'
  },
  {
    Id: 14489,
    Code: '5412',
    Name: 'Maker, case, brush'
  },
  {
    Id: 14490,
    Code: '5412',
    Name: 'Maker, case, dressing'
  },
  {
    Id: 14491,
    Code: '5412',
    Name: 'Maker, case, hat'
  },
  {
    Id: 14492,
    Code: '5412',
    Name: 'Maker, case, leather'
  },
  {
    Id: 14493,
    Code: '5412',
    Name: 'Maker, case, small'
  },
  {
    Id: 14494,
    Code: '5412',
    Name: 'Maker, case, suit'
  },
  {
    Id: 14495,
    Code: '5412',
    Name: 'Maker, clog'
  },
  {
    Id: 14496,
    Code: '5412',
    Name: 'Maker, collar, horse'
  },
  {
    Id: 14497,
    Code: '5412',
    Name: 'Maker, football'
  },
  {
    Id: 14498,
    Code: '5412',
    Name: 'Maker, footwear'
  },
  {
    Id: 14499,
    Code: '5412',
    Name: 'Maker, frame, photo, leather'
  },
  {
    Id: 14500,
    Code: '5412',
    Name: 'Maker, glove, boxing'
  },
  {
    Id: 14501,
    Code: '5412',
    Name: 'Maker, glove, cricket'
  },
  {
    Id: 14502,
    Code: '5412',
    Name: 'Maker, goods, leather'
  },
  {
    Id: 14503,
    Code: '5412',
    Name: 'Maker, handle, leather goods mfr'
  },
  {
    Id: 14504,
    Code: '5412',
    Name: 'Maker, harness'
  },
  {
    Id: 14505,
    Code: '5412',
    Name: 'Maker, heel'
  },
  {
    Id: 14506,
    Code: '5412',
    Name: 'Maker, hose, leather'
  },
  {
    Id: 14507,
    Code: '5412',
    Name: 'Maker, lace, boot, leather goods mfr'
  },
  {
    Id: 14508,
    Code: '5412',
    Name: 'Maker, leather, fancy'
  },
  {
    Id: 14509,
    Code: '5412',
    Name: 'Maker, lining, footwear mfr'
  },
  {
    Id: 14510,
    Code: '5412',
    Name: 'Maker, pattern, footwear mfr'
  },
  {
    Id: 14511,
    Code: '5412',
    Name: 'Maker, picker'
  },
  {
    Id: 14512,
    Code: '5412',
    Name: 'Maker, pouffe'
  },
  {
    Id: 14513,
    Code: '5412',
    Name: 'Maker, purse, leather'
  },
  {
    Id: 14514,
    Code: '5412',
    Name: 'Maker, rosette, leather'
  },
  {
    Id: 14515,
    Code: '5412',
    Name: 'Maker, saddle'
  },
  {
    Id: 14516,
    Code: '5412',
    Name: 'Maker, scabbard'
  },
  {
    Id: 14517,
    Code: '5412',
    Name: 'Maker, shoe'
  },
  {
    Id: 14518,
    Code: '5412',
    Name: 'Maker, slipper'
  },
  {
    Id: 14519,
    Code: '5412',
    Name: 'Maker, sock, boots and shoes'
  },
  {
    Id: 14520,
    Code: '5412',
    Name: 'Maker, strap'
  },
  {
    Id: 14521,
    Code: '5412',
    Name: 'Maker, trimming, slipper mfr'
  },
  {
    Id: 14522,
    Code: '5412',
    Name: 'Maker, trimmings, slipper mfr'
  },
  {
    Id: 14523,
    Code: '5412',
    Name: 'Maker, trunk, leather'
  },
  {
    Id: 14524,
    Code: '5412',
    Name: 'Maker, washer, leather'
  },
  {
    Id: 14525,
    Code: '5412',
    Name: 'Maker, welt'
  },
  {
    Id: 14526,
    Code: '5412',
    Name: 'Maker, whip'
  },
  {
    Id: 14527,
    Code: '5412',
    Name: 'Maker and repairer, shoe'
  },
  {
    Id: 14528,
    Code: '5412',
    Name: 'Maker-up, leather goods mfr'
  },
  {
    Id: 14529,
    Code: '5412',
    Name: 'Man, bench, footwear mfr'
  },
  {
    Id: 14530,
    Code: '5412',
    Name: 'Man, lathe, textile machinery roller covering'
  },
  {
    Id: 14531,
    Code: '5412',
    Name: 'Man, maintenance, belt'
  },
  {
    Id: 14532,
    Code: '5412',
    Name: 'Man, strap'
  },
  {
    Id: 14533,
    Code: '5412',
    Name: 'Man, table, footwear mfr'
  },
  {
    Id: 14534,
    Code: '5412',
    Name: 'Manufacturer, bag, leather goods mfr'
  },
  {
    Id: 14535,
    Code: '5412',
    Name: 'Manufacturer, goods, leather'
  },
  {
    Id: 14536,
    Code: '5412',
    Name: 'Manufacturer, shoe'
  },
  {
    Id: 14537,
    Code: '5412',
    Name: 'Manufacturer, footwear mfr'
  },
  {
    Id: 14538,
    Code: '5412',
    Name: 'Manufacturer, leather goods mfr'
  },
  {
    Id: 14539,
    Code: '5412',
    Name: 'Marker, stitch, footwear mfr'
  },
  {
    Id: 14540,
    Code: '5412',
    Name: 'Marker, strip'
  },
  {
    Id: 14541,
    Code: '5412',
    Name: 'Marker, upper, footwear mfr'
  },
  {
    Id: 14542,
    Code: '5412',
    Name: 'Marker, footwear mfr'
  },
  {
    Id: 14543,
    Code: '5412',
    Name: 'Mender, belt'
  },
  {
    Id: 14544,
    Code: '5412',
    Name: 'Mender, shoe'
  },
  {
    Id: 14545,
    Code: '5412',
    Name: 'Mender, strap'
  },
  {
    Id: 14546,
    Code: '5412',
    Name: 'Moulder, injection, footwear mfr'
  },
  {
    Id: 14547,
    Code: '5412',
    Name: 'Moulder, insole'
  },
  {
    Id: 14548,
    Code: '5412',
    Name: 'Moulder, leather'
  },
  {
    Id: 14549,
    Code: '5412',
    Name: 'Moulder, sole'
  },
  {
    Id: 14550,
    Code: '5412',
    Name: 'Moulder, stiffener'
  },
  {
    Id: 14551,
    Code: '5412',
    Name: 'Moulder, footwear mfr'
  },
  {
    Id: 14552,
    Code: '5412',
    Name: 'Nailer, footwear mfr'
  },
  {
    Id: 14553,
    Code: '5412',
    Name: 'Operative, boot and shoe'
  },
  {
    Id: 14554,
    Code: '5412',
    Name: 'Operative, manufacturing, footwear'
  },
  {
    Id: 14555,
    Code: '5412',
    Name: 'Operative, shoe'
  },
  {
    Id: 14556,
    Code: '5412',
    Name: 'Operative, slipper'
  },
  {
    Id: 14557,
    Code: '5412',
    Name: 'Operator, bar, heel'
  },
  {
    Id: 14558,
    Code: '5412',
    Name: 'Operator, boot'
  },
  {
    Id: 14559,
    Code: '5412',
    Name: 'Operator, consol, footwear mfr'
  },
  {
    Id: 14560,
    Code: '5412',
    Name: 'Operator, console, footwear mfr'
  },
  {
    Id: 14561,
    Code: '5412',
    Name: 'Operator, last, seat'
  },
  {
    Id: 14562,
    Code: '5412',
    Name: 'Operator, pullover, footwear mfr'
  },
  {
    Id: 14563,
    Code: '5412',
    Name: 'Operator, room, closing, footwear mfr'
  },
  {
    Id: 14564,
    Code: '5412',
    Name: 'Operator, shoe'
  },
  {
    Id: 14565,
    Code: '5412',
    Name: 'Parer, footwear mfr'
  },
  {
    Id: 14566,
    Code: '5412',
    Name: 'Paster, sock'
  },
  {
    Id: 14567,
    Code: '5412',
    Name: 'Paster, footwear mfr'
  },
  {
    Id: 14568,
    Code: '5412',
    Name: 'Paster, leather goods mfr'
  },
  {
    Id: 14569,
    Code: '5412',
    Name: 'Pegger, footwear mfr'
  },
  {
    Id: 14570,
    Code: '5412',
    Name: 'Perforator, footwear mfr'
  },
  {
    Id: 14571,
    Code: '5412',
    Name: 'Piecer, belt'
  },
  {
    Id: 14572,
    Code: '5412',
    Name: 'Piercer, strap'
  },
  {
    Id: 14573,
    Code: '5412',
    Name: 'Planner, die, footwear mfr'
  },
  {
    Id: 14574,
    Code: '5412',
    Name: 'Polisher, boot'
  },
  {
    Id: 14575,
    Code: '5412',
    Name: 'Polisher, clog mfr'
  },
  {
    Id: 14576,
    Code: '5412',
    Name: 'Polisher, footwear mfr'
  },
  {
    Id: 14577,
    Code: '5412',
    Name: 'Pounder'
  },
  {
    Id: 14578,
    Code: '5412',
    Name: 'Preparer, case'
  },
  {
    Id: 14579,
    Code: '5412',
    Name: 'Preparer, footwear mfr'
  },
  {
    Id: 14580,
    Code: '5412',
    Name: 'Preparer, leather goods mfr'
  },
  {
    Id: 14581,
    Code: '5412',
    Name: 'Presser, blanking'
  },
  {
    Id: 14582,
    Code: '5412',
    Name: 'Presser, clicking'
  },
  {
    Id: 14583,
    Code: '5412',
    Name: 'Presser, heel, footwear mfr'
  },
  {
    Id: 14584,
    Code: '5412',
    Name: 'Presser, lining, footwear mfr'
  },
  {
    Id: 14585,
    Code: '5412',
    Name: 'Presser, shoe'
  },
  {
    Id: 14586,
    Code: '5412',
    Name: 'Presser, footwear mfr'
  },
  {
    Id: 14587,
    Code: '5412',
    Name: 'Presser, leather goods mfr'
  },
  {
    Id: 14588,
    Code: '5412',
    Name: 'Pricker, leather goods mfr'
  },
  {
    Id: 14589,
    Code: '5412',
    Name: 'Pricker-up'
  },
  {
    Id: 14590,
    Code: '5412',
    Name: 'Puffer, footwear mfr'
  },
  {
    Id: 14591,
    Code: '5412',
    Name: 'Puller, tack'
  },
  {
    Id: 14592,
    Code: '5412',
    Name: 'Puller-on, footwear mfr'
  },
  {
    Id: 14593,
    Code: '5412',
    Name: 'Puller-over, footwear mfr'
  },
  {
    Id: 14594,
    Code: '5412',
    Name: 'Puncher, footwear mfr'
  },
  {
    Id: 14595,
    Code: '5412',
    Name: 'Putter-on, clog mfr'
  },
  {
    Id: 14596,
    Code: '5412',
    Name: 'Ranger, footwear mfr'
  },
  {
    Id: 14597,
    Code: '5412',
    Name: 'Re-laster'
  },
  {
    Id: 14598,
    Code: '5412',
    Name: 'Rectifier, footwear mfr'
  },
  {
    Id: 14599,
    Code: '5412',
    Name: 'Repairer, bag, hand bags'
  },
  {
    Id: 14600,
    Code: '5412',
    Name: 'Repairer, belt'
  },
  {
    Id: 14601,
    Code: '5412',
    Name: 'Repairer, belting'
  },
  {
    Id: 14602,
    Code: '5412',
    Name: 'Repairer, boot and shoe'
  },
  {
    Id: 14603,
    Code: '5412',
    Name: 'Repairer, shoe'
  },
  {
    Id: 14604,
    Code: '5412',
    Name: 'Repairer, footwear'
  },
  {
    Id: 14605,
    Code: '5412',
    Name: 'Repairer, leather goods'
  },
  {
    Id: 14606,
    Code: '5412',
    Name: 'Riveter, footwear'
  },
  {
    Id: 14607,
    Code: '5412',
    Name: 'Riveter, leather goods'
  },
  {
    Id: 14608,
    Code: '5412',
    Name: 'Rougher, outsole'
  },
  {
    Id: 14609,
    Code: '5412',
    Name: 'Rougher, upper, footwear mfr'
  },
  {
    Id: 14610,
    Code: '5412',
    Name: 'Rounder, footwear mfr'
  },
  {
    Id: 14611,
    Code: '5412',
    Name: 'Rubber-down, footwear mfr'
  },
  {
    Id: 14612,
    Code: '5412',
    Name: 'Rubber-off, footwear mfr'
  },
  {
    Id: 14613,
    Code: '5412',
    Name: 'Saddler'
  },
  {
    Id: 14614,
    Code: '5412',
    Name: 'Scourer, footwear mfr'
  },
  {
    Id: 14615,
    Code: '5412',
    Name: 'Scraper, heel, footwear mfr'
  },
  {
    Id: 14616,
    Code: '5412',
    Name: 'Separator, stitch'
  },
  {
    Id: 14617,
    Code: '5412',
    Name: 'Sewer, belting'
  },
  {
    Id: 14618,
    Code: '5412',
    Name: 'Sewer, rug, skin'
  },
  {
    Id: 14619,
    Code: '5412',
    Name: 'Sewer, sole'
  },
  {
    Id: 14620,
    Code: '5412',
    Name: 'Sewer, welt'
  },
  {
    Id: 14621,
    Code: '5412',
    Name: 'Sewer, wire'
  },
  {
    Id: 14622,
    Code: '5412',
    Name: 'Sewer, footwear mfr'
  },
  {
    Id: 14623,
    Code: '5412',
    Name: 'Sewer, leather goods mfr'
  },
  {
    Id: 14624,
    Code: '5412',
    Name: 'Sewer, rubber footwear mfr'
  },
  {
    Id: 14625,
    Code: '5412',
    Name: 'Shanker, footwear mfr'
  },
  {
    Id: 14626,
    Code: '5412',
    Name: 'Sinker, seat'
  },
  {
    Id: 14627,
    Code: '5412',
    Name: 'Skiver, footwear mfr'
  },
  {
    Id: 14628,
    Code: '5412',
    Name: 'Slipper, last'
  },
  {
    Id: 14629,
    Code: '5412',
    Name: 'Slipper, shoe'
  },
  {
    Id: 14630,
    Code: '5412',
    Name: 'Slugger, footwear mfr'
  },
  {
    Id: 14631,
    Code: '5412',
    Name: 'Socker'
  },
  {
    Id: 14632,
    Code: '5412',
    Name: 'Solutioner, footwear mfr'
  },
  {
    Id: 14633,
    Code: '5412',
    Name: 'Spotter, footwear mfr'
  },
  {
    Id: 14634,
    Code: '5412',
    Name: 'Squarer-up'
  },
  {
    Id: 14635,
    Code: '5412',
    Name: 'Stainer, boot'
  },
  {
    Id: 14636,
    Code: '5412',
    Name: 'Stainer, edge, footwear mfr'
  },
  {
    Id: 14637,
    Code: '5412',
    Name: 'Stainer, leather'
  },
  {
    Id: 14638,
    Code: '5412',
    Name: 'Stainer, shoe'
  },
  {
    Id: 14639,
    Code: '5412',
    Name: 'Stainer, leather goods mfr'
  },
  {
    Id: 14640,
    Code: '5412',
    Name: 'Stapler, slipper'
  },
  {
    Id: 14641,
    Code: '5412',
    Name: 'Stapler, footwear mfr'
  },
  {
    Id: 14642,
    Code: '5412',
    Name: 'Stapler, leather goods mfr'
  },
  {
    Id: 14643,
    Code: '5412',
    Name: 'Sticker, sole'
  },
  {
    Id: 14644,
    Code: '5412',
    Name: 'Sticker, footwear mfr'
  },
  {
    Id: 14645,
    Code: '5412',
    Name: 'Stiffener, footwear mfr'
  },
  {
    Id: 14646,
    Code: '5412',
    Name: 'Stitcher, ball, cricket'
  },
  {
    Id: 14647,
    Code: '5412',
    Name: 'Stitcher, collar'
  },
  {
    Id: 14648,
    Code: '5412',
    Name: 'Stitcher, football'
  },
  {
    Id: 14649,
    Code: '5412',
    Name: 'Stitcher, glove, boxing'
  },
  {
    Id: 14650,
    Code: '5412',
    Name: 'Stitcher, leather'
  },
  {
    Id: 14651,
    Code: '5412',
    Name: 'Stitcher, lock, slipper mfr'
  },
  {
    Id: 14652,
    Code: '5412',
    Name: 'Stitcher, rapid'
  },
  {
    Id: 14653,
    Code: '5412',
    Name: 'Stitcher, wire, leather goods mfr'
  },
  {
    Id: 14654,
    Code: '5412',
    Name: 'Stitcher, footwear mfr'
  },
  {
    Id: 14655,
    Code: '5412',
    Name: 'Stitcher, leather goods mfr'
  },
  {
    Id: 14656,
    Code: '5412',
    Name: 'Stocker, whip'
  },
  {
    Id: 14657,
    Code: '5412',
    Name: 'Stringer, footwear mfr'
  },
  {
    Id: 14658,
    Code: '5412',
    Name: 'Stripper, lace, leather'
  },
  {
    Id: 14659,
    Code: '5412',
    Name: 'Tacker, footwear mfr'
  },
  {
    Id: 14660,
    Code: '5412',
    Name: 'Taper, footwear mfr'
  },
  {
    Id: 14661,
    Code: '5412',
    Name: 'Tapper, footwear mfr'
  },
  {
    Id: 14662,
    Code: '5412',
    Name: 'Tracer, footwear mfr'
  },
  {
    Id: 14663,
    Code: '5412',
    Name: 'Treer'
  },
  {
    Id: 14664,
    Code: '5412',
    Name: 'Trimmer, boot'
  },
  {
    Id: 14665,
    Code: '5412',
    Name: 'Trimmer, heel'
  },
  {
    Id: 14666,
    Code: '5412',
    Name: 'Trimmer, footwear mfr'
  },
  {
    Id: 14667,
    Code: '5412',
    Name: 'Trimmer, leather goods mfr'
  },
  {
    Id: 14668,
    Code: '5412',
    Name: 'Turner, slipper'
  },
  {
    Id: 14669,
    Code: '5412',
    Name: 'Turner, footwear mfr'
  },
  {
    Id: 14670,
    Code: '5412',
    Name: 'Vamper'
  },
  {
    Id: 14671,
    Code: '5412',
    Name: 'Welder, footwear mfr'
  },
  {
    Id: 14672,
    Code: '5412',
    Name: 'Welter, footwear mfr'
  },
  {
    Id: 14673,
    Code: '5412',
    Name: 'Wetter, footwear mfr'
  },
  {
    Id: 14674,
    Code: '5412',
    Name: 'Wheeler, welt'
  },
  {
    Id: 14675,
    Code: '5412',
    Name: 'Wheeler, footwear mfr'
  },
  {
    Id: 14676,
    Code: '5412',
    Name: 'Whipper, footwear mfr'
  },
  {
    Id: 14677,
    Code: '5412',
    Name: 'Worker, bench, footwear mfr'
  },
  {
    Id: 14678,
    Code: '5412',
    Name: 'Worker, bench, leather goods mfr'
  },
  {
    Id: 14679,
    Code: '5412',
    Name: 'Worker, boot and shoe'
  },
  {
    Id: 14680,
    Code: '5412',
    Name: 'Worker, craft, leather'
  },
  {
    Id: 14681,
    Code: '5412',
    Name: 'Worker, factory, footwear mfr'
  },
  {
    Id: 14682,
    Code: '5412',
    Name: 'Worker, goods, leather'
  },
  {
    Id: 14683,
    Code: '5412',
    Name: 'Worker, leather, fancy'
  },
  {
    Id: 14684,
    Code: '5412',
    Name: 'Worker, leather, hydraulic and mechanical'
  },
  {
    Id: 14685,
    Code: '5412',
    Name: 'Worker, leather, orthopaedic'
  },
  {
    Id: 14686,
    Code: '5412',
    Name: 'Worker, leather, artificial limb mfr'
  },
  {
    Id: 14687,
    Code: '5412',
    Name: 'Worker, leather, leather goods mfr'
  },
  {
    Id: 14688,
    Code: '5412',
    Name: 'Worker, leather, railways'
  },
  {
    Id: 14689,
    Code: '5412',
    Name: 'Worker, leather, vehicle mfr'
  },
  {
    Id: 14690,
    Code: '5412',
    Name: 'Worker, room, shoe'
  },
  {
    Id: 14691,
    Code: '5412',
    Name: 'Worker, saddle'
  },
  {
    Id: 14692,
    Code: '5412',
    Name: 'Worker, shoe'
  },
  {
    Id: 14693,
    Code: '5412',
    Name: 'Worker, shoeroom'
  },
  {
    Id: 14694,
    Code: '5412',
    Name: 'Worker, slipper'
  },
  {
    Id: 14695,
    Code: '5413',
    Name: 'Assistant, alteration'
  },
  {
    Id: 14696,
    Code: '5413',
    Name: "Assistant, cutter's, clothing mfr"
  },
  {
    Id: 14697,
    Code: '5413',
    Name: "Assistant, milliner's"
  },
  {
    Id: 14698,
    Code: '5413',
    Name: 'Assistant, room, cutting, clothing mfr'
  },
  {
    Id: 14699,
    Code: '5413',
    Name: "Assistant, tailor's"
  },
  {
    Id: 14700,
    Code: '5413',
    Name: 'Assistant, tailoring'
  },
  {
    Id: 14701,
    Code: '5413',
    Name: 'Baister'
  },
  {
    Id: 14702,
    Code: '5413',
    Name: 'Baster'
  },
  {
    Id: 14703,
    Code: '5413',
    Name: 'Blocker, fur'
  },
  {
    Id: 14704,
    Code: '5413',
    Name: "Copyist, milliner's"
  },
  {
    Id: 14705,
    Code: '5413',
    Name: 'Copyist, millinery mfr'
  },
  {
    Id: 14706,
    Code: '5413',
    Name: 'Costumier'
  },
  {
    Id: 14707,
    Code: '5413',
    Name: 'Cutter, alteration'
  },
  {
    Id: 14708,
    Code: '5413',
    Name: 'Cutter, blouse'
  },
  {
    Id: 14709,
    Code: '5413',
    Name: 'Cutter, cap'
  },
  {
    Id: 14710,
    Code: '5413',
    Name: 'Cutter, cloth, clothing mfr'
  },
  {
    Id: 14711,
    Code: '5413',
    Name: 'Cutter, cloth, made-up textiles mfr'
  },
  {
    Id: 14712,
    Code: '5413',
    Name: "Cutter, clothier's"
  },
  {
    Id: 14713,
    Code: '5413',
    Name: 'Cutter, clothing'
  },
  {
    Id: 14714,
    Code: '5413',
    Name: 'Cutter, collar'
  },
  {
    Id: 14715,
    Code: '5413',
    Name: 'Cutter, costume'
  },
  {
    Id: 14716,
    Code: '5413',
    Name: 'Cutter, fabric'
  },
  {
    Id: 14717,
    Code: '5413',
    Name: 'Cutter, fur'
  },
  {
    Id: 14718,
    Code: '5413',
    Name: 'Cutter, garment'
  },
  {
    Id: 14719,
    Code: '5413',
    Name: 'Cutter, glove'
  },
  {
    Id: 14720,
    Code: '5413',
    Name: 'Cutter, hand, clothing mfr'
  },
  {
    Id: 14721,
    Code: '5413',
    Name: 'Cutter, hat'
  },
  {
    Id: 14722,
    Code: '5413',
    Name: 'Cutter, hosiery'
  },
  {
    Id: 14723,
    Code: '5413',
    Name: 'Cutter, knife, band'
  },
  {
    Id: 14724,
    Code: '5413',
    Name: 'Cutter, knife, hand'
  },
  {
    Id: 14725,
    Code: '5413',
    Name: 'Cutter, knife, machine'
  },
  {
    Id: 14726,
    Code: '5413',
    Name: 'Cutter, knife, leather glove mfr'
  },
  {
    Id: 14727,
    Code: '5413',
    Name: 'Cutter, leather, clothing mfr'
  },
  {
    Id: 14728,
    Code: '5413',
    Name: 'Cutter, linen, button mfr'
  },
  {
    Id: 14729,
    Code: '5413',
    Name: 'Cutter, lingerie'
  },
  {
    Id: 14730,
    Code: '5413',
    Name: 'Cutter, lining, clothing mfr'
  },
  {
    Id: 14731,
    Code: '5413',
    Name: 'Cutter, machine, clothing mfr'
  },
  {
    Id: 14732,
    Code: '5413',
    Name: 'Cutter, mantle'
  },
  {
    Id: 14733,
    Code: '5413',
    Name: 'Cutter, material'
  },
  {
    Id: 14734,
    Code: '5413',
    Name: 'Cutter, measure'
  },
  {
    Id: 14735,
    Code: '5413',
    Name: 'Cutter, pattern, clothing'
  },
  {
    Id: 14736,
    Code: '5413',
    Name: 'Cutter, pattern, clothing mfr'
  },
  {
    Id: 14737,
    Code: '5413',
    Name: 'Cutter, press, made-up textiles mfr'
  },
  {
    Id: 14738,
    Code: '5413',
    Name: 'Cutter, shirt'
  },
  {
    Id: 14739,
    Code: '5413',
    Name: 'Cutter, skin, clothing mfr'
  },
  {
    Id: 14740,
    Code: '5413',
    Name: 'Cutter, stock'
  },
  {
    Id: 14741,
    Code: '5413',
    Name: 'Cutter, table, glove mfr'
  },
  {
    Id: 14742,
    Code: '5413',
    Name: "Cutter, tailor's"
  },
  {
    Id: 14743,
    Code: '5413',
    Name: 'Cutter, tie'
  },
  {
    Id: 14744,
    Code: '5413',
    Name: 'Cutter, trimming'
  },
  {
    Id: 14745,
    Code: '5413',
    Name: 'Cutter, under'
  },
  {
    Id: 14746,
    Code: '5413',
    Name: 'Cutter, clothing'
  },
  {
    Id: 14747,
    Code: '5413',
    Name: 'Cutter, clothing mfr'
  },
  {
    Id: 14748,
    Code: '5413',
    Name: 'Cutter, embroidering'
  },
  {
    Id: 14749,
    Code: '5413',
    Name: 'Cutter, glove mfr'
  },
  {
    Id: 14750,
    Code: '5413',
    Name: 'Cutter, haberdashery mfr'
  },
  {
    Id: 14751,
    Code: '5413',
    Name: 'Cutter, hat mfr'
  },
  {
    Id: 14752,
    Code: '5413',
    Name: 'Cutter, hosiery, knitwear mfr'
  },
  {
    Id: 14753,
    Code: '5413',
    Name: 'Cutter and fitter'
  },
  {
    Id: 14754,
    Code: '5413',
    Name: 'Dressmaker'
  },
  {
    Id: 14755,
    Code: '5413',
    Name: 'Fitter, alteration'
  },
  {
    Id: 14756,
    Code: '5413',
    Name: 'Fitter, clothing, retail trade'
  },
  {
    Id: 14757,
    Code: '5413',
    Name: 'Fitter, coat'
  },
  {
    Id: 14758,
    Code: '5413',
    Name: 'Fitter, dress'
  },
  {
    Id: 14759,
    Code: '5413',
    Name: "Fitter, tailor's"
  },
  {
    Id: 14760,
    Code: '5413',
    Name: 'Fitter, clothing mfr'
  },
  {
    Id: 14761,
    Code: '5413',
    Name: 'Fitter and trimmer'
  },
  {
    Id: 14762,
    Code: '5413',
    Name: 'Fitter-up, clothing mfr'
  },
  {
    Id: 14763,
    Code: '5413',
    Name: 'Fixer, trimmer'
  },
  {
    Id: 14764,
    Code: '5413',
    Name: 'Fixer, clothing mfr'
  },
  {
    Id: 14765,
    Code: '5413',
    Name: 'Foreman, tailoring'
  },
  {
    Id: 14766,
    Code: '5413',
    Name: 'Furrier, fur goods mfr'
  },
  {
    Id: 14767,
    Code: '5413',
    Name: 'Hand, alteration'
  },
  {
    Id: 14768,
    Code: '5413',
    Name: 'Hand, first, clothing mfr'
  },
  {
    Id: 14769,
    Code: '5413',
    Name: 'Hand, general, dressmaking'
  },
  {
    Id: 14770,
    Code: '5413',
    Name: 'Hand, second, clothing mfr'
  },
  {
    Id: 14771,
    Code: '5413',
    Name: 'Improver, cutter'
  },
  {
    Id: 14772,
    Code: '5413',
    Name: 'Improver, millinery'
  },
  {
    Id: 14773,
    Code: '5413',
    Name: 'Layer-up, clothing mfr'
  },
  {
    Id: 14774,
    Code: '5413',
    Name: 'Machinist, cutting, clothing mfr'
  },
  {
    Id: 14775,
    Code: '5413',
    Name: 'Maker, blouse'
  },
  {
    Id: 14776,
    Code: '5413',
    Name: 'Maker, cap, clothing mfr'
  },
  {
    Id: 14777,
    Code: '5413',
    Name: 'Maker, coat'
  },
  {
    Id: 14778,
    Code: '5413',
    Name: 'Maker, costume'
  },
  {
    Id: 14779,
    Code: '5413',
    Name: 'Maker, dress'
  },
  {
    Id: 14780,
    Code: '5413',
    Name: 'Maker, garment'
  },
  {
    Id: 14781,
    Code: '5413',
    Name: 'Maker, kilt'
  },
  {
    Id: 14782,
    Code: '5413',
    Name: 'Maker, mantle, fur'
  },
  {
    Id: 14783,
    Code: '5413',
    Name: 'Maker, pattern, clothing mfr'
  },
  {
    Id: 14784,
    Code: '5413',
    Name: 'Maker, shirt'
  },
  {
    Id: 14785,
    Code: '5413',
    Name: 'Maker, skirt'
  },
  {
    Id: 14786,
    Code: '5413',
    Name: 'Maker, trouser'
  },
  {
    Id: 14787,
    Code: '5413',
    Name: 'Maker, waistcoat'
  },
  {
    Id: 14788,
    Code: '5413',
    Name: 'Marker, clothing mfr'
  },
  {
    Id: 14789,
    Code: '5413',
    Name: 'Marker-up, clothing mfr'
  },
  {
    Id: 14790,
    Code: '5413',
    Name: 'Measurer, clothing mfr'
  },
  {
    Id: 14791,
    Code: '5413',
    Name: 'Milliner'
  },
  {
    Id: 14792,
    Code: '5413',
    Name: 'Nailer, fur goods mfr'
  },
  {
    Id: 14793,
    Code: '5413',
    Name: 'Picker, clothing mfr'
  },
  {
    Id: 14794,
    Code: '5413',
    Name: 'Pleater, clothing mfr'
  },
  {
    Id: 14795,
    Code: '5413',
    Name: 'Preparer, clothing mfr'
  },
  {
    Id: 14796,
    Code: '5413',
    Name: 'Puncher, glove mfr'
  },
  {
    Id: 14797,
    Code: '5413',
    Name: 'Shaper, collar'
  },
  {
    Id: 14798,
    Code: '5413',
    Name: 'Shaper, clothing mfr'
  },
  {
    Id: 14799,
    Code: '5413',
    Name: 'Tacker, tailoring'
  },
  {
    Id: 14800,
    Code: '5413',
    Name: 'Tailor'
  },
  {
    Id: 14801,
    Code: '5413',
    Name: 'Tailor and outfitter'
  },
  {
    Id: 14802,
    Code: '5413',
    Name: 'Tailoress'
  },
  {
    Id: 14803,
    Code: '5413',
    Name: 'Webber'
  },
  {
    Id: 14804,
    Code: '5413',
    Name: 'Worker, glove'
  },
  {
    Id: 14805,
    Code: '5413',
    Name: 'Worker, hat'
  },
  {
    Id: 14806,
    Code: '5419',
    Name: "Assistant, maker's, dress"
  },
  {
    Id: 14807,
    Code: '5419',
    Name: "Assistant, trimmer's, upholstering"
  },
  {
    Id: 14808,
    Code: '5419',
    Name: 'Assistant, dressmaking'
  },
  {
    Id: 14809,
    Code: '5419',
    Name: 'Backer, carpet'
  },
  {
    Id: 14810,
    Code: '5419',
    Name: 'Binder, chair'
  },
  {
    Id: 14811,
    Code: '5419',
    Name: 'Binder, umbrella'
  },
  {
    Id: 14812,
    Code: '5419',
    Name: 'Blocker, hat'
  },
  {
    Id: 14813,
    Code: '5419',
    Name: 'Blocker, hat mfr'
  },
  {
    Id: 14814,
    Code: '5419',
    Name: 'Bonder, garment, rainproof'
  },
  {
    Id: 14815,
    Code: '5419',
    Name: 'Braider, vehicle building'
  },
  {
    Id: 14816,
    Code: '5419',
    Name: 'Buttoner, clothing mfr'
  },
  {
    Id: 14817,
    Code: '5419',
    Name: 'Cleaner, hat'
  },
  {
    Id: 14818,
    Code: '5419',
    Name: 'Cleaner, clothing mfr'
  },
  {
    Id: 14819,
    Code: '5419',
    Name: 'Clearer, embroidering'
  },
  {
    Id: 14820,
    Code: '5419',
    Name: 'Coner, felt hood mfr'
  },
  {
    Id: 14821,
    Code: '5419',
    Name: 'Conservationist, tapestry'
  },
  {
    Id: 14822,
    Code: '5419',
    Name: 'Conservationist, textile'
  },
  {
    Id: 14823,
    Code: '5419',
    Name: 'Consultant, corsetry'
  },
  {
    Id: 14824,
    Code: '5419',
    Name: 'Corder, upholstering'
  },
  {
    Id: 14825,
    Code: '5419',
    Name: 'Corsetiere'
  },
  {
    Id: 14826,
    Code: '5419',
    Name: 'Coverer, coat hanger mfr'
  },
  {
    Id: 14827,
    Code: '5419',
    Name: 'Curler, hat mfr'
  },
  {
    Id: 14828,
    Code: '5419',
    Name: 'Cutter, asbestos, mattress, upholstery mfr'
  },
  {
    Id: 14829,
    Code: '5419',
    Name: 'Cutter, bag, canvas'
  },
  {
    Id: 14830,
    Code: '5419',
    Name: 'Cutter, bandage'
  },
  {
    Id: 14831,
    Code: '5419',
    Name: 'Cutter, bias'
  },
  {
    Id: 14832,
    Code: '5419',
    Name: 'Cutter, cloth, umbrella'
  },
  {
    Id: 14833,
    Code: '5419',
    Name: 'Cutter, corset'
  },
  {
    Id: 14834,
    Code: '5419',
    Name: 'Cutter, design, clothing mfr'
  },
  {
    Id: 14835,
    Code: '5419',
    Name: 'Cutter, designer'
  },
  {
    Id: 14836,
    Code: '5419',
    Name: 'Cutter, felt, textile mfr'
  },
  {
    Id: 14837,
    Code: '5419',
    Name: 'Cutter, flag'
  },
  {
    Id: 14838,
    Code: '5419',
    Name: 'Cutter, laces'
  },
  {
    Id: 14839,
    Code: '5419',
    Name: 'Cutter, pattern, fur goods mfr'
  },
  {
    Id: 14840,
    Code: '5419',
    Name: 'Cutter, pattern, textile mfr'
  },
  {
    Id: 14841,
    Code: '5419',
    Name: 'Cutter, press, textile mfr'
  },
  {
    Id: 14842,
    Code: '5419',
    Name: 'Cutter, rib, hosiery, knitwear mfr'
  },
  {
    Id: 14843,
    Code: '5419',
    Name: 'Cutter, ribbon, typewriter ribbons'
  },
  {
    Id: 14844,
    Code: '5419',
    Name: 'Cutter, roll'
  },
  {
    Id: 14845,
    Code: '5419',
    Name: 'Cutter, sack'
  },
  {
    Id: 14846,
    Code: '5419',
    Name: 'Cutter, thread'
  },
  {
    Id: 14847,
    Code: '5419',
    Name: 'Cutter, upholstery'
  },
  {
    Id: 14848,
    Code: '5419',
    Name: 'Cutter, artificial flower mfr'
  },
  {
    Id: 14849,
    Code: '5419',
    Name: 'Cutter, canvas goods mfr'
  },
  {
    Id: 14850,
    Code: '5419',
    Name: 'Cutter, coach trimming'
  },
  {
    Id: 14851,
    Code: '5419',
    Name: 'Cutter, soft toy mfr'
  },
  {
    Id: 14852,
    Code: '5419',
    Name: 'Cutter, upholstering'
  },
  {
    Id: 14853,
    Code: '5419',
    Name: 'Darner, hotels, catering, public houses'
  },
  {
    Id: 14854,
    Code: '5419',
    Name: 'Darner, textile mfr'
  },
  {
    Id: 14855,
    Code: '5419',
    Name: 'Designer-cutter, clothing mfr'
  },
  {
    Id: 14856,
    Code: '5419',
    Name: 'Drawthreader'
  },
  {
    Id: 14857,
    Code: '5419',
    Name: 'Embroiderer'
  },
  {
    Id: 14858,
    Code: '5419',
    Name: 'Eyeletter, clothing mfr'
  },
  {
    Id: 14859,
    Code: '5419',
    Name: 'Filler, cushion'
  },
  {
    Id: 14860,
    Code: '5419',
    Name: 'Filler, hand, upholstery mfr'
  },
  {
    Id: 14861,
    Code: '5419',
    Name: 'Finisher, hand, clothing mfr'
  },
  {
    Id: 14862,
    Code: '5419',
    Name: 'Finisher, hand, felt hat mfr'
  },
  {
    Id: 14863,
    Code: '5419',
    Name: 'Finisher, hand, knitted goods mfr'
  },
  {
    Id: 14864,
    Code: '5419',
    Name: 'Finisher, hat'
  },
  {
    Id: 14865,
    Code: '5419',
    Name: 'Finisher, tent'
  },
  {
    Id: 14866,
    Code: '5419',
    Name: 'Finisher, canvas goods mfr'
  },
  {
    Id: 14867,
    Code: '5419',
    Name: 'Finisher, hat mfr'
  },
  {
    Id: 14868,
    Code: '5419',
    Name: 'Fitter, corset, surgical'
  },
  {
    Id: 14869,
    Code: '5419',
    Name: 'Fitter, corsetry'
  },
  {
    Id: 14870,
    Code: '5419',
    Name: 'Flanger, hat mfr'
  },
  {
    Id: 14871,
    Code: '5419',
    Name: 'Footer, hosiery, knitwear mfr'
  },
  {
    Id: 14872,
    Code: '5419',
    Name: 'Foreman, loom'
  },
  {
    Id: 14873,
    Code: '5419',
    Name: 'Foreman, shed, weaving'
  },
  {
    Id: 14874,
    Code: '5419',
    Name: 'Foreman, weaving'
  },
  {
    Id: 14875,
    Code: '5419',
    Name: 'Foreman, bookcloth mfr'
  },
  {
    Id: 14876,
    Code: '5419',
    Name: 'Foreman, canvas goods mfr'
  },
  {
    Id: 14877,
    Code: '5419',
    Name: 'Foreman, clothing mfr'
  },
  {
    Id: 14878,
    Code: '5419',
    Name: 'Foreman, textile mfr: hosiery mfr'
  },
  {
    Id: 14879,
    Code: '5419',
    Name: 'Foreman, textile mfr: textile weaving'
  },
  {
    Id: 14880,
    Code: '5419',
    Name: 'Framer, calico'
  },
  {
    Id: 14881,
    Code: '5419',
    Name: 'Fringer'
  },
  {
    Id: 14882,
    Code: '5419',
    Name: 'Gaiter, loom'
  },
  {
    Id: 14883,
    Code: '5419',
    Name: 'Grader, pattern, clothing'
  },
  {
    Id: 14884,
    Code: '5419',
    Name: 'Grader, pattern, clothing mfr'
  },
  {
    Id: 14885,
    Code: '5419',
    Name: 'Grader, paper pattern mfr'
  },
  {
    Id: 14886,
    Code: '5419',
    Name: 'Hand, bag'
  },
  {
    Id: 14887,
    Code: '5419',
    Name: 'Hand, circular'
  },
  {
    Id: 14888,
    Code: '5419',
    Name: 'Hand, front, clothing mfr'
  },
  {
    Id: 14889,
    Code: '5419',
    Name: 'Hand, lace'
  },
  {
    Id: 14890,
    Code: '5419',
    Name: 'Hand, loom, warp'
  },
  {
    Id: 14891,
    Code: '5419',
    Name: 'Hand, needle, latch'
  },
  {
    Id: 14892,
    Code: '5419',
    Name: 'Hand, pattern, lace mfr'
  },
  {
    Id: 14893,
    Code: '5419',
    Name: 'Hand, seamless'
  },
  {
    Id: 14894,
    Code: '5419',
    Name: 'Hand, smash'
  },
  {
    Id: 14895,
    Code: '5419',
    Name: 'Hand, stripping, hosiery, knitwear mfr'
  },
  {
    Id: 14896,
    Code: '5419',
    Name: 'Hand, twist, net, plain'
  },
  {
    Id: 14897,
    Code: '5419',
    Name: 'Hand, twist'
  },
  {
    Id: 14898,
    Code: '5419',
    Name: 'Hand, warp'
  },
  {
    Id: 14899,
    Code: '5419',
    Name: 'Hatter, hat mfr'
  },
  {
    Id: 14900,
    Code: '5419',
    Name: 'Hosier, elastic'
  },
  {
    Id: 14901,
    Code: '5419',
    Name: 'Hosier, surgical'
  },
  {
    Id: 14902,
    Code: '5419',
    Name: 'Joiner, textile mfr: hosiery mfr'
  },
  {
    Id: 14903,
    Code: '5419',
    Name: 'Knitter'
  },
  {
    Id: 14904,
    Code: '5419',
    Name: 'Layer-down, textile finishing'
  },
  {
    Id: 14905,
    Code: '5419',
    Name: 'Layer-out, glove mfr'
  },
  {
    Id: 14906,
    Code: '5419',
    Name: 'Lurer'
  },
  {
    Id: 14907,
    Code: '5419',
    Name: 'Machinist, blocking'
  },
  {
    Id: 14908,
    Code: '5419',
    Name: 'Machinist, eyelet-hole'
  },
  {
    Id: 14909,
    Code: '5419',
    Name: 'Machinist, guillotine, textile mfr'
  },
  {
    Id: 14910,
    Code: '5419',
    Name: 'Machinist, slitting, fabric mfr'
  },
  {
    Id: 14911,
    Code: '5419',
    Name: 'Machinist, slitting and winding'
  },
  {
    Id: 14912,
    Code: '5419',
    Name: 'Machinist, stitching, wire'
  },
  {
    Id: 14913,
    Code: '5419',
    Name: 'Machinist, tufting, mattress, upholstery mfr'
  },
  {
    Id: 14914,
    Code: '5419',
    Name: 'Machinist, sanitary towel mfr'
  },
  {
    Id: 14915,
    Code: '5419',
    Name: 'Maker, bag, jute'
  },
  {
    Id: 14916,
    Code: '5419',
    Name: 'Maker, bag, nail'
  },
  {
    Id: 14917,
    Code: '5419',
    Name: 'Maker, band, textile smallwares mfr'
  },
  {
    Id: 14918,
    Code: '5419',
    Name: 'Maker, bandage'
  },
  {
    Id: 14919,
    Code: '5419',
    Name: 'Maker, body, hat mfr'
  },
  {
    Id: 14920,
    Code: '5419',
    Name: 'Maker, coat, knitted coats'
  },
  {
    Id: 14921,
    Code: '5419',
    Name: 'Maker, colour, flag mfr'
  },
  {
    Id: 14922,
    Code: '5419',
    Name: 'Maker, corset'
  },
  {
    Id: 14923,
    Code: '5419',
    Name: 'Maker, fabric, glove'
  },
  {
    Id: 14924,
    Code: '5419',
    Name: 'Maker, fringe, metallic'
  },
  {
    Id: 14925,
    Code: '5419',
    Name: 'Maker, hat'
  },
  {
    Id: 14926,
    Code: '5419',
    Name: 'Maker, heald, yarn'
  },
  {
    Id: 14927,
    Code: '5419',
    Name: 'Maker, helmet'
  },
  {
    Id: 14928,
    Code: '5419',
    Name: 'Maker, hosiery'
  },
  {
    Id: 14929,
    Code: '5419',
    Name: 'Maker, lace, boot, textile smallwares mfr'
  },
  {
    Id: 14930,
    Code: '5419',
    Name: 'Maker, lace, warp'
  },
  {
    Id: 14931,
    Code: '5419',
    Name: 'Maker, lace'
  },
  {
    Id: 14932,
    Code: '5419',
    Name: 'Maker, marker'
  },
  {
    Id: 14933,
    Code: '5419',
    Name: 'Maker, mat'
  },
  {
    Id: 14934,
    Code: '5419',
    Name: 'Maker, mount, wig'
  },
  {
    Id: 14935,
    Code: '5419',
    Name: 'Maker, net'
  },
  {
    Id: 14936,
    Code: '5419',
    Name: 'Maker, pattern, artificial flower mfr'
  },
  {
    Id: 14937,
    Code: '5419',
    Name: 'Maker, pattern, textile mfr'
  },
  {
    Id: 14938,
    Code: '5419',
    Name: 'Maker, puff, powder'
  },
  {
    Id: 14939,
    Code: '5419',
    Name: 'Maker, rug'
  },
  {
    Id: 14940,
    Code: '5419',
    Name: 'Maker, sail'
  },
  {
    Id: 14941,
    Code: '5419',
    Name: 'Maker, sample, textile mfr'
  },
  {
    Id: 14942,
    Code: '5419',
    Name: 'Maker, sock, hosiery, knitwear mfr'
  },
  {
    Id: 14943,
    Code: '5419',
    Name: 'Maker, tassel'
  },
  {
    Id: 14944,
    Code: '5419',
    Name: 'Maker, tent'
  },
  {
    Id: 14945,
    Code: '5419',
    Name: 'Maker, towel'
  },
  {
    Id: 14946,
    Code: '5419',
    Name: 'Maker, umbrella'
  },
  {
    Id: 14947,
    Code: '5419',
    Name: 'Maker, waterproof'
  },
  {
    Id: 14948,
    Code: '5419',
    Name: 'Maker-up, needlework kit mfr'
  },
  {
    Id: 14949,
    Code: '5419',
    Name: 'Man, lace'
  },
  {
    Id: 14950,
    Code: '5419',
    Name: 'Man, table, clothing mfr'
  },
  {
    Id: 14951,
    Code: '5419',
    Name: 'Manufacturer, clothing'
  },
  {
    Id: 14952,
    Code: '5419',
    Name: 'Manufacturer, garment'
  },
  {
    Id: 14953,
    Code: '5419',
    Name: 'Manufacturer, goods, canvas'
  },
  {
    Id: 14954,
    Code: '5419',
    Name: 'Manufacturer, hat'
  },
  {
    Id: 14955,
    Code: '5419',
    Name: 'Manufacturer, knitwear'
  },
  {
    Id: 14956,
    Code: '5419',
    Name: 'Manufacturer, canvas goods mfr'
  },
  {
    Id: 14957,
    Code: '5419',
    Name: 'Manufacturer, clothing mfr'
  },
  {
    Id: 14958,
    Code: '5419',
    Name: 'Manufacturer, hat mfr'
  },
  {
    Id: 14959,
    Code: '5419',
    Name: 'Manufacturer, knitwear mfr'
  },
  {
    Id: 14960,
    Code: '5419',
    Name: 'Manufacturer, umbrella, parasol mfr'
  },
  {
    Id: 14961,
    Code: '5419',
    Name: 'Marker, button'
  },
  {
    Id: 14962,
    Code: '5419',
    Name: 'Marker, buttonhole'
  },
  {
    Id: 14963,
    Code: '5419',
    Name: 'Marker, lining'
  },
  {
    Id: 14964,
    Code: '5419',
    Name: 'Marker, pattern, down quilt'
  },
  {
    Id: 14965,
    Code: '5419',
    Name: 'Marker, piece, textile mfr'
  },
  {
    Id: 14966,
    Code: '5419',
    Name: 'Marker, embroidery mfr'
  },
  {
    Id: 14967,
    Code: '5419',
    Name: 'Marker, hosiery, knitwear mfr'
  },
  {
    Id: 14968,
    Code: '5419',
    Name: 'Marker, textile mfr'
  },
  {
    Id: 14969,
    Code: '5419',
    Name: 'Marker-off, textile mfr'
  },
  {
    Id: 14970,
    Code: '5419',
    Name: 'Matcher, clothing mfr'
  },
  {
    Id: 14971,
    Code: '5419',
    Name: 'Matcher, hat mfr'
  },
  {
    Id: 14972,
    Code: '5419',
    Name: 'Milliner, hosiery'
  },
  {
    Id: 14973,
    Code: '5419',
    Name: 'Mistress, weaving'
  },
  {
    Id: 14974,
    Code: '5419',
    Name: 'Modeller, pattern, paper'
  },
  {
    Id: 14975,
    Code: '5419',
    Name: 'Mounter, textile weaving'
  },
  {
    Id: 14976,
    Code: '5419',
    Name: 'Operator, guillotine, coach trimming'
  },
  {
    Id: 14977,
    Code: '5419',
    Name: 'Overlooker, loom, maintenance, textile mfr: textile weaving'
  },
  {
    Id: 14978,
    Code: '5419',
    Name: 'Painter, textile designing'
  },
  {
    Id: 14979,
    Code: '5419',
    Name: 'Pantographer, lace mfr'
  },
  {
    Id: 14980,
    Code: '5419',
    Name: 'Parer, clothing mfr'
  },
  {
    Id: 14981,
    Code: '5419',
    Name: 'Patcher, wool'
  },
  {
    Id: 14982,
    Code: '5419',
    Name: 'Penciller, clothing mfr'
  },
  {
    Id: 14983,
    Code: '5419',
    Name: 'Perforator, embroidery mfr'
  },
  {
    Id: 14984,
    Code: '5419',
    Name: 'Perforator, glove mfr'
  },
  {
    Id: 14985,
    Code: '5419',
    Name: "Planner, clothier's"
  },
  {
    Id: 14986,
    Code: '5419',
    Name: 'Planner, lay, clothing mfr'
  },
  {
    Id: 14987,
    Code: '5419',
    Name: 'Planner, clothing mfr'
  },
  {
    Id: 14988,
    Code: '5419',
    Name: 'Plier, needle, hosiery, knitwear mfr'
  },
  {
    Id: 14989,
    Code: '5419',
    Name: 'Polisher, hat'
  },
  {
    Id: 14990,
    Code: '5419',
    Name: 'Polisher, hat mfr'
  },
  {
    Id: 14991,
    Code: '5419',
    Name: 'Pouncer'
  },
  {
    Id: 14992,
    Code: '5419',
    Name: 'Preparer, design, textiles'
  },
  {
    Id: 14993,
    Code: '5419',
    Name: 'Preparer, embroidery mfr'
  },
  {
    Id: 14994,
    Code: '5419',
    Name: 'Puller, base, clothing mfr'
  },
  {
    Id: 14995,
    Code: '5419',
    Name: 'Puller, baste'
  },
  {
    Id: 14996,
    Code: '5419',
    Name: 'Putter-on, textile printing'
  },
  {
    Id: 14997,
    Code: '5419',
    Name: 'Putter-up, textile mfr: textile weaving'
  },
  {
    Id: 14998,
    Code: '5419',
    Name: 'Repairer, umbrella'
  },
  {
    Id: 14999,
    Code: '5419',
    Name: 'Repairer, embroidery mfr'
  },
  {
    Id: 15000,
    Code: '5419',
    Name: 'Restorer, tapestry'
  },
  {
    Id: 15001,
    Code: '5419',
    Name: 'Restorer, textiles'
  },
  {
    Id: 15002,
    Code: '5419',
    Name: 'Rounder, hat mfr'
  },
  {
    Id: 15003,
    Code: '5419',
    Name: 'Rounder-off'
  },
  {
    Id: 15004,
    Code: '5419',
    Name: 'Runner-off, hosiery, knitwear mfr'
  },
  {
    Id: 15005,
    Code: '5419',
    Name: 'Runner-on, hosiery, knitwear mfr'
  },
  {
    Id: 15006,
    Code: '5419',
    Name: 'Sewer, hand'
  },
  {
    Id: 15007,
    Code: '5419',
    Name: 'Sewer, powder puff mfr'
  },
  {
    Id: 15008,
    Code: '5419',
    Name: 'Shaper, brim'
  },
  {
    Id: 15009,
    Code: '5419',
    Name: 'Shaper, millinery mfr'
  },
  {
    Id: 15010,
    Code: '5419',
    Name: 'Shaver, hood'
  },
  {
    Id: 15011,
    Code: '5419',
    Name: 'Shaver, hat mfr'
  },
  {
    Id: 15012,
    Code: '5419',
    Name: 'Shearer, glove mfr'
  },
  {
    Id: 15013,
    Code: '5419',
    Name: 'Shearman, clothing mfr'
  },
  {
    Id: 15014,
    Code: '5419',
    Name: 'Shuttler'
  },
  {
    Id: 15015,
    Code: '5419',
    Name: 'Slitter, fabrics'
  },
  {
    Id: 15016,
    Code: '5419',
    Name: 'Smearer, waterproof garment mfr'
  },
  {
    Id: 15017,
    Code: '5419',
    Name: 'Smocker'
  },
  {
    Id: 15018,
    Code: '5419',
    Name: 'Spinner, gimp'
  },
  {
    Id: 15019,
    Code: '5419',
    Name: 'Starrer'
  },
  {
    Id: 15020,
    Code: '5419',
    Name: 'Steamer, hat'
  },
  {
    Id: 15021,
    Code: '5419',
    Name: 'Steamer, felt hat mfr'
  },
  {
    Id: 15022,
    Code: '5419',
    Name: 'Stenciller, needlework kit mfr'
  },
  {
    Id: 15023,
    Code: '5419',
    Name: 'Sticker, clothing mfr'
  },
  {
    Id: 15024,
    Code: '5419',
    Name: 'Stockinger'
  },
  {
    Id: 15025,
    Code: '5419',
    Name: 'Stuffer, chair'
  },
  {
    Id: 15026,
    Code: '5419',
    Name: 'Stuffer, cushion'
  },
  {
    Id: 15027,
    Code: '5419',
    Name: 'Stuffer, mattress, upholstery mfr'
  },
  {
    Id: 15028,
    Code: '5419',
    Name: 'Stuffer, toy mfr'
  },
  {
    Id: 15029,
    Code: '5419',
    Name: 'Swabber'
  },
  {
    Id: 15030,
    Code: '5419',
    Name: 'Sweater, hat mfr'
  },
  {
    Id: 15031,
    Code: '5419',
    Name: 'Tacker, corset mfr'
  },
  {
    Id: 15032,
    Code: '5419',
    Name: 'Tailer, beret'
  },
  {
    Id: 15033,
    Code: '5419',
    Name: 'Taker-off, clothing mfr'
  },
  {
    Id: 15034,
    Code: '5419',
    Name: 'Tambourer, textile making-up'
  },
  {
    Id: 15035,
    Code: '5419',
    Name: 'Tender, loom'
  },
  {
    Id: 15036,
    Code: '5419',
    Name: 'Threader, draw'
  },
  {
    Id: 15037,
    Code: '5419',
    Name: 'Threader, embroidery mfr'
  },
  {
    Id: 15038,
    Code: '5419',
    Name: 'Tier, smash'
  },
  {
    Id: 15039,
    Code: '5419',
    Name: 'Topper, hosiery, knitwear mfr'
  },
  {
    Id: 15040,
    Code: '5419',
    Name: 'Topper and tailer'
  },
  {
    Id: 15041,
    Code: '5419',
    Name: 'Tracer, embroidery mfr'
  },
  {
    Id: 15042,
    Code: '5419',
    Name: 'Transferer, hosiery'
  },
  {
    Id: 15043,
    Code: '5419',
    Name: 'Trimmer, coffin'
  },
  {
    Id: 15044,
    Code: '5419',
    Name: 'Trimmer, embroidery mfr'
  },
  {
    Id: 15045,
    Code: '5419',
    Name: 'Trimmer, glove mfr'
  },
  {
    Id: 15046,
    Code: '5419',
    Name: 'Trimmer, hat mfr'
  },
  {
    Id: 15047,
    Code: '5419',
    Name: 'Trimmer, knitwear mfr'
  },
  {
    Id: 15048,
    Code: '5419',
    Name: 'Trimmer, powder puff mfr'
  },
  {
    Id: 15049,
    Code: '5419',
    Name: 'Tucker, clothing mfr'
  },
  {
    Id: 15050,
    Code: '5419',
    Name: 'Tufter, mattress, upholstery mfr'
  },
  {
    Id: 15051,
    Code: '5419',
    Name: 'Tufter, soft furnishings mfr'
  },
  {
    Id: 15052,
    Code: '5419',
    Name: 'Turner, collar'
  },
  {
    Id: 15053,
    Code: '5419',
    Name: 'Turner, glove'
  },
  {
    Id: 15054,
    Code: '5419',
    Name: 'Turner, canvas goods mfr'
  },
  {
    Id: 15055,
    Code: '5419',
    Name: 'Turner, clothing mfr'
  },
  {
    Id: 15056,
    Code: '5419',
    Name: 'Turner, hosiery, knitwear mfr'
  },
  {
    Id: 15057,
    Code: '5419',
    Name: 'Turner-down, glove mfr'
  },
  {
    Id: 15058,
    Code: '5419',
    Name: 'Velourer'
  },
  {
    Id: 15059,
    Code: '5419',
    Name: 'Weaver, asbestos'
  },
  {
    Id: 15060,
    Code: '5419',
    Name: 'Weaver, carpet'
  },
  {
    Id: 15061,
    Code: '5419',
    Name: 'Weaver, contour'
  },
  {
    Id: 15062,
    Code: '5419',
    Name: 'Weaver, lace'
  },
  {
    Id: 15063,
    Code: '5419',
    Name: 'Weaver, loom, pattern'
  },
  {
    Id: 15064,
    Code: '5419',
    Name: 'Weaver, pattern'
  },
  {
    Id: 15065,
    Code: '5419',
    Name: 'Weaver, textile'
  },
  {
    Id: 15066,
    Code: '5419',
    Name: 'Weaver'
  },
  {
    Id: 15067,
    Code: '5419',
    Name: 'Whaler, hosiery'
  },
  {
    Id: 15068,
    Code: '5419',
    Name: 'Worker, carpet'
  },
  {
    Id: 15069,
    Code: '5419',
    Name: 'Worker, crochet'
  },
  {
    Id: 15070,
    Code: '5419',
    Name: 'Worker, lace'
  },
  {
    Id: 15071,
    Code: '5419',
    Name: 'Worker, loom, hand'
  },
  {
    Id: 15072,
    Code: '5419',
    Name: 'Worker, straw, hat mfr'
  },
  {
    Id: 15073,
    Code: '5419',
    Name: 'Worker, table, textile mfr'
  },
  {
    Id: 15074,
    Code: '5419',
    Name: 'Worker, tapestry'
  },
  {
    Id: 15075,
    Code: '5421',
    Name: 'Artist, paste-up'
  },
  {
    Id: 15076,
    Code: '5421',
    Name: 'Assembler, mould, monotype'
  },
  {
    Id: 15077,
    Code: '5421',
    Name: 'Assembler, photo-lithographic plates mfr'
  },
  {
    Id: 15078,
    Code: '5421',
    Name: 'Assistant, lithographic'
  },
  {
    Id: 15079,
    Code: '5421',
    Name: "Assistant, stereotyper's"
  },
  {
    Id: 15080,
    Code: '5421',
    Name: 'Attendant, caster, monotype'
  },
  {
    Id: 15081,
    Code: '5421',
    Name: 'Caster, monotype'
  },
  {
    Id: 15082,
    Code: '5421',
    Name: 'Caster, printing'
  },
  {
    Id: 15083,
    Code: '5421',
    Name: 'Clicker, printing'
  },
  {
    Id: 15084,
    Code: '5421',
    Name: 'Compositor'
  },
  {
    Id: 15085,
    Code: '5421',
    Name: 'Designer, typographical'
  },
  {
    Id: 15086,
    Code: '5421',
    Name: 'Displayman, printing'
  },
  {
    Id: 15087,
    Code: '5421',
    Name: 'Draughtsman, lithographic'
  },
  {
    Id: 15088,
    Code: '5421',
    Name: "Draughtsman, printer's"
  },
  {
    Id: 15089,
    Code: '5421',
    Name: 'Electrotyper'
  },
  {
    Id: 15090,
    Code: '5421',
    Name: 'Engraver, bank-note'
  },
  {
    Id: 15091,
    Code: '5421',
    Name: 'Engraver, block, process'
  },
  {
    Id: 15092,
    Code: '5421',
    Name: 'Engraver, chemical'
  },
  {
    Id: 15093,
    Code: '5421',
    Name: 'Engraver, copper'
  },
  {
    Id: 15094,
    Code: '5421',
    Name: 'Engraver, die'
  },
  {
    Id: 15095,
    Code: '5421',
    Name: 'Engraver, hand, textile mfr'
  },
  {
    Id: 15096,
    Code: '5421',
    Name: 'Engraver, heraldic'
  },
  {
    Id: 15097,
    Code: '5421',
    Name: 'Engraver, line'
  },
  {
    Id: 15098,
    Code: '5421',
    Name: 'Engraver, machine'
  },
  {
    Id: 15099,
    Code: '5421',
    Name: 'Engraver, map'
  },
  {
    Id: 15100,
    Code: '5421',
    Name: 'Engraver, mark, stamp'
  },
  {
    Id: 15101,
    Code: '5421',
    Name: 'Engraver, music'
  },
  {
    Id: 15102,
    Code: '5421',
    Name: 'Engraver, pantograph, roller engraving'
  },
  {
    Id: 15103,
    Code: '5421',
    Name: 'Engraver, photo'
  },
  {
    Id: 15104,
    Code: '5421',
    Name: 'Engraver, photographic'
  },
  {
    Id: 15105,
    Code: '5421',
    Name: 'Engraver, photogravure'
  },
  {
    Id: 15106,
    Code: '5421',
    Name: 'Engraver, plate, copper'
  },
  {
    Id: 15107,
    Code: '5421',
    Name: "Engraver, potter's"
  },
  {
    Id: 15108,
    Code: '5421',
    Name: 'Engraver, process'
  },
  {
    Id: 15109,
    Code: '5421',
    Name: 'Engraver, punch'
  },
  {
    Id: 15110,
    Code: '5421',
    Name: 'Engraver, relief'
  },
  {
    Id: 15111,
    Code: '5421',
    Name: 'Engraver, roller'
  },
  {
    Id: 15112,
    Code: '5421',
    Name: 'Engraver, seal'
  },
  {
    Id: 15113,
    Code: '5421',
    Name: 'Engraver, transfer'
  },
  {
    Id: 15114,
    Code: '5421',
    Name: 'Engraver, Ordnance Survey'
  },
  {
    Id: 15115,
    Code: '5421',
    Name: 'Engraver, printing'
  },
  {
    Id: 15116,
    Code: '5421',
    Name: 'Engraver, textile printing'
  },
  {
    Id: 15117,
    Code: '5421',
    Name: 'Etcher, block, process'
  },
  {
    Id: 15118,
    Code: '5421',
    Name: 'Etcher, colour'
  },
  {
    Id: 15119,
    Code: '5421',
    Name: 'Etcher, copper, printing'
  },
  {
    Id: 15120,
    Code: '5421',
    Name: 'Etcher, deep'
  },
  {
    Id: 15121,
    Code: '5421',
    Name: 'Etcher, fine'
  },
  {
    Id: 15122,
    Code: '5421',
    Name: 'Etcher, line'
  },
  {
    Id: 15123,
    Code: '5421',
    Name: 'Etcher, photogravure'
  },
  {
    Id: 15124,
    Code: '5421',
    Name: 'Etcher, roller'
  },
  {
    Id: 15125,
    Code: '5421',
    Name: 'Etcher, rough'
  },
  {
    Id: 15126,
    Code: '5421',
    Name: 'Etcher, tone, half'
  },
  {
    Id: 15127,
    Code: '5421',
    Name: 'Etcher, printing'
  },
  {
    Id: 15128,
    Code: '5421',
    Name: 'Hand, box, printing'
  },
  {
    Id: 15129,
    Code: '5421',
    Name: 'Hand, imposition'
  },
  {
    Id: 15130,
    Code: '5421',
    Name: 'Hand, make-up'
  },
  {
    Id: 15131,
    Code: '5421',
    Name: 'Hand, plate, printing'
  },
  {
    Id: 15132,
    Code: '5421',
    Name: 'Hand, poster'
  },
  {
    Id: 15133,
    Code: '5421',
    Name: 'Hand, stone'
  },
  {
    Id: 15134,
    Code: '5421',
    Name: 'Hand, yardage'
  },
  {
    Id: 15135,
    Code: '5421',
    Name: "Impressioner, engraver's"
  },
  {
    Id: 15136,
    Code: '5421',
    Name: 'Layer, plate, printing'
  },
  {
    Id: 15137,
    Code: '5421',
    Name: "Layer, printer's, textile printing"
  },
  {
    Id: 15138,
    Code: '5421',
    Name: 'Lithographer, photo'
  },
  {
    Id: 15139,
    Code: '5421',
    Name: 'Machinist, casting, monotype'
  },
  {
    Id: 15140,
    Code: '5421',
    Name: 'Machinist, linotype'
  },
  {
    Id: 15141,
    Code: '5421',
    Name: 'Machinist, plate-backing, photographic plate mfr'
  },
  {
    Id: 15142,
    Code: '5421',
    Name: 'Maker, block, printing'
  },
  {
    Id: 15143,
    Code: '5421',
    Name: 'Maker, plate, lithographic'
  },
  {
    Id: 15144,
    Code: '5421',
    Name: 'Maker, plate'
  },
  {
    Id: 15145,
    Code: '5421',
    Name: 'Maker, print'
  },
  {
    Id: 15146,
    Code: '5421',
    Name: 'Maker-up, printing'
  },
  {
    Id: 15147,
    Code: '5421',
    Name: 'Man, lay-out, printing'
  },
  {
    Id: 15148,
    Code: '5421',
    Name: 'Manager, pre-press'
  },
  {
    Id: 15149,
    Code: '5421',
    Name: 'Measurer, paper pattern mfr'
  },
  {
    Id: 15150,
    Code: '5421',
    Name: 'Metaller, bronzing'
  },
  {
    Id: 15151,
    Code: '5421',
    Name: 'Moulder, wax'
  },
  {
    Id: 15152,
    Code: '5421',
    Name: 'Moulder, printing'
  },
  {
    Id: 15153,
    Code: '5421',
    Name: 'Operator, caster, monotype'
  },
  {
    Id: 15154,
    Code: '5421',
    Name: 'Operator, composer, IBM'
  },
  {
    Id: 15155,
    Code: '5421',
    Name: 'Operator, intertype'
  },
  {
    Id: 15156,
    Code: '5421',
    Name: 'Operator, keyboard, typesetting'
  },
  {
    Id: 15157,
    Code: '5421',
    Name: 'Operator, linotype'
  },
  {
    Id: 15158,
    Code: '5421',
    Name: 'Operator, ludlow'
  },
  {
    Id: 15159,
    Code: '5421',
    Name: 'Operator, make-up, display'
  },
  {
    Id: 15160,
    Code: '5421',
    Name: 'Operator, monotype'
  },
  {
    Id: 15161,
    Code: '5421',
    Name: 'Operator, pre-press, printing'
  },
  {
    Id: 15162,
    Code: '5421',
    Name: 'Operator, typographical'
  },
  {
    Id: 15163,
    Code: '5421',
    Name: 'Originator, print'
  },
  {
    Id: 15164,
    Code: '5421',
    Name: 'Originator, printing'
  },
  {
    Id: 15165,
    Code: '5421',
    Name: 'Pantographer, roller engraving'
  },
  {
    Id: 15166,
    Code: '5421',
    Name: 'Planer, stereotypes'
  },
  {
    Id: 15167,
    Code: '5421',
    Name: 'Planner, gravure'
  },
  {
    Id: 15168,
    Code: '5421',
    Name: 'Planner, litho'
  },
  {
    Id: 15169,
    Code: '5421',
    Name: 'Planner, lithographic'
  },
  {
    Id: 15170,
    Code: '5421',
    Name: 'Planner, page, publishing'
  },
  {
    Id: 15171,
    Code: '5421',
    Name: 'Planner, printing'
  },
  {
    Id: 15172,
    Code: '5421',
    Name: 'Preparer, lithographic, printing'
  },
  {
    Id: 15173,
    Code: '5421',
    Name: 'Preparer, paper'
  },
  {
    Id: 15174,
    Code: '5421',
    Name: 'Preparer, lithography'
  },
  {
    Id: 15175,
    Code: '5421',
    Name: 'Printer, carbon'
  },
  {
    Id: 15176,
    Code: '5421',
    Name: 'Printer, metal, process engraving'
  },
  {
    Id: 15177,
    Code: '5421',
    Name: 'Printer, originator'
  },
  {
    Id: 15178,
    Code: '5421',
    Name: 'Printer-compositor'
  },
  {
    Id: 15179,
    Code: '5421',
    Name: 'Printer-down'
  },
  {
    Id: 15180,
    Code: '5421',
    Name: 'Printer-to-metal'
  },
  {
    Id: 15181,
    Code: '5421',
    Name: 'Puller, proof'
  },
  {
    Id: 15182,
    Code: '5421',
    Name: 'Raiser, printing'
  },
  {
    Id: 15183,
    Code: '5421',
    Name: 'Reader-compositor'
  },
  {
    Id: 15184,
    Code: '5421',
    Name: 'Repairer, plate, printing'
  },
  {
    Id: 15185,
    Code: '5421',
    Name: "Reproducer, plan, printer's"
  },
  {
    Id: 15186,
    Code: '5421',
    Name: 'Setter, type'
  },
  {
    Id: 15187,
    Code: '5421',
    Name: 'Stereographer'
  },
  {
    Id: 15188,
    Code: '5421',
    Name: 'Stereotyper'
  },
  {
    Id: 15189,
    Code: '5421',
    Name: 'Stripper, process engraving'
  },
  {
    Id: 15190,
    Code: '5421',
    Name: 'Supervisor, typesetting'
  },
  {
    Id: 15191,
    Code: '5421',
    Name: 'Technician, pre-press'
  },
  {
    Id: 15192,
    Code: '5421',
    Name: 'Transferer, lithograph, printing'
  },
  {
    Id: 15193,
    Code: '5421',
    Name: 'Turner, process engraving'
  },
  {
    Id: 15194,
    Code: '5421',
    Name: 'Typist, vari'
  },
  {
    Id: 15195,
    Code: '5421',
    Name: 'Typographer'
  },
  {
    Id: 15196,
    Code: '5421',
    Name: 'Worker, photogravure'
  },
  {
    Id: 15197,
    Code: '5422',
    Name: 'Blocker, foil'
  },
  {
    Id: 15198,
    Code: '5422',
    Name: 'Blocker, gold'
  },
  {
    Id: 15199,
    Code: '5422',
    Name: "Bronzer, printer's"
  },
  {
    Id: 15200,
    Code: '5422',
    Name: 'Bronzer, printing'
  },
  {
    Id: 15201,
    Code: '5422',
    Name: 'Coordinator, print'
  },
  {
    Id: 15202,
    Code: '5422',
    Name: 'Decorator, wallpaper mfr'
  },
  {
    Id: 15203,
    Code: '5422',
    Name: 'Foreman, printing'
  },
  {
    Id: 15204,
    Code: '5422',
    Name: 'Foreman, room, machine, printing'
  },
  {
    Id: 15205,
    Code: '5422',
    Name: 'Foreman, shop, print'
  },
  {
    Id: 15206,
    Code: '5422',
    Name: 'Foreman, printing'
  },
  {
    Id: 15207,
    Code: '5422',
    Name: 'Foreman, publishing'
  },
  {
    Id: 15208,
    Code: '5422',
    Name: 'Guider, textile printing'
  },
  {
    Id: 15209,
    Code: '5422',
    Name: 'Machinist, bronzing'
  },
  {
    Id: 15210,
    Code: '5422',
    Name: 'Machinist, screen, silk'
  },
  {
    Id: 15211,
    Code: '5422',
    Name: 'Maker, mark'
  },
  {
    Id: 15212,
    Code: '5422',
    Name: 'Maker, screen, silk'
  },
  {
    Id: 15213,
    Code: '5422',
    Name: 'Maker, screen, textile printing'
  },
  {
    Id: 15214,
    Code: '5422',
    Name: 'Maker, stamp'
  },
  {
    Id: 15215,
    Code: '5422',
    Name: 'Maker, ticket'
  },
  {
    Id: 15216,
    Code: '5422',
    Name: 'Manager, print, screen'
  },
  {
    Id: 15217,
    Code: '5422',
    Name: 'Manager, print'
  },
  {
    Id: 15218,
    Code: '5422',
    Name: 'Manager, printing'
  },
  {
    Id: 15219,
    Code: '5422',
    Name: 'Manager, room, press'
  },
  {
    Id: 15220,
    Code: '5422',
    Name: 'Manager, room, print'
  },
  {
    Id: 15221,
    Code: '5422',
    Name: 'Minder, machine, litho'
  },
  {
    Id: 15222,
    Code: '5422',
    Name: 'Minder, platen, printing'
  },
  {
    Id: 15223,
    Code: '5422',
    Name: 'Operator, litho, offset'
  },
  {
    Id: 15224,
    Code: '5422',
    Name: 'Operator, lithographic'
  },
  {
    Id: 15225,
    Code: '5422',
    Name: 'Operator, Multilith'
  },
  {
    Id: 15226,
    Code: '5422',
    Name: 'Operator, offset'
  },
  {
    Id: 15227,
    Code: '5422',
    Name: 'Operator, process, printing'
  },
  {
    Id: 15228,
    Code: '5422',
    Name: 'Operator, screen, silk'
  },
  {
    Id: 15229,
    Code: '5422',
    Name: 'Printer, block'
  },
  {
    Id: 15230,
    Code: '5422',
    Name: 'Printer, bromide'
  },
  {
    Id: 15231,
    Code: '5422',
    Name: 'Printer, calico'
  },
  {
    Id: 15232,
    Code: '5422',
    Name: 'Printer, cloth'
  },
  {
    Id: 15233,
    Code: '5422',
    Name: 'Printer, colour'
  },
  {
    Id: 15234,
    Code: '5422',
    Name: 'Printer, contact'
  },
  {
    Id: 15235,
    Code: '5422',
    Name: 'Printer, dial'
  },
  {
    Id: 15236,
    Code: '5422',
    Name: 'Printer, digital'
  },
  {
    Id: 15237,
    Code: '5422',
    Name: 'Printer, embroidery'
  },
  {
    Id: 15238,
    Code: '5422',
    Name: 'Printer, fabric'
  },
  {
    Id: 15239,
    Code: '5422',
    Name: 'Printer, film'
  },
  {
    Id: 15240,
    Code: '5422',
    Name: 'Printer, flexographic'
  },
  {
    Id: 15241,
    Code: '5422',
    Name: 'Printer, general'
  },
  {
    Id: 15242,
    Code: '5422',
    Name: 'Printer, glass'
  },
  {
    Id: 15243,
    Code: '5422',
    Name: 'Printer, gold'
  },
  {
    Id: 15244,
    Code: '5422',
    Name: 'Printer, gravure'
  },
  {
    Id: 15245,
    Code: '5422',
    Name: 'Printer, hand'
  },
  {
    Id: 15246,
    Code: '5422',
    Name: 'Printer, hat'
  },
  {
    Id: 15247,
    Code: '5422',
    Name: 'Printer, label'
  },
  {
    Id: 15248,
    Code: '5422',
    Name: 'Printer, letter, bronze'
  },
  {
    Id: 15249,
    Code: '5422',
    Name: 'Printer, letterpress'
  },
  {
    Id: 15250,
    Code: '5422',
    Name: 'Printer, litho, offset'
  },
  {
    Id: 15251,
    Code: '5422',
    Name: 'Printer, litho'
  },
  {
    Id: 15252,
    Code: '5422',
    Name: 'Printer, lithographic, offset'
  },
  {
    Id: 15253,
    Code: '5422',
    Name: 'Printer, lithographic'
  },
  {
    Id: 15254,
    Code: '5422',
    Name: 'Printer, map'
  },
  {
    Id: 15255,
    Code: '5422',
    Name: 'Printer, master'
  },
  {
    Id: 15256,
    Code: '5422',
    Name: 'Printer, mat'
  },
  {
    Id: 15257,
    Code: '5422',
    Name: 'Printer, Multilith'
  },
  {
    Id: 15258,
    Code: '5422',
    Name: 'Printer, offset'
  },
  {
    Id: 15259,
    Code: '5422',
    Name: 'Printer, photographic'
  },
  {
    Id: 15260,
    Code: '5422',
    Name: 'Printer, plate, ceramics mfr'
  },
  {
    Id: 15261,
    Code: '5422',
    Name: 'Printer, press'
  },
  {
    Id: 15262,
    Code: '5422',
    Name: 'Printer, process'
  },
  {
    Id: 15263,
    Code: '5422',
    Name: 'Printer, sack'
  },
  {
    Id: 15264,
    Code: '5422',
    Name: 'Printer, screen, silk'
  },
  {
    Id: 15265,
    Code: '5422',
    Name: 'Printer, screen'
  },
  {
    Id: 15266,
    Code: '5422',
    Name: 'Printer, silver'
  },
  {
    Id: 15267,
    Code: '5422',
    Name: 'Printer, textile'
  },
  {
    Id: 15268,
    Code: '5422',
    Name: 'Printer, ticket, leaf, metal'
  },
  {
    Id: 15269,
    Code: '5422',
    Name: 'Printer, ticket'
  },
  {
    Id: 15270,
    Code: '5422',
    Name: 'Printer, title, film processing'
  },
  {
    Id: 15271,
    Code: '5422',
    Name: 'Printer, transfer'
  },
  {
    Id: 15272,
    Code: '5422',
    Name: 'Printer, wallpaper'
  },
  {
    Id: 15273,
    Code: '5422',
    Name: 'Printer, lithographic'
  },
  {
    Id: 15274,
    Code: '5422',
    Name: 'Printer, nos, screen printing'
  },
  {
    Id: 15275,
    Code: '5422',
    Name: 'Printer, nos'
  },
  {
    Id: 15276,
    Code: '5422',
    Name: 'Printer and stationer'
  },
  {
    Id: 15277,
    Code: '5422',
    Name: 'Screener, silk'
  },
  {
    Id: 15278,
    Code: '5422',
    Name: 'Specialist, document, digital'
  },
  {
    Id: 15279,
    Code: '5422',
    Name: 'Stationer, paper goods mfr'
  },
  {
    Id: 15280,
    Code: '5422',
    Name: 'Stationer and printer'
  },
  {
    Id: 15281,
    Code: '5422',
    Name: 'Supervisor, print'
  },
  {
    Id: 15282,
    Code: '5422',
    Name: 'Technician, print'
  },
  {
    Id: 15283,
    Code: '5422',
    Name: 'Worker, colour, printing'
  },
  {
    Id: 15284,
    Code: '5423',
    Name: 'Assembler, film'
  },
  {
    Id: 15285,
    Code: '5423',
    Name: 'Assembler, negative, films'
  },
  {
    Id: 15286,
    Code: '5423',
    Name: "Assistant, binder's"
  },
  {
    Id: 15287,
    Code: '5423',
    Name: 'Assistant, bindery'
  },
  {
    Id: 15288,
    Code: '5423',
    Name: "Assistant, bookbinder's"
  },
  {
    Id: 15289,
    Code: '5423',
    Name: 'Assistant, control, sensitometric'
  },
  {
    Id: 15290,
    Code: '5423',
    Name: 'Assistant, presentation, printing'
  },
  {
    Id: 15291,
    Code: '5423',
    Name: 'Assistant, process, printing'
  },
  {
    Id: 15292,
    Code: '5423',
    Name: 'Assistant, room, dark'
  },
  {
    Id: 15293,
    Code: '5423',
    Name: 'Assistant, warehouse, printing'
  },
  {
    Id: 15294,
    Code: '5423',
    Name: 'Binder, book'
  },
  {
    Id: 15295,
    Code: '5423',
    Name: 'Binder, leather'
  },
  {
    Id: 15296,
    Code: '5423',
    Name: "Binder, printer's"
  },
  {
    Id: 15297,
    Code: '5423',
    Name: "Binder, publisher's"
  },
  {
    Id: 15298,
    Code: '5423',
    Name: "Binder, stationer's"
  },
  {
    Id: 15299,
    Code: '5423',
    Name: 'Binder, vellum'
  },
  {
    Id: 15300,
    Code: '5423',
    Name: 'Binder, bookbinding'
  },
  {
    Id: 15301,
    Code: '5423',
    Name: 'Binder, printing'
  },
  {
    Id: 15302,
    Code: '5423',
    Name: 'Blocker, bookbinding'
  },
  {
    Id: 15303,
    Code: '5423',
    Name: 'Bookbinder'
  },
  {
    Id: 15304,
    Code: '5423',
    Name: 'Calculator, sensitometric'
  },
  {
    Id: 15305,
    Code: '5423',
    Name: 'Collator, printing'
  },
  {
    Id: 15306,
    Code: '5423',
    Name: 'Colourer, hand, picture postcard'
  },
  {
    Id: 15307,
    Code: '5423',
    Name: 'Colourer, print'
  },
  {
    Id: 15308,
    Code: '5423',
    Name: 'Colourer, wallpaper printing'
  },
  {
    Id: 15309,
    Code: '5423',
    Name: 'Colourist, photographic'
  },
  {
    Id: 15310,
    Code: '5423',
    Name: 'Colourist, postcard'
  },
  {
    Id: 15311,
    Code: '5423',
    Name: 'Copyist, braille'
  },
  {
    Id: 15312,
    Code: '5423',
    Name: 'Coverer, bookbinding'
  },
  {
    Id: 15313,
    Code: '5423',
    Name: 'Cutter, cloth, bookbinding'
  },
  {
    Id: 15314,
    Code: '5423',
    Name: 'Cutter, leather, bookbinding'
  },
  {
    Id: 15315,
    Code: '5423',
    Name: 'Cutter, bookbinding'
  },
  {
    Id: 15316,
    Code: '5423',
    Name: 'Developer, film'
  },
  {
    Id: 15317,
    Code: '5423',
    Name: 'Developer, photographic film processing'
  },
  {
    Id: 15318,
    Code: '5423',
    Name: 'Dryer, photographic film mfr'
  },
  {
    Id: 15319,
    Code: '5423',
    Name: 'Dryer, photographic film processing'
  },
  {
    Id: 15320,
    Code: '5423',
    Name: 'Duster, printing'
  },
  {
    Id: 15321,
    Code: '5423',
    Name: 'Edger, gilt'
  },
  {
    Id: 15322,
    Code: '5423',
    Name: 'Embosser, leather, bookbinding'
  },
  {
    Id: 15323,
    Code: '5423',
    Name: 'Embosser, printing'
  },
  {
    Id: 15324,
    Code: '5423',
    Name: 'Enlarger, films'
  },
  {
    Id: 15325,
    Code: '5423',
    Name: 'Finisher, book, printing'
  },
  {
    Id: 15326,
    Code: '5423',
    Name: 'Finisher, gold'
  },
  {
    Id: 15327,
    Code: '5423',
    Name: 'Finisher, photo'
  },
  {
    Id: 15328,
    Code: '5423',
    Name: 'Finisher, photographic'
  },
  {
    Id: 15329,
    Code: '5423',
    Name: 'Finisher, print'
  },
  {
    Id: 15330,
    Code: '5423',
    Name: "Finisher, printer's"
  },
  {
    Id: 15331,
    Code: '5423',
    Name: 'Finisher, bookbinding'
  },
  {
    Id: 15332,
    Code: '5423',
    Name: 'Finisher, printing'
  },
  {
    Id: 15333,
    Code: '5423',
    Name: 'Finisher, process engraving'
  },
  {
    Id: 15334,
    Code: '5423',
    Name: 'Folder, book'
  },
  {
    Id: 15335,
    Code: '5423',
    Name: 'Folder, bookbinding'
  },
  {
    Id: 15336,
    Code: '5423',
    Name: 'Folder, printing'
  },
  {
    Id: 15337,
    Code: '5423',
    Name: 'Folder, rag book mfr'
  },
  {
    Id: 15338,
    Code: '5423',
    Name: 'Foreman, calico printers'
  },
  {
    Id: 15339,
    Code: '5423',
    Name: 'Foreman, textile mfr: printing dept'
  },
  {
    Id: 15340,
    Code: '5423',
    Name: 'Forwarder, bookbinding'
  },
  {
    Id: 15341,
    Code: '5423',
    Name: 'Gatherer, bookbinding'
  },
  {
    Id: 15342,
    Code: '5423',
    Name: 'Gilder, bookbinding'
  },
  {
    Id: 15343,
    Code: '5423',
    Name: 'Gilder, printing'
  },
  {
    Id: 15344,
    Code: '5423',
    Name: 'Gilder, wallpaper mfr'
  },
  {
    Id: 15345,
    Code: '5423',
    Name: 'Grader, photographic film processing'
  },
  {
    Id: 15346,
    Code: '5423',
    Name: 'Grinder, printing'
  },
  {
    Id: 15347,
    Code: '5423',
    Name: 'Hand, dis'
  },
  {
    Id: 15348,
    Code: '5423',
    Name: 'Hand, general, bookbinding'
  },
  {
    Id: 15349,
    Code: '5423',
    Name: 'Handler, photographic film mfr'
  },
  {
    Id: 15350,
    Code: '5423',
    Name: 'Indexer, bookbinding'
  },
  {
    Id: 15351,
    Code: '5423',
    Name: 'Joiner, film'
  },
  {
    Id: 15352,
    Code: '5423',
    Name: 'Loader, cassette, cartridge, photographic film mfr'
  },
  {
    Id: 15353,
    Code: '5423',
    Name: 'Loader, film'
  },
  {
    Id: 15354,
    Code: '5423',
    Name: 'Machinist, binding, perfect'
  },
  {
    Id: 15355,
    Code: '5423',
    Name: 'Machinist, collotype'
  },
  {
    Id: 15356,
    Code: '5423',
    Name: 'Machinist, combining, textile printing'
  },
  {
    Id: 15357,
    Code: '5423',
    Name: 'Machinist, developing, film'
  },
  {
    Id: 15358,
    Code: '5423',
    Name: 'Machinist, duplicating, offset'
  },
  {
    Id: 15359,
    Code: '5423',
    Name: 'Machinist, embossing'
  },
  {
    Id: 15360,
    Code: '5423',
    Name: 'Machinist, printing, film'
  },
  {
    Id: 15361,
    Code: '5423',
    Name: 'Machinist, sewing, bookbinding'
  },
  {
    Id: 15362,
    Code: '5423',
    Name: 'Machinist, bookbinding'
  },
  {
    Id: 15363,
    Code: '5423',
    Name: 'Machinist, photographic film processing'
  },
  {
    Id: 15364,
    Code: '5423',
    Name: 'Maker, book, pattern'
  },
  {
    Id: 15365,
    Code: '5423',
    Name: 'Maker, book, pocket'
  },
  {
    Id: 15366,
    Code: '5423',
    Name: 'Maker, case, leather, bookbinding'
  },
  {
    Id: 15367,
    Code: '5423',
    Name: 'Man, table, bookbinding'
  },
  {
    Id: 15368,
    Code: '5423',
    Name: 'Manager, finishing, printing'
  },
  {
    Id: 15369,
    Code: '5423',
    Name: 'Marbler, paper'
  },
  {
    Id: 15370,
    Code: '5423',
    Name: 'Marbler, bookbinding'
  },
  {
    Id: 15371,
    Code: '5423',
    Name: "Mounter, photographer's"
  },
  {
    Id: 15372,
    Code: '5423',
    Name: 'Mounter, process'
  },
  {
    Id: 15373,
    Code: '5423',
    Name: 'Mounter, printing'
  },
  {
    Id: 15374,
    Code: '5423',
    Name: 'Numberer, bookbinding'
  },
  {
    Id: 15375,
    Code: '5423',
    Name: 'Pager, bookbinding'
  },
  {
    Id: 15376,
    Code: '5423',
    Name: 'Pager, printing'
  },
  {
    Id: 15377,
    Code: '5423',
    Name: 'Passer, proof, lithography'
  },
  {
    Id: 15378,
    Code: '5423',
    Name: 'Patcher, lithography'
  },
  {
    Id: 15379,
    Code: '5423',
    Name: 'Perforator, card, stationery mfr'
  },
  {
    Id: 15380,
    Code: '5423',
    Name: 'Perforator, pattern, paper dress pattern mfr'
  },
  {
    Id: 15381,
    Code: '5423',
    Name: 'Perforator, stamp'
  },
  {
    Id: 15382,
    Code: '5423',
    Name: 'Perforator, bookbinding'
  },
  {
    Id: 15383,
    Code: '5423',
    Name: 'Plater, bookbinding'
  },
  {
    Id: 15384,
    Code: '5423',
    Name: 'Presser, bookbinding'
  },
  {
    Id: 15385,
    Code: '5423',
    Name: 'Processor, film'
  },
  {
    Id: 15386,
    Code: '5423',
    Name: 'Processor, photographic'
  },
  {
    Id: 15387,
    Code: '5423',
    Name: 'Prover, colour, printing'
  },
  {
    Id: 15388,
    Code: '5423',
    Name: 'Prover, process, printing'
  },
  {
    Id: 15389,
    Code: '5423',
    Name: 'Prover, lithography'
  },
  {
    Id: 15390,
    Code: '5423',
    Name: 'Prover, Ordnance Survey'
  },
  {
    Id: 15391,
    Code: '5423',
    Name: 'Reeler, photographic film mfr'
  },
  {
    Id: 15392,
    Code: '5423',
    Name: 'Repairer, book'
  },
  {
    Id: 15393,
    Code: '5423',
    Name: 'Restorer, book'
  },
  {
    Id: 15394,
    Code: '5423',
    Name: 'Retoucher, colour'
  },
  {
    Id: 15395,
    Code: '5423',
    Name: 'Retoucher, photographic'
  },
  {
    Id: 15396,
    Code: '5423',
    Name: 'Retoucher, photolitho'
  },
  {
    Id: 15397,
    Code: '5423',
    Name: 'Retoucher, film processing'
  },
  {
    Id: 15398,
    Code: '5423',
    Name: 'Retoucher, printing'
  },
  {
    Id: 15399,
    Code: '5423',
    Name: 'Roller, bookbinding'
  },
  {
    Id: 15400,
    Code: '5423',
    Name: 'Sewer, vellum'
  },
  {
    Id: 15401,
    Code: '5423',
    Name: 'Sewer, bookbinding'
  },
  {
    Id: 15402,
    Code: '5423',
    Name: 'Splicer, film'
  },
  {
    Id: 15403,
    Code: '5423',
    Name: 'Spooler, photographic film mfr'
  },
  {
    Id: 15404,
    Code: '5423',
    Name: 'Spotter, film processing'
  },
  {
    Id: 15405,
    Code: '5423',
    Name: 'Spotter, printing'
  },
  {
    Id: 15406,
    Code: '5423',
    Name: 'Stitcher, bookbinding'
  },
  {
    Id: 15407,
    Code: '5423',
    Name: 'Stitcher, printing'
  },
  {
    Id: 15408,
    Code: '5423',
    Name: 'Supervisor, finishing, printing'
  },
  {
    Id: 15409,
    Code: '5423',
    Name: 'Synchroniser, film, television production'
  },
  {
    Id: 15410,
    Code: '5423',
    Name: 'Technician, darkroom'
  },
  {
    Id: 15411,
    Code: '5423',
    Name: 'Tinter, film processing'
  },
  {
    Id: 15412,
    Code: '5423',
    Name: 'Trimmer, bookbinding'
  },
  {
    Id: 15413,
    Code: '5423',
    Name: 'Washer, photographic film processing'
  },
  {
    Id: 15414,
    Code: '5423',
    Name: 'Worker, bindery'
  },
  {
    Id: 15415,
    Code: '5423',
    Name: 'Worker, copper, electro, textile printing'
  },
  {
    Id: 15416,
    Code: '5423',
    Name: 'Worker, film, colour'
  },
  {
    Id: 15417,
    Code: '5423',
    Name: 'Worker, process, film processing'
  },
  {
    Id: 15418,
    Code: '5431',
    Name: "Assistant, butcher's"
  },
  {
    Id: 15419,
    Code: '5431',
    Name: 'Blockman, butchers'
  },
  {
    Id: 15420,
    Code: '5431',
    Name: 'Boner, meat'
  },
  {
    Id: 15421,
    Code: '5431',
    Name: 'Butcher, master'
  },
  {
    Id: 15422,
    Code: '5431',
    Name: 'Butcher, retail'
  },
  {
    Id: 15423,
    Code: '5431',
    Name: 'Butcher'
  },
  {
    Id: 15424,
    Code: '5431',
    Name: 'Butcher-driver'
  },
  {
    Id: 15425,
    Code: '5431',
    Name: 'Butcher-manager'
  },
  {
    Id: 15426,
    Code: '5431',
    Name: 'Cleaner, meat'
  },
  {
    Id: 15427,
    Code: '5431',
    Name: 'Collector, blood'
  },
  {
    Id: 15428,
    Code: '5431',
    Name: 'Cutter, bacon'
  },
  {
    Id: 15429,
    Code: '5431',
    Name: "Cutter, butcher's"
  },
  {
    Id: 15430,
    Code: '5431',
    Name: 'Cutter, meat'
  },
  {
    Id: 15431,
    Code: '5431',
    Name: "Cutter, shopman, butcher's"
  },
  {
    Id: 15432,
    Code: '5431',
    Name: "Cutter, butcher's shop"
  },
  {
    Id: 15433,
    Code: '5431',
    Name: 'Dehairer, pig'
  },
  {
    Id: 15434,
    Code: '5431',
    Name: 'Dresser, meat'
  },
  {
    Id: 15435,
    Code: '5431',
    Name: 'Foreman, abattoir'
  },
  {
    Id: 15436,
    Code: '5431',
    Name: 'Foreman, lairage'
  },
  {
    Id: 15437,
    Code: '5431',
    Name: 'Foreman, lairage'
  },
  {
    Id: 15438,
    Code: '5431',
    Name: 'Gutman'
  },
  {
    Id: 15439,
    Code: '5431',
    Name: 'Hand, first, retail trade: butchers'
  },
  {
    Id: 15440,
    Code: '5431',
    Name: 'Killer'
  },
  {
    Id: 15441,
    Code: '5431',
    Name: 'Knacker'
  },
  {
    Id: 15442,
    Code: '5431',
    Name: 'Knackerman'
  },
  {
    Id: 15443,
    Code: '5431',
    Name: "Machinist, butcher's"
  },
  {
    Id: 15444,
    Code: '5431',
    Name: 'Machinist, chopping, meat'
  },
  {
    Id: 15445,
    Code: '5431',
    Name: 'Machinist, mincing, meat'
  },
  {
    Id: 15446,
    Code: '5431',
    Name: 'Manager, butchery'
  },
  {
    Id: 15447,
    Code: '5431',
    Name: 'Mincer, meat, sausage'
  },
  {
    Id: 15448,
    Code: '5431',
    Name: 'Operative, abattoir'
  },
  {
    Id: 15449,
    Code: '5431',
    Name: 'Operator, saw, circular, meat'
  },
  {
    Id: 15450,
    Code: '5431',
    Name: 'Processor, meat'
  },
  {
    Id: 15451,
    Code: '5431',
    Name: 'Roller, bacon'
  },
  {
    Id: 15452,
    Code: '5431',
    Name: 'Sawyer, meat'
  },
  {
    Id: 15453,
    Code: '5431',
    Name: 'Shecheta'
  },
  {
    Id: 15454,
    Code: '5431',
    Name: 'Shocket'
  },
  {
    Id: 15455,
    Code: '5431',
    Name: "Shopman, butcher's"
  },
  {
    Id: 15456,
    Code: '5431',
    Name: 'Shopman, meat'
  },
  {
    Id: 15457,
    Code: '5431',
    Name: "Shopman-cutter, butcher's"
  },
  {
    Id: 15458,
    Code: '5431',
    Name: 'Skinner, food products mfr'
  },
  {
    Id: 15459,
    Code: '5431',
    Name: 'Skinner, slaughterhouse'
  },
  {
    Id: 15460,
    Code: '5431',
    Name: 'Slaughterer'
  },
  {
    Id: 15461,
    Code: '5431',
    Name: 'Slaughterman'
  },
  {
    Id: 15462,
    Code: '5431',
    Name: 'Sticker, slaughterhouse'
  },
  {
    Id: 15463,
    Code: '5431',
    Name: 'Stunner'
  },
  {
    Id: 15464,
    Code: '5431',
    Name: 'Supervisor, butchery'
  },
  {
    Id: 15465,
    Code: '5431',
    Name: 'Supervisor, meat, abattoir'
  },
  {
    Id: 15466,
    Code: '5431',
    Name: 'Trimmer, meat'
  },
  {
    Id: 15467,
    Code: '5431',
    Name: 'Worker, abattoir'
  },
  {
    Id: 15468,
    Code: '5431',
    Name: 'Worker, house, slaughter'
  },
  {
    Id: 15469,
    Code: '5431',
    Name: 'Worker, process, slaughterhouse'
  },
  {
    Id: 15470,
    Code: '5431',
    Name: 'Worker, slaughterhouse'
  },
  {
    Id: 15471,
    Code: '5432',
    Name: 'Artist, cake'
  },
  {
    Id: 15472,
    Code: '5432',
    Name: "Assistant, baker's"
  },
  {
    Id: 15473,
    Code: '5432',
    Name: "Assistant, confectioner's, flour confectionery mfr"
  },
  {
    Id: 15474,
    Code: '5432',
    Name: 'Baker, master'
  },
  {
    Id: 15475,
    Code: '5432',
    Name: 'Baker, skilled'
  },
  {
    Id: 15476,
    Code: '5432',
    Name: 'Baker'
  },
  {
    Id: 15477,
    Code: '5432',
    Name: 'Baker and confectioner'
  },
  {
    Id: 15478,
    Code: '5432',
    Name: 'Boardman, bakery'
  },
  {
    Id: 15479,
    Code: '5432',
    Name: 'Confectioner, flour'
  },
  {
    Id: 15480,
    Code: '5432',
    Name: 'Confectioner'
  },
  {
    Id: 15481,
    Code: '5432',
    Name: 'Cook, pastry, bakery'
  },
  {
    Id: 15482,
    Code: '5432',
    Name: 'Decorator, cake'
  },
  {
    Id: 15483,
    Code: '5432',
    Name: 'Decorator, flour confectionery'
  },
  {
    Id: 15484,
    Code: '5432',
    Name: 'Designer, cake'
  },
  {
    Id: 15485,
    Code: '5432',
    Name: 'Designer, flour confectionery mfr'
  },
  {
    Id: 15486,
    Code: '5432',
    Name: 'Foreman, bakery'
  },
  {
    Id: 15487,
    Code: '5432',
    Name: 'Foreman, plant, bakery'
  },
  {
    Id: 15488,
    Code: '5432',
    Name: 'Foreman, bakery'
  },
  {
    Id: 15489,
    Code: '5432',
    Name: 'Foreman, flour confectionery mfr'
  },
  {
    Id: 15490,
    Code: '5432',
    Name: 'Hand, first, bakery'
  },
  {
    Id: 15491,
    Code: '5432',
    Name: 'Hand, pastry'
  },
  {
    Id: 15492,
    Code: '5432',
    Name: 'Maker, cake, flour confectionery mfr'
  },
  {
    Id: 15493,
    Code: '5432',
    Name: 'Maker, confectionery, flour confectionery'
  },
  {
    Id: 15494,
    Code: '5432',
    Name: 'Maker, flour confectionery mfr'
  },
  {
    Id: 15495,
    Code: '5432',
    Name: 'Man, table, bakery'
  },
  {
    Id: 15496,
    Code: '5432',
    Name: "Manager, baker's"
  },
  {
    Id: 15497,
    Code: '5432',
    Name: 'Manager, bakery'
  },
  {
    Id: 15498,
    Code: '5432',
    Name: 'Manufacturer, flour confectionery mfr'
  },
  {
    Id: 15499,
    Code: '5432',
    Name: 'Pastrycook, bakery'
  },
  {
    Id: 15500,
    Code: '5432',
    Name: 'Specialist, sugarcraft'
  },
  {
    Id: 15501,
    Code: '5432',
    Name: 'Superintendent, bakery'
  },
  {
    Id: 15502,
    Code: '5432',
    Name: 'Superintendent, biscuit mfr'
  },
  {
    Id: 15503,
    Code: '5432',
    Name: 'Superintendent, flour confectionery mfr'
  },
  {
    Id: 15504,
    Code: '5432',
    Name: 'Supervisor, bakery'
  },
  {
    Id: 15505,
    Code: '5433',
    Name: "Assistant, fishmonger's"
  },
  {
    Id: 15506,
    Code: '5433',
    Name: 'Blockman, fishmongers'
  },
  {
    Id: 15507,
    Code: '5433',
    Name: 'Boner, fish'
  },
  {
    Id: 15508,
    Code: '5433',
    Name: 'Butcher, fish, poultry'
  },
  {
    Id: 15509,
    Code: '5433',
    Name: 'Cleaner, fish'
  },
  {
    Id: 15510,
    Code: '5433',
    Name: 'Dealer, fish'
  },
  {
    Id: 15511,
    Code: '5433',
    Name: 'Dresser, bird, game'
  },
  {
    Id: 15512,
    Code: '5433',
    Name: 'Dresser, crab'
  },
  {
    Id: 15513,
    Code: '5433',
    Name: 'Dresser, poultry'
  },
  {
    Id: 15514,
    Code: '5433',
    Name: 'Eviscerator'
  },
  {
    Id: 15515,
    Code: '5433',
    Name: 'Filleter, fish'
  },
  {
    Id: 15516,
    Code: '5433',
    Name: 'Filleter, fish'
  },
  {
    Id: 15517,
    Code: '5433',
    Name: 'Fishmonger'
  },
  {
    Id: 15518,
    Code: '5433',
    Name: 'Gutter, fish'
  },
  {
    Id: 15519,
    Code: '5433',
    Name: 'Hand, poultry, food processing'
  },
  {
    Id: 15520,
    Code: '5433',
    Name: 'Header, fish'
  },
  {
    Id: 15521,
    Code: '5433',
    Name: 'Jointer, chicken'
  },
  {
    Id: 15522,
    Code: '5433',
    Name: 'Killer and plucker'
  },
  {
    Id: 15523,
    Code: '5433',
    Name: 'Monger, fish'
  },
  {
    Id: 15524,
    Code: '5433',
    Name: 'Nobber, fish'
  },
  {
    Id: 15525,
    Code: '5433',
    Name: 'Plucker, chicken'
  },
  {
    Id: 15526,
    Code: '5433',
    Name: 'Plucker, poultry dressing'
  },
  {
    Id: 15527,
    Code: '5433',
    Name: 'Poulterer'
  },
  {
    Id: 15528,
    Code: '5433',
    Name: 'Preparer, fish'
  },
  {
    Id: 15529,
    Code: '5433',
    Name: 'Processor, fish'
  },
  {
    Id: 15530,
    Code: '5433',
    Name: 'Processor, poultry'
  },
  {
    Id: 15531,
    Code: '5433',
    Name: 'Splitter, fish'
  },
  {
    Id: 15532,
    Code: '5433',
    Name: 'Sticker, poultry'
  },
  {
    Id: 15533,
    Code: '5433',
    Name: 'Trimmer, salmon'
  },
  {
    Id: 15534,
    Code: '5433',
    Name: 'Trusser, fowl'
  },
  {
    Id: 15535,
    Code: '5433',
    Name: 'Trusser, poultry dressing'
  },
  {
    Id: 15536,
    Code: '5433',
    Name: 'Worker, fish, food processing'
  },
  {
    Id: 15537,
    Code: '5433',
    Name: 'Worker, herring'
  },
  {
    Id: 15538,
    Code: '5433',
    Name: 'Worker, house, fish'
  },
  {
    Id: 15539,
    Code: '5433',
    Name: 'Worker, process, fish, poultry'
  },
  {
    Id: 15540,
    Code: '5434',
    Name: 'Bhandary'
  },
  {
    Id: 15541,
    Code: '5434',
    Name: 'Chef, commis'
  },
  {
    Id: 15542,
    Code: '5434',
    Name: 'Chef, development'
  },
  {
    Id: 15543,
    Code: '5434',
    Name: 'Chef, head'
  },
  {
    Id: 15544,
    Code: '5434',
    Name: 'Chef, pastry'
  },
  {
    Id: 15545,
    Code: '5434',
    Name: 'Chef, private'
  },
  {
    Id: 15546,
    Code: '5434',
    Name: 'Chef, sous'
  },
  {
    Id: 15547,
    Code: '5434',
    Name: 'Chef'
  },
  {
    Id: 15548,
    Code: '5434',
    Name: 'Chef de cuisine'
  },
  {
    Id: 15549,
    Code: '5434',
    Name: 'Chef de partie'
  },
  {
    Id: 15550,
    Code: '5434',
    Name: 'Chef-manager'
  },
  {
    Id: 15551,
    Code: '5434',
    Name: 'Garde-manger'
  },
  {
    Id: 15552,
    Code: '5434',
    Name: 'Manager, chef'
  },
  {
    Id: 15553,
    Code: '5434',
    Name: 'Owner-chef'
  },
  {
    Id: 15554,
    Code: '5434',
    Name: 'Stylist, food'
  },
  {
    Id: 15555,
    Code: '5435',
    Name: 'Controller, meals, school'
  },
  {
    Id: 15556,
    Code: '5435',
    Name: 'Cook, chief'
  },
  {
    Id: 15557,
    Code: '5435',
    Name: 'Cook, head'
  },
  {
    Id: 15558,
    Code: '5435',
    Name: 'Cook, pastry'
  },
  {
    Id: 15559,
    Code: '5435',
    Name: 'Cook'
  },
  {
    Id: 15560,
    Code: '5435',
    Name: 'Cook in charge'
  },
  {
    Id: 15561,
    Code: '5435',
    Name: 'Cook-cleaner'
  },
  {
    Id: 15562,
    Code: '5435',
    Name: 'Cook-companion'
  },
  {
    Id: 15563,
    Code: '5435',
    Name: 'Cook-general'
  },
  {
    Id: 15564,
    Code: '5435',
    Name: 'Cook-manager'
  },
  {
    Id: 15565,
    Code: '5435',
    Name: 'Cook-steward'
  },
  {
    Id: 15566,
    Code: '5435',
    Name: 'Cook-supervisor'
  },
  {
    Id: 15567,
    Code: '5435',
    Name: 'Fryer, fish'
  },
  {
    Id: 15568,
    Code: '5435',
    Name: 'Fryer, catering'
  },
  {
    Id: 15569,
    Code: '5435',
    Name: 'Griddler, catering'
  },
  {
    Id: 15570,
    Code: '5435',
    Name: 'Hand, grill'
  },
  {
    Id: 15571,
    Code: '5435',
    Name: 'Manager, cook'
  },
  {
    Id: 15572,
    Code: '5435',
    Name: 'Pastrycook'
  },
  {
    Id: 15573,
    Code: '5435',
    Name: 'Supervisor, cook'
  },
  {
    Id: 15574,
    Code: '5436',
    Name: 'Adviser, catering'
  },
  {
    Id: 15575,
    Code: '5436',
    Name: 'Caterer, airline'
  },
  {
    Id: 15576,
    Code: '5436',
    Name: 'Caterer'
  },
  {
    Id: 15577,
    Code: '5436',
    Name: 'Consultant, catering'
  },
  {
    Id: 15578,
    Code: '5436',
    Name: 'Contractor, catering'
  },
  {
    Id: 15579,
    Code: '5436',
    Name: 'Controller, catering'
  },
  {
    Id: 15580,
    Code: '5436',
    Name: 'Franchisee, catering'
  },
  {
    Id: 15581,
    Code: '5436',
    Name: 'Head of kitchen'
  },
  {
    Id: 15582,
    Code: '5436',
    Name: 'Keeper, stall, coffee'
  },
  {
    Id: 15583,
    Code: '5436',
    Name: 'Manager, bar'
  },
  {
    Id: 15584,
    Code: '5436',
    Name: 'Manager, breakfast'
  },
  {
    Id: 15585,
    Code: '5436',
    Name: 'Manager, canteen'
  },
  {
    Id: 15586,
    Code: '5436',
    Name: 'Manager, catering'
  },
  {
    Id: 15587,
    Code: '5436',
    Name: 'Manager, duty, bar'
  },
  {
    Id: 15588,
    Code: '5436',
    Name: 'Manager, duty, public house'
  },
  {
    Id: 15589,
    Code: '5436',
    Name: 'Manager, floor, restaurant'
  },
  {
    Id: 15590,
    Code: '5436',
    Name: 'Manager, food and beverage'
  },
  {
    Id: 15591,
    Code: '5436',
    Name: 'Manager, kitchen'
  },
  {
    Id: 15592,
    Code: '5436',
    Name: 'Manager, room, dining'
  },
  {
    Id: 15593,
    Code: '5436',
    Name: 'Manager, running, shift, fast food outlet'
  },
  {
    Id: 15594,
    Code: '5436',
    Name: 'Manager, shift, catering'
  },
  {
    Id: 15595,
    Code: '5436',
    Name: 'Manager, shift, fast food outlet'
  },
  {
    Id: 15596,
    Code: '5436',
    Name: 'Manager, shift, restaurant'
  },
  {
    Id: 15597,
    Code: '5436',
    Name: 'Manager, shift, take-away food shop'
  },
  {
    Id: 15598,
    Code: '5436',
    Name: 'Manager, unit, catering'
  },
  {
    Id: 15599,
    Code: '5436',
    Name: 'Officer, catering'
  },
  {
    Id: 15600,
    Code: '5436',
    Name: 'Steward, chief, club'
  },
  {
    Id: 15601,
    Code: '5436',
    Name: 'Steward, club'
  },
  {
    Id: 15602,
    Code: '5436',
    Name: 'Steward, club'
  },
  {
    Id: 15603,
    Code: '5436',
    Name: 'Steward, community centre'
  },
  {
    Id: 15604,
    Code: '5436',
    Name: "Steward, working men's institute"
  },
  {
    Id: 15605,
    Code: '5436',
    Name: 'Superintendent, kitchen, hospital service'
  },
  {
    Id: 15606,
    Code: '5436',
    Name: 'Superintendent, catering'
  },
  {
    Id: 15607,
    Code: '5441',
    Name: 'Aerographer'
  },
  {
    Id: 15608,
    Code: '5441',
    Name: 'Archman, glass'
  },
  {
    Id: 15609,
    Code: '5441',
    Name: 'Artist, ceramic'
  },
  {
    Id: 15610,
    Code: '5441',
    Name: 'Artist, glass, stained'
  },
  {
    Id: 15611,
    Code: '5441',
    Name: 'Artist, pottery'
  },
  {
    Id: 15612,
    Code: '5441',
    Name: 'Artist, ceramics decorating'
  },
  {
    Id: 15613,
    Code: '5441',
    Name: 'Artist, glass decorating'
  },
  {
    Id: 15614,
    Code: '5441',
    Name: "Assistant, maker's, crucible"
  },
  {
    Id: 15615,
    Code: '5441',
    Name: "Assistant, moulder's, abrasives mfr"
  },
  {
    Id: 15616,
    Code: '5441',
    Name: "Attendant, polisher's, glass"
  },
  {
    Id: 15617,
    Code: '5441',
    Name: 'Badger'
  },
  {
    Id: 15618,
    Code: '5441',
    Name: 'Bander, clay'
  },
  {
    Id: 15619,
    Code: '5441',
    Name: 'Bander, glass'
  },
  {
    Id: 15620,
    Code: '5441',
    Name: 'Bander, wash'
  },
  {
    Id: 15621,
    Code: '5441',
    Name: 'Bander, ceramics mfr'
  },
  {
    Id: 15622,
    Code: '5441',
    Name: 'Batter, pottery mfr'
  },
  {
    Id: 15623,
    Code: '5441',
    Name: 'Bender, glass'
  },
  {
    Id: 15624,
    Code: '5441',
    Name: 'Bender, tube, glass'
  },
  {
    Id: 15625,
    Code: '5441',
    Name: 'Bender, glass mfr'
  },
  {
    Id: 15626,
    Code: '5441',
    Name: 'Beveller, glass mfr'
  },
  {
    Id: 15627,
    Code: '5441',
    Name: 'Blacker, ceramics mfr'
  },
  {
    Id: 15628,
    Code: '5441',
    Name: 'Blaster, sand, ceramics mfr'
  },
  {
    Id: 15629,
    Code: '5441',
    Name: 'Blocker, lens'
  },
  {
    Id: 15630,
    Code: '5441',
    Name: 'Blocker, brick mfr'
  },
  {
    Id: 15631,
    Code: '5441',
    Name: 'Blocker, lens mfr'
  },
  {
    Id: 15632,
    Code: '5441',
    Name: 'Blower, bottle'
  },
  {
    Id: 15633,
    Code: '5441',
    Name: 'Blower, bulb, lamp, valve'
  },
  {
    Id: 15634,
    Code: '5441',
    Name: 'Blower, glass, scientific'
  },
  {
    Id: 15635,
    Code: '5441',
    Name: 'Blower, glass'
  },
  {
    Id: 15636,
    Code: '5441',
    Name: 'Blower, glaze'
  },
  {
    Id: 15637,
    Code: '5441',
    Name: 'Blower, thermometer'
  },
  {
    Id: 15638,
    Code: '5441',
    Name: 'Blower, ceramics mfr'
  },
  {
    Id: 15639,
    Code: '5441',
    Name: 'Blower, glass mfr'
  },
  {
    Id: 15640,
    Code: '5441',
    Name: 'Boy, post, glass mfr'
  },
  {
    Id: 15641,
    Code: '5441',
    Name: 'Boy, wire'
  },
  {
    Id: 15642,
    Code: '5441',
    Name: 'Breaker-off, glass mfr'
  },
  {
    Id: 15643,
    Code: '5441',
    Name: 'Brusher, glaze'
  },
  {
    Id: 15644,
    Code: '5441',
    Name: 'Brusher, sanitary'
  },
  {
    Id: 15645,
    Code: '5441',
    Name: 'Brusher, ceramics mfr'
  },
  {
    Id: 15646,
    Code: '5441',
    Name: 'Bumper, ceramics mfr'
  },
  {
    Id: 15647,
    Code: '5441',
    Name: 'Burner-off, glass mfr'
  },
  {
    Id: 15648,
    Code: '5441',
    Name: 'Burnisher, gold, ceramics mfr'
  },
  {
    Id: 15649,
    Code: '5441',
    Name: 'Burnisher, ceramics mfr'
  },
  {
    Id: 15650,
    Code: '5441',
    Name: 'Carboniser, nickel, valve mfr'
  },
  {
    Id: 15651,
    Code: '5441',
    Name: 'Caster, china'
  },
  {
    Id: 15652,
    Code: '5441',
    Name: 'Caster, hollow-ware, ceramics mfr'
  },
  {
    Id: 15653,
    Code: '5441',
    Name: 'Caster, sanitary'
  },
  {
    Id: 15654,
    Code: '5441',
    Name: 'Caster, statue'
  },
  {
    Id: 15655,
    Code: '5441',
    Name: 'Caster, plaster'
  },
  {
    Id: 15656,
    Code: '5441',
    Name: 'Caster, ceramics mfr'
  },
  {
    Id: 15657,
    Code: '5441',
    Name: 'Caster, glass mfr'
  },
  {
    Id: 15658,
    Code: '5441',
    Name: 'Cementer, ceramics mfr'
  },
  {
    Id: 15659,
    Code: '5441',
    Name: 'Centerer, lens mfr'
  },
  {
    Id: 15660,
    Code: '5441',
    Name: 'Centerer and edger, lens mfr'
  },
  {
    Id: 15661,
    Code: '5441',
    Name: 'Ceramicist'
  },
  {
    Id: 15662,
    Code: '5441',
    Name: 'Ceramist'
  },
  {
    Id: 15663,
    Code: '5441',
    Name: 'Chairman, glass mfr'
  },
  {
    Id: 15664,
    Code: '5441',
    Name: 'Chequerer'
  },
  {
    Id: 15665,
    Code: '5441',
    Name: 'Chipper, ceramics mfr'
  },
  {
    Id: 15666,
    Code: '5441',
    Name: 'Chummer-in, ceramics mfr'
  },
  {
    Id: 15667,
    Code: '5441',
    Name: 'Cleaner, ceramics mfr'
  },
  {
    Id: 15668,
    Code: '5441',
    Name: 'Coater, ceramics'
  },
  {
    Id: 15669,
    Code: '5441',
    Name: 'Cracker-off'
  },
  {
    Id: 15670,
    Code: '5441',
    Name: 'Cutter, brick'
  },
  {
    Id: 15671,
    Code: '5441',
    Name: 'Cutter, brilliant, glass mfr'
  },
  {
    Id: 15672,
    Code: '5441',
    Name: 'Cutter, diamond, glass mfr'
  },
  {
    Id: 15673,
    Code: '5441',
    Name: 'Cutter, glass, optical'
  },
  {
    Id: 15674,
    Code: '5441',
    Name: 'Cutter, lens'
  },
  {
    Id: 15675,
    Code: '5441',
    Name: 'Cutter, letter, glass'
  },
  {
    Id: 15676,
    Code: '5441',
    Name: 'Cutter, lustre, glass'
  },
  {
    Id: 15677,
    Code: '5441',
    Name: 'Cutter, plate, photographic film mfr'
  },
  {
    Id: 15678,
    Code: '5441',
    Name: 'Cutter, ring'
  },
  {
    Id: 15679,
    Code: '5441',
    Name: 'Cutter, stilt'
  },
  {
    Id: 15680,
    Code: '5441',
    Name: 'Cutter, tube, glass'
  },
  {
    Id: 15681,
    Code: '5441',
    Name: 'Cutter, ceramics mfr'
  },
  {
    Id: 15682,
    Code: '5441',
    Name: 'Cutter, glass mfr'
  },
  {
    Id: 15683,
    Code: '5441',
    Name: 'Cutter, lens mfr'
  },
  {
    Id: 15684,
    Code: '5441',
    Name: 'Decorator, aerograph'
  },
  {
    Id: 15685,
    Code: '5441',
    Name: 'Decorator, aerographing, ceramics mfr'
  },
  {
    Id: 15686,
    Code: '5441',
    Name: 'Decorator, ceramic'
  },
  {
    Id: 15687,
    Code: '5441',
    Name: 'Decorator, glass'
  },
  {
    Id: 15688,
    Code: '5441',
    Name: 'Decorator, slip'
  },
  {
    Id: 15689,
    Code: '5441',
    Name: 'Decorator, ceramics'
  },
  {
    Id: 15690,
    Code: '5441',
    Name: 'Dipper, automatic, ceramics mfr'
  },
  {
    Id: 15691,
    Code: '5441',
    Name: 'Dipper, machine, ceramics mfr'
  },
  {
    Id: 15692,
    Code: '5441',
    Name: 'Dipper, ceramics mfr'
  },
  {
    Id: 15693,
    Code: '5441',
    Name: 'Drawer, glass'
  },
  {
    Id: 15694,
    Code: '5441',
    Name: 'Drawer, glass mfr'
  },
  {
    Id: 15695,
    Code: '5441',
    Name: 'Dresser, brick, brick mfr'
  },
  {
    Id: 15696,
    Code: '5441',
    Name: 'Driller, ceramic'
  },
  {
    Id: 15697,
    Code: '5441',
    Name: 'Driller, glass'
  },
  {
    Id: 15698,
    Code: '5441',
    Name: 'Driller, porcelain'
  },
  {
    Id: 15699,
    Code: '5441',
    Name: 'Driller, pottery'
  },
  {
    Id: 15700,
    Code: '5441',
    Name: 'Duster, colour'
  },
  {
    Id: 15701,
    Code: '5441',
    Name: 'Duster, ceramics mfr'
  },
  {
    Id: 15702,
    Code: '5441',
    Name: 'Edger, ceramics mfr'
  },
  {
    Id: 15703,
    Code: '5441',
    Name: 'Edger, lens mfr'
  },
  {
    Id: 15704,
    Code: '5441',
    Name: 'Embosser, glass mfr'
  },
  {
    Id: 15705,
    Code: '5441',
    Name: 'Emptier, press, electrical'
  },
  {
    Id: 15706,
    Code: '5441',
    Name: 'Emptier, press, ceramics mfr'
  },
  {
    Id: 15707,
    Code: '5441',
    Name: 'Enameller, ceramics mfr'
  },
  {
    Id: 15708,
    Code: '5441',
    Name: 'Engineer, optical'
  },
  {
    Id: 15709,
    Code: '5441',
    Name: 'Engraver, glass'
  },
  {
    Id: 15710,
    Code: '5441',
    Name: 'Engraver, pattern, pottery'
  },
  {
    Id: 15711,
    Code: '5441',
    Name: 'Engraver, pottery'
  },
  {
    Id: 15712,
    Code: '5441',
    Name: 'Engraver, ceramics mfr'
  },
  {
    Id: 15713,
    Code: '5441',
    Name: 'Engraver, glass mfr'
  },
  {
    Id: 15714,
    Code: '5441',
    Name: 'Etcher, hand, glass'
  },
  {
    Id: 15715,
    Code: '5441',
    Name: 'Etcher, machine'
  },
  {
    Id: 15716,
    Code: '5441',
    Name: 'Etcher, ceramics mfr'
  },
  {
    Id: 15717,
    Code: '5441',
    Name: 'Etcher, glass mfr'
  },
  {
    Id: 15718,
    Code: '5441',
    Name: 'Extruder, ceramics'
  },
  {
    Id: 15719,
    Code: '5441',
    Name: 'Fettler, pipe, sanitary'
  },
  {
    Id: 15720,
    Code: '5441',
    Name: 'Fettler, ceramics mfr'
  },
  {
    Id: 15721,
    Code: '5441',
    Name: 'Filler, ceramics mfr'
  },
  {
    Id: 15722,
    Code: '5441',
    Name: 'Finisher, crucible, plumbago'
  },
  {
    Id: 15723,
    Code: '5441',
    Name: 'Finisher, glass'
  },
  {
    Id: 15724,
    Code: '5441',
    Name: 'Finisher, lens'
  },
  {
    Id: 15725,
    Code: '5441',
    Name: 'Finisher, pipe, ceramics mfr'
  },
  {
    Id: 15726,
    Code: '5441',
    Name: 'Finisher, pipe, clay tobacco pipe mfr'
  },
  {
    Id: 15727,
    Code: '5441',
    Name: 'Finisher, sanitary'
  },
  {
    Id: 15728,
    Code: '5441',
    Name: 'Finisher, ceramics mfr'
  },
  {
    Id: 15729,
    Code: '5441',
    Name: 'Finisher, glass mfr'
  },
  {
    Id: 15730,
    Code: '5441',
    Name: 'Finisher, plaster cast mfr'
  },
  {
    Id: 15731,
    Code: '5441',
    Name: 'Finisher, refractory goods mfr'
  },
  {
    Id: 15732,
    Code: '5441',
    Name: 'Flasher'
  },
  {
    Id: 15733,
    Code: '5441',
    Name: 'Flattener, glass mfr'
  },
  {
    Id: 15734,
    Code: '5441',
    Name: 'Flatter, glass mfr'
  },
  {
    Id: 15735,
    Code: '5441',
    Name: 'Foreman, batch'
  },
  {
    Id: 15736,
    Code: '5441',
    Name: 'Foreman, house, glass, glass mfr'
  },
  {
    Id: 15737,
    Code: '5441',
    Name: 'Foreman, shed, press, brick mfr'
  },
  {
    Id: 15738,
    Code: '5441',
    Name: 'Foreman, shop, cutting, glass mfr'
  },
  {
    Id: 15739,
    Code: '5441',
    Name: 'Foreman, shop, ceramics mfr'
  },
  {
    Id: 15740,
    Code: '5441',
    Name: 'Foreman, yard, brick'
  },
  {
    Id: 15741,
    Code: '5441',
    Name: 'Foreman, brick mfr'
  },
  {
    Id: 15742,
    Code: '5441',
    Name: 'Foreman, ceramics mfr'
  },
  {
    Id: 15743,
    Code: '5441',
    Name: 'Foreman, glass mfr'
  },
  {
    Id: 15744,
    Code: '5441',
    Name: 'Former, glass'
  },
  {
    Id: 15745,
    Code: '5441',
    Name: 'Froster, electric lamp mfr'
  },
  {
    Id: 15746,
    Code: '5441',
    Name: 'Froster, glass mfr'
  },
  {
    Id: 15747,
    Code: '5441',
    Name: 'Gaffer, glass mfr'
  },
  {
    Id: 15748,
    Code: '5441',
    Name: 'Gatherer, glass mfr'
  },
  {
    Id: 15749,
    Code: '5441',
    Name: 'Gauger, glass mfr'
  },
  {
    Id: 15750,
    Code: '5441',
    Name: 'Gilder, ceramics mfr'
  },
  {
    Id: 15751,
    Code: '5441',
    Name: 'Glazer, button'
  },
  {
    Id: 15752,
    Code: '5441',
    Name: 'Glazer, lens'
  },
  {
    Id: 15753,
    Code: '5441',
    Name: 'Glazer, optical'
  },
  {
    Id: 15754,
    Code: '5441',
    Name: 'Glazer, pottery'
  },
  {
    Id: 15755,
    Code: '5441',
    Name: 'Glazer, ceramics mfr'
  },
  {
    Id: 15756,
    Code: '5441',
    Name: 'Glazer, glass mfr'
  },
  {
    Id: 15757,
    Code: '5441',
    Name: 'Glazier, lens'
  },
  {
    Id: 15758,
    Code: '5441',
    Name: 'Glazier, optical'
  },
  {
    Id: 15759,
    Code: '5441',
    Name: 'Grinder, flat, glass'
  },
  {
    Id: 15760,
    Code: '5441',
    Name: 'Grinder, glass'
  },
  {
    Id: 15761,
    Code: '5441',
    Name: 'Grinder, lens'
  },
  {
    Id: 15762,
    Code: '5441',
    Name: 'Grinder, optical'
  },
  {
    Id: 15763,
    Code: '5441',
    Name: 'Grinder, slab, optical'
  },
  {
    Id: 15764,
    Code: '5441',
    Name: 'Grinder, stopper, glass'
  },
  {
    Id: 15765,
    Code: '5441',
    Name: 'Grinder, surface, glass mfr'
  },
  {
    Id: 15766,
    Code: '5441',
    Name: 'Grinder, wheel, emery, glass mfr'
  },
  {
    Id: 15767,
    Code: '5441',
    Name: 'Grinder, ceramics mfr'
  },
  {
    Id: 15768,
    Code: '5441',
    Name: 'Grinder, glass mfr'
  },
  {
    Id: 15769,
    Code: '5441',
    Name: 'Grinder and polisher, optical goods mfr'
  },
  {
    Id: 15770,
    Code: '5441',
    Name: 'Hand, bottle'
  },
  {
    Id: 15771,
    Code: '5441',
    Name: 'Hand, prism'
  },
  {
    Id: 15772,
    Code: '5441',
    Name: 'Jiggerer, ceramics mfr'
  },
  {
    Id: 15773,
    Code: '5441',
    Name: 'Jointer, pipe, stoneware pipe mfr'
  },
  {
    Id: 15774,
    Code: '5441',
    Name: 'Jointer, stanford'
  },
  {
    Id: 15775,
    Code: '5441',
    Name: 'Jointer, ceramics mfr'
  },
  {
    Id: 15776,
    Code: '5441',
    Name: 'Jointer, stoneware pipe mfr'
  },
  {
    Id: 15777,
    Code: '5441',
    Name: 'Jollier'
  },
  {
    Id: 15778,
    Code: '5441',
    Name: 'Knocker, ceramics mfr'
  },
  {
    Id: 15779,
    Code: '5441',
    Name: 'Knocker-off, glass mfr'
  },
  {
    Id: 15780,
    Code: '5441',
    Name: 'Knocker-out, glass mfr'
  },
  {
    Id: 15781,
    Code: '5441',
    Name: 'Labeller, colour, glassware'
  },
  {
    Id: 15782,
    Code: '5441',
    Name: 'Ladler, glass mfr'
  },
  {
    Id: 15783,
    Code: '5441',
    Name: 'Laminator, safety glass mfr'
  },
  {
    Id: 15784,
    Code: '5441',
    Name: 'Lather, ceramics mfr'
  },
  {
    Id: 15785,
    Code: '5441',
    Name: 'Layer, ground'
  },
  {
    Id: 15786,
    Code: '5441',
    Name: 'Liner, gold, ceramics mfr'
  },
  {
    Id: 15787,
    Code: '5441',
    Name: 'Liner, tube'
  },
  {
    Id: 15788,
    Code: '5441',
    Name: 'Liner, lenses'
  },
  {
    Id: 15789,
    Code: '5441',
    Name: 'Liner, ceramics mfr'
  },
  {
    Id: 15790,
    Code: '5441',
    Name: 'Lipper, glass'
  },
  {
    Id: 15791,
    Code: '5441',
    Name: 'Lithographer, ceramics mfr'
  },
  {
    Id: 15792,
    Code: '5441',
    Name: 'Lustrer'
  },
  {
    Id: 15793,
    Code: '5441',
    Name: 'Maker, appliance, sanitary'
  },
  {
    Id: 15794,
    Code: '5441',
    Name: 'Maker, ball, glass mfr'
  },
  {
    Id: 15795,
    Code: '5441',
    Name: 'Maker, basin'
  },
  {
    Id: 15796,
    Code: '5441',
    Name: 'Maker, basket, ornamental, ceramics'
  },
  {
    Id: 15797,
    Code: '5441',
    Name: 'Maker, bat, clay'
  },
  {
    Id: 15798,
    Code: '5441',
    Name: 'Maker, block, ceramics mfr'
  },
  {
    Id: 15799,
    Code: '5441',
    Name: 'Maker, bottle, stoneware'
  },
  {
    Id: 15800,
    Code: '5441',
    Name: 'Maker, bottle'
  },
  {
    Id: 15801,
    Code: '5441',
    Name: 'Maker, bowl, ceramics mfr'
  },
  {
    Id: 15802,
    Code: '5441',
    Name: 'Maker, brick'
  },
  {
    Id: 15803,
    Code: '5441',
    Name: 'Maker, cast, plaster'
  },
  {
    Id: 15804,
    Code: '5441',
    Name: 'Maker, ceramic'
  },
  {
    Id: 15805,
    Code: '5441',
    Name: 'Maker, cover, ceramics mfr'
  },
  {
    Id: 15806,
    Code: '5441',
    Name: 'Maker, crank'
  },
  {
    Id: 15807,
    Code: '5441',
    Name: 'Maker, crucible'
  },
  {
    Id: 15808,
    Code: '5441',
    Name: 'Maker, cup'
  },
  {
    Id: 15809,
    Code: '5441',
    Name: 'Maker, dish'
  },
  {
    Id: 15810,
    Code: '5441',
    Name: 'Maker, eye, artificial, glass'
  },
  {
    Id: 15811,
    Code: '5441',
    Name: 'Maker, figure'
  },
  {
    Id: 15812,
    Code: '5441',
    Name: 'Maker, flange, electric lamp mfr'
  },
  {
    Id: 15813,
    Code: '5441',
    Name: 'Maker, flower, ceramics mfr'
  },
  {
    Id: 15814,
    Code: '5441',
    Name: 'Maker, foot'
  },
  {
    Id: 15815,
    Code: '5441',
    Name: 'Maker, form, ceramics mfr'
  },
  {
    Id: 15816,
    Code: '5441',
    Name: 'Maker, glass'
  },
  {
    Id: 15817,
    Code: '5441',
    Name: 'Maker, handle, ceramics mfr'
  },
  {
    Id: 15818,
    Code: '5441',
    Name: 'Maker, hollow-ware, ceramics mfr'
  },
  {
    Id: 15819,
    Code: '5441',
    Name: 'Maker, insulator, ceramics'
  },
  {
    Id: 15820,
    Code: '5441',
    Name: 'Maker, jar, ceramics'
  },
  {
    Id: 15821,
    Code: '5441',
    Name: 'Maker, lens'
  },
  {
    Id: 15822,
    Code: '5441',
    Name: 'Maker, mirror'
  },
  {
    Id: 15823,
    Code: '5441',
    Name: 'Maker, model, plaster'
  },
  {
    Id: 15824,
    Code: '5441',
    Name: 'Maker, model, ceramics mfr'
  },
  {
    Id: 15825,
    Code: '5441',
    Name: 'Maker, mould, plaster, plumbago crucible mfr'
  },
  {
    Id: 15826,
    Code: '5441',
    Name: 'Maker, mould, ceramics mfr'
  },
  {
    Id: 15827,
    Code: '5441',
    Name: 'Maker, oddstuff'
  },
  {
    Id: 15828,
    Code: '5441',
    Name: 'Maker, pantile'
  },
  {
    Id: 15829,
    Code: '5441',
    Name: 'Maker, pattern, ceramics mfr'
  },
  {
    Id: 15830,
    Code: '5441',
    Name: 'Maker, pin, ceramics mfr'
  },
  {
    Id: 15831,
    Code: '5441',
    Name: 'Maker, pipe, clay'
  },
  {
    Id: 15832,
    Code: '5441',
    Name: 'Maker, pipe, drain'
  },
  {
    Id: 15833,
    Code: '5441',
    Name: 'Maker, pipe, sanitary'
  },
  {
    Id: 15834,
    Code: '5441',
    Name: 'Maker, pipe, ceramics mfr'
  },
  {
    Id: 15835,
    Code: '5441',
    Name: 'Maker, pipe, zinc refining'
  },
  {
    Id: 15836,
    Code: '5441',
    Name: 'Maker, plaque'
  },
  {
    Id: 15837,
    Code: '5441',
    Name: 'Maker, plate, ceramics mfr'
  },
  {
    Id: 15838,
    Code: '5441',
    Name: 'Maker, pot'
  },
  {
    Id: 15839,
    Code: '5441',
    Name: 'Maker, pottery'
  },
  {
    Id: 15840,
    Code: '5441',
    Name: 'Maker, prism'
  },
  {
    Id: 15841,
    Code: '5441',
    Name: 'Maker, radiant, fire, gas'
  },
  {
    Id: 15842,
    Code: '5441',
    Name: 'Maker, ridge'
  },
  {
    Id: 15843,
    Code: '5441',
    Name: 'Maker, saddle, ceramics mfr'
  },
  {
    Id: 15844,
    Code: '5441',
    Name: 'Maker, saggar'
  },
  {
    Id: 15845,
    Code: '5441',
    Name: 'Maker, sagger'
  },
  {
    Id: 15846,
    Code: '5441',
    Name: 'Maker, saucer'
  },
  {
    Id: 15847,
    Code: '5441',
    Name: 'Maker, screen, wind'
  },
  {
    Id: 15848,
    Code: '5441',
    Name: 'Maker, sink, ceramics'
  },
  {
    Id: 15849,
    Code: '5441',
    Name: 'Maker, slab, ceramics mfr'
  },
  {
    Id: 15850,
    Code: '5441',
    Name: 'Maker, stopper, glass mfr'
  },
  {
    Id: 15851,
    Code: '5441',
    Name: 'Maker, stopper, steelworks'
  },
  {
    Id: 15852,
    Code: '5441',
    Name: 'Maker, teapot'
  },
  {
    Id: 15853,
    Code: '5441',
    Name: 'Maker, thermometer'
  },
  {
    Id: 15854,
    Code: '5441',
    Name: 'Maker, thimble, ceramics mfr'
  },
  {
    Id: 15855,
    Code: '5441',
    Name: 'Maker, tile'
  },
  {
    Id: 15856,
    Code: '5441',
    Name: 'Maker, tube, glass'
  },
  {
    Id: 15857,
    Code: '5441',
    Name: 'Maker, tubing, glass'
  },
  {
    Id: 15858,
    Code: '5441',
    Name: 'Man, balcony'
  },
  {
    Id: 15859,
    Code: '5441',
    Name: 'Man, cut-off, glass mfr'
  },
  {
    Id: 15860,
    Code: '5441',
    Name: 'Man, disc'
  },
  {
    Id: 15861,
    Code: '5441',
    Name: 'Man, muffle, glass mfr'
  },
  {
    Id: 15862,
    Code: '5441',
    Name: 'Manager, prescription, glass mfr'
  },
  {
    Id: 15863,
    Code: '5441',
    Name: 'Manipulator, glass'
  },
  {
    Id: 15864,
    Code: '5441',
    Name: 'Manufacturer, ceramics'
  },
  {
    Id: 15865,
    Code: '5441',
    Name: 'Manufacturer, glass'
  },
  {
    Id: 15866,
    Code: '5441',
    Name: 'Manufacturer, ceramics mfr'
  },
  {
    Id: 15867,
    Code: '5441',
    Name: 'Manufacturer, glass mfr'
  },
  {
    Id: 15868,
    Code: '5441',
    Name: 'Marker-out, glass mfr'
  },
  {
    Id: 15869,
    Code: '5441',
    Name: 'Marker-up, lens'
  },
  {
    Id: 15870,
    Code: '5441',
    Name: 'Mason, fireclay'
  },
  {
    Id: 15871,
    Code: '5441',
    Name: 'Mason, ware, sanitary'
  },
  {
    Id: 15872,
    Code: '5441',
    Name: 'Mechanic, optical'
  },
  {
    Id: 15873,
    Code: '5441',
    Name: 'Melter, glass mfr'
  },
  {
    Id: 15874,
    Code: '5441',
    Name: 'Miller, optical'
  },
  {
    Id: 15875,
    Code: '5441',
    Name: 'Modeller, clay'
  },
  {
    Id: 15876,
    Code: '5441',
    Name: 'Modeller, glass'
  },
  {
    Id: 15877,
    Code: '5441',
    Name: 'Modeller, plaster'
  },
  {
    Id: 15878,
    Code: '5441',
    Name: 'Modeller, ceramics mfr'
  },
  {
    Id: 15879,
    Code: '5441',
    Name: 'Mottler, ceramics mfr'
  },
  {
    Id: 15880,
    Code: '5441',
    Name: 'Moulder, bottle'
  },
  {
    Id: 15881,
    Code: '5441',
    Name: 'Moulder, brick'
  },
  {
    Id: 15882,
    Code: '5441',
    Name: 'Moulder, clay'
  },
  {
    Id: 15883,
    Code: '5441',
    Name: 'Moulder, faience'
  },
  {
    Id: 15884,
    Code: '5441',
    Name: 'Moulder, fireclay'
  },
  {
    Id: 15885,
    Code: '5441',
    Name: 'Moulder, furnace'
  },
  {
    Id: 15886,
    Code: '5441',
    Name: 'Moulder, hand, ceramics mfr'
  },
  {
    Id: 15887,
    Code: '5441',
    Name: 'Moulder, hand, plumbago crucible mfr'
  },
  {
    Id: 15888,
    Code: '5441',
    Name: 'Moulder, lens'
  },
  {
    Id: 15889,
    Code: '5441',
    Name: 'Moulder, pipe, clay'
  },
  {
    Id: 15890,
    Code: '5441',
    Name: 'Moulder, plaster'
  },
  {
    Id: 15891,
    Code: '5441',
    Name: 'Moulder, tile, hand, ceramics'
  },
  {
    Id: 15892,
    Code: '5441',
    Name: 'Moulder, ceramics mfr'
  },
  {
    Id: 15893,
    Code: '5441',
    Name: 'Moulder, glass mfr'
  },
  {
    Id: 15894,
    Code: '5441',
    Name: 'Notcher, glassware'
  },
  {
    Id: 15895,
    Code: '5441',
    Name: 'Opener, piece, glass mfr'
  },
  {
    Id: 15896,
    Code: '5441',
    Name: 'Optician, manufacturing'
  },
  {
    Id: 15897,
    Code: '5441',
    Name: 'Ornamenter, ceramics mfr'
  },
  {
    Id: 15898,
    Code: '5441',
    Name: 'Painter, craft'
  },
  {
    Id: 15899,
    Code: '5441',
    Name: 'Painter, enamel'
  },
  {
    Id: 15900,
    Code: '5441',
    Name: 'Painter, flower'
  },
  {
    Id: 15901,
    Code: '5441',
    Name: 'Painter, freehand'
  },
  {
    Id: 15902,
    Code: '5441',
    Name: 'Painter, glaze'
  },
  {
    Id: 15903,
    Code: '5441',
    Name: 'Painter, pottery'
  },
  {
    Id: 15904,
    Code: '5441',
    Name: 'Painter, rough, glass mfr'
  },
  {
    Id: 15905,
    Code: '5441',
    Name: 'Painter, slip'
  },
  {
    Id: 15906,
    Code: '5441',
    Name: 'Painter, spray, ceramics mfr'
  },
  {
    Id: 15907,
    Code: '5441',
    Name: 'Painter, underglaze'
  },
  {
    Id: 15908,
    Code: '5441',
    Name: 'Painter, ceramics mfr'
  },
  {
    Id: 15909,
    Code: '5441',
    Name: 'Painter, glass etching'
  },
  {
    Id: 15910,
    Code: '5441',
    Name: 'Pantographer, glass mfr'
  },
  {
    Id: 15911,
    Code: '5441',
    Name: 'Piercer, ceramics mfr'
  },
  {
    Id: 15912,
    Code: '5441',
    Name: 'Pinner, ceramics mfr'
  },
  {
    Id: 15913,
    Code: '5441',
    Name: 'Pitcher and malletter'
  },
  {
    Id: 15914,
    Code: '5441',
    Name: 'Plasterer, plaster cast mfr'
  },
  {
    Id: 15915,
    Code: '5441',
    Name: 'Polisher, edge'
  },
  {
    Id: 15916,
    Code: '5441',
    Name: 'Polisher, enamel'
  },
  {
    Id: 15917,
    Code: '5441',
    Name: 'Polisher, fine, glass'
  },
  {
    Id: 15918,
    Code: '5441',
    Name: 'Polisher, glass'
  },
  {
    Id: 15919,
    Code: '5441',
    Name: 'Polisher, glost'
  },
  {
    Id: 15920,
    Code: '5441',
    Name: 'Polisher, gold, ceramics mfr'
  },
  {
    Id: 15921,
    Code: '5441',
    Name: 'Polisher, hand, glass mfr'
  },
  {
    Id: 15922,
    Code: '5441',
    Name: 'Polisher, lens'
  },
  {
    Id: 15923,
    Code: '5441',
    Name: 'Polisher, slab, optical'
  },
  {
    Id: 15924,
    Code: '5441',
    Name: 'Polisher, tile, ceramics mfr'
  },
  {
    Id: 15925,
    Code: '5441',
    Name: 'Polisher, ceramics mfr'
  },
  {
    Id: 15926,
    Code: '5441',
    Name: 'Polisher, glass mfr'
  },
  {
    Id: 15927,
    Code: '5441',
    Name: 'Potter, clay'
  },
  {
    Id: 15928,
    Code: '5441',
    Name: 'Potter, ceramics mfr'
  },
  {
    Id: 15929,
    Code: '5441',
    Name: 'Potter, zinc refining'
  },
  {
    Id: 15930,
    Code: '5441',
    Name: 'Preparer, ceramics mfr'
  },
  {
    Id: 15931,
    Code: '5441',
    Name: 'Preparer, glass mfr'
  },
  {
    Id: 15932,
    Code: '5441',
    Name: 'Presser, brick'
  },
  {
    Id: 15933,
    Code: '5441',
    Name: 'Presser, die'
  },
  {
    Id: 15934,
    Code: '5441',
    Name: 'Presser, dust, ceramics mfr'
  },
  {
    Id: 15935,
    Code: '5441',
    Name: 'Presser, flat, ceramics mfr'
  },
  {
    Id: 15936,
    Code: '5441',
    Name: 'Presser, glass'
  },
  {
    Id: 15937,
    Code: '5441',
    Name: 'Presser, hollow-ware'
  },
  {
    Id: 15938,
    Code: '5441',
    Name: 'Presser, hot, ceramics mfr'
  },
  {
    Id: 15939,
    Code: '5441',
    Name: 'Presser, pottery'
  },
  {
    Id: 15940,
    Code: '5441',
    Name: 'Presser, sagger'
  },
  {
    Id: 15941,
    Code: '5441',
    Name: 'Presser, tile, ceramics mfr'
  },
  {
    Id: 15942,
    Code: '5441',
    Name: 'Presser, ceramics mfr'
  },
  {
    Id: 15943,
    Code: '5441',
    Name: 'Presser, glass mfr'
  },
  {
    Id: 15944,
    Code: '5441',
    Name: 'Puncher, zinc smelting'
  },
  {
    Id: 15945,
    Code: '5441',
    Name: 'Remoulder, glass mfr'
  },
  {
    Id: 15946,
    Code: '5441',
    Name: 'Repairer, china'
  },
  {
    Id: 15947,
    Code: '5441',
    Name: 'Repairer, glass'
  },
  {
    Id: 15948,
    Code: '5441',
    Name: 'Repairer, ceramics mfr'
  },
  {
    Id: 15949,
    Code: '5441',
    Name: 'Restorer, porcelain'
  },
  {
    Id: 15950,
    Code: '5441',
    Name: 'Restorer, ceramics mfr'
  },
  {
    Id: 15951,
    Code: '5441',
    Name: 'Riveter, china'
  },
  {
    Id: 15952,
    Code: '5441',
    Name: 'Riveter, glass'
  },
  {
    Id: 15953,
    Code: '5441',
    Name: 'Rougher, glass mfr'
  },
  {
    Id: 15954,
    Code: '5441',
    Name: 'Rumbler, ceramics'
  },
  {
    Id: 15955,
    Code: '5441',
    Name: 'Runner, mould, ceramics mfr'
  },
  {
    Id: 15956,
    Code: '5441',
    Name: 'Scolloper, ceramics mfr'
  },
  {
    Id: 15957,
    Code: '5441',
    Name: 'Scourer, ceramics mfr'
  },
  {
    Id: 15958,
    Code: '5441',
    Name: 'Selector, glass'
  },
  {
    Id: 15959,
    Code: '5441',
    Name: 'Servitor, glass mfr'
  },
  {
    Id: 15960,
    Code: '5441',
    Name: 'Setter, lens'
  },
  {
    Id: 15961,
    Code: '5441',
    Name: 'Setter, machine, optical'
  },
  {
    Id: 15962,
    Code: '5441',
    Name: 'Setter, glass mfr'
  },
  {
    Id: 15963,
    Code: '5441',
    Name: 'Shaper, glass mfr'
  },
  {
    Id: 15964,
    Code: '5441',
    Name: 'Sider, glass mfr'
  },
  {
    Id: 15965,
    Code: '5441',
    Name: 'Slitter, glass, optical'
  },
  {
    Id: 15966,
    Code: '5441',
    Name: 'Smoother, lens mfr'
  },
  {
    Id: 15967,
    Code: '5441',
    Name: 'Spinner, silk, glass'
  },
  {
    Id: 15968,
    Code: '5441',
    Name: 'Sponger, ceramics mfr'
  },
  {
    Id: 15969,
    Code: '5441',
    Name: 'Sponger of clayware'
  },
  {
    Id: 15970,
    Code: '5441',
    Name: 'Sprayer, aerograph, ceramics'
  },
  {
    Id: 15971,
    Code: '5441',
    Name: 'Sprayer, cellulose, ceramics mfr'
  },
  {
    Id: 15972,
    Code: '5441',
    Name: 'Sprayer, colour, ceramics mfr'
  },
  {
    Id: 15973,
    Code: '5441',
    Name: 'Sprayer, colour, glass mfr'
  },
  {
    Id: 15974,
    Code: '5441',
    Name: 'Sprayer, enamel, ceramics mfr'
  },
  {
    Id: 15975,
    Code: '5441',
    Name: 'Sprayer, glaze'
  },
  {
    Id: 15976,
    Code: '5441',
    Name: 'Sprayer, ceramics mfr'
  },
  {
    Id: 15977,
    Code: '5441',
    Name: 'Sprayer, lamp, valve mfr'
  },
  {
    Id: 15978,
    Code: '5441',
    Name: 'Stainer, glass mfr'
  },
  {
    Id: 15979,
    Code: '5441',
    Name: 'Stenciller, aerographing, ceramics mfr'
  },
  {
    Id: 15980,
    Code: '5441',
    Name: 'Sticker, junction, ceramics mfr'
  },
  {
    Id: 15981,
    Code: '5441',
    Name: 'Sticker, punty'
  },
  {
    Id: 15982,
    Code: '5441',
    Name: 'Stopper, glaze'
  },
  {
    Id: 15983,
    Code: '5441',
    Name: 'Stopper, ceramics mfr'
  },
  {
    Id: 15984,
    Code: '5441',
    Name: 'Stripper, cast stone products mfr'
  },
  {
    Id: 15985,
    Code: '5441',
    Name: 'Surfacer, lens, optical'
  },
  {
    Id: 15986,
    Code: '5441',
    Name: 'Surfacer, optical'
  },
  {
    Id: 15987,
    Code: '5441',
    Name: 'Taker-off, glass mfr'
  },
  {
    Id: 15988,
    Code: '5441',
    Name: 'Technician, lens, contact'
  },
  {
    Id: 15989,
    Code: '5441',
    Name: 'Thrower, ceramics mfr'
  },
  {
    Id: 15990,
    Code: '5441',
    Name: 'Tooler, glass'
  },
  {
    Id: 15991,
    Code: '5441',
    Name: 'Toucher-up, ceramics decorating'
  },
  {
    Id: 15992,
    Code: '5441',
    Name: 'Tower, ceramics mfr'
  },
  {
    Id: 15993,
    Code: '5441',
    Name: 'Transferer, lithograph, ceramics mfr'
  },
  {
    Id: 15994,
    Code: '5441',
    Name: 'Transferer, ceramics mfr'
  },
  {
    Id: 15995,
    Code: '5441',
    Name: 'Transferer, glass mfr'
  },
  {
    Id: 15996,
    Code: '5441',
    Name: 'Trimmer, glass mfr'
  },
  {
    Id: 15997,
    Code: '5441',
    Name: 'Turner, machine, pottery'
  },
  {
    Id: 15998,
    Code: '5441',
    Name: 'Turner, porcelain, electric'
  },
  {
    Id: 15999,
    Code: '5441',
    Name: 'Turner, ceramics mfr'
  },
  {
    Id: 16000,
    Code: '5441',
    Name: 'Turner-out, glass mfr'
  },
  {
    Id: 16001,
    Code: '5441',
    Name: 'Washer, ceramics mfr'
  },
  {
    Id: 16002,
    Code: '5441',
    Name: 'Wedger, clay'
  },
  {
    Id: 16003,
    Code: '5441',
    Name: 'Worker, acid, ceramics mfr'
  },
  {
    Id: 16004,
    Code: '5441',
    Name: 'Worker, blowpipe, quartz glass'
  },
  {
    Id: 16005,
    Code: '5441',
    Name: 'Worker, field, brick'
  },
  {
    Id: 16006,
    Code: '5441',
    Name: 'Worker, gold'
  },
  {
    Id: 16007,
    Code: '5441',
    Name: 'Worker, lamp, glass mfr'
  },
  {
    Id: 16008,
    Code: '5441',
    Name: 'Worker, lathe, glass mfr'
  },
  {
    Id: 16009,
    Code: '5441',
    Name: 'Worker, lens'
  },
  {
    Id: 16010,
    Code: '5441',
    Name: 'Worker, optical'
  },
  {
    Id: 16011,
    Code: '5441',
    Name: 'Worker, pottery'
  },
  {
    Id: 16012,
    Code: '5441',
    Name: 'Worker, prism'
  },
  {
    Id: 16013,
    Code: '5441',
    Name: 'Worker, tile, ceramics mfr'
  },
  {
    Id: 16014,
    Code: '5442',
    Name: 'Bender, timber'
  },
  {
    Id: 16015,
    Code: '5442',
    Name: 'Bender, stick making'
  },
  {
    Id: 16016,
    Code: '5442',
    Name: 'Bender, wood products mfr'
  },
  {
    Id: 16017,
    Code: '5442',
    Name: 'Blaster, sand, briar pipe mfr'
  },
  {
    Id: 16018,
    Code: '5442',
    Name: 'Bobber, wood products'
  },
  {
    Id: 16019,
    Code: '5442',
    Name: 'Bodger, chair'
  },
  {
    Id: 16020,
    Code: '5442',
    Name: 'Borer, wide, tobacco pipes'
  },
  {
    Id: 16021,
    Code: '5442',
    Name: 'Bowyer'
  },
  {
    Id: 16022,
    Code: '5442',
    Name: 'Builder, barrow'
  },
  {
    Id: 16023,
    Code: '5442',
    Name: 'Builder, cart'
  },
  {
    Id: 16024,
    Code: '5442',
    Name: 'Builder, shed'
  },
  {
    Id: 16025,
    Code: '5442',
    Name: 'Builder, wheel, wood'
  },
  {
    Id: 16026,
    Code: '5442',
    Name: 'Carver, frame'
  },
  {
    Id: 16027,
    Code: '5442',
    Name: 'Carver, letter, wood'
  },
  {
    Id: 16028,
    Code: '5442',
    Name: 'Carver, stock, gun'
  },
  {
    Id: 16029,
    Code: '5442',
    Name: 'Carver, wood'
  },
  {
    Id: 16030,
    Code: '5442',
    Name: 'Carver, furniture'
  },
  {
    Id: 16031,
    Code: '5442',
    Name: 'Carver, wood'
  },
  {
    Id: 16032,
    Code: '5442',
    Name: 'Caseworker, piano, organ mfr'
  },
  {
    Id: 16033,
    Code: '5442',
    Name: 'Cleaver, lath'
  },
  {
    Id: 16034,
    Code: '5442',
    Name: 'Cleaver, wood'
  },
  {
    Id: 16035,
    Code: '5442',
    Name: 'Cleaver, cricket bat mfr'
  },
  {
    Id: 16036,
    Code: '5442',
    Name: 'Cooper'
  },
  {
    Id: 16037,
    Code: '5442',
    Name: 'Craftsman, museum'
  },
  {
    Id: 16038,
    Code: '5442',
    Name: 'Dresser, stick'
  },
  {
    Id: 16039,
    Code: '5442',
    Name: 'Dresser, wood'
  },
  {
    Id: 16040,
    Code: '5442',
    Name: 'Embosser, wood products mfr'
  },
  {
    Id: 16041,
    Code: '5442',
    Name: 'Engraver, marquetry'
  },
  {
    Id: 16042,
    Code: '5442',
    Name: 'Engraver, parquetry'
  },
  {
    Id: 16043,
    Code: '5442',
    Name: 'Filer, tobacco pipe mfr'
  },
  {
    Id: 16044,
    Code: '5442',
    Name: 'Finisher, coffin'
  },
  {
    Id: 16045,
    Code: '5442',
    Name: 'Finisher, peg, shuttle'
  },
  {
    Id: 16046,
    Code: '5442',
    Name: 'Finisher, spray, furniture mfr'
  },
  {
    Id: 16047,
    Code: '5442',
    Name: 'Finisher, stick mfr'
  },
  {
    Id: 16048,
    Code: '5442',
    Name: 'Fitter, cabinet'
  },
  {
    Id: 16049,
    Code: '5442',
    Name: 'Fitter, case, cabinet'
  },
  {
    Id: 16050,
    Code: '5442',
    Name: 'Fitter, case, piano'
  },
  {
    Id: 16051,
    Code: '5442',
    Name: 'Fitter, fittings and furniture'
  },
  {
    Id: 16052,
    Code: '5442',
    Name: 'Fitter, frame, picture frame mfr'
  },
  {
    Id: 16053,
    Code: '5442',
    Name: 'Fitter, furniture'
  },
  {
    Id: 16054,
    Code: '5442',
    Name: 'Fitter, gymnastics'
  },
  {
    Id: 16055,
    Code: '5442',
    Name: 'Fitter, overmantel'
  },
  {
    Id: 16056,
    Code: '5442',
    Name: 'Fitter, cabinet making'
  },
  {
    Id: 16057,
    Code: '5442',
    Name: 'Fitter-up, musical instruments mfr: piano case mfr'
  },
  {
    Id: 16058,
    Code: '5442',
    Name: 'Foreman, furniture mfr'
  },
  {
    Id: 16059,
    Code: '5442',
    Name: 'Foreman, tobacco pipe mfr'
  },
  {
    Id: 16060,
    Code: '5442',
    Name: 'Framer, picture'
  },
  {
    Id: 16061,
    Code: '5442',
    Name: 'Framer, seat'
  },
  {
    Id: 16062,
    Code: '5442',
    Name: 'Framer, chair mfr'
  },
  {
    Id: 16063,
    Code: '5442',
    Name: 'Furnisher, coffin'
  },
  {
    Id: 16064,
    Code: '5442',
    Name: 'Hand, bench, cabinet making'
  },
  {
    Id: 16065,
    Code: '5442',
    Name: 'Hinger, last'
  },
  {
    Id: 16066,
    Code: '5442',
    Name: 'Installer, furniture'
  },
  {
    Id: 16067,
    Code: '5442',
    Name: 'Joiner, cabinet making'
  },
  {
    Id: 16068,
    Code: '5442',
    Name: 'Jointer, edge, veneer'
  },
  {
    Id: 16069,
    Code: '5442',
    Name: 'Jointer, tapeless'
  },
  {
    Id: 16070,
    Code: '5442',
    Name: 'Maker, arm, furniture mfr'
  },
  {
    Id: 16071,
    Code: '5442',
    Name: 'Maker, barrel and cask'
  },
  {
    Id: 16072,
    Code: '5442',
    Name: 'Maker, barrow'
  },
  {
    Id: 16073,
    Code: '5442',
    Name: 'Maker, bat, cricket'
  },
  {
    Id: 16074,
    Code: '5442',
    Name: 'Maker, bed, furniture mfr'
  },
  {
    Id: 16075,
    Code: '5442',
    Name: 'Maker, block, clog'
  },
  {
    Id: 16076,
    Code: '5442',
    Name: 'Maker, block, hat'
  },
  {
    Id: 16077,
    Code: '5442',
    Name: 'Maker, block, wood products mfr'
  },
  {
    Id: 16078,
    Code: '5442',
    Name: 'Maker, bobbin, wood'
  },
  {
    Id: 16079,
    Code: '5442',
    Name: 'Maker, bow, sports goods mfr'
  },
  {
    Id: 16080,
    Code: '5442',
    Name: 'Maker, bowl, sports goods mfr'
  },
  {
    Id: 16081,
    Code: '5442',
    Name: 'Maker, bowl, textile machinery mfr'
  },
  {
    Id: 16082,
    Code: '5442',
    Name: 'Maker, cabinet'
  },
  {
    Id: 16083,
    Code: '5442',
    Name: 'Maker, cart'
  },
  {
    Id: 16084,
    Code: '5442',
    Name: 'Maker, case, book'
  },
  {
    Id: 16085,
    Code: '5442',
    Name: 'Maker, case, cabinet'
  },
  {
    Id: 16086,
    Code: '5442',
    Name: 'Maker, case, clock, wood'
  },
  {
    Id: 16087,
    Code: '5442',
    Name: 'Maker, case, cutlery'
  },
  {
    Id: 16088,
    Code: '5442',
    Name: 'Maker, case, instrument'
  },
  {
    Id: 16089,
    Code: '5442',
    Name: 'Maker, case, jewel'
  },
  {
    Id: 16090,
    Code: '5442',
    Name: 'Maker, case, show'
  },
  {
    Id: 16091,
    Code: '5442',
    Name: 'Maker, case, television'
  },
  {
    Id: 16092,
    Code: '5442',
    Name: 'Maker, case, musical instruments mfr'
  },
  {
    Id: 16093,
    Code: '5442',
    Name: 'Maker, chair'
  },
  {
    Id: 16094,
    Code: '5442',
    Name: 'Maker, club, golf'
  },
  {
    Id: 16095,
    Code: '5442',
    Name: 'Maker, coffin'
  },
  {
    Id: 16096,
    Code: '5442',
    Name: 'Maker, coffin and casket'
  },
  {
    Id: 16097,
    Code: '5442',
    Name: 'Maker, cue, billiard'
  },
  {
    Id: 16098,
    Code: '5442',
    Name: 'Maker, desk'
  },
  {
    Id: 16099,
    Code: '5442',
    Name: 'Maker, drawers'
  },
  {
    Id: 16100,
    Code: '5442',
    Name: 'Maker, fence, timber'
  },
  {
    Id: 16101,
    Code: '5442',
    Name: 'Maker, float, sports goods mfr'
  },
  {
    Id: 16102,
    Code: '5442',
    Name: 'Maker, form'
  },
  {
    Id: 16103,
    Code: '5442',
    Name: 'Maker, frame, chair'
  },
  {
    Id: 16104,
    Code: '5442',
    Name: 'Maker, frame, mirror'
  },
  {
    Id: 16105,
    Code: '5442',
    Name: 'Maker, frame, oxon'
  },
  {
    Id: 16106,
    Code: '5442',
    Name: 'Maker, frame, photo'
  },
  {
    Id: 16107,
    Code: '5442',
    Name: 'Maker, frame, picture'
  },
  {
    Id: 16108,
    Code: '5442',
    Name: 'Maker, frame, racquet'
  },
  {
    Id: 16109,
    Code: '5442',
    Name: 'Maker, frame, concrete mfr'
  },
  {
    Id: 16110,
    Code: '5442',
    Name: 'Maker, frame, furniture mfr'
  },
  {
    Id: 16111,
    Code: '5442',
    Name: 'Maker, furniture, bamboo'
  },
  {
    Id: 16112,
    Code: '5442',
    Name: 'Maker, furniture, cane'
  },
  {
    Id: 16113,
    Code: '5442',
    Name: 'Maker, furniture, garden'
  },
  {
    Id: 16114,
    Code: '5442',
    Name: 'Maker, furniture, wicker'
  },
  {
    Id: 16115,
    Code: '5442',
    Name: 'Maker, furniture'
  },
  {
    Id: 16116,
    Code: '5442',
    Name: 'Maker, gate'
  },
  {
    Id: 16117,
    Code: '5442',
    Name: 'Maker, grid, wood products mfr'
  },
  {
    Id: 16118,
    Code: '5442',
    Name: 'Maker, heel, wood'
  },
  {
    Id: 16119,
    Code: '5442',
    Name: 'Maker, hurdle'
  },
  {
    Id: 16120,
    Code: '5442',
    Name: 'Maker, keg, wood'
  },
  {
    Id: 16121,
    Code: '5442',
    Name: 'Maker, ladder'
  },
  {
    Id: 16122,
    Code: '5442',
    Name: 'Maker, last'
  },
  {
    Id: 16123,
    Code: '5442',
    Name: 'Maker, letter, wood'
  },
  {
    Id: 16124,
    Code: '5442',
    Name: 'Maker, mould, cast concrete products mfr'
  },
  {
    Id: 16125,
    Code: '5442',
    Name: 'Maker, oar'
  },
  {
    Id: 16126,
    Code: '5442',
    Name: 'Maker, part, piano, organ mfr'
  },
  {
    Id: 16127,
    Code: '5442',
    Name: 'Maker, pattern, cast concrete products mfr'
  },
  {
    Id: 16128,
    Code: '5442',
    Name: 'Maker, peg'
  },
  {
    Id: 16129,
    Code: '5442',
    Name: 'Maker, pipe, tobacco pipe mfr'
  },
  {
    Id: 16130,
    Code: '5442',
    Name: 'Maker, racquet'
  },
  {
    Id: 16131,
    Code: '5442',
    Name: 'Maker, rod, fishing'
  },
  {
    Id: 16132,
    Code: '5442',
    Name: 'Maker, saddletree, wood'
  },
  {
    Id: 16133,
    Code: '5442',
    Name: 'Maker, screen, wood'
  },
  {
    Id: 16134,
    Code: '5442',
    Name: 'Maker, scull'
  },
  {
    Id: 16135,
    Code: '5442',
    Name: 'Maker, seat, cane'
  },
  {
    Id: 16136,
    Code: '5442',
    Name: 'Maker, seat, garden'
  },
  {
    Id: 16137,
    Code: '5442',
    Name: 'Maker, seat'
  },
  {
    Id: 16138,
    Code: '5442',
    Name: 'Maker, shed'
  },
  {
    Id: 16139,
    Code: '5442',
    Name: 'Maker, shovel, wood'
  },
  {
    Id: 16140,
    Code: '5442',
    Name: 'Maker, shuttle'
  },
  {
    Id: 16141,
    Code: '5442',
    Name: 'Maker, skewer, wood'
  },
  {
    Id: 16142,
    Code: '5442',
    Name: 'Maker, sley'
  },
  {
    Id: 16143,
    Code: '5442',
    Name: 'Maker, spar, wood'
  },
  {
    Id: 16144,
    Code: '5442',
    Name: 'Maker, staging, shipbuilding'
  },
  {
    Id: 16145,
    Code: '5442',
    Name: "Maker, stamp, bleacher's, wood"
  },
  {
    Id: 16146,
    Code: '5442',
    Name: 'Maker, stave'
  },
  {
    Id: 16147,
    Code: '5442',
    Name: 'Maker, stick, hockey'
  },
  {
    Id: 16148,
    Code: '5442',
    Name: 'Maker, stick, walking'
  },
  {
    Id: 16149,
    Code: '5442',
    Name: 'Maker, stock, gun mfr'
  },
  {
    Id: 16150,
    Code: '5442',
    Name: 'Maker, table, furniture mfr'
  },
  {
    Id: 16151,
    Code: '5442',
    Name: 'Maker, tray, wood'
  },
  {
    Id: 16152,
    Code: '5442',
    Name: 'Maker, vat'
  },
  {
    Id: 16153,
    Code: '5442',
    Name: 'Maker, wagon, timber'
  },
  {
    Id: 16154,
    Code: '5442',
    Name: 'Maker, wardrobe'
  },
  {
    Id: 16155,
    Code: '5442',
    Name: 'Maker, wattle'
  },
  {
    Id: 16156,
    Code: '5442',
    Name: 'Maker and joiner, cabinet'
  },
  {
    Id: 16157,
    Code: '5442',
    Name: 'Man, bench, cabinet making'
  },
  {
    Id: 16158,
    Code: '5442',
    Name: 'Manufacturer, furniture, cane'
  },
  {
    Id: 16159,
    Code: '5442',
    Name: 'Manufacturer, furniture'
  },
  {
    Id: 16160,
    Code: '5442',
    Name: 'Manufacturer, furniture mfr'
  },
  {
    Id: 16161,
    Code: '5442',
    Name: 'Manufacturer, wood products mfr'
  },
  {
    Id: 16162,
    Code: '5442',
    Name: 'Marker-off, wood products mfr'
  },
  {
    Id: 16163,
    Code: '5442',
    Name: 'Marker-out, wood products mfr'
  },
  {
    Id: 16164,
    Code: '5442',
    Name: 'Matcher, veneer'
  },
  {
    Id: 16165,
    Code: '5442',
    Name: 'Operator, jointer'
  },
  {
    Id: 16166,
    Code: '5442',
    Name: 'Painter, spray, furniture mfr'
  },
  {
    Id: 16167,
    Code: '5442',
    Name: 'Polisher, bobbin, wood'
  },
  {
    Id: 16168,
    Code: '5442',
    Name: 'Polisher, heald'
  },
  {
    Id: 16169,
    Code: '5442',
    Name: 'Polisher, pipe, wood'
  },
  {
    Id: 16170,
    Code: '5442',
    Name: 'Preparer, veneer'
  },
  {
    Id: 16171,
    Code: '5442',
    Name: 'Pumicer, tobacco pipes'
  },
  {
    Id: 16172,
    Code: '5442',
    Name: 'Pumicer, wood'
  },
  {
    Id: 16173,
    Code: '5442',
    Name: 'Renovator, antiques, furniture'
  },
  {
    Id: 16174,
    Code: '5442',
    Name: 'Renovator, antiques'
  },
  {
    Id: 16175,
    Code: '5442',
    Name: 'Renovator, furniture'
  },
  {
    Id: 16176,
    Code: '5442',
    Name: 'Repairer, antiques, furniture'
  },
  {
    Id: 16177,
    Code: '5442',
    Name: 'Repairer, antiques'
  },
  {
    Id: 16178,
    Code: '5442',
    Name: 'Repairer, chair'
  },
  {
    Id: 16179,
    Code: '5442',
    Name: 'Repairer, furniture'
  },
  {
    Id: 16180,
    Code: '5442',
    Name: 'Repairer, pipe, tobacco pipes'
  },
  {
    Id: 16181,
    Code: '5442',
    Name: 'Restorer, antiques, furniture'
  },
  {
    Id: 16182,
    Code: '5442',
    Name: 'Restorer, antiques'
  },
  {
    Id: 16183,
    Code: '5442',
    Name: 'Restorer, furniture'
  },
  {
    Id: 16184,
    Code: '5442',
    Name: 'Restorer, furniture'
  },
  {
    Id: 16185,
    Code: '5442',
    Name: 'Seater, chair'
  },
  {
    Id: 16186,
    Code: '5442',
    Name: 'Splicer, veneer'
  },
  {
    Id: 16187,
    Code: '5442',
    Name: 'Sprayer, cellulose, furniture mfr'
  },
  {
    Id: 16188,
    Code: '5442',
    Name: 'Sprayer, furniture'
  },
  {
    Id: 16189,
    Code: '5442',
    Name: 'Sprayer, paint, furniture mfr'
  },
  {
    Id: 16190,
    Code: '5442',
    Name: 'Sprayer, furniture mfr'
  },
  {
    Id: 16191,
    Code: '5442',
    Name: 'Stocker, gun mfr'
  },
  {
    Id: 16192,
    Code: '5442',
    Name: 'Weighter, golf club heads'
  },
  {
    Id: 16193,
    Code: '5442',
    Name: 'Wheeler, wheelwrights'
  },
  {
    Id: 16194,
    Code: '5442',
    Name: 'Wheelwright'
  },
  {
    Id: 16195,
    Code: '5442',
    Name: 'Woodworker'
  },
  {
    Id: 16196,
    Code: '5442',
    Name: 'Woodwright'
  },
  {
    Id: 16197,
    Code: '5442',
    Name: 'Worker, bench, cabinet making'
  },
  {
    Id: 16198,
    Code: '5442',
    Name: 'Worker, cabinet'
  },
  {
    Id: 16199,
    Code: '5442',
    Name: 'Worker, case, piano, organ mfr'
  },
  {
    Id: 16200,
    Code: '5442',
    Name: 'Worker, wood'
  },
  {
    Id: 16201,
    Code: '5443',
    Name: 'Arranger, floral'
  },
  {
    Id: 16202,
    Code: '5443',
    Name: 'Arranger, flower'
  },
  {
    Id: 16203,
    Code: '5443',
    Name: 'Artist, floral'
  },
  {
    Id: 16204,
    Code: '5443',
    Name: 'Assistant, floral'
  },
  {
    Id: 16205,
    Code: '5443',
    Name: 'Decorator, floral'
  },
  {
    Id: 16206,
    Code: '5443',
    Name: 'Designer, floral'
  },
  {
    Id: 16207,
    Code: '5443',
    Name: 'Florist, artificial'
  },
  {
    Id: 16208,
    Code: '5443',
    Name: 'Florist'
  },
  {
    Id: 16209,
    Code: '5443',
    Name: 'Maker, wreath, artificial'
  },
  {
    Id: 16210,
    Code: '5443',
    Name: 'Technician, floral'
  },
  {
    Id: 16211,
    Code: '5449',
    Name: 'Artist, mask mfr'
  },
  {
    Id: 16212,
    Code: '5449',
    Name: 'Assembler, action, piano'
  },
  {
    Id: 16213,
    Code: '5449',
    Name: 'Assembler, pianoforte'
  },
  {
    Id: 16214,
    Code: '5449',
    Name: 'Assembler, wax, aircraft mfr'
  },
  {
    Id: 16215,
    Code: '5449',
    Name: 'Assembler, silver goods mfr'
  },
  {
    Id: 16216,
    Code: '5449',
    Name: 'Balancer, wheel, abrasives mfr'
  },
  {
    Id: 16217,
    Code: '5449',
    Name: 'Balancer, abrasives mfr'
  },
  {
    Id: 16218,
    Code: '5449',
    Name: 'Beater, gold'
  },
  {
    Id: 16219,
    Code: '5449',
    Name: 'Beater, silver'
  },
  {
    Id: 16220,
    Code: '5449',
    Name: 'Bellyman, piano'
  },
  {
    Id: 16221,
    Code: '5449',
    Name: 'Binder, broom, brush mfr'
  },
  {
    Id: 16222,
    Code: '5449',
    Name: 'Borer, brush'
  },
  {
    Id: 16223,
    Code: '5449',
    Name: 'Borer, broom, brush mfr'
  },
  {
    Id: 16224,
    Code: '5449',
    Name: 'Braider, whip'
  },
  {
    Id: 16225,
    Code: '5449',
    Name: 'Braider, basket mfr'
  },
  {
    Id: 16226,
    Code: '5449',
    Name: 'Builder, organ'
  },
  {
    Id: 16227,
    Code: '5449',
    Name: 'Caner'
  },
  {
    Id: 16228,
    Code: '5449',
    Name: 'Capper, cartridge mfr'
  },
  {
    Id: 16229,
    Code: '5449',
    Name: 'Carver, gold'
  },
  {
    Id: 16230,
    Code: '5449',
    Name: 'Carver, ivory'
  },
  {
    Id: 16231,
    Code: '5449',
    Name: 'Carver, letter, brass'
  },
  {
    Id: 16232,
    Code: '5449',
    Name: 'Charger, cartridge'
  },
  {
    Id: 16233,
    Code: '5449',
    Name: 'Charger, cordite'
  },
  {
    Id: 16234,
    Code: '5449',
    Name: 'Charger, fireworks mfr'
  },
  {
    Id: 16235,
    Code: '5449',
    Name: 'Chaser, gold'
  },
  {
    Id: 16236,
    Code: '5449',
    Name: 'Chaser, platework'
  },
  {
    Id: 16237,
    Code: '5449',
    Name: 'Chaser, silver'
  },
  {
    Id: 16238,
    Code: '5449',
    Name: 'Chaser, metal, precious metal'
  },
  {
    Id: 16239,
    Code: '5449',
    Name: 'Chaser, manufacturing: jewellery, plate mfr'
  },
  {
    Id: 16240,
    Code: '5449',
    Name: 'Checker, piano, organ mfr'
  },
  {
    Id: 16241,
    Code: '5449',
    Name: 'Cleaver, diamond'
  },
  {
    Id: 16242,
    Code: '5449',
    Name: 'Cleaver, precious stones'
  },
  {
    Id: 16243,
    Code: '5449',
    Name: 'Colourman, artist'
  },
  {
    Id: 16244,
    Code: '5449',
    Name: 'Craftsman, research and development'
  },
  {
    Id: 16245,
    Code: '5449',
    Name: 'Craftsman, government'
  },
  {
    Id: 16246,
    Code: '5449',
    Name: 'Cutler, silver'
  },
  {
    Id: 16247,
    Code: '5449',
    Name: 'Cutter, cork'
  },
  {
    Id: 16248,
    Code: '5449',
    Name: 'Cutter, cotton'
  },
  {
    Id: 16249,
    Code: '5449',
    Name: 'Cutter, diamond'
  },
  {
    Id: 16250,
    Code: '5449',
    Name: 'Cutter, leaf, precious metals'
  },
  {
    Id: 16251,
    Code: '5449',
    Name: 'Cutter, plate, engraving'
  },
  {
    Id: 16252,
    Code: '5449',
    Name: 'Cutter, bone'
  },
  {
    Id: 16253,
    Code: '5449',
    Name: 'Cutter, precious stones'
  },
  {
    Id: 16254,
    Code: '5449',
    Name: 'Cutter, candle mfr'
  },
  {
    Id: 16255,
    Code: '5449',
    Name: 'Cutter, fancy goods mfr'
  },
  {
    Id: 16256,
    Code: '5449',
    Name: 'Cutter and booker'
  },
  {
    Id: 16257,
    Code: '5449',
    Name: 'Docker, cork'
  },
  {
    Id: 16258,
    Code: '5449',
    Name: 'Drawer, brush'
  },
  {
    Id: 16259,
    Code: '5449',
    Name: 'Dresser, diamond'
  },
  {
    Id: 16260,
    Code: '5449',
    Name: 'Dresser, doll'
  },
  {
    Id: 16261,
    Code: '5449',
    Name: 'Dresser, fly'
  },
  {
    Id: 16262,
    Code: '5449',
    Name: 'Driller, brush'
  },
  {
    Id: 16263,
    Code: '5449',
    Name: 'Driller, die, diamond'
  },
  {
    Id: 16264,
    Code: '5449',
    Name: 'Driller, wire, diamond'
  },
  {
    Id: 16265,
    Code: '5449',
    Name: 'Embosser, hilt, sword'
  },
  {
    Id: 16266,
    Code: '5449',
    Name: 'Embosser, jewellery, plate mfr'
  },
  {
    Id: 16267,
    Code: '5449',
    Name: 'Engraver, brass'
  },
  {
    Id: 16268,
    Code: '5449',
    Name: 'Engraver, gold'
  },
  {
    Id: 16269,
    Code: '5449',
    Name: 'Engraver, hand'
  },
  {
    Id: 16270,
    Code: '5449',
    Name: 'Engraver, instrument'
  },
  {
    Id: 16271,
    Code: '5449',
    Name: 'Engraver, machine, instrument mfr'
  },
  {
    Id: 16272,
    Code: '5449',
    Name: 'Engraver, machine, jewellery, plate mfr'
  },
  {
    Id: 16273,
    Code: '5449',
    Name: 'Engraver, metal'
  },
  {
    Id: 16274,
    Code: '5449',
    Name: 'Engraver, micrometer'
  },
  {
    Id: 16275,
    Code: '5449',
    Name: 'Engraver, plate, precious metals'
  },
  {
    Id: 16276,
    Code: '5449',
    Name: 'Engraver, silver'
  },
  {
    Id: 16277,
    Code: '5449',
    Name: 'Engraver'
  },
  {
    Id: 16278,
    Code: '5449',
    Name: 'Engraver-etcher'
  },
  {
    Id: 16279,
    Code: '5449',
    Name: 'Etcher, integrated, printed circuits'
  },
  {
    Id: 16280,
    Code: '5449',
    Name: 'Etcher, jewellery, plate mfr'
  },
  {
    Id: 16281,
    Code: '5449',
    Name: 'Exhauster, lamp, valve mfr'
  },
  {
    Id: 16282,
    Code: '5449',
    Name: 'Fabricator, sign'
  },
  {
    Id: 16283,
    Code: '5449',
    Name: 'Feeder, pallet'
  },
  {
    Id: 16284,
    Code: '5449',
    Name: 'Felter, printing rollers'
  },
  {
    Id: 16285,
    Code: '5449',
    Name: 'Filler, brush'
  },
  {
    Id: 16286,
    Code: '5449',
    Name: 'Filler, cartridge'
  },
  {
    Id: 16287,
    Code: '5449',
    Name: 'Filler, detonator'
  },
  {
    Id: 16288,
    Code: '5449',
    Name: 'Filler, machine, broom, brush mfr'
  },
  {
    Id: 16289,
    Code: '5449',
    Name: 'Filler, rocket'
  },
  {
    Id: 16290,
    Code: '5449',
    Name: 'Filler, explosives mfr'
  },
  {
    Id: 16291,
    Code: '5449',
    Name: 'Filler, fireworks mfr'
  },
  {
    Id: 16292,
    Code: '5449',
    Name: 'Filler, ordnance factory'
  },
  {
    Id: 16293,
    Code: '5449',
    Name: 'Finisher, cord, telephone'
  },
  {
    Id: 16294,
    Code: '5449',
    Name: 'Finisher, racquet'
  },
  {
    Id: 16295,
    Code: '5449',
    Name: 'Finisher, reed'
  },
  {
    Id: 16296,
    Code: '5449',
    Name: 'Finisher, rod, fishing'
  },
  {
    Id: 16297,
    Code: '5449',
    Name: 'Finisher, silver'
  },
  {
    Id: 16298,
    Code: '5449',
    Name: 'Finisher, spoon and fork'
  },
  {
    Id: 16299,
    Code: '5449',
    Name: 'Finisher, artificial teeth mfr'
  },
  {
    Id: 16300,
    Code: '5449',
    Name: 'Finisher, fishing rod mfr'
  },
  {
    Id: 16301,
    Code: '5449',
    Name: 'Finisher, incandescent mantle mfr'
  },
  {
    Id: 16302,
    Code: '5449',
    Name: 'Finisher, musical instruments mfr'
  },
  {
    Id: 16303,
    Code: '5449',
    Name: 'Finisher, piano: key mfr'
  },
  {
    Id: 16304,
    Code: '5449',
    Name: 'Finisher, piano: organ mfr'
  },
  {
    Id: 16305,
    Code: '5449',
    Name: 'Finisher, precious metal, plate mfr'
  },
  {
    Id: 16306,
    Code: '5449',
    Name: 'Finisher, tooth brush mfr'
  },
  {
    Id: 16307,
    Code: '5449',
    Name: "Fitter, depositor's"
  },
  {
    Id: 16308,
    Code: '5449',
    Name: 'Fitter, instrument, musical'
  },
  {
    Id: 16309,
    Code: '5449',
    Name: 'Fitter, organ'
  },
  {
    Id: 16310,
    Code: '5449',
    Name: 'Fitter, piano'
  },
  {
    Id: 16311,
    Code: '5449',
    Name: 'Fitter, table, billiard'
  },
  {
    Id: 16312,
    Code: '5449',
    Name: 'Fitter, fishing rod mfr'
  },
  {
    Id: 16313,
    Code: '5449',
    Name: 'Fitter, jewellery, plate mfr'
  },
  {
    Id: 16314,
    Code: '5449',
    Name: 'Fitter, musical instruments mfr'
  },
  {
    Id: 16315,
    Code: '5449',
    Name: 'Fitter-up, musical instruments mfr'
  },
  {
    Id: 16316,
    Code: '5449',
    Name: 'Fixer, lens'
  },
  {
    Id: 16317,
    Code: '5449',
    Name: 'Flatter, gold'
  },
  {
    Id: 16318,
    Code: '5449',
    Name: 'Flatter, silver'
  },
  {
    Id: 16319,
    Code: '5449',
    Name: 'Fluter, silver, plate'
  },
  {
    Id: 16320,
    Code: '5449',
    Name: 'Foreman, shop, pattern'
  },
  {
    Id: 16321,
    Code: '5449',
    Name: 'Foreman, broom, brush mfr'
  },
  {
    Id: 16322,
    Code: '5449',
    Name: 'Foreman, cork mfr'
  },
  {
    Id: 16323,
    Code: '5449',
    Name: 'Foreman, fancy goods mfr'
  },
  {
    Id: 16324,
    Code: '5449',
    Name: 'Foreman, jewellery, plate mfr'
  },
  {
    Id: 16325,
    Code: '5449',
    Name: 'Foreman, match mfr'
  },
  {
    Id: 16326,
    Code: '5449',
    Name: 'Foreman, ordnance factory: shell filling'
  },
  {
    Id: 16327,
    Code: '5449',
    Name: 'Foreman, pen mfr'
  },
  {
    Id: 16328,
    Code: '5449',
    Name: 'Foreman, pencil, crayon mfr'
  },
  {
    Id: 16329,
    Code: '5449',
    Name: 'Foreman, sports goods mfr'
  },
  {
    Id: 16330,
    Code: '5449',
    Name: 'Foreman, toy mfr'
  },
  {
    Id: 16331,
    Code: '5449',
    Name: 'Furnisher, house'
  },
  {
    Id: 16332,
    Code: '5449',
    Name: 'Girl, taper'
  },
  {
    Id: 16333,
    Code: '5449',
    Name: 'Goldsmith'
  },
  {
    Id: 16334,
    Code: '5449',
    Name: 'Grinder, disc, abrasive wheel mfr'
  },
  {
    Id: 16335,
    Code: '5449',
    Name: 'Grinder, edge, abrasive wheel mfr'
  },
  {
    Id: 16336,
    Code: '5449',
    Name: 'Hammerer, precious metal, plate mfr'
  },
  {
    Id: 16337,
    Code: '5449',
    Name: 'Hammerman, precious metal, plate mfr'
  },
  {
    Id: 16338,
    Code: '5449',
    Name: 'Hand, brush, broom, brush mfr'
  },
  {
    Id: 16339,
    Code: '5449',
    Name: 'Hand, display, fireworks mfr'
  },
  {
    Id: 16340,
    Code: '5449',
    Name: 'Hand, loom, loom furniture mfr'
  },
  {
    Id: 16341,
    Code: '5449',
    Name: 'Hand, pan, broom, brush mfr'
  },
  {
    Id: 16342,
    Code: '5449',
    Name: 'Jeweller, jobbing'
  },
  {
    Id: 16343,
    Code: '5449',
    Name: 'Jeweller, manufacturing'
  },
  {
    Id: 16344,
    Code: '5449',
    Name: 'Jeweller, masonic'
  },
  {
    Id: 16345,
    Code: '5449',
    Name: 'Jeweller, manufacturing, repairing'
  },
  {
    Id: 16346,
    Code: '5449',
    Name: 'Jobber, jewellery, plate mfr'
  },
  {
    Id: 16347,
    Code: '5449',
    Name: 'Knotter, wig mfr'
  },
  {
    Id: 16348,
    Code: '5449',
    Name: 'Lacquerer, spray'
  },
  {
    Id: 16349,
    Code: '5449',
    Name: 'Lapidary'
  },
  {
    Id: 16350,
    Code: '5449',
    Name: 'Letterer, signwriting'
  },
  {
    Id: 16351,
    Code: '5449',
    Name: 'Linker, chain'
  },
  {
    Id: 16352,
    Code: '5449',
    Name: 'Loader, cartridge'
  },
  {
    Id: 16353,
    Code: '5449',
    Name: 'Loader, ammunition mfr'
  },
  {
    Id: 16354,
    Code: '5449',
    Name: 'Luthier'
  },
  {
    Id: 16355,
    Code: '5449',
    Name: 'Machinist, musical instruments mfr'
  },
  {
    Id: 16356,
    Code: '5449',
    Name: 'Maker, action, piano, organ mfr'
  },
  {
    Id: 16357,
    Code: '5449',
    Name: 'Maker, appliance, orthopaedic'
  },
  {
    Id: 16358,
    Code: '5449',
    Name: 'Maker, appliance, surgical'
  },
  {
    Id: 16359,
    Code: '5449',
    Name: 'Maker, badge'
  },
  {
    Id: 16360,
    Code: '5449',
    Name: 'Maker, bait'
  },
  {
    Id: 16361,
    Code: '5449',
    Name: 'Maker, basket'
  },
  {
    Id: 16362,
    Code: '5449',
    Name: 'Maker, bear, teddy'
  },
  {
    Id: 16363,
    Code: '5449',
    Name: 'Maker, belly, piano'
  },
  {
    Id: 16364,
    Code: '5449',
    Name: 'Maker, belt, life'
  },
  {
    Id: 16365,
    Code: '5449',
    Name: 'Maker, belt, surgical'
  },
  {
    Id: 16366,
    Code: '5449',
    Name: 'Maker, besom'
  },
  {
    Id: 16367,
    Code: '5449',
    Name: 'Maker, blind'
  },
  {
    Id: 16368,
    Code: '5449',
    Name: 'Maker, bow, musical instruments mfr'
  },
  {
    Id: 16369,
    Code: '5449',
    Name: 'Maker, bracelet'
  },
  {
    Id: 16370,
    Code: '5449',
    Name: 'Maker, brooch'
  },
  {
    Id: 16371,
    Code: '5449',
    Name: 'Maker, broom'
  },
  {
    Id: 16372,
    Code: '5449',
    Name: 'Maker, brush'
  },
  {
    Id: 16373,
    Code: '5449',
    Name: 'Maker, candle'
  },
  {
    Id: 16374,
    Code: '5449',
    Name: 'Maker, cartridge'
  },
  {
    Id: 16375,
    Code: '5449',
    Name: 'Maker, case, cigarette, precious metals'
  },
  {
    Id: 16376,
    Code: '5449',
    Name: 'Maker, case, spectacle'
  },
  {
    Id: 16377,
    Code: '5449',
    Name: 'Maker, chain, metal, precious metal'
  },
  {
    Id: 16378,
    Code: '5449',
    Name: 'Maker, detonator'
  },
  {
    Id: 16379,
    Code: '5449',
    Name: 'Maker, door, fireproof'
  },
  {
    Id: 16380,
    Code: '5449',
    Name: 'Maker, drum, musical instruments mfr'
  },
  {
    Id: 16381,
    Code: '5449',
    Name: 'Maker, felt, piano, organ mfr'
  },
  {
    Id: 16382,
    Code: '5449',
    Name: 'Maker, figure, wax'
  },
  {
    Id: 16383,
    Code: '5449',
    Name: 'Maker, firework'
  },
  {
    Id: 16384,
    Code: '5449',
    Name: 'Maker, fireworks'
  },
  {
    Id: 16385,
    Code: '5449',
    Name: 'Maker, flower, artificial'
  },
  {
    Id: 16386,
    Code: '5449',
    Name: 'Maker, fly, sports goods mfr'
  },
  {
    Id: 16387,
    Code: '5449',
    Name: 'Maker, gasket'
  },
  {
    Id: 16388,
    Code: '5449',
    Name: 'Maker, glockenspiel'
  },
  {
    Id: 16389,
    Code: '5449',
    Name: 'Maker, goods, fancy'
  },
  {
    Id: 16390,
    Code: '5449',
    Name: 'Maker, hammer, piano, organ mfr'
  },
  {
    Id: 16391,
    Code: '5449',
    Name: 'Maker, handle, precious metal, plate mfr'
  },
  {
    Id: 16392,
    Code: '5449',
    Name: 'Maker, harp'
  },
  {
    Id: 16393,
    Code: '5449',
    Name: 'Maker, hollow-ware, precious metal, plate mfr'
  },
  {
    Id: 16394,
    Code: '5449',
    Name: 'Maker, hone'
  },
  {
    Id: 16395,
    Code: '5449',
    Name: 'Maker, instrument, musical'
  },
  {
    Id: 16396,
    Code: '5449',
    Name: 'Maker, instrument, musical instruments'
  },
  {
    Id: 16397,
    Code: '5449',
    Name: 'Maker, jewellery'
  },
  {
    Id: 16398,
    Code: '5449',
    Name: 'Maker, key, musical instruments mfr'
  },
  {
    Id: 16399,
    Code: '5449',
    Name: 'Maker, limb, artificial'
  },
  {
    Id: 16400,
    Code: '5449',
    Name: 'Maker, locket, precious metals'
  },
  {
    Id: 16401,
    Code: '5449',
    Name: 'Maker, mask'
  },
  {
    Id: 16402,
    Code: '5449',
    Name: 'Maker, mat, sinnet'
  },
  {
    Id: 16403,
    Code: '5449',
    Name: 'Maker, mattress, asbestos'
  },
  {
    Id: 16404,
    Code: '5449',
    Name: 'Maker, model, display'
  },
  {
    Id: 16405,
    Code: '5449',
    Name: 'Maker, model, jewellery'
  },
  {
    Id: 16406,
    Code: '5449',
    Name: 'Maker, model, animation'
  },
  {
    Id: 16407,
    Code: '5449',
    Name: 'Maker, model, engineering'
  },
  {
    Id: 16408,
    Code: '5449',
    Name: 'Maker, model, film, television production'
  },
  {
    Id: 16409,
    Code: '5449',
    Name: 'Maker, mop'
  },
  {
    Id: 16410,
    Code: '5449',
    Name: 'Maker, mould, asbestos-cement goods mfr'
  },
  {
    Id: 16411,
    Code: '5449',
    Name: 'Maker, pad, stamping'
  },
  {
    Id: 16412,
    Code: '5449',
    Name: 'Maker, pad, basket mfr'
  },
  {
    Id: 16413,
    Code: '5449',
    Name: "Maker, pattern, engineer's"
  },
  {
    Id: 16414,
    Code: '5449',
    Name: 'Maker, pattern, wood'
  },
  {
    Id: 16415,
    Code: '5449',
    Name: 'Maker, pattern, coal mine'
  },
  {
    Id: 16416,
    Code: '5449',
    Name: 'Maker, pattern, jewellery, plate mfr'
  },
  {
    Id: 16417,
    Code: '5449',
    Name: 'Maker, pattern, metal trades'
  },
  {
    Id: 16418,
    Code: '5449',
    Name: 'Maker, piano'
  },
  {
    Id: 16419,
    Code: '5449',
    Name: 'Maker, plate, precious metal, plate mfr'
  },
  {
    Id: 16420,
    Code: '5449',
    Name: 'Maker, poppy'
  },
  {
    Id: 16421,
    Code: '5449',
    Name: 'Maker, prop'
  },
  {
    Id: 16422,
    Code: '5449',
    Name: 'Maker, props'
  },
  {
    Id: 16423,
    Code: '5449',
    Name: 'Maker, reed, musical instruments mfr'
  },
  {
    Id: 16424,
    Code: '5449',
    Name: 'Maker, reed, textile machinery mfr'
  },
  {
    Id: 16425,
    Code: '5449',
    Name: 'Maker, reel, fishing'
  },
  {
    Id: 16426,
    Code: '5449',
    Name: 'Maker, ring, jump'
  },
  {
    Id: 16427,
    Code: '5449',
    Name: 'Maker, ring, wax'
  },
  {
    Id: 16428,
    Code: '5449',
    Name: 'Maker, ring, precious metal, plate mfr'
  },
  {
    Id: 16429,
    Code: '5449',
    Name: 'Maker, rocket'
  },
  {
    Id: 16430,
    Code: '5449',
    Name: 'Maker, roller, piano, organ mfr'
  },
  {
    Id: 16431,
    Code: '5449',
    Name: 'Maker, shade, lamp'
  },
  {
    Id: 16432,
    Code: '5449',
    Name: 'Maker, shuttlecock'
  },
  {
    Id: 16433,
    Code: '5449',
    Name: 'Maker, sign, electric signs'
  },
  {
    Id: 16434,
    Code: '5449',
    Name: 'Maker, sign, metal'
  },
  {
    Id: 16435,
    Code: '5449',
    Name: 'Maker, sign, neon'
  },
  {
    Id: 16436,
    Code: '5449',
    Name: 'Maker, sign, perspex'
  },
  {
    Id: 16437,
    Code: '5449',
    Name: 'Maker, sign, wood'
  },
  {
    Id: 16438,
    Code: '5449',
    Name: 'Maker, sign'
  },
  {
    Id: 16439,
    Code: '5449',
    Name: 'Maker, skep'
  },
  {
    Id: 16440,
    Code: '5449',
    Name: 'Maker, skip'
  },
  {
    Id: 16441,
    Code: '5449',
    Name: 'Maker, stone, artificial'
  },
  {
    Id: 16442,
    Code: '5449',
    Name: 'Maker, stone, patent'
  },
  {
    Id: 16443,
    Code: '5449',
    Name: 'Maker, stone, rubbing'
  },
  {
    Id: 16444,
    Code: '5449',
    Name: 'Maker, string, metal'
  },
  {
    Id: 16445,
    Code: '5449',
    Name: 'Maker, string, gut mfr'
  },
  {
    Id: 16446,
    Code: '5449',
    Name: 'Maker, table, billiard'
  },
  {
    Id: 16447,
    Code: '5449',
    Name: 'Maker, thimble, precious metal, plate mfr'
  },
  {
    Id: 16448,
    Code: '5449',
    Name: 'Maker, toy'
  },
  {
    Id: 16449,
    Code: '5449',
    Name: 'Maker, tray, wicker'
  },
  {
    Id: 16450,
    Code: '5449',
    Name: 'Maker, trophy'
  },
  {
    Id: 16451,
    Code: '5449',
    Name: 'Maker, truss, surgical goods mfr'
  },
  {
    Id: 16452,
    Code: '5449',
    Name: 'Maker, violin'
  },
  {
    Id: 16453,
    Code: '5449',
    Name: 'Maker, wig'
  },
  {
    Id: 16454,
    Code: '5449',
    Name: 'Maker, musical instruments mfr'
  },
  {
    Id: 16455,
    Code: '5449',
    Name: 'Maker, sports goods mfr'
  },
  {
    Id: 16456,
    Code: '5449',
    Name: "Maker-up, jeweller's"
  },
  {
    Id: 16457,
    Code: '5449',
    Name: 'Maker-up, silver, plate mfr'
  },
  {
    Id: 16458,
    Code: '5449',
    Name: 'Man, jobbing'
  },
  {
    Id: 16459,
    Code: '5449',
    Name: 'Man, mould, cast concrete products mfr'
  },
  {
    Id: 16460,
    Code: '5449',
    Name: 'Manufacturer, badge'
  },
  {
    Id: 16461,
    Code: '5449',
    Name: 'Manufacturer, broom'
  },
  {
    Id: 16462,
    Code: '5449',
    Name: 'Manufacturer, brush'
  },
  {
    Id: 16463,
    Code: '5449',
    Name: 'Manufacturer, instrument, musical'
  },
  {
    Id: 16464,
    Code: '5449',
    Name: 'Manufacturer, jewellery'
  },
  {
    Id: 16465,
    Code: '5449',
    Name: 'Manufacturer, rosette'
  },
  {
    Id: 16466,
    Code: '5449',
    Name: 'Manufacturer, sign'
  },
  {
    Id: 16467,
    Code: '5449',
    Name: 'Manufacturer, toy'
  },
  {
    Id: 16468,
    Code: '5449',
    Name: 'Manufacturer, basket mfr'
  },
  {
    Id: 16469,
    Code: '5449',
    Name: 'Manufacturer, broom, brush mfr'
  },
  {
    Id: 16470,
    Code: '5449',
    Name: 'Manufacturer, fancy goods mfr'
  },
  {
    Id: 16471,
    Code: '5449',
    Name: 'Manufacturer, jewellery, plate mfr'
  },
  {
    Id: 16472,
    Code: '5449',
    Name: 'Manufacturer, musical instruments mfr'
  },
  {
    Id: 16473,
    Code: '5449',
    Name: 'Manufacturer, sports goods mfr'
  },
  {
    Id: 16474,
    Code: '5449',
    Name: 'Manufacturer, surgical goods mfr'
  },
  {
    Id: 16475,
    Code: '5449',
    Name: 'Manufacturer, toy mfr'
  },
  {
    Id: 16476,
    Code: '5449',
    Name: 'Marker, metal file mfr'
  },
  {
    Id: 16477,
    Code: '5449',
    Name: 'Marker-off, piano'
  },
  {
    Id: 16478,
    Code: '5449',
    Name: 'Matcher, hair'
  },
  {
    Id: 16479,
    Code: '5449',
    Name: 'Mechanic, orthopaedic'
  },
  {
    Id: 16480,
    Code: '5449',
    Name: 'Mechanic, pen'
  },
  {
    Id: 16481,
    Code: '5449',
    Name: 'Mechanic, musical instruments'
  },
  {
    Id: 16482,
    Code: '5449',
    Name: 'Miller, diamond, jewellery, plate mfr'
  },
  {
    Id: 16483,
    Code: '5449',
    Name: 'Modeller, styling, vehicles'
  },
  {
    Id: 16484,
    Code: '5449',
    Name: 'Modeller, wax'
  },
  {
    Id: 16485,
    Code: '5449',
    Name: 'Modeller'
  },
  {
    Id: 16486,
    Code: '5449',
    Name: 'Moulder, abrasive'
  },
  {
    Id: 16487,
    Code: '5449',
    Name: 'Moulder, carborundum'
  },
  {
    Id: 16488,
    Code: '5449',
    Name: 'Moulder, stone, patent'
  },
  {
    Id: 16489,
    Code: '5449',
    Name: 'Moulder, tooth'
  },
  {
    Id: 16490,
    Code: '5449',
    Name: 'Moulder, wheel, abrasive'
  },
  {
    Id: 16491,
    Code: '5449',
    Name: 'Moulder, wheel, abrasive wheel mfr'
  },
  {
    Id: 16492,
    Code: '5449',
    Name: 'Moulder, abrasives mfr'
  },
  {
    Id: 16493,
    Code: '5449',
    Name: 'Moulder, brake linings mfr'
  },
  {
    Id: 16494,
    Code: '5449',
    Name: 'Moulder, candle mfr'
  },
  {
    Id: 16495,
    Code: '5449',
    Name: 'Moulder, lead pencil, chalk, crayon mfr'
  },
  {
    Id: 16496,
    Code: '5449',
    Name: 'Mounter, diamond'
  },
  {
    Id: 16497,
    Code: '5449',
    Name: 'Mounter, gold'
  },
  {
    Id: 16498,
    Code: '5449',
    Name: 'Mounter, metal'
  },
  {
    Id: 16499,
    Code: '5449',
    Name: 'Mounter, picture'
  },
  {
    Id: 16500,
    Code: '5449',
    Name: 'Mounter, silver'
  },
  {
    Id: 16501,
    Code: '5449',
    Name: 'Mounter, jewellery mfr'
  },
  {
    Id: 16502,
    Code: '5449',
    Name: 'Mounter, precious metal, plate mfr'
  },
  {
    Id: 16503,
    Code: '5449',
    Name: 'Operator, sheathing, explosives'
  },
  {
    Id: 16504,
    Code: '5449',
    Name: 'Overlooker, wire'
  },
  {
    Id: 16505,
    Code: '5449',
    Name: 'Packer, parachute'
  },
  {
    Id: 16506,
    Code: '5449',
    Name: "Painter, engraver's, textile printing"
  },
  {
    Id: 16507,
    Code: '5449',
    Name: 'Painter, roller, textile printing'
  },
  {
    Id: 16508,
    Code: '5449',
    Name: 'Painter, spray'
  },
  {
    Id: 16509,
    Code: '5449',
    Name: 'Painter, tin'
  },
  {
    Id: 16510,
    Code: '5449',
    Name: 'Painter, roller engraving'
  },
  {
    Id: 16511,
    Code: '5449',
    Name: 'Paperer, sand, mask mfr'
  },
  {
    Id: 16512,
    Code: '5449',
    Name: 'Piercer, saw'
  },
  {
    Id: 16513,
    Code: '5449',
    Name: 'Piercer, jewellery, plate mfr'
  },
  {
    Id: 16514,
    Code: '5449',
    Name: 'Pleater, incandescent mantle mfr'
  },
  {
    Id: 16515,
    Code: '5449',
    Name: 'Polisher, diamond'
  },
  {
    Id: 16516,
    Code: '5449',
    Name: 'Polisher, ivory'
  },
  {
    Id: 16517,
    Code: '5449',
    Name: 'Polisher, key, piano, organ mfr'
  },
  {
    Id: 16518,
    Code: '5449',
    Name: 'Posticheur'
  },
  {
    Id: 16519,
    Code: '5449',
    Name: 'Preparer, hair, wig mfr'
  },
  {
    Id: 16520,
    Code: '5449',
    Name: 'Presser, hand, sports goods mfr'
  },
  {
    Id: 16521,
    Code: '5449',
    Name: 'Presser, stone, artificial'
  },
  {
    Id: 16522,
    Code: '5449',
    Name: 'Primer, cap'
  },
  {
    Id: 16523,
    Code: '5449',
    Name: 'Primer, cartridge mfr'
  },
  {
    Id: 16524,
    Code: '5449',
    Name: 'Printer, spray'
  },
  {
    Id: 16525,
    Code: '5449',
    Name: 'Puller, pallet'
  },
  {
    Id: 16526,
    Code: '5449',
    Name: 'Pumicer, horn'
  },
  {
    Id: 16527,
    Code: '5449',
    Name: 'Puncher, shade, lamp'
  },
  {
    Id: 16528,
    Code: '5449',
    Name: 'Pyrotechnician'
  },
  {
    Id: 16529,
    Code: '5449',
    Name: 'Pyrotechnist'
  },
  {
    Id: 16530,
    Code: '5449',
    Name: 'Regulator, piano, organ mfr'
  },
  {
    Id: 16531,
    Code: '5449',
    Name: 'Repairer, blind'
  },
  {
    Id: 16532,
    Code: '5449',
    Name: 'Repairer, case, watch'
  },
  {
    Id: 16533,
    Code: '5449',
    Name: 'Repairer, film'
  },
  {
    Id: 16534,
    Code: '5449',
    Name: 'Repairer, instrument, musical'
  },
  {
    Id: 16535,
    Code: '5449',
    Name: 'Repairer, instrument, musical instruments'
  },
  {
    Id: 16536,
    Code: '5449',
    Name: 'Repairer, jewellery and plate'
  },
  {
    Id: 16537,
    Code: '5449',
    Name: 'Repairer, pen, fountain'
  },
  {
    Id: 16538,
    Code: '5449',
    Name: 'Repairer, reed'
  },
  {
    Id: 16539,
    Code: '5449',
    Name: 'Repairer, toy'
  },
  {
    Id: 16540,
    Code: '5449',
    Name: 'Repairer, jewellery'
  },
  {
    Id: 16541,
    Code: '5449',
    Name: 'Repairer, musical instruments'
  },
  {
    Id: 16542,
    Code: '5449',
    Name: 'Repairer, precious metal, plate'
  },
  {
    Id: 16543,
    Code: '5449',
    Name: 'Repairer, sports goods'
  },
  {
    Id: 16544,
    Code: '5449',
    Name: 'Restorer, horse, rocking'
  },
  {
    Id: 16545,
    Code: '5449',
    Name: 'Restorer, musical instruments'
  },
  {
    Id: 16546,
    Code: '5449',
    Name: 'Roller, cross'
  },
  {
    Id: 16547,
    Code: '5449',
    Name: 'Sawyer, diamond'
  },
  {
    Id: 16548,
    Code: '5449',
    Name: 'Sawyer, ivory'
  },
  {
    Id: 16549,
    Code: '5449',
    Name: 'Sawyer, bone, ivory'
  },
  {
    Id: 16550,
    Code: '5449',
    Name: 'Setter, diamond'
  },
  {
    Id: 16551,
    Code: '5449',
    Name: 'Setter, gem'
  },
  {
    Id: 16552,
    Code: '5449',
    Name: 'Setter, jewel'
  },
  {
    Id: 16553,
    Code: '5449',
    Name: 'Setter, machine, electrode'
  },
  {
    Id: 16554,
    Code: '5449',
    Name: 'Setter, machine, grid'
  },
  {
    Id: 16555,
    Code: '5449',
    Name: 'Setter, machine, metalising'
  },
  {
    Id: 16556,
    Code: '5449',
    Name: 'Setter, machine, sealing'
  },
  {
    Id: 16557,
    Code: '5449',
    Name: 'Setter, machine, broom, brush mfr'
  },
  {
    Id: 16558,
    Code: '5449',
    Name: 'Setter, machine, button mfr'
  },
  {
    Id: 16559,
    Code: '5449',
    Name: 'Setter, reed'
  },
  {
    Id: 16560,
    Code: '5449',
    Name: 'Setter, stone, jewellery'
  },
  {
    Id: 16561,
    Code: '5449',
    Name: 'Setter, arc welding electrode mfr'
  },
  {
    Id: 16562,
    Code: '5449',
    Name: 'Setter, broom, brush mfr'
  },
  {
    Id: 16563,
    Code: '5449',
    Name: 'Setter, jewellery, plate mfr'
  },
  {
    Id: 16564,
    Code: '5449',
    Name: 'Setter-operator, engraving, pantograph'
  },
  {
    Id: 16565,
    Code: '5449',
    Name: 'Setter-up, diamond polishing'
  },
  {
    Id: 16566,
    Code: '5449',
    Name: 'Shaper, incandescent mantle mfr'
  },
  {
    Id: 16567,
    Code: '5449',
    Name: 'Signmaker'
  },
  {
    Id: 16568,
    Code: '5449',
    Name: 'Signwriter'
  },
  {
    Id: 16569,
    Code: '5449',
    Name: 'Silversmith'
  },
  {
    Id: 16570,
    Code: '5449',
    Name: 'Smith, bright'
  },
  {
    Id: 16571,
    Code: '5449',
    Name: 'Smith, gold'
  },
  {
    Id: 16572,
    Code: '5449',
    Name: 'Smith, platinum'
  },
  {
    Id: 16573,
    Code: '5449',
    Name: 'Smith, silver'
  },
  {
    Id: 16574,
    Code: '5449',
    Name: 'Sprayer, paint'
  },
  {
    Id: 16575,
    Code: '5449',
    Name: 'Stringer, racquet'
  },
  {
    Id: 16576,
    Code: '5449',
    Name: 'Stringer, piano, organ mfr'
  },
  {
    Id: 16577,
    Code: '5449',
    Name: 'Stringer, sports goods mfr'
  },
  {
    Id: 16578,
    Code: '5449',
    Name: 'Superintendent, Royal Mint'
  },
  {
    Id: 16579,
    Code: '5449',
    Name: 'Surfacer, shot, steel'
  },
  {
    Id: 16580,
    Code: '5449',
    Name: 'Taper, coil'
  },
  {
    Id: 16581,
    Code: '5449',
    Name: 'Technician, instrument, musical'
  },
  {
    Id: 16582,
    Code: '5449',
    Name: 'Technician, piano'
  },
  {
    Id: 16583,
    Code: '5449',
    Name: 'Tester, musical instruments mfr'
  },
  {
    Id: 16584,
    Code: '5449',
    Name: 'Tester-rectifier, instrument, musical'
  },
  {
    Id: 16585,
    Code: '5449',
    Name: 'Trimmer, soap'
  },
  {
    Id: 16586,
    Code: '5449',
    Name: 'Trimmer, tooth'
  },
  {
    Id: 16587,
    Code: '5449',
    Name: 'Trimmer, artificial teeth mfr'
  },
  {
    Id: 16588,
    Code: '5449',
    Name: 'Trimmer, broom, brush mfr'
  },
  {
    Id: 16589,
    Code: '5449',
    Name: 'Tuner, piano'
  },
  {
    Id: 16590,
    Code: '5449',
    Name: 'Tuner, musical instruments'
  },
  {
    Id: 16591,
    Code: '5449',
    Name: 'Turner, asbestos'
  },
  {
    Id: 16592,
    Code: '5449',
    Name: 'Turner, cork'
  },
  {
    Id: 16593,
    Code: '5449',
    Name: 'Turner, engine, rose'
  },
  {
    Id: 16594,
    Code: '5449',
    Name: 'Turner, engine, jewellery, plate mfr'
  },
  {
    Id: 16595,
    Code: '5449',
    Name: 'Turner, ivory'
  },
  {
    Id: 16596,
    Code: '5449',
    Name: 'Turner, wheel, abrasives mfr'
  },
  {
    Id: 16597,
    Code: '5449',
    Name: 'Turner, precious metal, plate mfr'
  },
  {
    Id: 16598,
    Code: '5449',
    Name: 'Varnisher, spray'
  },
  {
    Id: 16599,
    Code: '5449',
    Name: 'Voicer, organ'
  },
  {
    Id: 16600,
    Code: '5449',
    Name: 'Voicer, organ'
  },
  {
    Id: 16601,
    Code: '5449',
    Name: 'Weaver, cane'
  },
  {
    Id: 16602,
    Code: '5449',
    Name: 'Weaver, hair, wig mfr'
  },
  {
    Id: 16603,
    Code: '5449',
    Name: 'Weaver, withy'
  },
  {
    Id: 16604,
    Code: '5449',
    Name: 'Weaver, basket mfr'
  },
  {
    Id: 16605,
    Code: '5449',
    Name: 'Weaver, wig mfr'
  },
  {
    Id: 16606,
    Code: '5449',
    Name: 'Wirer, artificial flower mfr'
  },
  {
    Id: 16607,
    Code: '5449',
    Name: 'Worker, basket'
  },
  {
    Id: 16608,
    Code: '5449',
    Name: 'Worker, brush'
  },
  {
    Id: 16609,
    Code: '5449',
    Name: 'Worker, cane'
  },
  {
    Id: 16610,
    Code: '5449',
    Name: 'Worker, carbon'
  },
  {
    Id: 16611,
    Code: '5449',
    Name: 'Worker, craft'
  },
  {
    Id: 16612,
    Code: '5449',
    Name: 'Worker, crystal, quartz'
  },
  {
    Id: 16613,
    Code: '5449',
    Name: 'Worker, detonator, chemical mfr'
  },
  {
    Id: 16614,
    Code: '5449',
    Name: 'Worker, jet'
  },
  {
    Id: 16615,
    Code: '5449',
    Name: 'Worker, metal, precious'
  },
  {
    Id: 16616,
    Code: '5449',
    Name: 'Worker, metal, white'
  },
  {
    Id: 16617,
    Code: '5449',
    Name: 'Worker, ornament, black'
  },
  {
    Id: 16618,
    Code: '5449',
    Name: 'Worker, pierce'
  },
  {
    Id: 16619,
    Code: '5449',
    Name: 'Worker, silver'
  },
  {
    Id: 16620,
    Code: '5449',
    Name: 'Worker, stone, artificial'
  },
  {
    Id: 16621,
    Code: '5449',
    Name: 'Worker, stone, precious'
  },
  {
    Id: 16622,
    Code: '5449',
    Name: 'Worker, willow'
  },
  {
    Id: 16623,
    Code: '5449',
    Name: 'Worker, wire, silver, plate mfr'
  },
  {
    Id: 16624,
    Code: '5449',
    Name: 'Writer, glass'
  },
  {
    Id: 16625,
    Code: '5449',
    Name: 'Writer, letter, signwriting'
  },
  {
    Id: 16626,
    Code: '5449',
    Name: 'Writer, poster'
  },
  {
    Id: 16627,
    Code: '5449',
    Name: 'Writer, sign'
  },
  {
    Id: 16628,
    Code: '5449',
    Name: 'Writer, ticket'
  },
  {
    Id: 16629,
    Code: '5449',
    Name: 'Writer, coach building'
  },
  {
    Id: 16630,
    Code: '5449',
    Name: 'Writer, signwriting'
  },
  {
    Id: 16631,
    Code: '5449',
    Name: 'Writer to the trade'
  },
  {
    Id: 16632,
    Code: '6111',
    Name: 'Assistant, care, child'
  },
  {
    Id: 16633,
    Code: '6111',
    Name: "Assistant, care, children's nursery"
  },
  {
    Id: 16634,
    Code: '6111',
    Name: 'Assistant, crèche'
  },
  {
    Id: 16635,
    Code: '6111',
    Name: 'Assistant, general, children’s nursery'
  },
  {
    Id: 16636,
    Code: '6111',
    Name: 'Assistant, kindergarten'
  },
  {
    Id: 16637,
    Code: '6111',
    Name: 'Assistant, nursery'
  },
  {
    Id: 16638,
    Code: '6111',
    Name: 'Assistant, playgroup'
  },
  {
    Id: 16639,
    Code: '6111',
    Name: 'Assistant, playschool'
  },
  {
    Id: 16640,
    Code: '6111',
    Name: 'Assistant, pre-school'
  },
  {
    Id: 16641,
    Code: '6111',
    Name: 'Assistant, school, play'
  },
  {
    Id: 16642,
    Code: '6111',
    Name: 'Assistant, schools, play'
  },
  {
    Id: 16643,
    Code: '6111',
    Name: 'Assistant, years, early'
  },
  {
    Id: 16644,
    Code: '6111',
    Name: 'Attendant, crèche'
  },
  {
    Id: 16645,
    Code: '6111',
    Name: 'Attendant, nursery'
  },
  {
    Id: 16646,
    Code: '6111',
    Name: 'Governess, nursery'
  },
  {
    Id: 16647,
    Code: '6111',
    Name: 'Helper, crèche'
  },
  {
    Id: 16648,
    Code: '6111',
    Name: 'Helper, nursery'
  },
  {
    Id: 16649,
    Code: '6111',
    Name: 'Helper, playgroup'
  },
  {
    Id: 16650,
    Code: '6111',
    Name: 'Helper, playschool'
  },
  {
    Id: 16651,
    Code: '6111',
    Name: 'Helper, pre-school'
  },
  {
    Id: 16652,
    Code: '6111',
    Name: 'Leader, crèche'
  },
  {
    Id: 16653,
    Code: '6111',
    Name: 'Leader, playgroup'
  },
  {
    Id: 16654,
    Code: '6111',
    Name: 'Leader, pre-school'
  },
  {
    Id: 16655,
    Code: '6111',
    Name: 'Leader, room, nursery'
  },
  {
    Id: 16656,
    Code: '6111',
    Name: 'Leader, room, children’s nursery'
  },
  {
    Id: 16657,
    Code: '6111',
    Name: 'NNEB'
  },
  {
    Id: 16658,
    Code: '6111',
    Name: 'Nurse, childcare'
  },
  {
    Id: 16659,
    Code: '6111',
    Name: 'Nurse, nursery, community'
  },
  {
    Id: 16660,
    Code: '6111',
    Name: 'Nurse, nursery'
  },
  {
    Id: 16661,
    Code: '6111',
    Name: 'Officer, education, nursery'
  },
  {
    Id: 16662,
    Code: '6111',
    Name: 'Officer, nursery'
  },
  {
    Id: 16663,
    Code: '6111',
    Name: 'Organiser, playgroup'
  },
  {
    Id: 16664,
    Code: '6111',
    Name: 'Supervisor, care, child'
  },
  {
    Id: 16665,
    Code: '6111',
    Name: 'Supervisor, crèche'
  },
  {
    Id: 16666,
    Code: '6111',
    Name: 'Supervisor, nursery'
  },
  {
    Id: 16667,
    Code: '6111',
    Name: 'Supervisor, playgroup'
  },
  {
    Id: 16668,
    Code: '6111',
    Name: 'Supervisor, pre-school'
  },
  {
    Id: 16669,
    Code: '6111',
    Name: 'Supervisor, school, play'
  },
  {
    Id: 16670,
    Code: '6111',
    Name: "Supervisor, children's nursery"
  },
  {
    Id: 16671,
    Code: '6111',
    Name: 'Teacher, kindergarten'
  },
  {
    Id: 16672,
    Code: '6111',
    Name: 'Teacher, nursery'
  },
  {
    Id: 16673,
    Code: '6111',
    Name: 'Teacher, pre-school'
  },
  {
    Id: 16674,
    Code: '6111',
    Name: 'Teacher, school, play'
  },
  {
    Id: 16675,
    Code: '6111',
    Name: 'Warden, day nursery'
  },
  {
    Id: 16676,
    Code: '6111',
    Name: 'Worker, crèche'
  },
  {
    Id: 16677,
    Code: '6111',
    Name: 'Worker, key, nursery'
  },
  {
    Id: 16678,
    Code: '6111',
    Name: 'Worker, key, pre-school'
  },
  {
    Id: 16679,
    Code: '6111',
    Name: 'Worker, nursery, day nursery'
  },
  {
    Id: 16680,
    Code: '6111',
    Name: 'Worker, playgroup'
  },
  {
    Id: 16681,
    Code: '6111',
    Name: 'Worker, playschool'
  },
  {
    Id: 16682,
    Code: '6111',
    Name: 'Worker, support, nursery'
  },
  {
    Id: 16683,
    Code: '6111',
    Name: 'Worker, support, years, early'
  },
  {
    Id: 16684,
    Code: '6111',
    Name: 'Worker, years, early'
  },
  {
    Id: 16685,
    Code: '6112',
    Name: "Aid, teacher's"
  },
  {
    Id: 16686,
    Code: '6112',
    Name: 'Aid, teaching'
  },
  {
    Id: 16687,
    Code: '6112',
    Name: "Aide, teacher's"
  },
  {
    Id: 16688,
    Code: '6112',
    Name: 'Aide, teaching'
  },
  {
    Id: 16689,
    Code: '6112',
    Name: 'Ancillary, classroom'
  },
  {
    Id: 16690,
    Code: '6112',
    Name: 'Ancillary, teaching, schools'
  },
  {
    Id: 16691,
    Code: '6112',
    Name: 'Ancillary, educational establishments'
  },
  {
    Id: 16692,
    Code: '6112',
    Name: 'Assistant, ancillary, educational establishments'
  },
  {
    Id: 16693,
    Code: '6112',
    Name: 'Assistant, auxiliary, educational establishments'
  },
  {
    Id: 16694,
    Code: '6112',
    Name: 'Assistant, care, schools'
  },
  {
    Id: 16695,
    Code: '6112',
    Name: 'Assistant, class, nursery'
  },
  {
    Id: 16696,
    Code: '6112',
    Name: 'Assistant, classroom'
  },
  {
    Id: 16697,
    Code: '6112',
    Name: 'Assistant, education'
  },
  {
    Id: 16698,
    Code: '6112',
    Name: 'Assistant, educational'
  },
  {
    Id: 16699,
    Code: '6112',
    Name: 'Assistant, general, educational establishments'
  },
  {
    Id: 16700,
    Code: '6112',
    Name: 'Assistant, room, class'
  },
  {
    Id: 16701,
    Code: '6112',
    Name: 'Assistant, school, forest'
  },
  {
    Id: 16702,
    Code: '6112',
    Name: 'Assistant, school, nursery'
  },
  {
    Id: 16703,
    Code: '6112',
    Name: 'Assistant, school'
  },
  {
    Id: 16704,
    Code: '6112',
    Name: 'Assistant, schools, nursery'
  },
  {
    Id: 16705,
    Code: '6112',
    Name: 'Assistant, schools'
  },
  {
    Id: 16706,
    Code: '6112',
    Name: 'Assistant, support, classroom'
  },
  {
    Id: 16707,
    Code: '6112',
    Name: 'Assistant, support, curriculum'
  },
  {
    Id: 16708,
    Code: '6112',
    Name: 'Assistant, teacher'
  },
  {
    Id: 16709,
    Code: '6112',
    Name: "Assistant, teacher's"
  },
  {
    Id: 16710,
    Code: '6112',
    Name: 'Assistant, teaching'
  },
  {
    Id: 16711,
    Code: '6112',
    Name: 'Attendant, class'
  },
  {
    Id: 16712,
    Code: '6112',
    Name: 'Auxiliary, school'
  },
  {
    Id: 16713,
    Code: '6112',
    Name: 'Auxiliary, teaching'
  },
  {
    Id: 16714,
    Code: '6112',
    Name: 'Auxiliary, educational establishments'
  },
  {
    Id: 16715,
    Code: '6112',
    Name: 'Help, school'
  },
  {
    Id: 16716,
    Code: '6112',
    Name: "Help, teacher's"
  },
  {
    Id: 16717,
    Code: '6112',
    Name: 'Helper, ancillary, schools'
  },
  {
    Id: 16718,
    Code: '6112',
    Name: 'Helper, classroom'
  },
  {
    Id: 16719,
    Code: '6112',
    Name: 'Helper, primary'
  },
  {
    Id: 16720,
    Code: '6112',
    Name: 'Helper, school'
  },
  {
    Id: 16721,
    Code: '6112',
    Name: 'Supervisor, cover, education'
  },
  {
    Id: 16722,
    Code: '6112',
    Name: 'Supervisor, cover, educational establishments'
  },
  {
    Id: 16723,
    Code: '6112',
    Name: 'TA'
  },
  {
    Id: 16724,
    Code: '6112',
    Name: 'Technician, economics, home'
  },
  {
    Id: 16725,
    Code: '6112',
    Name: 'Technician, food, educational establishments'
  },
  {
    Id: 16726,
    Code: '6112',
    Name: "Worker, support, teacher's"
  },
  {
    Id: 16727,
    Code: '6113',
    Name: 'Assistant, classroom, needs, additional'
  },
  {
    Id: 16728,
    Code: '6113',
    Name: 'Assistant, classroom, special/additional needs'
  },
  {
    Id: 16729,
    Code: '6113',
    Name: 'Assistant, education, special/additional needs'
  },
  {
    Id: 16730,
    Code: '6113',
    Name: 'Assistant, integration, schools'
  },
  {
    Id: 16731,
    Code: '6113',
    Name: 'Assistant, learning, educational establishments'
  },
  {
    Id: 16732,
    Code: '6113',
    Name: 'Assistant, needs, special, schools'
  },
  {
    Id: 16733,
    Code: '6113',
    Name: 'Assistant, needs, special'
  },
  {
    Id: 16734,
    Code: '6113',
    Name: 'Assistant, non-teaching, schools'
  },
  {
    Id: 16735,
    Code: '6113',
    Name: 'Assistant, school, special'
  },
  {
    Id: 16736,
    Code: '6113',
    Name: 'Assistant, schools, special'
  },
  {
    Id: 16737,
    Code: '6113',
    Name: 'Assistant, support, behaviour'
  },
  {
    Id: 16738,
    Code: '6113',
    Name: 'Assistant, support, education'
  },
  {
    Id: 16739,
    Code: '6113',
    Name: 'Assistant, support, educational'
  },
  {
    Id: 16740,
    Code: '6113',
    Name: 'Assistant, support, individual'
  },
  {
    Id: 16741,
    Code: '6113',
    Name: 'Assistant, support, learning'
  },
  {
    Id: 16742,
    Code: '6113',
    Name: 'Assistant, support, needs, additional'
  },
  {
    Id: 16743,
    Code: '6113',
    Name: 'Assistant, support, pupil'
  },
  {
    Id: 16744,
    Code: '6113',
    Name: 'Assistant, support, special, schools'
  },
  {
    Id: 16745,
    Code: '6113',
    Name: 'Assistant, support, student'
  },
  {
    Id: 16746,
    Code: '6113',
    Name: 'Assistant, support, teaching'
  },
  {
    Id: 16747,
    Code: '6113',
    Name: 'Assistant, support, educational establishments'
  },
  {
    Id: 16748,
    Code: '6113',
    Name: 'Assistant, support for learning'
  },
  {
    Id: 16749,
    Code: '6113',
    Name: 'Assistant, teacher, special/additional needs'
  },
  {
    Id: 16750,
    Code: '6113',
    Name: "Assistant, teacher's, special/additional needs"
  },
  {
    Id: 16751,
    Code: '6113',
    Name: 'Assistant, teaching, 1:1, special/additional needs'
  },
  {
    Id: 16752,
    Code: '6113',
    Name: 'Assistant, teaching, needs, additional'
  },
  {
    Id: 16753,
    Code: '6113',
    Name: 'Assistant, teaching, needs, educational, special'
  },
  {
    Id: 16754,
    Code: '6113',
    Name: 'Assistant, teaching, needs, special'
  },
  {
    Id: 16755,
    Code: '6113',
    Name: 'Assistant, teaching, one to one, special/additional needs'
  },
  {
    Id: 16756,
    Code: '6113',
    Name: 'Assistant, teaching, SEN'
  },
  {
    Id: 16757,
    Code: '6113',
    Name: 'Assistant, teaching, special/additional needs'
  },
  {
    Id: 16758,
    Code: '6113',
    Name: 'Assistant, teaching and learning'
  },
  {
    Id: 16759,
    Code: '6113',
    Name: 'Assistant, welfare, school'
  },
  {
    Id: 16760,
    Code: '6113',
    Name: 'Assistant, welfare, schools'
  },
  {
    Id: 16761,
    Code: '6113',
    Name: 'Auxiliary, needs, special'
  },
  {
    Id: 16762,
    Code: '6113',
    Name: 'Coordinator, support, learning'
  },
  {
    Id: 16763,
    Code: '6113',
    Name: 'Helper, needs, special'
  },
  {
    Id: 16764,
    Code: '6113',
    Name: 'LSA'
  },
  {
    Id: 16765,
    Code: '6113',
    Name: 'Officer, care, education'
  },
  {
    Id: 16766,
    Code: '6113',
    Name: 'Officer, support, learning'
  },
  {
    Id: 16767,
    Code: '6113',
    Name: 'TA, special/additional needs'
  },
  {
    Id: 16768,
    Code: '6113',
    Name: 'Taker, note, educational establishments'
  },
  {
    Id: 16769,
    Code: '6113',
    Name: 'Worker, ancillary, educational establishments'
  },
  {
    Id: 16770,
    Code: '6113',
    Name: 'Worker, support, behaviour'
  },
  {
    Id: 16771,
    Code: '6113',
    Name: 'Worker, support, communication, educational establishments'
  },
  {
    Id: 16772,
    Code: '6113',
    Name: 'Worker, support, education'
  },
  {
    Id: 16773,
    Code: '6113',
    Name: 'Worker, support, educational'
  },
  {
    Id: 16774,
    Code: '6113',
    Name: 'Worker, support, inclusion, educational establishments'
  },
  {
    Id: 16775,
    Code: '6113',
    Name: 'Worker, support, learning'
  },
  {
    Id: 16776,
    Code: '6113',
    Name: 'Worker, support, student'
  },
  {
    Id: 16777,
    Code: '6113',
    Name: 'Worker, support, educational establishments'
  },
  {
    Id: 16778,
    Code: '6113',
    Name: 'Worker, welfare, schools'
  },
  {
    Id: 16779,
    Code: '6114',
    Name: 'Babysitter'
  },
  {
    Id: 16780,
    Code: '6114',
    Name: 'Carer, child'
  },
  {
    Id: 16781,
    Code: '6114',
    Name: 'Childminder'
  },
  {
    Id: 16782,
    Code: '6114',
    Name: "Help, mother's"
  },
  {
    Id: 16783,
    Code: '6114',
    Name: 'Maid, nurse'
  },
  {
    Id: 16784,
    Code: '6114',
    Name: 'Minder, child'
  },
  {
    Id: 16785,
    Code: '6114',
    Name: "Nurse, children's, domestic service"
  },
  {
    Id: 16786,
    Code: '6114',
    Name: 'Nursemaid'
  },
  {
    Id: 16787,
    Code: '6114',
    Name: 'Sitter, baby'
  },
  {
    Id: 16788,
    Code: '6116',
    Name: 'Au pair'
  },
  {
    Id: 16789,
    Code: '6116',
    Name: 'Nannie'
  },
  {
    Id: 16790,
    Code: '6116',
    Name: "Nanny, children's"
  },
  {
    Id: 16791,
    Code: '6116',
    Name: 'Nanny, private'
  },
  {
    Id: 16792,
    Code: '6116',
    Name: 'Nanny'
  },
  {
    Id: 16793,
    Code: '6117',
    Name: 'Assistant, centre, play'
  },
  {
    Id: 16794,
    Code: '6117',
    Name: 'Assistant, club, breakfast'
  },
  {
    Id: 16795,
    Code: '6117',
    Name: 'Assistant, club, out of school'
  },
  {
    Id: 16796,
    Code: '6117',
    Name: 'Assistant, club, school, after'
  },
  {
    Id: 16797,
    Code: '6117',
    Name: "Assistant, leader's, play"
  },
  {
    Id: 16798,
    Code: '6117',
    Name: 'Assistant, play'
  },
  {
    Id: 16799,
    Code: '6117',
    Name: 'Assistant, school, after'
  },
  {
    Id: 16800,
    Code: '6117',
    Name: 'Coordinator, activities, club, school, after'
  },
  {
    Id: 16801,
    Code: '6117',
    Name: 'Coordinator, club, out of school'
  },
  {
    Id: 16802,
    Code: '6117',
    Name: 'Helper, infant'
  },
  {
    Id: 16803,
    Code: '6117',
    Name: 'Leader, club, school'
  },
  {
    Id: 16804,
    Code: '6117',
    Name: 'Leader, play'
  },
  {
    Id: 16805,
    Code: '6117',
    Name: 'Playleader'
  },
  {
    Id: 16806,
    Code: '6117',
    Name: 'Playworker'
  },
  {
    Id: 16807,
    Code: '6117',
    Name: 'Ranger, play'
  },
  {
    Id: 16808,
    Code: '6117',
    Name: 'Supervisor, club, afterschool'
  },
  {
    Id: 16809,
    Code: '6117',
    Name: 'Worker, play'
  },
  {
    Id: 16810,
    Code: '6117',
    Name: 'Worker, playscheme'
  },
  {
    Id: 16811,
    Code: '6121',
    Name: 'Catcher, mole'
  },
  {
    Id: 16812,
    Code: '6121',
    Name: 'Catcher, rat'
  },
  {
    Id: 16813,
    Code: '6121',
    Name: 'Controller, pest'
  },
  {
    Id: 16814,
    Code: '6121',
    Name: 'Destroyer, pest'
  },
  {
    Id: 16815,
    Code: '6121',
    Name: 'Disinfector'
  },
  {
    Id: 16816,
    Code: '6121',
    Name: 'Exterminator, pest'
  },
  {
    Id: 16817,
    Code: '6121',
    Name: 'Fumigator'
  },
  {
    Id: 16818,
    Code: '6121',
    Name: 'Inspector, control, pest'
  },
  {
    Id: 16819,
    Code: '6121',
    Name: 'Inspector, infestation'
  },
  {
    Id: 16820,
    Code: '6121',
    Name: 'Inspector, rodent'
  },
  {
    Id: 16821,
    Code: '6121',
    Name: 'Officer, control, pest'
  },
  {
    Id: 16822,
    Code: '6121',
    Name: 'Officer, control, rodent'
  },
  {
    Id: 16823,
    Code: '6121',
    Name: 'Officer, disinfecting'
  },
  {
    Id: 16824,
    Code: '6121',
    Name: 'Officer, pest'
  },
  {
    Id: 16825,
    Code: '6121',
    Name: 'Officer, rodent'
  },
  {
    Id: 16826,
    Code: '6121',
    Name: 'Operative, rodent'
  },
  {
    Id: 16827,
    Code: '6121',
    Name: 'Operator, beetle, colorado'
  },
  {
    Id: 16828,
    Code: '6121',
    Name: 'Operator, control, pest'
  },
  {
    Id: 16829,
    Code: '6121',
    Name: 'Operator, rodent'
  },
  {
    Id: 16830,
    Code: '6121',
    Name: 'Specialist, woodworm'
  },
  {
    Id: 16831,
    Code: '6121',
    Name: 'Sprayer, wood preservation service'
  },
  {
    Id: 16832,
    Code: '6121',
    Name: 'Surveyor, control, pest'
  },
  {
    Id: 16833,
    Code: '6121',
    Name: 'Surveyor, infestation, timber'
  },
  {
    Id: 16834,
    Code: '6121',
    Name: 'Surveyor, pest control'
  },
  {
    Id: 16835,
    Code: '6121',
    Name: 'Technician, control, pest'
  },
  {
    Id: 16836,
    Code: '6121',
    Name: 'Technician, service, prevention, pest'
  },
  {
    Id: 16837,
    Code: '6129',
    Name: 'Aquarist'
  },
  {
    Id: 16838,
    Code: '6129',
    Name: 'Assistant, care, animal'
  },
  {
    Id: 16839,
    Code: '6129',
    Name: 'Assistant, care, veterinary'
  },
  {
    Id: 16840,
    Code: '6129',
    Name: 'Assistant, cattery'
  },
  {
    Id: 16841,
    Code: '6129',
    Name: 'Assistant, clinic, animal'
  },
  {
    Id: 16842,
    Code: '6129',
    Name: 'Assistant, daycare, kennels'
  },
  {
    Id: 16843,
    Code: '6129',
    Name: 'Assistant, kennel'
  },
  {
    Id: 16844,
    Code: '6129',
    Name: 'Assistant, livestock, retail trade'
  },
  {
    Id: 16845,
    Code: '6129',
    Name: 'Assistant, nursing, veterinary'
  },
  {
    Id: 16846,
    Code: '6129',
    Name: "Assistant, surgeon's, veterinary"
  },
  {
    Id: 16847,
    Code: '6129',
    Name: "Assistant, technical, veterinarian's"
  },
  {
    Id: 16848,
    Code: '6129',
    Name: 'Assistant, technical, veterinary surgery'
  },
  {
    Id: 16849,
    Code: '6129',
    Name: 'Assistant, trek, equestrian trekking centre'
  },
  {
    Id: 16850,
    Code: '6129',
    Name: 'Assistant, veterinary'
  },
  {
    Id: 16851,
    Code: '6129',
    Name: 'Assistant, welfare, animal'
  },
  {
    Id: 16852,
    Code: '6129',
    Name: 'Attendant, animal'
  },
  {
    Id: 16853,
    Code: '6129',
    Name: 'Attendant, health, animal'
  },
  {
    Id: 16854,
    Code: '6129',
    Name: 'Attendant, kennel'
  },
  {
    Id: 16855,
    Code: '6129',
    Name: 'Attendant, racing stables'
  },
  {
    Id: 16856,
    Code: '6129',
    Name: 'Auxiliary, nursing, animal'
  },
  {
    Id: 16857,
    Code: '6129',
    Name: 'Beautician, canine'
  },
  {
    Id: 16858,
    Code: '6129',
    Name: 'Boy, stable'
  },
  {
    Id: 16859,
    Code: '6129',
    Name: 'Breaker, horse'
  },
  {
    Id: 16860,
    Code: '6129',
    Name: 'Breeder, cat'
  },
  {
    Id: 16861,
    Code: '6129',
    Name: 'Breeder, dog'
  },
  {
    Id: 16862,
    Code: '6129',
    Name: 'Breeder, horse'
  },
  {
    Id: 16863,
    Code: '6129',
    Name: 'Breeder, bloodstock'
  },
  {
    Id: 16864,
    Code: '6129',
    Name: 'Breeder, cat'
  },
  {
    Id: 16865,
    Code: '6129',
    Name: 'Breeder, dog'
  },
  {
    Id: 16866,
    Code: '6129',
    Name: 'Breeder, horse'
  },
  {
    Id: 16867,
    Code: '6129',
    Name: 'Carer, animal'
  },
  {
    Id: 16868,
    Code: '6129',
    Name: 'Carer, canine'
  },
  {
    Id: 16869,
    Code: '6129',
    Name: 'Carer, cat'
  },
  {
    Id: 16870,
    Code: '6129',
    Name: 'Carer, pet'
  },
  {
    Id: 16871,
    Code: '6129',
    Name: 'Careworker, animal'
  },
  {
    Id: 16872,
    Code: '6129',
    Name: 'Clipper, dog'
  },
  {
    Id: 16873,
    Code: '6129',
    Name: 'Clipper, horse'
  },
  {
    Id: 16874,
    Code: '6129',
    Name: 'Foreman, stable'
  },
  {
    Id: 16875,
    Code: '6129',
    Name: 'Foreman, zoological gardens'
  },
  {
    Id: 16876,
    Code: '6129',
    Name: 'Girl, stable'
  },
  {
    Id: 16877,
    Code: '6129',
    Name: 'Groom, equine'
  },
  {
    Id: 16878,
    Code: '6129',
    Name: 'Groom, head'
  },
  {
    Id: 16879,
    Code: '6129',
    Name: 'Groom'
  },
  {
    Id: 16880,
    Code: '6129',
    Name: 'Groom-gardener'
  },
  {
    Id: 16881,
    Code: '6129',
    Name: 'Groomer, dog'
  },
  {
    Id: 16882,
    Code: '6129',
    Name: 'Groomsman'
  },
  {
    Id: 16883,
    Code: '6129',
    Name: 'Hand, kennel'
  },
  {
    Id: 16884,
    Code: '6129',
    Name: 'Hand, stable'
  },
  {
    Id: 16885,
    Code: '6129',
    Name: 'Hand, stud'
  },
  {
    Id: 16886,
    Code: '6129',
    Name: 'Handler, animal'
  },
  {
    Id: 16887,
    Code: '6129',
    Name: 'Handler, dog'
  },
  {
    Id: 16888,
    Code: '6129',
    Name: 'Headman, racing stables'
  },
  {
    Id: 16889,
    Code: '6129',
    Name: 'Helper, stable'
  },
  {
    Id: 16890,
    Code: '6129',
    Name: 'Horseman'
  },
  {
    Id: 16891,
    Code: '6129',
    Name: 'Hostler'
  },
  {
    Id: 16892,
    Code: '6129',
    Name: 'Hunter'
  },
  {
    Id: 16893,
    Code: '6129',
    Name: 'Huntsman'
  },
  {
    Id: 16894,
    Code: '6129',
    Name: 'Keeper, animal'
  },
  {
    Id: 16895,
    Code: '6129',
    Name: 'Keeper, head, zoological gardens'
  },
  {
    Id: 16896,
    Code: '6129',
    Name: 'Keeper, horse, head'
  },
  {
    Id: 16897,
    Code: '6129',
    Name: 'Keeper, horse'
  },
  {
    Id: 16898,
    Code: '6129',
    Name: 'Keeper, kennel'
  },
  {
    Id: 16899,
    Code: '6129',
    Name: 'Keeper, menagerie'
  },
  {
    Id: 16900,
    Code: '6129',
    Name: 'Keeper, stable, livery'
  },
  {
    Id: 16901,
    Code: '6129',
    Name: 'Keeper, stable'
  },
  {
    Id: 16902,
    Code: '6129',
    Name: 'Keeper, zoo'
  },
  {
    Id: 16903,
    Code: '6129',
    Name: 'Keeper, zoological gardens'
  },
  {
    Id: 16904,
    Code: '6129',
    Name: 'Lad, head, racing stables'
  },
  {
    Id: 16905,
    Code: '6129',
    Name: 'Lad, stable'
  },
  {
    Id: 16906,
    Code: '6129',
    Name: 'Leader, trek, equestrian trekking centre'
  },
  {
    Id: 16907,
    Code: '6129',
    Name: 'Maid, kennel'
  },
  {
    Id: 16908,
    Code: '6129',
    Name: 'Man, yard, livery stable'
  },
  {
    Id: 16909,
    Code: '6129',
    Name: 'Manager, stable'
  },
  {
    Id: 16910,
    Code: '6129',
    Name: 'Minder, animal'
  },
  {
    Id: 16911,
    Code: '6129',
    Name: 'Officer, collection, animal'
  },
  {
    Id: 16912,
    Code: '6129',
    Name: 'Officer, welfare, animal'
  },
  {
    Id: 16913,
    Code: '6129',
    Name: 'Ostler'
  },
  {
    Id: 16914,
    Code: '6129',
    Name: 'Rider, work, stables'
  },
  {
    Id: 16915,
    Code: '6129',
    Name: 'Servant, hunt'
  },
  {
    Id: 16916,
    Code: '6129',
    Name: 'Sitter, dog'
  },
  {
    Id: 16917,
    Code: '6129',
    Name: 'Sitter, pet'
  },
  {
    Id: 16918,
    Code: '6129',
    Name: 'Stableman'
  },
  {
    Id: 16919,
    Code: '6129',
    Name: 'Stripper, dog'
  },
  {
    Id: 16920,
    Code: '6129',
    Name: 'Studhand'
  },
  {
    Id: 16921,
    Code: '6129',
    Name: 'Technician, animal'
  },
  {
    Id: 16922,
    Code: '6129',
    Name: 'Technician, laboratory, care, animal'
  },
  {
    Id: 16923,
    Code: '6129',
    Name: 'Technician, laboratory, animal care'
  },
  {
    Id: 16924,
    Code: '6129',
    Name: 'Trainer, animal'
  },
  {
    Id: 16925,
    Code: '6129',
    Name: 'Trainer, dog'
  },
  {
    Id: 16926,
    Code: '6129',
    Name: 'Trainer, greyhound'
  },
  {
    Id: 16927,
    Code: '6129',
    Name: 'Trainer, horse'
  },
  {
    Id: 16928,
    Code: '6129',
    Name: 'Trainer, pony'
  },
  {
    Id: 16929,
    Code: '6129',
    Name: 'Trimmer, barefoot, equine'
  },
  {
    Id: 16930,
    Code: '6129',
    Name: 'Walker, dog'
  },
  {
    Id: 16931,
    Code: '6129',
    Name: 'Warden, dog'
  },
  {
    Id: 16932,
    Code: '6129',
    Name: 'Whip, hunting'
  },
  {
    Id: 16933,
    Code: '6129',
    Name: 'Whipper-in, hunting'
  },
  {
    Id: 16934,
    Code: '6129',
    Name: 'Worker, care, animal'
  },
  {
    Id: 16935,
    Code: '6129',
    Name: 'Worker, kennel'
  },
  {
    Id: 16936,
    Code: '6129',
    Name: 'Yardman, livery stable'
  },
  {
    Id: 16937,
    Code: '6129',
    Name: 'Yardsman, livery stable'
  },
  {
    Id: 16938,
    Code: '6131',
    Name: "Aid, nurse's"
  },
  {
    Id: 16939,
    Code: '6131',
    Name: 'Aid, hospital service'
  },
  {
    Id: 16940,
    Code: '6131',
    Name: "Aide, nurse's"
  },
  {
    Id: 16941,
    Code: '6131',
    Name: 'Aide, ward'
  },
  {
    Id: 16942,
    Code: '6131',
    Name: 'Aide, hospital service'
  },
  {
    Id: 16943,
    Code: '6131',
    Name: 'Aider, first'
  },
  {
    Id: 16944,
    Code: '6131',
    Name: 'Assistant, anaesthetic'
  },
  {
    Id: 16945,
    Code: '6131',
    Name: 'Assistant, care, foot'
  },
  {
    Id: 16946,
    Code: '6131',
    Name: 'Assistant, care, health, theatre'
  },
  {
    Id: 16947,
    Code: '6131',
    Name: 'Assistant, care, health, hospital service'
  },
  {
    Id: 16948,
    Code: '6131',
    Name: 'Assistant, care, health, medical practice'
  },
  {
    Id: 16949,
    Code: '6131',
    Name: 'Assistant, care, health'
  },
  {
    Id: 16950,
    Code: '6131',
    Name: 'Assistant, care, maternity'
  },
  {
    Id: 16951,
    Code: '6131',
    Name: 'Assistant, care, hospital service'
  },
  {
    Id: 16952,
    Code: '6131',
    Name: 'Assistant, chiropody'
  },
  {
    Id: 16953,
    Code: '6131',
    Name: 'Assistant, chiropractic'
  },
  {
    Id: 16954,
    Code: '6131',
    Name: 'Assistant, clinic'
  },
  {
    Id: 16955,
    Code: '6131',
    Name: 'Assistant, clinical'
  },
  {
    Id: 16956,
    Code: '6131',
    Name: 'Assistant, CSSD'
  },
  {
    Id: 16957,
    Code: '6131',
    Name: 'Assistant, department, operating'
  },
  {
    Id: 16958,
    Code: '6131',
    Name: 'Assistant, dialysis'
  },
  {
    Id: 16959,
    Code: '6131',
    Name: 'Assistant, donor, blood'
  },
  {
    Id: 16960,
    Code: '6131',
    Name: 'Assistant, health, school'
  },
  {
    Id: 16961,
    Code: '6131',
    Name: 'Assistant, health, hospital service'
  },
  {
    Id: 16962,
    Code: '6131',
    Name: 'Assistant, healthcare, hospital service'
  },
  {
    Id: 16963,
    Code: '6131',
    Name: 'Assistant, healthcare, medical practice'
  },
  {
    Id: 16964,
    Code: '6131',
    Name: 'Assistant, healthcare'
  },
  {
    Id: 16965,
    Code: '6131',
    Name: 'Assistant, HSDU'
  },
  {
    Id: 16966,
    Code: '6131',
    Name: 'Assistant, imaging, radiology'
  },
  {
    Id: 16967,
    Code: '6131',
    Name: 'Assistant, maternity'
  },
  {
    Id: 16968,
    Code: '6131',
    Name: 'Assistant, medical'
  },
  {
    Id: 16969,
    Code: '6131',
    Name: 'Assistant, midwifery'
  },
  {
    Id: 16970,
    Code: '6131',
    Name: 'Assistant, nursing, health, mental'
  },
  {
    Id: 16971,
    Code: '6131',
    Name: 'Assistant, nursing'
  },
  {
    Id: 16972,
    Code: '6131',
    Name: 'Assistant, physio'
  },
  {
    Id: 16973,
    Code: '6131',
    Name: 'Assistant, physiotherapy'
  },
  {
    Id: 16974,
    Code: '6131',
    Name: 'Assistant, podiatry'
  },
  {
    Id: 16975,
    Code: '6131',
    Name: "Assistant, radiographer's"
  },
  {
    Id: 16976,
    Code: '6131',
    Name: 'Assistant, radiography'
  },
  {
    Id: 16977,
    Code: '6131',
    Name: 'Assistant, radiology'
  },
  {
    Id: 16978,
    Code: '6131',
    Name: 'Assistant, rehabilitation, hospital service'
  },
  {
    Id: 16979,
    Code: '6131',
    Name: 'Assistant, senior, CSSD'
  },
  {
    Id: 16980,
    Code: '6131',
    Name: 'Assistant, service, sterile'
  },
  {
    Id: 16981,
    Code: '6131',
    Name: 'Assistant, services, sterile'
  },
  {
    Id: 16982,
    Code: '6131',
    Name: 'Assistant, sterilising'
  },
  {
    Id: 16983,
    Code: '6131',
    Name: 'Assistant, surgery'
  },
  {
    Id: 16984,
    Code: '6131',
    Name: 'Assistant, team, clinical'
  },
  {
    Id: 16985,
    Code: '6131',
    Name: 'Assistant, theatre, operating'
  },
  {
    Id: 16986,
    Code: '6131',
    Name: 'Assistant, theatre, hospital service'
  },
  {
    Id: 16987,
    Code: '6131',
    Name: 'Assistant, therapist, occupational'
  },
  {
    Id: 16988,
    Code: '6131',
    Name: 'Assistant, therapy, occupational'
  },
  {
    Id: 16989,
    Code: '6131',
    Name: 'Assistant, therapy, speech and language'
  },
  {
    Id: 16990,
    Code: '6131',
    Name: 'Assistant, therapy, occupational therapy'
  },
  {
    Id: 16991,
    Code: '6131',
    Name: 'Assistant, TSSU'
  },
  {
    Id: 16992,
    Code: '6131',
    Name: 'Assistant, ward'
  },
  {
    Id: 16993,
    Code: '6131',
    Name: 'Assistant, x-ray'
  },
  {
    Id: 16994,
    Code: '6131',
    Name: 'Attendant, aid, first'
  },
  {
    Id: 16995,
    Code: '6131',
    Name: 'Attendant, bay, sick'
  },
  {
    Id: 16996,
    Code: '6131',
    Name: 'Attendant, clinic'
  },
  {
    Id: 16997,
    Code: '6131',
    Name: 'Attendant, donor, blood'
  },
  {
    Id: 16998,
    Code: '6131',
    Name: 'Attendant, hospital'
  },
  {
    Id: 16999,
    Code: '6131',
    Name: 'Attendant, hydrotherapy'
  },
  {
    Id: 17000,
    Code: '6131',
    Name: 'Attendant, medical'
  },
  {
    Id: 17001,
    Code: '6131',
    Name: 'Attendant, night, hospital service'
  },
  {
    Id: 17002,
    Code: '6131',
    Name: 'Attendant, nursing'
  },
  {
    Id: 17003,
    Code: '6131',
    Name: 'Attendant, room, aid, first'
  },
  {
    Id: 17004,
    Code: '6131',
    Name: 'Attendant, room, medical'
  },
  {
    Id: 17005,
    Code: '6131',
    Name: 'Attendant, room, sick'
  },
  {
    Id: 17006,
    Code: '6131',
    Name: 'Attendant, sterilizer, medical services'
  },
  {
    Id: 17007,
    Code: '6131',
    Name: 'Attendant, surgery'
  },
  {
    Id: 17008,
    Code: '6131',
    Name: 'Attendant, theatre, hospital service'
  },
  {
    Id: 17009,
    Code: '6131',
    Name: 'Attendant, ward'
  },
  {
    Id: 17010,
    Code: '6131',
    Name: 'Attendant, hospital service'
  },
  {
    Id: 17011,
    Code: '6131',
    Name: 'Auxiliary, nursing'
  },
  {
    Id: 17012,
    Code: '6131',
    Name: 'CSW'
  },
  {
    Id: 17013,
    Code: '6131',
    Name: 'Carer, donor, blood'
  },
  {
    Id: 17014,
    Code: '6131',
    Name: 'Carer, donor, National Health Service Blood and Transplant'
  },
  {
    Id: 17015,
    Code: '6131',
    Name: 'Collector, blood, hospital service'
  },
  {
    Id: 17016,
    Code: '6131',
    Name: 'Doula'
  },
  {
    Id: 17017,
    Code: '6131',
    Name: 'HCA, hospital service'
  },
  {
    Id: 17018,
    Code: '6131',
    Name: 'HCA, medical practice'
  },
  {
    Id: 17019,
    Code: '6131',
    Name: 'HCA'
  },
  {
    Id: 17020,
    Code: '6131',
    Name: 'HCSW'
  },
  {
    Id: 17021,
    Code: '6131',
    Name: 'Help, ward'
  },
  {
    Id: 17022,
    Code: '6131',
    Name: 'Helper, physiotherapy'
  },
  {
    Id: 17023,
    Code: '6131',
    Name: "Helper, radiographer's"
  },
  {
    Id: 17024,
    Code: '6131',
    Name: 'Helper, therapy, occupational'
  },
  {
    Id: 17025,
    Code: '6131',
    Name: 'Helper, ward'
  },
  {
    Id: 17026,
    Code: '6131',
    Name: 'Hostess, ward'
  },
  {
    Id: 17027,
    Code: '6131',
    Name: 'Immuniser, COVID'
  },
  {
    Id: 17028,
    Code: '6131',
    Name: 'Immuniser'
  },
  {
    Id: 17029,
    Code: '6131',
    Name: 'Instructor, aid, first'
  },
  {
    Id: 17030,
    Code: '6131',
    Name: 'Instructor, technical, physiotherapy'
  },
  {
    Id: 17031,
    Code: '6131',
    Name: 'Instructor, technical, therapy, occupational'
  },
  {
    Id: 17032,
    Code: '6131',
    Name: 'Instructor, technical, occupational therapy'
  },
  {
    Id: 17033,
    Code: '6131',
    Name: 'Nurse, ancillary'
  },
  {
    Id: 17034,
    Code: '6131',
    Name: 'Nurse, assistant'
  },
  {
    Id: 17035,
    Code: '6131',
    Name: 'Nurse, auxiliary'
  },
  {
    Id: 17036,
    Code: '6131',
    Name: 'Nurse, bank, auxiliary'
  },
  {
    Id: 17037,
    Code: '6131',
    Name: 'Nurse, orderly'
  },
  {
    Id: 17038,
    Code: '6131',
    Name: 'Nurse, grade A, B'
  },
  {
    Id: 17039,
    Code: '6131',
    Name: 'Officer, aid, first'
  },
  {
    Id: 17040,
    Code: '6131',
    Name: 'Officer, hospital, prison service'
  },
  {
    Id: 17041,
    Code: '6131',
    Name: 'Operator, sterilizer, hospital service'
  },
  {
    Id: 17042,
    Code: '6131',
    Name: 'Orderly, hospital'
  },
  {
    Id: 17043,
    Code: '6131',
    Name: 'Orderly, medical'
  },
  {
    Id: 17044,
    Code: '6131',
    Name: 'Orderly, nursing'
  },
  {
    Id: 17045,
    Code: '6131',
    Name: 'Orderly, theatre, operating'
  },
  {
    Id: 17046,
    Code: '6131',
    Name: 'Orderly, theatre, hospital service'
  },
  {
    Id: 17047,
    Code: '6131',
    Name: 'Orderly, ward'
  },
  {
    Id: 17048,
    Code: '6131',
    Name: 'Orderly, hospital service'
  },
  {
    Id: 17049,
    Code: '6131',
    Name: 'Phlebotomist'
  },
  {
    Id: 17050,
    Code: '6131',
    Name: 'Responder, first'
  },
  {
    Id: 17051,
    Code: '6131',
    Name: 'Setter, tray, hospital sterile supplies'
  },
  {
    Id: 17052,
    Code: '6131',
    Name: 'Steriliser, hospital service'
  },
  {
    Id: 17053,
    Code: '6131',
    Name: 'Steward, ward'
  },
  {
    Id: 17054,
    Code: '6131',
    Name: 'Supervisor, testing, COVID'
  },
  {
    Id: 17055,
    Code: '6131',
    Name: 'Technician, CSSD'
  },
  {
    Id: 17056,
    Code: '6131',
    Name: 'Technician, decontamination, hospital sterile supplies'
  },
  {
    Id: 17057,
    Code: '6131',
    Name: 'Technician, HSDU'
  },
  {
    Id: 17058,
    Code: '6131',
    Name: 'Technician, physiotherapy'
  },
  {
    Id: 17059,
    Code: '6131',
    Name: 'Technician, services, sterile'
  },
  {
    Id: 17060,
    Code: '6131',
    Name: 'Technician, sterile, hospital sterile supplies'
  },
  {
    Id: 17061,
    Code: '6131',
    Name: 'Technician, therapy, occupational'
  },
  {
    Id: 17062,
    Code: '6131',
    Name: 'Tester, COVID'
  },
  {
    Id: 17063,
    Code: '6131',
    Name: 'Tester, flow, lateral'
  },
  {
    Id: 17064,
    Code: '6131',
    Name: 'Tester, swab, COVID'
  },
  {
    Id: 17065,
    Code: '6131',
    Name: 'Trainer, aid, first'
  },
  {
    Id: 17066,
    Code: '6131',
    Name: 'Trainer, IMPS'
  },
  {
    Id: 17067,
    Code: '6131',
    Name: 'Vaccinator, COVID'
  },
  {
    Id: 17068,
    Code: '6131',
    Name: 'Vaccinator'
  },
  {
    Id: 17069,
    Code: '6131',
    Name: 'Venepuncturist'
  },
  {
    Id: 17070,
    Code: '6131',
    Name: 'Venesectionist'
  },
  {
    Id: 17071,
    Code: '6131',
    Name: 'Venesector'
  },
  {
    Id: 17072,
    Code: '6131',
    Name: 'Worker, ancillary, hospital service'
  },
  {
    Id: 17073,
    Code: '6131',
    Name: 'Worker, care, hospital service'
  },
  {
    Id: 17074,
    Code: '6131',
    Name: 'Worker, healthcare, hospital service'
  },
  {
    Id: 17075,
    Code: '6131',
    Name: 'Worker, hospital'
  },
  {
    Id: 17076,
    Code: '6131',
    Name: 'Worker, support, care, health'
  },
  {
    Id: 17077,
    Code: '6131',
    Name: 'Worker, support, care'
  },
  {
    Id: 17078,
    Code: '6131',
    Name: 'Worker, support, clinical'
  },
  {
    Id: 17079,
    Code: '6131',
    Name: 'Worker, support, health, hospital service'
  },
  {
    Id: 17080,
    Code: '6131',
    Name: 'Worker, support, healthcare'
  },
  {
    Id: 17081,
    Code: '6131',
    Name: 'Worker, support, maternity'
  },
  {
    Id: 17082,
    Code: '6131',
    Name: 'Worker, support, midwifery'
  },
  {
    Id: 17083,
    Code: '6131',
    Name: 'Worker, support, therapy, occupational'
  },
  {
    Id: 17084,
    Code: '6131',
    Name: 'Worker, support, therapy, occupational therapy'
  },
  {
    Id: 17085,
    Code: '6131',
    Name: 'Worker, support, hospital service'
  },
  {
    Id: 17086,
    Code: '6132',
    Name: 'ACA, ambulance service'
  },
  {
    Id: 17087,
    Code: '6132',
    Name: 'Ambulanceman'
  },
  {
    Id: 17088,
    Code: '6132',
    Name: 'Assistant, care, ambulance'
  },
  {
    Id: 17089,
    Code: '6132',
    Name: 'Assistant, care, emergency'
  },
  {
    Id: 17090,
    Code: '6132',
    Name: 'Assistant, resource, ambulance'
  },
  {
    Id: 17091,
    Code: '6132',
    Name: 'Attendant, ambulance'
  },
  {
    Id: 17092,
    Code: '6132',
    Name: 'Attendant, room, ambulance'
  },
  {
    Id: 17093,
    Code: '6132',
    Name: 'Attendant, station, ambulance'
  },
  {
    Id: 17094,
    Code: '6132',
    Name: 'Attendant, ambulance service'
  },
  {
    Id: 17095,
    Code: '6132',
    Name: 'Cadet, ambulance service'
  },
  {
    Id: 17096,
    Code: '6132',
    Name: 'Carer, patient, ambulance'
  },
  {
    Id: 17097,
    Code: '6132',
    Name: 'Crew, ambulance, emergency'
  },
  {
    Id: 17098,
    Code: '6132',
    Name: 'Crew, ambulance'
  },
  {
    Id: 17099,
    Code: '6132',
    Name: 'Driver, ambulance'
  },
  {
    Id: 17100,
    Code: '6132',
    Name: 'Driver, PTS'
  },
  {
    Id: 17101,
    Code: '6132',
    Name: 'Driver, service, transport, patient'
  },
  {
    Id: 17102,
    Code: '6132',
    Name: 'Driver, transport, patient'
  },
  {
    Id: 17103,
    Code: '6132',
    Name: 'Driver-attendant, ambulance'
  },
  {
    Id: 17104,
    Code: '6132',
    Name: 'ECA'
  },
  {
    Id: 17105,
    Code: '6132',
    Name: 'Leader, section, ambulance service'
  },
  {
    Id: 17106,
    Code: '6132',
    Name: 'Leader, shift, ambulance service'
  },
  {
    Id: 17107,
    Code: '6132',
    Name: 'Leader, team, ambulance service'
  },
  {
    Id: 17108,
    Code: '6132',
    Name: 'Medic, offshore'
  },
  {
    Id: 17109,
    Code: '6132',
    Name: 'Medic, oil rig'
  },
  {
    Id: 17110,
    Code: '6132',
    Name: 'Officer, ambulance'
  },
  {
    Id: 17111,
    Code: '6132',
    Name: 'Operative, preparation, service, ambulance'
  },
  {
    Id: 17112,
    Code: '6132',
    Name: 'Operative, ready, make, service, ambulance'
  },
  {
    Id: 17113,
    Code: '6132',
    Name: 'Supervisor, ambulance service'
  },
  {
    Id: 17114,
    Code: '6132',
    Name: 'Technician, ambulance'
  },
  {
    Id: 17115,
    Code: '6132',
    Name: 'Technician, medical, emergency'
  },
  {
    Id: 17116,
    Code: '6132',
    Name: 'Technician, ambulance service'
  },
  {
    Id: 17117,
    Code: '6132',
    Name: 'Worker, support, care, emergency'
  },
  {
    Id: 17118,
    Code: '6133',
    Name: 'Assistant, chairside, orthodontic'
  },
  {
    Id: 17119,
    Code: '6133',
    Name: 'Assistant, dental'
  },
  {
    Id: 17120,
    Code: '6133',
    Name: "Assistant, surgeon's, dental"
  },
  {
    Id: 17121,
    Code: '6133',
    Name: 'Assistant, surgery, dental'
  },
  {
    Id: 17122,
    Code: '6133',
    Name: 'Attendant, dental'
  },
  {
    Id: 17123,
    Code: '6133',
    Name: 'Auxiliary, dental'
  },
  {
    Id: 17124,
    Code: '6133',
    Name: 'Dental nurse-receptionist'
  },
  {
    Id: 17125,
    Code: '6133',
    Name: 'Nurse, dental'
  },
  {
    Id: 17126,
    Code: '6133',
    Name: 'Nurse, surgery, dental'
  },
  {
    Id: 17127,
    Code: '6133',
    Name: 'Nurse, dental practice'
  },
  {
    Id: 17128,
    Code: '6133',
    Name: 'Nurse-receptionist, dental'
  },
  {
    Id: 17129,
    Code: '6133',
    Name: 'Receptionist-nurse, dental'
  },
  {
    Id: 17130,
    Code: '6134',
    Name: 'Assistant, community, housing association'
  },
  {
    Id: 17131,
    Code: '6134',
    Name: 'Carer, foster'
  },
  {
    Id: 17132,
    Code: '6134',
    Name: 'Father, house'
  },
  {
    Id: 17133,
    Code: '6134',
    Name: 'Housemaster, local government: social services'
  },
  {
    Id: 17134,
    Code: '6134',
    Name: 'Houseparent'
  },
  {
    Id: 17135,
    Code: '6134',
    Name: "Leader, children's home"
  },
  {
    Id: 17136,
    Code: '6134',
    Name: 'Master, house, local government: social services'
  },
  {
    Id: 17137,
    Code: '6134',
    Name: 'Matron, home'
  },
  {
    Id: 17138,
    Code: '6134',
    Name: 'Matron, hostel'
  },
  {
    Id: 17139,
    Code: '6134',
    Name: 'Matron, house'
  },
  {
    Id: 17140,
    Code: '6134',
    Name: 'Matron, school'
  },
  {
    Id: 17141,
    Code: '6134',
    Name: 'Matron, educational establishments'
  },
  {
    Id: 17142,
    Code: '6134',
    Name: 'Mother, foster'
  },
  {
    Id: 17143,
    Code: '6134',
    Name: 'Mother, house'
  },
  {
    Id: 17144,
    Code: '6134',
    Name: 'Officer, care, residential'
  },
  {
    Id: 17145,
    Code: '6134',
    Name: 'Parent, foster'
  },
  {
    Id: 17146,
    Code: '6134',
    Name: 'Parent, house'
  },
  {
    Id: 17147,
    Code: '6134',
    Name: 'Practitioner, care, child, residential'
  },
  {
    Id: 17148,
    Code: '6134',
    Name: 'Practitioner, childcare, residential'
  },
  {
    Id: 17149,
    Code: '6134',
    Name: 'Sister, home, nurses home'
  },
  {
    Id: 17150,
    Code: '6134',
    Name: 'Superintendent, care/residential home'
  },
  {
    Id: 17151,
    Code: '6134',
    Name: "Superintendent, children's home"
  },
  {
    Id: 17152,
    Code: '6134',
    Name: 'Supervisor, home, nursing'
  },
  {
    Id: 17153,
    Code: '6134',
    Name: 'Supervisor, hostel'
  },
  {
    Id: 17154,
    Code: '6134',
    Name: 'Supervisor, institutions'
  },
  {
    Id: 17155,
    Code: '6134',
    Name: 'Warden, accommodation'
  },
  {
    Id: 17156,
    Code: '6134',
    Name: 'Warden, community, sheltered housing'
  },
  {
    Id: 17157,
    Code: '6134',
    Name: 'Warden, housing, local government'
  },
  {
    Id: 17158,
    Code: '6134',
    Name: 'Warden, mobile'
  },
  {
    Id: 17159,
    Code: '6134',
    Name: 'Warden, care/residential home'
  },
  {
    Id: 17160,
    Code: '6134',
    Name: 'Warden, housing association'
  },
  {
    Id: 17161,
    Code: '6134',
    Name: 'Warden, local government: housing dept'
  },
  {
    Id: 17162,
    Code: '6134',
    Name: 'Warden, local government: social services'
  },
  {
    Id: 17163,
    Code: '6134',
    Name: 'Worker, support, residential'
  },
  {
    Id: 17164,
    Code: '6135',
    Name: 'Aid, care'
  },
  {
    Id: 17165,
    Code: '6135',
    Name: 'Aid, home'
  },
  {
    Id: 17166,
    Code: '6135',
    Name: 'Aide, care'
  },
  {
    Id: 17167,
    Code: '6135',
    Name: 'Aide, home'
  },
  {
    Id: 17168,
    Code: '6135',
    Name: 'Assistant, care, community'
  },
  {
    Id: 17169,
    Code: '6135',
    Name: 'Assistant, care, day'
  },
  {
    Id: 17170,
    Code: '6135',
    Name: 'Assistant, care, domiciliary'
  },
  {
    Id: 17171,
    Code: '6135',
    Name: 'Assistant, care, health, mental'
  },
  {
    Id: 17172,
    Code: '6135',
    Name: 'Assistant, care, health, night, care/residential home'
  },
  {
    Id: 17173,
    Code: '6135',
    Name: 'Assistant, care, health, home care'
  },
  {
    Id: 17174,
    Code: '6135',
    Name: 'Assistant, care, health, care/residential home'
  },
  {
    Id: 17175,
    Code: '6135',
    Name: 'Assistant, care, health, nursing home'
  },
  {
    Id: 17176,
    Code: '6135',
    Name: 'Assistant, care, home'
  },
  {
    Id: 17177,
    Code: '6135',
    Name: 'Assistant, care, night'
  },
  {
    Id: 17178,
    Code: '6135',
    Name: 'Assistant, care, personal'
  },
  {
    Id: 17179,
    Code: '6135',
    Name: 'Assistant, care, community care'
  },
  {
    Id: 17180,
    Code: '6135',
    Name: 'Assistant, care'
  },
  {
    Id: 17181,
    Code: '6135',
    Name: 'Assistant, enablement'
  },
  {
    Id: 17182,
    Code: '6135',
    Name: 'Assistant, general, care/residential home'
  },
  {
    Id: 17183,
    Code: '6135',
    Name: 'Assistant, health, mental, community'
  },
  {
    Id: 17184,
    Code: '6135',
    Name: 'Assistant, health, care/residential home'
  },
  {
    Id: 17185,
    Code: '6135',
    Name: 'Assistant, healthcare, mental'
  },
  {
    Id: 17186,
    Code: '6135',
    Name: 'Assistant, healthcare, home care'
  },
  {
    Id: 17187,
    Code: '6135',
    Name: 'Assistant, healthcare, care/residential home'
  },
  {
    Id: 17188,
    Code: '6135',
    Name: 'Assistant, healthcare, nursing home'
  },
  {
    Id: 17189,
    Code: '6135',
    Name: 'Assistant, home, care'
  },
  {
    Id: 17190,
    Code: '6135',
    Name: 'Assistant, personal, welfare services'
  },
  {
    Id: 17191,
    Code: '6135',
    Name: 'Assistant, reablement, local government'
  },
  {
    Id: 17192,
    Code: '6135',
    Name: 'Assistant, support, welfare services'
  },
  {
    Id: 17193,
    Code: '6135',
    Name: 'Attendant, care'
  },
  {
    Id: 17194,
    Code: '6135',
    Name: 'Attendant, charge'
  },
  {
    Id: 17195,
    Code: '6135',
    Name: 'Attendant, invalid'
  },
  {
    Id: 17196,
    Code: '6135',
    Name: 'Attendant, night, care/residential home'
  },
  {
    Id: 17197,
    Code: '6135',
    Name: 'Attendant, care/residential home'
  },
  {
    Id: 17198,
    Code: '6135',
    Name: 'Befriender, local government: social services'
  },
  {
    Id: 17199,
    Code: '6135',
    Name: 'Carer, community'
  },
  {
    Id: 17200,
    Code: '6135',
    Name: 'Carer, domiciliary'
  },
  {
    Id: 17201,
    Code: '6135',
    Name: 'Carer, home'
  },
  {
    Id: 17202,
    Code: '6135',
    Name: 'Carer, in, live'
  },
  {
    Id: 17203,
    Code: '6135',
    Name: 'Carer, live-in'
  },
  {
    Id: 17204,
    Code: '6135',
    Name: 'Carer, lives, shared'
  },
  {
    Id: 17205,
    Code: '6135',
    Name: 'Carer, PA'
  },
  {
    Id: 17206,
    Code: '6135',
    Name: 'Carer, personal'
  },
  {
    Id: 17207,
    Code: '6135',
    Name: 'Carer, private'
  },
  {
    Id: 17208,
    Code: '6135',
    Name: 'Carer, reablement, local government'
  },
  {
    Id: 17209,
    Code: '6135',
    Name: 'Carer, home care'
  },
  {
    Id: 17210,
    Code: '6135',
    Name: 'Carer, care/residential home'
  },
  {
    Id: 17211,
    Code: '6135',
    Name: 'Carer, welfare services'
  },
  {
    Id: 17212,
    Code: '6135',
    Name: 'Carer'
  },
  {
    Id: 17213,
    Code: '6135',
    Name: 'Carer-personal assistant'
  },
  {
    Id: 17214,
    Code: '6135',
    Name: 'Careworker, child'
  },
  {
    Id: 17215,
    Code: '6135',
    Name: 'Careworker, welfare services'
  },
  {
    Id: 17216,
    Code: '6135',
    Name: 'Coordinator, activities, care/residential home'
  },
  {
    Id: 17217,
    Code: '6135',
    Name: 'Coordinator, activities, nursing home'
  },
  {
    Id: 17218,
    Code: '6135',
    Name: 'Coordinator, lifestyle, care/residential home'
  },
  {
    Id: 17219,
    Code: '6135',
    Name: 'Coordinator, wellbeing, care/residential home'
  },
  {
    Id: 17220,
    Code: '6135',
    Name: 'Enabler, care'
  },
  {
    Id: 17221,
    Code: '6135',
    Name: 'Giver, care, home care'
  },
  {
    Id: 17222,
    Code: '6135',
    Name: 'HCA, home care'
  },
  {
    Id: 17223,
    Code: '6135',
    Name: 'HCA, care/residential home'
  },
  {
    Id: 17224,
    Code: '6135',
    Name: 'HCA, nursing home'
  },
  {
    Id: 17225,
    Code: '6135',
    Name: 'Helper, home, home care'
  },
  {
    Id: 17226,
    Code: '6135',
    Name: "Helper, people's, old"
  },
  {
    Id: 17227,
    Code: '6135',
    Name: 'Homehelp, home care'
  },
  {
    Id: 17228,
    Code: '6135',
    Name: 'Nurse, care'
  },
  {
    Id: 17229,
    Code: '6135',
    Name: 'Officer, care, night'
  },
  {
    Id: 17230,
    Code: '6135',
    Name: 'Officer, centre, day'
  },
  {
    Id: 17231,
    Code: '6135',
    Name: 'Officer, residential, welfare services'
  },
  {
    Id: 17232,
    Code: '6135',
    Name: 'Orderly, nursing home'
  },
  {
    Id: 17233,
    Code: '6135',
    Name: 'Organiser, activity, care/residential home'
  },
  {
    Id: 17234,
    Code: '6135',
    Name: 'Organiser, activity, nursing home'
  },
  {
    Id: 17235,
    Code: '6135',
    Name: 'Practitioner, autism'
  },
  {
    Id: 17236,
    Code: '6135',
    Name: 'Sitter, welfare services'
  },
  {
    Id: 17237,
    Code: '6135',
    Name: 'Tenant, responsible'
  },
  {
    Id: 17238,
    Code: '6135',
    Name: 'Therapist, hobby'
  },
  {
    Id: 17239,
    Code: '6135',
    Name: 'Visitor, care'
  },
  {
    Id: 17240,
    Code: '6135',
    Name: 'Worker, care, child, residential'
  },
  {
    Id: 17241,
    Code: '6135',
    Name: 'Worker, care, child'
  },
  {
    Id: 17242,
    Code: '6135',
    Name: "Worker, care, children's, care/residential home"
  },
  {
    Id: 17243,
    Code: '6135',
    Name: 'Worker, care, community'
  },
  {
    Id: 17244,
    Code: '6135',
    Name: 'Worker, care, home'
  },
  {
    Id: 17245,
    Code: '6135',
    Name: 'Worker, care, residential'
  },
  {
    Id: 17246,
    Code: '6135',
    Name: 'Worker, care, social, residential'
  },
  {
    Id: 17247,
    Code: '6135',
    Name: 'Worker, care, social, home care'
  },
  {
    Id: 17248,
    Code: '6135',
    Name: 'Worker, care, social, care/residential home'
  },
  {
    Id: 17249,
    Code: '6135',
    Name: 'Worker, care, social'
  },
  {
    Id: 17250,
    Code: '6135',
    Name: 'Worker, care'
  },
  {
    Id: 17251,
    Code: '6135',
    Name: 'Worker, childcare, residential'
  },
  {
    Id: 17252,
    Code: '6135',
    Name: "Worker, children's, residential"
  },
  {
    Id: 17253,
    Code: '6135',
    Name: 'Worker, enablement'
  },
  {
    Id: 17254,
    Code: '6135',
    Name: 'Worker, health, mental, community'
  },
  {
    Id: 17255,
    Code: '6135',
    Name: 'Worker, healthcare, nursing home'
  },
  {
    Id: 17256,
    Code: '6135',
    Name: 'Worker, healthcare, welfare services'
  },
  {
    Id: 17257,
    Code: '6135',
    Name: 'Worker, homecare'
  },
  {
    Id: 17258,
    Code: '6135',
    Name: 'Worker, reablement, local government'
  },
  {
    Id: 17259,
    Code: '6135',
    Name: 'Worker, residential, welfare services'
  },
  {
    Id: 17260,
    Code: '6135',
    Name: 'Worker, resource'
  },
  {
    Id: 17261,
    Code: '6135',
    Name: 'Worker, shelter, welfare services'
  },
  {
    Id: 17262,
    Code: '6135',
    Name: 'Worker, social, resident'
  },
  {
    Id: 17263,
    Code: '6135',
    Name: 'Worker, social, residential'
  },
  {
    Id: 17264,
    Code: '6135',
    Name: 'Worker, support, adult'
  },
  {
    Id: 17265,
    Code: '6135',
    Name: 'Worker, support, autism'
  },
  {
    Id: 17266,
    Code: '6135',
    Name: 'Worker, support, care, health, mental'
  },
  {
    Id: 17267,
    Code: '6135',
    Name: 'Worker, support, care, home'
  },
  {
    Id: 17268,
    Code: '6135',
    Name: 'Worker, support, care, welfare services'
  },
  {
    Id: 17269,
    Code: '6135',
    Name: 'Worker, support, carers'
  },
  {
    Id: 17270,
    Code: '6135',
    Name: 'Worker, support, children'
  },
  {
    Id: 17271,
    Code: '6135',
    Name: 'Worker, support, community'
  },
  {
    Id: 17272,
    Code: '6135',
    Name: 'Worker, support, disabilities, learning'
  },
  {
    Id: 17273,
    Code: '6135',
    Name: 'Worker, support, health, mental'
  },
  {
    Id: 17274,
    Code: '6135',
    Name: 'Worker, support, health, nursing home'
  },
  {
    Id: 17275,
    Code: '6135',
    Name: 'Worker, support, home'
  },
  {
    Id: 17276,
    Code: '6135',
    Name: 'Worker, support, integrated'
  },
  {
    Id: 17277,
    Code: '6135',
    Name: 'Worker, support, night, waking'
  },
  {
    Id: 17278,
    Code: '6135',
    Name: 'Worker, support, night'
  },
  {
    Id: 17279,
    Code: '6135',
    Name: 'Worker, support, reablement'
  },
  {
    Id: 17280,
    Code: '6135',
    Name: 'Worker, support, rehabilitation'
  },
  {
    Id: 17281,
    Code: '6135',
    Name: 'Worker, support, home care'
  },
  {
    Id: 17282,
    Code: '6135',
    Name: 'Worker, support, care/residential home'
  },
  {
    Id: 17283,
    Code: '6135',
    Name: 'Worker, support, nursing home'
  },
  {
    Id: 17284,
    Code: '6135',
    Name: 'Worker, support, welfare services'
  },
  {
    Id: 17285,
    Code: '6136',
    Name: 'Assistant, care, community, senior'
  },
  {
    Id: 17286,
    Code: '6136',
    Name: 'Assistant, care, health, senior, nursing home'
  },
  {
    Id: 17287,
    Code: '6136',
    Name: 'Assistant, care, home, senior'
  },
  {
    Id: 17288,
    Code: '6136',
    Name: 'Assistant, care, senior, home care'
  },
  {
    Id: 17289,
    Code: '6136',
    Name: 'Assistant, care, senior, care/residential home'
  },
  {
    Id: 17290,
    Code: '6136',
    Name: 'Assistant, care, senior, nursing home'
  },
  {
    Id: 17291,
    Code: '6136',
    Name: 'Assistant, care, senior'
  },
  {
    Id: 17292,
    Code: '6136',
    Name: 'Carer, night, senior'
  },
  {
    Id: 17293,
    Code: '6136',
    Name: 'Carer, senior'
  },
  {
    Id: 17294,
    Code: '6136',
    Name: 'Coordinator, care, community'
  },
  {
    Id: 17295,
    Code: '6136',
    Name: 'Coordinator, care, care/residential home'
  },
  {
    Id: 17296,
    Code: '6136',
    Name: 'Coordinator, care, nursing home'
  },
  {
    Id: 17297,
    Code: '6136',
    Name: 'Coordinator, care, social, welfare services'
  },
  {
    Id: 17298,
    Code: '6136',
    Name: 'Leader, home, care/residential home'
  },
  {
    Id: 17299,
    Code: '6136',
    Name: 'Leader, home, nursing home'
  },
  {
    Id: 17300,
    Code: '6136',
    Name: 'Leader, team, care, home, local government: social services'
  },
  {
    Id: 17301,
    Code: '6136',
    Name: 'Leader, team, support, home'
  },
  {
    Id: 17302,
    Code: '6136',
    Name: 'Leader, team, care/residential home'
  },
  {
    Id: 17303,
    Code: '6136',
    Name: 'Leader, team, home care, local government: social services'
  },
  {
    Id: 17304,
    Code: '6136',
    Name: 'Leader, team, residential care, local government: social services'
  },
  {
    Id: 17305,
    Code: '6136',
    Name: 'Leader, team, nursing home'
  },
  {
    Id: 17306,
    Code: '6136',
    Name: 'Leader, care/residential home'
  },
  {
    Id: 17307,
    Code: '6136',
    Name: 'Manager, scheme, sheltered housing'
  },
  {
    Id: 17308,
    Code: '6136',
    Name: 'Practitioner, assistant, home, care'
  },
  {
    Id: 17309,
    Code: '6136',
    Name: 'Practitioner, autism, senior'
  },
  {
    Id: 17310,
    Code: '6136',
    Name: 'Practitioner, care, care/residential home'
  },
  {
    Id: 17311,
    Code: '6136',
    Name: 'Supervisor, care, field, welfare services'
  },
  {
    Id: 17312,
    Code: '6136',
    Name: 'Supervisor, care, home'
  },
  {
    Id: 17313,
    Code: '6136',
    Name: 'Supervisor, care, home care'
  },
  {
    Id: 17314,
    Code: '6136',
    Name: 'Supervisor, care, care/residential home'
  },
  {
    Id: 17315,
    Code: '6136',
    Name: 'Supervisor, care'
  },
  {
    Id: 17316,
    Code: '6136',
    Name: 'Worker, care, senior'
  },
  {
    Id: 17317,
    Code: '6136',
    Name: 'Worker, residential, senior'
  },
  {
    Id: 17318,
    Code: '6136',
    Name: 'Worker, social, resident, senior'
  },
  {
    Id: 17319,
    Code: '6136',
    Name: 'Worker, support, care, senior'
  },
  {
    Id: 17320,
    Code: '6136',
    Name: 'Worker, support, health, mental, senior'
  },
  {
    Id: 17321,
    Code: '6136',
    Name: 'Worker, support, senior, welfare services'
  },
  {
    Id: 17322,
    Code: '6137',
    Name: 'Assistant, passenger'
  },
  {
    Id: 17323,
    Code: '6137',
    Name: 'Assistant, travel, care escort'
  },
  {
    Id: 17324,
    Code: '6137',
    Name: 'Attendant, bus'
  },
  {
    Id: 17325,
    Code: '6137',
    Name: "Attendant, children's"
  },
  {
    Id: 17326,
    Code: '6137',
    Name: 'Chaperone, entertainment'
  },
  {
    Id: 17327,
    Code: '6137',
    Name: 'Driver-carer'
  },
  {
    Id: 17328,
    Code: '6137',
    Name: 'Driver-escort'
  },
  {
    Id: 17329,
    Code: '6137',
    Name: 'Escort, bus, school'
  },
  {
    Id: 17330,
    Code: '6137',
    Name: 'Escort, bus'
  },
  {
    Id: 17331,
    Code: '6137',
    Name: 'Escort, child'
  },
  {
    Id: 17332,
    Code: '6137',
    Name: 'Escort, school'
  },
  {
    Id: 17333,
    Code: '6137',
    Name: 'Escort, transport'
  },
  {
    Id: 17334,
    Code: '6137',
    Name: 'Escort'
  },
  {
    Id: 17335,
    Code: '6137',
    Name: 'Escort-driver'
  },
  {
    Id: 17336,
    Code: '6137',
    Name: 'Guide, pupil'
  },
  {
    Id: 17337,
    Code: '6137',
    Name: 'Worker, support, driver'
  },
  {
    Id: 17338,
    Code: '6138',
    Name: 'Administrator, funeral'
  },
  {
    Id: 17339,
    Code: '6138',
    Name: 'Arranger, funeral'
  },
  {
    Id: 17340,
    Code: '6138',
    Name: 'Arranger, service, funeral'
  },
  {
    Id: 17341,
    Code: '6138',
    Name: 'Assistant, crematorium'
  },
  {
    Id: 17342,
    Code: '6138',
    Name: "Assistant, director's, funeral"
  },
  {
    Id: 17343,
    Code: '6138',
    Name: 'Assistant, funeral'
  },
  {
    Id: 17344,
    Code: '6138',
    Name: 'Assistant, mortuary'
  },
  {
    Id: 17345,
    Code: '6138',
    Name: "Assistant, undertaker's"
  },
  {
    Id: 17346,
    Code: '6138',
    Name: 'Attendant, cemetery'
  },
  {
    Id: 17347,
    Code: '6138',
    Name: 'Attendant, crematorium'
  },
  {
    Id: 17348,
    Code: '6138',
    Name: 'Attendant, furnace, crematorium'
  },
  {
    Id: 17349,
    Code: '6138',
    Name: 'Attendant, mortuary'
  },
  {
    Id: 17350,
    Code: '6138',
    Name: 'Bearer, funeral'
  },
  {
    Id: 17351,
    Code: '6138',
    Name: 'Bearer, pall'
  },
  {
    Id: 17352,
    Code: '6138',
    Name: 'Bearer, funeral directors'
  },
  {
    Id: 17353,
    Code: '6138',
    Name: 'Director, funeral'
  },
  {
    Id: 17354,
    Code: '6138',
    Name: 'Driver-bearer'
  },
  {
    Id: 17355,
    Code: '6138',
    Name: 'Embalmer'
  },
  {
    Id: 17356,
    Code: '6138',
    Name: 'Foreman, cemetery, crematorium'
  },
  {
    Id: 17357,
    Code: '6138',
    Name: 'Furnaceman, crematorium'
  },
  {
    Id: 17358,
    Code: '6138',
    Name: 'Mortician'
  },
  {
    Id: 17359,
    Code: '6138',
    Name: 'Operative, funeral'
  },
  {
    Id: 17360,
    Code: '6138',
    Name: 'Operative, service, funeral'
  },
  {
    Id: 17361,
    Code: '6138',
    Name: 'Operator, crematorium'
  },
  {
    Id: 17362,
    Code: '6138',
    Name: 'Superintendent, assistant, cemetery, crematorium'
  },
  {
    Id: 17363,
    Code: '6138',
    Name: 'Superintendent, cemetery, crematorium'
  },
  {
    Id: 17364,
    Code: '6138',
    Name: 'Taxidermist'
  },
  {
    Id: 17365,
    Code: '6138',
    Name: 'Technician, crematorium'
  },
  {
    Id: 17366,
    Code: '6138',
    Name: 'Technician, mortuary'
  },
  {
    Id: 17367,
    Code: '6138',
    Name: 'Undertaker'
  },
  {
    Id: 17368,
    Code: '6138',
    Name: 'Worker, cemetery'
  },
  {
    Id: 17369,
    Code: '6211',
    Name: 'Accountant, turf'
  },
  {
    Id: 17370,
    Code: '6211',
    Name: 'Agent, commission, turf accountants'
  },
  {
    Id: 17371,
    Code: '6211',
    Name: 'Assistant, centre, leisure'
  },
  {
    Id: 17372,
    Code: '6211',
    Name: 'Assistant, collections, museum'
  },
  {
    Id: 17373,
    Code: '6211',
    Name: 'Assistant, education, museum'
  },
  {
    Id: 17374,
    Code: '6211',
    Name: 'Assistant, gym'
  },
  {
    Id: 17375,
    Code: '6211',
    Name: 'Assistant, heritage'
  },
  {
    Id: 17376,
    Code: '6211',
    Name: 'Assistant, leisure'
  },
  {
    Id: 17377,
    Code: '6211',
    Name: "Assistant, maker's, book, betting"
  },
  {
    Id: 17378,
    Code: '6211',
    Name: 'Assistant, museum'
  },
  {
    Id: 17379,
    Code: '6211',
    Name: 'Assistant, recreation'
  },
  {
    Id: 17380,
    Code: '6211',
    Name: 'Assistant, recreational'
  },
  {
    Id: 17381,
    Code: '6211',
    Name: 'Assistant, services, visitor, museum'
  },
  {
    Id: 17382,
    Code: '6211',
    Name: 'Assistant, services, visitor, museum'
  },
  {
    Id: 17383,
    Code: '6211',
    Name: 'Assistant, sports'
  },
  {
    Id: 17384,
    Code: '6211',
    Name: 'Assistant, swimming'
  },
  {
    Id: 17385,
    Code: '6211',
    Name: 'Assistant, wardrobe'
  },
  {
    Id: 17386,
    Code: '6211',
    Name: 'Assistant, welcome, visitor, historical sites'
  },
  {
    Id: 17387,
    Code: '6211',
    Name: 'Attendant, centre, sports'
  },
  {
    Id: 17388,
    Code: '6211',
    Name: 'Attendant, centre, leisure centre'
  },
  {
    Id: 17389,
    Code: '6211',
    Name: 'Attendant, games'
  },
  {
    Id: 17390,
    Code: '6211',
    Name: 'Attendant, green, bowling'
  },
  {
    Id: 17391,
    Code: '6211',
    Name: 'Attendant, gymnasium'
  },
  {
    Id: 17392,
    Code: '6211',
    Name: 'Attendant, leisure'
  },
  {
    Id: 17393,
    Code: '6211',
    Name: 'Attendant, pool, swimming'
  },
  {
    Id: 17394,
    Code: '6211',
    Name: 'Attendant, pool'
  },
  {
    Id: 17395,
    Code: '6211',
    Name: 'Attendant, services, visitor'
  },
  {
    Id: 17396,
    Code: '6211',
    Name: 'Attendant, baths: swimming'
  },
  {
    Id: 17397,
    Code: '6211',
    Name: 'Attendant, leisure centre'
  },
  {
    Id: 17398,
    Code: '6211',
    Name: 'Boardman, bookmakers, turf accountants'
  },
  {
    Id: 17399,
    Code: '6211',
    Name: 'Bookmaker'
  },
  {
    Id: 17400,
    Code: '6211',
    Name: 'Boss, pit, casino'
  },
  {
    Id: 17401,
    Code: '6211',
    Name: 'Boule de table'
  },
  {
    Id: 17402,
    Code: '6211',
    Name: 'Caddie'
  },
  {
    Id: 17403,
    Code: '6211',
    Name: 'Controller, operations, leisure centre'
  },
  {
    Id: 17404,
    Code: '6211',
    Name: 'Croupier'
  },
  {
    Id: 17405,
    Code: '6211',
    Name: 'Dealer, poker'
  },
  {
    Id: 17406,
    Code: '6211',
    Name: 'Dealer, casino'
  },
  {
    Id: 17407,
    Code: '6211',
    Name: 'Dresser, entertainment'
  },
  {
    Id: 17408,
    Code: '6211',
    Name: 'Gambler, professional'
  },
  {
    Id: 17409,
    Code: '6211',
    Name: 'Guard, life, beach'
  },
  {
    Id: 17410,
    Code: '6211',
    Name: 'Guard, life'
  },
  {
    Id: 17411,
    Code: '6211',
    Name: 'Guide, museum'
  },
  {
    Id: 17412,
    Code: '6211',
    Name: 'Keeper, wardrobe'
  },
  {
    Id: 17413,
    Code: '6211',
    Name: 'Lifeguard, beach'
  },
  {
    Id: 17414,
    Code: '6211',
    Name: 'Lifeguard, pool'
  },
  {
    Id: 17415,
    Code: '6211',
    Name: 'Lifeguard'
  },
  {
    Id: 17416,
    Code: '6211',
    Name: 'Linkman, entertainment'
  },
  {
    Id: 17417,
    Code: '6211',
    Name: 'Maker, book, betting'
  },
  {
    Id: 17418,
    Code: '6211',
    Name: 'Man, tic-tac'
  },
  {
    Id: 17419,
    Code: '6211',
    Name: 'Manager, wardrobe'
  },
  {
    Id: 17420,
    Code: '6211',
    Name: 'Master, caddy'
  },
  {
    Id: 17421,
    Code: '6211',
    Name: 'Master, property'
  },
  {
    Id: 17422,
    Code: '6211',
    Name: 'Master, toast'
  },
  {
    Id: 17423,
    Code: '6211',
    Name: 'Master, wardrobe'
  },
  {
    Id: 17424,
    Code: '6211',
    Name: 'Master of ceremonies, entertainment'
  },
  {
    Id: 17425,
    Code: '6211',
    Name: 'Mistress, wardrobe'
  },
  {
    Id: 17426,
    Code: '6211',
    Name: 'Officer, duty, leisure centre'
  },
  {
    Id: 17427,
    Code: '6211',
    Name: 'Runner, entertainment'
  },
  {
    Id: 17428,
    Code: '6211',
    Name: 'Runner, broadcasting'
  },
  {
    Id: 17429,
    Code: '6211',
    Name: 'Steward, monument'
  },
  {
    Id: 17430,
    Code: '6211',
    Name: 'Supervisor, centre, leisure'
  },
  {
    Id: 17431,
    Code: '6211',
    Name: 'Supervisor, centre, sports'
  },
  {
    Id: 17432,
    Code: '6211',
    Name: 'Supervisor, gaming'
  },
  {
    Id: 17433,
    Code: '6211',
    Name: 'Supervisor, pool, swimming'
  },
  {
    Id: 17434,
    Code: '6211',
    Name: 'Supervisor, services, visitor, museum'
  },
  {
    Id: 17435,
    Code: '6211',
    Name: 'Tipster'
  },
  {
    Id: 17436,
    Code: '6211',
    Name: 'Woman, wardrobe, theatre'
  },
  {
    Id: 17437,
    Code: '6212',
    Name: 'Administrator, travel'
  },
  {
    Id: 17438,
    Code: '6212',
    Name: 'Adviser, sales, holiday'
  },
  {
    Id: 17439,
    Code: '6212',
    Name: 'Adviser, sales, travel'
  },
  {
    Id: 17440,
    Code: '6212',
    Name: 'Adviser, travel'
  },
  {
    Id: 17441,
    Code: '6212',
    Name: 'Agent, booking, travel agents'
  },
  {
    Id: 17442,
    Code: '6212',
    Name: 'Agent, reservation'
  },
  {
    Id: 17443,
    Code: '6212',
    Name: 'Agent, reservations'
  },
  {
    Id: 17444,
    Code: '6212',
    Name: 'Agent, tourist'
  },
  {
    Id: 17445,
    Code: '6212',
    Name: 'Agent, travel, business'
  },
  {
    Id: 17446,
    Code: '6212',
    Name: 'Agent, travel'
  },
  {
    Id: 17447,
    Code: '6212',
    Name: 'Agent, travel'
  },
  {
    Id: 17448,
    Code: '6212',
    Name: 'Assistant, sales, holiday'
  },
  {
    Id: 17449,
    Code: '6212',
    Name: 'Assistant, travel'
  },
  {
    Id: 17450,
    Code: '6212',
    Name: 'Clerk, agency, travel'
  },
  {
    Id: 17451,
    Code: '6212',
    Name: 'Clerk, booking, travel agents'
  },
  {
    Id: 17452,
    Code: '6212',
    Name: 'Clerk, reservations, travel'
  },
  {
    Id: 17453,
    Code: '6212',
    Name: 'Clerk, travel'
  },
  {
    Id: 17454,
    Code: '6212',
    Name: 'Clerk, voyages'
  },
  {
    Id: 17455,
    Code: '6212',
    Name: 'Consultant, cruise'
  },
  {
    Id: 17456,
    Code: '6212',
    Name: 'Consultant, holiday'
  },
  {
    Id: 17457,
    Code: '6212',
    Name: 'Consultant, leisure, travel agents'
  },
  {
    Id: 17458,
    Code: '6212',
    Name: 'Consultant, reservations, travel agents'
  },
  {
    Id: 17459,
    Code: '6212',
    Name: 'Consultant, sales, travel'
  },
  {
    Id: 17460,
    Code: '6212',
    Name: 'Consultant, sales, travel agents'
  },
  {
    Id: 17461,
    Code: '6212',
    Name: 'Consultant, travel, business'
  },
  {
    Id: 17462,
    Code: '6212',
    Name: 'Consultant, travel'
  },
  {
    Id: 17463,
    Code: '6212',
    Name: 'Controller, operations, travel agents'
  },
  {
    Id: 17464,
    Code: '6212',
    Name: 'Controller, reservation, airlines'
  },
  {
    Id: 17465,
    Code: '6212',
    Name: 'Coordinator, tour'
  },
  {
    Id: 17466,
    Code: '6212',
    Name: 'Executive, operations, travel agents'
  },
  {
    Id: 17467,
    Code: '6212',
    Name: 'Executive, sales, travel'
  },
  {
    Id: 17468,
    Code: '6212',
    Name: 'Manager, reservations'
  },
  {
    Id: 17469,
    Code: '6212',
    Name: 'Officer, control, reservations, air transport'
  },
  {
    Id: 17470,
    Code: '6212',
    Name: 'Officer, reservations, air transport'
  },
  {
    Id: 17471,
    Code: '6212',
    Name: 'Officer, tourism'
  },
  {
    Id: 17472,
    Code: '6212',
    Name: 'Officer, tourist'
  },
  {
    Id: 17473,
    Code: '6212',
    Name: 'Official, airline'
  },
  {
    Id: 17474,
    Code: '6212',
    Name: 'Operator, tour'
  },
  {
    Id: 17475,
    Code: '6212',
    Name: 'Organiser, tour'
  },
  {
    Id: 17476,
    Code: '6212',
    Name: 'Organiser, travel'
  },
  {
    Id: 17477,
    Code: '6212',
    Name: 'Supervisor, centre, travel, airline'
  },
  {
    Id: 17478,
    Code: '6212',
    Name: 'Supervisor, reservations, air transport'
  },
  {
    Id: 17479,
    Code: '6212',
    Name: 'Supervisor, travel'
  },
  {
    Id: 17480,
    Code: '6213',
    Name: 'Agent, handling, passenger'
  },
  {
    Id: 17481,
    Code: '6213',
    Name: 'Agent, passenger'
  },
  {
    Id: 17482,
    Code: '6213',
    Name: 'Agent, reservation, airline'
  },
  {
    Id: 17483,
    Code: '6213',
    Name: 'Agent, service, customer, airline'
  },
  {
    Id: 17484,
    Code: '6213',
    Name: 'Agent, service, customer, air travel'
  },
  {
    Id: 17485,
    Code: '6213',
    Name: 'Agent, service, passenger'
  },
  {
    Id: 17486,
    Code: '6213',
    Name: 'Agent, service, airlines'
  },
  {
    Id: 17487,
    Code: '6213',
    Name: 'Agent, services, customer, air travel'
  },
  {
    Id: 17488,
    Code: '6213',
    Name: 'Agent, services, passenger'
  },
  {
    Id: 17489,
    Code: '6213',
    Name: 'Agent, ticket, airline'
  },
  {
    Id: 17490,
    Code: '6213',
    Name: 'Assistant, control, passenger, air transport'
  },
  {
    Id: 17491,
    Code: '6213',
    Name: 'Assistant, information, airport'
  },
  {
    Id: 17492,
    Code: '6213',
    Name: 'Assistant, service, passenger'
  },
  {
    Id: 17493,
    Code: '6213',
    Name: 'Assistant, services, passenger'
  },
  {
    Id: 17494,
    Code: '6213',
    Name: 'Assistant, traffic, passenger, air transport'
  },
  {
    Id: 17495,
    Code: '6213',
    Name: 'Attendant, cabin'
  },
  {
    Id: 17496,
    Code: '6213',
    Name: 'Attendant, flight'
  },
  {
    Id: 17497,
    Code: '6213',
    Name: 'Clerk, checking-in'
  },
  {
    Id: 17498,
    Code: '6213',
    Name: 'Crew, cabin, air'
  },
  {
    Id: 17499,
    Code: '6213',
    Name: 'Crew, cabin, airline'
  },
  {
    Id: 17500,
    Code: '6213',
    Name: 'Crew, cabin'
  },
  {
    Id: 17501,
    Code: '6213',
    Name: 'Director, service, cabin'
  },
  {
    Id: 17502,
    Code: '6213',
    Name: 'Director, services, cabin'
  },
  {
    Id: 17503,
    Code: '6213',
    Name: 'Hostess, air'
  },
  {
    Id: 17504,
    Code: '6213',
    Name: 'Hostess, ground'
  },
  {
    Id: 17505,
    Code: '6213',
    Name: 'Hostess, air travel'
  },
  {
    Id: 17506,
    Code: '6213',
    Name: 'Manager, cabin, airlines'
  },
  {
    Id: 17507,
    Code: '6213',
    Name: 'Manager, inflight'
  },
  {
    Id: 17508,
    Code: '6213',
    Name: 'Manager, service, flight'
  },
  {
    Id: 17509,
    Code: '6213',
    Name: 'Manager, service, inflight'
  },
  {
    Id: 17510,
    Code: '6213',
    Name: 'Officer, in, check, passenger'
  },
  {
    Id: 17511,
    Code: '6213',
    Name: 'Officer, services, cabin, airlines'
  },
  {
    Id: 17512,
    Code: '6213',
    Name: 'Purser, aircraft'
  },
  {
    Id: 17513,
    Code: '6213',
    Name: 'Purser, airlines'
  },
  {
    Id: 17514,
    Code: '6213',
    Name: 'Steward, air'
  },
  {
    Id: 17515,
    Code: '6213',
    Name: 'Steward, aircraft'
  },
  {
    Id: 17516,
    Code: '6213',
    Name: 'Steward, airline'
  },
  {
    Id: 17517,
    Code: '6213',
    Name: 'Steward, cabin, airlines'
  },
  {
    Id: 17518,
    Code: '6213',
    Name: 'Steward, chief, airlines'
  },
  {
    Id: 17519,
    Code: '6213',
    Name: 'Steward, flight'
  },
  {
    Id: 17520,
    Code: '6213',
    Name: 'Steward, ground'
  },
  {
    Id: 17521,
    Code: '6213',
    Name: "Steward, officer's"
  },
  {
    Id: 17522,
    Code: '6213',
    Name: 'Steward, airlines'
  },
  {
    Id: 17523,
    Code: '6213',
    Name: 'Superintendent, station, airline'
  },
  {
    Id: 17524,
    Code: '6213',
    Name: 'Supervisor, inflight'
  },
  {
    Id: 17525,
    Code: '6213',
    Name: 'Supervisor, services, flight'
  },
  {
    Id: 17526,
    Code: '6213',
    Name: 'Supervisor, services, passenger'
  },
  {
    Id: 17527,
    Code: '6214',
    Name: 'Adviser, travel, retail, railways'
  },
  {
    Id: 17528,
    Code: '6214',
    Name: 'Assistant, line, gate, railways'
  },
  {
    Id: 17529,
    Code: '6214',
    Name: 'Assistant, passenger, railways'
  },
  {
    Id: 17530,
    Code: '6214',
    Name: 'Assistant, protection, revenue'
  },
  {
    Id: 17531,
    Code: '6214',
    Name: 'Assistant, station, railway'
  },
  {
    Id: 17532,
    Code: '6214',
    Name: 'Assistant, station, underground railway'
  },
  {
    Id: 17533,
    Code: '6214',
    Name: 'Assistant, travel, railways'
  },
  {
    Id: 17534,
    Code: '6214',
    Name: 'Attendant, car, sleeping'
  },
  {
    Id: 17535,
    Code: '6214',
    Name: 'Attendant, carriage, railways'
  },
  {
    Id: 17536,
    Code: '6214',
    Name: 'Attendant, train'
  },
  {
    Id: 17537,
    Code: '6214',
    Name: 'Boy, call, railways'
  },
  {
    Id: 17538,
    Code: '6214',
    Name: 'Captain, railways'
  },
  {
    Id: 17539,
    Code: '6214',
    Name: 'Collector, luggage, excess'
  },
  {
    Id: 17540,
    Code: '6214',
    Name: 'Collector, ticket, railways'
  },
  {
    Id: 17541,
    Code: '6214',
    Name: 'Conductor, train'
  },
  {
    Id: 17542,
    Code: '6214',
    Name: 'Conductor, railways'
  },
  {
    Id: 17543,
    Code: '6214',
    Name: 'Crew, train'
  },
  {
    Id: 17544,
    Code: '6214',
    Name: 'Despatcher, train'
  },
  {
    Id: 17545,
    Code: '6214',
    Name: 'Dispatcher, train'
  },
  {
    Id: 17546,
    Code: '6214',
    Name: 'Examiner, ticket, railways'
  },
  {
    Id: 17547,
    Code: '6214',
    Name: 'Foreman, platform, railways'
  },
  {
    Id: 17548,
    Code: '6214',
    Name: 'Foreman, station, railways'
  },
  {
    Id: 17549,
    Code: '6214',
    Name: 'Guard, commercial, railways'
  },
  {
    Id: 17550,
    Code: '6214',
    Name: 'Guard, passenger'
  },
  {
    Id: 17551,
    Code: '6214',
    Name: 'Guard, railway'
  },
  {
    Id: 17552,
    Code: '6214',
    Name: 'Guard, train'
  },
  {
    Id: 17553,
    Code: '6214',
    Name: 'Guard, railways'
  },
  {
    Id: 17554,
    Code: '6214',
    Name: 'Inspector, platform, railways'
  },
  {
    Id: 17555,
    Code: '6214',
    Name: 'Inspector, protection, revenue'
  },
  {
    Id: 17556,
    Code: '6214',
    Name: 'Inspector, station, railways'
  },
  {
    Id: 17557,
    Code: '6214',
    Name: 'Inspector, ticket, railways'
  },
  {
    Id: 17558,
    Code: '6214',
    Name: 'Manager, train'
  },
  {
    Id: 17559,
    Code: '6214',
    Name: 'Officer, protection, revenue'
  },
  {
    Id: 17560,
    Code: '6214',
    Name: 'Porter, goods, railways'
  },
  {
    Id: 17561,
    Code: '6214',
    Name: 'Porter, mail'
  },
  {
    Id: 17562,
    Code: '6214',
    Name: 'Porter, parcel, railways'
  },
  {
    Id: 17563,
    Code: '6214',
    Name: 'Porter, parcels, railways'
  },
  {
    Id: 17564,
    Code: '6214',
    Name: 'Porter, transport: railways'
  },
  {
    Id: 17565,
    Code: '6214',
    Name: 'Porter-guard'
  },
  {
    Id: 17566,
    Code: '6214',
    Name: 'Porter-signalman'
  },
  {
    Id: 17567,
    Code: '6214',
    Name: 'Purser, railways'
  },
  {
    Id: 17568,
    Code: '6214',
    Name: 'Stationman, railway'
  },
  {
    Id: 17569,
    Code: '6214',
    Name: 'Supervisor, station'
  },
  {
    Id: 17570,
    Code: '6219',
    Name: 'Assistant, centre, information, tourist'
  },
  {
    Id: 17571,
    Code: '6219',
    Name: 'Assistant, information, tourist'
  },
  {
    Id: 17572,
    Code: '6219',
    Name: 'Assistant, information, travel'
  },
  {
    Id: 17573,
    Code: '6219',
    Name: 'Assistant, information, tourism'
  },
  {
    Id: 17574,
    Code: '6219',
    Name: 'Assistant, information, travel'
  },
  {
    Id: 17575,
    Code: '6219',
    Name: "Assistant, steward's"
  },
  {
    Id: 17576,
    Code: '6219',
    Name: 'Assistant, theatre, entertainment'
  },
  {
    Id: 17577,
    Code: '6219',
    Name: 'Attendant, bus, school'
  },
  {
    Id: 17578,
    Code: '6219',
    Name: 'Attendant, travel'
  },
  {
    Id: 17579,
    Code: '6219',
    Name: 'Boy, cabin, shipping'
  },
  {
    Id: 17580,
    Code: '6219',
    Name: 'Caterer, amusement'
  },
  {
    Id: 17581,
    Code: '6219',
    Name: 'Chauffeur, punt'
  },
  {
    Id: 17582,
    Code: '6219',
    Name: 'Clerk, enquiry, travel'
  },
  {
    Id: 17583,
    Code: '6219',
    Name: 'Clerk, information, tourist'
  },
  {
    Id: 17584,
    Code: '6219',
    Name: 'Clerk, information, travel'
  },
  {
    Id: 17585,
    Code: '6219',
    Name: 'Conductor, bus'
  },
  {
    Id: 17586,
    Code: '6219',
    Name: 'Conductor, PSV'
  },
  {
    Id: 17587,
    Code: '6219',
    Name: 'Conductor, road transport'
  },
  {
    Id: 17588,
    Code: '6219',
    Name: 'Courier, tour operator'
  },
  {
    Id: 17589,
    Code: '6219',
    Name: 'Crew, cabin, shipping'
  },
  {
    Id: 17590,
    Code: '6219',
    Name: 'Director, tour'
  },
  {
    Id: 17591,
    Code: '6219',
    Name: 'Guard, bus'
  },
  {
    Id: 17592,
    Code: '6219',
    Name: 'Guard, road passenger transport'
  },
  {
    Id: 17593,
    Code: '6219',
    Name: 'Guide, coach'
  },
  {
    Id: 17594,
    Code: '6219',
    Name: 'Guide, tour'
  },
  {
    Id: 17595,
    Code: '6219',
    Name: 'Guide, tourist'
  },
  {
    Id: 17596,
    Code: '6219',
    Name: 'Guide'
  },
  {
    Id: 17597,
    Code: '6219',
    Name: 'Leader, tour'
  },
  {
    Id: 17598,
    Code: '6219',
    Name: 'Manager, cabin, ferry'
  },
  {
    Id: 17599,
    Code: '6219',
    Name: 'Manager, tour'
  },
  {
    Id: 17600,
    Code: '6219',
    Name: 'Officer, catering, shipping'
  },
  {
    Id: 17601,
    Code: '6219',
    Name: 'Officer, information, tourist'
  },
  {
    Id: 17602,
    Code: '6219',
    Name: 'Officer, passenger, hovercraft'
  },
  {
    Id: 17603,
    Code: '6219',
    Name: 'Purser'
  },
  {
    Id: 17604,
    Code: '6219',
    Name: 'Purserette, hovercraft'
  },
  {
    Id: 17605,
    Code: '6219',
    Name: 'Representative, holiday'
  },
  {
    Id: 17606,
    Code: '6219',
    Name: 'Representative, resort'
  },
  {
    Id: 17607,
    Code: '6219',
    Name: 'Representative, tour operator'
  },
  {
    Id: 17608,
    Code: '6219',
    Name: 'Steward, cabin, shipping'
  },
  {
    Id: 17609,
    Code: '6219',
    Name: 'Steward, chief, shipping'
  },
  {
    Id: 17610,
    Code: '6219',
    Name: 'Steward, saloon, shipping'
  },
  {
    Id: 17611,
    Code: '6219',
    Name: 'Steward, ship'
  },
  {
    Id: 17612,
    Code: '6219',
    Name: 'Steward, shipping'
  },
  {
    Id: 17613,
    Code: '6219',
    Name: 'Steward'
  },
  {
    Id: 17614,
    Code: '6221',
    Name: 'Artist, tonsorial'
  },
  {
    Id: 17615,
    Code: '6221',
    Name: "Assistant, barber's"
  },
  {
    Id: 17616,
    Code: '6221',
    Name: 'Assistant, barbering'
  },
  {
    Id: 17617,
    Code: '6221',
    Name: 'Assistant, general, hairdressing'
  },
  {
    Id: 17618,
    Code: '6221',
    Name: "Assistant, hairdresser's"
  },
  {
    Id: 17619,
    Code: '6221',
    Name: 'Assistant, hairdressing'
  },
  {
    Id: 17620,
    Code: '6221',
    Name: 'Assistant, salon, hairdressing'
  },
  {
    Id: 17621,
    Code: '6221',
    Name: 'Barber'
  },
  {
    Id: 17622,
    Code: '6221',
    Name: 'Colourist, hairdressing'
  },
  {
    Id: 17623,
    Code: '6221',
    Name: 'Controller, salon, hairdressing'
  },
  {
    Id: 17624,
    Code: '6221',
    Name: 'Designer, hair'
  },
  {
    Id: 17625,
    Code: '6221',
    Name: 'Director, art, hairdressing'
  },
  {
    Id: 17626,
    Code: '6221',
    Name: 'Director, artistic, hairdressing'
  },
  {
    Id: 17627,
    Code: '6221',
    Name: 'Director, style, hairdressing'
  },
  {
    Id: 17628,
    Code: '6221',
    Name: 'Dresser, hair'
  },
  {
    Id: 17629,
    Code: '6221',
    Name: 'Hairdresser'
  },
  {
    Id: 17630,
    Code: '6221',
    Name: 'Hairstylist'
  },
  {
    Id: 17631,
    Code: '6221',
    Name: 'Shampooer'
  },
  {
    Id: 17632,
    Code: '6221',
    Name: 'Shampooist'
  },
  {
    Id: 17633,
    Code: '6221',
    Name: 'Stylist, hair'
  },
  {
    Id: 17634,
    Code: '6221',
    Name: 'Stylist, hairdressing'
  },
  {
    Id: 17635,
    Code: '6221',
    Name: 'Stylist, junior'
  },
  {
    Id: 17636,
    Code: '6221',
    Name: 'Stylist, hairdressing'
  },
  {
    Id: 17637,
    Code: '6221',
    Name: 'Technician, colour, hairdressing'
  },
  {
    Id: 17638,
    Code: '6221',
    Name: 'Technician, hair'
  },
  {
    Id: 17639,
    Code: '6221',
    Name: 'Technician, hair and beauty'
  },
  {
    Id: 17640,
    Code: '6221',
    Name: 'Technician, hairdressing'
  },
  {
    Id: 17641,
    Code: '6221',
    Name: 'Technician, hairdressing'
  },
  {
    Id: 17642,
    Code: '6221',
    Name: 'Therapist, hair and beauty'
  },
  {
    Id: 17643,
    Code: '6222',
    Name: 'Adviser, beauty'
  },
  {
    Id: 17644,
    Code: '6222',
    Name: 'Aesthetician, medical'
  },
  {
    Id: 17645,
    Code: '6222',
    Name: 'Aesthetician'
  },
  {
    Id: 17646,
    Code: '6222',
    Name: 'Analyst, colour'
  },
  {
    Id: 17647,
    Code: '6222',
    Name: 'Artist, body'
  },
  {
    Id: 17648,
    Code: '6222',
    Name: 'Artist, make-up'
  },
  {
    Id: 17649,
    Code: '6222',
    Name: 'Artist, nail'
  },
  {
    Id: 17650,
    Code: '6222',
    Name: 'Artist, retail, make-up'
  },
  {
    Id: 17651,
    Code: '6222',
    Name: 'Beautician'
  },
  {
    Id: 17652,
    Code: '6222',
    Name: 'Consultant, beauty'
  },
  {
    Id: 17653,
    Code: '6222',
    Name: 'Consultant, image, beautician'
  },
  {
    Id: 17654,
    Code: '6222',
    Name: 'Consultant, piercing, ear'
  },
  {
    Id: 17655,
    Code: '6222',
    Name: 'Consultant, skincare'
  },
  {
    Id: 17656,
    Code: '6222',
    Name: 'Consultant, slimming'
  },
  {
    Id: 17657,
    Code: '6222',
    Name: 'Consultant, wig'
  },
  {
    Id: 17658,
    Code: '6222',
    Name: 'Consultant, world, slimming'
  },
  {
    Id: 17659,
    Code: '6222',
    Name: 'Cosmetologist'
  },
  {
    Id: 17660,
    Code: '6222',
    Name: 'Counsellor, loss, weight'
  },
  {
    Id: 17661,
    Code: '6222',
    Name: 'Counsellor, slimming club'
  },
  {
    Id: 17662,
    Code: '6222',
    Name: 'Dresser, wig'
  },
  {
    Id: 17663,
    Code: '6222',
    Name: 'Electrologist'
  },
  {
    Id: 17664,
    Code: '6222',
    Name: 'Electrolysist'
  },
  {
    Id: 17665,
    Code: '6222',
    Name: 'Facialist'
  },
  {
    Id: 17666,
    Code: '6222',
    Name: 'Leader, slimming club'
  },
  {
    Id: 17667,
    Code: '6222',
    Name: 'Lecturer, watchers, weight'
  },
  {
    Id: 17668,
    Code: '6222',
    Name: 'Maker, mask, beautician'
  },
  {
    Id: 17669,
    Code: '6222',
    Name: 'Manicurist'
  },
  {
    Id: 17670,
    Code: '6222',
    Name: 'Pedicurist'
  },
  {
    Id: 17671,
    Code: '6222',
    Name: 'Piercer, body'
  },
  {
    Id: 17672,
    Code: '6222',
    Name: 'Practitioner, aesthetic'
  },
  {
    Id: 17673,
    Code: '6222',
    Name: 'Practitioner, sugaring'
  },
  {
    Id: 17674,
    Code: '6222',
    Name: 'Specialist, beauty'
  },
  {
    Id: 17675,
    Code: '6222',
    Name: 'Specialist, skincare'
  },
  {
    Id: 17676,
    Code: '6222',
    Name: 'Technician, brow'
  },
  {
    Id: 17677,
    Code: '6222',
    Name: 'Technician, eyelash'
  },
  {
    Id: 17678,
    Code: '6222',
    Name: 'Technician, lash'
  },
  {
    Id: 17679,
    Code: '6222',
    Name: 'Technician, nail'
  },
  {
    Id: 17680,
    Code: '6222',
    Name: 'Therapist, aesthetic'
  },
  {
    Id: 17681,
    Code: '6222',
    Name: 'Therapist, beauty'
  },
  {
    Id: 17682,
    Code: '6222',
    Name: 'Therapist, laser'
  },
  {
    Id: 17683,
    Code: '6222',
    Name: 'Therapist, spa'
  },
  {
    Id: 17684,
    Code: '6222',
    Name: 'Therapist, waxing'
  },
  {
    Id: 17685,
    Code: '6222',
    Name: 'Therapist, beauty'
  },
  {
    Id: 17686,
    Code: '6231',
    Name: 'Attendant, domestic service'
  },
  {
    Id: 17687,
    Code: '6231',
    Name: 'Batman, civilian'
  },
  {
    Id: 17688,
    Code: '6231',
    Name: 'Companion'
  },
  {
    Id: 17689,
    Code: '6231',
    Name: 'Companion-help'
  },
  {
    Id: 17690,
    Code: '6231',
    Name: 'Companion-housekeeper'
  },
  {
    Id: 17691,
    Code: '6231',
    Name: 'Cook-housekeeper'
  },
  {
    Id: 17692,
    Code: '6231',
    Name: 'Footman, domestic service'
  },
  {
    Id: 17693,
    Code: '6231',
    Name: 'Host, chalet'
  },
  {
    Id: 17694,
    Code: '6231',
    Name: 'Keeper, house, daily'
  },
  {
    Id: 17695,
    Code: '6231',
    Name: 'Keeper, house'
  },
  {
    Id: 17696,
    Code: '6231',
    Name: 'Keeper-companion, house'
  },
  {
    Id: 17697,
    Code: '6231',
    Name: "Maid, lady's"
  },
  {
    Id: 17698,
    Code: '6231',
    Name: 'Manager, lifestyle'
  },
  {
    Id: 17699,
    Code: '6231',
    Name: 'Orderly, civilian'
  },
  {
    Id: 17700,
    Code: '6231',
    Name: 'Steward, flats'
  },
  {
    Id: 17701,
    Code: '6231',
    Name: 'Steward, service flats'
  },
  {
    Id: 17702,
    Code: '6231',
    Name: 'Steward, university'
  },
  {
    Id: 17703,
    Code: '6231',
    Name: 'Valet'
  },
  {
    Id: 17704,
    Code: '6232',
    Name: "Assistant, caretaker's"
  },
  {
    Id: 17705,
    Code: '6232',
    Name: "Assistant, keeper's, hall, local government"
  },
  {
    Id: 17706,
    Code: '6232',
    Name: "Assistant, keeper's, school"
  },
  {
    Id: 17707,
    Code: '6232',
    Name: 'Assistant, premises'
  },
  {
    Id: 17708,
    Code: '6232',
    Name: 'Attendant, building'
  },
  {
    Id: 17709,
    Code: '6232',
    Name: 'Attendant, hall, town'
  },
  {
    Id: 17710,
    Code: '6232',
    Name: 'Attendant, synagogue'
  },
  {
    Id: 17711,
    Code: '6232',
    Name: 'Caretaker, cemetery'
  },
  {
    Id: 17712,
    Code: '6232',
    Name: 'Caretaker'
  },
  {
    Id: 17713,
    Code: '6232',
    Name: 'Concierge'
  },
  {
    Id: 17714,
    Code: '6232',
    Name: 'Custodian, castle'
  },
  {
    Id: 17715,
    Code: '6232',
    Name: 'Janitor'
  },
  {
    Id: 17716,
    Code: '6232',
    Name: 'Keeper, cemetery'
  },
  {
    Id: 17717,
    Code: '6232',
    Name: 'Keeper, chapel'
  },
  {
    Id: 17718,
    Code: '6232',
    Name: 'Keeper, church'
  },
  {
    Id: 17719,
    Code: '6232',
    Name: 'Keeper, court'
  },
  {
    Id: 17720,
    Code: '6232',
    Name: 'Keeper, door, synagogue'
  },
  {
    Id: 17721,
    Code: '6232',
    Name: 'Keeper, ground, burial'
  },
  {
    Id: 17722,
    Code: '6232',
    Name: 'Keeper, hall'
  },
  {
    Id: 17723,
    Code: '6232',
    Name: 'Keeper, house, resident, offices'
  },
  {
    Id: 17724,
    Code: '6232',
    Name: 'Keeper, house, offices'
  },
  {
    Id: 17725,
    Code: '6232',
    Name: 'Keeper, house, property management'
  },
  {
    Id: 17726,
    Code: '6232',
    Name: 'Keeper, mortuary'
  },
  {
    Id: 17727,
    Code: '6232',
    Name: 'Keeper, office'
  },
  {
    Id: 17728,
    Code: '6232',
    Name: 'Keeper, school'
  },
  {
    Id: 17729,
    Code: '6232',
    Name: 'Manager, premises, school'
  },
  {
    Id: 17730,
    Code: '6232',
    Name: 'Manager, premises, educational establishments'
  },
  {
    Id: 17731,
    Code: '6232',
    Name: 'Manager, site, school'
  },
  {
    Id: 17732,
    Code: '6232',
    Name: 'Manager, site, educational establishments'
  },
  {
    Id: 17733,
    Code: '6232',
    Name: 'Officer, premises'
  },
  {
    Id: 17734,
    Code: '6232',
    Name: 'Officer, services, site, educational establishments'
  },
  {
    Id: 17735,
    Code: '6232',
    Name: 'Officer, site, educational establishments'
  },
  {
    Id: 17736,
    Code: '6232',
    Name: 'Porter, college'
  },
  {
    Id: 17737,
    Code: '6232',
    Name: 'Porter, college'
  },
  {
    Id: 17738,
    Code: '6232',
    Name: 'Porter, schools'
  },
  {
    Id: 17739,
    Code: '6232',
    Name: 'Porter-caretaker'
  },
  {
    Id: 17740,
    Code: '6232',
    Name: 'Sacristan'
  },
  {
    Id: 17741,
    Code: '6232',
    Name: 'Sexton'
  },
  {
    Id: 17742,
    Code: '6232',
    Name: 'Superintendent, building'
  },
  {
    Id: 17743,
    Code: '6232',
    Name: 'Superintendent, hall, town'
  },
  {
    Id: 17744,
    Code: '6232',
    Name: 'Superintendent, house, nos'
  },
  {
    Id: 17745,
    Code: '6232',
    Name: 'Superintendent, precinct'
  },
  {
    Id: 17746,
    Code: '6232',
    Name: 'Superintendent, school'
  },
  {
    Id: 17747,
    Code: '6232',
    Name: 'Superintendent, residential buildings'
  },
  {
    Id: 17748,
    Code: '6232',
    Name: 'Supervisor, services, building, educational establishments'
  },
  {
    Id: 17749,
    Code: '6232',
    Name: 'Supervisor, site, educational establishments'
  },
  {
    Id: 17750,
    Code: '6232',
    Name: 'Taker, care, cemetery'
  },
  {
    Id: 17751,
    Code: '6232',
    Name: 'Taker, care'
  },
  {
    Id: 17752,
    Code: '6232',
    Name: 'Verger'
  },
  {
    Id: 17753,
    Code: '6232',
    Name: 'Warden, barrack'
  },
  {
    Id: 17754,
    Code: '6232',
    Name: 'Warden, camp'
  },
  {
    Id: 17755,
    Code: '6232',
    Name: 'Warden, castle'
  },
  {
    Id: 17756,
    Code: '6232',
    Name: 'Warden, park, caravan'
  },
  {
    Id: 17757,
    Code: '6232',
    Name: 'Warden, resident'
  },
  {
    Id: 17758,
    Code: '6232',
    Name: 'Warden, site, caravan'
  },
  {
    Id: 17759,
    Code: '6232',
    Name: 'Warden, station'
  },
  {
    Id: 17760,
    Code: '6232',
    Name: 'Warden, sheltered housing'
  },
  {
    Id: 17761,
    Code: '6232',
    Name: 'Warden, caravan site'
  },
  {
    Id: 17762,
    Code: '6232',
    Name: 'Warden, hostel'
  },
  {
    Id: 17763,
    Code: '6232',
    Name: 'Warden, MOD'
  },
  {
    Id: 17764,
    Code: '6232',
    Name: "Warden, nurse's home"
  },
  {
    Id: 17765,
    Code: '6232',
    Name: 'Warden, police service'
  },
  {
    Id: 17766,
    Code: '6240',
    Name: 'Butler'
  },
  {
    Id: 17767,
    Code: '6240',
    Name: 'Cleaner-in-charge'
  },
  {
    Id: 17768,
    Code: '6240',
    Name: 'Head of housekeeping'
  },
  {
    Id: 17769,
    Code: '6240',
    Name: 'Inspector, office cleaning services'
  },
  {
    Id: 17770,
    Code: '6240',
    Name: 'Keeper, house, head'
  },
  {
    Id: 17771,
    Code: '6240',
    Name: 'Keeper, house, hotel'
  },
  {
    Id: 17772,
    Code: '6240',
    Name: 'Keeper, house, hotel'
  },
  {
    Id: 17773,
    Code: '6240',
    Name: 'Keeper, house, schools'
  },
  {
    Id: 17774,
    Code: '6240',
    Name: 'Leader, team, hygiene'
  },
  {
    Id: 17775,
    Code: '6240',
    Name: 'Leader, team, services, housekeeping'
  },
  {
    Id: 17776,
    Code: '6240',
    Name: 'Leader, team, cleaning'
  },
  {
    Id: 17777,
    Code: '6240',
    Name: 'Manager, cleaning'
  },
  {
    Id: 17778,
    Code: '6240',
    Name: 'Manager, domestic'
  },
  {
    Id: 17779,
    Code: '6240',
    Name: 'Manager, housekeeping'
  },
  {
    Id: 17780,
    Code: '6240',
    Name: 'Manager, room, linen, hospital service'
  },
  {
    Id: 17781,
    Code: '6240',
    Name: 'Manager, service, guest'
  },
  {
    Id: 17782,
    Code: '6240',
    Name: 'Manager, services, domestic'
  },
  {
    Id: 17783,
    Code: '6240',
    Name: 'Manager, services, linen'
  },
  {
    Id: 17784,
    Code: '6240',
    Name: 'Manager, valeting'
  },
  {
    Id: 17785,
    Code: '6240',
    Name: 'Manager, cleaning services'
  },
  {
    Id: 17786,
    Code: '6240',
    Name: 'Sister, housekeeping, hospital'
  },
  {
    Id: 17787,
    Code: '6240',
    Name: 'Superintendent, domestic service'
  },
  {
    Id: 17788,
    Code: '6240',
    Name: 'Supervisor, bedroom'
  },
  {
    Id: 17789,
    Code: '6240',
    Name: 'Supervisor, cleaner'
  },
  {
    Id: 17790,
    Code: '6240',
    Name: 'Supervisor, cleaning'
  },
  {
    Id: 17791,
    Code: '6240',
    Name: 'Supervisor, domestic, hospital service'
  },
  {
    Id: 17792,
    Code: '6240',
    Name: 'Supervisor, domestic'
  },
  {
    Id: 17793,
    Code: '6240',
    Name: 'Supervisor, floor, hotel'
  },
  {
    Id: 17794,
    Code: '6240',
    Name: 'Supervisor, housekeeping'
  },
  {
    Id: 17795,
    Code: '6240',
    Name: 'Supervisor, room, linen, hospital service'
  },
  {
    Id: 17796,
    Code: '6240',
    Name: 'Supervisor, services, hotel'
  },
  {
    Id: 17797,
    Code: '6240',
    Name: 'Supervisor, cleaning'
  },
  {
    Id: 17798,
    Code: '6250',
    Name: 'Hotelier, bed and breakfast accommodation'
  },
  {
    Id: 17799,
    Code: '6250',
    Name: 'Hotelier, guest house'
  },
  {
    Id: 17800,
    Code: '6250',
    Name: 'Keeper, house, guest'
  },
  {
    Id: 17801,
    Code: '6250',
    Name: 'Landlady, guest house'
  },
  {
    Id: 17802,
    Code: '6250',
    Name: 'Landlord, guest house'
  },
  {
    Id: 17803,
    Code: '6250',
    Name: 'Manager, house, guest'
  },
  {
    Id: 17804,
    Code: '6250',
    Name: 'Owner, house, guest'
  },
  {
    Id: 17805,
    Code: '6250',
    Name: 'Owner, bed and breakfast accommodation'
  },
  {
    Id: 17806,
    Code: '6250',
    Name: 'Owner, guest house'
  },
  {
    Id: 17807,
    Code: '6250',
    Name: 'Provider, bed and breakfast'
  },
  {
    Id: 17808,
    Code: '6311',
    Name: 'Officer, community, rail'
  },
  {
    Id: 17809,
    Code: '6311',
    Name: 'Officer, protection, community, police'
  },
  {
    Id: 17810,
    Code: '6311',
    Name: 'Officer, protection, community, police service'
  },
  {
    Id: 17811,
    Code: '6311',
    Name: 'Officer, support, civilian, police service'
  },
  {
    Id: 17812,
    Code: '6311',
    Name: 'Officer, support, community, police'
  },
  {
    Id: 17813,
    Code: '6311',
    Name: 'Officer, support, community, police service'
  },
  {
    Id: 17814,
    Code: '6311',
    Name: 'PCSO'
  },
  {
    Id: 17815,
    Code: '6312',
    Name: 'Agent, enforcement, civil'
  },
  {
    Id: 17816,
    Code: '6312',
    Name: 'Ambassador, centre, city'
  },
  {
    Id: 17817,
    Code: '6312',
    Name: 'Assistant, meter'
  },
  {
    Id: 17818,
    Code: '6312',
    Name: 'Assistant, park, car'
  },
  {
    Id: 17819,
    Code: '6312',
    Name: 'Attendant, meter, parking'
  },
  {
    Id: 17820,
    Code: '6312',
    Name: 'Attendant, park, car'
  },
  {
    Id: 17821,
    Code: '6312',
    Name: 'Attendant, parking'
  },
  {
    Id: 17822,
    Code: '6312',
    Name: 'Clamper, wheel'
  },
  {
    Id: 17823,
    Code: '6312',
    Name: 'Inspector, park, car'
  },
  {
    Id: 17824,
    Code: '6312',
    Name: 'Officer, enforcement, civil'
  },
  {
    Id: 17825,
    Code: '6312',
    Name: 'Officer, enforcement, parking'
  },
  {
    Id: 17826,
    Code: '6312',
    Name: 'Officer, park, car'
  },
  {
    Id: 17827,
    Code: '6312',
    Name: 'Operative, scene, street'
  },
  {
    Id: 17828,
    Code: '6312',
    Name: 'Sergeant, town'
  },
  {
    Id: 17829,
    Code: '6312',
    Name: 'Steward, park, car'
  },
  {
    Id: 17830,
    Code: '6312',
    Name: 'Supervisor, park, car'
  },
  {
    Id: 17831,
    Code: '6312',
    Name: 'Supervisor, car park'
  },
  {
    Id: 17832,
    Code: '6312',
    Name: 'Warden, community'
  },
  {
    Id: 17833,
    Code: '6312',
    Name: 'Warden, estate, housing services'
  },
  {
    Id: 17834,
    Code: '6312',
    Name: 'Warden, neighbourhood'
  },
  {
    Id: 17835,
    Code: '6312',
    Name: 'Warden, street'
  },
  {
    Id: 17836,
    Code: '6312',
    Name: 'Warden, traffic'
  },
  {
    Id: 17837,
    Code: '7111',
    Name: 'Adviser, customer, retail trade'
  },
  {
    Id: 17838,
    Code: '7111',
    Name: 'Adviser, fashion, retail trade'
  },
  {
    Id: 17839,
    Code: '7111',
    Name: 'Adviser, sales'
  },
  {
    Id: 17840,
    Code: '7111',
    Name: 'Adviser, service, customer, wholesale, retail trade'
  },
  {
    Id: 17841,
    Code: '7111',
    Name: 'Adviser, services, customer, retail trade'
  },
  {
    Id: 17842,
    Code: '7111',
    Name: "Agent, cleaner's, dry"
  },
  {
    Id: 17843,
    Code: '7111',
    Name: "Agent, dyer's"
  },
  {
    Id: 17844,
    Code: '7111',
    Name: 'Agent, laundry'
  },
  {
    Id: 17845,
    Code: '7111',
    Name: 'Agent, receiving, laundry'
  },
  {
    Id: 17846,
    Code: '7111',
    Name: 'Assistant, away, take'
  },
  {
    Id: 17847,
    Code: '7111',
    Name: 'Assistant, bakery, retail trade'
  },
  {
    Id: 17848,
    Code: '7111',
    Name: "Assistant, bookseller's"
  },
  {
    Id: 17849,
    Code: '7111',
    Name: 'Assistant, bookstall'
  },
  {
    Id: 17850,
    Code: '7111',
    Name: "Assistant, cashier's, retail trade"
  },
  {
    Id: 17851,
    Code: '7111',
    Name: 'Assistant, centre, garden'
  },
  {
    Id: 17852,
    Code: '7111',
    Name: 'Assistant, confectioner and tobacconist'
  },
  {
    Id: 17853,
    Code: '7111',
    Name: "Assistant, confectioner's"
  },
  {
    Id: 17854,
    Code: '7111',
    Name: 'Assistant, counter, take-away food shop'
  },
  {
    Id: 17855,
    Code: '7111',
    Name: 'Assistant, counter'
  },
  {
    Id: 17856,
    Code: '7111',
    Name: 'Assistant, customer, retail trade'
  },
  {
    Id: 17857,
    Code: '7111',
    Name: 'Assistant, dairy, retail trade'
  },
  {
    Id: 17858,
    Code: '7111',
    Name: "Assistant, dairyman's, retail trade"
  },
  {
    Id: 17859,
    Code: '7111',
    Name: "Assistant, dealer's, wholesale, retail trade"
  },
  {
    Id: 17860,
    Code: '7111',
    Name: 'Assistant, delicatessen'
  },
  {
    Id: 17861,
    Code: '7111',
    Name: "Assistant, draper's"
  },
  {
    Id: 17862,
    Code: '7111',
    Name: 'Assistant, experience, customer, retail trade'
  },
  {
    Id: 17863,
    Code: '7111',
    Name: 'Assistant, floor, shop, retail trade'
  },
  {
    Id: 17864,
    Code: '7111',
    Name: "Assistant, florist's"
  },
  {
    Id: 17865,
    Code: '7111',
    Name: "Assistant, fruiterer's"
  },
  {
    Id: 17866,
    Code: '7111',
    Name: "Assistant, furrier's"
  },
  {
    Id: 17867,
    Code: '7111',
    Name: "Assistant, grocer's"
  },
  {
    Id: 17868,
    Code: '7111',
    Name: 'Assistant, haberdashery'
  },
  {
    Id: 17869,
    Code: '7111',
    Name: "Assistant, jeweller's"
  },
  {
    Id: 17870,
    Code: '7111',
    Name: 'Assistant, kiosk'
  },
  {
    Id: 17871,
    Code: '7111',
    Name: "Assistant, mercer's"
  },
  {
    Id: 17872,
    Code: '7111',
    Name: "Assistant, merchant's"
  },
  {
    Id: 17873,
    Code: '7111',
    Name: 'Assistant, NAAFI'
  },
  {
    Id: 17874,
    Code: '7111',
    Name: "Assistant, newsagent's"
  },
  {
    Id: 17875,
    Code: '7111',
    Name: 'Assistant, off-licence'
  },
  {
    Id: 17876,
    Code: '7111',
    Name: 'Assistant, office, post, sub-post office'
  },
  {
    Id: 17877,
    Code: '7111',
    Name: 'Assistant, office, sub-post'
  },
  {
    Id: 17878,
    Code: '7111',
    Name: 'Assistant, operations, retail trade'
  },
  {
    Id: 17879,
    Code: '7111',
    Name: "Assistant, pawnbroker's"
  },
  {
    Id: 17880,
    Code: '7111',
    Name: "Assistant, perfumer's"
  },
  {
    Id: 17881,
    Code: '7111',
    Name: "Assistant, poulterer's"
  },
  {
    Id: 17882,
    Code: '7111',
    Name: 'Assistant, retail'
  },
  {
    Id: 17883,
    Code: '7111',
    Name: 'Assistant, room, sale, wholesale, retail trade'
  },
  {
    Id: 17884,
    Code: '7111',
    Name: 'Assistant, room, show'
  },
  {
    Id: 17885,
    Code: '7111',
    Name: 'Assistant, sales'
  },
  {
    Id: 17886,
    Code: '7111',
    Name: "Assistant, seedsman's"
  },
  {
    Id: 17887,
    Code: '7111',
    Name: 'Assistant, service, customer, retail trade'
  },
  {
    Id: 17888,
    Code: '7111',
    Name: 'Assistant, service, customer, wholesale trade'
  },
  {
    Id: 17889,
    Code: '7111',
    Name: 'Assistant, services, customer, retail trade'
  },
  {
    Id: 17890,
    Code: '7111',
    Name: 'Assistant, shop'
  },
  {
    Id: 17891,
    Code: '7111',
    Name: 'Assistant, staff, retail trade'
  },
  {
    Id: 17892,
    Code: '7111',
    Name: 'Assistant, stall, book'
  },
  {
    Id: 17893,
    Code: '7111',
    Name: "Assistant, stationer's"
  },
  {
    Id: 17894,
    Code: '7111',
    Name: 'Assistant, store, retail trade'
  },
  {
    Id: 17895,
    Code: '7111',
    Name: 'Assistant, stores, retail trade'
  },
  {
    Id: 17896,
    Code: '7111',
    Name: 'Assistant, takeaway'
  },
  {
    Id: 17897,
    Code: '7111',
    Name: 'Assistant, retail trade'
  },
  {
    Id: 17898,
    Code: '7111',
    Name: 'Assistant, take-away food shop'
  },
  {
    Id: 17899,
    Code: '7111',
    Name: 'Associate, customer, retail trade'
  },
  {
    Id: 17900,
    Code: '7111',
    Name: 'Associate, retail'
  },
  {
    Id: 17901,
    Code: '7111',
    Name: 'Associate, sales, wholesale, retail trade'
  },
  {
    Id: 17902,
    Code: '7111',
    Name: 'Athlete, retail trade'
  },
  {
    Id: 17903,
    Code: '7111',
    Name: 'Attendant, kiosk'
  },
  {
    Id: 17904,
    Code: '7111',
    Name: 'Attendant, room, show'
  },
  {
    Id: 17905,
    Code: '7111',
    Name: 'Attendant, stores, retail trade'
  },
  {
    Id: 17906,
    Code: '7111',
    Name: 'Bookseller'
  },
  {
    Id: 17907,
    Code: '7111',
    Name: 'Boy, programme'
  },
  {
    Id: 17908,
    Code: '7111',
    Name: 'CSA, retail trade'
  },
  {
    Id: 17909,
    Code: '7111',
    Name: 'Colleague, Asda'
  },
  {
    Id: 17910,
    Code: '7111',
    Name: 'Colleague, service, customer, retail trade'
  },
  {
    Id: 17911,
    Code: '7111',
    Name: 'Consultant, beauty, retail trade'
  },
  {
    Id: 17912,
    Code: '7111',
    Name: 'Consultant, bridal, retail trade'
  },
  {
    Id: 17913,
    Code: '7111',
    Name: 'Consultant, carpet, retail trade'
  },
  {
    Id: 17914,
    Code: '7111',
    Name: 'Consultant, cosmetics'
  },
  {
    Id: 17915,
    Code: '7111',
    Name: 'Consultant, fashion, retail trade'
  },
  {
    Id: 17916,
    Code: '7111',
    Name: 'Consultant, food, retail trade'
  },
  {
    Id: 17917,
    Code: '7111',
    Name: 'Consultant, furniture'
  },
  {
    Id: 17918,
    Code: '7111',
    Name: 'Consultant, perfumery'
  },
  {
    Id: 17919,
    Code: '7111',
    Name: 'Consultant, sales, retail trade'
  },
  {
    Id: 17920,
    Code: '7111',
    Name: 'Counsellor, beauty, retail trade'
  },
  {
    Id: 17921,
    Code: '7111',
    Name: 'Counterhand, take-away food shop'
  },
  {
    Id: 17922,
    Code: '7111',
    Name: 'Counterhand, wholesale, retail trade'
  },
  {
    Id: 17923,
    Code: '7111',
    Name: 'Counterman, retail trade'
  },
  {
    Id: 17924,
    Code: '7111',
    Name: 'Counterman, take-away food shop'
  },
  {
    Id: 17925,
    Code: '7111',
    Name: 'Cutter, cheese'
  },
  {
    Id: 17926,
    Code: '7111',
    Name: 'Dairyman, retail trade'
  },
  {
    Id: 17927,
    Code: '7111',
    Name: 'Executive, sales, retail'
  },
  {
    Id: 17928,
    Code: '7111',
    Name: 'Executive, sales, retail trade'
  },
  {
    Id: 17929,
    Code: '7111',
    Name: 'Fitter, bra'
  },
  {
    Id: 17930,
    Code: '7111',
    Name: 'Fitter, shoe, retail trade'
  },
  {
    Id: 17931,
    Code: '7111',
    Name: 'Hand, bacon'
  },
  {
    Id: 17932,
    Code: '7111',
    Name: 'Hand, first, retail trade'
  },
  {
    Id: 17933,
    Code: '7111',
    Name: 'Hand, provision'
  },
  {
    Id: 17934,
    Code: '7111',
    Name: 'Help, part-time, retail trade'
  },
  {
    Id: 17935,
    Code: '7111',
    Name: 'Helper, part-time, retail trade'
  },
  {
    Id: 17936,
    Code: '7111',
    Name: 'Inspector, NAAFI'
  },
  {
    Id: 17937,
    Code: '7111',
    Name: 'Member, cast, retail trade'
  },
  {
    Id: 17938,
    Code: '7111',
    Name: 'Member, team, customer'
  },
  {
    Id: 17939,
    Code: '7111',
    Name: 'Member, team, retail trade'
  },
  {
    Id: 17940,
    Code: '7111',
    Name: 'Newsboy, bookstall'
  },
  {
    Id: 17941,
    Code: '7111',
    Name: 'Operative, kiosk, retail trade'
  },
  {
    Id: 17942,
    Code: '7111',
    Name: 'Operator, kiosk, retail trade'
  },
  {
    Id: 17943,
    Code: '7111',
    Name: 'Player, team, retail trade'
  },
  {
    Id: 17944,
    Code: '7111',
    Name: 'Receiver, laundry, launderette, dry cleaning'
  },
  {
    Id: 17945,
    Code: '7111',
    Name: 'Representative, brand, retail trade'
  },
  {
    Id: 17946,
    Code: '7111',
    Name: 'Salesman, bread, retail trade'
  },
  {
    Id: 17947,
    Code: '7111',
    Name: "Salesman, butcher's"
  },
  {
    Id: 17948,
    Code: '7111',
    Name: 'Salesman, counter'
  },
  {
    Id: 17949,
    Code: '7111',
    Name: 'Salesman, fish'
  },
  {
    Id: 17950,
    Code: '7111',
    Name: 'Salesman, fish and fruit'
  },
  {
    Id: 17951,
    Code: '7111',
    Name: 'Salesman, fish and poultry'
  },
  {
    Id: 17952,
    Code: '7111',
    Name: "Salesman, fishmonger's"
  },
  {
    Id: 17953,
    Code: '7111',
    Name: 'Salesman, indoor'
  },
  {
    Id: 17954,
    Code: '7111',
    Name: 'Salesman, market, wholesale trade'
  },
  {
    Id: 17955,
    Code: '7111',
    Name: 'Salesman, meat'
  },
  {
    Id: 17956,
    Code: '7111',
    Name: 'Salesman, milk, retail trade'
  },
  {
    Id: 17957,
    Code: '7111',
    Name: 'Salesman, retail'
  },
  {
    Id: 17958,
    Code: '7111',
    Name: 'Salesman, shop'
  },
  {
    Id: 17959,
    Code: '7111',
    Name: 'Salesman, showroom'
  },
  {
    Id: 17960,
    Code: '7111',
    Name: 'Salesman, television'
  },
  {
    Id: 17961,
    Code: '7111',
    Name: 'Salesman, TV'
  },
  {
    Id: 17962,
    Code: '7111',
    Name: 'Salesman, warehouse'
  },
  {
    Id: 17963,
    Code: '7111',
    Name: 'Salesman, building construction'
  },
  {
    Id: 17964,
    Code: '7111',
    Name: 'Salesman, retail trade'
  },
  {
    Id: 17965,
    Code: '7111',
    Name: 'Seller, book, The Stationery Office'
  },
  {
    Id: 17966,
    Code: '7111',
    Name: 'Seller, book'
  },
  {
    Id: 17967,
    Code: '7111',
    Name: 'Seller, fish and chips'
  },
  {
    Id: 17968,
    Code: '7111',
    Name: 'Seller, programme'
  },
  {
    Id: 17969,
    Code: '7111',
    Name: 'Server, take-away food shop'
  },
  {
    Id: 17970,
    Code: '7111',
    Name: 'Shopper, personal'
  },
  {
    Id: 17971,
    Code: '7111',
    Name: 'Staff, retail'
  },
  {
    Id: 17972,
    Code: '7111',
    Name: 'Stylist, personal, retail trade'
  },
  {
    Id: 17973,
    Code: '7111',
    Name: 'Worker, kiosk, retail trade'
  },
  {
    Id: 17974,
    Code: '7111',
    Name: 'Worker, retail'
  },
  {
    Id: 17975,
    Code: '7111',
    Name: 'Worker, shop, charity'
  },
  {
    Id: 17976,
    Code: '7111',
    Name: 'Worker, shop, fried fish'
  },
  {
    Id: 17977,
    Code: '7111',
    Name: 'Worker, shop, retail trade'
  },
  {
    Id: 17978,
    Code: '7111',
    Name: 'Worker, shop, take-away food shop'
  },
  {
    Id: 17979,
    Code: '7112',
    Name: 'Adviser, customer, checkout'
  },
  {
    Id: 17980,
    Code: '7112',
    Name: 'Assistant, cash and wrap'
  },
  {
    Id: 17981,
    Code: '7112',
    Name: 'Assistant, checkout'
  },
  {
    Id: 17982,
    Code: '7112',
    Name: 'Assistant, customer, checkout'
  },
  {
    Id: 17983,
    Code: '7112',
    Name: 'Assistant, customer, petrol station'
  },
  {
    Id: 17984,
    Code: '7112',
    Name: 'Assistant, experience, customer, checkout'
  },
  {
    Id: 17985,
    Code: '7112',
    Name: 'Assistant, forecourt'
  },
  {
    Id: 17986,
    Code: '7112',
    Name: 'Assistant, garage, petrol station'
  },
  {
    Id: 17987,
    Code: '7112',
    Name: 'Assistant, general, checkout, retail trade'
  },
  {
    Id: 17988,
    Code: '7112',
    Name: 'Assistant, general, checkout, retail trade'
  },
  {
    Id: 17989,
    Code: '7112',
    Name: 'Assistant, petrol'
  },
  {
    Id: 17990,
    Code: '7112',
    Name: 'Assistant, sales, forecourt'
  },
  {
    Id: 17991,
    Code: '7112',
    Name: 'Assistant, sales, petrol'
  },
  {
    Id: 17992,
    Code: '7112',
    Name: 'Assistant, sales, supermarket'
  },
  {
    Id: 17993,
    Code: '7112',
    Name: 'Assistant, sales, petrol station'
  },
  {
    Id: 17994,
    Code: '7112',
    Name: 'Assistant, scanner, retail trade'
  },
  {
    Id: 17995,
    Code: '7112',
    Name: 'Assistant, scanning, retail trade'
  },
  {
    Id: 17996,
    Code: '7112',
    Name: 'Assistant, service, customer, petrol station'
  },
  {
    Id: 17997,
    Code: '7112',
    Name: 'Assistant, shop, forecourt'
  },
  {
    Id: 17998,
    Code: '7112',
    Name: 'Assistant, station, petrol'
  },
  {
    Id: 17999,
    Code: '7112',
    Name: 'Assistant, supermarket'
  },
  {
    Id: 18000,
    Code: '7112',
    Name: 'Assistant, till'
  },
  {
    Id: 18001,
    Code: '7112',
    Name: 'Attendant, forecourt'
  },
  {
    Id: 18002,
    Code: '7112',
    Name: 'Attendant, garage, petrol station'
  },
  {
    Id: 18003,
    Code: '7112',
    Name: 'Attendant, petrol'
  },
  {
    Id: 18004,
    Code: '7112',
    Name: 'Attendant, pump, petrol'
  },
  {
    Id: 18005,
    Code: '7112',
    Name: 'Attendant, pump, garage'
  },
  {
    Id: 18006,
    Code: '7112',
    Name: 'Attendant, station, filling'
  },
  {
    Id: 18007,
    Code: '7112',
    Name: 'Attendant, station, petrol'
  },
  {
    Id: 18008,
    Code: '7112',
    Name: 'Attendant, station, service'
  },
  {
    Id: 18009,
    Code: '7112',
    Name: 'CTM, retail trade'
  },
  {
    Id: 18010,
    Code: '7112',
    Name: 'Cashier, checkout'
  },
  {
    Id: 18011,
    Code: '7112',
    Name: 'Cashier, chief, retail trade'
  },
  {
    Id: 18012,
    Code: '7112',
    Name: 'Cashier, forecourt'
  },
  {
    Id: 18013,
    Code: '7112',
    Name: 'Cashier, sales'
  },
  {
    Id: 18014,
    Code: '7112',
    Name: 'Cashier, shop'
  },
  {
    Id: 18015,
    Code: '7112',
    Name: 'Cashier, station, petrol'
  },
  {
    Id: 18016,
    Code: '7112',
    Name: 'Cashier, supermarket'
  },
  {
    Id: 18017,
    Code: '7112',
    Name: 'Cashier, café'
  },
  {
    Id: 18018,
    Code: '7112',
    Name: 'Cashier, canteen'
  },
  {
    Id: 18019,
    Code: '7112',
    Name: 'Cashier, restaurant'
  },
  {
    Id: 18020,
    Code: '7112',
    Name: 'Cashier, retail trade'
  },
  {
    Id: 18021,
    Code: '7112',
    Name: 'Cashier, wholesale trade'
  },
  {
    Id: 18022,
    Code: '7112',
    Name: 'Checker-out'
  },
  {
    Id: 18023,
    Code: '7112',
    Name: 'Clerk, checkout'
  },
  {
    Id: 18024,
    Code: '7112',
    Name: 'Clerk, EPOS'
  },
  {
    Id: 18025,
    Code: '7112',
    Name: 'Clerk, scanner, retail trade'
  },
  {
    Id: 18026,
    Code: '7112',
    Name: 'Clerk, scanning, retail trade'
  },
  {
    Id: 18027,
    Code: '7112',
    Name: 'Controller, EPOS'
  },
  {
    Id: 18028,
    Code: '7112',
    Name: 'Member, team, customer, retail trade'
  },
  {
    Id: 18029,
    Code: '7112',
    Name: 'Operative, checkout'
  },
  {
    Id: 18030,
    Code: '7112',
    Name: 'Operator, cash and wrap'
  },
  {
    Id: 18031,
    Code: '7112',
    Name: 'Operator, checkout'
  },
  {
    Id: 18032,
    Code: '7112',
    Name: 'Operator, consol, petrol station'
  },
  {
    Id: 18033,
    Code: '7112',
    Name: 'Operator, console, petrol station'
  },
  {
    Id: 18034,
    Code: '7112',
    Name: 'Operator, EPOS'
  },
  {
    Id: 18035,
    Code: '7112',
    Name: 'Operator, scanner, retail trade'
  },
  {
    Id: 18036,
    Code: '7112',
    Name: 'Operator, scanning, retail trade'
  },
  {
    Id: 18037,
    Code: '7112',
    Name: 'Operator, till'
  },
  {
    Id: 18038,
    Code: '7112',
    Name: 'Pumpman, petrol station'
  },
  {
    Id: 18039,
    Code: '7112',
    Name: 'Refueller, vehicles'
  },
  {
    Id: 18040,
    Code: '7112',
    Name: 'Salesman, petrol, petrol station'
  },
  {
    Id: 18041,
    Code: '7113',
    Name: 'Adviser, sales, inbound'
  },
  {
    Id: 18042,
    Code: '7113',
    Name: 'Adviser, sales, media'
  },
  {
    Id: 18043,
    Code: '7113',
    Name: 'Adviser, sales, telephone sales'
  },
  {
    Id: 18044,
    Code: '7113',
    Name: 'Adviser, telemarketing'
  },
  {
    Id: 18045,
    Code: '7113',
    Name: 'Adviser, telesales'
  },
  {
    Id: 18046,
    Code: '7113',
    Name: 'Agent, sales, inbound'
  },
  {
    Id: 18047,
    Code: '7113',
    Name: 'Agent, sales, telephone'
  },
  {
    Id: 18048,
    Code: '7113',
    Name: 'Agent, telemarketing'
  },
  {
    Id: 18049,
    Code: '7113',
    Name: 'Agent, telesales'
  },
  {
    Id: 18050,
    Code: '7113',
    Name: 'Assistant, marketing, telephone'
  },
  {
    Id: 18051,
    Code: '7113',
    Name: 'Assistant, sales, telephone'
  },
  {
    Id: 18052,
    Code: '7113',
    Name: 'Assistant, telemarketing'
  },
  {
    Id: 18053,
    Code: '7113',
    Name: 'Assistant, telesales'
  },
  {
    Id: 18054,
    Code: '7113',
    Name: 'Canvasser, tele-ad'
  },
  {
    Id: 18055,
    Code: '7113',
    Name: 'Canvasser, telephone'
  },
  {
    Id: 18056,
    Code: '7113',
    Name: 'Canvasser, telesales'
  },
  {
    Id: 18057,
    Code: '7113',
    Name: 'Clerk, order, telephone'
  },
  {
    Id: 18058,
    Code: '7113',
    Name: 'Clerk, sales, telephone'
  },
  {
    Id: 18059,
    Code: '7113',
    Name: 'Clerk, tele-ad'
  },
  {
    Id: 18060,
    Code: '7113',
    Name: 'Clerk, telesales'
  },
  {
    Id: 18061,
    Code: '7113',
    Name: 'Consultant, sales, tele'
  },
  {
    Id: 18062,
    Code: '7113',
    Name: 'Consultant, telesales'
  },
  {
    Id: 18063,
    Code: '7113',
    Name: 'Correspondent, sales'
  },
  {
    Id: 18064,
    Code: '7113',
    Name: 'Developer, business, telesales'
  },
  {
    Id: 18065,
    Code: '7113',
    Name: 'Executive, generation, lead'
  },
  {
    Id: 18066,
    Code: '7113',
    Name: 'Executive, sales, commercial, telephone'
  },
  {
    Id: 18067,
    Code: '7113',
    Name: 'Executive, sales, telephone'
  },
  {
    Id: 18068,
    Code: '7113',
    Name: 'Executive, sales, telephone sales'
  },
  {
    Id: 18069,
    Code: '7113',
    Name: 'Executive, telemarketing'
  },
  {
    Id: 18070,
    Code: '7113',
    Name: 'Executive, telesales'
  },
  {
    Id: 18071,
    Code: '7113',
    Name: 'Generator, lead'
  },
  {
    Id: 18072,
    Code: '7113',
    Name: 'Girl, tele-ad'
  },
  {
    Id: 18073,
    Code: '7113',
    Name: 'Operative, telesales'
  },
  {
    Id: 18074,
    Code: '7113',
    Name: 'Operator, line, order'
  },
  {
    Id: 18075,
    Code: '7113',
    Name: 'Operator, sales, telephone'
  },
  {
    Id: 18076,
    Code: '7113',
    Name: 'Operator, telemarketing'
  },
  {
    Id: 18077,
    Code: '7113',
    Name: 'Operator, telesales'
  },
  {
    Id: 18078,
    Code: '7113',
    Name: 'Representative, sales, telephone'
  },
  {
    Id: 18079,
    Code: '7113',
    Name: 'Representative, tele-ad'
  },
  {
    Id: 18080,
    Code: '7113',
    Name: 'Representative, telemarketing'
  },
  {
    Id: 18081,
    Code: '7113',
    Name: 'Representative, telesales'
  },
  {
    Id: 18082,
    Code: '7113',
    Name: 'Salesman, telephone'
  },
  {
    Id: 18083,
    Code: '7113',
    Name: 'Telecanvasser'
  },
  {
    Id: 18084,
    Code: '7113',
    Name: 'Telemarketer'
  },
  {
    Id: 18085,
    Code: '7113',
    Name: 'Telephonist, marketing'
  },
  {
    Id: 18086,
    Code: '7113',
    Name: 'Telephonist, sales'
  },
  {
    Id: 18087,
    Code: '7113',
    Name: 'Telesales'
  },
  {
    Id: 18088,
    Code: '7114',
    Name: 'Adviser, healthcare, retail pharmacy'
  },
  {
    Id: 18089,
    Code: '7114',
    Name: 'Adviser, optical'
  },
  {
    Id: 18090,
    Code: '7114',
    Name: 'Adviser, pharmacy'
  },
  {
    Id: 18091,
    Code: '7114',
    Name: 'Adviser, sales, optical'
  },
  {
    Id: 18092,
    Code: '7114',
    Name: 'Assistant, care, eye'
  },
  {
    Id: 18093,
    Code: '7114',
    Name: 'Assistant, care, health, retail pharmacy'
  },
  {
    Id: 18094,
    Code: '7114',
    Name: "Assistant, chemist's, retail trade"
  },
  {
    Id: 18095,
    Code: '7114',
    Name: "Assistant, chemist's"
  },
  {
    Id: 18096,
    Code: '7114',
    Name: 'Assistant, counter, medical'
  },
  {
    Id: 18097,
    Code: '7114',
    Name: 'Assistant, counter, medicine'
  },
  {
    Id: 18098,
    Code: '7114',
    Name: 'Assistant, counter, medicines'
  },
  {
    Id: 18099,
    Code: '7114',
    Name: 'Assistant, counter, pharmacy'
  },
  {
    Id: 18100,
    Code: '7114',
    Name: 'Assistant, counter, retail pharmacy'
  },
  {
    Id: 18101,
    Code: '7114',
    Name: 'Assistant, dispensary'
  },
  {
    Id: 18102,
    Code: '7114',
    Name: "Assistant, dispenser's"
  },
  {
    Id: 18103,
    Code: '7114',
    Name: 'Assistant, dispensing, optical'
  },
  {
    Id: 18104,
    Code: '7114',
    Name: 'Assistant, dispensing, pharmacy'
  },
  {
    Id: 18105,
    Code: '7114',
    Name: 'Assistant, dispensing, opticians'
  },
  {
    Id: 18106,
    Code: '7114',
    Name: 'Assistant, dispensing, retail pharmacy'
  },
  {
    Id: 18107,
    Code: '7114',
    Name: 'Assistant, dispensing'
  },
  {
    Id: 18108,
    Code: '7114',
    Name: 'Assistant, healthcare, retail pharmacy'
  },
  {
    Id: 18109,
    Code: '7114',
    Name: 'Assistant, lens, contact'
  },
  {
    Id: 18110,
    Code: '7114',
    Name: 'Assistant, ophthalmic'
  },
  {
    Id: 18111,
    Code: '7114',
    Name: 'Assistant, optical'
  },
  {
    Id: 18112,
    Code: '7114',
    Name: "Assistant, optician's"
  },
  {
    Id: 18113,
    Code: '7114',
    Name: 'Assistant, optometrist’s'
  },
  {
    Id: 18114,
    Code: '7114',
    Name: 'Assistant, pharmaceutical, retail pharmacy'
  },
  {
    Id: 18115,
    Code: '7114',
    Name: "Assistant, pharmacist's"
  },
  {
    Id: 18116,
    Code: '7114',
    Name: 'Assistant, pharmacy'
  },
  {
    Id: 18117,
    Code: '7114',
    Name: 'Assistant, sales, retail pharmacy'
  },
  {
    Id: 18118,
    Code: '7114',
    Name: 'Assistant, shop, chemist’s'
  },
  {
    Id: 18119,
    Code: '7114',
    Name: 'Assistant, shop, retail pharmacy'
  },
  {
    Id: 18120,
    Code: '7114',
    Name: 'ATO, pharmacy'
  },
  {
    Id: 18121,
    Code: '7114',
    Name: 'Consultant, optical'
  },
  {
    Id: 18122,
    Code: '7114',
    Name: 'Dispenser, chemist'
  },
  {
    Id: 18123,
    Code: '7114',
    Name: 'Dispenser, pharmaceutical'
  },
  {
    Id: 18124,
    Code: '7114',
    Name: 'Dispenser, pharmacy'
  },
  {
    Id: 18125,
    Code: '7114',
    Name: 'Dispenser, retail pharmacy'
  },
  {
    Id: 18126,
    Code: '7114',
    Name: 'Dispenser'
  },
  {
    Id: 18127,
    Code: '7114',
    Name: 'HCA, retail pharmacy'
  },
  {
    Id: 18128,
    Code: '7114',
    Name: 'Hygienist, ocular'
  },
  {
    Id: 18129,
    Code: '7114',
    Name: 'Officer, technical, assistant, pharmacy'
  },
  {
    Id: 18130,
    Code: '7114',
    Name: 'Officer, technical, assistant, retail pharmacy'
  },
  {
    Id: 18131,
    Code: '7114',
    Name: 'Partner, care, health, retail pharmacy'
  },
  {
    Id: 18132,
    Code: '7114',
    Name: 'Partner, healthcare, retail pharmacy'
  },
  {
    Id: 18133,
    Code: '7115',
    Name: 'Adviser, parts, retail trade'
  },
  {
    Id: 18134,
    Code: '7115',
    Name: 'Adviser, sales, car'
  },
  {
    Id: 18135,
    Code: '7115',
    Name: 'Adviser, sales, vehicle'
  },
  {
    Id: 18136,
    Code: '7115',
    Name: 'Adviser, sales, vehicles'
  },
  {
    Id: 18137,
    Code: '7115',
    Name: 'Assistant, parts'
  },
  {
    Id: 18138,
    Code: '7115',
    Name: 'Assistant, sales, vehicle'
  },
  {
    Id: 18139,
    Code: '7115',
    Name: 'Consultant, sales, garage'
  },
  {
    Id: 18140,
    Code: '7115',
    Name: 'Coordinator, parts'
  },
  {
    Id: 18141,
    Code: '7115',
    Name: 'Executive, sales, car'
  },
  {
    Id: 18142,
    Code: '7115',
    Name: 'Executive, sales, fleet'
  },
  {
    Id: 18143,
    Code: '7115',
    Name: 'Executive, sales, vehicle'
  },
  {
    Id: 18144,
    Code: '7115',
    Name: 'Executive, sales, vehicles'
  },
  {
    Id: 18145,
    Code: '7115',
    Name: 'Genius, product, vehicle parts'
  },
  {
    Id: 18146,
    Code: '7115',
    Name: 'Genius, product, car sales'
  },
  {
    Id: 18147,
    Code: '7115',
    Name: 'Man, parts, vehicle, motor'
  },
  {
    Id: 18148,
    Code: '7115',
    Name: 'Operative, parts, vehicle'
  },
  {
    Id: 18149,
    Code: '7115',
    Name: 'Partsman, retail trade'
  },
  {
    Id: 18150,
    Code: '7115',
    Name: 'Representative, sales, parts'
  },
  {
    Id: 18151,
    Code: '7115',
    Name: 'Representative, motor factors'
  },
  {
    Id: 18152,
    Code: '7115',
    Name: 'Salesman, car'
  },
  {
    Id: 18153,
    Code: '7115',
    Name: 'Salesman, forecourt, car sales'
  },
  {
    Id: 18154,
    Code: '7115',
    Name: 'Salesman, motor'
  },
  {
    Id: 18155,
    Code: '7115',
    Name: 'Salesman, parts'
  },
  {
    Id: 18156,
    Code: '7115',
    Name: 'Salesman, car sales'
  },
  {
    Id: 18157,
    Code: '7115',
    Name: 'Seller, car'
  },
  {
    Id: 18158,
    Code: '7115',
    Name: 'Technician, parts'
  },
  {
    Id: 18159,
    Code: '7121',
    Name: 'Agent, assurance'
  },
  {
    Id: 18160,
    Code: '7121',
    Name: 'Agent, club'
  },
  {
    Id: 18161,
    Code: '7121',
    Name: 'Agent, credit'
  },
  {
    Id: 18162,
    Code: '7121',
    Name: 'Agent, district, insurance'
  },
  {
    Id: 18163,
    Code: '7121',
    Name: 'Agent, insurance'
  },
  {
    Id: 18164,
    Code: '7121',
    Name: 'Agent, sales, insurance'
  },
  {
    Id: 18165,
    Code: '7121',
    Name: 'Agent, assurance'
  },
  {
    Id: 18166,
    Code: '7121',
    Name: 'Agent, door-to-door sales'
  },
  {
    Id: 18167,
    Code: '7121',
    Name: 'Agent, insurance'
  },
  {
    Id: 18168,
    Code: '7121',
    Name: 'Agent, mail order house'
  },
  {
    Id: 18169,
    Code: '7121',
    Name: 'Agent, wholesale, retail trade: credit trade'
  },
  {
    Id: 18170,
    Code: '7121',
    Name: 'Agent and collector, insurance'
  },
  {
    Id: 18171,
    Code: '7121',
    Name: 'Canvasser'
  },
  {
    Id: 18172,
    Code: '7121',
    Name: 'Canvasser and collector'
  },
  {
    Id: 18173,
    Code: '7121',
    Name: 'Collector, insurance'
  },
  {
    Id: 18174,
    Code: '7121',
    Name: 'Collector, insurance'
  },
  {
    Id: 18175,
    Code: '7121',
    Name: 'Collector and salesman'
  },
  {
    Id: 18176,
    Code: '7121',
    Name: 'Collector-agent, insurance'
  },
  {
    Id: 18177,
    Code: '7121',
    Name: 'Collector-salesman'
  },
  {
    Id: 18178,
    Code: '7121',
    Name: 'Consultant, beauty, retail trade: door-to-door sales'
  },
  {
    Id: 18179,
    Code: '7121',
    Name: 'Distributor, catalogue'
  },
  {
    Id: 18180,
    Code: '7121',
    Name: 'Distributor, door-to-door sales'
  },
  {
    Id: 18181,
    Code: '7121',
    Name: 'Draper, credit'
  },
  {
    Id: 18182,
    Code: '7121',
    Name: 'Lender, money'
  },
  {
    Id: 18183,
    Code: '7121',
    Name: 'Merchant, door-to-door sales'
  },
  {
    Id: 18184,
    Code: '7121',
    Name: 'Merchant, wholesale, retail trade: credit trade'
  },
  {
    Id: 18185,
    Code: '7121',
    Name: 'Representative, credit'
  },
  {
    Id: 18186,
    Code: '7121',
    Name: 'Representative, insurance'
  },
  {
    Id: 18187,
    Code: '7121',
    Name: 'Representative, sales, mail order house'
  },
  {
    Id: 18188,
    Code: '7121',
    Name: 'Representative, sales, retail trade: door-to-door sales'
  },
  {
    Id: 18189,
    Code: '7121',
    Name: 'Representative, insurance'
  },
  {
    Id: 18190,
    Code: '7121',
    Name: 'Representative, mail order house'
  },
  {
    Id: 18191,
    Code: '7121',
    Name: 'Representative, retail trade: credit trade'
  },
  {
    Id: 18192,
    Code: '7121',
    Name: 'Representative, retail trade: door-to-door sales'
  },
  {
    Id: 18193,
    Code: '7121',
    Name: 'Salesman, credit'
  },
  {
    Id: 18194,
    Code: '7121',
    Name: 'Salesman, travelling, retail trade'
  },
  {
    Id: 18195,
    Code: '7121',
    Name: 'Salesman, mail order house'
  },
  {
    Id: 18196,
    Code: '7121',
    Name: 'Salesman, retail trade: credit trade'
  },
  {
    Id: 18197,
    Code: '7121',
    Name: 'Salesman, retail trade: door-to-door sales'
  },
  {
    Id: 18198,
    Code: '7121',
    Name: 'Salesman-collector'
  },
  {
    Id: 18199,
    Code: '7121',
    Name: 'Superintendent of canvassers'
  },
  {
    Id: 18200,
    Code: '7121',
    Name: 'Tallyman'
  },
  {
    Id: 18201,
    Code: '7121',
    Name: 'Trader, credit'
  },
  {
    Id: 18202,
    Code: '7121',
    Name: 'Traveller, commercial, drapers, credit'
  },
  {
    Id: 18203,
    Code: '7121',
    Name: 'Traveller, drapers, credit'
  },
  {
    Id: 18204,
    Code: '7121',
    Name: 'Traveller, retail trade'
  },
  {
    Id: 18205,
    Code: '7121',
    Name: 'Traveller-salesman, credit trade'
  },
  {
    Id: 18206,
    Code: '7122',
    Name: 'Adviser, collections, debt collection'
  },
  {
    Id: 18207,
    Code: '7122',
    Name: 'Agent, collecting'
  },
  {
    Id: 18208,
    Code: '7122',
    Name: 'Agent, collection, data'
  },
  {
    Id: 18209,
    Code: '7122',
    Name: 'Agent, collection'
  },
  {
    Id: 18210,
    Code: '7122',
    Name: 'Agent, collections'
  },
  {
    Id: 18211,
    Code: '7122',
    Name: 'Agent, enforcement'
  },
  {
    Id: 18212,
    Code: '7122',
    Name: 'Agent, pools, football'
  },
  {
    Id: 18213,
    Code: '7122',
    Name: 'Agent-collector'
  },
  {
    Id: 18214,
    Code: '7122',
    Name: 'Assistant, collection'
  },
  {
    Id: 18215,
    Code: '7122',
    Name: 'Assistant, vending'
  },
  {
    Id: 18216,
    Code: '7122',
    Name: 'Attendant, booth, toll'
  },
  {
    Id: 18217,
    Code: '7122',
    Name: 'Attendant, gate, toll'
  },
  {
    Id: 18218,
    Code: '7122',
    Name: 'Attendant, machine, vending'
  },
  {
    Id: 18219,
    Code: '7122',
    Name: "Bailiff, auctioneer's"
  },
  {
    Id: 18220,
    Code: '7122',
    Name: 'Bailiff, certificated'
  },
  {
    Id: 18221,
    Code: '7122',
    Name: 'Bailiff, court'
  },
  {
    Id: 18222,
    Code: '7122',
    Name: 'Bailiff, rent'
  },
  {
    Id: 18223,
    Code: '7122',
    Name: 'Bailiff'
  },
  {
    Id: 18224,
    Code: '7122',
    Name: 'Broker, pawn'
  },
  {
    Id: 18225,
    Code: '7122',
    Name: 'Checker, meter'
  },
  {
    Id: 18226,
    Code: '7122',
    Name: 'Clearer, credit'
  },
  {
    Id: 18227,
    Code: '7122',
    Name: "Clerk, officer's, sheriff"
  },
  {
    Id: 18228,
    Code: '7122',
    Name: 'Collector, arrears'
  },
  {
    Id: 18229,
    Code: '7122',
    Name: 'Collector, cash'
  },
  {
    Id: 18230,
    Code: '7122',
    Name: 'Collector, club'
  },
  {
    Id: 18231,
    Code: '7122',
    Name: 'Collector, credit'
  },
  {
    Id: 18232,
    Code: '7122',
    Name: 'Collector, data, meter reading'
  },
  {
    Id: 18233,
    Code: '7122',
    Name: 'Collector, debt'
  },
  {
    Id: 18234,
    Code: '7122',
    Name: 'Collector, fee, parking'
  },
  {
    Id: 18235,
    Code: '7122',
    Name: 'Collector, meter'
  },
  {
    Id: 18236,
    Code: '7122',
    Name: 'Collector, mutuality'
  },
  {
    Id: 18237,
    Code: '7122',
    Name: 'Collector, pools'
  },
  {
    Id: 18238,
    Code: '7122',
    Name: 'Collector, rent'
  },
  {
    Id: 18239,
    Code: '7122',
    Name: 'Collector, senior, gas supplier'
  },
  {
    Id: 18240,
    Code: '7122',
    Name: 'Collector, subscription'
  },
  {
    Id: 18241,
    Code: '7122',
    Name: 'Collector, toll'
  },
  {
    Id: 18242,
    Code: '7122',
    Name: 'Collector, gaming machines'
  },
  {
    Id: 18243,
    Code: '7122',
    Name: 'Collector, credit trade'
  },
  {
    Id: 18244,
    Code: '7122',
    Name: 'Collector, electricity supplier'
  },
  {
    Id: 18245,
    Code: '7122',
    Name: 'Collector, finance company'
  },
  {
    Id: 18246,
    Code: '7122',
    Name: 'Collector, football pools'
  },
  {
    Id: 18247,
    Code: '7122',
    Name: 'Collector, gas supplier'
  },
  {
    Id: 18248,
    Code: '7122',
    Name: 'Collector, retail trade'
  },
  {
    Id: 18249,
    Code: '7122',
    Name: 'Collector-agent'
  },
  {
    Id: 18250,
    Code: '7122',
    Name: 'Controller, collection'
  },
  {
    Id: 18251,
    Code: '7122',
    Name: 'Controller, collections'
  },
  {
    Id: 18252,
    Code: '7122',
    Name: 'Controller, debt'
  },
  {
    Id: 18253,
    Code: '7122',
    Name: 'Converter, cash'
  },
  {
    Id: 18254,
    Code: '7122',
    Name: 'Driver and collector'
  },
  {
    Id: 18255,
    Code: '7122',
    Name: 'Engineer, machine, vending'
  },
  {
    Id: 18256,
    Code: '7122',
    Name: 'Engineer, vending'
  },
  {
    Id: 18257,
    Code: '7122',
    Name: 'Inspector, meter'
  },
  {
    Id: 18258,
    Code: '7122',
    Name: 'Inspector, reader, meter'
  },
  {
    Id: 18259,
    Code: '7122',
    Name: 'Inspector, rent'
  },
  {
    Id: 18260,
    Code: '7122',
    Name: 'Keeper, bridge, toll'
  },
  {
    Id: 18261,
    Code: '7122',
    Name: 'Keeper, gate, pier'
  },
  {
    Id: 18262,
    Code: '7122',
    Name: 'Leader, team, recoveries'
  },
  {
    Id: 18263,
    Code: '7122',
    Name: 'Negotiator, telephone, debt collection'
  },
  {
    Id: 18264,
    Code: '7122',
    Name: 'Officer, collecting, authorised'
  },
  {
    Id: 18265,
    Code: '7122',
    Name: 'Officer, collection'
  },
  {
    Id: 18266,
    Code: '7122',
    Name: 'Officer, collections'
  },
  {
    Id: 18267,
    Code: '7122',
    Name: 'Operative, machine, vending'
  },
  {
    Id: 18268,
    Code: '7122',
    Name: 'Operative, meter'
  },
  {
    Id: 18269,
    Code: '7122',
    Name: 'Operator, booth, toll'
  },
  {
    Id: 18270,
    Code: '7122',
    Name: 'Operator, machine, vending'
  },
  {
    Id: 18271,
    Code: '7122',
    Name: 'Operator, vending'
  },
  {
    Id: 18272,
    Code: '7122',
    Name: 'Pawnbroker'
  },
  {
    Id: 18273,
    Code: '7122',
    Name: 'Purser, pier'
  },
  {
    Id: 18274,
    Code: '7122',
    Name: 'Reader, meter'
  },
  {
    Id: 18275,
    Code: '7122',
    Name: 'Recoverer, debt'
  },
  {
    Id: 18276,
    Code: '7122',
    Name: 'Representative, accounts'
  },
  {
    Id: 18277,
    Code: '7122',
    Name: 'Representative, default'
  },
  {
    Id: 18278,
    Code: '7123',
    Name: "Assistant, grocer's, mobile"
  },
  {
    Id: 18279,
    Code: '7123',
    Name: 'Boy, delivery, bakery'
  },
  {
    Id: 18280,
    Code: '7123',
    Name: 'Boy, delivery, dairy'
  },
  {
    Id: 18281,
    Code: '7123',
    Name: 'Dairyman, retail trade: delivery round'
  },
  {
    Id: 18282,
    Code: '7123',
    Name: 'Deliverer, milk'
  },
  {
    Id: 18283,
    Code: '7123',
    Name: "Deliveryman, baker's"
  },
  {
    Id: 18284,
    Code: '7123',
    Name: 'Deliveryman, retail milk trade'
  },
  {
    Id: 18285,
    Code: '7123',
    Name: 'Deliveryman, retail trade: delivery round'
  },
  {
    Id: 18286,
    Code: '7123',
    Name: 'Driver and collector, laundry, launderette, dry cleaning'
  },
  {
    Id: 18287,
    Code: '7123',
    Name: 'Driver-salesman'
  },
  {
    Id: 18288,
    Code: '7123',
    Name: 'Greengrocer, mobile shop'
  },
  {
    Id: 18289,
    Code: '7123',
    Name: 'Grocer, travelling'
  },
  {
    Id: 18290,
    Code: '7123',
    Name: 'Man, milk'
  },
  {
    Id: 18291,
    Code: '7123',
    Name: 'Man, van'
  },
  {
    Id: 18292,
    Code: '7123',
    Name: 'Manager, shop, mobile'
  },
  {
    Id: 18293,
    Code: '7123',
    Name: 'Manager, shop, retail trade: mobile shop'
  },
  {
    Id: 18294,
    Code: '7123',
    Name: 'Milkman, roundsman'
  },
  {
    Id: 18295,
    Code: '7123',
    Name: 'Milkman, milk retailing'
  },
  {
    Id: 18296,
    Code: '7123',
    Name: 'Retailer, milk, retail trade: delivery round'
  },
  {
    Id: 18297,
    Code: '7123',
    Name: 'Retailer, mobile shop'
  },
  {
    Id: 18298,
    Code: '7123',
    Name: 'Roundsman'
  },
  {
    Id: 18299,
    Code: '7123',
    Name: 'Salesman, bread, retail trade: delivery round'
  },
  {
    Id: 18300,
    Code: '7123',
    Name: 'Salesman, cream, ice'
  },
  {
    Id: 18301,
    Code: '7123',
    Name: 'Salesman, drinks, soft'
  },
  {
    Id: 18302,
    Code: '7123',
    Name: 'Salesman, ice-cream'
  },
  {
    Id: 18303,
    Code: '7123',
    Name: 'Salesman, milk, retail trade: delivery round'
  },
  {
    Id: 18304,
    Code: '7123',
    Name: 'Salesman, shop, mobile shop'
  },
  {
    Id: 18305,
    Code: '7123',
    Name: 'Salesman, van'
  },
  {
    Id: 18306,
    Code: '7123',
    Name: 'Salesman, ice cream'
  },
  {
    Id: 18307,
    Code: '7123',
    Name: 'Salesman, retail trade: mobile shop'
  },
  {
    Id: 18308,
    Code: '7123',
    Name: 'Salesman-driver'
  },
  {
    Id: 18309,
    Code: '7123',
    Name: 'Seller, fast food'
  },
  {
    Id: 18310,
    Code: '7123',
    Name: 'Supervisor, round, retail trade: delivery round'
  },
  {
    Id: 18311,
    Code: '7123',
    Name: 'Supervisor, rounds, retail trade: delivery round'
  },
  {
    Id: 18312,
    Code: '7123',
    Name: 'Trader, mobile shop'
  },
  {
    Id: 18313,
    Code: '7123',
    Name: 'Traveller, grocers'
  },
  {
    Id: 18314,
    Code: '7123',
    Name: 'Traveller, van'
  },
  {
    Id: 18315,
    Code: '7123',
    Name: 'Vendor, cream, ice'
  },
  {
    Id: 18316,
    Code: '7123',
    Name: 'Vendor, ice-cream'
  },
  {
    Id: 18317,
    Code: '7123',
    Name: 'Vendor, milk'
  },
  {
    Id: 18318,
    Code: '7124',
    Name: 'Assistant, barrow'
  },
  {
    Id: 18319,
    Code: '7124',
    Name: 'Assistant, market'
  },
  {
    Id: 18320,
    Code: '7124',
    Name: 'Assistant, stall'
  },
  {
    Id: 18321,
    Code: '7124',
    Name: "Assistant, trader's, market"
  },
  {
    Id: 18322,
    Code: '7124',
    Name: "Assistant, trader's, street"
  },
  {
    Id: 18323,
    Code: '7124',
    Name: 'Attendant, stall, market'
  },
  {
    Id: 18324,
    Code: '7124',
    Name: 'Boy, barrow, retail trade'
  },
  {
    Id: 18325,
    Code: '7124',
    Name: 'Boy, trolley, street trading'
  },
  {
    Id: 18326,
    Code: '7124',
    Name: 'Coster'
  },
  {
    Id: 18327,
    Code: '7124',
    Name: 'Costermonger'
  },
  {
    Id: 18328,
    Code: '7124',
    Name: 'Dealer, firewood'
  },
  {
    Id: 18329,
    Code: '7124',
    Name: 'Dealer, log, fire'
  },
  {
    Id: 18330,
    Code: '7124',
    Name: 'Dealer, market trading'
  },
  {
    Id: 18331,
    Code: '7124',
    Name: 'Fruiterer, market trading'
  },
  {
    Id: 18332,
    Code: '7124',
    Name: 'Greengrocer, market trading'
  },
  {
    Id: 18333,
    Code: '7124',
    Name: 'Haberdasher, market trading'
  },
  {
    Id: 18334,
    Code: '7124',
    Name: 'Hawker'
  },
  {
    Id: 18335,
    Code: '7124',
    Name: 'Holder, stall'
  },
  {
    Id: 18336,
    Code: '7124',
    Name: 'Keeper, stall'
  },
  {
    Id: 18337,
    Code: '7124',
    Name: 'Man, barrow, retail trade'
  },
  {
    Id: 18338,
    Code: '7124',
    Name: 'Man, market'
  },
  {
    Id: 18339,
    Code: '7124',
    Name: 'Manager, stall, retail trade'
  },
  {
    Id: 18340,
    Code: '7124',
    Name: 'Manager, retail trade: market stall'
  },
  {
    Id: 18341,
    Code: '7124',
    Name: 'Merchant, firewood'
  },
  {
    Id: 18342,
    Code: '7124',
    Name: 'Merchant, log'
  },
  {
    Id: 18343,
    Code: '7124',
    Name: 'Orderly, market'
  },
  {
    Id: 18344,
    Code: '7124',
    Name: 'Organiser, market'
  },
  {
    Id: 18345,
    Code: '7124',
    Name: 'Owner, market stall'
  },
  {
    Id: 18346,
    Code: '7124',
    Name: 'Pedlar'
  },
  {
    Id: 18347,
    Code: '7124',
    Name: 'Retailer, market trading'
  },
  {
    Id: 18348,
    Code: '7124',
    Name: 'Salesman, market'
  },
  {
    Id: 18349,
    Code: '7124',
    Name: 'Salesman, hawking'
  },
  {
    Id: 18350,
    Code: '7124',
    Name: 'Salesman, market trading'
  },
  {
    Id: 18351,
    Code: '7124',
    Name: 'Seller, newspaper'
  },
  {
    Id: 18352,
    Code: '7124',
    Name: 'Seller, paper'
  },
  {
    Id: 18353,
    Code: '7124',
    Name: 'Seller, flowers'
  },
  {
    Id: 18354,
    Code: '7124',
    Name: 'Seller, fruit, vegetables'
  },
  {
    Id: 18355,
    Code: '7124',
    Name: 'Trader, market'
  },
  {
    Id: 18356,
    Code: '7124',
    Name: 'Trader, street'
  },
  {
    Id: 18357,
    Code: '7124',
    Name: 'Trader, market trading'
  },
  {
    Id: 18358,
    Code: '7124',
    Name: 'Vendor, market'
  },
  {
    Id: 18359,
    Code: '7124',
    Name: 'Vendor, news'
  },
  {
    Id: 18360,
    Code: '7124',
    Name: 'Vendor, newspaper'
  },
  {
    Id: 18361,
    Code: '7124',
    Name: 'Vendor, street'
  },
  {
    Id: 18362,
    Code: '7125',
    Name: 'Artist, display'
  },
  {
    Id: 18363,
    Code: '7125',
    Name: 'Assistant, display, merchandising, retail trade'
  },
  {
    Id: 18364,
    Code: '7125',
    Name: 'Assistant, merchandising, visual'
  },
  {
    Id: 18365,
    Code: '7125',
    Name: 'Associate, visual, retail trade'
  },
  {
    Id: 18366,
    Code: '7125',
    Name: 'Displayman, window'
  },
  {
    Id: 18367,
    Code: '7125',
    Name: 'Displayman, retail trade'
  },
  {
    Id: 18368,
    Code: '7125',
    Name: 'Dresser, window'
  },
  {
    Id: 18369,
    Code: '7125',
    Name: 'Hand, display, retail trade'
  },
  {
    Id: 18370,
    Code: '7125',
    Name: 'Inspector, window dressing'
  },
  {
    Id: 18371,
    Code: '7125',
    Name: 'Merchandiser, visual'
  },
  {
    Id: 18372,
    Code: '7125',
    Name: 'Representative, display'
  },
  {
    Id: 18373,
    Code: '7125',
    Name: 'Stylist, fashion'
  },
  {
    Id: 18374,
    Code: '7125',
    Name: 'Stylist, film'
  },
  {
    Id: 18375,
    Code: '7125',
    Name: 'Stylist, photographic'
  },
  {
    Id: 18376,
    Code: '7125',
    Name: 'Stylist, product'
  },
  {
    Id: 18377,
    Code: '7125',
    Name: 'Trimmer, window'
  },
  {
    Id: 18378,
    Code: '7129',
    Name: 'Adviser, membership'
  },
  {
    Id: 18379,
    Code: '7129',
    Name: 'Adviser, sales, inbound, insurance'
  },
  {
    Id: 18380,
    Code: '7129',
    Name: 'Adviser, sales, insurance'
  },
  {
    Id: 18381,
    Code: '7129',
    Name: 'Adviser, sales, insurance'
  },
  {
    Id: 18382,
    Code: '7129',
    Name: 'Agent, commission, insurance'
  },
  {
    Id: 18383,
    Code: '7129',
    Name: 'Agent, hire, car'
  },
  {
    Id: 18384,
    Code: '7129',
    Name: 'Agent, mercantile'
  },
  {
    Id: 18385,
    Code: '7129',
    Name: 'Agent, railway'
  },
  {
    Id: 18386,
    Code: '7129',
    Name: 'Agent, rental, car'
  },
  {
    Id: 18387,
    Code: '7129',
    Name: 'Agent, sales, rental, car hire'
  },
  {
    Id: 18388,
    Code: '7129',
    Name: 'Agent, party plan sales'
  },
  {
    Id: 18389,
    Code: '7129',
    Name: 'Assistant, canvassing, insurance'
  },
  {
    Id: 18390,
    Code: '7129',
    Name: "Broker, printer's"
  },
  {
    Id: 18391,
    Code: '7129',
    Name: 'Canvasser, advertisement'
  },
  {
    Id: 18392,
    Code: '7129',
    Name: 'Canvasser, advertising'
  },
  {
    Id: 18393,
    Code: '7129',
    Name: 'Canvasser, freight'
  },
  {
    Id: 18394,
    Code: '7129',
    Name: 'Canvasser, insurance'
  },
  {
    Id: 18395,
    Code: '7129',
    Name: 'Canvasser, traffic'
  },
  {
    Id: 18396,
    Code: '7129',
    Name: 'Canvasser, advertising'
  },
  {
    Id: 18397,
    Code: '7129',
    Name: 'Canvasser, insurance'
  },
  {
    Id: 18398,
    Code: '7129',
    Name: 'Canvasser, transport'
  },
  {
    Id: 18399,
    Code: '7129',
    Name: 'Consultant, energy, renewable'
  },
  {
    Id: 18400,
    Code: '7129',
    Name: 'Consultant, energy'
  },
  {
    Id: 18401,
    Code: '7129',
    Name: 'Consultant, food'
  },
  {
    Id: 18402,
    Code: '7129',
    Name: 'Consultant, sales, insurance'
  },
  {
    Id: 18403,
    Code: '7129',
    Name: 'Consultant, sales, insurance'
  },
  {
    Id: 18404,
    Code: '7129',
    Name: 'Contractor, hire, plant'
  },
  {
    Id: 18405,
    Code: '7129',
    Name: 'Controller, desk, hire'
  },
  {
    Id: 18406,
    Code: '7129',
    Name: 'Controller, hire, plant'
  },
  {
    Id: 18407,
    Code: '7129',
    Name: 'Controller, hire'
  },
  {
    Id: 18408,
    Code: '7129',
    Name: 'Controller, hire and sales'
  },
  {
    Id: 18409,
    Code: '7129',
    Name: 'Coordinator, hire'
  },
  {
    Id: 18410,
    Code: '7129',
    Name: 'Dealer, party plan sales'
  },
  {
    Id: 18411,
    Code: '7129',
    Name: 'Demonstrator, technical'
  },
  {
    Id: 18412,
    Code: '7129',
    Name: 'Demonstrator'
  },
  {
    Id: 18413,
    Code: '7129',
    Name: 'Demonstrator-consultant'
  },
  {
    Id: 18414,
    Code: '7129',
    Name: 'Demonstrator-salesman'
  },
  {
    Id: 18415,
    Code: '7129',
    Name: 'Distributor, film'
  },
  {
    Id: 18416,
    Code: '7129',
    Name: 'Executive, sales, inbound, insurance'
  },
  {
    Id: 18417,
    Code: '7129',
    Name: 'Executive, sales, insurance'
  },
  {
    Id: 18418,
    Code: '7129',
    Name: 'Executive, sales, insurance'
  },
  {
    Id: 18419,
    Code: '7129',
    Name: 'Finder, land'
  },
  {
    Id: 18420,
    Code: '7129',
    Name: 'Hirer, car'
  },
  {
    Id: 18421,
    Code: '7129',
    Name: 'Hirer'
  },
  {
    Id: 18422,
    Code: '7129',
    Name: 'Manager, plan, party'
  },
  {
    Id: 18423,
    Code: '7129',
    Name: 'Manager, service, retail, railways'
  },
  {
    Id: 18424,
    Code: '7129',
    Name: 'Manager, retail trade: party plan sales'
  },
  {
    Id: 18425,
    Code: '7129',
    Name: 'Merchant, party plan sales'
  },
  {
    Id: 18426,
    Code: '7129',
    Name: 'Negotiator'
  },
  {
    Id: 18427,
    Code: '7129',
    Name: 'Officer, wayleave'
  },
  {
    Id: 18428,
    Code: '7129',
    Name: 'Operator, hire, skip'
  },
  {
    Id: 18429,
    Code: '7129',
    Name: 'Operator, rental'
  },
  {
    Id: 18430,
    Code: '7129',
    Name: 'Organiser, party, retail trade: party plan sales'
  },
  {
    Id: 18431,
    Code: '7129',
    Name: 'Planner, party'
  },
  {
    Id: 18432,
    Code: '7129',
    Name: 'Planner, sales, party plan'
  },
  {
    Id: 18433,
    Code: '7129',
    Name: 'Promoter, sales'
  },
  {
    Id: 18434,
    Code: '7129',
    Name: 'Promoter, wholesale, retail trade'
  },
  {
    Id: 18435,
    Code: '7129',
    Name: 'Recruiter, membership'
  },
  {
    Id: 18436,
    Code: '7129',
    Name: 'Representative, advertisement'
  },
  {
    Id: 18437,
    Code: '7129',
    Name: 'Representative, advertising'
  },
  {
    Id: 18438,
    Code: '7129',
    Name: 'Representative, catering'
  },
  {
    Id: 18439,
    Code: '7129',
    Name: 'Representative, commercial'
  },
  {
    Id: 18440,
    Code: '7129',
    Name: 'Representative, finance'
  },
  {
    Id: 18441,
    Code: '7129',
    Name: 'Representative, financial'
  },
  {
    Id: 18442,
    Code: '7129',
    Name: 'Representative, freight'
  },
  {
    Id: 18443,
    Code: '7129',
    Name: 'Representative, newspaper'
  },
  {
    Id: 18444,
    Code: '7129',
    Name: 'Representative, publicity'
  },
  {
    Id: 18445,
    Code: '7129',
    Name: 'Representative, sales, property'
  },
  {
    Id: 18446,
    Code: '7129',
    Name: 'Representative, sales, services'
  },
  {
    Id: 18447,
    Code: '7129',
    Name: 'Representative, sales, retail trade: party plan sales'
  },
  {
    Id: 18448,
    Code: '7129',
    Name: 'Representative, sales, retail trade'
  },
  {
    Id: 18449,
    Code: '7129',
    Name: 'Representative, sales, telecoms'
  },
  {
    Id: 18450,
    Code: '7129',
    Name: 'Representative, service, railway'
  },
  {
    Id: 18451,
    Code: '7129',
    Name: 'Representative, shipping'
  },
  {
    Id: 18452,
    Code: '7129',
    Name: 'Representative, space, printing'
  },
  {
    Id: 18453,
    Code: '7129',
    Name: 'Representative, traffic, air transport'
  },
  {
    Id: 18454,
    Code: '7129',
    Name: 'Representative, retail trade: party plan sales'
  },
  {
    Id: 18455,
    Code: '7129',
    Name: 'Representative, retail trade'
  },
  {
    Id: 18456,
    Code: '7129',
    Name: 'Representative'
  },
  {
    Id: 18457,
    Code: '7129',
    Name: 'Salesman, advertising'
  },
  {
    Id: 18458,
    Code: '7129',
    Name: 'Salesman, insurance'
  },
  {
    Id: 18459,
    Code: '7129',
    Name: 'Salesman, property'
  },
  {
    Id: 18460,
    Code: '7129',
    Name: 'Salesman, space, advertising'
  },
  {
    Id: 18461,
    Code: '7129',
    Name: 'Salesman, services'
  },
  {
    Id: 18462,
    Code: '7129',
    Name: 'Salesman, retail trade: party plan sales'
  },
  {
    Id: 18463,
    Code: '7129',
    Name: 'Seller, space, advertising'
  },
  {
    Id: 18464,
    Code: '7129',
    Name: 'Supervisor, hire, car'
  },
  {
    Id: 18465,
    Code: '7129',
    Name: 'Supervisor, hire, plant'
  },
  {
    Id: 18466,
    Code: '7129',
    Name: 'Viewer, house'
  },
  {
    Id: 18467,
    Code: '7129',
    Name: 'Viewer, estate agents'
  },
  {
    Id: 18468,
    Code: '7131',
    Name: 'Agent, news'
  },
  {
    Id: 18469,
    Code: '7131',
    Name: 'Agent, newspaper'
  },
  {
    Id: 18470,
    Code: '7131',
    Name: 'Bookseller, antiquarian'
  },
  {
    Id: 18471,
    Code: '7131',
    Name: 'Chandler'
  },
  {
    Id: 18472,
    Code: '7131',
    Name: 'Cheesemonger'
  },
  {
    Id: 18473,
    Code: '7131',
    Name: 'Clothier, retail trade'
  },
  {
    Id: 18474,
    Code: '7131',
    Name: 'Clothier and outfitter'
  },
  {
    Id: 18475,
    Code: '7131',
    Name: 'Confectioner, wholesale'
  },
  {
    Id: 18476,
    Code: '7131',
    Name: 'Confectioner and tobacconist'
  },
  {
    Id: 18477,
    Code: '7131',
    Name: 'Dealer, accessories, motor'
  },
  {
    Id: 18478,
    Code: '7131',
    Name: 'Dealer, antique'
  },
  {
    Id: 18479,
    Code: '7131',
    Name: 'Dealer, antiques'
  },
  {
    Id: 18480,
    Code: '7131',
    Name: 'Dealer, art'
  },
  {
    Id: 18481,
    Code: '7131',
    Name: 'Dealer, book'
  },
  {
    Id: 18482,
    Code: '7131',
    Name: 'Dealer, car'
  },
  {
    Id: 18483,
    Code: '7131',
    Name: 'Dealer, cattle'
  },
  {
    Id: 18484,
    Code: '7131',
    Name: 'Dealer, game'
  },
  {
    Id: 18485,
    Code: '7131',
    Name: 'Dealer, livestock'
  },
  {
    Id: 18486,
    Code: '7131',
    Name: 'Dealer, motor'
  },
  {
    Id: 18487,
    Code: '7131',
    Name: 'Dealer, pig'
  },
  {
    Id: 18488,
    Code: '7131',
    Name: 'Dealer, poultry'
  },
  {
    Id: 18489,
    Code: '7131',
    Name: 'Dealer, sheep'
  },
  {
    Id: 18490,
    Code: '7131',
    Name: 'Dealer, stamp'
  },
  {
    Id: 18491,
    Code: '7131',
    Name: 'Dealer, store, marine'
  },
  {
    Id: 18492,
    Code: '7131',
    Name: 'Dealer, tyre'
  },
  {
    Id: 18493,
    Code: '7131',
    Name: 'Dealer, wholesale, retail trade'
  },
  {
    Id: 18494,
    Code: '7131',
    Name: 'Distributor, wholesale, retail trade'
  },
  {
    Id: 18495,
    Code: '7131',
    Name: 'Draper'
  },
  {
    Id: 18496,
    Code: '7131',
    Name: 'Factor, coal'
  },
  {
    Id: 18497,
    Code: '7131',
    Name: 'Factor, motor'
  },
  {
    Id: 18498,
    Code: '7131',
    Name: 'Fruiterer'
  },
  {
    Id: 18499,
    Code: '7131',
    Name: 'Furnisher, retail trade'
  },
  {
    Id: 18500,
    Code: '7131',
    Name: 'Furrier, retail trade'
  },
  {
    Id: 18501,
    Code: '7131',
    Name: 'Greengrocer'
  },
  {
    Id: 18502,
    Code: '7131',
    Name: 'Grocer, green'
  },
  {
    Id: 18503,
    Code: '7131',
    Name: 'Grocer'
  },
  {
    Id: 18504,
    Code: '7131',
    Name: 'Haberdasher'
  },
  {
    Id: 18505,
    Code: '7131',
    Name: 'Hatter, retail trade'
  },
  {
    Id: 18506,
    Code: '7131',
    Name: 'Holder, stock, steel'
  },
  {
    Id: 18507,
    Code: '7131',
    Name: 'Hosier'
  },
  {
    Id: 18508,
    Code: '7131',
    Name: 'Hosier and haberdasher'
  },
  {
    Id: 18509,
    Code: '7131',
    Name: 'Ironmonger'
  },
  {
    Id: 18510,
    Code: '7131',
    Name: 'Jeweller, retail'
  },
  {
    Id: 18511,
    Code: '7131',
    Name: 'Jeweller, retail trade'
  },
  {
    Id: 18512,
    Code: '7131',
    Name: 'Keeper, stall, book'
  },
  {
    Id: 18513,
    Code: '7131',
    Name: 'Keeper, store, drug'
  },
  {
    Id: 18514,
    Code: '7131',
    Name: 'Keeper, store, general'
  },
  {
    Id: 18515,
    Code: '7131',
    Name: 'Licensee, off-licence'
  },
  {
    Id: 18516,
    Code: '7131',
    Name: 'Manager, retail trade: mobile shop'
  },
  {
    Id: 18517,
    Code: '7131',
    Name: 'Mercer'
  },
  {
    Id: 18518,
    Code: '7131',
    Name: 'Merchant, agricultural'
  },
  {
    Id: 18519,
    Code: '7131',
    Name: "Merchant, builders'"
  },
  {
    Id: 18520,
    Code: '7131',
    Name: 'Merchant, coal'
  },
  {
    Id: 18521,
    Code: '7131',
    Name: 'Merchant, fish'
  },
  {
    Id: 18522,
    Code: '7131',
    Name: 'Merchant, glass'
  },
  {
    Id: 18523,
    Code: '7131',
    Name: 'Merchant, potato'
  },
  {
    Id: 18524,
    Code: '7131',
    Name: 'Merchant, timber'
  },
  {
    Id: 18525,
    Code: '7131',
    Name: 'Merchant, wine'
  },
  {
    Id: 18526,
    Code: '7131',
    Name: 'Milliner, wholesale, retail trade'
  },
  {
    Id: 18527,
    Code: '7131',
    Name: 'Newsagent'
  },
  {
    Id: 18528,
    Code: '7131',
    Name: 'Numismatist'
  },
  {
    Id: 18529,
    Code: '7131',
    Name: 'Off-licensee'
  },
  {
    Id: 18530,
    Code: '7131',
    Name: 'Outfitter, retail trade'
  },
  {
    Id: 18531,
    Code: '7131',
    Name: 'Owner, centre, garden'
  },
  {
    Id: 18532,
    Code: '7131',
    Name: "Owner, shop, butcher's"
  },
  {
    Id: 18533,
    Code: '7131',
    Name: 'Owner, shop, florist'
  },
  {
    Id: 18534,
    Code: '7131',
    Name: 'Owner, shop, retail'
  },
  {
    Id: 18535,
    Code: '7131',
    Name: 'Owner, station, filling'
  },
  {
    Id: 18536,
    Code: '7131',
    Name: 'Owner, station, petrol'
  },
  {
    Id: 18537,
    Code: '7131',
    Name: 'Owner, store, drug'
  },
  {
    Id: 18538,
    Code: '7131',
    Name: 'Owner, store, general'
  },
  {
    Id: 18539,
    Code: '7131',
    Name: 'Owner, butchers'
  },
  {
    Id: 18540,
    Code: '7131',
    Name: 'Owner, car sales'
  },
  {
    Id: 18541,
    Code: '7131',
    Name: 'Owner, delicatessen'
  },
  {
    Id: 18542,
    Code: '7131',
    Name: 'Owner, filling station'
  },
  {
    Id: 18543,
    Code: '7131',
    Name: 'Owner, fishmongers'
  },
  {
    Id: 18544,
    Code: '7131',
    Name: 'Owner, florists'
  },
  {
    Id: 18545,
    Code: '7131',
    Name: 'Owner, garden centre'
  },
  {
    Id: 18546,
    Code: '7131',
    Name: 'Owner, general store'
  },
  {
    Id: 18547,
    Code: '7131',
    Name: 'Owner, milk delivery round'
  },
  {
    Id: 18548,
    Code: '7131',
    Name: 'Owner, off-licence'
  },
  {
    Id: 18549,
    Code: '7131',
    Name: 'Owner, pet shop'
  },
  {
    Id: 18550,
    Code: '7131',
    Name: 'Owner, petrol station'
  },
  {
    Id: 18551,
    Code: '7131',
    Name: 'Owner, PO'
  },
  {
    Id: 18552,
    Code: '7131',
    Name: 'Owner, retail pharmacy'
  },
  {
    Id: 18553,
    Code: '7131',
    Name: 'Owner, retail trade'
  },
  {
    Id: 18554,
    Code: '7131',
    Name: 'Owner, street stall'
  },
  {
    Id: 18555,
    Code: '7131',
    Name: 'Owner, wholesale trade'
  },
  {
    Id: 18556,
    Code: '7131',
    Name: 'Philatelist'
  },
  {
    Id: 18557,
    Code: '7131',
    Name: 'Retailer, fashion'
  },
  {
    Id: 18558,
    Code: '7131',
    Name: 'Retailer, fish'
  },
  {
    Id: 18559,
    Code: '7131',
    Name: 'Retailer'
  },
  {
    Id: 18560,
    Code: '7131',
    Name: 'Seedsman, retail trade'
  },
  {
    Id: 18561,
    Code: '7131',
    Name: 'Seller, book, antiquarian'
  },
  {
    Id: 18562,
    Code: '7131',
    Name: 'Seller, eBay'
  },
  {
    Id: 18563,
    Code: '7131',
    Name: 'Seller, map'
  },
  {
    Id: 18564,
    Code: '7131',
    Name: 'Seller, online'
  },
  {
    Id: 18565,
    Code: '7131',
    Name: 'Shopkeeper, fish, fried'
  },
  {
    Id: 18566,
    Code: '7131',
    Name: 'Shopkeeper, fish and chip'
  },
  {
    Id: 18567,
    Code: '7131',
    Name: 'Shopkeeper, fish'
  },
  {
    Id: 18568,
    Code: '7131',
    Name: 'Shopkeeper, fish and chip'
  },
  {
    Id: 18569,
    Code: '7131',
    Name: 'Shopkeeper, fried fish'
  },
  {
    Id: 18570,
    Code: '7131',
    Name: 'Shopkeeper, laundry, launderette, dry cleaning'
  },
  {
    Id: 18571,
    Code: '7131',
    Name: 'Shopkeeper, mobile shop'
  },
  {
    Id: 18572,
    Code: '7131',
    Name: 'Shopkeeper, newsagents'
  },
  {
    Id: 18573,
    Code: '7131',
    Name: 'Shopkeeper, take-away food shop'
  },
  {
    Id: 18574,
    Code: '7131',
    Name: 'Shopkeeper, video shop'
  },
  {
    Id: 18575,
    Code: '7131',
    Name: 'Shopkeeper, wholesale trade'
  },
  {
    Id: 18576,
    Code: '7131',
    Name: 'Shopkeeper'
  },
  {
    Id: 18577,
    Code: '7131',
    Name: 'Specialist, book, antiquarian'
  },
  {
    Id: 18578,
    Code: '7131',
    Name: 'Stationer'
  },
  {
    Id: 18579,
    Code: '7131',
    Name: 'Stockholder, steel'
  },
  {
    Id: 18580,
    Code: '7131',
    Name: 'Supplier, wholesale, retail trade'
  },
  {
    Id: 18581,
    Code: '7131',
    Name: 'Tailor, merchant'
  },
  {
    Id: 18582,
    Code: '7131',
    Name: 'Tobacconist'
  },
  {
    Id: 18583,
    Code: '7131',
    Name: 'Trader, antique'
  },
  {
    Id: 18584,
    Code: '7131',
    Name: 'Trader, car'
  },
  {
    Id: 18585,
    Code: '7131',
    Name: 'Trader, eBay'
  },
  {
    Id: 18586,
    Code: '7131',
    Name: 'Trader, motor'
  },
  {
    Id: 18587,
    Code: '7131',
    Name: 'Trader, retail trade'
  },
  {
    Id: 18588,
    Code: '7131',
    Name: 'Vendor, horsemeat'
  },
  {
    Id: 18589,
    Code: '7131',
    Name: 'Victualler, licensed, off-licence'
  },
  {
    Id: 18590,
    Code: '7131',
    Name: 'Wholesaler'
  },
  {
    Id: 18591,
    Code: '7132',
    Name: 'Assistant, senior, retail trade'
  },
  {
    Id: 18592,
    Code: '7132',
    Name: 'Checker, dairy'
  },
  {
    Id: 18593,
    Code: '7132',
    Name: 'Controller, deli'
  },
  {
    Id: 18594,
    Code: '7132',
    Name: 'Controller, till'
  },
  {
    Id: 18595,
    Code: '7132',
    Name: 'Coordinator, operations, wholesale'
  },
  {
    Id: 18596,
    Code: '7132',
    Name: 'Coordinator, operations, wholesale trade'
  },
  {
    Id: 18597,
    Code: '7132',
    Name: 'Foreman, dairy, retail trade'
  },
  {
    Id: 18598,
    Code: '7132',
    Name: 'Foreman, district, retail trade'
  },
  {
    Id: 18599,
    Code: '7132',
    Name: 'Foreman, forecourt'
  },
  {
    Id: 18600,
    Code: '7132',
    Name: 'Foreman, rounds'
  },
  {
    Id: 18601,
    Code: '7132',
    Name: 'Foreman, shop, retail trade'
  },
  {
    Id: 18602,
    Code: '7132',
    Name: 'Foreman, dairy: retail trade'
  },
  {
    Id: 18603,
    Code: '7132',
    Name: 'Foreman, petrol station'
  },
  {
    Id: 18604,
    Code: '7132',
    Name: 'Foreman, provision merchants'
  },
  {
    Id: 18605,
    Code: '7132',
    Name: 'Foreman, retail trade'
  },
  {
    Id: 18606,
    Code: '7132',
    Name: 'Inspector, round, wholesale, retail trade'
  },
  {
    Id: 18607,
    Code: '7132',
    Name: 'Inspector, rounds, wholesale, retail trade'
  },
  {
    Id: 18608,
    Code: '7132',
    Name: 'Leader, section, retail trade'
  },
  {
    Id: 18609,
    Code: '7132',
    Name: 'Leader, shift, retail trade'
  },
  {
    Id: 18610,
    Code: '7132',
    Name: 'Leader, team, checkout'
  },
  {
    Id: 18611,
    Code: '7132',
    Name: 'Leader, team, delivery, home'
  },
  {
    Id: 18612,
    Code: '7132',
    Name: 'Leader, team, operation, sales, retail trade'
  },
  {
    Id: 18613,
    Code: '7132',
    Name: 'Leader, team, retail'
  },
  {
    Id: 18614,
    Code: '7132',
    Name: 'Leader, team, telesales'
  },
  {
    Id: 18615,
    Code: '7132',
    Name: 'Leader, team, retail trade'
  },
  {
    Id: 18616,
    Code: '7132',
    Name: 'Manager, bakery, retail trade'
  },
  {
    Id: 18617,
    Code: '7132',
    Name: 'Manager, checkout'
  },
  {
    Id: 18618,
    Code: '7132',
    Name: 'Manager, clothing, retail trade'
  },
  {
    Id: 18619,
    Code: '7132',
    Name: 'Manager, concession'
  },
  {
    Id: 18620,
    Code: '7132',
    Name: 'Manager, concessions'
  },
  {
    Id: 18621,
    Code: '7132',
    Name: 'Manager, counter, trade'
  },
  {
    Id: 18622,
    Code: '7132',
    Name: 'Manager, counter, retail trade'
  },
  {
    Id: 18623,
    Code: '7132',
    Name: 'Manager, department, retail trade'
  },
  {
    Id: 18624,
    Code: '7132',
    Name: 'Manager, depot, wholesale, retail trade'
  },
  {
    Id: 18625,
    Code: '7132',
    Name: 'Manager, floor, wholesale, retail trade'
  },
  {
    Id: 18626,
    Code: '7132',
    Name: 'Manager, food, fresh, retail trade'
  },
  {
    Id: 18627,
    Code: '7132',
    Name: 'Manager, forecourt'
  },
  {
    Id: 18628,
    Code: '7132',
    Name: 'Manager, goods, dry'
  },
  {
    Id: 18629,
    Code: '7132',
    Name: 'Manager, grocery'
  },
  {
    Id: 18630,
    Code: '7132',
    Name: 'Manager, line, retail trade'
  },
  {
    Id: 18631,
    Code: '7132',
    Name: 'Manager, parts'
  },
  {
    Id: 18632,
    Code: '7132',
    Name: 'Manager, produce, retail trade'
  },
  {
    Id: 18633,
    Code: '7132',
    Name: 'Manager, product, retail trade'
  },
  {
    Id: 18634,
    Code: '7132',
    Name: 'Manager, provisions'
  },
  {
    Id: 18635,
    Code: '7132',
    Name: 'Manager, replenishment'
  },
  {
    Id: 18636,
    Code: '7132',
    Name: 'Manager, section, wholesale/retail trade'
  },
  {
    Id: 18637,
    Code: '7132',
    Name: 'Manager, support, team, retail trade'
  },
  {
    Id: 18638,
    Code: '7132',
    Name: 'Manager, support, trading, retail trade'
  },
  {
    Id: 18639,
    Code: '7132',
    Name: 'Manager, team, retail trade'
  },
  {
    Id: 18640,
    Code: '7132',
    Name: 'Manager, trading, retail trade'
  },
  {
    Id: 18641,
    Code: '7132',
    Name: 'Specialist, brand, retail trade'
  },
  {
    Id: 18642,
    Code: '7132',
    Name: 'Superintendent, floor, department store'
  },
  {
    Id: 18643,
    Code: '7132',
    Name: 'Superintendent, retail trade'
  },
  {
    Id: 18644,
    Code: '7132',
    Name: 'Supervisor, cash, retail trade'
  },
  {
    Id: 18645,
    Code: '7132',
    Name: 'Supervisor, centre, service, electricity supplier'
  },
  {
    Id: 18646,
    Code: '7132',
    Name: 'Supervisor, checkout'
  },
  {
    Id: 18647,
    Code: '7132',
    Name: 'Supervisor, counter, wholesale, retail trade'
  },
  {
    Id: 18648,
    Code: '7132',
    Name: 'Supervisor, floor, retail trade'
  },
  {
    Id: 18649,
    Code: '7132',
    Name: 'Supervisor, kiosk, retail trade'
  },
  {
    Id: 18650,
    Code: '7132',
    Name: 'Supervisor, market'
  },
  {
    Id: 18651,
    Code: '7132',
    Name: 'Supervisor, produce'
  },
  {
    Id: 18652,
    Code: '7132',
    Name: 'Supervisor, retail'
  },
  {
    Id: 18653,
    Code: '7132',
    Name: 'Supervisor, room, show'
  },
  {
    Id: 18654,
    Code: '7132',
    Name: 'Supervisor, sales, retail trade: delivery round'
  },
  {
    Id: 18655,
    Code: '7132',
    Name: 'Supervisor, sales, wholesale, retail trade'
  },
  {
    Id: 18656,
    Code: '7132',
    Name: 'Supervisor, shop, retail trade'
  },
  {
    Id: 18657,
    Code: '7132',
    Name: 'Supervisor, store, retail trade'
  },
  {
    Id: 18658,
    Code: '7132',
    Name: 'Supervisor, supermarket'
  },
  {
    Id: 18659,
    Code: '7132',
    Name: 'Supervisor, telesales'
  },
  {
    Id: 18660,
    Code: '7132',
    Name: 'Supervisor, trade, retail trade'
  },
  {
    Id: 18661,
    Code: '7132',
    Name: 'Supervisor, wholesale, retail trade'
  },
  {
    Id: 18662,
    Code: '7132',
    Name: 'Walker, floor'
  },
  {
    Id: 18663,
    Code: '7132',
    Name: 'Walker, shop'
  },
  {
    Id: 18664,
    Code: '7211',
    Name: 'Adviser, centre, call'
  },
  {
    Id: 18665,
    Code: '7211',
    Name: 'Adviser, centre, contact'
  },
  {
    Id: 18666,
    Code: '7211',
    Name: 'Adviser, communications, telecoms'
  },
  {
    Id: 18667,
    Code: '7211',
    Name: 'Adviser, customer, telephone'
  },
  {
    Id: 18668,
    Code: '7211',
    Name: 'Adviser, customer, motoring organisation'
  },
  {
    Id: 18669,
    Code: '7211',
    Name: 'Adviser, experience, customer, call centre'
  },
  {
    Id: 18670,
    Code: '7211',
    Name: 'Adviser, fraud'
  },
  {
    Id: 18671,
    Code: '7211',
    Name: 'Adviser, health, 111'
  },
  {
    Id: 18672,
    Code: '7211',
    Name: 'Adviser, health, IUC'
  },
  {
    Id: 18673,
    Code: '7211',
    Name: 'Adviser, health, 111'
  },
  {
    Id: 18674,
    Code: '7211',
    Name: 'Adviser, lines, personal'
  },
  {
    Id: 18675,
    Code: '7211',
    Name: 'Adviser, management, customer'
  },
  {
    Id: 18676,
    Code: '7211',
    Name: 'Adviser, retention, business'
  },
  {
    Id: 18677,
    Code: '7211',
    Name: 'Adviser, retention, sales'
  },
  {
    Id: 18678,
    Code: '7211',
    Name: 'Adviser, service, customer, call centre'
  },
  {
    Id: 18679,
    Code: '7211',
    Name: 'Adviser, telephone'
  },
  {
    Id: 18680,
    Code: '7211',
    Name: 'Advocate, brand'
  },
  {
    Id: 18681,
    Code: '7211',
    Name: 'Agent, centre, call'
  },
  {
    Id: 18682,
    Code: '7211',
    Name: 'Agent, centre, contact'
  },
  {
    Id: 18683,
    Code: '7211',
    Name: 'Agent, delivery, service, call centre'
  },
  {
    Id: 18684,
    Code: '7211',
    Name: 'Agent, enquiry'
  },
  {
    Id: 18685,
    Code: '7211',
    Name: 'Agent, service, customer, call centre'
  },
  {
    Id: 18686,
    Code: '7211',
    Name: 'Agent, service, customer, government'
  },
  {
    Id: 18687,
    Code: '7211',
    Name: 'Assistant, centre, call'
  },
  {
    Id: 18688,
    Code: '7211',
    Name: 'Associate, centre, call'
  },
  {
    Id: 18689,
    Code: '7211',
    Name: 'Associate, collections'
  },
  {
    Id: 18690,
    Code: '7211',
    Name: 'Clerk, lines, personal'
  },
  {
    Id: 18691,
    Code: '7211',
    Name: 'Clerk, order, mail'
  },
  {
    Id: 18692,
    Code: '7211',
    Name: 'Clerk, telephone'
  },
  {
    Id: 18693,
    Code: '7211',
    Name: 'Handler, call, 111'
  },
  {
    Id: 18694,
    Code: '7211',
    Name: 'Handler, call'
  },
  {
    Id: 18695,
    Code: '7211',
    Name: 'Handler, centre, call'
  },
  {
    Id: 18696,
    Code: '7211',
    Name: 'Operative, centre, call'
  },
  {
    Id: 18697,
    Code: '7211',
    Name: 'Operative, service, customer'
  },
  {
    Id: 18698,
    Code: '7211',
    Name: 'Operator, callcentre'
  },
  {
    Id: 18699,
    Code: '7211',
    Name: 'Operator, centre, call'
  },
  {
    Id: 18700,
    Code: '7211',
    Name: 'Operator, centre, contact'
  },
  {
    Id: 18701,
    Code: '7211',
    Name: 'Operator, helpline'
  },
  {
    Id: 18702,
    Code: '7211',
    Name: 'Operator, line, answer'
  },
  {
    Id: 18703,
    Code: '7211',
    Name: 'Operator, response, serviceline'
  },
  {
    Id: 18704,
    Code: '7211',
    Name: 'Operator, response, tele'
  },
  {
    Id: 18705,
    Code: '7211',
    Name: 'Operator, service, customer'
  },
  {
    Id: 18706,
    Code: '7211',
    Name: 'Operator, services, customer'
  },
  {
    Id: 18707,
    Code: '7211',
    Name: 'Representative, customer, call centre'
  },
  {
    Id: 18708,
    Code: '7211',
    Name: 'Representative, service, customer, call centre'
  },
  {
    Id: 18709,
    Code: '7211',
    Name: 'Staff, centre, call'
  },
  {
    Id: 18710,
    Code: '7211',
    Name: 'Telephonist, centre, call'
  },
  {
    Id: 18711,
    Code: '7211',
    Name: 'Worker, centre, call'
  },
  {
    Id: 18712,
    Code: '7212',
    Name: 'Attendant, board, switch, telephone'
  },
  {
    Id: 18713,
    Code: '7212',
    Name: 'Attendant, telephone'
  },
  {
    Id: 18714,
    Code: '7212',
    Name: 'Handler, call, motoring organisation'
  },
  {
    Id: 18715,
    Code: '7212',
    Name: 'Operative, telephone'
  },
  {
    Id: 18716,
    Code: '7212',
    Name: 'Operator, enquiry, directory'
  },
  {
    Id: 18717,
    Code: '7212',
    Name: 'Operator, night, telephone service'
  },
  {
    Id: 18718,
    Code: '7212',
    Name: 'Operator, phone'
  },
  {
    Id: 18719,
    Code: '7212',
    Name: 'Operator, switchboard, telephone'
  },
  {
    Id: 18720,
    Code: '7212',
    Name: 'Operator, telephone'
  },
  {
    Id: 18721,
    Code: '7212',
    Name: 'Operator, telephone'
  },
  {
    Id: 18722,
    Code: '7212',
    Name: 'Telephonist, shorthand'
  },
  {
    Id: 18723,
    Code: '7212',
    Name: 'Telephonist'
  },
  {
    Id: 18724,
    Code: '7212',
    Name: 'Telephonist-clerk'
  },
  {
    Id: 18725,
    Code: '7212',
    Name: 'Telephonist-receptionist'
  },
  {
    Id: 18726,
    Code: '7212',
    Name: 'Telephonist-secretary'
  },
  {
    Id: 18727,
    Code: '7212',
    Name: 'Telephonist-typist'
  },
  {
    Id: 18728,
    Code: '7213',
    Name: 'Adviser, medical, emergency'
  },
  {
    Id: 18729,
    Code: '7213',
    Name: 'Announcer, transport'
  },
  {
    Id: 18730,
    Code: '7213',
    Name: 'Assessor, call, emergency services'
  },
  {
    Id: 18731,
    Code: '7213',
    Name: 'Assistant, communications'
  },
  {
    Id: 18732,
    Code: '7213',
    Name: 'Assistant, control, ambulance'
  },
  {
    Id: 18733,
    Code: '7213',
    Name: 'Assistant, control, traffic, air'
  },
  {
    Id: 18734,
    Code: '7213',
    Name: 'Assistant, control, emergency services'
  },
  {
    Id: 18735,
    Code: '7213',
    Name: 'Assistant, room, control, emergency services'
  },
  {
    Id: 18736,
    Code: '7213',
    Name: 'Assistant, traffic, air'
  },
  {
    Id: 18737,
    Code: '7213',
    Name: 'Assistant, traffic, telecoms'
  },
  {
    Id: 18738,
    Code: '7213',
    Name: 'Clerk, control, traffic, air'
  },
  {
    Id: 18739,
    Code: '7213',
    Name: 'Controller, ambulance'
  },
  {
    Id: 18740,
    Code: '7213',
    Name: 'Controller, cab'
  },
  {
    Id: 18741,
    Code: '7213',
    Name: 'Controller, communications'
  },
  {
    Id: 18742,
    Code: '7213',
    Name: 'Controller, divisional, police service'
  },
  {
    Id: 18743,
    Code: '7213',
    Name: 'Controller, office, taxi'
  },
  {
    Id: 18744,
    Code: '7213',
    Name: 'Controller, radio'
  },
  {
    Id: 18745,
    Code: '7213',
    Name: 'Controller, service, transport, patient'
  },
  {
    Id: 18746,
    Code: '7213',
    Name: 'Controller, taxi'
  },
  {
    Id: 18747,
    Code: '7213',
    Name: 'Controller, radio, emergency services'
  },
  {
    Id: 18748,
    Code: '7213',
    Name: 'Controller, taxi service'
  },
  {
    Id: 18749,
    Code: '7213',
    Name: 'Despatcher, radio'
  },
  {
    Id: 18750,
    Code: '7213',
    Name: 'Despatcher, room, control, emergency services'
  },
  {
    Id: 18751,
    Code: '7213',
    Name: 'Despatcher, emergency services'
  },
  {
    Id: 18752,
    Code: '7213',
    Name: 'Dispatcher, medical, emergency'
  },
  {
    Id: 18753,
    Code: '7213',
    Name: 'Dispatcher, radio'
  },
  {
    Id: 18754,
    Code: '7213',
    Name: 'Dispatcher, taxi'
  },
  {
    Id: 18755,
    Code: '7213',
    Name: 'Dispatcher, air traffic control'
  },
  {
    Id: 18756,
    Code: '7213',
    Name: 'Dispatcher, emergency services'
  },
  {
    Id: 18757,
    Code: '7213',
    Name: 'Dispatcher, motoring organisation'
  },
  {
    Id: 18758,
    Code: '7213',
    Name: 'Dispatcher, taxi service'
  },
  {
    Id: 18759,
    Code: '7213',
    Name: 'Handler, call, emergency'
  },
  {
    Id: 18760,
    Code: '7213',
    Name: 'Handler, call, emergency services'
  },
  {
    Id: 18761,
    Code: '7213',
    Name: 'Observer, radar, marine'
  },
  {
    Id: 18762,
    Code: '7213',
    Name: 'Officer, centre, contact, emergency services'
  },
  {
    Id: 18763,
    Code: '7213',
    Name: 'Officer, communications'
  },
  {
    Id: 18764,
    Code: '7213',
    Name: 'Officer, control, fire, fire service'
  },
  {
    Id: 18765,
    Code: '7213',
    Name: 'Officer, control, ambulance service'
  },
  {
    Id: 18766,
    Code: '7213',
    Name: 'Officer, control, fire service'
  },
  {
    Id: 18767,
    Code: '7213',
    Name: 'Officer, deployment, motoring organisation'
  },
  {
    Id: 18768,
    Code: '7213',
    Name: 'Officer, duty, emergency, welfare services'
  },
  {
    Id: 18769,
    Code: '7213',
    Name: 'Officer, radio, police'
  },
  {
    Id: 18770,
    Code: '7213',
    Name: 'Officer, radio, aircraft'
  },
  {
    Id: 18771,
    Code: '7213',
    Name: 'Officer, radio, government'
  },
  {
    Id: 18772,
    Code: '7213',
    Name: 'Officer, radio, telecoms'
  },
  {
    Id: 18773,
    Code: '7213',
    Name: 'Officer, room, control, emergency services'
  },
  {
    Id: 18774,
    Code: '7213',
    Name: 'Officer, telecommunications'
  },
  {
    Id: 18775,
    Code: '7213',
    Name: 'Officer, traffic, telecommunications'
  },
  {
    Id: 18776,
    Code: '7213',
    Name: 'Officer, traffic, telecoms'
  },
  {
    Id: 18777,
    Code: '7213',
    Name: 'Operator, bureau, paging service'
  },
  {
    Id: 18778,
    Code: '7213',
    Name: 'Operator, cable'
  },
  {
    Id: 18779,
    Code: '7213',
    Name: 'Operator, centre, control, sheltered housing'
  },
  {
    Id: 18780,
    Code: '7213',
    Name: 'Operator, communications'
  },
  {
    Id: 18781,
    Code: '7213',
    Name: 'Operator, control, fire, fire service'
  },
  {
    Id: 18782,
    Code: '7213',
    Name: 'Operator, despatch, aided, computer, emergency services'
  },
  {
    Id: 18783,
    Code: '7213',
    Name: 'Operator, homecall'
  },
  {
    Id: 18784,
    Code: '7213',
    Name: 'Operator, lifeline'
  },
  {
    Id: 18785,
    Code: '7213',
    Name: 'Operator, radar'
  },
  {
    Id: 18786,
    Code: '7213',
    Name: 'Operator, radio'
  },
  {
    Id: 18787,
    Code: '7213',
    Name: 'Operator, room, control, emergency services'
  },
  {
    Id: 18788,
    Code: '7213',
    Name: 'Operator, taxi'
  },
  {
    Id: 18789,
    Code: '7213',
    Name: 'Operator, telecommunications'
  },
  {
    Id: 18790,
    Code: '7213',
    Name: 'Operator, telephone, radio'
  },
  {
    Id: 18791,
    Code: '7213',
    Name: 'Operator, teleprinter'
  },
  {
    Id: 18792,
    Code: '7213',
    Name: 'Operator, teletype'
  },
  {
    Id: 18793,
    Code: '7213',
    Name: 'Operator, telex'
  },
  {
    Id: 18794,
    Code: '7213',
    Name: 'Operator, radio relay service'
  },
  {
    Id: 18795,
    Code: '7213',
    Name: 'Overseer, radio'
  },
  {
    Id: 18796,
    Code: '7213',
    Name: 'Signalman, civilian, MOD'
  },
  {
    Id: 18797,
    Code: '7213',
    Name: 'Signalman, marine'
  },
  {
    Id: 18798,
    Code: '7213',
    Name: 'Signalman, port'
  },
  {
    Id: 18799,
    Code: '7213',
    Name: 'TTO, telecoms'
  },
  {
    Id: 18800,
    Code: '7213',
    Name: 'Telegraphist'
  },
  {
    Id: 18801,
    Code: '7214',
    Name: 'Assistant, research, marketing'
  },
  {
    Id: 18802,
    Code: '7214',
    Name: 'Assistant, research, market research'
  },
  {
    Id: 18803,
    Code: '7214',
    Name: 'Canvasser, political'
  },
  {
    Id: 18804,
    Code: '7214',
    Name: 'Collector, data, interviewing'
  },
  {
    Id: 18805,
    Code: '7214',
    Name: 'Enumerator, traffic'
  },
  {
    Id: 18806,
    Code: '7214',
    Name: 'Interviewer, commercial'
  },
  {
    Id: 18807,
    Code: '7214',
    Name: 'Interviewer, field'
  },
  {
    Id: 18808,
    Code: '7214',
    Name: 'Interviewer, research, market'
  },
  {
    Id: 18809,
    Code: '7214',
    Name: 'Interviewer, telephone'
  },
  {
    Id: 18810,
    Code: '7214',
    Name: 'Interviewer, surveys'
  },
  {
    Id: 18811,
    Code: '7214',
    Name: 'Interviewer, market research'
  },
  {
    Id: 18812,
    Code: '7214',
    Name: 'Investigator, research, market'
  },
  {
    Id: 18813,
    Code: '7214',
    Name: 'Representative, telesurveys'
  },
  {
    Id: 18814,
    Code: '7214',
    Name: 'Researcher, market, interviewing'
  },
  {
    Id: 18815,
    Code: '7214',
    Name: 'Researcher, telephone'
  },
  {
    Id: 18816,
    Code: '7214',
    Name: 'Researcher, market research'
  },
  {
    Id: 18817,
    Code: '7214',
    Name: 'Shopper, mystery'
  },
  {
    Id: 18818,
    Code: '7214',
    Name: 'Surveyor, traffic, enumerator'
  },
  {
    Id: 18819,
    Code: '7214',
    Name: 'Worker, field, market research'
  },
  {
    Id: 18820,
    Code: '7219',
    Name: 'Administrator, service, customer'
  },
  {
    Id: 18821,
    Code: '7219',
    Name: 'Administrator, services, customer'
  },
  {
    Id: 18822,
    Code: '7219',
    Name: 'Administrator, support, customer'
  },
  {
    Id: 18823,
    Code: '7219',
    Name: 'Adviser, care, customer'
  },
  {
    Id: 18824,
    Code: '7219',
    Name: 'Adviser, consumer'
  },
  {
    Id: 18825,
    Code: '7219',
    Name: 'Adviser, course'
  },
  {
    Id: 18826,
    Code: '7219',
    Name: 'Adviser, customer'
  },
  {
    Id: 18827,
    Code: '7219',
    Name: 'Adviser, relations, customer'
  },
  {
    Id: 18828,
    Code: '7219',
    Name: 'Adviser, service, customer'
  },
  {
    Id: 18829,
    Code: '7219',
    Name: 'Adviser, service'
  },
  {
    Id: 18830,
    Code: '7219',
    Name: 'Adviser, services, customer'
  },
  {
    Id: 18831,
    Code: '7219',
    Name: 'Advocate, customer'
  },
  {
    Id: 18832,
    Code: '7219',
    Name: 'Agent, emigration'
  },
  {
    Id: 18833,
    Code: '7219',
    Name: 'Agent, passport'
  },
  {
    Id: 18834,
    Code: '7219',
    Name: 'Agent, reservations, hotel'
  },
  {
    Id: 18835,
    Code: '7219',
    Name: 'Agent, service, customer'
  },
  {
    Id: 18836,
    Code: '7219',
    Name: 'Agent, services, customer'
  },
  {
    Id: 18837,
    Code: '7219',
    Name: 'Ambassador, student'
  },
  {
    Id: 18838,
    Code: '7219',
    Name: 'Assistant, care, customer'
  },
  {
    Id: 18839,
    Code: '7219',
    Name: 'Assistant, centre, service'
  },
  {
    Id: 18840,
    Code: '7219',
    Name: 'Assistant, desk, help, customer service'
  },
  {
    Id: 18841,
    Code: '7219',
    Name: 'Assistant, helpdesk, customer service'
  },
  {
    Id: 18842,
    Code: '7219',
    Name: 'Assistant, liaison, customer'
  },
  {
    Id: 18843,
    Code: '7219',
    Name: 'Assistant, relations, customer'
  },
  {
    Id: 18844,
    Code: '7219',
    Name: 'Assistant, reservations, hotel'
  },
  {
    Id: 18845,
    Code: '7219',
    Name: 'Assistant, service, customer'
  },
  {
    Id: 18846,
    Code: '7219',
    Name: 'Assistant, services, customer'
  },
  {
    Id: 18847,
    Code: '7219',
    Name: 'Associate, care, customer'
  },
  {
    Id: 18848,
    Code: '7219',
    Name: 'Clerk, consumer'
  },
  {
    Id: 18849,
    Code: '7219',
    Name: 'Clerk, liaison, customer'
  },
  {
    Id: 18850,
    Code: '7219',
    Name: 'Clerk, query, sales'
  },
  {
    Id: 18851,
    Code: '7219',
    Name: 'Clerk, reservations'
  },
  {
    Id: 18852,
    Code: '7219',
    Name: 'Clerk, service, customer'
  },
  {
    Id: 18853,
    Code: '7219',
    Name: 'Clerk, services, consumer'
  },
  {
    Id: 18854,
    Code: '7219',
    Name: 'Clerk, services, customer'
  },
  {
    Id: 18855,
    Code: '7219',
    Name: 'Consultant, care, customer'
  },
  {
    Id: 18856,
    Code: '7219',
    Name: 'Consultant, sales, after'
  },
  {
    Id: 18857,
    Code: '7219',
    Name: 'Consultant, service, customer'
  },
  {
    Id: 18858,
    Code: '7219',
    Name: 'Controller, consumer'
  },
  {
    Id: 18859,
    Code: '7219',
    Name: 'Coordinator, care, customer'
  },
  {
    Id: 18860,
    Code: '7219',
    Name: 'Coordinator, service, customer'
  },
  {
    Id: 18861,
    Code: '7219',
    Name: 'Executive, care, customer'
  },
  {
    Id: 18862,
    Code: '7219',
    Name: 'Executive, relations, customer'
  },
  {
    Id: 18863,
    Code: '7219',
    Name: 'Executive, relations, guest'
  },
  {
    Id: 18864,
    Code: '7219',
    Name: 'Executive, service, customer'
  },
  {
    Id: 18865,
    Code: '7219',
    Name: 'Executive, services, customer'
  },
  {
    Id: 18866,
    Code: '7219',
    Name: 'Executive, support, customer'
  },
  {
    Id: 18867,
    Code: '7219',
    Name: 'Father Christmas'
  },
  {
    Id: 18868,
    Code: '7219',
    Name: 'Guide, store'
  },
  {
    Id: 18869,
    Code: '7219',
    Name: 'Handler, complaint'
  },
  {
    Id: 18870,
    Code: '7219',
    Name: 'Handler, complaints'
  },
  {
    Id: 18871,
    Code: '7219',
    Name: 'Helper, student, higher education, university'
  },
  {
    Id: 18872,
    Code: '7219',
    Name: 'Officer, care, customer'
  },
  {
    Id: 18873,
    Code: '7219',
    Name: 'Officer, commercial, telecoms'
  },
  {
    Id: 18874,
    Code: '7219',
    Name: 'Officer, complaints'
  },
  {
    Id: 18875,
    Code: '7219',
    Name: 'Officer, liaison, customer'
  },
  {
    Id: 18876,
    Code: '7219',
    Name: 'Officer, liaison, sales'
  },
  {
    Id: 18877,
    Code: '7219',
    Name: 'Officer, relations, customer'
  },
  {
    Id: 18878,
    Code: '7219',
    Name: 'Officer, service, customer'
  },
  {
    Id: 18879,
    Code: '7219',
    Name: 'Officer, services, customer'
  },
  {
    Id: 18880,
    Code: '7219',
    Name: 'Operator, helpdesk, customer service'
  },
  {
    Id: 18881,
    Code: '7219',
    Name: 'Receptionist, service, customer'
  },
  {
    Id: 18882,
    Code: '7219',
    Name: 'Representative, relations, customer'
  },
  {
    Id: 18883,
    Code: '7219',
    Name: 'Representative, service, customer'
  },
  {
    Id: 18884,
    Code: '7219',
    Name: 'Representative, services, customer'
  },
  {
    Id: 18885,
    Code: '7219',
    Name: 'Reservationist, hotel'
  },
  {
    Id: 18886,
    Code: '7219',
    Name: 'Specialist, care, customer'
  },
  {
    Id: 18887,
    Code: '7219',
    Name: 'Specialist, relations, customer'
  },
  {
    Id: 18888,
    Code: '7219',
    Name: 'Specialist, service, customer'
  },
  {
    Id: 18889,
    Code: '7219',
    Name: 'Specialist, support, customer'
  },
  {
    Id: 18890,
    Code: '7220',
    Name: 'Adviser, media, social'
  },
  {
    Id: 18891,
    Code: '7220',
    Name: 'Leader, team, centre, call'
  },
  {
    Id: 18892,
    Code: '7220',
    Name: 'Leader, team, services, customer'
  },
  {
    Id: 18893,
    Code: '7220',
    Name: 'Leader, team, customer service'
  },
  {
    Id: 18894,
    Code: '7220',
    Name: 'Leader, team, call centre'
  },
  {
    Id: 18895,
    Code: '7220',
    Name: 'Superintendent, radio, PO'
  },
  {
    Id: 18896,
    Code: '7220',
    Name: 'Superintendent, room, ambulance'
  },
  {
    Id: 18897,
    Code: '7220',
    Name: 'Superintendent, services, customer'
  },
  {
    Id: 18898,
    Code: '7220',
    Name: 'Superintendent, telecommunications'
  },
  {
    Id: 18899,
    Code: '7220',
    Name: 'Superintendent, traffic, telecommunications'
  },
  {
    Id: 18900,
    Code: '7220',
    Name: 'Supervisor, care, customer'
  },
  {
    Id: 18901,
    Code: '7220',
    Name: 'Supervisor, centre, call'
  },
  {
    Id: 18902,
    Code: '7220',
    Name: 'Supervisor, chief, PO'
  },
  {
    Id: 18903,
    Code: '7220',
    Name: 'Supervisor, chief, telecoms'
  },
  {
    Id: 18904,
    Code: '7220',
    Name: 'Supervisor, communications, air transport'
  },
  {
    Id: 18905,
    Code: '7220',
    Name: 'Supervisor, field, market research'
  },
  {
    Id: 18906,
    Code: '7220',
    Name: 'Supervisor, grade, higher, telecoms'
  },
  {
    Id: 18907,
    Code: '7220',
    Name: 'Supervisor, liaison, customer'
  },
  {
    Id: 18908,
    Code: '7220',
    Name: 'Supervisor, room, control, emergency services'
  },
  {
    Id: 18909,
    Code: '7220',
    Name: 'Supervisor, service, customer'
  },
  {
    Id: 18910,
    Code: '7220',
    Name: 'Supervisor, services, customer'
  },
  {
    Id: 18911,
    Code: '7220',
    Name: 'Supervisor, support, customer'
  },
  {
    Id: 18912,
    Code: '7220',
    Name: 'Supervisor, switchboard'
  },
  {
    Id: 18913,
    Code: '7220',
    Name: 'Supervisor, telecoms'
  },
  {
    Id: 18914,
    Code: '7220',
    Name: 'Supervisor, telephone service'
  },
  {
    Id: 18915,
    Code: '8111',
    Name: 'Assistant, bakehouse'
  },
  {
    Id: 18916,
    Code: '8111',
    Name: 'Assistant, bakery'
  },
  {
    Id: 18917,
    Code: '8111',
    Name: "Assistant, blender's, margarine"
  },
  {
    Id: 18918,
    Code: '8111',
    Name: "Assistant, boiler's, sugar"
  },
  {
    Id: 18919,
    Code: '8111',
    Name: 'Assistant, brewery'
  },
  {
    Id: 18920,
    Code: '8111',
    Name: "Assistant, churner's"
  },
  {
    Id: 18921,
    Code: '8111',
    Name: "Assistant, confectioner's, sugar confectionery mfr"
  },
  {
    Id: 18922,
    Code: '8111',
    Name: "Assistant, dairyman's, milk processing"
  },
  {
    Id: 18923,
    Code: '8111',
    Name: 'Assistant, general, food products mfr'
  },
  {
    Id: 18924,
    Code: '8111',
    Name: 'Assistant, house, char, sugar'
  },
  {
    Id: 18925,
    Code: '8111',
    Name: "Assistant, maker's, cheese"
  },
  {
    Id: 18926,
    Code: '8111',
    Name: 'Assistant, mill, offal, tobacco mfr'
  },
  {
    Id: 18927,
    Code: '8111',
    Name: "Assistant, miller's, food"
  },
  {
    Id: 18928,
    Code: '8111',
    Name: 'Assistant, oven, bakery'
  },
  {
    Id: 18929,
    Code: '8111',
    Name: 'Assistant, production, food processing'
  },
  {
    Id: 18930,
    Code: '8111',
    Name: 'Assistant, room, retort, food products mfr'
  },
  {
    Id: 18931,
    Code: '8111',
    Name: 'Assistant, sample, chocolate mfr'
  },
  {
    Id: 18932,
    Code: '8111',
    Name: "Assistant, spinner's, tobacco mfr"
  },
  {
    Id: 18933,
    Code: '8111',
    Name: 'Attendant, basin, outflow'
  },
  {
    Id: 18934,
    Code: '8111',
    Name: 'Attendant, boiler, temper, margarine'
  },
  {
    Id: 18935,
    Code: '8111',
    Name: 'Attendant, boiler, vacuum, margarine'
  },
  {
    Id: 18936,
    Code: '8111',
    Name: 'Attendant, centrifugal, food products mfr'
  },
  {
    Id: 18937,
    Code: '8111',
    Name: 'Attendant, conditioner, tobacco mfr'
  },
  {
    Id: 18938,
    Code: '8111',
    Name: 'Attendant, cooler'
  },
  {
    Id: 18939,
    Code: '8111',
    Name: 'Attendant, cylinder, tobacco mfr'
  },
  {
    Id: 18940,
    Code: '8111',
    Name: 'Attendant, diffuser'
  },
  {
    Id: 18941,
    Code: '8111',
    Name: "Attendant, earth, fuller's, margarine"
  },
  {
    Id: 18942,
    Code: '8111',
    Name: 'Attendant, equipment, automatic, food products mfr'
  },
  {
    Id: 18943,
    Code: '8111',
    Name: 'Attendant, evaporator, steepwater'
  },
  {
    Id: 18944,
    Code: '8111',
    Name: 'Attendant, evaporator, food products mfr'
  },
  {
    Id: 18945,
    Code: '8111',
    Name: 'Attendant, expeller, oil, edible oils'
  },
  {
    Id: 18946,
    Code: '8111',
    Name: 'Attendant, filter, starch mfr'
  },
  {
    Id: 18947,
    Code: '8111',
    Name: 'Attendant, granary'
  },
  {
    Id: 18948,
    Code: '8111',
    Name: 'Attendant, inversion'
  },
  {
    Id: 18949,
    Code: '8111',
    Name: 'Attendant, mixer, food products mfr'
  },
  {
    Id: 18950,
    Code: '8111',
    Name: 'Attendant, molasses'
  },
  {
    Id: 18951,
    Code: '8111',
    Name: 'Attendant, montejuice'
  },
  {
    Id: 18952,
    Code: '8111',
    Name: 'Attendant, multiplex, margarine'
  },
  {
    Id: 18953,
    Code: '8111',
    Name: 'Attendant, neutraliser'
  },
  {
    Id: 18954,
    Code: '8111',
    Name: 'Attendant, oven, bakery'
  },
  {
    Id: 18955,
    Code: '8111',
    Name: 'Attendant, oven, food products mfr'
  },
  {
    Id: 18956,
    Code: '8111',
    Name: 'Attendant, pan, food products mfr'
  },
  {
    Id: 18957,
    Code: '8111',
    Name: 'Attendant, plodder, margarine'
  },
  {
    Id: 18958,
    Code: '8111',
    Name: 'Attendant, press, sugar refining'
  },
  {
    Id: 18959,
    Code: '8111',
    Name: 'Attendant, pump, air, sugar refining'
  },
  {
    Id: 18960,
    Code: '8111',
    Name: 'Attendant, room, cold, brewery'
  },
  {
    Id: 18961,
    Code: '8111',
    Name: 'Attendant, room, ice'
  },
  {
    Id: 18962,
    Code: '8111',
    Name: 'Attendant, sieve, rotary, tobacco mfr'
  },
  {
    Id: 18963,
    Code: '8111',
    Name: 'Attendant, sterilizer, distillery'
  },
  {
    Id: 18964,
    Code: '8111',
    Name: 'Attendant, store, liquor'
  },
  {
    Id: 18965,
    Code: '8111',
    Name: 'Attendant, stove, starch'
  },
  {
    Id: 18966,
    Code: '8111',
    Name: 'Attendant, tank, sugar refining'
  },
  {
    Id: 18967,
    Code: '8111',
    Name: 'Attendant, washer, beet'
  },
  {
    Id: 18968,
    Code: '8111',
    Name: "Attendant, washer's"
  },
  {
    Id: 18969,
    Code: '8111',
    Name: 'Baker, oven, hand'
  },
  {
    Id: 18970,
    Code: '8111',
    Name: 'Baker, food products mfr'
  },
  {
    Id: 18971,
    Code: '8111',
    Name: 'Bandyman, provender milling'
  },
  {
    Id: 18972,
    Code: '8111',
    Name: 'Barrelman, rice starch'
  },
  {
    Id: 18973,
    Code: '8111',
    Name: 'Bathman, bacon, ham, meat curing'
  },
  {
    Id: 18974,
    Code: '8111',
    Name: 'Beater-up, tobacco mfr'
  },
  {
    Id: 18975,
    Code: '8111',
    Name: 'Binman, tempering'
  },
  {
    Id: 18976,
    Code: '8111',
    Name: 'Blancher, fruit, vegetables'
  },
  {
    Id: 18977,
    Code: '8111',
    Name: 'Bleacher, flour'
  },
  {
    Id: 18978,
    Code: '8111',
    Name: 'Blender, butter'
  },
  {
    Id: 18979,
    Code: '8111',
    Name: 'Blender, cocoa'
  },
  {
    Id: 18980,
    Code: '8111',
    Name: 'Blender, coffee'
  },
  {
    Id: 18981,
    Code: '8111',
    Name: 'Blender, flour'
  },
  {
    Id: 18982,
    Code: '8111',
    Name: 'Blender, spice'
  },
  {
    Id: 18983,
    Code: '8111',
    Name: 'Blender, tea'
  },
  {
    Id: 18984,
    Code: '8111',
    Name: 'Blender, whisky'
  },
  {
    Id: 18985,
    Code: '8111',
    Name: 'Blender, margarine'
  },
  {
    Id: 18986,
    Code: '8111',
    Name: 'Blender, spirits'
  },
  {
    Id: 18987,
    Code: '8111',
    Name: 'Blender, wines'
  },
  {
    Id: 18988,
    Code: '8111',
    Name: 'Blender, animal feeds mfr'
  },
  {
    Id: 18989,
    Code: '8111',
    Name: 'Blender, food products mfr'
  },
  {
    Id: 18990,
    Code: '8111',
    Name: 'Blender, mineral water mfr'
  },
  {
    Id: 18991,
    Code: '8111',
    Name: 'Blender, tobacco mfr'
  },
  {
    Id: 18992,
    Code: '8111',
    Name: 'Boiler, biscuit'
  },
  {
    Id: 18993,
    Code: '8111',
    Name: 'Boiler, fruit'
  },
  {
    Id: 18994,
    Code: '8111',
    Name: 'Boiler, gum, sugar confectionery mfr'
  },
  {
    Id: 18995,
    Code: '8111',
    Name: 'Boiler, jelly'
  },
  {
    Id: 18996,
    Code: '8111',
    Name: 'Boiler, liquorice'
  },
  {
    Id: 18997,
    Code: '8111',
    Name: 'Boiler, pan, sugar refining'
  },
  {
    Id: 18998,
    Code: '8111',
    Name: 'Boiler, sauce'
  },
  {
    Id: 18999,
    Code: '8111',
    Name: 'Boiler, sugar'
  },
  {
    Id: 19000,
    Code: '8111',
    Name: 'Boiler, food products'
  },
  {
    Id: 19001,
    Code: '8111',
    Name: 'Boiler, sugar confectionery'
  },
  {
    Id: 19002,
    Code: '8111',
    Name: 'Boilerman, food products mfr'
  },
  {
    Id: 19003,
    Code: '8111',
    Name: 'Brakesman, biscuit mfr'
  },
  {
    Id: 19004,
    Code: '8111',
    Name: 'Breaker, cake'
  },
  {
    Id: 19005,
    Code: '8111',
    Name: 'Breaker, egg'
  },
  {
    Id: 19006,
    Code: '8111',
    Name: 'Brewer, beer, ginger'
  },
  {
    Id: 19007,
    Code: '8111',
    Name: 'Brewer'
  },
  {
    Id: 19008,
    Code: '8111',
    Name: 'Brineman'
  },
  {
    Id: 19009,
    Code: '8111',
    Name: 'Briner'
  },
  {
    Id: 19010,
    Code: '8111',
    Name: 'Brusher, flour'
  },
  {
    Id: 19011,
    Code: '8111',
    Name: 'Bulker, tobacco mfr'
  },
  {
    Id: 19012,
    Code: '8111',
    Name: 'Buncher, cigar mfr'
  },
  {
    Id: 19013,
    Code: '8111',
    Name: 'Caker, liquorice'
  },
  {
    Id: 19014,
    Code: '8111',
    Name: 'Calciner, dextrin'
  },
  {
    Id: 19015,
    Code: '8111',
    Name: 'Carbonator, brewery'
  },
  {
    Id: 19016,
    Code: '8111',
    Name: 'Cellarer'
  },
  {
    Id: 19017,
    Code: '8111',
    Name: 'Cellarman, brewery'
  },
  {
    Id: 19018,
    Code: '8111',
    Name: 'Cheeser, biscuit mfr'
  },
  {
    Id: 19019,
    Code: '8111',
    Name: 'Chocolatier'
  },
  {
    Id: 19020,
    Code: '8111',
    Name: 'Chopper, sugar'
  },
  {
    Id: 19021,
    Code: '8111',
    Name: 'Churner'
  },
  {
    Id: 19022,
    Code: '8111',
    Name: 'Cleaner, fruit'
  },
  {
    Id: 19023,
    Code: '8111',
    Name: 'Cleaner, rice'
  },
  {
    Id: 19024,
    Code: '8111',
    Name: 'Cleaner, scrap, tobacco mfr'
  },
  {
    Id: 19025,
    Code: '8111',
    Name: 'Cleaner, seed'
  },
  {
    Id: 19026,
    Code: '8111',
    Name: 'Cleaner, skin, sausage'
  },
  {
    Id: 19027,
    Code: '8111',
    Name: 'Cleaner, tripe'
  },
  {
    Id: 19028,
    Code: '8111',
    Name: 'Cleaner, food products mfr'
  },
  {
    Id: 19029,
    Code: '8111',
    Name: 'Clearer, oven, bakery'
  },
  {
    Id: 19030,
    Code: '8111',
    Name: 'Coater, chocolate'
  },
  {
    Id: 19031,
    Code: '8111',
    Name: 'Coater, sugar, confectionery mfr'
  },
  {
    Id: 19032,
    Code: '8111',
    Name: 'Cobberer'
  },
  {
    Id: 19033,
    Code: '8111',
    Name: 'Compounder, food products mfr'
  },
  {
    Id: 19034,
    Code: '8111',
    Name: 'Compounder, mineral water mfr'
  },
  {
    Id: 19035,
    Code: '8111',
    Name: 'Concher'
  },
  {
    Id: 19036,
    Code: '8111',
    Name: 'Condenser, milk processing'
  },
  {
    Id: 19037,
    Code: '8111',
    Name: 'Conditioner, leaf'
  },
  {
    Id: 19038,
    Code: '8111',
    Name: 'Conditioner, food products mfr'
  },
  {
    Id: 19039,
    Code: '8111',
    Name: 'Confectioner, sugar confectionery mfr'
  },
  {
    Id: 19040,
    Code: '8111',
    Name: 'Controller, temperature, tobacco mfr'
  },
  {
    Id: 19041,
    Code: '8111',
    Name: 'Controlman, margarine mfr'
  },
  {
    Id: 19042,
    Code: '8111',
    Name: 'Cook, bakery'
  },
  {
    Id: 19043,
    Code: '8111',
    Name: 'Cook, food products mfr'
  },
  {
    Id: 19044,
    Code: '8111',
    Name: 'Cook, tripe dressing'
  },
  {
    Id: 19045,
    Code: '8111',
    Name: 'Cooker, crisp, potato'
  },
  {
    Id: 19046,
    Code: '8111',
    Name: 'Cooker, food products mfr'
  },
  {
    Id: 19047,
    Code: '8111',
    Name: 'Cookerman, cereal foods mfr'
  },
  {
    Id: 19048,
    Code: '8111',
    Name: 'Cooler, brewery'
  },
  {
    Id: 19049,
    Code: '8111',
    Name: 'Cooler, food products mfr'
  },
  {
    Id: 19050,
    Code: '8111',
    Name: 'Coppersidesman'
  },
  {
    Id: 19051,
    Code: '8111',
    Name: 'Corder, tobacco mfr'
  },
  {
    Id: 19052,
    Code: '8111',
    Name: 'Coverer, biscuit'
  },
  {
    Id: 19053,
    Code: '8111',
    Name: 'Coverer, chocolate'
  },
  {
    Id: 19054,
    Code: '8111',
    Name: 'Cracker, egg'
  },
  {
    Id: 19055,
    Code: '8111',
    Name: 'Creamer, biscuit mfr'
  },
  {
    Id: 19056,
    Code: '8111',
    Name: 'Crimper, pasty'
  },
  {
    Id: 19057,
    Code: '8111',
    Name: 'Crusher, malt'
  },
  {
    Id: 19058,
    Code: '8111',
    Name: 'Crusher, seed'
  },
  {
    Id: 19059,
    Code: '8111',
    Name: 'Crusher, seed crushing'
  },
  {
    Id: 19060,
    Code: '8111',
    Name: 'Cuber, seed crushing'
  },
  {
    Id: 19061,
    Code: '8111',
    Name: 'Curer, food products'
  },
  {
    Id: 19062,
    Code: '8111',
    Name: 'Cutter, biscuit'
  },
  {
    Id: 19063,
    Code: '8111',
    Name: 'Cutter, bread, bakery'
  },
  {
    Id: 19064,
    Code: '8111',
    Name: 'Cutter, leaf, tobacco'
  },
  {
    Id: 19065,
    Code: '8111',
    Name: 'Cutter, lemon'
  },
  {
    Id: 19066,
    Code: '8111',
    Name: 'Cutter, lozenge'
  },
  {
    Id: 19067,
    Code: '8111',
    Name: 'Cutter, peel'
  },
  {
    Id: 19068,
    Code: '8111',
    Name: 'Cutter, sugar'
  },
  {
    Id: 19069,
    Code: '8111',
    Name: 'Cutter, sweet'
  },
  {
    Id: 19070,
    Code: '8111',
    Name: 'Cutter, tobacco'
  },
  {
    Id: 19071,
    Code: '8111',
    Name: 'Cutter, wafer'
  },
  {
    Id: 19072,
    Code: '8111',
    Name: 'Cutter, bakery'
  },
  {
    Id: 19073,
    Code: '8111',
    Name: 'Cutter, food products mfr'
  },
  {
    Id: 19074,
    Code: '8111',
    Name: 'Cutter, tobacco mfr'
  },
  {
    Id: 19075,
    Code: '8111',
    Name: 'Dairymaid, dairy products mfr'
  },
  {
    Id: 19076,
    Code: '8111',
    Name: 'Dairymaid, milk processing'
  },
  {
    Id: 19077,
    Code: '8111',
    Name: 'Dairyman, dairy products mfr'
  },
  {
    Id: 19078,
    Code: '8111',
    Name: 'Dairyman, milk processing'
  },
  {
    Id: 19079,
    Code: '8111',
    Name: 'Decorator, sugar confectionery'
  },
  {
    Id: 19080,
    Code: '8111',
    Name: 'Dipper, chocolate'
  },
  {
    Id: 19081,
    Code: '8111',
    Name: 'Dipper, fondant'
  },
  {
    Id: 19082,
    Code: '8111',
    Name: 'Dipper, toffee'
  },
  {
    Id: 19083,
    Code: '8111',
    Name: 'Dipper, sugar confectionery mfr'
  },
  {
    Id: 19084,
    Code: '8111',
    Name: 'Disintegrator, food products mfr'
  },
  {
    Id: 19085,
    Code: '8111',
    Name: 'Divider, hand, bakery'
  },
  {
    Id: 19086,
    Code: '8111',
    Name: 'Draffman, whisky'
  },
  {
    Id: 19087,
    Code: '8111',
    Name: 'Dresser, flour'
  },
  {
    Id: 19088,
    Code: '8111',
    Name: 'Dresser, seed'
  },
  {
    Id: 19089,
    Code: '8111',
    Name: 'Dresser, skin, sausage mfr'
  },
  {
    Id: 19090,
    Code: '8111',
    Name: 'Dresser, tripe'
  },
  {
    Id: 19091,
    Code: '8111',
    Name: 'Dropper, bacon, ham, meat curing'
  },
  {
    Id: 19092,
    Code: '8111',
    Name: 'Dropper, sugar confectionery mfr'
  },
  {
    Id: 19093,
    Code: '8111',
    Name: 'Dropper, sugar refining'
  },
  {
    Id: 19094,
    Code: '8111',
    Name: 'Dryer, bacon'
  },
  {
    Id: 19095,
    Code: '8111',
    Name: 'Dryer, grain, malting'
  },
  {
    Id: 19096,
    Code: '8111',
    Name: 'Dryer, pulp'
  },
  {
    Id: 19097,
    Code: '8111',
    Name: 'Dryer, tobacco'
  },
  {
    Id: 19098,
    Code: '8111',
    Name: 'Dryer, brewery'
  },
  {
    Id: 19099,
    Code: '8111',
    Name: 'Dryer, cereal foods mfr'
  },
  {
    Id: 19100,
    Code: '8111',
    Name: 'Engineman, malt'
  },
  {
    Id: 19101,
    Code: '8111',
    Name: 'Enrober, sugar confectionery mfr'
  },
  {
    Id: 19102,
    Code: '8111',
    Name: 'Expeller, oil seed crushing'
  },
  {
    Id: 19103,
    Code: '8111',
    Name: 'Extractor, oil'
  },
  {
    Id: 19104,
    Code: '8111',
    Name: 'Feeder, biscuit'
  },
  {
    Id: 19105,
    Code: '8111',
    Name: 'Feeder, hopper, cigarette mfr'
  },
  {
    Id: 19106,
    Code: '8111',
    Name: 'Feeder, food products mfr'
  },
  {
    Id: 19107,
    Code: '8111',
    Name: 'Feeder-up, tobacco mfr'
  },
  {
    Id: 19108,
    Code: '8111',
    Name: 'Fermenter'
  },
  {
    Id: 19109,
    Code: '8111',
    Name: 'Filler, chocolate'
  },
  {
    Id: 19110,
    Code: '8111',
    Name: 'Filler, pie'
  },
  {
    Id: 19111,
    Code: '8111',
    Name: 'Filler, sausage'
  },
  {
    Id: 19112,
    Code: '8111',
    Name: 'Filler, tobacco mfr'
  },
  {
    Id: 19113,
    Code: '8111',
    Name: 'Filterer, alcoholic drink mfr'
  },
  {
    Id: 19114,
    Code: '8111',
    Name: 'Filterer, food products mfr'
  },
  {
    Id: 19115,
    Code: '8111',
    Name: 'Finer, beer'
  },
  {
    Id: 19116,
    Code: '8111',
    Name: 'Finingsman'
  },
  {
    Id: 19117,
    Code: '8111',
    Name: 'Finisher, cigar mfr'
  },
  {
    Id: 19118,
    Code: '8111',
    Name: 'Finisher, flour confectionery mfr'
  },
  {
    Id: 19119,
    Code: '8111',
    Name: 'Finisher, sugar confectionery mfr'
  },
  {
    Id: 19120,
    Code: '8111',
    Name: 'Fireman, kiln, food products mfr'
  },
  {
    Id: 19121,
    Code: '8111',
    Name: 'Fireman, bakery'
  },
  {
    Id: 19122,
    Code: '8111',
    Name: 'Fireman, food products mfr'
  },
  {
    Id: 19123,
    Code: '8111',
    Name: 'Fireman, malting'
  },
  {
    Id: 19124,
    Code: '8111',
    Name: 'Firer, malting'
  },
  {
    Id: 19125,
    Code: '8111',
    Name: 'Flusher, starch'
  },
  {
    Id: 19126,
    Code: '8111',
    Name: 'Freezer'
  },
  {
    Id: 19127,
    Code: '8111',
    Name: 'Fridgeman, ice cream mfr'
  },
  {
    Id: 19128,
    Code: '8111',
    Name: 'Fryer, food products mfr'
  },
  {
    Id: 19129,
    Code: '8111',
    Name: 'Gasman, cider mfr'
  },
  {
    Id: 19130,
    Code: '8111',
    Name: 'Glazer, sugar confectionery mfr'
  },
  {
    Id: 19131,
    Code: '8111',
    Name: 'Greaser, tin, bakery'
  },
  {
    Id: 19132,
    Code: '8111',
    Name: 'Greaser, bakery'
  },
  {
    Id: 19133,
    Code: '8111',
    Name: 'Grinder, coffee'
  },
  {
    Id: 19134,
    Code: '8111',
    Name: 'Grinder, corn'
  },
  {
    Id: 19135,
    Code: '8111',
    Name: 'Grinder, snuff'
  },
  {
    Id: 19136,
    Code: '8111',
    Name: 'Grinder, sugar'
  },
  {
    Id: 19137,
    Code: '8111',
    Name: 'Grinder, food products mfr'
  },
  {
    Id: 19138,
    Code: '8111',
    Name: 'Grinderman, grain milling'
  },
  {
    Id: 19139,
    Code: '8111',
    Name: 'Gristman, brewery'
  },
  {
    Id: 19140,
    Code: '8111',
    Name: 'Hammerman, tobacco mfr'
  },
  {
    Id: 19141,
    Code: '8111',
    Name: 'Hand, bakery'
  },
  {
    Id: 19142,
    Code: '8111',
    Name: 'Hand, bench, sugar confectionery mfr'
  },
  {
    Id: 19143,
    Code: '8111',
    Name: 'Hand, carbonating'
  },
  {
    Id: 19144,
    Code: '8111',
    Name: 'Hand, carbonation, sugar'
  },
  {
    Id: 19145,
    Code: '8111',
    Name: 'Hand, chocolate'
  },
  {
    Id: 19146,
    Code: '8111',
    Name: 'Hand, confectionery'
  },
  {
    Id: 19147,
    Code: '8111',
    Name: 'Hand, cooler, sugar refining'
  },
  {
    Id: 19148,
    Code: '8111',
    Name: 'Hand, cream, liquorice'
  },
  {
    Id: 19149,
    Code: '8111',
    Name: 'Hand, creamery'
  },
  {
    Id: 19150,
    Code: '8111',
    Name: 'Hand, dairy, milk processing'
  },
  {
    Id: 19151,
    Code: '8111',
    Name: 'Hand, deck, milk processing'
  },
  {
    Id: 19152,
    Code: '8111',
    Name: 'Hand, depository, sugar confectionery mfr'
  },
  {
    Id: 19153,
    Code: '8111',
    Name: 'Hand, divider, bakery'
  },
  {
    Id: 19154,
    Code: '8111',
    Name: 'Hand, essence'
  },
  {
    Id: 19155,
    Code: '8111',
    Name: 'Hand, filtration, alcoholic drink mfr'
  },
  {
    Id: 19156,
    Code: '8111',
    Name: 'Hand, frame, mustard'
  },
  {
    Id: 19157,
    Code: '8111',
    Name: 'Hand, frame, sugar confectionery mfr'
  },
  {
    Id: 19158,
    Code: '8111',
    Name: 'Hand, general, bacon, ham, meat curing'
  },
  {
    Id: 19159,
    Code: '8111',
    Name: 'Hand, general, bakery'
  },
  {
    Id: 19160,
    Code: '8111',
    Name: 'Hand, kitchen, food products mfr'
  },
  {
    Id: 19161,
    Code: '8111',
    Name: 'Hand, liquorice'
  },
  {
    Id: 19162,
    Code: '8111',
    Name: 'Hand, mill, flour'
  },
  {
    Id: 19163,
    Code: '8111',
    Name: 'Hand, mill, grain'
  },
  {
    Id: 19164,
    Code: '8111',
    Name: 'Hand, mill, provender'
  },
  {
    Id: 19165,
    Code: '8111',
    Name: 'Hand, mill, animal feeds mfr'
  },
  {
    Id: 19166,
    Code: '8111',
    Name: 'Hand, mill, food processing'
  },
  {
    Id: 19167,
    Code: '8111',
    Name: 'Hand, oven, bakery'
  },
  {
    Id: 19168,
    Code: '8111',
    Name: 'Hand, plant, bakery'
  },
  {
    Id: 19169,
    Code: '8111',
    Name: 'Hand, rock, sugar confectionery mfr'
  },
  {
    Id: 19170,
    Code: '8111',
    Name: 'Hand, slab'
  },
  {
    Id: 19171,
    Code: '8111',
    Name: 'Handyman, nos, grist milling'
  },
  {
    Id: 19172,
    Code: '8111',
    Name: 'Hearthman, brewery'
  },
  {
    Id: 19173,
    Code: '8111',
    Name: "Help, baker's"
  },
  {
    Id: 19174,
    Code: '8111',
    Name: 'Help, general, bakery'
  },
  {
    Id: 19175,
    Code: '8111',
    Name: 'Helper, bakehouse'
  },
  {
    Id: 19176,
    Code: '8111',
    Name: 'Homogeniser'
  },
  {
    Id: 19177,
    Code: '8111',
    Name: 'Hopperman, bakery'
  },
  {
    Id: 19178,
    Code: '8111',
    Name: 'Houseman, steep, starch mfr'
  },
  {
    Id: 19179,
    Code: '8111',
    Name: 'Houseman, tun, brewery'
  },
  {
    Id: 19180,
    Code: '8111',
    Name: 'Icer'
  },
  {
    Id: 19181,
    Code: '8111',
    Name: 'Inspector, cellar'
  },
  {
    Id: 19182,
    Code: '8111',
    Name: 'Juiceman'
  },
  {
    Id: 19183,
    Code: '8111',
    Name: 'Kibbler, food products mfr'
  },
  {
    Id: 19184,
    Code: '8111',
    Name: 'Kipperer'
  },
  {
    Id: 19185,
    Code: '8111',
    Name: 'Kneader, bakery'
  },
  {
    Id: 19186,
    Code: '8111',
    Name: 'Knocker-out, chocolate mfr'
  },
  {
    Id: 19187,
    Code: '8111',
    Name: 'Layer-out, tobacco'
  },
  {
    Id: 19188,
    Code: '8111',
    Name: 'Leader, line, food products mfr'
  },
  {
    Id: 19189,
    Code: '8111',
    Name: 'Leaser, spinning, machine'
  },
  {
    Id: 19190,
    Code: '8111',
    Name: 'Liner, tin, bakery'
  },
  {
    Id: 19191,
    Code: '8111',
    Name: 'Lineworker, food products mfr'
  },
  {
    Id: 19192,
    Code: '8111',
    Name: 'Linker, sausage'
  },
  {
    Id: 19193,
    Code: '8111',
    Name: 'Liquefier, butter'
  },
  {
    Id: 19194,
    Code: '8111',
    Name: 'Machinist, assembly, plug'
  },
  {
    Id: 19195,
    Code: '8111',
    Name: 'Machinist, banding'
  },
  {
    Id: 19196,
    Code: '8111',
    Name: 'Machinist, biscuit'
  },
  {
    Id: 19197,
    Code: '8111',
    Name: 'Machinist, centrifugal, sugar'
  },
  {
    Id: 19198,
    Code: '8111',
    Name: 'Machinist, cigar'
  },
  {
    Id: 19199,
    Code: '8111',
    Name: 'Machinist, cigarette'
  },
  {
    Id: 19200,
    Code: '8111',
    Name: 'Machinist, cream, ice'
  },
  {
    Id: 19201,
    Code: '8111',
    Name: 'Machinist, cutting, sugar confectionery mfr'
  },
  {
    Id: 19202,
    Code: '8111',
    Name: 'Machinist, cutting, tobacco mfr'
  },
  {
    Id: 19203,
    Code: '8111',
    Name: 'Machinist, cutting and wrapping, bakery'
  },
  {
    Id: 19204,
    Code: '8111',
    Name: 'Machinist, dough'
  },
  {
    Id: 19205,
    Code: '8111',
    Name: 'Machinist, drying, food products mfr'
  },
  {
    Id: 19206,
    Code: '8111',
    Name: 'Machinist, drying, tobacco mfr'
  },
  {
    Id: 19207,
    Code: '8111',
    Name: 'Machinist, enrobing'
  },
  {
    Id: 19208,
    Code: '8111',
    Name: 'Machinist, filling, skin, sausage'
  },
  {
    Id: 19209,
    Code: '8111',
    Name: 'Machinist, fondant'
  },
  {
    Id: 19210,
    Code: '8111',
    Name: 'Machinist, grading, sugar'
  },
  {
    Id: 19211,
    Code: '8111',
    Name: 'Machinist, ice-cream'
  },
  {
    Id: 19212,
    Code: '8111',
    Name: 'Machinist, making, cigarette'
  },
  {
    Id: 19213,
    Code: '8111',
    Name: 'Machinist, making, sausage'
  },
  {
    Id: 19214,
    Code: '8111',
    Name: 'Machinist, milk, dried'
  },
  {
    Id: 19215,
    Code: '8111',
    Name: 'Machinist, milling, food products mfr'
  },
  {
    Id: 19216,
    Code: '8111',
    Name: 'Machinist, mixing, bakery'
  },
  {
    Id: 19217,
    Code: '8111',
    Name: 'Machinist, mixing, food products mfr'
  },
  {
    Id: 19218,
    Code: '8111',
    Name: 'Machinist, pie'
  },
  {
    Id: 19219,
    Code: '8111',
    Name: 'Machinist, rubbing, food products mfr'
  },
  {
    Id: 19220,
    Code: '8111',
    Name: 'Machinist, slicing, bread'
  },
  {
    Id: 19221,
    Code: '8111',
    Name: 'Machinist, starch'
  },
  {
    Id: 19222,
    Code: '8111',
    Name: 'Machinist, stemming'
  },
  {
    Id: 19223,
    Code: '8111',
    Name: 'Machinist, stoving, tobacco'
  },
  {
    Id: 19224,
    Code: '8111',
    Name: 'Machinist, tempering, chocolate'
  },
  {
    Id: 19225,
    Code: '8111',
    Name: 'Machinist, threshing, tobacco'
  },
  {
    Id: 19226,
    Code: '8111',
    Name: 'Machinist, tobacco'
  },
  {
    Id: 19227,
    Code: '8111',
    Name: 'Machinist, toffee'
  },
  {
    Id: 19228,
    Code: '8111',
    Name: 'Machinist, washing, food products mfr'
  },
  {
    Id: 19229,
    Code: '8111',
    Name: 'Machinist, animal feeds mfr'
  },
  {
    Id: 19230,
    Code: '8111',
    Name: 'Machinist, bakery'
  },
  {
    Id: 19231,
    Code: '8111',
    Name: 'Machinist, bread, flour confectionery'
  },
  {
    Id: 19232,
    Code: '8111',
    Name: 'Machinist, brewery'
  },
  {
    Id: 19233,
    Code: '8111',
    Name: 'Machinist, cider mfr'
  },
  {
    Id: 19234,
    Code: '8111',
    Name: 'Machinist, dairy'
  },
  {
    Id: 19235,
    Code: '8111',
    Name: 'Machinist, distillery'
  },
  {
    Id: 19236,
    Code: '8111',
    Name: 'Machinist, food products mfr'
  },
  {
    Id: 19237,
    Code: '8111',
    Name: 'Machinist, grain milling'
  },
  {
    Id: 19238,
    Code: '8111',
    Name: 'Machinist, malting'
  },
  {
    Id: 19239,
    Code: '8111',
    Name: 'Machinist, soft drinks mfr'
  },
  {
    Id: 19240,
    Code: '8111',
    Name: 'Machinist, sugar refining'
  },
  {
    Id: 19241,
    Code: '8111',
    Name: 'Machinist, tobacco mfr'
  },
  {
    Id: 19242,
    Code: '8111',
    Name: 'Machinist, vinery'
  },
  {
    Id: 19243,
    Code: '8111',
    Name: 'Maker, base, custard powder mfr'
  },
  {
    Id: 19244,
    Code: '8111',
    Name: 'Maker, biscuit, dog'
  },
  {
    Id: 19245,
    Code: '8111',
    Name: 'Maker, biscuit'
  },
  {
    Id: 19246,
    Code: '8111',
    Name: 'Maker, bon-bon, sugar confectionery mfr'
  },
  {
    Id: 19247,
    Code: '8111',
    Name: 'Maker, brine, preserves mfr'
  },
  {
    Id: 19248,
    Code: '8111',
    Name: 'Maker, bunch'
  },
  {
    Id: 19249,
    Code: '8111',
    Name: 'Maker, butter'
  },
  {
    Id: 19250,
    Code: '8111',
    Name: 'Maker, cake, fish'
  },
  {
    Id: 19251,
    Code: '8111',
    Name: 'Maker, cake, pontefract'
  },
  {
    Id: 19252,
    Code: '8111',
    Name: 'Maker, cake, sugar confectionery mfr'
  },
  {
    Id: 19253,
    Code: '8111',
    Name: 'Maker, caramel, sugar refining'
  },
  {
    Id: 19254,
    Code: '8111',
    Name: 'Maker, cheese'
  },
  {
    Id: 19255,
    Code: '8111',
    Name: 'Maker, chocolate'
  },
  {
    Id: 19256,
    Code: '8111',
    Name: 'Maker, cider'
  },
  {
    Id: 19257,
    Code: '8111',
    Name: 'Maker, cigar'
  },
  {
    Id: 19258,
    Code: '8111',
    Name: 'Maker, cigarette'
  },
  {
    Id: 19259,
    Code: '8111',
    Name: 'Maker, confectionery, sugar confectionery'
  },
  {
    Id: 19260,
    Code: '8111',
    Name: 'Maker, cream, ice'
  },
  {
    Id: 19261,
    Code: '8111',
    Name: 'Maker, cream'
  },
  {
    Id: 19262,
    Code: '8111',
    Name: 'Maker, dough, flour confectionery mfr'
  },
  {
    Id: 19263,
    Code: '8111',
    Name: 'Maker, essence, food'
  },
  {
    Id: 19264,
    Code: '8111',
    Name: 'Maker, finings'
  },
  {
    Id: 19265,
    Code: '8111',
    Name: 'Maker, flake'
  },
  {
    Id: 19266,
    Code: '8111',
    Name: 'Maker, glucose'
  },
  {
    Id: 19267,
    Code: '8111',
    Name: 'Maker, ice-cream'
  },
  {
    Id: 19268,
    Code: '8111',
    Name: 'Maker, jam'
  },
  {
    Id: 19269,
    Code: '8111',
    Name: 'Maker, jelly'
  },
  {
    Id: 19270,
    Code: '8111',
    Name: 'Maker, lard'
  },
  {
    Id: 19271,
    Code: '8111',
    Name: 'Maker, lozenge'
  },
  {
    Id: 19272,
    Code: '8111',
    Name: 'Maker, malt'
  },
  {
    Id: 19273,
    Code: '8111',
    Name: 'Maker, margarine'
  },
  {
    Id: 19274,
    Code: '8111',
    Name: 'Maker, marzipan'
  },
  {
    Id: 19275,
    Code: '8111',
    Name: 'Maker, meat, potted'
  },
  {
    Id: 19276,
    Code: '8111',
    Name: 'Maker, mould, sugar confectionery mfr'
  },
  {
    Id: 19277,
    Code: '8111',
    Name: 'Maker, paste, food products mfr'
  },
  {
    Id: 19278,
    Code: '8111',
    Name: 'Maker, pepper'
  },
  {
    Id: 19279,
    Code: '8111',
    Name: 'Maker, pickle'
  },
  {
    Id: 19280,
    Code: '8111',
    Name: 'Maker, pie'
  },
  {
    Id: 19281,
    Code: '8111',
    Name: 'Maker, plug, tobacco mfr'
  },
  {
    Id: 19282,
    Code: '8111',
    Name: 'Maker, powder, food'
  },
  {
    Id: 19283,
    Code: '8111',
    Name: 'Maker, pudding'
  },
  {
    Id: 19284,
    Code: '8111',
    Name: 'Maker, sauce'
  },
  {
    Id: 19285,
    Code: '8111',
    Name: 'Maker, sausage'
  },
  {
    Id: 19286,
    Code: '8111',
    Name: 'Maker, skin, sausage'
  },
  {
    Id: 19287,
    Code: '8111',
    Name: 'Maker, snuff'
  },
  {
    Id: 19288,
    Code: '8111',
    Name: 'Maker, soup'
  },
  {
    Id: 19289,
    Code: '8111',
    Name: 'Maker, starch'
  },
  {
    Id: 19290,
    Code: '8111',
    Name: 'Maker, sugar'
  },
  {
    Id: 19291,
    Code: '8111',
    Name: 'Maker, sweet'
  },
  {
    Id: 19292,
    Code: '8111',
    Name: 'Maker, syrup'
  },
  {
    Id: 19293,
    Code: '8111',
    Name: 'Maker, wafer'
  },
  {
    Id: 19294,
    Code: '8111',
    Name: 'Maker, water, mineral'
  },
  {
    Id: 19295,
    Code: '8111',
    Name: 'Maker, water, soda'
  },
  {
    Id: 19296,
    Code: '8111',
    Name: 'Maltster'
  },
  {
    Id: 19297,
    Code: '8111',
    Name: 'Man, acid, sugar refining'
  },
  {
    Id: 19298,
    Code: '8111',
    Name: 'Man, autolysis'
  },
  {
    Id: 19299,
    Code: '8111',
    Name: 'Man, battery, food products mfr'
  },
  {
    Id: 19300,
    Code: '8111',
    Name: 'Man, cake, linseed'
  },
  {
    Id: 19301,
    Code: '8111',
    Name: 'Man, carbonation, sugar'
  },
  {
    Id: 19302,
    Code: '8111',
    Name: 'Man, chiller, brewery'
  },
  {
    Id: 19303,
    Code: '8111',
    Name: 'Man, clarifier'
  },
  {
    Id: 19304,
    Code: '8111',
    Name: 'Man, conche'
  },
  {
    Id: 19305,
    Code: '8111',
    Name: 'Man, converter, sugar, glucose mfr'
  },
  {
    Id: 19306,
    Code: '8111',
    Name: 'Man, dough'
  },
  {
    Id: 19307,
    Code: '8111',
    Name: 'Man, evaporator, multiple, sugar, glucose mfr'
  },
  {
    Id: 19308,
    Code: '8111',
    Name: 'Man, evaporator, food products mfr'
  },
  {
    Id: 19309,
    Code: '8111',
    Name: 'Man, fermenting, distillery'
  },
  {
    Id: 19310,
    Code: '8111',
    Name: 'Man, filter, alcoholic drink mfr'
  },
  {
    Id: 19311,
    Code: '8111',
    Name: 'Man, filter, vinegar mfr'
  },
  {
    Id: 19312,
    Code: '8111',
    Name: 'Man, flavouring, cereals'
  },
  {
    Id: 19313,
    Code: '8111',
    Name: 'Man, floor, malting'
  },
  {
    Id: 19314,
    Code: '8111',
    Name: 'Man, floor and kiln, malting'
  },
  {
    Id: 19315,
    Code: '8111',
    Name: 'Man, granary'
  },
  {
    Id: 19316,
    Code: '8111',
    Name: 'Man, granulator, sugar'
  },
  {
    Id: 19317,
    Code: '8111',
    Name: 'Man, kiln, distillery'
  },
  {
    Id: 19318,
    Code: '8111',
    Name: 'Man, liquor, sugar refining'
  },
  {
    Id: 19319,
    Code: '8111',
    Name: 'Man, machinery, grain'
  },
  {
    Id: 19320,
    Code: '8111',
    Name: 'Man, malt'
  },
  {
    Id: 19321,
    Code: '8111',
    Name: 'Man, melter, food products mfr'
  },
  {
    Id: 19322,
    Code: '8111',
    Name: 'Man, mill, malt'
  },
  {
    Id: 19323,
    Code: '8111',
    Name: 'Man, mill, salt mfr'
  },
  {
    Id: 19324,
    Code: '8111',
    Name: 'Man, mixer, animal feeds mfr'
  },
  {
    Id: 19325,
    Code: '8111',
    Name: 'Man, pan, boiling, foods'
  },
  {
    Id: 19326,
    Code: '8111',
    Name: 'Man, pan, vacuum, food products mfr'
  },
  {
    Id: 19327,
    Code: '8111',
    Name: 'Man, pan, food products mfr'
  },
  {
    Id: 19328,
    Code: '8111',
    Name: 'Man, pan, sugar refining'
  },
  {
    Id: 19329,
    Code: '8111',
    Name: 'Man, paraflow, brewing'
  },
  {
    Id: 19330,
    Code: '8111',
    Name: 'Man, plant, dehydration, food products mfr'
  },
  {
    Id: 19331,
    Code: '8111',
    Name: 'Man, purifier, food products mfr'
  },
  {
    Id: 19332,
    Code: '8111',
    Name: 'Man, refrigerator, brewery'
  },
  {
    Id: 19333,
    Code: '8111',
    Name: 'Man, retort, canned foods mfr'
  },
  {
    Id: 19334,
    Code: '8111',
    Name: 'Man, room, back, distillery'
  },
  {
    Id: 19335,
    Code: '8111',
    Name: 'Man, room, malt'
  },
  {
    Id: 19336,
    Code: '8111',
    Name: 'Man, room, mash'
  },
  {
    Id: 19337,
    Code: '8111',
    Name: 'Man, room, tun'
  },
  {
    Id: 19338,
    Code: '8111',
    Name: 'Man, safe, sugar refining'
  },
  {
    Id: 19339,
    Code: '8111',
    Name: 'Man, service, chocolate mfr'
  },
  {
    Id: 19340,
    Code: '8111',
    Name: 'Man, side, brewery'
  },
  {
    Id: 19341,
    Code: '8111',
    Name: 'Man, sulphitation'
  },
  {
    Id: 19342,
    Code: '8111',
    Name: 'Man, table, food products mfr'
  },
  {
    Id: 19343,
    Code: '8111',
    Name: 'Man, toffee'
  },
  {
    Id: 19344,
    Code: '8111',
    Name: 'Man, tunnel, ice cream'
  },
  {
    Id: 19345,
    Code: '8111',
    Name: 'Man, yeast'
  },
  {
    Id: 19346,
    Code: '8111',
    Name: 'Manufacturer, food'
  },
  {
    Id: 19347,
    Code: '8111',
    Name: 'Manufacturer, food products mfr'
  },
  {
    Id: 19348,
    Code: '8111',
    Name: 'Manufacturer, soft drinks mfr'
  },
  {
    Id: 19349,
    Code: '8111',
    Name: 'Manufacturer, tobacco mfr'
  },
  {
    Id: 19350,
    Code: '8111',
    Name: 'Marker, cask'
  },
  {
    Id: 19351,
    Code: '8111',
    Name: 'Marker, sugar confectionery mfr'
  },
  {
    Id: 19352,
    Code: '8111',
    Name: 'Mashman'
  },
  {
    Id: 19353,
    Code: '8111',
    Name: "Mate, liquorman's, sugar"
  },
  {
    Id: 19354,
    Code: '8111',
    Name: 'Melter, sugar'
  },
  {
    Id: 19355,
    Code: '8111',
    Name: 'Melter, sugar refining'
  },
  {
    Id: 19356,
    Code: '8111',
    Name: 'Miller, corn'
  },
  {
    Id: 19357,
    Code: '8111',
    Name: 'Miller, flour'
  },
  {
    Id: 19358,
    Code: '8111',
    Name: 'Miller, grain'
  },
  {
    Id: 19359,
    Code: '8111',
    Name: 'Miller, malt'
  },
  {
    Id: 19360,
    Code: '8111',
    Name: 'Miller, mustard'
  },
  {
    Id: 19361,
    Code: '8111',
    Name: 'Miller, oil'
  },
  {
    Id: 19362,
    Code: '8111',
    Name: 'Miller, provender'
  },
  {
    Id: 19363,
    Code: '8111',
    Name: 'Miller, rice'
  },
  {
    Id: 19364,
    Code: '8111',
    Name: 'Miller, spice'
  },
  {
    Id: 19365,
    Code: '8111',
    Name: 'Miller, animal feeds mfr'
  },
  {
    Id: 19366,
    Code: '8111',
    Name: 'Miller, brewery'
  },
  {
    Id: 19367,
    Code: '8111',
    Name: 'Miller, grain milling'
  },
  {
    Id: 19368,
    Code: '8111',
    Name: 'Miller, sugar refining'
  },
  {
    Id: 19369,
    Code: '8111',
    Name: 'Miller, whisky distilling'
  },
  {
    Id: 19370,
    Code: '8111',
    Name: 'Minder, oven, bakery'
  },
  {
    Id: 19371,
    Code: '8111',
    Name: 'Minder, retort, food canning'
  },
  {
    Id: 19372,
    Code: '8111',
    Name: 'Mixer, batch'
  },
  {
    Id: 19373,
    Code: '8111',
    Name: 'Mixer, cake'
  },
  {
    Id: 19374,
    Code: '8111',
    Name: 'Mixer, chocolate'
  },
  {
    Id: 19375,
    Code: '8111',
    Name: 'Mixer, colour, custard powder mfr'
  },
  {
    Id: 19376,
    Code: '8111',
    Name: 'Mixer, compound, animal feeds mfr'
  },
  {
    Id: 19377,
    Code: '8111',
    Name: 'Mixer, cream, ice'
  },
  {
    Id: 19378,
    Code: '8111',
    Name: 'Mixer, dough, flour confectionery mfr'
  },
  {
    Id: 19379,
    Code: '8111',
    Name: 'Mixer, flour'
  },
  {
    Id: 19380,
    Code: '8111',
    Name: 'Mixer, food'
  },
  {
    Id: 19381,
    Code: '8111',
    Name: 'Mixer, ice-cream'
  },
  {
    Id: 19382,
    Code: '8111',
    Name: 'Mixer, recipe, food products mfr'
  },
  {
    Id: 19383,
    Code: '8111',
    Name: 'Mixer, spice'
  },
  {
    Id: 19384,
    Code: '8111',
    Name: 'Mixer, sponge, bakery'
  },
  {
    Id: 19385,
    Code: '8111',
    Name: 'Mixer, sugar, condensed milk mfr'
  },
  {
    Id: 19386,
    Code: '8111',
    Name: 'Mixer, syrup, mineral water mfr'
  },
  {
    Id: 19387,
    Code: '8111',
    Name: 'Mixer, animal feeds mfr'
  },
  {
    Id: 19388,
    Code: '8111',
    Name: 'Mixer, flour confectionery mfr'
  },
  {
    Id: 19389,
    Code: '8111',
    Name: 'Mixer, food products mfr'
  },
  {
    Id: 19390,
    Code: '8111',
    Name: 'Mixer, soft drinks mfr'
  },
  {
    Id: 19391,
    Code: '8111',
    Name: 'Mixer, tobacco mfr'
  },
  {
    Id: 19392,
    Code: '8111',
    Name: 'Moulder, chocolate'
  },
  {
    Id: 19393,
    Code: '8111',
    Name: 'Moulder, cigar'
  },
  {
    Id: 19394,
    Code: '8111',
    Name: 'Moulder, machine, chocolate'
  },
  {
    Id: 19395,
    Code: '8111',
    Name: 'Moulder, marzipan'
  },
  {
    Id: 19396,
    Code: '8111',
    Name: 'Moulder, bakery'
  },
  {
    Id: 19397,
    Code: '8111',
    Name: 'Moulder, sugar confectionery mfr'
  },
  {
    Id: 19398,
    Code: '8111',
    Name: 'Moulder, tobacco mfr'
  },
  {
    Id: 19399,
    Code: '8111',
    Name: 'Nibber, cocoa mfr'
  },
  {
    Id: 19400,
    Code: '8111',
    Name: 'Operative, bakery'
  },
  {
    Id: 19401,
    Code: '8111',
    Name: 'Operative, food'
  },
  {
    Id: 19402,
    Code: '8111',
    Name: 'Operative, line, food products mfr'
  },
  {
    Id: 19403,
    Code: '8111',
    Name: 'Operative, powder, baking'
  },
  {
    Id: 19404,
    Code: '8111',
    Name: 'Operative, process, food'
  },
  {
    Id: 19405,
    Code: '8111',
    Name: 'Operative, processing, food'
  },
  {
    Id: 19406,
    Code: '8111',
    Name: 'Operative, production, food'
  },
  {
    Id: 19407,
    Code: '8111',
    Name: 'Operative, tobacco'
  },
  {
    Id: 19408,
    Code: '8111',
    Name: 'Operator, acidifier'
  },
  {
    Id: 19409,
    Code: '8111',
    Name: 'Operator, autoclave, food products mfr'
  },
  {
    Id: 19410,
    Code: '8111',
    Name: 'Operator, billet, bakery'
  },
  {
    Id: 19411,
    Code: '8111',
    Name: 'Operator, blending, custard powder mfr'
  },
  {
    Id: 19412,
    Code: '8111',
    Name: 'Operator, boiler, sugar'
  },
  {
    Id: 19413,
    Code: '8111',
    Name: 'Operator, brake, bakery'
  },
  {
    Id: 19414,
    Code: '8111',
    Name: 'Operator, brewery'
  },
  {
    Id: 19415,
    Code: '8111',
    Name: 'Operator, centrifugal, starch'
  },
  {
    Id: 19416,
    Code: '8111',
    Name: 'Operator, cooker, canned foods'
  },
  {
    Id: 19417,
    Code: '8111',
    Name: 'Operator, cooler, brine, milk'
  },
  {
    Id: 19418,
    Code: '8111',
    Name: 'Operator, cuber'
  },
  {
    Id: 19419,
    Code: '8111',
    Name: 'Operator, distillery'
  },
  {
    Id: 19420,
    Code: '8111',
    Name: "Operator, drier's, grain, milk foods"
  },
  {
    Id: 19421,
    Code: '8111',
    Name: 'Operator, evaporator, food products mfr'
  },
  {
    Id: 19422,
    Code: '8111',
    Name: 'Operator, filter, whisky distilling'
  },
  {
    Id: 19423,
    Code: '8111',
    Name: 'Operator, freezer, fruit, vegetable preserving'
  },
  {
    Id: 19424,
    Code: '8111',
    Name: 'Operator, freezer, ice cream making'
  },
  {
    Id: 19425,
    Code: '8111',
    Name: 'Operator, froster, fruit, vegetable preserving'
  },
  {
    Id: 19426,
    Code: '8111',
    Name: 'Operator, froster, ice cream making'
  },
  {
    Id: 19427,
    Code: '8111',
    Name: 'Operator, grinder and roller, cheese processing'
  },
  {
    Id: 19428,
    Code: '8111',
    Name: 'Operator, homogeniser, ice cream'
  },
  {
    Id: 19429,
    Code: '8111',
    Name: 'Operator, line, food products mfr'
  },
  {
    Id: 19430,
    Code: '8111',
    Name: 'Operator, mill, grain milling'
  },
  {
    Id: 19431,
    Code: '8111',
    Name: 'Operator, mixer, sugar confectionery mfr'
  },
  {
    Id: 19432,
    Code: '8111',
    Name: 'Operator, moulder, chocolate'
  },
  {
    Id: 19433,
    Code: '8111',
    Name: 'Operator, oven, vacuum, food products mfr'
  },
  {
    Id: 19434,
    Code: '8111',
    Name: 'Operator, pan, vacuum, food products mfr'
  },
  {
    Id: 19435,
    Code: '8111',
    Name: 'Operator, pan, food products mfr'
  },
  {
    Id: 19436,
    Code: '8111',
    Name: 'Operator, plant, bakery'
  },
  {
    Id: 19437,
    Code: '8111',
    Name: 'Operator, plant, dehydration, fruit, vegetable preserving'
  },
  {
    Id: 19438,
    Code: '8111',
    Name: 'Operator, plant, drying, food products mfr'
  },
  {
    Id: 19439,
    Code: '8111',
    Name: 'Operator, plant, spray, milk processing'
  },
  {
    Id: 19440,
    Code: '8111',
    Name: 'Operator, plant, bakery'
  },
  {
    Id: 19441,
    Code: '8111',
    Name: 'Operator, plant, food processing'
  },
  {
    Id: 19442,
    Code: '8111',
    Name: 'Operator, preserving, fruit pulp'
  },
  {
    Id: 19443,
    Code: '8111',
    Name: 'Operator, process, bakery, flour confectionery mfr'
  },
  {
    Id: 19444,
    Code: '8111',
    Name: 'Operator, process, brewery'
  },
  {
    Id: 19445,
    Code: '8111',
    Name: 'Operator, process, food products mfr'
  },
  {
    Id: 19446,
    Code: '8111',
    Name: 'Operator, retort, food products mfr'
  },
  {
    Id: 19447,
    Code: '8111',
    Name: 'Operator, room, sifting'
  },
  {
    Id: 19448,
    Code: '8111',
    Name: 'Operator, saw, band, food products mfr'
  },
  {
    Id: 19449,
    Code: '8111',
    Name: 'Operator, separator, food processing'
  },
  {
    Id: 19450,
    Code: '8111',
    Name: 'Operator, sieve, rotex, tobacco mfr'
  },
  {
    Id: 19451,
    Code: '8111',
    Name: 'Operator, sieve, food products mfr'
  },
  {
    Id: 19452,
    Code: '8111',
    Name: 'Operator, silo, tobacco mfr'
  },
  {
    Id: 19453,
    Code: '8111',
    Name: 'Operator, sterilizer, milk'
  },
  {
    Id: 19454,
    Code: '8111',
    Name: 'Operator, tandem, chocolate'
  },
  {
    Id: 19455,
    Code: '8111',
    Name: 'Operator, triples'
  },
  {
    Id: 19456,
    Code: '8111',
    Name: 'Operator, viscoliser, ice cream'
  },
  {
    Id: 19457,
    Code: '8111',
    Name: 'Operator, votator'
  },
  {
    Id: 19458,
    Code: '8111',
    Name: 'Operator, food products mfr'
  },
  {
    Id: 19459,
    Code: '8111',
    Name: 'Ovenman, bakery'
  },
  {
    Id: 19460,
    Code: '8111',
    Name: 'Ovenman, food products mfr'
  },
  {
    Id: 19461,
    Code: '8111',
    Name: 'Ovensman, bakery'
  },
  {
    Id: 19462,
    Code: '8111',
    Name: 'Panner, tobacco mfr'
  },
  {
    Id: 19463,
    Code: '8111',
    Name: 'Pansman, sugar refining'
  },
  {
    Id: 19464,
    Code: '8111',
    Name: 'Paperer, tin, bakery'
  },
  {
    Id: 19465,
    Code: '8111',
    Name: 'Paster, biscuit'
  },
  {
    Id: 19466,
    Code: '8111',
    Name: 'Paster, biscuit mfr'
  },
  {
    Id: 19467,
    Code: '8111',
    Name: 'Pasteuriser'
  },
  {
    Id: 19468,
    Code: '8111',
    Name: 'Peeler, lemon'
  },
  {
    Id: 19469,
    Code: '8111',
    Name: 'Peeler, orange'
  },
  {
    Id: 19470,
    Code: '8111',
    Name: 'Peeler, potato'
  },
  {
    Id: 19471,
    Code: '8111',
    Name: 'Peeler, food processing'
  },
  {
    Id: 19472,
    Code: '8111',
    Name: 'Perryman'
  },
  {
    Id: 19473,
    Code: '8111',
    Name: 'Picker, fruit, food processing'
  },
  {
    Id: 19474,
    Code: '8111',
    Name: 'Picker, food processing'
  },
  {
    Id: 19475,
    Code: '8111',
    Name: 'Picker-up, tobacco mfr'
  },
  {
    Id: 19476,
    Code: '8111',
    Name: 'Pickler, beef'
  },
  {
    Id: 19477,
    Code: '8111',
    Name: 'Pickler, food products mfr'
  },
  {
    Id: 19478,
    Code: '8111',
    Name: 'Piper, sugar'
  },
  {
    Id: 19479,
    Code: '8111',
    Name: 'Piper, sugar confectionery mfr'
  },
  {
    Id: 19480,
    Code: '8111',
    Name: 'Preparer, fruit, preserved'
  },
  {
    Id: 19481,
    Code: '8111',
    Name: 'Preparer, gelatine'
  },
  {
    Id: 19482,
    Code: '8111',
    Name: 'Preparer, ingredient, raw, flour confectionery mfr'
  },
  {
    Id: 19483,
    Code: '8111',
    Name: 'Preparer, food preserving'
  },
  {
    Id: 19484,
    Code: '8111',
    Name: 'Preparer, food products mfr'
  },
  {
    Id: 19485,
    Code: '8111',
    Name: 'Preserver, food products mfr'
  },
  {
    Id: 19486,
    Code: '8111',
    Name: 'Presser, cocoa'
  },
  {
    Id: 19487,
    Code: '8111',
    Name: 'Presser, filter, food products mfr'
  },
  {
    Id: 19488,
    Code: '8111',
    Name: 'Presser, hop'
  },
  {
    Id: 19489,
    Code: '8111',
    Name: 'Presser, oil, oil seed crushing'
  },
  {
    Id: 19490,
    Code: '8111',
    Name: 'Presser, yeast'
  },
  {
    Id: 19491,
    Code: '8111',
    Name: 'Presser, cider mfr'
  },
  {
    Id: 19492,
    Code: '8111',
    Name: 'Presser, distillery'
  },
  {
    Id: 19493,
    Code: '8111',
    Name: 'Presser, food products mfr'
  },
  {
    Id: 19494,
    Code: '8111',
    Name: 'Presser, oil seed crushing'
  },
  {
    Id: 19495,
    Code: '8111',
    Name: 'Presser, tobacco mfr'
  },
  {
    Id: 19496,
    Code: '8111',
    Name: 'Primer, brewery'
  },
  {
    Id: 19497,
    Code: '8111',
    Name: 'Processor, food'
  },
  {
    Id: 19498,
    Code: '8111',
    Name: 'Processor, meat, meat packing'
  },
  {
    Id: 19499,
    Code: '8111',
    Name: 'Processor, milk'
  },
  {
    Id: 19500,
    Code: '8111',
    Name: 'Pulper, food products mfr'
  },
  {
    Id: 19501,
    Code: '8111',
    Name: 'Purifier, food products mfr'
  },
  {
    Id: 19502,
    Code: '8111',
    Name: 'Racker, alcoholic drink mfr'
  },
  {
    Id: 19503,
    Code: '8111',
    Name: 'Racker, vinegar mfr'
  },
  {
    Id: 19504,
    Code: '8111',
    Name: 'Refiner, dripping'
  },
  {
    Id: 19505,
    Code: '8111',
    Name: 'Refiner, fat'
  },
  {
    Id: 19506,
    Code: '8111',
    Name: 'Refiner, lard'
  },
  {
    Id: 19507,
    Code: '8111',
    Name: 'Refiner, chocolate mfr'
  },
  {
    Id: 19508,
    Code: '8111',
    Name: 'Refiner, food products mfr'
  },
  {
    Id: 19509,
    Code: '8111',
    Name: 'Refiner, oil seed crushing'
  },
  {
    Id: 19510,
    Code: '8111',
    Name: 'Refiner, sugar refining'
  },
  {
    Id: 19511,
    Code: '8111',
    Name: 'Remoistener, dextrin'
  },
  {
    Id: 19512,
    Code: '8111',
    Name: 'Renderer, lard'
  },
  {
    Id: 19513,
    Code: '8111',
    Name: 'Roaster, barley'
  },
  {
    Id: 19514,
    Code: '8111',
    Name: 'Roaster, malt'
  },
  {
    Id: 19515,
    Code: '8111',
    Name: 'Roaster, food products'
  },
  {
    Id: 19516,
    Code: '8111',
    Name: 'Rodder, fish curing'
  },
  {
    Id: 19517,
    Code: '8111',
    Name: 'Roller, ball'
  },
  {
    Id: 19518,
    Code: '8111',
    Name: 'Roller, pastry'
  },
  {
    Id: 19519,
    Code: '8111',
    Name: 'Roller, slab'
  },
  {
    Id: 19520,
    Code: '8111',
    Name: 'Roller, cigar mfr'
  },
  {
    Id: 19521,
    Code: '8111',
    Name: 'Roller, flour confectionery mfr'
  },
  {
    Id: 19522,
    Code: '8111',
    Name: 'Roller, flour milling'
  },
  {
    Id: 19523,
    Code: '8111',
    Name: 'Roller, food products mfr'
  },
  {
    Id: 19524,
    Code: '8111',
    Name: 'Roller, oil seed crushing'
  },
  {
    Id: 19525,
    Code: '8111',
    Name: 'Rouser'
  },
  {
    Id: 19526,
    Code: '8111',
    Name: 'Runner, wort'
  },
  {
    Id: 19527,
    Code: '8111',
    Name: 'Salter, dry'
  },
  {
    Id: 19528,
    Code: '8111',
    Name: 'Salter, fish'
  },
  {
    Id: 19529,
    Code: '8111',
    Name: 'Salter, bacon, ham, meat curing'
  },
  {
    Id: 19530,
    Code: '8111',
    Name: 'Scalder, tripe dressing'
  },
  {
    Id: 19531,
    Code: '8111',
    Name: 'Screener, seed'
  },
  {
    Id: 19532,
    Code: '8111',
    Name: 'Screener, grain milling'
  },
  {
    Id: 19533,
    Code: '8111',
    Name: 'Screensman, seed'
  },
  {
    Id: 19534,
    Code: '8111',
    Name: 'Sealer, meat market'
  },
  {
    Id: 19535,
    Code: '8111',
    Name: 'Selector, skin, sausage'
  },
  {
    Id: 19536,
    Code: '8111',
    Name: 'Separator, milk'
  },
  {
    Id: 19537,
    Code: '8111',
    Name: 'Separator, skin, sausage'
  },
  {
    Id: 19538,
    Code: '8111',
    Name: 'Server, confectionery mfr'
  },
  {
    Id: 19539,
    Code: '8111',
    Name: 'Servicer, line, food products mfr'
  },
  {
    Id: 19540,
    Code: '8111',
    Name: 'Setter, sugar confectionery mfr'
  },
  {
    Id: 19541,
    Code: '8111',
    Name: 'Shellerman'
  },
  {
    Id: 19542,
    Code: '8111',
    Name: 'Shredder, food products mfr'
  },
  {
    Id: 19543,
    Code: '8111',
    Name: 'Sidesman, copper, brewery'
  },
  {
    Id: 19544,
    Code: '8111',
    Name: 'Siever, food products mfr'
  },
  {
    Id: 19545,
    Code: '8111',
    Name: 'Sifter, flour'
  },
  {
    Id: 19546,
    Code: '8111',
    Name: 'Sifter, food products mfr'
  },
  {
    Id: 19547,
    Code: '8111',
    Name: 'Silksman'
  },
  {
    Id: 19548,
    Code: '8111',
    Name: 'Siloman, seed crushing'
  },
  {
    Id: 19549,
    Code: '8111',
    Name: 'Slabber, toffee'
  },
  {
    Id: 19550,
    Code: '8111',
    Name: 'Slicer, bakery'
  },
  {
    Id: 19551,
    Code: '8111',
    Name: 'Slicer, food processing'
  },
  {
    Id: 19552,
    Code: '8111',
    Name: 'Slitter, tobacco mfr'
  },
  {
    Id: 19553,
    Code: '8111',
    Name: 'Smoker, food products mfr'
  },
  {
    Id: 19554,
    Code: '8111',
    Name: 'Sorter, bean, cocoa'
  },
  {
    Id: 19555,
    Code: '8111',
    Name: 'Sorter, seed, mustard'
  },
  {
    Id: 19556,
    Code: '8111',
    Name: 'Speeter, fish curing'
  },
  {
    Id: 19557,
    Code: '8111',
    Name: 'Spinner, sugar'
  },
  {
    Id: 19558,
    Code: '8111',
    Name: 'Spinner, sugar, glucose mfr'
  },
  {
    Id: 19559,
    Code: '8111',
    Name: 'Spinner, tobacco mfr'
  },
  {
    Id: 19560,
    Code: '8111',
    Name: 'Splitter, tobacco mfr'
  },
  {
    Id: 19561,
    Code: '8111',
    Name: 'Sprayer, food products mfr'
  },
  {
    Id: 19562,
    Code: '8111',
    Name: 'Spreader, food products mfr'
  },
  {
    Id: 19563,
    Code: '8111',
    Name: 'Spreader, tobacco mfr'
  },
  {
    Id: 19564,
    Code: '8111',
    Name: 'Steephouseman, starch mfr'
  },
  {
    Id: 19565,
    Code: '8111',
    Name: 'Steepsman, starch mfr'
  },
  {
    Id: 19566,
    Code: '8111',
    Name: 'Stemmer, leaf'
  },
  {
    Id: 19567,
    Code: '8111',
    Name: 'Stemmer, tobacco mfr'
  },
  {
    Id: 19568,
    Code: '8111',
    Name: 'Steriliser, milk'
  },
  {
    Id: 19569,
    Code: '8111',
    Name: 'Steriliser, canned foods mfr'
  },
  {
    Id: 19570,
    Code: '8111',
    Name: 'Stover, bacon, ham, meat curing'
  },
  {
    Id: 19571,
    Code: '8111',
    Name: 'Stover, food products mfr'
  },
  {
    Id: 19572,
    Code: '8111',
    Name: 'Stover, starch mfr'
  },
  {
    Id: 19573,
    Code: '8111',
    Name: 'Stover, tobacco mfr'
  },
  {
    Id: 19574,
    Code: '8111',
    Name: 'Stripper, biscuit'
  },
  {
    Id: 19575,
    Code: '8111',
    Name: 'Stripper, leaf'
  },
  {
    Id: 19576,
    Code: '8111',
    Name: 'Stripper, liquorice'
  },
  {
    Id: 19577,
    Code: '8111',
    Name: 'Stripper, tin, biscuit mfr'
  },
  {
    Id: 19578,
    Code: '8111',
    Name: 'Stripper, tobacco mfr'
  },
  {
    Id: 19579,
    Code: '8111',
    Name: 'Supplier, leaf'
  },
  {
    Id: 19580,
    Code: '8111',
    Name: 'Tankerman, whisky distilling'
  },
  {
    Id: 19581,
    Code: '8111',
    Name: 'Tankman, seed, yeast'
  },
  {
    Id: 19582,
    Code: '8111',
    Name: 'Teamer, tobacco mfr'
  },
  {
    Id: 19583,
    Code: '8111',
    Name: 'Tier, sausage'
  },
  {
    Id: 19584,
    Code: '8111',
    Name: 'Topman, bacon, ham, meat curing'
  },
  {
    Id: 19585,
    Code: '8111',
    Name: 'Topper, beet, sugar mfr'
  },
  {
    Id: 19586,
    Code: '8111',
    Name: 'Tracer, chocolate mfr'
  },
  {
    Id: 19587,
    Code: '8111',
    Name: 'Trimmer, cake'
  },
  {
    Id: 19588,
    Code: '8111',
    Name: 'Trimmer, vegetable'
  },
  {
    Id: 19589,
    Code: '8111',
    Name: 'Turner, biscuit'
  },
  {
    Id: 19590,
    Code: '8111',
    Name: 'Turner, brewery'
  },
  {
    Id: 19591,
    Code: '8111',
    Name: 'Twister, sugar, barley'
  },
  {
    Id: 19592,
    Code: '8111',
    Name: 'Vatman, brewery'
  },
  {
    Id: 19593,
    Code: '8111',
    Name: 'Vatman, cider mfr'
  },
  {
    Id: 19594,
    Code: '8111',
    Name: 'Vatman, soft drinks mfr'
  },
  {
    Id: 19595,
    Code: '8111',
    Name: 'Vatman, vinegar mfr'
  },
  {
    Id: 19596,
    Code: '8111',
    Name: 'Washer, beet, sugar'
  },
  {
    Id: 19597,
    Code: '8111',
    Name: 'Washer, fruit'
  },
  {
    Id: 19598,
    Code: '8111',
    Name: 'Washer, meat'
  },
  {
    Id: 19599,
    Code: '8111',
    Name: 'Washer, fish curing'
  },
  {
    Id: 19600,
    Code: '8111',
    Name: 'Washer, grain milling'
  },
  {
    Id: 19601,
    Code: '8111',
    Name: 'Washerman, grain milling'
  },
  {
    Id: 19602,
    Code: '8111',
    Name: 'Weigher and mixer, sugar confectionery mfr'
  },
  {
    Id: 19603,
    Code: '8111',
    Name: 'Worker, bakehouse'
  },
  {
    Id: 19604,
    Code: '8111',
    Name: 'Worker, bakery'
  },
  {
    Id: 19605,
    Code: '8111',
    Name: 'Worker, brewery'
  },
  {
    Id: 19606,
    Code: '8111',
    Name: 'Worker, butter'
  },
  {
    Id: 19607,
    Code: '8111',
    Name: 'Worker, casein, food products mfr'
  },
  {
    Id: 19608,
    Code: '8111',
    Name: 'Worker, cheese'
  },
  {
    Id: 19609,
    Code: '8111',
    Name: 'Worker, chocolate'
  },
  {
    Id: 19610,
    Code: '8111',
    Name: 'Worker, cider'
  },
  {
    Id: 19611,
    Code: '8111',
    Name: 'Worker, confectionery, bakery'
  },
  {
    Id: 19612,
    Code: '8111',
    Name: 'Worker, confectionery, sugar confectionery mfr'
  },
  {
    Id: 19613,
    Code: '8111',
    Name: 'Worker, copperhead'
  },
  {
    Id: 19614,
    Code: '8111',
    Name: 'Worker, creamery'
  },
  {
    Id: 19615,
    Code: '8111',
    Name: 'Worker, distillery'
  },
  {
    Id: 19616,
    Code: '8111',
    Name: 'Worker, factory, brewery'
  },
  {
    Id: 19617,
    Code: '8111',
    Name: 'Worker, factory, distillery'
  },
  {
    Id: 19618,
    Code: '8111',
    Name: 'Worker, factory, food products mfr'
  },
  {
    Id: 19619,
    Code: '8111',
    Name: 'Worker, factory, soft drinks mfr'
  },
  {
    Id: 19620,
    Code: '8111',
    Name: 'Worker, factory, tobacco mfr'
  },
  {
    Id: 19621,
    Code: '8111',
    Name: 'Worker, food'
  },
  {
    Id: 19622,
    Code: '8111',
    Name: 'Worker, gelatine'
  },
  {
    Id: 19623,
    Code: '8111',
    Name: 'Worker, general, food products mfr'
  },
  {
    Id: 19624,
    Code: '8111',
    Name: 'Worker, margarine'
  },
  {
    Id: 19625,
    Code: '8111',
    Name: 'Worker, marzipan'
  },
  {
    Id: 19626,
    Code: '8111',
    Name: 'Worker, mill, animal feeds mfr'
  },
  {
    Id: 19627,
    Code: '8111',
    Name: 'Worker, pan, revolving, sugar confectionery mfr'
  },
  {
    Id: 19628,
    Code: '8111',
    Name: 'Worker, pickle'
  },
  {
    Id: 19629,
    Code: '8111',
    Name: 'Worker, plant, bakery'
  },
  {
    Id: 19630,
    Code: '8111',
    Name: 'Worker, process, animal feeds mfr'
  },
  {
    Id: 19631,
    Code: '8111',
    Name: 'Worker, process, bakery'
  },
  {
    Id: 19632,
    Code: '8111',
    Name: 'Worker, process, brewery'
  },
  {
    Id: 19633,
    Code: '8111',
    Name: 'Worker, process, chocolate mfr'
  },
  {
    Id: 19634,
    Code: '8111',
    Name: 'Worker, process, dairy'
  },
  {
    Id: 19635,
    Code: '8111',
    Name: 'Worker, process, distillery'
  },
  {
    Id: 19636,
    Code: '8111',
    Name: 'Worker, process, flour confectionery mfr'
  },
  {
    Id: 19637,
    Code: '8111',
    Name: 'Worker, process, food products mfr'
  },
  {
    Id: 19638,
    Code: '8111',
    Name: 'Worker, process, meat products mfr'
  },
  {
    Id: 19639,
    Code: '8111',
    Name: 'Worker, process, organic oil and fat processing'
  },
  {
    Id: 19640,
    Code: '8111',
    Name: 'Worker, process, soft drinks mfr'
  },
  {
    Id: 19641,
    Code: '8111',
    Name: 'Worker, process, starch mfr'
  },
  {
    Id: 19642,
    Code: '8111',
    Name: 'Worker, process, tobacco mfr'
  },
  {
    Id: 19643,
    Code: '8111',
    Name: 'Worker, process, vinery'
  },
  {
    Id: 19644,
    Code: '8111',
    Name: 'Worker, process, yeast mfr'
  },
  {
    Id: 19645,
    Code: '8111',
    Name: 'Worker, pudding'
  },
  {
    Id: 19646,
    Code: '8111',
    Name: 'Worker, room, icing'
  },
  {
    Id: 19647,
    Code: '8111',
    Name: 'Worker, room, nut'
  },
  {
    Id: 19648,
    Code: '8111',
    Name: 'Worker, room, sausage'
  },
  {
    Id: 19649,
    Code: '8111',
    Name: 'Worker, room, starch'
  },
  {
    Id: 19650,
    Code: '8111',
    Name: 'Worker, sediment, whisky distilling'
  },
  {
    Id: 19651,
    Code: '8111',
    Name: 'Worker, sugar'
  },
  {
    Id: 19652,
    Code: '8111',
    Name: 'Worker, table, cigar mfr'
  },
  {
    Id: 19653,
    Code: '8111',
    Name: 'Worker, tobacco'
  },
  {
    Id: 19654,
    Code: '8111',
    Name: 'Worker-off, sugar confectionery mfr'
  },
  {
    Id: 19655,
    Code: '8112',
    Name: 'Alterer, loom'
  },
  {
    Id: 19656,
    Code: '8112',
    Name: 'Alterer, pattern, carpet, rug mfr'
  },
  {
    Id: 19657,
    Code: '8112',
    Name: 'Alterer, textile mfr'
  },
  {
    Id: 19658,
    Code: '8112',
    Name: 'Analyst, colour, textile mfr'
  },
  {
    Id: 19659,
    Code: '8112',
    Name: 'Assembler, harness, jacquard'
  },
  {
    Id: 19660,
    Code: '8112',
    Name: 'Assembler, yarn'
  },
  {
    Id: 19661,
    Code: '8112',
    Name: 'Assembler, textile mfr'
  },
  {
    Id: 19662,
    Code: '8112',
    Name: 'Assistant, calender, textile mfr'
  },
  {
    Id: 19663,
    Code: '8112',
    Name: 'Assistant, house, dye'
  },
  {
    Id: 19664,
    Code: '8112',
    Name: "Assistant, maker's, rope"
  },
  {
    Id: 19665,
    Code: '8112',
    Name: "Assistant, matcher's, colour"
  },
  {
    Id: 19666,
    Code: '8112',
    Name: "Assistant, minder's, cotton mfr"
  },
  {
    Id: 19667,
    Code: '8112',
    Name: 'Assistant, room, blowing'
  },
  {
    Id: 19668,
    Code: '8112',
    Name: "Assistant, spinner's, textile mfr"
  },
  {
    Id: 19669,
    Code: '8112',
    Name: 'Assistant, stenter'
  },
  {
    Id: 19670,
    Code: '8112',
    Name: "Assistant, stenterer's"
  },
  {
    Id: 19671,
    Code: '8112',
    Name: 'Assistant, stretcher'
  },
  {
    Id: 19672,
    Code: '8112',
    Name: "Assistant, weaver's"
  },
  {
    Id: 19673,
    Code: '8112',
    Name: 'Attendant, backwash'
  },
  {
    Id: 19674,
    Code: '8112',
    Name: 'Attendant, board, spread'
  },
  {
    Id: 19675,
    Code: '8112',
    Name: 'Attendant, box, drawing'
  },
  {
    Id: 19676,
    Code: '8112',
    Name: 'Attendant, box, gill'
  },
  {
    Id: 19677,
    Code: '8112',
    Name: 'Attendant, calender, textiles'
  },
  {
    Id: 19678,
    Code: '8112',
    Name: 'Attendant, card, textile mfr'
  },
  {
    Id: 19679,
    Code: '8112',
    Name: 'Attendant, centrifugal, textile mfr'
  },
  {
    Id: 19680,
    Code: '8112',
    Name: 'Attendant, converter, tow-to-top'
  },
  {
    Id: 19681,
    Code: '8112',
    Name: 'Attendant, frame, twist'
  },
  {
    Id: 19682,
    Code: '8112',
    Name: 'Attendant, frame, textile mfr'
  },
  {
    Id: 19683,
    Code: '8112',
    Name: 'Attendant, jigger, asbestos opening'
  },
  {
    Id: 19684,
    Code: '8112',
    Name: 'Attendant, loom'
  },
  {
    Id: 19685,
    Code: '8112',
    Name: 'Attendant, picker, waste'
  },
  {
    Id: 19686,
    Code: '8112',
    Name: 'Attendant, roll, milling, asbestos'
  },
  {
    Id: 19687,
    Code: '8112',
    Name: 'Attendant, room, blowing'
  },
  {
    Id: 19688,
    Code: '8112',
    Name: 'Attendant, rotary, asbestos'
  },
  {
    Id: 19689,
    Code: '8112',
    Name: 'Attendant, scutcher'
  },
  {
    Id: 19690,
    Code: '8112',
    Name: 'Attendant, teaser'
  },
  {
    Id: 19691,
    Code: '8112',
    Name: 'Backer, fustian, velvet mfr'
  },
  {
    Id: 19692,
    Code: '8112',
    Name: 'Backwasher'
  },
  {
    Id: 19693,
    Code: '8112',
    Name: 'Baker, textile mfr'
  },
  {
    Id: 19694,
    Code: '8112',
    Name: 'Baller, cross'
  },
  {
    Id: 19695,
    Code: '8112',
    Name: 'Baller, wool'
  },
  {
    Id: 19696,
    Code: '8112',
    Name: 'Baller, twine'
  },
  {
    Id: 19697,
    Code: '8112',
    Name: 'Baller, yarn'
  },
  {
    Id: 19698,
    Code: '8112',
    Name: 'Baller, wool combing'
  },
  {
    Id: 19699,
    Code: '8112',
    Name: 'Banker, yarn warping'
  },
  {
    Id: 19700,
    Code: '8112',
    Name: 'Barker, rope, twine mfr'
  },
  {
    Id: 19701,
    Code: '8112',
    Name: 'Batcher, textile finishing'
  },
  {
    Id: 19702,
    Code: '8112',
    Name: 'Batter, felt'
  },
  {
    Id: 19703,
    Code: '8112',
    Name: 'Beamer, textile mfr'
  },
  {
    Id: 19704,
    Code: '8112',
    Name: 'Beater, feather'
  },
  {
    Id: 19705,
    Code: '8112',
    Name: 'Beater, feather dressing'
  },
  {
    Id: 19706,
    Code: '8112',
    Name: 'Beater, textile finishing'
  },
  {
    Id: 19707,
    Code: '8112',
    Name: 'Beetler'
  },
  {
    Id: 19708,
    Code: '8112',
    Name: 'Blender, colour, textile spinning'
  },
  {
    Id: 19709,
    Code: '8112',
    Name: 'Blender, rag'
  },
  {
    Id: 19710,
    Code: '8112',
    Name: 'Blender, shade, wool'
  },
  {
    Id: 19711,
    Code: '8112',
    Name: 'Blender, asbestos composition goods mfr'
  },
  {
    Id: 19712,
    Code: '8112',
    Name: 'Blender, carpet, rug mfr'
  },
  {
    Id: 19713,
    Code: '8112',
    Name: 'Blender, fur fibre mfr'
  },
  {
    Id: 19714,
    Code: '8112',
    Name: 'Blender, oilskin mfr'
  },
  {
    Id: 19715,
    Code: '8112',
    Name: 'Blocker, textile mfr'
  },
  {
    Id: 19716,
    Code: '8112',
    Name: 'Blower, dry'
  },
  {
    Id: 19717,
    Code: '8112',
    Name: 'Blower, fur'
  },
  {
    Id: 19718,
    Code: '8112',
    Name: 'Blower, cotton mfr'
  },
  {
    Id: 19719,
    Code: '8112',
    Name: 'Blower, textile finishing'
  },
  {
    Id: 19720,
    Code: '8112',
    Name: 'Blower-up, textile mfr'
  },
  {
    Id: 19721,
    Code: '8112',
    Name: 'Bluer, textile mfr'
  },
  {
    Id: 19722,
    Code: '8112',
    Name: 'Boarder, hosiery, knitwear mfr'
  },
  {
    Id: 19723,
    Code: '8112',
    Name: 'Bobber, embroidery mfr'
  },
  {
    Id: 19724,
    Code: '8112',
    Name: 'Bobbiner'
  },
  {
    Id: 19725,
    Code: '8112',
    Name: 'Boiler, oil, oilskin mfr'
  },
  {
    Id: 19726,
    Code: '8112',
    Name: 'Boiler, flax'
  },
  {
    Id: 19727,
    Code: '8112',
    Name: 'Boiler, textile finishing'
  },
  {
    Id: 19728,
    Code: '8112',
    Name: 'Boilerman, textile waste merchants'
  },
  {
    Id: 19729,
    Code: '8112',
    Name: 'Bouker'
  },
  {
    Id: 19730,
    Code: '8112',
    Name: 'Bowker'
  },
  {
    Id: 19731,
    Code: '8112',
    Name: 'Braider, asbestos'
  },
  {
    Id: 19732,
    Code: '8112',
    Name: 'Braider, net'
  },
  {
    Id: 19733,
    Code: '8112',
    Name: 'Braider, twine, fishing net mfr'
  },
  {
    Id: 19734,
    Code: '8112',
    Name: 'Braider, cordage mfr'
  },
  {
    Id: 19735,
    Code: '8112',
    Name: 'Braider, fishing net mfr'
  },
  {
    Id: 19736,
    Code: '8112',
    Name: 'Braider, textile smallwares mfr'
  },
  {
    Id: 19737,
    Code: '8112',
    Name: 'Breaker, can'
  },
  {
    Id: 19738,
    Code: '8112',
    Name: 'Breaker, cotton'
  },
  {
    Id: 19739,
    Code: '8112',
    Name: 'Breaker, waste'
  },
  {
    Id: 19740,
    Code: '8112',
    Name: 'Breaker, textile finishing'
  },
  {
    Id: 19741,
    Code: '8112',
    Name: 'Breaker, textile spinning'
  },
  {
    Id: 19742,
    Code: '8112',
    Name: 'Breeder, fishing net mfr'
  },
  {
    Id: 19743,
    Code: '8112',
    Name: 'Brusher, cloth, textile finishing'
  },
  {
    Id: 19744,
    Code: '8112',
    Name: 'Brusher, flannelette'
  },
  {
    Id: 19745,
    Code: '8112',
    Name: 'Brusher, roller'
  },
  {
    Id: 19746,
    Code: '8112',
    Name: 'Brusher, carpet, rug mfr'
  },
  {
    Id: 19747,
    Code: '8112',
    Name: 'Brusher, hosiery, knitwear mfr'
  },
  {
    Id: 19748,
    Code: '8112',
    Name: 'Brusher, textile mfr'
  },
  {
    Id: 19749,
    Code: '8112',
    Name: 'Brusher, wool spinning'
  },
  {
    Id: 19750,
    Code: '8112',
    Name: 'Builder, harness, textile mfr'
  },
  {
    Id: 19751,
    Code: '8112',
    Name: 'Bumper, hat mfr'
  },
  {
    Id: 19752,
    Code: '8112',
    Name: 'Bumper, textile mfr'
  },
  {
    Id: 19753,
    Code: '8112',
    Name: 'Buncher, hank'
  },
  {
    Id: 19754,
    Code: '8112',
    Name: 'Buncher, textile mfr'
  },
  {
    Id: 19755,
    Code: '8112',
    Name: 'Bundler, flax'
  },
  {
    Id: 19756,
    Code: '8112',
    Name: 'Calculator, colour'
  },
  {
    Id: 19757,
    Code: '8112',
    Name: 'Calenderer, asbestos'
  },
  {
    Id: 19758,
    Code: '8112',
    Name: 'Calenderer, canvas hosepipe mfr'
  },
  {
    Id: 19759,
    Code: '8112',
    Name: 'Calenderer, textile mfr'
  },
  {
    Id: 19760,
    Code: '8112',
    Name: 'Calenderman, textile mfr'
  },
  {
    Id: 19761,
    Code: '8112',
    Name: 'Carboniser, cloth'
  },
  {
    Id: 19762,
    Code: '8112',
    Name: 'Carboniser, piece'
  },
  {
    Id: 19763,
    Code: '8112',
    Name: 'Carboniser, rag'
  },
  {
    Id: 19764,
    Code: '8112',
    Name: 'Carboniser, wool'
  },
  {
    Id: 19765,
    Code: '8112',
    Name: 'Carboniser, textile mfr'
  },
  {
    Id: 19766,
    Code: '8112',
    Name: 'Carder, asbestos'
  },
  {
    Id: 19767,
    Code: '8112',
    Name: 'Carder, cotton'
  },
  {
    Id: 19768,
    Code: '8112',
    Name: 'Carder, fibre'
  },
  {
    Id: 19769,
    Code: '8112',
    Name: 'Carder, hair'
  },
  {
    Id: 19770,
    Code: '8112',
    Name: 'Carder, speed'
  },
  {
    Id: 19771,
    Code: '8112',
    Name: 'Carder, textile mfr'
  },
  {
    Id: 19772,
    Code: '8112',
    Name: 'Chalkman'
  },
  {
    Id: 19773,
    Code: '8112',
    Name: 'Changer, card'
  },
  {
    Id: 19774,
    Code: '8112',
    Name: 'Changer, frame, carpets'
  },
  {
    Id: 19775,
    Code: '8112',
    Name: 'Changer, flax, hemp mfr'
  },
  {
    Id: 19776,
    Code: '8112',
    Name: 'Changer, textile printing'
  },
  {
    Id: 19777,
    Code: '8112',
    Name: 'Cheeser'
  },
  {
    Id: 19778,
    Code: '8112',
    Name: 'Chromer'
  },
  {
    Id: 19779,
    Code: '8112',
    Name: 'Clamper, textile finishing'
  },
  {
    Id: 19780,
    Code: '8112',
    Name: 'Cleaner, bobbin'
  },
  {
    Id: 19781,
    Code: '8112',
    Name: 'Cleaner, bristle'
  },
  {
    Id: 19782,
    Code: '8112',
    Name: 'Cleaner, cardroom'
  },
  {
    Id: 19783,
    Code: '8112',
    Name: 'Cleaner, cloth'
  },
  {
    Id: 19784,
    Code: '8112',
    Name: 'Cleaner, feather'
  },
  {
    Id: 19785,
    Code: '8112',
    Name: 'Cleaner, lace'
  },
  {
    Id: 19786,
    Code: '8112',
    Name: 'Cleaner, loom'
  },
  {
    Id: 19787,
    Code: '8112',
    Name: 'Cleaner, machine, textile mfr'
  },
  {
    Id: 19788,
    Code: '8112',
    Name: 'Cleaner, piece'
  },
  {
    Id: 19789,
    Code: '8112',
    Name: 'Cleaner, pirn'
  },
  {
    Id: 19790,
    Code: '8112',
    Name: 'Cleaner, rag'
  },
  {
    Id: 19791,
    Code: '8112',
    Name: 'Cleaner, ramie'
  },
  {
    Id: 19792,
    Code: '8112',
    Name: 'Cleaner, roller'
  },
  {
    Id: 19793,
    Code: '8112',
    Name: 'Cleaner, table, textile mfr'
  },
  {
    Id: 19794,
    Code: '8112',
    Name: 'Cleaner, warp, textile mfr'
  },
  {
    Id: 19795,
    Code: '8112',
    Name: 'Cleaner, waste'
  },
  {
    Id: 19796,
    Code: '8112',
    Name: 'Cleaner, silk throwing'
  },
  {
    Id: 19797,
    Code: '8112',
    Name: 'Cleaner, textile finishing'
  },
  {
    Id: 19798,
    Code: '8112',
    Name: 'Clearer, warp'
  },
  {
    Id: 19799,
    Code: '8112',
    Name: 'Clearer, cotton doubling'
  },
  {
    Id: 19800,
    Code: '8112',
    Name: 'Clearer, textile finishing'
  },
  {
    Id: 19801,
    Code: '8112',
    Name: 'Clipper, cloth'
  },
  {
    Id: 19802,
    Code: '8112',
    Name: 'Clipper, knot'
  },
  {
    Id: 19803,
    Code: '8112',
    Name: 'Clipper, rope, twine mfr'
  },
  {
    Id: 19804,
    Code: '8112',
    Name: 'Clipper, textile finishing'
  },
  {
    Id: 19805,
    Code: '8112',
    Name: 'Coater, hand, oilskin mfr'
  },
  {
    Id: 19806,
    Code: '8112',
    Name: 'Coiler, rope'
  },
  {
    Id: 19807,
    Code: '8112',
    Name: 'Coiler, tape'
  },
  {
    Id: 19808,
    Code: '8112',
    Name: 'Coiler, rope, twine mfr'
  },
  {
    Id: 19809,
    Code: '8112',
    Name: 'Collector, tow, flax'
  },
  {
    Id: 19810,
    Code: '8112',
    Name: 'Colourer, carpets'
  },
  {
    Id: 19811,
    Code: '8112',
    Name: 'Colourist'
  },
  {
    Id: 19812,
    Code: '8112',
    Name: 'Comber, textile mfr'
  },
  {
    Id: 19813,
    Code: '8112',
    Name: 'Combiner, canvas goods mfr'
  },
  {
    Id: 19814,
    Code: '8112',
    Name: 'Condenser, textile mfr'
  },
  {
    Id: 19815,
    Code: '8112',
    Name: 'Conditioner, yarn'
  },
  {
    Id: 19816,
    Code: '8112',
    Name: 'Conditioner, textile mfr'
  },
  {
    Id: 19817,
    Code: '8112',
    Name: 'Coner, wood, silk'
  },
  {
    Id: 19818,
    Code: '8112',
    Name: 'Coner, textile mfr'
  },
  {
    Id: 19819,
    Code: '8112',
    Name: 'Controller, humidity'
  },
  {
    Id: 19820,
    Code: '8112',
    Name: 'Copperman, textile mfr'
  },
  {
    Id: 19821,
    Code: '8112',
    Name: 'Corrector, hosiery, knitwear mfr'
  },
  {
    Id: 19822,
    Code: '8112',
    Name: 'Coverer, elastic, textile mfr'
  },
  {
    Id: 19823,
    Code: '8112',
    Name: 'Coverer, rubber, surgical dressing mfr'
  },
  {
    Id: 19824,
    Code: '8112',
    Name: 'Crabber, french'
  },
  {
    Id: 19825,
    Code: '8112',
    Name: 'Crabber, yorkshire'
  },
  {
    Id: 19826,
    Code: '8112',
    Name: 'Crabber, textile mfr'
  },
  {
    Id: 19827,
    Code: '8112',
    Name: 'Creaser, textile mfr'
  },
  {
    Id: 19828,
    Code: '8112',
    Name: 'Creaser and lapper'
  },
  {
    Id: 19829,
    Code: '8112',
    Name: 'Creeler'
  },
  {
    Id: 19830,
    Code: '8112',
    Name: 'Creper, silk'
  },
  {
    Id: 19831,
    Code: '8112',
    Name: 'Creper'
  },
  {
    Id: 19832,
    Code: '8112',
    Name: 'Crimper, flax, hemp mfr'
  },
  {
    Id: 19833,
    Code: '8112',
    Name: 'Crimper, textile mfr'
  },
  {
    Id: 19834,
    Code: '8112',
    Name: 'Crofter, textile mfr'
  },
  {
    Id: 19835,
    Code: '8112',
    Name: 'Cropper, textile mfr'
  },
  {
    Id: 19836,
    Code: '8112',
    Name: 'Crozier'
  },
  {
    Id: 19837,
    Code: '8112',
    Name: 'Crusher, burr'
  },
  {
    Id: 19838,
    Code: '8112',
    Name: 'Curler, feather'
  },
  {
    Id: 19839,
    Code: '8112',
    Name: 'Curler, soft'
  },
  {
    Id: 19840,
    Code: '8112',
    Name: 'Curler, yarn'
  },
  {
    Id: 19841,
    Code: '8112',
    Name: 'Cutler, cloth'
  },
  {
    Id: 19842,
    Code: '8112',
    Name: 'Cutter, bass'
  },
  {
    Id: 19843,
    Code: '8112',
    Name: 'Cutter, bobbin'
  },
  {
    Id: 19844,
    Code: '8112',
    Name: 'Cutter, card, textile mfr'
  },
  {
    Id: 19845,
    Code: '8112',
    Name: 'Cutter, cloth, textile mfr'
  },
  {
    Id: 19846,
    Code: '8112',
    Name: 'Cutter, dress, fibre'
  },
  {
    Id: 19847,
    Code: '8112',
    Name: 'Cutter, fibre'
  },
  {
    Id: 19848,
    Code: '8112',
    Name: 'Cutter, fustian'
  },
  {
    Id: 19849,
    Code: '8112',
    Name: 'Cutter, hemp'
  },
  {
    Id: 19850,
    Code: '8112',
    Name: 'Cutter, manilla'
  },
  {
    Id: 19851,
    Code: '8112',
    Name: 'Cutter, pattern, jacquard card cutting'
  },
  {
    Id: 19852,
    Code: '8112',
    Name: 'Cutter, pile'
  },
  {
    Id: 19853,
    Code: '8112',
    Name: 'Cutter, rag'
  },
  {
    Id: 19854,
    Code: '8112',
    Name: 'Cutter, scallop'
  },
  {
    Id: 19855,
    Code: '8112',
    Name: 'Cutter, thrum'
  },
  {
    Id: 19856,
    Code: '8112',
    Name: 'Cutter, velvet'
  },
  {
    Id: 19857,
    Code: '8112',
    Name: 'Cutter, waste, textile mfr'
  },
  {
    Id: 19858,
    Code: '8112',
    Name: 'Cutter, wiper'
  },
  {
    Id: 19859,
    Code: '8112',
    Name: 'Cutter, textile mfr'
  },
  {
    Id: 19860,
    Code: '8112',
    Name: 'Damper, textile mfr'
  },
  {
    Id: 19861,
    Code: '8112',
    Name: 'Decatiser'
  },
  {
    Id: 19862,
    Code: '8112',
    Name: 'Decorator, leather cloth'
  },
  {
    Id: 19863,
    Code: '8112',
    Name: 'Degger'
  },
  {
    Id: 19864,
    Code: '8112',
    Name: 'Desizer'
  },
  {
    Id: 19865,
    Code: '8112',
    Name: 'Developer, textile mfr'
  },
  {
    Id: 19866,
    Code: '8112',
    Name: 'Deviller'
  },
  {
    Id: 19867,
    Code: '8112',
    Name: 'Dewer'
  },
  {
    Id: 19868,
    Code: '8112',
    Name: 'Dipper, textile mfr'
  },
  {
    Id: 19869,
    Code: '8112',
    Name: 'Disintegrator, asbestos composition goods mfr'
  },
  {
    Id: 19870,
    Code: '8112',
    Name: 'Doubler, asbestos'
  },
  {
    Id: 19871,
    Code: '8112',
    Name: 'Doubler, cloth'
  },
  {
    Id: 19872,
    Code: '8112',
    Name: 'Doubler, ring'
  },
  {
    Id: 19873,
    Code: '8112',
    Name: 'Doubler, warp'
  },
  {
    Id: 19874,
    Code: '8112',
    Name: 'Doubler, textile mfr'
  },
  {
    Id: 19875,
    Code: '8112',
    Name: 'Drafter, fibre'
  },
  {
    Id: 19876,
    Code: '8112',
    Name: 'Drafter, slipper'
  },
  {
    Id: 19877,
    Code: '8112',
    Name: 'Drafter, broom, brush mfr'
  },
  {
    Id: 19878,
    Code: '8112',
    Name: 'Drawer, card'
  },
  {
    Id: 19879,
    Code: '8112',
    Name: 'Drawer, cloth, textile finishing'
  },
  {
    Id: 19880,
    Code: '8112',
    Name: 'Drawer, cotton'
  },
  {
    Id: 19881,
    Code: '8112',
    Name: 'Drawer, hair'
  },
  {
    Id: 19882,
    Code: '8112',
    Name: 'Drawer, wool'
  },
  {
    Id: 19883,
    Code: '8112',
    Name: 'Drawer, worsted'
  },
  {
    Id: 19884,
    Code: '8112',
    Name: 'Drawer, yarn'
  },
  {
    Id: 19885,
    Code: '8112',
    Name: 'Drawer, textile mfr'
  },
  {
    Id: 19886,
    Code: '8112',
    Name: 'Drawer-in, textile mfr'
  },
  {
    Id: 19887,
    Code: '8112',
    Name: 'Drawer-off, textile mfr'
  },
  {
    Id: 19888,
    Code: '8112',
    Name: 'Dresser, bag'
  },
  {
    Id: 19889,
    Code: '8112',
    Name: 'Dresser, bass'
  },
  {
    Id: 19890,
    Code: '8112',
    Name: 'Dresser, bristle'
  },
  {
    Id: 19891,
    Code: '8112',
    Name: 'Dresser, cloth'
  },
  {
    Id: 19892,
    Code: '8112',
    Name: 'Dresser, fibre'
  },
  {
    Id: 19893,
    Code: '8112',
    Name: 'Dresser, sack'
  },
  {
    Id: 19894,
    Code: '8112',
    Name: 'Dresser, silk'
  },
  {
    Id: 19895,
    Code: '8112',
    Name: 'Dresser, warp'
  },
  {
    Id: 19896,
    Code: '8112',
    Name: 'Dresser, woollen'
  },
  {
    Id: 19897,
    Code: '8112',
    Name: 'Dresser, yarn'
  },
  {
    Id: 19898,
    Code: '8112',
    Name: 'Dresser, textile mfr'
  },
  {
    Id: 19899,
    Code: '8112',
    Name: 'Driver, assistant, textile spinning'
  },
  {
    Id: 19900,
    Code: '8112',
    Name: 'Driver, gig'
  },
  {
    Id: 19901,
    Code: '8112',
    Name: 'Driver, stenter'
  },
  {
    Id: 19902,
    Code: '8112',
    Name: 'Dryer, can, textile mfr'
  },
  {
    Id: 19903,
    Code: '8112',
    Name: 'Dryer, clip, textile mfr'
  },
  {
    Id: 19904,
    Code: '8112',
    Name: 'Dryer, cloth'
  },
  {
    Id: 19905,
    Code: '8112',
    Name: 'Dryer, cylinder, textile mfr'
  },
  {
    Id: 19906,
    Code: '8112',
    Name: 'Dryer, dyed, textile mfr'
  },
  {
    Id: 19907,
    Code: '8112',
    Name: 'Dryer, felt'
  },
  {
    Id: 19908,
    Code: '8112',
    Name: 'Dryer, hair'
  },
  {
    Id: 19909,
    Code: '8112',
    Name: 'Dryer, machine, textile mfr'
  },
  {
    Id: 19910,
    Code: '8112',
    Name: 'Dryer, rag'
  },
  {
    Id: 19911,
    Code: '8112',
    Name: 'Dryer, warp'
  },
  {
    Id: 19912,
    Code: '8112',
    Name: 'Dryer, wool'
  },
  {
    Id: 19913,
    Code: '8112',
    Name: 'Dryer, yarn'
  },
  {
    Id: 19914,
    Code: '8112',
    Name: 'Dryer, textile mfr'
  },
  {
    Id: 19915,
    Code: '8112',
    Name: 'Dubber, textile mfr'
  },
  {
    Id: 19916,
    Code: '8112',
    Name: 'Duler, wool'
  },
  {
    Id: 19917,
    Code: '8112',
    Name: 'Dumper, textile mfr'
  },
  {
    Id: 19918,
    Code: '8112',
    Name: 'Embosser, cloth'
  },
  {
    Id: 19919,
    Code: '8112',
    Name: 'Embosser, leathercloth mfr'
  },
  {
    Id: 19920,
    Code: '8112',
    Name: 'Embosser, textile mfr'
  },
  {
    Id: 19921,
    Code: '8112',
    Name: 'Ender, textile mfr'
  },
  {
    Id: 19922,
    Code: '8112',
    Name: 'Enterer, textile mfr'
  },
  {
    Id: 19923,
    Code: '8112',
    Name: 'Extractor, hydro'
  },
  {
    Id: 19924,
    Code: '8112',
    Name: 'Extractor, textile mfr'
  },
  {
    Id: 19925,
    Code: '8112',
    Name: 'Feeder, bowl'
  },
  {
    Id: 19926,
    Code: '8112',
    Name: 'Feeder, card'
  },
  {
    Id: 19927,
    Code: '8112',
    Name: 'Feeder, carder'
  },
  {
    Id: 19928,
    Code: '8112',
    Name: 'Feeder, cotton'
  },
  {
    Id: 19929,
    Code: '8112',
    Name: 'Feeder, engine, textile mfr'
  },
  {
    Id: 19930,
    Code: '8112',
    Name: 'Feeder, scutcher'
  },
  {
    Id: 19931,
    Code: '8112',
    Name: 'Feeder, stenter'
  },
  {
    Id: 19932,
    Code: '8112',
    Name: 'Feeder, wool'
  },
  {
    Id: 19933,
    Code: '8112',
    Name: 'Feeder, woollen'
  },
  {
    Id: 19934,
    Code: '8112',
    Name: 'Feeder, felt hat mfr'
  },
  {
    Id: 19935,
    Code: '8112',
    Name: 'Feeder, textile mfr'
  },
  {
    Id: 19936,
    Code: '8112',
    Name: 'Feeder-in, textile mfr'
  },
  {
    Id: 19937,
    Code: '8112',
    Name: 'Felter, textile mfr'
  },
  {
    Id: 19938,
    Code: '8112',
    Name: 'Feltman, roofing felt mfr'
  },
  {
    Id: 19939,
    Code: '8112',
    Name: 'Feltman, textile mfr'
  },
  {
    Id: 19940,
    Code: '8112',
    Name: 'Fenter'
  },
  {
    Id: 19941,
    Code: '8112',
    Name: 'Filler, back'
  },
  {
    Id: 19942,
    Code: '8112',
    Name: 'Filler, bank, textile mfr'
  },
  {
    Id: 19943,
    Code: '8112',
    Name: 'Filler, battery, textile mfr'
  },
  {
    Id: 19944,
    Code: '8112',
    Name: 'Filler, bobbin'
  },
  {
    Id: 19945,
    Code: '8112',
    Name: 'Filler, box, textile mfr'
  },
  {
    Id: 19946,
    Code: '8112',
    Name: 'Filler, braid, silk'
  },
  {
    Id: 19947,
    Code: '8112',
    Name: 'Filler, card'
  },
  {
    Id: 19948,
    Code: '8112',
    Name: "Filler, dresser's"
  },
  {
    Id: 19949,
    Code: '8112',
    Name: 'Filler, hand, silk weaving'
  },
  {
    Id: 19950,
    Code: '8112',
    Name: 'Filler, hopper, textile mfr'
  },
  {
    Id: 19951,
    Code: '8112',
    Name: 'Filler, machine, textile mfr'
  },
  {
    Id: 19952,
    Code: '8112',
    Name: 'Filler, magazine, looms'
  },
  {
    Id: 19953,
    Code: '8112',
    Name: 'Filler, rag'
  },
  {
    Id: 19954,
    Code: '8112',
    Name: 'Filler, scribble'
  },
  {
    Id: 19955,
    Code: '8112',
    Name: 'Filler, shuttle'
  },
  {
    Id: 19956,
    Code: '8112',
    Name: 'Filler, silk'
  },
  {
    Id: 19957,
    Code: '8112',
    Name: 'Filler, tin, textile mfr'
  },
  {
    Id: 19958,
    Code: '8112',
    Name: "Filler, weaver's"
  },
  {
    Id: 19959,
    Code: '8112',
    Name: 'Filler, textile mfr'
  },
  {
    Id: 19960,
    Code: '8112',
    Name: 'Finder, worsted, carpet, rug mfr'
  },
  {
    Id: 19961,
    Code: '8112',
    Name: 'Finisher, belt, textile mfr'
  },
  {
    Id: 19962,
    Code: '8112',
    Name: 'Finisher, blanket'
  },
  {
    Id: 19963,
    Code: '8112',
    Name: 'Finisher, bleach, textile mfr'
  },
  {
    Id: 19964,
    Code: '8112',
    Name: 'Finisher, can, worsted mfr'
  },
  {
    Id: 19965,
    Code: '8112',
    Name: 'Finisher, carpet'
  },
  {
    Id: 19966,
    Code: '8112',
    Name: 'Finisher, cloth'
  },
  {
    Id: 19967,
    Code: '8112',
    Name: 'Finisher, combing'
  },
  {
    Id: 19968,
    Code: '8112',
    Name: 'Finisher, fabric'
  },
  {
    Id: 19969,
    Code: '8112',
    Name: 'Finisher, fuse, safety'
  },
  {
    Id: 19970,
    Code: '8112',
    Name: 'Finisher, hosiery'
  },
  {
    Id: 19971,
    Code: '8112',
    Name: 'Finisher, lace'
  },
  {
    Id: 19972,
    Code: '8112',
    Name: 'Finisher, piece'
  },
  {
    Id: 19973,
    Code: '8112',
    Name: 'Finisher, plush'
  },
  {
    Id: 19974,
    Code: '8112',
    Name: 'Finisher, rug'
  },
  {
    Id: 19975,
    Code: '8112',
    Name: 'Finisher, silk'
  },
  {
    Id: 19976,
    Code: '8112',
    Name: 'Finisher, stove'
  },
  {
    Id: 19977,
    Code: '8112',
    Name: "Finisher, taper's"
  },
  {
    Id: 19978,
    Code: '8112',
    Name: 'Finisher, velvet'
  },
  {
    Id: 19979,
    Code: '8112',
    Name: 'Finisher, woollen'
  },
  {
    Id: 19980,
    Code: '8112',
    Name: 'Finisher, textile mfr'
  },
  {
    Id: 19981,
    Code: '8112',
    Name: 'Fitter, card, textile mfr'
  },
  {
    Id: 19982,
    Code: '8112',
    Name: 'Fitter, net'
  },
  {
    Id: 19983,
    Code: '8112',
    Name: 'Fitter, net, rope mfr'
  },
  {
    Id: 19984,
    Code: '8112',
    Name: 'Fixer, net'
  },
  {
    Id: 19985,
    Code: '8112',
    Name: 'Fixer, pattern, lace machine'
  },
  {
    Id: 19986,
    Code: '8112',
    Name: 'Fixer, net, rope mfr'
  },
  {
    Id: 19987,
    Code: '8112',
    Name: 'Flagger, fibre preparation'
  },
  {
    Id: 19988,
    Code: '8112',
    Name: 'Folder, cloth'
  },
  {
    Id: 19989,
    Code: '8112',
    Name: 'Folder, towel, textile mfr'
  },
  {
    Id: 19990,
    Code: '8112',
    Name: 'Folder, textile mfr'
  },
  {
    Id: 19991,
    Code: '8112',
    Name: 'Folder-up, textile mfr'
  },
  {
    Id: 19992,
    Code: '8112',
    Name: 'Forehand, rope, twine mfr'
  },
  {
    Id: 19993,
    Code: '8112',
    Name: 'Former, hat'
  },
  {
    Id: 19994,
    Code: '8112',
    Name: 'Former, lap'
  },
  {
    Id: 19995,
    Code: '8112',
    Name: 'Former, rope'
  },
  {
    Id: 19996,
    Code: '8112',
    Name: 'Former, strand'
  },
  {
    Id: 19997,
    Code: '8112',
    Name: 'Former, felt hat mfr'
  },
  {
    Id: 19998,
    Code: '8112',
    Name: 'Framer, ring'
  },
  {
    Id: 19999,
    Code: '8112',
    Name: 'Framer, textile mfr'
  },
  {
    Id: 20000,
    Code: '8112',
    Name: 'Fuller'
  },
  {
    Id: 20001,
    Code: '8112',
    Name: 'Gabler and corder, net'
  },
  {
    Id: 20002,
    Code: '8112',
    Name: 'Gaiter, beam'
  },
  {
    Id: 20003,
    Code: '8112',
    Name: 'Gaiter, warp'
  },
  {
    Id: 20004,
    Code: '8112',
    Name: 'Gaiter, textile spinning'
  },
  {
    Id: 20005,
    Code: '8112',
    Name: 'Garnetter'
  },
  {
    Id: 20006,
    Code: '8112',
    Name: 'Gasser'
  },
  {
    Id: 20007,
    Code: '8112',
    Name: 'Gigger, textile finishing'
  },
  {
    Id: 20008,
    Code: '8112',
    Name: 'Giller, textile mfr'
  },
  {
    Id: 20009,
    Code: '8112',
    Name: 'Gimper'
  },
  {
    Id: 20010,
    Code: '8112',
    Name: 'Glazer, textile mfr'
  },
  {
    Id: 20011,
    Code: '8112',
    Name: 'Glosser, silk'
  },
  {
    Id: 20012,
    Code: '8112',
    Name: 'Grinder, asbestos'
  },
  {
    Id: 20013,
    Code: '8112',
    Name: 'Grinder, cylinder, textile mfr'
  },
  {
    Id: 20014,
    Code: '8112',
    Name: 'Grinder, flat'
  },
  {
    Id: 20015,
    Code: '8112',
    Name: 'Grinder, flock'
  },
  {
    Id: 20016,
    Code: '8112',
    Name: 'Grinder, jute'
  },
  {
    Id: 20017,
    Code: '8112',
    Name: 'Grinder, rag'
  },
  {
    Id: 20018,
    Code: '8112',
    Name: 'Grinder, shoddy'
  },
  {
    Id: 20019,
    Code: '8112',
    Name: 'Grinder, textile mfr'
  },
  {
    Id: 20020,
    Code: '8112',
    Name: 'Guarder, net, rope mfr'
  },
  {
    Id: 20021,
    Code: '8112',
    Name: 'Guider-in'
  },
  {
    Id: 20022,
    Code: '8112',
    Name: 'Hackler'
  },
  {
    Id: 20023,
    Code: '8112',
    Name: 'Halsher, wool'
  },
  {
    Id: 20024,
    Code: '8112',
    Name: 'Hand, back, leathercloth mfr'
  },
  {
    Id: 20025,
    Code: '8112',
    Name: 'Hand, bank, rope, twine mfr'
  },
  {
    Id: 20026,
    Code: '8112',
    Name: 'Hand, calender, fabric'
  },
  {
    Id: 20027,
    Code: '8112',
    Name: 'Hand, calender, leathercloth mfr'
  },
  {
    Id: 20028,
    Code: '8112',
    Name: 'Hand, card, flax, hemp'
  },
  {
    Id: 20029,
    Code: '8112',
    Name: 'Hand, card and drawing, jute'
  },
  {
    Id: 20030,
    Code: '8112',
    Name: 'Hand, croft'
  },
  {
    Id: 20031,
    Code: '8112',
    Name: 'Hand, dolly'
  },
  {
    Id: 20032,
    Code: '8112',
    Name: 'Hand, drawing, textile mfr'
  },
  {
    Id: 20033,
    Code: '8112',
    Name: 'Hand, frame, hosiery, knitwear mfr'
  },
  {
    Id: 20034,
    Code: '8112',
    Name: 'Hand, glove, knitted gloves'
  },
  {
    Id: 20035,
    Code: '8112',
    Name: 'Hand, hosiery'
  },
  {
    Id: 20036,
    Code: '8112',
    Name: 'Hand, jobbing, rope, twine mfr'
  },
  {
    Id: 20037,
    Code: '8112',
    Name: 'Hand, mill, hair, fibre dressing'
  },
  {
    Id: 20038,
    Code: '8112',
    Name: 'Hand, mill, textile mfr'
  },
  {
    Id: 20039,
    Code: '8112',
    Name: 'Hand, net, braiding'
  },
  {
    Id: 20040,
    Code: '8112',
    Name: 'Hand, plate, rope, twine mfr'
  },
  {
    Id: 20041,
    Code: '8112',
    Name: 'Hand, room, blowing'
  },
  {
    Id: 20042,
    Code: '8112',
    Name: 'Hand, room, card'
  },
  {
    Id: 20043,
    Code: '8112',
    Name: 'Hand, stenter'
  },
  {
    Id: 20044,
    Code: '8112',
    Name: 'Handler, stone, broom, brush mfr'
  },
  {
    Id: 20045,
    Code: '8112',
    Name: 'Hanger, leathercloth mfr'
  },
  {
    Id: 20046,
    Code: '8112',
    Name: 'Hanker, textile mfr'
  },
  {
    Id: 20047,
    Code: '8112',
    Name: 'Hardener, felt'
  },
  {
    Id: 20048,
    Code: '8112',
    Name: 'Hardener, wool, hats'
  },
  {
    Id: 20049,
    Code: '8112',
    Name: 'Hardener, hats'
  },
  {
    Id: 20050,
    Code: '8112',
    Name: 'Healder'
  },
  {
    Id: 20051,
    Code: '8112',
    Name: "Helper, examiner's, net, rope mfr"
  },
  {
    Id: 20052,
    Code: '8112',
    Name: "Helper, weaver's"
  },
  {
    Id: 20053,
    Code: '8112',
    Name: 'Helper, textile mfr: textile weaving'
  },
  {
    Id: 20054,
    Code: '8112',
    Name: 'Helper, textile mfr'
  },
  {
    Id: 20055,
    Code: '8112',
    Name: 'Hooker, textile mfr'
  },
  {
    Id: 20056,
    Code: '8112',
    Name: 'Hydro-extractor'
  },
  {
    Id: 20057,
    Code: '8112',
    Name: 'Improver, wool spinning'
  },
  {
    Id: 20058,
    Code: '8112',
    Name: 'Ingiver'
  },
  {
    Id: 20059,
    Code: '8112',
    Name: 'Inker, ribbon, typewriter'
  },
  {
    Id: 20060,
    Code: '8112',
    Name: 'Interlacer, hair and fibre'
  },
  {
    Id: 20061,
    Code: '8112',
    Name: 'Joiner, textile'
  },
  {
    Id: 20062,
    Code: '8112',
    Name: 'Joiner, textile mfr'
  },
  {
    Id: 20063,
    Code: '8112',
    Name: 'Jumper, fibre'
  },
  {
    Id: 20064,
    Code: '8112',
    Name: 'Knotter, reel'
  },
  {
    Id: 20065,
    Code: '8112',
    Name: 'Knotter, warp'
  },
  {
    Id: 20066,
    Code: '8112',
    Name: 'Knotter, textile mfr'
  },
  {
    Id: 20067,
    Code: '8112',
    Name: 'Labourer, millhouse, textile mfr'
  },
  {
    Id: 20068,
    Code: '8112',
    Name: 'Lacer, card'
  },
  {
    Id: 20069,
    Code: '8112',
    Name: 'Lacer, jacquard'
  },
  {
    Id: 20070,
    Code: '8112',
    Name: 'Lacer, textile weaving'
  },
  {
    Id: 20071,
    Code: '8112',
    Name: 'Laminator, textile mfr'
  },
  {
    Id: 20072,
    Code: '8112',
    Name: 'Lapman'
  },
  {
    Id: 20073,
    Code: '8112',
    Name: 'Lapper, cotton'
  },
  {
    Id: 20074,
    Code: '8112',
    Name: 'Lapper, tape'
  },
  {
    Id: 20075,
    Code: '8112',
    Name: 'Lapper, textile mfr'
  },
  {
    Id: 20076,
    Code: '8112',
    Name: 'Lasher'
  },
  {
    Id: 20077,
    Code: '8112',
    Name: 'Layer, bobbin'
  },
  {
    Id: 20078,
    Code: '8112',
    Name: 'Layer, drum'
  },
  {
    Id: 20079,
    Code: '8112',
    Name: 'Layer, rope, twine mfr'
  },
  {
    Id: 20080,
    Code: '8112',
    Name: 'Layer, textile spinning'
  },
  {
    Id: 20081,
    Code: '8112',
    Name: 'Layer-on, textile mfr'
  },
  {
    Id: 20082,
    Code: '8112',
    Name: 'Layer-out, yarn'
  },
  {
    Id: 20083,
    Code: '8112',
    Name: 'Leaser, textile mfr'
  },
  {
    Id: 20084,
    Code: '8112',
    Name: 'Legger, hosiery finishing'
  },
  {
    Id: 20085,
    Code: '8112',
    Name: 'Lengthener'
  },
  {
    Id: 20086,
    Code: '8112',
    Name: 'Lifter, beam'
  },
  {
    Id: 20087,
    Code: '8112',
    Name: 'Lifter, textile mfr'
  },
  {
    Id: 20088,
    Code: '8112',
    Name: 'Ligger'
  },
  {
    Id: 20089,
    Code: '8112',
    Name: 'Ligger-on, wool'
  },
  {
    Id: 20090,
    Code: '8112',
    Name: 'Linger'
  },
  {
    Id: 20091,
    Code: '8112',
    Name: 'Linker, textile mfr'
  },
  {
    Id: 20092,
    Code: '8112',
    Name: 'Loomer'
  },
  {
    Id: 20093,
    Code: '8112',
    Name: 'Loomer and twister'
  },
  {
    Id: 20094,
    Code: '8112',
    Name: 'Looper, wool spinning'
  },
  {
    Id: 20095,
    Code: '8112',
    Name: 'Machinist, ageing'
  },
  {
    Id: 20096,
    Code: '8112',
    Name: 'Machinist, ball'
  },
  {
    Id: 20097,
    Code: '8112',
    Name: 'Machinist, balling, textile mfr'
  },
  {
    Id: 20098,
    Code: '8112',
    Name: 'Machinist, beaming, textile weaving'
  },
  {
    Id: 20099,
    Code: '8112',
    Name: 'Machinist, blowing'
  },
  {
    Id: 20100,
    Code: '8112',
    Name: 'Machinist, bobbin'
  },
  {
    Id: 20101,
    Code: '8112',
    Name: 'Machinist, braid'
  },
  {
    Id: 20102,
    Code: '8112',
    Name: 'Machinist, braiding, asbestos rope mfr'
  },
  {
    Id: 20103,
    Code: '8112',
    Name: 'Machinist, breadthening'
  },
  {
    Id: 20104,
    Code: '8112',
    Name: 'Machinist, brushing, cross'
  },
  {
    Id: 20105,
    Code: '8112',
    Name: 'Machinist, brushing, textile mfr'
  },
  {
    Id: 20106,
    Code: '8112',
    Name: 'Machinist, buffing, leathercloth mfr'
  },
  {
    Id: 20107,
    Code: '8112',
    Name: 'Machinist, bullion'
  },
  {
    Id: 20108,
    Code: '8112',
    Name: 'Machinist, bumping'
  },
  {
    Id: 20109,
    Code: '8112',
    Name: 'Machinist, cabling, rope, twine mfr'
  },
  {
    Id: 20110,
    Code: '8112',
    Name: 'Machinist, carding'
  },
  {
    Id: 20111,
    Code: '8112',
    Name: 'Machinist, closing, rope, twine mfr'
  },
  {
    Id: 20112,
    Code: '8112',
    Name: 'Machinist, coating, carpet, rug mfr'
  },
  {
    Id: 20113,
    Code: '8112',
    Name: 'Machinist, coating, textile mfr'
  },
  {
    Id: 20114,
    Code: '8112',
    Name: 'Machinist, combing, textile spinning'
  },
  {
    Id: 20115,
    Code: '8112',
    Name: 'Machinist, condenser, textile mfr'
  },
  {
    Id: 20116,
    Code: '8112',
    Name: 'Machinist, cropping'
  },
  {
    Id: 20117,
    Code: '8112',
    Name: 'Machinist, cutting, textile mfr'
  },
  {
    Id: 20118,
    Code: '8112',
    Name: 'Machinist, cuttling'
  },
  {
    Id: 20119,
    Code: '8112',
    Name: 'Machinist, damping, textile mfr'
  },
  {
    Id: 20120,
    Code: '8112',
    Name: 'Machinist, doubling'
  },
  {
    Id: 20121,
    Code: '8112',
    Name: 'Machinist, drawing, textile mfr'
  },
  {
    Id: 20122,
    Code: '8112',
    Name: 'Machinist, dressing, fibre'
  },
  {
    Id: 20123,
    Code: '8112',
    Name: 'Machinist, drying, cloth'
  },
  {
    Id: 20124,
    Code: '8112',
    Name: 'Machinist, drying, textile mfr'
  },
  {
    Id: 20125,
    Code: '8112',
    Name: 'Machinist, felt, needleloom'
  },
  {
    Id: 20126,
    Code: '8112',
    Name: 'Machinist, finisher, textile mfr'
  },
  {
    Id: 20127,
    Code: '8112',
    Name: 'Machinist, flock'
  },
  {
    Id: 20128,
    Code: '8112',
    Name: 'Machinist, folding, textile mfr'
  },
  {
    Id: 20129,
    Code: '8112',
    Name: 'Machinist, forming, twine mfr'
  },
  {
    Id: 20130,
    Code: '8112',
    Name: 'Machinist, garnet'
  },
  {
    Id: 20131,
    Code: '8112',
    Name: 'Machinist, gilling'
  },
  {
    Id: 20132,
    Code: '8112',
    Name: 'Machinist, gimping, pattern cards'
  },
  {
    Id: 20133,
    Code: '8112',
    Name: 'Machinist, hair, horse'
  },
  {
    Id: 20134,
    Code: '8112',
    Name: 'Machinist, hanking'
  },
  {
    Id: 20135,
    Code: '8112',
    Name: 'Machinist, hardening'
  },
  {
    Id: 20136,
    Code: '8112',
    Name: 'Machinist, healding'
  },
  {
    Id: 20137,
    Code: '8112',
    Name: 'Machinist, hopper'
  },
  {
    Id: 20138,
    Code: '8112',
    Name: 'Machinist, house, twine mfr'
  },
  {
    Id: 20139,
    Code: '8112',
    Name: 'Machinist, knotting, warp'
  },
  {
    Id: 20140,
    Code: '8112',
    Name: 'Machinist, knotting, textile mfr'
  },
  {
    Id: 20141,
    Code: '8112',
    Name: 'Machinist, lap'
  },
  {
    Id: 20142,
    Code: '8112',
    Name: 'Machinist, lapping, textile mfr'
  },
  {
    Id: 20143,
    Code: '8112',
    Name: 'Machinist, looming'
  },
  {
    Id: 20144,
    Code: '8112',
    Name: 'Machinist, medicating, surgical dressings'
  },
  {
    Id: 20145,
    Code: '8112',
    Name: 'Machinist, opening, asbestos opening'
  },
  {
    Id: 20146,
    Code: '8112',
    Name: 'Machinist, perpetual'
  },
  {
    Id: 20147,
    Code: '8112',
    Name: 'Machinist, pickering, textile mfr'
  },
  {
    Id: 20148,
    Code: '8112',
    Name: 'Machinist, piping, textile smallwares mfr'
  },
  {
    Id: 20149,
    Code: '8112',
    Name: 'Machinist, plaiting, rope, twine mfr'
  },
  {
    Id: 20150,
    Code: '8112',
    Name: 'Machinist, polishing, velvet mfr'
  },
  {
    Id: 20151,
    Code: '8112',
    Name: 'Machinist, preparing'
  },
  {
    Id: 20152,
    Code: '8112',
    Name: 'Machinist, pressing, textile mfr'
  },
  {
    Id: 20153,
    Code: '8112',
    Name: 'Machinist, pulling, rag'
  },
  {
    Id: 20154,
    Code: '8112',
    Name: 'Machinist, ragging, cotton rag'
  },
  {
    Id: 20155,
    Code: '8112',
    Name: 'Machinist, raising'
  },
  {
    Id: 20156,
    Code: '8112',
    Name: 'Machinist, randing'
  },
  {
    Id: 20157,
    Code: '8112',
    Name: 'Machinist, ribbon, typewriter'
  },
  {
    Id: 20158,
    Code: '8112',
    Name: 'Machinist, ring'
  },
  {
    Id: 20159,
    Code: '8112',
    Name: 'Machinist, roller, textile mfr'
  },
  {
    Id: 20160,
    Code: '8112',
    Name: 'Machinist, rolling, textile mfr'
  },
  {
    Id: 20161,
    Code: '8112',
    Name: 'Machinist, rope, textile'
  },
  {
    Id: 20162,
    Code: '8112',
    Name: 'Machinist, running-down'
  },
  {
    Id: 20163,
    Code: '8112',
    Name: 'Machinist, scouring, textile mfr'
  },
  {
    Id: 20164,
    Code: '8112',
    Name: 'Machinist, scutcher'
  },
  {
    Id: 20165,
    Code: '8112',
    Name: 'Machinist, shearing, textile mfr'
  },
  {
    Id: 20166,
    Code: '8112',
    Name: 'Machinist, shrinking, hood, felt'
  },
  {
    Id: 20167,
    Code: '8112',
    Name: 'Machinist, shrinking'
  },
  {
    Id: 20168,
    Code: '8112',
    Name: 'Machinist, singeing'
  },
  {
    Id: 20169,
    Code: '8112',
    Name: 'Machinist, sizing, textile mfr'
  },
  {
    Id: 20170,
    Code: '8112',
    Name: 'Machinist, slitting and winding, textile mfr'
  },
  {
    Id: 20171,
    Code: '8112',
    Name: 'Machinist, souring'
  },
  {
    Id: 20172,
    Code: '8112',
    Name: 'Machinist, spanishing, leathercloth mfr'
  },
  {
    Id: 20173,
    Code: '8112',
    Name: 'Machinist, spinning, textiles'
  },
  {
    Id: 20174,
    Code: '8112',
    Name: 'Machinist, spooling, yarn'
  },
  {
    Id: 20175,
    Code: '8112',
    Name: 'Machinist, spreader'
  },
  {
    Id: 20176,
    Code: '8112',
    Name: 'Machinist, stentering'
  },
  {
    Id: 20177,
    Code: '8112',
    Name: 'Machinist, stiffening'
  },
  {
    Id: 20178,
    Code: '8112',
    Name: 'Machinist, stranding, rope, twine mfr'
  },
  {
    Id: 20179,
    Code: '8112',
    Name: 'Machinist, stretching'
  },
  {
    Id: 20180,
    Code: '8112',
    Name: 'Machinist, tenterer'
  },
  {
    Id: 20181,
    Code: '8112',
    Name: 'Machinist, textile'
  },
  {
    Id: 20182,
    Code: '8112',
    Name: 'Machinist, tube, silk mfr'
  },
  {
    Id: 20183,
    Code: '8112',
    Name: 'Machinist, tufting, carpet, rug mfr'
  },
  {
    Id: 20184,
    Code: '8112',
    Name: 'Machinist, twisting, textile mfr'
  },
  {
    Id: 20185,
    Code: '8112',
    Name: 'Machinist, tying, warp'
  },
  {
    Id: 20186,
    Code: '8112',
    Name: 'Machinist, velvet'
  },
  {
    Id: 20187,
    Code: '8112',
    Name: 'Machinist, warping'
  },
  {
    Id: 20188,
    Code: '8112',
    Name: 'Machinist, washing, textile mfr'
  },
  {
    Id: 20189,
    Code: '8112',
    Name: 'Machinist, winding, textile mfr'
  },
  {
    Id: 20190,
    Code: '8112',
    Name: "Machinist, hatters' fur mfr"
  },
  {
    Id: 20191,
    Code: '8112',
    Name: 'Machinist, leathercloth mfr'
  },
  {
    Id: 20192,
    Code: '8112',
    Name: 'Machinist, rope, twine mfr'
  },
  {
    Id: 20193,
    Code: '8112',
    Name: 'Machinist, textile mfr'
  },
  {
    Id: 20194,
    Code: '8112',
    Name: 'Maker, brayle'
  },
  {
    Id: 20195,
    Code: '8112',
    Name: 'Maker, cable, rope'
  },
  {
    Id: 20196,
    Code: '8112',
    Name: 'Maker, candlewick'
  },
  {
    Id: 20197,
    Code: '8112',
    Name: 'Maker, chain, textile mfr'
  },
  {
    Id: 20198,
    Code: '8112',
    Name: 'Maker, chenille'
  },
  {
    Id: 20199,
    Code: '8112',
    Name: 'Maker, cord'
  },
  {
    Id: 20200,
    Code: '8112',
    Name: 'Maker, core, rope, twine mfr'
  },
  {
    Id: 20201,
    Code: '8112',
    Name: 'Maker, felt'
  },
  {
    Id: 20202,
    Code: '8112',
    Name: "Maker, fender, ship's"
  },
  {
    Id: 20203,
    Code: '8112',
    Name: 'Maker, gimp'
  },
  {
    Id: 20204,
    Code: '8112',
    Name: 'Maker, gromet'
  },
  {
    Id: 20205,
    Code: '8112',
    Name: 'Maker, grommet'
  },
  {
    Id: 20206,
    Code: '8112',
    Name: 'Maker, halter, rope'
  },
  {
    Id: 20207,
    Code: '8112',
    Name: 'Maker, jacquard'
  },
  {
    Id: 20208,
    Code: '8112',
    Name: 'Maker, lanyard'
  },
  {
    Id: 20209,
    Code: '8112',
    Name: 'Maker, line'
  },
  {
    Id: 20210,
    Code: '8112',
    Name: 'Maker, mantlet, rope'
  },
  {
    Id: 20211,
    Code: '8112',
    Name: 'Maker, pattern, jacquard card cutting'
  },
  {
    Id: 20212,
    Code: '8112',
    Name: 'Maker, ribbon, typewriter'
  },
  {
    Id: 20213,
    Code: '8112',
    Name: 'Maker, rope'
  },
  {
    Id: 20214,
    Code: '8112',
    Name: 'Maker, string'
  },
  {
    Id: 20215,
    Code: '8112',
    Name: 'Maker, thread'
  },
  {
    Id: 20216,
    Code: '8112',
    Name: 'Maker, top, textile mfr'
  },
  {
    Id: 20217,
    Code: '8112',
    Name: 'Maker, trimming, tinsel mfr'
  },
  {
    Id: 20218,
    Code: '8112',
    Name: 'Maker, trimmings, tinsel mfr'
  },
  {
    Id: 20219,
    Code: '8112',
    Name: 'Maker, twine'
  },
  {
    Id: 20220,
    Code: '8112',
    Name: 'Maker, wadding'
  },
  {
    Id: 20221,
    Code: '8112',
    Name: 'Maker-up, textile mfr'
  },
  {
    Id: 20222,
    Code: '8112',
    Name: 'Malter'
  },
  {
    Id: 20223,
    Code: '8112',
    Name: 'Man, back, textile mfr'
  },
  {
    Id: 20224,
    Code: '8112',
    Name: 'Man, boiling-off'
  },
  {
    Id: 20225,
    Code: '8112',
    Name: 'Man, burden'
  },
  {
    Id: 20226,
    Code: '8112',
    Name: 'Man, burr'
  },
  {
    Id: 20227,
    Code: '8112',
    Name: 'Man, coating, roofing felt mfr'
  },
  {
    Id: 20228,
    Code: '8112',
    Name: 'Man, end, back, textile mfr'
  },
  {
    Id: 20229,
    Code: '8112',
    Name: 'Man, extractor, textile mfr'
  },
  {
    Id: 20230,
    Code: '8112',
    Name: 'Man, fibre, asbestos-cement goods mfr'
  },
  {
    Id: 20231,
    Code: '8112',
    Name: 'Man, frame, rope, twine mfr'
  },
  {
    Id: 20232,
    Code: '8112',
    Name: 'Man, heald'
  },
  {
    Id: 20233,
    Code: '8112',
    Name: 'Man, hydro, textile mfr'
  },
  {
    Id: 20234,
    Code: '8112',
    Name: 'Man, mill, textile finishing'
  },
  {
    Id: 20235,
    Code: '8112',
    Name: 'Man, mule'
  },
  {
    Id: 20236,
    Code: '8112',
    Name: 'Man, room, card'
  },
  {
    Id: 20237,
    Code: '8112',
    Name: 'Man, room, carding'
  },
  {
    Id: 20238,
    Code: '8112',
    Name: 'Man, shoddy'
  },
  {
    Id: 20239,
    Code: '8112',
    Name: 'Man, stenter'
  },
  {
    Id: 20240,
    Code: '8112',
    Name: 'Man, tool, fustian, velvet mfr'
  },
  {
    Id: 20241,
    Code: '8112',
    Name: 'Man, waste, wool'
  },
  {
    Id: 20242,
    Code: '8112',
    Name: 'Man, yarn'
  },
  {
    Id: 20243,
    Code: '8112',
    Name: 'Manufacturer, textile'
  },
  {
    Id: 20244,
    Code: '8112',
    Name: 'Manufacturer, rope, twine mfr'
  },
  {
    Id: 20245,
    Code: '8112',
    Name: 'Manufacturer, textile mfr'
  },
  {
    Id: 20246,
    Code: '8112',
    Name: 'Matcher, colour, leathercloth mfr'
  },
  {
    Id: 20247,
    Code: '8112',
    Name: 'Matcher, colour, textile mfr'
  },
  {
    Id: 20248,
    Code: '8112',
    Name: 'Matcher, yarn'
  },
  {
    Id: 20249,
    Code: '8112',
    Name: "Mate, splicer's, rope"
  },
  {
    Id: 20250,
    Code: '8112',
    Name: "Mate, weaver's"
  },
  {
    Id: 20251,
    Code: '8112',
    Name: 'Merceriser'
  },
  {
    Id: 20252,
    Code: '8112',
    Name: 'Miller, blanket'
  },
  {
    Id: 20253,
    Code: '8112',
    Name: 'Miller, cloth'
  },
  {
    Id: 20254,
    Code: '8112',
    Name: 'Miller, dry, textile mfr'
  },
  {
    Id: 20255,
    Code: '8112',
    Name: 'Miller, felt'
  },
  {
    Id: 20256,
    Code: '8112',
    Name: 'Miller, woollen'
  },
  {
    Id: 20257,
    Code: '8112',
    Name: 'Miller, hair, fibre dressing'
  },
  {
    Id: 20258,
    Code: '8112',
    Name: 'Miller, textile mfr'
  },
  {
    Id: 20259,
    Code: '8112',
    Name: 'Milner, textile finishing'
  },
  {
    Id: 20260,
    Code: '8112',
    Name: 'Minder, back, textile mfr'
  },
  {
    Id: 20261,
    Code: '8112',
    Name: 'Minder, backwash'
  },
  {
    Id: 20262,
    Code: '8112',
    Name: 'Minder, bowl, scouring'
  },
  {
    Id: 20263,
    Code: '8112',
    Name: 'Minder, bowl, wash, textile mfr'
  },
  {
    Id: 20264,
    Code: '8112',
    Name: 'Minder, box, textile mfr'
  },
  {
    Id: 20265,
    Code: '8112',
    Name: 'Minder, can'
  },
  {
    Id: 20266,
    Code: '8112',
    Name: 'Minder, card'
  },
  {
    Id: 20267,
    Code: '8112',
    Name: 'Minder, carding'
  },
  {
    Id: 20268,
    Code: '8112',
    Name: 'Minder, comb'
  },
  {
    Id: 20269,
    Code: '8112',
    Name: 'Minder, condenser'
  },
  {
    Id: 20270,
    Code: '8112',
    Name: 'Minder, copper, straw plait'
  },
  {
    Id: 20271,
    Code: '8112',
    Name: 'Minder, finisher, blowing room'
  },
  {
    Id: 20272,
    Code: '8112',
    Name: 'Minder, frame, cheesing'
  },
  {
    Id: 20273,
    Code: '8112',
    Name: 'Minder, frame, copping'
  },
  {
    Id: 20274,
    Code: '8112',
    Name: 'Minder, frame, lap, silk'
  },
  {
    Id: 20275,
    Code: '8112',
    Name: 'Minder, frame, roving, jute'
  },
  {
    Id: 20276,
    Code: '8112',
    Name: 'Minder, frame, slubbing'
  },
  {
    Id: 20277,
    Code: '8112',
    Name: 'Minder, frame'
  },
  {
    Id: 20278,
    Code: '8112',
    Name: 'Minder, front'
  },
  {
    Id: 20279,
    Code: '8112',
    Name: 'Minder, head, balling'
  },
  {
    Id: 20280,
    Code: '8112',
    Name: 'Minder, head'
  },
  {
    Id: 20281,
    Code: '8112',
    Name: 'Minder, jack'
  },
  {
    Id: 20282,
    Code: '8112',
    Name: 'Minder, joiner'
  },
  {
    Id: 20283,
    Code: '8112',
    Name: 'Minder, motion'
  },
  {
    Id: 20284,
    Code: '8112',
    Name: 'Minder, mule'
  },
  {
    Id: 20285,
    Code: '8112',
    Name: 'Minder, punch'
  },
  {
    Id: 20286,
    Code: '8112',
    Name: 'Minder, reducer, wool drawing'
  },
  {
    Id: 20287,
    Code: '8112',
    Name: 'Minder, rover'
  },
  {
    Id: 20288,
    Code: '8112',
    Name: 'Minder, roving'
  },
  {
    Id: 20289,
    Code: '8112',
    Name: 'Minder, scribbling'
  },
  {
    Id: 20290,
    Code: '8112',
    Name: 'Minder, scutcher'
  },
  {
    Id: 20291,
    Code: '8112',
    Name: 'Minder, side'
  },
  {
    Id: 20292,
    Code: '8112',
    Name: 'Minder, stenter'
  },
  {
    Id: 20293,
    Code: '8112',
    Name: 'Minder, twister, wool'
  },
  {
    Id: 20294,
    Code: '8112',
    Name: 'Mixer, bristle'
  },
  {
    Id: 20295,
    Code: '8112',
    Name: 'Mixer, cotton'
  },
  {
    Id: 20296,
    Code: '8112',
    Name: 'Mixer, fibre, fur'
  },
  {
    Id: 20297,
    Code: '8112',
    Name: 'Mixer, size, textile mfr'
  },
  {
    Id: 20298,
    Code: '8112',
    Name: 'Mixer, wool'
  },
  {
    Id: 20299,
    Code: '8112',
    Name: 'Mixer, asbestos composition goods mfr'
  },
  {
    Id: 20300,
    Code: '8112',
    Name: 'Mixer, felt hood mfr'
  },
  {
    Id: 20301,
    Code: '8112',
    Name: 'Mixer, textile mfr'
  },
  {
    Id: 20302,
    Code: '8112',
    Name: 'Moulder, felt lining mfr'
  },
  {
    Id: 20303,
    Code: '8112',
    Name: 'Mounter, card, pattern'
  },
  {
    Id: 20304,
    Code: '8112',
    Name: 'Mounter, net, rope mfr'
  },
  {
    Id: 20305,
    Code: '8112',
    Name: 'Needler, textile making-up'
  },
  {
    Id: 20306,
    Code: '8112',
    Name: 'Netter, hosiery, knitwear mfr'
  },
  {
    Id: 20307,
    Code: '8112',
    Name: 'Neutraliser, textile mfr'
  },
  {
    Id: 20308,
    Code: '8112',
    Name: 'Norseller, net'
  },
  {
    Id: 20309,
    Code: '8112',
    Name: 'Nosseller, net'
  },
  {
    Id: 20310,
    Code: '8112',
    Name: 'Oiler, silk'
  },
  {
    Id: 20311,
    Code: '8112',
    Name: 'Oiler, wool'
  },
  {
    Id: 20312,
    Code: '8112',
    Name: 'Oiler, canvas goods mfr'
  },
  {
    Id: 20313,
    Code: '8112',
    Name: 'Oiler and cleaner, textile mfr'
  },
  {
    Id: 20314,
    Code: '8112',
    Name: 'Opener, asbestos'
  },
  {
    Id: 20315,
    Code: '8112',
    Name: 'Opener, bale, opening dept, textile mfr'
  },
  {
    Id: 20316,
    Code: '8112',
    Name: 'Opener, fibre'
  },
  {
    Id: 20317,
    Code: '8112',
    Name: 'Opener, piece, textile mfr'
  },
  {
    Id: 20318,
    Code: '8112',
    Name: 'Opener, textile mfr'
  },
  {
    Id: 20319,
    Code: '8112',
    Name: 'Operative, carding, textile'
  },
  {
    Id: 20320,
    Code: '8112',
    Name: 'Operative, combing, textile mfr'
  },
  {
    Id: 20321,
    Code: '8112',
    Name: 'Operative, cotton'
  },
  {
    Id: 20322,
    Code: '8112',
    Name: 'Operative, drawtwist, nylon'
  },
  {
    Id: 20323,
    Code: '8112',
    Name: 'Operative, general, textile mfr'
  },
  {
    Id: 20324,
    Code: '8112',
    Name: 'Operative, hosiery'
  },
  {
    Id: 20325,
    Code: '8112',
    Name: 'Operative, room, blowing'
  },
  {
    Id: 20326,
    Code: '8112',
    Name: 'Operative, room, card'
  },
  {
    Id: 20327,
    Code: '8112',
    Name: 'Operative, textile'
  },
  {
    Id: 20328,
    Code: '8112',
    Name: 'Operative, weaving'
  },
  {
    Id: 20329,
    Code: '8112',
    Name: 'Operator, calender, textile mfr'
  },
  {
    Id: 20330,
    Code: '8112',
    Name: 'Operator, card, cotton'
  },
  {
    Id: 20331,
    Code: '8112',
    Name: 'Operator, drawtwist'
  },
  {
    Id: 20332,
    Code: '8112',
    Name: 'Operator, frame, spinning'
  },
  {
    Id: 20333,
    Code: '8112',
    Name: 'Operator, garnett'
  },
  {
    Id: 20334,
    Code: '8112',
    Name: 'Operator, guillotine, pressed woollen felt mfr'
  },
  {
    Id: 20335,
    Code: '8112',
    Name: 'Operator, hydro, textile finishing'
  },
  {
    Id: 20336,
    Code: '8112',
    Name: 'Operator, hydro-extractor'
  },
  {
    Id: 20337,
    Code: '8112',
    Name: 'Operator, jig, textile mfr'
  },
  {
    Id: 20338,
    Code: '8112',
    Name: 'Operator, knife, band, textile mfr'
  },
  {
    Id: 20339,
    Code: '8112',
    Name: 'Operator, lathe, industrial felt mfr'
  },
  {
    Id: 20340,
    Code: '8112',
    Name: 'Operator, loom'
  },
  {
    Id: 20341,
    Code: '8112',
    Name: 'Operator, multi-roller, hats'
  },
  {
    Id: 20342,
    Code: '8112',
    Name: 'Operator, ring'
  },
  {
    Id: 20343,
    Code: '8112',
    Name: 'Operator, stenter'
  },
  {
    Id: 20344,
    Code: '8112',
    Name: 'Operator, twisting'
  },
  {
    Id: 20345,
    Code: '8112',
    Name: 'Operator, unit, textile finishing'
  },
  {
    Id: 20346,
    Code: '8112',
    Name: 'Operator, willey, wool'
  },
  {
    Id: 20347,
    Code: '8112',
    Name: 'Operator, winder, fibreglass'
  },
  {
    Id: 20348,
    Code: '8112',
    Name: 'Orseller, net'
  },
  {
    Id: 20349,
    Code: '8112',
    Name: 'Osseller, net'
  },
  {
    Id: 20350,
    Code: '8112',
    Name: 'Overhauler, rag sorting'
  },
  {
    Id: 20351,
    Code: '8112',
    Name: 'Packer, shoddy'
  },
  {
    Id: 20352,
    Code: '8112',
    Name: 'Packman, woollen carding'
  },
  {
    Id: 20353,
    Code: '8112',
    Name: 'Padder, textile mfr'
  },
  {
    Id: 20354,
    Code: '8112',
    Name: 'Pegger, barrel, textile mfr'
  },
  {
    Id: 20355,
    Code: '8112',
    Name: 'Pegger, bobbin'
  },
  {
    Id: 20356,
    Code: '8112',
    Name: 'Pegger, card'
  },
  {
    Id: 20357,
    Code: '8112',
    Name: 'Pegger, dobby'
  },
  {
    Id: 20358,
    Code: '8112',
    Name: 'Pegger, textile weaving'
  },
  {
    Id: 20359,
    Code: '8112',
    Name: 'Penciller, textile mfr'
  },
  {
    Id: 20360,
    Code: '8112',
    Name: 'Perforator, card, jacquard card cutting'
  },
  {
    Id: 20361,
    Code: '8112',
    Name: 'Perforator, jacquard'
  },
  {
    Id: 20362,
    Code: '8112',
    Name: 'Perforator, jacquard card cutting'
  },
  {
    Id: 20363,
    Code: '8112',
    Name: 'Picker, bobbin, textile mfr'
  },
  {
    Id: 20364,
    Code: '8112',
    Name: 'Picker, roller'
  },
  {
    Id: 20365,
    Code: '8112',
    Name: 'Picker, silk'
  },
  {
    Id: 20366,
    Code: '8112',
    Name: 'Picker, textile mfr: silk throwing'
  },
  {
    Id: 20367,
    Code: '8112',
    Name: 'Picker-up, textile mfr'
  },
  {
    Id: 20368,
    Code: '8112',
    Name: 'Pickler, cloth'
  },
  {
    Id: 20369,
    Code: '8112',
    Name: 'Pickler, textile mfr'
  },
  {
    Id: 20370,
    Code: '8112',
    Name: 'Piecener'
  },
  {
    Id: 20371,
    Code: '8112',
    Name: 'Piecer, cotton'
  },
  {
    Id: 20372,
    Code: '8112',
    Name: 'Piecer, cross'
  },
  {
    Id: 20373,
    Code: '8112',
    Name: 'Piecer, mule'
  },
  {
    Id: 20374,
    Code: '8112',
    Name: 'Piecer, ring'
  },
  {
    Id: 20375,
    Code: '8112',
    Name: 'Piecer, side'
  },
  {
    Id: 20376,
    Code: '8112',
    Name: 'Piecer, silk'
  },
  {
    Id: 20377,
    Code: '8112',
    Name: 'Piecer, twiner'
  },
  {
    Id: 20378,
    Code: '8112',
    Name: 'Piecer, waste'
  },
  {
    Id: 20379,
    Code: '8112',
    Name: 'Piecer, textile mfr'
  },
  {
    Id: 20380,
    Code: '8112',
    Name: 'Piecer-out, flax, hemp mfr'
  },
  {
    Id: 20381,
    Code: '8112',
    Name: 'Piler, bobbin'
  },
  {
    Id: 20382,
    Code: '8112',
    Name: 'Piler, roving'
  },
  {
    Id: 20383,
    Code: '8112',
    Name: 'Pinner, textile mfr'
  },
  {
    Id: 20384,
    Code: '8112',
    Name: 'Pinner-on, textile mfr'
  },
  {
    Id: 20385,
    Code: '8112',
    Name: 'Plaiter, cordage'
  },
  {
    Id: 20386,
    Code: '8112',
    Name: 'Plaiter, textile mfr'
  },
  {
    Id: 20387,
    Code: '8112',
    Name: 'Planker'
  },
  {
    Id: 20388,
    Code: '8112',
    Name: 'Plater, hot'
  },
  {
    Id: 20389,
    Code: '8112',
    Name: 'Plater, textile mfr'
  },
  {
    Id: 20390,
    Code: '8112',
    Name: 'Plater-down, textile making-up'
  },
  {
    Id: 20391,
    Code: '8112',
    Name: 'Pleater, accordion'
  },
  {
    Id: 20392,
    Code: '8112',
    Name: 'Pleater, cloth'
  },
  {
    Id: 20393,
    Code: '8112',
    Name: 'Pleater, textile mfr'
  },
  {
    Id: 20394,
    Code: '8112',
    Name: 'Polisher, fibre'
  },
  {
    Id: 20395,
    Code: '8112',
    Name: 'Polisher, twine'
  },
  {
    Id: 20396,
    Code: '8112',
    Name: 'Polisher, yarn'
  },
  {
    Id: 20397,
    Code: '8112',
    Name: 'Polisher, straw hat mfr'
  },
  {
    Id: 20398,
    Code: '8112',
    Name: 'Polisher, textile mfr'
  },
  {
    Id: 20399,
    Code: '8112',
    Name: 'Pre-boarder, hosiery, knitwear mfr'
  },
  {
    Id: 20400,
    Code: '8112',
    Name: 'Preparer, hair'
  },
  {
    Id: 20401,
    Code: '8112',
    Name: 'Preparer, jute'
  },
  {
    Id: 20402,
    Code: '8112',
    Name: 'Preparer, warp'
  },
  {
    Id: 20403,
    Code: '8112',
    Name: 'Preparer, yarn'
  },
  {
    Id: 20404,
    Code: '8112',
    Name: 'Preparer, textile mfr'
  },
  {
    Id: 20405,
    Code: '8112',
    Name: 'Presser, cloth'
  },
  {
    Id: 20406,
    Code: '8112',
    Name: 'Presser, flat, pressed woollen felt mfr'
  },
  {
    Id: 20407,
    Code: '8112',
    Name: 'Presser, hosiery'
  },
  {
    Id: 20408,
    Code: '8112',
    Name: 'Presser, hot, textile finishing'
  },
  {
    Id: 20409,
    Code: '8112',
    Name: 'Presser, hydraulic, textile mfr'
  },
  {
    Id: 20410,
    Code: '8112',
    Name: 'Presser, machine, textile mfr'
  },
  {
    Id: 20411,
    Code: '8112',
    Name: 'Presser, rotary, textile mfr'
  },
  {
    Id: 20412,
    Code: '8112',
    Name: 'Presser, stuff'
  },
  {
    Id: 20413,
    Code: '8112',
    Name: 'Presser, yarn'
  },
  {
    Id: 20414,
    Code: '8112',
    Name: 'Presser, textile mfr'
  },
  {
    Id: 20415,
    Code: '8112',
    Name: 'Presser and threader'
  },
  {
    Id: 20416,
    Code: '8112',
    Name: 'Printer, wax, textile mfr'
  },
  {
    Id: 20417,
    Code: '8112',
    Name: 'Processor, textile'
  },
  {
    Id: 20418,
    Code: '8112',
    Name: 'Proofer, water, rubber goods mfr'
  },
  {
    Id: 20419,
    Code: '8112',
    Name: 'Proofer, rubber goods mfr'
  },
  {
    Id: 20420,
    Code: '8112',
    Name: 'Puller, rag'
  },
  {
    Id: 20421,
    Code: '8112',
    Name: 'Puller, silk'
  },
  {
    Id: 20422,
    Code: '8112',
    Name: 'Puller, waste, textile mfr'
  },
  {
    Id: 20423,
    Code: '8112',
    Name: 'Puller, wool'
  },
  {
    Id: 20424,
    Code: '8112',
    Name: 'Puller, textile mfr'
  },
  {
    Id: 20425,
    Code: '8112',
    Name: 'Puncher, card, jacquard card cutting'
  },
  {
    Id: 20426,
    Code: '8112',
    Name: 'Puncher, card, textile mfr'
  },
  {
    Id: 20427,
    Code: '8112',
    Name: 'Puncher, jacquard'
  },
  {
    Id: 20428,
    Code: '8112',
    Name: 'Puncher, piano, jacquard card cutting'
  },
  {
    Id: 20429,
    Code: '8112',
    Name: 'Puncher, wool combing'
  },
  {
    Id: 20430,
    Code: '8112',
    Name: 'Purifier, flock merchants'
  },
  {
    Id: 20431,
    Code: '8112',
    Name: 'Putter-in, textile mfr'
  },
  {
    Id: 20432,
    Code: '8112',
    Name: 'Putter-on, band'
  },
  {
    Id: 20433,
    Code: '8112',
    Name: 'Putter-on, tape, silk spinning'
  },
  {
    Id: 20434,
    Code: '8112',
    Name: 'Putter-on, textile spinning'
  },
  {
    Id: 20435,
    Code: '8112',
    Name: 'Quiller, textile mfr'
  },
  {
    Id: 20436,
    Code: '8112',
    Name: 'Racker, textile finishing'
  },
  {
    Id: 20437,
    Code: '8112',
    Name: 'Raiser, blanket'
  },
  {
    Id: 20438,
    Code: '8112',
    Name: 'Raiser, cloth'
  },
  {
    Id: 20439,
    Code: '8112',
    Name: 'Raiser, flannelette'
  },
  {
    Id: 20440,
    Code: '8112',
    Name: 'Raiser, textile finishing'
  },
  {
    Id: 20441,
    Code: '8112',
    Name: 'Rander, twine mfr'
  },
  {
    Id: 20442,
    Code: '8112',
    Name: 'Ratliner'
  },
  {
    Id: 20443,
    Code: '8112',
    Name: 'Re-beamer'
  },
  {
    Id: 20444,
    Code: '8112',
    Name: 'Re-drawer, silk'
  },
  {
    Id: 20445,
    Code: '8112',
    Name: 'Reacher, textile mfr'
  },
  {
    Id: 20446,
    Code: '8112',
    Name: 'Reacher-in, textile mfr'
  },
  {
    Id: 20447,
    Code: '8112',
    Name: 'Reader, lace mfr'
  },
  {
    Id: 20448,
    Code: '8112',
    Name: 'Reader-off'
  },
  {
    Id: 20449,
    Code: '8112',
    Name: 'Receiver, leathercloth mfr'
  },
  {
    Id: 20450,
    Code: '8112',
    Name: 'Reducer, textile printing'
  },
  {
    Id: 20451,
    Code: '8112',
    Name: 'Reducer, wool drawing'
  },
  {
    Id: 20452,
    Code: '8112',
    Name: 'Reeder'
  },
  {
    Id: 20453,
    Code: '8112',
    Name: 'Reeler, bobbin'
  },
  {
    Id: 20454,
    Code: '8112',
    Name: 'Reeler, cop'
  },
  {
    Id: 20455,
    Code: '8112',
    Name: 'Reeler, rope'
  },
  {
    Id: 20456,
    Code: '8112',
    Name: 'Reeler, twine'
  },
  {
    Id: 20457,
    Code: '8112',
    Name: 'Reeler, twist'
  },
  {
    Id: 20458,
    Code: '8112',
    Name: 'Reeler, yarn'
  },
  {
    Id: 20459,
    Code: '8112',
    Name: 'Reeler, textile mfr'
  },
  {
    Id: 20460,
    Code: '8112',
    Name: 'Reeler and lacer'
  },
  {
    Id: 20461,
    Code: '8112',
    Name: 'Reelerman, roofing felt mfr'
  },
  {
    Id: 20462,
    Code: '8112',
    Name: 'Respreader, silk mfr'
  },
  {
    Id: 20463,
    Code: '8112',
    Name: 'Rewinder, textile mfr'
  },
  {
    Id: 20464,
    Code: '8112',
    Name: 'Rigger, net'
  },
  {
    Id: 20465,
    Code: '8112',
    Name: 'Rigger, textile mfr'
  },
  {
    Id: 20466,
    Code: '8112',
    Name: 'Rigger and plaiter'
  },
  {
    Id: 20467,
    Code: '8112',
    Name: 'Rigger and roller'
  },
  {
    Id: 20468,
    Code: '8112',
    Name: 'Rigger-up, textile mfr'
  },
  {
    Id: 20469,
    Code: '8112',
    Name: 'Ripper, muslin'
  },
  {
    Id: 20470,
    Code: '8112',
    Name: 'Ripper, rag'
  },
  {
    Id: 20471,
    Code: '8112',
    Name: 'Ripper, shoddy mfr'
  },
  {
    Id: 20472,
    Code: '8112',
    Name: 'Roller, bandage'
  },
  {
    Id: 20473,
    Code: '8112',
    Name: 'Roller, piece'
  },
  {
    Id: 20474,
    Code: '8112',
    Name: 'Roller, textile mfr'
  },
  {
    Id: 20475,
    Code: '8112',
    Name: 'Roller-up, textile mfr'
  },
  {
    Id: 20476,
    Code: '8112',
    Name: 'Rover, asbestos'
  },
  {
    Id: 20477,
    Code: '8112',
    Name: 'Rover, cone'
  },
  {
    Id: 20478,
    Code: '8112',
    Name: 'Rover, dandy, wool'
  },
  {
    Id: 20479,
    Code: '8112',
    Name: 'Rover, textile mfr'
  },
  {
    Id: 20480,
    Code: '8112',
    Name: 'Rubber, textile finishing'
  },
  {
    Id: 20481,
    Code: '8112',
    Name: 'Rubberiser, carpets'
  },
  {
    Id: 20482,
    Code: '8112',
    Name: 'Sanforizer'
  },
  {
    Id: 20483,
    Code: '8112',
    Name: 'Scalloper, textile mfr'
  },
  {
    Id: 20484,
    Code: '8112',
    Name: 'Scolloper, lace mfr'
  },
  {
    Id: 20485,
    Code: '8112',
    Name: 'Scourer, cloth'
  },
  {
    Id: 20486,
    Code: '8112',
    Name: 'Scourer, piece'
  },
  {
    Id: 20487,
    Code: '8112',
    Name: 'Scourer, wool'
  },
  {
    Id: 20488,
    Code: '8112',
    Name: 'Scourer, textile mfr'
  },
  {
    Id: 20489,
    Code: '8112',
    Name: 'Scrapper, textile mfr'
  },
  {
    Id: 20490,
    Code: '8112',
    Name: 'Screener, textile mfr'
  },
  {
    Id: 20491,
    Code: '8112',
    Name: 'Scribbler'
  },
  {
    Id: 20492,
    Code: '8112',
    Name: 'Scutcher, textile mfr'
  },
  {
    Id: 20493,
    Code: '8112',
    Name: 'Separator, textile mfr'
  },
  {
    Id: 20494,
    Code: '8112',
    Name: 'Server, textile mfr'
  },
  {
    Id: 20495,
    Code: '8112',
    Name: 'Setter, beam'
  },
  {
    Id: 20496,
    Code: '8112',
    Name: 'Setter, bobbin'
  },
  {
    Id: 20497,
    Code: '8112',
    Name: 'Setter, card, textile mfr'
  },
  {
    Id: 20498,
    Code: '8112',
    Name: 'Setter, carpet'
  },
  {
    Id: 20499,
    Code: '8112',
    Name: 'Setter, chain'
  },
  {
    Id: 20500,
    Code: '8112',
    Name: 'Setter, crepe, textile mfr'
  },
  {
    Id: 20501,
    Code: '8112',
    Name: 'Setter, press, textile finishing'
  },
  {
    Id: 20502,
    Code: '8112',
    Name: 'Setter, spool'
  },
  {
    Id: 20503,
    Code: '8112',
    Name: 'Setter, teasel'
  },
  {
    Id: 20504,
    Code: '8112',
    Name: 'Setter, teazle'
  },
  {
    Id: 20505,
    Code: '8112',
    Name: 'Setter, tube, textile mfr'
  },
  {
    Id: 20506,
    Code: '8112',
    Name: 'Setter, yarn'
  },
  {
    Id: 20507,
    Code: '8112',
    Name: 'Setter, carpet, rug mfr'
  },
  {
    Id: 20508,
    Code: '8112',
    Name: 'Setter, textile mfr'
  },
  {
    Id: 20509,
    Code: '8112',
    Name: 'Settler, hat mfr'
  },
  {
    Id: 20510,
    Code: '8112',
    Name: 'Sewer, harness, wool weaving'
  },
  {
    Id: 20511,
    Code: '8112',
    Name: 'Shader, textile mfr'
  },
  {
    Id: 20512,
    Code: '8112',
    Name: 'Shaker, rag'
  },
  {
    Id: 20513,
    Code: '8112',
    Name: 'Shaker, waste'
  },
  {
    Id: 20514,
    Code: '8112',
    Name: 'Shaker, textile mfr'
  },
  {
    Id: 20515,
    Code: '8112',
    Name: 'Shaper, hosiery, knitwear mfr'
  },
  {
    Id: 20516,
    Code: '8112',
    Name: 'Shearer, cloth'
  },
  {
    Id: 20517,
    Code: '8112',
    Name: 'Shearer, mat'
  },
  {
    Id: 20518,
    Code: '8112',
    Name: 'Shearer, carpet, rug mfr'
  },
  {
    Id: 20519,
    Code: '8112',
    Name: 'Shearer, textile finishing'
  },
  {
    Id: 20520,
    Code: '8112',
    Name: 'Shifter, jute spinning'
  },
  {
    Id: 20521,
    Code: '8112',
    Name: 'Shrinker, London'
  },
  {
    Id: 20522,
    Code: '8112',
    Name: 'Shrinker, textile mfr'
  },
  {
    Id: 20523,
    Code: '8112',
    Name: 'Sizer, tape'
  },
  {
    Id: 20524,
    Code: '8112',
    Name: 'Sizer, warp'
  },
  {
    Id: 20525,
    Code: '8112',
    Name: 'Sizer, yarn'
  },
  {
    Id: 20526,
    Code: '8112',
    Name: 'Sizer, textile mfr'
  },
  {
    Id: 20527,
    Code: '8112',
    Name: 'Sizer and dryer, back'
  },
  {
    Id: 20528,
    Code: '8112',
    Name: 'Skeiner, textile mfr'
  },
  {
    Id: 20529,
    Code: '8112',
    Name: 'Slabber, textile mfr'
  },
  {
    Id: 20530,
    Code: '8112',
    Name: 'Slinger, textile mfr'
  },
  {
    Id: 20531,
    Code: '8112',
    Name: 'Sliverer'
  },
  {
    Id: 20532,
    Code: '8112',
    Name: 'Slubber, textile mfr'
  },
  {
    Id: 20533,
    Code: '8112',
    Name: 'Snipper'
  },
  {
    Id: 20534,
    Code: '8112',
    Name: 'Soaker, silk'
  },
  {
    Id: 20535,
    Code: '8112',
    Name: 'Soaper, rope'
  },
  {
    Id: 20536,
    Code: '8112',
    Name: 'Soapstoner, roofing felt mfr'
  },
  {
    Id: 20537,
    Code: '8112',
    Name: 'Softener, flax, hemp mfr'
  },
  {
    Id: 20538,
    Code: '8112',
    Name: 'Sorter, tube, textile spinning'
  },
  {
    Id: 20539,
    Code: '8112',
    Name: 'Sorter, weft'
  },
  {
    Id: 20540,
    Code: '8112',
    Name: 'Speeder, machine, braiding'
  },
  {
    Id: 20541,
    Code: '8112',
    Name: 'Spindler, ribbon'
  },
  {
    Id: 20542,
    Code: '8112',
    Name: 'Spinner, asbestos'
  },
  {
    Id: 20543,
    Code: '8112',
    Name: 'Spinner, cap'
  },
  {
    Id: 20544,
    Code: '8112',
    Name: 'Spinner, cord'
  },
  {
    Id: 20545,
    Code: '8112',
    Name: 'Spinner, crimp'
  },
  {
    Id: 20546,
    Code: '8112',
    Name: 'Spinner, doffer, self'
  },
  {
    Id: 20547,
    Code: '8112',
    Name: 'Spinner, fibreglass'
  },
  {
    Id: 20548,
    Code: '8112',
    Name: 'Spinner, fly'
  },
  {
    Id: 20549,
    Code: '8112',
    Name: 'Spinner, frame'
  },
  {
    Id: 20550,
    Code: '8112',
    Name: 'Spinner, fuse, safety'
  },
  {
    Id: 20551,
    Code: '8112',
    Name: 'Spinner, gill'
  },
  {
    Id: 20552,
    Code: '8112',
    Name: 'Spinner, mule'
  },
  {
    Id: 20553,
    Code: '8112',
    Name: 'Spinner, paper, cellulose film mfr'
  },
  {
    Id: 20554,
    Code: '8112',
    Name: 'Spinner, ring'
  },
  {
    Id: 20555,
    Code: '8112',
    Name: 'Spinner, rope'
  },
  {
    Id: 20556,
    Code: '8112',
    Name: 'Spinner, silk'
  },
  {
    Id: 20557,
    Code: '8112',
    Name: 'Spinner, thread, metal'
  },
  {
    Id: 20558,
    Code: '8112',
    Name: 'Spinner, twine'
  },
  {
    Id: 20559,
    Code: '8112',
    Name: 'Spinner, textiles'
  },
  {
    Id: 20560,
    Code: '8112',
    Name: 'Spinner, mining, safety fuse mfr'
  },
  {
    Id: 20561,
    Code: '8112',
    Name: 'Spinner, paper twine mfr'
  },
  {
    Id: 20562,
    Code: '8112',
    Name: 'Spinner, textile mfr'
  },
  {
    Id: 20563,
    Code: '8112',
    Name: 'Splicer, rope'
  },
  {
    Id: 20564,
    Code: '8112',
    Name: 'Splicer, yarn'
  },
  {
    Id: 20565,
    Code: '8112',
    Name: 'Splicer, rope'
  },
  {
    Id: 20566,
    Code: '8112',
    Name: 'Splicer, textile cords'
  },
  {
    Id: 20567,
    Code: '8112',
    Name: 'Splicer, textile mfr'
  },
  {
    Id: 20568,
    Code: '8112',
    Name: 'Splitter, cloth'
  },
  {
    Id: 20569,
    Code: '8112',
    Name: 'Splitter, textile mfr'
  },
  {
    Id: 20570,
    Code: '8112',
    Name: 'Spooler, textile mfr'
  },
  {
    Id: 20571,
    Code: '8112',
    Name: 'Spotter, lace mfr'
  },
  {
    Id: 20572,
    Code: '8112',
    Name: 'Sprayer, steam'
  },
  {
    Id: 20573,
    Code: '8112',
    Name: 'Sprayer, textile finishing'
  },
  {
    Id: 20574,
    Code: '8112',
    Name: 'Spreader, plaster'
  },
  {
    Id: 20575,
    Code: '8112',
    Name: 'Spreader, rubber, textile mfr'
  },
  {
    Id: 20576,
    Code: '8112',
    Name: 'Spreader, leathercloth mfr'
  },
  {
    Id: 20577,
    Code: '8112',
    Name: 'Spreader, surgical dressing mfr'
  },
  {
    Id: 20578,
    Code: '8112',
    Name: 'Spreader, textile mfr'
  },
  {
    Id: 20579,
    Code: '8112',
    Name: 'Springer, heald'
  },
  {
    Id: 20580,
    Code: '8112',
    Name: 'Springer, textile mfr'
  },
  {
    Id: 20581,
    Code: '8112',
    Name: 'Stamper, card, textile mfr'
  },
  {
    Id: 20582,
    Code: '8112',
    Name: 'Stapler, wool, textile mfr'
  },
  {
    Id: 20583,
    Code: '8112',
    Name: 'Steamer, silk'
  },
  {
    Id: 20584,
    Code: '8112',
    Name: 'Steamer, straw hat mfr'
  },
  {
    Id: 20585,
    Code: '8112',
    Name: 'Steamer, textile finishing'
  },
  {
    Id: 20586,
    Code: '8112',
    Name: 'Stenter'
  },
  {
    Id: 20587,
    Code: '8112',
    Name: 'Stenterer'
  },
  {
    Id: 20588,
    Code: '8112',
    Name: 'Steriliser, surgical dressing mfr'
  },
  {
    Id: 20589,
    Code: '8112',
    Name: 'Steriliser, textile mfr'
  },
  {
    Id: 20590,
    Code: '8112',
    Name: 'Stiffener, hat, straw'
  },
  {
    Id: 20591,
    Code: '8112',
    Name: 'Stover, hat mfr'
  },
  {
    Id: 20592,
    Code: '8112',
    Name: 'Stover, textile mfr'
  },
  {
    Id: 20593,
    Code: '8112',
    Name: 'Straightener, textile mfr: flax, hemp mfr'
  },
  {
    Id: 20594,
    Code: '8112',
    Name: 'Straightener, textile mfr: hosiery mfr'
  },
  {
    Id: 20595,
    Code: '8112',
    Name: 'Strander, rope, twine mfr'
  },
  {
    Id: 20596,
    Code: '8112',
    Name: 'Stretcher, clip, textile mfr'
  },
  {
    Id: 20597,
    Code: '8112',
    Name: 'Stretcher, dry, textile mfr'
  },
  {
    Id: 20598,
    Code: '8112',
    Name: 'Stretcher, yarn'
  },
  {
    Id: 20599,
    Code: '8112',
    Name: 'Stretcher, textile mfr'
  },
  {
    Id: 20600,
    Code: '8112',
    Name: 'Stripper, bobbin'
  },
  {
    Id: 20601,
    Code: '8112',
    Name: 'Stripper, cloth'
  },
  {
    Id: 20602,
    Code: '8112',
    Name: 'Stripper, cop'
  },
  {
    Id: 20603,
    Code: '8112',
    Name: 'Stripper, pirn'
  },
  {
    Id: 20604,
    Code: '8112',
    Name: 'Stripper, rag'
  },
  {
    Id: 20605,
    Code: '8112',
    Name: 'Stripper, wool'
  },
  {
    Id: 20606,
    Code: '8112',
    Name: 'Stripper, yarn'
  },
  {
    Id: 20607,
    Code: '8112',
    Name: 'Stripper, textile mfr'
  },
  {
    Id: 20608,
    Code: '8112',
    Name: 'Stripper and buncher'
  },
  {
    Id: 20609,
    Code: '8112',
    Name: 'Stumper'
  },
  {
    Id: 20610,
    Code: '8112',
    Name: 'Sweeper, cotton, textile mfr'
  },
  {
    Id: 20611,
    Code: '8112',
    Name: 'Sweeper, loom'
  },
  {
    Id: 20612,
    Code: '8112',
    Name: 'Swinger'
  },
  {
    Id: 20613,
    Code: '8112',
    Name: 'Taker-in, textile weaving'
  },
  {
    Id: 20614,
    Code: '8112',
    Name: 'Taker-off, bobbin'
  },
  {
    Id: 20615,
    Code: '8112',
    Name: 'Taker-off, textile mfr'
  },
  {
    Id: 20616,
    Code: '8112',
    Name: 'Taker-out, can, textile mfr'
  },
  {
    Id: 20617,
    Code: '8112',
    Name: 'Tankman, textile mfr'
  },
  {
    Id: 20618,
    Code: '8112',
    Name: 'Tanner, net, rope mfr'
  },
  {
    Id: 20619,
    Code: '8112',
    Name: 'Taper, textile mfr'
  },
  {
    Id: 20620,
    Code: '8112',
    Name: 'Targer, flax, hemp mfr'
  },
  {
    Id: 20621,
    Code: '8112',
    Name: 'Tarrer, bag'
  },
  {
    Id: 20622,
    Code: '8112',
    Name: 'Tarrer, sack'
  },
  {
    Id: 20623,
    Code: '8112',
    Name: 'Teazer, shoddy'
  },
  {
    Id: 20624,
    Code: '8112',
    Name: 'Teazer, textile mfr'
  },
  {
    Id: 20625,
    Code: '8112',
    Name: 'Teazler'
  },
  {
    Id: 20626,
    Code: '8112',
    Name: 'Tenter, back, frame, textile mfr'
  },
  {
    Id: 20627,
    Code: '8112',
    Name: 'Tenter, back, roving'
  },
  {
    Id: 20628,
    Code: '8112',
    Name: 'Tenter, back, textile mfr'
  },
  {
    Id: 20629,
    Code: '8112',
    Name: 'Tenter, box'
  },
  {
    Id: 20630,
    Code: '8112',
    Name: 'Tenter, braid, silk mfr'
  },
  {
    Id: 20631,
    Code: '8112',
    Name: 'Tenter, can'
  },
  {
    Id: 20632,
    Code: '8112',
    Name: 'Tenter, card'
  },
  {
    Id: 20633,
    Code: '8112',
    Name: 'Tenter, comb'
  },
  {
    Id: 20634,
    Code: '8112',
    Name: 'Tenter, comber'
  },
  {
    Id: 20635,
    Code: '8112',
    Name: 'Tenter, condenser'
  },
  {
    Id: 20636,
    Code: '8112',
    Name: 'Tenter, cotton'
  },
  {
    Id: 20637,
    Code: '8112',
    Name: 'Tenter, derby'
  },
  {
    Id: 20638,
    Code: '8112',
    Name: 'Tenter, devil'
  },
  {
    Id: 20639,
    Code: '8112',
    Name: 'Tenter, double'
  },
  {
    Id: 20640,
    Code: '8112',
    Name: 'Tenter, draw'
  },
  {
    Id: 20641,
    Code: '8112',
    Name: 'Tenter, drawing'
  },
  {
    Id: 20642,
    Code: '8112',
    Name: 'Tenter, engine, textile mfr'
  },
  {
    Id: 20643,
    Code: '8112',
    Name: 'Tenter, fly'
  },
  {
    Id: 20644,
    Code: '8112',
    Name: 'Tenter, frame, cheesing'
  },
  {
    Id: 20645,
    Code: '8112',
    Name: 'Tenter, frame, clearing'
  },
  {
    Id: 20646,
    Code: '8112',
    Name: 'Tenter, frame, copping'
  },
  {
    Id: 20647,
    Code: '8112',
    Name: 'Tenter, frame, doubling'
  },
  {
    Id: 20648,
    Code: '8112',
    Name: 'Tenter, frame, draw'
  },
  {
    Id: 20649,
    Code: '8112',
    Name: 'Tenter, frame, flyer'
  },
  {
    Id: 20650,
    Code: '8112',
    Name: 'Tenter, frame, ring'
  },
  {
    Id: 20651,
    Code: '8112',
    Name: 'Tenter, frame, twisting'
  },
  {
    Id: 20652,
    Code: '8112',
    Name: 'Tenter, frame'
  },
  {
    Id: 20653,
    Code: '8112',
    Name: 'Tenter, front'
  },
  {
    Id: 20654,
    Code: '8112',
    Name: 'Tenter, gill'
  },
  {
    Id: 20655,
    Code: '8112',
    Name: 'Tenter, hardener, flat'
  },
  {
    Id: 20656,
    Code: '8112',
    Name: 'Tenter, hardener, roller'
  },
  {
    Id: 20657,
    Code: '8112',
    Name: 'Tenter, inter'
  },
  {
    Id: 20658,
    Code: '8112',
    Name: 'Tenter, jack'
  },
  {
    Id: 20659,
    Code: '8112',
    Name: 'Tenter, joiner'
  },
  {
    Id: 20660,
    Code: '8112',
    Name: 'Tenter, lap'
  },
  {
    Id: 20661,
    Code: '8112',
    Name: 'Tenter, mule'
  },
  {
    Id: 20662,
    Code: '8112',
    Name: 'Tenter, opener'
  },
  {
    Id: 20663,
    Code: '8112',
    Name: 'Tenter, padding'
  },
  {
    Id: 20664,
    Code: '8112',
    Name: 'Tenter, picker'
  },
  {
    Id: 20665,
    Code: '8112',
    Name: 'Tenter, ribbon'
  },
  {
    Id: 20666,
    Code: '8112',
    Name: 'Tenter, rover'
  },
  {
    Id: 20667,
    Code: '8112',
    Name: 'Tenter, roving'
  },
  {
    Id: 20668,
    Code: '8112',
    Name: 'Tenter, scutcher'
  },
  {
    Id: 20669,
    Code: '8112',
    Name: 'Tenter, slub'
  },
  {
    Id: 20670,
    Code: '8112',
    Name: 'Tenter, slubber'
  },
  {
    Id: 20671,
    Code: '8112',
    Name: 'Tenter, slubbing'
  },
  {
    Id: 20672,
    Code: '8112',
    Name: 'Tenter, spare'
  },
  {
    Id: 20673,
    Code: '8112',
    Name: 'Tenter, speed'
  },
  {
    Id: 20674,
    Code: '8112',
    Name: 'Tenter, throstle'
  },
  {
    Id: 20675,
    Code: '8112',
    Name: 'Tenter, waste, hard'
  },
  {
    Id: 20676,
    Code: '8112',
    Name: 'Tenter, weilds'
  },
  {
    Id: 20677,
    Code: '8112',
    Name: 'Tenter, textile finishing'
  },
  {
    Id: 20678,
    Code: '8112',
    Name: 'Tenterer, woollen'
  },
  {
    Id: 20679,
    Code: '8112',
    Name: 'Tenterer, textile mfr'
  },
  {
    Id: 20680,
    Code: '8112',
    Name: 'Texturer, yarn'
  },
  {
    Id: 20681,
    Code: '8112',
    Name: 'Threader, bobbin'
  },
  {
    Id: 20682,
    Code: '8112',
    Name: 'Threader, brass, lace mfr'
  },
  {
    Id: 20683,
    Code: '8112',
    Name: 'Threader, frame'
  },
  {
    Id: 20684,
    Code: '8112',
    Name: 'Threader, heald'
  },
  {
    Id: 20685,
    Code: '8112',
    Name: 'Threader, warp, hosiery, knitwear mfr'
  },
  {
    Id: 20686,
    Code: '8112',
    Name: 'Threader, carpet, rug mfr'
  },
  {
    Id: 20687,
    Code: '8112',
    Name: 'Threader, lace mfr'
  },
  {
    Id: 20688,
    Code: '8112',
    Name: 'Thrower, rayon'
  },
  {
    Id: 20689,
    Code: '8112',
    Name: 'Throwster, textile mfr'
  },
  {
    Id: 20690,
    Code: '8112',
    Name: 'Tier, warp'
  },
  {
    Id: 20691,
    Code: '8112',
    Name: 'Tier-in, textile mfr'
  },
  {
    Id: 20692,
    Code: '8112',
    Name: 'Tier-on, warp'
  },
  {
    Id: 20693,
    Code: '8112',
    Name: 'Tier-on, textile mfr'
  },
  {
    Id: 20694,
    Code: '8112',
    Name: 'Tinsman, drying, textile mfr'
  },
  {
    Id: 20695,
    Code: '8112',
    Name: 'Tinter, textile mfr'
  },
  {
    Id: 20696,
    Code: '8112',
    Name: 'Tippler, flax, hemp mfr'
  },
  {
    Id: 20697,
    Code: '8112',
    Name: 'Tooler, fustian, velvet mfr'
  },
  {
    Id: 20698,
    Code: '8112',
    Name: 'Topper, cord mfr'
  },
  {
    Id: 20699,
    Code: '8112',
    Name: 'Trapper, wool'
  },
  {
    Id: 20700,
    Code: '8112',
    Name: 'Trapper, textile mfr'
  },
  {
    Id: 20701,
    Code: '8112',
    Name: 'Trimmer, bristle'
  },
  {
    Id: 20702,
    Code: '8112',
    Name: 'Trimmer, cloth'
  },
  {
    Id: 20703,
    Code: '8112',
    Name: 'Trimmer, fancy, broom, brush mfr'
  },
  {
    Id: 20704,
    Code: '8112',
    Name: 'Trimmer, fibre'
  },
  {
    Id: 20705,
    Code: '8112',
    Name: 'Trimmer, hosiery, textile finishing'
  },
  {
    Id: 20706,
    Code: '8112',
    Name: 'Trimmer, textile mfr'
  },
  {
    Id: 20707,
    Code: '8112',
    Name: 'Trimmer and finisher, hosiery, knitwear mfr'
  },
  {
    Id: 20708,
    Code: '8112',
    Name: 'Tuber, mule'
  },
  {
    Id: 20709,
    Code: '8112',
    Name: 'Tuber, rope, twine mfr'
  },
  {
    Id: 20710,
    Code: '8112',
    Name: 'Tuber, textile mfr'
  },
  {
    Id: 20711,
    Code: '8112',
    Name: 'Tucker, blanket mfr'
  },
  {
    Id: 20712,
    Code: '8112',
    Name: 'Tucker, textile mfr'
  },
  {
    Id: 20713,
    Code: '8112',
    Name: 'Tufter, carpet, rug mfr'
  },
  {
    Id: 20714,
    Code: '8112',
    Name: 'Twinder'
  },
  {
    Id: 20715,
    Code: '8112',
    Name: 'Twiner, textile mfr'
  },
  {
    Id: 20716,
    Code: '8112',
    Name: 'Twiner-joiner-minder'
  },
  {
    Id: 20717,
    Code: '8112',
    Name: 'Twister, cap, textile mfr'
  },
  {
    Id: 20718,
    Code: '8112',
    Name: 'Twister, cop'
  },
  {
    Id: 20719,
    Code: '8112',
    Name: 'Twister, cotton'
  },
  {
    Id: 20720,
    Code: '8112',
    Name: 'Twister, doubling'
  },
  {
    Id: 20721,
    Code: '8112',
    Name: 'Twister, false'
  },
  {
    Id: 20722,
    Code: '8112',
    Name: 'Twister, fly'
  },
  {
    Id: 20723,
    Code: '8112',
    Name: 'Twister, machine'
  },
  {
    Id: 20724,
    Code: '8112',
    Name: 'Twister, patent'
  },
  {
    Id: 20725,
    Code: '8112',
    Name: 'Twister, ring'
  },
  {
    Id: 20726,
    Code: '8112',
    Name: 'Twister, silk'
  },
  {
    Id: 20727,
    Code: '8112',
    Name: 'Twister, single'
  },
  {
    Id: 20728,
    Code: '8112',
    Name: 'Twister, spinning'
  },
  {
    Id: 20729,
    Code: '8112',
    Name: 'Twister, sprig'
  },
  {
    Id: 20730,
    Code: '8112',
    Name: 'Twister, twine'
  },
  {
    Id: 20731,
    Code: '8112',
    Name: 'Twister, warp'
  },
  {
    Id: 20732,
    Code: '8112',
    Name: 'Twister, wool'
  },
  {
    Id: 20733,
    Code: '8112',
    Name: 'Twister, worsted'
  },
  {
    Id: 20734,
    Code: '8112',
    Name: 'Twister, yarn'
  },
  {
    Id: 20735,
    Code: '8112',
    Name: 'Twister, textile mfr'
  },
  {
    Id: 20736,
    Code: '8112',
    Name: 'Twister and drawer'
  },
  {
    Id: 20737,
    Code: '8112',
    Name: 'Twister-in, textile mfr'
  },
  {
    Id: 20738,
    Code: '8112',
    Name: 'Twister-on, textile mfr'
  },
  {
    Id: 20739,
    Code: '8112',
    Name: 'Twister-up, textile mfr'
  },
  {
    Id: 20740,
    Code: '8112',
    Name: 'Uncurler, textile mfr'
  },
  {
    Id: 20741,
    Code: '8112',
    Name: 'Unwinder'
  },
  {
    Id: 20742,
    Code: '8112',
    Name: 'Warper'
  },
  {
    Id: 20743,
    Code: '8112',
    Name: 'Washer, back, textile mfr'
  },
  {
    Id: 20744,
    Code: '8112',
    Name: 'Washer, blanket, blanket mfr'
  },
  {
    Id: 20745,
    Code: '8112',
    Name: 'Washer, body, hat mfr'
  },
  {
    Id: 20746,
    Code: '8112',
    Name: 'Washer, brush'
  },
  {
    Id: 20747,
    Code: '8112',
    Name: 'Washer, cloth, textile mfr'
  },
  {
    Id: 20748,
    Code: '8112',
    Name: 'Washer, feather'
  },
  {
    Id: 20749,
    Code: '8112',
    Name: 'Washer, felt'
  },
  {
    Id: 20750,
    Code: '8112',
    Name: 'Washer, hair, horse'
  },
  {
    Id: 20751,
    Code: '8112',
    Name: 'Washer, rag, textile mfr'
  },
  {
    Id: 20752,
    Code: '8112',
    Name: 'Washer, silk'
  },
  {
    Id: 20753,
    Code: '8112',
    Name: 'Washer, wool'
  },
  {
    Id: 20754,
    Code: '8112',
    Name: 'Washer, yarn'
  },
  {
    Id: 20755,
    Code: '8112',
    Name: 'Washer, hat mfr'
  },
  {
    Id: 20756,
    Code: '8112',
    Name: 'Washer, textile mfr'
  },
  {
    Id: 20757,
    Code: '8112',
    Name: 'Washerman, textile mfr'
  },
  {
    Id: 20758,
    Code: '8112',
    Name: 'Washhouseman, wool'
  },
  {
    Id: 20759,
    Code: '8112',
    Name: 'Washhouseman, raw silk processing'
  },
  {
    Id: 20760,
    Code: '8112',
    Name: 'Waterman, textile mfr'
  },
  {
    Id: 20761,
    Code: '8112',
    Name: 'Waxer, cord'
  },
  {
    Id: 20762,
    Code: '8112',
    Name: 'Weigher, coiler'
  },
  {
    Id: 20763,
    Code: '8112',
    Name: 'Weigher and finisher, coil, asbestos rope mfr'
  },
  {
    Id: 20764,
    Code: '8112',
    Name: 'Whimseyer'
  },
  {
    Id: 20765,
    Code: '8112',
    Name: 'Willeyer'
  },
  {
    Id: 20766,
    Code: '8112',
    Name: 'Willier'
  },
  {
    Id: 20767,
    Code: '8112',
    Name: 'Willower, textile mfr'
  },
  {
    Id: 20768,
    Code: '8112',
    Name: 'Willowyer'
  },
  {
    Id: 20769,
    Code: '8112',
    Name: 'Willyer'
  },
  {
    Id: 20770,
    Code: '8112',
    Name: 'Winchman, textile mfr'
  },
  {
    Id: 20771,
    Code: '8112',
    Name: 'Winder, ball, textile mfr'
  },
  {
    Id: 20772,
    Code: '8112',
    Name: 'Winder, beam'
  },
  {
    Id: 20773,
    Code: '8112',
    Name: 'Winder, bit, textile mfr'
  },
  {
    Id: 20774,
    Code: '8112',
    Name: 'Winder, bobbin, brass'
  },
  {
    Id: 20775,
    Code: '8112',
    Name: 'Winder, bobbin, ring'
  },
  {
    Id: 20776,
    Code: '8112',
    Name: 'Winder, bobbin, textile mfr'
  },
  {
    Id: 20777,
    Code: '8112',
    Name: 'Winder, card'
  },
  {
    Id: 20778,
    Code: '8112',
    Name: 'Winder, cheese'
  },
  {
    Id: 20779,
    Code: '8112',
    Name: 'Winder, clear'
  },
  {
    Id: 20780,
    Code: '8112',
    Name: 'Winder, cloth, oil cloth mfr'
  },
  {
    Id: 20781,
    Code: '8112',
    Name: 'Winder, coloured'
  },
  {
    Id: 20782,
    Code: '8112',
    Name: 'Winder, cone'
  },
  {
    Id: 20783,
    Code: '8112',
    Name: 'Winder, cop'
  },
  {
    Id: 20784,
    Code: '8112',
    Name: 'Winder, cotton'
  },
  {
    Id: 20785,
    Code: '8112',
    Name: 'Winder, double, textile mfr'
  },
  {
    Id: 20786,
    Code: '8112',
    Name: 'Winder, doubler, textile mfr'
  },
  {
    Id: 20787,
    Code: '8112',
    Name: 'Winder, doubling'
  },
  {
    Id: 20788,
    Code: '8112',
    Name: 'Winder, drum'
  },
  {
    Id: 20789,
    Code: '8112',
    Name: 'Winder, engine, textile mfr'
  },
  {
    Id: 20790,
    Code: '8112',
    Name: 'Winder, gas'
  },
  {
    Id: 20791,
    Code: '8112',
    Name: 'Winder, hank'
  },
  {
    Id: 20792,
    Code: '8112',
    Name: 'Winder, jute'
  },
  {
    Id: 20793,
    Code: '8112',
    Name: 'Winder, machine, surgical dressing mfr'
  },
  {
    Id: 20794,
    Code: '8112',
    Name: 'Winder, mohair'
  },
  {
    Id: 20795,
    Code: '8112',
    Name: 'Winder, pin'
  },
  {
    Id: 20796,
    Code: '8112',
    Name: 'Winder, pirn'
  },
  {
    Id: 20797,
    Code: '8112',
    Name: 'Winder, rayon'
  },
  {
    Id: 20798,
    Code: '8112',
    Name: 'Winder, reel'
  },
  {
    Id: 20799,
    Code: '8112',
    Name: 'Winder, ribbon'
  },
  {
    Id: 20800,
    Code: '8112',
    Name: 'Winder, ring'
  },
  {
    Id: 20801,
    Code: '8112',
    Name: 'Winder, rubber'
  },
  {
    Id: 20802,
    Code: '8112',
    Name: 'Winder, silk, raw'
  },
  {
    Id: 20803,
    Code: '8112',
    Name: 'Winder, slip'
  },
  {
    Id: 20804,
    Code: '8112',
    Name: 'Winder, spool, textile mfr'
  },
  {
    Id: 20805,
    Code: '8112',
    Name: 'Winder, tape, electrical engineering'
  },
  {
    Id: 20806,
    Code: '8112',
    Name: 'Winder, thread, textile mfr'
  },
  {
    Id: 20807,
    Code: '8112',
    Name: 'Winder, tube, textile mfr'
  },
  {
    Id: 20808,
    Code: '8112',
    Name: 'Winder, twist'
  },
  {
    Id: 20809,
    Code: '8112',
    Name: 'Winder, warp'
  },
  {
    Id: 20810,
    Code: '8112',
    Name: 'Winder, weft'
  },
  {
    Id: 20811,
    Code: '8112',
    Name: 'Winder, weight'
  },
  {
    Id: 20812,
    Code: '8112',
    Name: 'Winder, wheel, lace mfr'
  },
  {
    Id: 20813,
    Code: '8112',
    Name: 'Winder, wool'
  },
  {
    Id: 20814,
    Code: '8112',
    Name: 'Winder, yarn'
  },
  {
    Id: 20815,
    Code: '8112',
    Name: 'Winder, textile mfr'
  },
  {
    Id: 20816,
    Code: '8112',
    Name: 'Winder, textile smallwares mfr'
  },
  {
    Id: 20817,
    Code: '8112',
    Name: 'Winder, typewriter ribbon mfr'
  },
  {
    Id: 20818,
    Code: '8112',
    Name: 'Wiper, bobbin'
  },
  {
    Id: 20819,
    Code: '8112',
    Name: 'Wirer, card, carpet, rug mfr'
  },
  {
    Id: 20820,
    Code: '8112',
    Name: 'Woolleyer'
  },
  {
    Id: 20821,
    Code: '8112',
    Name: 'Worker, bobbin, bottle'
  },
  {
    Id: 20822,
    Code: '8112',
    Name: 'Worker, cloth, textile finishing'
  },
  {
    Id: 20823,
    Code: '8112',
    Name: 'Worker, cotton'
  },
  {
    Id: 20824,
    Code: '8112',
    Name: 'Worker, factory, clothing mfr'
  },
  {
    Id: 20825,
    Code: '8112',
    Name: 'Worker, factory, textile mfr'
  },
  {
    Id: 20826,
    Code: '8112',
    Name: 'Worker, feather'
  },
  {
    Id: 20827,
    Code: '8112',
    Name: 'Worker, felt'
  },
  {
    Id: 20828,
    Code: '8112',
    Name: 'Worker, frame, hand'
  },
  {
    Id: 20829,
    Code: '8112',
    Name: 'Worker, frame, knitting'
  },
  {
    Id: 20830,
    Code: '8112',
    Name: 'Worker, frame, rope, twine mfr'
  },
  {
    Id: 20831,
    Code: '8112',
    Name: 'Worker, garnet'
  },
  {
    Id: 20832,
    Code: '8112',
    Name: 'Worker, hair'
  },
  {
    Id: 20833,
    Code: '8112',
    Name: 'Worker, hosiery'
  },
  {
    Id: 20834,
    Code: '8112',
    Name: 'Worker, mill, asbestos'
  },
  {
    Id: 20835,
    Code: '8112',
    Name: 'Worker, mill, cotton'
  },
  {
    Id: 20836,
    Code: '8112',
    Name: 'Worker, mill, woollen'
  },
  {
    Id: 20837,
    Code: '8112',
    Name: 'Worker, process, asbestos mfr'
  },
  {
    Id: 20838,
    Code: '8112',
    Name: 'Worker, process, felt mfr'
  },
  {
    Id: 20839,
    Code: '8112',
    Name: 'Worker, process, leathercloth mfr'
  },
  {
    Id: 20840,
    Code: '8112',
    Name: 'Worker, process, textile mfr'
  },
  {
    Id: 20841,
    Code: '8112',
    Name: 'Worker, room, card'
  },
  {
    Id: 20842,
    Code: '8112',
    Name: 'Worker, rope, rope, twine mfr'
  },
  {
    Id: 20843,
    Code: '8112',
    Name: 'Worker, service, textile mfr'
  },
  {
    Id: 20844,
    Code: '8112',
    Name: 'Worker, silk'
  },
  {
    Id: 20845,
    Code: '8112',
    Name: 'Worker, textile'
  },
  {
    Id: 20846,
    Code: '8112',
    Name: 'Wringman'
  },
  {
    Id: 20847,
    Code: '8113',
    Name: 'Acidifier'
  },
  {
    Id: 20848,
    Code: '8113',
    Name: 'Annealer, chemicals'
  },
  {
    Id: 20849,
    Code: '8113',
    Name: 'Assembler, cell, chemical mfr'
  },
  {
    Id: 20850,
    Code: '8113',
    Name: 'Assistant, craft, chemical works'
  },
  {
    Id: 20851,
    Code: '8113',
    Name: "Assistant, dyer's, textile mfr"
  },
  {
    Id: 20852,
    Code: '8113',
    Name: "Assistant, mixer's, colour"
  },
  {
    Id: 20853,
    Code: '8113',
    Name: "Assistant, refiner's"
  },
  {
    Id: 20854,
    Code: '8113',
    Name: 'Attendant, burner, coalite'
  },
  {
    Id: 20855,
    Code: '8113',
    Name: 'Attendant, centrifugal, chemical mfr'
  },
  {
    Id: 20856,
    Code: '8113',
    Name: 'Attendant, chlorination'
  },
  {
    Id: 20857,
    Code: '8113',
    Name: 'Attendant, composition, matches'
  },
  {
    Id: 20858,
    Code: '8113',
    Name: 'Attendant, condenser'
  },
  {
    Id: 20859,
    Code: '8113',
    Name: 'Attendant, evaporator'
  },
  {
    Id: 20860,
    Code: '8113',
    Name: 'Attendant, filtration'
  },
  {
    Id: 20861,
    Code: '8113',
    Name: 'Attendant, furnace, chemical mfr'
  },
  {
    Id: 20862,
    Code: '8113',
    Name: 'Attendant, gear, extractor, gas works'
  },
  {
    Id: 20863,
    Code: '8113',
    Name: 'Attendant, heat'
  },
  {
    Id: 20864,
    Code: '8113',
    Name: 'Attendant, heats'
  },
  {
    Id: 20865,
    Code: '8113',
    Name: 'Attendant, house, boiling'
  },
  {
    Id: 20866,
    Code: '8113',
    Name: 'Attendant, house, filter'
  },
  {
    Id: 20867,
    Code: '8113',
    Name: 'Attendant, house, retort'
  },
  {
    Id: 20868,
    Code: '8113',
    Name: 'Attendant, instrument, chemical mfr'
  },
  {
    Id: 20869,
    Code: '8113',
    Name: 'Attendant, kiln, glaze and colour mfr'
  },
  {
    Id: 20870,
    Code: '8113',
    Name: 'Attendant, mill, wash'
  },
  {
    Id: 20871,
    Code: '8113',
    Name: 'Attendant, oven, drying, cylinder'
  },
  {
    Id: 20872,
    Code: '8113',
    Name: 'Attendant, oven, gas'
  },
  {
    Id: 20873,
    Code: '8113',
    Name: 'Attendant, oven, chemical mfr'
  },
  {
    Id: 20874,
    Code: '8113',
    Name: 'Attendant, oven, coke ovens'
  },
  {
    Id: 20875,
    Code: '8113',
    Name: 'Attendant, paraffin'
  },
  {
    Id: 20876,
    Code: '8113',
    Name: 'Attendant, plant, acid'
  },
  {
    Id: 20877,
    Code: '8113',
    Name: 'Attendant, plant, ammonia'
  },
  {
    Id: 20878,
    Code: '8113',
    Name: 'Attendant, plant, breeze'
  },
  {
    Id: 20879,
    Code: '8113',
    Name: 'Attendant, plant, chlorination'
  },
  {
    Id: 20880,
    Code: '8113',
    Name: 'Attendant, plant, cooling, dry'
  },
  {
    Id: 20881,
    Code: '8113',
    Name: 'Attendant, plant, gas'
  },
  {
    Id: 20882,
    Code: '8113',
    Name: 'Attendant, plant, grading, coke'
  },
  {
    Id: 20883,
    Code: '8113',
    Name: 'Attendant, plant, retort'
  },
  {
    Id: 20884,
    Code: '8113',
    Name: 'Attendant, plant, sulphate'
  },
  {
    Id: 20885,
    Code: '8113',
    Name: 'Attendant, plant, tar'
  },
  {
    Id: 20886,
    Code: '8113',
    Name: 'Attendant, plant, water'
  },
  {
    Id: 20887,
    Code: '8113',
    Name: 'Attendant, plant, chemical mfr'
  },
  {
    Id: 20888,
    Code: '8113',
    Name: 'Attendant, plant, gas works'
  },
  {
    Id: 20889,
    Code: '8113',
    Name: 'Attendant, press, chemical mfr'
  },
  {
    Id: 20890,
    Code: '8113',
    Name: 'Attendant, producer, gas'
  },
  {
    Id: 20891,
    Code: '8113',
    Name: 'Attendant, purifier'
  },
  {
    Id: 20892,
    Code: '8113',
    Name: 'Attendant, retort, coal gas, coke ovens'
  },
  {
    Id: 20893,
    Code: '8113',
    Name: 'Attendant, screen, gas works'
  },
  {
    Id: 20894,
    Code: '8113',
    Name: 'Attendant, still'
  },
  {
    Id: 20895,
    Code: '8113',
    Name: 'Attendant, storage, liquor'
  },
  {
    Id: 20896,
    Code: '8113',
    Name: 'Attendant, tar and liquor'
  },
  {
    Id: 20897,
    Code: '8113',
    Name: 'Attendant, vat, textile bleaching, dyeing'
  },
  {
    Id: 20898,
    Code: '8113',
    Name: 'Attendant, washer'
  },
  {
    Id: 20899,
    Code: '8113',
    Name: 'Attendant, gas works'
  },
  {
    Id: 20900,
    Code: '8113',
    Name: 'Bander, textile mfr: textile bleaching, dyeing'
  },
  {
    Id: 20901,
    Code: '8113',
    Name: 'Bater'
  },
  {
    Id: 20902,
    Code: '8113',
    Name: 'Beaterman, celluloid mfr'
  },
  {
    Id: 20903,
    Code: '8113',
    Name: 'Bleacher, yarn'
  },
  {
    Id: 20904,
    Code: '8113',
    Name: 'Bleacher, feather'
  },
  {
    Id: 20905,
    Code: '8113',
    Name: 'Bleacher, oil'
  },
  {
    Id: 20906,
    Code: '8113',
    Name: 'Bleacher, textiles'
  },
  {
    Id: 20907,
    Code: '8113',
    Name: 'Bleacher and dyer'
  },
  {
    Id: 20908,
    Code: '8113',
    Name: 'Blender, batch'
  },
  {
    Id: 20909,
    Code: '8113',
    Name: 'Blender, coal, coke ovens'
  },
  {
    Id: 20910,
    Code: '8113',
    Name: 'Blender, coal, steel mfr'
  },
  {
    Id: 20911,
    Code: '8113',
    Name: 'Blender, colour, chemical mfr'
  },
  {
    Id: 20912,
    Code: '8113',
    Name: 'Blender, grease, mineral oil'
  },
  {
    Id: 20913,
    Code: '8113',
    Name: 'Blender, liquor'
  },
  {
    Id: 20914,
    Code: '8113',
    Name: 'Blender, oil'
  },
  {
    Id: 20915,
    Code: '8113',
    Name: 'Blender, pigment, chemicals'
  },
  {
    Id: 20916,
    Code: '8113',
    Name: 'Blender, varnish'
  },
  {
    Id: 20917,
    Code: '8113',
    Name: 'Blender'
  },
  {
    Id: 20918,
    Code: '8113',
    Name: 'Boiler, acid'
  },
  {
    Id: 20919,
    Code: '8113',
    Name: 'Boiler, fat'
  },
  {
    Id: 20920,
    Code: '8113',
    Name: 'Boiler, glue'
  },
  {
    Id: 20921,
    Code: '8113',
    Name: 'Boiler, grease'
  },
  {
    Id: 20922,
    Code: '8113',
    Name: 'Boiler, gum, textile bleaching, dyeing'
  },
  {
    Id: 20923,
    Code: '8113',
    Name: 'Boiler, oil'
  },
  {
    Id: 20924,
    Code: '8113',
    Name: 'Boiler, salt'
  },
  {
    Id: 20925,
    Code: '8113',
    Name: 'Boiler, size'
  },
  {
    Id: 20926,
    Code: '8113',
    Name: 'Boiler, soap'
  },
  {
    Id: 20927,
    Code: '8113',
    Name: 'Boiler, starch'
  },
  {
    Id: 20928,
    Code: '8113',
    Name: 'Boiler, tar, gas works'
  },
  {
    Id: 20929,
    Code: '8113',
    Name: 'Boiler, chemicals'
  },
  {
    Id: 20930,
    Code: '8113',
    Name: 'Boilerman, gelatine, glue, size mfr'
  },
  {
    Id: 20931,
    Code: '8113',
    Name: 'Breaker, slag'
  },
  {
    Id: 20932,
    Code: '8113',
    Name: 'Brightener, oil'
  },
  {
    Id: 20933,
    Code: '8113',
    Name: 'Bruiser, leather dressing'
  },
  {
    Id: 20934,
    Code: '8113',
    Name: 'Brusher, glove'
  },
  {
    Id: 20935,
    Code: '8113',
    Name: 'Brusher, leather finishing'
  },
  {
    Id: 20936,
    Code: '8113',
    Name: 'Bunkerman, kiln, chemical mfr'
  },
  {
    Id: 20937,
    Code: '8113',
    Name: 'Bunkerman, kiln, lime burning'
  },
  {
    Id: 20938,
    Code: '8113',
    Name: 'Bunkerman, chemical mfr'
  },
  {
    Id: 20939,
    Code: '8113',
    Name: 'Burner, chalk'
  },
  {
    Id: 20940,
    Code: '8113',
    Name: 'Burner, furnace, rotary, aluminium refining'
  },
  {
    Id: 20941,
    Code: '8113',
    Name: 'Burner, gas, coal gas, coke ovens'
  },
  {
    Id: 20942,
    Code: '8113',
    Name: 'Burner, gypsum'
  },
  {
    Id: 20943,
    Code: '8113',
    Name: 'Burner, kiln, carbon goods mfr'
  },
  {
    Id: 20944,
    Code: '8113',
    Name: 'Burner, charcoal mfr'
  },
  {
    Id: 20945,
    Code: '8113',
    Name: 'Burner, chemical mfr'
  },
  {
    Id: 20946,
    Code: '8113',
    Name: 'Burner-off, incandescent mantles'
  },
  {
    Id: 20947,
    Code: '8113',
    Name: 'Burnerman, acid'
  },
  {
    Id: 20948,
    Code: '8113',
    Name: 'Burnerman'
  },
  {
    Id: 20949,
    Code: '8113',
    Name: 'Burrer'
  },
  {
    Id: 20950,
    Code: '8113',
    Name: 'Cager'
  },
  {
    Id: 20951,
    Code: '8113',
    Name: 'Calciner'
  },
  {
    Id: 20952,
    Code: '8113',
    Name: 'Calenderman, asbestos-cement goods mfr'
  },
  {
    Id: 20953,
    Code: '8113',
    Name: 'Carboniser, gas works'
  },
  {
    Id: 20954,
    Code: '8113',
    Name: 'Carrotter'
  },
  {
    Id: 20955,
    Code: '8113',
    Name: 'Causticizer'
  },
  {
    Id: 20956,
    Code: '8113',
    Name: 'Chamberman, acids'
  },
  {
    Id: 20957,
    Code: '8113',
    Name: 'Chamberman, chemical mfr'
  },
  {
    Id: 20958,
    Code: '8113',
    Name: 'Charger, kiln'
  },
  {
    Id: 20959,
    Code: '8113',
    Name: 'Cleaner, bone'
  },
  {
    Id: 20960,
    Code: '8113',
    Name: 'Cleaner, hide'
  },
  {
    Id: 20961,
    Code: '8113',
    Name: 'Cleaner, sponge'
  },
  {
    Id: 20962,
    Code: '8113',
    Name: 'Cleaner, fellmongering'
  },
  {
    Id: 20963,
    Code: '8113',
    Name: 'Cleanser, soap, detergent mfr'
  },
  {
    Id: 20964,
    Code: '8113',
    Name: 'Clipper, tannery'
  },
  {
    Id: 20965,
    Code: '8113',
    Name: 'Coater, celluloid, film mfr'
  },
  {
    Id: 20966,
    Code: '8113',
    Name: 'Coater, emulsion'
  },
  {
    Id: 20967,
    Code: '8113',
    Name: 'Coater, paper, photographic'
  },
  {
    Id: 20968,
    Code: '8113',
    Name: 'Coater, plate, dry'
  },
  {
    Id: 20969,
    Code: '8113',
    Name: 'Coater, sugar, pharmaceutical mfr'
  },
  {
    Id: 20970,
    Code: '8113',
    Name: 'Coater, tablet'
  },
  {
    Id: 20971,
    Code: '8113',
    Name: 'Coater, tar, coal gas, coke ovens'
  },
  {
    Id: 20972,
    Code: '8113',
    Name: 'Coater, photographic film mfr'
  },
  {
    Id: 20973,
    Code: '8113',
    Name: 'Coker, coal gas, coke ovens'
  },
  {
    Id: 20974,
    Code: '8113',
    Name: 'Colourman'
  },
  {
    Id: 20975,
    Code: '8113',
    Name: 'Comber, fur dressing'
  },
  {
    Id: 20976,
    Code: '8113',
    Name: 'Compounder'
  },
  {
    Id: 20977,
    Code: '8113',
    Name: 'Compressor, tablet'
  },
  {
    Id: 20978,
    Code: '8113',
    Name: 'Concentrator'
  },
  {
    Id: 20979,
    Code: '8113',
    Name: 'Conditioner, tannery'
  },
  {
    Id: 20980,
    Code: '8113',
    Name: 'Controlman'
  },
  {
    Id: 20981,
    Code: '8113',
    Name: 'Converter, polythene'
  },
  {
    Id: 20982,
    Code: '8113',
    Name: 'Cooler, chemical mfr'
  },
  {
    Id: 20983,
    Code: '8113',
    Name: 'Corrugator, asbestos-cement goods mfr'
  },
  {
    Id: 20984,
    Code: '8113',
    Name: 'Crusher, calamine'
  },
  {
    Id: 20985,
    Code: '8113',
    Name: 'Crusher, chemicals'
  },
  {
    Id: 20986,
    Code: '8113',
    Name: 'Cureman'
  },
  {
    Id: 20987,
    Code: '8113',
    Name: 'Curer, skins'
  },
  {
    Id: 20988,
    Code: '8113',
    Name: 'Currier'
  },
  {
    Id: 20989,
    Code: '8113',
    Name: 'Cutter, leather, tannery'
  },
  {
    Id: 20990,
    Code: '8113',
    Name: 'Cutter, sheet, asbestos'
  },
  {
    Id: 20991,
    Code: '8113',
    Name: 'Cutter, skin, tannery'
  },
  {
    Id: 20992,
    Code: '8113',
    Name: 'Cutter, soap'
  },
  {
    Id: 20993,
    Code: '8113',
    Name: 'Cutter, sponge'
  },
  {
    Id: 20994,
    Code: '8113',
    Name: 'Cutter, glue mfr'
  },
  {
    Id: 20995,
    Code: '8113',
    Name: 'Cutter, tannery'
  },
  {
    Id: 20996,
    Code: '8113',
    Name: 'Degreaser, tannery'
  },
  {
    Id: 20997,
    Code: '8113',
    Name: 'Delimer'
  },
  {
    Id: 20998,
    Code: '8113',
    Name: 'Denierer, man-made fibre mfr'
  },
  {
    Id: 20999,
    Code: '8113',
    Name: 'Digester'
  },
  {
    Id: 21000,
    Code: '8113',
    Name: 'Dipper, leather dressing'
  },
  {
    Id: 21001,
    Code: '8113',
    Name: 'Distiller'
  },
  {
    Id: 21002,
    Code: '8113',
    Name: 'Doubler, textile mfr: textile bleaching, dyeing'
  },
  {
    Id: 21003,
    Code: '8113',
    Name: 'Drawer, kiln, chemical mfr'
  },
  {
    Id: 21004,
    Code: '8113',
    Name: 'Drencher'
  },
  {
    Id: 21005,
    Code: '8113',
    Name: 'Dresser, fur'
  },
  {
    Id: 21006,
    Code: '8113',
    Name: 'Dresser, leather'
  },
  {
    Id: 21007,
    Code: '8113',
    Name: 'Dresser, skin'
  },
  {
    Id: 21008,
    Code: '8113',
    Name: 'Dresser, fur dressing'
  },
  {
    Id: 21009,
    Code: '8113',
    Name: 'Dresser, leather dressing'
  },
  {
    Id: 21010,
    Code: '8113',
    Name: 'Driver, machine, asbestos-cement goods mfr'
  },
  {
    Id: 21011,
    Code: '8113',
    Name: 'Dropper, oil refining'
  },
  {
    Id: 21012,
    Code: '8113',
    Name: 'Drum and cagehand, tannery'
  },
  {
    Id: 21013,
    Code: '8113',
    Name: 'Drumhand'
  },
  {
    Id: 21014,
    Code: '8113',
    Name: 'Drummer, tannery'
  },
  {
    Id: 21015,
    Code: '8113',
    Name: 'Dryer, colour, dyestuffs mfr'
  },
  {
    Id: 21016,
    Code: '8113',
    Name: 'Dryer, gelatine'
  },
  {
    Id: 21017,
    Code: '8113',
    Name: 'Dryer, glue'
  },
  {
    Id: 21018,
    Code: '8113',
    Name: 'Dryer, chemical mfr'
  },
  {
    Id: 21019,
    Code: '8113',
    Name: 'Dryer, soap, detergent mfr'
  },
  {
    Id: 21020,
    Code: '8113',
    Name: 'Dryer, tannery'
  },
  {
    Id: 21021,
    Code: '8113',
    Name: 'Dyer, beam'
  },
  {
    Id: 21022,
    Code: '8113',
    Name: 'Dyer, black, textile mfr'
  },
  {
    Id: 21023,
    Code: '8113',
    Name: 'Dyer, brush, leather dressing'
  },
  {
    Id: 21024,
    Code: '8113',
    Name: 'Dyer, calico'
  },
  {
    Id: 21025,
    Code: '8113',
    Name: 'Dyer, carpet'
  },
  {
    Id: 21026,
    Code: '8113',
    Name: 'Dyer, clothes'
  },
  {
    Id: 21027,
    Code: '8113',
    Name: 'Dyer, colour'
  },
  {
    Id: 21028,
    Code: '8113',
    Name: 'Dyer, cop'
  },
  {
    Id: 21029,
    Code: '8113',
    Name: 'Dyer, cord'
  },
  {
    Id: 21030,
    Code: '8113',
    Name: 'Dyer, fibre'
  },
  {
    Id: 21031,
    Code: '8113',
    Name: 'Dyer, fur'
  },
  {
    Id: 21032,
    Code: '8113',
    Name: 'Dyer, fustian'
  },
  {
    Id: 21033,
    Code: '8113',
    Name: 'Dyer, garment'
  },
  {
    Id: 21034,
    Code: '8113',
    Name: 'Dyer, glove'
  },
  {
    Id: 21035,
    Code: '8113',
    Name: 'Dyer, hair'
  },
  {
    Id: 21036,
    Code: '8113',
    Name: 'Dyer, hank'
  },
  {
    Id: 21037,
    Code: '8113',
    Name: 'Dyer, hat'
  },
  {
    Id: 21038,
    Code: '8113',
    Name: 'Dyer, jig'
  },
  {
    Id: 21039,
    Code: '8113',
    Name: 'Dyer, job'
  },
  {
    Id: 21040,
    Code: '8113',
    Name: 'Dyer, operative'
  },
  {
    Id: 21041,
    Code: '8113',
    Name: 'Dyer, piece'
  },
  {
    Id: 21042,
    Code: '8113',
    Name: 'Dyer, skein'
  },
  {
    Id: 21043,
    Code: '8113',
    Name: 'Dyer, skin'
  },
  {
    Id: 21044,
    Code: '8113',
    Name: 'Dyer, vat'
  },
  {
    Id: 21045,
    Code: '8113',
    Name: 'Dyer, vessel'
  },
  {
    Id: 21046,
    Code: '8113',
    Name: 'Dyer, warp'
  },
  {
    Id: 21047,
    Code: '8113',
    Name: 'Dyer, winch'
  },
  {
    Id: 21048,
    Code: '8113',
    Name: 'Dyer, yarn'
  },
  {
    Id: 21049,
    Code: '8113',
    Name: 'Dyer'
  },
  {
    Id: 21050,
    Code: '8113',
    Name: 'Embosser, leather dressing'
  },
  {
    Id: 21051,
    Code: '8113',
    Name: 'Emptier, charcoal mfr'
  },
  {
    Id: 21052,
    Code: '8113',
    Name: 'Extractor, chemical mfr'
  },
  {
    Id: 21053,
    Code: '8113',
    Name: 'Fellmonger'
  },
  {
    Id: 21054,
    Code: '8113',
    Name: 'Filterer, chemical mfr'
  },
  {
    Id: 21055,
    Code: '8113',
    Name: 'Finisher, caustic'
  },
  {
    Id: 21056,
    Code: '8113',
    Name: 'Finisher, dyers'
  },
  {
    Id: 21057,
    Code: '8113',
    Name: 'Finisher, fur'
  },
  {
    Id: 21058,
    Code: '8113',
    Name: 'Finisher, leather'
  },
  {
    Id: 21059,
    Code: '8113',
    Name: 'Finisher, leather dressing'
  },
  {
    Id: 21060,
    Code: '8113',
    Name: 'Finisher, photographic film mfr'
  },
  {
    Id: 21061,
    Code: '8113',
    Name: 'Fireman, gas'
  },
  {
    Id: 21062,
    Code: '8113',
    Name: 'Fireman, pot'
  },
  {
    Id: 21063,
    Code: '8113',
    Name: 'Fireman, retort'
  },
  {
    Id: 21064,
    Code: '8113',
    Name: 'Fireman, chemical mfr'
  },
  {
    Id: 21065,
    Code: '8113',
    Name: 'Fireman, coal gas, coke ovens'
  },
  {
    Id: 21066,
    Code: '8113',
    Name: 'Fireman, composition die mfr'
  },
  {
    Id: 21067,
    Code: '8113',
    Name: 'Fireman, oil refining'
  },
  {
    Id: 21068,
    Code: '8113',
    Name: 'Fireman, pencil, crayon mfr'
  },
  {
    Id: 21069,
    Code: '8113',
    Name: 'Fireman, salt mfr'
  },
  {
    Id: 21070,
    Code: '8113',
    Name: 'Firer, chemical mfr'
  },
  {
    Id: 21071,
    Code: '8113',
    Name: 'Flesher'
  },
  {
    Id: 21072,
    Code: '8113',
    Name: 'Fluffer'
  },
  {
    Id: 21073,
    Code: '8113',
    Name: 'Fractionator, chemical mfr'
  },
  {
    Id: 21074,
    Code: '8113',
    Name: 'Furnaceman, barium'
  },
  {
    Id: 21075,
    Code: '8113',
    Name: 'Furnaceman, calcining'
  },
  {
    Id: 21076,
    Code: '8113',
    Name: 'Furnaceman, graphitising'
  },
  {
    Id: 21077,
    Code: '8113',
    Name: 'Furnaceman, chemical mfr'
  },
  {
    Id: 21078,
    Code: '8113',
    Name: 'Furnaceman, gas works'
  },
  {
    Id: 21079,
    Code: '8113',
    Name: 'Furnaceman, lead pencil, chalk, crayon mfr'
  },
  {
    Id: 21080,
    Code: '8113',
    Name: 'Furnaceman, oil refining'
  },
  {
    Id: 21081,
    Code: '8113',
    Name: 'Furnaceman, red lead mfr'
  },
  {
    Id: 21082,
    Code: '8113',
    Name: 'Gasman, coal gas'
  },
  {
    Id: 21083,
    Code: '8113',
    Name: 'Gasman, water gas'
  },
  {
    Id: 21084,
    Code: '8113',
    Name: 'Gasman, gas works'
  },
  {
    Id: 21085,
    Code: '8113',
    Name: 'Generator, acetylene'
  },
  {
    Id: 21086,
    Code: '8113',
    Name: 'Gilder, leather dressing'
  },
  {
    Id: 21087,
    Code: '8113',
    Name: 'Glazer, leather dressing'
  },
  {
    Id: 21088,
    Code: '8113',
    Name: 'Grader, coke'
  },
  {
    Id: 21089,
    Code: '8113',
    Name: 'Grader, chemical mfr'
  },
  {
    Id: 21090,
    Code: '8113',
    Name: 'Grainer, leather dressing'
  },
  {
    Id: 21091,
    Code: '8113',
    Name: 'Granulator, chemical mfr'
  },
  {
    Id: 21092,
    Code: '8113',
    Name: 'Grinder, bone'
  },
  {
    Id: 21093,
    Code: '8113',
    Name: 'Grinder, carbide'
  },
  {
    Id: 21094,
    Code: '8113',
    Name: 'Grinder, carbon, crucible mfr'
  },
  {
    Id: 21095,
    Code: '8113',
    Name: 'Grinder, colour'
  },
  {
    Id: 21096,
    Code: '8113',
    Name: 'Grinder, enamel'
  },
  {
    Id: 21097,
    Code: '8113',
    Name: 'Grinder, gelatine'
  },
  {
    Id: 21098,
    Code: '8113',
    Name: 'Grinder, ink'
  },
  {
    Id: 21099,
    Code: '8113',
    Name: 'Grinder, paint'
  },
  {
    Id: 21100,
    Code: '8113',
    Name: 'Grinder, resin'
  },
  {
    Id: 21101,
    Code: '8113',
    Name: 'Grinder, slag'
  },
  {
    Id: 21102,
    Code: '8113',
    Name: 'Grinder, soap, dry'
  },
  {
    Id: 21103,
    Code: '8113',
    Name: 'Grinder, wet, chemicals'
  },
  {
    Id: 21104,
    Code: '8113',
    Name: 'Grinder, chemical mfr'
  },
  {
    Id: 21105,
    Code: '8113',
    Name: 'Grinder, patent fuel mfr'
  },
  {
    Id: 21106,
    Code: '8113',
    Name: 'Hammerman, leather dressing'
  },
  {
    Id: 21107,
    Code: '8113',
    Name: 'Hand, bench, chemical mfr'
  },
  {
    Id: 21108,
    Code: '8113',
    Name: 'Hand, bleach, textile mfr'
  },
  {
    Id: 21109,
    Code: '8113',
    Name: 'Hand, boiling, soap'
  },
  {
    Id: 21110,
    Code: '8113',
    Name: 'Hand, explosive'
  },
  {
    Id: 21111,
    Code: '8113',
    Name: 'Hand, filtration, chemical mfr'
  },
  {
    Id: 21112,
    Code: '8113',
    Name: 'Hand, furnace, charcoal mfr'
  },
  {
    Id: 21113,
    Code: '8113',
    Name: 'Hand, installation, oil refining'
  },
  {
    Id: 21114,
    Code: '8113',
    Name: 'Hand, mill, finishing'
  },
  {
    Id: 21115,
    Code: '8113',
    Name: 'Hand, mill, grinding'
  },
  {
    Id: 21116,
    Code: '8113',
    Name: 'Hand, mill, ink'
  },
  {
    Id: 21117,
    Code: '8113',
    Name: 'Hand, mill, tints'
  },
  {
    Id: 21118,
    Code: '8113',
    Name: 'Hand, mill, chemical mfr'
  },
  {
    Id: 21119,
    Code: '8113',
    Name: 'Hand, milling, soap, detergent mfr'
  },
  {
    Id: 21120,
    Code: '8113',
    Name: 'Hand, plant, acid'
  },
  {
    Id: 21121,
    Code: '8113',
    Name: 'Hand, screen, coal gas, coke ovens'
  },
  {
    Id: 21122,
    Code: '8113',
    Name: 'Hand, set'
  },
  {
    Id: 21123,
    Code: '8113',
    Name: 'Hand, trowel, cement mfr'
  },
  {
    Id: 21124,
    Code: '8113',
    Name: 'Handler, mud, press, red'
  },
  {
    Id: 21125,
    Code: '8113',
    Name: 'Handler, tannery'
  },
  {
    Id: 21126,
    Code: '8113',
    Name: 'Haulier, butt'
  },
  {
    Id: 21127,
    Code: '8113',
    Name: 'Heater, oven, coke'
  },
  {
    Id: 21128,
    Code: '8113',
    Name: 'Heater, retort, chemical mfr'
  },
  {
    Id: 21129,
    Code: '8113',
    Name: 'Heater, coal gas, coke ovens'
  },
  {
    Id: 21130,
    Code: '8113',
    Name: 'Heaterman, patent fuel mfr'
  },
  {
    Id: 21131,
    Code: '8113',
    Name: "Helper, worker's, process"
  },
  {
    Id: 21132,
    Code: '8113',
    Name: 'Helper, salt mfr'
  },
  {
    Id: 21133,
    Code: '8113',
    Name: 'Hollanderman'
  },
  {
    Id: 21134,
    Code: '8113',
    Name: 'Houseman, cylinder'
  },
  {
    Id: 21135,
    Code: '8113',
    Name: 'Impregnator, armature'
  },
  {
    Id: 21136,
    Code: '8113',
    Name: 'Impregnator, asbestos composition goods mfr'
  },
  {
    Id: 21137,
    Code: '8113',
    Name: 'Ironer, leather dressing'
  },
  {
    Id: 21138,
    Code: '8113',
    Name: 'Japanner, leather dressing'
  },
  {
    Id: 21139,
    Code: '8113',
    Name: 'Jigger, leather dressing'
  },
  {
    Id: 21140,
    Code: '8113',
    Name: 'Jigger, textile bleaching, dyeing'
  },
  {
    Id: 21141,
    Code: '8113',
    Name: 'Jiggerman'
  },
  {
    Id: 21142,
    Code: '8113',
    Name: 'Jobber, leather dressing'
  },
  {
    Id: 21143,
    Code: '8113',
    Name: 'Jobber, textile mfr: textile bleaching, dyeing'
  },
  {
    Id: 21144,
    Code: '8113',
    Name: "Labourer, dyer's"
  },
  {
    Id: 21145,
    Code: '8113',
    Name: 'Labourer, house, dye'
  },
  {
    Id: 21146,
    Code: '8113',
    Name: 'Layer-up, tannery'
  },
  {
    Id: 21147,
    Code: '8113',
    Name: 'Lifter, butt'
  },
  {
    Id: 21148,
    Code: '8113',
    Name: 'Limer, fellmongering'
  },
  {
    Id: 21149,
    Code: '8113',
    Name: 'Loader, charcoal mfr'
  },
  {
    Id: 21150,
    Code: '8113',
    Name: 'Lofter, salt'
  },
  {
    Id: 21151,
    Code: '8113',
    Name: 'Machinist, brushing, leather dressing'
  },
  {
    Id: 21152,
    Code: '8113',
    Name: 'Machinist, buffing, leather dressing'
  },
  {
    Id: 21153,
    Code: '8113',
    Name: 'Machinist, carding, fur dressing'
  },
  {
    Id: 21154,
    Code: '8113',
    Name: 'Machinist, casting, film'
  },
  {
    Id: 21155,
    Code: '8113',
    Name: 'Machinist, casting, transparent cellulose wrappings mfr'
  },
  {
    Id: 21156,
    Code: '8113',
    Name: 'Machinist, coating, photographic films'
  },
  {
    Id: 21157,
    Code: '8113',
    Name: 'Machinist, compressing, tablets, pills'
  },
  {
    Id: 21158,
    Code: '8113',
    Name: 'Machinist, cutting, asbestos'
  },
  {
    Id: 21159,
    Code: '8113',
    Name: 'Machinist, cutting, soap, detergent mfr'
  },
  {
    Id: 21160,
    Code: '8113',
    Name: 'Machinist, dressing, pelt'
  },
  {
    Id: 21161,
    Code: '8113',
    Name: 'Machinist, embossing, leather dressing'
  },
  {
    Id: 21162,
    Code: '8113',
    Name: 'Machinist, film, cellulose'
  },
  {
    Id: 21163,
    Code: '8113',
    Name: 'Machinist, filter, celluloid'
  },
  {
    Id: 21164,
    Code: '8113',
    Name: 'Machinist, fleshing'
  },
  {
    Id: 21165,
    Code: '8113',
    Name: 'Machinist, frizing, tannery'
  },
  {
    Id: 21166,
    Code: '8113',
    Name: 'Machinist, graphite'
  },
  {
    Id: 21167,
    Code: '8113',
    Name: 'Machinist, grinding, paint mfr'
  },
  {
    Id: 21168,
    Code: '8113',
    Name: 'Machinist, hide, leather merchants'
  },
  {
    Id: 21169,
    Code: '8113',
    Name: 'Machinist, impregnating, plastics goods mfr'
  },
  {
    Id: 21170,
    Code: '8113',
    Name: 'Machinist, jigging'
  },
  {
    Id: 21171,
    Code: '8113',
    Name: 'Machinist, paint, paint mfr'
  },
  {
    Id: 21172,
    Code: '8113',
    Name: 'Machinist, powder, soap'
  },
  {
    Id: 21173,
    Code: '8113',
    Name: 'Machinist, rotary, textile bleaching, dyeing'
  },
  {
    Id: 21174,
    Code: '8113',
    Name: 'Machinist, setting, leather dressing'
  },
  {
    Id: 21175,
    Code: '8113',
    Name: 'Machinist, splitting, tannery'
  },
  {
    Id: 21176,
    Code: '8113',
    Name: 'Machinist, staking'
  },
  {
    Id: 21177,
    Code: '8113',
    Name: 'Machinist, tableting, tablets, pills'
  },
  {
    Id: 21178,
    Code: '8113',
    Name: 'Machinist, chemical mfr'
  },
  {
    Id: 21179,
    Code: '8113',
    Name: 'Machinist, leather dressing'
  },
  {
    Id: 21180,
    Code: '8113',
    Name: 'Machinist, photographic film mfr'
  },
  {
    Id: 21181,
    Code: '8113',
    Name: 'Machinist, plastics mfr'
  },
  {
    Id: 21182,
    Code: '8113',
    Name: 'Machinist, salt mfr'
  },
  {
    Id: 21183,
    Code: '8113',
    Name: 'Machinist, soap, detergent mfr'
  },
  {
    Id: 21184,
    Code: '8113',
    Name: 'Machinist, synthetics mfr'
  },
  {
    Id: 21185,
    Code: '8113',
    Name: 'Machinist, tannery'
  },
  {
    Id: 21186,
    Code: '8113',
    Name: 'Machinist, textile mfr: man-made fibre mfr'
  },
  {
    Id: 21187,
    Code: '8113',
    Name: 'Machinist, textile mfr: textile bleaching, dyeing'
  },
  {
    Id: 21188,
    Code: '8113',
    Name: 'Maker, acetate'
  },
  {
    Id: 21189,
    Code: '8113',
    Name: 'Maker, acid'
  },
  {
    Id: 21190,
    Code: '8113',
    Name: 'Maker, ammunition'
  },
  {
    Id: 21191,
    Code: '8113',
    Name: 'Maker, block, fuel, patent fuel'
  },
  {
    Id: 21192,
    Code: '8113',
    Name: 'Maker, briquette'
  },
  {
    Id: 21193,
    Code: '8113',
    Name: 'Maker, capsule, drug mfr'
  },
  {
    Id: 21194,
    Code: '8113',
    Name: 'Maker, carbon, carbon goods mfr'
  },
  {
    Id: 21195,
    Code: '8113',
    Name: 'Maker, carmine'
  },
  {
    Id: 21196,
    Code: '8113',
    Name: 'Maker, cellulose'
  },
  {
    Id: 21197,
    Code: '8113',
    Name: 'Maker, chemical, fine'
  },
  {
    Id: 21198,
    Code: '8113',
    Name: 'Maker, chemicals, fine'
  },
  {
    Id: 21199,
    Code: '8113',
    Name: 'Maker, chloride, ammonium'
  },
  {
    Id: 21200,
    Code: '8113',
    Name: 'Maker, chloroform'
  },
  {
    Id: 21201,
    Code: '8113',
    Name: 'Maker, colour'
  },
  {
    Id: 21202,
    Code: '8113',
    Name: 'Maker, composition'
  },
  {
    Id: 21203,
    Code: '8113',
    Name: 'Maker, corticine'
  },
  {
    Id: 21204,
    Code: '8113',
    Name: 'Maker, cosmetic'
  },
  {
    Id: 21205,
    Code: '8113',
    Name: 'Maker, cream, cosmetics mfr'
  },
  {
    Id: 21206,
    Code: '8113',
    Name: 'Maker, crystal'
  },
  {
    Id: 21207,
    Code: '8113',
    Name: 'Maker, dioxide, carbon'
  },
  {
    Id: 21208,
    Code: '8113',
    Name: 'Maker, dye'
  },
  {
    Id: 21209,
    Code: '8113',
    Name: 'Maker, emulsion'
  },
  {
    Id: 21210,
    Code: '8113',
    Name: 'Maker, enamel'
  },
  {
    Id: 21211,
    Code: '8113',
    Name: 'Maker, fertilizer'
  },
  {
    Id: 21212,
    Code: '8113',
    Name: 'Maker, fibre, man-made'
  },
  {
    Id: 21213,
    Code: '8113',
    Name: 'Maker, fibre, synthetic'
  },
  {
    Id: 21214,
    Code: '8113',
    Name: 'Maker, filament, continuous'
  },
  {
    Id: 21215,
    Code: '8113',
    Name: 'Maker, gas'
  },
  {
    Id: 21216,
    Code: '8113',
    Name: 'Maker, glue'
  },
  {
    Id: 21217,
    Code: '8113',
    Name: 'Maker, grease'
  },
  {
    Id: 21218,
    Code: '8113',
    Name: 'Maker, ink'
  },
  {
    Id: 21219,
    Code: '8113',
    Name: 'Maker, lacquer'
  },
  {
    Id: 21220,
    Code: '8113',
    Name: 'Maker, lead, red'
  },
  {
    Id: 21221,
    Code: '8113',
    Name: 'Maker, lead, white'
  },
  {
    Id: 21222,
    Code: '8113',
    Name: 'Maker, lime'
  },
  {
    Id: 21223,
    Code: '8113',
    Name: 'Maker, mat, sheepskin'
  },
  {
    Id: 21224,
    Code: '8113',
    Name: 'Maker, oil'
  },
  {
    Id: 21225,
    Code: '8113',
    Name: 'Maker, ointment'
  },
  {
    Id: 21226,
    Code: '8113',
    Name: 'Maker, oxide, lead'
  },
  {
    Id: 21227,
    Code: '8113',
    Name: 'Maker, paint'
  },
  {
    Id: 21228,
    Code: '8113',
    Name: 'Maker, paste, chemical mfr'
  },
  {
    Id: 21229,
    Code: '8113',
    Name: 'Maker, paste, paper goods mfr'
  },
  {
    Id: 21230,
    Code: '8113',
    Name: 'Maker, perfumery'
  },
  {
    Id: 21231,
    Code: '8113',
    Name: 'Maker, petroleum'
  },
  {
    Id: 21232,
    Code: '8113',
    Name: 'Maker, pigment'
  },
  {
    Id: 21233,
    Code: '8113',
    Name: 'Maker, pill'
  },
  {
    Id: 21234,
    Code: '8113',
    Name: 'Maker, pipe, asbestos'
  },
  {
    Id: 21235,
    Code: '8113',
    Name: 'Maker, polish'
  },
  {
    Id: 21236,
    Code: '8113',
    Name: 'Maker, powder, chemical'
  },
  {
    Id: 21237,
    Code: '8113',
    Name: 'Maker, resin'
  },
  {
    Id: 21238,
    Code: '8113',
    Name: 'Maker, saline'
  },
  {
    Id: 21239,
    Code: '8113',
    Name: 'Maker, salt'
  },
  {
    Id: 21240,
    Code: '8113',
    Name: 'Maker, size'
  },
  {
    Id: 21241,
    Code: '8113',
    Name: 'Maker, slip, pencil, crayon mfr'
  },
  {
    Id: 21242,
    Code: '8113',
    Name: 'Maker, soap'
  },
  {
    Id: 21243,
    Code: '8113',
    Name: 'Maker, solution, celluloid'
  },
  {
    Id: 21244,
    Code: '8113',
    Name: 'Maker, spirit'
  },
  {
    Id: 21245,
    Code: '8113',
    Name: 'Maker, starch, textile mfr'
  },
  {
    Id: 21246,
    Code: '8113',
    Name: 'Maker, sulphate'
  },
  {
    Id: 21247,
    Code: '8113',
    Name: 'Maker, tablet, pharmaceutical mfr'
  },
  {
    Id: 21248,
    Code: '8113',
    Name: 'Maker, tallow'
  },
  {
    Id: 21249,
    Code: '8113',
    Name: 'Maker, varnish'
  },
  {
    Id: 21250,
    Code: '8113',
    Name: 'Maker, white, wallpaper mfr'
  },
  {
    Id: 21251,
    Code: '8113',
    Name: 'Man, acid'
  },
  {
    Id: 21252,
    Code: '8113',
    Name: 'Man, bench, chemical mfr'
  },
  {
    Id: 21253,
    Code: '8113',
    Name: 'Man, cake, salt'
  },
  {
    Id: 21254,
    Code: '8113',
    Name: 'Man, carbonator'
  },
  {
    Id: 21255,
    Code: '8113',
    Name: 'Man, catalyst'
  },
  {
    Id: 21256,
    Code: '8113',
    Name: 'Man, cellroom'
  },
  {
    Id: 21257,
    Code: '8113',
    Name: 'Man, chlorate of soda'
  },
  {
    Id: 21258,
    Code: '8113',
    Name: 'Man, condenser'
  },
  {
    Id: 21259,
    Code: '8113',
    Name: 'Man, converter, chemical mfr'
  },
  {
    Id: 21260,
    Code: '8113',
    Name: 'Man, dehydrator, tar'
  },
  {
    Id: 21261,
    Code: '8113',
    Name: 'Man, dreep, coal gas by-products mfr'
  },
  {
    Id: 21262,
    Code: '8113',
    Name: 'Man, drum, tannery'
  },
  {
    Id: 21263,
    Code: '8113',
    Name: 'Man, evaporator, chemical mfr'
  },
  {
    Id: 21264,
    Code: '8113',
    Name: 'Man, extractor, fat'
  },
  {
    Id: 21265,
    Code: '8113',
    Name: 'Man, extractor, chemical mfr'
  },
  {
    Id: 21266,
    Code: '8113',
    Name: 'Man, filter, chemical mfr'
  },
  {
    Id: 21267,
    Code: '8113',
    Name: 'Man, gelatine, explosives mfr'
  },
  {
    Id: 21268,
    Code: '8113',
    Name: 'Man, generator'
  },
  {
    Id: 21269,
    Code: '8113',
    Name: 'Man, grease'
  },
  {
    Id: 21270,
    Code: '8113',
    Name: 'Man, house, char'
  },
  {
    Id: 21271,
    Code: '8113',
    Name: 'Man, house, still, distillery'
  },
  {
    Id: 21272,
    Code: '8113',
    Name: 'Man, kiln, carbon'
  },
  {
    Id: 21273,
    Code: '8113',
    Name: 'Man, kiln, char'
  },
  {
    Id: 21274,
    Code: '8113',
    Name: 'Man, kiln, asbestos composition goods mfr'
  },
  {
    Id: 21275,
    Code: '8113',
    Name: 'Man, kiln, chemical mfr'
  },
  {
    Id: 21276,
    Code: '8113',
    Name: 'Man, kiln, composition die mfr'
  },
  {
    Id: 21277,
    Code: '8113',
    Name: 'Man, lime'
  },
  {
    Id: 21278,
    Code: '8113',
    Name: 'Man, liquor, leather tanning'
  },
  {
    Id: 21279,
    Code: '8113',
    Name: 'Man, lump, salt mfr'
  },
  {
    Id: 21280,
    Code: '8113',
    Name: 'Man, machine, asbestos composition goods mfr'
  },
  {
    Id: 21281,
    Code: '8113',
    Name: 'Man, mill, lead'
  },
  {
    Id: 21282,
    Code: '8113',
    Name: 'Man, mill, paint'
  },
  {
    Id: 21283,
    Code: '8113',
    Name: 'Man, mill, carbon goods mfr'
  },
  {
    Id: 21284,
    Code: '8113',
    Name: 'Man, mill, chemical mfr'
  },
  {
    Id: 21285,
    Code: '8113',
    Name: 'Man, mill, leather dressing'
  },
  {
    Id: 21286,
    Code: '8113',
    Name: 'Man, mixer, slag, tar'
  },
  {
    Id: 21287,
    Code: '8113',
    Name: 'Man, mixer, textile mfr'
  },
  {
    Id: 21288,
    Code: '8113',
    Name: 'Man, oxidiser'
  },
  {
    Id: 21289,
    Code: '8113',
    Name: 'Man, pan, acid'
  },
  {
    Id: 21290,
    Code: '8113',
    Name: 'Man, pan, chemical mfr'
  },
  {
    Id: 21291,
    Code: '8113',
    Name: 'Man, plant, benzol'
  },
  {
    Id: 21292,
    Code: '8113',
    Name: 'Man, plant, benzole'
  },
  {
    Id: 21293,
    Code: '8113',
    Name: 'Man, plant, chemical mfr'
  },
  {
    Id: 21294,
    Code: '8113',
    Name: 'Man, purification'
  },
  {
    Id: 21295,
    Code: '8113',
    Name: 'Man, purifier'
  },
  {
    Id: 21296,
    Code: '8113',
    Name: 'Man, rectifying'
  },
  {
    Id: 21297,
    Code: '8113',
    Name: 'Man, retort, charcoal mfr'
  },
  {
    Id: 21298,
    Code: '8113',
    Name: 'Man, retort, coal gas, coke ovens'
  },
  {
    Id: 21299,
    Code: '8113',
    Name: 'Man, room, still, distillery'
  },
  {
    Id: 21300,
    Code: '8113',
    Name: 'Man, still'
  },
  {
    Id: 21301,
    Code: '8113',
    Name: 'Man, stillhouse, distillery'
  },
  {
    Id: 21302,
    Code: '8113',
    Name: 'Man, stillroom, distillery'
  },
  {
    Id: 21303,
    Code: '8113',
    Name: 'Man, sulphonator'
  },
  {
    Id: 21304,
    Code: '8113',
    Name: 'Man, suspender'
  },
  {
    Id: 21305,
    Code: '8113',
    Name: 'Man, table, leather dressing'
  },
  {
    Id: 21306,
    Code: '8113',
    Name: 'Man, tar, coal gas by-products mfr'
  },
  {
    Id: 21307,
    Code: '8113',
    Name: 'Man, vessel, textile bleaching, dyeing'
  },
  {
    Id: 21308,
    Code: '8113',
    Name: 'Man, viscose, man-made fibre mfr'
  },
  {
    Id: 21309,
    Code: '8113',
    Name: 'Man, wax'
  },
  {
    Id: 21310,
    Code: '8113',
    Name: 'Manufacturer, chemical'
  },
  {
    Id: 21311,
    Code: '8113',
    Name: 'Manufacturer, gas'
  },
  {
    Id: 21312,
    Code: '8113',
    Name: 'Manufacturer, chemical mfr'
  },
  {
    Id: 21313,
    Code: '8113',
    Name: 'Manufacturer, cosmetics mfr'
  },
  {
    Id: 21314,
    Code: '8113',
    Name: 'Manufacturer, leather mfr'
  },
  {
    Id: 21315,
    Code: '8113',
    Name: 'Marbler, leather dressing'
  },
  {
    Id: 21316,
    Code: '8113',
    Name: 'Matcher, colour, paint mfr'
  },
  {
    Id: 21317,
    Code: '8113',
    Name: 'Matcher, colour, printing'
  },
  {
    Id: 21318,
    Code: '8113',
    Name: 'Melter, emulsion, photographic'
  },
  {
    Id: 21319,
    Code: '8113',
    Name: 'Melter, fat'
  },
  {
    Id: 21320,
    Code: '8113',
    Name: 'Melter, grease'
  },
  {
    Id: 21321,
    Code: '8113',
    Name: 'Methylator'
  },
  {
    Id: 21322,
    Code: '8113',
    Name: 'Miller, dyewood'
  },
  {
    Id: 21323,
    Code: '8113',
    Name: 'Miller, madder'
  },
  {
    Id: 21324,
    Code: '8113',
    Name: 'Miller, paint'
  },
  {
    Id: 21325,
    Code: '8113',
    Name: 'Miller, soap'
  },
  {
    Id: 21326,
    Code: '8113',
    Name: 'Miller, solvent'
  },
  {
    Id: 21327,
    Code: '8113',
    Name: 'Miller, chemical mfr'
  },
  {
    Id: 21328,
    Code: '8113',
    Name: 'Mixer, acid'
  },
  {
    Id: 21329,
    Code: '8113',
    Name: 'Mixer, batch, chemical mfr'
  },
  {
    Id: 21330,
    Code: '8113',
    Name: 'Mixer, bleach, paper'
  },
  {
    Id: 21331,
    Code: '8113',
    Name: 'Mixer, carbide, tungsten'
  },
  {
    Id: 21332,
    Code: '8113',
    Name: 'Mixer, carbon'
  },
  {
    Id: 21333,
    Code: '8113',
    Name: 'Mixer, chemical, textile mfr'
  },
  {
    Id: 21334,
    Code: '8113',
    Name: 'Mixer, chemicals, textile mfr'
  },
  {
    Id: 21335,
    Code: '8113',
    Name: 'Mixer, clay, paper mfr'
  },
  {
    Id: 21336,
    Code: '8113',
    Name: 'Mixer, colour'
  },
  {
    Id: 21337,
    Code: '8113',
    Name: 'Mixer, compound'
  },
  {
    Id: 21338,
    Code: '8113',
    Name: 'Mixer, cosmetic'
  },
  {
    Id: 21339,
    Code: '8113',
    Name: 'Mixer, depolariser'
  },
  {
    Id: 21340,
    Code: '8113',
    Name: 'Mixer, dope'
  },
  {
    Id: 21341,
    Code: '8113',
    Name: 'Mixer, dye'
  },
  {
    Id: 21342,
    Code: '8113',
    Name: 'Mixer, electrolyte, electric battery mfr'
  },
  {
    Id: 21343,
    Code: '8113',
    Name: 'Mixer, emulsion'
  },
  {
    Id: 21344,
    Code: '8113',
    Name: 'Mixer, enamel'
  },
  {
    Id: 21345,
    Code: '8113',
    Name: 'Mixer, explosives'
  },
  {
    Id: 21346,
    Code: '8113',
    Name: 'Mixer, fluid, engineering'
  },
  {
    Id: 21347,
    Code: '8113',
    Name: 'Mixer, glue'
  },
  {
    Id: 21348,
    Code: '8113',
    Name: 'Mixer, ink'
  },
  {
    Id: 21349,
    Code: '8113',
    Name: 'Mixer, lacquer'
  },
  {
    Id: 21350,
    Code: '8113',
    Name: 'Mixer, oil'
  },
  {
    Id: 21351,
    Code: '8113',
    Name: 'Mixer, paint'
  },
  {
    Id: 21352,
    Code: '8113',
    Name: 'Mixer, paste, lead'
  },
  {
    Id: 21353,
    Code: '8113',
    Name: 'Mixer, paste, paper goods mfr'
  },
  {
    Id: 21354,
    Code: '8113',
    Name: 'Mixer, polish, furniture'
  },
  {
    Id: 21355,
    Code: '8113',
    Name: 'Mixer, powder, fluorescent'
  },
  {
    Id: 21356,
    Code: '8113',
    Name: 'Mixer, resin'
  },
  {
    Id: 21357,
    Code: '8113',
    Name: 'Mixer, size, paper mfr'
  },
  {
    Id: 21358,
    Code: '8113',
    Name: 'Mixer, soap'
  },
  {
    Id: 21359,
    Code: '8113',
    Name: 'Mixer, solution'
  },
  {
    Id: 21360,
    Code: '8113',
    Name: 'Mixer, starch'
  },
  {
    Id: 21361,
    Code: '8113',
    Name: 'Mixer, accumulator, battery mfr'
  },
  {
    Id: 21362,
    Code: '8113',
    Name: 'Mixer, chemical mfr'
  },
  {
    Id: 21363,
    Code: '8113',
    Name: 'Mixer, composition die mfr'
  },
  {
    Id: 21364,
    Code: '8113',
    Name: 'Mixer, pencil, crayon mfr'
  },
  {
    Id: 21365,
    Code: '8113',
    Name: 'Mixer, textile mfr: textile proofing'
  },
  {
    Id: 21366,
    Code: '8113',
    Name: 'Moulder, pipe, asbestos-cement'
  },
  {
    Id: 21367,
    Code: '8113',
    Name: 'Muller'
  },
  {
    Id: 21368,
    Code: '8113',
    Name: 'Nailer, tannery'
  },
  {
    Id: 21369,
    Code: '8113',
    Name: 'Neutraliser, chemical mfr'
  },
  {
    Id: 21370,
    Code: '8113',
    Name: 'Oiler, mould, asbestos'
  },
  {
    Id: 21371,
    Code: '8113',
    Name: 'Oiler, skin, leather'
  },
  {
    Id: 21372,
    Code: '8113',
    Name: 'Oiler, leather dressing'
  },
  {
    Id: 21373,
    Code: '8113',
    Name: 'Oiler, varnish mfr'
  },
  {
    Id: 21374,
    Code: '8113',
    Name: 'Oilman, engine'
  },
  {
    Id: 21375,
    Code: '8113',
    Name: "Operative, dyer's"
  },
  {
    Id: 21376,
    Code: '8113',
    Name: 'Operative, house, dye, textile mfr'
  },
  {
    Id: 21377,
    Code: '8113',
    Name: 'Operator, autoclave, asbestos composition goods mfr'
  },
  {
    Id: 21378,
    Code: '8113',
    Name: 'Operator, autoclave, chemical mfr'
  },
  {
    Id: 21379,
    Code: '8113',
    Name: 'Operator, benzol'
  },
  {
    Id: 21380,
    Code: '8113',
    Name: 'Operator, benzole'
  },
  {
    Id: 21381,
    Code: '8113',
    Name: 'Operator, burner, kiln, carbon goods mfr'
  },
  {
    Id: 21382,
    Code: '8113',
    Name: 'Operator, capsulation'
  },
  {
    Id: 21383,
    Code: '8113',
    Name: 'Operator, centrifuge, chemicals'
  },
  {
    Id: 21384,
    Code: '8113',
    Name: 'Operator, chemical'
  },
  {
    Id: 21385,
    Code: '8113',
    Name: 'Operator, column, oxygen mfr'
  },
  {
    Id: 21386,
    Code: '8113',
    Name: 'Operator, densification, chemicals'
  },
  {
    Id: 21387,
    Code: '8113',
    Name: 'Operator, disintegrator, chemicals'
  },
  {
    Id: 21388,
    Code: '8113',
    Name: 'Operator, evaporator, chemical mfr'
  },
  {
    Id: 21389,
    Code: '8113',
    Name: 'Operator, extruder, chemical mfr'
  },
  {
    Id: 21390,
    Code: '8113',
    Name: 'Operator, extrusion, chemical mfr'
  },
  {
    Id: 21391,
    Code: '8113',
    Name: 'Operator, filter, drum, rotary, chemical mfr'
  },
  {
    Id: 21392,
    Code: '8113',
    Name: 'Operator, furnace, electric, enamelling'
  },
  {
    Id: 21393,
    Code: '8113',
    Name: 'Operator, gas'
  },
  {
    Id: 21394,
    Code: '8113',
    Name: 'Operator, glazing, explosives'
  },
  {
    Id: 21395,
    Code: '8113',
    Name: 'Operator, guide'
  },
  {
    Id: 21396,
    Code: '8113',
    Name: 'Operator, house, dye, textile mfr'
  },
  {
    Id: 21397,
    Code: '8113',
    Name: 'Operator, hydro-extractor, chemical mfr'
  },
  {
    Id: 21398,
    Code: '8113',
    Name: 'Operator, hydro-extractor, tannery'
  },
  {
    Id: 21399,
    Code: '8113',
    Name: 'Operator, kettle, chemical mfr'
  },
  {
    Id: 21400,
    Code: '8113',
    Name: 'Operator, manifold'
  },
  {
    Id: 21401,
    Code: '8113',
    Name: 'Operator, mill, ball, chemical mfr'
  },
  {
    Id: 21402,
    Code: '8113',
    Name: 'Operator, mill, pug, chemicals'
  },
  {
    Id: 21403,
    Code: '8113',
    Name: 'Operator, mill, sheeting, chemicals'
  },
  {
    Id: 21404,
    Code: '8113',
    Name: 'Operator, milling, soap, detergent mfr'
  },
  {
    Id: 21405,
    Code: '8113',
    Name: 'Operator, mixing'
  },
  {
    Id: 21406,
    Code: '8113',
    Name: 'Operator, oven, coke'
  },
  {
    Id: 21407,
    Code: '8113',
    Name: 'Operator, pan, vacuum, chemical mfr'
  },
  {
    Id: 21408,
    Code: '8113',
    Name: 'Operator, plant, benzol'
  },
  {
    Id: 21409,
    Code: '8113',
    Name: 'Operator, plant, benzole'
  },
  {
    Id: 21410,
    Code: '8113',
    Name: 'Operator, plant, chemical'
  },
  {
    Id: 21411,
    Code: '8113',
    Name: 'Operator, plant, chlorination'
  },
  {
    Id: 21412,
    Code: '8113',
    Name: 'Operator, plant, cracker, oil refining'
  },
  {
    Id: 21413,
    Code: '8113',
    Name: 'Operator, plant, distillation, chemicals'
  },
  {
    Id: 21414,
    Code: '8113',
    Name: 'Operator, plant, gas'
  },
  {
    Id: 21415,
    Code: '8113',
    Name: 'Operator, plant, oxygen'
  },
  {
    Id: 21416,
    Code: '8113',
    Name: 'Operator, plant, sedimentation, chemical mfr'
  },
  {
    Id: 21417,
    Code: '8113',
    Name: 'Operator, plant, sterilising, surgical dressings'
  },
  {
    Id: 21418,
    Code: '8113',
    Name: 'Operator, plant, sulphur'
  },
  {
    Id: 21419,
    Code: '8113',
    Name: 'Operator, plant, chemical mfr'
  },
  {
    Id: 21420,
    Code: '8113',
    Name: 'Operator, plant, coal gas, coke ovens'
  },
  {
    Id: 21421,
    Code: '8113',
    Name: 'Operator, plant, oil refining'
  },
  {
    Id: 21422,
    Code: '8113',
    Name: 'Operator, process, chemical'
  },
  {
    Id: 21423,
    Code: '8113',
    Name: 'Operator, process, chemical mfr'
  },
  {
    Id: 21424,
    Code: '8113',
    Name: 'Operator, process, man-made fibre mfr'
  },
  {
    Id: 21425,
    Code: '8113',
    Name: 'Operator, process, oil refining'
  },
  {
    Id: 21426,
    Code: '8113',
    Name: 'Operator, process, pharmaceutical mfr'
  },
  {
    Id: 21427,
    Code: '8113',
    Name: 'Operator, process, plastics mfr'
  },
  {
    Id: 21428,
    Code: '8113',
    Name: 'Operator, producer, gas'
  },
  {
    Id: 21429,
    Code: '8113',
    Name: 'Operator, pumphouse, vacuum, oil refining'
  },
  {
    Id: 21430,
    Code: '8113',
    Name: 'Operator, refinery'
  },
  {
    Id: 21431,
    Code: '8113',
    Name: 'Operator, reproduction, atomic energy establishment'
  },
  {
    Id: 21432,
    Code: '8113',
    Name: 'Operator, retort, coal gas production'
  },
  {
    Id: 21433,
    Code: '8113',
    Name: 'Operator, room, drum'
  },
  {
    Id: 21434,
    Code: '8113',
    Name: 'Operator, saw, band, asbestos goods mfr'
  },
  {
    Id: 21435,
    Code: '8113',
    Name: 'Operator, skin and hide'
  },
  {
    Id: 21436,
    Code: '8113',
    Name: 'Operator, still'
  },
  {
    Id: 21437,
    Code: '8113',
    Name: 'Operator, synthesis, chemical mfr'
  },
  {
    Id: 21438,
    Code: '8113',
    Name: 'Operator, technical, chemical mfr'
  },
  {
    Id: 21439,
    Code: '8113',
    Name: 'Operator, treater, petroleum refining'
  },
  {
    Id: 21440,
    Code: '8113',
    Name: 'Operator, treatment, heat, carbon'
  },
  {
    Id: 21441,
    Code: '8113',
    Name: 'Operator, treatment, heat, carbon goods'
  },
  {
    Id: 21442,
    Code: '8113',
    Name: 'Operator, tunnel, gelatine, glue, size mfr'
  },
  {
    Id: 21443,
    Code: '8113',
    Name: 'Operator, vessel, reaction, chemicals'
  },
  {
    Id: 21444,
    Code: '8113',
    Name: 'Operator, oil refining'
  },
  {
    Id: 21445,
    Code: '8113',
    Name: 'Ovenman, coke'
  },
  {
    Id: 21446,
    Code: '8113',
    Name: 'Ovenman, brake linings mfr'
  },
  {
    Id: 21447,
    Code: '8113',
    Name: 'Ovenman, japanning, enamelling'
  },
  {
    Id: 21448,
    Code: '8113',
    Name: 'Padder, leather dressing'
  },
  {
    Id: 21449,
    Code: '8113',
    Name: 'Painter, hide, tannery'
  },
  {
    Id: 21450,
    Code: '8113',
    Name: 'Painter, skin, fellmongering'
  },
  {
    Id: 21451,
    Code: '8113',
    Name: 'Painter, tannery'
  },
  {
    Id: 21452,
    Code: '8113',
    Name: 'Panelman, oil refining'
  },
  {
    Id: 21453,
    Code: '8113',
    Name: 'Parer, leather dressing'
  },
  {
    Id: 21454,
    Code: '8113',
    Name: 'Paster, leather dressing'
  },
  {
    Id: 21455,
    Code: '8113',
    Name: 'Pearler'
  },
  {
    Id: 21456,
    Code: '8113',
    Name: 'Perfumer'
  },
  {
    Id: 21457,
    Code: '8113',
    Name: 'Picker, hat mfr'
  },
  {
    Id: 21458,
    Code: '8113',
    Name: 'Pickler, fellmongering'
  },
  {
    Id: 21459,
    Code: '8113',
    Name: 'Pickler, tannery'
  },
  {
    Id: 21460,
    Code: '8113',
    Name: 'Piecer, leather dressing'
  },
  {
    Id: 21461,
    Code: '8113',
    Name: 'Plater, fertiliser mfr'
  },
  {
    Id: 21462,
    Code: '8113',
    Name: 'Plater, leather dressing'
  },
  {
    Id: 21463,
    Code: '8113',
    Name: 'Polisher, brush'
  },
  {
    Id: 21464,
    Code: '8113',
    Name: 'Polisher, leather dressing'
  },
  {
    Id: 21465,
    Code: '8113',
    Name: 'Potter, celluloid mfr'
  },
  {
    Id: 21466,
    Code: '8113',
    Name: 'Precipitator'
  },
  {
    Id: 21467,
    Code: '8113',
    Name: 'Premixer, chemicals'
  },
  {
    Id: 21468,
    Code: '8113',
    Name: 'Preparer, starch'
  },
  {
    Id: 21469,
    Code: '8113',
    Name: 'Preparer for dyeing'
  },
  {
    Id: 21470,
    Code: '8113',
    Name: 'Presser, brush, carbon'
  },
  {
    Id: 21471,
    Code: '8113',
    Name: 'Presser, carbon'
  },
  {
    Id: 21472,
    Code: '8113',
    Name: 'Presser, powder, chemical mfr'
  },
  {
    Id: 21473,
    Code: '8113',
    Name: 'Presser, chemical mfr'
  },
  {
    Id: 21474,
    Code: '8113',
    Name: 'Presser, leather dressing'
  },
  {
    Id: 21475,
    Code: '8113',
    Name: 'Presser, patent fuel mfr'
  },
  {
    Id: 21476,
    Code: '8113',
    Name: 'Presser, pencil, crayon mfr'
  },
  {
    Id: 21477,
    Code: '8113',
    Name: 'Printer, nos, leather dressing'
  },
  {
    Id: 21478,
    Code: '8113',
    Name: 'Processor, pharmaceutical'
  },
  {
    Id: 21479,
    Code: '8113',
    Name: 'Producer, gas'
  },
  {
    Id: 21480,
    Code: '8113',
    Name: 'Proofer, dry'
  },
  {
    Id: 21481,
    Code: '8113',
    Name: 'Proofer, rot'
  },
  {
    Id: 21482,
    Code: '8113',
    Name: 'Proofer, water, clothing mfr'
  },
  {
    Id: 21483,
    Code: '8113',
    Name: 'Proofer, water, textile mfr'
  },
  {
    Id: 21484,
    Code: '8113',
    Name: 'Proofer, yarn'
  },
  {
    Id: 21485,
    Code: '8113',
    Name: 'Proofer, clothing mfr'
  },
  {
    Id: 21486,
    Code: '8113',
    Name: 'Proofer, textile mfr'
  },
  {
    Id: 21487,
    Code: '8113',
    Name: 'Puller, wool, fellmongering'
  },
  {
    Id: 21488,
    Code: '8113',
    Name: 'Puller, fellmongering'
  },
  {
    Id: 21489,
    Code: '8113',
    Name: 'Puller, fur dressing'
  },
  {
    Id: 21490,
    Code: '8113',
    Name: 'Pulveriser'
  },
  {
    Id: 21491,
    Code: '8113',
    Name: 'Pumpman, shale oil refining'
  },
  {
    Id: 21492,
    Code: '8113',
    Name: 'Purifier'
  },
  {
    Id: 21493,
    Code: '8113',
    Name: 'Putter-on, glue mfr'
  },
  {
    Id: 21494,
    Code: '8113',
    Name: 'Putter-on, photographic plate mfr'
  },
  {
    Id: 21495,
    Code: '8113',
    Name: 'Re-dyer'
  },
  {
    Id: 21496,
    Code: '8113',
    Name: 'Recoverer, acetone'
  },
  {
    Id: 21497,
    Code: '8113',
    Name: 'Recoverer, solvent'
  },
  {
    Id: 21498,
    Code: '8113',
    Name: 'Rectifier'
  },
  {
    Id: 21499,
    Code: '8113',
    Name: 'Reeler, textile mfr: textile bleaching, dyeing'
  },
  {
    Id: 21500,
    Code: '8113',
    Name: 'Refiner, paint'
  },
  {
    Id: 21501,
    Code: '8113',
    Name: 'Refiner, candle mfr'
  },
  {
    Id: 21502,
    Code: '8113',
    Name: 'Refiner, chemical mfr'
  },
  {
    Id: 21503,
    Code: '8113',
    Name: 'Refiner, oil refining'
  },
  {
    Id: 21504,
    Code: '8113',
    Name: 'Regenerator, oil, coal gas production'
  },
  {
    Id: 21505,
    Code: '8113',
    Name: 'Roller, belly'
  },
  {
    Id: 21506,
    Code: '8113',
    Name: 'Roller, bend, tannery'
  },
  {
    Id: 21507,
    Code: '8113',
    Name: 'Roller, leather'
  },
  {
    Id: 21508,
    Code: '8113',
    Name: 'Roller, leather dressing'
  },
  {
    Id: 21509,
    Code: '8113',
    Name: 'Salter, tannery'
  },
  {
    Id: 21510,
    Code: '8113',
    Name: 'Sawduster'
  },
  {
    Id: 21511,
    Code: '8113',
    Name: 'Scourer, grease'
  },
  {
    Id: 21512,
    Code: '8113',
    Name: 'Scourer, leather dressing'
  },
  {
    Id: 21513,
    Code: '8113',
    Name: 'Screener, coke, coke ovens'
  },
  {
    Id: 21514,
    Code: '8113',
    Name: 'Screener, paint'
  },
  {
    Id: 21515,
    Code: '8113',
    Name: 'Screener, chemical mfr'
  },
  {
    Id: 21516,
    Code: '8113',
    Name: 'Scrubberman, coke ovens'
  },
  {
    Id: 21517,
    Code: '8113',
    Name: 'Scudder'
  },
  {
    Id: 21518,
    Code: '8113',
    Name: 'Scutcher, textile mfr: textile bleaching, dyeing'
  },
  {
    Id: 21519,
    Code: '8113',
    Name: 'Sensitiser, film'
  },
  {
    Id: 21520,
    Code: '8113',
    Name: 'Setter, leather'
  },
  {
    Id: 21521,
    Code: '8113',
    Name: 'Setter, machine, tannery'
  },
  {
    Id: 21522,
    Code: '8113',
    Name: 'Setter, tannery'
  },
  {
    Id: 21523,
    Code: '8113',
    Name: 'Shaver, leather dressing'
  },
  {
    Id: 21524,
    Code: '8113',
    Name: 'Shearer, leather dressing'
  },
  {
    Id: 21525,
    Code: '8113',
    Name: 'Shedman, leather dressing'
  },
  {
    Id: 21526,
    Code: '8113',
    Name: 'Shredder, chemical mfr'
  },
  {
    Id: 21527,
    Code: '8113',
    Name: 'Siever, chemical mfr'
  },
  {
    Id: 21528,
    Code: '8113',
    Name: 'Sifter, chemical mfr'
  },
  {
    Id: 21529,
    Code: '8113',
    Name: 'Sizeman'
  },
  {
    Id: 21530,
    Code: '8113',
    Name: 'Skewerer, textile bleaching, dyeing'
  },
  {
    Id: 21531,
    Code: '8113',
    Name: 'Skinner, tannery'
  },
  {
    Id: 21532,
    Code: '8113',
    Name: 'Skiver, leather dressing'
  },
  {
    Id: 21533,
    Code: '8113',
    Name: 'Slicker, leather dressing'
  },
  {
    Id: 21534,
    Code: '8113',
    Name: 'Slipper, asbestos-cement mfr'
  },
  {
    Id: 21535,
    Code: '8113',
    Name: 'Slurryman'
  },
  {
    Id: 21536,
    Code: '8113',
    Name: 'Soaker, lime'
  },
  {
    Id: 21537,
    Code: '8113',
    Name: 'Soaker, leather dressing'
  },
  {
    Id: 21538,
    Code: '8113',
    Name: 'Softener, leather dressing'
  },
  {
    Id: 21539,
    Code: '8113',
    Name: 'Spinner, acetate'
  },
  {
    Id: 21540,
    Code: '8113',
    Name: 'Spinner, nylon'
  },
  {
    Id: 21541,
    Code: '8113',
    Name: 'Spinner, polyester'
  },
  {
    Id: 21542,
    Code: '8113',
    Name: 'Spinner, rayon'
  },
  {
    Id: 21543,
    Code: '8113',
    Name: 'Spinner, silk, artificial'
  },
  {
    Id: 21544,
    Code: '8113',
    Name: 'Spinner, viscose'
  },
  {
    Id: 21545,
    Code: '8113',
    Name: 'Spinner, textile mfr: man-made fibre mfr'
  },
  {
    Id: 21546,
    Code: '8113',
    Name: 'Splitter, hide'
  },
  {
    Id: 21547,
    Code: '8113',
    Name: 'Splitter, mica'
  },
  {
    Id: 21548,
    Code: '8113',
    Name: 'Splitter, skin'
  },
  {
    Id: 21549,
    Code: '8113',
    Name: 'Splitter, leather dressing'
  },
  {
    Id: 21550,
    Code: '8113',
    Name: 'Sprayer, leather'
  },
  {
    Id: 21551,
    Code: '8113',
    Name: 'Squeezer, textile bleaching, dyeing'
  },
  {
    Id: 21552,
    Code: '8113',
    Name: 'Staker, leather dressing'
  },
  {
    Id: 21553,
    Code: '8113',
    Name: 'Starcher, textile finishing'
  },
  {
    Id: 21554,
    Code: '8113',
    Name: 'Stockman, manufacturing: leather dressing'
  },
  {
    Id: 21555,
    Code: '8113',
    Name: 'Stocksman'
  },
  {
    Id: 21556,
    Code: '8113',
    Name: 'Stoker, gas'
  },
  {
    Id: 21557,
    Code: '8113',
    Name: 'Stoker, plant, gas'
  },
  {
    Id: 21558,
    Code: '8113',
    Name: 'Stoker, retort, coal gas production'
  },
  {
    Id: 21559,
    Code: '8113',
    Name: 'Stoker, coal gas, coke ovens'
  },
  {
    Id: 21560,
    Code: '8113',
    Name: 'Stover, seasoning'
  },
  {
    Id: 21561,
    Code: '8113',
    Name: 'Stover, leather dressing'
  },
  {
    Id: 21562,
    Code: '8113',
    Name: 'Strainer, chemical mfr'
  },
  {
    Id: 21563,
    Code: '8113',
    Name: 'Strainer, paint mfr'
  },
  {
    Id: 21564,
    Code: '8113',
    Name: 'Strainer, tannery'
  },
  {
    Id: 21565,
    Code: '8113',
    Name: 'Strainer, textile printing'
  },
  {
    Id: 21566,
    Code: '8113',
    Name: 'Stretcher, tannery'
  },
  {
    Id: 21567,
    Code: '8113',
    Name: 'Striker, colour'
  },
  {
    Id: 21568,
    Code: '8113',
    Name: 'Striper, fur dressing'
  },
  {
    Id: 21569,
    Code: '8113',
    Name: 'Stripper, film'
  },
  {
    Id: 21570,
    Code: '8113',
    Name: 'Stripper and setter, soap, detergent mfr'
  },
  {
    Id: 21571,
    Code: '8113',
    Name: 'Sueder'
  },
  {
    Id: 21572,
    Code: '8113',
    Name: 'Suspender, butt'
  },
  {
    Id: 21573,
    Code: '8113',
    Name: 'Tanner'
  },
  {
    Id: 21574,
    Code: '8113',
    Name: 'Tapper, carbide mfr'
  },
  {
    Id: 21575,
    Code: '8113',
    Name: 'Technician, paint, paint mfr'
  },
  {
    Id: 21576,
    Code: '8113',
    Name: 'Technician, plant, chemical mfr'
  },
  {
    Id: 21577,
    Code: '8113',
    Name: 'Technician, process, chemical mfr'
  },
  {
    Id: 21578,
    Code: '8113',
    Name: 'Technician, room, control, gas supplier'
  },
  {
    Id: 21579,
    Code: '8113',
    Name: 'Tenter, jig'
  },
  {
    Id: 21580,
    Code: '8113',
    Name: 'Thinner, varnish mfr'
  },
  {
    Id: 21581,
    Code: '8113',
    Name: 'Tinter, enamel, enamel mfr'
  },
  {
    Id: 21582,
    Code: '8113',
    Name: 'Tinter, paint'
  },
  {
    Id: 21583,
    Code: '8113',
    Name: 'Tinter, chemical mfr'
  },
  {
    Id: 21584,
    Code: '8113',
    Name: 'Toggler, leather dressing'
  },
  {
    Id: 21585,
    Code: '8113',
    Name: 'Towerman'
  },
  {
    Id: 21586,
    Code: '8113',
    Name: 'Trimmer, asbestos'
  },
  {
    Id: 21587,
    Code: '8113',
    Name: 'Trimmer, sponge'
  },
  {
    Id: 21588,
    Code: '8113',
    Name: 'Trimmer, leather dressing'
  },
  {
    Id: 21589,
    Code: '8113',
    Name: 'Tubber, leather dressing'
  },
  {
    Id: 21590,
    Code: '8113',
    Name: 'Turner, leather dressing'
  },
  {
    Id: 21591,
    Code: '8113',
    Name: 'Waller, salt mfr'
  },
  {
    Id: 21592,
    Code: '8113',
    Name: 'Washer, benzol'
  },
  {
    Id: 21593,
    Code: '8113',
    Name: 'Washer, benzole'
  },
  {
    Id: 21594,
    Code: '8113',
    Name: 'Washer, cake, man-made fibre mfr'
  },
  {
    Id: 21595,
    Code: '8113',
    Name: 'Washer, coke'
  },
  {
    Id: 21596,
    Code: '8113',
    Name: 'Washer, skin, fellmongering'
  },
  {
    Id: 21597,
    Code: '8113',
    Name: 'Washer, chemical mfr'
  },
  {
    Id: 21598,
    Code: '8113',
    Name: 'Washer, fellmongering'
  },
  {
    Id: 21599,
    Code: '8113',
    Name: 'Washer, tannery'
  },
  {
    Id: 21600,
    Code: '8113',
    Name: 'Watcher, calciner'
  },
  {
    Id: 21601,
    Code: '8113',
    Name: 'Waxer, leather dressing'
  },
  {
    Id: 21602,
    Code: '8113',
    Name: 'Wheeler, wet, leather dressing'
  },
  {
    Id: 21603,
    Code: '8113',
    Name: 'Wheeler, leather dressing'
  },
  {
    Id: 21604,
    Code: '8113',
    Name: 'Whizzerman, chemical mfr'
  },
  {
    Id: 21605,
    Code: '8113',
    Name: 'Worker, acid'
  },
  {
    Id: 21606,
    Code: '8113',
    Name: 'Worker, autoclave, aluminium refining'
  },
  {
    Id: 21607,
    Code: '8113',
    Name: 'Worker, bitumen'
  },
  {
    Id: 21608,
    Code: '8113',
    Name: 'Worker, bleach, textile mfr'
  },
  {
    Id: 21609,
    Code: '8113',
    Name: 'Worker, carbide'
  },
  {
    Id: 21610,
    Code: '8113',
    Name: 'Worker, chemical'
  },
  {
    Id: 21611,
    Code: '8113',
    Name: 'Worker, chlorine, electrolytic'
  },
  {
    Id: 21612,
    Code: '8113',
    Name: 'Worker, coke, coke ovens'
  },
  {
    Id: 21613,
    Code: '8113',
    Name: 'Worker, colour, paint mfr'
  },
  {
    Id: 21614,
    Code: '8113',
    Name: 'Worker, cordite'
  },
  {
    Id: 21615,
    Code: '8113',
    Name: 'Worker, digester'
  },
  {
    Id: 21616,
    Code: '8113',
    Name: 'Worker, dye, natural'
  },
  {
    Id: 21617,
    Code: '8113',
    Name: 'Worker, explosive'
  },
  {
    Id: 21618,
    Code: '8113',
    Name: 'Worker, extract, tannery'
  },
  {
    Id: 21619,
    Code: '8113',
    Name: 'Worker, filtration, red, aluminium refining'
  },
  {
    Id: 21620,
    Code: '8113',
    Name: 'Worker, fuseroom, chemical mfr'
  },
  {
    Id: 21621,
    Code: '8113',
    Name: 'Worker, galenical'
  },
  {
    Id: 21622,
    Code: '8113',
    Name: 'Worker, gas, chlorine'
  },
  {
    Id: 21623,
    Code: '8113',
    Name: 'Worker, gas, industrial gas'
  },
  {
    Id: 21624,
    Code: '8113',
    Name: 'Worker, glycerine'
  },
  {
    Id: 21625,
    Code: '8113',
    Name: 'Worker, house, dye, textile mfr'
  },
  {
    Id: 21626,
    Code: '8113',
    Name: 'Worker, house, retort, coal gas, coke ovens'
  },
  {
    Id: 21627,
    Code: '8113',
    Name: 'Worker, installation, oil refining'
  },
  {
    Id: 21628,
    Code: '8113',
    Name: 'Worker, leather, leather dressing'
  },
  {
    Id: 21629,
    Code: '8113',
    Name: 'Worker, lime'
  },
  {
    Id: 21630,
    Code: '8113',
    Name: 'Worker, magazine, explosives'
  },
  {
    Id: 21631,
    Code: '8113',
    Name: 'Worker, nylon, nylon mfr'
  },
  {
    Id: 21632,
    Code: '8113',
    Name: 'Worker, oven, coke'
  },
  {
    Id: 21633,
    Code: '8113',
    Name: 'Worker, paint, paint mfr'
  },
  {
    Id: 21634,
    Code: '8113',
    Name: 'Worker, pharmaceutical'
  },
  {
    Id: 21635,
    Code: '8113',
    Name: 'Worker, plant, gas'
  },
  {
    Id: 21636,
    Code: '8113',
    Name: 'Worker, plant, chemical mfr'
  },
  {
    Id: 21637,
    Code: '8113',
    Name: 'Worker, plant, coke ovens'
  },
  {
    Id: 21638,
    Code: '8113',
    Name: 'Worker, process, adhesive and sealants mfr'
  },
  {
    Id: 21639,
    Code: '8113',
    Name: 'Worker, process, asbestos-cement goods mfr'
  },
  {
    Id: 21640,
    Code: '8113',
    Name: 'Worker, process, cellulose film mfr'
  },
  {
    Id: 21641,
    Code: '8113',
    Name: 'Worker, process, cement mfr'
  },
  {
    Id: 21642,
    Code: '8113',
    Name: 'Worker, process, chemical mfr'
  },
  {
    Id: 21643,
    Code: '8113',
    Name: 'Worker, process, coal gas, coke ovens'
  },
  {
    Id: 21644,
    Code: '8113',
    Name: 'Worker, process, explosives mfr'
  },
  {
    Id: 21645,
    Code: '8113',
    Name: 'Worker, process, fat recovery'
  },
  {
    Id: 21646,
    Code: '8113',
    Name: 'Worker, process, leather mfr'
  },
  {
    Id: 21647,
    Code: '8113',
    Name: 'Worker, process, lubricating oil mfr'
  },
  {
    Id: 21648,
    Code: '8113',
    Name: 'Worker, process, nuclear fuel production'
  },
  {
    Id: 21649,
    Code: '8113',
    Name: 'Worker, process, oil refining'
  },
  {
    Id: 21650,
    Code: '8113',
    Name: 'Worker, process, ordnance factory'
  },
  {
    Id: 21651,
    Code: '8113',
    Name: 'Worker, process, paint mfr'
  },
  {
    Id: 21652,
    Code: '8113',
    Name: 'Worker, process, patent fuel mfr'
  },
  {
    Id: 21653,
    Code: '8113',
    Name: 'Worker, process, pharmaceutical mfr'
  },
  {
    Id: 21654,
    Code: '8113',
    Name: 'Worker, process, photographic film mfr'
  },
  {
    Id: 21655,
    Code: '8113',
    Name: 'Worker, process, plastics mfr'
  },
  {
    Id: 21656,
    Code: '8113',
    Name: 'Worker, process, polish mfr'
  },
  {
    Id: 21657,
    Code: '8113',
    Name: 'Worker, process, printing ink mfr'
  },
  {
    Id: 21658,
    Code: '8113',
    Name: 'Worker, process, soap, detergent mfr'
  },
  {
    Id: 21659,
    Code: '8113',
    Name: 'Worker, process, tannery'
  },
  {
    Id: 21660,
    Code: '8113',
    Name: 'Worker, process, textile mfr: man-made fibre mfr'
  },
  {
    Id: 21661,
    Code: '8113',
    Name: 'Worker, process, toilet preparations mfr'
  },
  {
    Id: 21662,
    Code: '8113',
    Name: 'Worker, process, UK Atomic Energy Authority'
  },
  {
    Id: 21663,
    Code: '8113',
    Name: 'Worker, products, medicinal'
  },
  {
    Id: 21664,
    Code: '8113',
    Name: 'Worker, rayon'
  },
  {
    Id: 21665,
    Code: '8113',
    Name: 'Worker, refinery, oil'
  },
  {
    Id: 21666,
    Code: '8113',
    Name: 'Worker, salt'
  },
  {
    Id: 21667,
    Code: '8113',
    Name: 'Worker, shed, tannery'
  },
  {
    Id: 21668,
    Code: '8113',
    Name: 'Worker, sheepskin'
  },
  {
    Id: 21669,
    Code: '8113',
    Name: 'Worker, soap'
  },
  {
    Id: 21670,
    Code: '8113',
    Name: 'Worker, tannery'
  },
  {
    Id: 21671,
    Code: '8113',
    Name: 'Worker, whiting'
  },
  {
    Id: 21672,
    Code: '8113',
    Name: 'Worker, yard, lime'
  },
  {
    Id: 21673,
    Code: '8113',
    Name: 'Worker, yard, tan'
  },
  {
    Id: 21674,
    Code: '8114',
    Name: 'Assistant, extrusion, plastics'
  },
  {
    Id: 21675,
    Code: '8114',
    Name: 'Blender, plastics goods mfr'
  },
  {
    Id: 21676,
    Code: '8114',
    Name: 'Blower, steam'
  },
  {
    Id: 21677,
    Code: '8114',
    Name: 'Blower, plastics goods mfr'
  },
  {
    Id: 21678,
    Code: '8114',
    Name: 'Clatter, celluloid'
  },
  {
    Id: 21679,
    Code: '8114',
    Name: 'Cleaner, button'
  },
  {
    Id: 21680,
    Code: '8114',
    Name: 'Colourist, plastics mfr'
  },
  {
    Id: 21681,
    Code: '8114',
    Name: 'Compounder, plastics goods mfr'
  },
  {
    Id: 21682,
    Code: '8114',
    Name: 'Converter, plastics goods mfr'
  },
  {
    Id: 21683,
    Code: '8114',
    Name: 'Cutter, button'
  },
  {
    Id: 21684,
    Code: '8114',
    Name: 'Cutter, plastics'
  },
  {
    Id: 21685,
    Code: '8114',
    Name: 'Cutter, plastics goods mfr'
  },
  {
    Id: 21686,
    Code: '8114',
    Name: 'Driller, button'
  },
  {
    Id: 21687,
    Code: '8114',
    Name: 'Driller, plastics goods mfr'
  },
  {
    Id: 21688,
    Code: '8114',
    Name: 'Driver, extruding'
  },
  {
    Id: 21689,
    Code: '8114',
    Name: 'Dyer, plastics'
  },
  {
    Id: 21690,
    Code: '8114',
    Name: 'Embosser, plastics goods mfr'
  },
  {
    Id: 21691,
    Code: '8114',
    Name: 'Engineer, extrusion, plastics goods mfr'
  },
  {
    Id: 21692,
    Code: '8114',
    Name: 'Engineer, plastics'
  },
  {
    Id: 21693,
    Code: '8114',
    Name: 'Extruder, cable, plastics goods mfr'
  },
  {
    Id: 21694,
    Code: '8114',
    Name: 'Extruder, plastics'
  },
  {
    Id: 21695,
    Code: '8114',
    Name: 'Fabricator, foam'
  },
  {
    Id: 21696,
    Code: '8114',
    Name: 'Fabricator, plastics mfr'
  },
  {
    Id: 21697,
    Code: '8114',
    Name: 'Felter, plastics mfr'
  },
  {
    Id: 21698,
    Code: '8114',
    Name: 'Filer, plastics'
  },
  {
    Id: 21699,
    Code: '8114',
    Name: 'Filer, plastics mfr'
  },
  {
    Id: 21700,
    Code: '8114',
    Name: 'Filler, hopper, plastics mfr'
  },
  {
    Id: 21701,
    Code: '8114',
    Name: 'Finisher, plastics'
  },
  {
    Id: 21702,
    Code: '8114',
    Name: 'Finisher, plastics goods mfr'
  },
  {
    Id: 21703,
    Code: '8114',
    Name: 'Fitter, composite, vehicle mfr'
  },
  {
    Id: 21704,
    Code: '8114',
    Name: 'Forcer, plastics goods mfr'
  },
  {
    Id: 21705,
    Code: '8114',
    Name: 'Former, plastics mfr'
  },
  {
    Id: 21706,
    Code: '8114',
    Name: 'Grailer, celluloid goods mfr'
  },
  {
    Id: 21707,
    Code: '8114',
    Name: 'Grinder, plastics goods mfr'
  },
  {
    Id: 21708,
    Code: '8114',
    Name: 'Hand, bench, plastics goods mfr'
  },
  {
    Id: 21709,
    Code: '8114',
    Name: 'Hand, calender, plastics goods mfr'
  },
  {
    Id: 21710,
    Code: '8114',
    Name: 'Hand, flasher, plastics mfr'
  },
  {
    Id: 21711,
    Code: '8114',
    Name: 'Injector, mould, plastics goods mfr'
  },
  {
    Id: 21712,
    Code: '8114',
    Name: 'Laminator, composite'
  },
  {
    Id: 21713,
    Code: '8114',
    Name: 'Laminator, fibreglass'
  },
  {
    Id: 21714,
    Code: '8114',
    Name: 'Laminator, GRP'
  },
  {
    Id: 21715,
    Code: '8114',
    Name: 'Laminator, plastic'
  },
  {
    Id: 21716,
    Code: '8114',
    Name: 'Laminator, fibreglass'
  },
  {
    Id: 21717,
    Code: '8114',
    Name: 'Laminator, plastics mfr'
  },
  {
    Id: 21718,
    Code: '8114',
    Name: 'Liner, tank, fibreglass'
  },
  {
    Id: 21719,
    Code: '8114',
    Name: 'Machinist, buffing, plastics goods mfr'
  },
  {
    Id: 21720,
    Code: '8114',
    Name: 'Machinist, calender, plastics goods mfr'
  },
  {
    Id: 21721,
    Code: '8114',
    Name: 'Machinist, coating, plastics goods mfr'
  },
  {
    Id: 21722,
    Code: '8114',
    Name: 'Machinist, cutting, plastics'
  },
  {
    Id: 21723,
    Code: '8114',
    Name: 'Machinist, cutting, plastics'
  },
  {
    Id: 21724,
    Code: '8114',
    Name: 'Machinist, extruding, plastics goods mfr'
  },
  {
    Id: 21725,
    Code: '8114',
    Name: 'Machinist, foam'
  },
  {
    Id: 21726,
    Code: '8114',
    Name: 'Machinist, guillotine, plastics goods mfr'
  },
  {
    Id: 21727,
    Code: '8114',
    Name: 'Machinist, moulding, plastics goods mfr'
  },
  {
    Id: 21728,
    Code: '8114',
    Name: 'Machinist, plastic, cable mfr'
  },
  {
    Id: 21729,
    Code: '8114',
    Name: 'Machinist, plastics'
  },
  {
    Id: 21730,
    Code: '8114',
    Name: 'Machinist, press, plastics goods mfr'
  },
  {
    Id: 21731,
    Code: '8114',
    Name: 'Machinist, roller, plastics goods mfr'
  },
  {
    Id: 21732,
    Code: '8114',
    Name: 'Machinist, slicing, celluloid'
  },
  {
    Id: 21733,
    Code: '8114',
    Name: 'Machinist, stamping, plastics goods mfr'
  },
  {
    Id: 21734,
    Code: '8114',
    Name: 'Machinist, plastics goods mfr'
  },
  {
    Id: 21735,
    Code: '8114',
    Name: 'Maker, Bakelite'
  },
  {
    Id: 21736,
    Code: '8114',
    Name: 'Maker, ball, billiard'
  },
  {
    Id: 21737,
    Code: '8114',
    Name: 'Maker, ball, celluloid goods mfr'
  },
  {
    Id: 21738,
    Code: '8114',
    Name: 'Maker, belt, conveyor, plastics goods mfr'
  },
  {
    Id: 21739,
    Code: '8114',
    Name: 'Maker, bottle, plastic'
  },
  {
    Id: 21740,
    Code: '8114',
    Name: 'Maker, bottle, plastics mfr'
  },
  {
    Id: 21741,
    Code: '8114',
    Name: 'Maker, bucket, plastics'
  },
  {
    Id: 21742,
    Code: '8114',
    Name: 'Maker, case, battery'
  },
  {
    Id: 21743,
    Code: '8114',
    Name: 'Maker, case, accumulator mfr'
  },
  {
    Id: 21744,
    Code: '8114',
    Name: 'Maker, comb'
  },
  {
    Id: 21745,
    Code: '8114',
    Name: 'Maker, eye, artificial, plastics'
  },
  {
    Id: 21746,
    Code: '8114',
    Name: 'Maker, frame, optical'
  },
  {
    Id: 21747,
    Code: '8114',
    Name: 'Maker, frame, spectacle'
  },
  {
    Id: 21748,
    Code: '8114',
    Name: 'Maker, frame, plastics goods mfr'
  },
  {
    Id: 21749,
    Code: '8114',
    Name: 'Maker, plate, number'
  },
  {
    Id: 21750,
    Code: '8114',
    Name: 'Maker, sink, plastics'
  },
  {
    Id: 21751,
    Code: '8114',
    Name: 'Maker, spectacle'
  },
  {
    Id: 21752,
    Code: '8114',
    Name: 'Maker, tile, plastics'
  },
  {
    Id: 21753,
    Code: '8114',
    Name: 'Maker, tray, plastics'
  },
  {
    Id: 21754,
    Code: '8114',
    Name: 'Maker, tube, plastics'
  },
  {
    Id: 21755,
    Code: '8114',
    Name: 'Man, die, plastics goods mfr'
  },
  {
    Id: 21756,
    Code: '8114',
    Name: 'Man, mill, plastics goods mfr'
  },
  {
    Id: 21757,
    Code: '8114',
    Name: 'Manipulator, plastics goods mfr'
  },
  {
    Id: 21758,
    Code: '8114',
    Name: 'Manufacturer, plastics goods mfr'
  },
  {
    Id: 21759,
    Code: '8114',
    Name: 'Matcher, colour, plastics goods mfr'
  },
  {
    Id: 21760,
    Code: '8114',
    Name: 'Miller, plastics goods mfr'
  },
  {
    Id: 21761,
    Code: '8114',
    Name: 'Mixer, colour, plastics goods mfr'
  },
  {
    Id: 21762,
    Code: '8114',
    Name: 'Mixer, dough, plastics goods mfr'
  },
  {
    Id: 21763,
    Code: '8114',
    Name: 'Mixer, dry, plastics goods mfr'
  },
  {
    Id: 21764,
    Code: '8114',
    Name: 'Mixer, plastic'
  },
  {
    Id: 21765,
    Code: '8114',
    Name: 'Mixer, plastics'
  },
  {
    Id: 21766,
    Code: '8114',
    Name: 'Mixer, plastics goods mfr'
  },
  {
    Id: 21767,
    Code: '8114',
    Name: 'Moulder, aloe, plastics'
  },
  {
    Id: 21768,
    Code: '8114',
    Name: 'Moulder, Bakelite'
  },
  {
    Id: 21769,
    Code: '8114',
    Name: 'Moulder, compression, plastics goods mfr'
  },
  {
    Id: 21770,
    Code: '8114',
    Name: 'Moulder, fibreglass'
  },
  {
    Id: 21771,
    Code: '8114',
    Name: 'Moulder, injection, plastics goods mfr'
  },
  {
    Id: 21772,
    Code: '8114',
    Name: 'Moulder, injection'
  },
  {
    Id: 21773,
    Code: '8114',
    Name: 'Moulder, plastic'
  },
  {
    Id: 21774,
    Code: '8114',
    Name: 'Moulder, press, plastics'
  },
  {
    Id: 21775,
    Code: '8114',
    Name: 'Moulder, spindle, plastics'
  },
  {
    Id: 21776,
    Code: '8114',
    Name: 'Moulder, tube'
  },
  {
    Id: 21777,
    Code: '8114',
    Name: 'Moulder, plastics goods mfr'
  },
  {
    Id: 21778,
    Code: '8114',
    Name: 'Operator, calender, plastics mfr'
  },
  {
    Id: 21779,
    Code: '8114',
    Name: 'Operator, coatings, plastic, plastics goods mfr'
  },
  {
    Id: 21780,
    Code: '8114',
    Name: 'Operator, composite, aircraft component mfr'
  },
  {
    Id: 21781,
    Code: '8114',
    Name: 'Operator, extruder, plastics mfr'
  },
  {
    Id: 21782,
    Code: '8114',
    Name: 'Operator, extrusion, plastics mfr'
  },
  {
    Id: 21783,
    Code: '8114',
    Name: 'Operator, guillotine, plastics goods mfr'
  },
  {
    Id: 21784,
    Code: '8114',
    Name: 'Operator, mill, plastics goods mfr'
  },
  {
    Id: 21785,
    Code: '8114',
    Name: 'Operator, plant, plastics goods mfr'
  },
  {
    Id: 21786,
    Code: '8114',
    Name: 'Operator, plastic'
  },
  {
    Id: 21787,
    Code: '8114',
    Name: 'Operator, plastics'
  },
  {
    Id: 21788,
    Code: '8114',
    Name: 'Operator, process, plastics goods mfr'
  },
  {
    Id: 21789,
    Code: '8114',
    Name: 'Operator, rolls, calender, plastics goods mfr'
  },
  {
    Id: 21790,
    Code: '8114',
    Name: 'Operator, saw, plastics'
  },
  {
    Id: 21791,
    Code: '8114',
    Name: 'Operator, tube, plastics goods mfr'
  },
  {
    Id: 21792,
    Code: '8114',
    Name: 'Planer, plastics'
  },
  {
    Id: 21793,
    Code: '8114',
    Name: 'Polisher, Bakelite'
  },
  {
    Id: 21794,
    Code: '8114',
    Name: 'Polisher, celluloid'
  },
  {
    Id: 21795,
    Code: '8114',
    Name: 'Polisher, frame, spectacle'
  },
  {
    Id: 21796,
    Code: '8114',
    Name: 'Polisher, plastics goods mfr'
  },
  {
    Id: 21797,
    Code: '8114',
    Name: 'Pre-former'
  },
  {
    Id: 21798,
    Code: '8114',
    Name: 'Presser, block, plastics goods mfr'
  },
  {
    Id: 21799,
    Code: '8114',
    Name: 'Presser, hydraulic, plastics goods mfr'
  },
  {
    Id: 21800,
    Code: '8114',
    Name: 'Presser, lens, contact, plastics'
  },
  {
    Id: 21801,
    Code: '8114',
    Name: 'Presser, plastics'
  },
  {
    Id: 21802,
    Code: '8114',
    Name: 'Presser, polishing'
  },
  {
    Id: 21803,
    Code: '8114',
    Name: 'Presser, plastics goods mfr'
  },
  {
    Id: 21804,
    Code: '8114',
    Name: 'Quiller, comb'
  },
  {
    Id: 21805,
    Code: '8114',
    Name: 'Reeler, plastics goods mfr'
  },
  {
    Id: 21806,
    Code: '8114',
    Name: 'Roller, tube, plastics'
  },
  {
    Id: 21807,
    Code: '8114',
    Name: 'Router, plastics'
  },
  {
    Id: 21808,
    Code: '8114',
    Name: 'Sawyer, plastics'
  },
  {
    Id: 21809,
    Code: '8114',
    Name: 'Setter, machine, plastics goods mfr'
  },
  {
    Id: 21810,
    Code: '8114',
    Name: 'Setter, plastics goods mfr'
  },
  {
    Id: 21811,
    Code: '8114',
    Name: 'Setter-up, spectacle mfr'
  },
  {
    Id: 21812,
    Code: '8114',
    Name: 'Sheeter, plastics goods mfr'
  },
  {
    Id: 21813,
    Code: '8114',
    Name: 'Slicer, celluloid sheet mfr'
  },
  {
    Id: 21814,
    Code: '8114',
    Name: 'Slitter, plastics goods mfr'
  },
  {
    Id: 21815,
    Code: '8114',
    Name: 'Spreader, laminated plastics mfr'
  },
  {
    Id: 21816,
    Code: '8114',
    Name: 'Stringer, plastics goods mfr'
  },
  {
    Id: 21817,
    Code: '8114',
    Name: 'Trimmer, Bakelite'
  },
  {
    Id: 21818,
    Code: '8114',
    Name: 'Trimmer, plastic'
  },
  {
    Id: 21819,
    Code: '8114',
    Name: 'Trimmer, plastics'
  },
  {
    Id: 21820,
    Code: '8114',
    Name: 'Trimmer, plastics mfr'
  },
  {
    Id: 21821,
    Code: '8114',
    Name: 'Turner, ball, billiard'
  },
  {
    Id: 21822,
    Code: '8114',
    Name: 'Turner, plastics'
  },
  {
    Id: 21823,
    Code: '8114',
    Name: 'Turner, plastics goods mfr'
  },
  {
    Id: 21824,
    Code: '8114',
    Name: 'Twister, tube'
  },
  {
    Id: 21825,
    Code: '8114',
    Name: 'Worker, bench, laminated plastics mfr'
  },
  {
    Id: 21826,
    Code: '8114',
    Name: 'Worker, composite, aircraft component mfr'
  },
  {
    Id: 21827,
    Code: '8114',
    Name: 'Worker, factory, plastics goods mfr'
  },
  {
    Id: 21828,
    Code: '8114',
    Name: 'Worker, plastics'
  },
  {
    Id: 21829,
    Code: '8114',
    Name: 'Worker, process, plastics goods mfr'
  },
  {
    Id: 21830,
    Code: '8114',
    Name: 'Worker, tile, plastics goods mfr'
  },
  {
    Id: 21831,
    Code: '8115',
    Name: 'Airman, hot'
  },
  {
    Id: 21832,
    Code: '8115',
    Name: 'Alloyman, copper'
  },
  {
    Id: 21833,
    Code: '8115',
    Name: 'Anchorer'
  },
  {
    Id: 21834,
    Code: '8115',
    Name: 'Annealer'
  },
  {
    Id: 21835,
    Code: '8115',
    Name: 'Anodiser'
  },
  {
    Id: 21836,
    Code: '8115',
    Name: 'Assistant, dip, hot'
  },
  {
    Id: 21837,
    Code: '8115',
    Name: 'Assistant, electroplating'
  },
  {
    Id: 21838,
    Code: '8115',
    Name: 'Assistant, forge'
  },
  {
    Id: 21839,
    Code: '8115',
    Name: 'Assistant, furnace, metal trades'
  },
  {
    Id: 21840,
    Code: '8115',
    Name: "Assistant, gilder's, electro-gilding"
  },
  {
    Id: 21841,
    Code: '8115',
    Name: "Assistant, hammerman's"
  },
  {
    Id: 21842,
    Code: '8115',
    Name: "Assistant, heater's, metal trades"
  },
  {
    Id: 21843,
    Code: '8115',
    Name: 'Assistant, mill, rolling'
  },
  {
    Id: 21844,
    Code: '8115',
    Name: "Assistant, pickler's, galvanised sheet"
  },
  {
    Id: 21845,
    Code: '8115',
    Name: 'Assistant, plating, electroplating'
  },
  {
    Id: 21846,
    Code: '8115',
    Name: "Assistant, roller's"
  },
  {
    Id: 21847,
    Code: '8115',
    Name: "Assistant, silverer's"
  },
  {
    Id: 21848,
    Code: '8115',
    Name: "Assistant, smith's"
  },
  {
    Id: 21849,
    Code: '8115',
    Name: "Assistant, stamper's, drop forging"
  },
  {
    Id: 21850,
    Code: '8115',
    Name: 'Attendant, anode'
  },
  {
    Id: 21851,
    Code: '8115',
    Name: 'Attendant, bath, copper, glass mfr'
  },
  {
    Id: 21852,
    Code: '8115',
    Name: 'Attendant, bath, salt'
  },
  {
    Id: 21853,
    Code: '8115',
    Name: 'Attendant, baths, copper, glass mfr'
  },
  {
    Id: 21854,
    Code: '8115',
    Name: 'Attendant, baths, salt'
  },
  {
    Id: 21855,
    Code: '8115',
    Name: 'Attendant, cupola'
  },
  {
    Id: 21856,
    Code: '8115',
    Name: 'Attendant, electrolytic'
  },
  {
    Id: 21857,
    Code: '8115',
    Name: 'Attendant, furnace, blast'
  },
  {
    Id: 21858,
    Code: '8115',
    Name: 'Attendant, furnace, annealing'
  },
  {
    Id: 21859,
    Code: '8115',
    Name: 'Attendant, furnace, metal trades'
  },
  {
    Id: 21860,
    Code: '8115',
    Name: 'Attendant, instrument, steelworks'
  },
  {
    Id: 21861,
    Code: '8115',
    Name: 'Attendant, lid, carbonising'
  },
  {
    Id: 21862,
    Code: '8115',
    Name: 'Attendant, mill, rod'
  },
  {
    Id: 21863,
    Code: '8115',
    Name: 'Attendant, mill, rolling mill'
  },
  {
    Id: 21864,
    Code: '8115',
    Name: 'Attendant, oven, core'
  },
  {
    Id: 21865,
    Code: '8115',
    Name: 'Attendant, pyrometer, metal mfr'
  },
  {
    Id: 21866,
    Code: '8115',
    Name: 'Attendant, roll, cold'
  },
  {
    Id: 21867,
    Code: '8115',
    Name: 'Attendant, stove, core'
  },
  {
    Id: 21868,
    Code: '8115',
    Name: 'Attender, tinplate mfr'
  },
  {
    Id: 21869,
    Code: '8115',
    Name: 'Babbitter'
  },
  {
    Id: 21870,
    Code: '8115',
    Name: 'Backer, mirror'
  },
  {
    Id: 21871,
    Code: '8115',
    Name: 'Backer, spindle, rolling mill'
  },
  {
    Id: 21872,
    Code: '8115',
    Name: 'Backer, rolling mill'
  },
  {
    Id: 21873,
    Code: '8115',
    Name: 'Backer-up, steelworks'
  },
  {
    Id: 21874,
    Code: '8115',
    Name: 'Baller, iron works'
  },
  {
    Id: 21875,
    Code: '8115',
    Name: 'Beater, leaf'
  },
  {
    Id: 21876,
    Code: '8115',
    Name: 'Bedman, blast furnace'
  },
  {
    Id: 21877,
    Code: '8115',
    Name: 'Behinder'
  },
  {
    Id: 21878,
    Code: '8115',
    Name: 'Bender, arch'
  },
  {
    Id: 21879,
    Code: '8115',
    Name: 'Bender, bar, handle'
  },
  {
    Id: 21880,
    Code: '8115',
    Name: 'Bender, spoke, cycle mfr'
  },
  {
    Id: 21881,
    Code: '8115',
    Name: 'Bender, rolling mill'
  },
  {
    Id: 21882,
    Code: '8115',
    Name: 'Billeter, rolling mill'
  },
  {
    Id: 21883,
    Code: '8115',
    Name: 'Blaster, furnace'
  },
  {
    Id: 21884,
    Code: '8115',
    Name: 'Blower, steelworks'
  },
  {
    Id: 21885,
    Code: '8115',
    Name: 'Bolter-down, metal trades'
  },
  {
    Id: 21886,
    Code: '8115',
    Name: 'Bonderiser'
  },
  {
    Id: 21887,
    Code: '8115',
    Name: 'Boxman, steel mfr'
  },
  {
    Id: 21888,
    Code: '8115',
    Name: 'Breaker, bear'
  },
  {
    Id: 21889,
    Code: '8115',
    Name: 'Breaker, skull'
  },
  {
    Id: 21890,
    Code: '8115',
    Name: 'Breaker, rolling mill'
  },
  {
    Id: 21891,
    Code: '8115',
    Name: 'Breaker-down, rolling mill'
  },
  {
    Id: 21892,
    Code: '8115',
    Name: 'Bronzer, metal'
  },
  {
    Id: 21893,
    Code: '8115',
    Name: 'Bronzer'
  },
  {
    Id: 21894,
    Code: '8115',
    Name: 'Browner'
  },
  {
    Id: 21895,
    Code: '8115',
    Name: 'Builder, mop, steelworks'
  },
  {
    Id: 21896,
    Code: '8115',
    Name: 'Burner, metal sinter plant'
  },
  {
    Id: 21897,
    Code: '8115',
    Name: 'Busher, lead'
  },
  {
    Id: 21898,
    Code: '8115',
    Name: 'Buttoner, rolling mill'
  },
  {
    Id: 21899,
    Code: '8115',
    Name: 'Carboniser, ball bearing mfr'
  },
  {
    Id: 21900,
    Code: '8115',
    Name: 'Carburizer'
  },
  {
    Id: 21901,
    Code: '8115',
    Name: 'Carrier, bar'
  },
  {
    Id: 21902,
    Code: '8115',
    Name: 'Caser, metal'
  },
  {
    Id: 21903,
    Code: '8115',
    Name: 'Caster, brass'
  },
  {
    Id: 21904,
    Code: '8115',
    Name: 'Caster, furnace, blast'
  },
  {
    Id: 21905,
    Code: '8115',
    Name: 'Caster, ingot'
  },
  {
    Id: 21906,
    Code: '8115',
    Name: 'Caster, iron'
  },
  {
    Id: 21907,
    Code: '8115',
    Name: 'Caster, lead, battery mfr'
  },
  {
    Id: 21908,
    Code: '8115',
    Name: 'Caster, metal'
  },
  {
    Id: 21909,
    Code: '8115',
    Name: 'Caster, needle'
  },
  {
    Id: 21910,
    Code: '8115',
    Name: 'Caster, shot'
  },
  {
    Id: 21911,
    Code: '8115',
    Name: 'Caster, strip'
  },
  {
    Id: 21912,
    Code: '8115',
    Name: 'Caster, type'
  },
  {
    Id: 21913,
    Code: '8115',
    Name: 'Caster, metal trades'
  },
  {
    Id: 21914,
    Code: '8115',
    Name: 'Catcher, bar'
  },
  {
    Id: 21915,
    Code: '8115',
    Name: 'Catcher, finishing'
  },
  {
    Id: 21916,
    Code: '8115',
    Name: 'Catcher, mill, sheet'
  },
  {
    Id: 21917,
    Code: '8115',
    Name: 'Catcher, roll, cold'
  },
  {
    Id: 21918,
    Code: '8115',
    Name: 'Catcher, steelworks'
  },
  {
    Id: 21919,
    Code: '8115',
    Name: 'Catcher and sticker, wire'
  },
  {
    Id: 21920,
    Code: '8115',
    Name: 'Cellarman, steelworks'
  },
  {
    Id: 21921,
    Code: '8115',
    Name: 'Changer, gold'
  },
  {
    Id: 21922,
    Code: '8115',
    Name: 'Changer, roll'
  },
  {
    Id: 21923,
    Code: '8115',
    Name: 'Charger, tinplate mfr'
  },
  {
    Id: 21924,
    Code: '8115',
    Name: 'Chipper-in, rolling mill'
  },
  {
    Id: 21925,
    Code: '8115',
    Name: 'Chromer, metal trades'
  },
  {
    Id: 21926,
    Code: '8115',
    Name: 'Cleaner, core'
  },
  {
    Id: 21927,
    Code: '8115',
    Name: 'Cleaner, press, rolling mill'
  },
  {
    Id: 21928,
    Code: '8115',
    Name: 'Cleaner, wire, wire mfr'
  },
  {
    Id: 21929,
    Code: '8115',
    Name: 'Cleaner, cartridge mfr'
  },
  {
    Id: 21930,
    Code: '8115',
    Name: 'Cleaner, electroplating'
  },
  {
    Id: 21931,
    Code: '8115',
    Name: 'Cleaner, enamelling'
  },
  {
    Id: 21932,
    Code: '8115',
    Name: 'Cleaner, galvanised sheet mfr'
  },
  {
    Id: 21933,
    Code: '8115',
    Name: 'Clipper, metal trades'
  },
  {
    Id: 21934,
    Code: '8115',
    Name: 'Closer, foundry'
  },
  {
    Id: 21935,
    Code: '8115',
    Name: 'Coater, colour'
  },
  {
    Id: 21936,
    Code: '8115',
    Name: 'Coater, powder'
  },
  {
    Id: 21937,
    Code: '8115',
    Name: 'Coater, tinplate mfr'
  },
  {
    Id: 21938,
    Code: '8115',
    Name: 'Coater, wire mfr'
  },
  {
    Id: 21939,
    Code: '8115',
    Name: 'Cogger, rolling mill'
  },
  {
    Id: 21940,
    Code: '8115',
    Name: 'Coiler, copper'
  },
  {
    Id: 21941,
    Code: '8115',
    Name: 'Coiler, steel mfr'
  },
  {
    Id: 21942,
    Code: '8115',
    Name: 'Coiler, wire mfr'
  },
  {
    Id: 21943,
    Code: '8115',
    Name: 'Coiler, wire rod mfr'
  },
  {
    Id: 21944,
    Code: '8115',
    Name: 'Colourer, metal'
  },
  {
    Id: 21945,
    Code: '8115',
    Name: 'Colourer, steel pens'
  },
  {
    Id: 21946,
    Code: '8115',
    Name: 'Controlman, blast furnace'
  },
  {
    Id: 21947,
    Code: '8115',
    Name: 'Converter, steel'
  },
  {
    Id: 21948,
    Code: '8115',
    Name: 'Converter, metal mfr'
  },
  {
    Id: 21949,
    Code: '8115',
    Name: 'Copperer, carbon brushes'
  },
  {
    Id: 21950,
    Code: '8115',
    Name: 'Coverer, lead'
  },
  {
    Id: 21951,
    Code: '8115',
    Name: 'Cutter, nut'
  },
  {
    Id: 21952,
    Code: '8115',
    Name: 'Cutter-down, rolling mill'
  },
  {
    Id: 21953,
    Code: '8115',
    Name: 'Cutter-out, cutlery mfr'
  },
  {
    Id: 21954,
    Code: '8115',
    Name: 'Cutter-through, steelworks'
  },
  {
    Id: 21955,
    Code: '8115',
    Name: 'De-ruster'
  },
  {
    Id: 21956,
    Code: '8115',
    Name: 'Degreaser, metal trades'
  },
  {
    Id: 21957,
    Code: '8115',
    Name: 'Depositor, electroplating'
  },
  {
    Id: 21958,
    Code: '8115',
    Name: 'Depositor, welding'
  },
  {
    Id: 21959,
    Code: '8115',
    Name: 'Descaler, steelworks'
  },
  {
    Id: 21960,
    Code: '8115',
    Name: 'Desilveriser'
  },
  {
    Id: 21961,
    Code: '8115',
    Name: 'Dipper, acid'
  },
  {
    Id: 21962,
    Code: '8115',
    Name: 'Dipper, brass'
  },
  {
    Id: 21963,
    Code: '8115',
    Name: 'Dipper, core'
  },
  {
    Id: 21964,
    Code: '8115',
    Name: 'Dipper, galvanising'
  },
  {
    Id: 21965,
    Code: '8115',
    Name: 'Dipper, metal'
  },
  {
    Id: 21966,
    Code: '8115',
    Name: 'Dipper, wire'
  },
  {
    Id: 21967,
    Code: '8115',
    Name: 'Dipper, arc welding electrode mfr'
  },
  {
    Id: 21968,
    Code: '8115',
    Name: 'Dipper, galvanising'
  },
  {
    Id: 21969,
    Code: '8115',
    Name: 'Dipper, precious metal, plate mfr'
  },
  {
    Id: 21970,
    Code: '8115',
    Name: 'Dipper and stripper'
  },
  {
    Id: 21971,
    Code: '8115',
    Name: 'Distiller, lead, zinc refining'
  },
  {
    Id: 21972,
    Code: '8115',
    Name: 'Distributor, paste, aluminium mfr'
  },
  {
    Id: 21973,
    Code: '8115',
    Name: 'Doorman, furnace'
  },
  {
    Id: 21974,
    Code: '8115',
    Name: 'Doorman, forging'
  },
  {
    Id: 21975,
    Code: '8115',
    Name: 'Doubler, metal rolling'
  },
  {
    Id: 21976,
    Code: '8115',
    Name: 'Dragger, bar'
  },
  {
    Id: 21977,
    Code: '8115',
    Name: 'Dragger-down'
  },
  {
    Id: 21978,
    Code: '8115',
    Name: 'Drawer, bar'
  },
  {
    Id: 21979,
    Code: '8115',
    Name: 'Drawer, fork'
  },
  {
    Id: 21980,
    Code: '8115',
    Name: 'Drawer, plate, wire'
  },
  {
    Id: 21981,
    Code: '8115',
    Name: 'Drawer, rod, metal'
  },
  {
    Id: 21982,
    Code: '8115',
    Name: 'Drawer, steel'
  },
  {
    Id: 21983,
    Code: '8115',
    Name: 'Drawer, strip, metal'
  },
  {
    Id: 21984,
    Code: '8115',
    Name: 'Drawer, tape, metal'
  },
  {
    Id: 21985,
    Code: '8115',
    Name: 'Drawer, tube, metal'
  },
  {
    Id: 21986,
    Code: '8115',
    Name: 'Drawer, wire'
  },
  {
    Id: 21987,
    Code: '8115',
    Name: 'Drawer, metal'
  },
  {
    Id: 21988,
    Code: '8115',
    Name: 'Drawer, metal trades'
  },
  {
    Id: 21989,
    Code: '8115',
    Name: 'Drawer, puddling'
  },
  {
    Id: 21990,
    Code: '8115',
    Name: 'Drawer, zinc refining'
  },
  {
    Id: 21991,
    Code: '8115',
    Name: 'Drawer and marker, Assay Office'
  },
  {
    Id: 21992,
    Code: '8115',
    Name: 'Dresser, wire'
  },
  {
    Id: 21993,
    Code: '8115',
    Name: 'Dresser, metal fastening mfr'
  },
  {
    Id: 21994,
    Code: '8115',
    Name: 'Driver, assistant, iron and steelworks'
  },
  {
    Id: 21995,
    Code: '8115',
    Name: 'Driver, caster'
  },
  {
    Id: 21996,
    Code: '8115',
    Name: 'Driver, control, steelworks'
  },
  {
    Id: 21997,
    Code: '8115',
    Name: 'Driver, gear, rolling mill'
  },
  {
    Id: 21998,
    Code: '8115',
    Name: 'Driver, hammer'
  },
  {
    Id: 21999,
    Code: '8115',
    Name: 'Driver, press'
  },
  {
    Id: 22000,
    Code: '8115',
    Name: 'Driver, rack, rolling mill'
  },
  {
    Id: 22001,
    Code: '8115',
    Name: 'Driver, rest, rolling mill'
  },
  {
    Id: 22002,
    Code: '8115',
    Name: 'Driver, roll'
  },
  {
    Id: 22003,
    Code: '8115',
    Name: 'Driver, roller, steelworks'
  },
  {
    Id: 22004,
    Code: '8115',
    Name: 'Driver, skid, rolling mill'
  },
  {
    Id: 22005,
    Code: '8115',
    Name: 'Driver, spray, water, rolling mill'
  },
  {
    Id: 22006,
    Code: '8115',
    Name: 'Driver, table, rolling mill'
  },
  {
    Id: 22007,
    Code: '8115',
    Name: 'Driver, tilter'
  },
  {
    Id: 22008,
    Code: '8115',
    Name: 'Drosser'
  },
  {
    Id: 22009,
    Code: '8115',
    Name: 'Drossman'
  },
  {
    Id: 22010,
    Code: '8115',
    Name: 'Dryer, core, foundry'
  },
  {
    Id: 22011,
    Code: '8115',
    Name: 'Electro-brasser, screws'
  },
  {
    Id: 22012,
    Code: '8115',
    Name: 'Electroformer'
  },
  {
    Id: 22013,
    Code: '8115',
    Name: 'Electroplater'
  },
  {
    Id: 22014,
    Code: '8115',
    Name: 'Engineer, treatment, heat'
  },
  {
    Id: 22015,
    Code: '8115',
    Name: 'Extruder, cable, metal trades'
  },
  {
    Id: 22016,
    Code: '8115',
    Name: 'Extruder, metal'
  },
  {
    Id: 22017,
    Code: '8115',
    Name: 'Extruder, metal'
  },
  {
    Id: 22018,
    Code: '8115',
    Name: 'Fabricator, pipe, heavy engineering'
  },
  {
    Id: 22019,
    Code: '8115',
    Name: 'Fabricator, tube mfr'
  },
  {
    Id: 22020,
    Code: '8115',
    Name: 'Feeder, furnace'
  },
  {
    Id: 22021,
    Code: '8115',
    Name: 'Feeder, pass, skin, steelworks'
  },
  {
    Id: 22022,
    Code: '8115',
    Name: 'Feeder, roll, metal mfr'
  },
  {
    Id: 22023,
    Code: '8115',
    Name: 'Feeder, rolls, metal mfr'
  },
  {
    Id: 22024,
    Code: '8115',
    Name: 'Feeder, foundry'
  },
  {
    Id: 22025,
    Code: '8115',
    Name: 'Feeder, metal tube mfr'
  },
  {
    Id: 22026,
    Code: '8115',
    Name: 'Feeder, rolling mill'
  },
  {
    Id: 22027,
    Code: '8115',
    Name: 'Fettler, puddling'
  },
  {
    Id: 22028,
    Code: '8115',
    Name: 'Filler, pan, steelworks'
  },
  {
    Id: 22029,
    Code: '8115',
    Name: 'Filler, plug'
  },
  {
    Id: 22030,
    Code: '8115',
    Name: 'Filler, wire'
  },
  {
    Id: 22031,
    Code: '8115',
    Name: 'Finisher, chromium'
  },
  {
    Id: 22032,
    Code: '8115',
    Name: 'Finisher, spring'
  },
  {
    Id: 22033,
    Code: '8115',
    Name: 'Finisher, tube, steelworks'
  },
  {
    Id: 22034,
    Code: '8115',
    Name: 'Finisher, metal tube mfr'
  },
  {
    Id: 22035,
    Code: '8115',
    Name: 'Finisher, rolling mill'
  },
  {
    Id: 22036,
    Code: '8115',
    Name: 'Finisher, spring mfr'
  },
  {
    Id: 22037,
    Code: '8115',
    Name: 'Fireman, furnace, annealing'
  },
  {
    Id: 22038,
    Code: '8115',
    Name: 'Fireman, furnace, metal trades'
  },
  {
    Id: 22039,
    Code: '8115',
    Name: 'Fireman, oven, annealing'
  },
  {
    Id: 22040,
    Code: '8115',
    Name: 'Fireman, retort, zinc'
  },
  {
    Id: 22041,
    Code: '8115',
    Name: 'Fireman, soaker'
  },
  {
    Id: 22042,
    Code: '8115',
    Name: 'Fireman, stove'
  },
  {
    Id: 22043,
    Code: '8115',
    Name: 'Fireman, annealing'
  },
  {
    Id: 22044,
    Code: '8115',
    Name: 'Fireman, metal trades'
  },
  {
    Id: 22045,
    Code: '8115',
    Name: 'Fireman, shipbuilding'
  },
  {
    Id: 22046,
    Code: '8115',
    Name: 'Firer, stove, blast furnace'
  },
  {
    Id: 22047,
    Code: '8115',
    Name: 'Firer, metal mfr'
  },
  {
    Id: 22048,
    Code: '8115',
    Name: 'Fisher, copper'
  },
  {
    Id: 22049,
    Code: '8115',
    Name: 'Fitter, mould'
  },
  {
    Id: 22050,
    Code: '8115',
    Name: 'Flaker-on, electric cable'
  },
  {
    Id: 22051,
    Code: '8115',
    Name: 'Flattener, patent, galvanised sheet'
  },
  {
    Id: 22052,
    Code: '8115',
    Name: 'Flattener, sheet, metal'
  },
  {
    Id: 22053,
    Code: '8115',
    Name: 'Forehand'
  },
  {
    Id: 22054,
    Code: '8115',
    Name: 'Forgeman'
  },
  {
    Id: 22055,
    Code: '8115',
    Name: 'Forker, wrought iron mfr'
  },
  {
    Id: 22056,
    Code: '8115',
    Name: 'Former, tube'
  },
  {
    Id: 22057,
    Code: '8115',
    Name: 'Former, tube mfr'
  },
  {
    Id: 22058,
    Code: '8115',
    Name: 'Founder, type'
  },
  {
    Id: 22059,
    Code: '8115',
    Name: 'Founder, metal trades'
  },
  {
    Id: 22060,
    Code: '8115',
    Name: 'Furnaceman, annealing'
  },
  {
    Id: 22061,
    Code: '8115',
    Name: 'Furnaceman, blast'
  },
  {
    Id: 22062,
    Code: '8115',
    Name: 'Furnaceman, brass'
  },
  {
    Id: 22063,
    Code: '8115',
    Name: 'Furnaceman, chrome'
  },
  {
    Id: 22064,
    Code: '8115',
    Name: 'Furnaceman, cupola'
  },
  {
    Id: 22065,
    Code: '8115',
    Name: 'Furnaceman, electric'
  },
  {
    Id: 22066,
    Code: '8115',
    Name: 'Furnaceman, hardening, case'
  },
  {
    Id: 22067,
    Code: '8115',
    Name: 'Furnaceman, hearth, open'
  },
  {
    Id: 22068,
    Code: '8115',
    Name: 'Furnaceman, spring, coach'
  },
  {
    Id: 22069,
    Code: '8115',
    Name: 'Furnaceman, treatment, heat'
  },
  {
    Id: 22070,
    Code: '8115',
    Name: 'Furnaceman, metal trades'
  },
  {
    Id: 22071,
    Code: '8115',
    Name: 'Fuser, enamel'
  },
  {
    Id: 22072,
    Code: '8115',
    Name: 'Fuser, metal trades'
  },
  {
    Id: 22073,
    Code: '8115',
    Name: 'Galvaniser'
  },
  {
    Id: 22074,
    Code: '8115',
    Name: 'Gauger, furnace'
  },
  {
    Id: 22075,
    Code: '8115',
    Name: 'Gilder and plater'
  },
  {
    Id: 22076,
    Code: '8115',
    Name: 'Granulator, aluminium'
  },
  {
    Id: 22077,
    Code: '8115',
    Name: 'Grouter, mould, steelworks'
  },
  {
    Id: 22078,
    Code: '8115',
    Name: 'Guider, tube mfr'
  },
  {
    Id: 22079,
    Code: '8115',
    Name: 'Hand, bench, brass foundry'
  },
  {
    Id: 22080,
    Code: '8115',
    Name: 'Hand, cupola, metal mfr'
  },
  {
    Id: 22081,
    Code: '8115',
    Name: 'Hand, first, steelworks'
  },
  {
    Id: 22082,
    Code: '8115',
    Name: 'Hand, forge'
  },
  {
    Id: 22083,
    Code: '8115',
    Name: 'Hand, furnace, blast'
  },
  {
    Id: 22084,
    Code: '8115',
    Name: 'Hand, furnace, metal mfr'
  },
  {
    Id: 22085,
    Code: '8115',
    Name: 'Hand, mill, rolling'
  },
  {
    Id: 22086,
    Code: '8115',
    Name: 'Hand, mill, galvanised sheet mfr'
  },
  {
    Id: 22087,
    Code: '8115',
    Name: 'Hand, mill, tinplate mfr'
  },
  {
    Id: 22088,
    Code: '8115',
    Name: 'Hand, pit, tube mfr'
  },
  {
    Id: 22089,
    Code: '8115',
    Name: 'Hand, roll, steel mfr'
  },
  {
    Id: 22090,
    Code: '8115',
    Name: "Hand, second, roller's"
  },
  {
    Id: 22091,
    Code: '8115',
    Name: 'Hand, second, metal rolling'
  },
  {
    Id: 22092,
    Code: '8115',
    Name: 'Hand, second, steelworks'
  },
  {
    Id: 22093,
    Code: '8115',
    Name: 'Hand, spare, rolling mill'
  },
  {
    Id: 22094,
    Code: '8115',
    Name: 'Hand, stretcher, aluminium mfr'
  },
  {
    Id: 22095,
    Code: '8115',
    Name: 'Hand, third, foundry'
  },
  {
    Id: 22096,
    Code: '8115',
    Name: 'Hand, third, rolling mill'
  },
  {
    Id: 22097,
    Code: '8115',
    Name: 'Hand, vat, electroplating'
  },
  {
    Id: 22098,
    Code: '8115',
    Name: 'Hardener, blade'
  },
  {
    Id: 22099,
    Code: '8115',
    Name: 'Hardener, case'
  },
  {
    Id: 22100,
    Code: '8115',
    Name: 'Hardener, die and mill'
  },
  {
    Id: 22101,
    Code: '8115',
    Name: 'Hardener, drill'
  },
  {
    Id: 22102,
    Code: '8115',
    Name: 'Hardener, file'
  },
  {
    Id: 22103,
    Code: '8115',
    Name: 'Hardener, ring'
  },
  {
    Id: 22104,
    Code: '8115',
    Name: 'Hardener, saw'
  },
  {
    Id: 22105,
    Code: '8115',
    Name: 'Hardener, section'
  },
  {
    Id: 22106,
    Code: '8115',
    Name: 'Hardener, tool'
  },
  {
    Id: 22107,
    Code: '8115',
    Name: 'Hardener, metal'
  },
  {
    Id: 22108,
    Code: '8115',
    Name: 'Haulier, shop, tube mfr'
  },
  {
    Id: 22109,
    Code: '8115',
    Name: 'Heater, ingot'
  },
  {
    Id: 22110,
    Code: '8115',
    Name: 'Heater, iron, foundry'
  },
  {
    Id: 22111,
    Code: '8115',
    Name: 'Heater, mill, rolling mill'
  },
  {
    Id: 22112,
    Code: '8115',
    Name: 'Heater, pit, soaking'
  },
  {
    Id: 22113,
    Code: '8115',
    Name: "Heater, smith's"
  },
  {
    Id: 22114,
    Code: '8115',
    Name: 'Heater, tube, steel'
  },
  {
    Id: 22115,
    Code: '8115',
    Name: 'Heater, metal trades'
  },
  {
    Id: 22116,
    Code: '8115',
    Name: "Helper, blacksmith's"
  },
  {
    Id: 22117,
    Code: '8115',
    Name: "Helper, caster's"
  },
  {
    Id: 22118,
    Code: '8115',
    Name: 'Helper, first, rolling mill'
  },
  {
    Id: 22119,
    Code: '8115',
    Name: 'Helper, first, tinplate mfr'
  },
  {
    Id: 22120,
    Code: '8115',
    Name: 'Helper, forge'
  },
  {
    Id: 22121,
    Code: '8115',
    Name: "Helper, furnaceman's"
  },
  {
    Id: 22122,
    Code: '8115',
    Name: "Helper, keeper's"
  },
  {
    Id: 22123,
    Code: '8115',
    Name: "Helper, pickler's, metal trades"
  },
  {
    Id: 22124,
    Code: '8115',
    Name: 'Helper, pit'
  },
  {
    Id: 22125,
    Code: '8115',
    Name: "Helper, pitman's"
  },
  {
    Id: 22126,
    Code: '8115',
    Name: "Helper, potman's, nickel"
  },
  {
    Id: 22127,
    Code: '8115',
    Name: 'Helper, press'
  },
  {
    Id: 22128,
    Code: '8115',
    Name: "Helper, roller's"
  },
  {
    Id: 22129,
    Code: '8115',
    Name: "Helper, smith's"
  },
  {
    Id: 22130,
    Code: '8115',
    Name: "Helper, stamper's"
  },
  {
    Id: 22131,
    Code: '8115',
    Name: "Helper, straightener's, rolling mill"
  },
  {
    Id: 22132,
    Code: '8115',
    Name: 'Helper, blast furnace'
  },
  {
    Id: 22133,
    Code: '8115',
    Name: 'Helper, copper refining'
  },
  {
    Id: 22134,
    Code: '8115',
    Name: 'Helper, forging'
  },
  {
    Id: 22135,
    Code: '8115',
    Name: 'Helper, metal tube mfr'
  },
  {
    Id: 22136,
    Code: '8115',
    Name: 'Helper, rolling mill'
  },
  {
    Id: 22137,
    Code: '8115',
    Name: 'Holder, double, rolling mill'
  },
  {
    Id: 22138,
    Code: '8115',
    Name: 'Hooker, rolling mill'
  },
  {
    Id: 22139,
    Code: '8115',
    Name: 'Illuminiser'
  },
  {
    Id: 22140,
    Code: '8115',
    Name: 'Injector, wax'
  },
  {
    Id: 22141,
    Code: '8115',
    Name: 'Inspector, furnace'
  },
  {
    Id: 22142,
    Code: '8115',
    Name: 'Jigger, metal trades'
  },
  {
    Id: 22143,
    Code: '8115',
    Name: 'Keeper, furnace, metal mfr'
  },
  {
    Id: 22144,
    Code: '8115',
    Name: 'Keeper, blast furnace'
  },
  {
    Id: 22145,
    Code: '8115',
    Name: 'Kettleman'
  },
  {
    Id: 22146,
    Code: '8115',
    Name: 'Ladler, copper lead refining'
  },
  {
    Id: 22147,
    Code: '8115',
    Name: 'Lapper, metal tube mfr'
  },
  {
    Id: 22148,
    Code: '8115',
    Name: 'Layer, core'
  },
  {
    Id: 22149,
    Code: '8115',
    Name: 'Leader, abrasive wheel mfr'
  },
  {
    Id: 22150,
    Code: '8115',
    Name: 'Levelhand'
  },
  {
    Id: 22151,
    Code: '8115',
    Name: 'Leveller, plate'
  },
  {
    Id: 22152,
    Code: '8115',
    Name: 'Leveller, roller, steelworks'
  },
  {
    Id: 22153,
    Code: '8115',
    Name: 'Leverman, iron and steelworks'
  },
  {
    Id: 22154,
    Code: '8115',
    Name: 'Lifter-up, rolling mill'
  },
  {
    Id: 22155,
    Code: '8115',
    Name: 'Machinist, bending, iron works'
  },
  {
    Id: 22156,
    Code: '8115',
    Name: 'Machinist, casting, centrifugal, steel'
  },
  {
    Id: 22157,
    Code: '8115',
    Name: 'Machinist, casting, pig'
  },
  {
    Id: 22158,
    Code: '8115',
    Name: 'Machinist, coiling, metal tube mfr'
  },
  {
    Id: 22159,
    Code: '8115',
    Name: 'Machinist, drawing, wire'
  },
  {
    Id: 22160,
    Code: '8115',
    Name: 'Machinist, extruding, metal tube mfr'
  },
  {
    Id: 22161,
    Code: '8115',
    Name: 'Machinist, forge'
  },
  {
    Id: 22162,
    Code: '8115',
    Name: 'Machinist, forging'
  },
  {
    Id: 22163,
    Code: '8115',
    Name: 'Machinist, gold'
  },
  {
    Id: 22164,
    Code: '8115',
    Name: 'Machinist, moulding, lead refining'
  },
  {
    Id: 22165,
    Code: '8115',
    Name: 'Machinist, plating, wire'
  },
  {
    Id: 22166,
    Code: '8115',
    Name: 'Machinist, plating, metal trades'
  },
  {
    Id: 22167,
    Code: '8115',
    Name: 'Machinist, rolling, metal trades'
  },
  {
    Id: 22168,
    Code: '8115',
    Name: 'Machinist, stoving, metal goods'
  },
  {
    Id: 22169,
    Code: '8115',
    Name: 'Machinist, upsetting'
  },
  {
    Id: 22170,
    Code: '8115',
    Name: 'Machinist, wire'
  },
  {
    Id: 22171,
    Code: '8115',
    Name: 'Maker, block, cylinder'
  },
  {
    Id: 22172,
    Code: '8115',
    Name: 'Maker, block, radiator'
  },
  {
    Id: 22173,
    Code: '8115',
    Name: 'Maker, fittings, tube'
  },
  {
    Id: 22174,
    Code: '8115',
    Name: 'Maker, last, iron'
  },
  {
    Id: 22175,
    Code: '8115',
    Name: "Maker, lead, printer's"
  },
  {
    Id: 22176,
    Code: '8115',
    Name: 'Maker, nail, forged'
  },
  {
    Id: 22177,
    Code: '8115',
    Name: 'Maker, nail, frost'
  },
  {
    Id: 22178,
    Code: '8115',
    Name: 'Maker, nail, wrought'
  },
  {
    Id: 22179,
    Code: '8115',
    Name: 'Maker, pipe, lead'
  },
  {
    Id: 22180,
    Code: '8115',
    Name: 'Maker, plate, accumulator'
  },
  {
    Id: 22181,
    Code: '8115',
    Name: 'Maker, punch'
  },
  {
    Id: 22182,
    Code: '8115',
    Name: 'Maker, runner'
  },
  {
    Id: 22183,
    Code: '8115',
    Name: 'Maker, screw, frost'
  },
  {
    Id: 22184,
    Code: '8115',
    Name: 'Maker, strap, fork'
  },
  {
    Id: 22185,
    Code: '8115',
    Name: 'Maker, tube, metal'
  },
  {
    Id: 22186,
    Code: '8115',
    Name: 'Maker, tube, metal'
  },
  {
    Id: 22187,
    Code: '8115',
    Name: 'Maker, tube, musical instruments mfr'
  },
  {
    Id: 22188,
    Code: '8115',
    Name: 'Man, annealing'
  },
  {
    Id: 22189,
    Code: '8115',
    Name: 'Man, bell, blast furnace'
  },
  {
    Id: 22190,
    Code: '8115',
    Name: 'Man, casting, blast furnace'
  },
  {
    Id: 22191,
    Code: '8115',
    Name: 'Man, condenser, blast furnace'
  },
  {
    Id: 22192,
    Code: '8115',
    Name: 'Man, converter, metal mfr'
  },
  {
    Id: 22193,
    Code: '8115',
    Name: 'Man, crucible, metal'
  },
  {
    Id: 22194,
    Code: '8115',
    Name: 'Man, cupel'
  },
  {
    Id: 22195,
    Code: '8115',
    Name: 'Man, cupola'
  },
  {
    Id: 22196,
    Code: '8115',
    Name: 'Man, drop, blast furnace'
  },
  {
    Id: 22197,
    Code: '8115',
    Name: 'Man, extractor, tube mfr'
  },
  {
    Id: 22198,
    Code: '8115',
    Name: 'Man, fan, lead mfr'
  },
  {
    Id: 22199,
    Code: '8115',
    Name: 'Man, foundry'
  },
  {
    Id: 22200,
    Code: '8115',
    Name: 'Man, ingot, non-ferrous metal mfr'
  },
  {
    Id: 22201,
    Code: '8115',
    Name: 'Man, ladle, direct'
  },
  {
    Id: 22202,
    Code: '8115',
    Name: 'Man, ladle, metal mfr'
  },
  {
    Id: 22203,
    Code: '8115',
    Name: 'Man, lime, steelworks'
  },
  {
    Id: 22204,
    Code: '8115',
    Name: 'Man, mill, metal mfr'
  },
  {
    Id: 22205,
    Code: '8115',
    Name: 'Man, mixer, steel mfr'
  },
  {
    Id: 22206,
    Code: '8115',
    Name: 'Man, moulds, metal mfr'
  },
  {
    Id: 22207,
    Code: '8115',
    Name: 'Man, muffle, annealing'
  },
  {
    Id: 22208,
    Code: '8115',
    Name: 'Man, muffle, foundry'
  },
  {
    Id: 22209,
    Code: '8115',
    Name: 'Man, muffle, steel mfr'
  },
  {
    Id: 22210,
    Code: '8115',
    Name: 'Man, pit, steelworks'
  },
  {
    Id: 22211,
    Code: '8115',
    Name: 'Man, platform, steelworks'
  },
  {
    Id: 22212,
    Code: '8115',
    Name: 'Man, still, metal smelting'
  },
  {
    Id: 22213,
    Code: '8115',
    Name: 'Man, tin, tinplate mfr'
  },
  {
    Id: 22214,
    Code: '8115',
    Name: 'Man, vessel, steelworks'
  },
  {
    Id: 22215,
    Code: '8115',
    Name: 'Man, vice, spring'
  },
  {
    Id: 22216,
    Code: '8115',
    Name: 'Mangler, plate'
  },
  {
    Id: 22217,
    Code: '8115',
    Name: 'Mangler, steelworks'
  },
  {
    Id: 22218,
    Code: '8115',
    Name: 'Manipulator, metal mfr'
  },
  {
    Id: 22219,
    Code: '8115',
    Name: 'Manufacturer, metal mfr'
  },
  {
    Id: 22220,
    Code: '8115',
    Name: 'Marker, billet, steelworks'
  },
  {
    Id: 22221,
    Code: '8115',
    Name: 'Marker, hall, Assay Office'
  },
  {
    Id: 22222,
    Code: '8115',
    Name: 'Marker, ingot, metal mfr'
  },
  {
    Id: 22223,
    Code: '8115',
    Name: 'Marker, plate, rolling mill'
  },
  {
    Id: 22224,
    Code: '8115',
    Name: 'Marker-off, foundry'
  },
  {
    Id: 22225,
    Code: '8115',
    Name: "Mate, blacksmith's"
  },
  {
    Id: 22226,
    Code: '8115',
    Name: "Mate, forger's, drop"
  },
  {
    Id: 22227,
    Code: '8115',
    Name: "Mate, smith's"
  },
  {
    Id: 22228,
    Code: '8115',
    Name: 'Melter, bullion'
  },
  {
    Id: 22229,
    Code: '8115',
    Name: 'Melter, electric'
  },
  {
    Id: 22230,
    Code: '8115',
    Name: 'Melter, gold'
  },
  {
    Id: 22231,
    Code: '8115',
    Name: 'Melter, hand, first'
  },
  {
    Id: 22232,
    Code: '8115',
    Name: 'Melter, lead'
  },
  {
    Id: 22233,
    Code: '8115',
    Name: 'Melter, platinum'
  },
  {
    Id: 22234,
    Code: '8115',
    Name: 'Melter, silver'
  },
  {
    Id: 22235,
    Code: '8115',
    Name: 'Melter, steel'
  },
  {
    Id: 22236,
    Code: '8115',
    Name: 'Melter, Royal Mint'
  },
  {
    Id: 22237,
    Code: '8115',
    Name: 'Melter, steelworks'
  },
  {
    Id: 22238,
    Code: '8115',
    Name: 'Melter, zinc smelting'
  },
  {
    Id: 22239,
    Code: '8115',
    Name: 'Metaller'
  },
  {
    Id: 22240,
    Code: '8115',
    Name: 'Metalliser, spray'
  },
  {
    Id: 22241,
    Code: '8115',
    Name: 'Metalliser, lamp, valve mfr'
  },
  {
    Id: 22242,
    Code: '8115',
    Name: 'Middler, rolling mill'
  },
  {
    Id: 22243,
    Code: '8115',
    Name: 'Minder, cell, galvanising'
  },
  {
    Id: 22244,
    Code: '8115',
    Name: 'Minder, stove'
  },
  {
    Id: 22245,
    Code: '8115',
    Name: 'Mixer, metal'
  },
  {
    Id: 22246,
    Code: '8115',
    Name: 'Mixer, sand, metal mfr'
  },
  {
    Id: 22247,
    Code: '8115',
    Name: 'Mixer, steelworks'
  },
  {
    Id: 22248,
    Code: '8115',
    Name: 'Moulder, battery'
  },
  {
    Id: 22249,
    Code: '8115',
    Name: 'Moulder, fork, digging, hay'
  },
  {
    Id: 22250,
    Code: '8115',
    Name: 'Moulder, lead, battery mfr'
  },
  {
    Id: 22251,
    Code: '8115',
    Name: 'Nitrider'
  },
  {
    Id: 22252,
    Code: '8115',
    Name: 'Normaliser'
  },
  {
    Id: 22253,
    Code: '8115',
    Name: 'Oiler, tube, tube mfr'
  },
  {
    Id: 22254,
    Code: '8115',
    Name: 'Opener, hot, steel mfr'
  },
  {
    Id: 22255,
    Code: '8115',
    Name: 'Opener, plate, tinplate'
  },
  {
    Id: 22256,
    Code: '8115',
    Name: 'Opener, tinplate mfr'
  },
  {
    Id: 22257,
    Code: '8115',
    Name: 'Operator, bath, salt, metal goods mfr'
  },
  {
    Id: 22258,
    Code: '8115',
    Name: 'Operator, bench, draw'
  },
  {
    Id: 22259,
    Code: '8115',
    Name: 'Operator, block, bull'
  },
  {
    Id: 22260,
    Code: '8115',
    Name: 'Operator, castings, metal mfr'
  },
  {
    Id: 22261,
    Code: '8115',
    Name: 'Operator, coil, steel'
  },
  {
    Id: 22262,
    Code: '8115',
    Name: 'Operator, control, steelworks'
  },
  {
    Id: 22263,
    Code: '8115',
    Name: 'Operator, degrease'
  },
  {
    Id: 22264,
    Code: '8115',
    Name: 'Operator, degreaser'
  },
  {
    Id: 22265,
    Code: '8115',
    Name: 'Operator, depiler, metal mfr'
  },
  {
    Id: 22266,
    Code: '8115',
    Name: 'Operator, dip, metal trades'
  },
  {
    Id: 22267,
    Code: '8115',
    Name: 'Operator, extruder, metal trades'
  },
  {
    Id: 22268,
    Code: '8115',
    Name: 'Operator, extrusion, metal trades'
  },
  {
    Id: 22269,
    Code: '8115',
    Name: 'Operator, forge'
  },
  {
    Id: 22270,
    Code: '8115',
    Name: 'Operator, furnace, annealing'
  },
  {
    Id: 22271,
    Code: '8115',
    Name: 'Operator, furnace, carburising'
  },
  {
    Id: 22272,
    Code: '8115',
    Name: 'Operator, furnace, electrical, metal mfr'
  },
  {
    Id: 22273,
    Code: '8115',
    Name: 'Operator, furnace, slab, pusher'
  },
  {
    Id: 22274,
    Code: '8115',
    Name: 'Operator, furnace, treatment, heat'
  },
  {
    Id: 22275,
    Code: '8115',
    Name: 'Operator, furnace, metal mfr'
  },
  {
    Id: 22276,
    Code: '8115',
    Name: 'Operator, hammer'
  },
  {
    Id: 22277,
    Code: '8115',
    Name: 'Operator, hot'
  },
  {
    Id: 22278,
    Code: '8115',
    Name: 'Operator, house, tin, tinplate'
  },
  {
    Id: 22279,
    Code: '8115',
    Name: 'Operator, line, pickle, steel mfr'
  },
  {
    Id: 22280,
    Code: '8115',
    Name: 'Operator, mangle'
  },
  {
    Id: 22281,
    Code: '8115',
    Name: 'Operator, manipulator, steel mfr'
  },
  {
    Id: 22282,
    Code: '8115',
    Name: 'Operator, mill, foil, aluminium'
  },
  {
    Id: 22283,
    Code: '8115',
    Name: 'Operator, mill, hot, metal trades'
  },
  {
    Id: 22284,
    Code: '8115',
    Name: 'Operator, mill, rolling'
  },
  {
    Id: 22285,
    Code: '8115',
    Name: 'Operator, mill, tube'
  },
  {
    Id: 22286,
    Code: '8115',
    Name: 'Operator, mill, rolling mill'
  },
  {
    Id: 22287,
    Code: '8115',
    Name: 'Operator, pilger'
  },
  {
    Id: 22288,
    Code: '8115',
    Name: 'Operator, plant, degreasing'
  },
  {
    Id: 22289,
    Code: '8115',
    Name: 'Operator, plant, plating'
  },
  {
    Id: 22290,
    Code: '8115',
    Name: 'Operator, plant, sinter'
  },
  {
    Id: 22291,
    Code: '8115',
    Name: 'Operator, plant, vacuum, metallisation'
  },
  {
    Id: 22292,
    Code: '8115',
    Name: 'Operator, plating'
  },
  {
    Id: 22293,
    Code: '8115',
    Name: 'Operator, pot, aluminium mfr'
  },
  {
    Id: 22294,
    Code: '8115',
    Name: 'Operator, process, aluminium mfr'
  },
  {
    Id: 22295,
    Code: '8115',
    Name: 'Operator, pulpit, steel mfr'
  },
  {
    Id: 22296,
    Code: '8115',
    Name: 'Operator, pusher, metal mfr'
  },
  {
    Id: 22297,
    Code: '8115',
    Name: 'Operator, shear, flying'
  },
  {
    Id: 22298,
    Code: '8115',
    Name: 'Operator, shears, flying'
  },
  {
    Id: 22299,
    Code: '8115',
    Name: 'Operator, sinter'
  },
  {
    Id: 22300,
    Code: '8115',
    Name: 'Operator, steel, metal mfr'
  },
  {
    Id: 22301,
    Code: '8115',
    Name: 'Operator, swaging'
  },
  {
    Id: 22302,
    Code: '8115',
    Name: 'Operator, tilter, steel'
  },
  {
    Id: 22303,
    Code: '8115',
    Name: 'Operator, transfer, metal mfr'
  },
  {
    Id: 22304,
    Code: '8115',
    Name: 'Operator, treatment, heat, metal'
  },
  {
    Id: 22305,
    Code: '8115',
    Name: 'Operator, vat, metal mfr'
  },
  {
    Id: 22306,
    Code: '8115',
    Name: 'Ovenman, iron, malleable'
  },
  {
    Id: 22307,
    Code: '8115',
    Name: 'Oxidiser, metal trades'
  },
  {
    Id: 22308,
    Code: '8115',
    Name: 'Packer, oven, foundry'
  },
  {
    Id: 22309,
    Code: '8115',
    Name: 'Passer, rolling mill'
  },
  {
    Id: 22310,
    Code: '8115',
    Name: 'Patenter, wire'
  },
  {
    Id: 22311,
    Code: '8115',
    Name: 'Phosphater'
  },
  {
    Id: 22312,
    Code: '8115',
    Name: 'Pickler, aluminium'
  },
  {
    Id: 22313,
    Code: '8115',
    Name: 'Pickler, iron'
  },
  {
    Id: 22314,
    Code: '8115',
    Name: 'Pickler, steel, strip'
  },
  {
    Id: 22315,
    Code: '8115',
    Name: 'Pickler, tube'
  },
  {
    Id: 22316,
    Code: '8115',
    Name: 'Pickler, underhand'
  },
  {
    Id: 22317,
    Code: '8115',
    Name: 'Pickler, wire'
  },
  {
    Id: 22318,
    Code: '8115',
    Name: 'Pickler, metal trades'
  },
  {
    Id: 22319,
    Code: '8115',
    Name: 'Piercer, tube mfr'
  },
  {
    Id: 22320,
    Code: '8115',
    Name: 'Plater, barrel'
  },
  {
    Id: 22321,
    Code: '8115',
    Name: 'Plater, brass'
  },
  {
    Id: 22322,
    Code: '8115',
    Name: 'Plater, cadmium'
  },
  {
    Id: 22323,
    Code: '8115',
    Name: 'Plater, chrome'
  },
  {
    Id: 22324,
    Code: '8115',
    Name: 'Plater, chromium'
  },
  {
    Id: 22325,
    Code: '8115',
    Name: 'Plater, copper'
  },
  {
    Id: 22326,
    Code: '8115',
    Name: 'Plater, dip'
  },
  {
    Id: 22327,
    Code: '8115',
    Name: 'Plater, electro'
  },
  {
    Id: 22328,
    Code: '8115',
    Name: 'Plater, gold'
  },
  {
    Id: 22329,
    Code: '8115',
    Name: 'Plater, hand'
  },
  {
    Id: 22330,
    Code: '8115',
    Name: 'Plater, lock'
  },
  {
    Id: 22331,
    Code: '8115',
    Name: 'Plater, metal, white'
  },
  {
    Id: 22332,
    Code: '8115',
    Name: 'Plater, metal'
  },
  {
    Id: 22333,
    Code: '8115',
    Name: 'Plater, needle'
  },
  {
    Id: 22334,
    Code: '8115',
    Name: 'Plater, nickel'
  },
  {
    Id: 22335,
    Code: '8115',
    Name: 'Plater, silver'
  },
  {
    Id: 22336,
    Code: '8115',
    Name: 'Plater, tin'
  },
  {
    Id: 22337,
    Code: '8115',
    Name: 'Plater, tool, edge, surgical instrument mfr'
  },
  {
    Id: 22338,
    Code: '8115',
    Name: 'Plater, wire'
  },
  {
    Id: 22339,
    Code: '8115',
    Name: 'Plater, electroplating'
  },
  {
    Id: 22340,
    Code: '8115',
    Name: 'Plater and gilder'
  },
  {
    Id: 22341,
    Code: '8115',
    Name: 'Potman, cable mfr'
  },
  {
    Id: 22342,
    Code: '8115',
    Name: 'Potman, metal mfr'
  },
  {
    Id: 22343,
    Code: '8115',
    Name: 'Potter, furnace, blast'
  },
  {
    Id: 22344,
    Code: '8115',
    Name: 'Potter, lead smelting'
  },
  {
    Id: 22345,
    Code: '8115',
    Name: 'Pourer, foundry'
  },
  {
    Id: 22346,
    Code: '8115',
    Name: 'Presser, bending'
  },
  {
    Id: 22347,
    Code: '8115',
    Name: 'Presser, clipping'
  },
  {
    Id: 22348,
    Code: '8115',
    Name: 'Presser, extruding, metal trades'
  },
  {
    Id: 22349,
    Code: '8115',
    Name: "Presser, fitter's"
  },
  {
    Id: 22350,
    Code: '8115',
    Name: 'Presser, lead, cable mfr'
  },
  {
    Id: 22351,
    Code: '8115',
    Name: 'Presser, pipe'
  },
  {
    Id: 22352,
    Code: '8115',
    Name: 'Presser, metal tube mfr'
  },
  {
    Id: 22353,
    Code: '8115',
    Name: 'Proofer, rust'
  },
  {
    Id: 22354,
    Code: '8115',
    Name: 'Puddler, metal'
  },
  {
    Id: 22355,
    Code: '8115',
    Name: 'Puller-out, metal mfr'
  },
  {
    Id: 22356,
    Code: '8115',
    Name: 'Rackman, metal mfr'
  },
  {
    Id: 22357,
    Code: '8115',
    Name: 'Rammer, plug'
  },
  {
    Id: 22358,
    Code: '8115',
    Name: 'Rammer, metal mfr'
  },
  {
    Id: 22359,
    Code: '8115',
    Name: 'Re-roller, wire mfr'
  },
  {
    Id: 22360,
    Code: '8115',
    Name: 'Recaster'
  },
  {
    Id: 22361,
    Code: '8115',
    Name: 'Receiver, rolling mill'
  },
  {
    Id: 22362,
    Code: '8115',
    Name: 'Recoverer, zinc'
  },
  {
    Id: 22363,
    Code: '8115',
    Name: 'Rectifier, tube'
  },
  {
    Id: 22364,
    Code: '8115',
    Name: 'Reeler, bar'
  },
  {
    Id: 22365,
    Code: '8115',
    Name: 'Reeler, rolling mill'
  },
  {
    Id: 22366,
    Code: '8115',
    Name: 'Reelerman, rolling mill'
  },
  {
    Id: 22367,
    Code: '8115',
    Name: 'Refiner, bullion'
  },
  {
    Id: 22368,
    Code: '8115',
    Name: 'Refiner, gold'
  },
  {
    Id: 22369,
    Code: '8115',
    Name: 'Refiner, nickel'
  },
  {
    Id: 22370,
    Code: '8115',
    Name: 'Refiner, silver'
  },
  {
    Id: 22371,
    Code: '8115',
    Name: 'Refiner, metal mfr'
  },
  {
    Id: 22372,
    Code: '8115',
    Name: 'Reheater'
  },
  {
    Id: 22373,
    Code: '8115',
    Name: 'Reliner, bearing'
  },
  {
    Id: 22374,
    Code: '8115',
    Name: 'Riser, mill'
  },
  {
    Id: 22375,
    Code: '8115',
    Name: 'Riser, metal rolling'
  },
  {
    Id: 22376,
    Code: '8115',
    Name: 'Roller, bar, puddled'
  },
  {
    Id: 22377,
    Code: '8115',
    Name: 'Roller, cogging'
  },
  {
    Id: 22378,
    Code: '8115',
    Name: 'Roller, cold'
  },
  {
    Id: 22379,
    Code: '8115',
    Name: 'Roller, finishing'
  },
  {
    Id: 22380,
    Code: '8115',
    Name: 'Roller, forge'
  },
  {
    Id: 22381,
    Code: '8115',
    Name: 'Roller, head'
  },
  {
    Id: 22382,
    Code: '8115',
    Name: 'Roller, hot, steel mfr'
  },
  {
    Id: 22383,
    Code: '8115',
    Name: 'Roller, mill, blooming'
  },
  {
    Id: 22384,
    Code: '8115',
    Name: 'Roller, mill, roughing'
  },
  {
    Id: 22385,
    Code: '8115',
    Name: 'Roller, mill, sheet'
  },
  {
    Id: 22386,
    Code: '8115',
    Name: 'Roller, mill, iron, steel tube mfr'
  },
  {
    Id: 22387,
    Code: '8115',
    Name: 'Roller, plate'
  },
  {
    Id: 22388,
    Code: '8115',
    Name: 'Roller, rod'
  },
  {
    Id: 22389,
    Code: '8115',
    Name: 'Roller, roughing'
  },
  {
    Id: 22390,
    Code: '8115',
    Name: 'Roller, section'
  },
  {
    Id: 22391,
    Code: '8115',
    Name: 'Roller, side'
  },
  {
    Id: 22392,
    Code: '8115',
    Name: 'Roller, strip'
  },
  {
    Id: 22393,
    Code: '8115',
    Name: 'Roller, tube, metal'
  },
  {
    Id: 22394,
    Code: '8115',
    Name: 'Roller, tyre, steel'
  },
  {
    Id: 22395,
    Code: '8115',
    Name: 'Roller, under'
  },
  {
    Id: 22396,
    Code: '8115',
    Name: 'Roller, wire'
  },
  {
    Id: 22397,
    Code: '8115',
    Name: 'Roller, metal trades'
  },
  {
    Id: 22398,
    Code: '8115',
    Name: 'Rollsman, copper rolling'
  },
  {
    Id: 22399,
    Code: '8115',
    Name: 'Rougher, rolling mill'
  },
  {
    Id: 22400,
    Code: '8115',
    Name: 'Rounder, tube mfr'
  },
  {
    Id: 22401,
    Code: '8115',
    Name: 'Runner, metal, white'
  },
  {
    Id: 22402,
    Code: '8115',
    Name: 'Scaler, rolling mill'
  },
  {
    Id: 22403,
    Code: '8115',
    Name: 'Scaler, steel mfr'
  },
  {
    Id: 22404,
    Code: '8115',
    Name: 'Scourer, tinplate mfr'
  },
  {
    Id: 22405,
    Code: '8115',
    Name: 'Scourer, wire mfr'
  },
  {
    Id: 22406,
    Code: '8115',
    Name: 'Screwer, rolling mill'
  },
  {
    Id: 22407,
    Code: '8115',
    Name: 'Screwer-down, rolling mill'
  },
  {
    Id: 22408,
    Code: '8115',
    Name: 'Screwman, metal mfr'
  },
  {
    Id: 22409,
    Code: '8115',
    Name: 'Setter, core'
  },
  {
    Id: 22410,
    Code: '8115',
    Name: 'Setter, die, wire mfr'
  },
  {
    Id: 22411,
    Code: '8115',
    Name: 'Setter, guide, steel mfr'
  },
  {
    Id: 22412,
    Code: '8115',
    Name: 'Setter, mould, steelworks'
  },
  {
    Id: 22413,
    Code: '8115',
    Name: 'Setter, spring'
  },
  {
    Id: 22414,
    Code: '8115',
    Name: 'Setter, type foundry'
  },
  {
    Id: 22415,
    Code: '8115',
    Name: 'Sheather, cable'
  },
  {
    Id: 22416,
    Code: '8115',
    Name: 'Sherardizer'
  },
  {
    Id: 22417,
    Code: '8115',
    Name: 'Shingler, iron works'
  },
  {
    Id: 22418,
    Code: '8115',
    Name: 'Shopman, bottle, iron, steel tube mfr'
  },
  {
    Id: 22419,
    Code: '8115',
    Name: 'Silverer, electroplating'
  },
  {
    Id: 22420,
    Code: '8115',
    Name: 'Silverer, glass mfr'
  },
  {
    Id: 22421,
    Code: '8115',
    Name: 'Sinterer, metal mfr'
  },
  {
    Id: 22422,
    Code: '8115',
    Name: 'Sinterer, mine: not coal'
  },
  {
    Id: 22423,
    Code: '8115',
    Name: 'Skimmer, metal mfr'
  },
  {
    Id: 22424,
    Code: '8115',
    Name: 'Slagger, blast furnace'
  },
  {
    Id: 22425,
    Code: '8115',
    Name: 'Slagger, steel casting'
  },
  {
    Id: 22426,
    Code: '8115',
    Name: 'Slagman, blast furnace'
  },
  {
    Id: 22427,
    Code: '8115',
    Name: 'Smelter, lead'
  },
  {
    Id: 22428,
    Code: '8115',
    Name: 'Smelter, steel'
  },
  {
    Id: 22429,
    Code: '8115',
    Name: 'Smelter, metal mfr'
  },
  {
    Id: 22430,
    Code: '8115',
    Name: 'Smith, chain'
  },
  {
    Id: 22431,
    Code: '8115',
    Name: 'Smith, coil, tube mfr'
  },
  {
    Id: 22432,
    Code: '8115',
    Name: 'Softener, lead'
  },
  {
    Id: 22433,
    Code: '8115',
    Name: 'Spinner, pipe, iron, steel tube mfr'
  },
  {
    Id: 22434,
    Code: '8115',
    Name: 'Sprayer, coat, powder'
  },
  {
    Id: 22435,
    Code: '8115',
    Name: 'Sprayer, copper'
  },
  {
    Id: 22436,
    Code: '8115',
    Name: 'Sprayer, lime, iron and steelworks'
  },
  {
    Id: 22437,
    Code: '8115',
    Name: 'Sprayer, metal'
  },
  {
    Id: 22438,
    Code: '8115',
    Name: 'Sprayer, paint, metal'
  },
  {
    Id: 22439,
    Code: '8115',
    Name: 'Sprayer, paint, metal trades'
  },
  {
    Id: 22440,
    Code: '8115',
    Name: 'Sprayer, electroplating'
  },
  {
    Id: 22441,
    Code: '8115',
    Name: 'Springer, tube mfr'
  },
  {
    Id: 22442,
    Code: '8115',
    Name: 'Squirter, lead, cartridge mfr'
  },
  {
    Id: 22443,
    Code: '8115',
    Name: 'Stamper, ingot'
  },
  {
    Id: 22444,
    Code: '8115',
    Name: 'Stamper, Assay Office'
  },
  {
    Id: 22445,
    Code: '8115',
    Name: 'Staver, tube mfr'
  },
  {
    Id: 22446,
    Code: '8115',
    Name: 'Stoker, furnace, metal mfr'
  },
  {
    Id: 22447,
    Code: '8115',
    Name: 'Stoker, metal mfr'
  },
  {
    Id: 22448,
    Code: '8115',
    Name: 'Stoker, shipbuilding'
  },
  {
    Id: 22449,
    Code: '8115',
    Name: 'Stover, blast furnace'
  },
  {
    Id: 22450,
    Code: '8115',
    Name: 'Stover, iron foundry'
  },
  {
    Id: 22451,
    Code: '8115',
    Name: 'Straightener, bar'
  },
  {
    Id: 22452,
    Code: '8115',
    Name: 'Straightener, drill'
  },
  {
    Id: 22453,
    Code: '8115',
    Name: 'Straightener, iron'
  },
  {
    Id: 22454,
    Code: '8115',
    Name: 'Straightener, mills, rod'
  },
  {
    Id: 22455,
    Code: '8115',
    Name: 'Straightener, plate'
  },
  {
    Id: 22456,
    Code: '8115',
    Name: 'Straightener, rail'
  },
  {
    Id: 22457,
    Code: '8115',
    Name: 'Straightener, roller'
  },
  {
    Id: 22458,
    Code: '8115',
    Name: 'Straightener, section'
  },
  {
    Id: 22459,
    Code: '8115',
    Name: 'Straightener, shaft, crank'
  },
  {
    Id: 22460,
    Code: '8115',
    Name: 'Straightener, steel'
  },
  {
    Id: 22461,
    Code: '8115',
    Name: 'Straightener, tube'
  },
  {
    Id: 22462,
    Code: '8115',
    Name: 'Straightener, metal mfr'
  },
  {
    Id: 22463,
    Code: '8115',
    Name: 'Stretcher, tube'
  },
  {
    Id: 22464,
    Code: '8115',
    Name: 'Stretcher, metal mfr'
  },
  {
    Id: 22465,
    Code: '8115',
    Name: 'Striker, anvil'
  },
  {
    Id: 22466,
    Code: '8115',
    Name: 'Striker, chain'
  },
  {
    Id: 22467,
    Code: '8115',
    Name: 'Striker, forge'
  },
  {
    Id: 22468,
    Code: '8115',
    Name: "Striker, forger's"
  },
  {
    Id: 22469,
    Code: '8115',
    Name: 'Striker, iron'
  },
  {
    Id: 22470,
    Code: '8115',
    Name: "Striker, smith's"
  },
  {
    Id: 22471,
    Code: '8115',
    Name: 'Striker, wheel'
  },
  {
    Id: 22472,
    Code: '8115',
    Name: 'Striker, coal mine'
  },
  {
    Id: 22473,
    Code: '8115',
    Name: 'Striker, metal trades'
  },
  {
    Id: 22474,
    Code: '8115',
    Name: 'Striker, railways'
  },
  {
    Id: 22475,
    Code: '8115',
    Name: 'Stripper, gold'
  },
  {
    Id: 22476,
    Code: '8115',
    Name: 'Stripper, ingot'
  },
  {
    Id: 22477,
    Code: '8115',
    Name: 'Stripper, silver'
  },
  {
    Id: 22478,
    Code: '8115',
    Name: 'Stripper, electroplating'
  },
  {
    Id: 22479,
    Code: '8115',
    Name: 'Stripper, gold refining'
  },
  {
    Id: 22480,
    Code: '8115',
    Name: 'Stripper, wire drawing'
  },
  {
    Id: 22481,
    Code: '8115',
    Name: 'Swiftman'
  },
  {
    Id: 22482,
    Code: '8115',
    Name: 'Tagger, steel mfr'
  },
  {
    Id: 22483,
    Code: '8115',
    Name: 'Tagger, tube mfr'
  },
  {
    Id: 22484,
    Code: '8115',
    Name: 'Taker, temperature'
  },
  {
    Id: 22485,
    Code: '8115',
    Name: 'Tanker, galvanised sheet mfr'
  },
  {
    Id: 22486,
    Code: '8115',
    Name: 'Tankman, non-ferrous metal mfr'
  },
  {
    Id: 22487,
    Code: '8115',
    Name: 'Tapper, iron and steelworks'
  },
  {
    Id: 22488,
    Code: '8115',
    Name: 'Tapper-out'
  },
  {
    Id: 22489,
    Code: '8115',
    Name: 'Teazer, metal trades'
  },
  {
    Id: 22490,
    Code: '8115',
    Name: 'Teemer, ladle'
  },
  {
    Id: 22491,
    Code: '8115',
    Name: 'Teemer, steelworks'
  },
  {
    Id: 22492,
    Code: '8115',
    Name: 'Temperer, wire'
  },
  {
    Id: 22493,
    Code: '8115',
    Name: 'Temperer, metal trades'
  },
  {
    Id: 22494,
    Code: '8115',
    Name: 'Tender, furnace, metal trades'
  },
  {
    Id: 22495,
    Code: '8115',
    Name: 'Tenter, cupola'
  },
  {
    Id: 22496,
    Code: '8115',
    Name: 'Tenter, furnace'
  },
  {
    Id: 22497,
    Code: '8115',
    Name: 'Tester, furnace'
  },
  {
    Id: 22498,
    Code: '8115',
    Name: 'Threader, needle mfr'
  },
  {
    Id: 22499,
    Code: '8115',
    Name: 'Tilter'
  },
  {
    Id: 22500,
    Code: '8115',
    Name: 'Tilterman'
  },
  {
    Id: 22501,
    Code: '8115',
    Name: 'Tinner, coil'
  },
  {
    Id: 22502,
    Code: '8115',
    Name: 'Tinner, copper'
  },
  {
    Id: 22503,
    Code: '8115',
    Name: 'Tinner, grease'
  },
  {
    Id: 22504,
    Code: '8115',
    Name: 'Tinner, wire'
  },
  {
    Id: 22505,
    Code: '8115',
    Name: 'Tinner, metal trades'
  },
  {
    Id: 22506,
    Code: '8115',
    Name: 'Tinplater, tinplate mfr'
  },
  {
    Id: 22507,
    Code: '8115',
    Name: 'Tonger, wire'
  },
  {
    Id: 22508,
    Code: '8115',
    Name: 'Tongsman, back'
  },
  {
    Id: 22509,
    Code: '8115',
    Name: 'Tongsman, rolling mill'
  },
  {
    Id: 22510,
    Code: '8115',
    Name: 'Tongsman, steel hoop mill'
  },
  {
    Id: 22511,
    Code: '8115',
    Name: 'Tongsman, wrought iron mfr'
  },
  {
    Id: 22512,
    Code: '8115',
    Name: 'Tonguer'
  },
  {
    Id: 22513,
    Code: '8115',
    Name: 'Trailer-down, rolling mill'
  },
  {
    Id: 22514,
    Code: '8115',
    Name: 'Treater, heat'
  },
  {
    Id: 22515,
    Code: '8115',
    Name: 'Tuber, wire, wire mfr'
  },
  {
    Id: 22516,
    Code: '8115',
    Name: 'Tuber, wire mfr'
  },
  {
    Id: 22517,
    Code: '8115',
    Name: 'Tupper, steel mfr'
  },
  {
    Id: 22518,
    Code: '8115',
    Name: 'Turner, core'
  },
  {
    Id: 22519,
    Code: '8115',
    Name: 'Uncoiler, tinplate mfr'
  },
  {
    Id: 22520,
    Code: '8115',
    Name: 'Unreeler, steel mfr'
  },
  {
    Id: 22521,
    Code: '8115',
    Name: 'Vatman, wire mfr'
  },
  {
    Id: 22522,
    Code: '8115',
    Name: 'Washer, metal trades'
  },
  {
    Id: 22523,
    Code: '8115',
    Name: 'Watcher, furnace'
  },
  {
    Id: 22524,
    Code: '8115',
    Name: 'Watcher, metal mfr'
  },
  {
    Id: 22525,
    Code: '8115',
    Name: 'Wheelman, copper refining'
  },
  {
    Id: 22526,
    Code: '8115',
    Name: 'Wirer-up, electroplating'
  },
  {
    Id: 22527,
    Code: '8115',
    Name: 'Worker, aluminium'
  },
  {
    Id: 22528,
    Code: '8115',
    Name: 'Worker, forge'
  },
  {
    Id: 22529,
    Code: '8115',
    Name: 'Worker, furnace, blast'
  },
  {
    Id: 22530,
    Code: '8115',
    Name: 'Worker, furnace, metal trades'
  },
  {
    Id: 22531,
    Code: '8115',
    Name: 'Worker, hammer, power'
  },
  {
    Id: 22532,
    Code: '8115',
    Name: 'Worker, lead, tea'
  },
  {
    Id: 22533,
    Code: '8115',
    Name: 'Worker, mill, rolling'
  },
  {
    Id: 22534,
    Code: '8115',
    Name: 'Worker, mill, sheet'
  },
  {
    Id: 22535,
    Code: '8115',
    Name: 'Worker, mill, metal mfr'
  },
  {
    Id: 22536,
    Code: '8115',
    Name: 'Worker, muffle, foundry'
  },
  {
    Id: 22537,
    Code: '8115',
    Name: 'Worker, muffle, annealing'
  },
  {
    Id: 22538,
    Code: '8115',
    Name: 'Worker, nickel, electroplating'
  },
  {
    Id: 22539,
    Code: '8115',
    Name: 'Worker, plant, sinter'
  },
  {
    Id: 22540,
    Code: '8115',
    Name: 'Worker, plate, tin, tinplate mfr'
  },
  {
    Id: 22541,
    Code: '8115',
    Name: 'Worker, process, nickel mfr'
  },
  {
    Id: 22542,
    Code: '8115',
    Name: 'Worker, process, steelworks'
  },
  {
    Id: 22543,
    Code: '8115',
    Name: 'Worker, strip, copper'
  },
  {
    Id: 22544,
    Code: '8115',
    Name: 'Worker, tinplate, tinplate mfr'
  },
  {
    Id: 22545,
    Code: '8115',
    Name: 'Worker, treatment, heat, metal'
  },
  {
    Id: 22546,
    Code: '8115',
    Name: 'Worker, treatment, heat, metal trades'
  },
  {
    Id: 22547,
    Code: '8115',
    Name: 'Worker, tube, steel'
  },
  {
    Id: 22548,
    Code: '8115',
    Name: 'Worker, wire, tungsten'
  },
  {
    Id: 22549,
    Code: '8119',
    Name: 'Annealer, pot'
  },
  {
    Id: 22550,
    Code: '8119',
    Name: 'Annealer, ceramics'
  },
  {
    Id: 22551,
    Code: '8119',
    Name: 'Annealer, glass'
  },
  {
    Id: 22552,
    Code: '8119',
    Name: "Assistant, blower's, glass"
  },
  {
    Id: 22553,
    Code: '8119',
    Name: "Assistant, embosser's"
  },
  {
    Id: 22554,
    Code: '8119',
    Name: "Assistant, etcher's"
  },
  {
    Id: 22555,
    Code: '8119',
    Name: 'Assistant, extrusion, rubber'
  },
  {
    Id: 22556,
    Code: '8119',
    Name: "Assistant, grinder's, plate glass"
  },
  {
    Id: 22557,
    Code: '8119',
    Name: 'Assistant, kiln'
  },
  {
    Id: 22558,
    Code: '8119',
    Name: "Assistant, ladler's, glass"
  },
  {
    Id: 22559,
    Code: '8119',
    Name: "Assistant, polisher's, glass"
  },
  {
    Id: 22560,
    Code: '8119',
    Name: 'Assistant, pot, melting, electric cable'
  },
  {
    Id: 22561,
    Code: '8119',
    Name: "Assistant, potter's"
  },
  {
    Id: 22562,
    Code: '8119',
    Name: "Assistant, thrower's"
  },
  {
    Id: 22563,
    Code: '8119',
    Name: 'Attendant, belt, casting'
  },
  {
    Id: 22564,
    Code: '8119',
    Name: 'Attendant, box, dod'
  },
  {
    Id: 22565,
    Code: '8119',
    Name: 'Attendant, calender, linoleum'
  },
  {
    Id: 22566,
    Code: '8119',
    Name: 'Attendant, calender, rubber'
  },
  {
    Id: 22567,
    Code: '8119',
    Name: 'Attendant, dryer, macadam'
  },
  {
    Id: 22568,
    Code: '8119',
    Name: 'Attendant, dryer, plasterboard'
  },
  {
    Id: 22569,
    Code: '8119',
    Name: 'Attendant, furnace, glass mfr'
  },
  {
    Id: 22570,
    Code: '8119',
    Name: 'Attendant, grinder, cement'
  },
  {
    Id: 22571,
    Code: '8119',
    Name: 'Attendant, house, slip'
  },
  {
    Id: 22572,
    Code: '8119',
    Name: 'Attendant, kiln, glass'
  },
  {
    Id: 22573,
    Code: '8119',
    Name: 'Attendant, kiln, brick mfr'
  },
  {
    Id: 22574,
    Code: '8119',
    Name: 'Attendant, kiln, cement mfr'
  },
  {
    Id: 22575,
    Code: '8119',
    Name: 'Attendant, kiln, ceramics mfr'
  },
  {
    Id: 22576,
    Code: '8119',
    Name: 'Attendant, lathe, rubber'
  },
  {
    Id: 22577,
    Code: '8119',
    Name: 'Attendant, lehr'
  },
  {
    Id: 22578,
    Code: '8119',
    Name: 'Attendant, magazine, plasterboard'
  },
  {
    Id: 22579,
    Code: '8119',
    Name: 'Attendant, mill, mortar'
  },
  {
    Id: 22580,
    Code: '8119',
    Name: 'Attendant, mill, cement mfr'
  },
  {
    Id: 22581,
    Code: '8119',
    Name: 'Attendant, mixer, concrete'
  },
  {
    Id: 22582,
    Code: '8119',
    Name: 'Attendant, mixer, ceramics mfr'
  },
  {
    Id: 22583,
    Code: '8119',
    Name: 'Attendant, oven, ceramics mfr'
  },
  {
    Id: 22584,
    Code: '8119',
    Name: "Attendant, potter's"
  },
  {
    Id: 22585,
    Code: '8119',
    Name: 'Attendant, press, ceramics mfr'
  },
  {
    Id: 22586,
    Code: '8119',
    Name: 'Attendant, roller, edge'
  },
  {
    Id: 22587,
    Code: '8119',
    Name: 'Attendant, screen, ceramics mfr'
  },
  {
    Id: 22588,
    Code: '8119',
    Name: "Attendant, thrower's"
  },
  {
    Id: 22589,
    Code: '8119',
    Name: 'Backer, linoleum mfr'
  },
  {
    Id: 22590,
    Code: '8119',
    Name: 'Baller, ceramics mfr'
  },
  {
    Id: 22591,
    Code: '8119',
    Name: 'Bander, rubber tyre mfr'
  },
  {
    Id: 22592,
    Code: '8119',
    Name: 'Batcher, concrete'
  },
  {
    Id: 22593,
    Code: '8119',
    Name: 'Batchman, glass mfr'
  },
  {
    Id: 22594,
    Code: '8119',
    Name: 'Beaterman, asbestos-cement mfr'
  },
  {
    Id: 22595,
    Code: '8119',
    Name: 'Bedder, ceramics mfr'
  },
  {
    Id: 22596,
    Code: '8119',
    Name: 'Bedman, asphalt mfr'
  },
  {
    Id: 22597,
    Code: '8119',
    Name: 'Blender, cast stone products mfr'
  },
  {
    Id: 22598,
    Code: '8119',
    Name: 'Blender, linoleum mfr'
  },
  {
    Id: 22599,
    Code: '8119',
    Name: 'Braider, rubber hose mfr'
  },
  {
    Id: 22600,
    Code: '8119',
    Name: 'Breaker, ore, blast furnace'
  },
  {
    Id: 22601,
    Code: '8119',
    Name: 'Breaker, stone'
  },
  {
    Id: 22602,
    Code: '8119',
    Name: 'Builder, bead, tyre'
  },
  {
    Id: 22603,
    Code: '8119',
    Name: 'Builder, belt, rubber goods mfr'
  },
  {
    Id: 22604,
    Code: '8119',
    Name: 'Builder, hose'
  },
  {
    Id: 22605,
    Code: '8119',
    Name: 'Builder, micanite'
  },
  {
    Id: 22606,
    Code: '8119',
    Name: 'Builder, plate, mica, micanite'
  },
  {
    Id: 22607,
    Code: '8119',
    Name: 'Builder, roller'
  },
  {
    Id: 22608,
    Code: '8119',
    Name: 'Builder, rubber'
  },
  {
    Id: 22609,
    Code: '8119',
    Name: 'Builder, tank, rubber lining'
  },
  {
    Id: 22610,
    Code: '8119',
    Name: 'Builder, tread'
  },
  {
    Id: 22611,
    Code: '8119',
    Name: 'Builder, tyre'
  },
  {
    Id: 22612,
    Code: '8119',
    Name: 'Builder, wheel, rubber'
  },
  {
    Id: 22613,
    Code: '8119',
    Name: 'Burner, brick'
  },
  {
    Id: 22614,
    Code: '8119',
    Name: 'Burner, head, ceramics mfr'
  },
  {
    Id: 22615,
    Code: '8119',
    Name: 'Burner, kiln, brick mfr'
  },
  {
    Id: 22616,
    Code: '8119',
    Name: 'Burner, kiln, cement mfr'
  },
  {
    Id: 22617,
    Code: '8119',
    Name: 'Burner, kiln, ceramics mfr'
  },
  {
    Id: 22618,
    Code: '8119',
    Name: 'Burner, kiln, glass mfr'
  },
  {
    Id: 22619,
    Code: '8119',
    Name: 'Burner, lime'
  },
  {
    Id: 22620,
    Code: '8119',
    Name: 'Burner, mould, rubber tyre mfr'
  },
  {
    Id: 22621,
    Code: '8119',
    Name: 'Burner, sand'
  },
  {
    Id: 22622,
    Code: '8119',
    Name: 'Burner, tile'
  },
  {
    Id: 22623,
    Code: '8119',
    Name: 'Burner, cement mfr'
  },
  {
    Id: 22624,
    Code: '8119',
    Name: 'Burner, ceramics mfr'
  },
  {
    Id: 22625,
    Code: '8119',
    Name: 'Burner, glass mfr'
  },
  {
    Id: 22626,
    Code: '8119',
    Name: 'Burner, lime burning'
  },
  {
    Id: 22627,
    Code: '8119',
    Name: 'Calciner, mine: not coal'
  },
  {
    Id: 22628,
    Code: '8119',
    Name: 'Calenderer, rubber'
  },
  {
    Id: 22629,
    Code: '8119',
    Name: 'Calenderer, rubber mfr'
  },
  {
    Id: 22630,
    Code: '8119',
    Name: 'Calenderman, linoleum mfr'
  },
  {
    Id: 22631,
    Code: '8119',
    Name: 'Calenderman, rubber mfr'
  },
  {
    Id: 22632,
    Code: '8119',
    Name: 'Carrier-in, glass mfr'
  },
  {
    Id: 22633,
    Code: '8119',
    Name: 'Caster, cold, rubber'
  },
  {
    Id: 22634,
    Code: '8119',
    Name: 'Caster, concrete'
  },
  {
    Id: 22635,
    Code: '8119',
    Name: "Caster, roller, printer's"
  },
  {
    Id: 22636,
    Code: '8119',
    Name: 'Caster, slab'
  },
  {
    Id: 22637,
    Code: '8119',
    Name: 'Caster, stone'
  },
  {
    Id: 22638,
    Code: '8119',
    Name: 'Caster, tile'
  },
  {
    Id: 22639,
    Code: '8119',
    Name: 'Caster, cast stone products mfr'
  },
  {
    Id: 22640,
    Code: '8119',
    Name: 'Caster, footwear mfr'
  },
  {
    Id: 22641,
    Code: '8119',
    Name: 'Catcher, pole, linoleum'
  },
  {
    Id: 22642,
    Code: '8119',
    Name: 'Cellarman, rubber mfr'
  },
  {
    Id: 22643,
    Code: '8119',
    Name: 'Changer, mould, rubber mfr'
  },
  {
    Id: 22644,
    Code: '8119',
    Name: 'Charger, blunger'
  },
  {
    Id: 22645,
    Code: '8119',
    Name: 'Charger, linoleum mfr'
  },
  {
    Id: 22646,
    Code: '8119',
    Name: 'Charger, slag wool mfr'
  },
  {
    Id: 22647,
    Code: '8119',
    Name: 'Clampman, fire brick mfr'
  },
  {
    Id: 22648,
    Code: '8119',
    Name: 'Cleaner, gut'
  },
  {
    Id: 22649,
    Code: '8119',
    Name: 'Cleaner, asbestos-cement mfr'
  },
  {
    Id: 22650,
    Code: '8119',
    Name: 'Coater, linoleum mfr'
  },
  {
    Id: 22651,
    Code: '8119',
    Name: 'Coiler, rubber tubing mfr'
  },
  {
    Id: 22652,
    Code: '8119',
    Name: 'Compounder, rubber mfr'
  },
  {
    Id: 22653,
    Code: '8119',
    Name: 'Cook, mastic, asphalt mfr'
  },
  {
    Id: 22654,
    Code: '8119',
    Name: 'Coverer, roller, printing'
  },
  {
    Id: 22655,
    Code: '8119',
    Name: 'Coverer, rubber, cable mfr'
  },
  {
    Id: 22656,
    Code: '8119',
    Name: 'Coverer, rubber, textile mfr'
  },
  {
    Id: 22657,
    Code: '8119',
    Name: 'Coverer, wheel, rubber'
  },
  {
    Id: 22658,
    Code: '8119',
    Name: 'Coverer, wire, insulated wire, cable mfr'
  },
  {
    Id: 22659,
    Code: '8119',
    Name: 'Coverer, rubber goods mfr'
  },
  {
    Id: 22660,
    Code: '8119',
    Name: 'Cranker, ceramics mfr'
  },
  {
    Id: 22661,
    Code: '8119',
    Name: 'Cranker-up, ceramics mfr'
  },
  {
    Id: 22662,
    Code: '8119',
    Name: 'Crowder'
  },
  {
    Id: 22663,
    Code: '8119',
    Name: 'Crusher, bone'
  },
  {
    Id: 22664,
    Code: '8119',
    Name: 'Crusher, coal'
  },
  {
    Id: 22665,
    Code: '8119',
    Name: 'Crusher, minerals'
  },
  {
    Id: 22666,
    Code: '8119',
    Name: 'Crusher, ceramics mfr'
  },
  {
    Id: 22667,
    Code: '8119',
    Name: 'Curer, rubber'
  },
  {
    Id: 22668,
    Code: '8119',
    Name: 'Cutter, band, rubber'
  },
  {
    Id: 22669,
    Code: '8119',
    Name: 'Cutter, collar, rubber'
  },
  {
    Id: 22670,
    Code: '8119',
    Name: 'Cutter, disc, rubber mfr'
  },
  {
    Id: 22671,
    Code: '8119',
    Name: 'Cutter, guillotine, asbestos-cement goods mfr'
  },
  {
    Id: 22672,
    Code: '8119',
    Name: 'Cutter, mica'
  },
  {
    Id: 22673,
    Code: '8119',
    Name: 'Cutter, rubber'
  },
  {
    Id: 22674,
    Code: '8119',
    Name: 'Cutter, tread'
  },
  {
    Id: 22675,
    Code: '8119',
    Name: 'Cutter, tyre'
  },
  {
    Id: 22676,
    Code: '8119',
    Name: 'Cutter, linoleum mfr'
  },
  {
    Id: 22677,
    Code: '8119',
    Name: 'Cutter, rubber goods mfr'
  },
  {
    Id: 22678,
    Code: '8119',
    Name: 'Dipper, glove'
  },
  {
    Id: 22679,
    Code: '8119',
    Name: 'Dipper, rubber'
  },
  {
    Id: 22680,
    Code: '8119',
    Name: 'Dipper, rubber mfr'
  },
  {
    Id: 22681,
    Code: '8119',
    Name: 'Drawer, lime, lime burning'
  },
  {
    Id: 22682,
    Code: '8119',
    Name: 'Drawer and setter, brick mfr'
  },
  {
    Id: 22683,
    Code: '8119',
    Name: 'Dresser, concrete'
  },
  {
    Id: 22684,
    Code: '8119',
    Name: 'Dresser, gypsum'
  },
  {
    Id: 22685,
    Code: '8119',
    Name: 'Dresser, lime'
  },
  {
    Id: 22686,
    Code: '8119',
    Name: 'Dresser, stone, concrete products mfr'
  },
  {
    Id: 22687,
    Code: '8119',
    Name: 'Driller, asbestos'
  },
  {
    Id: 22688,
    Code: '8119',
    Name: 'Driller, asbestos composition goods mfr'
  },
  {
    Id: 22689,
    Code: '8119',
    Name: 'Driller, asbestos goods mfr'
  },
  {
    Id: 22690,
    Code: '8119',
    Name: 'Dryer, clay'
  },
  {
    Id: 22691,
    Code: '8119',
    Name: 'Dryer, ore'
  },
  {
    Id: 22692,
    Code: '8119',
    Name: 'Dryer, pearl'
  },
  {
    Id: 22693,
    Code: '8119',
    Name: 'Dryer, salt'
  },
  {
    Id: 22694,
    Code: '8119',
    Name: 'Dryer, sand'
  },
  {
    Id: 22695,
    Code: '8119',
    Name: 'Dryer, metal trades'
  },
  {
    Id: 22696,
    Code: '8119',
    Name: 'Dryer, refractory goods mfr'
  },
  {
    Id: 22697,
    Code: '8119',
    Name: 'Extruder, rubber'
  },
  {
    Id: 22698,
    Code: '8119',
    Name: 'Fabricator, cast stone products mfr'
  },
  {
    Id: 22699,
    Code: '8119',
    Name: 'Feeder, clay'
  },
  {
    Id: 22700,
    Code: '8119',
    Name: 'Feeder, hopper, ceramics mfr'
  },
  {
    Id: 22701,
    Code: '8119',
    Name: 'Feeder, mill'
  },
  {
    Id: 22702,
    Code: '8119',
    Name: 'Feeder, pan'
  },
  {
    Id: 22703,
    Code: '8119',
    Name: 'Festooner, linoleum mfr'
  },
  {
    Id: 22704,
    Code: '8119',
    Name: 'Festooner, oilskin mfr'
  },
  {
    Id: 22705,
    Code: '8119',
    Name: 'Fettler, cast concrete products mfr'
  },
  {
    Id: 22706,
    Code: '8119',
    Name: 'Filler, kiln'
  },
  {
    Id: 22707,
    Code: '8119',
    Name: 'Filler, oven, ceramics mfr'
  },
  {
    Id: 22708,
    Code: '8119',
    Name: 'Filler, cast concrete products mfr'
  },
  {
    Id: 22709,
    Code: '8119',
    Name: 'Filler-in, ceramics mfr'
  },
  {
    Id: 22710,
    Code: '8119',
    Name: 'Finisher, concrete'
  },
  {
    Id: 22711,
    Code: '8119',
    Name: 'Finisher, ebonite'
  },
  {
    Id: 22712,
    Code: '8119',
    Name: 'Finisher, pipe, cast concrete products mfr'
  },
  {
    Id: 22713,
    Code: '8119',
    Name: 'Finisher, post, concrete'
  },
  {
    Id: 22714,
    Code: '8119',
    Name: 'Finisher, stone, cast concrete products mfr'
  },
  {
    Id: 22715,
    Code: '8119',
    Name: 'Finisher, tyre'
  },
  {
    Id: 22716,
    Code: '8119',
    Name: 'Finisher, asbestos-cement goods mfr'
  },
  {
    Id: 22717,
    Code: '8119',
    Name: 'Finisher, cast concrete products mfr'
  },
  {
    Id: 22718,
    Code: '8119',
    Name: 'Finisher, rubber goods mfr'
  },
  {
    Id: 22719,
    Code: '8119',
    Name: 'Fireman, biscuit'
  },
  {
    Id: 22720,
    Code: '8119',
    Name: 'Fireman, kiln, ceramics mfr'
  },
  {
    Id: 22721,
    Code: '8119',
    Name: 'Fireman, kiln, glass mfr'
  },
  {
    Id: 22722,
    Code: '8119',
    Name: 'Fireman, oven, ceramics mfr'
  },
  {
    Id: 22723,
    Code: '8119',
    Name: 'Fireman, abrasives mfr'
  },
  {
    Id: 22724,
    Code: '8119',
    Name: 'Fireman, ceramics mfr'
  },
  {
    Id: 22725,
    Code: '8119',
    Name: 'Firer, foundry, glass mfr'
  },
  {
    Id: 22726,
    Code: '8119',
    Name: 'Firer, kiln'
  },
  {
    Id: 22727,
    Code: '8119',
    Name: 'Firer, stove, ceramics mfr'
  },
  {
    Id: 22728,
    Code: '8119',
    Name: 'Firer, ceramics mfr'
  },
  {
    Id: 22729,
    Code: '8119',
    Name: 'Fitter, bead, tyre'
  },
  {
    Id: 22730,
    Code: '8119',
    Name: 'Fitter, tread, tyre'
  },
  {
    Id: 22731,
    Code: '8119',
    Name: 'Flipper, bead'
  },
  {
    Id: 22732,
    Code: '8119',
    Name: 'Foiler, plasterboard mfr'
  },
  {
    Id: 22733,
    Code: '8119',
    Name: 'Forcer, rubber'
  },
  {
    Id: 22734,
    Code: '8119',
    Name: 'Forcer, rubber goods mfr'
  },
  {
    Id: 22735,
    Code: '8119',
    Name: 'Forker, glass mfr'
  },
  {
    Id: 22736,
    Code: '8119',
    Name: 'Founder, glass mfr'
  },
  {
    Id: 22737,
    Code: '8119',
    Name: 'Fritter'
  },
  {
    Id: 22738,
    Code: '8119',
    Name: 'Furnaceman, calcining, flint'
  },
  {
    Id: 22739,
    Code: '8119',
    Name: 'Furnaceman, ceramics mfr'
  },
  {
    Id: 22740,
    Code: '8119',
    Name: 'Furnaceman, electric bulb mfr'
  },
  {
    Id: 22741,
    Code: '8119',
    Name: 'Furnaceman, glass mfr'
  },
  {
    Id: 22742,
    Code: '8119',
    Name: 'Fuser, bifocal'
  },
  {
    Id: 22743,
    Code: '8119',
    Name: 'Fuser, glass mfr'
  },
  {
    Id: 22744,
    Code: '8119',
    Name: 'Gauger, gut'
  },
  {
    Id: 22745,
    Code: '8119',
    Name: 'Gauger, mica'
  },
  {
    Id: 22746,
    Code: '8119',
    Name: 'Gauger, cast stone products mfr'
  },
  {
    Id: 22747,
    Code: '8119',
    Name: 'Grinder, clay, ceramics mfr'
  },
  {
    Id: 22748,
    Code: '8119',
    Name: 'Grinder, compo, metal mfr'
  },
  {
    Id: 22749,
    Code: '8119',
    Name: 'Grinder, composition, ceramics mfr'
  },
  {
    Id: 22750,
    Code: '8119',
    Name: 'Grinder, composition, metal mfr'
  },
  {
    Id: 22751,
    Code: '8119',
    Name: 'Grinder, cutter, cemented carbide goods mfr'
  },
  {
    Id: 22752,
    Code: '8119',
    Name: 'Grinder, dust'
  },
  {
    Id: 22753,
    Code: '8119',
    Name: 'Grinder, ebonite'
  },
  {
    Id: 22754,
    Code: '8119',
    Name: 'Grinder, flint, ceramics mfr'
  },
  {
    Id: 22755,
    Code: '8119',
    Name: 'Grinder, glaze, ceramics mfr'
  },
  {
    Id: 22756,
    Code: '8119',
    Name: 'Grinder, rubber'
  },
  {
    Id: 22757,
    Code: '8119',
    Name: 'Grinder, sand'
  },
  {
    Id: 22758,
    Code: '8119',
    Name: 'Grinder, silica'
  },
  {
    Id: 22759,
    Code: '8119',
    Name: 'Grinder, cast concrete products mfr'
  },
  {
    Id: 22760,
    Code: '8119',
    Name: 'Hand, bench, rubber flooring mfr'
  },
  {
    Id: 22761,
    Code: '8119',
    Name: 'Hand, brick'
  },
  {
    Id: 22762,
    Code: '8119',
    Name: 'Hand, calender, linoleum mfr'
  },
  {
    Id: 22763,
    Code: '8119',
    Name: 'Hand, calender, rubber mfr'
  },
  {
    Id: 22764,
    Code: '8119',
    Name: 'Hand, furnace, mine: not coal'
  },
  {
    Id: 22765,
    Code: '8119',
    Name: 'Hand, guillotine, asbestos-cement goods mfr'
  },
  {
    Id: 22766,
    Code: '8119',
    Name: 'Hand, house, slip'
  },
  {
    Id: 22767,
    Code: '8119',
    Name: 'Hand, kiln, ceramics mfr'
  },
  {
    Id: 22768,
    Code: '8119',
    Name: 'Hand, mechanical, rubber goods mfr'
  },
  {
    Id: 22769,
    Code: '8119',
    Name: 'Hand, mill, rubber'
  },
  {
    Id: 22770,
    Code: '8119',
    Name: 'Hand, mill, wash, cement'
  },
  {
    Id: 22771,
    Code: '8119',
    Name: 'Hand, mill, rubber goods mfr'
  },
  {
    Id: 22772,
    Code: '8119',
    Name: 'Hand, mill'
  },
  {
    Id: 22773,
    Code: '8119',
    Name: 'Hand, oven, ceramics mfr'
  },
  {
    Id: 22774,
    Code: '8119',
    Name: 'Hand, oven, mica, micanite goods mfr'
  },
  {
    Id: 22775,
    Code: '8119',
    Name: 'Hand, sliphouse'
  },
  {
    Id: 22776,
    Code: '8119',
    Name: 'Hand, surgical, rubber goods mfr'
  },
  {
    Id: 22777,
    Code: '8119',
    Name: 'Hand, tube'
  },
  {
    Id: 22778,
    Code: '8119',
    Name: 'Hanger, lino, linoleum mfr'
  },
  {
    Id: 22779,
    Code: '8119',
    Name: 'Hanger, linoleum, linoleum mfr'
  },
  {
    Id: 22780,
    Code: '8119',
    Name: 'Heater, rubber, tyre mfr'
  },
  {
    Id: 22781,
    Code: '8119',
    Name: 'Heatman, linoleum mfr'
  },
  {
    Id: 22782,
    Code: '8119',
    Name: 'Hopperman, cement mfr'
  },
  {
    Id: 22783,
    Code: '8119',
    Name: 'Houseman, slip'
  },
  {
    Id: 22784,
    Code: '8119',
    Name: 'Hydrator, lime'
  },
  {
    Id: 22785,
    Code: '8119',
    Name: 'Joiner, rubber, cycle tubes'
  },
  {
    Id: 22786,
    Code: '8119',
    Name: 'Keeper, furnace, glass mfr'
  },
  {
    Id: 22787,
    Code: '8119',
    Name: 'Latexer'
  },
  {
    Id: 22788,
    Code: '8119',
    Name: 'Layer, plate, mica, micanite mfr'
  },
  {
    Id: 22789,
    Code: '8119',
    Name: 'Liner, pipe, rubber lining'
  },
  {
    Id: 22790,
    Code: '8119',
    Name: 'Liner, plant, rubber lining'
  },
  {
    Id: 22791,
    Code: '8119',
    Name: 'Liner, tank, rubber lining'
  },
  {
    Id: 22792,
    Code: '8119',
    Name: 'Liner, rubber'
  },
  {
    Id: 22793,
    Code: '8119',
    Name: 'Loader, kiln'
  },
  {
    Id: 22794,
    Code: '8119',
    Name: 'Loader, lehr'
  },
  {
    Id: 22795,
    Code: '8119',
    Name: 'Loader, mould, asbestos composition goods mfr'
  },
  {
    Id: 22796,
    Code: '8119',
    Name: 'Loader, ceramics mfr'
  },
  {
    Id: 22797,
    Code: '8119',
    Name: 'Machinist, bias, rubber tyre mfr'
  },
  {
    Id: 22798,
    Code: '8119',
    Name: 'Machinist, bottle'
  },
  {
    Id: 22799,
    Code: '8119',
    Name: 'Machinist, brick'
  },
  {
    Id: 22800,
    Code: '8119',
    Name: 'Machinist, building, micanite'
  },
  {
    Id: 22801,
    Code: '8119',
    Name: 'Machinist, coating, glass, bulb, valve mfr'
  },
  {
    Id: 22802,
    Code: '8119',
    Name: 'Machinist, covering, rubber'
  },
  {
    Id: 22803,
    Code: '8119',
    Name: 'Machinist, curing, rubber'
  },
  {
    Id: 22804,
    Code: '8119',
    Name: 'Machinist, cutting, glass'
  },
  {
    Id: 22805,
    Code: '8119',
    Name: 'Machinist, cutting, rubber'
  },
  {
    Id: 22806,
    Code: '8119',
    Name: 'Machinist, cutting, rubber'
  },
  {
    Id: 22807,
    Code: '8119',
    Name: 'Machinist, cutting, tyre mfr'
  },
  {
    Id: 22808,
    Code: '8119',
    Name: 'Machinist, embossing, floor and leather cloth mfr'
  },
  {
    Id: 22809,
    Code: '8119',
    Name: 'Machinist, extruding, rubber goods mfr'
  },
  {
    Id: 22810,
    Code: '8119',
    Name: 'Machinist, forcing'
  },
  {
    Id: 22811,
    Code: '8119',
    Name: 'Machinist, frosting, glass mfr'
  },
  {
    Id: 22812,
    Code: '8119',
    Name: 'Machinist, grinding, cement mfr'
  },
  {
    Id: 22813,
    Code: '8119',
    Name: 'Machinist, grinding, glass mfr'
  },
  {
    Id: 22814,
    Code: '8119',
    Name: 'Machinist, guillotine, rubber goods mfr'
  },
  {
    Id: 22815,
    Code: '8119',
    Name: 'Machinist, jigger'
  },
  {
    Id: 22816,
    Code: '8119',
    Name: 'Machinist, jolley'
  },
  {
    Id: 22817,
    Code: '8119',
    Name: 'Machinist, making, bottle'
  },
  {
    Id: 22818,
    Code: '8119',
    Name: 'Machinist, making, brick'
  },
  {
    Id: 22819,
    Code: '8119',
    Name: 'Machinist, making, plasterboard mfr'
  },
  {
    Id: 22820,
    Code: '8119',
    Name: 'Machinist, mica'
  },
  {
    Id: 22821,
    Code: '8119',
    Name: 'Machinist, mixing, rubber mfr'
  },
  {
    Id: 22822,
    Code: '8119',
    Name: 'Machinist, moulding, rubber goods mfr'
  },
  {
    Id: 22823,
    Code: '8119',
    Name: 'Machinist, painting, slip'
  },
  {
    Id: 22824,
    Code: '8119',
    Name: 'Machinist, pipe, sanitary'
  },
  {
    Id: 22825,
    Code: '8119',
    Name: 'Machinist, press, rubber goods mfr'
  },
  {
    Id: 22826,
    Code: '8119',
    Name: 'Machinist, shaking'
  },
  {
    Id: 22827,
    Code: '8119',
    Name: 'Machinist, spewing'
  },
  {
    Id: 22828,
    Code: '8119',
    Name: 'Machinist, tapping, ceramics mfr'
  },
  {
    Id: 22829,
    Code: '8119',
    Name: 'Machinist, tile'
  },
  {
    Id: 22830,
    Code: '8119',
    Name: 'Machinist, washing and mixing, abrasives mfr'
  },
  {
    Id: 22831,
    Code: '8119',
    Name: 'Machinist, winding, oilskin mfr'
  },
  {
    Id: 22832,
    Code: '8119',
    Name: 'Machinist, wiring, rubber tyre mfr'
  },
  {
    Id: 22833,
    Code: '8119',
    Name: 'Machinist, brick mfr'
  },
  {
    Id: 22834,
    Code: '8119',
    Name: 'Machinist, candle mfr'
  },
  {
    Id: 22835,
    Code: '8119',
    Name: 'Machinist, cast concrete products mfr'
  },
  {
    Id: 22836,
    Code: '8119',
    Name: 'Machinist, ceramics mfr'
  },
  {
    Id: 22837,
    Code: '8119',
    Name: 'Machinist, concrete mfr'
  },
  {
    Id: 22838,
    Code: '8119',
    Name: 'Machinist, glass mfr'
  },
  {
    Id: 22839,
    Code: '8119',
    Name: 'Machinist, linoleum mfr'
  },
  {
    Id: 22840,
    Code: '8119',
    Name: 'Machinist, optical goods mfr'
  },
  {
    Id: 22841,
    Code: '8119',
    Name: 'Machinist, rubber goods mfr'
  },
  {
    Id: 22842,
    Code: '8119',
    Name: 'Maker, asphalt'
  },
  {
    Id: 22843,
    Code: '8119',
    Name: 'Maker, bag, air'
  },
  {
    Id: 22844,
    Code: '8119',
    Name: 'Maker, ball, golf'
  },
  {
    Id: 22845,
    Code: '8119',
    Name: 'Maker, ball, rubber goods mfr'
  },
  {
    Id: 22846,
    Code: '8119',
    Name: 'Maker, bed, air'
  },
  {
    Id: 22847,
    Code: '8119',
    Name: 'Maker, belt, conveyor, rubber goods mfr'
  },
  {
    Id: 22848,
    Code: '8119',
    Name: 'Maker, belt, vee'
  },
  {
    Id: 22849,
    Code: '8119',
    Name: 'Maker, belt, rubber'
  },
  {
    Id: 22850,
    Code: '8119',
    Name: 'Maker, belting, rubber'
  },
  {
    Id: 22851,
    Code: '8119',
    Name: 'Maker, block, asphalt'
  },
  {
    Id: 22852,
    Code: '8119',
    Name: 'Maker, block, brake'
  },
  {
    Id: 22853,
    Code: '8119',
    Name: 'Maker, block, cement'
  },
  {
    Id: 22854,
    Code: '8119',
    Name: 'Maker, block, concrete'
  },
  {
    Id: 22855,
    Code: '8119',
    Name: 'Maker, block, rubber goods mfr'
  },
  {
    Id: 22856,
    Code: '8119',
    Name: 'Maker, bottle, rubber'
  },
  {
    Id: 22857,
    Code: '8119',
    Name: 'Maker, case, tyre mfr'
  },
  {
    Id: 22858,
    Code: '8119',
    Name: 'Maker, catheter, rubber'
  },
  {
    Id: 22859,
    Code: '8119',
    Name: 'Maker, cement'
  },
  {
    Id: 22860,
    Code: '8119',
    Name: 'Maker, clay'
  },
  {
    Id: 22861,
    Code: '8119',
    Name: 'Maker, concrete'
  },
  {
    Id: 22862,
    Code: '8119',
    Name: 'Maker, cover, waterproof'
  },
  {
    Id: 22863,
    Code: '8119',
    Name: 'Maker, curb, cast concrete products mfr'
  },
  {
    Id: 22864,
    Code: '8119',
    Name: 'Maker, cushion, air'
  },
  {
    Id: 22865,
    Code: '8119',
    Name: 'Maker, cushion, table, billiard'
  },
  {
    Id: 22866,
    Code: '8119',
    Name: 'Maker, dust, ceramics mfr'
  },
  {
    Id: 22867,
    Code: '8119',
    Name: 'Maker, flag, cast concrete products mfr'
  },
  {
    Id: 22868,
    Code: '8119',
    Name: 'Maker, flow, ceramics mfr'
  },
  {
    Id: 22869,
    Code: '8119',
    Name: 'Maker, glaze, ceramics mfr'
  },
  {
    Id: 22870,
    Code: '8119',
    Name: 'Maker, glove, rubber'
  },
  {
    Id: 22871,
    Code: '8119',
    Name: 'Maker, glove, surgical'
  },
  {
    Id: 22872,
    Code: '8119',
    Name: 'Maker, heel, rubber'
  },
  {
    Id: 22873,
    Code: '8119',
    Name: 'Maker, hose'
  },
  {
    Id: 22874,
    Code: '8119',
    Name: 'Maker, kerbstone, cast concrete products mfr'
  },
  {
    Id: 22875,
    Code: '8119',
    Name: 'Maker, lino'
  },
  {
    Id: 22876,
    Code: '8119',
    Name: 'Maker, linoleum'
  },
  {
    Id: 22877,
    Code: '8119',
    Name: 'Maker, mash, leathercloth mfr'
  },
  {
    Id: 22878,
    Code: '8119',
    Name: 'Maker, mat, rubber'
  },
  {
    Id: 22879,
    Code: '8119',
    Name: 'Maker, mattress, rubber'
  },
  {
    Id: 22880,
    Code: '8119',
    Name: 'Maker, micanite'
  },
  {
    Id: 22881,
    Code: '8119',
    Name: 'Maker, packing, rubber'
  },
  {
    Id: 22882,
    Code: '8119',
    Name: 'Maker, panel, plaster'
  },
  {
    Id: 22883,
    Code: '8119',
    Name: 'Maker, pipe, flexible'
  },
  {
    Id: 22884,
    Code: '8119',
    Name: 'Maker, pipe, cast concrete products mfr'
  },
  {
    Id: 22885,
    Code: '8119',
    Name: 'Maker, pipe, rubber goods mfr'
  },
  {
    Id: 22886,
    Code: '8119',
    Name: 'Maker, pocket, tyre mfr'
  },
  {
    Id: 22887,
    Code: '8119',
    Name: 'Maker, post, concrete'
  },
  {
    Id: 22888,
    Code: '8119',
    Name: 'Maker, putty'
  },
  {
    Id: 22889,
    Code: '8119',
    Name: 'Maker, ring, asbestos'
  },
  {
    Id: 22890,
    Code: '8119',
    Name: "Maker, roller, composition, printer's"
  },
  {
    Id: 22891,
    Code: '8119',
    Name: "Maker, roller, printer's"
  },
  {
    Id: 22892,
    Code: '8119',
    Name: 'Maker, roller, rubber'
  },
  {
    Id: 22893,
    Code: '8119',
    Name: 'Maker, rubber'
  },
  {
    Id: 22894,
    Code: '8119',
    Name: 'Maker, slab, cast concrete products mfr'
  },
  {
    Id: 22895,
    Code: '8119',
    Name: 'Maker, slip, ceramics mfr'
  },
  {
    Id: 22896,
    Code: '8119',
    Name: 'Maker, solution, rubber'
  },
  {
    Id: 22897,
    Code: '8119',
    Name: 'Maker, stamp, endorsing, rubber'
  },
  {
    Id: 22898,
    Code: '8119',
    Name: 'Maker, stone, composition'
  },
  {
    Id: 22899,
    Code: '8119',
    Name: 'Maker, stone, concrete, precast'
  },
  {
    Id: 22900,
    Code: '8119',
    Name: 'Maker, stone, kerb, cast concrete products mfr'
  },
  {
    Id: 22901,
    Code: '8119',
    Name: 'Maker, tile, asbestos-cement'
  },
  {
    Id: 22902,
    Code: '8119',
    Name: 'Maker, tile, concrete'
  },
  {
    Id: 22903,
    Code: '8119',
    Name: 'Maker, tile, rubber'
  },
  {
    Id: 22904,
    Code: '8119',
    Name: 'Maker, tread, rubber'
  },
  {
    Id: 22905,
    Code: '8119',
    Name: 'Maker, tube, flexible'
  },
  {
    Id: 22906,
    Code: '8119',
    Name: 'Maker, tube, rubber'
  },
  {
    Id: 22907,
    Code: '8119',
    Name: 'Maker, tubing, rubber'
  },
  {
    Id: 22908,
    Code: '8119',
    Name: 'Maker, tyre'
  },
  {
    Id: 22909,
    Code: '8119',
    Name: 'Maker, washer, rubber'
  },
  {
    Id: 22910,
    Code: '8119',
    Name: 'Maker, cast concrete products mfr'
  },
  {
    Id: 22911,
    Code: '8119',
    Name: 'Man, back-end, cement mfr'
  },
  {
    Id: 22912,
    Code: '8119',
    Name: 'Man, boiling, tar, cable mfr'
  },
  {
    Id: 22913,
    Code: '8119',
    Name: 'Man, clay, ceramics mfr'
  },
  {
    Id: 22914,
    Code: '8119',
    Name: 'Man, devulcaniser, rubber reclamation'
  },
  {
    Id: 22915,
    Code: '8119',
    Name: 'Man, dolomite, iron and steelworks'
  },
  {
    Id: 22916,
    Code: '8119',
    Name: 'Man, dry, china clay'
  },
  {
    Id: 22917,
    Code: '8119',
    Name: 'Man, floor, asphalt mfr'
  },
  {
    Id: 22918,
    Code: '8119',
    Name: 'Man, kiln, dry'
  },
  {
    Id: 22919,
    Code: '8119',
    Name: 'Man, kiln, enamel'
  },
  {
    Id: 22920,
    Code: '8119',
    Name: 'Man, kiln, frit'
  },
  {
    Id: 22921,
    Code: '8119',
    Name: 'Man, kiln, glost'
  },
  {
    Id: 22922,
    Code: '8119',
    Name: 'Man, kiln, gypsum'
  },
  {
    Id: 22923,
    Code: '8119',
    Name: 'Man, kiln, lime'
  },
  {
    Id: 22924,
    Code: '8119',
    Name: 'Man, kiln, abrasive wheel mfr'
  },
  {
    Id: 22925,
    Code: '8119',
    Name: 'Man, kiln, cement mfr'
  },
  {
    Id: 22926,
    Code: '8119',
    Name: 'Man, kiln, ceramics mfr'
  },
  {
    Id: 22927,
    Code: '8119',
    Name: 'Man, kiln, glass mfr'
  },
  {
    Id: 22928,
    Code: '8119',
    Name: 'Man, lehr'
  },
  {
    Id: 22929,
    Code: '8119',
    Name: "Man, mill, potter's"
  },
  {
    Id: 22930,
    Code: '8119',
    Name: 'Man, mill, pug'
  },
  {
    Id: 22931,
    Code: '8119',
    Name: 'Man, mill, rubber'
  },
  {
    Id: 22932,
    Code: '8119',
    Name: 'Man, mill, ceramics mfr'
  },
  {
    Id: 22933,
    Code: '8119',
    Name: 'Man, mill, rubber goods mfr'
  },
  {
    Id: 22934,
    Code: '8119',
    Name: 'Man, mill, whiting mfr'
  },
  {
    Id: 22935,
    Code: '8119',
    Name: 'Man, mixer, asphalt'
  },
  {
    Id: 22936,
    Code: '8119',
    Name: 'Man, mixer, building construction'
  },
  {
    Id: 22937,
    Code: '8119',
    Name: 'Man, pan, ceramics mfr'
  },
  {
    Id: 22938,
    Code: '8119',
    Name: 'Man, paper, plasterboard mfr'
  },
  {
    Id: 22939,
    Code: '8119',
    Name: 'Man, pug'
  },
  {
    Id: 22940,
    Code: '8119',
    Name: 'Man, slaker'
  },
  {
    Id: 22941,
    Code: '8119',
    Name: 'Man, table, rubber goods mfr'
  },
  {
    Id: 22942,
    Code: '8119',
    Name: 'Man, tank, cable'
  },
  {
    Id: 22943,
    Code: '8119',
    Name: 'Manipulator, tube, rubber'
  },
  {
    Id: 22944,
    Code: '8119',
    Name: 'Manufacturer, cast concrete products mfr'
  },
  {
    Id: 22945,
    Code: '8119',
    Name: 'Manufacturer, rubber goods mfr'
  },
  {
    Id: 22946,
    Code: '8119',
    Name: 'Matcher, colour, linoleum mfr'
  },
  {
    Id: 22947,
    Code: '8119',
    Name: 'Metaler, glass'
  },
  {
    Id: 22948,
    Code: '8119',
    Name: 'Miller, cement'
  },
  {
    Id: 22949,
    Code: '8119',
    Name: 'Miller, coal, cement mfr'
  },
  {
    Id: 22950,
    Code: '8119',
    Name: 'Miller, dust, ceramics mfr'
  },
  {
    Id: 22951,
    Code: '8119',
    Name: 'Miller, flint, ceramics mfr'
  },
  {
    Id: 22952,
    Code: '8119',
    Name: 'Miller, glaze, ceramics mfr'
  },
  {
    Id: 22953,
    Code: '8119',
    Name: 'Miller, gypsum'
  },
  {
    Id: 22954,
    Code: '8119',
    Name: 'Miller, lime'
  },
  {
    Id: 22955,
    Code: '8119',
    Name: 'Miller, rubber'
  },
  {
    Id: 22956,
    Code: '8119',
    Name: 'Miller, wash, cement mfr'
  },
  {
    Id: 22957,
    Code: '8119',
    Name: 'Miller, wet, cement mfr'
  },
  {
    Id: 22958,
    Code: '8119',
    Name: 'Miller, cement mfr'
  },
  {
    Id: 22959,
    Code: '8119',
    Name: 'Miller, ceramics mfr'
  },
  {
    Id: 22960,
    Code: '8119',
    Name: 'Mixer, asphalt'
  },
  {
    Id: 22961,
    Code: '8119',
    Name: 'Mixer, banbury'
  },
  {
    Id: 22962,
    Code: '8119',
    Name: 'Mixer, batch, glass mfr'
  },
  {
    Id: 22963,
    Code: '8119',
    Name: 'Mixer, cement, building construction'
  },
  {
    Id: 22964,
    Code: '8119',
    Name: 'Mixer, clay, ceramics mfr'
  },
  {
    Id: 22965,
    Code: '8119',
    Name: 'Mixer, concrete'
  },
  {
    Id: 22966,
    Code: '8119',
    Name: 'Mixer, dough, rubber mfr'
  },
  {
    Id: 22967,
    Code: '8119',
    Name: 'Mixer, dry, rubber goods mfr'
  },
  {
    Id: 22968,
    Code: '8119',
    Name: 'Mixer, dust, ceramics mfr'
  },
  {
    Id: 22969,
    Code: '8119',
    Name: 'Mixer, glass'
  },
  {
    Id: 22970,
    Code: '8119',
    Name: 'Mixer, glaze, ceramics mfr'
  },
  {
    Id: 22971,
    Code: '8119',
    Name: 'Mixer, grog, ceramics mfr'
  },
  {
    Id: 22972,
    Code: '8119',
    Name: 'Mixer, latex'
  },
  {
    Id: 22973,
    Code: '8119',
    Name: 'Mixer, lino'
  },
  {
    Id: 22974,
    Code: '8119',
    Name: 'Mixer, linoleum'
  },
  {
    Id: 22975,
    Code: '8119',
    Name: 'Mixer, macadam'
  },
  {
    Id: 22976,
    Code: '8119',
    Name: 'Mixer, marl'
  },
  {
    Id: 22977,
    Code: '8119',
    Name: 'Mixer, putty'
  },
  {
    Id: 22978,
    Code: '8119',
    Name: 'Mixer, rubber'
  },
  {
    Id: 22979,
    Code: '8119',
    Name: 'Mixer, slurry, cement mfr'
  },
  {
    Id: 22980,
    Code: '8119',
    Name: 'Mixer, artificial teeth mfr'
  },
  {
    Id: 22981,
    Code: '8119',
    Name: 'Mixer, cast concrete products mfr'
  },
  {
    Id: 22982,
    Code: '8119',
    Name: 'Mixer, cement mfr'
  },
  {
    Id: 22983,
    Code: '8119',
    Name: 'Mixer, cemented carbide goods mfr'
  },
  {
    Id: 22984,
    Code: '8119',
    Name: 'Mixer, ceramics mfr'
  },
  {
    Id: 22985,
    Code: '8119',
    Name: 'Mixer, glass mfr'
  },
  {
    Id: 22986,
    Code: '8119',
    Name: 'Mixer, rubber mfr'
  },
  {
    Id: 22987,
    Code: '8119',
    Name: 'Mixer, tarmacadam mfr'
  },
  {
    Id: 22988,
    Code: '8119',
    Name: 'Moulder, compo'
  },
  {
    Id: 22989,
    Code: '8119',
    Name: 'Moulder, concrete'
  },
  {
    Id: 22990,
    Code: '8119',
    Name: 'Moulder, ebonite'
  },
  {
    Id: 22991,
    Code: '8119',
    Name: 'Moulder, hand, asbestos goods mfr'
  },
  {
    Id: 22992,
    Code: '8119',
    Name: 'Moulder, injection, rubber goods mfr'
  },
  {
    Id: 22993,
    Code: '8119',
    Name: 'Moulder, mica'
  },
  {
    Id: 22994,
    Code: '8119',
    Name: 'Moulder, micanite'
  },
  {
    Id: 22995,
    Code: '8119',
    Name: 'Moulder, press, rubber'
  },
  {
    Id: 22996,
    Code: '8119',
    Name: 'Moulder, rubber, moulds'
  },
  {
    Id: 22997,
    Code: '8119',
    Name: 'Moulder, stamp'
  },
  {
    Id: 22998,
    Code: '8119',
    Name: 'Moulder, tube, rubber'
  },
  {
    Id: 22999,
    Code: '8119',
    Name: 'Moulder, tyre'
  },
  {
    Id: 23000,
    Code: '8119',
    Name: 'Moulder, asbestos-cement goods mfr'
  },
  {
    Id: 23001,
    Code: '8119',
    Name: 'Moulder, bottle cap mfr'
  },
  {
    Id: 23002,
    Code: '8119',
    Name: 'Moulder, cast concrete products mfr'
  },
  {
    Id: 23003,
    Code: '8119',
    Name: 'Moulder, rubber goods mfr'
  },
  {
    Id: 23004,
    Code: '8119',
    Name: 'Oddman, biscuit'
  },
  {
    Id: 23005,
    Code: '8119',
    Name: 'Oddman, glost'
  },
  {
    Id: 23006,
    Code: '8119',
    Name: 'Oddman, kiln'
  },
  {
    Id: 23007,
    Code: '8119',
    Name: 'Oddman, oven'
  },
  {
    Id: 23008,
    Code: '8119',
    Name: 'Operative, clay, brick mfr'
  },
  {
    Id: 23009,
    Code: '8119',
    Name: 'Operative, concrete, concrete products mfr'
  },
  {
    Id: 23010,
    Code: '8119',
    Name: 'Operative, oven, tunnel'
  },
  {
    Id: 23011,
    Code: '8119',
    Name: 'Operator, autoclave, glass mfr'
  },
  {
    Id: 23012,
    Code: '8119',
    Name: 'Operator, banbury, rubber mfr'
  },
  {
    Id: 23013,
    Code: '8119',
    Name: 'Operator, calender, rubber mfr'
  },
  {
    Id: 23014,
    Code: '8119',
    Name: 'Operator, castings, rubber goods mfr'
  },
  {
    Id: 23015,
    Code: '8119',
    Name: 'Operator, cathedral'
  },
  {
    Id: 23016,
    Code: '8119',
    Name: 'Operator, drier, plasterboard'
  },
  {
    Id: 23017,
    Code: '8119',
    Name: 'Operator, end, cold'
  },
  {
    Id: 23018,
    Code: '8119',
    Name: 'Operator, extruder, rubber goods mfr'
  },
  {
    Id: 23019,
    Code: '8119',
    Name: 'Operator, extrusion, rubber goods mfr'
  },
  {
    Id: 23020,
    Code: '8119',
    Name: 'Operator, furnace, glass'
  },
  {
    Id: 23021,
    Code: '8119',
    Name: 'Operator, furnace, ceramics mfr'
  },
  {
    Id: 23022,
    Code: '8119',
    Name: 'Operator, furnace, glass mfr'
  },
  {
    Id: 23023,
    Code: '8119',
    Name: 'Operator, glass, fibre'
  },
  {
    Id: 23024,
    Code: '8119',
    Name: 'Operator, hydrate'
  },
  {
    Id: 23025,
    Code: '8119',
    Name: 'Operator, kiln, ceramics mfr'
  },
  {
    Id: 23026,
    Code: '8119',
    Name: 'Operator, kiln'
  },
  {
    Id: 23027,
    Code: '8119',
    Name: 'Operator, mill, ball, ceramics mfr'
  },
  {
    Id: 23028,
    Code: '8119',
    Name: 'Operator, mill, mortar'
  },
  {
    Id: 23029,
    Code: '8119',
    Name: 'Operator, mill, rubber goods mfr'
  },
  {
    Id: 23030,
    Code: '8119',
    Name: 'Operator, mixer, cast concrete products mfr'
  },
  {
    Id: 23031,
    Code: '8119',
    Name: 'Operator, pipe, blow, quartz'
  },
  {
    Id: 23032,
    Code: '8119',
    Name: 'Operator, plant, brick'
  },
  {
    Id: 23033,
    Code: '8119',
    Name: 'Operator, plant, concrete'
  },
  {
    Id: 23034,
    Code: '8119',
    Name: 'Operator, plant, brick mfr'
  },
  {
    Id: 23035,
    Code: '8119',
    Name: 'Operator, plant, rubber goods mfr'
  },
  {
    Id: 23036,
    Code: '8119',
    Name: 'Operator, process, glass mfr'
  },
  {
    Id: 23037,
    Code: '8119',
    Name: 'Operator, process, rubber mfr'
  },
  {
    Id: 23038,
    Code: '8119',
    Name: 'Operator, room, drying'
  },
  {
    Id: 23039,
    Code: '8119',
    Name: 'Operator, tank, glass'
  },
  {
    Id: 23040,
    Code: '8119',
    Name: 'Operator, tumbler, ceramics mfr'
  },
  {
    Id: 23041,
    Code: '8119',
    Name: 'Ovenman, biscuit'
  },
  {
    Id: 23042,
    Code: '8119',
    Name: 'Ovenman, glost'
  },
  {
    Id: 23043,
    Code: '8119',
    Name: 'Ovenman, ceramics mfr'
  },
  {
    Id: 23044,
    Code: '8119',
    Name: 'Packer, kiln'
  },
  {
    Id: 23045,
    Code: '8119',
    Name: "Packer, potter's"
  },
  {
    Id: 23046,
    Code: '8119',
    Name: 'Packer, sagger'
  },
  {
    Id: 23047,
    Code: '8119',
    Name: 'Picker, bone, ceramics mfr'
  },
  {
    Id: 23048,
    Code: '8119',
    Name: 'Picker, ceramics mfr'
  },
  {
    Id: 23049,
    Code: '8119',
    Name: 'Pitcher, ceramics mfr'
  },
  {
    Id: 23050,
    Code: '8119',
    Name: 'Placer, biscuit'
  },
  {
    Id: 23051,
    Code: '8119',
    Name: 'Placer, glost'
  },
  {
    Id: 23052,
    Code: '8119',
    Name: 'Placer, kiln'
  },
  {
    Id: 23053,
    Code: '8119',
    Name: 'Placer, tile'
  },
  {
    Id: 23054,
    Code: '8119',
    Name: 'Placer, ware, sanitary'
  },
  {
    Id: 23055,
    Code: '8119',
    Name: 'Placer, ceramics mfr'
  },
  {
    Id: 23056,
    Code: '8119',
    Name: 'Plasterer, cast concrete products mfr'
  },
  {
    Id: 23057,
    Code: '8119',
    Name: 'Plugger, stoneware pipe mfr'
  },
  {
    Id: 23058,
    Code: '8119',
    Name: 'Polisher, tile, asbestos-cement goods mfr'
  },
  {
    Id: 23059,
    Code: '8119',
    Name: 'Preparer, colour, ceramics mfr'
  },
  {
    Id: 23060,
    Code: '8119',
    Name: 'Preparer, glaze, ceramics mfr'
  },
  {
    Id: 23061,
    Code: '8119',
    Name: 'Presser, belt'
  },
  {
    Id: 23062,
    Code: '8119',
    Name: 'Presser, heel, rubber goods mfr'
  },
  {
    Id: 23063,
    Code: '8119',
    Name: 'Presser, hot, cemented carbide goods mfr'
  },
  {
    Id: 23064,
    Code: '8119',
    Name: 'Presser, hot, rubber goods mfr'
  },
  {
    Id: 23065,
    Code: '8119',
    Name: 'Presser, jobbing'
  },
  {
    Id: 23066,
    Code: '8119',
    Name: 'Presser, lining, brake'
  },
  {
    Id: 23067,
    Code: '8119',
    Name: 'Presser, mica'
  },
  {
    Id: 23068,
    Code: '8119',
    Name: 'Presser, rubber'
  },
  {
    Id: 23069,
    Code: '8119',
    Name: 'Presser, steam, rubber'
  },
  {
    Id: 23070,
    Code: '8119',
    Name: 'Presser, washer'
  },
  {
    Id: 23071,
    Code: '8119',
    Name: 'Presser, cast concrete products mfr'
  },
  {
    Id: 23072,
    Code: '8119',
    Name: 'Presser, cemented carbide goods mfr'
  },
  {
    Id: 23073,
    Code: '8119',
    Name: 'Presser, mineral products mfr'
  },
  {
    Id: 23074,
    Code: '8119',
    Name: 'Presser, rubber goods mfr'
  },
  {
    Id: 23075,
    Code: '8119',
    Name: 'Processor, glass'
  },
  {
    Id: 23076,
    Code: '8119',
    Name: 'Pugger'
  },
  {
    Id: 23077,
    Code: '8119',
    Name: 'Rasper, remould tyres'
  },
  {
    Id: 23078,
    Code: '8119',
    Name: 'Reclaimer, rubber'
  },
  {
    Id: 23079,
    Code: '8119',
    Name: 'Recoverer, rubber reclamation'
  },
  {
    Id: 23080,
    Code: '8119',
    Name: 'Refiner, rubber reclamation'
  },
  {
    Id: 23081,
    Code: '8119',
    Name: 'Remoulder, rubber goods mfr'
  },
  {
    Id: 23082,
    Code: '8119',
    Name: 'Renewer, tread'
  },
  {
    Id: 23083,
    Code: '8119',
    Name: 'Repairer, tyre'
  },
  {
    Id: 23084,
    Code: '8119',
    Name: 'Restorer, tyre'
  },
  {
    Id: 23085,
    Code: '8119',
    Name: 'Retreader, tyre'
  },
  {
    Id: 23086,
    Code: '8119',
    Name: 'Roaster, minerals'
  },
  {
    Id: 23087,
    Code: '8119',
    Name: 'Roller, tube, micanite'
  },
  {
    Id: 23088,
    Code: '8119',
    Name: 'Roller, tube, rubber'
  },
  {
    Id: 23089,
    Code: '8119',
    Name: 'Roller, rubber goods mfr'
  },
  {
    Id: 23090,
    Code: '8119',
    Name: 'Rubberer, tyre'
  },
  {
    Id: 23091,
    Code: '8119',
    Name: 'Sawyer, asbestos-cement goods mfr'
  },
  {
    Id: 23092,
    Code: '8119',
    Name: 'Scraper, gut'
  },
  {
    Id: 23093,
    Code: '8119',
    Name: 'Scratcher, linoleum mfr'
  },
  {
    Id: 23094,
    Code: '8119',
    Name: 'Screener, iron shot and grit mfr'
  },
  {
    Id: 23095,
    Code: '8119',
    Name: 'Selector, gut'
  },
  {
    Id: 23096,
    Code: '8119',
    Name: 'Setter, brick, brick mfr'
  },
  {
    Id: 23097,
    Code: '8119',
    Name: 'Setter, clamp, ceramics mfr'
  },
  {
    Id: 23098,
    Code: '8119',
    Name: 'Setter, kiln'
  },
  {
    Id: 23099,
    Code: '8119',
    Name: 'Setter, machine, moulding, rubber'
  },
  {
    Id: 23100,
    Code: '8119',
    Name: 'Setter, oven, ceramics mfr'
  },
  {
    Id: 23101,
    Code: '8119',
    Name: 'Setter, pipe'
  },
  {
    Id: 23102,
    Code: '8119',
    Name: 'Setter, tile'
  },
  {
    Id: 23103,
    Code: '8119',
    Name: 'Setter, ceramics mfr'
  },
  {
    Id: 23104,
    Code: '8119',
    Name: 'Setter and drawer, ceramics mfr'
  },
  {
    Id: 23105,
    Code: '8119',
    Name: 'Setter-in, ceramics mfr'
  },
  {
    Id: 23106,
    Code: '8119',
    Name: 'Shader, ceramics mfr'
  },
  {
    Id: 23107,
    Code: '8119',
    Name: 'Sheeter, asbestos, asbestos-cement goods mfr'
  },
  {
    Id: 23108,
    Code: '8119',
    Name: 'Sheeter, rubber goods mfr'
  },
  {
    Id: 23109,
    Code: '8119',
    Name: 'Shrinker, rubber goods mfr'
  },
  {
    Id: 23110,
    Code: '8119',
    Name: 'Sifter, dust, ceramics mfr'
  },
  {
    Id: 23111,
    Code: '8119',
    Name: 'Sifter, ceramics mfr'
  },
  {
    Id: 23112,
    Code: '8119',
    Name: 'Skimmer, glass mfr'
  },
  {
    Id: 23113,
    Code: '8119',
    Name: 'Slaker, ceramics mfr'
  },
  {
    Id: 23114,
    Code: '8119',
    Name: 'Slimer, gut cleaning'
  },
  {
    Id: 23115,
    Code: '8119',
    Name: 'Slugger, paste'
  },
  {
    Id: 23116,
    Code: '8119',
    Name: 'Smelter, glass mfr'
  },
  {
    Id: 23117,
    Code: '8119',
    Name: 'Spinner, concrete'
  },
  {
    Id: 23118,
    Code: '8119',
    Name: 'Spinner, gut'
  },
  {
    Id: 23119,
    Code: '8119',
    Name: 'Spinner, pipe, cast concrete products mfr'
  },
  {
    Id: 23120,
    Code: '8119',
    Name: 'Spinner, pot, silicon carbide mfr'
  },
  {
    Id: 23121,
    Code: '8119',
    Name: 'Spinner, gut processing'
  },
  {
    Id: 23122,
    Code: '8119',
    Name: 'Splicer, tyre mfr'
  },
  {
    Id: 23123,
    Code: '8119',
    Name: 'Splitter, gut'
  },
  {
    Id: 23124,
    Code: '8119',
    Name: 'Splitter, gut dressing'
  },
  {
    Id: 23125,
    Code: '8119',
    Name: 'Splitter, rubber reclamation'
  },
  {
    Id: 23126,
    Code: '8119',
    Name: 'Sprayer, asbestos'
  },
  {
    Id: 23127,
    Code: '8119',
    Name: 'Sprayer, tyre'
  },
  {
    Id: 23128,
    Code: '8119',
    Name: 'Spreader, rubber'
  },
  {
    Id: 23129,
    Code: '8119',
    Name: 'Spreader, linoleum mfr'
  },
  {
    Id: 23130,
    Code: '8119',
    Name: 'Spreader, rubber mfr'
  },
  {
    Id: 23131,
    Code: '8119',
    Name: 'Stamper, rubber, rubber goods mfr'
  },
  {
    Id: 23132,
    Code: '8119',
    Name: 'Steamer, pipe, spun'
  },
  {
    Id: 23133,
    Code: '8119',
    Name: 'Stoker, furnace, ceramics mfr'
  },
  {
    Id: 23134,
    Code: '8119',
    Name: 'Stoker, kiln, brick'
  },
  {
    Id: 23135,
    Code: '8119',
    Name: 'Stoker, kiln, lime'
  },
  {
    Id: 23136,
    Code: '8119',
    Name: 'Stoker, glass mfr'
  },
  {
    Id: 23137,
    Code: '8119',
    Name: 'Stover'
  },
  {
    Id: 23138,
    Code: '8119',
    Name: 'Stripper, furniture'
  },
  {
    Id: 23139,
    Code: '8119',
    Name: 'Stripper, pine'
  },
  {
    Id: 23140,
    Code: '8119',
    Name: 'Stripper, rubber'
  },
  {
    Id: 23141,
    Code: '8119',
    Name: 'Stripper, tyre'
  },
  {
    Id: 23142,
    Code: '8119',
    Name: 'Taker-in, glass mfr'
  },
  {
    Id: 23143,
    Code: '8119',
    Name: 'Taker-off, cast concrete products mfr'
  },
  {
    Id: 23144,
    Code: '8119',
    Name: 'Taker-off, plasterboard mfr'
  },
  {
    Id: 23145,
    Code: '8119',
    Name: 'Tankman, glass mfr'
  },
  {
    Id: 23146,
    Code: '8119',
    Name: 'Teazer, glass mfr'
  },
  {
    Id: 23147,
    Code: '8119',
    Name: 'Temperer, blast furnace'
  },
  {
    Id: 23148,
    Code: '8119',
    Name: 'Temperer, ceramics mfr'
  },
  {
    Id: 23149,
    Code: '8119',
    Name: 'Toughener, glass'
  },
  {
    Id: 23150,
    Code: '8119',
    Name: 'Trimmer, block, rubber goods mfr'
  },
  {
    Id: 23151,
    Code: '8119',
    Name: 'Trimmer, pad, heel'
  },
  {
    Id: 23152,
    Code: '8119',
    Name: 'Trimmer, rubber'
  },
  {
    Id: 23153,
    Code: '8119',
    Name: 'Trimmer, tyre, solid'
  },
  {
    Id: 23154,
    Code: '8119',
    Name: 'Trimmer, cement mfr'
  },
  {
    Id: 23155,
    Code: '8119',
    Name: 'Trimmer, rubber mfr'
  },
  {
    Id: 23156,
    Code: '8119',
    Name: 'Turner, ebonite'
  },
  {
    Id: 23157,
    Code: '8119',
    Name: 'Turner, roller, rubber'
  },
  {
    Id: 23158,
    Code: '8119',
    Name: 'Turner, rubber'
  },
  {
    Id: 23159,
    Code: '8119',
    Name: 'Turner, vulcanite'
  },
  {
    Id: 23160,
    Code: '8119',
    Name: 'Turner, rubber'
  },
  {
    Id: 23161,
    Code: '8119',
    Name: 'Turner, asbestos-cement goods mfr'
  },
  {
    Id: 23162,
    Code: '8119',
    Name: 'Turner, rubber goods mfr'
  },
  {
    Id: 23163,
    Code: '8119',
    Name: 'Twister, gut'
  },
  {
    Id: 23164,
    Code: '8119',
    Name: 'Veneerer, tyre'
  },
  {
    Id: 23165,
    Code: '8119',
    Name: 'Vitrifier, artificial teeth mfr'
  },
  {
    Id: 23166,
    Code: '8119',
    Name: 'Vulcanizer'
  },
  {
    Id: 23167,
    Code: '8119',
    Name: 'Wadder'
  },
  {
    Id: 23168,
    Code: '8119',
    Name: 'Washer, rubber'
  },
  {
    Id: 23169,
    Code: '8119',
    Name: 'Waxer, block, thread'
  },
  {
    Id: 23170,
    Code: '8119',
    Name: 'Winder, ball, golf'
  },
  {
    Id: 23171,
    Code: '8119',
    Name: 'Winder, belt, rubber'
  },
  {
    Id: 23172,
    Code: '8119',
    Name: 'Winder, core, golf ball mfr'
  },
  {
    Id: 23173,
    Code: '8119',
    Name: 'Wirer, tyre'
  },
  {
    Id: 23174,
    Code: '8119',
    Name: 'Worker, ball, golf'
  },
  {
    Id: 23175,
    Code: '8119',
    Name: 'Worker, bench, glass mfr'
  },
  {
    Id: 23176,
    Code: '8119',
    Name: 'Worker, block, concrete'
  },
  {
    Id: 23177,
    Code: '8119',
    Name: 'Worker, brick'
  },
  {
    Id: 23178,
    Code: '8119',
    Name: 'Worker, cement'
  },
  {
    Id: 23179,
    Code: '8119',
    Name: 'Worker, clay, ceramics mfr'
  },
  {
    Id: 23180,
    Code: '8119',
    Name: 'Worker, concrete, pre-cast'
  },
  {
    Id: 23181,
    Code: '8119',
    Name: 'Worker, concrete, concrete products mfr'
  },
  {
    Id: 23182,
    Code: '8119',
    Name: 'Worker, crucible'
  },
  {
    Id: 23183,
    Code: '8119',
    Name: 'Worker, ebonite'
  },
  {
    Id: 23184,
    Code: '8119',
    Name: 'Worker, factory, glass mfr'
  },
  {
    Id: 23185,
    Code: '8119',
    Name: 'Worker, factory, rubber mfr'
  },
  {
    Id: 23186,
    Code: '8119',
    Name: 'Worker, fibre, rubber goods mfr'
  },
  {
    Id: 23187,
    Code: '8119',
    Name: 'Worker, fibreglass'
  },
  {
    Id: 23188,
    Code: '8119',
    Name: 'Worker, glass, fibre'
  },
  {
    Id: 23189,
    Code: '8119',
    Name: 'Worker, glass'
  },
  {
    Id: 23190,
    Code: '8119',
    Name: 'Worker, goods, rubber'
  },
  {
    Id: 23191,
    Code: '8119',
    Name: 'Worker, house, slip'
  },
  {
    Id: 23192,
    Code: '8119',
    Name: 'Worker, kiln, ceramics mfr'
  },
  {
    Id: 23193,
    Code: '8119',
    Name: 'Worker, kiln, lime burning'
  },
  {
    Id: 23194,
    Code: '8119',
    Name: 'Worker, mica'
  },
  {
    Id: 23195,
    Code: '8119',
    Name: 'Worker, micanite'
  },
  {
    Id: 23196,
    Code: '8119',
    Name: 'Worker, mill, grog'
  },
  {
    Id: 23197,
    Code: '8119',
    Name: 'Worker, mill, rubber'
  },
  {
    Id: 23198,
    Code: '8119',
    Name: 'Worker, process, abrasives mfr'
  },
  {
    Id: 23199,
    Code: '8119',
    Name: 'Worker, process, cable mfr'
  },
  {
    Id: 23200,
    Code: '8119',
    Name: 'Worker, process, cast concrete products mfr'
  },
  {
    Id: 23201,
    Code: '8119',
    Name: 'Worker, process, cemented carbide goods mfr'
  },
  {
    Id: 23202,
    Code: '8119',
    Name: 'Worker, process, ceramics mfr'
  },
  {
    Id: 23203,
    Code: '8119',
    Name: 'Worker, process, fibreglass mfr'
  },
  {
    Id: 23204,
    Code: '8119',
    Name: 'Worker, process, glass mfr'
  },
  {
    Id: 23205,
    Code: '8119',
    Name: 'Worker, process, jewellery, plate mfr'
  },
  {
    Id: 23206,
    Code: '8119',
    Name: 'Worker, process, linoleum mfr'
  },
  {
    Id: 23207,
    Code: '8119',
    Name: 'Worker, process, mica, micanite goods mfr'
  },
  {
    Id: 23208,
    Code: '8119',
    Name: 'Worker, process, rubber goods mfr'
  },
  {
    Id: 23209,
    Code: '8119',
    Name: 'Worker, process, rubber reclamation'
  },
  {
    Id: 23210,
    Code: '8119',
    Name: 'Worker, rubber'
  },
  {
    Id: 23211,
    Code: '8119',
    Name: 'Worker, stone, cast concrete products mfr'
  },
  {
    Id: 23212,
    Code: '8119',
    Name: 'Worker, tile, cast concrete products mfr'
  },
  {
    Id: 23213,
    Code: '8119',
    Name: 'Worker, tyre'
  },
  {
    Id: 23214,
    Code: '8119',
    Name: 'Wrapper, bead, tyre mfr'
  },
  {
    Id: 23215,
    Code: '8119',
    Name: 'Wrapper, tube, rubber goods mfr'
  },
  {
    Id: 23216,
    Code: '8119',
    Name: 'Wrapper, inner tube mfr'
  },
  {
    Id: 23217,
    Code: '8120',
    Name: 'Attendant, auto'
  },
  {
    Id: 23218,
    Code: '8120',
    Name: 'Attendant, automatic'
  },
  {
    Id: 23219,
    Code: '8120',
    Name: 'Attendant, lathe'
  },
  {
    Id: 23220,
    Code: '8120',
    Name: 'Attendant, mill, metal goods mfr'
  },
  {
    Id: 23221,
    Code: '8120',
    Name: 'Attendant, plant, blast, shot'
  },
  {
    Id: 23222,
    Code: '8120',
    Name: 'Attendant, punch'
  },
  {
    Id: 23223,
    Code: '8120',
    Name: 'Barreller'
  },
  {
    Id: 23224,
    Code: '8120',
    Name: 'Batterer'
  },
  {
    Id: 23225,
    Code: '8120',
    Name: 'Beader, tin box mfr'
  },
  {
    Id: 23226,
    Code: '8120',
    Name: 'Belter, forks and spades'
  },
  {
    Id: 23227,
    Code: '8120',
    Name: 'Blaster, grit'
  },
  {
    Id: 23228,
    Code: '8120',
    Name: 'Blaster, sand'
  },
  {
    Id: 23229,
    Code: '8120',
    Name: 'Blaster, shot'
  },
  {
    Id: 23230,
    Code: '8120',
    Name: 'Blaster, vapour'
  },
  {
    Id: 23231,
    Code: '8120',
    Name: 'Blower, sand'
  },
  {
    Id: 23232,
    Code: '8120',
    Name: 'Blower, shot'
  },
  {
    Id: 23233,
    Code: '8120',
    Name: 'Bobber, emery'
  },
  {
    Id: 23234,
    Code: '8120',
    Name: 'Bobber, metal goods'
  },
  {
    Id: 23235,
    Code: '8120',
    Name: 'Bobber, arc welding electrode mfr'
  },
  {
    Id: 23236,
    Code: '8120',
    Name: 'Bobber and polisher, spur'
  },
  {
    Id: 23237,
    Code: '8120',
    Name: 'Bottomer, metal goods mfr'
  },
  {
    Id: 23238,
    Code: '8120',
    Name: 'Breaker-off, foundry'
  },
  {
    Id: 23239,
    Code: '8120',
    Name: 'Brightener'
  },
  {
    Id: 23240,
    Code: '8120',
    Name: 'Broacher'
  },
  {
    Id: 23241,
    Code: '8120',
    Name: 'Brusher, scratch'
  },
  {
    Id: 23242,
    Code: '8120',
    Name: 'Brusher, wire'
  },
  {
    Id: 23243,
    Code: '8120',
    Name: 'Brusher, foundry'
  },
  {
    Id: 23244,
    Code: '8120',
    Name: 'Brusher, scissors mfr'
  },
  {
    Id: 23245,
    Code: '8120',
    Name: 'Brusher-in, scratch'
  },
  {
    Id: 23246,
    Code: '8120',
    Name: 'Buffer'
  },
  {
    Id: 23247,
    Code: '8120',
    Name: 'Bumper, tin box mfr'
  },
  {
    Id: 23248,
    Code: '8120',
    Name: 'Burnisher, metal trades'
  },
  {
    Id: 23249,
    Code: '8120',
    Name: 'Chamferer'
  },
  {
    Id: 23250,
    Code: '8120',
    Name: 'Channeller, metal trades'
  },
  {
    Id: 23251,
    Code: '8120',
    Name: 'Chipper, tyre'
  },
  {
    Id: 23252,
    Code: '8120',
    Name: 'Chipper, fish hook mfr'
  },
  {
    Id: 23253,
    Code: '8120',
    Name: 'Cleaner, casting'
  },
  {
    Id: 23254,
    Code: '8120',
    Name: 'Cleaner, iron'
  },
  {
    Id: 23255,
    Code: '8120',
    Name: 'Cleaner, metal'
  },
  {
    Id: 23256,
    Code: '8120',
    Name: 'Cleaner, ring'
  },
  {
    Id: 23257,
    Code: '8120',
    Name: 'Cleaner, steel, foundry'
  },
  {
    Id: 23258,
    Code: '8120',
    Name: 'Cleaner, foundry'
  },
  {
    Id: 23259,
    Code: '8120',
    Name: 'Coiner'
  },
  {
    Id: 23260,
    Code: '8120',
    Name: 'Corrugator, galvanised sheet mfr'
  },
  {
    Id: 23261,
    Code: '8120',
    Name: 'Cramper, nails, needles'
  },
  {
    Id: 23262,
    Code: '8120',
    Name: 'Cupper, shell'
  },
  {
    Id: 23263,
    Code: '8120',
    Name: 'Cutter, bar'
  },
  {
    Id: 23264,
    Code: '8120',
    Name: 'Cutter, billet, steelworks'
  },
  {
    Id: 23265,
    Code: '8120',
    Name: 'Cutter, blank, spoon, fork mfr'
  },
  {
    Id: 23266,
    Code: '8120',
    Name: 'Cutter, cable'
  },
  {
    Id: 23267,
    Code: '8120',
    Name: 'Cutter, circle'
  },
  {
    Id: 23268,
    Code: '8120',
    Name: 'Cutter, gold'
  },
  {
    Id: 23269,
    Code: '8120',
    Name: 'Cutter, guillotine, metal trades'
  },
  {
    Id: 23270,
    Code: '8120',
    Name: 'Cutter, key'
  },
  {
    Id: 23271,
    Code: '8120',
    Name: 'Cutter, knife, machine, metal trades'
  },
  {
    Id: 23272,
    Code: '8120',
    Name: 'Cutter, machine, punching, metal trades'
  },
  {
    Id: 23273,
    Code: '8120',
    Name: 'Cutter, metal'
  },
  {
    Id: 23274,
    Code: '8120',
    Name: 'Cutter, panel, metal'
  },
  {
    Id: 23275,
    Code: '8120',
    Name: 'Cutter, pin'
  },
  {
    Id: 23276,
    Code: '8120',
    Name: 'Cutter, rail'
  },
  {
    Id: 23277,
    Code: '8120',
    Name: 'Cutter, rasp'
  },
  {
    Id: 23278,
    Code: '8120',
    Name: 'Cutter, rotary, metal trades'
  },
  {
    Id: 23279,
    Code: '8120',
    Name: 'Cutter, silver'
  },
  {
    Id: 23280,
    Code: '8120',
    Name: 'Cutter, test, rolling mill'
  },
  {
    Id: 23281,
    Code: '8120',
    Name: 'Cutter, tube, metal'
  },
  {
    Id: 23282,
    Code: '8120',
    Name: 'Cutter, wire'
  },
  {
    Id: 23283,
    Code: '8120',
    Name: 'Cutter, metal cable mfr'
  },
  {
    Id: 23284,
    Code: '8120',
    Name: 'Cutter, metal cutlery mfr'
  },
  {
    Id: 23285,
    Code: '8120',
    Name: 'Cutter, metal fastening mfr'
  },
  {
    Id: 23286,
    Code: '8120',
    Name: 'Cutter, metal trades'
  },
  {
    Id: 23287,
    Code: '8120',
    Name: 'Cutter, steel pen mfr'
  },
  {
    Id: 23288,
    Code: '8120',
    Name: 'Cutter-off, iron pipe mfr'
  },
  {
    Id: 23289,
    Code: '8120',
    Name: 'Cutter-off, metal trades'
  },
  {
    Id: 23290,
    Code: '8120',
    Name: 'Cutter-up, scrap'
  },
  {
    Id: 23291,
    Code: '8120',
    Name: 'Deburrer'
  },
  {
    Id: 23292,
    Code: '8120',
    Name: 'Dresser, box, axle'
  },
  {
    Id: 23293,
    Code: '8120',
    Name: 'Dresser, casting'
  },
  {
    Id: 23294,
    Code: '8120',
    Name: 'Dresser, core'
  },
  {
    Id: 23295,
    Code: '8120',
    Name: 'Dresser, iron'
  },
  {
    Id: 23296,
    Code: '8120',
    Name: 'Dresser, metal'
  },
  {
    Id: 23297,
    Code: '8120',
    Name: 'Dresser, pipe'
  },
  {
    Id: 23298,
    Code: '8120',
    Name: 'Dresser, plate'
  },
  {
    Id: 23299,
    Code: '8120',
    Name: 'Dresser, scissors'
  },
  {
    Id: 23300,
    Code: '8120',
    Name: 'Dresser, steel'
  },
  {
    Id: 23301,
    Code: '8120',
    Name: 'Dresser, tube'
  },
  {
    Id: 23302,
    Code: '8120',
    Name: 'Dresser, wheel'
  },
  {
    Id: 23303,
    Code: '8120',
    Name: 'Dresser, metal trades'
  },
  {
    Id: 23304,
    Code: '8120',
    Name: 'Dresser, type foundry'
  },
  {
    Id: 23305,
    Code: '8120',
    Name: 'Embosser, metal trades'
  },
  {
    Id: 23306,
    Code: '8120',
    Name: 'Engineer, nos'
  },
  {
    Id: 23307,
    Code: '8120',
    Name: 'Eyer, needles'
  },
  {
    Id: 23308,
    Code: '8120',
    Name: 'Facer, metal fastening mfr'
  },
  {
    Id: 23309,
    Code: '8120',
    Name: 'Feeder, tack'
  },
  {
    Id: 23310,
    Code: '8120',
    Name: 'Feeder, metal fastening mfr'
  },
  {
    Id: 23311,
    Code: '8120',
    Name: 'Feeder, sheet metal working'
  },
  {
    Id: 23312,
    Code: '8120',
    Name: 'Fettler, brass'
  },
  {
    Id: 23313,
    Code: '8120',
    Name: 'Fettler, castings'
  },
  {
    Id: 23314,
    Code: '8120',
    Name: 'Fettler, core'
  },
  {
    Id: 23315,
    Code: '8120',
    Name: 'Fettler, iron'
  },
  {
    Id: 23316,
    Code: '8120',
    Name: 'Fettler, shop, machine'
  },
  {
    Id: 23317,
    Code: '8120',
    Name: 'Fettler, metal trades'
  },
  {
    Id: 23318,
    Code: '8120',
    Name: 'Fettler'
  },
  {
    Id: 23319,
    Code: '8120',
    Name: 'Filer, foundry'
  },
  {
    Id: 23320,
    Code: '8120',
    Name: 'Filer, pattern'
  },
  {
    Id: 23321,
    Code: '8120',
    Name: 'Filer, spoon and fork'
  },
  {
    Id: 23322,
    Code: '8120',
    Name: 'Filer, metal trades'
  },
  {
    Id: 23323,
    Code: '8120',
    Name: 'Finisher, bobbin, brass'
  },
  {
    Id: 23324,
    Code: '8120',
    Name: 'Finisher, brass'
  },
  {
    Id: 23325,
    Code: '8120',
    Name: "Finisher, propeller, ships' propeller mfr"
  },
  {
    Id: 23326,
    Code: '8120',
    Name: 'Finisher, satin, metal trades'
  },
  {
    Id: 23327,
    Code: '8120',
    Name: "Finisher, smith's"
  },
  {
    Id: 23328,
    Code: '8120',
    Name: 'Finisher, brass foundry'
  },
  {
    Id: 23329,
    Code: '8120',
    Name: 'Finisher, cock founding'
  },
  {
    Id: 23330,
    Code: '8120',
    Name: 'Finisher, foundry'
  },
  {
    Id: 23331,
    Code: '8120',
    Name: 'Finisher, metal fastening mfr'
  },
  {
    Id: 23332,
    Code: '8120',
    Name: 'Finner'
  },
  {
    Id: 23333,
    Code: '8120',
    Name: 'Flanger, sheet metal working'
  },
  {
    Id: 23334,
    Code: '8120',
    Name: 'Flanger, tin box mfr'
  },
  {
    Id: 23335,
    Code: '8120',
    Name: 'Flattener, metal trades'
  },
  {
    Id: 23336,
    Code: '8120',
    Name: 'Floater, tube'
  },
  {
    Id: 23337,
    Code: '8120',
    Name: 'Floater, metal trades'
  },
  {
    Id: 23338,
    Code: '8120',
    Name: 'Fluter, drill'
  },
  {
    Id: 23339,
    Code: '8120',
    Name: 'Fluter, metal'
  },
  {
    Id: 23340,
    Code: '8120',
    Name: 'Folder, box, tin box mfr'
  },
  {
    Id: 23341,
    Code: '8120',
    Name: 'Folder, drum, keg mfr'
  },
  {
    Id: 23342,
    Code: '8120',
    Name: 'Fraiser'
  },
  {
    Id: 23343,
    Code: '8120',
    Name: 'Frazer, metal trades'
  },
  {
    Id: 23344,
    Code: '8120',
    Name: 'Glazer, cutlery'
  },
  {
    Id: 23345,
    Code: '8120',
    Name: 'Glazer, metal trades'
  },
  {
    Id: 23346,
    Code: '8120',
    Name: 'Grinder, ball, ball bearing'
  },
  {
    Id: 23347,
    Code: '8120',
    Name: 'Grinder, cast, rough'
  },
  {
    Id: 23348,
    Code: '8120',
    Name: 'Grinder, castings'
  },
  {
    Id: 23349,
    Code: '8120',
    Name: 'Grinder, emery, steelworks'
  },
  {
    Id: 23350,
    Code: '8120',
    Name: 'Grinder, foundry'
  },
  {
    Id: 23351,
    Code: '8120',
    Name: 'Grinder, precious metal, plate mfr'
  },
  {
    Id: 23352,
    Code: '8120',
    Name: 'Groover, metal trades'
  },
  {
    Id: 23353,
    Code: '8120',
    Name: 'Hand, capstan, brass'
  },
  {
    Id: 23354,
    Code: '8120',
    Name: 'Hand, lathe'
  },
  {
    Id: 23355,
    Code: '8120',
    Name: 'Hand, turret'
  },
  {
    Id: 23356,
    Code: '8120',
    Name: 'Holer, flyer'
  },
  {
    Id: 23357,
    Code: '8120',
    Name: 'Honer'
  },
  {
    Id: 23358,
    Code: '8120',
    Name: 'Hydro-blaster'
  },
  {
    Id: 23359,
    Code: '8120',
    Name: 'Kicker, metal stamping'
  },
  {
    Id: 23360,
    Code: '8120',
    Name: 'Lapper, barrel, gun'
  },
  {
    Id: 23361,
    Code: '8120',
    Name: 'Lapper, gear'
  },
  {
    Id: 23362,
    Code: '8120',
    Name: "Lapper, jeweller's"
  },
  {
    Id: 23363,
    Code: '8120',
    Name: 'Lapper, metal trades'
  },
  {
    Id: 23364,
    Code: '8120',
    Name: 'Latcher, needle mfr'
  },
  {
    Id: 23365,
    Code: '8120',
    Name: 'Linisher'
  },
  {
    Id: 23366,
    Code: '8120',
    Name: 'Machinist, action'
  },
  {
    Id: 23367,
    Code: '8120',
    Name: 'Machinist, anocut'
  },
  {
    Id: 23368,
    Code: '8120',
    Name: 'Machinist, automatic'
  },
  {
    Id: 23369,
    Code: '8120',
    Name: 'Machinist, bearing, ball'
  },
  {
    Id: 23370,
    Code: '8120',
    Name: 'Machinist, bending, press'
  },
  {
    Id: 23371,
    Code: '8120',
    Name: 'Machinist, blasting, vapour'
  },
  {
    Id: 23372,
    Code: '8120',
    Name: 'Machinist, box, axle'
  },
  {
    Id: 23373,
    Code: '8120',
    Name: 'Machinist, brass'
  },
  {
    Id: 23374,
    Code: '8120',
    Name: 'Machinist, broaching'
  },
  {
    Id: 23375,
    Code: '8120',
    Name: 'Machinist, buffing, metal trades'
  },
  {
    Id: 23376,
    Code: '8120',
    Name: 'Machinist, cap, bottle'
  },
  {
    Id: 23377,
    Code: '8120',
    Name: 'Machinist, capstan'
  },
  {
    Id: 23378,
    Code: '8120',
    Name: 'Machinist, capsule, metal'
  },
  {
    Id: 23379,
    Code: '8120',
    Name: 'Machinist, centering'
  },
  {
    Id: 23380,
    Code: '8120',
    Name: 'Machinist, copying'
  },
  {
    Id: 23381,
    Code: '8120',
    Name: 'Machinist, cutting, gear'
  },
  {
    Id: 23382,
    Code: '8120',
    Name: 'Machinist, cycle'
  },
  {
    Id: 23383,
    Code: '8120',
    Name: 'Machinist, dressing, metal trades'
  },
  {
    Id: 23384,
    Code: '8120',
    Name: 'Machinist, drilling, metal trades'
  },
  {
    Id: 23385,
    Code: '8120',
    Name: "Machinist, engineer's"
  },
  {
    Id: 23386,
    Code: '8120',
    Name: 'Machinist, fettling, metal trades'
  },
  {
    Id: 23387,
    Code: '8120',
    Name: 'Machinist, flanging'
  },
  {
    Id: 23388,
    Code: '8120',
    Name: 'Machinist, grinding, shaft'
  },
  {
    Id: 23389,
    Code: '8120',
    Name: 'Machinist, hand, metal trades'
  },
  {
    Id: 23390,
    Code: '8120',
    Name: 'Machinist, hobbing'
  },
  {
    Id: 23391,
    Code: '8120',
    Name: 'Machinist, honing'
  },
  {
    Id: 23392,
    Code: '8120',
    Name: 'Machinist, lapping, metal trades'
  },
  {
    Id: 23393,
    Code: '8120',
    Name: 'Machinist, making, chain, cycle chains'
  },
  {
    Id: 23394,
    Code: '8120',
    Name: 'Machinist, metal'
  },
  {
    Id: 23395,
    Code: '8120',
    Name: 'Machinist, mill, boring'
  },
  {
    Id: 23396,
    Code: '8120',
    Name: 'Machinist, nosing'
  },
  {
    Id: 23397,
    Code: '8120',
    Name: 'Machinist, planing, metal trades'
  },
  {
    Id: 23398,
    Code: '8120',
    Name: 'Machinist, polishing, metal trades'
  },
  {
    Id: 23399,
    Code: '8120',
    Name: 'Machinist, press, metal trades'
  },
  {
    Id: 23400,
    Code: '8120',
    Name: 'Machinist, profiling'
  },
  {
    Id: 23401,
    Code: '8120',
    Name: 'Machinist, punching, metal trades'
  },
  {
    Id: 23402,
    Code: '8120',
    Name: 'Machinist, reamering'
  },
  {
    Id: 23403,
    Code: '8120',
    Name: 'Machinist, room, tool'
  },
  {
    Id: 23404,
    Code: '8120',
    Name: 'Machinist, router, metal trades'
  },
  {
    Id: 23405,
    Code: '8120',
    Name: 'Machinist, sanding, metal'
  },
  {
    Id: 23406,
    Code: '8120',
    Name: 'Machinist, screw, automatic'
  },
  {
    Id: 23407,
    Code: '8120',
    Name: 'Machinist, screwer'
  },
  {
    Id: 23408,
    Code: '8120',
    Name: 'Machinist, screwing'
  },
  {
    Id: 23409,
    Code: '8120',
    Name: 'Machinist, sealing, metal, automatic'
  },
  {
    Id: 23410,
    Code: '8120',
    Name: 'Machinist, seaming, metal trades'
  },
  {
    Id: 23411,
    Code: '8120',
    Name: 'Machinist, shaper'
  },
  {
    Id: 23412,
    Code: '8120',
    Name: 'Machinist, shaping, metal trades'
  },
  {
    Id: 23413,
    Code: '8120',
    Name: 'Machinist, sinking, die'
  },
  {
    Id: 23414,
    Code: '8120',
    Name: 'Machinist, slotter'
  },
  {
    Id: 23415,
    Code: '8120',
    Name: 'Machinist, slotting'
  },
  {
    Id: 23416,
    Code: '8120',
    Name: 'Machinist, stamping, metal trades'
  },
  {
    Id: 23417,
    Code: '8120',
    Name: 'Machinist, tapping'
  },
  {
    Id: 23418,
    Code: '8120',
    Name: 'Machinist, textile, textile machinery mfr'
  },
  {
    Id: 23419,
    Code: '8120',
    Name: 'Machinist, tool'
  },
  {
    Id: 23420,
    Code: '8120',
    Name: 'Machinist, weaving, wire'
  },
  {
    Id: 23421,
    Code: '8120',
    Name: 'Machinist, window, metal'
  },
  {
    Id: 23422,
    Code: '8120',
    Name: 'Machinist, atomic energy establishment'
  },
  {
    Id: 23423,
    Code: '8120',
    Name: 'Machinist, coal mine: above ground'
  },
  {
    Id: 23424,
    Code: '8120',
    Name: 'Machinist, nos, engineering'
  },
  {
    Id: 23425,
    Code: '8120',
    Name: 'Machinist, garage'
  },
  {
    Id: 23426,
    Code: '8120',
    Name: 'Machinist, metal trades'
  },
  {
    Id: 23427,
    Code: '8120',
    Name: 'Machinist, railway workshops'
  },
  {
    Id: 23428,
    Code: '8120',
    Name: 'Machinist, Royal Mint'
  },
  {
    Id: 23429,
    Code: '8120',
    Name: 'Maker, bar, steel, textile machinery mfr'
  },
  {
    Id: 23430,
    Code: '8120',
    Name: 'Maker, blade, razor'
  },
  {
    Id: 23431,
    Code: '8120',
    Name: 'Maker, bolt, clock mfr'
  },
  {
    Id: 23432,
    Code: '8120',
    Name: 'Maker, bridge'
  },
  {
    Id: 23433,
    Code: '8120',
    Name: 'Maker, can, metal'
  },
  {
    Id: 23434,
    Code: '8120',
    Name: 'Maker, cap, butt, fishing rods'
  },
  {
    Id: 23435,
    Code: '8120',
    Name: 'Maker, cap, electric lamp mfr'
  },
  {
    Id: 23436,
    Code: '8120',
    Name: 'Maker, case, spring, small arms'
  },
  {
    Id: 23437,
    Code: '8120',
    Name: 'Maker, clip, wire'
  },
  {
    Id: 23438,
    Code: '8120',
    Name: 'Maker, coin'
  },
  {
    Id: 23439,
    Code: '8120',
    Name: 'Maker, dab'
  },
  {
    Id: 23440,
    Code: '8120',
    Name: 'Maker, ferrule'
  },
  {
    Id: 23441,
    Code: '8120',
    Name: 'Maker, guard, fork'
  },
  {
    Id: 23442,
    Code: '8120',
    Name: 'Maker, key, clock mfr'
  },
  {
    Id: 23443,
    Code: '8120',
    Name: 'Maker, key'
  },
  {
    Id: 23444,
    Code: '8120',
    Name: 'Maker, nail'
  },
  {
    Id: 23445,
    Code: '8120',
    Name: 'Maker, nut'
  },
  {
    Id: 23446,
    Code: '8120',
    Name: 'Maker, roller, textile machinery mfr'
  },
  {
    Id: 23447,
    Code: '8120',
    Name: 'Maker, screen, malt'
  },
  {
    Id: 23448,
    Code: '8120',
    Name: 'Maker, sink, metal'
  },
  {
    Id: 23449,
    Code: '8120',
    Name: 'Maker, sinker'
  },
  {
    Id: 23450,
    Code: '8120',
    Name: 'Maker, spring, carriage'
  },
  {
    Id: 23451,
    Code: '8120',
    Name: 'Maker, spring, flat'
  },
  {
    Id: 23452,
    Code: '8120',
    Name: 'Maker, spring, lock'
  },
  {
    Id: 23453,
    Code: '8120',
    Name: "Maker, stamp, bleacher's, metal"
  },
  {
    Id: 23454,
    Code: '8120',
    Name: 'Maker, weight'
  },
  {
    Id: 23455,
    Code: '8120',
    Name: 'Man, filter, metal trades'
  },
  {
    Id: 23456,
    Code: '8120',
    Name: 'Man, lathe'
  },
  {
    Id: 23457,
    Code: '8120',
    Name: 'Man, vice'
  },
  {
    Id: 23458,
    Code: '8120',
    Name: 'Marker, fish hook mfr'
  },
  {
    Id: 23459,
    Code: '8120',
    Name: 'Marker, metal cutlery mfr'
  },
  {
    Id: 23460,
    Code: '8120',
    Name: 'Marker, needle mfr'
  },
  {
    Id: 23461,
    Code: '8120',
    Name: 'Masker, files'
  },
  {
    Id: 23462,
    Code: '8120',
    Name: "Mate, stamper's"
  },
  {
    Id: 23463,
    Code: '8120',
    Name: 'Medallist'
  },
  {
    Id: 23464,
    Code: '8120',
    Name: 'Modeller, art metal work mfr'
  },
  {
    Id: 23465,
    Code: '8120',
    Name: 'Mopper, metal trades'
  },
  {
    Id: 23466,
    Code: '8120',
    Name: 'Notcher, tin box mfr'
  },
  {
    Id: 23467,
    Code: '8120',
    Name: 'Operative, can, tin'
  },
  {
    Id: 23468,
    Code: '8120',
    Name: 'Operator, auto'
  },
  {
    Id: 23469,
    Code: '8120',
    Name: 'Operator, blast, sand'
  },
  {
    Id: 23470,
    Code: '8120',
    Name: 'Operator, blast, shot'
  },
  {
    Id: 23471,
    Code: '8120',
    Name: 'Operator, bullard'
  },
  {
    Id: 23472,
    Code: '8120',
    Name: 'Operator, capstan'
  },
  {
    Id: 23473,
    Code: '8120',
    Name: 'Operator, drill'
  },
  {
    Id: 23474,
    Code: '8120',
    Name: 'Operator, embosser, engineering'
  },
  {
    Id: 23475,
    Code: '8120',
    Name: 'Operator, grinder'
  },
  {
    Id: 23476,
    Code: '8120',
    Name: 'Operator, lathe, metal trades'
  },
  {
    Id: 23477,
    Code: '8120',
    Name: 'Operator, mill, boring'
  },
  {
    Id: 23478,
    Code: '8120',
    Name: 'Operator, mill, plano'
  },
  {
    Id: 23479,
    Code: '8120',
    Name: 'Operator, mill, steel foundry'
  },
  {
    Id: 23480,
    Code: '8120',
    Name: 'Operator, milling, metal trades'
  },
  {
    Id: 23481,
    Code: '8120',
    Name: 'Operator, press, hand, jewellery, plate mfr'
  },
  {
    Id: 23482,
    Code: '8120',
    Name: 'Operator, profile'
  },
  {
    Id: 23483,
    Code: '8120',
    Name: 'Operator, room, tool'
  },
  {
    Id: 23484,
    Code: '8120',
    Name: 'Operator, router'
  },
  {
    Id: 23485,
    Code: '8120',
    Name: 'Operator, tapping'
  },
  {
    Id: 23486,
    Code: '8120',
    Name: 'Operator, tool, machine'
  },
  {
    Id: 23487,
    Code: '8120',
    Name: 'Operator, turret'
  },
  {
    Id: 23488,
    Code: '8120',
    Name: 'Operator, wheelabrator'
  },
  {
    Id: 23489,
    Code: '8120',
    Name: 'Operator, Royal Mint'
  },
  {
    Id: 23490,
    Code: '8120',
    Name: 'Perforator, metal trades'
  },
  {
    Id: 23491,
    Code: '8120',
    Name: 'Piercer, pen nib mfr'
  },
  {
    Id: 23492,
    Code: '8120',
    Name: 'Pointer, hook, fish'
  },
  {
    Id: 23493,
    Code: '8120',
    Name: 'Pointer, needle mfr'
  },
  {
    Id: 23494,
    Code: '8120',
    Name: 'Polisher, barrel, gun'
  },
  {
    Id: 23495,
    Code: '8120',
    Name: 'Polisher, bobbin'
  },
  {
    Id: 23496,
    Code: '8120',
    Name: 'Polisher, brass'
  },
  {
    Id: 23497,
    Code: '8120',
    Name: 'Polisher, cutlery'
  },
  {
    Id: 23498,
    Code: '8120',
    Name: 'Polisher, cycle'
  },
  {
    Id: 23499,
    Code: '8120',
    Name: 'Polisher, die'
  },
  {
    Id: 23500,
    Code: '8120',
    Name: 'Polisher, emery'
  },
  {
    Id: 23501,
    Code: '8120',
    Name: 'Polisher, frame'
  },
  {
    Id: 23502,
    Code: '8120',
    Name: 'Polisher, gold'
  },
  {
    Id: 23503,
    Code: '8120',
    Name: 'Polisher, hame'
  },
  {
    Id: 23504,
    Code: '8120',
    Name: 'Polisher, jewellery'
  },
  {
    Id: 23505,
    Code: '8120',
    Name: 'Polisher, lathe, metal trades'
  },
  {
    Id: 23506,
    Code: '8120',
    Name: 'Polisher, lime'
  },
  {
    Id: 23507,
    Code: '8120',
    Name: 'Polisher, metal'
  },
  {
    Id: 23508,
    Code: '8120',
    Name: 'Polisher, mirror, cutlery mfr'
  },
  {
    Id: 23509,
    Code: '8120',
    Name: 'Polisher, mould, metal'
  },
  {
    Id: 23510,
    Code: '8120',
    Name: 'Polisher, plate, precious metal, plate mfr'
  },
  {
    Id: 23511,
    Code: '8120',
    Name: "Polisher, plater's"
  },
  {
    Id: 23512,
    Code: '8120',
    Name: 'Polisher, roll, tinplate mfr'
  },
  {
    Id: 23513,
    Code: '8120',
    Name: 'Polisher, roller'
  },
  {
    Id: 23514,
    Code: '8120',
    Name: 'Polisher, sand'
  },
  {
    Id: 23515,
    Code: '8120',
    Name: 'Polisher, silver'
  },
  {
    Id: 23516,
    Code: '8120',
    Name: 'Polisher, spoon and fork'
  },
  {
    Id: 23517,
    Code: '8120',
    Name: 'Polisher, steel, stainless'
  },
  {
    Id: 23518,
    Code: '8120',
    Name: 'Polisher, tin'
  },
  {
    Id: 23519,
    Code: '8120',
    Name: 'Polisher, brass musical instruments mfr'
  },
  {
    Id: 23520,
    Code: '8120',
    Name: 'Polisher, metal trades'
  },
  {
    Id: 23521,
    Code: '8120',
    Name: 'Presser, coining'
  },
  {
    Id: 23522,
    Code: '8120',
    Name: 'Presser, component'
  },
  {
    Id: 23523,
    Code: '8120',
    Name: 'Presser, draw'
  },
  {
    Id: 23524,
    Code: '8120',
    Name: 'Presser, fly, cutlery mfr'
  },
  {
    Id: 23525,
    Code: '8120',
    Name: 'Presser, hand, metal trades'
  },
  {
    Id: 23526,
    Code: '8120',
    Name: 'Presser, hydraulic, metal trades'
  },
  {
    Id: 23527,
    Code: '8120',
    Name: 'Presser, machine, metal trades'
  },
  {
    Id: 23528,
    Code: '8120',
    Name: 'Presser, power, metal trades'
  },
  {
    Id: 23529,
    Code: '8120',
    Name: 'Presser, ring'
  },
  {
    Id: 23530,
    Code: '8120',
    Name: 'Presser, stamping'
  },
  {
    Id: 23531,
    Code: '8120',
    Name: 'Presser, steam, metal'
  },
  {
    Id: 23532,
    Code: '8120',
    Name: 'Presser, tool'
  },
  {
    Id: 23533,
    Code: '8120',
    Name: 'Presser, tube'
  },
  {
    Id: 23534,
    Code: '8120',
    Name: 'Presser, metal trades'
  },
  {
    Id: 23535,
    Code: '8120',
    Name: 'Presser'
  },
  {
    Id: 23536,
    Code: '8120',
    Name: 'Presser-out, textile machinery mfr'
  },
  {
    Id: 23537,
    Code: '8120',
    Name: 'Profiler, metal'
  },
  {
    Id: 23538,
    Code: '8120',
    Name: 'Pronger, fork mfr'
  },
  {
    Id: 23539,
    Code: '8120',
    Name: 'Puller, stamp'
  },
  {
    Id: 23540,
    Code: '8120',
    Name: 'Pumicer, precious metal, plate'
  },
  {
    Id: 23541,
    Code: '8120',
    Name: 'Puncher, bar, steel'
  },
  {
    Id: 23542,
    Code: '8120',
    Name: 'Puncher, eye, needles'
  },
  {
    Id: 23543,
    Code: '8120',
    Name: 'Puncher, fishplate'
  },
  {
    Id: 23544,
    Code: '8120',
    Name: 'Puncher, rail'
  },
  {
    Id: 23545,
    Code: '8120',
    Name: 'Puncher, tip, shoe'
  },
  {
    Id: 23546,
    Code: '8120',
    Name: 'Puncher, metal trades'
  },
  {
    Id: 23547,
    Code: '8120',
    Name: 'Puncher and shearer'
  },
  {
    Id: 23548,
    Code: '8120',
    Name: 'Reamer, metal'
  },
  {
    Id: 23549,
    Code: '8120',
    Name: 'Reamerer, barrel'
  },
  {
    Id: 23550,
    Code: '8120',
    Name: 'Reamerer, metal trades'
  },
  {
    Id: 23551,
    Code: '8120',
    Name: 'Rifler, barrel'
  },
  {
    Id: 23552,
    Code: '8120',
    Name: 'Roller, thread, screws'
  },
  {
    Id: 23553,
    Code: '8120',
    Name: 'Rougher, foundry'
  },
  {
    Id: 23554,
    Code: '8120',
    Name: 'Rubber-down, jewellery, plate mfr'
  },
  {
    Id: 23555,
    Code: '8120',
    Name: 'Rubber-off, foundry'
  },
  {
    Id: 23556,
    Code: '8120',
    Name: 'Rumbler, metal'
  },
  {
    Id: 23557,
    Code: '8120',
    Name: 'Sander, metal trades'
  },
  {
    Id: 23558,
    Code: '8120',
    Name: 'Scourer, foundry'
  },
  {
    Id: 23559,
    Code: '8120',
    Name: 'Scraper, metal'
  },
  {
    Id: 23560,
    Code: '8120',
    Name: 'Scratcher, metal trades'
  },
  {
    Id: 23561,
    Code: '8120',
    Name: 'Screwer, tube'
  },
  {
    Id: 23562,
    Code: '8120',
    Name: 'Screwer, metal trades'
  },
  {
    Id: 23563,
    Code: '8120',
    Name: 'Scrubber, chair'
  },
  {
    Id: 23564,
    Code: '8120',
    Name: 'Seamer, hollow-ware'
  },
  {
    Id: 23565,
    Code: '8120',
    Name: 'Seamer, metal trades'
  },
  {
    Id: 23566,
    Code: '8120',
    Name: 'Seater, key'
  },
  {
    Id: 23567,
    Code: '8120',
    Name: 'Settler, fish hook mfr'
  },
  {
    Id: 23568,
    Code: '8120',
    Name: 'Shaper, blades, airscrew, metal'
  },
  {
    Id: 23569,
    Code: '8120',
    Name: 'Shaper, die'
  },
  {
    Id: 23570,
    Code: '8120',
    Name: 'Shaper, metal trades'
  },
  {
    Id: 23571,
    Code: '8120',
    Name: 'Sharpener, pick'
  },
  {
    Id: 23572,
    Code: '8120',
    Name: 'Sharpener, tool'
  },
  {
    Id: 23573,
    Code: '8120',
    Name: 'Shaver, gear'
  },
  {
    Id: 23574,
    Code: '8120',
    Name: 'Sinker, counter'
  },
  {
    Id: 23575,
    Code: '8120',
    Name: 'Sleever, electrical goods mfr'
  },
  {
    Id: 23576,
    Code: '8120',
    Name: 'Slotter, frame'
  },
  {
    Id: 23577,
    Code: '8120',
    Name: 'Slotter, metal trades'
  },
  {
    Id: 23578,
    Code: '8120',
    Name: 'Slotter, needle mfr'
  },
  {
    Id: 23579,
    Code: '8120',
    Name: 'Stamper, brass'
  },
  {
    Id: 23580,
    Code: '8120',
    Name: 'Stamper, cold'
  },
  {
    Id: 23581,
    Code: '8120',
    Name: 'Stamper, die'
  },
  {
    Id: 23582,
    Code: '8120',
    Name: 'Stamper, drop, sheet metal goods mfr'
  },
  {
    Id: 23583,
    Code: '8120',
    Name: 'Stamper, gold, jewellery, plate mfr'
  },
  {
    Id: 23584,
    Code: '8120',
    Name: 'Stamper, hollow-ware'
  },
  {
    Id: 23585,
    Code: '8120',
    Name: 'Stamper, metal'
  },
  {
    Id: 23586,
    Code: '8120',
    Name: 'Stamper, silver'
  },
  {
    Id: 23587,
    Code: '8120',
    Name: 'Stamper, metal trades'
  },
  {
    Id: 23588,
    Code: '8120',
    Name: 'Stripper, metal trades'
  },
  {
    Id: 23589,
    Code: '8120',
    Name: 'Tacker, tack mfr'
  },
  {
    Id: 23590,
    Code: '8120',
    Name: 'Taperer, metal trades'
  },
  {
    Id: 23591,
    Code: '8120',
    Name: 'Tapper, nut and socket, tube fittings mfr'
  },
  {
    Id: 23592,
    Code: '8120',
    Name: 'Tapper, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 23593,
    Code: '8120',
    Name: 'Threader, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 23594,
    Code: '8120',
    Name: 'Tipper, metal'
  },
  {
    Id: 23595,
    Code: '8120',
    Name: 'Tracker, ball bearing mfr'
  },
  {
    Id: 23596,
    Code: '8120',
    Name: 'Trimmer, blade, aircraft mfr'
  },
  {
    Id: 23597,
    Code: '8120',
    Name: 'Trimmer, box, steelworks'
  },
  {
    Id: 23598,
    Code: '8120',
    Name: 'Trimmer, needle'
  },
  {
    Id: 23599,
    Code: '8120',
    Name: 'Trimmer, steel'
  },
  {
    Id: 23600,
    Code: '8120',
    Name: 'Trimmer, metal fastening mfr'
  },
  {
    Id: 23601,
    Code: '8120',
    Name: 'Trimmer, metal trades'
  },
  {
    Id: 23602,
    Code: '8120',
    Name: 'Trimmer, needle mfr'
  },
  {
    Id: 23603,
    Code: '8120',
    Name: 'Trimmer, tin box mfr'
  },
  {
    Id: 23604,
    Code: '8120',
    Name: 'Tumbler, metal trades'
  },
  {
    Id: 23605,
    Code: '8120',
    Name: 'Turner, brass'
  },
  {
    Id: 23606,
    Code: '8120',
    Name: 'Turner, optical'
  },
  {
    Id: 23607,
    Code: '8120',
    Name: 'Turner, shell'
  },
  {
    Id: 23608,
    Code: '8120',
    Name: 'Twister, wire, hat'
  },
  {
    Id: 23609,
    Code: '8120',
    Name: 'Weaver, cloth, wire'
  },
  {
    Id: 23610,
    Code: '8120',
    Name: 'Weaver, wire goods mfr'
  },
  {
    Id: 23611,
    Code: '8120',
    Name: 'Wheelabrator'
  },
  {
    Id: 23612,
    Code: '8120',
    Name: 'Wirer, box'
  },
  {
    Id: 23613,
    Code: '8120',
    Name: 'Worker, box, metal'
  },
  {
    Id: 23614,
    Code: '8120',
    Name: 'Worker, brass'
  },
  {
    Id: 23615,
    Code: '8120',
    Name: 'Worker, bronze, architectural'
  },
  {
    Id: 23616,
    Code: '8120',
    Name: 'Worker, bronze, ornamental'
  },
  {
    Id: 23617,
    Code: '8120',
    Name: 'Worker, iron, art'
  },
  {
    Id: 23618,
    Code: '8120',
    Name: 'Worker, lathe'
  },
  {
    Id: 23619,
    Code: '8120',
    Name: 'Worker, metal, architectural'
  },
  {
    Id: 23620,
    Code: '8120',
    Name: 'Worker, metal, art'
  },
  {
    Id: 23621,
    Code: '8120',
    Name: 'Worker, metal, ornamental'
  },
  {
    Id: 23622,
    Code: '8120',
    Name: 'Worker, press, metal trades'
  },
  {
    Id: 23623,
    Code: '8120',
    Name: 'Worker, process, metal trades'
  },
  {
    Id: 23624,
    Code: '8120',
    Name: 'Worker, spoon and fork'
  },
  {
    Id: 23625,
    Code: '8120',
    Name: 'Worker, tin, fancy'
  },
  {
    Id: 23626,
    Code: '8131',
    Name: 'Adjuster, envelope'
  },
  {
    Id: 23627,
    Code: '8131',
    Name: 'Adjuster, machine, paper goods mfr'
  },
  {
    Id: 23628,
    Code: '8131',
    Name: "Assistant, beater's"
  },
  {
    Id: 23629,
    Code: '8131',
    Name: "Assistant, beaterman's"
  },
  {
    Id: 23630,
    Code: '8131',
    Name: 'Assistant, calender, paper mfr'
  },
  {
    Id: 23631,
    Code: '8131',
    Name: "Assistant, calenderman's, paper mfr"
  },
  {
    Id: 23632,
    Code: '8131',
    Name: "Assistant, cutter's, paper"
  },
  {
    Id: 23633,
    Code: '8131',
    Name: "Assistant, cutter's, paper mfr"
  },
  {
    Id: 23634,
    Code: '8131',
    Name: "Assistant, cutter's, paper pattern mfr"
  },
  {
    Id: 23635,
    Code: '8131',
    Name: "Assistant, dryerman's, paper"
  },
  {
    Id: 23636,
    Code: '8131',
    Name: 'Assistant, hydropulper'
  },
  {
    Id: 23637,
    Code: '8131',
    Name: 'Assistant, laminating, paper mfr'
  },
  {
    Id: 23638,
    Code: '8131',
    Name: "Assistant, man's, machine, paper mfr"
  },
  {
    Id: 23639,
    Code: '8131',
    Name: "Assistant, reelerman's, paper mfr"
  },
  {
    Id: 23640,
    Code: '8131',
    Name: "Assistant, slitter's, films"
  },
  {
    Id: 23641,
    Code: '8131',
    Name: "Assistant, tender's, machine, pasteboard"
  },
  {
    Id: 23642,
    Code: '8131',
    Name: 'Attendant, calender, paper'
  },
  {
    Id: 23643,
    Code: '8131',
    Name: 'Attendant, conditioner, paper mfr'
  },
  {
    Id: 23644,
    Code: '8131',
    Name: 'Attendant, creel, sisal'
  },
  {
    Id: 23645,
    Code: '8131',
    Name: 'Attendant, felt, paper mfr'
  },
  {
    Id: 23646,
    Code: '8131',
    Name: 'Attendant, grainer, paper mfr'
  },
  {
    Id: 23647,
    Code: '8131',
    Name: 'Attendant, humidifier'
  },
  {
    Id: 23648,
    Code: '8131',
    Name: 'Attendant, mill, saw'
  },
  {
    Id: 23649,
    Code: '8131',
    Name: 'Attendant, mill, wood'
  },
  {
    Id: 23650,
    Code: '8131',
    Name: 'Attendant, thickener'
  },
  {
    Id: 23651,
    Code: '8131',
    Name: 'Backer, paper, stencil'
  },
  {
    Id: 23652,
    Code: '8131',
    Name: 'Backer, paper mfr'
  },
  {
    Id: 23653,
    Code: '8131',
    Name: 'Bander, cardboard box mfr'
  },
  {
    Id: 23654,
    Code: '8131',
    Name: 'Beater, paper mill'
  },
  {
    Id: 23655,
    Code: '8131',
    Name: 'Beaterman, paper mfr'
  },
  {
    Id: 23656,
    Code: '8131',
    Name: 'Bender, cardboard box mfr'
  },
  {
    Id: 23657,
    Code: '8131',
    Name: 'Bender and slotter, cardboard'
  },
  {
    Id: 23658,
    Code: '8131',
    Name: 'Binder, cardboard box mfr'
  },
  {
    Id: 23659,
    Code: '8131',
    Name: 'Bitter, cardboard box mfr'
  },
  {
    Id: 23660,
    Code: '8131',
    Name: 'Bleacher, paper'
  },
  {
    Id: 23661,
    Code: '8131',
    Name: 'Bleacher, wood pulp'
  },
  {
    Id: 23662,
    Code: '8131',
    Name: 'Blocker, wood heel mfr'
  },
  {
    Id: 23663,
    Code: '8131',
    Name: 'Boiler, rag'
  },
  {
    Id: 23664,
    Code: '8131',
    Name: 'Boiler, woodpulp'
  },
  {
    Id: 23665,
    Code: '8131',
    Name: 'Boiler, paper mfr'
  },
  {
    Id: 23666,
    Code: '8131',
    Name: 'Boilerman, paper mfr'
  },
  {
    Id: 23667,
    Code: '8131',
    Name: 'Borer, wood'
  },
  {
    Id: 23668,
    Code: '8131',
    Name: 'Borer, woodwind instruments mfr'
  },
  {
    Id: 23669,
    Code: '8131',
    Name: 'Borer and cutter, cross'
  },
  {
    Id: 23670,
    Code: '8131',
    Name: 'Bottomer, cardboard box mfr'
  },
  {
    Id: 23671,
    Code: '8131',
    Name: 'Brander'
  },
  {
    Id: 23672,
    Code: '8131',
    Name: 'Breaker, woodpulp'
  },
  {
    Id: 23673,
    Code: '8131',
    Name: 'Breaker, paper mfr'
  },
  {
    Id: 23674,
    Code: '8131',
    Name: 'Breakerman, rag'
  },
  {
    Id: 23675,
    Code: '8131',
    Name: 'Breakerman, paper mfr'
  },
  {
    Id: 23676,
    Code: '8131',
    Name: 'Breaster, heel, wood'
  },
  {
    Id: 23677,
    Code: '8131',
    Name: 'Brusher, pigment'
  },
  {
    Id: 23678,
    Code: '8131',
    Name: 'Brusher, paper mfr'
  },
  {
    Id: 23679,
    Code: '8131',
    Name: 'Brusher, wallpaper mfr'
  },
  {
    Id: 23680,
    Code: '8131',
    Name: 'Builder, drum, cables'
  },
  {
    Id: 23681,
    Code: '8131',
    Name: 'Builder, vat'
  },
  {
    Id: 23682,
    Code: '8131',
    Name: 'Calenderer, paper mfr'
  },
  {
    Id: 23683,
    Code: '8131',
    Name: 'Calenderman, super'
  },
  {
    Id: 23684,
    Code: '8131',
    Name: 'Calenderman, paper mfr'
  },
  {
    Id: 23685,
    Code: '8131',
    Name: 'Catcher, sheet, paper'
  },
  {
    Id: 23686,
    Code: '8131',
    Name: 'Catcher, paper mfr'
  },
  {
    Id: 23687,
    Code: '8131',
    Name: 'Cementer, paper'
  },
  {
    Id: 23688,
    Code: '8131',
    Name: 'Changer, roller'
  },
  {
    Id: 23689,
    Code: '8131',
    Name: 'Chipper, chipboard mfr'
  },
  {
    Id: 23690,
    Code: '8131',
    Name: 'Chopper, wood, sawmilling'
  },
  {
    Id: 23691,
    Code: '8131',
    Name: 'Chopperman, paper mfr'
  },
  {
    Id: 23692,
    Code: '8131',
    Name: 'Cleaner, wood'
  },
  {
    Id: 23693,
    Code: '8131',
    Name: 'Clipper, veneer'
  },
  {
    Id: 23694,
    Code: '8131',
    Name: 'Coater, paper'
  },
  {
    Id: 23695,
    Code: '8131',
    Name: 'Coater, stencil paper mfr'
  },
  {
    Id: 23696,
    Code: '8131',
    Name: 'Combiner, paper mfr'
  },
  {
    Id: 23697,
    Code: '8131',
    Name: 'Concaver, footwear mfr'
  },
  {
    Id: 23698,
    Code: '8131',
    Name: 'Concentratorman, paper mfr'
  },
  {
    Id: 23699,
    Code: '8131',
    Name: 'Conditioner, paper'
  },
  {
    Id: 23700,
    Code: '8131',
    Name: 'Conditioner, paper mfr'
  },
  {
    Id: 23701,
    Code: '8131',
    Name: 'Converter, paper'
  },
  {
    Id: 23702,
    Code: '8131',
    Name: 'Converter, timber'
  },
  {
    Id: 23703,
    Code: '8131',
    Name: 'Copier, pattern, paper'
  },
  {
    Id: 23704,
    Code: '8131',
    Name: 'Corrugator, paper mfr'
  },
  {
    Id: 23705,
    Code: '8131',
    Name: 'Coucher'
  },
  {
    Id: 23706,
    Code: '8131',
    Name: 'Creosoter, timber'
  },
  {
    Id: 23707,
    Code: '8131',
    Name: 'Cutter, belt, abrasives mfr'
  },
  {
    Id: 23708,
    Code: '8131',
    Name: 'Cutter, box, cardboard'
  },
  {
    Id: 23709,
    Code: '8131',
    Name: 'Cutter, card, paper goods mfr'
  },
  {
    Id: 23710,
    Code: '8131',
    Name: 'Cutter, film, photographic film mfr'
  },
  {
    Id: 23711,
    Code: '8131',
    Name: 'Cutter, guillotine, paper goods mfr'
  },
  {
    Id: 23712,
    Code: '8131',
    Name: 'Cutter, guillotine, wood products mfr'
  },
  {
    Id: 23713,
    Code: '8131',
    Name: "Cutter, maker's, box"
  },
  {
    Id: 23714,
    Code: '8131',
    Name: 'Cutter, negative'
  },
  {
    Id: 23715,
    Code: '8131',
    Name: 'Cutter, panel, wood'
  },
  {
    Id: 23716,
    Code: '8131',
    Name: 'Cutter, paper'
  },
  {
    Id: 23717,
    Code: '8131',
    Name: 'Cutter, pattern, paper'
  },
  {
    Id: 23718,
    Code: '8131',
    Name: 'Cutter, press, paper goods mfr'
  },
  {
    Id: 23719,
    Code: '8131',
    Name: 'Cutter, print'
  },
  {
    Id: 23720,
    Code: '8131',
    Name: 'Cutter, rotary, paper goods mfr'
  },
  {
    Id: 23721,
    Code: '8131',
    Name: 'Cutter, sole, clog'
  },
  {
    Id: 23722,
    Code: '8131',
    Name: 'Cutter, stencil, printing'
  },
  {
    Id: 23723,
    Code: '8131',
    Name: 'Cutter, timber'
  },
  {
    Id: 23724,
    Code: '8131',
    Name: 'Cutter, tube, paper'
  },
  {
    Id: 23725,
    Code: '8131',
    Name: 'Cutter, veneer'
  },
  {
    Id: 23726,
    Code: '8131',
    Name: 'Cutter, wood'
  },
  {
    Id: 23727,
    Code: '8131',
    Name: 'Cutter, abrasive paper, cloth mfr'
  },
  {
    Id: 23728,
    Code: '8131',
    Name: 'Cutter, furniture mfr'
  },
  {
    Id: 23729,
    Code: '8131',
    Name: 'Cutter, packing case wood mfr'
  },
  {
    Id: 23730,
    Code: '8131',
    Name: 'Cutter, paper goods mfr'
  },
  {
    Id: 23731,
    Code: '8131',
    Name: 'Cutter, paper mfr'
  },
  {
    Id: 23732,
    Code: '8131',
    Name: 'Cutter, paper pattern mfr'
  },
  {
    Id: 23733,
    Code: '8131',
    Name: 'Cutter, woodworking'
  },
  {
    Id: 23734,
    Code: '8131',
    Name: 'Cutterman, paper mfr'
  },
  {
    Id: 23735,
    Code: '8131',
    Name: 'Cylinderman, paper mfr'
  },
  {
    Id: 23736,
    Code: '8131',
    Name: 'Damperman, paper mfr'
  },
  {
    Id: 23737,
    Code: '8131',
    Name: 'Designer-cutter, paper goods mfr'
  },
  {
    Id: 23738,
    Code: '8131',
    Name: 'Devilman, paper mfr'
  },
  {
    Id: 23739,
    Code: '8131',
    Name: 'Dipper, paper mfr'
  },
  {
    Id: 23740,
    Code: '8131',
    Name: 'Driller, wood'
  },
  {
    Id: 23741,
    Code: '8131',
    Name: 'Dropperman'
  },
  {
    Id: 23742,
    Code: '8131',
    Name: 'Dryer, kiln, wood'
  },
  {
    Id: 23743,
    Code: '8131',
    Name: 'Dryer, veneer'
  },
  {
    Id: 23744,
    Code: '8131',
    Name: 'Dryer, abrasive paper, cloth mfr'
  },
  {
    Id: 23745,
    Code: '8131',
    Name: 'Dryer, paper mfr'
  },
  {
    Id: 23746,
    Code: '8131',
    Name: 'Dryer, vulcanised fibre mfr'
  },
  {
    Id: 23747,
    Code: '8131',
    Name: 'Edgeman'
  },
  {
    Id: 23748,
    Code: '8131',
    Name: 'Facer, wood'
  },
  {
    Id: 23749,
    Code: '8131',
    Name: 'Feeder, card, pasteboard mfr'
  },
  {
    Id: 23750,
    Code: '8131',
    Name: 'Feltman, paper mfr'
  },
  {
    Id: 23751,
    Code: '8131',
    Name: 'Filler-in, paper mfr'
  },
  {
    Id: 23752,
    Code: '8131',
    Name: 'Finisher, paper'
  },
  {
    Id: 23753,
    Code: '8131',
    Name: 'Finisher, plate, paper mfr'
  },
  {
    Id: 23754,
    Code: '8131',
    Name: 'Finisher, wood'
  },
  {
    Id: 23755,
    Code: '8131',
    Name: 'Finisher, paper mfr'
  },
  {
    Id: 23756,
    Code: '8131',
    Name: 'Finisher, stencil paper mfr'
  },
  {
    Id: 23757,
    Code: '8131',
    Name: 'Fireman, retort, charcoal'
  },
  {
    Id: 23758,
    Code: '8131',
    Name: 'Fixer, tape, paper pattern mfr'
  },
  {
    Id: 23759,
    Code: '8131',
    Name: 'Flusher, tobacco pipes'
  },
  {
    Id: 23760,
    Code: '8131',
    Name: 'Folder, envelope'
  },
  {
    Id: 23761,
    Code: '8131',
    Name: 'Folder, map'
  },
  {
    Id: 23762,
    Code: '8131',
    Name: 'Folder, paper'
  },
  {
    Id: 23763,
    Code: '8131',
    Name: 'Framer, rule'
  },
  {
    Id: 23764,
    Code: '8131',
    Name: 'Frazer, tobacco pipe mfr'
  },
  {
    Id: 23765,
    Code: '8131',
    Name: 'Glazer, friction'
  },
  {
    Id: 23766,
    Code: '8131',
    Name: 'Glazer, postcard'
  },
  {
    Id: 23767,
    Code: '8131',
    Name: 'Glazer, paper mfr'
  },
  {
    Id: 23768,
    Code: '8131',
    Name: 'Glazer, wallpaper mfr'
  },
  {
    Id: 23769,
    Code: '8131',
    Name: 'Grinder, logwood'
  },
  {
    Id: 23770,
    Code: '8131',
    Name: 'Grinder, paper'
  },
  {
    Id: 23771,
    Code: '8131',
    Name: 'Grinder, paper mfr'
  },
  {
    Id: 23772,
    Code: '8131',
    Name: 'Grinderman, paper mfr'
  },
  {
    Id: 23773,
    Code: '8131',
    Name: 'Groover, pencil'
  },
  {
    Id: 23774,
    Code: '8131',
    Name: 'Hand, bag, paper goods mfr'
  },
  {
    Id: 23775,
    Code: '8131',
    Name: 'Hand, guillotine, paper goods mfr'
  },
  {
    Id: 23776,
    Code: '8131',
    Name: 'Hand, mill, saw'
  },
  {
    Id: 23777,
    Code: '8131',
    Name: 'Hand, paper, piano hammers'
  },
  {
    Id: 23778,
    Code: '8131',
    Name: 'Hand, spindle'
  },
  {
    Id: 23779,
    Code: '8131',
    Name: 'Hand, tenon'
  },
  {
    Id: 23780,
    Code: '8131',
    Name: 'Handler, bag, paper'
  },
  {
    Id: 23781,
    Code: '8131',
    Name: 'Header-up, cask mfr'
  },
  {
    Id: 23782,
    Code: '8131',
    Name: 'Header-up, wooden cask mfr'
  },
  {
    Id: 23783,
    Code: '8131',
    Name: 'Helper, paper mfr'
  },
  {
    Id: 23784,
    Code: '8131',
    Name: 'Houseman, sand'
  },
  {
    Id: 23785,
    Code: '8131',
    Name: 'Hydrapulper'
  },
  {
    Id: 23786,
    Code: '8131',
    Name: 'Interleaver, paper'
  },
  {
    Id: 23787,
    Code: '8131',
    Name: 'Jogger, paper mfr'
  },
  {
    Id: 23788,
    Code: '8131',
    Name: 'Joiner, machine'
  },
  {
    Id: 23789,
    Code: '8131',
    Name: 'Kollerganger'
  },
  {
    Id: 23790,
    Code: '8131',
    Name: 'Laminator, wood'
  },
  {
    Id: 23791,
    Code: '8131',
    Name: 'Laminator, wood'
  },
  {
    Id: 23792,
    Code: '8131',
    Name: 'Laminator, paper mfr'
  },
  {
    Id: 23793,
    Code: '8131',
    Name: 'Layer, veneer'
  },
  {
    Id: 23794,
    Code: '8131',
    Name: 'Layer, paper mfr'
  },
  {
    Id: 23795,
    Code: '8131',
    Name: 'Layer-on, cardboard box mfr'
  },
  {
    Id: 23796,
    Code: '8131',
    Name: 'Lurrier'
  },
  {
    Id: 23797,
    Code: '8131',
    Name: 'Machinist, bag, carrier'
  },
  {
    Id: 23798,
    Code: '8131',
    Name: 'Machinist, bag, paper'
  },
  {
    Id: 23799,
    Code: '8131',
    Name: 'Machinist, barking'
  },
  {
    Id: 23800,
    Code: '8131',
    Name: 'Machinist, boring, wood'
  },
  {
    Id: 23801,
    Code: '8131',
    Name: 'Machinist, box, paper goods mfr'
  },
  {
    Id: 23802,
    Code: '8131',
    Name: 'Machinist, box and slide, cardboard box mfr'
  },
  {
    Id: 23803,
    Code: '8131',
    Name: 'Machinist, brushing, paper mfr'
  },
  {
    Id: 23804,
    Code: '8131',
    Name: "Machinist, builder's"
  },
  {
    Id: 23805,
    Code: '8131',
    Name: 'Machinist, burnishing'
  },
  {
    Id: 23806,
    Code: '8131',
    Name: 'Machinist, cabinet'
  },
  {
    Id: 23807,
    Code: '8131',
    Name: 'Machinist, coating, paper'
  },
  {
    Id: 23808,
    Code: '8131',
    Name: 'Machinist, coating, carbon paper'
  },
  {
    Id: 23809,
    Code: '8131',
    Name: 'Machinist, combining, paper mfr'
  },
  {
    Id: 23810,
    Code: '8131',
    Name: "Machinist, cooper's"
  },
  {
    Id: 23811,
    Code: '8131',
    Name: 'Machinist, cording, paper goods mfr'
  },
  {
    Id: 23812,
    Code: '8131',
    Name: 'Machinist, corrugating'
  },
  {
    Id: 23813,
    Code: '8131',
    Name: 'Machinist, cutting, cloth, emery'
  },
  {
    Id: 23814,
    Code: '8131',
    Name: 'Machinist, cutting, paper'
  },
  {
    Id: 23815,
    Code: '8131',
    Name: 'Machinist, cutting, tube, cardboard'
  },
  {
    Id: 23816,
    Code: '8131',
    Name: 'Machinist, cutting, wood'
  },
  {
    Id: 23817,
    Code: '8131',
    Name: 'Machinist, cutting, paper'
  },
  {
    Id: 23818,
    Code: '8131',
    Name: 'Machinist, cutting, paper goods mfr'
  },
  {
    Id: 23819,
    Code: '8131',
    Name: 'Machinist, cutting, paper mfr'
  },
  {
    Id: 23820,
    Code: '8131',
    Name: 'Machinist, drilling, wood products mfr'
  },
  {
    Id: 23821,
    Code: '8131',
    Name: 'Machinist, embossing, wood products mfr'
  },
  {
    Id: 23822,
    Code: '8131',
    Name: 'Machinist, envelope'
  },
  {
    Id: 23823,
    Code: '8131',
    Name: 'Machinist, fibre, vulcanised'
  },
  {
    Id: 23824,
    Code: '8131',
    Name: 'Machinist, gluing and winding, tube, cardboard'
  },
  {
    Id: 23825,
    Code: '8131',
    Name: 'Machinist, grinding, paper mfr'
  },
  {
    Id: 23826,
    Code: '8131',
    Name: 'Machinist, guillotine, paper goods mfr'
  },
  {
    Id: 23827,
    Code: '8131',
    Name: 'Machinist, gumming, gum paper'
  },
  {
    Id: 23828,
    Code: '8131',
    Name: 'Machinist, handle'
  },
  {
    Id: 23829,
    Code: '8131',
    Name: 'Machinist, impregnating, paper mfr'
  },
  {
    Id: 23830,
    Code: '8131',
    Name: 'Machinist, insulating, paper'
  },
  {
    Id: 23831,
    Code: '8131',
    Name: 'Machinist, joiner'
  },
  {
    Id: 23832,
    Code: '8131',
    Name: "Machinist, joiner's"
  },
  {
    Id: 23833,
    Code: '8131',
    Name: 'Machinist, lining, paper mfr'
  },
  {
    Id: 23834,
    Code: '8131',
    Name: "Machinist, maker's, crate"
  },
  {
    Id: 23835,
    Code: '8131',
    Name: 'Machinist, making, box'
  },
  {
    Id: 23836,
    Code: '8131',
    Name: 'Machinist, making, abrasive paper, cloth mfr'
  },
  {
    Id: 23837,
    Code: '8131',
    Name: 'Machinist, mill, moulding'
  },
  {
    Id: 23838,
    Code: '8131',
    Name: 'Machinist, mill, saw'
  },
  {
    Id: 23839,
    Code: '8131',
    Name: 'Machinist, mixing, abrasive paper, cloth mfr'
  },
  {
    Id: 23840,
    Code: '8131',
    Name: 'Machinist, moulding, wood products mfr'
  },
  {
    Id: 23841,
    Code: '8131',
    Name: 'Machinist, paper, carbon'
  },
  {
    Id: 23842,
    Code: '8131',
    Name: 'Machinist, paper, crinkled'
  },
  {
    Id: 23843,
    Code: '8131',
    Name: 'Machinist, paper, paper goods mfr'
  },
  {
    Id: 23844,
    Code: '8131',
    Name: 'Machinist, pasteboard'
  },
  {
    Id: 23845,
    Code: '8131',
    Name: 'Machinist, pasting, pasteboard'
  },
  {
    Id: 23846,
    Code: '8131',
    Name: 'Machinist, perforating, paper goods mfr'
  },
  {
    Id: 23847,
    Code: '8131',
    Name: 'Machinist, planing, wood products mfr'
  },
  {
    Id: 23848,
    Code: '8131',
    Name: 'Machinist, pleating, paper'
  },
  {
    Id: 23849,
    Code: '8131',
    Name: 'Machinist, punching, paper goods mfr'
  },
  {
    Id: 23850,
    Code: '8131',
    Name: 'Machinist, roll, toilet'
  },
  {
    Id: 23851,
    Code: '8131',
    Name: 'Machinist, ruling'
  },
  {
    Id: 23852,
    Code: '8131',
    Name: 'Machinist, sanding, wood'
  },
  {
    Id: 23853,
    Code: '8131',
    Name: 'Machinist, sandpapering'
  },
  {
    Id: 23854,
    Code: '8131',
    Name: 'Machinist, shaping, wood products mfr'
  },
  {
    Id: 23855,
    Code: '8131',
    Name: 'Machinist, slitting, adhesive tape mfr'
  },
  {
    Id: 23856,
    Code: '8131',
    Name: 'Machinist, slitting, paper goods mfr'
  },
  {
    Id: 23857,
    Code: '8131',
    Name: 'Machinist, slitting, paper mfr'
  },
  {
    Id: 23858,
    Code: '8131',
    Name: 'Machinist, slitting and cutting, photographic films'
  },
  {
    Id: 23859,
    Code: '8131',
    Name: 'Machinist, spindle'
  },
  {
    Id: 23860,
    Code: '8131',
    Name: 'Machinist, thicknessing'
  },
  {
    Id: 23861,
    Code: '8131',
    Name: 'Machinist, timber'
  },
  {
    Id: 23862,
    Code: '8131',
    Name: 'Machinist, tube, paper'
  },
  {
    Id: 23863,
    Code: '8131',
    Name: 'Machinist, turning, wood'
  },
  {
    Id: 23864,
    Code: '8131',
    Name: 'Machinist, up-taking'
  },
  {
    Id: 23865,
    Code: '8131',
    Name: 'Machinist, varnishing, lithography'
  },
  {
    Id: 23866,
    Code: '8131',
    Name: 'Machinist, veneer'
  },
  {
    Id: 23867,
    Code: '8131',
    Name: 'Machinist, waxing'
  },
  {
    Id: 23868,
    Code: '8131',
    Name: 'Machinist, wood'
  },
  {
    Id: 23869,
    Code: '8131',
    Name: 'Machinist, woodcutting'
  },
  {
    Id: 23870,
    Code: '8131',
    Name: 'Machinist, woodworking'
  },
  {
    Id: 23871,
    Code: '8131',
    Name: 'Machinist, adhesive tape mfr'
  },
  {
    Id: 23872,
    Code: '8131',
    Name: 'Machinist, furniture mfr'
  },
  {
    Id: 23873,
    Code: '8131',
    Name: 'Machinist, lead pencil, chalk, crayon mfr'
  },
  {
    Id: 23874,
    Code: '8131',
    Name: 'Machinist, match mfr'
  },
  {
    Id: 23875,
    Code: '8131',
    Name: 'Machinist, paper dress pattern mfr'
  },
  {
    Id: 23876,
    Code: '8131',
    Name: 'Machinist, paper goods mfr'
  },
  {
    Id: 23877,
    Code: '8131',
    Name: 'Machinist, paper mfr'
  },
  {
    Id: 23878,
    Code: '8131',
    Name: 'Machinist, piano action, hammer mfr'
  },
  {
    Id: 23879,
    Code: '8131',
    Name: 'Machinist, wallpaper mfr'
  },
  {
    Id: 23880,
    Code: '8131',
    Name: 'Machinist, wood products mfr'
  },
  {
    Id: 23881,
    Code: '8131',
    Name: 'Maker, bag, paper'
  },
  {
    Id: 23882,
    Code: '8131',
    Name: 'Maker, bag, paper goods mfr'
  },
  {
    Id: 23883,
    Code: '8131',
    Name: 'Maker, barrel, paper goods mfr'
  },
  {
    Id: 23884,
    Code: '8131',
    Name: 'Maker, block, cork'
  },
  {
    Id: 23885,
    Code: '8131',
    Name: 'Maker, board, paper mfr'
  },
  {
    Id: 23886,
    Code: '8131',
    Name: 'Maker, bobbin, cardboard'
  },
  {
    Id: 23887,
    Code: '8131',
    Name: 'Maker, box, card'
  },
  {
    Id: 23888,
    Code: '8131',
    Name: 'Maker, box, cardboard'
  },
  {
    Id: 23889,
    Code: '8131',
    Name: 'Maker, box, ointment'
  },
  {
    Id: 23890,
    Code: '8131',
    Name: 'Maker, box, paper'
  },
  {
    Id: 23891,
    Code: '8131',
    Name: 'Maker, box, wooden'
  },
  {
    Id: 23892,
    Code: '8131',
    Name: 'Maker, box, cardboard'
  },
  {
    Id: 23893,
    Code: '8131',
    Name: 'Maker, box, wood'
  },
  {
    Id: 23894,
    Code: '8131',
    Name: 'Maker, can, fibre'
  },
  {
    Id: 23895,
    Code: '8131',
    Name: 'Maker, card, pattern'
  },
  {
    Id: 23896,
    Code: '8131',
    Name: 'Maker, card, show'
  },
  {
    Id: 23897,
    Code: '8131',
    Name: 'Maker, card, paper mfr'
  },
  {
    Id: 23898,
    Code: '8131',
    Name: 'Maker, carton'
  },
  {
    Id: 23899,
    Code: '8131',
    Name: 'Maker, case, cardboard'
  },
  {
    Id: 23900,
    Code: '8131',
    Name: 'Maker, case, packing'
  },
  {
    Id: 23901,
    Code: '8131',
    Name: 'Maker, case'
  },
  {
    Id: 23902,
    Code: '8131',
    Name: 'Maker, churn'
  },
  {
    Id: 23903,
    Code: '8131',
    Name: 'Maker, cloth, glass'
  },
  {
    Id: 23904,
    Code: '8131',
    Name: 'Maker, crate'
  },
  {
    Id: 23905,
    Code: '8131',
    Name: 'Maker, cup, cream, paper goods mfr'
  },
  {
    Id: 23906,
    Code: '8131',
    Name: 'Maker, drum, cable'
  },
  {
    Id: 23907,
    Code: '8131',
    Name: 'Maker, drum, wood'
  },
  {
    Id: 23908,
    Code: '8131',
    Name: 'Maker, envelope, paper goods mfr'
  },
  {
    Id: 23909,
    Code: '8131',
    Name: 'Maker, file, box'
  },
  {
    Id: 23910,
    Code: '8131',
    Name: 'Maker, handle, wood'
  },
  {
    Id: 23911,
    Code: '8131',
    Name: 'Maker, hanger, coat, wood'
  },
  {
    Id: 23912,
    Code: '8131',
    Name: 'Maker, helve'
  },
  {
    Id: 23913,
    Code: '8131',
    Name: 'Maker, label, wood'
  },
  {
    Id: 23914,
    Code: '8131',
    Name: 'Maker, label'
  },
  {
    Id: 23915,
    Code: '8131',
    Name: 'Maker, medal'
  },
  {
    Id: 23916,
    Code: '8131',
    Name: 'Maker, pallet'
  },
  {
    Id: 23917,
    Code: '8131',
    Name: 'Maker, panel, wood'
  },
  {
    Id: 23918,
    Code: '8131',
    Name: 'Maker, paper, abrasive'
  },
  {
    Id: 23919,
    Code: '8131',
    Name: 'Maker, paper, carbon'
  },
  {
    Id: 23920,
    Code: '8131',
    Name: 'Maker, paper, emery'
  },
  {
    Id: 23921,
    Code: '8131',
    Name: 'Maker, paper, fly'
  },
  {
    Id: 23922,
    Code: '8131',
    Name: 'Maker, paper, glass'
  },
  {
    Id: 23923,
    Code: '8131',
    Name: 'Maker, paper, laced'
  },
  {
    Id: 23924,
    Code: '8131',
    Name: 'Maker, paper, photographic'
  },
  {
    Id: 23925,
    Code: '8131',
    Name: 'Maker, paper, sand'
  },
  {
    Id: 23926,
    Code: '8131',
    Name: 'Maker, paper'
  },
  {
    Id: 23927,
    Code: '8131',
    Name: 'Maker, plywood'
  },
  {
    Id: 23928,
    Code: '8131',
    Name: 'Maker, pole'
  },
  {
    Id: 23929,
    Code: '8131',
    Name: 'Maker, pulp, paper mfr'
  },
  {
    Id: 23930,
    Code: '8131',
    Name: 'Maker, reel'
  },
  {
    Id: 23931,
    Code: '8131',
    Name: 'Maker, sample, paper goods mfr'
  },
  {
    Id: 23932,
    Code: '8131',
    Name: 'Maker, screw, wooden'
  },
  {
    Id: 23933,
    Code: '8131',
    Name: 'Maker, sheet, vulcanised fibre'
  },
  {
    Id: 23934,
    Code: '8131',
    Name: 'Maker, tape, adhesive tape mfr'
  },
  {
    Id: 23935,
    Code: '8131',
    Name: 'Maker, transfer'
  },
  {
    Id: 23936,
    Code: '8131',
    Name: 'Maker, tray, paper'
  },
  {
    Id: 23937,
    Code: '8131',
    Name: 'Maker, trunk, wood'
  },
  {
    Id: 23938,
    Code: '8131',
    Name: 'Maker, tube, paper'
  },
  {
    Id: 23939,
    Code: '8131',
    Name: 'Maker, wheel, abrasive paper, cloth mfr'
  },
  {
    Id: 23940,
    Code: '8131',
    Name: 'Maker, wool, wood'
  },
  {
    Id: 23941,
    Code: '8131',
    Name: 'Man, cut-off, corrugated paper mfr'
  },
  {
    Id: 23942,
    Code: '8131',
    Name: 'Man, machine, paper mfr'
  },
  {
    Id: 23943,
    Code: '8131',
    Name: 'Man, mould, paper mfr'
  },
  {
    Id: 23944,
    Code: '8131',
    Name: 'Man, pan, paper mfr'
  },
  {
    Id: 23945,
    Code: '8131',
    Name: 'Man, press-pate'
  },
  {
    Id: 23946,
    Code: '8131',
    Name: 'Man, saw, wood'
  },
  {
    Id: 23947,
    Code: '8131',
    Name: 'Man, table, wooden box mfr'
  },
  {
    Id: 23948,
    Code: '8131',
    Name: 'Manager, machine, paper goods mfr'
  },
  {
    Id: 23949,
    Code: '8131',
    Name: 'Manufacturer, paper'
  },
  {
    Id: 23950,
    Code: '8131',
    Name: 'Manufacturer, packing case mfr'
  },
  {
    Id: 23951,
    Code: '8131',
    Name: 'Manufacturer, paper goods mfr'
  },
  {
    Id: 23952,
    Code: '8131',
    Name: 'Manufacturer, paper mfr'
  },
  {
    Id: 23953,
    Code: '8131',
    Name: 'Mender, crate'
  },
  {
    Id: 23954,
    Code: '8131',
    Name: 'Miller, flour, wood'
  },
  {
    Id: 23955,
    Code: '8131',
    Name: 'Miller, logwood'
  },
  {
    Id: 23956,
    Code: '8131',
    Name: 'Miller, saw'
  },
  {
    Id: 23957,
    Code: '8131',
    Name: 'Miller, paper mfr'
  },
  {
    Id: 23958,
    Code: '8131',
    Name: 'Mixer, adhesive, abrasive paper, cloth mfr'
  },
  {
    Id: 23959,
    Code: '8131',
    Name: 'Mixer, abrasive paper, cloth mfr'
  },
  {
    Id: 23960,
    Code: '8131',
    Name: 'Mixer, paper mfr'
  },
  {
    Id: 23961,
    Code: '8131',
    Name: 'Moulder, spindle, wood'
  },
  {
    Id: 23962,
    Code: '8131',
    Name: 'Moulder, wood'
  },
  {
    Id: 23963,
    Code: '8131',
    Name: 'Nipper, paper mfr'
  },
  {
    Id: 23964,
    Code: '8131',
    Name: 'Operator, calender, paper mfr'
  },
  {
    Id: 23965,
    Code: '8131',
    Name: 'Operator, clipper, veneer'
  },
  {
    Id: 23966,
    Code: '8131',
    Name: 'Operator, coating, paper mfr'
  },
  {
    Id: 23967,
    Code: '8131',
    Name: 'Operator, compressor, paper, leather board mfr'
  },
  {
    Id: 23968,
    Code: '8131',
    Name: 'Operator, guillotine, paper goods mfr'
  },
  {
    Id: 23969,
    Code: '8131',
    Name: 'Operator, guillotine, printing'
  },
  {
    Id: 23970,
    Code: '8131',
    Name: 'Operator, kiln, wood products mfr'
  },
  {
    Id: 23971,
    Code: '8131',
    Name: 'Operator, lathe, wood products mfr'
  },
  {
    Id: 23972,
    Code: '8131',
    Name: 'Operator, mill, saw'
  },
  {
    Id: 23973,
    Code: '8131',
    Name: 'Operator, plant, paper mfr'
  },
  {
    Id: 23974,
    Code: '8131',
    Name: 'Operator, process, paper mfr'
  },
  {
    Id: 23975,
    Code: '8131',
    Name: 'Operator, punch, paper goods mfr'
  },
  {
    Id: 23976,
    Code: '8131',
    Name: 'Operator, saw, band, wood'
  },
  {
    Id: 23977,
    Code: '8131',
    Name: 'Operator, saw, wood'
  },
  {
    Id: 23978,
    Code: '8131',
    Name: 'Operator, saw, sawmilling'
  },
  {
    Id: 23979,
    Code: '8131',
    Name: 'Operator, take-down, abrasive sheet'
  },
  {
    Id: 23980,
    Code: '8131',
    Name: 'Operator, winder, paper mfr'
  },
  {
    Id: 23981,
    Code: '8131',
    Name: 'Ovenman, abrasive paper, cloth mfr'
  },
  {
    Id: 23982,
    Code: '8131',
    Name: 'Paperer, tobacco pipe mfr'
  },
  {
    Id: 23983,
    Code: '8131',
    Name: 'Parchmentiser'
  },
  {
    Id: 23984,
    Code: '8131',
    Name: 'Pickler, sleeper'
  },
  {
    Id: 23985,
    Code: '8131',
    Name: 'Planer, wood'
  },
  {
    Id: 23986,
    Code: '8131',
    Name: 'Plater, paper mfr'
  },
  {
    Id: 23987,
    Code: '8131',
    Name: 'Potcherman'
  },
  {
    Id: 23988,
    Code: '8131',
    Name: 'Preserver, timber'
  },
  {
    Id: 23989,
    Code: '8131',
    Name: 'Presser, hot, paper mfr'
  },
  {
    Id: 23990,
    Code: '8131',
    Name: 'Presser, hot, printing'
  },
  {
    Id: 23991,
    Code: '8131',
    Name: 'Presser, veneer'
  },
  {
    Id: 23992,
    Code: '8131',
    Name: 'Presser, paper mfr'
  },
  {
    Id: 23993,
    Code: '8131',
    Name: 'Presser, plywood mfr'
  },
  {
    Id: 23994,
    Code: '8131',
    Name: 'Presser, wood pulp mfr'
  },
  {
    Id: 23995,
    Code: '8131',
    Name: 'Proofer, moisture, transparent paper mfr'
  },
  {
    Id: 23996,
    Code: '8131',
    Name: 'Pulper, paper mfr'
  },
  {
    Id: 23997,
    Code: '8131',
    Name: 'Pulperman, paper mfr'
  },
  {
    Id: 23998,
    Code: '8131',
    Name: 'Pulpman, paper mfr'
  },
  {
    Id: 23999,
    Code: '8131',
    Name: 'Recoverer, paper mfr'
  },
  {
    Id: 24000,
    Code: '8131',
    Name: 'Reeler, paper mfr'
  },
  {
    Id: 24001,
    Code: '8131',
    Name: 'Reeler, wallpaper mfr'
  },
  {
    Id: 24002,
    Code: '8131',
    Name: 'Reelerman, paper mfr'
  },
  {
    Id: 24003,
    Code: '8131',
    Name: 'Reelman'
  },
  {
    Id: 24004,
    Code: '8131',
    Name: 'Refiner, paper mfr'
  },
  {
    Id: 24005,
    Code: '8131',
    Name: 'Repairer, box'
  },
  {
    Id: 24006,
    Code: '8131',
    Name: 'Repairer, case, wood'
  },
  {
    Id: 24007,
    Code: '8131',
    Name: 'Repairer, cask'
  },
  {
    Id: 24008,
    Code: '8131',
    Name: 'Repairer, crate'
  },
  {
    Id: 24009,
    Code: '8131',
    Name: 'Repairer, pallet'
  },
  {
    Id: 24010,
    Code: '8131',
    Name: 'Rincer, bobbin'
  },
  {
    Id: 24011,
    Code: '8131',
    Name: 'Ripperman, paper mfr'
  },
  {
    Id: 24012,
    Code: '8131',
    Name: 'Roller, cold, paper mfr'
  },
  {
    Id: 24013,
    Code: '8131',
    Name: 'Roller, hot'
  },
  {
    Id: 24014,
    Code: '8131',
    Name: 'Roller, tube, paper'
  },
  {
    Id: 24015,
    Code: '8131',
    Name: 'Roller, paper mfr'
  },
  {
    Id: 24016,
    Code: '8131',
    Name: 'Roller, paper tube mfr'
  },
  {
    Id: 24017,
    Code: '8131',
    Name: 'Roller-up, wallpaper mfr'
  },
  {
    Id: 24018,
    Code: '8131',
    Name: 'Rougher and borer, woodwind instruments mfr'
  },
  {
    Id: 24019,
    Code: '8131',
    Name: 'Router, wood'
  },
  {
    Id: 24020,
    Code: '8131',
    Name: 'Sander, wood products mfr'
  },
  {
    Id: 24021,
    Code: '8131',
    Name: 'Sandman, abrasive paper, cloth mfr'
  },
  {
    Id: 24022,
    Code: '8131',
    Name: 'Sawyer, back'
  },
  {
    Id: 24023,
    Code: '8131',
    Name: 'Sawyer, band'
  },
  {
    Id: 24024,
    Code: '8131',
    Name: 'Sawyer, circular'
  },
  {
    Id: 24025,
    Code: '8131',
    Name: 'Sawyer, cut, cross'
  },
  {
    Id: 24026,
    Code: '8131',
    Name: 'Sawyer, mill'
  },
  {
    Id: 24027,
    Code: '8131',
    Name: 'Sawyer, pulp, wood'
  },
  {
    Id: 24028,
    Code: '8131',
    Name: 'Sawyer, rack'
  },
  {
    Id: 24029,
    Code: '8131',
    Name: 'Sawyer, whip'
  },
  {
    Id: 24030,
    Code: '8131',
    Name: 'Sawyer, wood'
  },
  {
    Id: 24031,
    Code: '8131',
    Name: 'Sawyer, coal mine'
  },
  {
    Id: 24032,
    Code: '8131',
    Name: 'Sawyer, converting mill'
  },
  {
    Id: 24033,
    Code: '8131',
    Name: 'Sawyer'
  },
  {
    Id: 24034,
    Code: '8131',
    Name: 'Seasoner, paper mfr'
  },
  {
    Id: 24035,
    Code: '8131',
    Name: 'Servicer, textile printing'
  },
  {
    Id: 24036,
    Code: '8131',
    Name: 'Setter, film, textile mfr'
  },
  {
    Id: 24037,
    Code: '8131',
    Name: 'Setter, forme, paper box mfr'
  },
  {
    Id: 24038,
    Code: '8131',
    Name: 'Setter, machine, board'
  },
  {
    Id: 24039,
    Code: '8131',
    Name: 'Setter, machine, woodcutting'
  },
  {
    Id: 24040,
    Code: '8131',
    Name: 'Setter, machine, paper goods mfr'
  },
  {
    Id: 24041,
    Code: '8131',
    Name: 'Setter-operator, machine, paper'
  },
  {
    Id: 24042,
    Code: '8131',
    Name: 'Shaper, wood products mfr'
  },
  {
    Id: 24043,
    Code: '8131',
    Name: 'Sharpener, prop, pit'
  },
  {
    Id: 24044,
    Code: '8131',
    Name: 'Sheeter, paper mfr'
  },
  {
    Id: 24045,
    Code: '8131',
    Name: 'Siever, abrasive paper, cloth mfr'
  },
  {
    Id: 24046,
    Code: '8131',
    Name: 'Sizeman, paper mill'
  },
  {
    Id: 24047,
    Code: '8131',
    Name: 'Sizer, bobbin'
  },
  {
    Id: 24048,
    Code: '8131',
    Name: 'Sizer, engine, paper mfr'
  },
  {
    Id: 24049,
    Code: '8131',
    Name: 'Sizer, paper mfr'
  },
  {
    Id: 24050,
    Code: '8131',
    Name: 'Slicer, veneer'
  },
  {
    Id: 24051,
    Code: '8131',
    Name: 'Slitter, paper'
  },
  {
    Id: 24052,
    Code: '8131',
    Name: 'Slitter, abrasive paper, cloth mfr'
  },
  {
    Id: 24053,
    Code: '8131',
    Name: 'Slitter, paper and printing'
  },
  {
    Id: 24054,
    Code: '8131',
    Name: 'Slitter, photographic film mfr'
  },
  {
    Id: 24055,
    Code: '8131',
    Name: 'Slitter, transparent cellulose wrappings mfr'
  },
  {
    Id: 24056,
    Code: '8131',
    Name: 'Slitterman, paper mfr'
  },
  {
    Id: 24057,
    Code: '8131',
    Name: 'Slotter, paper goods mfr'
  },
  {
    Id: 24058,
    Code: '8131',
    Name: 'Spooler, paper goods mfr'
  },
  {
    Id: 24059,
    Code: '8131',
    Name: 'Spreader, glue, abrasive paper, cloth mfr'
  },
  {
    Id: 24060,
    Code: '8131',
    Name: 'Stainer, paper'
  },
  {
    Id: 24061,
    Code: '8131',
    Name: 'Stayer, cardboard box mfr'
  },
  {
    Id: 24062,
    Code: '8131',
    Name: 'Strainerman, paper mfr'
  },
  {
    Id: 24063,
    Code: '8131',
    Name: 'Stripper, cardboard'
  },
  {
    Id: 24064,
    Code: '8131',
    Name: 'Stripper, carton'
  },
  {
    Id: 24065,
    Code: '8131',
    Name: 'Stripper, paper'
  },
  {
    Id: 24066,
    Code: '8131',
    Name: 'Stripper, paper goods mfr'
  },
  {
    Id: 24067,
    Code: '8131',
    Name: 'Tackler, paper goods mfr'
  },
  {
    Id: 24068,
    Code: '8131',
    Name: 'Taker-off, bag, paper'
  },
  {
    Id: 24069,
    Code: '8131',
    Name: 'Tankhand, vulcanised fibre'
  },
  {
    Id: 24070,
    Code: '8131',
    Name: 'Tender, machine, pasteboard'
  },
  {
    Id: 24071,
    Code: '8131',
    Name: 'Tenter, back, machine, linen'
  },
  {
    Id: 24072,
    Code: '8131',
    Name: 'Tenter, back, paper mfr'
  },
  {
    Id: 24073,
    Code: '8131',
    Name: 'Tenter, back, paper staining'
  },
  {
    Id: 24074,
    Code: '8131',
    Name: 'Treater, timber'
  },
  {
    Id: 24075,
    Code: '8131',
    Name: 'Trimmer, card'
  },
  {
    Id: 24076,
    Code: '8131',
    Name: 'Trimmer, paper, printing'
  },
  {
    Id: 24077,
    Code: '8131',
    Name: 'Trimmer, print, photographic'
  },
  {
    Id: 24078,
    Code: '8131',
    Name: 'Trimmer, sheet, paper goods mfr'
  },
  {
    Id: 24079,
    Code: '8131',
    Name: 'Trimmer, veneer'
  },
  {
    Id: 24080,
    Code: '8131',
    Name: 'Trimmer, wallpaper'
  },
  {
    Id: 24081,
    Code: '8131',
    Name: 'Turner, block, wood'
  },
  {
    Id: 24082,
    Code: '8131',
    Name: 'Turner, bobbin'
  },
  {
    Id: 24083,
    Code: '8131',
    Name: 'Turner, bowl, tobacco pipe mfr'
  },
  {
    Id: 24084,
    Code: '8131',
    Name: 'Turner, counter'
  },
  {
    Id: 24085,
    Code: '8131',
    Name: 'Turner, handle'
  },
  {
    Id: 24086,
    Code: '8131',
    Name: 'Turner, heel, wood heels'
  },
  {
    Id: 24087,
    Code: '8131',
    Name: 'Turner, mould, fringe'
  },
  {
    Id: 24088,
    Code: '8131',
    Name: 'Turner, pipe, tobacco pipe mfr'
  },
  {
    Id: 24089,
    Code: '8131',
    Name: 'Turner, pirn'
  },
  {
    Id: 24090,
    Code: '8131',
    Name: 'Turner, rod, fishing rods'
  },
  {
    Id: 24091,
    Code: '8131',
    Name: 'Turner, roller, wood'
  },
  {
    Id: 24092,
    Code: '8131',
    Name: 'Turner, shive'
  },
  {
    Id: 24093,
    Code: '8131',
    Name: 'Turner, spiral'
  },
  {
    Id: 24094,
    Code: '8131',
    Name: 'Turner, spool'
  },
  {
    Id: 24095,
    Code: '8131',
    Name: 'Turner, wood'
  },
  {
    Id: 24096,
    Code: '8131',
    Name: 'Turner, wood'
  },
  {
    Id: 24097,
    Code: '8131',
    Name: 'Turner, wood products mfr'
  },
  {
    Id: 24098,
    Code: '8131',
    Name: 'Turner, woodwind instruments mfr'
  },
  {
    Id: 24099,
    Code: '8131',
    Name: 'Varnisher, paper'
  },
  {
    Id: 24100,
    Code: '8131',
    Name: 'Varnisher, wallpaper mfr'
  },
  {
    Id: 24101,
    Code: '8131',
    Name: 'Vatman, paper mfr'
  },
  {
    Id: 24102,
    Code: '8131',
    Name: 'Veneerer, wood'
  },
  {
    Id: 24103,
    Code: '8131',
    Name: 'Washer, paper mfr'
  },
  {
    Id: 24104,
    Code: '8131',
    Name: 'Washerman, paper mfr'
  },
  {
    Id: 24105,
    Code: '8131',
    Name: 'Waterman, paper mfr'
  },
  {
    Id: 24106,
    Code: '8131',
    Name: 'Waxer, paper, stencil'
  },
  {
    Id: 24107,
    Code: '8131',
    Name: 'Waxer, paper mfr'
  },
  {
    Id: 24108,
    Code: '8131',
    Name: 'Willower, paper mfr'
  },
  {
    Id: 24109,
    Code: '8131',
    Name: 'Winder, spiral, paper tube mfr'
  },
  {
    Id: 24110,
    Code: '8131',
    Name: 'Winder, tube, paper tube mfr'
  },
  {
    Id: 24111,
    Code: '8131',
    Name: 'Winder, wallpaper'
  },
  {
    Id: 24112,
    Code: '8131',
    Name: 'Winder, paper mfr'
  },
  {
    Id: 24113,
    Code: '8131',
    Name: 'Winder, wallpaper mfr'
  },
  {
    Id: 24114,
    Code: '8131',
    Name: 'Winderman, paper mfr'
  },
  {
    Id: 24115,
    Code: '8131',
    Name: 'Woodcutter'
  },
  {
    Id: 24116,
    Code: '8131',
    Name: 'Woodturner'
  },
  {
    Id: 24117,
    Code: '8131',
    Name: 'Worker, bark'
  },
  {
    Id: 24118,
    Code: '8131',
    Name: 'Worker, box, cardboard'
  },
  {
    Id: 24119,
    Code: '8131',
    Name: 'Worker, dry, paper mfr'
  },
  {
    Id: 24120,
    Code: '8131',
    Name: 'Worker, fibre, paper mfr'
  },
  {
    Id: 24121,
    Code: '8131',
    Name: 'Worker, house, rag'
  },
  {
    Id: 24122,
    Code: '8131',
    Name: 'Worker, kiln, furniture mfr'
  },
  {
    Id: 24123,
    Code: '8131',
    Name: 'Worker, mill, paper'
  },
  {
    Id: 24124,
    Code: '8131',
    Name: 'Worker, mill, saw'
  },
  {
    Id: 24125,
    Code: '8131',
    Name: 'Worker, mill, timber merchants'
  },
  {
    Id: 24126,
    Code: '8131',
    Name: 'Worker, paper, paper mfr'
  },
  {
    Id: 24127,
    Code: '8131',
    Name: 'Worker, process, paper and board products mfr'
  },
  {
    Id: 24128,
    Code: '8131',
    Name: 'Worker, process, paper mfr'
  },
  {
    Id: 24129,
    Code: '8131',
    Name: 'Worker, process, wood products mfr'
  },
  {
    Id: 24130,
    Code: '8131',
    Name: 'Worker, room, pattern, wallpaper mfr'
  },
  {
    Id: 24131,
    Code: '8131',
    Name: 'Worker, tank, wax, cardboard box mfr'
  },
  {
    Id: 24132,
    Code: '8131',
    Name: 'Worker, tube, paper'
  },
  {
    Id: 24133,
    Code: '8131',
    Name: 'Wrapper, cardboard box mfr'
  },
  {
    Id: 24134,
    Code: '8132',
    Name: 'Airwayman, mine: not coal'
  },
  {
    Id: 24135,
    Code: '8132',
    Name: "Assistant, banksman's, coal mine"
  },
  {
    Id: 24136,
    Code: '8132',
    Name: "Assistant, borer's, coal mine"
  },
  {
    Id: 24137,
    Code: '8132',
    Name: 'Assistant, collier'
  },
  {
    Id: 24138,
    Code: '8132',
    Name: "Assistant, collier's"
  },
  {
    Id: 24139,
    Code: '8132',
    Name: "Assistant, onsetter's"
  },
  {
    Id: 24140,
    Code: '8132',
    Name: "Assistant, repairer's, coal mine"
  },
  {
    Id: 24141,
    Code: '8132',
    Name: "Assistant, shotfirer's, coal mine"
  },
  {
    Id: 24142,
    Code: '8132',
    Name: "Assistant, sinker's"
  },
  {
    Id: 24143,
    Code: '8132',
    Name: 'Assistant, timberman, coal mine'
  },
  {
    Id: 24144,
    Code: '8132',
    Name: "Assistant, timberman's, coal mine"
  },
  {
    Id: 24145,
    Code: '8132',
    Name: 'Attendant, aerial, mine: above ground'
  },
  {
    Id: 24146,
    Code: '8132',
    Name: 'Attendant, car, mine'
  },
  {
    Id: 24147,
    Code: '8132',
    Name: 'Attendant, coal'
  },
  {
    Id: 24148,
    Code: '8132',
    Name: 'Attendant, creeper, coal mine'
  },
  {
    Id: 24149,
    Code: '8132',
    Name: 'Attendant, crusher'
  },
  {
    Id: 24150,
    Code: '8132',
    Name: 'Attendant, depot, coal mine'
  },
  {
    Id: 24151,
    Code: '8132',
    Name: 'Attendant, frame, mine: not coal'
  },
  {
    Id: 24152,
    Code: '8132',
    Name: 'Attendant, hopper, coal mine'
  },
  {
    Id: 24153,
    Code: '8132',
    Name: 'Attendant, house, filter, mine: not coal'
  },
  {
    Id: 24154,
    Code: '8132',
    Name: 'Attendant, journey, coal mine'
  },
  {
    Id: 24155,
    Code: '8132',
    Name: 'Attendant, landing, coal mine'
  },
  {
    Id: 24156,
    Code: '8132',
    Name: 'Attendant, landsale'
  },
  {
    Id: 24157,
    Code: '8132',
    Name: 'Attendant, loader, coal mine'
  },
  {
    Id: 24158,
    Code: '8132',
    Name: 'Attendant, paddy, coal mine'
  },
  {
    Id: 24159,
    Code: '8132',
    Name: 'Attendant, plant, coal'
  },
  {
    Id: 24160,
    Code: '8132',
    Name: 'Attendant, plant, crushing, ore'
  },
  {
    Id: 24161,
    Code: '8132',
    Name: 'Attendant, plant, lime'
  },
  {
    Id: 24162,
    Code: '8132',
    Name: 'Attendant, plant, quarry'
  },
  {
    Id: 24163,
    Code: '8132',
    Name: 'Attendant, rope, coal mine'
  },
  {
    Id: 24164,
    Code: '8132',
    Name: 'Attendant, screen, mine'
  },
  {
    Id: 24165,
    Code: '8132',
    Name: 'Attendant, tipper, coal mine: above ground'
  },
  {
    Id: 24166,
    Code: '8132',
    Name: 'Attendant, tipper, coal mine'
  },
  {
    Id: 24167,
    Code: '8132',
    Name: 'Attendant, tippler, coal mine'
  },
  {
    Id: 24168,
    Code: '8132',
    Name: 'Attendant, unit, cracker'
  },
  {
    Id: 24169,
    Code: '8132',
    Name: 'Attendant, washery, coal mine'
  },
  {
    Id: 24170,
    Code: '8132',
    Name: 'Beltman, coal mine: above ground'
  },
  {
    Id: 24171,
    Code: '8132',
    Name: 'Beltman, coal mine'
  },
  {
    Id: 24172,
    Code: '8132',
    Name: 'Blaster, mine: not coal'
  },
  {
    Id: 24173,
    Code: '8132',
    Name: 'Bolter, roof, coal mine'
  },
  {
    Id: 24174,
    Code: '8132',
    Name: 'Borer, artesian'
  },
  {
    Id: 24175,
    Code: '8132',
    Name: 'Borer, ironstone'
  },
  {
    Id: 24176,
    Code: '8132',
    Name: 'Borer, methane, coal mine'
  },
  {
    Id: 24177,
    Code: '8132',
    Name: 'Borer, shot'
  },
  {
    Id: 24178,
    Code: '8132',
    Name: 'Borer, well'
  },
  {
    Id: 24179,
    Code: '8132',
    Name: 'Borer, coal mine: above ground'
  },
  {
    Id: 24180,
    Code: '8132',
    Name: 'Borer, coal mine'
  },
  {
    Id: 24181,
    Code: '8132',
    Name: 'Borer, mine: not coal'
  },
  {
    Id: 24182,
    Code: '8132',
    Name: 'Boss, shift'
  },
  {
    Id: 24183,
    Code: '8132',
    Name: 'Braker, mine: not coal'
  },
  {
    Id: 24184,
    Code: '8132',
    Name: 'Brakesman, coal mine'
  },
  {
    Id: 24185,
    Code: '8132',
    Name: 'Brakesman, mine: not coal'
  },
  {
    Id: 24186,
    Code: '8132',
    Name: 'Breaker, coal'
  },
  {
    Id: 24187,
    Code: '8132',
    Name: 'Breaker, rock'
  },
  {
    Id: 24188,
    Code: '8132',
    Name: 'Breaker, stone, mine: not coal'
  },
  {
    Id: 24189,
    Code: '8132',
    Name: 'Breaker, mine: not coal'
  },
  {
    Id: 24190,
    Code: '8132',
    Name: 'Breaker and filler'
  },
  {
    Id: 24191,
    Code: '8132',
    Name: 'Brusher, coal mine'
  },
  {
    Id: 24192,
    Code: '8132',
    Name: 'Buddler'
  },
  {
    Id: 24193,
    Code: '8132',
    Name: 'Bumper, coal mine'
  },
  {
    Id: 24194,
    Code: '8132',
    Name: 'Bunker, coal mine'
  },
  {
    Id: 24195,
    Code: '8132',
    Name: 'Bunkerman, blast furnace'
  },
  {
    Id: 24196,
    Code: '8132',
    Name: 'Bunkerman, coal mine'
  },
  {
    Id: 24197,
    Code: '8132',
    Name: 'Burster'
  },
  {
    Id: 24198,
    Code: '8132',
    Name: 'Byeworker, coal mine'
  },
  {
    Id: 24199,
    Code: '8132',
    Name: 'Canchman, coal mine'
  },
  {
    Id: 24200,
    Code: '8132',
    Name: 'Captain, underground'
  },
  {
    Id: 24201,
    Code: '8132',
    Name: 'Captain, mine: not coal'
  },
  {
    Id: 24202,
    Code: '8132',
    Name: 'Carter, mine: not coal'
  },
  {
    Id: 24203,
    Code: '8132',
    Name: 'Chainman, mine: not coal'
  },
  {
    Id: 24204,
    Code: '8132',
    Name: 'Changer, drill'
  },
  {
    Id: 24205,
    Code: '8132',
    Name: 'Changer, rope, coal mine'
  },
  {
    Id: 24206,
    Code: '8132',
    Name: 'Channeller, mine: not coal'
  },
  {
    Id: 24207,
    Code: '8132',
    Name: 'Chargeman, coal mine'
  },
  {
    Id: 24208,
    Code: '8132',
    Name: 'Charger, drill'
  },
  {
    Id: 24209,
    Code: '8132',
    Name: 'Chocker, coal mine'
  },
  {
    Id: 24210,
    Code: '8132',
    Name: 'Cleaner, belt, coal mine'
  },
  {
    Id: 24211,
    Code: '8132',
    Name: 'Cleaner, blende'
  },
  {
    Id: 24212,
    Code: '8132',
    Name: 'Cleaner, coal'
  },
  {
    Id: 24213,
    Code: '8132',
    Name: 'Cleaner, lamp, coal mine'
  },
  {
    Id: 24214,
    Code: '8132',
    Name: 'Cleaner, spillage'
  },
  {
    Id: 24215,
    Code: '8132',
    Name: 'Cleaner, stone, iron'
  },
  {
    Id: 24216,
    Code: '8132',
    Name: 'Cleaner, tin, mine: not coal'
  },
  {
    Id: 24217,
    Code: '8132',
    Name: 'Cleaner, wagon, coal mine'
  },
  {
    Id: 24218,
    Code: '8132',
    Name: 'Cleaner, coal mine'
  },
  {
    Id: 24219,
    Code: '8132',
    Name: 'Clipper, coal mine'
  },
  {
    Id: 24220,
    Code: '8132',
    Name: 'Collier, coal mine'
  },
  {
    Id: 24221,
    Code: '8132',
    Name: 'Companyman, coal mine'
  },
  {
    Id: 24222,
    Code: '8132',
    Name: 'Conductor, paddy'
  },
  {
    Id: 24223,
    Code: '8132',
    Name: 'Contractor, stone, coal mine'
  },
  {
    Id: 24224,
    Code: '8132',
    Name: 'Contractor, mine: not coal'
  },
  {
    Id: 24225,
    Code: '8132',
    Name: 'Controller, conveyer, coal mine'
  },
  {
    Id: 24226,
    Code: '8132',
    Name: 'Crusher, coal, coal mine'
  },
  {
    Id: 24227,
    Code: '8132',
    Name: 'Crusher, slag'
  },
  {
    Id: 24228,
    Code: '8132',
    Name: 'Crusher, abrasives'
  },
  {
    Id: 24229,
    Code: '8132',
    Name: 'Crusher, rock, mine: not coal'
  },
  {
    Id: 24230,
    Code: '8132',
    Name: 'Crusher, minerals, mines and quarries'
  },
  {
    Id: 24231,
    Code: '8132',
    Name: 'Crusherman, rock, mine: not coal'
  },
  {
    Id: 24232,
    Code: '8132',
    Name: 'Cutter, bottom'
  },
  {
    Id: 24233,
    Code: '8132',
    Name: 'Cutter, clay'
  },
  {
    Id: 24234,
    Code: '8132',
    Name: 'Cutter, coal, coal mine'
  },
  {
    Id: 24235,
    Code: '8132',
    Name: 'Cutter, machine, coal mine'
  },
  {
    Id: 24236,
    Code: '8132',
    Name: 'Cutter, machine, mine: not coal'
  },
  {
    Id: 24237,
    Code: '8132',
    Name: 'Cutter, top'
  },
  {
    Id: 24238,
    Code: '8132',
    Name: 'Cutter, coal mine'
  },
  {
    Id: 24239,
    Code: '8132',
    Name: 'Cutter, mine: not coal'
  },
  {
    Id: 24240,
    Code: '8132',
    Name: 'Cutterman, coal'
  },
  {
    Id: 24241,
    Code: '8132',
    Name: 'Cutterman, coal mine'
  },
  {
    Id: 24242,
    Code: '8132',
    Name: 'Delinter'
  },
  {
    Id: 24243,
    Code: '8132',
    Name: 'Delver, mine: not coal'
  },
  {
    Id: 24244,
    Code: '8132',
    Name: 'Deputy, coal mine'
  },
  {
    Id: 24245,
    Code: '8132',
    Name: 'Deputy, mine: not coal'
  },
  {
    Id: 24246,
    Code: '8132',
    Name: 'Derrickman, oil wells'
  },
  {
    Id: 24247,
    Code: '8132',
    Name: 'Developer, coal mine'
  },
  {
    Id: 24248,
    Code: '8132',
    Name: 'Digger, coal, coal mine'
  },
  {
    Id: 24249,
    Code: '8132',
    Name: 'Digger, mine: not coal'
  },
  {
    Id: 24250,
    Code: '8132',
    Name: 'Dinker, coal mine'
  },
  {
    Id: 24251,
    Code: '8132',
    Name: 'Dismantler, coal mine'
  },
  {
    Id: 24252,
    Code: '8132',
    Name: 'Drawer, chock, coal mine'
  },
  {
    Id: 24253,
    Code: '8132',
    Name: 'Drawer, prop, coal mine'
  },
  {
    Id: 24254,
    Code: '8132',
    Name: 'Drawer, prop'
  },
  {
    Id: 24255,
    Code: '8132',
    Name: 'Drawer, salvage, coal mine'
  },
  {
    Id: 24256,
    Code: '8132',
    Name: 'Drawer, steel, coal mine'
  },
  {
    Id: 24257,
    Code: '8132',
    Name: 'Drawer, timber, coal mine'
  },
  {
    Id: 24258,
    Code: '8132',
    Name: 'Drawer, timber, mine: not coal'
  },
  {
    Id: 24259,
    Code: '8132',
    Name: 'Drawer, waste, coal mine'
  },
  {
    Id: 24260,
    Code: '8132',
    Name: 'Drawer, coal mine'
  },
  {
    Id: 24261,
    Code: '8132',
    Name: 'Drawer-off, coal mine'
  },
  {
    Id: 24262,
    Code: '8132',
    Name: 'Dresser, tin'
  },
  {
    Id: 24263,
    Code: '8132',
    Name: 'Drifter, coal mine'
  },
  {
    Id: 24264,
    Code: '8132',
    Name: 'Drifter, mine: not coal'
  },
  {
    Id: 24265,
    Code: '8132',
    Name: 'Driftman, coal mine'
  },
  {
    Id: 24266,
    Code: '8132',
    Name: 'Driller, core'
  },
  {
    Id: 24267,
    Code: '8132',
    Name: 'Driller, diamond, well sinking'
  },
  {
    Id: 24268,
    Code: '8132',
    Name: 'Driller, directional'
  },
  {
    Id: 24269,
    Code: '8132',
    Name: 'Driller, machine, mine: not coal'
  },
  {
    Id: 24270,
    Code: '8132',
    Name: 'Driller, sample, mine: not coal'
  },
  {
    Id: 24271,
    Code: '8132',
    Name: 'Driller, well'
  },
  {
    Id: 24272,
    Code: '8132',
    Name: 'Driller, civil engineering construction'
  },
  {
    Id: 24273,
    Code: '8132',
    Name: 'Driller, coal mine'
  },
  {
    Id: 24274,
    Code: '8132',
    Name: 'Driller, marine operations'
  },
  {
    Id: 24275,
    Code: '8132',
    Name: 'Driller, mine: not coal'
  },
  {
    Id: 24276,
    Code: '8132',
    Name: 'Driller, well sinking'
  },
  {
    Id: 24277,
    Code: '8132',
    Name: 'Driver, crusher, mine: not coal'
  },
  {
    Id: 24278,
    Code: '8132',
    Name: 'Driver, drill, mine: not coal'
  },
  {
    Id: 24279,
    Code: '8132',
    Name: 'Driver, roller, oil wells'
  },
  {
    Id: 24280,
    Code: '8132',
    Name: 'Driver, shearer, coal mine'
  },
  {
    Id: 24281,
    Code: '8132',
    Name: 'Driver, pony, coal mine: below ground'
  },
  {
    Id: 24282,
    Code: '8132',
    Name: 'Driver, mine: not coal: below ground'
  },
  {
    Id: 24283,
    Code: '8132',
    Name: 'Dumper, coal mine'
  },
  {
    Id: 24284,
    Code: '8132',
    Name: 'Emptier, wagon, coal mine'
  },
  {
    Id: 24285,
    Code: '8132',
    Name: 'Engineer, boring'
  },
  {
    Id: 24286,
    Code: '8132',
    Name: 'Examiner, coal mine'
  },
  {
    Id: 24287,
    Code: '8132',
    Name: 'Excavator, mine: not coal'
  },
  {
    Id: 24288,
    Code: '8132',
    Name: 'Extractor, coal mine'
  },
  {
    Id: 24289,
    Code: '8132',
    Name: 'Feeder, crusher, mine: not coal'
  },
  {
    Id: 24290,
    Code: '8132',
    Name: 'Feeder, mine: not coal'
  },
  {
    Id: 24291,
    Code: '8132',
    Name: 'Ferrier'
  },
  {
    Id: 24292,
    Code: '8132',
    Name: 'Filler, coal'
  },
  {
    Id: 24293,
    Code: '8132',
    Name: 'Filler, conveyor, coal mine: below ground'
  },
  {
    Id: 24294,
    Code: '8132',
    Name: 'Filler, truck, coal mine'
  },
  {
    Id: 24295,
    Code: '8132',
    Name: 'Filler, tub, coal mine'
  },
  {
    Id: 24296,
    Code: '8132',
    Name: 'Filler, coal mine'
  },
  {
    Id: 24297,
    Code: '8132',
    Name: 'Fireman, coal mine: below ground'
  },
  {
    Id: 24298,
    Code: '8132',
    Name: 'Fireman, mine: not coal'
  },
  {
    Id: 24299,
    Code: '8132',
    Name: 'Firer, shot, coal mine'
  },
  {
    Id: 24300,
    Code: '8132',
    Name: 'Firer, shot, mine: not coal'
  },
  {
    Id: 24301,
    Code: '8132',
    Name: 'Fitter, chock, coal mine'
  },
  {
    Id: 24302,
    Code: '8132',
    Name: 'Fixer, girder, coal mine'
  },
  {
    Id: 24303,
    Code: '8132',
    Name: 'Foreman, coal'
  },
  {
    Id: 24304,
    Code: '8132',
    Name: 'Foreman, drill, mine: not coal'
  },
  {
    Id: 24305,
    Code: '8132',
    Name: 'Foreman, explosives, mine: not coal'
  },
  {
    Id: 24306,
    Code: '8132',
    Name: 'Foreman, outside, mine: not coal'
  },
  {
    Id: 24307,
    Code: '8132',
    Name: 'Foreman, pit, aggregates, clay, gravel, sand'
  },
  {
    Id: 24308,
    Code: '8132',
    Name: 'Foreman, plant, cleaning, dry, coal mine'
  },
  {
    Id: 24309,
    Code: '8132',
    Name: 'Foreman, quarry'
  },
  {
    Id: 24310,
    Code: '8132',
    Name: 'Foreman, rock, mine: not coal'
  },
  {
    Id: 24311,
    Code: '8132',
    Name: 'Foreman, screen'
  },
  {
    Id: 24312,
    Code: '8132',
    Name: 'Foreman, screens'
  },
  {
    Id: 24313,
    Code: '8132',
    Name: 'Foreman, surface, mine: not coal'
  },
  {
    Id: 24314,
    Code: '8132',
    Name: 'Foreman, traffic, coal mine: below ground'
  },
  {
    Id: 24315,
    Code: '8132',
    Name: 'Foreman, working, coal mine'
  },
  {
    Id: 24316,
    Code: '8132',
    Name: 'Foreman, yard, mine: not coal'
  },
  {
    Id: 24317,
    Code: '8132',
    Name: 'Foreman, coal mine: opencast'
  },
  {
    Id: 24318,
    Code: '8132',
    Name: 'Foreman, coal mine'
  },
  {
    Id: 24319,
    Code: '8132',
    Name: 'Foreman, mine: not coal'
  },
  {
    Id: 24320,
    Code: '8132',
    Name: 'Foreman, oil wells'
  },
  {
    Id: 24321,
    Code: '8132',
    Name: 'Ganger, coal mine'
  },
  {
    Id: 24322,
    Code: '8132',
    Name: 'Gaulter'
  },
  {
    Id: 24323,
    Code: '8132',
    Name: 'Getter, clay'
  },
  {
    Id: 24324,
    Code: '8132',
    Name: 'Getter, coal mine'
  },
  {
    Id: 24325,
    Code: '8132',
    Name: 'Getter, mine: not coal'
  },
  {
    Id: 24326,
    Code: '8132',
    Name: 'Grader, coal mine'
  },
  {
    Id: 24327,
    Code: '8132',
    Name: 'Grinder, ganister'
  },
  {
    Id: 24328,
    Code: '8132',
    Name: 'Grinder, stone, lime, quarry'
  },
  {
    Id: 24329,
    Code: '8132',
    Name: 'Grinder, mine: not coal'
  },
  {
    Id: 24330,
    Code: '8132',
    Name: 'Gummer, coal mine'
  },
  {
    Id: 24331,
    Code: '8132',
    Name: 'Hand, brow, mining'
  },
  {
    Id: 24332,
    Code: '8132',
    Name: 'Hand, datal, coal mine'
  },
  {
    Id: 24333,
    Code: '8132',
    Name: 'Hand, haulage, coal mine'
  },
  {
    Id: 24334,
    Code: '8132',
    Name: 'Hand, mill, mine: not coal'
  },
  {
    Id: 24335,
    Code: '8132',
    Name: 'Hand, rock, mine: not coal'
  },
  {
    Id: 24336,
    Code: '8132',
    Name: 'Hand, salvage, coal mine'
  },
  {
    Id: 24337,
    Code: '8132',
    Name: 'Hand, screen, coal mine'
  },
  {
    Id: 24338,
    Code: '8132',
    Name: 'Hand, surface, coal mine'
  },
  {
    Id: 24339,
    Code: '8132',
    Name: 'Hand, surface, mine: not coal'
  },
  {
    Id: 24340,
    Code: '8132',
    Name: 'Hand, washery, coal mine'
  },
  {
    Id: 24341,
    Code: '8132',
    Name: 'Handyman, nos, coal mine'
  },
  {
    Id: 24342,
    Code: '8132',
    Name: 'Hanger-on, coal mine'
  },
  {
    Id: 24343,
    Code: '8132',
    Name: 'Haulier, coal mine'
  },
  {
    Id: 24344,
    Code: '8132',
    Name: 'Header, coal mine'
  },
  {
    Id: 24345,
    Code: '8132',
    Name: 'Headman, gear, coal mine'
  },
  {
    Id: 24346,
    Code: '8132',
    Name: 'Helper, collier'
  },
  {
    Id: 24347,
    Code: '8132',
    Name: "Helper, collier's"
  },
  {
    Id: 24348,
    Code: '8132',
    Name: "Helper, miner's, mine: not coal"
  },
  {
    Id: 24349,
    Code: '8132',
    Name: "Helper, operator's, wireline"
  },
  {
    Id: 24350,
    Code: '8132',
    Name: 'Hewer, coal mine'
  },
  {
    Id: 24351,
    Code: '8132',
    Name: 'Hitcher, coal mine'
  },
  {
    Id: 24352,
    Code: '8132',
    Name: 'Holder, stall, coal mine'
  },
  {
    Id: 24353,
    Code: '8132',
    Name: 'Holer, mine: not coal'
  },
  {
    Id: 24354,
    Code: '8132',
    Name: 'Hoseman'
  },
  {
    Id: 24355,
    Code: '8132',
    Name: 'Infuser, water, coal mine'
  },
  {
    Id: 24356,
    Code: '8132',
    Name: 'Inspector, belt, coal mine'
  },
  {
    Id: 24357,
    Code: '8132',
    Name: 'Jigman, mine: not coal'
  },
  {
    Id: 24358,
    Code: '8132',
    Name: 'Keeker'
  },
  {
    Id: 24359,
    Code: '8132',
    Name: 'Knocker, catch, coal mine'
  },
  {
    Id: 24360,
    Code: '8132',
    Name: "Labourer, miner's, tunnel"
  },
  {
    Id: 24361,
    Code: '8132',
    Name: 'Labourer, coal mine'
  },
  {
    Id: 24362,
    Code: '8132',
    Name: 'Landsaler, coal mine'
  },
  {
    Id: 24363,
    Code: '8132',
    Name: 'Latcher, coal mine'
  },
  {
    Id: 24364,
    Code: '8132',
    Name: 'Leader, girder, coal mine'
  },
  {
    Id: 24365,
    Code: '8132',
    Name: 'Leader, timber, coal mine'
  },
  {
    Id: 24366,
    Code: '8132',
    Name: 'Lighter, shot, coal mine'
  },
  {
    Id: 24367,
    Code: '8132',
    Name: 'Linesman, survey, coal mine'
  },
  {
    Id: 24368,
    Code: '8132',
    Name: 'Linesman, coal mine'
  },
  {
    Id: 24369,
    Code: '8132',
    Name: 'Loader, conveyor, coal mine'
  },
  {
    Id: 24370,
    Code: '8132',
    Name: 'Loader, power, coal mine'
  },
  {
    Id: 24371,
    Code: '8132',
    Name: 'Loader, timber, coal mine'
  },
  {
    Id: 24372,
    Code: '8132',
    Name: 'Loader, coal mine'
  },
  {
    Id: 24373,
    Code: '8132',
    Name: 'Loaderman, power, coal mine'
  },
  {
    Id: 24374,
    Code: '8132',
    Name: 'Lugger, mine: not coal'
  },
  {
    Id: 24375,
    Code: '8132',
    Name: 'Machinist, boring, coal mine'
  },
  {
    Id: 24376,
    Code: '8132',
    Name: 'Machinist, cutting, coal mine'
  },
  {
    Id: 24377,
    Code: '8132',
    Name: 'Machinist, drilling, mine: not coal'
  },
  {
    Id: 24378,
    Code: '8132',
    Name: 'Machinist, loading, power'
  },
  {
    Id: 24379,
    Code: '8132',
    Name: 'Machinist, ratchet'
  },
  {
    Id: 24380,
    Code: '8132',
    Name: 'Machinist, coal mine'
  },
  {
    Id: 24381,
    Code: '8132',
    Name: 'Machinist, mine: not coal'
  },
  {
    Id: 24382,
    Code: '8132',
    Name: 'Magneter'
  },
  {
    Id: 24383,
    Code: '8132',
    Name: 'Maker, manhole'
  },
  {
    Id: 24384,
    Code: '8132',
    Name: 'Man, bargain, mine: not coal: below ground'
  },
  {
    Id: 24385,
    Code: '8132',
    Name: 'Man, button, colliery'
  },
  {
    Id: 24386,
    Code: '8132',
    Name: 'Man, clay, clay pit'
  },
  {
    Id: 24387,
    Code: '8132',
    Name: "Man, contractor's, coal mine"
  },
  {
    Id: 24388,
    Code: '8132',
    Name: 'Man, drill, mine: not coal'
  },
  {
    Id: 24389,
    Code: '8132',
    Name: 'Man, floor, derrick, oil wells'
  },
  {
    Id: 24390,
    Code: '8132',
    Name: 'Man, floor, oil wells'
  },
  {
    Id: 24391,
    Code: '8132',
    Name: 'Man, haulage, face'
  },
  {
    Id: 24392,
    Code: '8132',
    Name: 'Man, haulage, coal mine'
  },
  {
    Id: 24393,
    Code: '8132',
    Name: 'Man, heading, hard'
  },
  {
    Id: 24394,
    Code: '8132',
    Name: 'Man, infusion, water'
  },
  {
    Id: 24395,
    Code: '8132',
    Name: 'Man, job, odd, coal mine'
  },
  {
    Id: 24396,
    Code: '8132',
    Name: 'Man, machine, coal mine'
  },
  {
    Id: 24397,
    Code: '8132',
    Name: 'Man, market, coal mine'
  },
  {
    Id: 24398,
    Code: '8132',
    Name: 'Man, mica, mine: not coal'
  },
  {
    Id: 24399,
    Code: '8132',
    Name: 'Man, mill, mine: not coal'
  },
  {
    Id: 24400,
    Code: '8132',
    Name: 'Man, pan, coal mine'
  },
  {
    Id: 24401,
    Code: '8132',
    Name: 'Man, pit, coal mine'
  },
  {
    Id: 24402,
    Code: '8132',
    Name: 'Man, pit, mine: not coal'
  },
  {
    Id: 24403,
    Code: '8132',
    Name: 'Man, quarry'
  },
  {
    Id: 24404,
    Code: '8132',
    Name: 'Man, salvage, coal mine'
  },
  {
    Id: 24405,
    Code: '8132',
    Name: 'Man, screen, coal mine'
  },
  {
    Id: 24406,
    Code: '8132',
    Name: 'Man, supply, coal mine'
  },
  {
    Id: 24407,
    Code: '8132',
    Name: 'Man, surface, coal mine'
  },
  {
    Id: 24408,
    Code: '8132',
    Name: 'Man, timber, building construction: tunnelling contracting'
  },
  {
    Id: 24409,
    Code: '8132',
    Name: 'Man, timber, coal mine'
  },
  {
    Id: 24410,
    Code: '8132',
    Name: 'Man, timber, mine: not coal'
  },
  {
    Id: 24411,
    Code: '8132',
    Name: 'Man, utility, coal mine'
  },
  {
    Id: 24412,
    Code: '8132',
    Name: 'Man, waste, assistant, coal mine'
  },
  {
    Id: 24413,
    Code: '8132',
    Name: 'Man, waste, coal mine'
  },
  {
    Id: 24414,
    Code: '8132',
    Name: 'Manipulator, tub, coal mine'
  },
  {
    Id: 24415,
    Code: '8132',
    Name: 'Master, quarry'
  },
  {
    Id: 24416,
    Code: '8132',
    Name: 'Master, mine: metal ore'
  },
  {
    Id: 24417,
    Code: '8132',
    Name: 'Miller, stone, mine: not coal'
  },
  {
    Id: 24418,
    Code: '8132',
    Name: 'Miller, mine: not coal'
  },
  {
    Id: 24419,
    Code: '8132',
    Name: 'Minder, belt, coal mine'
  },
  {
    Id: 24420,
    Code: '8132',
    Name: 'Minder, crusher, mine: not coal'
  },
  {
    Id: 24421,
    Code: '8132',
    Name: 'Minder, turn, coal mine'
  },
  {
    Id: 24422,
    Code: '8132',
    Name: 'Miner, clay'
  },
  {
    Id: 24423,
    Code: '8132',
    Name: 'Miner, coal, opencast'
  },
  {
    Id: 24424,
    Code: '8132',
    Name: 'Miner, coal'
  },
  {
    Id: 24425,
    Code: '8132',
    Name: 'Miner, tin'
  },
  {
    Id: 24426,
    Code: '8132',
    Name: 'Miner, coal mine'
  },
  {
    Id: 24427,
    Code: '8132',
    Name: 'Miner, mine: not coal'
  },
  {
    Id: 24428,
    Code: '8132',
    Name: 'Officer, control, roof, coal mine'
  },
  {
    Id: 24429,
    Code: '8132',
    Name: 'Officer, salvage, coal mine'
  },
  {
    Id: 24430,
    Code: '8132',
    Name: 'Official, coal mine'
  },
  {
    Id: 24431,
    Code: '8132',
    Name: 'Operative, pit'
  },
  {
    Id: 24432,
    Code: '8132',
    Name: 'Operative, quarry'
  },
  {
    Id: 24433,
    Code: '8132',
    Name: 'Operator, cleaner, dry, coal mine'
  },
  {
    Id: 24434,
    Code: '8132',
    Name: 'Operator, crusher, mine'
  },
  {
    Id: 24435,
    Code: '8132',
    Name: 'Operator, cutter, coal'
  },
  {
    Id: 24436,
    Code: '8132',
    Name: 'Operator, disc, coal mine'
  },
  {
    Id: 24437,
    Code: '8132',
    Name: 'Operator, flotation, froth, coal mine'
  },
  {
    Id: 24438,
    Code: '8132',
    Name: 'Operator, loader, power'
  },
  {
    Id: 24439,
    Code: '8132',
    Name: 'Operator, plant, flotation'
  },
  {
    Id: 24440,
    Code: '8132',
    Name: 'Operator, plant, coal mine: coal washery'
  },
  {
    Id: 24441,
    Code: '8132',
    Name: 'Operator, plant, quarry'
  },
  {
    Id: 24442,
    Code: '8132',
    Name: 'Operator, process, clay extraction'
  },
  {
    Id: 24443,
    Code: '8132',
    Name: 'Operator, screen, mines: quarries'
  },
  {
    Id: 24444,
    Code: '8132',
    Name: 'Operator, separator, magnetic'
  },
  {
    Id: 24445,
    Code: '8132',
    Name: 'Operator, slusher, coal mine'
  },
  {
    Id: 24446,
    Code: '8132',
    Name: 'Operator, switch, coal mine'
  },
  {
    Id: 24447,
    Code: '8132',
    Name: 'Operator, washery'
  },
  {
    Id: 24448,
    Code: '8132',
    Name: 'Operator, wireline'
  },
  {
    Id: 24449,
    Code: '8132',
    Name: 'Overman, deputy, coal mine'
  },
  {
    Id: 24450,
    Code: '8132',
    Name: 'Overman, mining'
  },
  {
    Id: 24451,
    Code: '8132',
    Name: 'Packer, coal mine'
  },
  {
    Id: 24452,
    Code: '8132',
    Name: 'Packer, mine: not coal'
  },
  {
    Id: 24453,
    Code: '8132',
    Name: 'Panner, coal mine'
  },
  {
    Id: 24454,
    Code: '8132',
    Name: 'Patrol, coal mine'
  },
  {
    Id: 24455,
    Code: '8132',
    Name: 'Patrolman, coal mine'
  },
  {
    Id: 24456,
    Code: '8132',
    Name: 'Picker, flint'
  },
  {
    Id: 24457,
    Code: '8132',
    Name: 'Picker, metal, mine: not coal'
  },
  {
    Id: 24458,
    Code: '8132',
    Name: 'Picker, stone, coal mine'
  },
  {
    Id: 24459,
    Code: '8132',
    Name: 'Picker, coal mine'
  },
  {
    Id: 24460,
    Code: '8132',
    Name: 'Picker, mine: not coal'
  },
  {
    Id: 24461,
    Code: '8132',
    Name: 'Popper'
  },
  {
    Id: 24462,
    Code: '8132',
    Name: 'Presser, filter, coal mine'
  },
  {
    Id: 24463,
    Code: '8132',
    Name: 'Presser, mine: not coal'
  },
  {
    Id: 24464,
    Code: '8132',
    Name: 'Propman, coal mine'
  },
  {
    Id: 24465,
    Code: '8132',
    Name: 'Propper, coal mine'
  },
  {
    Id: 24466,
    Code: '8132',
    Name: 'Putter, coal mine'
  },
  {
    Id: 24467,
    Code: '8132',
    Name: 'Quarrier'
  },
  {
    Id: 24468,
    Code: '8132',
    Name: 'Refiner, clay, china'
  },
  {
    Id: 24469,
    Code: '8132',
    Name: 'Repairer, airway, coal mine'
  },
  {
    Id: 24470,
    Code: '8132',
    Name: 'Repairer, airway, mine: not coal'
  },
  {
    Id: 24471,
    Code: '8132',
    Name: 'Repairer, road, coal mine'
  },
  {
    Id: 24472,
    Code: '8132',
    Name: 'Repairer, coal mine'
  },
  {
    Id: 24473,
    Code: '8132',
    Name: 'Riddler, sand'
  },
  {
    Id: 24474,
    Code: '8132',
    Name: 'Riddler, mine: not coal'
  },
  {
    Id: 24475,
    Code: '8132',
    Name: 'Rider, coal mine'
  },
  {
    Id: 24476,
    Code: '8132',
    Name: 'Ripper, coal mine'
  },
  {
    Id: 24477,
    Code: '8132',
    Name: 'Rockman, mine: not coal'
  },
  {
    Id: 24478,
    Code: '8132',
    Name: 'Roughneck'
  },
  {
    Id: 24479,
    Code: '8132',
    Name: 'Runner, belt, coal mine'
  },
  {
    Id: 24480,
    Code: '8132',
    Name: 'Runner, wagon, coal mine'
  },
  {
    Id: 24481,
    Code: '8132',
    Name: 'Scalloper, coal mine'
  },
  {
    Id: 24482,
    Code: '8132',
    Name: 'Scraper, coal mine'
  },
  {
    Id: 24483,
    Code: '8132',
    Name: 'Screener, coal'
  },
  {
    Id: 24484,
    Code: '8132',
    Name: 'Screener, coal mine'
  },
  {
    Id: 24485,
    Code: '8132',
    Name: 'Screener, mine: not coal'
  },
  {
    Id: 24486,
    Code: '8132',
    Name: 'Screenworker, coal mine'
  },
  {
    Id: 24487,
    Code: '8132',
    Name: 'Securer'
  },
  {
    Id: 24488,
    Code: '8132',
    Name: 'Separator, ore'
  },
  {
    Id: 24489,
    Code: '8132',
    Name: 'Setter, prop, coal mine'
  },
  {
    Id: 24490,
    Code: '8132',
    Name: 'Setter, timber'
  },
  {
    Id: 24491,
    Code: '8132',
    Name: 'Shearer, coal mine: below ground'
  },
  {
    Id: 24492,
    Code: '8132',
    Name: 'Shifter, coal mine'
  },
  {
    Id: 24493,
    Code: '8132',
    Name: 'Shifthand, coal mine'
  },
  {
    Id: 24494,
    Code: '8132',
    Name: 'Shiftman, mine: not coal'
  },
  {
    Id: 24495,
    Code: '8132',
    Name: 'Shotman, mine: not coal'
  },
  {
    Id: 24496,
    Code: '8132',
    Name: 'Shotsman, coal mine'
  },
  {
    Id: 24497,
    Code: '8132',
    Name: 'Sinker, coal mine'
  },
  {
    Id: 24498,
    Code: '8132',
    Name: 'Smudger'
  },
  {
    Id: 24499,
    Code: '8132',
    Name: 'Sorter, coal, coal mine'
  },
  {
    Id: 24500,
    Code: '8132',
    Name: 'Splitter, mine: not coal: below ground'
  },
  {
    Id: 24501,
    Code: '8132',
    Name: 'Spragger, coal mine'
  },
  {
    Id: 24502,
    Code: '8132',
    Name: 'Stamper, mine: not coal'
  },
  {
    Id: 24503,
    Code: '8132',
    Name: 'Stemmer, coal mine'
  },
  {
    Id: 24504,
    Code: '8132',
    Name: 'Stoneman, coal mine'
  },
  {
    Id: 24505,
    Code: '8132',
    Name: 'Stower, coal'
  },
  {
    Id: 24506,
    Code: '8132',
    Name: 'Stower, power'
  },
  {
    Id: 24507,
    Code: '8132',
    Name: 'Stower, coal mine'
  },
  {
    Id: 24508,
    Code: '8132',
    Name: 'Striker, catch, coal mine'
  },
  {
    Id: 24509,
    Code: '8132',
    Name: 'Stripper, coal mine'
  },
  {
    Id: 24510,
    Code: '8132',
    Name: 'Stripper, mine: not coal'
  },
  {
    Id: 24511,
    Code: '8132',
    Name: 'Sumper'
  },
  {
    Id: 24512,
    Code: '8132',
    Name: 'Supervisor, service, well, oil wells'
  },
  {
    Id: 24513,
    Code: '8132',
    Name: 'Supplier, timber, coal mine'
  },
  {
    Id: 24514,
    Code: '8132',
    Name: 'Suppressor, dust, coal mine'
  },
  {
    Id: 24515,
    Code: '8132',
    Name: 'Switchman, coal mine'
  },
  {
    Id: 24516,
    Code: '8132',
    Name: 'Teemer, coal, coal mine: above ground'
  },
  {
    Id: 24517,
    Code: '8132',
    Name: 'Timberer, coal mine'
  },
  {
    Id: 24518,
    Code: '8132',
    Name: 'Tipper, coal mine'
  },
  {
    Id: 24519,
    Code: '8132',
    Name: 'Tipplerman, coal mine'
  },
  {
    Id: 24520,
    Code: '8132',
    Name: 'Topman, coal mine'
  },
  {
    Id: 24521,
    Code: '8132',
    Name: 'Tracker, coal mine'
  },
  {
    Id: 24522,
    Code: '8132',
    Name: 'Transporter, cable, coal mine'
  },
  {
    Id: 24523,
    Code: '8132',
    Name: 'Transporter, supplies, coal mine'
  },
  {
    Id: 24524,
    Code: '8132',
    Name: 'Trimmer, coal, coal mine'
  },
  {
    Id: 24525,
    Code: '8132',
    Name: 'Trimmer, face, coal mine'
  },
  {
    Id: 24526,
    Code: '8132',
    Name: 'Trimmer, lime'
  },
  {
    Id: 24527,
    Code: '8132',
    Name: 'Trimmer, coal mine'
  },
  {
    Id: 24528,
    Code: '8132',
    Name: 'Truckman, coal mine'
  },
  {
    Id: 24529,
    Code: '8132',
    Name: 'Tunneller, coal mine'
  },
  {
    Id: 24530,
    Code: '8132',
    Name: 'Wageman, coal mine'
  },
  {
    Id: 24531,
    Code: '8132',
    Name: 'Washer, coal, coal mine'
  },
  {
    Id: 24532,
    Code: '8132',
    Name: 'Washer, gravel'
  },
  {
    Id: 24533,
    Code: '8132',
    Name: 'Washer, grit'
  },
  {
    Id: 24534,
    Code: '8132',
    Name: 'Washer, hand, coal mine'
  },
  {
    Id: 24535,
    Code: '8132',
    Name: 'Washer, lime'
  },
  {
    Id: 24536,
    Code: '8132',
    Name: 'Washer, stone'
  },
  {
    Id: 24537,
    Code: '8132',
    Name: 'Washer, mine: not coal'
  },
  {
    Id: 24538,
    Code: '8132',
    Name: 'Waterman, coal mine'
  },
  {
    Id: 24539,
    Code: '8132',
    Name: 'Woodman, mine: not coal'
  },
  {
    Id: 24540,
    Code: '8132',
    Name: 'Worker, clay, clay pit'
  },
  {
    Id: 24541,
    Code: '8132',
    Name: 'Worker, colliery'
  },
  {
    Id: 24542,
    Code: '8132',
    Name: 'Worker, contract, coal mine'
  },
  {
    Id: 24543,
    Code: '8132',
    Name: 'Worker, development, coal mine'
  },
  {
    Id: 24544,
    Code: '8132',
    Name: 'Worker, face, coal mine'
  },
  {
    Id: 24545,
    Code: '8132',
    Name: 'Worker, general, coal mine'
  },
  {
    Id: 24546,
    Code: '8132',
    Name: 'Worker, gravel, gravel extraction'
  },
  {
    Id: 24547,
    Code: '8132',
    Name: 'Worker, ground, above, coal mine'
  },
  {
    Id: 24548,
    Code: '8132',
    Name: 'Worker, ground, above, mine: not coal'
  },
  {
    Id: 24549,
    Code: '8132',
    Name: 'Worker, ground, under, coal mine'
  },
  {
    Id: 24550,
    Code: '8132',
    Name: 'Worker, ground, under, mine: not coal'
  },
  {
    Id: 24551,
    Code: '8132',
    Name: 'Worker, haulage, coal mine'
  },
  {
    Id: 24552,
    Code: '8132',
    Name: 'Worker, ironstone'
  },
  {
    Id: 24553,
    Code: '8132',
    Name: 'Worker, mine, coal mine'
  },
  {
    Id: 24554,
    Code: '8132',
    Name: 'Worker, mine, mine: not coal'
  },
  {
    Id: 24555,
    Code: '8132',
    Name: 'Worker, odd, coal mine'
  },
  {
    Id: 24556,
    Code: '8132',
    Name: 'Worker, opencast'
  },
  {
    Id: 24557,
    Code: '8132',
    Name: 'Worker, piece, coal mine'
  },
  {
    Id: 24558,
    Code: '8132',
    Name: 'Worker, pit, coal mine'
  },
  {
    Id: 24559,
    Code: '8132',
    Name: 'Worker, plant, hydrating, lime'
  },
  {
    Id: 24560,
    Code: '8132',
    Name: 'Worker, plant, screening'
  },
  {
    Id: 24561,
    Code: '8132',
    Name: 'Worker, process, clay extraction'
  },
  {
    Id: 24562,
    Code: '8132',
    Name: 'Worker, quarry'
  },
  {
    Id: 24563,
    Code: '8132',
    Name: 'Worker, repair, coal mine'
  },
  {
    Id: 24564,
    Code: '8132',
    Name: 'Worker, rig, oil, well drilling'
  },
  {
    Id: 24565,
    Code: '8132',
    Name: 'Worker, rig, oil'
  },
  {
    Id: 24566,
    Code: '8132',
    Name: 'Worker, salvage, coal mine'
  },
  {
    Id: 24567,
    Code: '8132',
    Name: 'Worker, sand, quarry'
  },
  {
    Id: 24568,
    Code: '8132',
    Name: 'Worker, screen, coal mine'
  },
  {
    Id: 24569,
    Code: '8132',
    Name: 'Worker, shift, coal mine'
  },
  {
    Id: 24570,
    Code: '8132',
    Name: 'Worker, slurry, coal mine'
  },
  {
    Id: 24571,
    Code: '8132',
    Name: 'Worker, stone, coal mine'
  },
  {
    Id: 24572,
    Code: '8132',
    Name: 'Worker, stone, mine: not coal'
  },
  {
    Id: 24573,
    Code: '8132',
    Name: 'Worker, surface, coal mine'
  },
  {
    Id: 24574,
    Code: '8132',
    Name: 'Worker, surface, mine: not coal'
  },
  {
    Id: 24575,
    Code: '8132',
    Name: 'Worker, task, coal mine'
  },
  {
    Id: 24576,
    Code: '8132',
    Name: 'Worker, transit, coal mine'
  },
  {
    Id: 24577,
    Code: '8132',
    Name: 'Worker, underground, coal mine'
  },
  {
    Id: 24578,
    Code: '8132',
    Name: 'Worker, underground, mine: not coal'
  },
  {
    Id: 24579,
    Code: '8132',
    Name: 'Worker, waste, coal mine'
  },
  {
    Id: 24580,
    Code: '8133',
    Name: 'APA, power station'
  },
  {
    Id: 24581,
    Code: '8133',
    Name: 'Assistant, control, electricity supplier'
  },
  {
    Id: 24582,
    Code: '8133',
    Name: "Assistant, driver's, engine"
  },
  {
    Id: 24583,
    Code: '8133',
    Name: "Assistant, driver's, turbine"
  },
  {
    Id: 24584,
    Code: '8133',
    Name: 'Assistant, room, engine'
  },
  {
    Id: 24585,
    Code: '8133',
    Name: 'Assistant, station, electricity supplier'
  },
  {
    Id: 24586,
    Code: '8133',
    Name: "Assistant, stoker's"
  },
  {
    Id: 24587,
    Code: '8133',
    Name: 'Assistant, sub-station'
  },
  {
    Id: 24588,
    Code: '8133',
    Name: 'Attendant, alternator'
  },
  {
    Id: 24589,
    Code: '8133',
    Name: 'Attendant, board, control'
  },
  {
    Id: 24590,
    Code: '8133',
    Name: 'Attendant, board, switch'
  },
  {
    Id: 24591,
    Code: '8133',
    Name: 'Attendant, boiler'
  },
  {
    Id: 24592,
    Code: '8133',
    Name: 'Attendant, booster'
  },
  {
    Id: 24593,
    Code: '8133',
    Name: 'Attendant, compression, air'
  },
  {
    Id: 24594,
    Code: '8133',
    Name: 'Attendant, compressor'
  },
  {
    Id: 24595,
    Code: '8133',
    Name: 'Attendant, condenser, power station'
  },
  {
    Id: 24596,
    Code: '8133',
    Name: 'Attendant, dynamo'
  },
  {
    Id: 24597,
    Code: '8133',
    Name: 'Attendant, engine'
  },
  {
    Id: 24598,
    Code: '8133',
    Name: 'Attendant, gas, steelworks'
  },
  {
    Id: 24599,
    Code: '8133',
    Name: 'Attendant, gear, coal mine'
  },
  {
    Id: 24600,
    Code: '8133',
    Name: 'Attendant, generator'
  },
  {
    Id: 24601,
    Code: '8133',
    Name: 'Attendant, heating'
  },
  {
    Id: 24602,
    Code: '8133',
    Name: 'Attendant, house, boiler'
  },
  {
    Id: 24603,
    Code: '8133',
    Name: 'Attendant, house, booster'
  },
  {
    Id: 24604,
    Code: '8133',
    Name: 'Attendant, house, compressor'
  },
  {
    Id: 24605,
    Code: '8133',
    Name: 'Attendant, house, power'
  },
  {
    Id: 24606,
    Code: '8133',
    Name: 'Attendant, machinery'
  },
  {
    Id: 24607,
    Code: '8133',
    Name: 'Attendant, motor'
  },
  {
    Id: 24608,
    Code: '8133',
    Name: 'Attendant, plant, auxiliary'
  },
  {
    Id: 24609,
    Code: '8133',
    Name: 'Attendant, plant, boiler'
  },
  {
    Id: 24610,
    Code: '8133',
    Name: 'Attendant, plant, electric'
  },
  {
    Id: 24611,
    Code: '8133',
    Name: 'Attendant, plant'
  },
  {
    Id: 24612,
    Code: '8133',
    Name: 'Attendant, pump, air'
  },
  {
    Id: 24613,
    Code: '8133',
    Name: 'Attendant, room, boiler'
  },
  {
    Id: 24614,
    Code: '8133',
    Name: 'Attendant, room, compressor'
  },
  {
    Id: 24615,
    Code: '8133',
    Name: 'Attendant, room, power'
  },
  {
    Id: 24616,
    Code: '8133',
    Name: 'Attendant, station, power'
  },
  {
    Id: 24617,
    Code: '8133',
    Name: 'Attendant, station, sub, electricity supplier'
  },
  {
    Id: 24618,
    Code: '8133',
    Name: 'Attendant, substation'
  },
  {
    Id: 24619,
    Code: '8133',
    Name: 'Attendant, transformer'
  },
  {
    Id: 24620,
    Code: '8133',
    Name: 'Attendant, turbine'
  },
  {
    Id: 24621,
    Code: '8133',
    Name: 'Beater, fire'
  },
  {
    Id: 24622,
    Code: '8133',
    Name: 'Blower, soot, power station'
  },
  {
    Id: 24623,
    Code: '8133',
    Name: 'Blower, chemical mfr'
  },
  {
    Id: 24624,
    Code: '8133',
    Name: 'Boiler, steam'
  },
  {
    Id: 24625,
    Code: '8133',
    Name: 'Boilerman'
  },
  {
    Id: 24626,
    Code: '8133',
    Name: 'Booster, gas, steelworks'
  },
  {
    Id: 24627,
    Code: '8133',
    Name: 'Bunkerman, power station'
  },
  {
    Id: 24628,
    Code: '8133',
    Name: 'Cleaner, lamp, valve mfr'
  },
  {
    Id: 24629,
    Code: '8133',
    Name: "Compressor, engineer's"
  },
  {
    Id: 24630,
    Code: '8133',
    Name: 'Compressor, gas'
  },
  {
    Id: 24631,
    Code: '8133',
    Name: 'Controller, electrical, railways'
  },
  {
    Id: 24632,
    Code: '8133',
    Name: 'Controller, power, railways'
  },
  {
    Id: 24633,
    Code: '8133',
    Name: 'Driver, compressor'
  },
  {
    Id: 24634,
    Code: '8133',
    Name: 'Driver, engine, mining'
  },
  {
    Id: 24635,
    Code: '8133',
    Name: 'Driver, engine'
  },
  {
    Id: 24636,
    Code: '8133',
    Name: 'Driver, house, power'
  },
  {
    Id: 24637,
    Code: '8133',
    Name: 'Driver, motor, coal mine'
  },
  {
    Id: 24638,
    Code: '8133',
    Name: 'Driver, turbine'
  },
  {
    Id: 24639,
    Code: '8133',
    Name: 'Driver, turbo-blower'
  },
  {
    Id: 24640,
    Code: '8133',
    Name: 'Engineer, boiler'
  },
  {
    Id: 24641,
    Code: '8133',
    Name: 'Engineer, boilerhouse'
  },
  {
    Id: 24642,
    Code: '8133',
    Name: 'Engineer, steam'
  },
  {
    Id: 24643,
    Code: '8133',
    Name: 'Engineer, turbine'
  },
  {
    Id: 24644,
    Code: '8133',
    Name: 'Engineman, hydraulic'
  },
  {
    Id: 24645,
    Code: '8133',
    Name: 'Engineman'
  },
  {
    Id: 24646,
    Code: '8133',
    Name: 'Feeder, boiler'
  },
  {
    Id: 24647,
    Code: '8133',
    Name: 'Fireman, boiler'
  },
  {
    Id: 24648,
    Code: '8133',
    Name: 'Fireman, engine'
  },
  {
    Id: 24649,
    Code: '8133',
    Name: 'Fireman, surface'
  },
  {
    Id: 24650,
    Code: '8133',
    Name: 'Fireman, boiler'
  },
  {
    Id: 24651,
    Code: '8133',
    Name: 'Fireman, coal mine: above ground'
  },
  {
    Id: 24652,
    Code: '8133',
    Name: 'Fireman, electricity supply'
  },
  {
    Id: 24653,
    Code: '8133',
    Name: 'Firer, boiler'
  },
  {
    Id: 24654,
    Code: '8133',
    Name: 'Foreman, boiler'
  },
  {
    Id: 24655,
    Code: '8133',
    Name: 'Foreman, house, boiler'
  },
  {
    Id: 24656,
    Code: '8133',
    Name: 'Foreman, house, power'
  },
  {
    Id: 24657,
    Code: '8133',
    Name: 'Foreman, plant, coal, electricity supplier'
  },
  {
    Id: 24658,
    Code: '8133',
    Name: 'Foreman, turbine'
  },
  {
    Id: 24659,
    Code: '8133',
    Name: 'Foreman, power station'
  },
  {
    Id: 24660,
    Code: '8133',
    Name: 'Furnaceman, boiler'
  },
  {
    Id: 24661,
    Code: '8133',
    Name: 'Hand, compressor'
  },
  {
    Id: 24662,
    Code: '8133',
    Name: 'Hand, maintenance, boilers'
  },
  {
    Id: 24663,
    Code: '8133',
    Name: 'Hand, switch'
  },
  {
    Id: 24664,
    Code: '8133',
    Name: 'Hand, switchboard'
  },
  {
    Id: 24665,
    Code: '8133',
    Name: 'Houseman, boiler'
  },
  {
    Id: 24666,
    Code: '8133',
    Name: 'Houseman, power'
  },
  {
    Id: 24667,
    Code: '8133',
    Name: 'Inspector, sub-station, electricity supplier'
  },
  {
    Id: 24668,
    Code: '8133',
    Name: 'Machinist, stationary'
  },
  {
    Id: 24669,
    Code: '8133',
    Name: 'Man, compressor'
  },
  {
    Id: 24670,
    Code: '8133',
    Name: 'Man, gas, assistant, iron and steelworks'
  },
  {
    Id: 24671,
    Code: '8133',
    Name: 'Man, house, power'
  },
  {
    Id: 24672,
    Code: '8133',
    Name: 'Man, maintenance, boilers'
  },
  {
    Id: 24673,
    Code: '8133',
    Name: 'Man, motor'
  },
  {
    Id: 24674,
    Code: '8133',
    Name: "Mate, stoker's"
  },
  {
    Id: 24675,
    Code: '8133',
    Name: 'Minder, boiler'
  },
  {
    Id: 24676,
    Code: '8133',
    Name: 'Minder, engine'
  },
  {
    Id: 24677,
    Code: '8133',
    Name: 'Officer, decommissioning, nuclear fuel production'
  },
  {
    Id: 24678,
    Code: '8133',
    Name: 'Operative, de-commissioning, nuclear power station'
  },
  {
    Id: 24679,
    Code: '8133',
    Name: 'Operator, boiler'
  },
  {
    Id: 24680,
    Code: '8133',
    Name: 'Operator, coal, power station'
  },
  {
    Id: 24681,
    Code: '8133',
    Name: 'Operator, compressor'
  },
  {
    Id: 24682,
    Code: '8133',
    Name: 'Operator, control, railways'
  },
  {
    Id: 24683,
    Code: '8133',
    Name: 'Operator, de-commissioning, nuclear fuel production'
  },
  {
    Id: 24684,
    Code: '8133',
    Name: 'Operator, de-commissioning, nuclear power station'
  },
  {
    Id: 24685,
    Code: '8133',
    Name: 'Operator, engine'
  },
  {
    Id: 24686,
    Code: '8133',
    Name: 'Operator, gearhead'
  },
  {
    Id: 24687,
    Code: '8133',
    Name: 'Operator, house, power'
  },
  {
    Id: 24688,
    Code: '8133',
    Name: 'Operator, plant, power'
  },
  {
    Id: 24689,
    Code: '8133',
    Name: 'Operator, plant, electricity supplier'
  },
  {
    Id: 24690,
    Code: '8133',
    Name: 'Operator, plant, nuclear power station'
  },
  {
    Id: 24691,
    Code: '8133',
    Name: 'Operator, room, control, electric'
  },
  {
    Id: 24692,
    Code: '8133',
    Name: 'Operator, room, control, electrical'
  },
  {
    Id: 24693,
    Code: '8133',
    Name: 'Operator, station, power'
  },
  {
    Id: 24694,
    Code: '8133',
    Name: 'Operator, switchboard, power station'
  },
  {
    Id: 24695,
    Code: '8133',
    Name: 'Operator, turbine'
  },
  {
    Id: 24696,
    Code: '8133',
    Name: 'Operator, unit'
  },
  {
    Id: 24697,
    Code: '8133',
    Name: 'Raiser, steam'
  },
  {
    Id: 24698,
    Code: '8133',
    Name: 'Regulator, steam'
  },
  {
    Id: 24699,
    Code: '8133',
    Name: 'Riser, steam'
  },
  {
    Id: 24700,
    Code: '8133',
    Name: 'Stoker, boiler'
  },
  {
    Id: 24701,
    Code: '8133',
    Name: 'Stoker, engine'
  },
  {
    Id: 24702,
    Code: '8133',
    Name: 'Stoker, furnace'
  },
  {
    Id: 24703,
    Code: '8133',
    Name: 'Stoker, leading'
  },
  {
    Id: 24704,
    Code: '8133',
    Name: 'Stoker, pressure, high'
  },
  {
    Id: 24705,
    Code: '8133',
    Name: 'Stoker'
  },
  {
    Id: 24706,
    Code: '8133',
    Name: 'Stoker-cleaner'
  },
  {
    Id: 24707,
    Code: '8133',
    Name: 'Stoker-engineer'
  },
  {
    Id: 24708,
    Code: '8133',
    Name: 'Stoker-mechanic'
  },
  {
    Id: 24709,
    Code: '8133',
    Name: 'Stoker-porter'
  },
  {
    Id: 24710,
    Code: '8133',
    Name: 'Superintendent, electrical, electricity supplier'
  },
  {
    Id: 24711,
    Code: '8133',
    Name: 'Superintendent, house, boiler'
  },
  {
    Id: 24712,
    Code: '8133',
    Name: 'Superintendent, house, turbine, electricity supplier'
  },
  {
    Id: 24713,
    Code: '8133',
    Name: 'Superintendent, power'
  },
  {
    Id: 24714,
    Code: '8133',
    Name: 'Superintendent, station, power'
  },
  {
    Id: 24715,
    Code: '8133',
    Name: 'Superintendent, works, electricity supplier'
  },
  {
    Id: 24716,
    Code: '8133',
    Name: 'Superintendent, electricity supplier'
  },
  {
    Id: 24717,
    Code: '8133',
    Name: 'Supervisor, decommissioning, nuclear fuel production'
  },
  {
    Id: 24718,
    Code: '8133',
    Name: 'Switchman, electricity supplier'
  },
  {
    Id: 24719,
    Code: '8133',
    Name: 'Tender, engine'
  },
  {
    Id: 24720,
    Code: '8133',
    Name: 'Tenter, boiler'
  },
  {
    Id: 24721,
    Code: '8133',
    Name: 'Tenter, engine, blowing, metal mfr'
  },
  {
    Id: 24722,
    Code: '8133',
    Name: 'Tenter, engine'
  },
  {
    Id: 24723,
    Code: '8133',
    Name: 'Worker, de-commissioning, nuclear power station'
  },
  {
    Id: 24724,
    Code: '8133',
    Name: 'Worker, engine'
  },
  {
    Id: 24725,
    Code: '8133',
    Name: 'Worker, house, power'
  },
  {
    Id: 24726,
    Code: '8133',
    Name: 'Worker, power'
  },
  {
    Id: 24727,
    Code: '8134',
    Name: 'Assistant, distribution, water company'
  },
  {
    Id: 24728,
    Code: '8134',
    Name: 'Assistant, mains, water company'
  },
  {
    Id: 24729,
    Code: '8134',
    Name: 'Attendant, bed, bacteria'
  },
  {
    Id: 24730,
    Code: '8134',
    Name: 'Attendant, bed, filter, water works'
  },
  {
    Id: 24731,
    Code: '8134',
    Name: 'Attendant, filter, water works'
  },
  {
    Id: 24732,
    Code: '8134',
    Name: 'Attendant, house, filter, water works'
  },
  {
    Id: 24733,
    Code: '8134',
    Name: 'Attendant, house, press'
  },
  {
    Id: 24734,
    Code: '8134',
    Name: 'Attendant, plant, purifying, water'
  },
  {
    Id: 24735,
    Code: '8134',
    Name: 'Attendant, plant, sewage'
  },
  {
    Id: 24736,
    Code: '8134',
    Name: 'Attendant, plant, softening, water'
  },
  {
    Id: 24737,
    Code: '8134',
    Name: 'Attendant, plant, treatment, water'
  },
  {
    Id: 24738,
    Code: '8134',
    Name: 'Attendant, pump'
  },
  {
    Id: 24739,
    Code: '8134',
    Name: 'Attendant, pumping'
  },
  {
    Id: 24740,
    Code: '8134',
    Name: 'Attendant, room, pump'
  },
  {
    Id: 24741,
    Code: '8134',
    Name: 'Attendant, sewage'
  },
  {
    Id: 24742,
    Code: '8134',
    Name: 'Attendant, sewerage'
  },
  {
    Id: 24743,
    Code: '8134',
    Name: 'Attendant, softener, water'
  },
  {
    Id: 24744,
    Code: '8134',
    Name: 'Attendant, sprinkler'
  },
  {
    Id: 24745,
    Code: '8134',
    Name: 'Attendant, station, pumping'
  },
  {
    Id: 24746,
    Code: '8134',
    Name: 'Attendant, syphon'
  },
  {
    Id: 24747,
    Code: '8134',
    Name: 'Attendant, treatment, water'
  },
  {
    Id: 24748,
    Code: '8134',
    Name: 'Attendant, water'
  },
  {
    Id: 24749,
    Code: '8134',
    Name: 'Attendant, works, outfall'
  },
  {
    Id: 24750,
    Code: '8134',
    Name: 'Attendant, sewage farm'
  },
  {
    Id: 24751,
    Code: '8134',
    Name: 'Attendant, water works'
  },
  {
    Id: 24752,
    Code: '8134',
    Name: 'Chlorinator, water works'
  },
  {
    Id: 24753,
    Code: '8134',
    Name: 'Collector, sample, water'
  },
  {
    Id: 24754,
    Code: '8134',
    Name: 'Controller, process, sewage works'
  },
  {
    Id: 24755,
    Code: '8134',
    Name: 'Controller, process, water works'
  },
  {
    Id: 24756,
    Code: '8134',
    Name: 'Controller, shift, water treatment'
  },
  {
    Id: 24757,
    Code: '8134',
    Name: 'Controller, treatment, water'
  },
  {
    Id: 24758,
    Code: '8134',
    Name: 'Controller, water treatment'
  },
  {
    Id: 24759,
    Code: '8134',
    Name: 'Engineer, hygiene, water'
  },
  {
    Id: 24760,
    Code: '8134',
    Name: 'Engineer, pool, swimming'
  },
  {
    Id: 24761,
    Code: '8134',
    Name: 'Engineer, treatment, water'
  },
  {
    Id: 24762,
    Code: '8134',
    Name: 'Engineer, water'
  },
  {
    Id: 24763,
    Code: '8134',
    Name: 'Filterer, water works'
  },
  {
    Id: 24764,
    Code: '8134',
    Name: 'Flusher and cleanser, sewer'
  },
  {
    Id: 24765,
    Code: '8134',
    Name: 'Foreman, sewer'
  },
  {
    Id: 24766,
    Code: '8134',
    Name: 'Foreman, station, pumping'
  },
  {
    Id: 24767,
    Code: '8134',
    Name: 'Foreman, local government: sewage works'
  },
  {
    Id: 24768,
    Code: '8134',
    Name: 'Foreman, sewage works'
  },
  {
    Id: 24769,
    Code: '8134',
    Name: 'Foreman, water works'
  },
  {
    Id: 24770,
    Code: '8134',
    Name: 'Ganger, filtration, water works'
  },
  {
    Id: 24771,
    Code: '8134',
    Name: 'Inspector, district, water company'
  },
  {
    Id: 24772,
    Code: '8134',
    Name: 'Inspector, leakage, water company'
  },
  {
    Id: 24773,
    Code: '8134',
    Name: 'Inspector, sewer'
  },
  {
    Id: 24774,
    Code: '8134',
    Name: 'Machinist, sewage disposal'
  },
  {
    Id: 24775,
    Code: '8134',
    Name: 'Man, filter, sewage farm'
  },
  {
    Id: 24776,
    Code: '8134',
    Name: 'Man, sewerage, local government'
  },
  {
    Id: 24777,
    Code: '8134',
    Name: 'Operative, sewer'
  },
  {
    Id: 24778,
    Code: '8134',
    Name: 'Operator, distribution, water company'
  },
  {
    Id: 24779,
    Code: '8134',
    Name: 'Operator, plant, softener, water'
  },
  {
    Id: 24780,
    Code: '8134',
    Name: 'Operator, plant, treatment, water'
  },
  {
    Id: 24781,
    Code: '8134',
    Name: 'Operator, plant, sewage works'
  },
  {
    Id: 24782,
    Code: '8134',
    Name: 'Operator, plant, water works'
  },
  {
    Id: 24783,
    Code: '8134',
    Name: 'Operator, process, water treatment'
  },
  {
    Id: 24784,
    Code: '8134',
    Name: 'Operator, pump'
  },
  {
    Id: 24785,
    Code: '8134',
    Name: 'Operator, room, control, water company'
  },
  {
    Id: 24786,
    Code: '8134',
    Name: 'Operator, sewage'
  },
  {
    Id: 24787,
    Code: '8134',
    Name: 'Operator, sewer'
  },
  {
    Id: 24788,
    Code: '8134',
    Name: 'Operator, softener, water'
  },
  {
    Id: 24789,
    Code: '8134',
    Name: 'Operator, treatment, sewage'
  },
  {
    Id: 24790,
    Code: '8134',
    Name: 'Operator, treatment, water'
  },
  {
    Id: 24791,
    Code: '8134',
    Name: 'Operator, works, sewage'
  },
  {
    Id: 24792,
    Code: '8134',
    Name: 'Operator, water company'
  },
  {
    Id: 24793,
    Code: '8134',
    Name: 'Screener, sewage disposal'
  },
  {
    Id: 24794,
    Code: '8134',
    Name: 'Sewerman'
  },
  {
    Id: 24795,
    Code: '8134',
    Name: 'Softener, water'
  },
  {
    Id: 24796,
    Code: '8134',
    Name: 'Superintendent, distribution, water works'
  },
  {
    Id: 24797,
    Code: '8134',
    Name: 'Superintendent, works, water'
  },
  {
    Id: 24798,
    Code: '8134',
    Name: 'Superintendent, works, water company'
  },
  {
    Id: 24799,
    Code: '8134',
    Name: 'Superintendent, water company'
  },
  {
    Id: 24800,
    Code: '8134',
    Name: 'Technician, distribution, water company'
  },
  {
    Id: 24801,
    Code: '8134',
    Name: 'Technician, leakage'
  },
  {
    Id: 24802,
    Code: '8134',
    Name: 'Technician, pool, swimming'
  },
  {
    Id: 24803,
    Code: '8134',
    Name: 'Technician, service, environmental'
  },
  {
    Id: 24804,
    Code: '8134',
    Name: 'Technician, services, environmental, water treatment'
  },
  {
    Id: 24805,
    Code: '8134',
    Name: 'Technician, treatment, water'
  },
  {
    Id: 24806,
    Code: '8134',
    Name: 'Technician, water'
  },
  {
    Id: 24807,
    Code: '8134',
    Name: 'Turncock'
  },
  {
    Id: 24808,
    Code: '8134',
    Name: 'Valveman, water works'
  },
  {
    Id: 24809,
    Code: '8134',
    Name: 'Waterman, local government'
  },
  {
    Id: 24810,
    Code: '8134',
    Name: 'Waterman, sewage disposal'
  },
  {
    Id: 24811,
    Code: '8134',
    Name: 'Waterman, water works'
  },
  {
    Id: 24812,
    Code: '8134',
    Name: 'Worker, board, water'
  },
  {
    Id: 24813,
    Code: '8134',
    Name: 'Worker, farm, sewage'
  },
  {
    Id: 24814,
    Code: '8134',
    Name: 'Worker, sewage'
  },
  {
    Id: 24815,
    Code: '8134',
    Name: 'Worker, treatment, water'
  },
  {
    Id: 24816,
    Code: '8135',
    Name: 'Assistant, general, printing'
  },
  {
    Id: 24817,
    Code: '8135',
    Name: 'Assistant, lab, photo'
  },
  {
    Id: 24818,
    Code: '8135',
    Name: 'Assistant, letterpress'
  },
  {
    Id: 24819,
    Code: '8135',
    Name: "Assistant, minder's, machine, printing"
  },
  {
    Id: 24820,
    Code: '8135',
    Name: 'Assistant, press, printing'
  },
  {
    Id: 24821,
    Code: '8135',
    Name: 'Assistant, print'
  },
  {
    Id: 24822,
    Code: '8135',
    Name: "Assistant, printer's"
  },
  {
    Id: 24823,
    Code: '8135',
    Name: 'Assistant, printing'
  },
  {
    Id: 24824,
    Code: '8135',
    Name: 'Assistant, room, machine, newspaper printing'
  },
  {
    Id: 24825,
    Code: '8135',
    Name: 'Assistant, room, print'
  },
  {
    Id: 24826,
    Code: '8135',
    Name: 'Assistant, rotary, printing'
  },
  {
    Id: 24827,
    Code: '8135',
    Name: 'Assistant, shop, print'
  },
  {
    Id: 24828,
    Code: '8135',
    Name: 'Attendant, room, print'
  },
  {
    Id: 24829,
    Code: '8135',
    Name: 'Beveller, printing'
  },
  {
    Id: 24830,
    Code: '8135',
    Name: 'Boy, machine, printing'
  },
  {
    Id: 24831,
    Code: '8135',
    Name: 'Brakesman, printing'
  },
  {
    Id: 24832,
    Code: '8135',
    Name: 'Clammer, roller engraving'
  },
  {
    Id: 24833,
    Code: '8135',
    Name: 'Clamper, roller engraving'
  },
  {
    Id: 24834,
    Code: '8135',
    Name: 'Cleaner, glass, dry plates'
  },
  {
    Id: 24835,
    Code: '8135',
    Name: 'Cleaner, plate, printing'
  },
  {
    Id: 24836,
    Code: '8135',
    Name: 'Crater, manufacturing: printing'
  },
  {
    Id: 24837,
    Code: '8135',
    Name: 'Creaser, printing'
  },
  {
    Id: 24838,
    Code: '8135',
    Name: 'Cutter, die, engraving'
  },
  {
    Id: 24839,
    Code: '8135',
    Name: 'Cutter, letter, die sinking'
  },
  {
    Id: 24840,
    Code: '8135',
    Name: 'Cutter, punch'
  },
  {
    Id: 24841,
    Code: '8135',
    Name: 'Cutter, roller'
  },
  {
    Id: 24842,
    Code: '8135',
    Name: 'Cutter, seal'
  },
  {
    Id: 24843,
    Code: '8135',
    Name: 'Cutter, printing'
  },
  {
    Id: 24844,
    Code: '8135',
    Name: 'Decorator, plate, tin'
  },
  {
    Id: 24845,
    Code: '8135',
    Name: "Driver, printer's"
  },
  {
    Id: 24846,
    Code: '8135',
    Name: 'Embosser, hat mfr'
  },
  {
    Id: 24847,
    Code: '8135',
    Name: 'Embosser, paper goods mfr'
  },
  {
    Id: 24848,
    Code: '8135',
    Name: 'Feeder, letterpress'
  },
  {
    Id: 24849,
    Code: '8135',
    Name: 'Feeder, platen'
  },
  {
    Id: 24850,
    Code: '8135',
    Name: "Feeder, printer's"
  },
  {
    Id: 24851,
    Code: '8135',
    Name: 'Feeder, printing'
  },
  {
    Id: 24852,
    Code: '8135',
    Name: 'Hand, brake, printing'
  },
  {
    Id: 24853,
    Code: '8135',
    Name: 'Hand, controller, printing'
  },
  {
    Id: 24854,
    Code: '8135',
    Name: 'Hand, floor, printing'
  },
  {
    Id: 24855,
    Code: '8135',
    Name: 'Hand, fly'
  },
  {
    Id: 24856,
    Code: '8135',
    Name: 'Hand, news'
  },
  {
    Id: 24857,
    Code: '8135',
    Name: 'Hand, reel'
  },
  {
    Id: 24858,
    Code: '8135',
    Name: 'Hand, tension'
  },
  {
    Id: 24859,
    Code: '8135',
    Name: "Help, printer's"
  },
  {
    Id: 24860,
    Code: '8135',
    Name: "Helper, printer's"
  },
  {
    Id: 24861,
    Code: '8135',
    Name: "Jobber, printer's"
  },
  {
    Id: 24862,
    Code: '8135',
    Name: 'Layer-on, machine'
  },
  {
    Id: 24863,
    Code: '8135',
    Name: 'Leveller, lithography'
  },
  {
    Id: 24864,
    Code: '8135',
    Name: 'Lithographer, printing'
  },
  {
    Id: 24865,
    Code: '8135',
    Name: 'Loader, lithography'
  },
  {
    Id: 24866,
    Code: '8135',
    Name: 'Machinist, collating'
  },
  {
    Id: 24867,
    Code: '8135',
    Name: 'Machinist, cylinder, printing'
  },
  {
    Id: 24868,
    Code: '8135',
    Name: 'Machinist, decollating'
  },
  {
    Id: 24869,
    Code: '8135',
    Name: 'Machinist, duplicating'
  },
  {
    Id: 24870,
    Code: '8135',
    Name: 'Machinist, dyeline'
  },
  {
    Id: 24871,
    Code: '8135',
    Name: 'Machinist, folding, printing'
  },
  {
    Id: 24872,
    Code: '8135',
    Name: 'Machinist, gravure, printing'
  },
  {
    Id: 24873,
    Code: '8135',
    Name: 'Machinist, lithographic'
  },
  {
    Id: 24874,
    Code: '8135',
    Name: 'Machinist, photogravure'
  },
  {
    Id: 24875,
    Code: '8135',
    Name: 'Machinist, platen'
  },
  {
    Id: 24876,
    Code: '8135',
    Name: 'Machinist, press, letter'
  },
  {
    Id: 24877,
    Code: '8135',
    Name: 'Machinist, press, printing'
  },
  {
    Id: 24878,
    Code: '8135',
    Name: "Machinist, printer's"
  },
  {
    Id: 24879,
    Code: '8135',
    Name: 'Machinist, printing'
  },
  {
    Id: 24880,
    Code: '8135',
    Name: 'Machinist, rotary'
  },
  {
    Id: 24881,
    Code: '8135',
    Name: 'Machinist, tinplate'
  },
  {
    Id: 24882,
    Code: '8135',
    Name: 'Machinist, relief stamping'
  },
  {
    Id: 24883,
    Code: '8135',
    Name: 'Machinist, printing'
  },
  {
    Id: 24884,
    Code: '8135',
    Name: 'Machinist, wallpaper mfr: wallpaper printing'
  },
  {
    Id: 24885,
    Code: '8135',
    Name: 'Maker, map'
  },
  {
    Id: 24886,
    Code: '8135',
    Name: 'Maker, ticket, reel'
  },
  {
    Id: 24887,
    Code: '8135',
    Name: 'Man, table, printing'
  },
  {
    Id: 24888,
    Code: '8135',
    Name: 'Manager, machine, collotype'
  },
  {
    Id: 24889,
    Code: '8135',
    Name: 'Manager, machine, letterpress'
  },
  {
    Id: 24890,
    Code: '8135',
    Name: 'Manager, machine, lithographic'
  },
  {
    Id: 24891,
    Code: '8135',
    Name: 'Manager, machine, photogravure'
  },
  {
    Id: 24892,
    Code: '8135',
    Name: 'Manager, machine, printing'
  },
  {
    Id: 24893,
    Code: '8135',
    Name: 'Manager, machine, printing'
  },
  {
    Id: 24894,
    Code: '8135',
    Name: "Mate, printer's"
  },
  {
    Id: 24895,
    Code: '8135',
    Name: 'Minder, machine, printing'
  },
  {
    Id: 24896,
    Code: '8135',
    Name: 'Minder, machine, printing'
  },
  {
    Id: 24897,
    Code: '8135',
    Name: 'Numberer, parts'
  },
  {
    Id: 24898,
    Code: '8135',
    Name: 'Numberer, printing'
  },
  {
    Id: 24899,
    Code: '8135',
    Name: 'Operative, finishing, printing'
  },
  {
    Id: 24900,
    Code: '8135',
    Name: "Operative, printer's"
  },
  {
    Id: 24901,
    Code: '8135',
    Name: 'Operator, dyeline'
  },
  {
    Id: 24902,
    Code: '8135',
    Name: 'Operator, folding, printing'
  },
  {
    Id: 24903,
    Code: '8135',
    Name: "Operator, gravure, printer's"
  },
  {
    Id: 24904,
    Code: '8135',
    Name: 'Operator, lab, photo'
  },
  {
    Id: 24905,
    Code: '8135',
    Name: 'Operator, line, dye'
  },
  {
    Id: 24906,
    Code: '8135',
    Name: 'Operator, print, photo'
  },
  {
    Id: 24907,
    Code: '8135',
    Name: 'Operator, print'
  },
  {
    Id: 24908,
    Code: '8135',
    Name: "Operator, printer's"
  },
  {
    Id: 24909,
    Code: '8135',
    Name: 'Operator, rotary, printing'
  },
  {
    Id: 24910,
    Code: '8135',
    Name: 'Plater, tin, tinplate printing'
  },
  {
    Id: 24911,
    Code: '8135',
    Name: 'Presser, rotary, printing'
  },
  {
    Id: 24912,
    Code: '8135',
    Name: 'Presser, printing'
  },
  {
    Id: 24913,
    Code: '8135',
    Name: 'Printer, blue'
  },
  {
    Id: 24914,
    Code: '8135',
    Name: 'Printer, dyeline'
  },
  {
    Id: 24915,
    Code: '8135',
    Name: 'Printer, light, arc'
  },
  {
    Id: 24916,
    Code: '8135',
    Name: 'Printer, office, drawing'
  },
  {
    Id: 24917,
    Code: '8135',
    Name: 'Printer, photostat'
  },
  {
    Id: 24918,
    Code: '8135',
    Name: 'Printer, phototype'
  },
  {
    Id: 24919,
    Code: '8135',
    Name: 'Printer, plan'
  },
  {
    Id: 24920,
    Code: '8135',
    Name: 'Printer, rota'
  },
  {
    Id: 24921,
    Code: '8135',
    Name: 'Proofer, lithography'
  },
  {
    Id: 24922,
    Code: '8135',
    Name: 'Puncher, card'
  },
  {
    Id: 24923,
    Code: '8135',
    Name: 'Puncher, label'
  },
  {
    Id: 24924,
    Code: '8135',
    Name: 'Puncher, paper'
  },
  {
    Id: 24925,
    Code: '8135',
    Name: 'Puncher, pattern, paper pattern mfr'
  },
  {
    Id: 24926,
    Code: '8135',
    Name: 'Puncher, paper goods mfr'
  },
  {
    Id: 24927,
    Code: '8135',
    Name: 'Racker, lithography'
  },
  {
    Id: 24928,
    Code: '8135',
    Name: 'Reeler, printing'
  },
  {
    Id: 24929,
    Code: '8135',
    Name: "Ruler, printer's"
  },
  {
    Id: 24930,
    Code: '8135',
    Name: 'Ruler, printing'
  },
  {
    Id: 24931,
    Code: '8135',
    Name: 'Ruler, textile printing'
  },
  {
    Id: 24932,
    Code: '8135',
    Name: 'Setter, machine, printing'
  },
  {
    Id: 24933,
    Code: '8135',
    Name: 'Sinker, die, printing'
  },
  {
    Id: 24934,
    Code: '8135',
    Name: 'Stamper, box'
  },
  {
    Id: 24935,
    Code: '8135',
    Name: 'Stamper, brush'
  },
  {
    Id: 24936,
    Code: '8135',
    Name: 'Stamper, bulb, electric'
  },
  {
    Id: 24937,
    Code: '8135',
    Name: 'Stamper, cloth'
  },
  {
    Id: 24938,
    Code: '8135',
    Name: 'Stamper, collar'
  },
  {
    Id: 24939,
    Code: '8135',
    Name: 'Stamper, die, printing'
  },
  {
    Id: 24940,
    Code: '8135',
    Name: 'Stamper, gold, ceramics mfr'
  },
  {
    Id: 24941,
    Code: '8135',
    Name: 'Stamper, gold, footwear mfr'
  },
  {
    Id: 24942,
    Code: '8135',
    Name: 'Stamper, gold, hat mfr'
  },
  {
    Id: 24943,
    Code: '8135',
    Name: 'Stamper, pattern, ceramics mfr'
  },
  {
    Id: 24944,
    Code: '8135',
    Name: 'Stamper, pattern, paper pattern mfr'
  },
  {
    Id: 24945,
    Code: '8135',
    Name: "Stamper, printer's"
  },
  {
    Id: 24946,
    Code: '8135',
    Name: 'Stamper, relief'
  },
  {
    Id: 24947,
    Code: '8135',
    Name: 'Stamper, size'
  },
  {
    Id: 24948,
    Code: '8135',
    Name: 'Stamper, soap'
  },
  {
    Id: 24949,
    Code: '8135',
    Name: 'Stamper, sock'
  },
  {
    Id: 24950,
    Code: '8135',
    Name: 'Stamper, ceramics mfr'
  },
  {
    Id: 24951,
    Code: '8135',
    Name: 'Stamper, footwear mfr'
  },
  {
    Id: 24952,
    Code: '8135',
    Name: 'Stamper, paper goods mfr'
  },
  {
    Id: 24953,
    Code: '8135',
    Name: 'Stamper, tannery'
  },
  {
    Id: 24954,
    Code: '8135',
    Name: 'Stamper, textile mfr'
  },
  {
    Id: 24955,
    Code: '8135',
    Name: 'Taker, impression, printing'
  },
  {
    Id: 24956,
    Code: '8135',
    Name: 'Taker-off, machine, printing'
  },
  {
    Id: 24957,
    Code: '8135',
    Name: 'Taker-off, ceramic transfer mfr'
  },
  {
    Id: 24958,
    Code: '8135',
    Name: 'Technician, bindery, printing'
  },
  {
    Id: 24959,
    Code: '8135',
    Name: 'Technician, laboratory, photo'
  },
  {
    Id: 24960,
    Code: '8135',
    Name: 'Technician, photo, retail trade'
  },
  {
    Id: 24961,
    Code: '8135',
    Name: 'Teerer, textile printing'
  },
  {
    Id: 24962,
    Code: '8135',
    Name: 'Tenter, back, textile mfr: textile printing'
  },
  {
    Id: 24963,
    Code: '8135',
    Name: 'Tenter, back, wallpaper printing'
  },
  {
    Id: 24964,
    Code: '8135',
    Name: 'Tierer'
  },
  {
    Id: 24965,
    Code: '8135',
    Name: 'Transferer, tinplate mfr'
  },
  {
    Id: 24966,
    Code: '8135',
    Name: 'Valver'
  },
  {
    Id: 24967,
    Code: '8135',
    Name: 'Worker, print'
  },
  {
    Id: 24968,
    Code: '8135',
    Name: 'Worker, process, printing'
  },
  {
    Id: 24969,
    Code: '8135',
    Name: 'Worker, process, textile mfr: textile printing'
  },
  {
    Id: 24970,
    Code: '8135',
    Name: 'Worker, table, printing'
  },
  {
    Id: 24971,
    Code: '8139',
    Name: 'Adjuster, lift'
  },
  {
    Id: 24972,
    Code: '8139',
    Name: 'Adjuster, weight'
  },
  {
    Id: 24973,
    Code: '8139',
    Name: 'Armourer, cable'
  },
  {
    Id: 24974,
    Code: '8139',
    Name: 'Armourer, hose'
  },
  {
    Id: 24975,
    Code: '8139',
    Name: 'Assistant, armouring'
  },
  {
    Id: 24976,
    Code: '8139',
    Name: "Assistant, glazer's"
  },
  {
    Id: 24977,
    Code: '8139',
    Name: 'Assistant, maintenance, machinery, plant'
  },
  {
    Id: 24978,
    Code: '8139',
    Name: 'Assistant, shop, spreading, cables'
  },
  {
    Id: 24979,
    Code: '8139',
    Name: "Assistant, valveman's"
  },
  {
    Id: 24980,
    Code: '8139',
    Name: 'Attendant, battery'
  },
  {
    Id: 24981,
    Code: '8139',
    Name: 'Attendant, bay, wash'
  },
  {
    Id: 24982,
    Code: '8139',
    Name: 'Attendant, lubrication'
  },
  {
    Id: 24983,
    Code: '8139',
    Name: 'Attendant, machinery, lift'
  },
  {
    Id: 24984,
    Code: '8139',
    Name: 'Attendant, plant, fume, lead mfr'
  },
  {
    Id: 24985,
    Code: '8139',
    Name: 'Backer, saw'
  },
  {
    Id: 24986,
    Code: '8139',
    Name: 'Baler, scrap'
  },
  {
    Id: 24987,
    Code: '8139',
    Name: 'Bander, textile mfr'
  },
  {
    Id: 24988,
    Code: '8139',
    Name: 'Beamer, wire weaving'
  },
  {
    Id: 24989,
    Code: '8139',
    Name: 'Bender, hook, fish'
  },
  {
    Id: 24990,
    Code: '8139',
    Name: 'Bender, knife'
  },
  {
    Id: 24991,
    Code: '8139',
    Name: 'Bender, pipe'
  },
  {
    Id: 24992,
    Code: '8139',
    Name: 'Bender, tube'
  },
  {
    Id: 24993,
    Code: '8139',
    Name: 'Bender, wire'
  },
  {
    Id: 24994,
    Code: '8139',
    Name: 'Borer, fancy comb, slide mfr'
  },
  {
    Id: 24995,
    Code: '8139',
    Name: 'Braider, wire'
  },
  {
    Id: 24996,
    Code: '8139',
    Name: 'Braider, cable mfr'
  },
  {
    Id: 24997,
    Code: '8139',
    Name: 'Braider, flexible tubing mfr'
  },
  {
    Id: 24998,
    Code: '8139',
    Name: 'Breaker, billet'
  },
  {
    Id: 24999,
    Code: '8139',
    Name: 'Breaker, boiler'
  },
  {
    Id: 25000,
    Code: '8139',
    Name: 'Breaker, car'
  },
  {
    Id: 25001,
    Code: '8139',
    Name: 'Breaker, engine'
  },
  {
    Id: 25002,
    Code: '8139',
    Name: 'Breaker, rail'
  },
  {
    Id: 25003,
    Code: '8139',
    Name: 'Breaker, scrap'
  },
  {
    Id: 25004,
    Code: '8139',
    Name: 'Breaker, ship'
  },
  {
    Id: 25005,
    Code: '8139',
    Name: 'Breaker, scrap merchants, breakers'
  },
  {
    Id: 25006,
    Code: '8139',
    Name: 'Breaker-off, type foundry'
  },
  {
    Id: 25007,
    Code: '8139',
    Name: 'Brusher, tube, railways'
  },
  {
    Id: 25008,
    Code: '8139',
    Name: 'Brusher, needle mfr'
  },
  {
    Id: 25009,
    Code: '8139',
    Name: 'Burner, scrap, scrap merchants, breakers'
  },
  {
    Id: 25010,
    Code: '8139',
    Name: 'Buttoner-up, bolts and nuts'
  },
  {
    Id: 25011,
    Code: '8139',
    Name: 'Capper and sealer, end'
  },
  {
    Id: 25012,
    Code: '8139',
    Name: 'Carrier, rivet'
  },
  {
    Id: 25013,
    Code: '8139',
    Name: 'Caser, die'
  },
  {
    Id: 25014,
    Code: '8139',
    Name: 'Catcher, rivet'
  },
  {
    Id: 25015,
    Code: '8139',
    Name: 'Chargeman, battery'
  },
  {
    Id: 25016,
    Code: '8139',
    Name: 'Charger, accumulator'
  },
  {
    Id: 25017,
    Code: '8139',
    Name: 'Charger, battery'
  },
  {
    Id: 25018,
    Code: '8139',
    Name: 'Chaser, metal'
  },
  {
    Id: 25019,
    Code: '8139',
    Name: 'Chipper, pneumatic'
  },
  {
    Id: 25020,
    Code: '8139',
    Name: 'Chipper, steel, steelworks'
  },
  {
    Id: 25021,
    Code: '8139',
    Name: 'Chipper, metal trades'
  },
  {
    Id: 25022,
    Code: '8139',
    Name: 'Clipper, top, card'
  },
  {
    Id: 25023,
    Code: '8139',
    Name: 'Closer, wire rope, cable mfr'
  },
  {
    Id: 25024,
    Code: '8139',
    Name: 'Clothier, card'
  },
  {
    Id: 25025,
    Code: '8139',
    Name: 'Coater, cathode'
  },
  {
    Id: 25026,
    Code: '8139',
    Name: 'Coater, filament'
  },
  {
    Id: 25027,
    Code: '8139',
    Name: 'Coiler, cable mfr'
  },
  {
    Id: 25028,
    Code: '8139',
    Name: 'Coiler, spring mfr'
  },
  {
    Id: 25029,
    Code: '8139',
    Name: 'Coiler, wire rope, cable mfr'
  },
  {
    Id: 25030,
    Code: '8139',
    Name: 'Coverer, bar, metal'
  },
  {
    Id: 25031,
    Code: '8139',
    Name: 'Coverer, insulated wire, cable mfr'
  },
  {
    Id: 25032,
    Code: '8139',
    Name: 'Crimper, cable mfr'
  },
  {
    Id: 25033,
    Code: '8139',
    Name: 'Cropper, metal trades'
  },
  {
    Id: 25034,
    Code: '8139',
    Name: 'Cutler'
  },
  {
    Id: 25035,
    Code: '8139',
    Name: 'Cutter, block, linoleum mfr'
  },
  {
    Id: 25036,
    Code: '8139',
    Name: 'Cutter, block, wallpaper mfr'
  },
  {
    Id: 25037,
    Code: '8139',
    Name: 'Cutter, button, pearl'
  },
  {
    Id: 25038,
    Code: '8139',
    Name: 'Cutter, design, printing'
  },
  {
    Id: 25039,
    Code: '8139',
    Name: 'Cutter, file'
  },
  {
    Id: 25040,
    Code: '8139',
    Name: 'Cutter, insulation'
  },
  {
    Id: 25041,
    Code: '8139',
    Name: 'Cutter, shell, pearl'
  },
  {
    Id: 25042,
    Code: '8139',
    Name: 'Cutter, tip, cemented carbide goods mfr'
  },
  {
    Id: 25043,
    Code: '8139',
    Name: 'Dipper, match mfr'
  },
  {
    Id: 25044,
    Code: '8139',
    Name: 'Dismantler, car'
  },
  {
    Id: 25045,
    Code: '8139',
    Name: 'Dismantler, machinery'
  },
  {
    Id: 25046,
    Code: '8139',
    Name: 'Dismantler, ship'
  },
  {
    Id: 25047,
    Code: '8139',
    Name: 'Dismantler, scrap merchants, breakers'
  },
  {
    Id: 25048,
    Code: '8139',
    Name: 'Doctor, saw'
  },
  {
    Id: 25049,
    Code: '8139',
    Name: 'Dresser, bow'
  },
  {
    Id: 25050,
    Code: '8139',
    Name: 'Dresser, card'
  },
  {
    Id: 25051,
    Code: '8139',
    Name: 'Dresser, heald'
  },
  {
    Id: 25052,
    Code: '8139',
    Name: 'Dresser, weld'
  },
  {
    Id: 25053,
    Code: '8139',
    Name: 'Driller, mica'
  },
  {
    Id: 25054,
    Code: '8139',
    Name: 'Driller, micanite'
  },
  {
    Id: 25055,
    Code: '8139',
    Name: 'Driver, calender, insulated wire, cable mfr'
  },
  {
    Id: 25056,
    Code: '8139',
    Name: 'Driver, closer, wire rope, cable mfr'
  },
  {
    Id: 25057,
    Code: '8139',
    Name: 'Driver, machine, armouring'
  },
  {
    Id: 25058,
    Code: '8139',
    Name: 'Driver, machine, cable'
  },
  {
    Id: 25059,
    Code: '8139',
    Name: 'Driver, machine, cabling'
  },
  {
    Id: 25060,
    Code: '8139',
    Name: 'Driver, machine, insulating'
  },
  {
    Id: 25061,
    Code: '8139',
    Name: 'Driver, machine, lapping'
  },
  {
    Id: 25062,
    Code: '8139',
    Name: 'Driver, machine, layer-up'
  },
  {
    Id: 25063,
    Code: '8139',
    Name: 'Driver, machine, tubing'
  },
  {
    Id: 25064,
    Code: '8139',
    Name: 'Driver, saw, metal trades'
  },
  {
    Id: 25065,
    Code: '8139',
    Name: 'Driver, shear, metal trades'
  },
  {
    Id: 25066,
    Code: '8139',
    Name: 'Driver, shears, metal trades'
  },
  {
    Id: 25067,
    Code: '8139',
    Name: 'Engineer, aerial, installation'
  },
  {
    Id: 25068,
    Code: '8139',
    Name: 'Engineer, battery'
  },
  {
    Id: 25069,
    Code: '8139',
    Name: 'Engineer, lubrication'
  },
  {
    Id: 25070,
    Code: '8139',
    Name: 'Engineer, semi-skilled'
  },
  {
    Id: 25071,
    Code: '8139',
    Name: 'Erector, aerial, television'
  },
  {
    Id: 25072,
    Code: '8139',
    Name: 'Erector, conveyor, coal mine'
  },
  {
    Id: 25073,
    Code: '8139',
    Name: 'Erector, duct, work'
  },
  {
    Id: 25074,
    Code: '8139',
    Name: 'Etcher, cutlery'
  },
  {
    Id: 25075,
    Code: '8139',
    Name: 'Etcher, cutlery mfr'
  },
  {
    Id: 25076,
    Code: '8139',
    Name: 'Etcher, tool mfr'
  },
  {
    Id: 25077,
    Code: '8139',
    Name: 'Examiner, shaft, coal mine'
  },
  {
    Id: 25078,
    Code: '8139',
    Name: 'Expander, tube'
  },
  {
    Id: 25079,
    Code: '8139',
    Name: 'Expander, tube mfr'
  },
  {
    Id: 25080,
    Code: '8139',
    Name: 'Extender, belt, coal mine'
  },
  {
    Id: 25081,
    Code: '8139',
    Name: 'Extender, conveyor, coal mine'
  },
  {
    Id: 25082,
    Code: '8139',
    Name: 'Extruder, machine, arc welding electrode mfr'
  },
  {
    Id: 25083,
    Code: '8139',
    Name: 'Fasher'
  },
  {
    Id: 25084,
    Code: '8139',
    Name: 'Feeder, bar'
  },
  {
    Id: 25085,
    Code: '8139',
    Name: 'Feeder, conveyor, metal trades'
  },
  {
    Id: 25086,
    Code: '8139',
    Name: 'Fettler, card'
  },
  {
    Id: 25087,
    Code: '8139',
    Name: 'Fettler, machine'
  },
  {
    Id: 25088,
    Code: '8139',
    Name: 'Fettler, woollen'
  },
  {
    Id: 25089,
    Code: '8139',
    Name: 'Fettler, textile mfr'
  },
  {
    Id: 25090,
    Code: '8139',
    Name: 'Filer, tool'
  },
  {
    Id: 25091,
    Code: '8139',
    Name: 'Filler, battery, accumulator mfr'
  },
  {
    Id: 25092,
    Code: '8139',
    Name: 'Filler, battery mfr'
  },
  {
    Id: 25093,
    Code: '8139',
    Name: 'Filler-up, card clothing mfr'
  },
  {
    Id: 25094,
    Code: '8139',
    Name: 'Finer, super, metal buckle mfr'
  },
  {
    Id: 25095,
    Code: '8139',
    Name: 'Finer, jewellery, plate mfr'
  },
  {
    Id: 25096,
    Code: '8139',
    Name: 'Finisher, card, card clothing mfr'
  },
  {
    Id: 25097,
    Code: '8139',
    Name: 'Finisher, metal trades'
  },
  {
    Id: 25098,
    Code: '8139',
    Name: 'Fitter, aerial, television'
  },
  {
    Id: 25099,
    Code: '8139',
    Name: 'Fitter, balustrade'
  },
  {
    Id: 25100,
    Code: '8139',
    Name: 'Fitter, box, foundry'
  },
  {
    Id: 25101,
    Code: '8139',
    Name: 'Fitter, scythe'
  },
  {
    Id: 25102,
    Code: '8139',
    Name: 'Flattener, wire mfr'
  },
  {
    Id: 25103,
    Code: '8139',
    Name: 'Flitter, coal mine'
  },
  {
    Id: 25104,
    Code: '8139',
    Name: 'Forker'
  },
  {
    Id: 25105,
    Code: '8139',
    Name: 'Former, accumulator'
  },
  {
    Id: 25106,
    Code: '8139',
    Name: 'Former, battery'
  },
  {
    Id: 25107,
    Code: '8139',
    Name: 'Former, cell, battery'
  },
  {
    Id: 25108,
    Code: '8139',
    Name: 'Former, filament'
  },
  {
    Id: 25109,
    Code: '8139',
    Name: 'Former, plate, tungsten'
  },
  {
    Id: 25110,
    Code: '8139',
    Name: 'Former, wire'
  },
  {
    Id: 25111,
    Code: '8139',
    Name: 'Glazer, assistant, metal trades'
  },
  {
    Id: 25112,
    Code: '8139',
    Name: 'Grainer, plate'
  },
  {
    Id: 25113,
    Code: '8139',
    Name: 'Grainer, printing'
  },
  {
    Id: 25114,
    Code: '8139',
    Name: 'Greaser, kiln'
  },
  {
    Id: 25115,
    Code: '8139',
    Name: 'Greaser, roll, cold'
  },
  {
    Id: 25116,
    Code: '8139',
    Name: 'Greaser, sheave'
  },
  {
    Id: 25117,
    Code: '8139',
    Name: 'Greaser'
  },
  {
    Id: 25118,
    Code: '8139',
    Name: 'Grinder, anvil'
  },
  {
    Id: 25119,
    Code: '8139',
    Name: 'Grinder, assistant, metal trades'
  },
  {
    Id: 25120,
    Code: '8139',
    Name: 'Grinder, billet, steelworks'
  },
  {
    Id: 25121,
    Code: '8139',
    Name: 'Grinder, bit, coal mine'
  },
  {
    Id: 25122,
    Code: '8139',
    Name: 'Grinder, blade'
  },
  {
    Id: 25123,
    Code: '8139',
    Name: 'Grinder, bow'
  },
  {
    Id: 25124,
    Code: '8139',
    Name: 'Grinder, burr'
  },
  {
    Id: 25125,
    Code: '8139',
    Name: 'Grinder, card'
  },
  {
    Id: 25126,
    Code: '8139',
    Name: 'Grinder, cardroom'
  },
  {
    Id: 25127,
    Code: '8139',
    Name: 'Grinder, clothing, card'
  },
  {
    Id: 25128,
    Code: '8139',
    Name: 'Grinder, comb'
  },
  {
    Id: 25129,
    Code: '8139',
    Name: 'Grinder, dry, metal'
  },
  {
    Id: 25130,
    Code: '8139',
    Name: 'Grinder, jobbing'
  },
  {
    Id: 25131,
    Code: '8139',
    Name: 'Grinder, needle'
  },
  {
    Id: 25132,
    Code: '8139',
    Name: 'Grinder, scissors'
  },
  {
    Id: 25133,
    Code: '8139',
    Name: 'Grinder, steel'
  },
  {
    Id: 25134,
    Code: '8139',
    Name: 'Grinder, stone, wet'
  },
  {
    Id: 25135,
    Code: '8139',
    Name: 'Grinder, straight'
  },
  {
    Id: 25136,
    Code: '8139',
    Name: 'Grinder, surface, carbon goods mfr'
  },
  {
    Id: 25137,
    Code: '8139',
    Name: 'Grinder, swing'
  },
  {
    Id: 25138,
    Code: '8139',
    Name: 'Grinder, wet'
  },
  {
    Id: 25139,
    Code: '8139',
    Name: 'Grinder, wheel, emery'
  },
  {
    Id: 25140,
    Code: '8139',
    Name: 'Grinder, abrasive paper, cloth mfr'
  },
  {
    Id: 25141,
    Code: '8139',
    Name: 'Grinder, brake linings mfr'
  },
  {
    Id: 25142,
    Code: '8139',
    Name: 'Grinder, carbon goods mfr'
  },
  {
    Id: 25143,
    Code: '8139',
    Name: 'Grinder and polisher, metal trades'
  },
  {
    Id: 25144,
    Code: '8139',
    Name: 'Gunner, steelworks'
  },
  {
    Id: 25145,
    Code: '8139',
    Name: 'Hafter'
  },
  {
    Id: 25146,
    Code: '8139',
    Name: 'Hammerer, saw'
  },
  {
    Id: 25147,
    Code: '8139',
    Name: 'Hand, bench, saw'
  },
  {
    Id: 25148,
    Code: '8139',
    Name: 'Hand, bench, metal trades'
  },
  {
    Id: 25149,
    Code: '8139',
    Name: 'Hand, charging, battery'
  },
  {
    Id: 25150,
    Code: '8139',
    Name: 'Hand, guillotine, metal trades'
  },
  {
    Id: 25151,
    Code: '8139',
    Name: 'Hand, knife, metal trades'
  },
  {
    Id: 25152,
    Code: '8139',
    Name: 'Hand, leading, metal trades'
  },
  {
    Id: 25153,
    Code: '8139',
    Name: 'Hand, maintenance, electrical'
  },
  {
    Id: 25154,
    Code: '8139',
    Name: 'Hand, maintenance, machine'
  },
  {
    Id: 25155,
    Code: '8139',
    Name: 'Hand, maintenance, machinery'
  },
  {
    Id: 25156,
    Code: '8139',
    Name: 'Hand, maintenance, coal mine'
  },
  {
    Id: 25157,
    Code: '8139',
    Name: 'Hand, maintenance, mine: not coal'
  },
  {
    Id: 25158,
    Code: '8139',
    Name: 'Hand, wire, cable mfr'
  },
  {
    Id: 25159,
    Code: '8139',
    Name: 'Handyman, nos, gas works'
  },
  {
    Id: 25160,
    Code: '8139',
    Name: 'Handyman, nos, water works'
  },
  {
    Id: 25161,
    Code: '8139',
    Name: 'Header, bolt'
  },
  {
    Id: 25162,
    Code: '8139',
    Name: 'Header, cold, rivets'
  },
  {
    Id: 25163,
    Code: '8139',
    Name: 'Header, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 25164,
    Code: '8139',
    Name: 'Header-up, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 25165,
    Code: '8139',
    Name: 'Heater, rivet'
  },
  {
    Id: 25166,
    Code: '8139',
    Name: "Helper, cutter's"
  },
  {
    Id: 25167,
    Code: '8139',
    Name: 'Helper, frame'
  },
  {
    Id: 25168,
    Code: '8139',
    Name: "Helper, repairer's, coal mine"
  },
  {
    Id: 25169,
    Code: '8139',
    Name: 'Holer, button, button mfr'
  },
  {
    Id: 25170,
    Code: '8139',
    Name: 'Houseman, press, coal mine'
  },
  {
    Id: 25171,
    Code: '8139',
    Name: 'Inspector, steel, coal mine'
  },
  {
    Id: 25172,
    Code: '8139',
    Name: 'Installer, aerial'
  },
  {
    Id: 25173,
    Code: '8139',
    Name: 'Insulator, cable'
  },
  {
    Id: 25174,
    Code: '8139',
    Name: 'Insulator, electrical'
  },
  {
    Id: 25175,
    Code: '8139',
    Name: 'Insulator, thermal, electrical appliances mfr'
  },
  {
    Id: 25176,
    Code: '8139',
    Name: 'Joggler'
  },
  {
    Id: 25177,
    Code: '8139',
    Name: 'Jointer, cutlery mfr'
  },
  {
    Id: 25178,
    Code: '8139',
    Name: 'Kerner, type foundry'
  },
  {
    Id: 25179,
    Code: '8139',
    Name: 'Keysmith'
  },
  {
    Id: 25180,
    Code: '8139',
    Name: 'Lapper, paper'
  },
  {
    Id: 25181,
    Code: '8139',
    Name: 'Lapper, cable mfr'
  },
  {
    Id: 25182,
    Code: '8139',
    Name: 'Layer, wire rope, cable mfr'
  },
  {
    Id: 25183,
    Code: '8139',
    Name: 'Layer-up, cable mfr'
  },
  {
    Id: 25184,
    Code: '8139',
    Name: 'Lubricator'
  },
  {
    Id: 25185,
    Code: '8139',
    Name: 'Machinist, armouring'
  },
  {
    Id: 25186,
    Code: '8139',
    Name: 'Machinist, battery'
  },
  {
    Id: 25187,
    Code: '8139',
    Name: 'Machinist, bending, sheet metal working'
  },
  {
    Id: 25188,
    Code: '8139',
    Name: 'Machinist, bolt'
  },
  {
    Id: 25189,
    Code: '8139',
    Name: 'Machinist, braiding, cable mfr'
  },
  {
    Id: 25190,
    Code: '8139',
    Name: 'Machinist, bullet'
  },
  {
    Id: 25191,
    Code: '8139',
    Name: 'Machinist, button'
  },
  {
    Id: 25192,
    Code: '8139',
    Name: 'Machinist, cable'
  },
  {
    Id: 25193,
    Code: '8139',
    Name: 'Machinist, cabling'
  },
  {
    Id: 25194,
    Code: '8139',
    Name: 'Machinist, carbon'
  },
  {
    Id: 25195,
    Code: '8139',
    Name: 'Machinist, closing, wire rope, cable mfr'
  },
  {
    Id: 25196,
    Code: '8139',
    Name: 'Machinist, cork'
  },
  {
    Id: 25197,
    Code: '8139',
    Name: 'Machinist, cutting, core'
  },
  {
    Id: 25198,
    Code: '8139',
    Name: 'Machinist, cutting, plate, shipbuilding'
  },
  {
    Id: 25199,
    Code: '8139',
    Name: 'Machinist, cutting, rotary, metal'
  },
  {
    Id: 25200,
    Code: '8139',
    Name: 'Machinist, cutting, cork'
  },
  {
    Id: 25201,
    Code: '8139',
    Name: 'Machinist, cutting, metal'
  },
  {
    Id: 25202,
    Code: '8139',
    Name: 'Machinist, cutting, metal trades'
  },
  {
    Id: 25203,
    Code: '8139',
    Name: "Machinist, engraver's"
  },
  {
    Id: 25204,
    Code: '8139',
    Name: 'Machinist, eyelet'
  },
  {
    Id: 25205,
    Code: '8139',
    Name: 'Machinist, grading, garment pattern'
  },
  {
    Id: 25206,
    Code: '8139',
    Name: 'Machinist, guillotine, metal trades'
  },
  {
    Id: 25207,
    Code: '8139',
    Name: 'Machinist, heading, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 25208,
    Code: '8139',
    Name: 'Machinist, joggling'
  },
  {
    Id: 25209,
    Code: '8139',
    Name: 'Machinist, making, screw'
  },
  {
    Id: 25210,
    Code: '8139',
    Name: 'Machinist, milling, cemented carbide goods mfr'
  },
  {
    Id: 25211,
    Code: '8139',
    Name: 'Machinist, nail'
  },
  {
    Id: 25212,
    Code: '8139',
    Name: 'Machinist, pin'
  },
  {
    Id: 25213,
    Code: '8139',
    Name: 'Machinist, planing, plate'
  },
  {
    Id: 25214,
    Code: '8139',
    Name: 'Machinist, rivet'
  },
  {
    Id: 25215,
    Code: '8139',
    Name: 'Machinist, rolling, sheet metal working'
  },
  {
    Id: 25216,
    Code: '8139',
    Name: 'Machinist, rope, wire'
  },
  {
    Id: 25217,
    Code: '8139',
    Name: 'Machinist, sanding, micanite'
  },
  {
    Id: 25218,
    Code: '8139',
    Name: 'Machinist, sawing, metal trades'
  },
  {
    Id: 25219,
    Code: '8139',
    Name: 'Machinist, shearing, metal trades'
  },
  {
    Id: 25220,
    Code: '8139',
    Name: 'Machinist, socket'
  },
  {
    Id: 25221,
    Code: '8139',
    Name: 'Machinist, spring'
  },
  {
    Id: 25222,
    Code: '8139',
    Name: 'Machinist, stranding'
  },
  {
    Id: 25223,
    Code: '8139',
    Name: 'Machinist, taping, cable mfr'
  },
  {
    Id: 25224,
    Code: '8139',
    Name: 'Machinist, trim, vehicle mfr'
  },
  {
    Id: 25225,
    Code: '8139',
    Name: 'Machinist, trimming, brushes'
  },
  {
    Id: 25226,
    Code: '8139',
    Name: 'Machinist, asbestos-cement goods mfr'
  },
  {
    Id: 25227,
    Code: '8139',
    Name: 'Machinist, basket mfr'
  },
  {
    Id: 25228,
    Code: '8139',
    Name: 'Machinist, battery, accumulator mfr'
  },
  {
    Id: 25229,
    Code: '8139',
    Name: 'Machinist, brake linings mfr'
  },
  {
    Id: 25230,
    Code: '8139',
    Name: 'Machinist, broom, brush mfr'
  },
  {
    Id: 25231,
    Code: '8139',
    Name: 'Machinist, button mfr'
  },
  {
    Id: 25232,
    Code: '8139',
    Name: 'Machinist, cable mfr'
  },
  {
    Id: 25233,
    Code: '8139',
    Name: 'Machinist, carbon goods mfr'
  },
  {
    Id: 25234,
    Code: '8139',
    Name: 'Machinist, cold storage'
  },
  {
    Id: 25235,
    Code: '8139',
    Name: 'Machinist, cork stopper mfr'
  },
  {
    Id: 25236,
    Code: '8139',
    Name: 'Machinist, fishing rod mfr'
  },
  {
    Id: 25237,
    Code: '8139',
    Name: 'Machinist, lamp, valve mfr'
  },
  {
    Id: 25238,
    Code: '8139',
    Name: 'Machinist, metal fastener mfr'
  },
  {
    Id: 25239,
    Code: '8139',
    Name: 'Machinist, metal smallwares mfr'
  },
  {
    Id: 25240,
    Code: '8139',
    Name: 'Machinist, metal trades: card clothing mfr'
  },
  {
    Id: 25241,
    Code: '8139',
    Name: 'Machinist, metal trades: reed mfr'
  },
  {
    Id: 25242,
    Code: '8139',
    Name: 'Machinist, surgical goods mfr'
  },
  {
    Id: 25243,
    Code: '8139',
    Name: 'Machinist, textile mfr: textile printing'
  },
  {
    Id: 25244,
    Code: '8139',
    Name: 'Machinist, toy mfr'
  },
  {
    Id: 25245,
    Code: '8139',
    Name: 'Machinist, wire goods mfr'
  },
  {
    Id: 25246,
    Code: '8139',
    Name: 'Machinist, wire rope mfr'
  },
  {
    Id: 25247,
    Code: '8139',
    Name: 'Maker, barb, barbed wire'
  },
  {
    Id: 25248,
    Code: '8139',
    Name: 'Maker, basket, wire'
  },
  {
    Id: 25249,
    Code: '8139',
    Name: 'Maker, basket, wire goods mfr'
  },
  {
    Id: 25250,
    Code: '8139',
    Name: 'Maker, battery, electric'
  },
  {
    Id: 25251,
    Code: '8139',
    Name: 'Maker, block, breeze'
  },
  {
    Id: 25252,
    Code: '8139',
    Name: 'Maker, block, building'
  },
  {
    Id: 25253,
    Code: '8139',
    Name: 'Maker, block, carbon'
  },
  {
    Id: 25254,
    Code: '8139',
    Name: 'Maker, bobbin, metal'
  },
  {
    Id: 25255,
    Code: '8139',
    Name: 'Maker, bobbin, electric battery mfr'
  },
  {
    Id: 25256,
    Code: '8139',
    Name: 'Maker, bolt'
  },
  {
    Id: 25257,
    Code: '8139',
    Name: 'Maker, box, match'
  },
  {
    Id: 25258,
    Code: '8139',
    Name: 'Maker, box, match mfr'
  },
  {
    Id: 25259,
    Code: '8139',
    Name: 'Maker, brush, twisted-in'
  },
  {
    Id: 25260,
    Code: '8139',
    Name: 'Maker, brush, carbon, electric'
  },
  {
    Id: 25261,
    Code: '8139',
    Name: 'Maker, bullet'
  },
  {
    Id: 25262,
    Code: '8139',
    Name: 'Maker, button'
  },
  {
    Id: 25263,
    Code: '8139',
    Name: 'Maker, cable, wire'
  },
  {
    Id: 25264,
    Code: '8139',
    Name: 'Maker, cable, electric cable mfr'
  },
  {
    Id: 25265,
    Code: '8139',
    Name: 'Maker, cable, spring mfr'
  },
  {
    Id: 25266,
    Code: '8139',
    Name: 'Maker, cell, accumulator, battery mfr'
  },
  {
    Id: 25267,
    Code: '8139',
    Name: 'Maker, chain, metal'
  },
  {
    Id: 25268,
    Code: '8139',
    Name: 'Maker, chain, metal trades'
  },
  {
    Id: 25269,
    Code: '8139',
    Name: 'Maker, core, cable'
  },
  {
    Id: 25270,
    Code: '8139',
    Name: 'Maker, crate, steel'
  },
  {
    Id: 25271,
    Code: '8139',
    Name: 'Maker, crayon'
  },
  {
    Id: 25272,
    Code: '8139',
    Name: 'Maker, doctor'
  },
  {
    Id: 25273,
    Code: '8139',
    Name: 'Maker, electrode, carbon'
  },
  {
    Id: 25274,
    Code: '8139',
    Name: 'Maker, element'
  },
  {
    Id: 25275,
    Code: '8139',
    Name: 'Maker, feed, fountain pen mfr'
  },
  {
    Id: 25276,
    Code: '8139',
    Name: 'Maker, frame, handbag'
  },
  {
    Id: 25277,
    Code: '8139',
    Name: 'Maker, frame, hood'
  },
  {
    Id: 25278,
    Code: '8139',
    Name: 'Maker, frame, wire'
  },
  {
    Id: 25279,
    Code: '8139',
    Name: 'Maker, goods, abrasive'
  },
  {
    Id: 25280,
    Code: '8139',
    Name: 'Maker, guard, wire goods mfr'
  },
  {
    Id: 25281,
    Code: '8139',
    Name: 'Maker, heald'
  },
  {
    Id: 25282,
    Code: '8139',
    Name: 'Maker, leather, comb'
  },
  {
    Id: 25283,
    Code: '8139',
    Name: 'Maker, lighter, fire'
  },
  {
    Id: 25284,
    Code: '8139',
    Name: 'Maker, mantle, gas'
  },
  {
    Id: 25285,
    Code: '8139',
    Name: 'Maker, mantle, incandescent'
  },
  {
    Id: 25286,
    Code: '8139',
    Name: 'Maker, marker, footwear mfr'
  },
  {
    Id: 25287,
    Code: '8139',
    Name: 'Maker, match'
  },
  {
    Id: 25288,
    Code: '8139',
    Name: 'Maker, matrix, type foundry'
  },
  {
    Id: 25289,
    Code: '8139',
    Name: 'Maker, mattress, spring'
  },
  {
    Id: 25290,
    Code: '8139',
    Name: 'Maker, mattress, wire'
  },
  {
    Id: 25291,
    Code: '8139',
    Name: 'Maker, nail, cut'
  },
  {
    Id: 25292,
    Code: '8139',
    Name: 'Maker, needle'
  },
  {
    Id: 25293,
    Code: '8139',
    Name: 'Maker, nib, pen'
  },
  {
    Id: 25294,
    Code: '8139',
    Name: 'Maker, pen'
  },
  {
    Id: 25295,
    Code: '8139',
    Name: 'Maker, pencil'
  },
  {
    Id: 25296,
    Code: '8139',
    Name: 'Maker, rib, umbrella'
  },
  {
    Id: 25297,
    Code: '8139',
    Name: 'Maker, rivet'
  },
  {
    Id: 25298,
    Code: '8139',
    Name: 'Maker, roll, dandy'
  },
  {
    Id: 25299,
    Code: '8139',
    Name: 'Maker, rope, metal'
  },
  {
    Id: 25300,
    Code: '8139',
    Name: 'Maker, saw'
  },
  {
    Id: 25301,
    Code: '8139',
    Name: 'Maker, screw'
  },
  {
    Id: 25302,
    Code: '8139',
    Name: 'Maker, seat, spring'
  },
  {
    Id: 25303,
    Code: '8139',
    Name: 'Maker, sieve, wire goods mfr'
  },
  {
    Id: 25304,
    Code: '8139',
    Name: 'Maker, spindle'
  },
  {
    Id: 25305,
    Code: '8139',
    Name: 'Maker, spring'
  },
  {
    Id: 25306,
    Code: '8139',
    Name: 'Maker, tack'
  },
  {
    Id: 25307,
    Code: '8139',
    Name: 'Maker, taper'
  },
  {
    Id: 25308,
    Code: '8139',
    Name: 'Maker, tissue, carbon'
  },
  {
    Id: 25309,
    Code: '8139',
    Name: 'Maker, washer, micanite'
  },
  {
    Id: 25310,
    Code: '8139',
    Name: 'Maker, wick'
  },
  {
    Id: 25311,
    Code: '8139',
    Name: 'Maker-up, small chain mfr'
  },
  {
    Id: 25312,
    Code: '8139',
    Name: 'Man, battery, iron and steelworks'
  },
  {
    Id: 25313,
    Code: '8139',
    Name: 'Man, compo'
  },
  {
    Id: 25314,
    Code: '8139',
    Name: 'Man, maintenance, coal mine'
  },
  {
    Id: 25315,
    Code: '8139',
    Name: 'Man, safety, coal mine'
  },
  {
    Id: 25316,
    Code: '8139',
    Name: 'Man, saw, metal'
  },
  {
    Id: 25317,
    Code: '8139',
    Name: 'Man, steel, coal mine'
  },
  {
    Id: 25318,
    Code: '8139',
    Name: 'Man, turnover, coal mine'
  },
  {
    Id: 25319,
    Code: '8139',
    Name: 'Manipulator, tube, metal'
  },
  {
    Id: 25320,
    Code: '8139',
    Name: 'Manufacturer, button'
  },
  {
    Id: 25321,
    Code: '8139',
    Name: 'Manufacturer, furniture, metal'
  },
  {
    Id: 25322,
    Code: '8139',
    Name: 'Manufacturer, button mfr'
  },
  {
    Id: 25323,
    Code: '8139',
    Name: 'Manufacturer, furniture mfr: metal furniture'
  },
  {
    Id: 25324,
    Code: '8139',
    Name: 'Manufacturer, metal goods mfr'
  },
  {
    Id: 25325,
    Code: '8139',
    Name: 'Marker, clog iron mfr'
  },
  {
    Id: 25326,
    Code: '8139',
    Name: 'Marker, pen nib mfr'
  },
  {
    Id: 25327,
    Code: '8139',
    Name: 'Mechanic, surgical'
  },
  {
    Id: 25328,
    Code: '8139',
    Name: 'Mechanic, umbrella'
  },
  {
    Id: 25329,
    Code: '8139',
    Name: 'Mender, tub, coal mine'
  },
  {
    Id: 25330,
    Code: '8139',
    Name: 'Moulder, carbon'
  },
  {
    Id: 25331,
    Code: '8139',
    Name: 'Moulder, pipe, cast concrete'
  },
  {
    Id: 25332,
    Code: '8139',
    Name: 'Moulder, cork goods mfr'
  },
  {
    Id: 25333,
    Code: '8139',
    Name: 'Mover, conveyor, coal mine'
  },
  {
    Id: 25334,
    Code: '8139',
    Name: 'Nailer, card'
  },
  {
    Id: 25335,
    Code: '8139',
    Name: 'Oiler, frame, textile mfr'
  },
  {
    Id: 25336,
    Code: '8139',
    Name: 'Oiler, loom'
  },
  {
    Id: 25337,
    Code: '8139',
    Name: 'Oiler, machine, printing'
  },
  {
    Id: 25338,
    Code: '8139',
    Name: 'Oiler, machine, textile mfr'
  },
  {
    Id: 25339,
    Code: '8139',
    Name: 'Oiler'
  },
  {
    Id: 25340,
    Code: '8139',
    Name: 'Oiler and bander, textile mfr'
  },
  {
    Id: 25341,
    Code: '8139',
    Name: 'Oiler and beltman'
  },
  {
    Id: 25342,
    Code: '8139',
    Name: 'Oiler and cleaner'
  },
  {
    Id: 25343,
    Code: '8139',
    Name: 'Oiler and greaser'
  },
  {
    Id: 25344,
    Code: '8139',
    Name: 'Oilman, coal mine'
  },
  {
    Id: 25345,
    Code: '8139',
    Name: 'Operator, brake, steelworks'
  },
  {
    Id: 25346,
    Code: '8139',
    Name: 'Operator, cable, cable mfr'
  },
  {
    Id: 25347,
    Code: '8139',
    Name: 'Operator, combine'
  },
  {
    Id: 25348,
    Code: '8139',
    Name: 'Operator, compactor'
  },
  {
    Id: 25349,
    Code: '8139',
    Name: 'Operator, cooker, dry batteries'
  },
  {
    Id: 25350,
    Code: '8139',
    Name: 'Operator, cropper'
  },
  {
    Id: 25351,
    Code: '8139',
    Name: 'Operator, guillotine, coal mine'
  },
  {
    Id: 25352,
    Code: '8139',
    Name: 'Operator, guillotine, metal trades'
  },
  {
    Id: 25353,
    Code: '8139',
    Name: 'Operator, laser'
  },
  {
    Id: 25354,
    Code: '8139',
    Name: 'Operator, lathe, carbon goods mfr'
  },
  {
    Id: 25355,
    Code: '8139',
    Name: 'Operator, mill, sand, steelworks'
  },
  {
    Id: 25356,
    Code: '8139',
    Name: 'Operator, pantograph'
  },
  {
    Id: 25357,
    Code: '8139',
    Name: 'Operator, process, aircraft component mfr'
  },
  {
    Id: 25358,
    Code: '8139',
    Name: 'Operator, refrigerator'
  },
  {
    Id: 25359,
    Code: '8139',
    Name: 'Operator, saw, band, metal trades'
  },
  {
    Id: 25360,
    Code: '8139',
    Name: 'Operator, saw, metal'
  },
  {
    Id: 25361,
    Code: '8139',
    Name: 'Operator, saw, metal trades'
  },
  {
    Id: 25362,
    Code: '8139',
    Name: 'Operator, shear'
  },
  {
    Id: 25363,
    Code: '8139',
    Name: 'Operator, shears'
  },
  {
    Id: 25364,
    Code: '8139',
    Name: 'Operator, slitter, metal mfr'
  },
  {
    Id: 25365,
    Code: '8139',
    Name: 'Operator, spray, mechanical'
  },
  {
    Id: 25366,
    Code: '8139',
    Name: 'Operator, stretcher, metal mfr'
  },
  {
    Id: 25367,
    Code: '8139',
    Name: 'Operator, trimming, bullet'
  },
  {
    Id: 25368,
    Code: '8139',
    Name: 'Packer, gland'
  },
  {
    Id: 25369,
    Code: '8139',
    Name: 'Packer, wheel'
  },
  {
    Id: 25370,
    Code: '8139',
    Name: 'Parer, sheet, steelworks'
  },
  {
    Id: 25371,
    Code: '8139',
    Name: 'Parer, rolling mill'
  },
  {
    Id: 25372,
    Code: '8139',
    Name: 'Parer, saw mfr'
  },
  {
    Id: 25373,
    Code: '8139',
    Name: 'Paster, battery'
  },
  {
    Id: 25374,
    Code: '8139',
    Name: 'Paster, lead'
  },
  {
    Id: 25375,
    Code: '8139',
    Name: 'Paster, accumulator mfr'
  },
  {
    Id: 25376,
    Code: '8139',
    Name: 'Planer, edge, plate'
  },
  {
    Id: 25377,
    Code: '8139',
    Name: 'Pointer, bar'
  },
  {
    Id: 25378,
    Code: '8139',
    Name: 'Pointer, rod, wire mfr'
  },
  {
    Id: 25379,
    Code: '8139',
    Name: 'Pointer, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 25380,
    Code: '8139',
    Name: 'Pointer, wire mfr'
  },
  {
    Id: 25381,
    Code: '8139',
    Name: 'Polisher, button'
  },
  {
    Id: 25382,
    Code: '8139',
    Name: 'Polisher, pen, fountain'
  },
  {
    Id: 25383,
    Code: '8139',
    Name: 'Polisher, stone, lithography'
  },
  {
    Id: 25384,
    Code: '8139',
    Name: 'Polisher, tube'
  },
  {
    Id: 25385,
    Code: '8139',
    Name: 'Polisher, wire'
  },
  {
    Id: 25386,
    Code: '8139',
    Name: 'Preparer, cylinder'
  },
  {
    Id: 25387,
    Code: '8139',
    Name: 'Preparer, plate, lithographic'
  },
  {
    Id: 25388,
    Code: '8139',
    Name: 'Preparer, metal trades'
  },
  {
    Id: 25389,
    Code: '8139',
    Name: 'Preparer and sealer, cable mfr'
  },
  {
    Id: 25390,
    Code: '8139',
    Name: 'Presser, power, carbon goods mfr'
  },
  {
    Id: 25391,
    Code: '8139',
    Name: 'Presser, scale, knife handle mfr'
  },
  {
    Id: 25392,
    Code: '8139',
    Name: 'Presser, asbestos-cement goods mfr'
  },
  {
    Id: 25393,
    Code: '8139',
    Name: 'Presser, cable mfr'
  },
  {
    Id: 25394,
    Code: '8139',
    Name: 'Presser, electric battery mfr'
  },
  {
    Id: 25395,
    Code: '8139',
    Name: 'Puller, conveyor, coal mine'
  },
  {
    Id: 25396,
    Code: '8139',
    Name: 'Puller, coal mine'
  },
  {
    Id: 25397,
    Code: '8139',
    Name: 'Puller-up, coal mine'
  },
  {
    Id: 25398,
    Code: '8139',
    Name: 'Pulleyman, coal mine'
  },
  {
    Id: 25399,
    Code: '8139',
    Name: 'Pumper, syphon, gas supplier'
  },
  {
    Id: 25400,
    Code: '8139',
    Name: 'Pumper, lamp, valve mfr'
  },
  {
    Id: 25401,
    Code: '8139',
    Name: 'Pumper, mining'
  },
  {
    Id: 25402,
    Code: '8139',
    Name: 'Pumpman, still, vinegar mfr'
  },
  {
    Id: 25403,
    Code: '8139',
    Name: 'Pumpman'
  },
  {
    Id: 25404,
    Code: '8139',
    Name: 'Pumpman-dipper'
  },
  {
    Id: 25405,
    Code: '8139',
    Name: 'Pumpsman'
  },
  {
    Id: 25406,
    Code: '8139',
    Name: 'Reconditioner, girder'
  },
  {
    Id: 25407,
    Code: '8139',
    Name: 'Rectifier, cycle, motor'
  },
  {
    Id: 25408,
    Code: '8139',
    Name: 'Reeler, wire rope, cable mfr'
  },
  {
    Id: 25409,
    Code: '8139',
    Name: 'Regulator, gas, coal gas, coke ovens'
  },
  {
    Id: 25410,
    Code: '8139',
    Name: 'Remover, belt, coal mine'
  },
  {
    Id: 25411,
    Code: '8139',
    Name: 'Remover, conveyor, coal mine'
  },
  {
    Id: 25412,
    Code: '8139',
    Name: 'Repairer, saw'
  },
  {
    Id: 25413,
    Code: '8139',
    Name: 'Repairer, tub'
  },
  {
    Id: 25414,
    Code: '8139',
    Name: 'Reshearer, metal trades'
  },
  {
    Id: 25415,
    Code: '8139',
    Name: 'Rigger, aerial'
  },
  {
    Id: 25416,
    Code: '8139',
    Name: 'Rigger, gas works'
  },
  {
    Id: 25417,
    Code: '8139',
    Name: 'Rodder, tube mfr'
  },
  {
    Id: 25418,
    Code: '8139',
    Name: 'Roller, coal mine'
  },
  {
    Id: 25419,
    Code: '8139',
    Name: 'Router, printing plates'
  },
  {
    Id: 25420,
    Code: '8139',
    Name: 'Router and mounter'
  },
  {
    Id: 25421,
    Code: '8139',
    Name: 'Sawyer, back, metal'
  },
  {
    Id: 25422,
    Code: '8139',
    Name: 'Sawyer, band, metal'
  },
  {
    Id: 25423,
    Code: '8139',
    Name: 'Sawyer, hot'
  },
  {
    Id: 25424,
    Code: '8139',
    Name: 'Sawyer, rail'
  },
  {
    Id: 25425,
    Code: '8139',
    Name: 'Sawyer, roller'
  },
  {
    Id: 25426,
    Code: '8139',
    Name: 'Sawyer, metal'
  },
  {
    Id: 25427,
    Code: '8139',
    Name: 'Sawyer, steel tube mfr'
  },
  {
    Id: 25428,
    Code: '8139',
    Name: 'Scaler, boiler, ship'
  },
  {
    Id: 25429,
    Code: '8139',
    Name: "Scaler, boiler, ship's"
  },
  {
    Id: 25430,
    Code: '8139',
    Name: 'Scaler, boiler'
  },
  {
    Id: 25431,
    Code: '8139',
    Name: 'Scaler, boiler maintenance'
  },
  {
    Id: 25432,
    Code: '8139',
    Name: 'Scourer, pin'
  },
  {
    Id: 25433,
    Code: '8139',
    Name: 'Scourer, needle mfr'
  },
  {
    Id: 25434,
    Code: '8139',
    Name: 'Scraper, boiler'
  },
  {
    Id: 25435,
    Code: '8139',
    Name: 'Screwer, button, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 25436,
    Code: '8139',
    Name: 'Screwer, metal fastener mfr'
  },
  {
    Id: 25437,
    Code: '8139',
    Name: 'Scurfer, boiler'
  },
  {
    Id: 25438,
    Code: '8139',
    Name: 'Serrator, knives'
  },
  {
    Id: 25439,
    Code: '8139',
    Name: 'Servicer, machinery'
  },
  {
    Id: 25440,
    Code: '8139',
    Name: 'Setter, barrel'
  },
  {
    Id: 25441,
    Code: '8139',
    Name: 'Setter, circle, textile mfr'
  },
  {
    Id: 25442,
    Code: '8139',
    Name: 'Setter, file'
  },
  {
    Id: 25443,
    Code: '8139',
    Name: 'Setter, frame'
  },
  {
    Id: 25444,
    Code: '8139',
    Name: 'Setter, fuse, damper'
  },
  {
    Id: 25445,
    Code: '8139',
    Name: 'Setter, needle, textile mfr'
  },
  {
    Id: 25446,
    Code: '8139',
    Name: 'Setter, pin'
  },
  {
    Id: 25447,
    Code: '8139',
    Name: 'Setter, saw'
  },
  {
    Id: 25448,
    Code: '8139',
    Name: 'Setter, tool, edge'
  },
  {
    Id: 25449,
    Code: '8139',
    Name: 'Setter-out, mill, sawmilling'
  },
  {
    Id: 25450,
    Code: '8139',
    Name: 'Setter-up, type foundry'
  },
  {
    Id: 25451,
    Code: '8139',
    Name: 'Sewer, tape, textile spinning'
  },
  {
    Id: 25452,
    Code: '8139',
    Name: 'Shaftman, coal mine'
  },
  {
    Id: 25453,
    Code: '8139',
    Name: 'Shaper, filament'
  },
  {
    Id: 25454,
    Code: '8139',
    Name: 'Shaper, steel pen mfr'
  },
  {
    Id: 25455,
    Code: '8139',
    Name: 'Sharpener, saw'
  },
  {
    Id: 25456,
    Code: '8139',
    Name: 'Sharpener, edge tool mfr'
  },
  {
    Id: 25457,
    Code: '8139',
    Name: 'Shearer, billet, rolling mill'
  },
  {
    Id: 25458,
    Code: '8139',
    Name: 'Shearer, bloom'
  },
  {
    Id: 25459,
    Code: '8139',
    Name: 'Shearer, coil, metal trades'
  },
  {
    Id: 25460,
    Code: '8139',
    Name: 'Shearer, rotary'
  },
  {
    Id: 25461,
    Code: '8139',
    Name: 'Shearer, coal mine'
  },
  {
    Id: 25462,
    Code: '8139',
    Name: 'Shearer, metal trades'
  },
  {
    Id: 25463,
    Code: '8139',
    Name: 'Shearman, metal trades'
  },
  {
    Id: 25464,
    Code: '8139',
    Name: 'Shearsman, scrap, metal trades'
  },
  {
    Id: 25465,
    Code: '8139',
    Name: 'Sheavesman, coal mine'
  },
  {
    Id: 25466,
    Code: '8139',
    Name: 'Shifter, conveyor, coal mine'
  },
  {
    Id: 25467,
    Code: '8139',
    Name: 'Skiver, belt, abrasive paper, cloth mfr'
  },
  {
    Id: 25468,
    Code: '8139',
    Name: 'Sleever, cable mfr'
  },
  {
    Id: 25469,
    Code: '8139',
    Name: 'Slitter, foil, tin'
  },
  {
    Id: 25470,
    Code: '8139',
    Name: 'Slitter, metal'
  },
  {
    Id: 25471,
    Code: '8139',
    Name: 'Slitter, steel'
  },
  {
    Id: 25472,
    Code: '8139',
    Name: 'Slitter, pen nib mfr'
  },
  {
    Id: 25473,
    Code: '8139',
    Name: 'Slitter, steelworks'
  },
  {
    Id: 25474,
    Code: '8139',
    Name: 'Smith, cold'
  },
  {
    Id: 25475,
    Code: '8139',
    Name: 'Smith, key'
  },
  {
    Id: 25476,
    Code: '8139',
    Name: 'Smith, saw'
  },
  {
    Id: 25477,
    Code: '8139',
    Name: 'Spinner, wire'
  },
  {
    Id: 25478,
    Code: '8139',
    Name: 'Spinner, electric cable mfr'
  },
  {
    Id: 25479,
    Code: '8139',
    Name: 'Spinner, wire rope, cable mfr'
  },
  {
    Id: 25480,
    Code: '8139',
    Name: 'Spiraller, filament'
  },
  {
    Id: 25481,
    Code: '8139',
    Name: 'Spooler, wire'
  },
  {
    Id: 25482,
    Code: '8139',
    Name: 'Springer, needle mfr'
  },
  {
    Id: 25483,
    Code: '8139',
    Name: 'Stitcher, wire goods mfr'
  },
  {
    Id: 25484,
    Code: '8139',
    Name: 'Straightener, barrel'
  },
  {
    Id: 25485,
    Code: '8139',
    Name: 'Straightener, comb'
  },
  {
    Id: 25486,
    Code: '8139',
    Name: 'Straightener, hard, needle mfr'
  },
  {
    Id: 25487,
    Code: '8139',
    Name: 'Straightener, plate, iron'
  },
  {
    Id: 25488,
    Code: '8139',
    Name: 'Straightener, plate, saw'
  },
  {
    Id: 25489,
    Code: '8139',
    Name: 'Straightener, prop, coal mine'
  },
  {
    Id: 25490,
    Code: '8139',
    Name: 'Straightener, steel, coal mine'
  },
  {
    Id: 25491,
    Code: '8139',
    Name: 'Straightener, wire'
  },
  {
    Id: 25492,
    Code: '8139',
    Name: 'Straightener, coal mine'
  },
  {
    Id: 25493,
    Code: '8139',
    Name: 'Straightener, needle mfr'
  },
  {
    Id: 25494,
    Code: '8139',
    Name: 'Straightener, wire mfr'
  },
  {
    Id: 25495,
    Code: '8139',
    Name: 'Strander, wire'
  },
  {
    Id: 25496,
    Code: '8139',
    Name: 'Strander, cable mfr'
  },
  {
    Id: 25497,
    Code: '8139',
    Name: 'Strander, wire rope, cable mfr'
  },
  {
    Id: 25498,
    Code: '8139',
    Name: 'Stripper, card'
  },
  {
    Id: 25499,
    Code: '8139',
    Name: 'Stripper, file'
  },
  {
    Id: 25500,
    Code: '8139',
    Name: 'Stripper and grinder'
  },
  {
    Id: 25501,
    Code: '8139',
    Name: 'Stubber'
  },
  {
    Id: 25502,
    Code: '8139',
    Name: 'Supervisor, steel, coal mine'
  },
  {
    Id: 25503,
    Code: '8139',
    Name: 'Supporter, lamp, valve mfr'
  },
  {
    Id: 25504,
    Code: '8139',
    Name: 'Swager, cutlery mfr'
  },
  {
    Id: 25505,
    Code: '8139',
    Name: 'Swarfer, tube mfr'
  },
  {
    Id: 25506,
    Code: '8139',
    Name: 'Taper, cable mfr'
  },
  {
    Id: 25507,
    Code: '8139',
    Name: 'Taper, electrical goods mfr'
  },
  {
    Id: 25508,
    Code: '8139',
    Name: 'Tester-rectifier, cylinder'
  },
  {
    Id: 25509,
    Code: '8139',
    Name: 'Threader, heald, wire'
  },
  {
    Id: 25510,
    Code: '8139',
    Name: 'Tier, battery'
  },
  {
    Id: 25511,
    Code: '8139',
    Name: 'Toother, saw mfr'
  },
  {
    Id: 25512,
    Code: '8139',
    Name: 'Trimmer, bullet'
  },
  {
    Id: 25513,
    Code: '8139',
    Name: 'Trimmer, cable'
  },
  {
    Id: 25514,
    Code: '8139',
    Name: 'Trimmer, plate, metal trades'
  },
  {
    Id: 25515,
    Code: '8139',
    Name: 'Trimmer, gold, silver wire mfr'
  },
  {
    Id: 25516,
    Code: '8139',
    Name: 'Trouncer, metal trades'
  },
  {
    Id: 25517,
    Code: '8139',
    Name: 'Truer, wheel, cycle mfr'
  },
  {
    Id: 25518,
    Code: '8139',
    Name: 'Truer-up, wheel, cycle mfr'
  },
  {
    Id: 25519,
    Code: '8139',
    Name: 'Turner, belt, coal mine'
  },
  {
    Id: 25520,
    Code: '8139',
    Name: 'Turner, button'
  },
  {
    Id: 25521,
    Code: '8139',
    Name: 'Turner, conveyor, coal mine'
  },
  {
    Id: 25522,
    Code: '8139',
    Name: 'Turner, graphite'
  },
  {
    Id: 25523,
    Code: '8139',
    Name: 'Turner, wire'
  },
  {
    Id: 25524,
    Code: '8139',
    Name: 'Turner, cemented carbide goods mfr'
  },
  {
    Id: 25525,
    Code: '8139',
    Name: 'Twister, broom, brush mfr'
  },
  {
    Id: 25526,
    Code: '8139',
    Name: 'Valveman, cartridge mfr'
  },
  {
    Id: 25527,
    Code: '8139',
    Name: 'Veiner'
  },
  {
    Id: 25528,
    Code: '8139',
    Name: 'Warmer, rivet'
  },
  {
    Id: 25529,
    Code: '8139',
    Name: 'Washer, boiler'
  },
  {
    Id: 25530,
    Code: '8139',
    Name: 'Waxer, battery mfr'
  },
  {
    Id: 25531,
    Code: '8139',
    Name: 'Weaver, spring'
  },
  {
    Id: 25532,
    Code: '8139',
    Name: 'Wheeler, silver, plate mfr'
  },
  {
    Id: 25533,
    Code: '8139',
    Name: 'Wheelman, coal mine'
  },
  {
    Id: 25534,
    Code: '8139',
    Name: 'Whetter, cutlery'
  },
  {
    Id: 25535,
    Code: '8139',
    Name: 'Winder, bobbin, wire mfr'
  },
  {
    Id: 25536,
    Code: '8139',
    Name: 'Winder, cable'
  },
  {
    Id: 25537,
    Code: '8139',
    Name: 'Winder, copper, cable mfr'
  },
  {
    Id: 25538,
    Code: '8139',
    Name: 'Winder, core, cable mfr'
  },
  {
    Id: 25539,
    Code: '8139',
    Name: 'Winder, filament'
  },
  {
    Id: 25540,
    Code: '8139',
    Name: 'Winder, insulating, electrical engineering'
  },
  {
    Id: 25541,
    Code: '8139',
    Name: 'Winder, rope, wire'
  },
  {
    Id: 25542,
    Code: '8139',
    Name: 'Winder, wire'
  },
  {
    Id: 25543,
    Code: '8139',
    Name: 'Winder, cable mfr'
  },
  {
    Id: 25544,
    Code: '8139',
    Name: 'Winder, wire goods mfr'
  },
  {
    Id: 25545,
    Code: '8139',
    Name: 'Worker, bench, engineering'
  },
  {
    Id: 25546,
    Code: '8139',
    Name: 'Worker, button'
  },
  {
    Id: 25547,
    Code: '8139',
    Name: 'Worker, cable, cable mfr'
  },
  {
    Id: 25548,
    Code: '8139',
    Name: 'Worker, cutlery'
  },
  {
    Id: 25549,
    Code: '8139',
    Name: 'Worker, lead, accumulator mfr'
  },
  {
    Id: 25550,
    Code: '8139',
    Name: 'Worker, lino'
  },
  {
    Id: 25551,
    Code: '8139',
    Name: 'Worker, linoleum'
  },
  {
    Id: 25552,
    Code: '8139',
    Name: 'Worker, odd, engineering'
  },
  {
    Id: 25553,
    Code: '8139',
    Name: 'Worker, process, electrical engineering'
  },
  {
    Id: 25554,
    Code: '8139',
    Name: 'Worker, saw, hot'
  },
  {
    Id: 25555,
    Code: '8139',
    Name: 'Worker, spring'
  },
  {
    Id: 25556,
    Code: '8139',
    Name: 'Worker, tool, edge'
  },
  {
    Id: 25557,
    Code: '8139',
    Name: 'Worker, wire, cable mfr'
  },
  {
    Id: 25558,
    Code: '8139',
    Name: 'Worker, wire'
  },
  {
    Id: 25559,
    Code: '8139',
    Name: 'Worksetter'
  },
  {
    Id: 25560,
    Code: '8139',
    Name: 'Wrapper, cable'
  },
  {
    Id: 25561,
    Code: '8141',
    Name: 'Aligner, radio, television and video'
  },
  {
    Id: 25562,
    Code: '8141',
    Name: 'Assembler, accumulator'
  },
  {
    Id: 25563,
    Code: '8141',
    Name: 'Assembler, aerial'
  },
  {
    Id: 25564,
    Code: '8141',
    Name: 'Assembler, apparatus, electricity supplier'
  },
  {
    Id: 25565,
    Code: '8141',
    Name: 'Assembler, armature'
  },
  {
    Id: 25566,
    Code: '8141',
    Name: 'Assembler, Bakelite'
  },
  {
    Id: 25567,
    Code: '8141',
    Name: 'Assembler, battery'
  },
  {
    Id: 25568,
    Code: '8141',
    Name: 'Assembler, belt'
  },
  {
    Id: 25569,
    Code: '8141',
    Name: 'Assembler, board, circuit, printed'
  },
  {
    Id: 25570,
    Code: '8141',
    Name: 'Assembler, cable'
  },
  {
    Id: 25571,
    Code: '8141',
    Name: 'Assembler, change, record'
  },
  {
    Id: 25572,
    Code: '8141',
    Name: 'Assembler, coil'
  },
  {
    Id: 25573,
    Code: '8141',
    Name: 'Assembler, commutator'
  },
  {
    Id: 25574,
    Code: '8141',
    Name: 'Assembler, component, electrical, electronic'
  },
  {
    Id: 25575,
    Code: '8141',
    Name: 'Assembler, components, electrical, electronic'
  },
  {
    Id: 25576,
    Code: '8141',
    Name: 'Assembler, computer'
  },
  {
    Id: 25577,
    Code: '8141',
    Name: 'Assembler, cooker, electric'
  },
  {
    Id: 25578,
    Code: '8141',
    Name: 'Assembler, core, electrical engineering'
  },
  {
    Id: 25579,
    Code: '8141',
    Name: 'Assembler, crystal, quartz'
  },
  {
    Id: 25580,
    Code: '8141',
    Name: 'Assembler, dynamo'
  },
  {
    Id: 25581,
    Code: '8141',
    Name: 'Assembler, electrical'
  },
  {
    Id: 25582,
    Code: '8141',
    Name: 'Assembler, electronic'
  },
  {
    Id: 25583,
    Code: '8141',
    Name: 'Assembler, electronics'
  },
  {
    Id: 25584,
    Code: '8141',
    Name: 'Assembler, equipment, video'
  },
  {
    Id: 25585,
    Code: '8141',
    Name: 'Assembler, filament'
  },
  {
    Id: 25586,
    Code: '8141',
    Name: 'Assembler, fire, electric'
  },
  {
    Id: 25587,
    Code: '8141',
    Name: 'Assembler, gun, hand'
  },
  {
    Id: 25588,
    Code: '8141',
    Name: 'Assembler, instrument, electrical'
  },
  {
    Id: 25589,
    Code: '8141',
    Name: 'Assembler, instrument, telephone'
  },
  {
    Id: 25590,
    Code: '8141',
    Name: 'Assembler, lamp, electric'
  },
  {
    Id: 25591,
    Code: '8141',
    Name: 'Assembler, magnet'
  },
  {
    Id: 25592,
    Code: '8141',
    Name: 'Assembler, motor, electric'
  },
  {
    Id: 25593,
    Code: '8141',
    Name: 'Assembler, PCB'
  },
  {
    Id: 25594,
    Code: '8141',
    Name: 'Assembler, radar'
  },
  {
    Id: 25595,
    Code: '8141',
    Name: 'Assembler, radio'
  },
  {
    Id: 25596,
    Code: '8141',
    Name: 'Assembler, recorder, video'
  },
  {
    Id: 25597,
    Code: '8141',
    Name: 'Assembler, rectifier'
  },
  {
    Id: 25598,
    Code: '8141',
    Name: 'Assembler, relay'
  },
  {
    Id: 25599,
    Code: '8141',
    Name: 'Assembler, sign, neon'
  },
  {
    Id: 25600,
    Code: '8141',
    Name: 'Assembler, stator'
  },
  {
    Id: 25601,
    Code: '8141',
    Name: 'Assembler, stove, electric'
  },
  {
    Id: 25602,
    Code: '8141',
    Name: 'Assembler, switchboard'
  },
  {
    Id: 25603,
    Code: '8141',
    Name: 'Assembler, switchgear'
  },
  {
    Id: 25604,
    Code: '8141',
    Name: 'Assembler, system, stereo'
  },
  {
    Id: 25605,
    Code: '8141',
    Name: 'Assembler, telephone'
  },
  {
    Id: 25606,
    Code: '8141',
    Name: 'Assembler, television'
  },
  {
    Id: 25607,
    Code: '8141',
    Name: 'Assembler, temple'
  },
  {
    Id: 25608,
    Code: '8141',
    Name: 'Assembler, valve'
  },
  {
    Id: 25609,
    Code: '8141',
    Name: 'Assembler, accumulator mfr'
  },
  {
    Id: 25610,
    Code: '8141',
    Name: 'Assembler, calculating machines mfr'
  },
  {
    Id: 25611,
    Code: '8141',
    Name: 'Assembler, electrical, electronic equipment mfr'
  },
  {
    Id: 25612,
    Code: '8141',
    Name: 'Assembler, radio, television and video mfr'
  },
  {
    Id: 25613,
    Code: '8141',
    Name: 'Assembler, telecoms: equipment mfr'
  },
  {
    Id: 25614,
    Code: '8141',
    Name: 'Bander, armature'
  },
  {
    Id: 25615,
    Code: '8141',
    Name: 'Bender, copper, generators'
  },
  {
    Id: 25616,
    Code: '8141',
    Name: 'Bender, element'
  },
  {
    Id: 25617,
    Code: '8141',
    Name: 'Binder, armature'
  },
  {
    Id: 25618,
    Code: '8141',
    Name: 'Bonder, electrical'
  },
  {
    Id: 25619,
    Code: '8141',
    Name: 'Builder, condenser'
  },
  {
    Id: 25620,
    Code: '8141',
    Name: 'Builder, core'
  },
  {
    Id: 25621,
    Code: '8141',
    Name: 'Capper'
  },
  {
    Id: 25622,
    Code: '8141',
    Name: 'Cementer, electrical insulator mfr'
  },
  {
    Id: 25623,
    Code: '8141',
    Name: 'Coiler, electrical goods mfr'
  },
  {
    Id: 25624,
    Code: '8141',
    Name: 'Connector, armature'
  },
  {
    Id: 25625,
    Code: '8141',
    Name: 'Engineer, aerial'
  },
  {
    Id: 25626,
    Code: '8141',
    Name: 'Erector, battery'
  },
  {
    Id: 25627,
    Code: '8141',
    Name: 'Erector, cell, chemical mfr'
  },
  {
    Id: 25628,
    Code: '8141',
    Name: 'Filler, cap, lamp, valve mfr'
  },
  {
    Id: 25629,
    Code: '8141',
    Name: 'Finisher, coil'
  },
  {
    Id: 25630,
    Code: '8141',
    Name: 'Fixer, cap, lamp and valves'
  },
  {
    Id: 25631,
    Code: '8141',
    Name: 'Former, cable'
  },
  {
    Id: 25632,
    Code: '8141',
    Name: 'Former, coil'
  },
  {
    Id: 25633,
    Code: '8141',
    Name: 'Former, copper, generators'
  },
  {
    Id: 25634,
    Code: '8141',
    Name: 'Former, loom'
  },
  {
    Id: 25635,
    Code: '8141',
    Name: 'Hand, condenser'
  },
  {
    Id: 25636,
    Code: '8141',
    Name: 'Hand, transformer'
  },
  {
    Id: 25637,
    Code: '8141',
    Name: 'Inserter, coil'
  },
  {
    Id: 25638,
    Code: '8141',
    Name: 'Inserter, electrical, electronic equipment mfr'
  },
  {
    Id: 25639,
    Code: '8141',
    Name: 'Inserter, lamp, valve mfr'
  },
  {
    Id: 25640,
    Code: '8141',
    Name: 'Joiner, bulb, valve mfr'
  },
  {
    Id: 25641,
    Code: '8141',
    Name: 'Joiner, lamp, valve mfr'
  },
  {
    Id: 25642,
    Code: '8141',
    Name: 'Machinist, capping, lamp'
  },
  {
    Id: 25643,
    Code: '8141',
    Name: 'Maker, board, circuit, printed'
  },
  {
    Id: 25644,
    Code: '8141',
    Name: 'Maker, bulb, electric lamp mfr'
  },
  {
    Id: 25645,
    Code: '8141',
    Name: 'Maker, coil, electric'
  },
  {
    Id: 25646,
    Code: '8141',
    Name: 'Maker, condenser, electric'
  },
  {
    Id: 25647,
    Code: '8141',
    Name: 'Maker, fan, electrical goods mfr'
  },
  {
    Id: 25648,
    Code: '8141',
    Name: 'Maker, filament'
  },
  {
    Id: 25649,
    Code: '8141',
    Name: 'Maker, fire, electric'
  },
  {
    Id: 25650,
    Code: '8141',
    Name: 'Maker, form, cable mfr'
  },
  {
    Id: 25651,
    Code: '8141',
    Name: 'Maker, fuse'
  },
  {
    Id: 25652,
    Code: '8141',
    Name: 'Maker, harness, electrical'
  },
  {
    Id: 25653,
    Code: '8141',
    Name: 'Maker, lamp, electric'
  },
  {
    Id: 25654,
    Code: '8141',
    Name: 'Maker, lamp, glow'
  },
  {
    Id: 25655,
    Code: '8141',
    Name: 'Maker, pinch'
  },
  {
    Id: 25656,
    Code: '8141',
    Name: 'Maker, plug, sparking'
  },
  {
    Id: 25657,
    Code: '8141',
    Name: 'Maker, switch'
  },
  {
    Id: 25658,
    Code: '8141',
    Name: 'Maker, tube, television'
  },
  {
    Id: 25659,
    Code: '8141',
    Name: 'Maker, valve, radio valve mfr'
  },
  {
    Id: 25660,
    Code: '8141',
    Name: 'Marker, line, sub-assembly, radio mfr'
  },
  {
    Id: 25661,
    Code: '8141',
    Name: 'Mounter, filament'
  },
  {
    Id: 25662,
    Code: '8141',
    Name: 'Operator, circuit, printed'
  },
  {
    Id: 25663,
    Code: '8141',
    Name: 'Operator, line, electrical'
  },
  {
    Id: 25664,
    Code: '8141',
    Name: 'Operator, manufacturing, electrical, electronic equipment mfr'
  },
  {
    Id: 25665,
    Code: '8141',
    Name: 'Operator, mount, surface'
  },
  {
    Id: 25666,
    Code: '8141',
    Name: 'Operator, PCB'
  },
  {
    Id: 25667,
    Code: '8141',
    Name: 'Operator, rig, electrical, electronic equipment mfr'
  },
  {
    Id: 25668,
    Code: '8141',
    Name: 'Operator, SMT'
  },
  {
    Id: 25669,
    Code: '8141',
    Name: 'Operator, solder, flow'
  },
  {
    Id: 25670,
    Code: '8141',
    Name: 'Operator, technical, circuit board mfr'
  },
  {
    Id: 25671,
    Code: '8141',
    Name: 'Operator, tube, lamp, valve mfr'
  },
  {
    Id: 25672,
    Code: '8141',
    Name: 'Pinner, lamp, valve mfr'
  },
  {
    Id: 25673,
    Code: '8141',
    Name: 'Presser, coil'
  },
  {
    Id: 25674,
    Code: '8141',
    Name: 'Repairer, coil'
  },
  {
    Id: 25675,
    Code: '8141',
    Name: 'Rewinder, motor, electric'
  },
  {
    Id: 25676,
    Code: '8141',
    Name: 'Sealer, lamp, valve mfr'
  },
  {
    Id: 25677,
    Code: '8141',
    Name: 'Separator, plate, car battery'
  },
  {
    Id: 25678,
    Code: '8141',
    Name: 'Setter, flame'
  },
  {
    Id: 25679,
    Code: '8141',
    Name: 'Setter, thermostat'
  },
  {
    Id: 25680,
    Code: '8141',
    Name: 'Sleever, radio valve mfr'
  },
  {
    Id: 25681,
    Code: '8141',
    Name: 'Solderer, flow'
  },
  {
    Id: 25682,
    Code: '8141',
    Name: 'Solderer'
  },
  {
    Id: 25683,
    Code: '8141',
    Name: 'Specialist, production, electrical, electronic equipment mfr'
  },
  {
    Id: 25684,
    Code: '8141',
    Name: 'Technician, SMT'
  },
  {
    Id: 25685,
    Code: '8141',
    Name: 'Technician, technology, mount, surface'
  },
  {
    Id: 25686,
    Code: '8141',
    Name: 'Tester-rectifier, equipment, electrical'
  },
  {
    Id: 25687,
    Code: '8141',
    Name: 'Tester-rectifier, equipment, electronic'
  },
  {
    Id: 25688,
    Code: '8141',
    Name: 'Threader, lamp, valve mfr'
  },
  {
    Id: 25689,
    Code: '8141',
    Name: 'Winder, armature'
  },
  {
    Id: 25690,
    Code: '8141',
    Name: 'Winder, bobbin, electrical goods mfr'
  },
  {
    Id: 25691,
    Code: '8141',
    Name: 'Winder, coil'
  },
  {
    Id: 25692,
    Code: '8141',
    Name: 'Winder, disc, armature'
  },
  {
    Id: 25693,
    Code: '8141',
    Name: 'Winder, dynamo'
  },
  {
    Id: 25694,
    Code: '8141',
    Name: 'Winder, electrical'
  },
  {
    Id: 25695,
    Code: '8141',
    Name: 'Winder, element'
  },
  {
    Id: 25696,
    Code: '8141',
    Name: 'Winder, mesh'
  },
  {
    Id: 25697,
    Code: '8141',
    Name: 'Winder, motor, induction'
  },
  {
    Id: 25698,
    Code: '8141',
    Name: 'Winder, rotor'
  },
  {
    Id: 25699,
    Code: '8141',
    Name: 'Winder, spool, electrical goods mfr'
  },
  {
    Id: 25700,
    Code: '8141',
    Name: 'Winder, stator'
  },
  {
    Id: 25701,
    Code: '8141',
    Name: 'Winder, transformer'
  },
  {
    Id: 25702,
    Code: '8141',
    Name: 'Winder, turbo'
  },
  {
    Id: 25703,
    Code: '8141',
    Name: 'Winder, electrical goods mfr'
  },
  {
    Id: 25704,
    Code: '8141',
    Name: 'Wireman, indoor'
  },
  {
    Id: 25705,
    Code: '8141',
    Name: 'Wireman, instrument'
  },
  {
    Id: 25706,
    Code: '8141',
    Name: 'Wireman, radar'
  },
  {
    Id: 25707,
    Code: '8141',
    Name: 'Wireman'
  },
  {
    Id: 25708,
    Code: '8141',
    Name: 'Wireman-assembler'
  },
  {
    Id: 25709,
    Code: '8141',
    Name: 'Wirer, panel'
  },
  {
    Id: 25710,
    Code: '8141',
    Name: 'Wirer, electronic apparatus mfr'
  },
  {
    Id: 25711,
    Code: '8141',
    Name: 'Wirer and solderer, radio, television and video mfr'
  },
  {
    Id: 25712,
    Code: '8141',
    Name: 'Worker, factory, electrical goods mfr: assembling, soldering'
  },
  {
    Id: 25713,
    Code: '8141',
    Name: 'Worker, process, electrical domestic appliance mfr'
  },
  {
    Id: 25714,
    Code: '8141',
    Name: 'Worker, process, electrical, electronic equipment mfr'
  },
  {
    Id: 25715,
    Code: '8141',
    Name: 'Worker, process, lamp, valve mfr'
  },
  {
    Id: 25716,
    Code: '8142',
    Name: 'Assembler, body, vehicles'
  },
  {
    Id: 25717,
    Code: '8142',
    Name: 'Assembler, brake'
  },
  {
    Id: 25718,
    Code: '8142',
    Name: 'Assembler, brass'
  },
  {
    Id: 25719,
    Code: '8142',
    Name: 'Assembler, car'
  },
  {
    Id: 25720,
    Code: '8142',
    Name: 'Assembler, component, mechanical'
  },
  {
    Id: 25721,
    Code: '8142',
    Name: 'Assembler, components, mechanical'
  },
  {
    Id: 25722,
    Code: '8142',
    Name: 'Assembler, cooker, gas'
  },
  {
    Id: 25723,
    Code: '8142',
    Name: 'Assembler, cycle'
  },
  {
    Id: 25724,
    Code: '8142',
    Name: 'Assembler, detonator'
  },
  {
    Id: 25725,
    Code: '8142',
    Name: 'Assembler, engine'
  },
  {
    Id: 25726,
    Code: '8142',
    Name: 'Assembler, filter, machinery mfr'
  },
  {
    Id: 25727,
    Code: '8142',
    Name: 'Assembler, frame, bed'
  },
  {
    Id: 25728,
    Code: '8142',
    Name: 'Assembler, gun'
  },
  {
    Id: 25729,
    Code: '8142',
    Name: 'Assembler, jewellery'
  },
  {
    Id: 25730,
    Code: '8142',
    Name: 'Assembler, lamp'
  },
  {
    Id: 25731,
    Code: '8142',
    Name: 'Assembler, lock'
  },
  {
    Id: 25732,
    Code: '8142',
    Name: 'Assembler, machine'
  },
  {
    Id: 25733,
    Code: '8142',
    Name: 'Assembler, motor, engineering'
  },
  {
    Id: 25734,
    Code: '8142',
    Name: 'Assembler, refrigerator'
  },
  {
    Id: 25735,
    Code: '8142',
    Name: 'Assembler, rifle'
  },
  {
    Id: 25736,
    Code: '8142',
    Name: 'Assembler, seat, spring'
  },
  {
    Id: 25737,
    Code: '8142',
    Name: 'Assembler, spring'
  },
  {
    Id: 25738,
    Code: '8142',
    Name: 'Assembler, stove'
  },
  {
    Id: 25739,
    Code: '8142',
    Name: 'Assembler, tub'
  },
  {
    Id: 25740,
    Code: '8142',
    Name: "Assembler, valve, engineer's valves"
  },
  {
    Id: 25741,
    Code: '8142',
    Name: 'Assembler, vehicle, motor'
  },
  {
    Id: 25742,
    Code: '8142',
    Name: 'Assembler, cycle mfr'
  },
  {
    Id: 25743,
    Code: '8142',
    Name: 'Assembler, engineering'
  },
  {
    Id: 25744,
    Code: '8142',
    Name: 'Assembler, gun mfr'
  },
  {
    Id: 25745,
    Code: '8142',
    Name: 'Assembler, jewellery, plate mfr'
  },
  {
    Id: 25746,
    Code: '8142',
    Name: 'Assembler, metal trades'
  },
  {
    Id: 25747,
    Code: '8142',
    Name: 'Assembler, vehicle mfr'
  },
  {
    Id: 25748,
    Code: '8142',
    Name: 'Assembler, metal frames, window and door mfr'
  },
  {
    Id: 25749,
    Code: '8142',
    Name: 'Associate, general, vehicle mfr'
  },
  {
    Id: 25750,
    Code: '8142',
    Name: 'Associate, production, vehicle mfr'
  },
  {
    Id: 25751,
    Code: '8142',
    Name: 'Associate, vehicle mfr'
  },
  {
    Id: 25752,
    Code: '8142',
    Name: 'Bolter-up, metal trades'
  },
  {
    Id: 25753,
    Code: '8142',
    Name: 'Builder, wheel, vehicles'
  },
  {
    Id: 25754,
    Code: '8142',
    Name: 'Capper, bobbin'
  },
  {
    Id: 25755,
    Code: '8142',
    Name: 'Cementer, metal capsule mfr'
  },
  {
    Id: 25756,
    Code: '8142',
    Name: 'Coverer, corset mfr'
  },
  {
    Id: 25757,
    Code: '8142',
    Name: 'Crimper, detonator'
  },
  {
    Id: 25758,
    Code: '8142',
    Name: 'Finisher, brush, wire'
  },
  {
    Id: 25759,
    Code: '8142',
    Name: 'Finisher, watch, clock mfr'
  },
  {
    Id: 25760,
    Code: '8142',
    Name: 'Fitter, bar, handle'
  },
  {
    Id: 25761,
    Code: '8142',
    Name: 'Fitter, bonnet, vehicles'
  },
  {
    Id: 25762,
    Code: '8142',
    Name: 'Fitter, brake, cycle mfr'
  },
  {
    Id: 25763,
    Code: '8142',
    Name: 'Fitter, grip, tools'
  },
  {
    Id: 25764,
    Code: '8142',
    Name: 'Fitter, paragon, umbrellas'
  },
  {
    Id: 25765,
    Code: '8142',
    Name: 'Fitter, saddle, cycles'
  },
  {
    Id: 25766,
    Code: '8142',
    Name: 'Fitter, semi-skilled'
  },
  {
    Id: 25767,
    Code: '8142',
    Name: 'Fitter, stove, stove mfr'
  },
  {
    Id: 25768,
    Code: '8142',
    Name: 'Fitter, bag frame mfr'
  },
  {
    Id: 25769,
    Code: '8142',
    Name: 'Fitter, loose leaf book mfr'
  },
  {
    Id: 25770,
    Code: '8142',
    Name: 'Fixer, panel, vehicles'
  },
  {
    Id: 25771,
    Code: '8142',
    Name: 'Foreman, line, metal trades'
  },
  {
    Id: 25772,
    Code: '8142',
    Name: 'Giller, motor radiator mfr'
  },
  {
    Id: 25773,
    Code: '8142',
    Name: 'Hand, cycle'
  },
  {
    Id: 25774,
    Code: '8142',
    Name: 'Hanger, door, coach body'
  },
  {
    Id: 25775,
    Code: '8142',
    Name: 'Lacer, wheel'
  },
  {
    Id: 25776,
    Code: '8142',
    Name: 'Lacer and driller, wheel, cycle mfr'
  },
  {
    Id: 25777,
    Code: '8142',
    Name: 'Lineworker, vehicle mfr'
  },
  {
    Id: 25778,
    Code: '8142',
    Name: 'Machinist, production, vehicle mfr'
  },
  {
    Id: 25779,
    Code: '8142',
    Name: 'Machinist, riveting, metal trades'
  },
  {
    Id: 25780,
    Code: '8142',
    Name: 'Machinist, stapling, mattress, upholstery mfr'
  },
  {
    Id: 25781,
    Code: '8142',
    Name: 'Maker, bell, cycle bells'
  },
  {
    Id: 25782,
    Code: '8142',
    Name: 'Maker, bicycle'
  },
  {
    Id: 25783,
    Code: '8142',
    Name: 'Maker, brake, car'
  },
  {
    Id: 25784,
    Code: '8142',
    Name: 'Maker, carriage, invalid'
  },
  {
    Id: 25785,
    Code: '8142',
    Name: 'Maker, chair, metal furniture mfr'
  },
  {
    Id: 25786,
    Code: '8142',
    Name: 'Maker, cycle'
  },
  {
    Id: 25787,
    Code: '8142',
    Name: 'Maker, frame, umbrella'
  },
  {
    Id: 25788,
    Code: '8142',
    Name: 'Maker, hinge'
  },
  {
    Id: 25789,
    Code: '8142',
    Name: 'Maker, mattress, link'
  },
  {
    Id: 25790,
    Code: '8142',
    Name: 'Maker, wheel, cycle mfr'
  },
  {
    Id: 25791,
    Code: '8142',
    Name: 'Mounter, body'
  },
  {
    Id: 25792,
    Code: '8142',
    Name: 'Mounter, wing, coach body'
  },
  {
    Id: 25793,
    Code: '8142',
    Name: 'Mounter, vehicle building'
  },
  {
    Id: 25794,
    Code: '8142',
    Name: 'Nutter-up'
  },
  {
    Id: 25795,
    Code: '8142',
    Name: 'Operator, cell, vehicle mfr'
  },
  {
    Id: 25796,
    Code: '8142',
    Name: 'Operator, line, assembly, vehicle mfr'
  },
  {
    Id: 25797,
    Code: '8142',
    Name: 'Operator, line, trim, vehicle mfr'
  },
  {
    Id: 25798,
    Code: '8142',
    Name: 'Operator, line, engineering'
  },
  {
    Id: 25799,
    Code: '8142',
    Name: 'Operator, manufacturing, metal trades'
  },
  {
    Id: 25800,
    Code: '8142',
    Name: 'Pinner, comb, woollen'
  },
  {
    Id: 25801,
    Code: '8142',
    Name: 'Pinner, metal trades'
  },
  {
    Id: 25802,
    Code: '8142',
    Name: 'Riveter, bag frames'
  },
  {
    Id: 25803,
    Code: '8142',
    Name: 'Riveter, corsets'
  },
  {
    Id: 25804,
    Code: '8142',
    Name: 'Riveter, curry combs'
  },
  {
    Id: 25805,
    Code: '8142',
    Name: 'Riveter, umbrella ribs'
  },
  {
    Id: 25806,
    Code: '8142',
    Name: 'Sealer, car'
  },
  {
    Id: 25807,
    Code: '8142',
    Name: 'Setter, door, vehicle mfr'
  },
  {
    Id: 25808,
    Code: '8142',
    Name: 'Setter, jewel, watch mfr'
  },
  {
    Id: 25809,
    Code: '8142',
    Name: 'Springer, umbrella'
  },
  {
    Id: 25810,
    Code: '8142',
    Name: 'Stapler, mattress mfr'
  },
  {
    Id: 25811,
    Code: '8142',
    Name: 'Trackworker, vehicle mfr'
  },
  {
    Id: 25812,
    Code: '8142',
    Name: 'Wireman, cycle mfr'
  },
  {
    Id: 25813,
    Code: '8142',
    Name: 'Worker, car'
  },
  {
    Id: 25814,
    Code: '8142',
    Name: 'Worker, cycle'
  },
  {
    Id: 25815,
    Code: '8142',
    Name: 'Worker, process, metal trades: assembly'
  },
  {
    Id: 25816,
    Code: '8142',
    Name: 'Worker, process, vehicle mfr'
  },
  {
    Id: 25817,
    Code: '8143',
    Name: 'Adjuster, telephone'
  },
  {
    Id: 25818,
    Code: '8143',
    Name: 'Assessor, quality'
  },
  {
    Id: 25819,
    Code: '8143',
    Name: "Assistant, checker's, metal trades"
  },
  {
    Id: 25820,
    Code: '8143',
    Name: 'Assistant, control, quality'
  },
  {
    Id: 25821,
    Code: '8143',
    Name: "Assistant, inspector's, metal trades"
  },
  {
    Id: 25822,
    Code: '8143',
    Name: 'Assistant, quality'
  },
  {
    Id: 25823,
    Code: '8143',
    Name: 'Assistant, room, test, electrical'
  },
  {
    Id: 25824,
    Code: '8143',
    Name: "Assistant, tester's, meter"
  },
  {
    Id: 25825,
    Code: '8143',
    Name: 'Assorter, galvanised sheet'
  },
  {
    Id: 25826,
    Code: '8143',
    Name: 'Assorter, tinplate'
  },
  {
    Id: 25827,
    Code: '8143',
    Name: 'Attendant, room, sample, food products mfr'
  },
  {
    Id: 25828,
    Code: '8143',
    Name: 'Auditor, control, quality'
  },
  {
    Id: 25829,
    Code: '8143',
    Name: 'Auditor, quality'
  },
  {
    Id: 25830,
    Code: '8143',
    Name: 'Balancer, dynamic'
  },
  {
    Id: 25831,
    Code: '8143',
    Name: 'Balancer, dynamics'
  },
  {
    Id: 25832,
    Code: '8143',
    Name: 'Balancer, shaft, crank'
  },
  {
    Id: 25833,
    Code: '8143',
    Name: 'Balancer, wheel, railway workshops'
  },
  {
    Id: 25834,
    Code: '8143',
    Name: 'Balancer, engineering'
  },
  {
    Id: 25835,
    Code: '8143',
    Name: 'Brineller'
  },
  {
    Id: 25836,
    Code: '8143',
    Name: 'Candler, egg'
  },
  {
    Id: 25837,
    Code: '8143',
    Name: 'Catcher, cigarette'
  },
  {
    Id: 25838,
    Code: '8143',
    Name: 'Catcher, machine'
  },
  {
    Id: 25839,
    Code: '8143',
    Name: 'Certifier, order, money'
  },
  {
    Id: 25840,
    Code: '8143',
    Name: 'Checker, bank-note'
  },
  {
    Id: 25841,
    Code: '8143',
    Name: 'Checker, gauge'
  },
  {
    Id: 25842,
    Code: '8143',
    Name: 'Checker, ingot, steelworks'
  },
  {
    Id: 25843,
    Code: '8143',
    Name: 'Checker, iron'
  },
  {
    Id: 25844,
    Code: '8143',
    Name: 'Checker, machine, engineering'
  },
  {
    Id: 25845,
    Code: '8143',
    Name: 'Checker, mica'
  },
  {
    Id: 25846,
    Code: '8143',
    Name: 'Checker, milk'
  },
  {
    Id: 25847,
    Code: '8143',
    Name: 'Checker, moulding'
  },
  {
    Id: 25848,
    Code: '8143',
    Name: 'Checker, paper, paper mfr'
  },
  {
    Id: 25849,
    Code: '8143',
    Name: 'Checker, photographic, printed circuit board mfr'
  },
  {
    Id: 25850,
    Code: '8143',
    Name: 'Checker, electrical, electronic equipment'
  },
  {
    Id: 25851,
    Code: '8143',
    Name: 'Checker, nos'
  },
  {
    Id: 25852,
    Code: '8143',
    Name: 'Checker, bakery'
  },
  {
    Id: 25853,
    Code: '8143',
    Name: 'Checker, Bank of England'
  },
  {
    Id: 25854,
    Code: '8143',
    Name: 'Checker, brewery'
  },
  {
    Id: 25855,
    Code: '8143',
    Name: 'Checker, ceramics mfr'
  },
  {
    Id: 25856,
    Code: '8143',
    Name: 'Checker, clothing mfr'
  },
  {
    Id: 25857,
    Code: '8143',
    Name: 'Checker, drug mfr'
  },
  {
    Id: 25858,
    Code: '8143',
    Name: 'Checker, electrical, electronic engineering'
  },
  {
    Id: 25859,
    Code: '8143',
    Name: 'Checker, food products mfr'
  },
  {
    Id: 25860,
    Code: '8143',
    Name: 'Checker, metal trades'
  },
  {
    Id: 25861,
    Code: '8143',
    Name: 'Checker, paint mfr'
  },
  {
    Id: 25862,
    Code: '8143',
    Name: 'Checker, plastics goods mfr'
  },
  {
    Id: 25863,
    Code: '8143',
    Name: 'Checker, printing'
  },
  {
    Id: 25864,
    Code: '8143',
    Name: 'Checker, rubber goods mfr'
  },
  {
    Id: 25865,
    Code: '8143',
    Name: 'Checker, textile materials, products mfr'
  },
  {
    Id: 25866,
    Code: '8143',
    Name: 'Checker, wood products mfr'
  },
  {
    Id: 25867,
    Code: '8143',
    Name: 'Controller, quality'
  },
  {
    Id: 25868,
    Code: '8143',
    Name: 'Coordinator, quality, manufacturing'
  },
  {
    Id: 25869,
    Code: '8143',
    Name: 'Counter, paper'
  },
  {
    Id: 25870,
    Code: '8143',
    Name: 'Counter, paper mfr'
  },
  {
    Id: 25871,
    Code: '8143',
    Name: 'Detector, crack, metal mfr'
  },
  {
    Id: 25872,
    Code: '8143',
    Name: 'Drawer, cloth'
  },
  {
    Id: 25873,
    Code: '8143',
    Name: 'Drawer, textile mfr: lace mfr'
  },
  {
    Id: 25874,
    Code: '8143',
    Name: 'Engineer, inspection'
  },
  {
    Id: 25875,
    Code: '8143',
    Name: 'Engineer, inspector'
  },
  {
    Id: 25876,
    Code: '8143',
    Name: 'Engineer, maintenance, tyre'
  },
  {
    Id: 25877,
    Code: '8143',
    Name: 'Engineer, test, electrical'
  },
  {
    Id: 25878,
    Code: '8143',
    Name: 'Engineer, test'
  },
  {
    Id: 25879,
    Code: '8143',
    Name: 'Engineer, ultrasonic'
  },
  {
    Id: 25880,
    Code: '8143',
    Name: 'Engineer-examiner'
  },
  {
    Id: 25881,
    Code: '8143',
    Name: 'Engineer-inspector'
  },
  {
    Id: 25882,
    Code: '8143',
    Name: 'Examiner, ammunition'
  },
  {
    Id: 25883,
    Code: '8143',
    Name: 'Examiner, armaments'
  },
  {
    Id: 25884,
    Code: '8143',
    Name: 'Examiner, bag'
  },
  {
    Id: 25885,
    Code: '8143',
    Name: 'Examiner, bank-note'
  },
  {
    Id: 25886,
    Code: '8143',
    Name: 'Examiner, book, printing'
  },
  {
    Id: 25887,
    Code: '8143',
    Name: 'Examiner, brush'
  },
  {
    Id: 25888,
    Code: '8143',
    Name: 'Examiner, bulb, lamp mfr'
  },
  {
    Id: 25889,
    Code: '8143',
    Name: 'Examiner, bullet'
  },
  {
    Id: 25890,
    Code: '8143',
    Name: 'Examiner, burr, dental instrument mfr'
  },
  {
    Id: 25891,
    Code: '8143',
    Name: 'Examiner, bus'
  },
  {
    Id: 25892,
    Code: '8143',
    Name: 'Examiner, chain'
  },
  {
    Id: 25893,
    Code: '8143',
    Name: 'Examiner, cheque'
  },
  {
    Id: 25894,
    Code: '8143',
    Name: 'Examiner, cigar'
  },
  {
    Id: 25895,
    Code: '8143',
    Name: 'Examiner, cloth, leather'
  },
  {
    Id: 25896,
    Code: '8143',
    Name: 'Examiner, cloth'
  },
  {
    Id: 25897,
    Code: '8143',
    Name: 'Examiner, cycle'
  },
  {
    Id: 25898,
    Code: '8143',
    Name: "Examiner, decorator's, ceramics mfr"
  },
  {
    Id: 25899,
    Code: '8143',
    Name: 'Examiner, engineering'
  },
  {
    Id: 25900,
    Code: '8143',
    Name: 'Examiner, file'
  },
  {
    Id: 25901,
    Code: '8143',
    Name: 'Examiner, film'
  },
  {
    Id: 25902,
    Code: '8143',
    Name: 'Examiner, final, clothing mfr'
  },
  {
    Id: 25903,
    Code: '8143',
    Name: 'Examiner, glass, glass mfr'
  },
  {
    Id: 25904,
    Code: '8143',
    Name: 'Examiner, hosiery'
  },
  {
    Id: 25905,
    Code: '8143',
    Name: 'Examiner, impression, Ordnance Survey'
  },
  {
    Id: 25906,
    Code: '8143',
    Name: 'Examiner, label'
  },
  {
    Id: 25907,
    Code: '8143',
    Name: 'Examiner, machine, cigarette'
  },
  {
    Id: 25908,
    Code: '8143',
    Name: 'Examiner, mechanical'
  },
  {
    Id: 25909,
    Code: '8143',
    Name: 'Examiner, meter, Dept for Business, Energy and Industrial Strategy'
  },
  {
    Id: 25910,
    Code: '8143',
    Name: 'Examiner, motor'
  },
  {
    Id: 25911,
    Code: '8143',
    Name: 'Examiner, pen'
  },
  {
    Id: 25912,
    Code: '8143',
    Name: 'Examiner, piece'
  },
  {
    Id: 25913,
    Code: '8143',
    Name: 'Examiner, plan, Ordnance Survey'
  },
  {
    Id: 25914,
    Code: '8143',
    Name: 'Examiner, print'
  },
  {
    Id: 25915,
    Code: '8143',
    Name: "Examiner, printer's"
  },
  {
    Id: 25916,
    Code: '8143',
    Name: 'Examiner, roller, printing'
  },
  {
    Id: 25917,
    Code: '8143',
    Name: 'Examiner, shell'
  },
  {
    Id: 25918,
    Code: '8143',
    Name: 'Examiner, shoe'
  },
  {
    Id: 25919,
    Code: '8143',
    Name: 'Examiner, spring'
  },
  {
    Id: 25920,
    Code: '8143',
    Name: 'Examiner, steel, steelworks'
  },
  {
    Id: 25921,
    Code: '8143',
    Name: 'Examiner, stem'
  },
  {
    Id: 25922,
    Code: '8143',
    Name: 'Examiner, stencil'
  },
  {
    Id: 25923,
    Code: '8143',
    Name: 'Examiner, thread'
  },
  {
    Id: 25924,
    Code: '8143',
    Name: 'Examiner, timber'
  },
  {
    Id: 25925,
    Code: '8143',
    Name: 'Examiner, tool, edge'
  },
  {
    Id: 25926,
    Code: '8143',
    Name: 'Examiner, tyre'
  },
  {
    Id: 25927,
    Code: '8143',
    Name: 'Examiner, vehicle'
  },
  {
    Id: 25928,
    Code: '8143',
    Name: 'Examiner, wheel'
  },
  {
    Id: 25929,
    Code: '8143',
    Name: 'Examiner, wire'
  },
  {
    Id: 25930,
    Code: '8143',
    Name: 'Examiner, yarn'
  },
  {
    Id: 25931,
    Code: '8143',
    Name: 'Examiner, asbestos composition goods mfr'
  },
  {
    Id: 25932,
    Code: '8143',
    Name: 'Examiner, bookbinding'
  },
  {
    Id: 25933,
    Code: '8143',
    Name: 'Examiner, ceramics mfr'
  },
  {
    Id: 25934,
    Code: '8143',
    Name: 'Examiner, chemical mfr'
  },
  {
    Id: 25935,
    Code: '8143',
    Name: 'Examiner, clothing mfr'
  },
  {
    Id: 25936,
    Code: '8143',
    Name: 'Examiner, dyeing and cleaning'
  },
  {
    Id: 25937,
    Code: '8143',
    Name: 'Examiner, electrical, electronic equipment mfr'
  },
  {
    Id: 25938,
    Code: '8143',
    Name: 'Examiner, electrical goods mfr'
  },
  {
    Id: 25939,
    Code: '8143',
    Name: 'Examiner, fancy goods mfr'
  },
  {
    Id: 25940,
    Code: '8143',
    Name: 'Examiner, food products mfr'
  },
  {
    Id: 25941,
    Code: '8143',
    Name: 'Examiner, footwear mfr'
  },
  {
    Id: 25942,
    Code: '8143',
    Name: 'Examiner, glass mfr'
  },
  {
    Id: 25943,
    Code: '8143',
    Name: 'Examiner, laundry, launderette, dry cleaning'
  },
  {
    Id: 25944,
    Code: '8143',
    Name: 'Examiner, leathercloth mfr'
  },
  {
    Id: 25945,
    Code: '8143',
    Name: 'Examiner, match mfr'
  },
  {
    Id: 25946,
    Code: '8143',
    Name: 'Examiner, metal trades'
  },
  {
    Id: 25947,
    Code: '8143',
    Name: 'Examiner, mica, micanite goods mfr'
  },
  {
    Id: 25948,
    Code: '8143',
    Name: 'Examiner, MOD'
  },
  {
    Id: 25949,
    Code: '8143',
    Name: 'Examiner, ordnance factory'
  },
  {
    Id: 25950,
    Code: '8143',
    Name: 'Examiner, paper mfr'
  },
  {
    Id: 25951,
    Code: '8143',
    Name: 'Examiner, pencil, crayon mfr'
  },
  {
    Id: 25952,
    Code: '8143',
    Name: 'Examiner, plastics goods mfr'
  },
  {
    Id: 25953,
    Code: '8143',
    Name: 'Examiner, printing'
  },
  {
    Id: 25954,
    Code: '8143',
    Name: 'Examiner, railway maintenance and repair'
  },
  {
    Id: 25955,
    Code: '8143',
    Name: 'Examiner, railways'
  },
  {
    Id: 25956,
    Code: '8143',
    Name: 'Examiner, Royal Mint'
  },
  {
    Id: 25957,
    Code: '8143',
    Name: 'Examiner, rubber goods mfr'
  },
  {
    Id: 25958,
    Code: '8143',
    Name: 'Examiner, textile mfr'
  },
  {
    Id: 25959,
    Code: '8143',
    Name: 'Examiner, tobacco mfr'
  },
  {
    Id: 25960,
    Code: '8143',
    Name: 'Examiner, toy mfr'
  },
  {
    Id: 25961,
    Code: '8143',
    Name: 'Examiner, wallpaper mfr'
  },
  {
    Id: 25962,
    Code: '8143',
    Name: 'Examiner, wood products mfr'
  },
  {
    Id: 25963,
    Code: '8143',
    Name: 'Filler-in, furniture mfr'
  },
  {
    Id: 25964,
    Code: '8143',
    Name: 'Finder, fault'
  },
  {
    Id: 25965,
    Code: '8143',
    Name: 'Finisher, cellulose'
  },
  {
    Id: 25966,
    Code: '8143',
    Name: 'Finisher, paint'
  },
  {
    Id: 25967,
    Code: '8143',
    Name: 'Finisher, pencil'
  },
  {
    Id: 25968,
    Code: '8143',
    Name: 'Finisher, surface, aircraft mfr'
  },
  {
    Id: 25969,
    Code: '8143',
    Name: 'Gauger, bullet'
  },
  {
    Id: 25970,
    Code: '8143',
    Name: 'Gauger, metal trades'
  },
  {
    Id: 25971,
    Code: '8143',
    Name: 'Hand, clock, ball bearing mfr'
  },
  {
    Id: 25972,
    Code: '8143',
    Name: 'Hand, maintenance, transport: railways'
  },
  {
    Id: 25973,
    Code: '8143',
    Name: 'Hand, test, metal trades'
  },
  {
    Id: 25974,
    Code: '8143',
    Name: 'Inflator, bed, air'
  },
  {
    Id: 25975,
    Code: '8143',
    Name: 'Inflator, cushion, air'
  },
  {
    Id: 25976,
    Code: '8143',
    Name: 'Inspector, aeronautical'
  },
  {
    Id: 25977,
    Code: '8143',
    Name: 'Inspector, AID, Board of Trade'
  },
  {
    Id: 25978,
    Code: '8143',
    Name: 'Inspector, aircraft'
  },
  {
    Id: 25979,
    Code: '8143',
    Name: 'Inspector, apparatus, photographic'
  },
  {
    Id: 25980,
    Code: '8143',
    Name: 'Inspector, area, automatic machines'
  },
  {
    Id: 25981,
    Code: '8143',
    Name: 'Inspector, armaments'
  },
  {
    Id: 25982,
    Code: '8143',
    Name: 'Inspector, assembly'
  },
  {
    Id: 25983,
    Code: '8143',
    Name: 'Inspector, assurance, quality'
  },
  {
    Id: 25984,
    Code: '8143',
    Name: 'Inspector, bank-note'
  },
  {
    Id: 25985,
    Code: '8143',
    Name: 'Inspector, bar, rolling mill'
  },
  {
    Id: 25986,
    Code: '8143',
    Name: 'Inspector, battery'
  },
  {
    Id: 25987,
    Code: '8143',
    Name: 'Inspector, bench'
  },
  {
    Id: 25988,
    Code: '8143',
    Name: 'Inspector, billet, steelworks'
  },
  {
    Id: 25989,
    Code: '8143',
    Name: 'Inspector, boiler'
  },
  {
    Id: 25990,
    Code: '8143',
    Name: 'Inspector, cabinet'
  },
  {
    Id: 25991,
    Code: '8143',
    Name: 'Inspector, cable, cable mfr'
  },
  {
    Id: 25992,
    Code: '8143',
    Name: 'Inspector, car'
  },
  {
    Id: 25993,
    Code: '8143',
    Name: 'Inspector, carpet'
  },
  {
    Id: 25994,
    Code: '8143',
    Name: 'Inspector, carriage, railways'
  },
  {
    Id: 25995,
    Code: '8143',
    Name: 'Inspector, carriage and wagon'
  },
  {
    Id: 25996,
    Code: '8143',
    Name: 'Inspector, cell, chemical mfr'
  },
  {
    Id: 25997,
    Code: '8143',
    Name: 'Inspector, chicken, food processing'
  },
  {
    Id: 25998,
    Code: '8143',
    Name: 'Inspector, cloth'
  },
  {
    Id: 25999,
    Code: '8143',
    Name: 'Inspector, clothing'
  },
  {
    Id: 26000,
    Code: '8143',
    Name: 'Inspector, coil'
  },
  {
    Id: 26001,
    Code: '8143',
    Name: 'Inspector, component, metal trades'
  },
  {
    Id: 26002,
    Code: '8143',
    Name: 'Inspector, components, metal trades'
  },
  {
    Id: 26003,
    Code: '8143',
    Name: 'Inspector, control, quality'
  },
  {
    Id: 26004,
    Code: '8143',
    Name: 'Inspector, core'
  },
  {
    Id: 26005,
    Code: '8143',
    Name: 'Inspector, crisp, potato'
  },
  {
    Id: 26006,
    Code: '8143',
    Name: 'Inspector, cylinder'
  },
  {
    Id: 26007,
    Code: '8143',
    Name: 'Inspector, electrical'
  },
  {
    Id: 26008,
    Code: '8143',
    Name: 'Inspector, electronics, components mfr'
  },
  {
    Id: 26009,
    Code: '8143',
    Name: 'Inspector, enamel'
  },
  {
    Id: 26010,
    Code: '8143',
    Name: 'Inspector, engine'
  },
  {
    Id: 26011,
    Code: '8143',
    Name: 'Inspector, engineering'
  },
  {
    Id: 26012,
    Code: '8143',
    Name: 'Inspector, factory, clothing mfr'
  },
  {
    Id: 26013,
    Code: '8143',
    Name: 'Inspector, factory, metal trades'
  },
  {
    Id: 26014,
    Code: '8143',
    Name: 'Inspector, film'
  },
  {
    Id: 26015,
    Code: '8143',
    Name: 'Inspector, flight'
  },
  {
    Id: 26016,
    Code: '8143',
    Name: 'Inspector, floor, engineering'
  },
  {
    Id: 26017,
    Code: '8143',
    Name: 'Inspector, food, food products mfr'
  },
  {
    Id: 26018,
    Code: '8143',
    Name: 'Inspector, furnace, furnace mfr'
  },
  {
    Id: 26019,
    Code: '8143',
    Name: 'Inspector, garment'
  },
  {
    Id: 26020,
    Code: '8143',
    Name: 'Inspector, gauge'
  },
  {
    Id: 26021,
    Code: '8143',
    Name: 'Inspector, glass'
  },
  {
    Id: 26022,
    Code: '8143',
    Name: 'Inspector, government, small arms'
  },
  {
    Id: 26023,
    Code: '8143',
    Name: 'Inspector, graphite'
  },
  {
    Id: 26024,
    Code: '8143',
    Name: 'Inspector, hosiery'
  },
  {
    Id: 26025,
    Code: '8143',
    Name: 'Inspector, instrument'
  },
  {
    Id: 26026,
    Code: '8143',
    Name: 'Inspector, insulation'
  },
  {
    Id: 26027,
    Code: '8143',
    Name: 'Inspector, lamp'
  },
  {
    Id: 26028,
    Code: '8143',
    Name: 'Inspector, layout'
  },
  {
    Id: 26029,
    Code: '8143',
    Name: 'Inspector, leaf'
  },
  {
    Id: 26030,
    Code: '8143',
    Name: 'Inspector, lens'
  },
  {
    Id: 26031,
    Code: '8143',
    Name: 'Inspector, lift'
  },
  {
    Id: 26032,
    Code: '8143',
    Name: 'Inspector, line, vehicle mfr'
  },
  {
    Id: 26033,
    Code: '8143',
    Name: 'Inspector, lining, brake linings mfr'
  },
  {
    Id: 26034,
    Code: '8143',
    Name: 'Inspector, machine, weighing machines'
  },
  {
    Id: 26035,
    Code: '8143',
    Name: 'Inspector, machine'
  },
  {
    Id: 26036,
    Code: '8143',
    Name: 'Inspector, machinery'
  },
  {
    Id: 26037,
    Code: '8143',
    Name: 'Inspector, mechanical'
  },
  {
    Id: 26038,
    Code: '8143',
    Name: 'Inspector, metal'
  },
  {
    Id: 26039,
    Code: '8143',
    Name: 'Inspector, micrometer'
  },
  {
    Id: 26040,
    Code: '8143',
    Name: 'Inspector, mirror'
  },
  {
    Id: 26041,
    Code: '8143',
    Name: 'Inspector, motor'
  },
  {
    Id: 26042,
    Code: '8143',
    Name: 'Inspector, mould, glass mfr'
  },
  {
    Id: 26043,
    Code: '8143',
    Name: 'Inspector, NDT'
  },
  {
    Id: 26044,
    Code: '8143',
    Name: 'Inspector, nylon'
  },
  {
    Id: 26045,
    Code: '8143',
    Name: 'Inspector, optical'
  },
  {
    Id: 26046,
    Code: '8143',
    Name: 'Inspector, paint, engineering'
  },
  {
    Id: 26047,
    Code: '8143',
    Name: 'Inspector, patrol, metal trades'
  },
  {
    Id: 26048,
    Code: '8143',
    Name: 'Inspector, PCB'
  },
  {
    Id: 26049,
    Code: '8143',
    Name: 'Inspector, plant, electrical'
  },
  {
    Id: 26050,
    Code: '8143',
    Name: 'Inspector, plant, gas'
  },
  {
    Id: 26051,
    Code: '8143',
    Name: 'Inspector, plant, mechanical'
  },
  {
    Id: 26052,
    Code: '8143',
    Name: 'Inspector, plant, preparation, coal mine'
  },
  {
    Id: 26053,
    Code: '8143',
    Name: 'Inspector, plant, process'
  },
  {
    Id: 26054,
    Code: '8143',
    Name: 'Inspector, plastics'
  },
  {
    Id: 26055,
    Code: '8143',
    Name: 'Inspector, plate, steelworks'
  },
  {
    Id: 26056,
    Code: '8143',
    Name: 'Inspector, process'
  },
  {
    Id: 26057,
    Code: '8143',
    Name: 'Inspector, production'
  },
  {
    Id: 26058,
    Code: '8143',
    Name: 'Inspector, QA'
  },
  {
    Id: 26059,
    Code: '8143',
    Name: 'Inspector, QC'
  },
  {
    Id: 26060,
    Code: '8143',
    Name: 'Inspector, quality'
  },
  {
    Id: 26061,
    Code: '8143',
    Name: 'Inspector, radio'
  },
  {
    Id: 26062,
    Code: '8143',
    Name: 'Inspector, rail'
  },
  {
    Id: 26063,
    Code: '8143',
    Name: 'Inspector, records, musical'
  },
  {
    Id: 26064,
    Code: '8143',
    Name: 'Inspector, relay'
  },
  {
    Id: 26065,
    Code: '8143',
    Name: 'Inspector, ring, engineering'
  },
  {
    Id: 26066,
    Code: '8143',
    Name: 'Inspector, roller, metal trades'
  },
  {
    Id: 26067,
    Code: '8143',
    Name: 'Inspector, room, tool'
  },
  {
    Id: 26068,
    Code: '8143',
    Name: 'Inspector, rope'
  },
  {
    Id: 26069,
    Code: '8143',
    Name: 'Inspector, rubber'
  },
  {
    Id: 26070,
    Code: '8143',
    Name: 'Inspector, sanitary, ceramics mfr'
  },
  {
    Id: 26071,
    Code: '8143',
    Name: 'Inspector, shell'
  },
  {
    Id: 26072,
    Code: '8143',
    Name: 'Inspector, shop, fitting'
  },
  {
    Id: 26073,
    Code: '8143',
    Name: 'Inspector, shop, machine'
  },
  {
    Id: 26074,
    Code: '8143',
    Name: 'Inspector, system'
  },
  {
    Id: 26075,
    Code: '8143',
    Name: 'Inspector, test'
  },
  {
    Id: 26076,
    Code: '8143',
    Name: 'Inspector, textile'
  },
  {
    Id: 26077,
    Code: '8143',
    Name: 'Inspector, timber'
  },
  {
    Id: 26078,
    Code: '8143',
    Name: 'Inspector, tool'
  },
  {
    Id: 26079,
    Code: '8143',
    Name: 'Inspector, trimming, coach'
  },
  {
    Id: 26080,
    Code: '8143',
    Name: 'Inspector, tube'
  },
  {
    Id: 26081,
    Code: '8143',
    Name: 'Inspector, tyre'
  },
  {
    Id: 26082,
    Code: '8143',
    Name: 'Inspector, ultrasonic'
  },
  {
    Id: 26083,
    Code: '8143',
    Name: 'Inspector, upholstery'
  },
  {
    Id: 26084,
    Code: '8143',
    Name: 'Inspector, vehicle'
  },
  {
    Id: 26085,
    Code: '8143',
    Name: 'Inspector, veneer'
  },
  {
    Id: 26086,
    Code: '8143',
    Name: 'Inspector, wagon'
  },
  {
    Id: 26087,
    Code: '8143',
    Name: 'Inspector, welding'
  },
  {
    Id: 26088,
    Code: '8143',
    Name: 'Inspector, works, vehicle mfr'
  },
  {
    Id: 26089,
    Code: '8143',
    Name: 'Inspector, workshops, coal mine'
  },
  {
    Id: 26090,
    Code: '8143',
    Name: 'Inspector, yarn'
  },
  {
    Id: 26091,
    Code: '8143',
    Name: 'Inspector, abrasive paper, cloth mfr'
  },
  {
    Id: 26092,
    Code: '8143',
    Name: 'Inspector, asbestos goods mfr'
  },
  {
    Id: 26093,
    Code: '8143',
    Name: 'Inspector, asbestos-cement goods mfr'
  },
  {
    Id: 26094,
    Code: '8143',
    Name: 'Inspector, broom, brush mfr'
  },
  {
    Id: 26095,
    Code: '8143',
    Name: 'Inspector, canvas goods mfr'
  },
  {
    Id: 26096,
    Code: '8143',
    Name: 'Inspector, carbon goods mfr'
  },
  {
    Id: 26097,
    Code: '8143',
    Name: 'Inspector, cardboard mfr'
  },
  {
    Id: 26098,
    Code: '8143',
    Name: 'Inspector, carpet, rug mfr'
  },
  {
    Id: 26099,
    Code: '8143',
    Name: 'Inspector, cartridge mfr'
  },
  {
    Id: 26100,
    Code: '8143',
    Name: 'Inspector, ceramics mfr'
  },
  {
    Id: 26101,
    Code: '8143',
    Name: 'Inspector, clothing mfr'
  },
  {
    Id: 26102,
    Code: '8143',
    Name: 'Inspector, dyeing and cleaning'
  },
  {
    Id: 26103,
    Code: '8143',
    Name: 'Inspector, electrical goods mfr'
  },
  {
    Id: 26104,
    Code: '8143',
    Name: 'Inspector, fancy goods mfr'
  },
  {
    Id: 26105,
    Code: '8143',
    Name: 'Inspector, food products mfr'
  },
  {
    Id: 26106,
    Code: '8143',
    Name: 'Inspector, footwear mfr'
  },
  {
    Id: 26107,
    Code: '8143',
    Name: 'Inspector, furniture mfr'
  },
  {
    Id: 26108,
    Code: '8143',
    Name: 'Inspector, glass mfr'
  },
  {
    Id: 26109,
    Code: '8143',
    Name: 'Inspector, instrument mfr'
  },
  {
    Id: 26110,
    Code: '8143',
    Name: 'Inspector, leathercloth mfr'
  },
  {
    Id: 26111,
    Code: '8143',
    Name: 'Inspector, man-made fibre mfr'
  },
  {
    Id: 26112,
    Code: '8143',
    Name: 'Inspector, match mfr'
  },
  {
    Id: 26113,
    Code: '8143',
    Name: 'Inspector, metal trades'
  },
  {
    Id: 26114,
    Code: '8143',
    Name: 'Inspector, mica, micanite goods mfr'
  },
  {
    Id: 26115,
    Code: '8143',
    Name: 'Inspector, paper goods mfr'
  },
  {
    Id: 26116,
    Code: '8143',
    Name: 'Inspector, paper mfr'
  },
  {
    Id: 26117,
    Code: '8143',
    Name: 'Inspector, plasterboard mfr'
  },
  {
    Id: 26118,
    Code: '8143',
    Name: 'Inspector, plastics goods mfr'
  },
  {
    Id: 26119,
    Code: '8143',
    Name: 'Inspector, printing'
  },
  {
    Id: 26120,
    Code: '8143',
    Name: 'Inspector, rubber goods mfr'
  },
  {
    Id: 26121,
    Code: '8143',
    Name: 'Inspector, stone dressing'
  },
  {
    Id: 26122,
    Code: '8143',
    Name: 'Inspector, surgical goods mfr'
  },
  {
    Id: 26123,
    Code: '8143',
    Name: 'Inspector, textile mfr'
  },
  {
    Id: 26124,
    Code: '8143',
    Name: 'Inspector, wood products mfr'
  },
  {
    Id: 26125,
    Code: '8143',
    Name: 'Inspector and packer, yarn'
  },
  {
    Id: 26126,
    Code: '8143',
    Name: 'Inspector of naval ordnance'
  },
  {
    Id: 26127,
    Code: '8143',
    Name: 'Liner and finisher, vehicle mfr'
  },
  {
    Id: 26128,
    Code: '8143',
    Name: 'Looker, cloth'
  },
  {
    Id: 26129,
    Code: '8143',
    Name: 'Looker, piece'
  },
  {
    Id: 26130,
    Code: '8143',
    Name: 'Looker, yarn'
  },
  {
    Id: 26131,
    Code: '8143',
    Name: 'Looker-out, pen nib mfr'
  },
  {
    Id: 26132,
    Code: '8143',
    Name: "Looker-over, decorator's"
  },
  {
    Id: 26133,
    Code: '8143',
    Name: 'Looker-over, ceramics mfr'
  },
  {
    Id: 26134,
    Code: '8143',
    Name: 'Looker-over, textile finishing'
  },
  {
    Id: 26135,
    Code: '8143',
    Name: 'Machinist, testing, metal trades'
  },
  {
    Id: 26136,
    Code: '8143',
    Name: 'Maker-up, paper mfr'
  },
  {
    Id: 26137,
    Code: '8143',
    Name: 'Man, cloth, clothing mfr'
  },
  {
    Id: 26138,
    Code: '8143',
    Name: 'Measurer, steel'
  },
  {
    Id: 26139,
    Code: '8143',
    Name: 'Measurer, rolling mill'
  },
  {
    Id: 26140,
    Code: '8143',
    Name: 'Officer, control, quality'
  },
  {
    Id: 26141,
    Code: '8143',
    Name: 'Operator, assurance, quality'
  },
  {
    Id: 26142,
    Code: '8143',
    Name: 'Operator, detector, engineering'
  },
  {
    Id: 26143,
    Code: '8143',
    Name: 'Operator, gauger, cartridges'
  },
  {
    Id: 26144,
    Code: '8143',
    Name: 'Operator, lamp, electric lamp mfr'
  },
  {
    Id: 26145,
    Code: '8143',
    Name: 'Operator, photometer'
  },
  {
    Id: 26146,
    Code: '8143',
    Name: 'Operator, quality'
  },
  {
    Id: 26147,
    Code: '8143',
    Name: 'Operator, ultrasonic'
  },
  {
    Id: 26148,
    Code: '8143',
    Name: 'Overlooker, cloth'
  },
  {
    Id: 26149,
    Code: '8143',
    Name: 'Overlooker, greenhouse'
  },
  {
    Id: 26150,
    Code: '8143',
    Name: 'Overlooker, clothing mfr'
  },
  {
    Id: 26151,
    Code: '8143',
    Name: 'Overlooker, hat mfr'
  },
  {
    Id: 26152,
    Code: '8143',
    Name: 'Overlooker, lace examining'
  },
  {
    Id: 26153,
    Code: '8143',
    Name: 'Passer, cigar'
  },
  {
    Id: 26154,
    Code: '8143',
    Name: 'Passer, cloth'
  },
  {
    Id: 26155,
    Code: '8143',
    Name: 'Passer, final, tailoring'
  },
  {
    Id: 26156,
    Code: '8143',
    Name: 'Passer, finished, textile mfr'
  },
  {
    Id: 26157,
    Code: '8143',
    Name: 'Passer, garment'
  },
  {
    Id: 26158,
    Code: '8143',
    Name: 'Passer, glove, finished'
  },
  {
    Id: 26159,
    Code: '8143',
    Name: 'Passer, machine, clothing mfr'
  },
  {
    Id: 26160,
    Code: '8143',
    Name: 'Passer, piece'
  },
  {
    Id: 26161,
    Code: '8143',
    Name: 'Passer, broom, brush mfr'
  },
  {
    Id: 26162,
    Code: '8143',
    Name: 'Passer, canned foods mfr'
  },
  {
    Id: 26163,
    Code: '8143',
    Name: 'Passer, cardboard box mfr'
  },
  {
    Id: 26164,
    Code: '8143',
    Name: 'Passer, clothing mfr'
  },
  {
    Id: 26165,
    Code: '8143',
    Name: 'Passer, footwear mfr'
  },
  {
    Id: 26166,
    Code: '8143',
    Name: 'Passer, fur goods mfr'
  },
  {
    Id: 26167,
    Code: '8143',
    Name: 'Passer, glove mfr'
  },
  {
    Id: 26168,
    Code: '8143',
    Name: 'Passer, metal trades'
  },
  {
    Id: 26169,
    Code: '8143',
    Name: 'Passer, textile mfr'
  },
  {
    Id: 26170,
    Code: '8143',
    Name: 'Patrol, vehicle mfr'
  },
  {
    Id: 26171,
    Code: '8143',
    Name: 'Patrolman, vehicle mfr'
  },
  {
    Id: 26172,
    Code: '8143',
    Name: 'Percher, textile mfr'
  },
  {
    Id: 26173,
    Code: '8143',
    Name: 'Picker, confectionery'
  },
  {
    Id: 26174,
    Code: '8143',
    Name: 'Picker, nut'
  },
  {
    Id: 26175,
    Code: '8143',
    Name: 'Picker, stilt'
  },
  {
    Id: 26176,
    Code: '8143',
    Name: 'Picker, thimble'
  },
  {
    Id: 26177,
    Code: '8143',
    Name: 'Picker, paper mfr'
  },
  {
    Id: 26178,
    Code: '8143',
    Name: 'Picker-out, galvanised sheet mfr'
  },
  {
    Id: 26179,
    Code: '8143',
    Name: 'Prover, die'
  },
  {
    Id: 26180,
    Code: '8143',
    Name: 'Prover, file'
  },
  {
    Id: 26181,
    Code: '8143',
    Name: 'Prover, gun'
  },
  {
    Id: 26182,
    Code: '8143',
    Name: 'Prover, meter'
  },
  {
    Id: 26183,
    Code: '8143',
    Name: 'Prover, stove, gas supplier'
  },
  {
    Id: 26184,
    Code: '8143',
    Name: 'Prover, tube mfr'
  },
  {
    Id: 26185,
    Code: '8143',
    Name: 'Prover and tester, metal trades'
  },
  {
    Id: 26186,
    Code: '8143',
    Name: 'Ransacker, fishing net mfr'
  },
  {
    Id: 26187,
    Code: '8143',
    Name: 'Sampler, grain, grain milling'
  },
  {
    Id: 26188,
    Code: '8143',
    Name: 'Sampler, milk'
  },
  {
    Id: 26189,
    Code: '8143',
    Name: 'Sampler, tea'
  },
  {
    Id: 26190,
    Code: '8143',
    Name: 'Sampler'
  },
  {
    Id: 26191,
    Code: '8143',
    Name: 'Searcher, manufacturing: woollen mfr'
  },
  {
    Id: 26192,
    Code: '8143',
    Name: 'Shooter, gun mfr'
  },
  {
    Id: 26193,
    Code: '8143',
    Name: 'Smeller, cask'
  },
  {
    Id: 26194,
    Code: '8143',
    Name: 'Sniffer'
  },
  {
    Id: 26195,
    Code: '8143',
    Name: 'Sorter, card, playing'
  },
  {
    Id: 26196,
    Code: '8143',
    Name: 'Sorter, glass'
  },
  {
    Id: 26197,
    Code: '8143',
    Name: 'Sorter, glove'
  },
  {
    Id: 26198,
    Code: '8143',
    Name: 'Sorter, label'
  },
  {
    Id: 26199,
    Code: '8143',
    Name: 'Sorter, note, paper goods mfr'
  },
  {
    Id: 26200,
    Code: '8143',
    Name: 'Sorter, paper, paper goods mfr'
  },
  {
    Id: 26201,
    Code: '8143',
    Name: 'Sorter, paper, paper mfr'
  },
  {
    Id: 26202,
    Code: '8143',
    Name: 'Sorter, paper, wallpaper mfr'
  },
  {
    Id: 26203,
    Code: '8143',
    Name: "Sorter, printer's"
  },
  {
    Id: 26204,
    Code: '8143',
    Name: 'Sorter, sheet, printing'
  },
  {
    Id: 26205,
    Code: '8143',
    Name: 'Sorter, glass mfr'
  },
  {
    Id: 26206,
    Code: '8143',
    Name: 'Sorter, paper goods mfr'
  },
  {
    Id: 26207,
    Code: '8143',
    Name: 'Sorter, paper mfr'
  },
  {
    Id: 26208,
    Code: '8143',
    Name: 'Sorter, printing'
  },
  {
    Id: 26209,
    Code: '8143',
    Name: 'Spotter, textile finishing'
  },
  {
    Id: 26210,
    Code: '8143',
    Name: 'Straightener, yarn'
  },
  {
    Id: 26211,
    Code: '8143',
    Name: 'Straightener, textile mfr'
  },
  {
    Id: 26212,
    Code: '8143',
    Name: 'Tapper, wheel, railways'
  },
  {
    Id: 26213,
    Code: '8143',
    Name: 'Tapper, railways'
  },
  {
    Id: 26214,
    Code: '8143',
    Name: 'Tester, aircraft'
  },
  {
    Id: 26215,
    Code: '8143',
    Name: 'Tester, balloon'
  },
  {
    Id: 26216,
    Code: '8143',
    Name: 'Tester, bench, motors, motor cycles'
  },
  {
    Id: 26217,
    Code: '8143',
    Name: 'Tester, bobbin'
  },
  {
    Id: 26218,
    Code: '8143',
    Name: 'Tester, boiler'
  },
  {
    Id: 26219,
    Code: '8143',
    Name: 'Tester, brake'
  },
  {
    Id: 26220,
    Code: '8143',
    Name: 'Tester, cable'
  },
  {
    Id: 26221,
    Code: '8143',
    Name: 'Tester, can'
  },
  {
    Id: 26222,
    Code: '8143',
    Name: 'Tester, carburettor'
  },
  {
    Id: 26223,
    Code: '8143',
    Name: 'Tester, cask'
  },
  {
    Id: 26224,
    Code: '8143',
    Name: 'Tester, cell, dry battery mfr'
  },
  {
    Id: 26225,
    Code: '8143',
    Name: 'Tester, cement'
  },
  {
    Id: 26226,
    Code: '8143',
    Name: 'Tester, cloth, textile merchants'
  },
  {
    Id: 26227,
    Code: '8143',
    Name: 'Tester, coil'
  },
  {
    Id: 26228,
    Code: '8143',
    Name: 'Tester, coke, coal gas, coke ovens'
  },
  {
    Id: 26229,
    Code: '8143',
    Name: 'Tester, coke'
  },
  {
    Id: 26230,
    Code: '8143',
    Name: 'Tester, conditioning, air'
  },
  {
    Id: 26231,
    Code: '8143',
    Name: 'Tester, cylinder'
  },
  {
    Id: 26232,
    Code: '8143',
    Name: 'Tester, denier'
  },
  {
    Id: 26233,
    Code: '8143',
    Name: 'Tester, disc, compact'
  },
  {
    Id: 26234,
    Code: '8143',
    Name: 'Tester, dye, textile bleaching, dyeing'
  },
  {
    Id: 26235,
    Code: '8143',
    Name: 'Tester, dynamo'
  },
  {
    Id: 26236,
    Code: '8143',
    Name: 'Tester, egg'
  },
  {
    Id: 26237,
    Code: '8143',
    Name: 'Tester, electrical'
  },
  {
    Id: 26238,
    Code: '8143',
    Name: 'Tester, engine'
  },
  {
    Id: 26239,
    Code: '8143',
    Name: 'Tester, furnace, furnace mfr'
  },
  {
    Id: 26240,
    Code: '8143',
    Name: 'Tester, gear, engineering'
  },
  {
    Id: 26241,
    Code: '8143',
    Name: 'Tester, glass'
  },
  {
    Id: 26242,
    Code: '8143',
    Name: 'Tester, hollow-ware'
  },
  {
    Id: 26243,
    Code: '8143',
    Name: 'Tester, instrument'
  },
  {
    Id: 26244,
    Code: '8143',
    Name: 'Tester, insulation'
  },
  {
    Id: 26245,
    Code: '8143',
    Name: 'Tester, lamp, arc'
  },
  {
    Id: 26246,
    Code: '8143',
    Name: 'Tester, machine'
  },
  {
    Id: 26247,
    Code: '8143',
    Name: 'Tester, matrix, type foundry'
  },
  {
    Id: 26248,
    Code: '8143',
    Name: 'Tester, meter'
  },
  {
    Id: 26249,
    Code: '8143',
    Name: 'Tester, milk'
  },
  {
    Id: 26250,
    Code: '8143',
    Name: 'Tester, moisture'
  },
  {
    Id: 26251,
    Code: '8143',
    Name: 'Tester, motor'
  },
  {
    Id: 26252,
    Code: '8143',
    Name: 'Tester, music'
  },
  {
    Id: 26253,
    Code: '8143',
    Name: 'Tester, NDT'
  },
  {
    Id: 26254,
    Code: '8143',
    Name: 'Tester, non-destructive'
  },
  {
    Id: 26255,
    Code: '8143',
    Name: 'Tester, paper'
  },
  {
    Id: 26256,
    Code: '8143',
    Name: 'Tester, PAT'
  },
  {
    Id: 26257,
    Code: '8143',
    Name: 'Tester, pipe, concrete'
  },
  {
    Id: 26258,
    Code: '8143',
    Name: 'Tester, pipe, metal mfr'
  },
  {
    Id: 26259,
    Code: '8143',
    Name: 'Tester, pole, telephone'
  },
  {
    Id: 26260,
    Code: '8143',
    Name: 'Tester, pump'
  },
  {
    Id: 26261,
    Code: '8143',
    Name: 'Tester, quality'
  },
  {
    Id: 26262,
    Code: '8143',
    Name: 'Tester, record'
  },
  {
    Id: 26263,
    Code: '8143',
    Name: 'Tester, road, vehicle mfr'
  },
  {
    Id: 26264,
    Code: '8143',
    Name: 'Tester, roller'
  },
  {
    Id: 26265,
    Code: '8143',
    Name: 'Tester, rope'
  },
  {
    Id: 26266,
    Code: '8143',
    Name: 'Tester, shift, chemical mfr'
  },
  {
    Id: 26267,
    Code: '8143',
    Name: 'Tester, silk, man-made fibre mfr'
  },
  {
    Id: 26268,
    Code: '8143',
    Name: 'Tester, slow, bobbin'
  },
  {
    Id: 26269,
    Code: '8143',
    Name: 'Tester, spring'
  },
  {
    Id: 26270,
    Code: '8143',
    Name: 'Tester, stove'
  },
  {
    Id: 26271,
    Code: '8143',
    Name: 'Tester, systems, electronic'
  },
  {
    Id: 26272,
    Code: '8143',
    Name: 'Tester, tank'
  },
  {
    Id: 26273,
    Code: '8143',
    Name: 'Tester, tractor'
  },
  {
    Id: 26274,
    Code: '8143',
    Name: 'Tester, tube, ray, cathode'
  },
  {
    Id: 26275,
    Code: '8143',
    Name: 'Tester, valve'
  },
  {
    Id: 26276,
    Code: '8143',
    Name: 'Tester, vehicle'
  },
  {
    Id: 26277,
    Code: '8143',
    Name: 'Tester, wire'
  },
  {
    Id: 26278,
    Code: '8143',
    Name: 'Tester, yarn'
  },
  {
    Id: 26279,
    Code: '8143',
    Name: 'Tester, abrasive wheel mfr'
  },
  {
    Id: 26280,
    Code: '8143',
    Name: 'Tester, asbestos-cement goods mfr'
  },
  {
    Id: 26281,
    Code: '8143',
    Name: 'Tester, cast concrete products mfr'
  },
  {
    Id: 26282,
    Code: '8143',
    Name: 'Tester, ceramics mfr'
  },
  {
    Id: 26283,
    Code: '8143',
    Name: 'Tester, electrical: electronic equipment mfr'
  },
  {
    Id: 26284,
    Code: '8143',
    Name: 'Tester, food canning'
  },
  {
    Id: 26285,
    Code: '8143',
    Name: 'Tester, glass mfr'
  },
  {
    Id: 26286,
    Code: '8143',
    Name: 'Tester, lens mfr'
  },
  {
    Id: 26287,
    Code: '8143',
    Name: 'Tester, match mfr'
  },
  {
    Id: 26288,
    Code: '8143',
    Name: 'Tester, metal trades'
  },
  {
    Id: 26289,
    Code: '8143',
    Name: 'Tester, paint mfr'
  },
  {
    Id: 26290,
    Code: '8143',
    Name: 'Tester, plastics goods mfr'
  },
  {
    Id: 26291,
    Code: '8143',
    Name: 'Tester, record mfr'
  },
  {
    Id: 26292,
    Code: '8143',
    Name: 'Tester, rubber goods mfr'
  },
  {
    Id: 26293,
    Code: '8143',
    Name: 'Tester, safety fuse mfr'
  },
  {
    Id: 26294,
    Code: '8143',
    Name: 'Verifier, rubber tyre mfr'
  },
  {
    Id: 26295,
    Code: '8143',
    Name: 'Viewer, ammunition'
  },
  {
    Id: 26296,
    Code: '8143',
    Name: 'Viewer, barrel, gun'
  },
  {
    Id: 26297,
    Code: '8143',
    Name: 'Viewer, bearings, ball'
  },
  {
    Id: 26298,
    Code: '8143',
    Name: 'Viewer, cloth'
  },
  {
    Id: 26299,
    Code: '8143',
    Name: 'Viewer, component, metal trades'
  },
  {
    Id: 26300,
    Code: '8143',
    Name: 'Viewer, cycle'
  },
  {
    Id: 26301,
    Code: '8143',
    Name: 'Viewer, garment'
  },
  {
    Id: 26302,
    Code: '8143',
    Name: 'Viewer, glass'
  },
  {
    Id: 26303,
    Code: '8143',
    Name: 'Viewer, patrol, metal trades'
  },
  {
    Id: 26304,
    Code: '8143',
    Name: 'Viewer, plastics'
  },
  {
    Id: 26305,
    Code: '8143',
    Name: 'Viewer, cartridge mfr'
  },
  {
    Id: 26306,
    Code: '8143',
    Name: 'Viewer, chocolate mfr'
  },
  {
    Id: 26307,
    Code: '8143',
    Name: 'Viewer, clothing mfr'
  },
  {
    Id: 26308,
    Code: '8143',
    Name: 'Viewer, electrical goods mfr'
  },
  {
    Id: 26309,
    Code: '8143',
    Name: 'Viewer, film, television production'
  },
  {
    Id: 26310,
    Code: '8143',
    Name: 'Viewer, food products mfr'
  },
  {
    Id: 26311,
    Code: '8143',
    Name: 'Viewer, hat mfr'
  },
  {
    Id: 26312,
    Code: '8143',
    Name: 'Viewer, metal trades'
  },
  {
    Id: 26313,
    Code: '8143',
    Name: 'Viewer, plastics goods mfr'
  },
  {
    Id: 26314,
    Code: '8143',
    Name: 'Viewer, rubber goods mfr'
  },
  {
    Id: 26315,
    Code: '8143',
    Name: 'Viewer, sports goods mfr'
  },
  {
    Id: 26316,
    Code: '8143',
    Name: 'Viewer, textile mfr'
  },
  {
    Id: 26317,
    Code: '8143',
    Name: 'Viewer, wood products mfr'
  },
  {
    Id: 26318,
    Code: '8143',
    Name: 'Viewer of bullets'
  },
  {
    Id: 26319,
    Code: '8143',
    Name: 'Worker, inspection'
  },
  {
    Id: 26320,
    Code: '8143',
    Name: 'Worker, salle'
  },
  {
    Id: 26321,
    Code: '8144',
    Name: 'Analyst, sensory, food products mfr'
  },
  {
    Id: 26322,
    Code: '8144',
    Name: "Assistant, bundler's, metal"
  },
  {
    Id: 26323,
    Code: '8144',
    Name: "Assistant, taster's, tea"
  },
  {
    Id: 26324,
    Code: '8144',
    Name: 'Attendant, bridge, weigh'
  },
  {
    Id: 26325,
    Code: '8144',
    Name: 'Attendant, cabin, weigh'
  },
  {
    Id: 26326,
    Code: '8144',
    Name: 'Attendant, scale'
  },
  {
    Id: 26327,
    Code: '8144',
    Name: 'Attendant, weighbridge'
  },
  {
    Id: 26328,
    Code: '8144',
    Name: 'Banksman, coal mine: opencast'
  },
  {
    Id: 26329,
    Code: '8144',
    Name: 'Bridgeman, weigh'
  },
  {
    Id: 26330,
    Code: '8144',
    Name: 'Bundler, scrap'
  },
  {
    Id: 26331,
    Code: '8144',
    Name: 'Bundler, sheet, metal'
  },
  {
    Id: 26332,
    Code: '8144',
    Name: 'Bundler, broom, brush mfr'
  },
  {
    Id: 26333,
    Code: '8144',
    Name: 'Bundler, metal trades'
  },
  {
    Id: 26334,
    Code: '8144',
    Name: 'Checker, dipper'
  },
  {
    Id: 26335,
    Code: '8144',
    Name: 'Checkweigher'
  },
  {
    Id: 26336,
    Code: '8144',
    Name: 'Checkweighman'
  },
  {
    Id: 26337,
    Code: '8144',
    Name: 'Classer'
  },
  {
    Id: 26338,
    Code: '8144',
    Name: 'Classifier'
  },
  {
    Id: 26339,
    Code: '8144',
    Name: 'Clerk, machine, weigh, coal mine'
  },
  {
    Id: 26340,
    Code: '8144',
    Name: 'Clerk, weigh'
  },
  {
    Id: 26341,
    Code: '8144',
    Name: 'Clerk, weighbridge'
  },
  {
    Id: 26342,
    Code: '8144',
    Name: 'Clerk, weighing'
  },
  {
    Id: 26343,
    Code: '8144',
    Name: 'Clerk of the scales'
  },
  {
    Id: 26344,
    Code: '8144',
    Name: 'Controller, weighbridge'
  },
  {
    Id: 26345,
    Code: '8144',
    Name: 'Counter, bank-note'
  },
  {
    Id: 26346,
    Code: '8144',
    Name: 'Counter, mine: not coal'
  },
  {
    Id: 26347,
    Code: '8144',
    Name: 'Despatcher, rail'
  },
  {
    Id: 26348,
    Code: '8144',
    Name: 'Despatcher, road'
  },
  {
    Id: 26349,
    Code: '8144',
    Name: 'Dipper, tank, petroleum distribution'
  },
  {
    Id: 26350,
    Code: '8144',
    Name: 'Dipper, oil refining'
  },
  {
    Id: 26351,
    Code: '8144',
    Name: 'Dispatcher, rail'
  },
  {
    Id: 26352,
    Code: '8144',
    Name: 'Dispatcher, road'
  },
  {
    Id: 26353,
    Code: '8144',
    Name: 'Dispenser, bakery'
  },
  {
    Id: 26354,
    Code: '8144',
    Name: 'Divider, type foundry'
  },
  {
    Id: 26355,
    Code: '8144',
    Name: 'Examiner, bottle, brewery'
  },
  {
    Id: 26356,
    Code: '8144',
    Name: 'Examiner, scrap, steelworks'
  },
  {
    Id: 26357,
    Code: '8144',
    Name: 'Examiner, leather dressing'
  },
  {
    Id: 26358,
    Code: '8144',
    Name: 'Examiner, tannery'
  },
  {
    Id: 26359,
    Code: '8144',
    Name: 'Fitter, stock, footwear mfr'
  },
  {
    Id: 26360,
    Code: '8144',
    Name: 'Gauger, cement'
  },
  {
    Id: 26361,
    Code: '8144',
    Name: 'Gauger, tank, oil refining'
  },
  {
    Id: 26362,
    Code: '8144',
    Name: 'Gauger, tank, petroleum distribution'
  },
  {
    Id: 26363,
    Code: '8144',
    Name: 'Gauger, lamp, valve mfr'
  },
  {
    Id: 26364,
    Code: '8144',
    Name: 'Gauger, oil refining'
  },
  {
    Id: 26365,
    Code: '8144',
    Name: 'Grader, egg'
  },
  {
    Id: 26366,
    Code: '8144',
    Name: 'Grader, fat'
  },
  {
    Id: 26367,
    Code: '8144',
    Name: 'Grader, fruit'
  },
  {
    Id: 26368,
    Code: '8144',
    Name: 'Grader, grain'
  },
  {
    Id: 26369,
    Code: '8144',
    Name: 'Grader, hosiery'
  },
  {
    Id: 26370,
    Code: '8144',
    Name: 'Grader, leather'
  },
  {
    Id: 26371,
    Code: '8144',
    Name: 'Grader, meat'
  },
  {
    Id: 26372,
    Code: '8144',
    Name: 'Grader, pelt, fellmongering'
  },
  {
    Id: 26373,
    Code: '8144',
    Name: 'Grader, poultry, retail trade'
  },
  {
    Id: 26374,
    Code: '8144',
    Name: 'Grader, rag'
  },
  {
    Id: 26375,
    Code: '8144',
    Name: 'Grader, sack'
  },
  {
    Id: 26376,
    Code: '8144',
    Name: 'Grader, skin'
  },
  {
    Id: 26377,
    Code: '8144',
    Name: 'Grader, sole'
  },
  {
    Id: 26378,
    Code: '8144',
    Name: 'Grader, wool'
  },
  {
    Id: 26379,
    Code: '8144',
    Name: 'Grader, vegetable'
  },
  {
    Id: 26380,
    Code: '8144',
    Name: 'Grader, abrasive paper, cloth mfr'
  },
  {
    Id: 26381,
    Code: '8144',
    Name: 'Grader, ceramics mfr'
  },
  {
    Id: 26382,
    Code: '8144',
    Name: 'Grader, food products mfr'
  },
  {
    Id: 26383,
    Code: '8144',
    Name: 'Grader, glass mfr'
  },
  {
    Id: 26384,
    Code: '8144',
    Name: 'Grader, metal trades'
  },
  {
    Id: 26385,
    Code: '8144',
    Name: 'Grader, plasterboard mfr'
  },
  {
    Id: 26386,
    Code: '8144',
    Name: 'Grader, textile mfr'
  },
  {
    Id: 26387,
    Code: '8144',
    Name: 'Grader, tobacco pipe mfr'
  },
  {
    Id: 26388,
    Code: '8144',
    Name: 'Grader, wholesale fish trade'
  },
  {
    Id: 26389,
    Code: '8144',
    Name: 'Grader-packer'
  },
  {
    Id: 26390,
    Code: '8144',
    Name: 'Hand, hair, broom, brush mfr'
  },
  {
    Id: 26391,
    Code: '8144',
    Name: 'Inspector, ore'
  },
  {
    Id: 26392,
    Code: '8144',
    Name: 'Inspector, leather dressing'
  },
  {
    Id: 26393,
    Code: '8144',
    Name: 'Keeper, bridge, weigh'
  },
  {
    Id: 26394,
    Code: '8144',
    Name: 'Keeper, machine, weighing'
  },
  {
    Id: 26395,
    Code: '8144',
    Name: 'Machinist, measuring, piece goods'
  },
  {
    Id: 26396,
    Code: '8144',
    Name: 'Machinist, weighing'
  },
  {
    Id: 26397,
    Code: '8144',
    Name: 'Man, yard, coal mine'
  },
  {
    Id: 26398,
    Code: '8144',
    Name: 'Marker, galvanised sheet mfr'
  },
  {
    Id: 26399,
    Code: '8144',
    Name: 'Marker-off, tube mfr'
  },
  {
    Id: 26400,
    Code: '8144',
    Name: 'Marker-out, fustian, velvet mfr'
  },
  {
    Id: 26401,
    Code: '8144',
    Name: 'Measurer, braid'
  },
  {
    Id: 26402,
    Code: '8144',
    Name: 'Measurer, cloth, textile mfr'
  },
  {
    Id: 26403,
    Code: '8144',
    Name: 'Measurer, piece'
  },
  {
    Id: 26404,
    Code: '8144',
    Name: 'Measurer, skin, food products mfr'
  },
  {
    Id: 26405,
    Code: '8144',
    Name: 'Measurer, timber'
  },
  {
    Id: 26406,
    Code: '8144',
    Name: 'Measurer, wood'
  },
  {
    Id: 26407,
    Code: '8144',
    Name: 'Measurer, cable mfr'
  },
  {
    Id: 26408,
    Code: '8144',
    Name: 'Measurer, carpet, rug mfr'
  },
  {
    Id: 26409,
    Code: '8144',
    Name: 'Measurer, chemical mfr'
  },
  {
    Id: 26410,
    Code: '8144',
    Name: 'Measurer, leather dressing'
  },
  {
    Id: 26411,
    Code: '8144',
    Name: 'Measurer, tape mfr'
  },
  {
    Id: 26412,
    Code: '8144',
    Name: 'Operator, bridge, weigh'
  },
  {
    Id: 26413,
    Code: '8144',
    Name: 'Operator, weighbridge'
  },
  {
    Id: 26414,
    Code: '8144',
    Name: 'Packer, drum, furnace'
  },
  {
    Id: 26415,
    Code: '8144',
    Name: 'Packer-grader'
  },
  {
    Id: 26416,
    Code: '8144',
    Name: 'Picker, bowl'
  },
  {
    Id: 26417,
    Code: '8144',
    Name: 'Picker, cotton'
  },
  {
    Id: 26418,
    Code: '8144',
    Name: 'Picker, flock'
  },
  {
    Id: 26419,
    Code: '8144',
    Name: 'Picker, lime'
  },
  {
    Id: 26420,
    Code: '8144',
    Name: 'Picker, pea, food processing'
  },
  {
    Id: 26421,
    Code: '8144',
    Name: 'Picker, prawn'
  },
  {
    Id: 26422,
    Code: '8144',
    Name: 'Picker, stone, stone dressing'
  },
  {
    Id: 26423,
    Code: '8144',
    Name: 'Picker, building construction'
  },
  {
    Id: 26424,
    Code: '8144',
    Name: 'Picker, stone dressing'
  },
  {
    Id: 26425,
    Code: '8144',
    Name: 'Picker, textile mfr: flock mfr'
  },
  {
    Id: 26426,
    Code: '8144',
    Name: 'Picker, textile mfr: wool sorting'
  },
  {
    Id: 26427,
    Code: '8144',
    Name: 'Picker-packer, vehicle mfr'
  },
  {
    Id: 26428,
    Code: '8144',
    Name: 'Racker, laundry, launderette, dry cleaning'
  },
  {
    Id: 26429,
    Code: '8144',
    Name: 'Reckoner, tinplate mfr'
  },
  {
    Id: 26430,
    Code: '8144',
    Name: 'Scaleman'
  },
  {
    Id: 26431,
    Code: '8144',
    Name: 'Scaler, slaughterhouse'
  },
  {
    Id: 26432,
    Code: '8144',
    Name: 'Scalesman'
  },
  {
    Id: 26433,
    Code: '8144',
    Name: 'Selector, biscuit, ceramics mfr'
  },
  {
    Id: 26434,
    Code: '8144',
    Name: 'Selector, sack'
  },
  {
    Id: 26435,
    Code: '8144',
    Name: 'Selector, skin'
  },
  {
    Id: 26436,
    Code: '8144',
    Name: 'Selector, canvas goods mfr'
  },
  {
    Id: 26437,
    Code: '8144',
    Name: 'Selector, ceramics mfr'
  },
  {
    Id: 26438,
    Code: '8144',
    Name: 'Selector, flax, hemp mfr'
  },
  {
    Id: 26439,
    Code: '8144',
    Name: 'Selector, mine: not coal'
  },
  {
    Id: 26440,
    Code: '8144',
    Name: 'Selector, plastics mfr'
  },
  {
    Id: 26441,
    Code: '8144',
    Name: 'Selector and classifier, mica'
  },
  {
    Id: 26442,
    Code: '8144',
    Name: 'Skipman'
  },
  {
    Id: 26443,
    Code: '8144',
    Name: 'Sorter, biscuit'
  },
  {
    Id: 26444,
    Code: '8144',
    Name: 'Sorter, bobbin'
  },
  {
    Id: 26445,
    Code: '8144',
    Name: 'Sorter, bottle'
  },
  {
    Id: 26446,
    Code: '8144',
    Name: 'Sorter, breakage, food products mfr'
  },
  {
    Id: 26447,
    Code: '8144',
    Name: 'Sorter, bulb, electric lamp mfr'
  },
  {
    Id: 26448,
    Code: '8144',
    Name: 'Sorter, clip'
  },
  {
    Id: 26449,
    Code: '8144',
    Name: 'Sorter, cloth'
  },
  {
    Id: 26450,
    Code: '8144',
    Name: 'Sorter, cork'
  },
  {
    Id: 26451,
    Code: '8144',
    Name: 'Sorter, diamond'
  },
  {
    Id: 26452,
    Code: '8144',
    Name: 'Sorter, dyehouse, textile mfr'
  },
  {
    Id: 26453,
    Code: '8144',
    Name: 'Sorter, egg'
  },
  {
    Id: 26454,
    Code: '8144',
    Name: 'Sorter, feather'
  },
  {
    Id: 26455,
    Code: '8144',
    Name: 'Sorter, fent'
  },
  {
    Id: 26456,
    Code: '8144',
    Name: 'Sorter, fibre'
  },
  {
    Id: 26457,
    Code: '8144',
    Name: 'Sorter, flock'
  },
  {
    Id: 26458,
    Code: '8144',
    Name: 'Sorter, foil, tin'
  },
  {
    Id: 26459,
    Code: '8144',
    Name: 'Sorter, fruit'
  },
  {
    Id: 26460,
    Code: '8144',
    Name: 'Sorter, fur'
  },
  {
    Id: 26461,
    Code: '8144',
    Name: 'Sorter, gelatine'
  },
  {
    Id: 26462,
    Code: '8144',
    Name: 'Sorter, gum'
  },
  {
    Id: 26463,
    Code: '8144',
    Name: 'Sorter, hair'
  },
  {
    Id: 26464,
    Code: '8144',
    Name: 'Sorter, head, galvanised sheet mfr'
  },
  {
    Id: 26465,
    Code: '8144',
    Name: 'Sorter, hide'
  },
  {
    Id: 26466,
    Code: '8144',
    Name: 'Sorter, hosiery'
  },
  {
    Id: 26467,
    Code: '8144',
    Name: 'Sorter, house, dye, textile mfr'
  },
  {
    Id: 26468,
    Code: '8144',
    Name: 'Sorter, last'
  },
  {
    Id: 26469,
    Code: '8144',
    Name: 'Sorter, leaf'
  },
  {
    Id: 26470,
    Code: '8144',
    Name: 'Sorter, leather'
  },
  {
    Id: 26471,
    Code: '8144',
    Name: 'Sorter, lime'
  },
  {
    Id: 26472,
    Code: '8144',
    Name: 'Sorter, linen, hospital service'
  },
  {
    Id: 26473,
    Code: '8144',
    Name: 'Sorter, machine, ceramics mfr'
  },
  {
    Id: 26474,
    Code: '8144',
    Name: 'Sorter, meat'
  },
  {
    Id: 26475,
    Code: '8144',
    Name: 'Sorter, metal'
  },
  {
    Id: 26476,
    Code: '8144',
    Name: 'Sorter, mica'
  },
  {
    Id: 26477,
    Code: '8144',
    Name: 'Sorter, mohair'
  },
  {
    Id: 26478,
    Code: '8144',
    Name: 'Sorter, newspaper'
  },
  {
    Id: 26479,
    Code: '8144',
    Name: 'Sorter, paper, waste paper merchants'
  },
  {
    Id: 26480,
    Code: '8144',
    Name: 'Sorter, pipe, ceramic'
  },
  {
    Id: 26481,
    Code: '8144',
    Name: 'Sorter, rag'
  },
  {
    Id: 26482,
    Code: '8144',
    Name: 'Sorter, rag and metal'
  },
  {
    Id: 26483,
    Code: '8144',
    Name: 'Sorter, refuse'
  },
  {
    Id: 26484,
    Code: '8144',
    Name: 'Sorter, rubber'
  },
  {
    Id: 26485,
    Code: '8144',
    Name: 'Sorter, sack'
  },
  {
    Id: 26486,
    Code: '8144',
    Name: 'Sorter, salvage'
  },
  {
    Id: 26487,
    Code: '8144',
    Name: 'Sorter, scrap'
  },
  {
    Id: 26488,
    Code: '8144',
    Name: 'Sorter, seed'
  },
  {
    Id: 26489,
    Code: '8144',
    Name: 'Sorter, shuttle'
  },
  {
    Id: 26490,
    Code: '8144',
    Name: 'Sorter, skin'
  },
  {
    Id: 26491,
    Code: '8144',
    Name: 'Sorter, slag'
  },
  {
    Id: 26492,
    Code: '8144',
    Name: 'Sorter, sole'
  },
  {
    Id: 26493,
    Code: '8144',
    Name: 'Sorter, spool'
  },
  {
    Id: 26494,
    Code: '8144',
    Name: 'Sorter, stocking'
  },
  {
    Id: 26495,
    Code: '8144',
    Name: 'Sorter, stores'
  },
  {
    Id: 26496,
    Code: '8144',
    Name: 'Sorter, tape'
  },
  {
    Id: 26497,
    Code: '8144',
    Name: 'Sorter, tile, roofing'
  },
  {
    Id: 26498,
    Code: '8144',
    Name: 'Sorter, timber'
  },
  {
    Id: 26499,
    Code: '8144',
    Name: 'Sorter, tin'
  },
  {
    Id: 26500,
    Code: '8144',
    Name: 'Sorter, warehouse, biscuit, ceramics mfr'
  },
  {
    Id: 26501,
    Code: '8144',
    Name: 'Sorter, waste'
  },
  {
    Id: 26502,
    Code: '8144',
    Name: 'Sorter, wood'
  },
  {
    Id: 26503,
    Code: '8144',
    Name: 'Sorter, wool'
  },
  {
    Id: 26504,
    Code: '8144',
    Name: 'Sorter, woollen'
  },
  {
    Id: 26505,
    Code: '8144',
    Name: 'Sorter, yarn'
  },
  {
    Id: 26506,
    Code: '8144',
    Name: 'Sorter, charitable organisation'
  },
  {
    Id: 26507,
    Code: '8144',
    Name: 'Sorter, broom, brush mfr'
  },
  {
    Id: 26508,
    Code: '8144',
    Name: 'Sorter, button mfr'
  },
  {
    Id: 26509,
    Code: '8144',
    Name: 'Sorter, ceramics mfr'
  },
  {
    Id: 26510,
    Code: '8144',
    Name: 'Sorter, cigar mfr'
  },
  {
    Id: 26511,
    Code: '8144',
    Name: 'Sorter, clothing mfr'
  },
  {
    Id: 26512,
    Code: '8144',
    Name: 'Sorter, cutlery handle mfr'
  },
  {
    Id: 26513,
    Code: '8144',
    Name: 'Sorter, dyeing and cleaning'
  },
  {
    Id: 26514,
    Code: '8144',
    Name: 'Sorter, food products mfr'
  },
  {
    Id: 26515,
    Code: '8144',
    Name: 'Sorter, footwear mfr'
  },
  {
    Id: 26516,
    Code: '8144',
    Name: 'Sorter, fur dressing'
  },
  {
    Id: 26517,
    Code: '8144',
    Name: 'Sorter, glass mfr: glass bottle mfr'
  },
  {
    Id: 26518,
    Code: '8144',
    Name: 'Sorter, incandescent mantle mfr'
  },
  {
    Id: 26519,
    Code: '8144',
    Name: 'Sorter, laundry, launderette, dry cleaning'
  },
  {
    Id: 26520,
    Code: '8144',
    Name: 'Sorter, metal trades'
  },
  {
    Id: 26521,
    Code: '8144',
    Name: 'Sorter, mine: not coal'
  },
  {
    Id: 26522,
    Code: '8144',
    Name: 'Sorter, seed merchants'
  },
  {
    Id: 26523,
    Code: '8144',
    Name: 'Sorter, stick mfr'
  },
  {
    Id: 26524,
    Code: '8144',
    Name: 'Sorter, tannery'
  },
  {
    Id: 26525,
    Code: '8144',
    Name: 'Sorter, textile mfr'
  },
  {
    Id: 26526,
    Code: '8144',
    Name: 'Sorter, waste merchants'
  },
  {
    Id: 26527,
    Code: '8144',
    Name: 'Sorter, wholesale fish trade'
  },
  {
    Id: 26528,
    Code: '8144',
    Name: 'Sorter and grader, canvas goods mfr'
  },
  {
    Id: 26529,
    Code: '8144',
    Name: 'Sorter and packer'
  },
  {
    Id: 26530,
    Code: '8144',
    Name: 'Stuffer, textile bleaching, dyeing'
  },
  {
    Id: 26531,
    Code: '8144',
    Name: 'Taker, weight'
  },
  {
    Id: 26532,
    Code: '8144',
    Name: 'Taker-off, textile mfr: wool sorting'
  },
  {
    Id: 26533,
    Code: '8144',
    Name: 'Taster, coffee'
  },
  {
    Id: 26534,
    Code: '8144',
    Name: 'Taster, tea'
  },
  {
    Id: 26535,
    Code: '8144',
    Name: 'Taster, wine'
  },
  {
    Id: 26536,
    Code: '8144',
    Name: 'Taster, food products mfr'
  },
  {
    Id: 26537,
    Code: '8144',
    Name: 'Teller, printing'
  },
  {
    Id: 26538,
    Code: '8144',
    Name: 'Washer, cullet'
  },
  {
    Id: 26539,
    Code: '8144',
    Name: 'Weighbridgeman'
  },
  {
    Id: 26540,
    Code: '8144',
    Name: 'Weigher'
  },
  {
    Id: 26541,
    Code: '8144',
    Name: 'Weigher and mixer, colour'
  },
  {
    Id: 26542,
    Code: '8144',
    Name: 'Weigherman, card, pasteboard mfr'
  },
  {
    Id: 26543,
    Code: '8144',
    Name: 'Weighman'
  },
  {
    Id: 26544,
    Code: '8144',
    Name: 'Yardman, coal mine'
  },
  {
    Id: 26545,
    Code: '8144',
    Name: 'Yardsman, coal mine'
  },
  {
    Id: 26546,
    Code: '8145',
    Name: 'Assistant, garage'
  },
  {
    Id: 26547,
    Code: '8145',
    Name: 'Fitter, exhaust, motor vehicle repair'
  },
  {
    Id: 26548,
    Code: '8145',
    Name: 'Fitter, exhaust, vehicles'
  },
  {
    Id: 26549,
    Code: '8145',
    Name: 'Fitter, glass, vehicle mfr'
  },
  {
    Id: 26550,
    Code: '8145',
    Name: 'Fitter, maintenance, tyre'
  },
  {
    Id: 26551,
    Code: '8145',
    Name: 'Fitter, screen, vehicle mfr'
  },
  {
    Id: 26552,
    Code: '8145',
    Name: 'Fitter, towbar'
  },
  {
    Id: 26553,
    Code: '8145',
    Name: 'Fitter, tyre'
  },
  {
    Id: 26554,
    Code: '8145',
    Name: 'Fitter, tyre and exhaust'
  },
  {
    Id: 26555,
    Code: '8145',
    Name: 'Fitter, windscreen'
  },
  {
    Id: 26556,
    Code: '8145',
    Name: 'Fitter, exhaust, tyre fitting'
  },
  {
    Id: 26557,
    Code: '8145',
    Name: 'Fitter, windscreen fitting'
  },
  {
    Id: 26558,
    Code: '8145',
    Name: 'Fixer, window, vehicles'
  },
  {
    Id: 26559,
    Code: '8145',
    Name: 'Glazier, automotive'
  },
  {
    Id: 26560,
    Code: '8145',
    Name: 'Glazier, vehicle'
  },
  {
    Id: 26561,
    Code: '8145',
    Name: 'Hand, garage'
  },
  {
    Id: 26562,
    Code: '8145',
    Name: 'Technician, fit, fast'
  },
  {
    Id: 26563,
    Code: '8145',
    Name: 'Technician, glazing, vehicles'
  },
  {
    Id: 26564,
    Code: '8145',
    Name: 'Technician, tyre'
  },
  {
    Id: 26565,
    Code: '8145',
    Name: 'Technician, windscreen'
  },
  {
    Id: 26566,
    Code: '8145',
    Name: 'Worker, garage, PO'
  },
  {
    Id: 26567,
    Code: '8145',
    Name: 'Worker, tyre, garage'
  },
  {
    Id: 26568,
    Code: '8146',
    Name: 'Assistant, room, sewing'
  },
  {
    Id: 26569,
    Code: '8146',
    Name: "Assistant, upholsterer's"
  },
  {
    Id: 26570,
    Code: '8146',
    Name: 'Backer, clothing mfr'
  },
  {
    Id: 26571,
    Code: '8146',
    Name: 'Bagger-out'
  },
  {
    Id: 26572,
    Code: '8146',
    Name: 'Beader, embroidering'
  },
  {
    Id: 26573,
    Code: '8146',
    Name: 'Binder, blanket'
  },
  {
    Id: 26574,
    Code: '8146',
    Name: 'Binder, carpet'
  },
  {
    Id: 26575,
    Code: '8146',
    Name: 'Binder, leather, hat'
  },
  {
    Id: 26576,
    Code: '8146',
    Name: 'Binder, blanket mfr'
  },
  {
    Id: 26577,
    Code: '8146',
    Name: 'Binder, canvas goods mfr'
  },
  {
    Id: 26578,
    Code: '8146',
    Name: 'Binder, fabric glove mfr'
  },
  {
    Id: 26579,
    Code: '8146',
    Name: 'Binder, hat mfr'
  },
  {
    Id: 26580,
    Code: '8146',
    Name: 'Binder, hosiery, knitwear mfr'
  },
  {
    Id: 26581,
    Code: '8146',
    Name: 'Braider, clothing mfr'
  },
  {
    Id: 26582,
    Code: '8146',
    Name: 'Burler'
  },
  {
    Id: 26583,
    Code: '8146',
    Name: 'Burler and mender'
  },
  {
    Id: 26584,
    Code: '8146',
    Name: 'Canvasser, clothing mfr'
  },
  {
    Id: 26585,
    Code: '8146',
    Name: 'Clocker'
  },
  {
    Id: 26586,
    Code: '8146',
    Name: 'Closer, clothing mfr'
  },
  {
    Id: 26587,
    Code: '8146',
    Name: 'Coverer, umbrella'
  },
  {
    Id: 26588,
    Code: '8146',
    Name: 'Drawer, fine'
  },
  {
    Id: 26589,
    Code: '8146',
    Name: 'Elasticator'
  },
  {
    Id: 26590,
    Code: '8146',
    Name: 'Examiner, heald'
  },
  {
    Id: 26591,
    Code: '8146',
    Name: 'Examiner and finisher, net'
  },
  {
    Id: 26592,
    Code: '8146',
    Name: 'Examiner and mender, hosiery'
  },
  {
    Id: 26593,
    Code: '8146',
    Name: 'Fanner, corset mfr'
  },
  {
    Id: 26594,
    Code: '8146',
    Name: 'Feller, clothing mfr'
  },
  {
    Id: 26595,
    Code: '8146',
    Name: 'Finisher, cap'
  },
  {
    Id: 26596,
    Code: '8146',
    Name: 'Finisher, coat'
  },
  {
    Id: 26597,
    Code: '8146',
    Name: 'Finisher, collar'
  },
  {
    Id: 26598,
    Code: '8146',
    Name: 'Finisher, curtain'
  },
  {
    Id: 26599,
    Code: '8146',
    Name: 'Finisher, dress'
  },
  {
    Id: 26600,
    Code: '8146',
    Name: 'Finisher, glove'
  },
  {
    Id: 26601,
    Code: '8146',
    Name: 'Finisher, gown'
  },
  {
    Id: 26602,
    Code: '8146',
    Name: 'Finisher, kilt'
  },
  {
    Id: 26603,
    Code: '8146',
    Name: 'Finisher, shirt'
  },
  {
    Id: 26604,
    Code: '8146',
    Name: "Finisher, tailor's"
  },
  {
    Id: 26605,
    Code: '8146',
    Name: 'Finisher, trouser'
  },
  {
    Id: 26606,
    Code: '8146',
    Name: 'Finisher, umbrella'
  },
  {
    Id: 26607,
    Code: '8146',
    Name: 'Finisher, clothing mfr'
  },
  {
    Id: 26608,
    Code: '8146',
    Name: 'Finisher, embroidery mfr'
  },
  {
    Id: 26609,
    Code: '8146',
    Name: 'Finisher, fur goods mfr'
  },
  {
    Id: 26610,
    Code: '8146',
    Name: 'Finisher, hosiery garment mfr'
  },
  {
    Id: 26611,
    Code: '8146',
    Name: 'Finisher, knitwear mfr'
  },
  {
    Id: 26612,
    Code: '8146',
    Name: 'Finisher, soft furnishings mfr'
  },
  {
    Id: 26613,
    Code: '8146',
    Name: 'Finisher, soft toy mfr'
  },
  {
    Id: 26614,
    Code: '8146',
    Name: 'Finisher, umbrella, parasol mfr'
  },
  {
    Id: 26615,
    Code: '8146',
    Name: 'Finisher and liner, fur garment mfr'
  },
  {
    Id: 26616,
    Code: '8146',
    Name: 'Friller'
  },
  {
    Id: 26617,
    Code: '8146',
    Name: 'Glover, glove mfr'
  },
  {
    Id: 26618,
    Code: '8146',
    Name: 'Hand, buttonhole'
  },
  {
    Id: 26619,
    Code: '8146',
    Name: 'Hand, collar, clothing mfr'
  },
  {
    Id: 26620,
    Code: '8146',
    Name: 'Hand, feller'
  },
  {
    Id: 26621,
    Code: '8146',
    Name: 'Hand, felling'
  },
  {
    Id: 26622,
    Code: '8146',
    Name: 'Hand, needlework'
  },
  {
    Id: 26623,
    Code: '8146',
    Name: 'Hand, palm and needle'
  },
  {
    Id: 26624,
    Code: '8146',
    Name: 'Hand, sample, clothing mfr'
  },
  {
    Id: 26625,
    Code: '8146',
    Name: 'Hand, sewing'
  },
  {
    Id: 26626,
    Code: '8146',
    Name: 'Hand, suspender'
  },
  {
    Id: 26627,
    Code: '8146',
    Name: 'Hemmer'
  },
  {
    Id: 26628,
    Code: '8146',
    Name: 'Hemstitcher'
  },
  {
    Id: 26629,
    Code: '8146',
    Name: 'Holer, button'
  },
  {
    Id: 26630,
    Code: '8146',
    Name: 'Knitter, power, textile mfr'
  },
  {
    Id: 26631,
    Code: '8146',
    Name: 'Knotter, textile mfr: examining dept'
  },
  {
    Id: 26632,
    Code: '8146',
    Name: 'Liner, clothing mfr'
  },
  {
    Id: 26633,
    Code: '8146',
    Name: 'Liner, fur garment mfr'
  },
  {
    Id: 26634,
    Code: '8146',
    Name: 'Liner, hat mfr'
  },
  {
    Id: 26635,
    Code: '8146',
    Name: 'Linker, textile mfr: hosiery, knitwear mfr'
  },
  {
    Id: 26636,
    Code: '8146',
    Name: 'Locker, flat'
  },
  {
    Id: 26637,
    Code: '8146',
    Name: 'Looper, hosiery, knitwear mfr'
  },
  {
    Id: 26638,
    Code: '8146',
    Name: 'Machinist, automatic, sewing'
  },
  {
    Id: 26639,
    Code: '8146',
    Name: 'Machinist, bag, sack mfr'
  },
  {
    Id: 26640,
    Code: '8146',
    Name: 'Machinist, barring, clothing mfr'
  },
  {
    Id: 26641,
    Code: '8146',
    Name: 'Machinist, bartack'
  },
  {
    Id: 26642,
    Code: '8146',
    Name: 'Machinist, basting'
  },
  {
    Id: 26643,
    Code: '8146',
    Name: 'Machinist, bedding'
  },
  {
    Id: 26644,
    Code: '8146',
    Name: 'Machinist, belt, conveyor'
  },
  {
    Id: 26645,
    Code: '8146',
    Name: 'Machinist, binding'
  },
  {
    Id: 26646,
    Code: '8146',
    Name: 'Machinist, blanket'
  },
  {
    Id: 26647,
    Code: '8146',
    Name: 'Machinist, blouse'
  },
  {
    Id: 26648,
    Code: '8146',
    Name: 'Machinist, button, clothing mfr'
  },
  {
    Id: 26649,
    Code: '8146',
    Name: 'Machinist, buttonhole'
  },
  {
    Id: 26650,
    Code: '8146',
    Name: 'Machinist, buttoning, clothing mfr'
  },
  {
    Id: 26651,
    Code: '8146',
    Name: 'Machinist, canvas'
  },
  {
    Id: 26652,
    Code: '8146',
    Name: 'Machinist, cap'
  },
  {
    Id: 26653,
    Code: '8146',
    Name: 'Machinist, carpet'
  },
  {
    Id: 26654,
    Code: '8146',
    Name: 'Machinist, clothing'
  },
  {
    Id: 26655,
    Code: '8146',
    Name: 'Machinist, coat'
  },
  {
    Id: 26656,
    Code: '8146',
    Name: 'Machinist, collar, clothing mfr'
  },
  {
    Id: 26657,
    Code: '8146',
    Name: 'Machinist, cornelly'
  },
  {
    Id: 26658,
    Code: '8146',
    Name: 'Machinist, cornely'
  },
  {
    Id: 26659,
    Code: '8146',
    Name: 'Machinist, corset'
  },
  {
    Id: 26660,
    Code: '8146',
    Name: 'Machinist, darning, textile mfr'
  },
  {
    Id: 26661,
    Code: '8146',
    Name: 'Machinist, dress'
  },
  {
    Id: 26662,
    Code: '8146',
    Name: 'Machinist, dressing, surgical'
  },
  {
    Id: 26663,
    Code: '8146',
    Name: "Machinist, dressmaker's"
  },
  {
    Id: 26664,
    Code: '8146',
    Name: 'Machinist, elasticator'
  },
  {
    Id: 26665,
    Code: '8146',
    Name: 'Machinist, embroidery'
  },
  {
    Id: 26666,
    Code: '8146',
    Name: 'Machinist, fabric, circular'
  },
  {
    Id: 26667,
    Code: '8146',
    Name: 'Machinist, fancy'
  },
  {
    Id: 26668,
    Code: '8146',
    Name: 'Machinist, feather'
  },
  {
    Id: 26669,
    Code: '8146',
    Name: 'Machinist, felling'
  },
  {
    Id: 26670,
    Code: '8146',
    Name: 'Machinist, flag'
  },
  {
    Id: 26671,
    Code: '8146',
    Name: 'Machinist, flat, hand'
  },
  {
    Id: 26672,
    Code: '8146',
    Name: 'Machinist, flat, clothing mfr'
  },
  {
    Id: 26673,
    Code: '8146',
    Name: 'Machinist, foot'
  },
  {
    Id: 26674,
    Code: '8146',
    Name: 'Machinist, fur'
  },
  {
    Id: 26675,
    Code: '8146',
    Name: 'Machinist, glove, leather'
  },
  {
    Id: 26676,
    Code: '8146',
    Name: 'Machinist, glove, woollen'
  },
  {
    Id: 26677,
    Code: '8146',
    Name: 'Machinist, gown'
  },
  {
    Id: 26678,
    Code: '8146',
    Name: 'Machinist, hand, repairer'
  },
  {
    Id: 26679,
    Code: '8146',
    Name: 'Machinist, hand, clothing mfr'
  },
  {
    Id: 26680,
    Code: '8146',
    Name: 'Machinist, hemming'
  },
  {
    Id: 26681,
    Code: '8146',
    Name: 'Machinist, hemstitch'
  },
  {
    Id: 26682,
    Code: '8146',
    Name: 'Machinist, hosiery, surgical'
  },
  {
    Id: 26683,
    Code: '8146',
    Name: 'Machinist, hosiery'
  },
  {
    Id: 26684,
    Code: '8146',
    Name: 'Machinist, jacket'
  },
  {
    Id: 26685,
    Code: '8146',
    Name: 'Machinist, jacquard'
  },
  {
    Id: 26686,
    Code: '8146',
    Name: 'Machinist, knicker'
  },
  {
    Id: 26687,
    Code: '8146',
    Name: 'Machinist, knitting'
  },
  {
    Id: 26688,
    Code: '8146',
    Name: 'Machinist, lace, lace mfr'
  },
  {
    Id: 26689,
    Code: '8146',
    Name: 'Machinist, lace, textile mfr'
  },
  {
    Id: 26690,
    Code: '8146',
    Name: 'Machinist, lashing'
  },
  {
    Id: 26691,
    Code: '8146',
    Name: 'Machinist, leather, clothing mfr'
  },
  {
    Id: 26692,
    Code: '8146',
    Name: 'Machinist, linen'
  },
  {
    Id: 26693,
    Code: '8146',
    Name: 'Machinist, lining, clothing mfr'
  },
  {
    Id: 26694,
    Code: '8146',
    Name: 'Machinist, lining, hat mfr'
  },
  {
    Id: 26695,
    Code: '8146',
    Name: 'Machinist, lock, flat'
  },
  {
    Id: 26696,
    Code: '8146',
    Name: 'Machinist, lockstitch'
  },
  {
    Id: 26697,
    Code: '8146',
    Name: 'Machinist, millinery'
  },
  {
    Id: 26698,
    Code: '8146',
    Name: 'Machinist, needle'
  },
  {
    Id: 26699,
    Code: '8146',
    Name: 'Machinist, net'
  },
  {
    Id: 26700,
    Code: '8146',
    Name: 'Machinist, oilskin'
  },
  {
    Id: 26701,
    Code: '8146',
    Name: 'Machinist, outer-wear'
  },
  {
    Id: 26702,
    Code: '8146',
    Name: 'Machinist, outerwear'
  },
  {
    Id: 26703,
    Code: '8146',
    Name: 'Machinist, overhead'
  },
  {
    Id: 26704,
    Code: '8146',
    Name: 'Machinist, overlock'
  },
  {
    Id: 26705,
    Code: '8146',
    Name: 'Machinist, overlocking'
  },
  {
    Id: 26706,
    Code: '8146',
    Name: 'Machinist, padding, clothing mfr'
  },
  {
    Id: 26707,
    Code: '8146',
    Name: 'Machinist, plain, shirts'
  },
  {
    Id: 26708,
    Code: '8146',
    Name: 'Machinist, pleating'
  },
  {
    Id: 26709,
    Code: '8146',
    Name: 'Machinist, post, clothing mfr'
  },
  {
    Id: 26710,
    Code: '8146',
    Name: 'Machinist, quilting'
  },
  {
    Id: 26711,
    Code: '8146',
    Name: 'Machinist, rug and blanket, horse'
  },
  {
    Id: 26712,
    Code: '8146',
    Name: 'Machinist, sack'
  },
  {
    Id: 26713,
    Code: '8146',
    Name: 'Machinist, sample'
  },
  {
    Id: 26714,
    Code: '8146',
    Name: 'Machinist, seamless'
  },
  {
    Id: 26715,
    Code: '8146',
    Name: 'Machinist, sewing'
  },
  {
    Id: 26716,
    Code: '8146',
    Name: 'Machinist, shirt'
  },
  {
    Id: 26717,
    Code: '8146',
    Name: 'Machinist, silk, clothing mfr'
  },
  {
    Id: 26718,
    Code: '8146',
    Name: 'Machinist, sleeving'
  },
  {
    Id: 26719,
    Code: '8146',
    Name: 'Machinist, special, clothing mfr'
  },
  {
    Id: 26720,
    Code: '8146',
    Name: 'Machinist, stitch'
  },
  {
    Id: 26721,
    Code: '8146',
    Name: 'Machinist, stitching, footwear mfr'
  },
  {
    Id: 26722,
    Code: '8146',
    Name: 'Machinist, stocking, hosiery, knitwear mfr'
  },
  {
    Id: 26723,
    Code: '8146',
    Name: "Machinist, tailor's"
  },
  {
    Id: 26724,
    Code: '8146',
    Name: 'Machinist, tape, hat mfr'
  },
  {
    Id: 26725,
    Code: '8146',
    Name: 'Machinist, tent'
  },
  {
    Id: 26726,
    Code: '8146',
    Name: 'Machinist, textile, clothing mfr'
  },
  {
    Id: 26727,
    Code: '8146',
    Name: 'Machinist, tie'
  },
  {
    Id: 26728,
    Code: '8146',
    Name: 'Machinist, towel'
  },
  {
    Id: 26729,
    Code: '8146',
    Name: 'Machinist, toy, soft'
  },
  {
    Id: 26730,
    Code: '8146',
    Name: 'Machinist, trouser'
  },
  {
    Id: 26731,
    Code: '8146',
    Name: 'Machinist, tucking'
  },
  {
    Id: 26732,
    Code: '8146',
    Name: "Machinist, upholsterer's"
  },
  {
    Id: 26733,
    Code: '8146',
    Name: 'Machinist, upholstery'
  },
  {
    Id: 26734,
    Code: '8146',
    Name: 'Machinist, vest'
  },
  {
    Id: 26735,
    Code: '8146',
    Name: 'Machinist, welt, clothing mfr'
  },
  {
    Id: 26736,
    Code: '8146',
    Name: 'Machinist, welting, hosiery'
  },
  {
    Id: 26737,
    Code: '8146',
    Name: 'Machinist, wheel'
  },
  {
    Id: 26738,
    Code: '8146',
    Name: 'Machinist, zigzag'
  },
  {
    Id: 26739,
    Code: '8146',
    Name: 'Machinist, bedding mfr'
  },
  {
    Id: 26740,
    Code: '8146',
    Name: 'Machinist, canvas goods mfr'
  },
  {
    Id: 26741,
    Code: '8146',
    Name: 'Machinist, clothing mfr'
  },
  {
    Id: 26742,
    Code: '8146',
    Name: 'Machinist, electric blanket mfr'
  },
  {
    Id: 26743,
    Code: '8146',
    Name: 'Machinist, hat mfr'
  },
  {
    Id: 26744,
    Code: '8146',
    Name: 'Machinist, mattress mfr'
  },
  {
    Id: 26745,
    Code: '8146',
    Name: 'Machinist, soft furnishings mfr'
  },
  {
    Id: 26746,
    Code: '8146',
    Name: 'Machinist, surgical dressing mfr'
  },
  {
    Id: 26747,
    Code: '8146',
    Name: 'Machinist, textile mfr: carpet, rug mfr'
  },
  {
    Id: 26748,
    Code: '8146',
    Name: 'Machinist, textile mfr: hosiery mfr'
  },
  {
    Id: 26749,
    Code: '8146',
    Name: 'Machinist, textile mfr: textile weaving'
  },
  {
    Id: 26750,
    Code: '8146',
    Name: 'Machinist, textile products mfr'
  },
  {
    Id: 26751,
    Code: '8146',
    Name: 'Machinist, upholstering'
  },
  {
    Id: 26752,
    Code: '8146',
    Name: 'Maid, sewing'
  },
  {
    Id: 26753,
    Code: '8146',
    Name: 'Maker, accoutrements'
  },
  {
    Id: 26754,
    Code: '8146',
    Name: 'Maker, bag, gun'
  },
  {
    Id: 26755,
    Code: '8146',
    Name: 'Maker, bag, rod, fishing'
  },
  {
    Id: 26756,
    Code: '8146',
    Name: 'Maker, bag, sand'
  },
  {
    Id: 26757,
    Code: '8146',
    Name: 'Maker, bag, canvas goods mfr'
  },
  {
    Id: 26758,
    Code: '8146',
    Name: 'Maker, belt'
  },
  {
    Id: 26759,
    Code: '8146',
    Name: 'Maker, blanket'
  },
  {
    Id: 26760,
    Code: '8146',
    Name: 'Maker, bow, clothing mfr'
  },
  {
    Id: 26761,
    Code: '8146',
    Name: 'Maker, canopy'
  },
  {
    Id: 26762,
    Code: '8146',
    Name: 'Maker, collar, clothing mfr'
  },
  {
    Id: 26763,
    Code: '8146',
    Name: 'Maker, cover, loose'
  },
  {
    Id: 26764,
    Code: '8146',
    Name: 'Maker, cover, tyre'
  },
  {
    Id: 26765,
    Code: '8146',
    Name: 'Maker, dressing, surgical'
  },
  {
    Id: 26766,
    Code: '8146',
    Name: 'Maker, embroidery'
  },
  {
    Id: 26767,
    Code: '8146',
    Name: 'Maker, fichu'
  },
  {
    Id: 26768,
    Code: '8146',
    Name: 'Maker, glove'
  },
  {
    Id: 26769,
    Code: '8146',
    Name: 'Maker, goods, canvas'
  },
  {
    Id: 26770,
    Code: '8146',
    Name: 'Maker, hole, button'
  },
  {
    Id: 26771,
    Code: '8146',
    Name: 'Maker, knapsack'
  },
  {
    Id: 26772,
    Code: '8146',
    Name: 'Maker, lining, clothing mfr'
  },
  {
    Id: 26773,
    Code: '8146',
    Name: 'Maker, marquee'
  },
  {
    Id: 26774,
    Code: '8146',
    Name: 'Maker, mat, wool'
  },
  {
    Id: 26775,
    Code: '8146',
    Name: 'Maker, parachute'
  },
  {
    Id: 26776,
    Code: '8146',
    Name: 'Maker, pyjama'
  },
  {
    Id: 26777,
    Code: '8146',
    Name: 'Maker, sack and bag'
  },
  {
    Id: 26778,
    Code: '8146',
    Name: 'Maker, sheet, railways'
  },
  {
    Id: 26779,
    Code: '8146',
    Name: 'Maker, shroud'
  },
  {
    Id: 26780,
    Code: '8146',
    Name: 'Maker, sleeve, clothing mfr'
  },
  {
    Id: 26781,
    Code: '8146',
    Name: 'Maker, surplice'
  },
  {
    Id: 26782,
    Code: '8146',
    Name: 'Maker, suspender'
  },
  {
    Id: 26783,
    Code: '8146',
    Name: 'Maker, tarpaulin'
  },
  {
    Id: 26784,
    Code: '8146',
    Name: 'Maker, tie'
  },
  {
    Id: 26785,
    Code: '8146',
    Name: 'Maker, trimming, clothing mfr'
  },
  {
    Id: 26786,
    Code: '8146',
    Name: 'Maker, trimmings, clothing mfr'
  },
  {
    Id: 26787,
    Code: '8146',
    Name: 'Maker-up, hosiery'
  },
  {
    Id: 26788,
    Code: '8146',
    Name: 'Maker-up, knitwear mfr'
  },
  {
    Id: 26789,
    Code: '8146',
    Name: 'Maker-up, umbrella, parasol mfr'
  },
  {
    Id: 26790,
    Code: '8146',
    Name: 'Manufacturer, glove'
  },
  {
    Id: 26791,
    Code: '8146',
    Name: 'Manufacturer, glove mfr'
  },
  {
    Id: 26792,
    Code: '8146',
    Name: 'Matron, sewing'
  },
  {
    Id: 26793,
    Code: '8146',
    Name: 'Mender, bag'
  },
  {
    Id: 26794,
    Code: '8146',
    Name: 'Mender, carpet'
  },
  {
    Id: 26795,
    Code: '8146',
    Name: 'Mender, cloth, textile mfr'
  },
  {
    Id: 26796,
    Code: '8146',
    Name: 'Mender, dress, hosiery'
  },
  {
    Id: 26797,
    Code: '8146',
    Name: 'Mender, embroidery'
  },
  {
    Id: 26798,
    Code: '8146',
    Name: 'Mender, hosiery'
  },
  {
    Id: 26799,
    Code: '8146',
    Name: 'Mender, invisible'
  },
  {
    Id: 26800,
    Code: '8146',
    Name: 'Mender, net'
  },
  {
    Id: 26801,
    Code: '8146',
    Name: 'Mender, piece'
  },
  {
    Id: 26802,
    Code: '8146',
    Name: 'Mender, sack'
  },
  {
    Id: 26803,
    Code: '8146',
    Name: 'Mender, embroidery mfr'
  },
  {
    Id: 26804,
    Code: '8146',
    Name: 'Mender, hotels, catering, public houses'
  },
  {
    Id: 26805,
    Code: '8146',
    Name: 'Mender, laundry, launderette, dry cleaning'
  },
  {
    Id: 26806,
    Code: '8146',
    Name: 'Mender, textile mfr'
  },
  {
    Id: 26807,
    Code: '8146',
    Name: 'Mistress, needle'
  },
  {
    Id: 26808,
    Code: '8146',
    Name: 'Mistress, room, work'
  },
  {
    Id: 26809,
    Code: '8146',
    Name: 'Operative, rosso'
  },
  {
    Id: 26810,
    Code: '8146',
    Name: 'Overlocker'
  },
  {
    Id: 26811,
    Code: '8146',
    Name: 'Padder, clothing mfr'
  },
  {
    Id: 26812,
    Code: '8146',
    Name: 'Pantographer, embroidery mfr'
  },
  {
    Id: 26813,
    Code: '8146',
    Name: 'Picker, carpet'
  },
  {
    Id: 26814,
    Code: '8146',
    Name: 'Picker, cloth'
  },
  {
    Id: 26815,
    Code: '8146',
    Name: 'Picker, yarn'
  },
  {
    Id: 26816,
    Code: '8146',
    Name: 'Picker, textile mfr'
  },
  {
    Id: 26817,
    Code: '8146',
    Name: 'Piecer-up, clothing mfr'
  },
  {
    Id: 26818,
    Code: '8146',
    Name: 'Pointer, machine'
  },
  {
    Id: 26819,
    Code: '8146',
    Name: 'Purler'
  },
  {
    Id: 26820,
    Code: '8146',
    Name: 'Quilter, textile mfr'
  },
  {
    Id: 26821,
    Code: '8146',
    Name: 'Raiser and finisher, embroidery mfr'
  },
  {
    Id: 26822,
    Code: '8146',
    Name: 'Rectifier, textile mfr'
  },
  {
    Id: 26823,
    Code: '8146',
    Name: 'Renovator, clothing'
  },
  {
    Id: 26824,
    Code: '8146',
    Name: 'Repairer, carpet'
  },
  {
    Id: 26825,
    Code: '8146',
    Name: 'Repairer, hosiery'
  },
  {
    Id: 26826,
    Code: '8146',
    Name: 'Repairer, net'
  },
  {
    Id: 26827,
    Code: '8146',
    Name: 'Repairer, sack'
  },
  {
    Id: 26828,
    Code: '8146',
    Name: 'Repairer, sheet'
  },
  {
    Id: 26829,
    Code: '8146',
    Name: 'Repairer, tarpaulin'
  },
  {
    Id: 26830,
    Code: '8146',
    Name: 'Repairer, tent'
  },
  {
    Id: 26831,
    Code: '8146',
    Name: 'Repairer, canvas goods'
  },
  {
    Id: 26832,
    Code: '8146',
    Name: 'Repairer, clothing'
  },
  {
    Id: 26833,
    Code: '8146',
    Name: 'Seamer, corset'
  },
  {
    Id: 26834,
    Code: '8146',
    Name: 'Seamer, cup, knitwear mfr'
  },
  {
    Id: 26835,
    Code: '8146',
    Name: 'Seamer, carpet, rug mfr'
  },
  {
    Id: 26836,
    Code: '8146',
    Name: 'Seamer, clothing mfr'
  },
  {
    Id: 26837,
    Code: '8146',
    Name: 'Seamer, hosiery, knitwear mfr'
  },
  {
    Id: 26838,
    Code: '8146',
    Name: 'Seamer-round'
  },
  {
    Id: 26839,
    Code: '8146',
    Name: 'Seamstress'
  },
  {
    Id: 26840,
    Code: '8146',
    Name: 'Sempstress'
  },
  {
    Id: 26841,
    Code: '8146',
    Name: 'Sewer, bag'
  },
  {
    Id: 26842,
    Code: '8146',
    Name: 'Sewer, button'
  },
  {
    Id: 26843,
    Code: '8146',
    Name: 'Sewer, carpet'
  },
  {
    Id: 26844,
    Code: '8146',
    Name: 'Sewer, cloth'
  },
  {
    Id: 26845,
    Code: '8146',
    Name: 'Sewer, felt'
  },
  {
    Id: 26846,
    Code: '8146',
    Name: 'Sewer, fur'
  },
  {
    Id: 26847,
    Code: '8146',
    Name: 'Sewer, glove'
  },
  {
    Id: 26848,
    Code: '8146',
    Name: 'Sewer, hat'
  },
  {
    Id: 26849,
    Code: '8146',
    Name: 'Sewer, piece'
  },
  {
    Id: 26850,
    Code: '8146',
    Name: 'Sewer, seam'
  },
  {
    Id: 26851,
    Code: '8146',
    Name: 'Sewer, spangle'
  },
  {
    Id: 26852,
    Code: '8146',
    Name: 'Sewer, tent'
  },
  {
    Id: 26853,
    Code: '8146',
    Name: 'Sewer, canvas goods mfr'
  },
  {
    Id: 26854,
    Code: '8146',
    Name: 'Sewer, carpet, rug mfr'
  },
  {
    Id: 26855,
    Code: '8146',
    Name: 'Sewer, cloth mending'
  },
  {
    Id: 26856,
    Code: '8146',
    Name: 'Sewer, clothing mfr'
  },
  {
    Id: 26857,
    Code: '8146',
    Name: 'Sewer, glove mfr'
  },
  {
    Id: 26858,
    Code: '8146',
    Name: 'Sewer, hat mfr'
  },
  {
    Id: 26859,
    Code: '8146',
    Name: 'Sewer, textile mfr'
  },
  {
    Id: 26860,
    Code: '8146',
    Name: 'Silker, textile mfr'
  },
  {
    Id: 26861,
    Code: '8146',
    Name: 'Sleever, clothing mfr'
  },
  {
    Id: 26862,
    Code: '8146',
    Name: 'Slipper, cushion'
  },
  {
    Id: 26863,
    Code: '8146',
    Name: 'Slipper, tie'
  },
  {
    Id: 26864,
    Code: '8146',
    Name: 'Slipper, furniture mfr'
  },
  {
    Id: 26865,
    Code: '8146',
    Name: 'Spiler, textile mfr'
  },
  {
    Id: 26866,
    Code: '8146',
    Name: 'Stitcher, ball, tennis'
  },
  {
    Id: 26867,
    Code: '8146',
    Name: 'Stitcher, hem'
  },
  {
    Id: 26868,
    Code: '8146',
    Name: 'Stitcher, lock'
  },
  {
    Id: 26869,
    Code: '8146',
    Name: 'Stitcher'
  },
  {
    Id: 26870,
    Code: '8146',
    Name: 'Strapper, corset mfr'
  },
  {
    Id: 26871,
    Code: '8146',
    Name: 'Tabber, corset mfr'
  },
  {
    Id: 26872,
    Code: '8146',
    Name: 'Tacker, hosiery, knitwear mfr'
  },
  {
    Id: 26873,
    Code: '8146',
    Name: 'Thumber, glove mfr'
  },
  {
    Id: 26874,
    Code: '8146',
    Name: 'Tier, knot, textile mfr'
  },
  {
    Id: 26875,
    Code: '8146',
    Name: 'Tipper, umbrella'
  },
  {
    Id: 26876,
    Code: '8146',
    Name: 'Topper, clothing mfr'
  },
  {
    Id: 26877,
    Code: '8146',
    Name: 'Trimmer, clothing mfr'
  },
  {
    Id: 26878,
    Code: '8146',
    Name: 'Welter, hosiery, knitwear mfr'
  },
  {
    Id: 26879,
    Code: '8146',
    Name: 'Whipper, blanket'
  },
  {
    Id: 26880,
    Code: '8146',
    Name: 'Woman, needle'
  },
  {
    Id: 26881,
    Code: '8146',
    Name: 'Worker, badge'
  },
  {
    Id: 26882,
    Code: '8146',
    Name: 'Worker, buttonhole'
  },
  {
    Id: 26883,
    Code: '8146',
    Name: 'Worker, fabric'
  },
  {
    Id: 26884,
    Code: '8146',
    Name: 'Worker, needle'
  },
  {
    Id: 26885,
    Code: '8146',
    Name: 'Worker, silk, greetings cards mfr'
  },
  {
    Id: 26886,
    Code: '8149',
    Name: 'Applicator, film'
  },
  {
    Id: 26887,
    Code: '8149',
    Name: 'Assembler, action'
  },
  {
    Id: 26888,
    Code: '8149',
    Name: 'Assembler, aircraft'
  },
  {
    Id: 26889,
    Code: '8149',
    Name: 'Assembler, ammeter'
  },
  {
    Id: 26890,
    Code: '8149',
    Name: 'Assembler, bench, engineering'
  },
  {
    Id: 26891,
    Code: '8149',
    Name: 'Assembler, bi-focal'
  },
  {
    Id: 26892,
    Code: '8149',
    Name: 'Assembler, binocular'
  },
  {
    Id: 26893,
    Code: '8149',
    Name: 'Assembler, block, carbon'
  },
  {
    Id: 26894,
    Code: '8149',
    Name: 'Assembler, box'
  },
  {
    Id: 26895,
    Code: '8149',
    Name: 'Assembler, brush, carbon'
  },
  {
    Id: 26896,
    Code: '8149',
    Name: 'Assembler, brush'
  },
  {
    Id: 26897,
    Code: '8149',
    Name: 'Assembler, cabinet'
  },
  {
    Id: 26898,
    Code: '8149',
    Name: 'Assembler, camera'
  },
  {
    Id: 26899,
    Code: '8149',
    Name: 'Assembler, card, printing'
  },
  {
    Id: 26900,
    Code: '8149',
    Name: 'Assembler, carton'
  },
  {
    Id: 26901,
    Code: '8149',
    Name: 'Assembler, case, electrical, electronic equipment mfr'
  },
  {
    Id: 26902,
    Code: '8149',
    Name: 'Assembler, clock'
  },
  {
    Id: 26903,
    Code: '8149',
    Name: 'Assembler, clothing'
  },
  {
    Id: 26904,
    Code: '8149',
    Name: 'Assembler, conveyor'
  },
  {
    Id: 26905,
    Code: '8149',
    Name: 'Assembler, cosmetics'
  },
  {
    Id: 26906,
    Code: '8149',
    Name: 'Assembler, cutlery'
  },
  {
    Id: 26907,
    Code: '8149',
    Name: 'Assembler, doll'
  },
  {
    Id: 26908,
    Code: '8149',
    Name: 'Assembler, fencing'
  },
  {
    Id: 26909,
    Code: '8149',
    Name: 'Assembler, firework'
  },
  {
    Id: 26910,
    Code: '8149',
    Name: 'Assembler, flask, vacuum'
  },
  {
    Id: 26911,
    Code: '8149',
    Name: 'Assembler, frame, spectacle'
  },
  {
    Id: 26912,
    Code: '8149',
    Name: 'Assembler, frame, engineering'
  },
  {
    Id: 26913,
    Code: '8149',
    Name: 'Assembler, furniture'
  },
  {
    Id: 26914,
    Code: '8149',
    Name: 'Assembler, instrument, optical'
  },
  {
    Id: 26915,
    Code: '8149',
    Name: 'Assembler, instrument'
  },
  {
    Id: 26916,
    Code: '8149',
    Name: 'Assembler, ligature, surgical'
  },
  {
    Id: 26917,
    Code: '8149',
    Name: 'Assembler, mattress'
  },
  {
    Id: 26918,
    Code: '8149',
    Name: 'Assembler, meter'
  },
  {
    Id: 26919,
    Code: '8149',
    Name: 'Assembler, optical'
  },
  {
    Id: 26920,
    Code: '8149',
    Name: 'Assembler, pad, stamp'
  },
  {
    Id: 26921,
    Code: '8149',
    Name: 'Assembler, pen'
  },
  {
    Id: 26922,
    Code: '8149',
    Name: 'Assembler, plastics'
  },
  {
    Id: 26923,
    Code: '8149',
    Name: 'Assembler, poppy'
  },
  {
    Id: 26924,
    Code: '8149',
    Name: 'Assembler, pottery'
  },
  {
    Id: 26925,
    Code: '8149',
    Name: 'Assembler, toy'
  },
  {
    Id: 26926,
    Code: '8149',
    Name: 'Assembler, transformer'
  },
  {
    Id: 26927,
    Code: '8149',
    Name: 'Assembler, trim'
  },
  {
    Id: 26928,
    Code: '8149',
    Name: 'Assembler, tube, plastics goods mfr'
  },
  {
    Id: 26929,
    Code: '8149',
    Name: 'Assembler, umbrella'
  },
  {
    Id: 26930,
    Code: '8149',
    Name: 'Assembler, watch'
  },
  {
    Id: 26931,
    Code: '8149',
    Name: 'Assembler, woodwork'
  },
  {
    Id: 26932,
    Code: '8149',
    Name: 'Assembler'
  },
  {
    Id: 26933,
    Code: '8149',
    Name: 'Assembler-fitter'
  },
  {
    Id: 26934,
    Code: '8149',
    Name: "Assistant, dipper's"
  },
  {
    Id: 26935,
    Code: '8149',
    Name: 'Assistant, general, manufacturing'
  },
  {
    Id: 26936,
    Code: '8149',
    Name: 'Assistant, house, green, ceramics mfr'
  },
  {
    Id: 26937,
    Code: '8149',
    Name: "Assistant, lithographer's"
  },
  {
    Id: 26938,
    Code: '8149',
    Name: "Assistant, painter's"
  },
  {
    Id: 26939,
    Code: '8149',
    Name: 'Assistant, production, manufacturing'
  },
  {
    Id: 26940,
    Code: '8149',
    Name: 'Assistant, production'
  },
  {
    Id: 26941,
    Code: '8149',
    Name: 'Assistant, shop, paint'
  },
  {
    Id: 26942,
    Code: '8149',
    Name: 'Attacher'
  },
  {
    Id: 26943,
    Code: '8149',
    Name: "Attendant, dipper's"
  },
  {
    Id: 26944,
    Code: '8149',
    Name: 'Bander, envelope mfr'
  },
  {
    Id: 26945,
    Code: '8149',
    Name: 'Binder, footwear mfr: rubber footwear'
  },
  {
    Id: 26946,
    Code: '8149',
    Name: 'Blacker'
  },
  {
    Id: 26947,
    Code: '8149',
    Name: 'Blacker-in, enamelled slate'
  },
  {
    Id: 26948,
    Code: '8149',
    Name: 'Boner, corsets'
  },
  {
    Id: 26949,
    Code: '8149',
    Name: 'Brusher, enamel'
  },
  {
    Id: 26950,
    Code: '8149',
    Name: 'Brusher, paint'
  },
  {
    Id: 26951,
    Code: '8149',
    Name: 'Brusher-off, metal trades'
  },
  {
    Id: 26952,
    Code: '8149',
    Name: 'Buckler'
  },
  {
    Id: 26953,
    Code: '8149',
    Name: 'Builder, heel'
  },
  {
    Id: 26954,
    Code: '8149',
    Name: 'Bundler, clothing mfr'
  },
  {
    Id: 26955,
    Code: '8149',
    Name: 'Caner, corset mfr'
  },
  {
    Id: 26956,
    Code: '8149',
    Name: 'Cementer, envelope'
  },
  {
    Id: 26957,
    Code: '8149',
    Name: 'Cementer, rubber'
  },
  {
    Id: 26958,
    Code: '8149',
    Name: 'Cementer, lens mfr'
  },
  {
    Id: 26959,
    Code: '8149',
    Name: 'Cementer, plastics goods mfr'
  },
  {
    Id: 26960,
    Code: '8149',
    Name: 'Cementer, rubber goods mfr'
  },
  {
    Id: 26961,
    Code: '8149',
    Name: 'Checker, chemical mfr'
  },
  {
    Id: 26962,
    Code: '8149',
    Name: 'Chipper, painting, decorating'
  },
  {
    Id: 26963,
    Code: '8149',
    Name: 'Clamper, pencil, crayon mfr'
  },
  {
    Id: 26964,
    Code: '8149',
    Name: 'Clipper, hosiery, knitwear mfr'
  },
  {
    Id: 26965,
    Code: '8149',
    Name: 'Closer, channel'
  },
  {
    Id: 26966,
    Code: '8149',
    Name: 'Colourer, artificial flowers'
  },
  {
    Id: 26967,
    Code: '8149',
    Name: 'Connector, rubber boots and shoes'
  },
  {
    Id: 26968,
    Code: '8149',
    Name: 'Converter, foam'
  },
  {
    Id: 26969,
    Code: '8149',
    Name: 'Corder, printing'
  },
  {
    Id: 26970,
    Code: '8149',
    Name: 'Corker, fishing rod mfr'
  },
  {
    Id: 26971,
    Code: '8149',
    Name: 'Coupler, hose pipe mfr'
  },
  {
    Id: 26972,
    Code: '8149',
    Name: 'Coverer, ball, tennis'
  },
  {
    Id: 26973,
    Code: '8149',
    Name: 'Coverer, box, wooden fixture boxes'
  },
  {
    Id: 26974,
    Code: '8149',
    Name: 'Coverer, buckle'
  },
  {
    Id: 26975,
    Code: '8149',
    Name: 'Coverer, button'
  },
  {
    Id: 26976,
    Code: '8149',
    Name: 'Coverer, cabinet, furniture mfr'
  },
  {
    Id: 26977,
    Code: '8149',
    Name: 'Coverer, case'
  },
  {
    Id: 26978,
    Code: '8149',
    Name: 'Coverer, fireworks'
  },
  {
    Id: 26979,
    Code: '8149',
    Name: 'Coverer, hat'
  },
  {
    Id: 26980,
    Code: '8149',
    Name: 'Coverer, heel'
  },
  {
    Id: 26981,
    Code: '8149',
    Name: 'Coverer, helmet'
  },
  {
    Id: 26982,
    Code: '8149',
    Name: 'Coverer, cardboard box mfr'
  },
  {
    Id: 26983,
    Code: '8149',
    Name: 'Coverer, piano, organ mfr'
  },
  {
    Id: 26984,
    Code: '8149',
    Name: 'Coverer and liner, case'
  },
  {
    Id: 26985,
    Code: '8149',
    Name: 'Cutter, litho, ceramics mfr'
  },
  {
    Id: 26986,
    Code: '8149',
    Name: "Cutter, transferrer's"
  },
  {
    Id: 26987,
    Code: '8149',
    Name: 'Decorator, art'
  },
  {
    Id: 26988,
    Code: '8149',
    Name: 'Decorator, card, greeting cards'
  },
  {
    Id: 26989,
    Code: '8149',
    Name: 'Dipper, cellulose'
  },
  {
    Id: 26990,
    Code: '8149',
    Name: 'Dipper, enamel'
  },
  {
    Id: 26991,
    Code: '8149',
    Name: 'Dipper, paint'
  },
  {
    Id: 26992,
    Code: '8149',
    Name: 'Dipper, metal trades'
  },
  {
    Id: 26993,
    Code: '8149',
    Name: 'Divider, clothing mfr'
  },
  {
    Id: 26994,
    Code: '8149',
    Name: 'Duster, coal mine'
  },
  {
    Id: 26995,
    Code: '8149',
    Name: 'Dyer, button mfr'
  },
  {
    Id: 26996,
    Code: '8149',
    Name: 'Enameller'
  },
  {
    Id: 26997,
    Code: '8149',
    Name: 'Facer, coach painting'
  },
  {
    Id: 26998,
    Code: '8149',
    Name: 'Filler, wood'
  },
  {
    Id: 26999,
    Code: '8149',
    Name: 'Filler, pencil, crayon mfr'
  },
  {
    Id: 27000,
    Code: '8149',
    Name: "Filler-in, polisher's"
  },
  {
    Id: 27001,
    Code: '8149',
    Name: 'Filler-in, pencil, crayon mfr'
  },
  {
    Id: 27002,
    Code: '8149',
    Name: 'Finisher, blade'
  },
  {
    Id: 27003,
    Code: '8149',
    Name: 'Finisher, brush'
  },
  {
    Id: 27004,
    Code: '8149',
    Name: 'Finisher, card, printing'
  },
  {
    Id: 27005,
    Code: '8149',
    Name: 'Finisher, case, jewel cases'
  },
  {
    Id: 27006,
    Code: '8149',
    Name: 'Finisher, faller'
  },
  {
    Id: 27007,
    Code: '8149',
    Name: 'Finisher, fork'
  },
  {
    Id: 27008,
    Code: '8149',
    Name: 'Finisher, frame, cycle mfr'
  },
  {
    Id: 27009,
    Code: '8149',
    Name: 'Finisher, furniture'
  },
  {
    Id: 27010,
    Code: '8149',
    Name: 'Finisher, hook, spring'
  },
  {
    Id: 27011,
    Code: '8149',
    Name: 'Finisher, key'
  },
  {
    Id: 27012,
    Code: '8149',
    Name: 'Finisher, needle, needle mfr'
  },
  {
    Id: 27013,
    Code: '8149',
    Name: 'Finisher, shop, machine'
  },
  {
    Id: 27014,
    Code: '8149',
    Name: 'Finisher, spade'
  },
  {
    Id: 27015,
    Code: '8149',
    Name: 'Finisher, tool, edge'
  },
  {
    Id: 27016,
    Code: '8149',
    Name: 'Finisher, wrench'
  },
  {
    Id: 27017,
    Code: '8149',
    Name: 'Finisher, broom, brush mfr'
  },
  {
    Id: 27018,
    Code: '8149',
    Name: 'Finisher, fireworks mfr'
  },
  {
    Id: 27019,
    Code: '8149',
    Name: 'Finisher, furniture mfr'
  },
  {
    Id: 27020,
    Code: '8149',
    Name: 'Finisher, greetings cards mfr'
  },
  {
    Id: 27021,
    Code: '8149',
    Name: 'Finisher, paper goods mfr'
  },
  {
    Id: 27022,
    Code: '8149',
    Name: 'Fitter, bag, air'
  },
  {
    Id: 27023,
    Code: '8149',
    Name: 'Fitter, bag, curing'
  },
  {
    Id: 27024,
    Code: '8149',
    Name: 'Fitter, basket, work'
  },
  {
    Id: 27025,
    Code: '8149',
    Name: 'Fitter, box, work'
  },
  {
    Id: 27026,
    Code: '8149',
    Name: 'Fitter, frame, bag'
  },
  {
    Id: 27027,
    Code: '8149',
    Name: 'Fitter, glass, watch mfr'
  },
  {
    Id: 27028,
    Code: '8149',
    Name: 'Fitter, mouthpiece, pipes'
  },
  {
    Id: 27029,
    Code: '8149',
    Name: 'Fitter, paste'
  },
  {
    Id: 27030,
    Code: '8149',
    Name: 'Fitter, pen, fountain'
  },
  {
    Id: 27031,
    Code: '8149',
    Name: 'Fitter, pipe, briar'
  },
  {
    Id: 27032,
    Code: '8149',
    Name: 'Fitter, umbrella'
  },
  {
    Id: 27033,
    Code: '8149',
    Name: 'Fitter, valve, tyre mfr'
  },
  {
    Id: 27034,
    Code: '8149',
    Name: 'Fitter, cardboard container mfr'
  },
  {
    Id: 27035,
    Code: '8149',
    Name: 'Fitter, tobacco pipe mfr'
  },
  {
    Id: 27036,
    Code: '8149',
    Name: 'Fitter-up, frame, picture'
  },
  {
    Id: 27037,
    Code: '8149',
    Name: 'Fixer, transfer, japanning'
  },
  {
    Id: 27038,
    Code: '8149',
    Name: 'Flatter, cellulose'
  },
  {
    Id: 27039,
    Code: '8149',
    Name: 'Flatter, paint'
  },
  {
    Id: 27040,
    Code: '8149',
    Name: 'Flatter, vehicle mfr'
  },
  {
    Id: 27041,
    Code: '8149',
    Name: 'Flatter and polisher'
  },
  {
    Id: 27042,
    Code: '8149',
    Name: 'Folder, box, cardboard box mfr'
  },
  {
    Id: 27043,
    Code: '8149',
    Name: 'Folder, pattern, paper'
  },
  {
    Id: 27044,
    Code: '8149',
    Name: 'Folder-in, glove mfr'
  },
  {
    Id: 27045,
    Code: '8149',
    Name: 'Foreman, shop, enamelling'
  },
  {
    Id: 27046,
    Code: '8149',
    Name: 'Foreman, shop, paint'
  },
  {
    Id: 27047,
    Code: '8149',
    Name: 'Foreman, enamelling'
  },
  {
    Id: 27048,
    Code: '8149',
    Name: 'Framer, bag'
  },
  {
    Id: 27049,
    Code: '8149',
    Name: 'Fuser, textile materials, products mfr'
  },
  {
    Id: 27050,
    Code: '8149',
    Name: 'Garterer'
  },
  {
    Id: 27051,
    Code: '8149',
    Name: 'Gilder, composition'
  },
  {
    Id: 27052,
    Code: '8149',
    Name: 'Gilder, furniture mfr'
  },
  {
    Id: 27053,
    Code: '8149',
    Name: 'Gluer, furniture mfr'
  },
  {
    Id: 27054,
    Code: '8149',
    Name: 'Gluer, paper goods mfr'
  },
  {
    Id: 27055,
    Code: '8149',
    Name: 'Gluer, sports goods mfr'
  },
  {
    Id: 27056,
    Code: '8149',
    Name: 'Gluer-up, woodwork'
  },
  {
    Id: 27057,
    Code: '8149',
    Name: 'Grommeter'
  },
  {
    Id: 27058,
    Code: '8149',
    Name: 'Grounder, fur'
  },
  {
    Id: 27059,
    Code: '8149',
    Name: 'Grounder, wallpaper printing'
  },
  {
    Id: 27060,
    Code: '8149',
    Name: 'Gummer, paper goods mfr'
  },
  {
    Id: 27061,
    Code: '8149',
    Name: 'Hand, bench, cardboard box mfr'
  },
  {
    Id: 27062,
    Code: '8149',
    Name: 'Hand, bench, rubber footwear mfr'
  },
  {
    Id: 27063,
    Code: '8149',
    Name: 'Hand, brush, coach painting'
  },
  {
    Id: 27064,
    Code: '8149',
    Name: 'Hand, cracker, paper goods mfr'
  },
  {
    Id: 27065,
    Code: '8149',
    Name: 'Hand, paint'
  },
  {
    Id: 27066,
    Code: '8149',
    Name: 'Hand, painter'
  },
  {
    Id: 27067,
    Code: '8149',
    Name: 'Hand, tassel'
  },
  {
    Id: 27068,
    Code: '8149',
    Name: 'Hand, wiring'
  },
  {
    Id: 27069,
    Code: '8149',
    Name: 'Handler, spade and shovel'
  },
  {
    Id: 27070,
    Code: '8149',
    Name: 'Handler, tool, edge tool mfr'
  },
  {
    Id: 27071,
    Code: '8149',
    Name: 'Handler, broom, brush mfr'
  },
  {
    Id: 27072,
    Code: '8149',
    Name: 'Handler, ceramics mfr'
  },
  {
    Id: 27073,
    Code: '8149',
    Name: 'Handler, edge tool mfr'
  },
  {
    Id: 27074,
    Code: '8149',
    Name: 'Helver, tools'
  },
  {
    Id: 27075,
    Code: '8149',
    Name: 'Impregnator, cable mfr'
  },
  {
    Id: 27076,
    Code: '8149',
    Name: 'Inker, edge'
  },
  {
    Id: 27077,
    Code: '8149',
    Name: 'Inserter, clothing mfr'
  },
  {
    Id: 27078,
    Code: '8149',
    Name: 'Insulator, bitumen'
  },
  {
    Id: 27079,
    Code: '8149',
    Name: 'Insulator, coil'
  },
  {
    Id: 27080,
    Code: '8149',
    Name: 'Insulator, electrical appliances mfr'
  },
  {
    Id: 27081,
    Code: '8149',
    Name: 'Japanner'
  },
  {
    Id: 27082,
    Code: '8149',
    Name: 'Joiner, rubber footwear mfr'
  },
  {
    Id: 27083,
    Code: '8149',
    Name: 'Jointer, soft toy mfr'
  },
  {
    Id: 27084,
    Code: '8149',
    Name: 'Lacer, corset'
  },
  {
    Id: 27085,
    Code: '8149',
    Name: 'Lacer, shade, lamp'
  },
  {
    Id: 27086,
    Code: '8149',
    Name: 'Lacer, corset mfr'
  },
  {
    Id: 27087,
    Code: '8149',
    Name: 'Lacquerer'
  },
  {
    Id: 27088,
    Code: '8149',
    Name: 'Leader, team, assembly'
  },
  {
    Id: 27089,
    Code: '8149',
    Name: 'Lidder, cardboard box mfr'
  },
  {
    Id: 27090,
    Code: '8149',
    Name: 'Liner, basket'
  },
  {
    Id: 27091,
    Code: '8149',
    Name: 'Liner, box, work'
  },
  {
    Id: 27092,
    Code: '8149',
    Name: 'Liner, brake'
  },
  {
    Id: 27093,
    Code: '8149',
    Name: 'Liner, cabinet, upholstery mfr'
  },
  {
    Id: 27094,
    Code: '8149',
    Name: 'Liner, case'
  },
  {
    Id: 27095,
    Code: '8149',
    Name: 'Liner, cycle'
  },
  {
    Id: 27096,
    Code: '8149',
    Name: 'Liner, glove'
  },
  {
    Id: 27097,
    Code: '8149',
    Name: 'Liner, gold, cycle mfr'
  },
  {
    Id: 27098,
    Code: '8149',
    Name: 'Liner, machine'
  },
  {
    Id: 27099,
    Code: '8149',
    Name: 'Liner, picture'
  },
  {
    Id: 27100,
    Code: '8149',
    Name: 'Liner, pouch, leather'
  },
  {
    Id: 27101,
    Code: '8149',
    Name: 'Liner, table'
  },
  {
    Id: 27102,
    Code: '8149',
    Name: 'Liner, cardboard box mfr'
  },
  {
    Id: 27103,
    Code: '8149',
    Name: 'Liner, metal trades'
  },
  {
    Id: 27104,
    Code: '8149',
    Name: "Looker-to-ware, thrower's"
  },
  {
    Id: 27105,
    Code: '8149',
    Name: 'Machinist, backer, footwear'
  },
  {
    Id: 27106,
    Code: '8149',
    Name: 'Machinist, bag, plastics goods mfr'
  },
  {
    Id: 27107,
    Code: '8149',
    Name: 'Machinist, dipping'
  },
  {
    Id: 27108,
    Code: '8149',
    Name: 'Machinist, gumming, paper goods mfr'
  },
  {
    Id: 27109,
    Code: '8149',
    Name: 'Machinist, nailing'
  },
  {
    Id: 27110,
    Code: '8149',
    Name: 'Machinist, pressing, transfer'
  },
  {
    Id: 27111,
    Code: '8149',
    Name: 'Machinist, riveting, plastics goods mfr'
  },
  {
    Id: 27112,
    Code: '8149',
    Name: 'Machinist, slugger'
  },
  {
    Id: 27113,
    Code: '8149',
    Name: 'Machinist, varnishing, metal trades'
  },
  {
    Id: 27114,
    Code: '8149',
    Name: 'Machinist, welding, plastics'
  },
  {
    Id: 27115,
    Code: '8149',
    Name: 'Maker, bag, polythene'
  },
  {
    Id: 27116,
    Code: '8149',
    Name: 'Maker, basket, chip'
  },
  {
    Id: 27117,
    Code: '8149',
    Name: 'Maker, belt, abrasive paper, cloth mfr'
  },
  {
    Id: 27118,
    Code: '8149',
    Name: 'Maker, boot, rubber'
  },
  {
    Id: 27119,
    Code: '8149',
    Name: 'Maker, box, cigar'
  },
  {
    Id: 27120,
    Code: '8149',
    Name: 'Maker, card, shade'
  },
  {
    Id: 27121,
    Code: '8149',
    Name: 'Maker, case, pattern'
  },
  {
    Id: 27122,
    Code: '8149',
    Name: 'Maker, case, fireworks mfr'
  },
  {
    Id: 27123,
    Code: '8149',
    Name: 'Maker, cracker, paper goods mfr'
  },
  {
    Id: 27124,
    Code: '8149',
    Name: 'Maker, doll'
  },
  {
    Id: 27125,
    Code: '8149',
    Name: 'Maker, dolly, toy mfr'
  },
  {
    Id: 27126,
    Code: '8149',
    Name: 'Maker, flag, paper goods mfr'
  },
  {
    Id: 27127,
    Code: '8149',
    Name: 'Maker, flower, artificial, plastics'
  },
  {
    Id: 27128,
    Code: '8149',
    Name: 'Maker, frame, cork'
  },
  {
    Id: 27129,
    Code: '8149',
    Name: 'Maker, hat, paper'
  },
  {
    Id: 27130,
    Code: '8149',
    Name: 'Maker, map, dissected'
  },
  {
    Id: 27131,
    Code: '8149',
    Name: 'Maker, meter, gas'
  },
  {
    Id: 27132,
    Code: '8149',
    Name: 'Maker, postcard'
  },
  {
    Id: 27133,
    Code: '8149',
    Name: 'Maker, slipper, rubber'
  },
  {
    Id: 27134,
    Code: '8149',
    Name: 'Maker, stamp, endorsing, metal'
  },
  {
    Id: 27135,
    Code: '8149',
    Name: 'Maker, stamp, rubber'
  },
  {
    Id: 27136,
    Code: '8149',
    Name: 'Maker, trellis'
  },
  {
    Id: 27137,
    Code: '8149',
    Name: 'Man, enamel, stove mfr'
  },
  {
    Id: 27138,
    Code: '8149',
    Name: 'Manufacturer, firelighter'
  },
  {
    Id: 27139,
    Code: '8149',
    Name: 'Marker, bale'
  },
  {
    Id: 27140,
    Code: '8149',
    Name: 'Marker, box'
  },
  {
    Id: 27141,
    Code: '8149',
    Name: 'Marker, dial'
  },
  {
    Id: 27142,
    Code: '8149',
    Name: 'Marker, size'
  },
  {
    Id: 27143,
    Code: '8149',
    Name: 'Marker, timber'
  },
  {
    Id: 27144,
    Code: '8149',
    Name: 'Marker, trade'
  },
  {
    Id: 27145,
    Code: '8149',
    Name: 'Marker, valve'
  },
  {
    Id: 27146,
    Code: '8149',
    Name: 'Marker, brewery'
  },
  {
    Id: 27147,
    Code: '8149',
    Name: 'Marker, laundry, launderette, dry cleaning'
  },
  {
    Id: 27148,
    Code: '8149',
    Name: 'Marker, rolling mill'
  },
  {
    Id: 27149,
    Code: '8149',
    Name: 'Marker, steel sheet, strip mfr'
  },
  {
    Id: 27150,
    Code: '8149',
    Name: 'Marker, tinplate mfr'
  },
  {
    Id: 27151,
    Code: '8149',
    Name: 'Masker, paint'
  },
  {
    Id: 27152,
    Code: '8149',
    Name: 'Masker, metal trades'
  },
  {
    Id: 27153,
    Code: '8149',
    Name: "Mate, polisher's, french"
  },
  {
    Id: 27154,
    Code: '8149',
    Name: 'Mounter, card'
  },
  {
    Id: 27155,
    Code: '8149',
    Name: 'Mounter, drawing'
  },
  {
    Id: 27156,
    Code: '8149',
    Name: 'Mounter, feather'
  },
  {
    Id: 27157,
    Code: '8149',
    Name: 'Mounter, handle, umbrella'
  },
  {
    Id: 27158,
    Code: '8149',
    Name: 'Mounter, lens'
  },
  {
    Id: 27159,
    Code: '8149',
    Name: 'Mounter, map'
  },
  {
    Id: 27160,
    Code: '8149',
    Name: "Mounter, print, lithographer's"
  },
  {
    Id: 27161,
    Code: '8149',
    Name: 'Mounter, stick, walking'
  },
  {
    Id: 27162,
    Code: '8149',
    Name: 'Nailer, wooden box mfr'
  },
  {
    Id: 27163,
    Code: '8149',
    Name: 'Operative, manufacturing, furniture'
  },
  {
    Id: 27164,
    Code: '8149',
    Name: 'Operator, line, paint'
  },
  {
    Id: 27165,
    Code: '8149',
    Name: 'Operator, plant, paint'
  },
  {
    Id: 27166,
    Code: '8149',
    Name: 'Operator, plant, painting, electrophoretic'
  },
  {
    Id: 27167,
    Code: '8149',
    Name: 'Ornamenter, japanned ware mfr'
  },
  {
    Id: 27168,
    Code: '8149',
    Name: 'Padder, colour'
  },
  {
    Id: 27169,
    Code: '8149',
    Name: 'Painter, bottom'
  },
  {
    Id: 27170,
    Code: '8149',
    Name: 'Painter, toy'
  },
  {
    Id: 27171,
    Code: '8149',
    Name: 'Painter, artificial flower mfr'
  },
  {
    Id: 27172,
    Code: '8149',
    Name: 'Pairer, corset mfr'
  },
  {
    Id: 27173,
    Code: '8149',
    Name: 'Paperer, chair'
  },
  {
    Id: 27174,
    Code: '8149',
    Name: 'Paperer, sand'
  },
  {
    Id: 27175,
    Code: '8149',
    Name: 'Paperer-on, whips'
  },
  {
    Id: 27176,
    Code: '8149',
    Name: 'Paster, paper goods mfr'
  },
  {
    Id: 27177,
    Code: '8149',
    Name: 'Piecer, sole'
  },
  {
    Id: 27178,
    Code: '8149',
    Name: 'Plugger, rod, fishing'
  },
  {
    Id: 27179,
    Code: '8149',
    Name: 'Polisher, cellulose'
  },
  {
    Id: 27180,
    Code: '8149',
    Name: 'Polisher, stick'
  },
  {
    Id: 27181,
    Code: '8149',
    Name: 'Preparer, litho, ceramics mfr'
  },
  {
    Id: 27182,
    Code: '8149',
    Name: 'Presser, fusing'
  },
  {
    Id: 27183,
    Code: '8149',
    Name: 'Putter-together, scissors'
  },
  {
    Id: 27184,
    Code: '8149',
    Name: 'Putter-together, cutlery mfr'
  },
  {
    Id: 27185,
    Code: '8149',
    Name: 'Ranger, glass mfr'
  },
  {
    Id: 27186,
    Code: '8149',
    Name: 'Receiver, tobacco mfr'
  },
  {
    Id: 27187,
    Code: '8149',
    Name: 'Recorder, laboratory'
  },
  {
    Id: 27188,
    Code: '8149',
    Name: 'Rectifier, paint, vehicle mfr'
  },
  {
    Id: 27189,
    Code: '8149',
    Name: 'Reliner, brake'
  },
  {
    Id: 27190,
    Code: '8149',
    Name: 'Riveter, glove fastenings'
  },
  {
    Id: 27191,
    Code: '8149',
    Name: 'Riveter, plastics goods'
  },
  {
    Id: 27192,
    Code: '8149',
    Name: 'Riveter, soft toy mfr'
  },
  {
    Id: 27193,
    Code: '8149',
    Name: 'Rubber, vehicle mfr'
  },
  {
    Id: 27194,
    Code: '8149',
    Name: 'Rubber and flatter, coach building'
  },
  {
    Id: 27195,
    Code: '8149',
    Name: 'Rubber and polisher, vehicle mfr'
  },
  {
    Id: 27196,
    Code: '8149',
    Name: 'Rubber-down'
  },
  {
    Id: 27197,
    Code: '8149',
    Name: 'Sackhand, sack mfr'
  },
  {
    Id: 27198,
    Code: '8149',
    Name: 'Sander, hand, furniture mfr'
  },
  {
    Id: 27199,
    Code: '8149',
    Name: 'Sander, wet, motor body mfr'
  },
  {
    Id: 27200,
    Code: '8149',
    Name: 'Scaler, vehicle mfr'
  },
  {
    Id: 27201,
    Code: '8149',
    Name: 'Sealer-in'
  },
  {
    Id: 27202,
    Code: '8149',
    Name: 'Setter, steel, corset mfr'
  },
  {
    Id: 27203,
    Code: '8149',
    Name: 'Shader, artificial flower mfr'
  },
  {
    Id: 27204,
    Code: '8149',
    Name: 'Solutioner, rubber goods mfr'
  },
  {
    Id: 27205,
    Code: '8149',
    Name: 'Solutionist, rubber goods mfr'
  },
  {
    Id: 27206,
    Code: '8149',
    Name: 'Spotter, artificial flower mfr'
  },
  {
    Id: 27207,
    Code: '8149',
    Name: 'Springer-in, spectacles'
  },
  {
    Id: 27208,
    Code: '8149',
    Name: 'Stapler, box, cardboard box mfr'
  },
  {
    Id: 27209,
    Code: '8149',
    Name: 'Stemmer and waxer, battery carbon mfr'
  },
  {
    Id: 27210,
    Code: '8149',
    Name: 'Stenciller, box'
  },
  {
    Id: 27211,
    Code: '8149',
    Name: 'Stenciller'
  },
  {
    Id: 27212,
    Code: '8149',
    Name: 'Sticker, feather'
  },
  {
    Id: 27213,
    Code: '8149',
    Name: 'Sticker, leaf, artificial flower mfr'
  },
  {
    Id: 27214,
    Code: '8149',
    Name: 'Sticker, pattern, paper pattern mfr'
  },
  {
    Id: 27215,
    Code: '8149',
    Name: 'Sticker-up, ceramics mfr'
  },
  {
    Id: 27216,
    Code: '8149',
    Name: 'Stitcher, box'
  },
  {
    Id: 27217,
    Code: '8149',
    Name: 'Stitcher, carton'
  },
  {
    Id: 27218,
    Code: '8149',
    Name: 'Stitcher, wire, paper goods mfr'
  },
  {
    Id: 27219,
    Code: '8149',
    Name: 'Stitcher, paper goods mfr'
  },
  {
    Id: 27220,
    Code: '8149',
    Name: 'Stringer, bag, paper'
  },
  {
    Id: 27221,
    Code: '8149',
    Name: 'Stringer, bead'
  },
  {
    Id: 27222,
    Code: '8149',
    Name: 'Stringer, pearl'
  },
  {
    Id: 27223,
    Code: '8149',
    Name: 'Stringer, printing'
  },
  {
    Id: 27224,
    Code: '8149',
    Name: 'Stripper, cable'
  },
  {
    Id: 27225,
    Code: '8149',
    Name: 'Stripper, paint, vehicle, vehicle mfr'
  },
  {
    Id: 27226,
    Code: '8149',
    Name: 'Stripper, polish'
  },
  {
    Id: 27227,
    Code: '8149',
    Name: 'Studder, clothing mfr'
  },
  {
    Id: 27228,
    Code: '8149',
    Name: 'Swiller, enamelling'
  },
  {
    Id: 27229,
    Code: '8149',
    Name: 'Tackler, bag'
  },
  {
    Id: 27230,
    Code: '8149',
    Name: 'Tagger, label'
  },
  {
    Id: 27231,
    Code: '8149',
    Name: 'Tagger, lace mfr'
  },
  {
    Id: 27232,
    Code: '8149',
    Name: "Taker-off, dipper's"
  },
  {
    Id: 27233,
    Code: '8149',
    Name: 'Taker-off, ceramics mfr'
  },
  {
    Id: 27234,
    Code: '8149',
    Name: 'Taker-out'
  },
  {
    Id: 27235,
    Code: '8149',
    Name: 'Taper, wiring'
  },
  {
    Id: 27236,
    Code: '8149',
    Name: 'Taper, cardboard box mfr'
  },
  {
    Id: 27237,
    Code: '8149',
    Name: 'Threader, pearl'
  },
  {
    Id: 27238,
    Code: '8149',
    Name: 'Tier, hook'
  },
  {
    Id: 27239,
    Code: '8149',
    Name: 'Tier, ring'
  },
  {
    Id: 27240,
    Code: '8149',
    Name: 'Tier, tackle, fishing tackle'
  },
  {
    Id: 27241,
    Code: '8149',
    Name: 'Tier-on, fish hook mfr'
  },
  {
    Id: 27242,
    Code: '8149',
    Name: 'Tier-up, cloth hat mfr'
  },
  {
    Id: 27243,
    Code: '8149',
    Name: 'Tipper, enamelling'
  },
  {
    Id: 27244,
    Code: '8149',
    Name: 'Topper, fur dyeing'
  },
  {
    Id: 27245,
    Code: '8149',
    Name: 'Toucher-up, vehicle mfr'
  },
  {
    Id: 27246,
    Code: '8149',
    Name: 'Transferer, japanning, enamelling'
  },
  {
    Id: 27247,
    Code: '8149',
    Name: 'Transferer'
  },
  {
    Id: 27248,
    Code: '8149',
    Name: 'Turner, sheet, rolling mill'
  },
  {
    Id: 27249,
    Code: '8149',
    Name: 'Turner-in, steel mfr'
  },
  {
    Id: 27250,
    Code: '8149',
    Name: 'Turner-over, rolling mill'
  },
  {
    Id: 27251,
    Code: '8149',
    Name: 'Turner-up, rolling mill'
  },
  {
    Id: 27252,
    Code: '8149',
    Name: 'Typer, textile mfr'
  },
  {
    Id: 27253,
    Code: '8149',
    Name: 'Varnisher'
  },
  {
    Id: 27254,
    Code: '8149',
    Name: 'Veiner and marker, artificial flower mfr'
  },
  {
    Id: 27255,
    Code: '8149',
    Name: 'Welder, plastic'
  },
  {
    Id: 27256,
    Code: '8149',
    Name: 'Welder, plastics goods mfr'
  },
  {
    Id: 27257,
    Code: '8149',
    Name: 'Wheeler, vitreous enamelling'
  },
  {
    Id: 27258,
    Code: '8149',
    Name: 'Wicker'
  },
  {
    Id: 27259,
    Code: '8149',
    Name: 'Wirer and paperer'
  },
  {
    Id: 27260,
    Code: '8149',
    Name: 'Worker, enamel'
  },
  {
    Id: 27261,
    Code: '8149',
    Name: 'Worker, stencil'
  },
  {
    Id: 27262,
    Code: '8149',
    Name: 'Worker, tackle, fishing'
  },
  {
    Id: 27263,
    Code: '8149',
    Name: 'Writer, dial'
  },
  {
    Id: 27264,
    Code: '8151',
    Name: 'Erector, scaffolding'
  },
  {
    Id: 27265,
    Code: '8151',
    Name: 'Erector, shuttering, metal'
  },
  {
    Id: 27266,
    Code: '8151',
    Name: 'Erector, stage, ship repairing'
  },
  {
    Id: 27267,
    Code: '8151',
    Name: 'Fitter, rope and belt'
  },
  {
    Id: 27268,
    Code: '8151',
    Name: 'Fitter, ropery, wire'
  },
  {
    Id: 27269,
    Code: '8151',
    Name: 'Fixer, scaffolding'
  },
  {
    Id: 27270,
    Code: '8151',
    Name: 'Foreman, scaffolder'
  },
  {
    Id: 27271,
    Code: '8151',
    Name: 'Hanger, bell, church bells'
  },
  {
    Id: 27272,
    Code: '8151',
    Name: 'Lifter, heavy'
  },
  {
    Id: 27273,
    Code: '8151',
    Name: 'Maker, gromet, wire rope, cable mfr'
  },
  {
    Id: 27274,
    Code: '8151',
    Name: 'Maker, grommet, wire rope, cable mfr'
  },
  {
    Id: 27275,
    Code: '8151',
    Name: 'Rigger, factory'
  },
  {
    Id: 27276,
    Code: '8151',
    Name: 'Rigger, offshore'
  },
  {
    Id: 27277,
    Code: '8151',
    Name: 'Rigger, salvage'
  },
  {
    Id: 27278,
    Code: '8151',
    Name: 'Rigger, scaffolding'
  },
  {
    Id: 27279,
    Code: '8151',
    Name: "Rigger, ship's"
  },
  {
    Id: 27280,
    Code: '8151',
    Name: 'Rigger, stage, shipbuilding'
  },
  {
    Id: 27281,
    Code: '8151',
    Name: 'Rigger, film, television production'
  },
  {
    Id: 27282,
    Code: '8151',
    Name: 'Rigger'
  },
  {
    Id: 27283,
    Code: '8151',
    Name: 'Ropeman, mining'
  },
  {
    Id: 27284,
    Code: '8151',
    Name: 'Scaffolder, advanced'
  },
  {
    Id: 27285,
    Code: '8151',
    Name: 'Scaffolder'
  },
  {
    Id: 27286,
    Code: '8151',
    Name: 'Scaffolder-foreman'
  },
  {
    Id: 27287,
    Code: '8151',
    Name: 'Smith, rope, coal mine'
  },
  {
    Id: 27288,
    Code: '8151',
    Name: 'Splicer, rope, coal mine'
  },
  {
    Id: 27289,
    Code: '8151',
    Name: 'Splicer, rope, steel mfr'
  },
  {
    Id: 27290,
    Code: '8151',
    Name: 'Splicer, wire'
  },
  {
    Id: 27291,
    Code: '8151',
    Name: 'Splicer, rope, wire'
  },
  {
    Id: 27292,
    Code: '8151',
    Name: 'Splicer, coal mine'
  },
  {
    Id: 27293,
    Code: '8151',
    Name: 'Stager, shipbuilding'
  },
  {
    Id: 27294,
    Code: '8151',
    Name: 'Tackleman, steelworks'
  },
  {
    Id: 27295,
    Code: '8151',
    Name: 'Tester, rope, coal mine'
  },
  {
    Id: 27296,
    Code: '8152',
    Name: 'Asphalter'
  },
  {
    Id: 27297,
    Code: '8152',
    Name: 'Assembler, concrete'
  },
  {
    Id: 27298,
    Code: '8152',
    Name: 'Chipper, road surfacing'
  },
  {
    Id: 27299,
    Code: '8152',
    Name: 'Concreter'
  },
  {
    Id: 27300,
    Code: '8152',
    Name: 'Constructor, road'
  },
  {
    Id: 27301,
    Code: '8152',
    Name: 'Contractor, lining, road'
  },
  {
    Id: 27302,
    Code: '8152',
    Name: 'Contractor, road'
  },
  {
    Id: 27303,
    Code: '8152',
    Name: 'Erector, concrete'
  },
  {
    Id: 27304,
    Code: '8152',
    Name: 'Finisher, concrete, building construction'
  },
  {
    Id: 27305,
    Code: '8152',
    Name: 'Flagger'
  },
  {
    Id: 27306,
    Code: '8152',
    Name: 'Hand, maintenance, highways'
  },
  {
    Id: 27307,
    Code: '8152',
    Name: 'Hand, maintenance, local government: highways dept'
  },
  {
    Id: 27308,
    Code: '8152',
    Name: 'Inspector, road'
  },
  {
    Id: 27309,
    Code: '8152',
    Name: 'Inspector, roads'
  },
  {
    Id: 27310,
    Code: '8152',
    Name: 'Layer, asphalt'
  },
  {
    Id: 27311,
    Code: '8152',
    Name: 'Layer, concrete'
  },
  {
    Id: 27312,
    Code: '8152',
    Name: 'Layer, floor, concrete'
  },
  {
    Id: 27313,
    Code: '8152',
    Name: 'Layer, kerb'
  },
  {
    Id: 27314,
    Code: '8152',
    Name: 'Layer, road'
  },
  {
    Id: 27315,
    Code: '8152',
    Name: 'Layer, slab'
  },
  {
    Id: 27316,
    Code: '8152',
    Name: 'Layer, stone'
  },
  {
    Id: 27317,
    Code: '8152',
    Name: 'Layer, surface, gas supplier'
  },
  {
    Id: 27318,
    Code: '8152',
    Name: 'Layer, tar'
  },
  {
    Id: 27319,
    Code: '8152',
    Name: 'Layer, tarmac'
  },
  {
    Id: 27320,
    Code: '8152',
    Name: 'Lengthman, road'
  },
  {
    Id: 27321,
    Code: '8152',
    Name: 'Lengthman, local government'
  },
  {
    Id: 27322,
    Code: '8152',
    Name: 'Leveller, concrete'
  },
  {
    Id: 27323,
    Code: '8152',
    Name: 'Liner, road'
  },
  {
    Id: 27324,
    Code: '8152',
    Name: 'Maker, road'
  },
  {
    Id: 27325,
    Code: '8152',
    Name: 'Man, road, building construction'
  },
  {
    Id: 27326,
    Code: '8152',
    Name: 'Man, road, local government'
  },
  {
    Id: 27327,
    Code: '8152',
    Name: 'Man, surface, civil engineering'
  },
  {
    Id: 27328,
    Code: '8152',
    Name: 'Man, tar, building construction'
  },
  {
    Id: 27329,
    Code: '8152',
    Name: 'Marker, road'
  },
  {
    Id: 27330,
    Code: '8152',
    Name: 'Mason, street'
  },
  {
    Id: 27331,
    Code: '8152',
    Name: 'Mender, road'
  },
  {
    Id: 27332,
    Code: '8152',
    Name: 'Operative, highways'
  },
  {
    Id: 27333,
    Code: '8152',
    Name: 'Operative, management, traffic'
  },
  {
    Id: 27334,
    Code: '8152',
    Name: 'Operative, realm, public, Highways Agency'
  },
  {
    Id: 27335,
    Code: '8152',
    Name: 'Paver, tar'
  },
  {
    Id: 27336,
    Code: '8152',
    Name: 'Paver'
  },
  {
    Id: 27337,
    Code: '8152',
    Name: 'Pavior, tar'
  },
  {
    Id: 27338,
    Code: '8152',
    Name: 'Pavior'
  },
  {
    Id: 27339,
    Code: '8152',
    Name: 'Paviour, tar'
  },
  {
    Id: 27340,
    Code: '8152',
    Name: 'Paviour'
  },
  {
    Id: 27341,
    Code: '8152',
    Name: 'Raker, asphalt'
  },
  {
    Id: 27342,
    Code: '8152',
    Name: 'Raker, tarmac'
  },
  {
    Id: 27343,
    Code: '8152',
    Name: 'Reinstater, road'
  },
  {
    Id: 27344,
    Code: '8152',
    Name: 'Repairer, road'
  },
  {
    Id: 27345,
    Code: '8152',
    Name: 'Roadman'
  },
  {
    Id: 27346,
    Code: '8152',
    Name: 'Roadsman, local government'
  },
  {
    Id: 27347,
    Code: '8152',
    Name: 'Screeder'
  },
  {
    Id: 27348,
    Code: '8152',
    Name: 'Setter, kerb'
  },
  {
    Id: 27349,
    Code: '8152',
    Name: 'Spreader, asphalt'
  },
  {
    Id: 27350,
    Code: '8152',
    Name: 'Spreader, tar'
  },
  {
    Id: 27351,
    Code: '8152',
    Name: 'Superintendent, highways'
  },
  {
    Id: 27352,
    Code: '8152',
    Name: 'Surfacer, road'
  },
  {
    Id: 27353,
    Code: '8152',
    Name: 'Tamperman'
  },
  {
    Id: 27354,
    Code: '8152',
    Name: 'Tarmacer'
  },
  {
    Id: 27355,
    Code: '8152',
    Name: 'Worker, concrete'
  },
  {
    Id: 27356,
    Code: '8152',
    Name: 'Worker, reinstatement, road'
  },
  {
    Id: 27357,
    Code: '8152',
    Name: 'Worker, road'
  },
  {
    Id: 27358,
    Code: '8152',
    Name: 'Worker, surface, road'
  },
  {
    Id: 27359,
    Code: '8152',
    Name: 'Worker, tarmac'
  },
  {
    Id: 27360,
    Code: '8153',
    Name: 'Constructor, way, permanent'
  },
  {
    Id: 27361,
    Code: '8153',
    Name: 'Detector, flaw, rail, ultrasonic'
  },
  {
    Id: 27362,
    Code: '8153',
    Name: 'Engineer, maintenance, track'
  },
  {
    Id: 27363,
    Code: '8153',
    Name: 'Engineer, railway, railway maintenance and repair'
  },
  {
    Id: 27364,
    Code: '8153',
    Name: 'Engineer, ultrasonic, railway maintenance and repair'
  },
  {
    Id: 27365,
    Code: '8153',
    Name: 'Engineer, ultrasonic, railways'
  },
  {
    Id: 27366,
    Code: '8153',
    Name: 'Examiner, bridge, railway maintenance and repair'
  },
  {
    Id: 27367,
    Code: '8153',
    Name: 'Examiner, bridge, railways'
  },
  {
    Id: 27368,
    Code: '8153',
    Name: 'Examiner, track'
  },
  {
    Id: 27369,
    Code: '8153',
    Name: 'Hand, maintenance, railway maintenance and repair'
  },
  {
    Id: 27370,
    Code: '8153',
    Name: 'Inspector, line, railways'
  },
  {
    Id: 27371,
    Code: '8153',
    Name: 'Inspector, ultrasonic, railway maintenance and repair'
  },
  {
    Id: 27372,
    Code: '8153',
    Name: 'Inspector, ultrasonic, railways'
  },
  {
    Id: 27373,
    Code: '8153',
    Name: 'Installer, way, permanent'
  },
  {
    Id: 27374,
    Code: '8153',
    Name: 'Layer, block, mine'
  },
  {
    Id: 27375,
    Code: '8153',
    Name: 'Layer, plate'
  },
  {
    Id: 27376,
    Code: '8153',
    Name: 'Layer, rail'
  },
  {
    Id: 27377,
    Code: '8153',
    Name: 'Layer, road, railway maintenance and repair'
  },
  {
    Id: 27378,
    Code: '8153',
    Name: 'Layer, road, railways'
  },
  {
    Id: 27379,
    Code: '8153',
    Name: 'Layer, track'
  },
  {
    Id: 27380,
    Code: '8153',
    Name: 'Lengthman, railway maintenance and repair'
  },
  {
    Id: 27381,
    Code: '8153',
    Name: 'Lengthman, railways'
  },
  {
    Id: 27382,
    Code: '8153',
    Name: 'Man, maintenance, track, railway maintenance and repair'
  },
  {
    Id: 27383,
    Code: '8153',
    Name: 'Man, maintenance, track, railways'
  },
  {
    Id: 27384,
    Code: '8153',
    Name: 'Man, maintenance, railway maintenance and repair'
  },
  {
    Id: 27385,
    Code: '8153',
    Name: 'Man, maintenance, transport: railways'
  },
  {
    Id: 27386,
    Code: '8153',
    Name: 'Man, road, mining'
  },
  {
    Id: 27387,
    Code: '8153',
    Name: 'Man, way, permanent'
  },
  {
    Id: 27388,
    Code: '8153',
    Name: 'Operator, detector, flaw, rail, ultra'
  },
  {
    Id: 27389,
    Code: '8153',
    Name: 'Operator, ultrasonic, railway maintenance and repair'
  },
  {
    Id: 27390,
    Code: '8153',
    Name: 'Operator, ultrasonic, railways'
  },
  {
    Id: 27391,
    Code: '8153',
    Name: 'Patrol, railway maintenance and repair'
  },
  {
    Id: 27392,
    Code: '8153',
    Name: 'Patrol, railways'
  },
  {
    Id: 27393,
    Code: '8153',
    Name: 'Patrolman, railway maintenance and repair'
  },
  {
    Id: 27394,
    Code: '8153',
    Name: 'Patrolman, railways'
  },
  {
    Id: 27395,
    Code: '8153',
    Name: 'Railman, coal mine'
  },
  {
    Id: 27396,
    Code: '8153',
    Name: 'Relayer, railway maintenance and repair'
  },
  {
    Id: 27397,
    Code: '8153',
    Name: 'Relayer, railways'
  },
  {
    Id: 27398,
    Code: '8153',
    Name: 'Repairer, railway'
  },
  {
    Id: 27399,
    Code: '8153',
    Name: 'Repairer, road, mine: not coal'
  },
  {
    Id: 27400,
    Code: '8153',
    Name: 'Roadsman, mining'
  },
  {
    Id: 27401,
    Code: '8153',
    Name: 'Setter, road'
  },
  {
    Id: 27402,
    Code: '8153',
    Name: 'Sub-inspector, railway maintenance and repair'
  },
  {
    Id: 27403,
    Code: '8153',
    Name: 'Sub-inspector, railways: engineering'
  },
  {
    Id: 27404,
    Code: '8153',
    Name: 'Trackman, mine: not coal'
  },
  {
    Id: 27405,
    Code: '8153',
    Name: 'Trackman, railway maintenance and repair'
  },
  {
    Id: 27406,
    Code: '8153',
    Name: 'Trackman, railways'
  },
  {
    Id: 27407,
    Code: '8153',
    Name: 'Wagonwayman'
  },
  {
    Id: 27408,
    Code: '8153',
    Name: 'Worker, maintenance, track, rail'
  },
  {
    Id: 27409,
    Code: '8159',
    Name: 'Asphalter, mastic'
  },
  {
    Id: 27410,
    Code: '8159',
    Name: 'Assistant, estate, maintenance'
  },
  {
    Id: 27411,
    Code: '8159',
    Name: 'Assistant, estates, maintenance'
  },
  {
    Id: 27412,
    Code: '8159',
    Name: 'Assistant, facilities, maintenance'
  },
  {
    Id: 27413,
    Code: '8159',
    Name: 'Assistant, lighting, street'
  },
  {
    Id: 27414,
    Code: '8159',
    Name: 'Assistant, maintenance'
  },
  {
    Id: 27415,
    Code: '8159',
    Name: 'Assistant, management, facilities, maintenance'
  },
  {
    Id: 27416,
    Code: '8159',
    Name: 'Attendant, light'
  },
  {
    Id: 27417,
    Code: '8159',
    Name: 'Attendant, lighting'
  },
  {
    Id: 27418,
    Code: '8159',
    Name: 'Blaster, sand, building construction'
  },
  {
    Id: 27419,
    Code: '8159',
    Name: 'Borer, tunnel'
  },
  {
    Id: 27420,
    Code: '8159',
    Name: 'Cleaner, building'
  },
  {
    Id: 27421,
    Code: '8159',
    Name: 'Cleaner, stone'
  },
  {
    Id: 27422,
    Code: '8159',
    Name: 'Contractor, demolition'
  },
  {
    Id: 27423,
    Code: '8159',
    Name: "Contractor, engineer's, civil"
  },
  {
    Id: 27424,
    Code: '8159',
    Name: 'Contractor, engineering, civil'
  },
  {
    Id: 27425,
    Code: '8159',
    Name: 'Contractor, general'
  },
  {
    Id: 27426,
    Code: '8159',
    Name: 'Contractor, works, public'
  },
  {
    Id: 27427,
    Code: '8159',
    Name: 'Contractor, building construction'
  },
  {
    Id: 27428,
    Code: '8159',
    Name: 'Contractor, pipe lagging'
  },
  {
    Id: 27429,
    Code: '8159',
    Name: 'Corer, hard'
  },
  {
    Id: 27430,
    Code: '8159',
    Name: 'Demolisher'
  },
  {
    Id: 27431,
    Code: '8159',
    Name: 'Dismantler, building construction'
  },
  {
    Id: 27432,
    Code: '8159',
    Name: 'Engineer, demolition'
  },
  {
    Id: 27433,
    Code: '8159',
    Name: 'Engineer, drainage'
  },
  {
    Id: 27434,
    Code: '8159',
    Name: 'Engineer, explosives, demolition'
  },
  {
    Id: 27435,
    Code: '8159',
    Name: 'Engineer, insulating'
  },
  {
    Id: 27436,
    Code: '8159',
    Name: 'Engineer, insulation, thermal'
  },
  {
    Id: 27437,
    Code: '8159',
    Name: 'Engineer, insulation'
  },
  {
    Id: 27438,
    Code: '8159',
    Name: 'Engineer, lining, chimney'
  },
  {
    Id: 27439,
    Code: '8159',
    Name: 'Engineer, protection, fire, construction'
  },
  {
    Id: 27440,
    Code: '8159',
    Name: 'Erector, building, portable'
  },
  {
    Id: 27441,
    Code: '8159',
    Name: 'Erector, ceiling'
  },
  {
    Id: 27442,
    Code: '8159',
    Name: 'Erector, garage'
  },
  {
    Id: 27443,
    Code: '8159',
    Name: 'Erector, greenhouse'
  },
  {
    Id: 27444,
    Code: '8159',
    Name: 'Erector, prefab'
  },
  {
    Id: 27445,
    Code: '8159',
    Name: 'Erector, sign'
  },
  {
    Id: 27446,
    Code: '8159',
    Name: 'Firer, shot, civil engineering'
  },
  {
    Id: 27447,
    Code: '8159',
    Name: 'Fitter, blind'
  },
  {
    Id: 27448,
    Code: '8159',
    Name: 'Fitter, ceiling'
  },
  {
    Id: 27449,
    Code: '8159',
    Name: 'Fitter, distribution, gas supplier'
  },
  {
    Id: 27450,
    Code: '8159',
    Name: 'Fitter, distribution, water works'
  },
  {
    Id: 27451,
    Code: '8159',
    Name: 'Fitter, insulating'
  },
  {
    Id: 27452,
    Code: '8159',
    Name: 'Fitter, insulation'
  },
  {
    Id: 27453,
    Code: '8159',
    Name: 'Fitter, mains, water supplier'
  },
  {
    Id: 27454,
    Code: '8159',
    Name: 'Fitter, pipe, drain'
  },
  {
    Id: 27455,
    Code: '8159',
    Name: 'Fitter, sign'
  },
  {
    Id: 27456,
    Code: '8159',
    Name: 'Fitter, sun-blind'
  },
  {
    Id: 27457,
    Code: '8159',
    Name: 'Fitter, shop blind mfr'
  },
  {
    Id: 27458,
    Code: '8159',
    Name: 'Fixer, blind'
  },
  {
    Id: 27459,
    Code: '8159',
    Name: 'Fixer, board, plaster'
  },
  {
    Id: 27460,
    Code: '8159',
    Name: 'Fixer, ceiling'
  },
  {
    Id: 27461,
    Code: '8159',
    Name: 'Fixer, frame, metal'
  },
  {
    Id: 27462,
    Code: '8159',
    Name: 'Fixer, sign'
  },
  {
    Id: 27463,
    Code: '8159',
    Name: 'Fixer, wall, curtain'
  },
  {
    Id: 27464,
    Code: '8159',
    Name: 'Foreman, lighting'
  },
  {
    Id: 27465,
    Code: '8159',
    Name: 'Grouter'
  },
  {
    Id: 27466,
    Code: '8159',
    Name: 'Hand, maintenance, building construction'
  },
  {
    Id: 27467,
    Code: '8159',
    Name: 'Handyman, general'
  },
  {
    Id: 27468,
    Code: '8159',
    Name: 'Handyman, maintenance'
  },
  {
    Id: 27469,
    Code: '8159',
    Name: 'Handyman, nos'
  },
  {
    Id: 27470,
    Code: '8159',
    Name: 'Installer, blind'
  },
  {
    Id: 27471,
    Code: '8159',
    Name: 'Installer, ceiling, suspended'
  },
  {
    Id: 27472,
    Code: '8159',
    Name: 'Installer, insulation, acoustic'
  },
  {
    Id: 27473,
    Code: '8159',
    Name: 'Installer, insulation, sound'
  },
  {
    Id: 27474,
    Code: '8159',
    Name: 'Installer, insulation'
  },
  {
    Id: 27475,
    Code: '8159',
    Name: 'Installer, sign'
  },
  {
    Id: 27476,
    Code: '8159',
    Name: 'Installer, wall, cavity'
  },
  {
    Id: 27477,
    Code: '8159',
    Name: 'Insulator, building'
  },
  {
    Id: 27478,
    Code: '8159',
    Name: 'Insulator, thermal'
  },
  {
    Id: 27479,
    Code: '8159',
    Name: 'Insulator, wall, cavity'
  },
  {
    Id: 27480,
    Code: '8159',
    Name: 'Insulator, construction'
  },
  {
    Id: 27481,
    Code: '8159',
    Name: 'Jigger, spindle'
  },
  {
    Id: 27482,
    Code: '8159',
    Name: 'Joiner, pipe'
  },
  {
    Id: 27483,
    Code: '8159',
    Name: 'Jointer, pipe'
  },
  {
    Id: 27484,
    Code: '8159',
    Name: 'Jointer, civil engineering'
  },
  {
    Id: 27485,
    Code: '8159',
    Name: 'Jointer, gas supplier'
  },
  {
    Id: 27486,
    Code: '8159',
    Name: 'Jointer, water company'
  },
  {
    Id: 27487,
    Code: '8159',
    Name: 'Lampman, signal'
  },
  {
    Id: 27488,
    Code: '8159',
    Name: 'Layer, cable'
  },
  {
    Id: 27489,
    Code: '8159',
    Name: 'Layer, drain'
  },
  {
    Id: 27490,
    Code: '8159',
    Name: 'Layer, main'
  },
  {
    Id: 27491,
    Code: '8159',
    Name: 'Layer, mains'
  },
  {
    Id: 27492,
    Code: '8159',
    Name: 'Layer, pipe'
  },
  {
    Id: 27493,
    Code: '8159',
    Name: 'Layer, service, gas'
  },
  {
    Id: 27494,
    Code: '8159',
    Name: 'Layer, service'
  },
  {
    Id: 27495,
    Code: '8159',
    Name: 'Layer, track, pipe'
  },
  {
    Id: 27496,
    Code: '8159',
    Name: 'Layer and fixer, patent flooring and roofing'
  },
  {
    Id: 27497,
    Code: '8159',
    Name: 'Man, leading, building construction'
  },
  {
    Id: 27498,
    Code: '8159',
    Name: 'Man, maintenance'
  },
  {
    Id: 27499,
    Code: '8159',
    Name: 'Man, mattock'
  },
  {
    Id: 27500,
    Code: '8159',
    Name: 'Miner, tunnel'
  },
  {
    Id: 27501,
    Code: '8159',
    Name: 'Officer, estates, maintenance'
  },
  {
    Id: 27502,
    Code: '8159',
    Name: 'Officer, maintenance'
  },
  {
    Id: 27503,
    Code: '8159',
    Name: 'Officer, signs, motoring organisation'
  },
  {
    Id: 27504,
    Code: '8159',
    Name: 'Operative, asbestos'
  },
  {
    Id: 27505,
    Code: '8159',
    Name: 'Operative, building'
  },
  {
    Id: 27506,
    Code: '8159',
    Name: 'Operative, concrete'
  },
  {
    Id: 27507,
    Code: '8159',
    Name: 'Operative, construction'
  },
  {
    Id: 27508,
    Code: '8159',
    Name: 'Operative, demolition'
  },
  {
    Id: 27509,
    Code: '8159',
    Name: 'Operative, engineering, civil'
  },
  {
    Id: 27510,
    Code: '8159',
    Name: 'Operative, facilities, maintenance'
  },
  {
    Id: 27511,
    Code: '8159',
    Name: 'Operative, lighting, public'
  },
  {
    Id: 27512,
    Code: '8159',
    Name: 'Operative, maintenance, property'
  },
  {
    Id: 27513,
    Code: '8159',
    Name: 'Operator, building'
  },
  {
    Id: 27514,
    Code: '8159',
    Name: 'Operator, CCTV, drain surveying'
  },
  {
    Id: 27515,
    Code: '8159',
    Name: 'Partitioner, building construction'
  },
  {
    Id: 27516,
    Code: '8159',
    Name: 'Pitcher, stone'
  },
  {
    Id: 27517,
    Code: '8159',
    Name: 'Pitcher, building construction'
  },
  {
    Id: 27518,
    Code: '8159',
    Name: 'Polisher, floor, building construction'
  },
  {
    Id: 27519,
    Code: '8159',
    Name: 'Proofer, draught'
  },
  {
    Id: 27520,
    Code: '8159',
    Name: 'Proofer, fire'
  },
  {
    Id: 27521,
    Code: '8159',
    Name: 'Remover, asbestos'
  },
  {
    Id: 27522,
    Code: '8159',
    Name: 'Repairer, bridge'
  },
  {
    Id: 27523,
    Code: '8159',
    Name: "Repairer, builder's"
  },
  {
    Id: 27524,
    Code: '8159',
    Name: 'Repairer, building'
  },
  {
    Id: 27525,
    Code: '8159',
    Name: 'Repairer, concrete'
  },
  {
    Id: 27526,
    Code: '8159',
    Name: 'Repairer, house'
  },
  {
    Id: 27527,
    Code: '8159',
    Name: 'Repairer, property'
  },
  {
    Id: 27528,
    Code: '8159',
    Name: 'Restorer, stone'
  },
  {
    Id: 27529,
    Code: '8159',
    Name: 'Sinker, pit'
  },
  {
    Id: 27530,
    Code: '8159',
    Name: 'Sinker, shaft'
  },
  {
    Id: 27531,
    Code: '8159',
    Name: 'Sinker, well'
  },
  {
    Id: 27532,
    Code: '8159',
    Name: 'Sinker, mine sinking'
  },
  {
    Id: 27533,
    Code: '8159',
    Name: 'Sprayer, insulation'
  },
  {
    Id: 27534,
    Code: '8159',
    Name: 'Sprayer, insulation contracting'
  },
  {
    Id: 27535,
    Code: '8159',
    Name: 'Stripper, asbestos'
  },
  {
    Id: 27536,
    Code: '8159',
    Name: 'Taper, Ames'
  },
  {
    Id: 27537,
    Code: '8159',
    Name: 'Taper and jointer'
  },
  {
    Id: 27538,
    Code: '8159',
    Name: 'Technician, insulation, wall, cavity'
  },
  {
    Id: 27539,
    Code: '8159',
    Name: 'Technician, wall, cavity'
  },
  {
    Id: 27540,
    Code: '8159',
    Name: 'Topman, demolition'
  },
  {
    Id: 27541,
    Code: '8159',
    Name: 'Tunneller'
  },
  {
    Id: 27542,
    Code: '8159',
    Name: 'Worker, demolition'
  },
  {
    Id: 27543,
    Code: '8159',
    Name: 'Worker, distribution, mains services'
  },
  {
    Id: 27544,
    Code: '8160',
    Name: 'Blender, wool'
  },
  {
    Id: 27545,
    Code: '8160',
    Name: 'Blender, wool blending'
  },
  {
    Id: 27546,
    Code: '8160',
    Name: 'Carder, head'
  },
  {
    Id: 27547,
    Code: '8160',
    Name: 'Carder, under'
  },
  {
    Id: 27548,
    Code: '8160',
    Name: 'Controller, converter'
  },
  {
    Id: 27549,
    Code: '8160',
    Name: 'Controller, furnace, metal mfr'
  },
  {
    Id: 27550,
    Code: '8160',
    Name: 'Controller, furnace, sherardizing'
  },
  {
    Id: 27551,
    Code: '8160',
    Name: 'Dyer, head'
  },
  {
    Id: 27552,
    Code: '8160',
    Name: 'Dyer, master'
  },
  {
    Id: 27553,
    Code: '8160',
    Name: 'Foreman, assembling'
  },
  {
    Id: 27554,
    Code: '8160',
    Name: 'Foreman, battery, coke ovens'
  },
  {
    Id: 27555,
    Code: '8160',
    Name: 'Foreman, blowing'
  },
  {
    Id: 27556,
    Code: '8160',
    Name: 'Foreman, brewer'
  },
  {
    Id: 27557,
    Code: '8160',
    Name: 'Foreman, calender, paper mfr'
  },
  {
    Id: 27558,
    Code: '8160',
    Name: 'Foreman, carbonising, coal gas, coke ovens'
  },
  {
    Id: 27559,
    Code: '8160',
    Name: 'Foreman, card'
  },
  {
    Id: 27560,
    Code: '8160',
    Name: 'Foreman, carding'
  },
  {
    Id: 27561,
    Code: '8160',
    Name: 'Foreman, checking, engineering'
  },
  {
    Id: 27562,
    Code: '8160',
    Name: 'Foreman, chemical'
  },
  {
    Id: 27563,
    Code: '8160',
    Name: 'Foreman, colour, carpet, rug mfr'
  },
  {
    Id: 27564,
    Code: '8160',
    Name: 'Foreman, comber'
  },
  {
    Id: 27565,
    Code: '8160',
    Name: 'Foreman, combing'
  },
  {
    Id: 27566,
    Code: '8160',
    Name: 'Foreman, cupola'
  },
  {
    Id: 27567,
    Code: '8160',
    Name: 'Foreman, dairy'
  },
  {
    Id: 27568,
    Code: '8160',
    Name: 'Foreman, doubler'
  },
  {
    Id: 27569,
    Code: '8160',
    Name: 'Foreman, drawing, textile mfr'
  },
  {
    Id: 27570,
    Code: '8160',
    Name: 'Foreman, electroplating'
  },
  {
    Id: 27571,
    Code: '8160',
    Name: 'Foreman, extrusion, metal'
  },
  {
    Id: 27572,
    Code: '8160',
    Name: 'Foreman, frame, carding'
  },
  {
    Id: 27573,
    Code: '8160',
    Name: 'Foreman, furnace, blast'
  },
  {
    Id: 27574,
    Code: '8160',
    Name: 'Foreman, furnace, glass mfr'
  },
  {
    Id: 27575,
    Code: '8160',
    Name: 'Foreman, furnace, metal trades'
  },
  {
    Id: 27576,
    Code: '8160',
    Name: 'Foreman, hearth, soak'
  },
  {
    Id: 27577,
    Code: '8160',
    Name: 'Foreman, heat, gas works'
  },
  {
    Id: 27578,
    Code: '8160',
    Name: 'Foreman, house, gas'
  },
  {
    Id: 27579,
    Code: '8160',
    Name: 'Foreman, house, retort'
  },
  {
    Id: 27580,
    Code: '8160',
    Name: 'Foreman, inspection'
  },
  {
    Id: 27581,
    Code: '8160',
    Name: 'Foreman, kiln, carbon goods mfr'
  },
  {
    Id: 27582,
    Code: '8160',
    Name: 'Foreman, kiln, ceramics mfr'
  },
  {
    Id: 27583,
    Code: '8160',
    Name: 'Foreman, lime'
  },
  {
    Id: 27584,
    Code: '8160',
    Name: 'Foreman, milk, dairy'
  },
  {
    Id: 27585,
    Code: '8160',
    Name: 'Foreman, mill, blue'
  },
  {
    Id: 27586,
    Code: '8160',
    Name: 'Foreman, mill, rolling'
  },
  {
    Id: 27587,
    Code: '8160',
    Name: 'Foreman, mill, food products mfr'
  },
  {
    Id: 27588,
    Code: '8160',
    Name: 'Foreman, mule'
  },
  {
    Id: 27589,
    Code: '8160',
    Name: 'Foreman, oven'
  },
  {
    Id: 27590,
    Code: '8160',
    Name: 'Foreman, plant, carbonisation'
  },
  {
    Id: 27591,
    Code: '8160',
    Name: 'Foreman, plant, crushing'
  },
  {
    Id: 27592,
    Code: '8160',
    Name: 'Foreman, plant, gas'
  },
  {
    Id: 27593,
    Code: '8160',
    Name: 'Foreman, plant, mixing, asphalt mfr'
  },
  {
    Id: 27594,
    Code: '8160',
    Name: 'Foreman, plant, reforming'
  },
  {
    Id: 27595,
    Code: '8160',
    Name: 'Foreman, polish'
  },
  {
    Id: 27596,
    Code: '8160',
    Name: 'Foreman, process, food products mfr'
  },
  {
    Id: 27597,
    Code: '8160',
    Name: 'Foreman, process, plastics mfr'
  },
  {
    Id: 27598,
    Code: '8160',
    Name: 'Foreman, process'
  },
  {
    Id: 27599,
    Code: '8160',
    Name: 'Foreman, purification, gas supplier'
  },
  {
    Id: 27600,
    Code: '8160',
    Name: 'Foreman, purifier, gas'
  },
  {
    Id: 27601,
    Code: '8160',
    Name: 'Foreman, quality, engineering'
  },
  {
    Id: 27602,
    Code: '8160',
    Name: 'Foreman, refining, metal'
  },
  {
    Id: 27603,
    Code: '8160',
    Name: 'Foreman, retort, gas works'
  },
  {
    Id: 27604,
    Code: '8160',
    Name: 'Foreman, ring'
  },
  {
    Id: 27605,
    Code: '8160',
    Name: 'Foreman, room, blowing'
  },
  {
    Id: 27606,
    Code: '8160',
    Name: 'Foreman, room, card'
  },
  {
    Id: 27607,
    Code: '8160',
    Name: 'Foreman, room, machine, clothing mfr'
  },
  {
    Id: 27608,
    Code: '8160',
    Name: 'Foreman, room, mill, fur dressing'
  },
  {
    Id: 27609,
    Code: '8160',
    Name: 'Foreman, room, sewing'
  },
  {
    Id: 27610,
    Code: '8160',
    Name: 'Foreman, salle'
  },
  {
    Id: 27611,
    Code: '8160',
    Name: 'Foreman, screen, gas works: coke ovens'
  },
  {
    Id: 27612,
    Code: '8160',
    Name: 'Foreman, screens, gas works: coke ovens'
  },
  {
    Id: 27613,
    Code: '8160',
    Name: 'Foreman, scribbling'
  },
  {
    Id: 27614,
    Code: '8160',
    Name: 'Foreman, shed, tannery'
  },
  {
    Id: 27615,
    Code: '8160',
    Name: 'Foreman, shop, melting'
  },
  {
    Id: 27616,
    Code: '8160',
    Name: 'Foreman, shop, press, plastics goods mfr'
  },
  {
    Id: 27617,
    Code: '8160',
    Name: 'Foreman, shop, steel'
  },
  {
    Id: 27618,
    Code: '8160',
    Name: 'Foreman, shop, paper mfr'
  },
  {
    Id: 27619,
    Code: '8160',
    Name: 'Foreman, spinning, man-made fibre mfr'
  },
  {
    Id: 27620,
    Code: '8160',
    Name: 'Foreman, spinning'
  },
  {
    Id: 27621,
    Code: '8160',
    Name: 'Foreman, stage, gas works'
  },
  {
    Id: 27622,
    Code: '8160',
    Name: 'Foreman, tank, glass mfr'
  },
  {
    Id: 27623,
    Code: '8160',
    Name: 'Foreman, test, motor'
  },
  {
    Id: 27624,
    Code: '8160',
    Name: 'Foreman, textile'
  },
  {
    Id: 27625,
    Code: '8160',
    Name: 'Foreman, timber'
  },
  {
    Id: 27626,
    Code: '8160',
    Name: 'Foreman, treatment, heat'
  },
  {
    Id: 27627,
    Code: '8160',
    Name: 'Foreman, twisting'
  },
  {
    Id: 27628,
    Code: '8160',
    Name: 'Foreman, winding'
  },
  {
    Id: 27629,
    Code: '8160',
    Name: 'Foreman, wool'
  },
  {
    Id: 27630,
    Code: '8160',
    Name: 'Foreman, yard, tan'
  },
  {
    Id: 27631,
    Code: '8160',
    Name: 'Foreman, abrasive paper, cloth mfr'
  },
  {
    Id: 27632,
    Code: '8160',
    Name: 'Foreman, animal feeds mfr'
  },
  {
    Id: 27633,
    Code: '8160',
    Name: 'Foreman, annealing'
  },
  {
    Id: 27634,
    Code: '8160',
    Name: 'Foreman, asbestos mfr'
  },
  {
    Id: 27635,
    Code: '8160',
    Name: 'Foreman, asbestos-cement goods mfr'
  },
  {
    Id: 27636,
    Code: '8160',
    Name: 'Foreman, asphalt mfr'
  },
  {
    Id: 27637,
    Code: '8160',
    Name: 'Foreman, blast furnace'
  },
  {
    Id: 27638,
    Code: '8160',
    Name: 'Foreman, blue and starch mfr'
  },
  {
    Id: 27639,
    Code: '8160',
    Name: 'Foreman, brake linings mfr'
  },
  {
    Id: 27640,
    Code: '8160',
    Name: 'Foreman, brewery'
  },
  {
    Id: 27641,
    Code: '8160',
    Name: 'Foreman, cable mfr'
  },
  {
    Id: 27642,
    Code: '8160',
    Name: 'Foreman, cardboard box mfr'
  },
  {
    Id: 27643,
    Code: '8160',
    Name: 'Foreman, carpet, rug mfr'
  },
  {
    Id: 27644,
    Code: '8160',
    Name: 'Foreman, cast concrete products mfr'
  },
  {
    Id: 27645,
    Code: '8160',
    Name: 'Foreman, cattle food mfr'
  },
  {
    Id: 27646,
    Code: '8160',
    Name: 'Foreman, cement mfr'
  },
  {
    Id: 27647,
    Code: '8160',
    Name: 'Foreman, chemical mfr'
  },
  {
    Id: 27648,
    Code: '8160',
    Name: 'Foreman, chocolate mfr'
  },
  {
    Id: 27649,
    Code: '8160',
    Name: 'Foreman, cigarette mfr'
  },
  {
    Id: 27650,
    Code: '8160',
    Name: 'Foreman, coal gas by-products mfr'
  },
  {
    Id: 27651,
    Code: '8160',
    Name: 'Foreman, coke ovens'
  },
  {
    Id: 27652,
    Code: '8160',
    Name: 'Foreman, concrete mfr'
  },
  {
    Id: 27653,
    Code: '8160',
    Name: 'Foreman, cotton waste merchants'
  },
  {
    Id: 27654,
    Code: '8160',
    Name: 'Foreman, dairy'
  },
  {
    Id: 27655,
    Code: '8160',
    Name: 'Foreman, dyeworks'
  },
  {
    Id: 27656,
    Code: '8160',
    Name: 'Foreman, electrical domestic appliance mfr'
  },
  {
    Id: 27657,
    Code: '8160',
    Name: 'Foreman, electrical lighting equipment mfr'
  },
  {
    Id: 27658,
    Code: '8160',
    Name: 'Foreman, electroplating'
  },
  {
    Id: 27659,
    Code: '8160',
    Name: 'Foreman, food products mfr'
  },
  {
    Id: 27660,
    Code: '8160',
    Name: 'Foreman, gas supplier: gas works'
  },
  {
    Id: 27661,
    Code: '8160',
    Name: 'Foreman, gelatine, glue, size mfr'
  },
  {
    Id: 27662,
    Code: '8160',
    Name: 'Foreman, grain milling'
  },
  {
    Id: 27663,
    Code: '8160',
    Name: 'Foreman, grinding wheel mfr'
  },
  {
    Id: 27664,
    Code: '8160',
    Name: 'Foreman, iron, steel mfr'
  },
  {
    Id: 27665,
    Code: '8160',
    Name: 'Foreman, leather dressing'
  },
  {
    Id: 27666,
    Code: '8160',
    Name: 'Foreman, leathercloth mfr'
  },
  {
    Id: 27667,
    Code: '8160',
    Name: 'Foreman, linoleum mfr'
  },
  {
    Id: 27668,
    Code: '8160',
    Name: 'Foreman, metal cutlery mfr'
  },
  {
    Id: 27669,
    Code: '8160',
    Name: 'Foreman, metal extrusion'
  },
  {
    Id: 27670,
    Code: '8160',
    Name: 'Foreman, metal heat treatment'
  },
  {
    Id: 27671,
    Code: '8160',
    Name: 'Foreman, metal refining'
  },
  {
    Id: 27672,
    Code: '8160',
    Name: 'Foreman, metal tube mfr'
  },
  {
    Id: 27673,
    Code: '8160',
    Name: 'Foreman, mineral water mfr'
  },
  {
    Id: 27674,
    Code: '8160',
    Name: 'Foreman, oil refining'
  },
  {
    Id: 27675,
    Code: '8160',
    Name: 'Foreman, oil seed crushing'
  },
  {
    Id: 27676,
    Code: '8160',
    Name: 'Foreman, ordnance factory: explosive mfr'
  },
  {
    Id: 27677,
    Code: '8160',
    Name: 'Foreman, paint mfr'
  },
  {
    Id: 27678,
    Code: '8160',
    Name: 'Foreman, paper goods mfr'
  },
  {
    Id: 27679,
    Code: '8160',
    Name: 'Foreman, paper mfr'
  },
  {
    Id: 27680,
    Code: '8160',
    Name: 'Foreman, patent fuel mfr'
  },
  {
    Id: 27681,
    Code: '8160',
    Name: 'Foreman, plasterboard mfr'
  },
  {
    Id: 27682,
    Code: '8160',
    Name: 'Foreman, plastics goods mfr'
  },
  {
    Id: 27683,
    Code: '8160',
    Name: 'Foreman, plastics mfr'
  },
  {
    Id: 27684,
    Code: '8160',
    Name: 'Foreman, razor blade mfr'
  },
  {
    Id: 27685,
    Code: '8160',
    Name: 'Foreman, rolling mill'
  },
  {
    Id: 27686,
    Code: '8160',
    Name: 'Foreman, rubber goods mfr'
  },
  {
    Id: 27687,
    Code: '8160',
    Name: 'Foreman, sawmilling'
  },
  {
    Id: 27688,
    Code: '8160',
    Name: 'Foreman, slag wool mfr'
  },
  {
    Id: 27689,
    Code: '8160',
    Name: 'Foreman, soap, detergent mfr'
  },
  {
    Id: 27690,
    Code: '8160',
    Name: 'Foreman, spirit distilling'
  },
  {
    Id: 27691,
    Code: '8160',
    Name: 'Foreman, steel drawing'
  },
  {
    Id: 27692,
    Code: '8160',
    Name: 'Foreman, sugar confectionery mfr'
  },
  {
    Id: 27693,
    Code: '8160',
    Name: 'Foreman, sugar refining'
  },
  {
    Id: 27694,
    Code: '8160',
    Name: 'Foreman, surgical dressing mfr'
  },
  {
    Id: 27695,
    Code: '8160',
    Name: 'Foreman, tannery'
  },
  {
    Id: 27696,
    Code: '8160',
    Name: 'Foreman, telecoms: equipment mfr'
  },
  {
    Id: 27697,
    Code: '8160',
    Name: 'Foreman, textile mfr: bleaching, dyeing'
  },
  {
    Id: 27698,
    Code: '8160',
    Name: 'Foreman, textile mfr: man-made fibre mfr'
  },
  {
    Id: 27699,
    Code: '8160',
    Name: 'Foreman, textile mfr'
  },
  {
    Id: 27700,
    Code: '8160',
    Name: 'Foreman, tinplate mfr'
  },
  {
    Id: 27701,
    Code: '8160',
    Name: 'Foreman, tobacco mfr'
  },
  {
    Id: 27702,
    Code: '8160',
    Name: 'Foreman, toilet preparations mfr'
  },
  {
    Id: 27703,
    Code: '8160',
    Name: 'Foreman, typewriter ribbon mfr'
  },
  {
    Id: 27704,
    Code: '8160',
    Name: 'Foreman, vehicle mfr'
  },
  {
    Id: 27705,
    Code: '8160',
    Name: 'Foreman, wire mfr'
  },
  {
    Id: 27706,
    Code: '8160',
    Name: 'Forewoman, factory, telecoms'
  },
  {
    Id: 27707,
    Code: '8160',
    Name: 'Hand, leading, blast furnace'
  },
  {
    Id: 27708,
    Code: '8160',
    Name: 'Hand, leading, food products mfr'
  },
  {
    Id: 27709,
    Code: '8160',
    Name: 'Hand, leading, foundry'
  },
  {
    Id: 27710,
    Code: '8160',
    Name: 'Hand, leading, rubber goods mfr'
  },
  {
    Id: 27711,
    Code: '8160',
    Name: 'Hand, leading, textile mfr'
  },
  {
    Id: 27712,
    Code: '8160',
    Name: 'Inspector, chief, engineering'
  },
  {
    Id: 27713,
    Code: '8160',
    Name: 'Inspector, coke, coke ovens'
  },
  {
    Id: 27714,
    Code: '8160',
    Name: 'Inspector, diesel, railways'
  },
  {
    Id: 27715,
    Code: '8160',
    Name: 'Inspector, locomotive, railways'
  },
  {
    Id: 27716,
    Code: '8160',
    Name: 'Inspector, machine, weighing machines, coal mine'
  },
  {
    Id: 27717,
    Code: '8160',
    Name: 'Inspector, stock, rolling'
  },
  {
    Id: 27718,
    Code: '8160',
    Name: 'Jobber, card'
  },
  {
    Id: 27719,
    Code: '8160',
    Name: 'Jobber, carding'
  },
  {
    Id: 27720,
    Code: '8160',
    Name: 'Leader, team, production, assembly, electrical, electronic equipment mfr'
  },
  {
    Id: 27721,
    Code: '8160',
    Name: 'Leader, team, production, vehicle mfr'
  },
  {
    Id: 27722,
    Code: '8160',
    Name: 'Leader, team, assembly, electrical, electronic equipment mfr'
  },
  {
    Id: 27723,
    Code: '8160',
    Name: 'Leader, team, assembly, vehicle mfr'
  },
  {
    Id: 27724,
    Code: '8160',
    Name: 'Major, blow'
  },
  {
    Id: 27725,
    Code: '8160',
    Name: 'Major, blower'
  },
  {
    Id: 27726,
    Code: '8160',
    Name: 'Major, room, blow'
  },
  {
    Id: 27727,
    Code: '8160',
    Name: 'Maker, varnish, head'
  },
  {
    Id: 27728,
    Code: '8160',
    Name: 'Manager, drawing, textile mfr'
  },
  {
    Id: 27729,
    Code: '8160',
    Name: 'Master, card'
  },
  {
    Id: 27730,
    Code: '8160',
    Name: 'Master, spinning'
  },
  {
    Id: 27731,
    Code: '8160',
    Name: 'Master, winding'
  },
  {
    Id: 27732,
    Code: '8160',
    Name: 'Mistress, doffing'
  },
  {
    Id: 27733,
    Code: '8160',
    Name: 'Mistress, sewing'
  },
  {
    Id: 27734,
    Code: '8160',
    Name: 'Mistress, shifting'
  },
  {
    Id: 27735,
    Code: '8160',
    Name: 'Overlooker, textile mfr: textile warping'
  },
  {
    Id: 27736,
    Code: '8160',
    Name: 'Passer, sample'
  },
  {
    Id: 27737,
    Code: '8160',
    Name: 'Setter-out, wood products mfr'
  },
  {
    Id: 27738,
    Code: '8160',
    Name: 'Superintendent, plant, refinery'
  },
  {
    Id: 27739,
    Code: '8160',
    Name: 'Superintendent, platform, coal tar distillers'
  },
  {
    Id: 27740,
    Code: '8160',
    Name: 'Superintendent, refinery'
  },
  {
    Id: 27741,
    Code: '8160',
    Name: 'Superintendent, works, gas'
  },
  {
    Id: 27742,
    Code: '8160',
    Name: 'Superintendent, works, gas supplier'
  },
  {
    Id: 27743,
    Code: '8160',
    Name: 'Superintendent, gas supplier'
  },
  {
    Id: 27744,
    Code: '8160',
    Name: 'Supervisor, cotton'
  },
  {
    Id: 27745,
    Code: '8160',
    Name: 'Supervisor, process, chemical mfr'
  },
  {
    Id: 27746,
    Code: '8160',
    Name: 'Supervisor, process, explosives mfr'
  },
  {
    Id: 27747,
    Code: '8160',
    Name: 'Supervisor, process, oil refining'
  },
  {
    Id: 27748,
    Code: '8160',
    Name: 'Supervisor, room, print'
  },
  {
    Id: 27749,
    Code: '8160',
    Name: 'Supervisor, room, sewing'
  },
  {
    Id: 27750,
    Code: '8160',
    Name: 'Supervisor, animal feeds mfr'
  },
  {
    Id: 27751,
    Code: '8160',
    Name: 'Supervisor, oil refining'
  },
  {
    Id: 27752,
    Code: '8160',
    Name: 'Tenter, head, engine'
  },
  {
    Id: 27753,
    Code: '8211',
    Name: 'Carman, coal merchants'
  },
  {
    Id: 27754,
    Code: '8211',
    Name: 'Carrier, coal'
  },
  {
    Id: 27755,
    Code: '8211',
    Name: 'Carrier, general'
  },
  {
    Id: 27756,
    Code: '8211',
    Name: 'Carrier, railway'
  },
  {
    Id: 27757,
    Code: '8211',
    Name: 'Carrier, nos'
  },
  {
    Id: 27758,
    Code: '8211',
    Name: 'Carrier, mine: not coal'
  },
  {
    Id: 27759,
    Code: '8211',
    Name: 'Carrier, transport'
  },
  {
    Id: 27760,
    Code: '8211',
    Name: 'Carter, coal'
  },
  {
    Id: 27761,
    Code: '8211',
    Name: 'Coalman, delivery'
  },
  {
    Id: 27762,
    Code: '8211',
    Name: 'Collector, milk'
  },
  {
    Id: 27763,
    Code: '8211',
    Name: 'Collector-driver, refuse'
  },
  {
    Id: 27764,
    Code: '8211',
    Name: 'Contractor, cartage'
  },
  {
    Id: 27765,
    Code: '8211',
    Name: 'Contractor, disposal, waste'
  },
  {
    Id: 27766,
    Code: '8211',
    Name: 'Contractor, haulage'
  },
  {
    Id: 27767,
    Code: '8211',
    Name: 'Contractor, transport, road'
  },
  {
    Id: 27768,
    Code: '8211',
    Name: 'Contractor, transport'
  },
  {
    Id: 27769,
    Code: '8211',
    Name: 'Deliverer, coal'
  },
  {
    Id: 27770,
    Code: '8211',
    Name: 'Deliveryman, coal'
  },
  {
    Id: 27771,
    Code: '8211',
    Name: 'Drayman'
  },
  {
    Id: 27772,
    Code: '8211',
    Name: 'Driver, collection, refuse'
  },
  {
    Id: 27773,
    Code: '8211',
    Name: 'Driver, collection, waste'
  },
  {
    Id: 27774,
    Code: '8211',
    Name: 'Driver, delivery, HGV'
  },
  {
    Id: 27775,
    Code: '8211',
    Name: 'Driver, drop, multi, HGV'
  },
  {
    Id: 27776,
    Code: '8211',
    Name: 'Driver, dustcart'
  },
  {
    Id: 27777,
    Code: '8211',
    Name: 'Driver, goods'
  },
  {
    Id: 27778,
    Code: '8211',
    Name: 'Driver, haulage, motor'
  },
  {
    Id: 27779,
    Code: '8211',
    Name: 'Driver, haulage, heavy goods road transport'
  },
  {
    Id: 27780,
    Code: '8211',
    Name: 'Driver, HGV'
  },
  {
    Id: 27781,
    Code: '8211',
    Name: 'Driver, HGV/LGV'
  },
  {
    Id: 27782,
    Code: '8211',
    Name: 'Driver, LGV'
  },
  {
    Id: 27783,
    Code: '8211',
    Name: 'Driver, LGV/HGV'
  },
  {
    Id: 27784,
    Code: '8211',
    Name: 'Driver, library, mobile'
  },
  {
    Id: 27785,
    Code: '8211',
    Name: 'Driver, loader, low'
  },
  {
    Id: 27786,
    Code: '8211',
    Name: 'Driver, loader'
  },
  {
    Id: 27787,
    Code: '8211',
    Name: 'Driver, lorry'
  },
  {
    Id: 27788,
    Code: '8211',
    Name: 'Driver, low-loader'
  },
  {
    Id: 27789,
    Code: '8211',
    Name: 'Driver, mixer, concrete'
  },
  {
    Id: 27790,
    Code: '8211',
    Name: 'Driver, multi-drop, HGV'
  },
  {
    Id: 27791,
    Code: '8211',
    Name: 'Driver, pump, concrete'
  },
  {
    Id: 27792,
    Code: '8211',
    Name: 'Driver, refuse'
  },
  {
    Id: 27793,
    Code: '8211',
    Name: 'Driver, removal'
  },
  {
    Id: 27794,
    Code: '8211',
    Name: 'Driver, rolley'
  },
  {
    Id: 27795,
    Code: '8211',
    Name: 'Driver, rolly'
  },
  {
    Id: 27796,
    Code: '8211',
    Name: 'Driver, scammell'
  },
  {
    Id: 27797,
    Code: '8211',
    Name: 'Driver, shunter, road transport'
  },
  {
    Id: 27798,
    Code: '8211',
    Name: 'Driver, skip'
  },
  {
    Id: 27799,
    Code: '8211',
    Name: 'Driver, tanker'
  },
  {
    Id: 27800,
    Code: '8211',
    Name: 'Driver, tipper'
  },
  {
    Id: 27801,
    Code: '8211',
    Name: 'Driver, tractor, road transport'
  },
  {
    Id: 27802,
    Code: '8211',
    Name: 'Driver, transport'
  },
  {
    Id: 27803,
    Code: '8211',
    Name: 'Driver, transporter'
  },
  {
    Id: 27804,
    Code: '8211',
    Name: 'Driver, truck, road transport'
  },
  {
    Id: 27805,
    Code: '8211',
    Name: 'Driver, vehicle, articulated'
  },
  {
    Id: 27806,
    Code: '8211',
    Name: 'Driver, vehicle, goods, large'
  },
  {
    Id: 27807,
    Code: '8211',
    Name: 'Driver, vehicle, motor'
  },
  {
    Id: 27808,
    Code: '8211',
    Name: 'Driver, wagon'
  },
  {
    Id: 27809,
    Code: '8211',
    Name: 'Driver, articulated lorry'
  },
  {
    Id: 27810,
    Code: '8211',
    Name: 'Driver, vehicles, heavy goods transport'
  },
  {
    Id: 27811,
    Code: '8211',
    Name: 'Driver, waste disposal and environmental services'
  },
  {
    Id: 27812,
    Code: '8211',
    Name: 'Driver-fitter'
  },
  {
    Id: 27813,
    Code: '8211',
    Name: 'Driver-mechanic'
  },
  {
    Id: 27814,
    Code: '8211',
    Name: 'Hand, haulage, haulage contractor'
  },
  {
    Id: 27815,
    Code: '8211',
    Name: 'Hauler, coal, retail trade'
  },
  {
    Id: 27816,
    Code: '8211',
    Name: 'Hauler, timber'
  },
  {
    Id: 27817,
    Code: '8211',
    Name: 'Haulier, general'
  },
  {
    Id: 27818,
    Code: '8211',
    Name: 'Haulier, timber'
  },
  {
    Id: 27819,
    Code: '8211',
    Name: 'Haulier'
  },
  {
    Id: 27820,
    Code: '8211',
    Name: 'Loader-driver, HGV/LGV'
  },
  {
    Id: 27821,
    Code: '8211',
    Name: 'Man, coal, coal merchants'
  },
  {
    Id: 27822,
    Code: '8211',
    Name: 'Operator, transport'
  },
  {
    Id: 27823,
    Code: '8211',
    Name: 'Owner, HGV/LGV'
  },
  {
    Id: 27824,
    Code: '8211',
    Name: 'Owner-driver, haulage contractor'
  },
  {
    Id: 27825,
    Code: '8211',
    Name: 'Remover, cattle'
  },
  {
    Id: 27826,
    Code: '8211',
    Name: 'Roundsman, coal delivery'
  },
  {
    Id: 27827,
    Code: '8211',
    Name: 'Rullyman'
  },
  {
    Id: 27828,
    Code: '8211',
    Name: 'Shunter, HGV'
  },
  {
    Id: 27829,
    Code: '8211',
    Name: 'Transporter, cattle'
  },
  {
    Id: 27830,
    Code: '8211',
    Name: 'Transporter, livestock'
  },
  {
    Id: 27831,
    Code: '8211',
    Name: 'Truckman, road transport'
  },
  {
    Id: 27832,
    Code: '8212',
    Name: 'Busman'
  },
  {
    Id: 27833,
    Code: '8212',
    Name: 'Driver, bus, mini'
  },
  {
    Id: 27834,
    Code: '8212',
    Name: 'Driver, bus'
  },
  {
    Id: 27835,
    Code: '8212',
    Name: 'Driver, coach'
  },
  {
    Id: 27836,
    Code: '8212',
    Name: 'Driver, minibus'
  },
  {
    Id: 27837,
    Code: '8212',
    Name: 'Driver, PCV'
  },
  {
    Id: 27838,
    Code: '8212',
    Name: 'Driver, PSV'
  },
  {
    Id: 27839,
    Code: '8212',
    Name: 'Driver, vehicle, carrying, passenger'
  },
  {
    Id: 27840,
    Code: '8212',
    Name: 'Driver, vehicle, service, public'
  },
  {
    Id: 27841,
    Code: '8212',
    Name: 'Driver, vehicles, passenger transport, bus, coach'
  },
  {
    Id: 27842,
    Code: '8212',
    Name: 'Driver, bus service'
  },
  {
    Id: 27843,
    Code: '8212',
    Name: 'Driver-conductor'
  },
  {
    Id: 27844,
    Code: '8212',
    Name: 'Driver-fitter, public service vehicle'
  },
  {
    Id: 27845,
    Code: '8212',
    Name: 'Driver-mechanic, bus, coach'
  },
  {
    Id: 27846,
    Code: '8212',
    Name: 'Operator, bus'
  },
  {
    Id: 27847,
    Code: '8212',
    Name: 'Operator, coach'
  },
  {
    Id: 27848,
    Code: '8212',
    Name: 'Operator, person, one'
  },
  {
    Id: 27849,
    Code: '8212',
    Name: 'Shunter, bus'
  },
  {
    Id: 27850,
    Code: '8212',
    Name: 'Shunter, passenger road transport'
  },
  {
    Id: 27851,
    Code: '8213',
    Name: 'Cabbie'
  },
  {
    Id: 27852,
    Code: '8213',
    Name: 'Chauffeur'
  },
  {
    Id: 27853,
    Code: '8213',
    Name: 'Chauffeur-gardener'
  },
  {
    Id: 27854,
    Code: '8213',
    Name: 'Chauffeur-handyman'
  },
  {
    Id: 27855,
    Code: '8213',
    Name: 'Chauffeur-mechanic'
  },
  {
    Id: 27856,
    Code: '8213',
    Name: 'Chauffeur-valet'
  },
  {
    Id: 27857,
    Code: '8213',
    Name: 'Driver, cab, black'
  },
  {
    Id: 27858,
    Code: '8213',
    Name: 'Driver, cab, mini'
  },
  {
    Id: 27859,
    Code: '8213',
    Name: 'Driver, cab'
  },
  {
    Id: 27860,
    Code: '8213',
    Name: 'Driver, car, motor'
  },
  {
    Id: 27861,
    Code: '8213',
    Name: 'Driver, carriage'
  },
  {
    Id: 27862,
    Code: '8213',
    Name: 'Driver, hearse'
  },
  {
    Id: 27863,
    Code: '8213',
    Name: 'Driver, hire, private'
  },
  {
    Id: 27864,
    Code: '8213',
    Name: 'Driver, minicab'
  },
  {
    Id: 27865,
    Code: '8213',
    Name: 'Driver, motor, funeral directors'
  },
  {
    Id: 27866,
    Code: '8213',
    Name: 'Driver, PCO'
  },
  {
    Id: 27867,
    Code: '8213',
    Name: 'Driver, police, civilian'
  },
  {
    Id: 27868,
    Code: '8213',
    Name: 'Driver, taxi'
  },
  {
    Id: 27869,
    Code: '8213',
    Name: 'Driver, taxicab'
  },
  {
    Id: 27870,
    Code: '8213',
    Name: 'Driver, uber'
  },
  {
    Id: 27871,
    Code: '8213',
    Name: 'Driver, vehicles, passenger transport'
  },
  {
    Id: 27872,
    Code: '8213',
    Name: 'Driver and collector, coal mine'
  },
  {
    Id: 27873,
    Code: '8213',
    Name: 'Driver-handyman'
  },
  {
    Id: 27874,
    Code: '8213',
    Name: 'Driver-mechanic, passenger transport vehicles'
  },
  {
    Id: 27875,
    Code: '8213',
    Name: 'Gardener-chauffeur'
  },
  {
    Id: 27876,
    Code: '8213',
    Name: 'Handyman-driver'
  },
  {
    Id: 27877,
    Code: '8213',
    Name: 'Owner, carriage, hackney'
  },
  {
    Id: 27878,
    Code: '8213',
    Name: 'Owner, taxi'
  },
  {
    Id: 27879,
    Code: '8213',
    Name: 'Owner-driver, taxi'
  },
  {
    Id: 27880,
    Code: '8213',
    Name: 'Owner-driver, taxi service'
  },
  {
    Id: 27881,
    Code: '8213',
    Name: 'Shunter, car'
  },
  {
    Id: 27882,
    Code: '8214',
    Name: 'Agent, delivery'
  },
  {
    Id: 27883,
    Code: '8214',
    Name: 'Assistant, delivery, customer'
  },
  {
    Id: 27884,
    Code: '8214',
    Name: 'Assistant, fulfilment, customer'
  },
  {
    Id: 27885,
    Code: '8214',
    Name: 'CSTM, delivery service'
  },
  {
    Id: 27886,
    Code: '8214',
    Name: 'Carman'
  },
  {
    Id: 27887,
    Code: '8214',
    Name: 'Collector, car'
  },
  {
    Id: 27888,
    Code: '8214',
    Name: 'Contractor, haulage, light'
  },
  {
    Id: 27889,
    Code: '8214',
    Name: 'Courier, bank'
  },
  {
    Id: 27890,
    Code: '8214',
    Name: 'Courier, cycle'
  },
  {
    Id: 27891,
    Code: '8214',
    Name: 'Courier, delivery'
  },
  {
    Id: 27892,
    Code: '8214',
    Name: 'Courier, food, fast'
  },
  {
    Id: 27893,
    Code: '8214',
    Name: 'Courier, medical'
  },
  {
    Id: 27894,
    Code: '8214',
    Name: 'Courier, parcel delivery service'
  },
  {
    Id: 27895,
    Code: '8214',
    Name: 'Courier'
  },
  {
    Id: 27896,
    Code: '8214',
    Name: 'Courier-driver'
  },
  {
    Id: 27897,
    Code: '8214',
    Name: 'Deliverer, book'
  },
  {
    Id: 27898,
    Code: '8214',
    Name: 'Deliverer, car'
  },
  {
    Id: 27899,
    Code: '8214',
    Name: 'Deliverer, parcel'
  },
  {
    Id: 27900,
    Code: '8214',
    Name: 'Deliverer, fast food'
  },
  {
    Id: 27901,
    Code: '8214',
    Name: 'Deliveryman, newsagents'
  },
  {
    Id: 27902,
    Code: '8214',
    Name: 'Deliveryman'
  },
  {
    Id: 27903,
    Code: '8214',
    Name: 'Driver, car, package'
  },
  {
    Id: 27904,
    Code: '8214',
    Name: 'Driver, cash in transit'
  },
  {
    Id: 27905,
    Code: '8214',
    Name: 'Driver, CIT'
  },
  {
    Id: 27906,
    Code: '8214',
    Name: 'Driver, courier'
  },
  {
    Id: 27907,
    Code: '8214',
    Name: 'Driver, deliveroo'
  },
  {
    Id: 27908,
    Code: '8214',
    Name: 'Driver, delivery, car'
  },
  {
    Id: 27909,
    Code: '8214',
    Name: 'Driver, delivery, customer'
  },
  {
    Id: 27910,
    Code: '8214',
    Name: 'Driver, delivery, drop, multi'
  },
  {
    Id: 27911,
    Code: '8214',
    Name: 'Driver, delivery, food'
  },
  {
    Id: 27912,
    Code: '8214',
    Name: 'Driver, delivery, home'
  },
  {
    Id: 27913,
    Code: '8214',
    Name: 'Driver, delivery, newspaper'
  },
  {
    Id: 27914,
    Code: '8214',
    Name: 'Driver, delivery, online'
  },
  {
    Id: 27915,
    Code: '8214',
    Name: 'Driver, delivery, parcel'
  },
  {
    Id: 27916,
    Code: '8214',
    Name: 'Driver, delivery, parts'
  },
  {
    Id: 27917,
    Code: '8214',
    Name: 'Driver, delivery, pharmacy'
  },
  {
    Id: 27918,
    Code: '8214',
    Name: 'Driver, delivery, shopping, home'
  },
  {
    Id: 27919,
    Code: '8214',
    Name: 'Driver, delivery, car delivery service'
  },
  {
    Id: 27920,
    Code: '8214',
    Name: 'Driver, delivery'
  },
  {
    Id: 27921,
    Code: '8214',
    Name: 'Driver, despatch'
  },
  {
    Id: 27922,
    Code: '8214',
    Name: 'Driver, drop, multi'
  },
  {
    Id: 27923,
    Code: '8214',
    Name: 'Driver, mail, motor'
  },
  {
    Id: 27924,
    Code: '8214',
    Name: 'Driver, meals on wheels'
  },
  {
    Id: 27925,
    Code: '8214',
    Name: 'Driver, multi-drop'
  },
  {
    Id: 27926,
    Code: '8214',
    Name: 'Driver, online'
  },
  {
    Id: 27927,
    Code: '8214',
    Name: 'Driver, parts'
  },
  {
    Id: 27928,
    Code: '8214',
    Name: 'Driver, pharmacy'
  },
  {
    Id: 27929,
    Code: '8214',
    Name: 'Driver, plate, trade'
  },
  {
    Id: 27930,
    Code: '8214',
    Name: 'Driver, security'
  },
  {
    Id: 27931,
    Code: '8214',
    Name: 'Driver, shopping, home'
  },
  {
    Id: 27932,
    Code: '8214',
    Name: 'Driver, uber, fast food'
  },
  {
    Id: 27933,
    Code: '8214',
    Name: 'Driver, van, delivery'
  },
  {
    Id: 27934,
    Code: '8214',
    Name: 'Driver, van'
  },
  {
    Id: 27935,
    Code: '8214',
    Name: 'Driver, fast food'
  },
  {
    Id: 27936,
    Code: '8214',
    Name: 'Driver, car delivery service'
  },
  {
    Id: 27937,
    Code: '8214',
    Name: 'Driver, parcel delivery service'
  },
  {
    Id: 27938,
    Code: '8214',
    Name: 'Driver and collector, car delivery service'
  },
  {
    Id: 27939,
    Code: '8214',
    Name: 'Driver-courier'
  },
  {
    Id: 27940,
    Code: '8214',
    Name: 'Member, team, service, customer, delivery service'
  },
  {
    Id: 27941,
    Code: '8214',
    Name: 'Rider, deliveroo'
  },
  {
    Id: 27942,
    Code: '8214',
    Name: 'Rider, delivery'
  },
  {
    Id: 27943,
    Code: '8214',
    Name: 'Rider, delivery service'
  },
  {
    Id: 27944,
    Code: '8215',
    Name: 'Driver-instructor'
  },
  {
    Id: 27945,
    Code: '8215',
    Name: 'Instructor, cycle, motor'
  },
  {
    Id: 27946,
    Code: '8215',
    Name: 'Instructor, cycle'
  },
  {
    Id: 27947,
    Code: '8215',
    Name: 'Instructor, cycling'
  },
  {
    Id: 27948,
    Code: '8215',
    Name: 'Instructor, driving, advanced'
  },
  {
    Id: 27949,
    Code: '8215',
    Name: 'Instructor, driving, approved'
  },
  {
    Id: 27950,
    Code: '8215',
    Name: 'Instructor, driving'
  },
  {
    Id: 27951,
    Code: '8215',
    Name: 'Instructor, HGV'
  },
  {
    Id: 27952,
    Code: '8215',
    Name: 'Instructor, LGV'
  },
  {
    Id: 27953,
    Code: '8215',
    Name: 'Instructor, lift, fork'
  },
  {
    Id: 27954,
    Code: '8215',
    Name: 'Instructor, motorcycle'
  },
  {
    Id: 27955,
    Code: '8215',
    Name: 'Instructor, operator, plant, mobile'
  },
  {
    Id: 27956,
    Code: '8215',
    Name: 'Instructor, truck, lift, fork'
  },
  {
    Id: 27957,
    Code: '8215',
    Name: 'Instructor, driving school'
  },
  {
    Id: 27958,
    Code: '8215',
    Name: 'Trainer, driver'
  },
  {
    Id: 27959,
    Code: '8215',
    Name: 'Trainer, lift, fork'
  },
  {
    Id: 27960,
    Code: '8215',
    Name: 'Trainer, truck, lift, fork'
  },
  {
    Id: 27961,
    Code: '8219',
    Name: 'Collector, bag, charity'
  },
  {
    Id: 27962,
    Code: '8219',
    Name: 'Collector, egg'
  },
  {
    Id: 27963,
    Code: '8219',
    Name: 'Collector, sack'
  },
  {
    Id: 27964,
    Code: '8219',
    Name: 'Collector, stock, charitable organisation'
  },
  {
    Id: 27965,
    Code: '8219',
    Name: 'Driver, motor, railway'
  },
  {
    Id: 27966,
    Code: '8219',
    Name: 'Driver, motor'
  },
  {
    Id: 27967,
    Code: '8219',
    Name: 'Driver, recovery'
  },
  {
    Id: 27968,
    Code: '8219',
    Name: 'Driver, test, vehicle mfr'
  },
  {
    Id: 27969,
    Code: '8219',
    Name: 'Driver, truck, recovery'
  },
  {
    Id: 27970,
    Code: '8219',
    Name: 'Driver, vehicle, recovery'
  },
  {
    Id: 27971,
    Code: '8219',
    Name: 'Driver, warehouse'
  },
  {
    Id: 27972,
    Code: '8219',
    Name: 'Driver'
  },
  {
    Id: 27973,
    Code: '8219',
    Name: 'Driver-cleaner'
  },
  {
    Id: 27974,
    Code: '8219',
    Name: 'Driver-custodian, security services'
  },
  {
    Id: 27975,
    Code: '8219',
    Name: 'Driver-labourer'
  },
  {
    Id: 27976,
    Code: '8219',
    Name: 'Driver-packer'
  },
  {
    Id: 27977,
    Code: '8219',
    Name: 'Driver-storeman'
  },
  {
    Id: 27978,
    Code: '8219',
    Name: 'Driver-valeter'
  },
  {
    Id: 27979,
    Code: '8219',
    Name: 'Driver-warehouseman'
  },
  {
    Id: 27980,
    Code: '8219',
    Name: 'Leader, coal'
  },
  {
    Id: 27981,
    Code: '8219',
    Name: 'Operator, recovery, vehicles'
  },
  {
    Id: 27982,
    Code: '8219',
    Name: 'Packer-driver'
  },
  {
    Id: 27983,
    Code: '8219',
    Name: 'Porter-driver'
  },
  {
    Id: 27984,
    Code: '8219',
    Name: 'Storeman-driver'
  },
  {
    Id: 27985,
    Code: '8219',
    Name: 'Warehouseman-driver'
  },
  {
    Id: 27986,
    Code: '8221',
    Name: 'Attendant, cage'
  },
  {
    Id: 27987,
    Code: '8221',
    Name: 'Attendant, engine, winding'
  },
  {
    Id: 27988,
    Code: '8221',
    Name: 'Attendant, hoist'
  },
  {
    Id: 27989,
    Code: '8221',
    Name: 'Attendant, mouth, drift, coal mine'
  },
  {
    Id: 27990,
    Code: '8221',
    Name: 'Attendant, winch'
  },
  {
    Id: 27991,
    Code: '8221',
    Name: 'Banker, mine: not coal'
  },
  {
    Id: 27992,
    Code: '8221',
    Name: 'Banksman, pit, staple'
  },
  {
    Id: 27993,
    Code: '8221',
    Name: 'Banksman, coal mine'
  },
  {
    Id: 27994,
    Code: '8221',
    Name: 'Banksman, mine: not coal'
  },
  {
    Id: 27995,
    Code: '8221',
    Name: 'Banksman, salt works'
  },
  {
    Id: 27996,
    Code: '8221',
    Name: 'Cageman, mine: not coal'
  },
  {
    Id: 27997,
    Code: '8221',
    Name: 'Corporal, underground'
  },
  {
    Id: 27998,
    Code: '8221',
    Name: 'Driver, carrier, straddle'
  },
  {
    Id: 27999,
    Code: '8221',
    Name: 'Driver, crane'
  },
  {
    Id: 28000,
    Code: '8221',
    Name: 'Driver, derrick'
  },
  {
    Id: 28001,
    Code: '8221',
    Name: 'Driver, drum, steelworks'
  },
  {
    Id: 28002,
    Code: '8221',
    Name: 'Driver, elevator'
  },
  {
    Id: 28003,
    Code: '8221',
    Name: 'Driver, engine, cable'
  },
  {
    Id: 28004,
    Code: '8221',
    Name: 'Driver, engine, haulage'
  },
  {
    Id: 28005,
    Code: '8221',
    Name: 'Driver, engine, winding'
  },
  {
    Id: 28006,
    Code: '8221',
    Name: 'Driver, gantry'
  },
  {
    Id: 28007,
    Code: '8221',
    Name: 'Driver, grab'
  },
  {
    Id: 28008,
    Code: '8221',
    Name: 'Driver, haulage, mining'
  },
  {
    Id: 28009,
    Code: '8221',
    Name: 'Driver, hauler, coal mine'
  },
  {
    Id: 28010,
    Code: '8221',
    Name: 'Driver, hoist'
  },
  {
    Id: 28011,
    Code: '8221',
    Name: 'Driver, incline'
  },
  {
    Id: 28012,
    Code: '8221',
    Name: 'Driver, lift'
  },
  {
    Id: 28013,
    Code: '8221',
    Name: 'Driver, magnet, steelworks'
  },
  {
    Id: 28014,
    Code: '8221',
    Name: 'Driver, mail, paddy, coal mine'
  },
  {
    Id: 28015,
    Code: '8221',
    Name: 'Driver, motor, telpher'
  },
  {
    Id: 28016,
    Code: '8221',
    Name: 'Driver, paddy, coal mine'
  },
  {
    Id: 28017,
    Code: '8221',
    Name: 'Driver, skip, blast furnace'
  },
  {
    Id: 28018,
    Code: '8221',
    Name: 'Driver, tandem, coal mine'
  },
  {
    Id: 28019,
    Code: '8221',
    Name: 'Driver, tip'
  },
  {
    Id: 28020,
    Code: '8221',
    Name: 'Driver, winch'
  },
  {
    Id: 28021,
    Code: '8221',
    Name: 'Engineer, winding, coal mine'
  },
  {
    Id: 28022,
    Code: '8221',
    Name: 'Engineman, donkey, coal mine'
  },
  {
    Id: 28023,
    Code: '8221',
    Name: 'Engineman, haulage'
  },
  {
    Id: 28024,
    Code: '8221',
    Name: 'Engineman, winding'
  },
  {
    Id: 28025,
    Code: '8221',
    Name: 'Foreman, haulage, coal mine'
  },
  {
    Id: 28026,
    Code: '8221',
    Name: 'Hand, bank, coal mine'
  },
  {
    Id: 28027,
    Code: '8221',
    Name: 'Hand, bottom, coal mine'
  },
  {
    Id: 28028,
    Code: '8221',
    Name: 'Hand, cage, coal mine'
  },
  {
    Id: 28029,
    Code: '8221',
    Name: 'Hand, capstan, railways'
  },
  {
    Id: 28030,
    Code: '8221',
    Name: 'Hoister, crane'
  },
  {
    Id: 28031,
    Code: '8221',
    Name: 'Hoistman'
  },
  {
    Id: 28032,
    Code: '8221',
    Name: 'Lander, mine: not coal: below ground'
  },
  {
    Id: 28033,
    Code: '8221',
    Name: 'Lifter, steelworks'
  },
  {
    Id: 28034,
    Code: '8221',
    Name: 'Liftman, gantry'
  },
  {
    Id: 28035,
    Code: '8221',
    Name: 'Liftman, steam'
  },
  {
    Id: 28036,
    Code: '8221',
    Name: 'Liftman, iron and steelworks'
  },
  {
    Id: 28037,
    Code: '8221',
    Name: 'Man, capstan'
  },
  {
    Id: 28038,
    Code: '8221',
    Name: 'Man, crane'
  },
  {
    Id: 28039,
    Code: '8221',
    Name: 'Man, gantry, forge, steelworks'
  },
  {
    Id: 28040,
    Code: '8221',
    Name: 'Man, gantry'
  },
  {
    Id: 28041,
    Code: '8221',
    Name: 'Man, motor, haulage'
  },
  {
    Id: 28042,
    Code: '8221',
    Name: 'Man, motor, screen'
  },
  {
    Id: 28043,
    Code: '8221',
    Name: 'Man, runner, steelworks'
  },
  {
    Id: 28044,
    Code: '8221',
    Name: 'Onsetter, pit, staple, coal mine'
  },
  {
    Id: 28045,
    Code: '8221',
    Name: 'Onsetter'
  },
  {
    Id: 28046,
    Code: '8221',
    Name: 'Operator, bridge, coal mine'
  },
  {
    Id: 28047,
    Code: '8221',
    Name: 'Operator, crane'
  },
  {
    Id: 28048,
    Code: '8221',
    Name: 'Operator, elevator'
  },
  {
    Id: 28049,
    Code: '8221',
    Name: 'Operator, engine, winding'
  },
  {
    Id: 28050,
    Code: '8221',
    Name: 'Operator, hoist'
  },
  {
    Id: 28051,
    Code: '8221',
    Name: 'Operator, winch'
  },
  {
    Id: 28052,
    Code: '8221',
    Name: 'Runner, steelworks'
  },
  {
    Id: 28053,
    Code: '8221',
    Name: 'Signaller, mine: not coal'
  },
  {
    Id: 28054,
    Code: '8221',
    Name: 'Signalman, mining'
  },
  {
    Id: 28055,
    Code: '8221',
    Name: 'Spillager, coal mine'
  },
  {
    Id: 28056,
    Code: '8221',
    Name: 'Tenter, crane'
  },
  {
    Id: 28057,
    Code: '8221',
    Name: 'Tenter, hoist'
  },
  {
    Id: 28058,
    Code: '8221',
    Name: 'Wagoner, coal mine'
  },
  {
    Id: 28059,
    Code: '8221',
    Name: 'Winchman'
  },
  {
    Id: 28060,
    Code: '8221',
    Name: 'Winder, cage, coal mine'
  },
  {
    Id: 28061,
    Code: '8221',
    Name: 'Winder, engine, mining'
  },
  {
    Id: 28062,
    Code: '8221',
    Name: 'Winder, incline'
  },
  {
    Id: 28063,
    Code: '8221',
    Name: 'Winder, pit, staple'
  },
  {
    Id: 28064,
    Code: '8221',
    Name: 'Winder, skip, coal mine'
  },
  {
    Id: 28065,
    Code: '8221',
    Name: 'Winder, mining'
  },
  {
    Id: 28066,
    Code: '8221',
    Name: 'Winderman, coal mine'
  },
  {
    Id: 28067,
    Code: '8222',
    Name: 'Driver, car, scale'
  },
  {
    Id: 28068,
    Code: '8222',
    Name: 'Driver, car, weigh'
  },
  {
    Id: 28069,
    Code: '8222',
    Name: 'Driver, FLT'
  },
  {
    Id: 28070,
    Code: '8222',
    Name: 'Driver, forklift'
  },
  {
    Id: 28071,
    Code: '8222',
    Name: 'Driver, hyster'
  },
  {
    Id: 28072,
    Code: '8222',
    Name: 'Driver, internal'
  },
  {
    Id: 28073,
    Code: '8222',
    Name: 'Driver, lift, fork'
  },
  {
    Id: 28074,
    Code: '8222',
    Name: 'Driver, loader, side'
  },
  {
    Id: 28075,
    Code: '8222',
    Name: 'Driver, stacker'
  },
  {
    Id: 28076,
    Code: '8222',
    Name: 'Driver, transport, internal transport'
  },
  {
    Id: 28077,
    Code: '8222',
    Name: 'Driver, truck, clamp'
  },
  {
    Id: 28078,
    Code: '8222',
    Name: 'Driver, truck, electric'
  },
  {
    Id: 28079,
    Code: '8222',
    Name: 'Driver, truck, fork'
  },
  {
    Id: 28080,
    Code: '8222',
    Name: 'Driver, truck, forklift'
  },
  {
    Id: 28081,
    Code: '8222',
    Name: 'Driver, truck, lift, fork'
  },
  {
    Id: 28082,
    Code: '8222',
    Name: 'Driver, truck, power'
  },
  {
    Id: 28083,
    Code: '8222',
    Name: 'Driver, truck, stacker'
  },
  {
    Id: 28084,
    Code: '8222',
    Name: 'Driver, truck, works'
  },
  {
    Id: 28085,
    Code: '8222',
    Name: 'Driver, truck'
  },
  {
    Id: 28086,
    Code: '8222',
    Name: 'Driver, fork-lift'
  },
  {
    Id: 28087,
    Code: '8222',
    Name: 'Driver, works trucks'
  },
  {
    Id: 28088,
    Code: '8222',
    Name: 'Hyster'
  },
  {
    Id: 28089,
    Code: '8222',
    Name: 'Lifter, fork'
  },
  {
    Id: 28090,
    Code: '8222',
    Name: 'Operator, forklift'
  },
  {
    Id: 28091,
    Code: '8222',
    Name: 'Operator, lift, fork'
  },
  {
    Id: 28092,
    Code: '8222',
    Name: 'Operator, loader, side'
  },
  {
    Id: 28093,
    Code: '8222',
    Name: 'Operator, truck, fork'
  },
  {
    Id: 28094,
    Code: '8222',
    Name: 'Operator, truck, forklift'
  },
  {
    Id: 28095,
    Code: '8222',
    Name: 'Operator, truck, lift, fork'
  },
  {
    Id: 28096,
    Code: '8222',
    Name: 'Stacker-driver'
  },
  {
    Id: 28097,
    Code: '8229',
    Name: 'Attendant, agricultural machinery'
  },
  {
    Id: 28098,
    Code: '8229',
    Name: 'Baler, hay'
  },
  {
    Id: 28099,
    Code: '8229',
    Name: 'Binder, straw'
  },
  {
    Id: 28100,
    Code: '8229',
    Name: 'Carman, coke ovens'
  },
  {
    Id: 28101,
    Code: '8229',
    Name: 'Chargeman, copper, zinc refining'
  },
  {
    Id: 28102,
    Code: '8229',
    Name: 'Charger, coal'
  },
  {
    Id: 28103,
    Code: '8229',
    Name: 'Charger, ore'
  },
  {
    Id: 28104,
    Code: '8229',
    Name: 'Charger, oven'
  },
  {
    Id: 28105,
    Code: '8229',
    Name: 'Charger, retort, gas works'
  },
  {
    Id: 28106,
    Code: '8229',
    Name: 'Charger, coke ovens'
  },
  {
    Id: 28107,
    Code: '8229',
    Name: 'Charger, gas works'
  },
  {
    Id: 28108,
    Code: '8229',
    Name: 'Charger, mine: not coal'
  },
  {
    Id: 28109,
    Code: '8229',
    Name: 'Chargerman'
  },
  {
    Id: 28110,
    Code: '8229',
    Name: 'Contractor, spraying, crop'
  },
  {
    Id: 28111,
    Code: '8229',
    Name: 'Dredgeman'
  },
  {
    Id: 28112,
    Code: '8229',
    Name: 'Dredger'
  },
  {
    Id: 28113,
    Code: '8229',
    Name: 'Dredgerman'
  },
  {
    Id: 28114,
    Code: '8229',
    Name: 'Dredgermaster'
  },
  {
    Id: 28115,
    Code: '8229',
    Name: 'Driver, bobcat'
  },
  {
    Id: 28116,
    Code: '8229',
    Name: 'Driver, bogie'
  },
  {
    Id: 28117,
    Code: '8229',
    Name: 'Driver, bowser, water'
  },
  {
    Id: 28118,
    Code: '8229',
    Name: 'Driver, bulldozer'
  },
  {
    Id: 28119,
    Code: '8229',
    Name: 'Driver, car, charger'
  },
  {
    Id: 28120,
    Code: '8229',
    Name: 'Driver, car, coke, gas ovens'
  },
  {
    Id: 28121,
    Code: '8229',
    Name: 'Driver, charge'
  },
  {
    Id: 28122,
    Code: '8229',
    Name: 'Driver, charger'
  },
  {
    Id: 28123,
    Code: '8229',
    Name: 'Driver, crawler'
  },
  {
    Id: 28124,
    Code: '8229',
    Name: 'Driver, digger'
  },
  {
    Id: 28125,
    Code: '8229',
    Name: 'Driver, dozer, angle'
  },
  {
    Id: 28126,
    Code: '8229',
    Name: 'Driver, dredger'
  },
  {
    Id: 28127,
    Code: '8229',
    Name: 'Driver, drott'
  },
  {
    Id: 28128,
    Code: '8229',
    Name: 'Driver, dumper'
  },
  {
    Id: 28129,
    Code: '8229',
    Name: 'Driver, engine, agriculture'
  },
  {
    Id: 28130,
    Code: '8229',
    Name: 'Driver, euclid'
  },
  {
    Id: 28131,
    Code: '8229',
    Name: 'Driver, excavator'
  },
  {
    Id: 28132,
    Code: '8229',
    Name: 'Driver, gearhead, coal mine'
  },
  {
    Id: 28133,
    Code: '8229',
    Name: 'Driver, grader'
  },
  {
    Id: 28134,
    Code: '8229',
    Name: 'Driver, JCB'
  },
  {
    Id: 28135,
    Code: '8229',
    Name: 'Driver, loader, building construction'
  },
  {
    Id: 28136,
    Code: '8229',
    Name: 'Driver, machine, spreading, asphalt, concrete'
  },
  {
    Id: 28137,
    Code: '8229',
    Name: 'Driver, machine, stoking'
  },
  {
    Id: 28138,
    Code: '8229',
    Name: 'Driver, machine, agriculture'
  },
  {
    Id: 28139,
    Code: '8229',
    Name: 'Driver, machine, civil engineering'
  },
  {
    Id: 28140,
    Code: '8229',
    Name: 'Driver, machine, gas works'
  },
  {
    Id: 28141,
    Code: '8229',
    Name: 'Driver, motor, dumpy'
  },
  {
    Id: 28142,
    Code: '8229',
    Name: 'Driver, navvy'
  },
  {
    Id: 28143,
    Code: '8229',
    Name: 'Driver, pile'
  },
  {
    Id: 28144,
    Code: '8229',
    Name: 'Driver, plant, heavy'
  },
  {
    Id: 28145,
    Code: '8229',
    Name: 'Driver, plant, building construction'
  },
  {
    Id: 28146,
    Code: '8229',
    Name: 'Driver, roller'
  },
  {
    Id: 28147,
    Code: '8229',
    Name: 'Driver, scoop'
  },
  {
    Id: 28148,
    Code: '8229',
    Name: 'Driver, scraper'
  },
  {
    Id: 28149,
    Code: '8229',
    Name: 'Driver, sentinel'
  },
  {
    Id: 28150,
    Code: '8229',
    Name: 'Driver, shovel'
  },
  {
    Id: 28151,
    Code: '8229',
    Name: 'Driver, steamroller'
  },
  {
    Id: 28152,
    Code: '8229',
    Name: 'Driver, sweeper, road, mechanical'
  },
  {
    Id: 28153,
    Code: '8229',
    Name: 'Driver, sweeper'
  },
  {
    Id: 28154,
    Code: '8229',
    Name: 'Driver, telehandler'
  },
  {
    Id: 28155,
    Code: '8229',
    Name: 'Driver, tool, mechanical'
  },
  {
    Id: 28156,
    Code: '8229',
    Name: 'Driver, tractor, agriculture'
  },
  {
    Id: 28157,
    Code: '8229',
    Name: 'Driver, tractor, building construction'
  },
  {
    Id: 28158,
    Code: '8229',
    Name: 'Driver, tractor, coal mine: opencast'
  },
  {
    Id: 28159,
    Code: '8229',
    Name: 'Driver, tractor, local government'
  },
  {
    Id: 28160,
    Code: '8229',
    Name: 'Driver, tractor, manufacturing'
  },
  {
    Id: 28161,
    Code: '8229',
    Name: 'Driver, tractor, mining'
  },
  {
    Id: 28162,
    Code: '8229',
    Name: 'Driver, trolley'
  },
  {
    Id: 28163,
    Code: '8229',
    Name: 'Driver, truck, bogie'
  },
  {
    Id: 28164,
    Code: '8229',
    Name: 'Driver, truck, dumper'
  },
  {
    Id: 28165,
    Code: '8229',
    Name: 'Driver, truck, ransom'
  },
  {
    Id: 28166,
    Code: '8229',
    Name: 'Driver, agricultural machinery'
  },
  {
    Id: 28167,
    Code: '8229',
    Name: 'Driver, building construction'
  },
  {
    Id: 28168,
    Code: '8229',
    Name: 'Driver, mine: not coal: above ground'
  },
  {
    Id: 28169,
    Code: '8229',
    Name: 'Driver, plant hire'
  },
  {
    Id: 28170,
    Code: '8229',
    Name: 'Driver-mechanic, agricultural machinery'
  },
  {
    Id: 28171,
    Code: '8229',
    Name: 'Dumper, mine: not coal'
  },
  {
    Id: 28172,
    Code: '8229',
    Name: 'Excavator, building construction'
  },
  {
    Id: 28173,
    Code: '8229',
    Name: 'Excavator, steelworks'
  },
  {
    Id: 28174,
    Code: '8229',
    Name: 'Feeder, drum, agricultural machinery'
  },
  {
    Id: 28175,
    Code: '8229',
    Name: 'Filler, coke ovens'
  },
  {
    Id: 28176,
    Code: '8229',
    Name: 'Foreman, dredging'
  },
  {
    Id: 28177,
    Code: '8229',
    Name: 'Foreman, piling, civil engineering'
  },
  {
    Id: 28178,
    Code: '8229',
    Name: 'Foreman, plant, building construction'
  },
  {
    Id: 28179,
    Code: '8229',
    Name: 'Foreman, agricultural contracting'
  },
  {
    Id: 28180,
    Code: '8229',
    Name: 'Hammerman, pile driving'
  },
  {
    Id: 28181,
    Code: '8229',
    Name: 'Handler, telescopic'
  },
  {
    Id: 28182,
    Code: '8229',
    Name: 'Leveller, asphalt spreading machine'
  },
  {
    Id: 28183,
    Code: '8229',
    Name: 'Machinist, agricultural'
  },
  {
    Id: 28184,
    Code: '8229',
    Name: 'Machinist, chipping'
  },
  {
    Id: 28185,
    Code: '8229',
    Name: 'Machinist, gully'
  },
  {
    Id: 28186,
    Code: '8229',
    Name: 'Machinist, levelling, rail'
  },
  {
    Id: 28187,
    Code: '8229',
    Name: 'Machinist, paving, concrete'
  },
  {
    Id: 28188,
    Code: '8229',
    Name: 'Machinist, tamping'
  },
  {
    Id: 28189,
    Code: '8229',
    Name: 'Machinist, tracklaying'
  },
  {
    Id: 28190,
    Code: '8229',
    Name: 'Machinist, building construction'
  },
  {
    Id: 28191,
    Code: '8229',
    Name: 'Machinist, coal mine: opencast'
  },
  {
    Id: 28192,
    Code: '8229',
    Name: "Machinist, railways: civil engineer's dept"
  },
  {
    Id: 28193,
    Code: '8229',
    Name: 'Man, digger'
  },
  {
    Id: 28194,
    Code: '8229',
    Name: 'Man, navvy'
  },
  {
    Id: 28195,
    Code: '8229',
    Name: 'Man, plate, asphalt spreading'
  },
  {
    Id: 28196,
    Code: '8229',
    Name: 'Man, plough'
  },
  {
    Id: 28197,
    Code: '8229',
    Name: 'Master, dredger'
  },
  {
    Id: 28198,
    Code: '8229',
    Name: 'Master, dredging'
  },
  {
    Id: 28199,
    Code: '8229',
    Name: "Mate, driver's, excavator"
  },
  {
    Id: 28200,
    Code: '8229',
    Name: 'Officer, deck, dredging contractors'
  },
  {
    Id: 28201,
    Code: '8229',
    Name: 'Operative, piling'
  },
  {
    Id: 28202,
    Code: '8229',
    Name: 'Operative, plant, construction'
  },
  {
    Id: 28203,
    Code: '8229',
    Name: 'Operator, 360'
  },
  {
    Id: 28204,
    Code: '8229',
    Name: 'Operator, bulldozer'
  },
  {
    Id: 28205,
    Code: '8229',
    Name: 'Operator, caterpillar'
  },
  {
    Id: 28206,
    Code: '8229',
    Name: 'Operator, charger, rolling mill'
  },
  {
    Id: 28207,
    Code: '8229',
    Name: 'Operator, dredger'
  },
  {
    Id: 28208,
    Code: '8229',
    Name: 'Operator, drill, pneumatic'
  },
  {
    Id: 28209,
    Code: '8229',
    Name: 'Operator, drott'
  },
  {
    Id: 28210,
    Code: '8229',
    Name: 'Operator, excavator'
  },
  {
    Id: 28211,
    Code: '8229',
    Name: 'Operator, hire, plant'
  },
  {
    Id: 28212,
    Code: '8229',
    Name: 'Operator, JCB'
  },
  {
    Id: 28213,
    Code: '8229',
    Name: 'Operator, line, drag'
  },
  {
    Id: 28214,
    Code: '8229',
    Name: 'Operator, machine, plant'
  },
  {
    Id: 28215,
    Code: '8229',
    Name: "Operator, plant, builder's"
  },
  {
    Id: 28216,
    Code: '8229',
    Name: 'Operator, plant, constructional'
  },
  {
    Id: 28217,
    Code: '8229',
    Name: 'Operator, plant, heavy'
  },
  {
    Id: 28218,
    Code: '8229',
    Name: 'Operator, plant, mobile'
  },
  {
    Id: 28219,
    Code: '8229',
    Name: 'Operator, plant, building construction'
  },
  {
    Id: 28220,
    Code: '8229',
    Name: 'Operator, plant, opencast mining'
  },
  {
    Id: 28221,
    Code: '8229',
    Name: 'Operator, plant'
  },
  {
    Id: 28222,
    Code: '8229',
    Name: 'Operator, pump, concrete'
  },
  {
    Id: 28223,
    Code: '8229',
    Name: 'Operator, rig'
  },
  {
    Id: 28224,
    Code: '8229',
    Name: 'Operator, agricultural machinery'
  },
  {
    Id: 28225,
    Code: '8229',
    Name: 'Operator, construction machinery'
  },
  {
    Id: 28226,
    Code: '8229',
    Name: 'Owner-driver, plant hire'
  },
  {
    Id: 28227,
    Code: '8229',
    Name: 'Screwman, asphalt spreading'
  },
  {
    Id: 28228,
    Code: '8229',
    Name: 'Sprayer, agricultural'
  },
  {
    Id: 28229,
    Code: '8229',
    Name: 'Sprayer, tar'
  },
  {
    Id: 28230,
    Code: '8229',
    Name: 'Sprayer, agricultural contracting'
  },
  {
    Id: 28231,
    Code: '8229',
    Name: 'Sweeper, road, mechanical'
  },
  {
    Id: 28232,
    Code: '8229',
    Name: 'Telehandler, operator'
  },
  {
    Id: 28233,
    Code: '8229',
    Name: 'Telehandler'
  },
  {
    Id: 28234,
    Code: '8229',
    Name: 'Topman, pile driving'
  },
  {
    Id: 28235,
    Code: '8229',
    Name: 'Tractorman, agriculture'
  },
  {
    Id: 28236,
    Code: '8229',
    Name: 'Trucker'
  },
  {
    Id: 28237,
    Code: '8229',
    Name: 'Worker, agricultural, agricultural contracting'
  },
  {
    Id: 28238,
    Code: '8231',
    Name: 'Driver, car, rail'
  },
  {
    Id: 28239,
    Code: '8231',
    Name: 'Driver, car, shuttle'
  },
  {
    Id: 28240,
    Code: '8231',
    Name: 'Driver, diesel, railways'
  },
  {
    Id: 28241,
    Code: '8231',
    Name: 'Driver, engine, locomotive'
  },
  {
    Id: 28242,
    Code: '8231',
    Name: 'Driver, engine, railway'
  },
  {
    Id: 28243,
    Code: '8231',
    Name: 'Driver, engine, shunting'
  },
  {
    Id: 28244,
    Code: '8231',
    Name: 'Driver, engine, railways'
  },
  {
    Id: 28245,
    Code: '8231',
    Name: 'Driver, loco'
  },
  {
    Id: 28246,
    Code: '8231',
    Name: 'Driver, locomotive'
  },
  {
    Id: 28247,
    Code: '8231',
    Name: 'Driver, shunter'
  },
  {
    Id: 28248,
    Code: '8231',
    Name: 'Driver, train'
  },
  {
    Id: 28249,
    Code: '8231',
    Name: 'Driver, tram'
  },
  {
    Id: 28250,
    Code: '8231',
    Name: 'Driver, railways'
  },
  {
    Id: 28251,
    Code: '8231',
    Name: 'Engineman, railways'
  },
  {
    Id: 28252,
    Code: '8231',
    Name: 'Operator, train'
  },
  {
    Id: 28253,
    Code: '8232',
    Name: 'AB, shipping'
  },
  {
    Id: 28254,
    Code: '8232',
    Name: 'Attendant, boat'
  },
  {
    Id: 28255,
    Code: '8232',
    Name: 'Attendant, engine, shipping'
  },
  {
    Id: 28256,
    Code: '8232',
    Name: 'Attendant, leg, marine'
  },
  {
    Id: 28257,
    Code: '8232',
    Name: 'Attendant, room, engine, shipping'
  },
  {
    Id: 28258,
    Code: '8232',
    Name: 'Bargee'
  },
  {
    Id: 28259,
    Code: '8232',
    Name: 'Bargeman'
  },
  {
    Id: 28260,
    Code: '8232',
    Name: "Bo'sun"
  },
  {
    Id: 28261,
    Code: '8232',
    Name: 'Boatman, foy'
  },
  {
    Id: 28262,
    Code: '8232',
    Name: 'Boatman'
  },
  {
    Id: 28263,
    Code: '8232',
    Name: 'Boatswain'
  },
  {
    Id: 28264,
    Code: '8232',
    Name: 'Bosun'
  },
  {
    Id: 28265,
    Code: '8232',
    Name: 'Cleaner, engine, shipping'
  },
  {
    Id: 28266,
    Code: '8232',
    Name: 'Cobbleman'
  },
  {
    Id: 28267,
    Code: '8232',
    Name: 'Driver, engine, shipping'
  },
  {
    Id: 28268,
    Code: '8232',
    Name: 'Driver, launch'
  },
  {
    Id: 28269,
    Code: '8232',
    Name: 'Driver, tug'
  },
  {
    Id: 28270,
    Code: '8232',
    Name: 'Driver, shipping'
  },
  {
    Id: 28271,
    Code: '8232',
    Name: 'Electrician, chief, shipping'
  },
  {
    Id: 28272,
    Code: '8232',
    Name: 'Electrician, marine'
  },
  {
    Id: 28273,
    Code: '8232',
    Name: 'Engineer, chief, fishing'
  },
  {
    Id: 28274,
    Code: '8232',
    Name: 'Engineer, dredger'
  },
  {
    Id: 28275,
    Code: '8232',
    Name: 'Engineer, second, boat, barge'
  },
  {
    Id: 28276,
    Code: '8232',
    Name: 'Engineer, second, fishing'
  },
  {
    Id: 28277,
    Code: '8232',
    Name: 'Engineer, third, fishing'
  },
  {
    Id: 28278,
    Code: '8232',
    Name: 'Engineer, nos: boat, barge'
  },
  {
    Id: 28279,
    Code: '8232',
    Name: 'Engineer, nos, fishing'
  },
  {
    Id: 28280,
    Code: '8232',
    Name: 'Engineman, donkey, shipping'
  },
  {
    Id: 28281,
    Code: '8232',
    Name: 'Engineman, shipping'
  },
  {
    Id: 28282,
    Code: '8232',
    Name: 'Ferryman'
  },
  {
    Id: 28283,
    Code: '8232',
    Name: 'Fireman, marine'
  },
  {
    Id: 28284,
    Code: '8232',
    Name: 'Fireman, fishing'
  },
  {
    Id: 28285,
    Code: '8232',
    Name: 'Fireman, shipping'
  },
  {
    Id: 28286,
    Code: '8232',
    Name: 'Fireman and trimmer'
  },
  {
    Id: 28287,
    Code: '8232',
    Name: 'Fireman-greaser, shipping'
  },
  {
    Id: 28288,
    Code: '8232',
    Name: 'Flatman'
  },
  {
    Id: 28289,
    Code: '8232',
    Name: 'Freeman, River Thames'
  },
  {
    Id: 28290,
    Code: '8232',
    Name: 'Fuelman, ship'
  },
  {
    Id: 28291,
    Code: '8232',
    Name: 'Greaser, donkey, shipping'
  },
  {
    Id: 28292,
    Code: '8232',
    Name: 'Greaser, electric, shipping'
  },
  {
    Id: 28293,
    Code: '8232',
    Name: 'Greaser, fan, shipping'
  },
  {
    Id: 28294,
    Code: '8232',
    Name: 'Greaser, refrigerating, shipping'
  },
  {
    Id: 28295,
    Code: '8232',
    Name: 'Greaser, fishing'
  },
  {
    Id: 28296,
    Code: '8232',
    Name: 'Greaser, shipping'
  },
  {
    Id: 28297,
    Code: '8232',
    Name: 'Hand, barge'
  },
  {
    Id: 28298,
    Code: '8232',
    Name: 'Hand, boat'
  },
  {
    Id: 28299,
    Code: '8232',
    Name: 'Hand, deck, shipping'
  },
  {
    Id: 28300,
    Code: '8232',
    Name: 'Hand, launch'
  },
  {
    Id: 28301,
    Code: '8232',
    Name: 'Hand, tug'
  },
  {
    Id: 28302,
    Code: '8232',
    Name: 'Helmsman'
  },
  {
    Id: 28303,
    Code: '8232',
    Name: 'Hirer, boat'
  },
  {
    Id: 28304,
    Code: '8232',
    Name: 'Keeper, boat'
  },
  {
    Id: 28305,
    Code: '8232',
    Name: 'Keeper, store, room, engine'
  },
  {
    Id: 28306,
    Code: '8232',
    Name: 'Lifeboatman'
  },
  {
    Id: 28307,
    Code: '8232',
    Name: 'Lighter, lamp, ships, Trinity House'
  },
  {
    Id: 28308,
    Code: '8232',
    Name: 'Lighterman'
  },
  {
    Id: 28309,
    Code: '8232',
    Name: 'Lightsman, lightship'
  },
  {
    Id: 28310,
    Code: '8232',
    Name: 'Look-out and AB'
  },
  {
    Id: 28311,
    Code: '8232',
    Name: 'Man, launch'
  },
  {
    Id: 28312,
    Code: '8232',
    Name: 'Man, lightship'
  },
  {
    Id: 28313,
    Code: '8232',
    Name: 'Man, motor, shipping'
  },
  {
    Id: 28314,
    Code: '8232',
    Name: 'Man, room, engine, shipping'
  },
  {
    Id: 28315,
    Code: '8232',
    Name: 'Mariner'
  },
  {
    Id: 28316,
    Code: '8232',
    Name: 'Master, derrick'
  },
  {
    Id: 28317,
    Code: '8232',
    Name: 'Master, quarter, shipping'
  },
  {
    Id: 28318,
    Code: '8232',
    Name: 'Master at Arms, shipping'
  },
  {
    Id: 28319,
    Code: '8232',
    Name: "Mate, boatswain's"
  },
  {
    Id: 28320,
    Code: '8232',
    Name: 'Mate, boat, barge'
  },
  {
    Id: 28321,
    Code: '8232',
    Name: 'Mate, docks'
  },
  {
    Id: 28322,
    Code: '8232',
    Name: 'Mate on barge'
  },
  {
    Id: 28323,
    Code: '8232',
    Name: 'Mate-in-charge'
  },
  {
    Id: 28324,
    Code: '8232',
    Name: 'Mechanic, senior, shipping'
  },
  {
    Id: 28325,
    Code: '8232',
    Name: 'Mechanic, shipping'
  },
  {
    Id: 28326,
    Code: '8232',
    Name: 'OS, shipping'
  },
  {
    Id: 28327,
    Code: '8232',
    Name: 'Oilman, shipping'
  },
  {
    Id: 28328,
    Code: '8232',
    Name: 'Operative, marine'
  },
  {
    Id: 28329,
    Code: '8232',
    Name: 'Owner, boat'
  },
  {
    Id: 28330,
    Code: '8232',
    Name: 'Pumpman, fishing'
  },
  {
    Id: 28331,
    Code: '8232',
    Name: 'Pumpman, shipping'
  },
  {
    Id: 28332,
    Code: '8232',
    Name: 'QM, shipping'
  },
  {
    Id: 28333,
    Code: '8232',
    Name: 'Quartermaster, shipping'
  },
  {
    Id: 28334,
    Code: '8232',
    Name: 'Rating, engine-room, shipping'
  },
  {
    Id: 28335,
    Code: '8232',
    Name: 'Rating, Navy, Merchant'
  },
  {
    Id: 28336,
    Code: '8232',
    Name: 'Rating, shipping'
  },
  {
    Id: 28337,
    Code: '8232',
    Name: 'Runner, shipping'
  },
  {
    Id: 28338,
    Code: '8232',
    Name: 'Sailor, shipping'
  },
  {
    Id: 28339,
    Code: '8232',
    Name: 'Seafarer'
  },
  {
    Id: 28340,
    Code: '8232',
    Name: 'Seaman, merchant'
  },
  {
    Id: 28341,
    Code: '8232',
    Name: 'Seaman, shipping'
  },
  {
    Id: 28342,
    Code: '8232',
    Name: 'Steerer, boat, barge'
  },
  {
    Id: 28343,
    Code: '8232',
    Name: 'Steersman'
  },
  {
    Id: 28344,
    Code: '8232',
    Name: 'Stoker, drifter'
  },
  {
    Id: 28345,
    Code: '8232',
    Name: 'Stoker, engine, boat, barge'
  },
  {
    Id: 28346,
    Code: '8232',
    Name: 'Stoker, engine, shipping'
  },
  {
    Id: 28347,
    Code: '8232',
    Name: 'Stoker, fishing'
  },
  {
    Id: 28348,
    Code: '8232',
    Name: 'Stoker, shipping'
  },
  {
    Id: 28349,
    Code: '8232',
    Name: 'Stoker-mechanic, shipping'
  },
  {
    Id: 28350,
    Code: '8232',
    Name: 'Tindal, first, shipping'
  },
  {
    Id: 28351,
    Code: '8232',
    Name: 'Trimmer, coal, shipping'
  },
  {
    Id: 28352,
    Code: '8232',
    Name: 'Trimmer, lamp, shipping'
  },
  {
    Id: 28353,
    Code: '8232',
    Name: 'Trimmer, fishing'
  },
  {
    Id: 28354,
    Code: '8232',
    Name: 'Trimmer, shipping'
  },
  {
    Id: 28355,
    Code: '8232',
    Name: 'Tugboatman'
  },
  {
    Id: 28356,
    Code: '8232',
    Name: 'Tugman'
  },
  {
    Id: 28357,
    Code: '8232',
    Name: 'Watchman, barge'
  },
  {
    Id: 28358,
    Code: '8232',
    Name: 'Waterman, water transport'
  },
  {
    Id: 28359,
    Code: '8232',
    Name: 'Wherryman'
  },
  {
    Id: 28360,
    Code: '8232',
    Name: 'Winchman, shipping'
  },
  {
    Id: 28361,
    Code: '8232',
    Name: 'Yachtsman'
  },
  {
    Id: 28362,
    Code: '8233',
    Name: 'Agent, ramp'
  },
  {
    Id: 28363,
    Code: '8233',
    Name: 'Agent, service, ramp'
  },
  {
    Id: 28364,
    Code: '8233',
    Name: 'Attendant, aerodrome'
  },
  {
    Id: 28365,
    Code: '8233',
    Name: 'Controller, airfield'
  },
  {
    Id: 28366,
    Code: '8233',
    Name: 'Controller, apron'
  },
  {
    Id: 28367,
    Code: '8233',
    Name: 'Coordinator, despatch, aircraft'
  },
  {
    Id: 28368,
    Code: '8233',
    Name: 'Despatcher, aircraft'
  },
  {
    Id: 28369,
    Code: '8233',
    Name: 'Despatcher, flight'
  },
  {
    Id: 28370,
    Code: '8233',
    Name: 'Despatcher, traffic, aircraft'
  },
  {
    Id: 28371,
    Code: '8233',
    Name: 'Dispatcher, aircraft'
  },
  {
    Id: 28372,
    Code: '8233',
    Name: 'Dispatcher, flight'
  },
  {
    Id: 28373,
    Code: '8233',
    Name: 'Dispatcher, traffic, aircraft'
  },
  {
    Id: 28374,
    Code: '8233',
    Name: 'Driver, loader, airport'
  },
  {
    Id: 28375,
    Code: '8233',
    Name: 'Driver, tug, aircraft'
  },
  {
    Id: 28376,
    Code: '8233',
    Name: 'Driver-loader, airport'
  },
  {
    Id: 28377,
    Code: '8233',
    Name: 'Hand, aircraft, airport'
  },
  {
    Id: 28378,
    Code: '8233',
    Name: 'Hand, airport'
  },
  {
    Id: 28379,
    Code: '8233',
    Name: 'Hand, apron'
  },
  {
    Id: 28380,
    Code: '8233',
    Name: 'Hand, general, airport'
  },
  {
    Id: 28381,
    Code: '8233',
    Name: 'Handler, aircraft'
  },
  {
    Id: 28382,
    Code: '8233',
    Name: 'Handler, baggage'
  },
  {
    Id: 28383,
    Code: '8233',
    Name: 'Handler, cargo, airport'
  },
  {
    Id: 28384,
    Code: '8233',
    Name: 'Leader, team, baggage, airline'
  },
  {
    Id: 28385,
    Code: '8233',
    Name: 'Loader, aircraft'
  },
  {
    Id: 28386,
    Code: '8233',
    Name: 'Loader-driver, airport'
  },
  {
    Id: 28387,
    Code: '8233',
    Name: 'Loadmaster, airlines'
  },
  {
    Id: 28388,
    Code: '8233',
    Name: 'Man, tarmac, airport'
  },
  {
    Id: 28389,
    Code: '8233',
    Name: 'Manager, dispatch, airport activities'
  },
  {
    Id: 28390,
    Code: '8233',
    Name: 'Marshaller, aircraft'
  },
  {
    Id: 28391,
    Code: '8233',
    Name: 'Master, load, airlines'
  },
  {
    Id: 28392,
    Code: '8233',
    Name: 'Officer, landing, helicopter'
  },
  {
    Id: 28393,
    Code: '8233',
    Name: 'Officer, operations, flight'
  },
  {
    Id: 28394,
    Code: '8233',
    Name: 'Officer, service, ramp'
  },
  {
    Id: 28395,
    Code: '8233',
    Name: 'Operative, x-ray, cargo/freight, airport'
  },
  {
    Id: 28396,
    Code: '8233',
    Name: 'Operator, airfield'
  },
  {
    Id: 28397,
    Code: '8233',
    Name: 'Refueller, aircraft'
  },
  {
    Id: 28398,
    Code: '8233',
    Name: 'Refueller, airport'
  },
  {
    Id: 28399,
    Code: '8233',
    Name: 'Staff, ground, airport'
  },
  {
    Id: 28400,
    Code: '8233',
    Name: 'Superintendent, airport'
  },
  {
    Id: 28401,
    Code: '8233',
    Name: 'Supervisor, loading, aircraft'
  },
  {
    Id: 28402,
    Code: '8233',
    Name: 'Supervisor, ramp'
  },
  {
    Id: 28403,
    Code: '8233',
    Name: 'Worker, ramp'
  },
  {
    Id: 28404,
    Code: '8234',
    Name: 'Allocator, traffic'
  },
  {
    Id: 28405,
    Code: '8234',
    Name: 'Assistant, depot, railways'
  },
  {
    Id: 28406,
    Code: '8234',
    Name: 'Assistant, movement, railways'
  },
  {
    Id: 28407,
    Code: '8234',
    Name: 'Assistant, movements, railways'
  },
  {
    Id: 28408,
    Code: '8234',
    Name: 'Attendant, crossing, railways'
  },
  {
    Id: 28409,
    Code: '8234',
    Name: 'Attendant, point'
  },
  {
    Id: 28410,
    Code: '8234',
    Name: 'Attendant, shunter'
  },
  {
    Id: 28411,
    Code: '8234',
    Name: 'Attendant, switch, coal mine'
  },
  {
    Id: 28412,
    Code: '8234',
    Name: 'Attendant, traffic, coal mine'
  },
  {
    Id: 28413,
    Code: '8234',
    Name: 'Attendant, train, coal mine'
  },
  {
    Id: 28414,
    Code: '8234',
    Name: 'Brakesman, transport: railways'
  },
  {
    Id: 28415,
    Code: '8234',
    Name: 'Controller, chief, deputy, railways'
  },
  {
    Id: 28416,
    Code: '8234',
    Name: 'Controller, locomotive'
  },
  {
    Id: 28417,
    Code: '8234',
    Name: "Controller, relief, trainsman's"
  },
  {
    Id: 28418,
    Code: '8234',
    Name: 'Controller, section, railways'
  },
  {
    Id: 28419,
    Code: '8234',
    Name: 'Controller, train'
  },
  {
    Id: 28420,
    Code: '8234',
    Name: 'Controller, railways'
  },
  {
    Id: 28421,
    Code: '8234',
    Name: 'Driver, diesel, coal mine'
  },
  {
    Id: 28422,
    Code: '8234',
    Name: 'Driver, engine, diesel, coal mine'
  },
  {
    Id: 28423,
    Code: '8234',
    Name: 'Driver, surface, coal mine'
  },
  {
    Id: 28424,
    Code: '8234',
    Name: 'Driver, coal mine: above ground'
  },
  {
    Id: 28425,
    Code: '8234',
    Name: 'Driver, train, coal mine: below ground'
  },
  {
    Id: 28426,
    Code: '8234',
    Name: 'Dropper, fire, railways'
  },
  {
    Id: 28427,
    Code: '8234',
    Name: 'Ferryman, railways'
  },
  {
    Id: 28428,
    Code: '8234',
    Name: 'Fireman, engine, locomotive'
  },
  {
    Id: 28429,
    Code: '8234',
    Name: 'Fireman, loco'
  },
  {
    Id: 28430,
    Code: '8234',
    Name: 'Fireman, locomotive'
  },
  {
    Id: 28431,
    Code: '8234',
    Name: 'Fireman, shed'
  },
  {
    Id: 28432,
    Code: '8234',
    Name: 'Fireman, boiler, locomotive'
  },
  {
    Id: 28433,
    Code: '8234',
    Name: 'Flagman'
  },
  {
    Id: 28434,
    Code: '8234',
    Name: 'Foreman, capstan'
  },
  {
    Id: 28435,
    Code: '8234',
    Name: 'Foreman, goods, railways'
  },
  {
    Id: 28436,
    Code: '8234',
    Name: 'Foreman, locomotive'
  },
  {
    Id: 28437,
    Code: '8234',
    Name: 'Foreman, parcel, railways'
  },
  {
    Id: 28438,
    Code: '8234',
    Name: 'Foreman, parcels, railways'
  },
  {
    Id: 28439,
    Code: '8234',
    Name: 'Foreman, running, railways'
  },
  {
    Id: 28440,
    Code: '8234',
    Name: 'Foreman, shed, transport: railways'
  },
  {
    Id: 28441,
    Code: '8234',
    Name: 'Foreman, train'
  },
  {
    Id: 28442,
    Code: '8234',
    Name: 'Foreman, transport, railways'
  },
  {
    Id: 28443,
    Code: '8234',
    Name: 'Foreman, yard, railways'
  },
  {
    Id: 28444,
    Code: '8234',
    Name: 'Foreman, railways'
  },
  {
    Id: 28445,
    Code: '8234',
    Name: 'Gatekeeper and pointsman'
  },
  {
    Id: 28446,
    Code: '8234',
    Name: 'Gateman, coal mine'
  },
  {
    Id: 28447,
    Code: '8234',
    Name: 'Gateman, railways'
  },
  {
    Id: 28448,
    Code: '8234',
    Name: 'Guard, ballast'
  },
  {
    Id: 28449,
    Code: '8234',
    Name: 'Guard, goods'
  },
  {
    Id: 28450,
    Code: '8234',
    Name: 'Guard, loco'
  },
  {
    Id: 28451,
    Code: '8234',
    Name: 'Inspector, cartage, railways'
  },
  {
    Id: 28452,
    Code: '8234',
    Name: 'Inspector, district, transport: railways'
  },
  {
    Id: 28453,
    Code: '8234',
    Name: 'Inspector, goods, railways'
  },
  {
    Id: 28454,
    Code: '8234',
    Name: 'Inspector, guards, railways'
  },
  {
    Id: 28455,
    Code: '8234',
    Name: 'Inspector, mineral, railways'
  },
  {
    Id: 28456,
    Code: '8234',
    Name: 'Inspector, office, head, railways'
  },
  {
    Id: 28457,
    Code: '8234',
    Name: 'Inspector, operating, railways'
  },
  {
    Id: 28458,
    Code: '8234',
    Name: 'Inspector, parcels, railways'
  },
  {
    Id: 28459,
    Code: '8234',
    Name: 'Inspector, railway'
  },
  {
    Id: 28460,
    Code: '8234',
    Name: 'Inspector, shunting'
  },
  {
    Id: 28461,
    Code: '8234',
    Name: "Inspector, signalman's"
  },
  {
    Id: 28462,
    Code: '8234',
    Name: 'Inspector, traffic, railways'
  },
  {
    Id: 28463,
    Code: '8234',
    Name: 'Inspector, train'
  },
  {
    Id: 28464,
    Code: '8234',
    Name: 'Inspector, yard'
  },
  {
    Id: 28465,
    Code: '8234',
    Name: 'Inspector, transport: railways'
  },
  {
    Id: 28466,
    Code: '8234',
    Name: 'Keeper, crossing, level'
  },
  {
    Id: 28467,
    Code: '8234',
    Name: 'Keeper, crossing'
  },
  {
    Id: 28468,
    Code: '8234',
    Name: 'Keeper, gate, crossing, railways'
  },
  {
    Id: 28469,
    Code: '8234',
    Name: 'Keeper, gate, railways'
  },
  {
    Id: 28470,
    Code: '8234',
    Name: 'Keeper, switch'
  },
  {
    Id: 28471,
    Code: '8234',
    Name: 'Keeper and pointsman, gate'
  },
  {
    Id: 28472,
    Code: '8234',
    Name: 'Lampman, railways'
  },
  {
    Id: 28473,
    Code: '8234',
    Name: 'Latcher, locomotive'
  },
  {
    Id: 28474,
    Code: '8234',
    Name: 'Latcher, wagon, steelworks'
  },
  {
    Id: 28475,
    Code: '8234',
    Name: 'Latcher, railways'
  },
  {
    Id: 28476,
    Code: '8234',
    Name: 'Man, locomotive, coal mine'
  },
  {
    Id: 28477,
    Code: '8234',
    Name: 'Man, look-out, railways'
  },
  {
    Id: 28478,
    Code: '8234',
    Name: 'Man, motor, railways'
  },
  {
    Id: 28479,
    Code: '8234',
    Name: 'Man, surface, railways'
  },
  {
    Id: 28480,
    Code: '8234',
    Name: 'Master, protection'
  },
  {
    Id: 28481,
    Code: '8234',
    Name: "Mate, examiner's, cable, railways"
  },
  {
    Id: 28482,
    Code: '8234',
    Name: 'Operator, points, railways'
  },
  {
    Id: 28483,
    Code: '8234',
    Name: 'Operator, signal, railways'
  },
  {
    Id: 28484,
    Code: '8234',
    Name: 'Pointsman'
  },
  {
    Id: 28485,
    Code: '8234',
    Name: 'Railman'
  },
  {
    Id: 28486,
    Code: '8234',
    Name: 'Railwayman'
  },
  {
    Id: 28487,
    Code: '8234',
    Name: 'Secondman, railways'
  },
  {
    Id: 28488,
    Code: '8234',
    Name: 'Shedman, railways'
  },
  {
    Id: 28489,
    Code: '8234',
    Name: 'Sheeter, wagon'
  },
  {
    Id: 28490,
    Code: '8234',
    Name: 'Sheeter, railways'
  },
  {
    Id: 28491,
    Code: '8234',
    Name: 'Shunter'
  },
  {
    Id: 28492,
    Code: '8234',
    Name: 'Signaller'
  },
  {
    Id: 28493,
    Code: '8234',
    Name: 'Signalman, railway'
  },
  {
    Id: 28494,
    Code: '8234',
    Name: 'Signalman, relief'
  },
  {
    Id: 28495,
    Code: '8234',
    Name: 'Signalman, railways'
  },
  {
    Id: 28496,
    Code: '8234',
    Name: 'Stoker, engine, railways'
  },
  {
    Id: 28497,
    Code: '8234',
    Name: 'Stower, railways'
  },
  {
    Id: 28498,
    Code: '8234',
    Name: 'Sub-inspector, railways'
  },
  {
    Id: 28499,
    Code: '8234',
    Name: 'Supervisor, area, railway signalling'
  },
  {
    Id: 28500,
    Code: '8234',
    Name: 'Supervisor, movements, railways'
  },
  {
    Id: 28501,
    Code: '8234',
    Name: 'Supervisor, railway, area'
  },
  {
    Id: 28502,
    Code: '8234',
    Name: 'Supervisor, signalling, railways'
  },
  {
    Id: 28503,
    Code: '8234',
    Name: 'Supervisor, transport, railways'
  },
  {
    Id: 28504,
    Code: '8234',
    Name: 'Trainman, railways'
  },
  {
    Id: 28505,
    Code: '8234',
    Name: 'Worker, goods, railways'
  },
  {
    Id: 28506,
    Code: '8234',
    Name: 'Worker, railway'
  },
  {
    Id: 28507,
    Code: '8234',
    Name: 'Worker, shed, railways'
  },
  {
    Id: 28508,
    Code: '8234',
    Name: 'Yardsman, blast furnace'
  },
  {
    Id: 28509,
    Code: '8239',
    Name: 'Assistant, depot, road'
  },
  {
    Id: 28510,
    Code: '8239',
    Name: 'Assistant, marina'
  },
  {
    Id: 28511,
    Code: '8239',
    Name: 'Assistant, operations, freight handling'
  },
  {
    Id: 28512,
    Code: '8239',
    Name: 'Assistant, port'
  },
  {
    Id: 28513,
    Code: '8239',
    Name: 'Assistant, survey, hydrographic'
  },
  {
    Id: 28514,
    Code: '8239',
    Name: 'Assistant, terminals, transport'
  },
  {
    Id: 28515,
    Code: '8239',
    Name: 'Attendant, bridge'
  },
  {
    Id: 28516,
    Code: '8239',
    Name: 'Attendant, dock'
  },
  {
    Id: 28517,
    Code: '8239',
    Name: 'Attendant, gate, flood'
  },
  {
    Id: 28518,
    Code: '8239',
    Name: 'Attendant, pier'
  },
  {
    Id: 28519,
    Code: '8239',
    Name: 'Attendant, shed, transport'
  },
  {
    Id: 28520,
    Code: '8239',
    Name: 'Attendant, sluice'
  },
  {
    Id: 28521,
    Code: '8239',
    Name: 'Attendant, wagon'
  },
  {
    Id: 28522,
    Code: '8239',
    Name: 'Bankman, rail'
  },
  {
    Id: 28523,
    Code: '8239',
    Name: 'Brakeman and steersman'
  },
  {
    Id: 28524,
    Code: '8239',
    Name: 'Braker, wagon'
  },
  {
    Id: 28525,
    Code: '8239',
    Name: 'Brakesman, transport'
  },
  {
    Id: 28526,
    Code: '8239',
    Name: 'Breaker, wagon'
  },
  {
    Id: 28527,
    Code: '8239',
    Name: 'Bridgeman, sluice'
  },
  {
    Id: 28528,
    Code: '8239',
    Name: 'Bridgeman'
  },
  {
    Id: 28529,
    Code: '8239',
    Name: 'Bridgemaster'
  },
  {
    Id: 28530,
    Code: '8239',
    Name: 'Cabman'
  },
  {
    Id: 28531,
    Code: '8239',
    Name: 'Carter, transport'
  },
  {
    Id: 28532,
    Code: '8239',
    Name: 'Cartman'
  },
  {
    Id: 28533,
    Code: '8239',
    Name: 'Coachman'
  },
  {
    Id: 28534,
    Code: '8239',
    Name: 'Controller, service, public transport'
  },
  {
    Id: 28535,
    Code: '8239',
    Name: 'Controller, signals'
  },
  {
    Id: 28536,
    Code: '8239',
    Name: 'Despatcher, traffic'
  },
  {
    Id: 28537,
    Code: '8239',
    Name: 'Driver, bridge, swing'
  },
  {
    Id: 28538,
    Code: '8239',
    Name: 'Driver, bridge'
  },
  {
    Id: 28539,
    Code: '8239',
    Name: 'Driver, cart'
  },
  {
    Id: 28540,
    Code: '8239',
    Name: 'Driver, engine, traction'
  },
  {
    Id: 28541,
    Code: '8239',
    Name: 'Driver, horse'
  },
  {
    Id: 28542,
    Code: '8239',
    Name: 'Driver, lister'
  },
  {
    Id: 28543,
    Code: '8239',
    Name: 'Driver, motor, electric'
  },
  {
    Id: 28544,
    Code: '8239',
    Name: 'Driver, ram'
  },
  {
    Id: 28545,
    Code: '8239',
    Name: 'Driver, ransom'
  },
  {
    Id: 28546,
    Code: '8239',
    Name: 'Driver, traverser'
  },
  {
    Id: 28547,
    Code: '8239',
    Name: 'Driver, truck, lister'
  },
  {
    Id: 28548,
    Code: '8239',
    Name: 'Examiner, ticket, road transport'
  },
  {
    Id: 28549,
    Code: '8239',
    Name: 'Foreman, bank, transport'
  },
  {
    Id: 28550,
    Code: '8239',
    Name: 'Foreman, cartage'
  },
  {
    Id: 28551,
    Code: '8239',
    Name: 'Foreman, delivery'
  },
  {
    Id: 28552,
    Code: '8239',
    Name: 'Foreman, depot, coal merchants'
  },
  {
    Id: 28553,
    Code: '8239',
    Name: 'Foreman, depot, transport'
  },
  {
    Id: 28554,
    Code: '8239',
    Name: 'Foreman, export'
  },
  {
    Id: 28555,
    Code: '8239',
    Name: 'Foreman, installation, oil refining'
  },
  {
    Id: 28556,
    Code: '8239',
    Name: 'Foreman, lock'
  },
  {
    Id: 28557,
    Code: '8239',
    Name: 'Foreman, shed, transport: road'
  },
  {
    Id: 28558,
    Code: '8239',
    Name: 'Foreman, traffic'
  },
  {
    Id: 28559,
    Code: '8239',
    Name: 'Foreman, transport'
  },
  {
    Id: 28560,
    Code: '8239',
    Name: 'Foreman, transporting'
  },
  {
    Id: 28561,
    Code: '8239',
    Name: 'Foreman, yard, coal'
  },
  {
    Id: 28562,
    Code: '8239',
    Name: "Foreman, yard, builders' merchants"
  },
  {
    Id: 28563,
    Code: '8239',
    Name: 'Foreman, yard, building construction'
  },
  {
    Id: 28564,
    Code: '8239',
    Name: 'Foreman, yard, canals'
  },
  {
    Id: 28565,
    Code: '8239',
    Name: 'Foreman, yard, local government'
  },
  {
    Id: 28566,
    Code: '8239',
    Name: 'Foreman, yard, road transport'
  },
  {
    Id: 28567,
    Code: '8239',
    Name: 'Foreman, bus service'
  },
  {
    Id: 28568,
    Code: '8239',
    Name: 'Foreman, coal merchants'
  },
  {
    Id: 28569,
    Code: '8239',
    Name: 'Foreman, local government: council depot'
  },
  {
    Id: 28570,
    Code: '8239',
    Name: 'Foreman, local government: transport dept'
  },
  {
    Id: 28571,
    Code: '8239',
    Name: 'Foreman, petroleum distribution'
  },
  {
    Id: 28572,
    Code: '8239',
    Name: 'Foreman, road transport'
  },
  {
    Id: 28573,
    Code: '8239',
    Name: 'Gasman, railways'
  },
  {
    Id: 28574,
    Code: '8239',
    Name: 'Gateman, bridge'
  },
  {
    Id: 28575,
    Code: '8239',
    Name: 'Gateman, dock'
  },
  {
    Id: 28576,
    Code: '8239',
    Name: 'Gateman, flood'
  },
  {
    Id: 28577,
    Code: '8239',
    Name: 'Gateman, lock'
  },
  {
    Id: 28578,
    Code: '8239',
    Name: 'Gateman, docks'
  },
  {
    Id: 28579,
    Code: '8239',
    Name: 'Gateman, waterways'
  },
  {
    Id: 28580,
    Code: '8239',
    Name: 'Hand, deck, oil rigs'
  },
  {
    Id: 28581,
    Code: '8239',
    Name: 'Hand, stage, docks'
  },
  {
    Id: 28582,
    Code: '8239',
    Name: 'Horseman, timber haulage'
  },
  {
    Id: 28583,
    Code: '8239',
    Name: 'Horseman, canals'
  },
  {
    Id: 28584,
    Code: '8239',
    Name: 'Inspector, bus'
  },
  {
    Id: 28585,
    Code: '8239',
    Name: 'Inspector, depot, transport'
  },
  {
    Id: 28586,
    Code: '8239',
    Name: 'Inspector, district, transport: road'
  },
  {
    Id: 28587,
    Code: '8239',
    Name: 'Inspector, garage'
  },
  {
    Id: 28588,
    Code: '8239',
    Name: 'Inspector, loading, transport'
  },
  {
    Id: 28589,
    Code: '8239',
    Name: 'Inspector, road, transport'
  },
  {
    Id: 28590,
    Code: '8239',
    Name: 'Inspector, roads, transport'
  },
  {
    Id: 28591,
    Code: '8239',
    Name: 'Inspector, sanitary, railways'
  },
  {
    Id: 28592,
    Code: '8239',
    Name: 'Inspector, telegraph, railways'
  },
  {
    Id: 28593,
    Code: '8239',
    Name: 'Inspector, ticket, public transport'
  },
  {
    Id: 28594,
    Code: '8239',
    Name: 'Inspector, traffic, road transport'
  },
  {
    Id: 28595,
    Code: '8239',
    Name: 'Inspector, transport, road transport'
  },
  {
    Id: 28596,
    Code: '8239',
    Name: 'Inspector, motoring organisation'
  },
  {
    Id: 28597,
    Code: '8239',
    Name: 'Inspector, transport: road'
  },
  {
    Id: 28598,
    Code: '8239',
    Name: 'Keeper, bridge'
  },
  {
    Id: 28599,
    Code: '8239',
    Name: 'Keeper, gate, lock'
  },
  {
    Id: 28600,
    Code: '8239',
    Name: 'Keeper, house, bridge'
  },
  {
    Id: 28601,
    Code: '8239',
    Name: 'Keeper, house, light'
  },
  {
    Id: 28602,
    Code: '8239',
    Name: 'Keeper, light'
  },
  {
    Id: 28603,
    Code: '8239',
    Name: 'Keeper, lighthouse'
  },
  {
    Id: 28604,
    Code: '8239',
    Name: 'Keeper, lock'
  },
  {
    Id: 28605,
    Code: '8239',
    Name: 'Keeper, sluice'
  },
  {
    Id: 28606,
    Code: '8239',
    Name: 'Keeper, swingbridge'
  },
  {
    Id: 28607,
    Code: '8239',
    Name: 'Keeper, weir'
  },
  {
    Id: 28608,
    Code: '8239',
    Name: 'Keeper, Trinity House'
  },
  {
    Id: 28609,
    Code: '8239',
    Name: 'Man, berthing'
  },
  {
    Id: 28610,
    Code: '8239',
    Name: 'Man, head, pier'
  },
  {
    Id: 28611,
    Code: '8239',
    Name: 'Man, lighthouse'
  },
  {
    Id: 28612,
    Code: '8239',
    Name: 'Man, pontoon'
  },
  {
    Id: 28613,
    Code: '8239',
    Name: 'Man, swingbridge'
  },
  {
    Id: 28614,
    Code: '8239',
    Name: 'Marshal, transport'
  },
  {
    Id: 28615,
    Code: '8239',
    Name: 'Marshall, traffic'
  },
  {
    Id: 28616,
    Code: '8239',
    Name: 'Marshaller, transport'
  },
  {
    Id: 28617,
    Code: '8239',
    Name: 'Master, berthing, marina'
  },
  {
    Id: 28618,
    Code: '8239',
    Name: 'Master, bridge'
  },
  {
    Id: 28619,
    Code: '8239',
    Name: 'Master, lock'
  },
  {
    Id: 28620,
    Code: '8239',
    Name: 'Master, swingbridge'
  },
  {
    Id: 28621,
    Code: '8239',
    Name: 'Operator, bridge'
  },
  {
    Id: 28622,
    Code: '8239',
    Name: 'Operator, capstan, railways'
  },
  {
    Id: 28623,
    Code: '8239',
    Name: 'Pierman'
  },
  {
    Id: 28624,
    Code: '8239',
    Name: 'Planner, load'
  },
  {
    Id: 28625,
    Code: '8239',
    Name: 'Pointsman, road transport'
  },
  {
    Id: 28626,
    Code: '8239',
    Name: 'Putter, pony'
  },
  {
    Id: 28627,
    Code: '8239',
    Name: 'Regulator, traffic'
  },
  {
    Id: 28628,
    Code: '8239',
    Name: 'Regulator, transport'
  },
  {
    Id: 28629,
    Code: '8239',
    Name: 'Roadsman, canals'
  },
  {
    Id: 28630,
    Code: '8239',
    Name: 'Scotcher'
  },
  {
    Id: 28631,
    Code: '8239',
    Name: 'Seaman, stage, landing'
  },
  {
    Id: 28632,
    Code: '8239',
    Name: 'Setter, van, railways'
  },
  {
    Id: 28633,
    Code: '8239',
    Name: 'Shackler'
  },
  {
    Id: 28634,
    Code: '8239',
    Name: 'Shedman, transport'
  },
  {
    Id: 28635,
    Code: '8239',
    Name: "Signalman, Lloyd's"
  },
  {
    Id: 28636,
    Code: '8239',
    Name: 'Sounder, survey'
  },
  {
    Id: 28637,
    Code: '8239',
    Name: 'Stageman, landing'
  },
  {
    Id: 28638,
    Code: '8239',
    Name: 'Steersman, bridge'
  },
  {
    Id: 28639,
    Code: '8239',
    Name: 'Superintendent, depot, transport'
  },
  {
    Id: 28640,
    Code: '8239',
    Name: 'Superintendent, district, transport'
  },
  {
    Id: 28641,
    Code: '8239',
    Name: 'Superintendent, divisional, railways'
  },
  {
    Id: 28642,
    Code: '8239',
    Name: 'Superintendent, marine'
  },
  {
    Id: 28643,
    Code: '8239',
    Name: 'Superintendent, operations, transport'
  },
  {
    Id: 28644,
    Code: '8239',
    Name: 'Superintendent, traffic'
  },
  {
    Id: 28645,
    Code: '8239',
    Name: 'Superintendent, transport'
  },
  {
    Id: 28646,
    Code: '8239',
    Name: 'Superintendent, docks'
  },
  {
    Id: 28647,
    Code: '8239',
    Name: 'Superintendent, railways'
  },
  {
    Id: 28648,
    Code: '8239',
    Name: 'Supervisor, transport, public transport'
  },
  {
    Id: 28649,
    Code: '8239',
    Name: 'Supervisor, transport'
  },
  {
    Id: 28650,
    Code: '8239',
    Name: 'Teamster'
  },
  {
    Id: 28651,
    Code: '8239',
    Name: 'Tender, lock'
  },
  {
    Id: 28652,
    Code: '8239',
    Name: 'Worker, transport'
  },
  {
    Id: 28653,
    Code: '9111',
    Name: 'Assistant, farm'
  },
  {
    Id: 28654,
    Code: '9111',
    Name: 'Assistant, general, agriculture'
  },
  {
    Id: 28655,
    Code: '9111',
    Name: 'Assistant, poultry'
  },
  {
    Id: 28656,
    Code: '9111',
    Name: 'Attendant, pig'
  },
  {
    Id: 28657,
    Code: '9111',
    Name: 'Attendant, poultry'
  },
  {
    Id: 28658,
    Code: '9111',
    Name: 'Catcher, poultry'
  },
  {
    Id: 28659,
    Code: '9111',
    Name: 'Cattleman'
  },
  {
    Id: 28660,
    Code: '9111',
    Name: 'Collector, egg, poultry farm'
  },
  {
    Id: 28661,
    Code: '9111',
    Name: 'Cowman'
  },
  {
    Id: 28662,
    Code: '9111',
    Name: 'Craftsman, agricultural'
  },
  {
    Id: 28663,
    Code: '9111',
    Name: 'Cropper, agriculture'
  },
  {
    Id: 28664,
    Code: '9111',
    Name: 'Dairymaid, farming'
  },
  {
    Id: 28665,
    Code: '9111',
    Name: 'Dairyman, farming'
  },
  {
    Id: 28666,
    Code: '9111',
    Name: 'Hand, dairy, farming'
  },
  {
    Id: 28667,
    Code: '9111',
    Name: 'Hand, farm'
  },
  {
    Id: 28668,
    Code: '9111',
    Name: 'Handler, poultry'
  },
  {
    Id: 28669,
    Code: '9111',
    Name: 'Handyman, farm'
  },
  {
    Id: 28670,
    Code: '9111',
    Name: 'Help, farm'
  },
  {
    Id: 28671,
    Code: '9111',
    Name: 'Herdsman'
  },
  {
    Id: 28672,
    Code: '9111',
    Name: 'Keeper, cow'
  },
  {
    Id: 28673,
    Code: '9111',
    Name: 'Keeper, stock, agriculture'
  },
  {
    Id: 28674,
    Code: '9111',
    Name: 'Labourer, agricultural'
  },
  {
    Id: 28675,
    Code: '9111',
    Name: 'Labourer, estate'
  },
  {
    Id: 28676,
    Code: '9111',
    Name: 'Labourer, farm'
  },
  {
    Id: 28677,
    Code: '9111',
    Name: 'Labourer, agriculture'
  },
  {
    Id: 28678,
    Code: '9111',
    Name: 'Lamber'
  },
  {
    Id: 28679,
    Code: '9111',
    Name: 'Landworker'
  },
  {
    Id: 28680,
    Code: '9111',
    Name: 'Man, gang, agriculture'
  },
  {
    Id: 28681,
    Code: '9111',
    Name: 'Man, job, odd, agriculture'
  },
  {
    Id: 28682,
    Code: '9111',
    Name: 'Man, poultry'
  },
  {
    Id: 28683,
    Code: '9111',
    Name: 'Man, yard, farming'
  },
  {
    Id: 28684,
    Code: '9111',
    Name: 'Master, gang'
  },
  {
    Id: 28685,
    Code: '9111',
    Name: 'Operative, agricultural'
  },
  {
    Id: 28686,
    Code: '9111',
    Name: 'Pigman'
  },
  {
    Id: 28687,
    Code: '9111',
    Name: 'Shepherd'
  },
  {
    Id: 28688,
    Code: '9111',
    Name: 'Stockman, farming'
  },
  {
    Id: 28689,
    Code: '9111',
    Name: 'Stocksman, farming'
  },
  {
    Id: 28690,
    Code: '9111',
    Name: 'Supervisor, unit, poultry'
  },
  {
    Id: 28691,
    Code: '9111',
    Name: "Wife, farmer's"
  },
  {
    Id: 28692,
    Code: '9111',
    Name: 'Worker, agricultural'
  },
  {
    Id: 28693,
    Code: '9111',
    Name: 'Worker, farm'
  },
  {
    Id: 28694,
    Code: '9111',
    Name: 'Worker, field'
  },
  {
    Id: 28695,
    Code: '9111',
    Name: 'Worker, general, farming'
  },
  {
    Id: 28696,
    Code: '9111',
    Name: 'Worker, land'
  },
  {
    Id: 28697,
    Code: '9111',
    Name: 'Worker, poultry'
  },
  {
    Id: 28698,
    Code: '9111',
    Name: 'Worker, wire, hop growing'
  },
  {
    Id: 28699,
    Code: '9111',
    Name: 'Yardman, farming'
  },
  {
    Id: 28700,
    Code: '9111',
    Name: 'Yardsman, farming'
  },
  {
    Id: 28701,
    Code: '9112',
    Name: 'Assistant, arboricultural'
  },
  {
    Id: 28702,
    Code: '9112',
    Name: 'Attendant, park'
  },
  {
    Id: 28703,
    Code: '9112',
    Name: 'Axeman'
  },
  {
    Id: 28704,
    Code: '9112',
    Name: 'Caretaker, woodlands'
  },
  {
    Id: 28705,
    Code: '9112',
    Name: 'Contractor, forestry'
  },
  {
    Id: 28706,
    Code: '9112',
    Name: 'Craftsman, forest'
  },
  {
    Id: 28707,
    Code: '9112',
    Name: 'Craftsman, forestry'
  },
  {
    Id: 28708,
    Code: '9112',
    Name: 'Cutter, copse'
  },
  {
    Id: 28709,
    Code: '9112',
    Name: 'Cutter, tree'
  },
  {
    Id: 28710,
    Code: '9112',
    Name: 'Cutter, willow'
  },
  {
    Id: 28711,
    Code: '9112',
    Name: 'Cutter, wood, forestry'
  },
  {
    Id: 28712,
    Code: '9112',
    Name: 'Driver, tractor, forestry'
  },
  {
    Id: 28713,
    Code: '9112',
    Name: 'Feller, timber'
  },
  {
    Id: 28714,
    Code: '9112',
    Name: 'Feller, tree'
  },
  {
    Id: 28715,
    Code: '9112',
    Name: 'Feller, forestry'
  },
  {
    Id: 28716,
    Code: '9112',
    Name: 'Foreman, forestry'
  },
  {
    Id: 28717,
    Code: '9112',
    Name: 'Forester'
  },
  {
    Id: 28718,
    Code: '9112',
    Name: 'Grower, Christmas trees'
  },
  {
    Id: 28719,
    Code: '9112',
    Name: 'Grower, osier'
  },
  {
    Id: 28720,
    Code: '9112',
    Name: 'Grower, willow'
  },
  {
    Id: 28721,
    Code: '9112',
    Name: 'Grower, withy'
  },
  {
    Id: 28722,
    Code: '9112',
    Name: 'Grower, wood'
  },
  {
    Id: 28723,
    Code: '9112',
    Name: 'Holder, small, forestry'
  },
  {
    Id: 28724,
    Code: '9112',
    Name: 'Inspector, park'
  },
  {
    Id: 28725,
    Code: '9112',
    Name: 'Inspector of park keepers'
  },
  {
    Id: 28726,
    Code: '9112',
    Name: 'Jack, lumber'
  },
  {
    Id: 28727,
    Code: '9112',
    Name: 'Keeper, forest'
  },
  {
    Id: 28728,
    Code: '9112',
    Name: 'Keeper, head, park'
  },
  {
    Id: 28729,
    Code: '9112',
    Name: 'Keeper, park, head'
  },
  {
    Id: 28730,
    Code: '9112',
    Name: 'Keeper, park'
  },
  {
    Id: 28731,
    Code: '9112',
    Name: 'Labourer, Crown'
  },
  {
    Id: 28732,
    Code: '9112',
    Name: "Labourer, feller's, timber"
  },
  {
    Id: 28733,
    Code: '9112',
    Name: 'Labourer, forest'
  },
  {
    Id: 28734,
    Code: '9112',
    Name: 'Labourer, wood'
  },
  {
    Id: 28735,
    Code: '9112',
    Name: 'Labourer, forestry'
  },
  {
    Id: 28736,
    Code: '9112',
    Name: 'Loader, timber, forestry'
  },
  {
    Id: 28737,
    Code: '9112',
    Name: 'Lopper, tree'
  },
  {
    Id: 28738,
    Code: '9112',
    Name: 'Lumberjack'
  },
  {
    Id: 28739,
    Code: '9112',
    Name: 'Man, timber, forestry'
  },
  {
    Id: 28740,
    Code: '9112',
    Name: 'Pruner, tree, forestry'
  },
  {
    Id: 28741,
    Code: '9112',
    Name: 'Ranger, forest'
  },
  {
    Id: 28742,
    Code: '9112',
    Name: 'Ranger, wood'
  },
  {
    Id: 28743,
    Code: '9112',
    Name: 'Silviculturist'
  },
  {
    Id: 28744,
    Code: '9112',
    Name: 'Smallholder, forestry'
  },
  {
    Id: 28745,
    Code: '9112',
    Name: 'Superintendent, park'
  },
  {
    Id: 28746,
    Code: '9112',
    Name: 'Superintendent, park'
  },
  {
    Id: 28747,
    Code: '9112',
    Name: 'Supervisor, park'
  },
  {
    Id: 28748,
    Code: '9112',
    Name: 'Taker, care, woodlands'
  },
  {
    Id: 28749,
    Code: '9112',
    Name: 'Verderer'
  },
  {
    Id: 28750,
    Code: '9112',
    Name: 'Verderor'
  },
  {
    Id: 28751,
    Code: '9112',
    Name: 'Warden, park'
  },
  {
    Id: 28752,
    Code: '9112',
    Name: 'Warden, wood'
  },
  {
    Id: 28753,
    Code: '9112',
    Name: 'Warden, forestry'
  },
  {
    Id: 28754,
    Code: '9112',
    Name: 'Woodcutter, forestry'
  },
  {
    Id: 28755,
    Code: '9112',
    Name: 'Woodman'
  },
  {
    Id: 28756,
    Code: '9112',
    Name: 'Woodsman'
  },
  {
    Id: 28757,
    Code: '9112',
    Name: 'Worker, arboricultural'
  },
  {
    Id: 28758,
    Code: '9112',
    Name: 'Worker, coppice'
  },
  {
    Id: 28759,
    Code: '9112',
    Name: 'Worker, forest'
  },
  {
    Id: 28760,
    Code: '9112',
    Name: 'Worker, forestry'
  },
  {
    Id: 28761,
    Code: '9112',
    Name: 'Workman, forest'
  },
  {
    Id: 28762,
    Code: '9119',
    Name: 'Assistant, farm, fish'
  },
  {
    Id: 28763,
    Code: '9119',
    Name: 'Assistant, farm, mink'
  },
  {
    Id: 28764,
    Code: '9119',
    Name: 'Assistant, general, horticulture'
  },
  {
    Id: 28765,
    Code: '9119',
    Name: 'Assistant, hatchery'
  },
  {
    Id: 28766,
    Code: '9119',
    Name: 'Attendant, aquarium'
  },
  {
    Id: 28767,
    Code: '9119',
    Name: 'Attendant, nursery, horticulture'
  },
  {
    Id: 28768,
    Code: '9119',
    Name: "Bo'sun, fishing"
  },
  {
    Id: 28769,
    Code: '9119',
    Name: 'Boatswain, fishing'
  },
  {
    Id: 28770,
    Code: '9119',
    Name: 'Bosun, fishing'
  },
  {
    Id: 28771,
    Code: '9119',
    Name: 'Breeder, fish'
  },
  {
    Id: 28772,
    Code: '9119',
    Name: 'Breeder, maggot'
  },
  {
    Id: 28773,
    Code: '9119',
    Name: 'Breeder, mealworm'
  },
  {
    Id: 28774,
    Code: '9119',
    Name: 'Carter, farming'
  },
  {
    Id: 28775,
    Code: '9119',
    Name: 'Catcher, bird'
  },
  {
    Id: 28776,
    Code: '9119',
    Name: 'Catcher, chicken'
  },
  {
    Id: 28777,
    Code: '9119',
    Name: 'Catcher, rabbit'
  },
  {
    Id: 28778,
    Code: '9119',
    Name: 'Chick-sexer'
  },
  {
    Id: 28779,
    Code: '9119',
    Name: 'Chopper, firewood'
  },
  {
    Id: 28780,
    Code: '9119',
    Name: 'Classifier, livestock'
  },
  {
    Id: 28781,
    Code: '9119',
    Name: 'Cockler'
  },
  {
    Id: 28782,
    Code: '9119',
    Name: 'Collector, fern'
  },
  {
    Id: 28783,
    Code: '9119',
    Name: 'Collector, kelp'
  },
  {
    Id: 28784,
    Code: '9119',
    Name: 'Collector, moss'
  },
  {
    Id: 28785,
    Code: '9119',
    Name: 'Collector, reed'
  },
  {
    Id: 28786,
    Code: '9119',
    Name: 'Collector, seaweed'
  },
  {
    Id: 28787,
    Code: '9119',
    Name: 'Crabber, fishing'
  },
  {
    Id: 28788,
    Code: '9119',
    Name: 'Cultivator, shellfish'
  },
  {
    Id: 28789,
    Code: '9119',
    Name: 'Cutter, grass'
  },
  {
    Id: 28790,
    Code: '9119',
    Name: 'Cutter, hay, farming'
  },
  {
    Id: 28791,
    Code: '9119',
    Name: 'Cutter, heath'
  },
  {
    Id: 28792,
    Code: '9119',
    Name: 'Cutter, hedge'
  },
  {
    Id: 28793,
    Code: '9119',
    Name: 'Cutter, mushroom'
  },
  {
    Id: 28794,
    Code: '9119',
    Name: 'Cutter, peat'
  },
  {
    Id: 28795,
    Code: '9119',
    Name: 'Cutter, reed'
  },
  {
    Id: 28796,
    Code: '9119',
    Name: 'Cutter, straw, farming'
  },
  {
    Id: 28797,
    Code: '9119',
    Name: 'Cutter, vegetable'
  },
  {
    Id: 28798,
    Code: '9119',
    Name: 'Cutter, watercress'
  },
  {
    Id: 28799,
    Code: '9119',
    Name: 'Digger, bait'
  },
  {
    Id: 28800,
    Code: '9119',
    Name: 'Digger, peat'
  },
  {
    Id: 28801,
    Code: '9119',
    Name: 'Dredgeman, shell fish'
  },
  {
    Id: 28802,
    Code: '9119',
    Name: 'Dredger, shell fish'
  },
  {
    Id: 28803,
    Code: '9119',
    Name: 'Dresser, potato'
  },
  {
    Id: 28804,
    Code: '9119',
    Name: 'Drover'
  },
  {
    Id: 28805,
    Code: '9119',
    Name: 'Gatherer, mussel'
  },
  {
    Id: 28806,
    Code: '9119',
    Name: 'Gatherer, seaweed'
  },
  {
    Id: 28807,
    Code: '9119',
    Name: 'Gatherer, watercress'
  },
  {
    Id: 28808,
    Code: '9119',
    Name: 'Gatherer, agricultural products'
  },
  {
    Id: 28809,
    Code: '9119',
    Name: 'Grader, pig'
  },
  {
    Id: 28810,
    Code: '9119',
    Name: 'Grader, stock, live'
  },
  {
    Id: 28811,
    Code: '9119',
    Name: 'Hand, deck, fishing'
  },
  {
    Id: 28812,
    Code: '9119',
    Name: 'Hand, maintenance, gardening, grounds keeping services'
  },
  {
    Id: 28813,
    Code: '9119',
    Name: 'Hand, nursery'
  },
  {
    Id: 28814,
    Code: '9119',
    Name: 'Hand, second, fishing'
  },
  {
    Id: 28815,
    Code: '9119',
    Name: 'Hand, spare, fishing'
  },
  {
    Id: 28816,
    Code: '9119',
    Name: 'Hand, third, fishing'
  },
  {
    Id: 28817,
    Code: '9119',
    Name: 'Hand, trawler'
  },
  {
    Id: 28818,
    Code: '9119',
    Name: 'Handyman, estate'
  },
  {
    Id: 28819,
    Code: '9119',
    Name: "Handyman, gardener's"
  },
  {
    Id: 28820,
    Code: '9119',
    Name: 'Harpooner'
  },
  {
    Id: 28821,
    Code: '9119',
    Name: 'Harvester, crop'
  },
  {
    Id: 28822,
    Code: '9119',
    Name: 'Harvester, fruit, vegetable growing'
  },
  {
    Id: 28823,
    Code: '9119',
    Name: 'Hatcher, fish'
  },
  {
    Id: 28824,
    Code: '9119',
    Name: 'Heathman'
  },
  {
    Id: 28825,
    Code: '9119',
    Name: 'Heathsman'
  },
  {
    Id: 28826,
    Code: '9119',
    Name: 'Hedger'
  },
  {
    Id: 28827,
    Code: '9119',
    Name: 'Hedger and ditcher'
  },
  {
    Id: 28828,
    Code: '9119',
    Name: 'Incubationist, agriculture'
  },
  {
    Id: 28829,
    Code: '9119',
    Name: 'Inseminator, artificial'
  },
  {
    Id: 28830,
    Code: '9119',
    Name: 'Irrigator'
  },
  {
    Id: 28831,
    Code: '9119',
    Name: 'Labourer, garden'
  },
  {
    Id: 28832,
    Code: '9119',
    Name: 'Labourer, garden and parks'
  },
  {
    Id: 28833,
    Code: '9119',
    Name: "Labourer, gardener's"
  },
  {
    Id: 28834,
    Code: '9119',
    Name: 'Labourer, landscape'
  },
  {
    Id: 28835,
    Code: '9119',
    Name: 'Labourer, parks'
  },
  {
    Id: 28836,
    Code: '9119',
    Name: 'Labourer, policy, Scotland'
  },
  {
    Id: 28837,
    Code: '9119',
    Name: 'Labourer, horticulture'
  },
  {
    Id: 28838,
    Code: '9119',
    Name: 'Labourer, landscape gardening'
  },
  {
    Id: 28839,
    Code: '9119',
    Name: 'Lairman'
  },
  {
    Id: 28840,
    Code: '9119',
    Name: 'Layer, hedge'
  },
  {
    Id: 28841,
    Code: '9119',
    Name: 'Layman, mussel'
  },
  {
    Id: 28842,
    Code: '9119',
    Name: 'Man, decoy'
  },
  {
    Id: 28843,
    Code: '9119',
    Name: 'Man, seed'
  },
  {
    Id: 28844,
    Code: '9119',
    Name: 'Man, trawler'
  },
  {
    Id: 28845,
    Code: '9119',
    Name: 'Mariner, fishing'
  },
  {
    Id: 28846,
    Code: '9119',
    Name: 'Mate, boat, fishing'
  },
  {
    Id: 28847,
    Code: '9119',
    Name: 'Mate, trawler'
  },
  {
    Id: 28848,
    Code: '9119',
    Name: 'Mate, fishing'
  },
  {
    Id: 28849,
    Code: '9119',
    Name: 'Member, crew, fishing'
  },
  {
    Id: 28850,
    Code: '9119',
    Name: 'Milker'
  },
  {
    Id: 28851,
    Code: '9119',
    Name: 'Milkman, farming'
  },
  {
    Id: 28852,
    Code: '9119',
    Name: 'Mower, lawn'
  },
  {
    Id: 28853,
    Code: '9119',
    Name: 'Musseler'
  },
  {
    Id: 28854,
    Code: '9119',
    Name: 'Operator, hatchery'
  },
  {
    Id: 28855,
    Code: '9119',
    Name: 'Picker, cockle'
  },
  {
    Id: 28856,
    Code: '9119',
    Name: 'Picker, flower'
  },
  {
    Id: 28857,
    Code: '9119',
    Name: 'Picker, fruit, farming'
  },
  {
    Id: 28858,
    Code: '9119',
    Name: 'Picker, hop'
  },
  {
    Id: 28859,
    Code: '9119',
    Name: 'Picker, moss'
  },
  {
    Id: 28860,
    Code: '9119',
    Name: 'Picker, mushroom'
  },
  {
    Id: 28861,
    Code: '9119',
    Name: 'Picker, pea, farming'
  },
  {
    Id: 28862,
    Code: '9119',
    Name: 'Picker, potato'
  },
  {
    Id: 28863,
    Code: '9119',
    Name: 'Picker, fruit, vegetable growing'
  },
  {
    Id: 28864,
    Code: '9119',
    Name: 'Pisciculturist'
  },
  {
    Id: 28865,
    Code: '9119',
    Name: 'Puller, pea'
  },
  {
    Id: 28866,
    Code: '9119',
    Name: 'Riddler, potato'
  },
  {
    Id: 28867,
    Code: '9119',
    Name: 'Seaman, fishing'
  },
  {
    Id: 28868,
    Code: '9119',
    Name: 'Sexer, chick'
  },
  {
    Id: 28869,
    Code: '9119',
    Name: 'Shearer, sheep'
  },
  {
    Id: 28870,
    Code: '9119',
    Name: 'Shrimper'
  },
  {
    Id: 28871,
    Code: '9119',
    Name: 'Teamsman, farming'
  },
  {
    Id: 28872,
    Code: '9119',
    Name: 'Teamster, farming'
  },
  {
    Id: 28873,
    Code: '9119',
    Name: 'Technician, bovine'
  },
  {
    Id: 28874,
    Code: '9119',
    Name: 'Technician, insemination, artificial'
  },
  {
    Id: 28875,
    Code: '9119',
    Name: 'Trapper, rabbit'
  },
  {
    Id: 28876,
    Code: '9119',
    Name: 'Trapper, forestry'
  },
  {
    Id: 28877,
    Code: '9119',
    Name: 'Trusser, farming'
  },
  {
    Id: 28878,
    Code: '9119',
    Name: 'Vaccinator, poultry'
  },
  {
    Id: 28879,
    Code: '9119',
    Name: 'Warder, river'
  },
  {
    Id: 28880,
    Code: '9119',
    Name: 'Washer, fruit, vegetable growing'
  },
  {
    Id: 28881,
    Code: '9119',
    Name: 'Watcher, river'
  },
  {
    Id: 28882,
    Code: '9119',
    Name: 'Weeder'
  },
  {
    Id: 28883,
    Code: '9119',
    Name: 'Worker, bed, oyster'
  },
  {
    Id: 28884,
    Code: '9119',
    Name: 'Worker, bulb, horticulture'
  },
  {
    Id: 28885,
    Code: '9119',
    Name: 'Worker, crop, horticulture'
  },
  {
    Id: 28886,
    Code: '9119',
    Name: 'Worker, estate'
  },
  {
    Id: 28887,
    Code: '9119',
    Name: 'Worker, farm, fish'
  },
  {
    Id: 28888,
    Code: '9119',
    Name: 'Worker, farm, salmon'
  },
  {
    Id: 28889,
    Code: '9119',
    Name: 'Worker, farm, trout'
  },
  {
    Id: 28890,
    Code: '9119',
    Name: 'Worker, farm, fish farm, hatchery'
  },
  {
    Id: 28891,
    Code: '9119',
    Name: 'Worker, garden, market'
  },
  {
    Id: 28892,
    Code: '9119',
    Name: 'Worker, general, horticulture'
  },
  {
    Id: 28893,
    Code: '9119',
    Name: 'Worker, glass, agriculture'
  },
  {
    Id: 28894,
    Code: '9119',
    Name: 'Worker, glasshouse'
  },
  {
    Id: 28895,
    Code: '9119',
    Name: 'Worker, greenhouse, agriculture'
  },
  {
    Id: 28896,
    Code: '9119',
    Name: 'Worker, hatchery, agriculture'
  },
  {
    Id: 28897,
    Code: '9119',
    Name: 'Worker, hatchery, fishing'
  },
  {
    Id: 28898,
    Code: '9119',
    Name: 'Worker, horticultural'
  },
  {
    Id: 28899,
    Code: '9119',
    Name: 'Worker, nursery'
  },
  {
    Id: 28900,
    Code: '9119',
    Name: 'Worker, peat'
  },
  {
    Id: 28901,
    Code: '9119',
    Name: 'Worker, seasonal, agriculture, market gardening'
  },
  {
    Id: 28902,
    Code: '9119',
    Name: 'Worker, turf'
  },
  {
    Id: 28903,
    Code: '9119',
    Name: 'Worker, watercress'
  },
  {
    Id: 28904,
    Code: '9121',
    Name: 'Contractor, drainage'
  },
  {
    Id: 28905,
    Code: '9121',
    Name: 'Digger, trench'
  },
  {
    Id: 28906,
    Code: '9121',
    Name: 'Groundworker'
  },
  {
    Id: 28907,
    Code: '9121',
    Name: 'Tester, drain'
  },
  {
    Id: 28908,
    Code: '9121',
    Name: 'Tester, pipe, main laying'
  },
  {
    Id: 28909,
    Code: '9121',
    Name: 'Trenchman'
  },
  {
    Id: 28910,
    Code: '9121',
    Name: 'Worker, drainage'
  },
  {
    Id: 28911,
    Code: '9121',
    Name: 'Worker, ground, building construction'
  },
  {
    Id: 28912,
    Code: '9129',
    Name: 'Applicator, mastic'
  },
  {
    Id: 28913,
    Code: '9129',
    Name: "Assistant, bricklayer's"
  },
  {
    Id: 28914,
    Code: '9129',
    Name: "Assistant, builders'"
  },
  {
    Id: 28915,
    Code: '9129',
    Name: "Assistant, carpenter's"
  },
  {
    Id: 28916,
    Code: '9129',
    Name: "Assistant, diver's, sea"
  },
  {
    Id: 28917,
    Code: '9129',
    Name: "Assistant, electrician's, building construction"
  },
  {
    Id: 28918,
    Code: '9129',
    Name: 'Assistant, general, building maintenance'
  },
  {
    Id: 28919,
    Code: '9129',
    Name: 'Assistant, general, building construction'
  },
  {
    Id: 28920,
    Code: '9129',
    Name: "Assistant, glazier's"
  },
  {
    Id: 28921,
    Code: '9129',
    Name: "Assistant, joiner's"
  },
  {
    Id: 28922,
    Code: '9129',
    Name: "Assistant, mason's"
  },
  {
    Id: 28923,
    Code: '9129',
    Name: "Assistant, plasterer's"
  },
  {
    Id: 28924,
    Code: '9129',
    Name: "Assistant, plumber's"
  },
  {
    Id: 28925,
    Code: '9129',
    Name: "Assistant, regulator's, gas"
  },
  {
    Id: 28926,
    Code: '9129',
    Name: "Assistant, sawyer's"
  },
  {
    Id: 28927,
    Code: '9129',
    Name: 'Assistant, survey, construction'
  },
  {
    Id: 28928,
    Code: '9129',
    Name: 'Assistant, surveying'
  },
  {
    Id: 28929,
    Code: '9129',
    Name: "Assistant, surveyor's"
  },
  {
    Id: 28930,
    Code: '9129',
    Name: "Attendant, diver's"
  },
  {
    Id: 28931,
    Code: '9129',
    Name: 'Attendant, reservoir'
  },
  {
    Id: 28932,
    Code: '9129',
    Name: 'Attendant, river'
  },
  {
    Id: 28933,
    Code: '9129',
    Name: 'Backer-up, sawmilling'
  },
  {
    Id: 28934,
    Code: '9129',
    Name: 'Bailiff, reservoir'
  },
  {
    Id: 28935,
    Code: '9129',
    Name: 'Bailiff, level, sewer commissioners'
  },
  {
    Id: 28936,
    Code: '9129',
    Name: 'Banksman, canal contractors'
  },
  {
    Id: 28937,
    Code: '9129',
    Name: 'Boiler, tar, building construction'
  },
  {
    Id: 28938,
    Code: '9129',
    Name: 'Boy, chain'
  },
  {
    Id: 28939,
    Code: '9129',
    Name: 'Breaker, rock, construction'
  },
  {
    Id: 28940,
    Code: '9129',
    Name: 'Canalman'
  },
  {
    Id: 28941,
    Code: '9129',
    Name: 'Caretaker, reservoir'
  },
  {
    Id: 28942,
    Code: '9129',
    Name: 'Carrier, hod'
  },
  {
    Id: 28943,
    Code: '9129',
    Name: 'Chainman'
  },
  {
    Id: 28944,
    Code: '9129',
    Name: 'Cleaner, river'
  },
  {
    Id: 28945,
    Code: '9129',
    Name: 'Clothier, boiler'
  },
  {
    Id: 28946,
    Code: '9129',
    Name: 'Contractor, penning'
  },
  {
    Id: 28947,
    Code: '9129',
    Name: 'Contractor, coal mine'
  },
  {
    Id: 28948,
    Code: '9129',
    Name: 'Coverer, boiler'
  },
  {
    Id: 28949,
    Code: '9129',
    Name: 'Coverer, pipe'
  },
  {
    Id: 28950,
    Code: '9129',
    Name: 'Digger, grave'
  },
  {
    Id: 28951,
    Code: '9129',
    Name: 'Dismantler, furnace'
  },
  {
    Id: 28952,
    Code: '9129',
    Name: 'Ditcher'
  },
  {
    Id: 28953,
    Code: '9129',
    Name: 'Drainer'
  },
  {
    Id: 28954,
    Code: '9129',
    Name: 'Erector, light, street'
  },
  {
    Id: 28955,
    Code: '9129',
    Name: 'Erector, marquee'
  },
  {
    Id: 28956,
    Code: '9129',
    Name: 'Erector, tent'
  },
  {
    Id: 28957,
    Code: '9129',
    Name: 'Examiner, trench'
  },
  {
    Id: 28958,
    Code: '9129',
    Name: "Fitter, builder's"
  },
  {
    Id: 28959,
    Code: '9129',
    Name: 'Fixer, insulation'
  },
  {
    Id: 28960,
    Code: '9129',
    Name: 'Fixer, lath, metal'
  },
  {
    Id: 28961,
    Code: '9129',
    Name: 'Foreman, length, river, water authority'
  },
  {
    Id: 28962,
    Code: '9129',
    Name: 'Foreman, surface, coal mine'
  },
  {
    Id: 28963,
    Code: '9129',
    Name: 'Foreman, yard, coal mine'
  },
  {
    Id: 28964,
    Code: '9129',
    Name: 'Foreman, river, water authority'
  },
  {
    Id: 28965,
    Code: '9129',
    Name: 'Foreman, waterways'
  },
  {
    Id: 28966,
    Code: '9129',
    Name: 'Ganger, canals'
  },
  {
    Id: 28967,
    Code: '9129',
    Name: 'Hand, insulation'
  },
  {
    Id: 28968,
    Code: '9129',
    Name: 'Hand, maintenance, estate'
  },
  {
    Id: 28969,
    Code: '9129',
    Name: 'Hand, maintenance, canals'
  },
  {
    Id: 28970,
    Code: '9129',
    Name: "Handyman, builder's"
  },
  {
    Id: 28971,
    Code: '9129',
    Name: "Handyman, carpenter's"
  },
  {
    Id: 28972,
    Code: '9129',
    Name: "Handyman, electrician's, building construction"
  },
  {
    Id: 28973,
    Code: '9129',
    Name: "Handyman, fitter's, pipe"
  },
  {
    Id: 28974,
    Code: '9129',
    Name: "Handyman, mate's, pipe"
  },
  {
    Id: 28975,
    Code: '9129',
    Name: 'Handyman-labourer'
  },
  {
    Id: 28976,
    Code: '9129',
    Name: "Help, electrician's, building construction"
  },
  {
    Id: 28977,
    Code: '9129',
    Name: "Helper, bricklayer's"
  },
  {
    Id: 28978,
    Code: '9129',
    Name: "Helper, carpenter's"
  },
  {
    Id: 28979,
    Code: '9129',
    Name: "Helper, electrician's, building construction"
  },
  {
    Id: 28980,
    Code: '9129',
    Name: "Helper, mason's"
  },
  {
    Id: 28981,
    Code: '9129',
    Name: 'Inspector, canal'
  },
  {
    Id: 28982,
    Code: '9129',
    Name: 'Inspector, navigation, river, water authority'
  },
  {
    Id: 28983,
    Code: '9129',
    Name: 'Inspector, river'
  },
  {
    Id: 28984,
    Code: '9129',
    Name: 'Inspector, section, waterways'
  },
  {
    Id: 28985,
    Code: '9129',
    Name: 'Inspector, waterways'
  },
  {
    Id: 28986,
    Code: '9129',
    Name: 'Insulator, loft'
  },
  {
    Id: 28987,
    Code: '9129',
    Name: 'Insulator, pipe'
  },
  {
    Id: 28988,
    Code: '9129',
    Name: 'Insulator, refrigerator'
  },
  {
    Id: 28989,
    Code: '9129',
    Name: "Jobber, agent's, estate"
  },
  {
    Id: 28990,
    Code: '9129',
    Name: "Jobber, builder's"
  },
  {
    Id: 28991,
    Code: '9129',
    Name: 'Jobber, property'
  },
  {
    Id: 28992,
    Code: '9129',
    Name: 'Jobber, building construction'
  },
  {
    Id: 28993,
    Code: '9129',
    Name: 'Keeper, reservoir'
  },
  {
    Id: 28994,
    Code: '9129',
    Name: 'Keeper, wharf, canal'
  },
  {
    Id: 28995,
    Code: '9129',
    Name: "Labourer, bricklayer's"
  },
  {
    Id: 28996,
    Code: '9129',
    Name: "Labourer, bricksetter's"
  },
  {
    Id: 28997,
    Code: '9129',
    Name: "Labourer, builder's, boat"
  },
  {
    Id: 28998,
    Code: '9129',
    Name: "Labourer, builder's"
  },
  {
    Id: 28999,
    Code: '9129',
    Name: 'Labourer, building'
  },
  {
    Id: 29000,
    Code: '9129',
    Name: "Labourer, carpenter's"
  },
  {
    Id: 29001,
    Code: '9129',
    Name: 'Labourer, construction'
  },
  {
    Id: 29002,
    Code: '9129',
    Name: "Labourer, contractor's"
  },
  {
    Id: 29003,
    Code: '9129',
    Name: "Labourer, cooper's"
  },
  {
    Id: 29004,
    Code: '9129',
    Name: "Labourer, electrician's, building construction"
  },
  {
    Id: 29005,
    Code: '9129',
    Name: "Labourer, engineer's, civil"
  },
  {
    Id: 29006,
    Code: '9129',
    Name: 'Labourer, engineering, civil'
  },
  {
    Id: 29007,
    Code: '9129',
    Name: "Labourer, fitter's, shop"
  },
  {
    Id: 29008,
    Code: '9129',
    Name: "Labourer, fixer's, felt"
  },
  {
    Id: 29009,
    Code: '9129',
    Name: "Labourer, fixer's, sheet"
  },
  {
    Id: 29010,
    Code: '9129',
    Name: 'Labourer, general'
  },
  {
    Id: 29011,
    Code: '9129',
    Name: "Labourer, joiner's"
  },
  {
    Id: 29012,
    Code: '9129',
    Name: "Labourer, layer's, granolithic"
  },
  {
    Id: 29013,
    Code: '9129',
    Name: "Labourer, layer's, terrazzo"
  },
  {
    Id: 29014,
    Code: '9129',
    Name: "Labourer, maker's, cabinet"
  },
  {
    Id: 29015,
    Code: '9129',
    Name: "Labourer, mason's, fixer"
  },
  {
    Id: 29016,
    Code: '9129',
    Name: "Labourer, mason's, stone"
  },
  {
    Id: 29017,
    Code: '9129',
    Name: "Labourer, plasterer's"
  },
  {
    Id: 29018,
    Code: '9129',
    Name: "Labourer, platelayer's"
  },
  {
    Id: 29019,
    Code: '9129',
    Name: "Labourer, plumber's"
  },
  {
    Id: 29020,
    Code: '9129',
    Name: "Labourer, roofer's"
  },
  {
    Id: 29021,
    Code: '9129',
    Name: 'Labourer, roofing'
  },
  {
    Id: 29022,
    Code: '9129',
    Name: 'Labourer, scaffold'
  },
  {
    Id: 29023,
    Code: '9129',
    Name: 'Labourer, scaffolding'
  },
  {
    Id: 29024,
    Code: '9129',
    Name: 'Labourer, site'
  },
  {
    Id: 29025,
    Code: '9129',
    Name: "Labourer, slater's"
  },
  {
    Id: 29026,
    Code: '9129',
    Name: "Labourer, steeplejack's"
  },
  {
    Id: 29027,
    Code: '9129',
    Name: 'Labourer, track, electric'
  },
  {
    Id: 29028,
    Code: '9129',
    Name: 'Labourer, building construction'
  },
  {
    Id: 29029,
    Code: '9129',
    Name: 'Labourer-driver'
  },
  {
    Id: 29030,
    Code: '9129',
    Name: 'Lagger, asbestos'
  },
  {
    Id: 29031,
    Code: '9129',
    Name: 'Lagger, boiler'
  },
  {
    Id: 29032,
    Code: '9129',
    Name: 'Lagger, pipe'
  },
  {
    Id: 29033,
    Code: '9129',
    Name: 'Lagger'
  },
  {
    Id: 29034,
    Code: '9129',
    Name: 'Lather, metal'
  },
  {
    Id: 29035,
    Code: '9129',
    Name: 'Lengthman, canals'
  },
  {
    Id: 29036,
    Code: '9129',
    Name: 'Liner, pipe, building construction'
  },
  {
    Id: 29037,
    Code: '9129',
    Name: 'Liner, pipe'
  },
  {
    Id: 29038,
    Code: '9129',
    Name: "Linesman, diver's"
  },
  {
    Id: 29039,
    Code: '9129',
    Name: "Linesman, surveyor's"
  },
  {
    Id: 29040,
    Code: '9129',
    Name: 'Linesman, water works'
  },
  {
    Id: 29041,
    Code: '9129',
    Name: 'Maker, composition, boiler covering'
  },
  {
    Id: 29042,
    Code: '9129',
    Name: 'Man, job, odd'
  },
  {
    Id: 29043,
    Code: '9129',
    Name: 'Man, maintenance, water works'
  },
  {
    Id: 29044,
    Code: '9129',
    Name: 'Man, marsh'
  },
  {
    Id: 29045,
    Code: '9129',
    Name: 'Man, mixer, asphalt, building construction'
  },
  {
    Id: 29046,
    Code: '9129',
    Name: 'Man, timber, building construction'
  },
  {
    Id: 29047,
    Code: '9129',
    Name: 'Man, timber, coal mine: above ground'
  },
  {
    Id: 29048,
    Code: '9129',
    Name: 'Man, timber, electricity supplier'
  },
  {
    Id: 29049,
    Code: '9129',
    Name: 'Man, timber, local government'
  },
  {
    Id: 29050,
    Code: '9129',
    Name: 'Man, timber, railways'
  },
  {
    Id: 29051,
    Code: '9129',
    Name: 'Man, timber, water company'
  },
  {
    Id: 29052,
    Code: '9129',
    Name: "Mate, asphalter's"
  },
  {
    Id: 29053,
    Code: '9129',
    Name: "Mate, builder's"
  },
  {
    Id: 29054,
    Code: '9129',
    Name: "Mate, carpenter's"
  },
  {
    Id: 29055,
    Code: '9129',
    Name: "Mate, carpenter's and joiner's"
  },
  {
    Id: 29056,
    Code: '9129',
    Name: "Mate, coverer's, boiler"
  },
  {
    Id: 29057,
    Code: '9129',
    Name: "Mate, craftsman's"
  },
  {
    Id: 29058,
    Code: '9129',
    Name: "Mate, electrician's, building construction"
  },
  {
    Id: 29059,
    Code: '9129',
    Name: "Mate, fixer's"
  },
  {
    Id: 29060,
    Code: '9129',
    Name: "Mate, flagger's"
  },
  {
    Id: 29061,
    Code: '9129',
    Name: "Mate, glazier's"
  },
  {
    Id: 29062,
    Code: '9129',
    Name: "Mate, joiner's"
  },
  {
    Id: 29063,
    Code: '9129',
    Name: "Mate, jointer's, pipe"
  },
  {
    Id: 29064,
    Code: '9129',
    Name: "Mate, lagger's"
  },
  {
    Id: 29065,
    Code: '9129',
    Name: "Mate, layer's, brick"
  },
  {
    Id: 29066,
    Code: '9129',
    Name: "Mate, layer's, granolithic"
  },
  {
    Id: 29067,
    Code: '9129',
    Name: "Mate, layer's, main"
  },
  {
    Id: 29068,
    Code: '9129',
    Name: "Mate, layer's, plate"
  },
  {
    Id: 29069,
    Code: '9129',
    Name: "Mate, layer's, service"
  },
  {
    Id: 29070,
    Code: '9129',
    Name: "Mate, layer's, terrazzo"
  },
  {
    Id: 29071,
    Code: '9129',
    Name: "Mate, machinist's, wood"
  },
  {
    Id: 29072,
    Code: '9129',
    Name: "Mate, mason's"
  },
  {
    Id: 29073,
    Code: '9129',
    Name: "Mate, painter's"
  },
  {
    Id: 29074,
    Code: '9129',
    Name: "Mate, pavior's"
  },
  {
    Id: 29075,
    Code: '9129',
    Name: "Mate, plasterer's"
  },
  {
    Id: 29076,
    Code: '9129',
    Name: "Mate, plumber and jointer's"
  },
  {
    Id: 29077,
    Code: '9129',
    Name: "Mate, plumber's"
  },
  {
    Id: 29078,
    Code: '9129',
    Name: "Mate, roofer's"
  },
  {
    Id: 29079,
    Code: '9129',
    Name: "Mate, sawyer's"
  },
  {
    Id: 29080,
    Code: '9129',
    Name: "Mate, scaffolder's"
  },
  {
    Id: 29081,
    Code: '9129',
    Name: "Mate, sheeter's"
  },
  {
    Id: 29082,
    Code: '9129',
    Name: "Mate, slater's"
  },
  {
    Id: 29083,
    Code: '9129',
    Name: "Mate, steeplejack's"
  },
  {
    Id: 29084,
    Code: '9129',
    Name: "Mate, tiler's"
  },
  {
    Id: 29085,
    Code: '9129',
    Name: "Mate, timberman's"
  },
  {
    Id: 29086,
    Code: '9129',
    Name: "Mate, wheelwright's"
  },
  {
    Id: 29087,
    Code: '9129',
    Name: 'Mixer, asphalt, building construction'
  },
  {
    Id: 29088,
    Code: '9129',
    Name: 'Mixer, tar, building construction'
  },
  {
    Id: 29089,
    Code: '9129',
    Name: 'Navvy, pond, slurry'
  },
  {
    Id: 29090,
    Code: '9129',
    Name: 'Navvy'
  },
  {
    Id: 29091,
    Code: '9129',
    Name: 'Operative, defence, flood'
  },
  {
    Id: 29092,
    Code: '9129',
    Name: 'Operative, rivers'
  },
  {
    Id: 29093,
    Code: '9129',
    Name: 'Operator, gun, cement'
  },
  {
    Id: 29094,
    Code: '9129',
    Name: 'Operator, tank, asphalt'
  },
  {
    Id: 29095,
    Code: '9129',
    Name: 'Packer, asbestos'
  },
  {
    Id: 29096,
    Code: '9129',
    Name: 'Pipeliner'
  },
  {
    Id: 29097,
    Code: '9129',
    Name: 'Porter-handyman'
  },
  {
    Id: 29098,
    Code: '9129',
    Name: 'Potman, asphalt'
  },
  {
    Id: 29099,
    Code: '9129',
    Name: 'Potman, building construction'
  },
  {
    Id: 29100,
    Code: '9129',
    Name: 'Proofer, damp'
  },
  {
    Id: 29101,
    Code: '9129',
    Name: 'Proofer, water, building construction'
  },
  {
    Id: 29102,
    Code: '9129',
    Name: 'Propman, coal mine: above ground'
  },
  {
    Id: 29103,
    Code: '9129',
    Name: 'Propper, coal mine: above ground'
  },
  {
    Id: 29104,
    Code: '9129',
    Name: 'Puller-off, sawmilling'
  },
  {
    Id: 29105,
    Code: '9129',
    Name: 'Rammerman'
  },
  {
    Id: 29106,
    Code: '9129',
    Name: 'Repairer, bank, canal'
  },
  {
    Id: 29107,
    Code: '9129',
    Name: 'Riverman'
  },
  {
    Id: 29108,
    Code: '9129',
    Name: "Signalman, diver's"
  },
  {
    Id: 29109,
    Code: '9129',
    Name: 'Spreader, building construction'
  },
  {
    Id: 29110,
    Code: '9129',
    Name: 'Superintendent, reservoir'
  },
  {
    Id: 29111,
    Code: '9129',
    Name: 'Tacker, board'
  },
  {
    Id: 29112,
    Code: '9129',
    Name: 'Taker, care, reservoir'
  },
  {
    Id: 29113,
    Code: '9129',
    Name: "Tender, diver's"
  },
  {
    Id: 29114,
    Code: '9129',
    Name: "Tenter, diver's"
  },
  {
    Id: 29115,
    Code: '9129',
    Name: 'Tupper, building construction'
  },
  {
    Id: 29116,
    Code: '9129',
    Name: 'Worker, building'
  },
  {
    Id: 29117,
    Code: '9129',
    Name: 'Worker, canal'
  },
  {
    Id: 29118,
    Code: '9129',
    Name: 'Worker, construction'
  },
  {
    Id: 29119,
    Code: '9129',
    Name: 'Worker, river'
  },
  {
    Id: 29120,
    Code: '9131',
    Name: 'Cleaner, bag'
  },
  {
    Id: 29121,
    Code: '9131',
    Name: 'Cleaner, barrel'
  },
  {
    Id: 29122,
    Code: '9131',
    Name: 'Cleaner, bogie'
  },
  {
    Id: 29123,
    Code: '9131',
    Name: 'Cleaner, boiler'
  },
  {
    Id: 29124,
    Code: '9131',
    Name: 'Cleaner, book'
  },
  {
    Id: 29125,
    Code: '9131',
    Name: 'Cleaner, boot'
  },
  {
    Id: 29126,
    Code: '9131',
    Name: 'Cleaner, bottle'
  },
  {
    Id: 29127,
    Code: '9131',
    Name: 'Cleaner, box, textile printing'
  },
  {
    Id: 29128,
    Code: '9131',
    Name: 'Cleaner, brass'
  },
  {
    Id: 29129,
    Code: '9131',
    Name: 'Cleaner, buddle'
  },
  {
    Id: 29130,
    Code: '9131',
    Name: 'Cleaner, card'
  },
  {
    Id: 29131,
    Code: '9131',
    Name: 'Cleaner, cask'
  },
  {
    Id: 29132,
    Code: '9131',
    Name: 'Cleaner, crane'
  },
  {
    Id: 29133,
    Code: '9131',
    Name: 'Cleaner, cycle'
  },
  {
    Id: 29134,
    Code: '9131',
    Name: 'Cleaner, decomposer, nickel mfr'
  },
  {
    Id: 29135,
    Code: '9131',
    Name: 'Cleaner, drum'
  },
  {
    Id: 29136,
    Code: '9131',
    Name: 'Cleaner, engine, carding'
  },
  {
    Id: 29137,
    Code: '9131',
    Name: 'Cleaner, engine, coal mine'
  },
  {
    Id: 29138,
    Code: '9131',
    Name: 'Cleaner, engine, railways'
  },
  {
    Id: 29139,
    Code: '9131',
    Name: 'Cleaner, engine, water works'
  },
  {
    Id: 29140,
    Code: '9131',
    Name: 'Cleaner, equipment'
  },
  {
    Id: 29141,
    Code: '9131',
    Name: 'Cleaner, factory'
  },
  {
    Id: 29142,
    Code: '9131',
    Name: 'Cleaner, frame, cycles, motors'
  },
  {
    Id: 29143,
    Code: '9131',
    Name: 'Cleaner, gulley'
  },
  {
    Id: 29144,
    Code: '9131',
    Name: 'Cleaner, heald'
  },
  {
    Id: 29145,
    Code: '9131',
    Name: 'Cleaner, heddle'
  },
  {
    Id: 29146,
    Code: '9131',
    Name: 'Cleaner, house, boiler'
  },
  {
    Id: 29147,
    Code: '9131',
    Name: 'Cleaner, house, power'
  },
  {
    Id: 29148,
    Code: '9131',
    Name: 'Cleaner, hydraulic'
  },
  {
    Id: 29149,
    Code: '9131',
    Name: 'Cleaner, hygiene, manufacturing'
  },
  {
    Id: 29150,
    Code: '9131',
    Name: 'Cleaner, industrial'
  },
  {
    Id: 29151,
    Code: '9131',
    Name: 'Cleaner, jet'
  },
  {
    Id: 29152,
    Code: '9131',
    Name: 'Cleaner, key, locks'
  },
  {
    Id: 29153,
    Code: '9131',
    Name: 'Cleaner, laboratory'
  },
  {
    Id: 29154,
    Code: '9131',
    Name: 'Cleaner, lamp'
  },
  {
    Id: 29155,
    Code: '9131',
    Name: 'Cleaner, lens'
  },
  {
    Id: 29156,
    Code: '9131',
    Name: 'Cleaner, line'
  },
  {
    Id: 29157,
    Code: '9131',
    Name: 'Cleaner, loco'
  },
  {
    Id: 29158,
    Code: '9131',
    Name: 'Cleaner, locomotive'
  },
  {
    Id: 29159,
    Code: '9131',
    Name: 'Cleaner, machine'
  },
  {
    Id: 29160,
    Code: '9131',
    Name: 'Cleaner, machinery'
  },
  {
    Id: 29161,
    Code: '9131',
    Name: 'Cleaner, maintenance'
  },
  {
    Id: 29162,
    Code: '9131',
    Name: 'Cleaner, mould'
  },
  {
    Id: 29163,
    Code: '9131',
    Name: 'Cleaner, pipe'
  },
  {
    Id: 29164,
    Code: '9131',
    Name: 'Cleaner, pit, railways'
  },
  {
    Id: 29165,
    Code: '9131',
    Name: 'Cleaner, plant'
  },
  {
    Id: 29166,
    Code: '9131',
    Name: "Cleaner, printer's"
  },
  {
    Id: 29167,
    Code: '9131',
    Name: 'Cleaner, road, mine: not coal'
  },
  {
    Id: 29168,
    Code: '9131',
    Name: 'Cleaner, road and yard, railways'
  },
  {
    Id: 29169,
    Code: '9131',
    Name: 'Cleaner, sack'
  },
  {
    Id: 29170,
    Code: '9131',
    Name: 'Cleaner, scrap'
  },
  {
    Id: 29171,
    Code: '9131',
    Name: 'Cleaner, sheet, plastics mfr'
  },
  {
    Id: 29172,
    Code: '9131',
    Name: 'Cleaner, silver'
  },
  {
    Id: 29173,
    Code: '9131',
    Name: 'Cleaner, steam, building construction'
  },
  {
    Id: 29174,
    Code: '9131',
    Name: 'Cleaner, steam, engineering'
  },
  {
    Id: 29175,
    Code: '9131',
    Name: 'Cleaner, steam, vehicle trades'
  },
  {
    Id: 29176,
    Code: '9131',
    Name: 'Cleaner, still'
  },
  {
    Id: 29177,
    Code: '9131',
    Name: 'Cleaner, tank'
  },
  {
    Id: 29178,
    Code: '9131',
    Name: 'Cleaner, tape, magnetic'
  },
  {
    Id: 29179,
    Code: '9131',
    Name: 'Cleaner, tin, bakery'
  },
  {
    Id: 29180,
    Code: '9131',
    Name: 'Cleaner, tin, food canning'
  },
  {
    Id: 29181,
    Code: '9131',
    Name: 'Cleaner, train'
  },
  {
    Id: 29182,
    Code: '9131',
    Name: 'Cleaner, tray, bakery'
  },
  {
    Id: 29183,
    Code: '9131',
    Name: 'Cleaner, tube, boiler'
  },
  {
    Id: 29184,
    Code: '9131',
    Name: 'Cleaner, tube, blast furnace'
  },
  {
    Id: 29185,
    Code: '9131',
    Name: 'Cleaner, tube, lamp, valve mfr'
  },
  {
    Id: 29186,
    Code: '9131',
    Name: 'Cleaner, tube, railways'
  },
  {
    Id: 29187,
    Code: '9131',
    Name: 'Cleaner, vat'
  },
  {
    Id: 29188,
    Code: '9131',
    Name: 'Cleaner, ware'
  },
  {
    Id: 29189,
    Code: '9131',
    Name: 'Cleaner, works'
  },
  {
    Id: 29190,
    Code: '9131',
    Name: 'Cleaner, factory'
  },
  {
    Id: 29191,
    Code: '9131',
    Name: 'Cleaner, machinery'
  },
  {
    Id: 29192,
    Code: '9131',
    Name: 'Cleaner, brass musical instruments mfr'
  },
  {
    Id: 29193,
    Code: '9131',
    Name: 'Cleaner, metal goods mfr'
  },
  {
    Id: 29194,
    Code: '9131',
    Name: 'Cleaner, needle mfr'
  },
  {
    Id: 29195,
    Code: '9131',
    Name: 'Cleaner, optical instrument mfr'
  },
  {
    Id: 29196,
    Code: '9131',
    Name: 'Cleaner, piano, organ mfr'
  },
  {
    Id: 29197,
    Code: '9131',
    Name: 'Cleaner, printing'
  },
  {
    Id: 29198,
    Code: '9131',
    Name: 'Cleaner and greaser'
  },
  {
    Id: 29199,
    Code: '9131',
    Name: 'Cleaner-engineer'
  },
  {
    Id: 29200,
    Code: '9131',
    Name: 'Cleaner-stoker'
  },
  {
    Id: 29201,
    Code: '9131',
    Name: 'Conditioner, air'
  },
  {
    Id: 29202,
    Code: '9131',
    Name: 'Drainman'
  },
  {
    Id: 29203,
    Code: '9131',
    Name: 'Emptier'
  },
  {
    Id: 29204,
    Code: '9131',
    Name: 'Fireman, refuse disposal'
  },
  {
    Id: 29205,
    Code: '9131',
    Name: 'Fluffer, underground railway'
  },
  {
    Id: 29206,
    Code: '9131',
    Name: 'Foreman, destructor, dust'
  },
  {
    Id: 29207,
    Code: '9131',
    Name: 'Hygienist, night, manufacturing'
  },
  {
    Id: 29208,
    Code: '9131',
    Name: 'Hygienist'
  },
  {
    Id: 29209,
    Code: '9131',
    Name: 'Jetter, water, pressure, high'
  },
  {
    Id: 29210,
    Code: '9131',
    Name: 'Labourer, industrial cleaning'
  },
  {
    Id: 29211,
    Code: '9131',
    Name: 'Machinist, cleaning, seed merchants'
  },
  {
    Id: 29212,
    Code: '9131',
    Name: 'Machinist, jetting, industrial cleaning'
  },
  {
    Id: 29213,
    Code: '9131',
    Name: 'Machinist, washing, bottle'
  },
  {
    Id: 29214,
    Code: '9131',
    Name: 'Man, destructor, refuse'
  },
  {
    Id: 29215,
    Code: '9131',
    Name: 'Man, dry, mine: not coal'
  },
  {
    Id: 29216,
    Code: '9131',
    Name: 'Man, gulley'
  },
  {
    Id: 29217,
    Code: '9131',
    Name: 'Man, jet'
  },
  {
    Id: 29218,
    Code: '9131',
    Name: 'Operative, cleansing, machine'
  },
  {
    Id: 29219,
    Code: '9131',
    Name: 'Operative, hygiene'
  },
  {
    Id: 29220,
    Code: '9131',
    Name: 'Operative, space, confined, industrial cleaning'
  },
  {
    Id: 29221,
    Code: '9131',
    Name: 'Operator, hygiene'
  },
  {
    Id: 29222,
    Code: '9131',
    Name: 'Operator, incinerator'
  },
  {
    Id: 29223,
    Code: '9131',
    Name: 'Rinser, bottle'
  },
  {
    Id: 29224,
    Code: '9131',
    Name: 'Roofman'
  },
  {
    Id: 29225,
    Code: '9131',
    Name: 'Washer, barrel'
  },
  {
    Id: 29226,
    Code: '9131',
    Name: 'Washer, basket, docks'
  },
  {
    Id: 29227,
    Code: '9131',
    Name: 'Washer, bottle'
  },
  {
    Id: 29228,
    Code: '9131',
    Name: 'Washer, box'
  },
  {
    Id: 29229,
    Code: '9131',
    Name: 'Washer, bulb, lamp mfr'
  },
  {
    Id: 29230,
    Code: '9131',
    Name: 'Washer, carriage'
  },
  {
    Id: 29231,
    Code: '9131',
    Name: 'Washer, cask'
  },
  {
    Id: 29232,
    Code: '9131',
    Name: 'Washer, drum'
  },
  {
    Id: 29233,
    Code: '9131',
    Name: 'Washer, jar'
  },
  {
    Id: 29234,
    Code: '9131',
    Name: 'Washer, keg'
  },
  {
    Id: 29235,
    Code: '9131',
    Name: 'Washer, pot'
  },
  {
    Id: 29236,
    Code: '9131',
    Name: 'Worker, hygiene'
  },
  {
    Id: 29237,
    Code: '9132',
    Name: 'Assistant, bottling'
  },
  {
    Id: 29238,
    Code: '9132',
    Name: 'Assistant, general, packing'
  },
  {
    Id: 29239,
    Code: '9132',
    Name: 'Assistant, packing'
  },
  {
    Id: 29240,
    Code: '9132',
    Name: 'Bagger, cake'
  },
  {
    Id: 29241,
    Code: '9132',
    Name: 'Bagger, coal'
  },
  {
    Id: 29242,
    Code: '9132',
    Name: 'Bagger, fibre, asbestos mfr'
  },
  {
    Id: 29243,
    Code: '9132',
    Name: 'Bagger'
  },
  {
    Id: 29244,
    Code: '9132',
    Name: 'Bagger-off, starch mfr'
  },
  {
    Id: 29245,
    Code: '9132',
    Name: 'Bagman, cement mfr'
  },
  {
    Id: 29246,
    Code: '9132',
    Name: 'Baler, paper'
  },
  {
    Id: 29247,
    Code: '9132',
    Name: 'Baler, salle'
  },
  {
    Id: 29248,
    Code: '9132',
    Name: 'Baler'
  },
  {
    Id: 29249,
    Code: '9132',
    Name: 'Bander, cigar'
  },
  {
    Id: 29250,
    Code: '9132',
    Name: 'Bander, coil'
  },
  {
    Id: 29251,
    Code: '9132',
    Name: 'Bander, boot polish mfr'
  },
  {
    Id: 29252,
    Code: '9132',
    Name: 'Bander, iron and steelworks'
  },
  {
    Id: 29253,
    Code: '9132',
    Name: 'Bolter, textile bleaching, dyeing'
  },
  {
    Id: 29254,
    Code: '9132',
    Name: 'Bottler'
  },
  {
    Id: 29255,
    Code: '9132',
    Name: 'Boxer'
  },
  {
    Id: 29256,
    Code: '9132',
    Name: 'Boxer-up, ceramics mfr'
  },
  {
    Id: 29257,
    Code: '9132',
    Name: 'Buncher, watercress'
  },
  {
    Id: 29258,
    Code: '9132',
    Name: 'Bundler, bag'
  },
  {
    Id: 29259,
    Code: '9132',
    Name: 'Bundler, waste, textile mfr'
  },
  {
    Id: 29260,
    Code: '9132',
    Name: 'Bundler'
  },
  {
    Id: 29261,
    Code: '9132',
    Name: 'Bundler and wrapper, cigarettes'
  },
  {
    Id: 29262,
    Code: '9132',
    Name: 'Canner'
  },
  {
    Id: 29263,
    Code: '9132',
    Name: 'Capper, bottle'
  },
  {
    Id: 29264,
    Code: '9132',
    Name: 'Capper, paper'
  },
  {
    Id: 29265,
    Code: '9132',
    Name: 'Capper, polish mfr'
  },
  {
    Id: 29266,
    Code: '9132',
    Name: 'Capsuler'
  },
  {
    Id: 29267,
    Code: '9132',
    Name: 'Carder, comb'
  },
  {
    Id: 29268,
    Code: '9132',
    Name: 'Carder, button mfr'
  },
  {
    Id: 29269,
    Code: '9132',
    Name: 'Carder, hook mfr'
  },
  {
    Id: 29270,
    Code: '9132',
    Name: 'Carder, pencil, crayon mfr'
  },
  {
    Id: 29271,
    Code: '9132',
    Name: 'Cartoner'
  },
  {
    Id: 29272,
    Code: '9132',
    Name: 'Caser, packing'
  },
  {
    Id: 29273,
    Code: '9132',
    Name: 'Cellophaner'
  },
  {
    Id: 29274,
    Code: '9132',
    Name: 'Checker and packer'
  },
  {
    Id: 29275,
    Code: '9132',
    Name: 'Coder, manufacturing'
  },
  {
    Id: 29276,
    Code: '9132',
    Name: 'Cooper, wine'
  },
  {
    Id: 29277,
    Code: '9132',
    Name: 'Corker'
  },
  {
    Id: 29278,
    Code: '9132',
    Name: 'Counter, bolt, nail, nut, rivet, screw mfr'
  },
  {
    Id: 29279,
    Code: '9132',
    Name: 'Counterman, hosiery, knitwear mfr'
  },
  {
    Id: 29280,
    Code: '9132',
    Name: 'Crater, manufacturing'
  },
  {
    Id: 29281,
    Code: '9132',
    Name: 'Dresser, box'
  },
  {
    Id: 29282,
    Code: '9132',
    Name: 'Drummer, glycerine'
  },
  {
    Id: 29283,
    Code: '9132',
    Name: 'Feeder, can'
  },
  {
    Id: 29284,
    Code: '9132',
    Name: 'Filler, ampoule'
  },
  {
    Id: 29285,
    Code: '9132',
    Name: 'Filler, bag'
  },
  {
    Id: 29286,
    Code: '9132',
    Name: 'Filler, barrel'
  },
  {
    Id: 29287,
    Code: '9132',
    Name: 'Filler, bottle'
  },
  {
    Id: 29288,
    Code: '9132',
    Name: 'Filler, box'
  },
  {
    Id: 29289,
    Code: '9132',
    Name: 'Filler, can, paint mfr'
  },
  {
    Id: 29290,
    Code: '9132',
    Name: 'Filler, can, petroleum distribution'
  },
  {
    Id: 29291,
    Code: '9132',
    Name: 'Filler, cylinder'
  },
  {
    Id: 29292,
    Code: '9132',
    Name: 'Filler, drum, oil refining'
  },
  {
    Id: 29293,
    Code: '9132',
    Name: 'Filler, medical, oxygen works'
  },
  {
    Id: 29294,
    Code: '9132',
    Name: 'Filler, oil'
  },
  {
    Id: 29295,
    Code: '9132',
    Name: 'Filler, oxygen'
  },
  {
    Id: 29296,
    Code: '9132',
    Name: 'Filler, paint'
  },
  {
    Id: 29297,
    Code: '9132',
    Name: 'Filler, pickle'
  },
  {
    Id: 29298,
    Code: '9132',
    Name: 'Filler, polish'
  },
  {
    Id: 29299,
    Code: '9132',
    Name: 'Filler, sterile, CSSD'
  },
  {
    Id: 29300,
    Code: '9132',
    Name: 'Filler, varnish'
  },
  {
    Id: 29301,
    Code: '9132',
    Name: 'Filler, brewery'
  },
  {
    Id: 29302,
    Code: '9132',
    Name: 'Filler, cement mfr'
  },
  {
    Id: 29303,
    Code: '9132',
    Name: 'Filler, chemical mfr'
  },
  {
    Id: 29304,
    Code: '9132',
    Name: 'Filler, food products mfr'
  },
  {
    Id: 29305,
    Code: '9132',
    Name: 'Filler, match mfr'
  },
  {
    Id: 29306,
    Code: '9132',
    Name: 'Filler, oil refining'
  },
  {
    Id: 29307,
    Code: '9132',
    Name: 'Filler, paint mfr'
  },
  {
    Id: 29308,
    Code: '9132',
    Name: 'Finisher, jam'
  },
  {
    Id: 29309,
    Code: '9132',
    Name: 'Finisher, pharmaceutical products mfr'
  },
  {
    Id: 29310,
    Code: '9132',
    Name: 'Finisher, toilet preparations mfr'
  },
  {
    Id: 29311,
    Code: '9132',
    Name: "Fitter, box, artists' colours mfr"
  },
  {
    Id: 29312,
    Code: '9132',
    Name: 'Fitter, cork'
  },
  {
    Id: 29313,
    Code: '9132',
    Name: 'Foiler'
  },
  {
    Id: 29314,
    Code: '9132',
    Name: 'Folder, curtain'
  },
  {
    Id: 29315,
    Code: '9132',
    Name: 'Folder, handkerchief'
  },
  {
    Id: 29316,
    Code: '9132',
    Name: 'Folder, net'
  },
  {
    Id: 29317,
    Code: '9132',
    Name: 'Folder, clothing mfr'
  },
  {
    Id: 29318,
    Code: '9132',
    Name: 'Folder, laundry, launderette, dry cleaning'
  },
  {
    Id: 29319,
    Code: '9132',
    Name: 'Folder, textile mfr: hosiery finishing'
  },
  {
    Id: 29320,
    Code: '9132',
    Name: 'Foreman, bottling'
  },
  {
    Id: 29321,
    Code: '9132',
    Name: 'Foreman, room, packing'
  },
  {
    Id: 29322,
    Code: '9132',
    Name: 'Foreman, bottling'
  },
  {
    Id: 29323,
    Code: '9132',
    Name: 'Foreman, food canning'
  },
  {
    Id: 29324,
    Code: '9132',
    Name: 'Foreman, packing service'
  },
  {
    Id: 29325,
    Code: '9132',
    Name: 'Grosser, textile making-up'
  },
  {
    Id: 29326,
    Code: '9132',
    Name: 'Hand, bench, printing: newspaper printing'
  },
  {
    Id: 29327,
    Code: '9132',
    Name: 'Hand, bench, stationers'
  },
  {
    Id: 29328,
    Code: '9132',
    Name: 'Hand, box'
  },
  {
    Id: 29329,
    Code: '9132',
    Name: 'Hanker, textile packing'
  },
  {
    Id: 29330,
    Code: '9132',
    Name: 'Jennier'
  },
  {
    Id: 29331,
    Code: '9132',
    Name: 'Kimballer'
  },
  {
    Id: 29332,
    Code: '9132',
    Name: 'Labeller'
  },
  {
    Id: 29333,
    Code: '9132',
    Name: 'Lidder, boot polish mfr'
  },
  {
    Id: 29334,
    Code: '9132',
    Name: 'Linesman, brewery'
  },
  {
    Id: 29335,
    Code: '9132',
    Name: 'Locker, hat mfr'
  },
  {
    Id: 29336,
    Code: '9132',
    Name: 'Machinist, baling'
  },
  {
    Id: 29337,
    Code: '9132',
    Name: 'Machinist, bottling'
  },
  {
    Id: 29338,
    Code: '9132',
    Name: 'Machinist, capping, bottle'
  },
  {
    Id: 29339,
    Code: '9132',
    Name: 'Machinist, closing, canned foods mfr'
  },
  {
    Id: 29340,
    Code: '9132',
    Name: 'Machinist, filling, cosmetics mfr'
  },
  {
    Id: 29341,
    Code: '9132',
    Name: 'Machinist, filling, food canning'
  },
  {
    Id: 29342,
    Code: '9132',
    Name: 'Machinist, filling and capping'
  },
  {
    Id: 29343,
    Code: '9132',
    Name: 'Machinist, labelling'
  },
  {
    Id: 29344,
    Code: '9132',
    Name: 'Machinist, packing'
  },
  {
    Id: 29345,
    Code: '9132',
    Name: 'Machinist, seaming, canned foods mfr'
  },
  {
    Id: 29346,
    Code: '9132',
    Name: 'Machinist, wrapping'
  },
  {
    Id: 29347,
    Code: '9132',
    Name: 'Machinist-packer'
  },
  {
    Id: 29348,
    Code: '9132',
    Name: 'Maker-up, cloth'
  },
  {
    Id: 29349,
    Code: '9132',
    Name: 'Maker-up, piece'
  },
  {
    Id: 29350,
    Code: '9132',
    Name: 'Maker-up, smallware'
  },
  {
    Id: 29351,
    Code: '9132',
    Name: 'Maker-up, spool'
  },
  {
    Id: 29352,
    Code: '9132',
    Name: 'Maker-up, yarn'
  },
  {
    Id: 29353,
    Code: '9132',
    Name: 'Maker-up, handkerchief mfr'
  },
  {
    Id: 29354,
    Code: '9132',
    Name: 'Man, room, white'
  },
  {
    Id: 29355,
    Code: '9132',
    Name: 'Man, tare, textile mfr'
  },
  {
    Id: 29356,
    Code: '9132',
    Name: 'Operative, packing'
  },
  {
    Id: 29357,
    Code: '9132',
    Name: 'Operator, baler'
  },
  {
    Id: 29358,
    Code: '9132',
    Name: 'Operator, bottling'
  },
  {
    Id: 29359,
    Code: '9132',
    Name: 'Operator, distribution'
  },
  {
    Id: 29360,
    Code: '9132',
    Name: 'Operator, line, packing, wrapping'
  },
  {
    Id: 29361,
    Code: '9132',
    Name: 'Operator, packaging'
  },
  {
    Id: 29362,
    Code: '9132',
    Name: 'Operator, packing'
  },
  {
    Id: 29363,
    Code: '9132',
    Name: 'Operator, plant, packaging'
  },
  {
    Id: 29364,
    Code: '9132',
    Name: 'Operator, process, packing'
  },
  {
    Id: 29365,
    Code: '9132',
    Name: 'Packer, biscuit'
  },
  {
    Id: 29366,
    Code: '9132',
    Name: 'Packer, cable'
  },
  {
    Id: 29367,
    Code: '9132',
    Name: 'Packer, chlorine'
  },
  {
    Id: 29368,
    Code: '9132',
    Name: 'Packer, clothing'
  },
  {
    Id: 29369,
    Code: '9132',
    Name: 'Packer, cop'
  },
  {
    Id: 29370,
    Code: '9132',
    Name: 'Packer, egg'
  },
  {
    Id: 29371,
    Code: '9132',
    Name: 'Packer, factory'
  },
  {
    Id: 29372,
    Code: '9132',
    Name: 'Packer, food'
  },
  {
    Id: 29373,
    Code: '9132',
    Name: 'Packer, fruit'
  },
  {
    Id: 29374,
    Code: '9132',
    Name: 'Packer, meat'
  },
  {
    Id: 29375,
    Code: '9132',
    Name: 'Packer, warehouse'
  },
  {
    Id: 29376,
    Code: '9132',
    Name: 'Packer, wool'
  },
  {
    Id: 29377,
    Code: '9132',
    Name: 'Packer'
  },
  {
    Id: 29378,
    Code: '9132',
    Name: 'Packer and sorter, laundry, launderette, dry cleaning'
  },
  {
    Id: 29379,
    Code: '9132',
    Name: 'Packer and stacker'
  },
  {
    Id: 29380,
    Code: '9132',
    Name: 'Packer-labourer'
  },
  {
    Id: 29381,
    Code: '9132',
    Name: 'Pager, type foundry'
  },
  {
    Id: 29382,
    Code: '9132',
    Name: 'Pairer, hosiery, knitwear mfr'
  },
  {
    Id: 29383,
    Code: '9132',
    Name: 'Palletiser'
  },
  {
    Id: 29384,
    Code: '9132',
    Name: 'Paperer, ceramics mfr'
  },
  {
    Id: 29385,
    Code: '9132',
    Name: 'Paperer, lace mfr'
  },
  {
    Id: 29386,
    Code: '9132',
    Name: 'Parceller'
  },
  {
    Id: 29387,
    Code: '9132',
    Name: 'Picker-packer'
  },
  {
    Id: 29388,
    Code: '9132',
    Name: 'Potter, shrimp'
  },
  {
    Id: 29389,
    Code: '9132',
    Name: 'Presser, hydraulic, packaging activities'
  },
  {
    Id: 29390,
    Code: '9132',
    Name: 'Presser, hydraulic, paper merchants'
  },
  {
    Id: 29391,
    Code: '9132',
    Name: 'Presser, paper'
  },
  {
    Id: 29392,
    Code: '9132',
    Name: 'Presser, textile mfr: textile packing'
  },
  {
    Id: 29393,
    Code: '9132',
    Name: 'Presser, waste merchants'
  },
  {
    Id: 29394,
    Code: '9132',
    Name: 'Putter-up, textile mfr'
  },
  {
    Id: 29395,
    Code: '9132',
    Name: 'Ribboner'
  },
  {
    Id: 29396,
    Code: '9132',
    Name: 'Roper'
  },
  {
    Id: 29397,
    Code: '9132',
    Name: 'Sealer, box'
  },
  {
    Id: 29398,
    Code: '9132',
    Name: 'Seamer, can'
  },
  {
    Id: 29399,
    Code: '9132',
    Name: 'Sighter, bottle, brewery'
  },
  {
    Id: 29400,
    Code: '9132',
    Name: 'Sticker, label'
  },
  {
    Id: 29401,
    Code: '9132',
    Name: 'Sticker, needle mfr'
  },
  {
    Id: 29402,
    Code: '9132',
    Name: 'Stitcher, bale'
  },
  {
    Id: 29403,
    Code: '9132',
    Name: 'Supervisor, packing'
  },
  {
    Id: 29404,
    Code: '9132',
    Name: 'Tabber, glove mfr'
  },
  {
    Id: 29405,
    Code: '9132',
    Name: 'Tabber, hosiery, knitwear mfr'
  },
  {
    Id: 29406,
    Code: '9132',
    Name: 'Technician, packaging'
  },
  {
    Id: 29407,
    Code: '9132',
    Name: 'Ticketer'
  },
  {
    Id: 29408,
    Code: '9132',
    Name: 'Tier, bag'
  },
  {
    Id: 29409,
    Code: '9132',
    Name: 'Tier, hook, cork'
  },
  {
    Id: 29410,
    Code: '9132',
    Name: 'Tier, ream'
  },
  {
    Id: 29411,
    Code: '9132',
    Name: 'Tinner, fruit'
  },
  {
    Id: 29412,
    Code: '9132',
    Name: 'Tinner, food canning'
  },
  {
    Id: 29413,
    Code: '9132',
    Name: 'Topper, jam'
  },
  {
    Id: 29414,
    Code: '9132',
    Name: 'Topper, boot polish mfr'
  },
  {
    Id: 29415,
    Code: '9132',
    Name: 'Worker, bench, newspaper printing'
  },
  {
    Id: 29416,
    Code: '9132',
    Name: 'Worker, cannery'
  },
  {
    Id: 29417,
    Code: '9132',
    Name: 'Worker, factory, packing'
  },
  {
    Id: 29418,
    Code: '9132',
    Name: 'Worker, process, packing'
  },
  {
    Id: 29419,
    Code: '9132',
    Name: 'Worker, process, packing, food products mfr'
  },
  {
    Id: 29420,
    Code: '9132',
    Name: 'Wrapper'
  },
  {
    Id: 29421,
    Code: '9132',
    Name: 'Wrapper-up'
  },
  {
    Id: 29422,
    Code: '9139',
    Name: 'Acetoner'
  },
  {
    Id: 29423,
    Code: '9139',
    Name: "Assistant, barker's"
  },
  {
    Id: 29424,
    Code: '9139',
    Name: "Assistant, burner's"
  },
  {
    Id: 29425,
    Code: '9139',
    Name: "Assistant, checker's, iron"
  },
  {
    Id: 29426,
    Code: '9139',
    Name: 'Assistant, craft, railways'
  },
  {
    Id: 29427,
    Code: '9139',
    Name: "Assistant, electrician's"
  },
  {
    Id: 29428,
    Code: '9139',
    Name: "Assistant, engineer's, maintenance"
  },
  {
    Id: 29429,
    Code: '9139',
    Name: 'Assistant, factory'
  },
  {
    Id: 29430,
    Code: '9139',
    Name: "Assistant, fitter's"
  },
  {
    Id: 29431,
    Code: '9139',
    Name: "Assistant, flanger's"
  },
  {
    Id: 29432,
    Code: '9139',
    Name: "Assistant, galvaniser's"
  },
  {
    Id: 29433,
    Code: '9139',
    Name: "Assistant, grinder's"
  },
  {
    Id: 29434,
    Code: '9139',
    Name: "Assistant, hardener's"
  },
  {
    Id: 29435,
    Code: '9139',
    Name: "Assistant, jointer's"
  },
  {
    Id: 29436,
    Code: '9139',
    Name: "Assistant, lifter's"
  },
  {
    Id: 29437,
    Code: '9139',
    Name: "Assistant, linesman's"
  },
  {
    Id: 29438,
    Code: '9139',
    Name: 'Assistant, linotype'
  },
  {
    Id: 29439,
    Code: '9139',
    Name: "Assistant, loader's"
  },
  {
    Id: 29440,
    Code: '9139',
    Name: "Assistant, maker's, boiler"
  },
  {
    Id: 29441,
    Code: '9139',
    Name: "Assistant, maker's, coach"
  },
  {
    Id: 29442,
    Code: '9139',
    Name: "Assistant, maker's, tool"
  },
  {
    Id: 29443,
    Code: '9139',
    Name: "Assistant, mechanic's"
  },
  {
    Id: 29444,
    Code: '9139',
    Name: 'Assistant, mill'
  },
  {
    Id: 29445,
    Code: '9139',
    Name: "Assistant, millwright's"
  },
  {
    Id: 29446,
    Code: '9139',
    Name: 'Assistant, plant, pilot'
  },
  {
    Id: 29447,
    Code: '9139',
    Name: "Assistant, plater's"
  },
  {
    Id: 29448,
    Code: '9139',
    Name: "Assistant, repairer's, wagon"
  },
  {
    Id: 29449,
    Code: '9139',
    Name: "Assistant, reshearer's"
  },
  {
    Id: 29450,
    Code: '9139',
    Name: 'Assistant, room, receiving, tailoring'
  },
  {
    Id: 29451,
    Code: '9139',
    Name: "Assistant, shipwright's"
  },
  {
    Id: 29452,
    Code: '9139',
    Name: 'Assistant, shop, machine'
  },
  {
    Id: 29453,
    Code: '9139',
    Name: "Assistant, smith's, boiler"
  },
  {
    Id: 29454,
    Code: '9139',
    Name: "Assistant, smith's, copper"
  },
  {
    Id: 29455,
    Code: '9139',
    Name: "Assistant, spreader's, colour"
  },
  {
    Id: 29456,
    Code: '9139',
    Name: 'Assistant, teaser'
  },
  {
    Id: 29457,
    Code: '9139',
    Name: "Assistant, temperer's"
  },
  {
    Id: 29458,
    Code: '9139',
    Name: 'Assistant, trade, shipbuilding'
  },
  {
    Id: 29459,
    Code: '9139',
    Name: 'Assistant, trades, shipbuilding'
  },
  {
    Id: 29460,
    Code: '9139',
    Name: "Assistant, tradesman's, metal trades"
  },
  {
    Id: 29461,
    Code: '9139',
    Name: "Assistant, wireman's"
  },
  {
    Id: 29462,
    Code: '9139',
    Name: 'Attendant, ash'
  },
  {
    Id: 29463,
    Code: '9139',
    Name: 'Attendant, battery, mine: not coal'
  },
  {
    Id: 29464,
    Code: '9139',
    Name: 'Attendant, belt'
  },
  {
    Id: 29465,
    Code: '9139',
    Name: 'Attendant, bunker'
  },
  {
    Id: 29466,
    Code: '9139',
    Name: 'Attendant, car, steel mfr'
  },
  {
    Id: 29467,
    Code: '9139',
    Name: 'Attendant, condenser, blast furnace'
  },
  {
    Id: 29468,
    Code: '9139',
    Name: 'Attendant, conveyor'
  },
  {
    Id: 29469,
    Code: '9139',
    Name: 'Attendant, craft, electricity supplier'
  },
  {
    Id: 29470,
    Code: '9139',
    Name: 'Attendant, elevator, char, wet'
  },
  {
    Id: 29471,
    Code: '9139',
    Name: "Attendant, engineer's, DfT"
  },
  {
    Id: 29472,
    Code: '9139',
    Name: 'Attendant, exhaust'
  },
  {
    Id: 29473,
    Code: '9139',
    Name: 'Attendant, exhauster'
  },
  {
    Id: 29474,
    Code: '9139',
    Name: 'Attendant, fan'
  },
  {
    Id: 29475,
    Code: '9139',
    Name: 'Attendant, gearhead'
  },
  {
    Id: 29476,
    Code: '9139',
    Name: 'Attendant, governor, gas works'
  },
  {
    Id: 29477,
    Code: '9139',
    Name: 'Attendant, gun'
  },
  {
    Id: 29478,
    Code: '9139',
    Name: 'Attendant, hopper, mine: not coal'
  },
  {
    Id: 29479,
    Code: '9139',
    Name: 'Attendant, house, blower'
  },
  {
    Id: 29480,
    Code: '9139',
    Name: 'Attendant, house, engine'
  },
  {
    Id: 29481,
    Code: '9139',
    Name: 'Attendant, house, exhaust'
  },
  {
    Id: 29482,
    Code: '9139',
    Name: 'Attendant, house, meter, gas works'
  },
  {
    Id: 29483,
    Code: '9139',
    Name: 'Attendant, house, pump'
  },
  {
    Id: 29484,
    Code: '9139',
    Name: 'Attendant, house, screen'
  },
  {
    Id: 29485,
    Code: '9139',
    Name: 'Attendant, incinerator, hospital service'
  },
  {
    Id: 29486,
    Code: '9139',
    Name: 'Attendant, locomotive'
  },
  {
    Id: 29487,
    Code: '9139',
    Name: 'Attendant, main, hydraulic'
  },
  {
    Id: 29488,
    Code: '9139',
    Name: 'Attendant, plant, ash'
  },
  {
    Id: 29489,
    Code: '9139',
    Name: 'Attendant, plant, cleaning, air'
  },
  {
    Id: 29490,
    Code: '9139',
    Name: 'Attendant, plant, coke'
  },
  {
    Id: 29491,
    Code: '9139',
    Name: 'Attendant, plant, conditioning, air'
  },
  {
    Id: 29492,
    Code: '9139',
    Name: 'Attendant, plant, drainage, mining'
  },
  {
    Id: 29493,
    Code: '9139',
    Name: 'Attendant, plant, refrigerating'
  },
  {
    Id: 29494,
    Code: '9139',
    Name: 'Attendant, plant, washing, vehicles'
  },
  {
    Id: 29495,
    Code: '9139',
    Name: 'Attendant, point, transfer, conveyor'
  },
  {
    Id: 29496,
    Code: '9139',
    Name: 'Attendant, quencher, coke'
  },
  {
    Id: 29497,
    Code: '9139',
    Name: 'Attendant, refrigerator'
  },
  {
    Id: 29498,
    Code: '9139',
    Name: 'Attendant, room, battery'
  },
  {
    Id: 29499,
    Code: '9139',
    Name: 'Attendant, room, engine'
  },
  {
    Id: 29500,
    Code: '9139',
    Name: 'Attendant, scale, green'
  },
  {
    Id: 29501,
    Code: '9139',
    Name: 'Attendant, scrubber, coke ovens'
  },
  {
    Id: 29502,
    Code: '9139',
    Name: 'Attendant, station, gas works'
  },
  {
    Id: 29503,
    Code: '9139',
    Name: 'Attendant, tank, cable mfr'
  },
  {
    Id: 29504,
    Code: '9139',
    Name: 'Attendant, tank, local government'
  },
  {
    Id: 29505,
    Code: '9139',
    Name: 'Attendant, valve'
  },
  {
    Id: 29506,
    Code: '9139',
    Name: 'Attendant, ventilation'
  },
  {
    Id: 29507,
    Code: '9139',
    Name: 'Auxiliary, craft, DfT'
  },
  {
    Id: 29508,
    Code: '9139',
    Name: 'Baler, oil wells'
  },
  {
    Id: 29509,
    Code: '9139',
    Name: 'Bankman, rolling mill'
  },
  {
    Id: 29510,
    Code: '9139',
    Name: 'Banksman, blast furnace'
  },
  {
    Id: 29511,
    Code: '9139',
    Name: 'Bayman, steelworks'
  },
  {
    Id: 29512,
    Code: '9139',
    Name: 'Beltman, patent fuel mfr'
  },
  {
    Id: 29513,
    Code: '9139',
    Name: 'Binder, iron'
  },
  {
    Id: 29514,
    Code: '9139',
    Name: 'Blockman, metal trades'
  },
  {
    Id: 29515,
    Code: '9139',
    Name: 'Bowler, tyre'
  },
  {
    Id: 29516,
    Code: '9139',
    Name: 'Bowler, steelworks'
  },
  {
    Id: 29517,
    Code: '9139',
    Name: 'Boy, best'
  },
  {
    Id: 29518,
    Code: '9139',
    Name: 'Boy, clay, metal mfr'
  },
  {
    Id: 29519,
    Code: '9139',
    Name: 'Brakesman, engine'
  },
  {
    Id: 29520,
    Code: '9139',
    Name: 'Brakesman, blast furnace'
  },
  {
    Id: 29521,
    Code: '9139',
    Name: 'Breaker, iron'
  },
  {
    Id: 29522,
    Code: '9139',
    Name: 'Breaker, blast or puddling furnace'
  },
  {
    Id: 29523,
    Code: '9139',
    Name: 'Breaker-up'
  },
  {
    Id: 29524,
    Code: '9139',
    Name: 'Breakerman, foundry'
  },
  {
    Id: 29525,
    Code: '9139',
    Name: 'Brusher, clothing mfr'
  },
  {
    Id: 29526,
    Code: '9139',
    Name: 'Brusher, metal file mfr'
  },
  {
    Id: 29527,
    Code: '9139',
    Name: 'Brusher-off, clothing mfr'
  },
  {
    Id: 29528,
    Code: '9139',
    Name: 'Bunkerman, coal gas, coke ovens'
  },
  {
    Id: 29529,
    Code: '9139',
    Name: 'Carman, blast furnace'
  },
  {
    Id: 29530,
    Code: '9139',
    Name: 'Carrier, bag'
  },
  {
    Id: 29531,
    Code: '9139',
    Name: 'Carrier, dust'
  },
  {
    Id: 29532,
    Code: '9139',
    Name: 'Carrier, glass, glass mfr'
  },
  {
    Id: 29533,
    Code: '9139',
    Name: 'Carrier, lap'
  },
  {
    Id: 29534,
    Code: '9139',
    Name: 'Carrier, piece'
  },
  {
    Id: 29535,
    Code: '9139',
    Name: 'Carrier, roller'
  },
  {
    Id: 29536,
    Code: '9139',
    Name: 'Carrier, ware'
  },
  {
    Id: 29537,
    Code: '9139',
    Name: 'Carrier, mine: not coal: below ground'
  },
  {
    Id: 29538,
    Code: '9139',
    Name: 'Carrier'
  },
  {
    Id: 29539,
    Code: '9139',
    Name: 'Carrier-away'
  },
  {
    Id: 29540,
    Code: '9139',
    Name: 'Carrier-off'
  },
  {
    Id: 29541,
    Code: '9139',
    Name: 'Cellarman, bacon, ham, meat curing'
  },
  {
    Id: 29542,
    Code: '9139',
    Name: 'Charger, cupola'
  },
  {
    Id: 29543,
    Code: '9139',
    Name: 'Charger, dust, flux'
  },
  {
    Id: 29544,
    Code: '9139',
    Name: 'Charger, furnace, metal mfr'
  },
  {
    Id: 29545,
    Code: '9139',
    Name: 'Charger, spare, blast furnace'
  },
  {
    Id: 29546,
    Code: '9139',
    Name: 'Charger, tube, brass tube mfr'
  },
  {
    Id: 29547,
    Code: '9139',
    Name: 'Charger, metal mfr'
  },
  {
    Id: 29548,
    Code: '9139',
    Name: 'Charman, wet'
  },
  {
    Id: 29549,
    Code: '9139',
    Name: 'Charman, sugar refining'
  },
  {
    Id: 29550,
    Code: '9139',
    Name: 'Chipper and scaler'
  },
  {
    Id: 29551,
    Code: '9139',
    Name: 'Clearer, bottom, tuyere'
  },
  {
    Id: 29552,
    Code: '9139',
    Name: 'Coaler'
  },
  {
    Id: 29553,
    Code: '9139',
    Name: 'Collector, ash'
  },
  {
    Id: 29554,
    Code: '9139',
    Name: 'Collector, cloth'
  },
  {
    Id: 29555,
    Code: '9139',
    Name: 'Collector, cop'
  },
  {
    Id: 29556,
    Code: '9139',
    Name: 'Collector, glass, dry plates'
  },
  {
    Id: 29557,
    Code: '9139',
    Name: 'Collector, pit, ash'
  },
  {
    Id: 29558,
    Code: '9139',
    Name: 'Collector, scaleboard'
  },
  {
    Id: 29559,
    Code: '9139',
    Name: 'Collector, textile mfr'
  },
  {
    Id: 29560,
    Code: '9139',
    Name: 'Controller, charge, metal mfr'
  },
  {
    Id: 29561,
    Code: '9139',
    Name: 'Controller, coal, metal mfr'
  },
  {
    Id: 29562,
    Code: '9139',
    Name: 'Controlman, bunker'
  },
  {
    Id: 29563,
    Code: '9139',
    Name: 'Conveyor'
  },
  {
    Id: 29564,
    Code: '9139',
    Name: 'Craftsman, assistant, metal trades'
  },
  {
    Id: 29565,
    Code: '9139',
    Name: 'Dauber, ladle, iron and steelworks'
  },
  {
    Id: 29566,
    Code: '9139',
    Name: 'Dauber, coal gas, coke ovens'
  },
  {
    Id: 29567,
    Code: '9139',
    Name: 'Deliverer, textile mfr'
  },
  {
    Id: 29568,
    Code: '9139',
    Name: 'Deliveryman, textile mfr'
  },
  {
    Id: 29569,
    Code: '9139',
    Name: 'Depotman, blast furnace'
  },
  {
    Id: 29570,
    Code: '9139',
    Name: "Devil, printer's"
  },
  {
    Id: 29571,
    Code: '9139',
    Name: 'Devil, printing'
  },
  {
    Id: 29572,
    Code: '9139',
    Name: 'Discharger, coal gas, coke ovens'
  },
  {
    Id: 29573,
    Code: '9139',
    Name: 'Distributor, weft'
  },
  {
    Id: 29574,
    Code: '9139',
    Name: 'Distributor, work'
  },
  {
    Id: 29575,
    Code: '9139',
    Name: 'Distributor, manufacturing'
  },
  {
    Id: 29576,
    Code: '9139',
    Name: 'Doorman, coke ovens'
  },
  {
    Id: 29577,
    Code: '9139',
    Name: 'Dragger, pipe, brickworks'
  },
  {
    Id: 29578,
    Code: '9139',
    Name: 'Dragger, set'
  },
  {
    Id: 29579,
    Code: '9139',
    Name: 'Dragger, skip'
  },
  {
    Id: 29580,
    Code: '9139',
    Name: 'Drainer, brewery'
  },
  {
    Id: 29581,
    Code: '9139',
    Name: 'Drawer, brick'
  },
  {
    Id: 29582,
    Code: '9139',
    Name: 'Drawer, coke, coke ovens'
  },
  {
    Id: 29583,
    Code: '9139',
    Name: 'Drawer, kiln, ceramics mfr'
  },
  {
    Id: 29584,
    Code: '9139',
    Name: 'Drawer, lime, mine: not coal'
  },
  {
    Id: 29585,
    Code: '9139',
    Name: 'Drawer, oven, ceramics mfr'
  },
  {
    Id: 29586,
    Code: '9139',
    Name: 'Drawer, ceramics mfr'
  },
  {
    Id: 29587,
    Code: '9139',
    Name: 'Drawer, mine: not coal'
  },
  {
    Id: 29588,
    Code: '9139',
    Name: 'Drawer, paper mfr'
  },
  {
    Id: 29589,
    Code: '9139',
    Name: 'Driver, belt'
  },
  {
    Id: 29590,
    Code: '9139',
    Name: 'Driver, car, electric, steelworks'
  },
  {
    Id: 29591,
    Code: '9139',
    Name: 'Driver, car, furnace, blast'
  },
  {
    Id: 29592,
    Code: '9139',
    Name: 'Driver, charger, steelworks'
  },
  {
    Id: 29593,
    Code: '9139',
    Name: 'Driver, controller, steelworks'
  },
  {
    Id: 29594,
    Code: '9139',
    Name: 'Driver, conveyor'
  },
  {
    Id: 29595,
    Code: '9139',
    Name: 'Driver, exhauster, gas works'
  },
  {
    Id: 29596,
    Code: '9139',
    Name: 'Driver, fan'
  },
  {
    Id: 29597,
    Code: '9139',
    Name: 'Driver, guide, coke'
  },
  {
    Id: 29598,
    Code: '9139',
    Name: 'Driver, manipulator, steelworks'
  },
  {
    Id: 29599,
    Code: '9139',
    Name: 'Duffer'
  },
  {
    Id: 29600,
    Code: '9139',
    Name: 'Emptier, biscuit'
  },
  {
    Id: 29601,
    Code: '9139',
    Name: 'Emptier, rubbish, steelworks'
  },
  {
    Id: 29602,
    Code: '9139',
    Name: 'Emptier, ware'
  },
  {
    Id: 29603,
    Code: '9139',
    Name: 'Emptier, wheel'
  },
  {
    Id: 29604,
    Code: '9139',
    Name: 'Emptier, ceramics mfr'
  },
  {
    Id: 29605,
    Code: '9139',
    Name: 'Erector, wicket, ceramics mfr'
  },
  {
    Id: 29606,
    Code: '9139',
    Name: 'Feeder, belt'
  },
  {
    Id: 29607,
    Code: '9139',
    Name: 'Feeder, bin'
  },
  {
    Id: 29608,
    Code: '9139',
    Name: 'Feeder, line'
  },
  {
    Id: 29609,
    Code: '9139',
    Name: 'Feeder, cement mfr'
  },
  {
    Id: 29610,
    Code: '9139',
    Name: 'Felter, shipbuilding'
  },
  {
    Id: 29611,
    Code: '9139',
    Name: 'Fetcher, textile mfr'
  },
  {
    Id: 29612,
    Code: '9139',
    Name: 'Fielder, textile mfr'
  },
  {
    Id: 29613,
    Code: '9139',
    Name: 'Filler, barrow'
  },
  {
    Id: 29614,
    Code: '9139',
    Name: 'Filler, box, blast furnace'
  },
  {
    Id: 29615,
    Code: '9139',
    Name: 'Filler, coke'
  },
  {
    Id: 29616,
    Code: '9139',
    Name: 'Filler, furnace, blast furnace'
  },
  {
    Id: 29617,
    Code: '9139',
    Name: 'Filler, pot, steelworks'
  },
  {
    Id: 29618,
    Code: '9139',
    Name: 'Filler, salt'
  },
  {
    Id: 29619,
    Code: '9139',
    Name: 'Filler, spare'
  },
  {
    Id: 29620,
    Code: '9139',
    Name: 'Filler, tray'
  },
  {
    Id: 29621,
    Code: '9139',
    Name: 'Filler, wagon'
  },
  {
    Id: 29622,
    Code: '9139',
    Name: 'Filler, blast furnace'
  },
  {
    Id: 29623,
    Code: '9139',
    Name: 'Filler, mine: not coal'
  },
  {
    Id: 29624,
    Code: '9139',
    Name: 'Filler, petroleum distribution'
  },
  {
    Id: 29625,
    Code: '9139',
    Name: 'Filler-loader, petroleum distribution'
  },
  {
    Id: 29626,
    Code: '9139',
    Name: 'Floater, blast furnace'
  },
  {
    Id: 29627,
    Code: '9139',
    Name: 'Flyman'
  },
  {
    Id: 29628,
    Code: '9139',
    Name: 'Foreman, belt, mine: not coal'
  },
  {
    Id: 29629,
    Code: '9139',
    Name: 'Foreman, flat, card clothing mfr'
  },
  {
    Id: 29630,
    Code: '9139',
    Name: 'Foreman, transport, internal'
  },
  {
    Id: 29631,
    Code: '9139',
    Name: 'Foreman, yard, scrap'
  },
  {
    Id: 29632,
    Code: '9139',
    Name: 'Foreman, yard'
  },
  {
    Id: 29633,
    Code: '9139',
    Name: 'Foreman, scrap merchants, breakers'
  },
  {
    Id: 29634,
    Code: '9139',
    Name: 'Foreman, wallpaper mfr'
  },
  {
    Id: 29635,
    Code: '9139',
    Name: 'Gasman, blast furnace'
  },
  {
    Id: 29636,
    Code: '9139',
    Name: 'Gatherer, rag, woollen mfr'
  },
  {
    Id: 29637,
    Code: '9139',
    Name: 'Gatherer, ceramics mfr'
  },
  {
    Id: 29638,
    Code: '9139',
    Name: 'Grainer, brewery'
  },
  {
    Id: 29639,
    Code: '9139',
    Name: 'Grater, steelworks'
  },
  {
    Id: 29640,
    Code: '9139',
    Name: 'Greaser, tin box mfr'
  },
  {
    Id: 29641,
    Code: '9139',
    Name: 'Grinder, sweep'
  },
  {
    Id: 29642,
    Code: '9139',
    Name: 'Guard, train, ropes'
  },
  {
    Id: 29643,
    Code: '9139',
    Name: 'Hammerman, jute mfr'
  },
  {
    Id: 29644,
    Code: '9139',
    Name: 'Hand, belt'
  },
  {
    Id: 29645,
    Code: '9139',
    Name: 'Hand, bench, printing'
  },
  {
    Id: 29646,
    Code: '9139',
    Name: 'Hand, box, sugar refining'
  },
  {
    Id: 29647,
    Code: '9139',
    Name: 'Hand, cable'
  },
  {
    Id: 29648,
    Code: '9139',
    Name: 'Hand, conveyor'
  },
  {
    Id: 29649,
    Code: '9139',
    Name: 'Hand, datal, mine: not coal'
  },
  {
    Id: 29650,
    Code: '9139',
    Name: 'Hand, foundry'
  },
  {
    Id: 29651,
    Code: '9139',
    Name: 'Hand, granary'
  },
  {
    Id: 29652,
    Code: '9139',
    Name: 'Hand, haulage, mine: not coal'
  },
  {
    Id: 29653,
    Code: '9139',
    Name: 'Hand, house, waste, textile mfr'
  },
  {
    Id: 29654,
    Code: '9139',
    Name: 'Hand, maintenance'
  },
  {
    Id: 29655,
    Code: '9139',
    Name: 'Hand, mill, nitrate'
  },
  {
    Id: 29656,
    Code: '9139',
    Name: 'Hand, pump'
  },
  {
    Id: 29657,
    Code: '9139',
    Name: 'Hand, warehouse, lace'
  },
  {
    Id: 29658,
    Code: '9139',
    Name: 'Handler, body, vehicle mfr'
  },
  {
    Id: 29659,
    Code: '9139',
    Name: 'Handler, coal'
  },
  {
    Id: 29660,
    Code: '9139',
    Name: 'Handler, material'
  },
  {
    Id: 29661,
    Code: '9139',
    Name: 'Handler, materials'
  },
  {
    Id: 29662,
    Code: '9139',
    Name: 'Handler, sheet, metal'
  },
  {
    Id: 29663,
    Code: '9139',
    Name: 'Handler, stock, asbestos-cement goods mfr'
  },
  {
    Id: 29664,
    Code: '9139',
    Name: "Handyman, electrician's"
  },
  {
    Id: 29665,
    Code: '9139',
    Name: "Handyman, engineer's"
  },
  {
    Id: 29666,
    Code: '9139',
    Name: "Handyman, fitter's"
  },
  {
    Id: 29667,
    Code: '9139',
    Name: 'Handyman, engineering'
  },
  {
    Id: 29668,
    Code: '9139',
    Name: 'Haulerman'
  },
  {
    Id: 29669,
    Code: '9139',
    Name: "Help, electrician's"
  },
  {
    Id: 29670,
    Code: '9139',
    Name: "Help, moulder's"
  },
  {
    Id: 29671,
    Code: '9139',
    Name: "Help, plater's"
  },
  {
    Id: 29672,
    Code: '9139',
    Name: "Helper, bender's, frame"
  },
  {
    Id: 29673,
    Code: '9139',
    Name: "Helper, electrician's"
  },
  {
    Id: 29674,
    Code: '9139',
    Name: "Helper, erector's, steel"
  },
  {
    Id: 29675,
    Code: '9139',
    Name: "Helper, fitter's"
  },
  {
    Id: 29676,
    Code: '9139',
    Name: "Helper, maker's, boiler"
  },
  {
    Id: 29677,
    Code: '9139',
    Name: "Helper, plater's"
  },
  {
    Id: 29678,
    Code: '9139',
    Name: "Helper, shearer's, metal trades"
  },
  {
    Id: 29679,
    Code: '9139',
    Name: "Helper, smith's, boiler"
  },
  {
    Id: 29680,
    Code: '9139',
    Name: "Helper, straightener's"
  },
  {
    Id: 29681,
    Code: '9139',
    Name: 'Helper, metal trades'
  },
  {
    Id: 29682,
    Code: '9139',
    Name: 'Helper-up'
  },
  {
    Id: 29683,
    Code: '9139',
    Name: 'Holder at drill, rolling mill'
  },
  {
    Id: 29684,
    Code: '9139',
    Name: "Holder-on, riveter's"
  },
  {
    Id: 29685,
    Code: '9139',
    Name: "Holder-up, boilermaker's"
  },
  {
    Id: 29686,
    Code: '9139',
    Name: "Holder-up, riveter's"
  },
  {
    Id: 29687,
    Code: '9139',
    Name: 'Hooker, mine: not coal'
  },
  {
    Id: 29688,
    Code: '9139',
    Name: 'Hookman'
  },
  {
    Id: 29689,
    Code: '9139',
    Name: 'Hopperman, dredge'
  },
  {
    Id: 29690,
    Code: '9139',
    Name: 'Hopperman, dredging contractors'
  },
  {
    Id: 29691,
    Code: '9139',
    Name: 'Hopperman, iron and steelworks'
  },
  {
    Id: 29692,
    Code: '9139',
    Name: 'Horseman, tannery'
  },
  {
    Id: 29693,
    Code: '9139',
    Name: 'Hugger-off'
  },
  {
    Id: 29694,
    Code: '9139',
    Name: 'Humper, coal'
  },
  {
    Id: 29695,
    Code: '9139',
    Name: 'Hydraulicman, docks'
  },
  {
    Id: 29696,
    Code: '9139',
    Name: 'Iron and steelworker'
  },
  {
    Id: 29697,
    Code: '9139',
    Name: 'Issuer, work, engineering'
  },
  {
    Id: 29698,
    Code: '9139',
    Name: 'Jackman'
  },
  {
    Id: 29699,
    Code: '9139',
    Name: 'Jigman, constructional engineering'
  },
  {
    Id: 29700,
    Code: '9139',
    Name: 'Keeper, stanch'
  },
  {
    Id: 29701,
    Code: '9139',
    Name: 'Knocker, catch, pit, staple'
  },
  {
    Id: 29702,
    Code: '9139',
    Name: 'Knocker-off, foundry'
  },
  {
    Id: 29703,
    Code: '9139',
    Name: 'Knocker-out, foundry'
  },
  {
    Id: 29704,
    Code: '9139',
    Name: 'Labourer, brewery'
  },
  {
    Id: 29705,
    Code: '9139',
    Name: 'Labourer, bulking-floor'
  },
  {
    Id: 29706,
    Code: '9139',
    Name: 'Labourer, dock, dry'
  },
  {
    Id: 29707,
    Code: '9139',
    Name: "Labourer, electrician's"
  },
  {
    Id: 29708,
    Code: '9139',
    Name: "Labourer, erector's"
  },
  {
    Id: 29709,
    Code: '9139',
    Name: 'Labourer, factory'
  },
  {
    Id: 29710,
    Code: '9139',
    Name: "Labourer, fitter's"
  },
  {
    Id: 29711,
    Code: '9139',
    Name: "Labourer, fixer's, steel"
  },
  {
    Id: 29712,
    Code: '9139',
    Name: 'Labourer, foundry'
  },
  {
    Id: 29713,
    Code: '9139',
    Name: 'Labourer, grab'
  },
  {
    Id: 29714,
    Code: '9139',
    Name: "Labourer, mechanic's"
  },
  {
    Id: 29715,
    Code: '9139',
    Name: "Labourer, millwright's"
  },
  {
    Id: 29716,
    Code: '9139',
    Name: "Labourer, moulder's"
  },
  {
    Id: 29717,
    Code: '9139',
    Name: "Labourer, plater's"
  },
  {
    Id: 29718,
    Code: '9139',
    Name: 'Labourer, production'
  },
  {
    Id: 29719,
    Code: '9139',
    Name: "Labourer, rigger's"
  },
  {
    Id: 29720,
    Code: '9139',
    Name: "Labourer, riveter's"
  },
  {
    Id: 29721,
    Code: '9139',
    Name: "Labourer, shipwright's"
  },
  {
    Id: 29722,
    Code: '9139',
    Name: 'Labourer, textile'
  },
  {
    Id: 29723,
    Code: '9139',
    Name: 'Labourer, engineering'
  },
  {
    Id: 29724,
    Code: '9139',
    Name: 'Labourer, foundry'
  },
  {
    Id: 29725,
    Code: '9139',
    Name: 'Labourer, galvanising, tinning'
  },
  {
    Id: 29726,
    Code: '9139',
    Name: 'Labourer, manufacturing'
  },
  {
    Id: 29727,
    Code: '9139',
    Name: 'Labourer, steelworks'
  },
  {
    Id: 29728,
    Code: '9139',
    Name: 'Lad, roller, metal trades'
  },
  {
    Id: 29729,
    Code: '9139',
    Name: 'Lagger, drum'
  },
  {
    Id: 29730,
    Code: '9139',
    Name: 'Lander, clay'
  },
  {
    Id: 29731,
    Code: '9139',
    Name: 'Lander, mine: not coal: above ground'
  },
  {
    Id: 29732,
    Code: '9139',
    Name: 'Layer, glass, plate'
  },
  {
    Id: 29733,
    Code: '9139',
    Name: 'Layer, tray'
  },
  {
    Id: 29734,
    Code: '9139',
    Name: 'Layer-on, metal file mfr'
  },
  {
    Id: 29735,
    Code: '9139',
    Name: 'Layer-out, textile mfr'
  },
  {
    Id: 29736,
    Code: '9139',
    Name: 'Leader, timber'
  },
  {
    Id: 29737,
    Code: '9139',
    Name: 'Leader, water'
  },
  {
    Id: 29738,
    Code: '9139',
    Name: 'Leadsman'
  },
  {
    Id: 29739,
    Code: '9139',
    Name: 'Leveller, coke ovens'
  },
  {
    Id: 29740,
    Code: '9139',
    Name: 'Lifter, box'
  },
  {
    Id: 29741,
    Code: '9139',
    Name: 'Lifter, coke'
  },
  {
    Id: 29742,
    Code: '9139',
    Name: 'Lifter-up, foundry'
  },
  {
    Id: 29743,
    Code: '9139',
    Name: 'Lighter, fire'
  },
  {
    Id: 29744,
    Code: '9139',
    Name: 'Limer, blast furnace'
  },
  {
    Id: 29745,
    Code: '9139',
    Name: 'Loader, bulk, petroleum distribution'
  },
  {
    Id: 29746,
    Code: '9139',
    Name: 'Loader, coal'
  },
  {
    Id: 29747,
    Code: '9139',
    Name: 'Loader, coke'
  },
  {
    Id: 29748,
    Code: '9139',
    Name: 'Loader, conveyor'
  },
  {
    Id: 29749,
    Code: '9139',
    Name: 'Loader, furnace, metal trades'
  },
  {
    Id: 29750,
    Code: '9139',
    Name: 'Loader, machine'
  },
  {
    Id: 29751,
    Code: '9139',
    Name: 'Loader, mechanical, mine: not coal'
  },
  {
    Id: 29752,
    Code: '9139',
    Name: 'Loader, milk, dairy'
  },
  {
    Id: 29753,
    Code: '9139',
    Name: 'Loader, paper'
  },
  {
    Id: 29754,
    Code: '9139',
    Name: 'Loader, stone, mine: not coal'
  },
  {
    Id: 29755,
    Code: '9139',
    Name: 'Loader, timber, sawmilling'
  },
  {
    Id: 29756,
    Code: '9139',
    Name: 'Loader, wagon'
  },
  {
    Id: 29757,
    Code: '9139',
    Name: 'Loader, coke ovens'
  },
  {
    Id: 29758,
    Code: '9139',
    Name: 'Loader, mine: not coal'
  },
  {
    Id: 29759,
    Code: '9139',
    Name: 'Lobber, card, pasteboard mfr'
  },
  {
    Id: 29760,
    Code: '9139',
    Name: 'Lowerer, wagon'
  },
  {
    Id: 29761,
    Code: '9139',
    Name: 'Lubricator, gun mfr'
  },
  {
    Id: 29762,
    Code: '9139',
    Name: 'Luter, coke ovens'
  },
  {
    Id: 29763,
    Code: '9139',
    Name: 'Machinist, coal gas, coke ovens'
  },
  {
    Id: 29764,
    Code: '9139',
    Name: 'Machinist, PLA'
  },
  {
    Id: 29765,
    Code: '9139',
    Name: 'Man, acid, dyestuffs mfr'
  },
  {
    Id: 29766,
    Code: '9139',
    Name: 'Man, ash'
  },
  {
    Id: 29767,
    Code: '9139',
    Name: 'Man, ash and muck'
  },
  {
    Id: 29768,
    Code: '9139',
    Name: 'Man, bargain, mine: not coal: above ground'
  },
  {
    Id: 29769,
    Code: '9139',
    Name: 'Man, barrow'
  },
  {
    Id: 29770,
    Code: '9139',
    Name: 'Man, battery, coke ovens'
  },
  {
    Id: 29771,
    Code: '9139',
    Name: 'Man, battery, mine: not coal'
  },
  {
    Id: 29772,
    Code: '9139',
    Name: 'Man, bellows, shipbuilding'
  },
  {
    Id: 29773,
    Code: '9139',
    Name: 'Man, bench, coke ovens'
  },
  {
    Id: 29774,
    Code: '9139',
    Name: 'Man, bench, rolling mill'
  },
  {
    Id: 29775,
    Code: '9139',
    Name: 'Man, billet'
  },
  {
    Id: 29776,
    Code: '9139',
    Name: 'Man, bottom, coke ovens'
  },
  {
    Id: 29777,
    Code: '9139',
    Name: 'Man, breeze'
  },
  {
    Id: 29778,
    Code: '9139',
    Name: 'Man, button, haulage'
  },
  {
    Id: 29779,
    Code: '9139',
    Name: 'Man, cable'
  },
  {
    Id: 29780,
    Code: '9139',
    Name: 'Man, clinker'
  },
  {
    Id: 29781,
    Code: '9139',
    Name: 'Man, coal'
  },
  {
    Id: 29782,
    Code: '9139',
    Name: 'Man, coke, coke ovens'
  },
  {
    Id: 29783,
    Code: '9139',
    Name: 'Man, conveyor'
  },
  {
    Id: 29784,
    Code: '9139',
    Name: 'Man, dock, ship repairing'
  },
  {
    Id: 29785,
    Code: '9139',
    Name: 'Man, exhauster, coal gas, coke ovens'
  },
  {
    Id: 29786,
    Code: '9139',
    Name: 'Man, fan, store, cold'
  },
  {
    Id: 29787,
    Code: '9139',
    Name: 'Man, fan, coal mine'
  },
  {
    Id: 29788,
    Code: '9139',
    Name: 'Man, gang, shore, shipbuilding'
  },
  {
    Id: 29789,
    Code: '9139',
    Name: 'Man, governor, gas works'
  },
  {
    Id: 29790,
    Code: '9139',
    Name: 'Man, guide, coke'
  },
  {
    Id: 29791,
    Code: '9139',
    Name: 'Man, heap, copperas'
  },
  {
    Id: 29792,
    Code: '9139',
    Name: 'Man, ingot, rolling mill'
  },
  {
    Id: 29793,
    Code: '9139',
    Name: 'Man, junction'
  },
  {
    Id: 29794,
    Code: '9139',
    Name: 'Man, knock-out'
  },
  {
    Id: 29795,
    Code: '9139',
    Name: 'Man, lock'
  },
  {
    Id: 29796,
    Code: '9139',
    Name: 'Man, mooring'
  },
  {
    Id: 29797,
    Code: '9139',
    Name: 'Man, motor, belt'
  },
  {
    Id: 29798,
    Code: '9139',
    Name: 'Man, oncost, mine: not coal'
  },
  {
    Id: 29799,
    Code: '9139',
    Name: 'Man, pitch'
  },
  {
    Id: 29800,
    Code: '9139',
    Name: 'Man, powder, bleaching'
  },
  {
    Id: 29801,
    Code: '9139',
    Name: 'Man, quencher, coal gas, coke ovens'
  },
  {
    Id: 29802,
    Code: '9139',
    Name: 'Man, ram'
  },
  {
    Id: 29803,
    Code: '9139',
    Name: 'Man, rope-way, aerial'
  },
  {
    Id: 29804,
    Code: '9139',
    Name: 'Man, salt'
  },
  {
    Id: 29805,
    Code: '9139',
    Name: 'Man, scrap'
  },
  {
    Id: 29806,
    Code: '9139',
    Name: 'Man, service, chemical mfr'
  },
  {
    Id: 29807,
    Code: '9139',
    Name: 'Man, sluice'
  },
  {
    Id: 29808,
    Code: '9139',
    Name: 'Man, stopper, coal gas, coke ovens'
  },
  {
    Id: 29809,
    Code: '9139',
    Name: 'Man, syphon'
  },
  {
    Id: 29810,
    Code: '9139',
    Name: 'Man, timber, timber merchants'
  },
  {
    Id: 29811,
    Code: '9139',
    Name: 'Man, tool'
  },
  {
    Id: 29812,
    Code: '9139',
    Name: 'Man, traffic, coal mine'
  },
  {
    Id: 29813,
    Code: '9139',
    Name: 'Man, turn, bye, steelworks'
  },
  {
    Id: 29814,
    Code: '9139',
    Name: 'Man, utility'
  },
  {
    Id: 29815,
    Code: '9139',
    Name: 'Man, valve and steam, coal gas, coke ovens'
  },
  {
    Id: 29816,
    Code: '9139',
    Name: 'Man, waste, textile mfr'
  },
  {
    Id: 29817,
    Code: '9139',
    Name: 'Man, yard, metal, steelworks'
  },
  {
    Id: 29818,
    Code: '9139',
    Name: 'Man, yard, vulcanised fibre board mfr'
  },
  {
    Id: 29819,
    Code: '9139',
    Name: "Marker, plater's"
  },
  {
    Id: 29820,
    Code: '9139',
    Name: 'Master, hopper'
  },
  {
    Id: 29821,
    Code: '9139',
    Name: "Mate, bender's, metal"
  },
  {
    Id: 29822,
    Code: '9139',
    Name: "Mate, burner's"
  },
  {
    Id: 29823,
    Code: '9139',
    Name: "Mate, driller's, shipbuilding"
  },
  {
    Id: 29824,
    Code: '9139',
    Name: "Mate, driver's, dredger"
  },
  {
    Id: 29825,
    Code: '9139',
    Name: "Mate, electrician's"
  },
  {
    Id: 29826,
    Code: '9139',
    Name: "Mate, engineer's"
  },
  {
    Id: 29827,
    Code: '9139',
    Name: "Mate, erector's"
  },
  {
    Id: 29828,
    Code: '9139',
    Name: "Mate, fitter's, pipe"
  },
  {
    Id: 29829,
    Code: '9139',
    Name: "Mate, fitter's"
  },
  {
    Id: 29830,
    Code: '9139',
    Name: "Mate, fuser's"
  },
  {
    Id: 29831,
    Code: '9139',
    Name: "Mate, grinder's, metal"
  },
  {
    Id: 29832,
    Code: '9139',
    Name: "Mate, jointer's, cable"
  },
  {
    Id: 29833,
    Code: '9139',
    Name: "Mate, jointer's, electricity supplier"
  },
  {
    Id: 29834,
    Code: '9139',
    Name: "Mate, linesman's"
  },
  {
    Id: 29835,
    Code: '9139',
    Name: "Mate, maker's, boiler"
  },
  {
    Id: 29836,
    Code: '9139',
    Name: "Mate, mechanic's"
  },
  {
    Id: 29837,
    Code: '9139',
    Name: "Mate, miller's, cement"
  },
  {
    Id: 29838,
    Code: '9139',
    Name: "Mate, millwright's"
  },
  {
    Id: 29839,
    Code: '9139',
    Name: "Mate, plater's"
  },
  {
    Id: 29840,
    Code: '9139',
    Name: "Mate, rigger's"
  },
  {
    Id: 29841,
    Code: '9139',
    Name: "Mate, riveter's"
  },
  {
    Id: 29842,
    Code: '9139',
    Name: "Mate, shipwright's"
  },
  {
    Id: 29843,
    Code: '9139',
    Name: "Mate, smith's, boiler"
  },
  {
    Id: 29844,
    Code: '9139',
    Name: "Mate, smith's, copper"
  },
  {
    Id: 29845,
    Code: '9139',
    Name: "Mate, tester's, motor cars"
  },
  {
    Id: 29846,
    Code: '9139',
    Name: "Mate, welder's"
  },
  {
    Id: 29847,
    Code: '9139',
    Name: "Mate, wireman's"
  },
  {
    Id: 29848,
    Code: '9139',
    Name: 'Minder, block, wire mfr'
  },
  {
    Id: 29849,
    Code: '9139',
    Name: 'Minder, chain'
  },
  {
    Id: 29850,
    Code: '9139',
    Name: 'Minder, pump'
  },
  {
    Id: 29851,
    Code: '9139',
    Name: 'Minder, swift, wire'
  },
  {
    Id: 29852,
    Code: '9139',
    Name: 'Navvy, pond, slurry, cement mfr'
  },
  {
    Id: 29853,
    Code: '9139',
    Name: 'Navvy, mine: not coal'
  },
  {
    Id: 29854,
    Code: '9139',
    Name: 'Necker'
  },
  {
    Id: 29855,
    Code: '9139',
    Name: 'Oddman, bank'
  },
  {
    Id: 29856,
    Code: '9139',
    Name: 'Oddman, ceramics mfr'
  },
  {
    Id: 29857,
    Code: '9139',
    Name: 'Officer, salvage, manufacturing'
  },
  {
    Id: 29858,
    Code: '9139',
    Name: 'Opener, bale'
  },
  {
    Id: 29859,
    Code: '9139',
    Name: 'Opener, foundry'
  },
  {
    Id: 29860,
    Code: '9139',
    Name: 'Operative, foundry'
  },
  {
    Id: 29861,
    Code: '9139',
    Name: 'Operative, maintenance, fire service'
  },
  {
    Id: 29862,
    Code: '9139',
    Name: 'Operative, mill'
  },
  {
    Id: 29863,
    Code: '9139',
    Name: 'Operator, belt, conveyor'
  },
  {
    Id: 29864,
    Code: '9139',
    Name: 'Operator, button'
  },
  {
    Id: 29865,
    Code: '9139',
    Name: 'Operator, car, ingot'
  },
  {
    Id: 29866,
    Code: '9139',
    Name: 'Operator, conveyor'
  },
  {
    Id: 29867,
    Code: '9139',
    Name: 'Operator, electrical, rolling mill'
  },
  {
    Id: 29868,
    Code: '9139',
    Name: 'Operator, fan, coal mine'
  },
  {
    Id: 29869,
    Code: '9139',
    Name: 'Operator, hydraulic'
  },
  {
    Id: 29870,
    Code: '9139',
    Name: 'Operator, oil, metal trades'
  },
  {
    Id: 29871,
    Code: '9139',
    Name: 'Operator, panel, steel mfr'
  },
  {
    Id: 29872,
    Code: '9139',
    Name: 'Operator, plant, drying'
  },
  {
    Id: 29873,
    Code: '9139',
    Name: 'Operator, plant, mobile, steel mfr'
  },
  {
    Id: 29874,
    Code: '9139',
    Name: 'Operator, plant, refractory'
  },
  {
    Id: 29875,
    Code: '9139',
    Name: 'Operator, point, transfer'
  },
  {
    Id: 29876,
    Code: '9139',
    Name: 'Operator, process, foundry'
  },
  {
    Id: 29877,
    Code: '9139',
    Name: 'Operator, pusher, coke ovens'
  },
  {
    Id: 29878,
    Code: '9139',
    Name: 'Operator, ropeway'
  },
  {
    Id: 29879,
    Code: '9139',
    Name: 'Operator, tippler'
  },
  {
    Id: 29880,
    Code: '9139',
    Name: 'Operator, traverser, wagon'
  },
  {
    Id: 29881,
    Code: '9139',
    Name: 'Operator, turntable'
  },
  {
    Id: 29882,
    Code: '9139',
    Name: 'Panner-out'
  },
  {
    Id: 29883,
    Code: '9139',
    Name: 'Patrol, belt'
  },
  {
    Id: 29884,
    Code: '9139',
    Name: 'Patrolman, belt'
  },
  {
    Id: 29885,
    Code: '9139',
    Name: 'Picker-up, galvanised sheet mfr'
  },
  {
    Id: 29886,
    Code: '9139',
    Name: 'Piler, hot'
  },
  {
    Id: 29887,
    Code: '9139',
    Name: 'Piler'
  },
  {
    Id: 29888,
    Code: '9139',
    Name: 'Pitcher, flour'
  },
  {
    Id: 29889,
    Code: '9139',
    Name: 'Plasterer, coke ovens'
  },
  {
    Id: 29890,
    Code: '9139',
    Name: 'Poker-in, coke ovens'
  },
  {
    Id: 29891,
    Code: '9139',
    Name: 'Puller-down'
  },
  {
    Id: 29892,
    Code: '9139',
    Name: 'Puller-off, metal trades'
  },
  {
    Id: 29893,
    Code: '9139',
    Name: 'Puller-up'
  },
  {
    Id: 29894,
    Code: '9139',
    Name: 'Pusher, truck'
  },
  {
    Id: 29895,
    Code: '9139',
    Name: 'Pusher, coal gas, coke ovens'
  },
  {
    Id: 29896,
    Code: '9139',
    Name: 'Pusher-out'
  },
  {
    Id: 29897,
    Code: '9139',
    Name: 'Racker, tinplate goods mfr'
  },
  {
    Id: 29898,
    Code: '9139',
    Name: 'Racker, whiting mfr'
  },
  {
    Id: 29899,
    Code: '9139',
    Name: 'Raker-out, asbestos mfr'
  },
  {
    Id: 29900,
    Code: '9139',
    Name: 'Remover, scrap'
  },
  {
    Id: 29901,
    Code: '9139',
    Name: 'Resiner, brewery'
  },
  {
    Id: 29902,
    Code: '9139',
    Name: 'Rinser, file'
  },
  {
    Id: 29903,
    Code: '9139',
    Name: 'Rodsman'
  },
  {
    Id: 29904,
    Code: '9139',
    Name: 'Roustabout'
  },
  {
    Id: 29905,
    Code: '9139',
    Name: 'Rucker, blast furnace'
  },
  {
    Id: 29906,
    Code: '9139',
    Name: 'Ruller, mine: not coal'
  },
  {
    Id: 29907,
    Code: '9139',
    Name: 'Runner, bobbin'
  },
  {
    Id: 29908,
    Code: '9139',
    Name: 'Runner, clay'
  },
  {
    Id: 29909,
    Code: '9139',
    Name: 'Runner, rope'
  },
  {
    Id: 29910,
    Code: '9139',
    Name: 'Runner, skip'
  },
  {
    Id: 29911,
    Code: '9139',
    Name: 'Runner, wagon'
  },
  {
    Id: 29912,
    Code: '9139',
    Name: 'Runner, water'
  },
  {
    Id: 29913,
    Code: '9139',
    Name: 'Runner, wool'
  },
  {
    Id: 29914,
    Code: '9139',
    Name: 'Runner, ceramics mfr'
  },
  {
    Id: 29915,
    Code: '9139',
    Name: 'Runner, textile mfr'
  },
  {
    Id: 29916,
    Code: '9139',
    Name: 'Sailor, shipbuilding'
  },
  {
    Id: 29917,
    Code: '9139',
    Name: 'Sailorman'
  },
  {
    Id: 29918,
    Code: '9139',
    Name: 'Scaleman, rolling mill'
  },
  {
    Id: 29919,
    Code: '9139',
    Name: 'Scaler, metal'
  },
  {
    Id: 29920,
    Code: '9139',
    Name: 'Scaler, shipbuilding'
  },
  {
    Id: 29921,
    Code: '9139',
    Name: 'Scraper, shipbuilding'
  },
  {
    Id: 29922,
    Code: '9139',
    Name: 'Scrapper, metal mfr'
  },
  {
    Id: 29923,
    Code: '9139',
    Name: 'Scurfer, retort'
  },
  {
    Id: 29924,
    Code: '9139',
    Name: 'Scurfer, aircraft mfr'
  },
  {
    Id: 29925,
    Code: '9139',
    Name: 'Separator, metal and oil'
  },
  {
    Id: 29926,
    Code: '9139',
    Name: 'Servant, civil, nos: industrial'
  },
  {
    Id: 29927,
    Code: '9139',
    Name: 'Server, silk mfr'
  },
  {
    Id: 29928,
    Code: '9139',
    Name: 'Shaker, bag'
  },
  {
    Id: 29929,
    Code: '9139',
    Name: 'Shifter, iron'
  },
  {
    Id: 29930,
    Code: '9139',
    Name: 'Shifter, rolling mill'
  },
  {
    Id: 29931,
    Code: '9139',
    Name: 'Shipper, patent fuel mfr'
  },
  {
    Id: 29932,
    Code: '9139',
    Name: 'Shipper, tinplate mfr'
  },
  {
    Id: 29933,
    Code: '9139',
    Name: 'Shopman, railway workshops'
  },
  {
    Id: 29934,
    Code: '9139',
    Name: 'Shuntman, mine: not coal'
  },
  {
    Id: 29935,
    Code: '9139',
    Name: 'Shutter, door, coke ovens'
  },
  {
    Id: 29936,
    Code: '9139',
    Name: 'Sorter, iron, shipbuilding'
  },
  {
    Id: 29937,
    Code: '9139',
    Name: 'Splitter, bale, rubber goods mfr'
  },
  {
    Id: 29938,
    Code: '9139',
    Name: 'Spreader, colour'
  },
  {
    Id: 29939,
    Code: '9139',
    Name: 'Stacker'
  },
  {
    Id: 29940,
    Code: '9139',
    Name: 'Steelworker'
  },
  {
    Id: 29941,
    Code: '9139',
    Name: 'Stocker, steel mfr'
  },
  {
    Id: 29942,
    Code: '9139',
    Name: 'Stocker-up'
  },
  {
    Id: 29943,
    Code: '9139',
    Name: 'Stockman, manufacturing: blast furnace'
  },
  {
    Id: 29944,
    Code: '9139',
    Name: 'Stockman, manufacturing: rolling mill'
  },
  {
    Id: 29945,
    Code: '9139',
    Name: 'Stoker, destructor'
  },
  {
    Id: 29946,
    Code: '9139',
    Name: 'Stower, cake'
  },
  {
    Id: 29947,
    Code: '9139',
    Name: 'Stower, cement'
  },
  {
    Id: 29948,
    Code: '9139',
    Name: 'Stower, grain milling'
  },
  {
    Id: 29949,
    Code: '9139',
    Name: 'Strapper, textile mfr'
  },
  {
    Id: 29950,
    Code: '9139',
    Name: 'Stripper, frame'
  },
  {
    Id: 29951,
    Code: '9139',
    Name: 'Stripper, candle mfr'
  },
  {
    Id: 29952,
    Code: '9139',
    Name: 'Stripper, ceramics mfr'
  },
  {
    Id: 29953,
    Code: '9139',
    Name: 'Stripper-assembler, cast concrete products mfr'
  },
  {
    Id: 29954,
    Code: '9139',
    Name: 'Supervisor, rope, trot'
  },
  {
    Id: 29955,
    Code: '9139',
    Name: 'Supervisor, The Stationery Office'
  },
  {
    Id: 29956,
    Code: '9139',
    Name: 'Swiller, tinplate mfr'
  },
  {
    Id: 29957,
    Code: '9139',
    Name: 'Taker, work'
  },
  {
    Id: 29958,
    Code: '9139',
    Name: 'Taker-off, metal trades'
  },
  {
    Id: 29959,
    Code: '9139',
    Name: 'Taker-out, ceramics mfr'
  },
  {
    Id: 29960,
    Code: '9139',
    Name: 'Tankman, storage'
  },
  {
    Id: 29961,
    Code: '9139',
    Name: 'Teemer, coke ovens'
  },
  {
    Id: 29962,
    Code: '9139',
    Name: 'Tenter, press, metal trades'
  },
  {
    Id: 29963,
    Code: '9139',
    Name: 'Tenter, pump'
  },
  {
    Id: 29964,
    Code: '9139',
    Name: 'Thrower, brewery'
  },
  {
    Id: 29965,
    Code: '9139',
    Name: 'Thrower, distillery'
  },
  {
    Id: 29966,
    Code: '9139',
    Name: 'Tipman, mine: not coal'
  },
  {
    Id: 29967,
    Code: '9139',
    Name: 'Tipper, coal, coal patent fuel mfr'
  },
  {
    Id: 29968,
    Code: '9139',
    Name: 'Tipper, coal, steelworks'
  },
  {
    Id: 29969,
    Code: '9139',
    Name: 'Tipper, ore, steelworks'
  },
  {
    Id: 29970,
    Code: '9139',
    Name: 'Tipper, scrap'
  },
  {
    Id: 29971,
    Code: '9139',
    Name: 'Tipper, shale'
  },
  {
    Id: 29972,
    Code: '9139',
    Name: 'Tipper, slag'
  },
  {
    Id: 29973,
    Code: '9139',
    Name: 'Tipper, mine: not coal'
  },
  {
    Id: 29974,
    Code: '9139',
    Name: 'Tippler, steelworks'
  },
  {
    Id: 29975,
    Code: '9139',
    Name: 'Topman, battery, coke ovens'
  },
  {
    Id: 29976,
    Code: '9139',
    Name: 'Topman, coal gas, coke ovens'
  },
  {
    Id: 29977,
    Code: '9139',
    Name: 'Towerman, paper mfr'
  },
  {
    Id: 29978,
    Code: '9139',
    Name: 'Trammer'
  },
  {
    Id: 29979,
    Code: '9139',
    Name: 'Trimmer, boiler'
  },
  {
    Id: 29980,
    Code: '9139',
    Name: 'Trimmer, coal'
  },
  {
    Id: 29981,
    Code: '9139',
    Name: 'Trimmer, house, boiler'
  },
  {
    Id: 29982,
    Code: '9139',
    Name: 'Trimmer, wagon'
  },
  {
    Id: 29983,
    Code: '9139',
    Name: 'Trimmer, boiler house'
  },
  {
    Id: 29984,
    Code: '9139',
    Name: 'Trimmer, coal gas, coke ovens'
  },
  {
    Id: 29985,
    Code: '9139',
    Name: 'Trimmer, electricity supplier'
  },
  {
    Id: 29986,
    Code: '9139',
    Name: 'Trimmer, power station'
  },
  {
    Id: 29987,
    Code: '9139',
    Name: 'Trolleyman'
  },
  {
    Id: 29988,
    Code: '9139',
    Name: 'Truckman, blast furnace'
  },
  {
    Id: 29989,
    Code: '9139',
    Name: 'Turner, sheet, galvanised sheet mfr'
  },
  {
    Id: 29990,
    Code: '9139',
    Name: 'Turner, wheel, ceramics mfr'
  },
  {
    Id: 29991,
    Code: '9139',
    Name: 'Unloader, autoclave, ceramics'
  },
  {
    Id: 29992,
    Code: '9139',
    Name: 'Unloader, kiln, ceramics'
  },
  {
    Id: 29993,
    Code: '9139',
    Name: 'Valveman, coal gas, coke ovens'
  },
  {
    Id: 29994,
    Code: '9139',
    Name: 'Wagoner, coal mine: above ground'
  },
  {
    Id: 29995,
    Code: '9139',
    Name: 'Washer, board'
  },
  {
    Id: 29996,
    Code: '9139',
    Name: 'Washer, glass'
  },
  {
    Id: 29997,
    Code: '9139',
    Name: 'Washer, metal'
  },
  {
    Id: 29998,
    Code: '9139',
    Name: 'Washer, stencil'
  },
  {
    Id: 29999,
    Code: '9139',
    Name: 'Washer, tin, biscuit'
  },
  {
    Id: 30000,
    Code: '9139',
    Name: 'Washer, tray'
  },
  {
    Id: 30001,
    Code: '9139',
    Name: 'Washer, flour confectionery mfr'
  },
  {
    Id: 30002,
    Code: '9139',
    Name: 'Watchman-operator, petroleum distribution'
  },
  {
    Id: 30003,
    Code: '9139',
    Name: 'Waterman, furnace, blast'
  },
  {
    Id: 30004,
    Code: '9139',
    Name: 'Waterman, coal gas, coke ovens'
  },
  {
    Id: 30005,
    Code: '9139',
    Name: 'Waterman, steel mfr'
  },
  {
    Id: 30006,
    Code: '9139',
    Name: 'Weighman, charge, foundry'
  },
  {
    Id: 30007,
    Code: '9139',
    Name: 'Wheeler, frit'
  },
  {
    Id: 30008,
    Code: '9139',
    Name: 'Wheeler, metal, metal mfr'
  },
  {
    Id: 30009,
    Code: '9139',
    Name: 'Wheeler, pick, mine: not coal'
  },
  {
    Id: 30010,
    Code: '9139',
    Name: 'Wheeler'
  },
  {
    Id: 30011,
    Code: '9139',
    Name: 'Wheeler-in'
  },
  {
    Id: 30012,
    Code: '9139',
    Name: 'Wiper, cutlery, cutlery mfr'
  },
  {
    Id: 30013,
    Code: '9139',
    Name: 'Wiper, scissors'
  },
  {
    Id: 30014,
    Code: '9139',
    Name: 'Wiper, glass mfr'
  },
  {
    Id: 30015,
    Code: '9139',
    Name: 'Wirer, metal trades'
  },
  {
    Id: 30016,
    Code: '9139',
    Name: 'Worker, aircraft'
  },
  {
    Id: 30017,
    Code: '9139',
    Name: 'Worker, ammunition'
  },
  {
    Id: 30018,
    Code: '9139',
    Name: 'Worker, asbestos'
  },
  {
    Id: 30019,
    Code: '9139',
    Name: 'Worker, battery'
  },
  {
    Id: 30020,
    Code: '9139',
    Name: 'Worker, belt, conveyor'
  },
  {
    Id: 30021,
    Code: '9139',
    Name: 'Worker, belt'
  },
  {
    Id: 30022,
    Code: '9139',
    Name: 'Worker, copper, refining'
  },
  {
    Id: 30023,
    Code: '9139',
    Name: 'Worker, dock, dry'
  },
  {
    Id: 30024,
    Code: '9139',
    Name: 'Worker, dock, dry dock'
  },
  {
    Id: 30025,
    Code: '9139',
    Name: 'Worker, dockyard'
  },
  {
    Id: 30026,
    Code: '9139',
    Name: 'Worker, engineering'
  },
  {
    Id: 30027,
    Code: '9139',
    Name: 'Worker, factory, engineering'
  },
  {
    Id: 30028,
    Code: '9139',
    Name: 'Worker, factory'
  },
  {
    Id: 30029,
    Code: '9139',
    Name: 'Worker, foundry'
  },
  {
    Id: 30030,
    Code: '9139',
    Name: 'Worker, gas'
  },
  {
    Id: 30031,
    Code: '9139',
    Name: 'Worker, general, metal trades'
  },
  {
    Id: 30032,
    Code: '9139',
    Name: 'Worker, iron, iron and steelworks'
  },
  {
    Id: 30033,
    Code: '9139',
    Name: 'Worker, metal'
  },
  {
    Id: 30034,
    Code: '9139',
    Name: 'Worker, mill, corn'
  },
  {
    Id: 30035,
    Code: '9139',
    Name: 'Worker, mill, flour'
  },
  {
    Id: 30036,
    Code: '9139',
    Name: 'Worker, mill'
  },
  {
    Id: 30037,
    Code: '9139',
    Name: 'Worker, munitions'
  },
  {
    Id: 30038,
    Code: '9139',
    Name: 'Worker, paper, printing'
  },
  {
    Id: 30039,
    Code: '9139',
    Name: 'Worker, refractory'
  },
  {
    Id: 30040,
    Code: '9139',
    Name: 'Worker, room, ware'
  },
  {
    Id: 30041,
    Code: '9139',
    Name: 'Worker, shop, paint'
  },
  {
    Id: 30042,
    Code: '9139',
    Name: 'Worker, shop, scrap, celluloid'
  },
  {
    Id: 30043,
    Code: '9139',
    Name: 'Worker, steel'
  },
  {
    Id: 30044,
    Code: '9139',
    Name: 'Worker, timber'
  },
  {
    Id: 30045,
    Code: '9139',
    Name: 'Worker-round, iron and steelworks'
  },
  {
    Id: 30046,
    Code: '9139',
    Name: 'Yardman, metal, steelworks'
  },
  {
    Id: 30047,
    Code: '9139',
    Name: 'Yardman, vulcanised fibre board mfr'
  },
  {
    Id: 30048,
    Code: '9139',
    Name: 'Yardsman, metal, steelworks'
  },
  {
    Id: 30049,
    Code: '9139',
    Name: 'Yardsman, dairy'
  },
  {
    Id: 30050,
    Code: '9139',
    Name: 'Yardsman, vulcanised fibre board mfr'
  },
  {
    Id: 30051,
    Code: '9211',
    Name: 'Assistant, facilities'
  },
  {
    Id: 30052,
    Code: '9211',
    Name: 'Assistant, room, mail'
  },
  {
    Id: 30053,
    Code: '9211',
    Name: 'Assistant, room, post'
  },
  {
    Id: 30054,
    Code: '9211',
    Name: 'Assistant, sorting, PO'
  },
  {
    Id: 30055,
    Code: '9211',
    Name: 'Attendant, room, mail'
  },
  {
    Id: 30056,
    Code: '9211',
    Name: 'Attendant, room, post'
  },
  {
    Id: 30057,
    Code: '9211',
    Name: 'Boy, delivery'
  },
  {
    Id: 30058,
    Code: '9211',
    Name: 'Boy, newspaper'
  },
  {
    Id: 30059,
    Code: '9211',
    Name: 'Boy, paper'
  },
  {
    Id: 30060,
    Code: '9211',
    Name: 'Cadet, postal'
  },
  {
    Id: 30061,
    Code: '9211',
    Name: 'Clerk, mailroom'
  },
  {
    Id: 30062,
    Code: '9211',
    Name: 'Clerk, post'
  },
  {
    Id: 30063,
    Code: '9211',
    Name: 'Clerk, postal'
  },
  {
    Id: 30064,
    Code: '9211',
    Name: 'Clerk, postroom'
  },
  {
    Id: 30065,
    Code: '9211',
    Name: 'Clerk, room, mail'
  },
  {
    Id: 30066,
    Code: '9211',
    Name: 'Clerk, room, post'
  },
  {
    Id: 30067,
    Code: '9211',
    Name: 'Clerk, sorting, PO'
  },
  {
    Id: 30068,
    Code: '9211',
    Name: 'Clerk and telegraphist, sorting'
  },
  {
    Id: 30069,
    Code: '9211',
    Name: 'Deliverer, allowance'
  },
  {
    Id: 30070,
    Code: '9211',
    Name: 'Deliverer, newspaper'
  },
  {
    Id: 30071,
    Code: '9211',
    Name: 'Deliverer, newspapers, magazines'
  },
  {
    Id: 30072,
    Code: '9211',
    Name: 'Distributor, circular'
  },
  {
    Id: 30073,
    Code: '9211',
    Name: 'Distributor, leaflet'
  },
  {
    Id: 30074,
    Code: '9211',
    Name: 'Distributor, newspaper'
  },
  {
    Id: 30075,
    Code: '9211',
    Name: 'Driver, collection, PO'
  },
  {
    Id: 30076,
    Code: '9211',
    Name: 'Driver-postman'
  },
  {
    Id: 30077,
    Code: '9211',
    Name: 'Executive, postal, grade D, PO'
  },
  {
    Id: 30078,
    Code: '9211',
    Name: 'Forewoman, PO'
  },
  {
    Id: 30079,
    Code: '9211',
    Name: 'Girl, paper, newsagents'
  },
  {
    Id: 30080,
    Code: '9211',
    Name: "Girl, shop, tailor's"
  },
  {
    Id: 30081,
    Code: '9211',
    Name: 'Grade, postal, operational'
  },
  {
    Id: 30082,
    Code: '9211',
    Name: 'Handler, parcel'
  },
  {
    Id: 30083,
    Code: '9211',
    Name: 'Inspector, assistant, PO'
  },
  {
    Id: 30084,
    Code: '9211',
    Name: 'Inspector, postal'
  },
  {
    Id: 30085,
    Code: '9211',
    Name: 'Inspector, PO'
  },
  {
    Id: 30086,
    Code: '9211',
    Name: 'Inspector of postmen'
  },
  {
    Id: 30087,
    Code: '9211',
    Name: 'Lad, order'
  },
  {
    Id: 30088,
    Code: '9211',
    Name: 'Loader, parcel delivery service'
  },
  {
    Id: 30089,
    Code: '9211',
    Name: 'Manager, line, PO'
  },
  {
    Id: 30090,
    Code: '9211',
    Name: 'Messenger, chief'
  },
  {
    Id: 30091,
    Code: '9211',
    Name: 'Messenger, head'
  },
  {
    Id: 30092,
    Code: '9211',
    Name: "Messenger, King's"
  },
  {
    Id: 30093,
    Code: '9211',
    Name: "Messenger, Queen's"
  },
  {
    Id: 30094,
    Code: '9211',
    Name: 'Messenger'
  },
  {
    Id: 30095,
    Code: '9211',
    Name: 'OPG'
  },
  {
    Id: 30096,
    Code: '9211',
    Name: 'Officer, controlling, PO'
  },
  {
    Id: 30097,
    Code: '9211',
    Name: 'Officer, outdoor'
  },
  {
    Id: 30098,
    Code: '9211',
    Name: 'Officer, supervising, PO'
  },
  {
    Id: 30099,
    Code: '9211',
    Name: 'Operative, room, post'
  },
  {
    Id: 30100,
    Code: '9211',
    Name: 'Operative, x-ray, parcel delivery service'
  },
  {
    Id: 30101,
    Code: '9211',
    Name: 'Orderly, office'
  },
  {
    Id: 30102,
    Code: '9211',
    Name: 'Overseer, PO: Royal Mail sorting office'
  },
  {
    Id: 30103,
    Code: '9211',
    Name: 'PHG'
  },
  {
    Id: 30104,
    Code: '9211',
    Name: 'Porter, room, post'
  },
  {
    Id: 30105,
    Code: '9211',
    Name: 'Postie'
  },
  {
    Id: 30106,
    Code: '9211',
    Name: 'Postman, grade, higher'
  },
  {
    Id: 30107,
    Code: '9211',
    Name: 'Postman, head'
  },
  {
    Id: 30108,
    Code: '9211',
    Name: 'Postman, works'
  },
  {
    Id: 30109,
    Code: '9211',
    Name: 'Postman'
  },
  {
    Id: 30110,
    Code: '9211',
    Name: 'Postman-driver'
  },
  {
    Id: 30111,
    Code: '9211',
    Name: 'Postwoman, grade, higher'
  },
  {
    Id: 30112,
    Code: '9211',
    Name: 'Postwoman, head'
  },
  {
    Id: 30113,
    Code: '9211',
    Name: 'Postwoman, works'
  },
  {
    Id: 30114,
    Code: '9211',
    Name: 'Postwoman'
  },
  {
    Id: 30115,
    Code: '9211',
    Name: 'Rider, dispatch'
  },
  {
    Id: 30116,
    Code: '9211',
    Name: 'Roundsman, newspaper delivery'
  },
  {
    Id: 30117,
    Code: '9211',
    Name: 'Sorter, head, PO'
  },
  {
    Id: 30118,
    Code: '9211',
    Name: 'Sorter, hub, parcel delivery service'
  },
  {
    Id: 30119,
    Code: '9211',
    Name: 'Sorter, letter'
  },
  {
    Id: 30120,
    Code: '9211',
    Name: 'Sorter, mail'
  },
  {
    Id: 30121,
    Code: '9211',
    Name: 'Sorter, parcel'
  },
  {
    Id: 30122,
    Code: '9211',
    Name: 'Sorter, post'
  },
  {
    Id: 30123,
    Code: '9211',
    Name: 'Sorter, postal'
  },
  {
    Id: 30124,
    Code: '9211',
    Name: 'Sorter, parcel delivery service'
  },
  {
    Id: 30125,
    Code: '9211',
    Name: 'Sorter, PO'
  },
  {
    Id: 30126,
    Code: '9211',
    Name: 'Sorter-loader, parcel delivery service'
  },
  {
    Id: 30127,
    Code: '9211',
    Name: 'Stamper, PO'
  },
  {
    Id: 30128,
    Code: '9211',
    Name: 'Supervisor, mail'
  },
  {
    Id: 30129,
    Code: '9211',
    Name: 'Supervisor, postal'
  },
  {
    Id: 30130,
    Code: '9211',
    Name: 'Supervisor, room, mail'
  },
  {
    Id: 30131,
    Code: '9211',
    Name: 'Supervisor, room, post'
  },
  {
    Id: 30132,
    Code: '9211',
    Name: 'Supervisor, PO: Royal Mail sorting office'
  },
  {
    Id: 30133,
    Code: '9211',
    Name: 'Supervisor of sorting assistants, PO'
  },
  {
    Id: 30134,
    Code: '9211',
    Name: 'Taker, copy, publishing'
  },
  {
    Id: 30135,
    Code: '9211',
    Name: 'Waiter, stock exchange'
  },
  {
    Id: 30136,
    Code: '9211',
    Name: 'Worker, delivery, postal'
  },
  {
    Id: 30137,
    Code: '9211',
    Name: 'Worker, office, post'
  },
  {
    Id: 30138,
    Code: '9211',
    Name: 'Worker, postal'
  },
  {
    Id: 30139,
    Code: '9219',
    Name: 'Assistant, general'
  },
  {
    Id: 30140,
    Code: '9219',
    Name: 'Assistant, mailing'
  },
  {
    Id: 30141,
    Code: '9219',
    Name: 'Assistant, reprographic'
  },
  {
    Id: 30142,
    Code: '9219',
    Name: 'Assistant, scanning'
  },
  {
    Id: 30143,
    Code: '9219',
    Name: 'Assistant, technical, photocopying, scanning'
  },
  {
    Id: 30144,
    Code: '9219',
    Name: 'Associate, scanning'
  },
  {
    Id: 30145,
    Code: '9219',
    Name: 'Boy, office'
  },
  {
    Id: 30146,
    Code: '9219',
    Name: 'Clerk, junior'
  },
  {
    Id: 30147,
    Code: '9219',
    Name: 'Copyist, photo'
  },
  {
    Id: 30148,
    Code: '9219',
    Name: 'Duplicator'
  },
  {
    Id: 30149,
    Code: '9219',
    Name: 'Encloser'
  },
  {
    Id: 30150,
    Code: '9219',
    Name: 'Filler, envelope'
  },
  {
    Id: 30151,
    Code: '9219',
    Name: 'Filmer, micro'
  },
  {
    Id: 30152,
    Code: '9219',
    Name: 'Girl, office'
  },
  {
    Id: 30153,
    Code: '9219',
    Name: 'Hand, progress'
  },
  {
    Id: 30154,
    Code: '9219',
    Name: 'Handyman, office'
  },
  {
    Id: 30155,
    Code: '9219',
    Name: 'Inserter, newspapers, magazines'
  },
  {
    Id: 30156,
    Code: '9219',
    Name: 'Junior, office'
  },
  {
    Id: 30157,
    Code: '9219',
    Name: 'Keeper, paper'
  },
  {
    Id: 30158,
    Code: '9219',
    Name: 'Machinist, office'
  },
  {
    Id: 30159,
    Code: '9219',
    Name: 'Machinist, photocopying'
  },
  {
    Id: 30160,
    Code: '9219',
    Name: 'Machinist, office machinery'
  },
  {
    Id: 30161,
    Code: '9219',
    Name: 'Machinist, banking'
  },
  {
    Id: 30162,
    Code: '9219',
    Name: 'Mailer'
  },
  {
    Id: 30163,
    Code: '9219',
    Name: 'Man, progress'
  },
  {
    Id: 30164,
    Code: '9219',
    Name: 'Officer, reprographics'
  },
  {
    Id: 30165,
    Code: '9219',
    Name: 'Opener, mail'
  },
  {
    Id: 30166,
    Code: '9219',
    Name: 'Operator, camera, microfilm'
  },
  {
    Id: 30167,
    Code: '9219',
    Name: 'Operator, camera, photocopying'
  },
  {
    Id: 30168,
    Code: '9219',
    Name: 'Operator, duplicator'
  },
  {
    Id: 30169,
    Code: '9219',
    Name: 'Operator, film, micro'
  },
  {
    Id: 30170,
    Code: '9219',
    Name: 'Operator, machinery, office'
  },
  {
    Id: 30171,
    Code: '9219',
    Name: 'Operator, photocopy'
  },
  {
    Id: 30172,
    Code: '9219',
    Name: 'Operator, reprographics'
  },
  {
    Id: 30173,
    Code: '9219',
    Name: 'Operator, room, print'
  },
  {
    Id: 30174,
    Code: '9219',
    Name: 'Operator, scanner, printing'
  },
  {
    Id: 30175,
    Code: '9219',
    Name: 'Operator, scanning'
  },
  {
    Id: 30176,
    Code: '9219',
    Name: 'Operator, xerox'
  },
  {
    Id: 30177,
    Code: '9219',
    Name: 'Paperkeeper'
  },
  {
    Id: 30178,
    Code: '9219',
    Name: 'Photocopier'
  },
  {
    Id: 30179,
    Code: '9219',
    Name: 'Printer, copy'
  },
  {
    Id: 30180,
    Code: '9219',
    Name: 'Printer, photo'
  },
  {
    Id: 30181,
    Code: '9219',
    Name: 'Printer, nos: photocopying'
  },
  {
    Id: 30182,
    Code: '9219',
    Name: 'Scanner'
  },
  {
    Id: 30183,
    Code: '9219',
    Name: 'Shredder, confidential'
  },
  {
    Id: 30184,
    Code: '9219',
    Name: 'Sorter, coupon'
  },
  {
    Id: 30185,
    Code: '9219',
    Name: 'Sorter, voucher'
  },
  {
    Id: 30186,
    Code: '9219',
    Name: 'Supervisor, office machinery'
  },
  {
    Id: 30187,
    Code: '9219',
    Name: 'Technician, reprographic'
  },
  {
    Id: 30188,
    Code: '9219',
    Name: 'Worker, office'
  },
  {
    Id: 30189,
    Code: '9219',
    Name: 'Worker, progress'
  },
  {
    Id: 30190,
    Code: '9221',
    Name: 'Cleaner, window'
  },
  {
    Id: 30191,
    Code: '9221',
    Name: 'Cleaner, windows'
  },
  {
    Id: 30192,
    Code: '9221',
    Name: 'Cleaner, windows'
  },
  {
    Id: 30193,
    Code: '9221',
    Name: 'Contractor, cleaning, window'
  },
  {
    Id: 30194,
    Code: '9222',
    Name: 'Attendant, market'
  },
  {
    Id: 30195,
    Code: '9222',
    Name: 'Cleaner, environmental'
  },
  {
    Id: 30196,
    Code: '9222',
    Name: 'Cleaner, highways'
  },
  {
    Id: 30197,
    Code: '9222',
    Name: 'Cleaner, road'
  },
  {
    Id: 30198,
    Code: '9222',
    Name: 'Cleaner, street'
  },
  {
    Id: 30199,
    Code: '9222',
    Name: 'Operative, cleansing, street'
  },
  {
    Id: 30200,
    Code: '9222',
    Name: 'Operative, cleansing, street cleaning'
  },
  {
    Id: 30201,
    Code: '9222',
    Name: 'Operative, realm, public, local government'
  },
  {
    Id: 30202,
    Code: '9222',
    Name: 'Orderly, road'
  },
  {
    Id: 30203,
    Code: '9222',
    Name: 'Orderly, street'
  },
  {
    Id: 30204,
    Code: '9222',
    Name: 'Picker, litter'
  },
  {
    Id: 30205,
    Code: '9222',
    Name: 'Sweeper, road'
  },
  {
    Id: 30206,
    Code: '9222',
    Name: 'Sweeper, street'
  },
  {
    Id: 30207,
    Code: '9222',
    Name: 'Sweeper, street cleaning'
  },
  {
    Id: 30208,
    Code: '9223',
    Name: 'Ace, Asda'
  },
  {
    Id: 30209,
    Code: '9223',
    Name: 'Assistant, bedroom, hotel'
  },
  {
    Id: 30210,
    Code: '9223',
    Name: 'Assistant, chalet'
  },
  {
    Id: 30211,
    Code: '9223',
    Name: 'Assistant, cleaning, commercial cleaning'
  },
  {
    Id: 30212,
    Code: '9223',
    Name: 'Assistant, cleaning'
  },
  {
    Id: 30213,
    Code: '9223',
    Name: 'Assistant, domestic, commercial cleaning'
  },
  {
    Id: 30214,
    Code: '9223',
    Name: 'Assistant, domestic'
  },
  {
    Id: 30215,
    Code: '9223',
    Name: 'Assistant, general, cleaning'
  },
  {
    Id: 30216,
    Code: '9223',
    Name: 'Assistant, house, guest'
  },
  {
    Id: 30217,
    Code: '9223',
    Name: 'Assistant, household'
  },
  {
    Id: 30218,
    Code: '9223',
    Name: 'Assistant, housekeeping'
  },
  {
    Id: 30219,
    Code: '9223',
    Name: 'Assistant, hygiene'
  },
  {
    Id: 30220,
    Code: '9223',
    Name: 'Assistant, keeping, house'
  },
  {
    Id: 30221,
    Code: '9223',
    Name: "Assistant, matron's"
  },
  {
    Id: 30222,
    Code: '9223',
    Name: 'Assistant, room, hotel'
  },
  {
    Id: 30223,
    Code: '9223',
    Name: 'Assistant, services, hotel'
  },
  {
    Id: 30224,
    Code: '9223',
    Name: 'Assistant, services, patient'
  },
  {
    Id: 30225,
    Code: '9223',
    Name: 'Attendant, cleaning'
  },
  {
    Id: 30226,
    Code: '9223',
    Name: 'Attendant, room, hotel'
  },
  {
    Id: 30227,
    Code: '9223',
    Name: 'Attendant, room'
  },
  {
    Id: 30228,
    Code: '9223',
    Name: 'Bedder, college'
  },
  {
    Id: 30229,
    Code: '9223',
    Name: 'Carer, domestic'
  },
  {
    Id: 30230,
    Code: '9223',
    Name: 'Chambermaid'
  },
  {
    Id: 30231,
    Code: '9223',
    Name: 'Chambermaid-housekeeper'
  },
  {
    Id: 30232,
    Code: '9223',
    Name: 'Charlady'
  },
  {
    Id: 30233,
    Code: '9223',
    Name: 'Charwoman'
  },
  {
    Id: 30234,
    Code: '9223',
    Name: 'Cleaner, bank'
  },
  {
    Id: 30235,
    Code: '9223',
    Name: 'Cleaner, bar, catering'
  },
  {
    Id: 30236,
    Code: '9223',
    Name: 'Cleaner, berth'
  },
  {
    Id: 30237,
    Code: '9223',
    Name: 'Cleaner, canteen'
  },
  {
    Id: 30238,
    Code: '9223',
    Name: 'Cleaner, church'
  },
  {
    Id: 30239,
    Code: '9223',
    Name: 'Cleaner, closet'
  },
  {
    Id: 30240,
    Code: '9223',
    Name: 'Cleaner, commercial'
  },
  {
    Id: 30241,
    Code: '9223',
    Name: 'Cleaner, domestic, commercial cleaning'
  },
  {
    Id: 30242,
    Code: '9223',
    Name: 'Cleaner, domestic'
  },
  {
    Id: 30243,
    Code: '9223',
    Name: 'Cleaner, floor'
  },
  {
    Id: 30244,
    Code: '9223',
    Name: 'Cleaner, general'
  },
  {
    Id: 30245,
    Code: '9223',
    Name: 'Cleaner, healthcare'
  },
  {
    Id: 30246,
    Code: '9223',
    Name: 'Cleaner, hospital'
  },
  {
    Id: 30247,
    Code: '9223',
    Name: 'Cleaner, hotel'
  },
  {
    Id: 30248,
    Code: '9223',
    Name: 'Cleaner, house, private'
  },
  {
    Id: 30249,
    Code: '9223',
    Name: 'Cleaner, house'
  },
  {
    Id: 30250,
    Code: '9223',
    Name: 'Cleaner, kiosk, telephone'
  },
  {
    Id: 30251,
    Code: '9223',
    Name: 'Cleaner, kitchen'
  },
  {
    Id: 30252,
    Code: '9223',
    Name: 'Cleaner, library'
  },
  {
    Id: 30253,
    Code: '9223',
    Name: 'Cleaner, mobile, commercial cleaning'
  },
  {
    Id: 30254,
    Code: '9223',
    Name: 'Cleaner, mobile'
  },
  {
    Id: 30255,
    Code: '9223',
    Name: 'Cleaner, night'
  },
  {
    Id: 30256,
    Code: '9223',
    Name: 'Cleaner, office'
  },
  {
    Id: 30257,
    Code: '9223',
    Name: 'Cleaner, oven, domestic ovens'
  },
  {
    Id: 30258,
    Code: '9223',
    Name: 'Cleaner, private'
  },
  {
    Id: 30259,
    Code: '9223',
    Name: 'Cleaner, room, mess'
  },
  {
    Id: 30260,
    Code: '9223',
    Name: 'Cleaner, room, show'
  },
  {
    Id: 30261,
    Code: '9223',
    Name: 'Cleaner, school'
  },
  {
    Id: 30262,
    Code: '9223',
    Name: 'Cleaner, shop'
  },
  {
    Id: 30263,
    Code: '9223',
    Name: 'Cleaner, station, railways'
  },
  {
    Id: 30264,
    Code: '9223',
    Name: 'Cleaner, telephone'
  },
  {
    Id: 30265,
    Code: '9223',
    Name: 'Cleaner, warehouse'
  },
  {
    Id: 30266,
    Code: '9223',
    Name: 'Cleaner, workshop'
  },
  {
    Id: 30267,
    Code: '9223',
    Name: 'Cleaner, yard'
  },
  {
    Id: 30268,
    Code: '9223',
    Name: 'Cleaner, commercial cleaning'
  },
  {
    Id: 30269,
    Code: '9223',
    Name: 'Cleaner, holiday homes'
  },
  {
    Id: 30270,
    Code: '9223',
    Name: 'Cleaner, hospital'
  },
  {
    Id: 30271,
    Code: '9223',
    Name: 'Cleaner, offices'
  },
  {
    Id: 30272,
    Code: '9223',
    Name: 'Cleaner, private houses'
  },
  {
    Id: 30273,
    Code: '9223',
    Name: 'Cleaner, educational establishments'
  },
  {
    Id: 30274,
    Code: '9223',
    Name: 'Cleaner, wholesale, retail trade'
  },
  {
    Id: 30275,
    Code: '9223',
    Name: 'Cleaner'
  },
  {
    Id: 30276,
    Code: '9223',
    Name: 'Cleaner-doorman'
  },
  {
    Id: 30277,
    Code: '9223',
    Name: 'Closer, night, fast food outlet'
  },
  {
    Id: 30278,
    Code: '9223',
    Name: 'Contractor, cleaning'
  },
  {
    Id: 30279,
    Code: '9223',
    Name: 'Controller, hygiene'
  },
  {
    Id: 30280,
    Code: '9223',
    Name: 'Domestic, commercial cleaning'
  },
  {
    Id: 30281,
    Code: '9223',
    Name: 'Domestic'
  },
  {
    Id: 30282,
    Code: '9223',
    Name: 'Engineer, domestic, commercial cleaning'
  },
  {
    Id: 30283,
    Code: '9223',
    Name: 'Engineer, domestic'
  },
  {
    Id: 30284,
    Code: '9223',
    Name: 'Hand, general, hotel'
  },
  {
    Id: 30285,
    Code: '9223',
    Name: 'Help, daily'
  },
  {
    Id: 30286,
    Code: '9223',
    Name: 'Help, domestic'
  },
  {
    Id: 30287,
    Code: '9223',
    Name: 'Help, home'
  },
  {
    Id: 30288,
    Code: '9223',
    Name: 'Help, domestic service'
  },
  {
    Id: 30289,
    Code: '9223',
    Name: 'Helper, domestic'
  },
  {
    Id: 30290,
    Code: '9223',
    Name: 'Helper, home'
  },
  {
    Id: 30291,
    Code: '9223',
    Name: 'Homehelp'
  },
  {
    Id: 30292,
    Code: '9223',
    Name: 'Housemaid'
  },
  {
    Id: 30293,
    Code: '9223',
    Name: 'Houseman, school'
  },
  {
    Id: 30294,
    Code: '9223',
    Name: 'Houseman, domestic service'
  },
  {
    Id: 30295,
    Code: '9223',
    Name: 'Houseman, hotel'
  },
  {
    Id: 30296,
    Code: '9223',
    Name: 'Lady, cleaning'
  },
  {
    Id: 30297,
    Code: '9223',
    Name: 'Maid, chalet'
  },
  {
    Id: 30298,
    Code: '9223',
    Name: 'Maid, chamber'
  },
  {
    Id: 30299,
    Code: '9223',
    Name: 'Maid, house'
  },
  {
    Id: 30300,
    Code: '9223',
    Name: 'Maid, room'
  },
  {
    Id: 30301,
    Code: '9223',
    Name: 'Maid, ward'
  },
  {
    Id: 30302,
    Code: '9223',
    Name: 'Maid'
  },
  {
    Id: 30303,
    Code: '9223',
    Name: 'Maidservant'
  },
  {
    Id: 30304,
    Code: '9223',
    Name: 'Maker, bed, care/residential home'
  },
  {
    Id: 30305,
    Code: '9223',
    Name: 'Maker, bed, hospital service'
  },
  {
    Id: 30306,
    Code: '9223',
    Name: 'Maker, bed, school, university'
  },
  {
    Id: 30307,
    Code: '9223',
    Name: 'Man, cabin, mine: not coal'
  },
  {
    Id: 30308,
    Code: '9223',
    Name: 'Officer, service, ward'
  },
  {
    Id: 30309,
    Code: '9223',
    Name: 'Operative, cleaning, commercial cleaning'
  },
  {
    Id: 30310,
    Code: '9223',
    Name: 'Operative, cleaning'
  },
  {
    Id: 30311,
    Code: '9223',
    Name: 'Operative, domestic, commercial cleaning'
  },
  {
    Id: 30312,
    Code: '9223',
    Name: 'Operative, domestic'
  },
  {
    Id: 30313,
    Code: '9223',
    Name: 'Operator, service, cleaning'
  },
  {
    Id: 30314,
    Code: '9223',
    Name: 'Operator, sterilizer, telephone'
  },
  {
    Id: 30315,
    Code: '9223',
    Name: 'Orderly, domestic, hospital service'
  },
  {
    Id: 30316,
    Code: '9223',
    Name: 'Parlourmaid'
  },
  {
    Id: 30317,
    Code: '9223',
    Name: 'Parlourman'
  },
  {
    Id: 30318,
    Code: '9223',
    Name: 'Polisher, floor'
  },
  {
    Id: 30319,
    Code: '9223',
    Name: 'Porter-cleaner'
  },
  {
    Id: 30320,
    Code: '9223',
    Name: 'Scout, college'
  },
  {
    Id: 30321,
    Code: '9223',
    Name: 'Servant, college'
  },
  {
    Id: 30322,
    Code: '9223',
    Name: 'Servant, daily'
  },
  {
    Id: 30323,
    Code: '9223',
    Name: 'Servant, domestic'
  },
  {
    Id: 30324,
    Code: '9223',
    Name: 'Servant'
  },
  {
    Id: 30325,
    Code: '9223',
    Name: 'Steriliser, telephone sterilising service'
  },
  {
    Id: 30326,
    Code: '9223',
    Name: 'Steward, house'
  },
  {
    Id: 30327,
    Code: '9223',
    Name: 'Sweeper, tube'
  },
  {
    Id: 30328,
    Code: '9223',
    Name: 'Sweeper'
  },
  {
    Id: 30329,
    Code: '9223',
    Name: 'Sweeper-up'
  },
  {
    Id: 30330,
    Code: '9223',
    Name: 'Technician, cleaning'
  },
  {
    Id: 30331,
    Code: '9223',
    Name: 'Technician, domestic, commercial cleaning'
  },
  {
    Id: 30332,
    Code: '9223',
    Name: 'Technician, domestic'
  },
  {
    Id: 30333,
    Code: '9223',
    Name: 'Worker, domestic, commercial cleaning'
  },
  {
    Id: 30334,
    Code: '9223',
    Name: 'Worker, domestic'
  },
  {
    Id: 30335,
    Code: '9223',
    Name: 'Worker, house, domestic service'
  },
  {
    Id: 30336,
    Code: '9224',
    Name: 'Assistant, branch, laundry, launderette, dry cleaning'
  },
  {
    Id: 30337,
    Code: '9224',
    Name: 'Assistant, cleaning, dry'
  },
  {
    Id: 30338,
    Code: '9224',
    Name: 'Assistant, general, laundry, launderette, dry cleaning'
  },
  {
    Id: 30339,
    Code: '9224',
    Name: 'Assistant, launderette'
  },
  {
    Id: 30340,
    Code: '9224',
    Name: 'Assistant, laundry'
  },
  {
    Id: 30341,
    Code: '9224',
    Name: 'Assistant, laundry, launderette, dry cleaning'
  },
  {
    Id: 30342,
    Code: '9224',
    Name: 'Attendant, house, wash'
  },
  {
    Id: 30343,
    Code: '9224',
    Name: 'Attendant, hydro, laundry, launderette, dry cleaning'
  },
  {
    Id: 30344,
    Code: '9224',
    Name: 'Attendant, launderette'
  },
  {
    Id: 30345,
    Code: '9224',
    Name: 'Attendant, laundry'
  },
  {
    Id: 30346,
    Code: '9224',
    Name: 'Attendant, tumbler'
  },
  {
    Id: 30347,
    Code: '9224',
    Name: 'Beater, carpet'
  },
  {
    Id: 30348,
    Code: '9224',
    Name: 'Blocker, laundry, launderette, dry cleaning'
  },
  {
    Id: 30349,
    Code: '9224',
    Name: 'Bluer, laundry, launderette, dry cleaning'
  },
  {
    Id: 30350,
    Code: '9224',
    Name: 'Brusher, dyeing and cleaning'
  },
  {
    Id: 30351,
    Code: '9224',
    Name: 'Calenderer, laundry, launderette, dry cleaning'
  },
  {
    Id: 30352,
    Code: '9224',
    Name: 'Cleaner, carpet'
  },
  {
    Id: 30353,
    Code: '9224',
    Name: 'Cleaner, carpet and upholstery'
  },
  {
    Id: 30354,
    Code: '9224',
    Name: 'Cleaner, clothes'
  },
  {
    Id: 30355,
    Code: '9224',
    Name: 'Cleaner, dry'
  },
  {
    Id: 30356,
    Code: '9224',
    Name: 'Cleaner, fur'
  },
  {
    Id: 30357,
    Code: '9224',
    Name: 'Cleaner, garment'
  },
  {
    Id: 30358,
    Code: '9224',
    Name: 'Cleaner, upholstery'
  },
  {
    Id: 30359,
    Code: '9224',
    Name: 'Cleaner, vacuum'
  },
  {
    Id: 30360,
    Code: '9224',
    Name: 'Cleaner, carpets'
  },
  {
    Id: 30361,
    Code: '9224',
    Name: 'Cleaner, dyeing and cleaning'
  },
  {
    Id: 30362,
    Code: '9224',
    Name: 'Cleaner, fur goods mfr'
  },
  {
    Id: 30363,
    Code: '9224',
    Name: 'Dryer, laundry, launderette, dry cleaning'
  },
  {
    Id: 30364,
    Code: '9224',
    Name: 'Dyer and cleaner'
  },
  {
    Id: 30365,
    Code: '9224',
    Name: 'Feeder, laundry, launderette, dry cleaning'
  },
  {
    Id: 30366,
    Code: '9224',
    Name: 'Finisher, dyeing and cleaning'
  },
  {
    Id: 30367,
    Code: '9224',
    Name: 'Foreman, house, wash'
  },
  {
    Id: 30368,
    Code: '9224',
    Name: 'Foreman, laundry'
  },
  {
    Id: 30369,
    Code: '9224',
    Name: 'Foreman, dyeing and cleaning'
  },
  {
    Id: 30370,
    Code: '9224',
    Name: 'Foreman, laundry, launderette, dry cleaning'
  },
  {
    Id: 30371,
    Code: '9224',
    Name: 'Framer, laundry, launderette, dry cleaning'
  },
  {
    Id: 30372,
    Code: '9224',
    Name: 'Hand, calender, laundry, launderette, dry cleaning'
  },
  {
    Id: 30373,
    Code: '9224',
    Name: 'Hand, hydro, laundry, launderette, dry cleaning'
  },
  {
    Id: 30374,
    Code: '9224',
    Name: 'Hand, laundry'
  },
  {
    Id: 30375,
    Code: '9224',
    Name: 'Hand, leading, laundry, launderette, dry cleaning'
  },
  {
    Id: 30376,
    Code: '9224',
    Name: 'Houseman, wash'
  },
  {
    Id: 30377,
    Code: '9224',
    Name: 'Ironer, glove'
  },
  {
    Id: 30378,
    Code: '9224',
    Name: 'Ironer, clothing mfr'
  },
  {
    Id: 30379,
    Code: '9224',
    Name: 'Ironer, hosiery, knitwear mfr'
  },
  {
    Id: 30380,
    Code: '9224',
    Name: 'Ironer, laundry, launderette, dry cleaning'
  },
  {
    Id: 30381,
    Code: '9224',
    Name: 'Ironer'
  },
  {
    Id: 30382,
    Code: '9224',
    Name: 'Launderer'
  },
  {
    Id: 30383,
    Code: '9224',
    Name: 'Laundress'
  },
  {
    Id: 30384,
    Code: '9224',
    Name: 'Machinist, collar, laundry, launderette, dry cleaning'
  },
  {
    Id: 30385,
    Code: '9224',
    Name: 'Machinist, laundry'
  },
  {
    Id: 30386,
    Code: '9224',
    Name: 'Machinist, shirt, laundry, launderette, dry cleaning'
  },
  {
    Id: 30387,
    Code: '9224',
    Name: 'Machinist, washing, laundry, launderette, dry cleaning'
  },
  {
    Id: 30388,
    Code: '9224',
    Name: 'Machinist, dyeing and cleaning'
  },
  {
    Id: 30389,
    Code: '9224',
    Name: 'Machinist, laundry, launderette, dry cleaning'
  },
  {
    Id: 30390,
    Code: '9224',
    Name: 'Maid, laundry'
  },
  {
    Id: 30391,
    Code: '9224',
    Name: 'Man, laundry'
  },
  {
    Id: 30392,
    Code: '9224',
    Name: 'Matron, laundry'
  },
  {
    Id: 30393,
    Code: '9224',
    Name: 'Operative, cleaning, dry'
  },
  {
    Id: 30394,
    Code: '9224',
    Name: 'Operative, laundry'
  },
  {
    Id: 30395,
    Code: '9224',
    Name: 'Operative, restoration, carpet cleaning'
  },
  {
    Id: 30396,
    Code: '9224',
    Name: 'Operator, calender, laundry, launderette, dry cleaning'
  },
  {
    Id: 30397,
    Code: '9224',
    Name: 'Operator, cleaner, dry, laundry, launderette, dry cleaning'
  },
  {
    Id: 30398,
    Code: '9224',
    Name: 'Operator, hydro, laundry, launderette, dry cleaning'
  },
  {
    Id: 30399,
    Code: '9224',
    Name: 'Operator, hydro'
  },
  {
    Id: 30400,
    Code: '9224',
    Name: 'Operator, hydro-extractor, laundry, launderette, dry cleaning'
  },
  {
    Id: 30401,
    Code: '9224',
    Name: 'Operator, laundry'
  },
  {
    Id: 30402,
    Code: '9224',
    Name: 'Operator, presser, steam'
  },
  {
    Id: 30403,
    Code: '9224',
    Name: 'Operator, tumbler, laundry, launderette, dry cleaning'
  },
  {
    Id: 30404,
    Code: '9224',
    Name: 'Operator, unit, laundry, launderette, dry cleaning'
  },
  {
    Id: 30405,
    Code: '9224',
    Name: 'Preparer, laundry, launderette, dry cleaning'
  },
  {
    Id: 30406,
    Code: '9224',
    Name: 'Presser, blouse'
  },
  {
    Id: 30407,
    Code: '9224',
    Name: 'Presser, cap'
  },
  {
    Id: 30408,
    Code: '9224',
    Name: 'Presser, clothes'
  },
  {
    Id: 30409,
    Code: '9224',
    Name: 'Presser, clothing'
  },
  {
    Id: 30410,
    Code: '9224',
    Name: 'Presser, collar'
  },
  {
    Id: 30411,
    Code: '9224',
    Name: 'Presser, garment'
  },
  {
    Id: 30412,
    Code: '9224',
    Name: 'Presser, general'
  },
  {
    Id: 30413,
    Code: '9224',
    Name: 'Presser, hand, tailoring'
  },
  {
    Id: 30414,
    Code: '9224',
    Name: 'Presser, hand, textile finishing'
  },
  {
    Id: 30415,
    Code: '9224',
    Name: 'Presser, Hoffman'
  },
  {
    Id: 30416,
    Code: '9224',
    Name: 'Presser, knitwear'
  },
  {
    Id: 30417,
    Code: '9224',
    Name: 'Presser, lining'
  },
  {
    Id: 30418,
    Code: '9224',
    Name: 'Presser, machine, clothing mfr'
  },
  {
    Id: 30419,
    Code: '9224',
    Name: 'Presser, seam'
  },
  {
    Id: 30420,
    Code: '9224',
    Name: 'Presser, shirt'
  },
  {
    Id: 30421,
    Code: '9224',
    Name: 'Presser, sleeve'
  },
  {
    Id: 30422,
    Code: '9224',
    Name: 'Presser, steam, clothing'
  },
  {
    Id: 30423,
    Code: '9224',
    Name: "Presser, tailor's"
  },
  {
    Id: 30424,
    Code: '9224',
    Name: 'Presser, top'
  },
  {
    Id: 30425,
    Code: '9224',
    Name: 'Presser, trouser'
  },
  {
    Id: 30426,
    Code: '9224',
    Name: 'Presser, clothing mfr'
  },
  {
    Id: 30427,
    Code: '9224',
    Name: 'Presser, laundry, launderette, dry cleaning'
  },
  {
    Id: 30428,
    Code: '9224',
    Name: 'Smoother, clothing mfr'
  },
  {
    Id: 30429,
    Code: '9224',
    Name: 'Spotter, laundry, launderette, dry cleaning'
  },
  {
    Id: 30430,
    Code: '9224',
    Name: 'Starcher, laundry, launderette, dry cleaning'
  },
  {
    Id: 30431,
    Code: '9224',
    Name: 'Steamer, dyeing and cleaning'
  },
  {
    Id: 30432,
    Code: '9224',
    Name: 'Superintendent, laundry, hospital service'
  },
  {
    Id: 30433,
    Code: '9224',
    Name: 'Supervisor, laundry'
  },
  {
    Id: 30434,
    Code: '9224',
    Name: 'Tabber, laundry, launderette, dry cleaning'
  },
  {
    Id: 30435,
    Code: '9224',
    Name: 'Taker-off, textile mfr: lace finishing'
  },
  {
    Id: 30436,
    Code: '9224',
    Name: 'Technician, cleaning, carpet'
  },
  {
    Id: 30437,
    Code: '9224',
    Name: 'Underpresser'
  },
  {
    Id: 30438,
    Code: '9224',
    Name: 'Washer, laundry, launderette, dry cleaning'
  },
  {
    Id: 30439,
    Code: '9224',
    Name: 'Washerman, laundry, launderette, dry cleaning'
  },
  {
    Id: 30440,
    Code: '9224',
    Name: 'Washman, laundry, launderette, dry cleaning'
  },
  {
    Id: 30441,
    Code: '9224',
    Name: 'Winder, towel, laundry, launderette, dry cleaning'
  },
  {
    Id: 30442,
    Code: '9224',
    Name: 'Worker, factory, laundry'
  },
  {
    Id: 30443,
    Code: '9224',
    Name: 'Worker, launderette'
  },
  {
    Id: 30444,
    Code: '9224',
    Name: 'Worker, laundry'
  },
  {
    Id: 30445,
    Code: '9224',
    Name: 'Worker, process, laundry, launderette, dry cleaning'
  },
  {
    Id: 30446,
    Code: '9225',
    Name: 'Attendant, hopper, refuse and salvage services'
  },
  {
    Id: 30447,
    Code: '9225',
    Name: 'Attendant, tip'
  },
  {
    Id: 30448,
    Code: '9225',
    Name: 'Binman'
  },
  {
    Id: 30449,
    Code: '9225',
    Name: 'Cleanser, local government'
  },
  {
    Id: 30450,
    Code: '9225',
    Name: 'Collector, dust, local government'
  },
  {
    Id: 30451,
    Code: '9225',
    Name: 'Collector, metal, scrap'
  },
  {
    Id: 30452,
    Code: '9225',
    Name: 'Collector, refuse'
  },
  {
    Id: 30453,
    Code: '9225',
    Name: 'Collector, salvage'
  },
  {
    Id: 30454,
    Code: '9225',
    Name: 'Collector, scrap'
  },
  {
    Id: 30455,
    Code: '9225',
    Name: 'Collector, waste'
  },
  {
    Id: 30456,
    Code: '9225',
    Name: 'Dustman'
  },
  {
    Id: 30457,
    Code: '9225',
    Name: 'Foreman, district, sanitary services'
  },
  {
    Id: 30458,
    Code: '9225',
    Name: 'Foreman, salvage'
  },
  {
    Id: 30459,
    Code: '9225',
    Name: 'Foreman, local government: sanitary dept'
  },
  {
    Id: 30460,
    Code: '9225',
    Name: 'Foreman, refuse and salvage services'
  },
  {
    Id: 30461,
    Code: '9225',
    Name: 'Hand, salvage'
  },
  {
    Id: 30462,
    Code: '9225',
    Name: 'Inspector, cleansing'
  },
  {
    Id: 30463,
    Code: '9225',
    Name: 'Inspector, refuse and salvage services'
  },
  {
    Id: 30464,
    Code: '9225',
    Name: 'Lifter, bin'
  },
  {
    Id: 30465,
    Code: '9225',
    Name: 'Loader, refuse'
  },
  {
    Id: 30466,
    Code: '9225',
    Name: 'Loader, refuse and salvage services'
  },
  {
    Id: 30467,
    Code: '9225',
    Name: 'Man, bin'
  },
  {
    Id: 30468,
    Code: '9225',
    Name: 'Officer, disposal, refuse'
  },
  {
    Id: 30469,
    Code: '9225',
    Name: 'Officer, estate, refuse and salvage services'
  },
  {
    Id: 30470,
    Code: '9225',
    Name: 'Officer, estates, refuse and salvage services'
  },
  {
    Id: 30471,
    Code: '9225',
    Name: 'Operative, disposal, refuse'
  },
  {
    Id: 30472,
    Code: '9225',
    Name: 'Operative, disposal, waste'
  },
  {
    Id: 30473,
    Code: '9225',
    Name: 'Operative, landfill'
  },
  {
    Id: 30474,
    Code: '9225',
    Name: 'Operative, recycling'
  },
  {
    Id: 30475,
    Code: '9225',
    Name: 'Operative, refuse'
  },
  {
    Id: 30476,
    Code: '9225',
    Name: 'Operative, site, refuse and salvage services'
  },
  {
    Id: 30477,
    Code: '9225',
    Name: 'Operative, waste'
  },
  {
    Id: 30478,
    Code: '9225',
    Name: 'Operator, disposal, refuse'
  },
  {
    Id: 30479,
    Code: '9225',
    Name: 'Operator, disposal, waste'
  },
  {
    Id: 30480,
    Code: '9225',
    Name: 'Operator, recycling'
  },
  {
    Id: 30481,
    Code: '9225',
    Name: 'Recycler, paper'
  },
  {
    Id: 30482,
    Code: '9225',
    Name: 'Recycler'
  },
  {
    Id: 30483,
    Code: '9225',
    Name: 'Remover, refuse'
  },
  {
    Id: 30484,
    Code: '9225',
    Name: 'Scavenger'
  },
  {
    Id: 30485,
    Code: '9225',
    Name: 'Superintendent, cleansing, local government'
  },
  {
    Id: 30486,
    Code: '9225',
    Name: 'Superintendent, sanitary'
  },
  {
    Id: 30487,
    Code: '9225',
    Name: 'Tatter, waste'
  },
  {
    Id: 30488,
    Code: '9225',
    Name: 'Tipman, refuse'
  },
  {
    Id: 30489,
    Code: '9225',
    Name: 'Trimmer, disposal, refuse'
  },
  {
    Id: 30490,
    Code: '9225',
    Name: 'Worker, recycling'
  },
  {
    Id: 30491,
    Code: '9225',
    Name: 'Worker, refuse'
  },
  {
    Id: 30492,
    Code: '9225',
    Name: 'Worker, salvage'
  },
  {
    Id: 30493,
    Code: '9226',
    Name: 'Assistant, wash, car'
  },
  {
    Id: 30494,
    Code: '9226',
    Name: 'Cleaner, aircraft'
  },
  {
    Id: 30495,
    Code: '9226',
    Name: 'Cleaner, boat'
  },
  {
    Id: 30496,
    Code: '9226',
    Name: 'Cleaner, bus'
  },
  {
    Id: 30497,
    Code: '9226',
    Name: 'Cleaner, cab'
  },
  {
    Id: 30498,
    Code: '9226',
    Name: 'Cleaner, car'
  },
  {
    Id: 30499,
    Code: '9226',
    Name: 'Cleaner, carriage'
  },
  {
    Id: 30500,
    Code: '9226',
    Name: 'Cleaner, coach'
  },
  {
    Id: 30501,
    Code: '9226',
    Name: 'Cleaner, motor, garage'
  },
  {
    Id: 30502,
    Code: '9226',
    Name: "Cleaner, ship's"
  },
  {
    Id: 30503,
    Code: '9226',
    Name: 'Cleaner, train, exterior'
  },
  {
    Id: 30504,
    Code: '9226',
    Name: 'Cleaner, train, interior'
  },
  {
    Id: 30505,
    Code: '9226',
    Name: 'Cleaner, vehicle'
  },
  {
    Id: 30506,
    Code: '9226',
    Name: 'Cleaner, vehicles'
  },
  {
    Id: 30507,
    Code: '9226',
    Name: 'Detailer, car'
  },
  {
    Id: 30508,
    Code: '9226',
    Name: 'Groomer, aircraft'
  },
  {
    Id: 30509,
    Code: '9226',
    Name: 'Machinist, washing, transport'
  },
  {
    Id: 30510,
    Code: '9226',
    Name: 'Man, service, carriage, railways'
  },
  {
    Id: 30511,
    Code: '9226',
    Name: 'Operative, care, train'
  },
  {
    Id: 30512,
    Code: '9226',
    Name: 'Operative, presentation, train'
  },
  {
    Id: 30513,
    Code: '9226',
    Name: 'Polisher, car, motor, garage'
  },
  {
    Id: 30514,
    Code: '9226',
    Name: 'Progressor, vehicle'
  },
  {
    Id: 30515,
    Code: '9226',
    Name: 'Technician, appearance, vehicle'
  },
  {
    Id: 30516,
    Code: '9226',
    Name: 'Valet, vehicles'
  },
  {
    Id: 30517,
    Code: '9226',
    Name: 'Valeter, car'
  },
  {
    Id: 30518,
    Code: '9226',
    Name: 'Valeter, vehicle'
  },
  {
    Id: 30519,
    Code: '9226',
    Name: 'Valeter'
  },
  {
    Id: 30520,
    Code: '9226',
    Name: 'Washer, cab'
  },
  {
    Id: 30521,
    Code: '9226',
    Name: 'Washer, car'
  },
  {
    Id: 30522,
    Code: '9226',
    Name: 'Washer, lorry'
  },
  {
    Id: 30523,
    Code: '9226',
    Name: 'Washer, van'
  },
  {
    Id: 30524,
    Code: '9226',
    Name: 'Washer, transport'
  },
  {
    Id: 30525,
    Code: '9229',
    Name: 'Attendant, amenity'
  },
  {
    Id: 30526,
    Code: '9229',
    Name: 'Attendant, block, amenity'
  },
  {
    Id: 30527,
    Code: '9229',
    Name: 'Attendant, cleansing'
  },
  {
    Id: 30528,
    Code: '9229',
    Name: 'Attendant, convenience, public'
  },
  {
    Id: 30529,
    Code: '9229',
    Name: 'Attendant, lavatory'
  },
  {
    Id: 30530,
    Code: '9229',
    Name: "Attendant, room, ladies'"
  },
  {
    Id: 30531,
    Code: '9229',
    Name: 'Attendant, room, wash'
  },
  {
    Id: 30532,
    Code: '9229',
    Name: 'Attendant, sanitary'
  },
  {
    Id: 30533,
    Code: '9229',
    Name: 'Attendant, toilet'
  },
  {
    Id: 30534,
    Code: '9229',
    Name: 'Attendant, public conveniences'
  },
  {
    Id: 30535,
    Code: '9229',
    Name: 'Cleaner, bin'
  },
  {
    Id: 30536,
    Code: '9229',
    Name: 'Cleaner, chimney'
  },
  {
    Id: 30537,
    Code: '9229',
    Name: 'Cleaner, drain'
  },
  {
    Id: 30538,
    Code: '9229',
    Name: 'Cleaner, flue'
  },
  {
    Id: 30539,
    Code: '9229',
    Name: 'Cleaner, hygiene'
  },
  {
    Id: 30540,
    Code: '9229',
    Name: 'Cleaner, toilet'
  },
  {
    Id: 30541,
    Code: '9229',
    Name: 'Man, service, carriage'
  },
  {
    Id: 30542,
    Code: '9229',
    Name: 'Operative, environmental'
  },
  {
    Id: 30543,
    Code: '9229',
    Name: 'Orderly, sanitary'
  },
  {
    Id: 30544,
    Code: '9229',
    Name: 'Sweep, chimney'
  },
  {
    Id: 30545,
    Code: '9229',
    Name: 'Sweep, chimney'
  },
  {
    Id: 30546,
    Code: '9229',
    Name: 'Sweeper, chimney'
  },
  {
    Id: 30547,
    Code: '9231',
    Name: 'Agent, legal'
  },
  {
    Id: 30548,
    Code: '9231',
    Name: 'Agent, security, airport'
  },
  {
    Id: 30549,
    Code: '9231',
    Name: 'Agent, security, aviation'
  },
  {
    Id: 30550,
    Code: '9231',
    Name: 'Agent, security'
  },
  {
    Id: 30551,
    Code: '9231',
    Name: 'Assistant, custody, police service'
  },
  {
    Id: 30552,
    Code: '9231',
    Name: 'Assistant, museum, security'
  },
  {
    Id: 30553,
    Code: '9231',
    Name: 'Assistant, security'
  },
  {
    Id: 30554,
    Code: '9231',
    Name: 'Assistant, PO: security & investigation branch'
  },
  {
    Id: 30555,
    Code: '9231',
    Name: 'Attendant, court'
  },
  {
    Id: 30556,
    Code: '9231',
    Name: 'Attendant, gate'
  },
  {
    Id: 30557,
    Code: '9231',
    Name: 'Attendant, lodge'
  },
  {
    Id: 30558,
    Code: '9231',
    Name: 'Attendant, security'
  },
  {
    Id: 30559,
    Code: '9231',
    Name: 'Attendant, services, visitor, security'
  },
  {
    Id: 30560,
    Code: '9231',
    Name: 'Bodyguard'
  },
  {
    Id: 30561,
    Code: '9231',
    Name: 'Bouncer'
  },
  {
    Id: 30562,
    Code: '9231',
    Name: 'Checker, gate'
  },
  {
    Id: 30563,
    Code: '9231',
    Name: 'Colleague, security'
  },
  {
    Id: 30564,
    Code: '9231',
    Name: 'Constable, market'
  },
  {
    Id: 30565,
    Code: '9231',
    Name: 'Constable, non-statutory'
  },
  {
    Id: 30566,
    Code: '9231',
    Name: 'Controller, security'
  },
  {
    Id: 30567,
    Code: '9231',
    Name: 'Custodian, civilian'
  },
  {
    Id: 30568,
    Code: '9231',
    Name: 'Custodian, security services'
  },
  {
    Id: 30569,
    Code: '9231',
    Name: 'Custodian'
  },
  {
    Id: 30570,
    Code: '9231',
    Name: 'Detective, hotel'
  },
  {
    Id: 30571,
    Code: '9231',
    Name: 'Detective, store'
  },
  {
    Id: 30572,
    Code: '9231',
    Name: 'Detective, retail trade'
  },
  {
    Id: 30573,
    Code: '9231',
    Name: 'Doorman'
  },
  {
    Id: 30574,
    Code: '9231',
    Name: 'Foreman, security services'
  },
  {
    Id: 30575,
    Code: '9231',
    Name: 'Gatekeeper'
  },
  {
    Id: 30576,
    Code: '9231',
    Name: 'Gateman'
  },
  {
    Id: 30577,
    Code: '9231',
    Name: 'Guard, bank'
  },
  {
    Id: 30578,
    Code: '9231',
    Name: 'Guard, body'
  },
  {
    Id: 30579,
    Code: '9231',
    Name: 'Guard, custody'
  },
  {
    Id: 30580,
    Code: '9231',
    Name: 'Guard, night'
  },
  {
    Id: 30581,
    Code: '9231',
    Name: 'Guard, security'
  },
  {
    Id: 30582,
    Code: '9231',
    Name: 'Guard, van'
  },
  {
    Id: 30583,
    Code: '9231',
    Name: 'Guard, works'
  },
  {
    Id: 30584,
    Code: '9231',
    Name: 'Guard, manufacturing'
  },
  {
    Id: 30585,
    Code: '9231',
    Name: 'Guard, road goods transport'
  },
  {
    Id: 30586,
    Code: '9231',
    Name: 'Guard, security services'
  },
  {
    Id: 30587,
    Code: '9231',
    Name: 'Inspector, beach'
  },
  {
    Id: 30588,
    Code: '9231',
    Name: 'Inspector, patrol'
  },
  {
    Id: 30589,
    Code: '9231',
    Name: 'Keeper, door'
  },
  {
    Id: 30590,
    Code: '9231',
    Name: 'Keeper, gate'
  },
  {
    Id: 30591,
    Code: '9231',
    Name: 'Keeper, house, gate'
  },
  {
    Id: 30592,
    Code: '9231',
    Name: 'Keeper, lodge'
  },
  {
    Id: 30593,
    Code: '9231',
    Name: 'Keeper, ship'
  },
  {
    Id: 30594,
    Code: '9231',
    Name: 'Man, door'
  },
  {
    Id: 30595,
    Code: '9231',
    Name: 'Man, lodge'
  },
  {
    Id: 30596,
    Code: '9231',
    Name: 'Man, security'
  },
  {
    Id: 30597,
    Code: '9231',
    Name: 'Marshall, COVID'
  },
  {
    Id: 30598,
    Code: '9231',
    Name: 'Messenger at Arms'
  },
  {
    Id: 30599,
    Code: '9231',
    Name: 'Nightwatchman'
  },
  {
    Id: 30600,
    Code: '9231',
    Name: 'Officer, alarm, community'
  },
  {
    Id: 30601,
    Code: '9231',
    Name: 'Officer, branch, security services'
  },
  {
    Id: 30602,
    Code: '9231',
    Name: 'Officer, custody, detainee'
  },
  {
    Id: 30603,
    Code: '9231',
    Name: 'Officer, custody, detention'
  },
  {
    Id: 30604,
    Code: '9231',
    Name: 'Officer, custody, immigration detention'
  },
  {
    Id: 30605,
    Code: '9231',
    Name: 'Officer, detention, custody'
  },
  {
    Id: 30606,
    Code: '9231',
    Name: 'Officer, detention'
  },
  {
    Id: 30607,
    Code: '9231',
    Name: 'Officer, enforcement, court'
  },
  {
    Id: 30608,
    Code: '9231',
    Name: 'Officer, enforcement, environmental'
  },
  {
    Id: 30609,
    Code: '9231',
    Name: 'Officer, enforcement'
  },
  {
    Id: 30610,
    Code: '9231',
    Name: 'Officer, investigating'
  },
  {
    Id: 30611,
    Code: '9231',
    Name: 'Officer, investigation'
  },
  {
    Id: 30612,
    Code: '9231',
    Name: 'Officer, monitoring, CCTV'
  },
  {
    Id: 30613,
    Code: '9231',
    Name: 'Officer, monitoring, field'
  },
  {
    Id: 30614,
    Code: '9231',
    Name: 'Officer, patrol, security'
  },
  {
    Id: 30615,
    Code: '9231',
    Name: 'Officer, patrol'
  },
  {
    Id: 30616,
    Code: '9231',
    Name: 'Officer, police, non-statutory'
  },
  {
    Id: 30617,
    Code: '9231',
    Name: 'Officer, prevention, loss'
  },
  {
    Id: 30618,
    Code: '9231',
    Name: 'Officer, protection, close'
  },
  {
    Id: 30619,
    Code: '9231',
    Name: 'Officer, response, mobile, security'
  },
  {
    Id: 30620,
    Code: '9231',
    Name: 'Officer, security, aviation'
  },
  {
    Id: 30621,
    Code: '9231',
    Name: 'Officer, security, chief'
  },
  {
    Id: 30622,
    Code: '9231',
    Name: 'Officer, security, court'
  },
  {
    Id: 30623,
    Code: '9231',
    Name: 'Officer, security, door supervisor'
  },
  {
    Id: 30624,
    Code: '9231',
    Name: 'Officer, security'
  },
  {
    Id: 30625,
    Code: '9231',
    Name: 'Officer, sheriff'
  },
  {
    Id: 30626,
    Code: '9231',
    Name: 'Officer, warrant, county court'
  },
  {
    Id: 30627,
    Code: '9231',
    Name: 'Officer, investigation'
  },
  {
    Id: 30628,
    Code: '9231',
    Name: 'Operative, CCTV'
  },
  {
    Id: 30629,
    Code: '9231',
    Name: 'Operative, security'
  },
  {
    Id: 30630,
    Code: '9231',
    Name: 'Operator, CCTV'
  },
  {
    Id: 30631,
    Code: '9231',
    Name: 'Operator, room, control, security services'
  },
  {
    Id: 30632,
    Code: '9231',
    Name: 'Operator, television, circuit, close'
  },
  {
    Id: 30633,
    Code: '9231',
    Name: 'Patrol'
  },
  {
    Id: 30634,
    Code: '9231',
    Name: 'Patrolman'
  },
  {
    Id: 30635,
    Code: '9231',
    Name: 'Porter, gate'
  },
  {
    Id: 30636,
    Code: '9231',
    Name: 'Porter, lodge'
  },
  {
    Id: 30637,
    Code: '9231',
    Name: 'Porter, security'
  },
  {
    Id: 30638,
    Code: '9231',
    Name: 'Ranger, centre, town'
  },
  {
    Id: 30639,
    Code: '9231',
    Name: 'Ranger, street'
  },
  {
    Id: 30640,
    Code: '9231',
    Name: 'Receptionist, door'
  },
  {
    Id: 30641,
    Code: '9231',
    Name: 'Receptionist, security'
  },
  {
    Id: 30642,
    Code: '9231',
    Name: 'Security'
  },
  {
    Id: 30643,
    Code: '9231',
    Name: 'Securityman'
  },
  {
    Id: 30644,
    Code: '9231',
    Name: 'Sergeant, security'
  },
  {
    Id: 30645,
    Code: '9231',
    Name: 'Server, process'
  },
  {
    Id: 30646,
    Code: '9231',
    Name: 'Supervisor, door'
  },
  {
    Id: 30647,
    Code: '9231',
    Name: 'Supervisor, security'
  },
  {
    Id: 30648,
    Code: '9231',
    Name: 'Usher, court'
  },
  {
    Id: 30649,
    Code: '9231',
    Name: 'Warden, garage'
  },
  {
    Id: 30650,
    Code: '9231',
    Name: 'Warden, security'
  },
  {
    Id: 30651,
    Code: '9231',
    Name: 'Warden, manufacturing'
  },
  {
    Id: 30652,
    Code: '9231',
    Name: 'Warden, museum'
  },
  {
    Id: 30653,
    Code: '9231',
    Name: 'Warden, schools'
  },
  {
    Id: 30654,
    Code: '9231',
    Name: 'Warden, tourism'
  },
  {
    Id: 30655,
    Code: '9231',
    Name: 'Warder, yeoman'
  },
  {
    Id: 30656,
    Code: '9231',
    Name: 'Warder, museum'
  },
  {
    Id: 30657,
    Code: '9231',
    Name: 'Watcher, fire'
  },
  {
    Id: 30658,
    Code: '9231',
    Name: 'Watcher, night'
  },
  {
    Id: 30659,
    Code: '9231',
    Name: 'Watcher, ship'
  },
  {
    Id: 30660,
    Code: '9231',
    Name: 'Watchman'
  },
  {
    Id: 30661,
    Code: '9231',
    Name: 'Witness, debt collection'
  },
  {
    Id: 30662,
    Code: '9232',
    Name: 'Assistant, lunchtime, schools'
  },
  {
    Id: 30663,
    Code: '9232',
    Name: 'Assistant, mealtime, schools'
  },
  {
    Id: 30664,
    Code: '9232',
    Name: 'Assistant, midday'
  },
  {
    Id: 30665,
    Code: '9232',
    Name: 'Assistant, playground'
  },
  {
    Id: 30666,
    Code: '9232',
    Name: 'Assistant, supervisory, lunchtime'
  },
  {
    Id: 30667,
    Code: '9232',
    Name: 'Assistant, supervisory, midday'
  },
  {
    Id: 30668,
    Code: '9232',
    Name: 'Assistant, supervisory, school meals'
  },
  {
    Id: 30669,
    Code: '9232',
    Name: 'Assistant, supervisory, midday, schools'
  },
  {
    Id: 30670,
    Code: '9232',
    Name: 'Assistant, time, lunch, schools'
  },
  {
    Id: 30671,
    Code: '9232',
    Name: 'Assistant, time, meal, schools'
  },
  {
    Id: 30672,
    Code: '9232',
    Name: 'Assistant, welfare, dinner'
  },
  {
    Id: 30673,
    Code: '9232',
    Name: 'Assistant, welfare, lunchtime, schools'
  },
  {
    Id: 30674,
    Code: '9232',
    Name: 'Attendant, crossing, road'
  },
  {
    Id: 30675,
    Code: '9232',
    Name: 'Attendant, crossing, schools'
  },
  {
    Id: 30676,
    Code: '9232',
    Name: 'Attendant, ground, play'
  },
  {
    Id: 30677,
    Code: '9232',
    Name: 'Attendant, midday, school meals'
  },
  {
    Id: 30678,
    Code: '9232',
    Name: 'Auxiliary, lunchtime, schools'
  },
  {
    Id: 30679,
    Code: '9232',
    Name: 'Controller, dinner'
  },
  {
    Id: 30680,
    Code: '9232',
    Name: 'Controller, lunch, schools'
  },
  {
    Id: 30681,
    Code: '9232',
    Name: 'Controller, lunchtime, schools'
  },
  {
    Id: 30682,
    Code: '9232',
    Name: 'Dinnerlady'
  },
  {
    Id: 30683,
    Code: '9232',
    Name: "Help, teacher's, school meals"
  },
  {
    Id: 30684,
    Code: '9232',
    Name: 'Lady, dinner, schools'
  },
  {
    Id: 30685,
    Code: '9232',
    Name: 'MDSA'
  },
  {
    Id: 30686,
    Code: '9232',
    Name: 'MSA'
  },
  {
    Id: 30687,
    Code: '9232',
    Name: 'Man, lollipop'
  },
  {
    Id: 30688,
    Code: '9232',
    Name: 'Officer, patrol, crossing, school'
  },
  {
    Id: 30689,
    Code: '9232',
    Name: 'Organiser, lunchtime'
  },
  {
    Id: 30690,
    Code: '9232',
    Name: 'Patrol, crossing, school'
  },
  {
    Id: 30691,
    Code: '9232',
    Name: 'Patrol, crossing'
  },
  {
    Id: 30692,
    Code: '9232',
    Name: 'Patroller, crossing'
  },
  {
    Id: 30693,
    Code: '9232',
    Name: 'Patrolman, crossing, school'
  },
  {
    Id: 30694,
    Code: '9232',
    Name: 'Patrolman, crossing'
  },
  {
    Id: 30695,
    Code: '9232',
    Name: 'SMSA'
  },
  {
    Id: 30696,
    Code: '9232',
    Name: "Superintendent, children's"
  },
  {
    Id: 30697,
    Code: '9232',
    Name: "Supervisor, children's"
  },
  {
    Id: 30698,
    Code: '9232',
    Name: 'Supervisor, dinner'
  },
  {
    Id: 30699,
    Code: '9232',
    Name: 'Supervisor, lunchtime'
  },
  {
    Id: 30700,
    Code: '9232',
    Name: 'Supervisor, meals, schools'
  },
  {
    Id: 30701,
    Code: '9232',
    Name: 'Supervisor, midday'
  },
  {
    Id: 30702,
    Code: '9232',
    Name: 'Supervisor, playground'
  },
  {
    Id: 30703,
    Code: '9232',
    Name: 'Supervisor, school'
  },
  {
    Id: 30704,
    Code: '9232',
    Name: 'Supervisor, time, dinner'
  },
  {
    Id: 30705,
    Code: '9232',
    Name: 'Supervisor, time, lunch'
  },
  {
    Id: 30706,
    Code: '9232',
    Name: 'Supervisor, school meals service'
  },
  {
    Id: 30707,
    Code: '9232',
    Name: 'Warden, crossing'
  },
  {
    Id: 30708,
    Code: '9232',
    Name: 'Warden, dinner, schools'
  },
  {
    Id: 30709,
    Code: '9232',
    Name: 'Warden, patrol, crossing, school'
  },
  {
    Id: 30710,
    Code: '9233',
    Name: 'Invigilator, exam'
  },
  {
    Id: 30711,
    Code: '9233',
    Name: 'Invigilator, examination'
  },
  {
    Id: 30712,
    Code: '9233',
    Name: 'Invigilator, examinations'
  },
  {
    Id: 30713,
    Code: '9233',
    Name: 'Invigilator, exams'
  },
  {
    Id: 30714,
    Code: '9233',
    Name: 'Invigilator'
  },
  {
    Id: 30715,
    Code: '9241',
    Name: 'Adviser, customer, shelf filling'
  },
  {
    Id: 30716,
    Code: '9241',
    Name: 'Assistant, display, evening, shelf filling'
  },
  {
    Id: 30717,
    Code: '9241',
    Name: 'Assistant, display, shelf filling'
  },
  {
    Id: 30718,
    Code: '9241',
    Name: 'Assistant, display, retail trade'
  },
  {
    Id: 30719,
    Code: '9241',
    Name: 'Assistant, filling, stock'
  },
  {
    Id: 30720,
    Code: '9241',
    Name: 'Assistant, floor, retail trade'
  },
  {
    Id: 30721,
    Code: '9241',
    Name: 'Assistant, food, fresh, retail trade'
  },
  {
    Id: 30722,
    Code: '9241',
    Name: 'Assistant, general, retail trade'
  },
  {
    Id: 30723,
    Code: '9241',
    Name: 'Assistant, grocery'
  },
  {
    Id: 30724,
    Code: '9241',
    Name: 'Assistant, night, shelf filling'
  },
  {
    Id: 30725,
    Code: '9241',
    Name: 'Assistant, operations, shelf filling'
  },
  {
    Id: 30726,
    Code: '9241',
    Name: 'Assistant, produce'
  },
  {
    Id: 30727,
    Code: '9241',
    Name: 'Assistant, provision'
  },
  {
    Id: 30728,
    Code: '9241',
    Name: 'Assistant, provisions'
  },
  {
    Id: 30729,
    Code: '9241',
    Name: 'Assistant, replenishment'
  },
  {
    Id: 30730,
    Code: '9241',
    Name: 'Assistant, stock, shelf filling'
  },
  {
    Id: 30731,
    Code: '9241',
    Name: 'Assistant, stock, retail trade'
  },
  {
    Id: 30732,
    Code: '9241',
    Name: 'Colleague, Asda, shelf filling'
  },
  {
    Id: 30733,
    Code: '9241',
    Name: 'Colleague, night, shelf filling'
  },
  {
    Id: 30734,
    Code: '9241',
    Name: 'Controller, ambient'
  },
  {
    Id: 30735,
    Code: '9241',
    Name: 'Controller, replenishment, ambience'
  },
  {
    Id: 30736,
    Code: '9241',
    Name: 'Displayman, shelf filling'
  },
  {
    Id: 30737,
    Code: '9241',
    Name: 'Filler, night, shelf filling'
  },
  {
    Id: 30738,
    Code: '9241',
    Name: 'Filler, shelf'
  },
  {
    Id: 30739,
    Code: '9241',
    Name: 'Filler, shop, shelf filling'
  },
  {
    Id: 30740,
    Code: '9241',
    Name: 'Filler, shelf filling'
  },
  {
    Id: 30741,
    Code: '9241',
    Name: 'Hand, display, shelf filling'
  },
  {
    Id: 30742,
    Code: '9241',
    Name: 'Handler, stock'
  },
  {
    Id: 30743,
    Code: '9241',
    Name: 'Member, team, customer, shelf filling'
  },
  {
    Id: 30744,
    Code: '9241',
    Name: 'Merchandiser, evening'
  },
  {
    Id: 30745,
    Code: '9241',
    Name: 'Merchandiser, shelf filling'
  },
  {
    Id: 30746,
    Code: '9241',
    Name: 'Packer, shelf'
  },
  {
    Id: 30747,
    Code: '9241',
    Name: 'Replenisher, shelf'
  },
  {
    Id: 30748,
    Code: '9241',
    Name: 'Replenisher, stock'
  },
  {
    Id: 30749,
    Code: '9241',
    Name: 'Replenisher, shelf filling'
  },
  {
    Id: 30750,
    Code: '9241',
    Name: 'Runner, retail trade'
  },
  {
    Id: 30751,
    Code: '9241',
    Name: 'Stacker, shelf'
  },
  {
    Id: 30752,
    Code: '9241',
    Name: 'Stacker, shelf filling'
  },
  {
    Id: 30753,
    Code: '9241',
    Name: 'Stocker, shelf'
  },
  {
    Id: 30754,
    Code: '9241',
    Name: 'Stockist, shelf filling'
  },
  {
    Id: 30755,
    Code: '9249',
    Name: 'Assistant, integrity, price'
  },
  {
    Id: 30756,
    Code: '9249',
    Name: 'Assistant, online, retail trade'
  },
  {
    Id: 30757,
    Code: '9249',
    Name: 'Assistant, shopping, internet'
  },
  {
    Id: 30758,
    Code: '9249',
    Name: 'Assistant, shopping, online'
  },
  {
    Id: 30759,
    Code: '9249',
    Name: 'Assistant, trolley'
  },
  {
    Id: 30760,
    Code: '9249',
    Name: 'Attendant, minibar'
  },
  {
    Id: 30761,
    Code: '9249',
    Name: 'Attendant, trolley'
  },
  {
    Id: 30762,
    Code: '9249',
    Name: 'Boy, trolley'
  },
  {
    Id: 30763,
    Code: '9249',
    Name: 'Caller, checkout'
  },
  {
    Id: 30764,
    Code: '9249',
    Name: 'Caller, wholesale, retail trade'
  },
  {
    Id: 30765,
    Code: '9249',
    Name: 'Checker, code, retail trade'
  },
  {
    Id: 30766,
    Code: '9249',
    Name: 'Checker, ticket, wholesale, retail trade'
  },
  {
    Id: 30767,
    Code: '9249',
    Name: 'Collector, trolley'
  },
  {
    Id: 30768,
    Code: '9249',
    Name: 'Controller, code, wholesale, retail trade'
  },
  {
    Id: 30769,
    Code: '9249',
    Name: 'Controller, maintenance, file, wholesale, retail trade'
  },
  {
    Id: 30770,
    Code: '9249',
    Name: 'Controller, price, wholesale, retail trade'
  },
  {
    Id: 30771,
    Code: '9249',
    Name: 'Counter, stock, wholesale, retail trade'
  },
  {
    Id: 30772,
    Code: '9249',
    Name: 'Fixer, advertisement'
  },
  {
    Id: 30773,
    Code: '9249',
    Name: 'Greeter, retail trade'
  },
  {
    Id: 30774,
    Code: '9249',
    Name: 'Hand, advertisement'
  },
  {
    Id: 30775,
    Code: '9249',
    Name: 'Hanger, poster'
  },
  {
    Id: 30776,
    Code: '9249',
    Name: 'Inspector, advertisement'
  },
  {
    Id: 30777,
    Code: '9249',
    Name: 'Inspector, advertising'
  },
  {
    Id: 30778,
    Code: '9249',
    Name: 'Inspector, bill, advertising'
  },
  {
    Id: 30779,
    Code: '9249',
    Name: 'Inspector, site, advertising'
  },
  {
    Id: 30780,
    Code: '9249',
    Name: 'Installer, advertising'
  },
  {
    Id: 30781,
    Code: '9249',
    Name: 'Issuer, basket, retail trade'
  },
  {
    Id: 30782,
    Code: '9249',
    Name: 'Man, basket, retail trade'
  },
  {
    Id: 30783,
    Code: '9249',
    Name: 'Operator, maintenance, file, wholesale, retail trade'
  },
  {
    Id: 30784,
    Code: '9249',
    Name: 'Picker, com, dot, retail trade'
  },
  {
    Id: 30785,
    Code: '9249',
    Name: 'Picker, delivery, home'
  },
  {
    Id: 30786,
    Code: '9249',
    Name: 'Picker, online, retail trade'
  },
  {
    Id: 30787,
    Code: '9249',
    Name: 'Picker, order, retail trade'
  },
  {
    Id: 30788,
    Code: '9249',
    Name: 'Picker, shopping, home'
  },
  {
    Id: 30789,
    Code: '9249',
    Name: 'Picker, retail trade'
  },
  {
    Id: 30790,
    Code: '9249',
    Name: 'Picker-packer, retail trade'
  },
  {
    Id: 30791,
    Code: '9249',
    Name: 'Porter, trolley'
  },
  {
    Id: 30792,
    Code: '9249',
    Name: 'Porter, retail trade'
  },
  {
    Id: 30793,
    Code: '9249',
    Name: 'Poster, bill'
  },
  {
    Id: 30794,
    Code: '9249',
    Name: 'Pricer, retail trade'
  },
  {
    Id: 30795,
    Code: '9249',
    Name: 'Shopper, home'
  },
  {
    Id: 30796,
    Code: '9249',
    Name: 'Shopper, internet, retail trade'
  },
  {
    Id: 30797,
    Code: '9249',
    Name: 'Shopper, online, retail trade'
  },
  {
    Id: 30798,
    Code: '9249',
    Name: 'Sticker, bill'
  },
  {
    Id: 30799,
    Code: '9249',
    Name: 'Tagger, wholesale, retail trade'
  },
  {
    Id: 30800,
    Code: '9251',
    Name: 'Foreman, bay, loading'
  },
  {
    Id: 30801,
    Code: '9251',
    Name: 'Foreman, depot'
  },
  {
    Id: 30802,
    Code: '9251',
    Name: 'Foreman, despatch'
  },
  {
    Id: 30803,
    Code: '9251',
    Name: 'Foreman, dock'
  },
  {
    Id: 30804,
    Code: '9251',
    Name: 'Foreman, hatch'
  },
  {
    Id: 30805,
    Code: '9251',
    Name: 'Foreman, jetty'
  },
  {
    Id: 30806,
    Code: '9251',
    Name: 'Foreman, quay'
  },
  {
    Id: 30807,
    Code: '9251',
    Name: 'Foreman, receiving'
  },
  {
    Id: 30808,
    Code: '9251',
    Name: 'Foreman, room, grey, textile mfr'
  },
  {
    Id: 30809,
    Code: '9251',
    Name: 'Foreman, room, lamp, coal mine'
  },
  {
    Id: 30810,
    Code: '9251',
    Name: 'Foreman, room, pattern, textile mfr'
  },
  {
    Id: 30811,
    Code: '9251',
    Name: 'Foreman, shipping'
  },
  {
    Id: 30812,
    Code: '9251',
    Name: 'Foreman, staithes'
  },
  {
    Id: 30813,
    Code: '9251',
    Name: 'Foreman, stock'
  },
  {
    Id: 30814,
    Code: '9251',
    Name: 'Foreman, store'
  },
  {
    Id: 30815,
    Code: '9251',
    Name: 'Foreman, stores'
  },
  {
    Id: 30816,
    Code: '9251',
    Name: 'Foreman, timber, docks'
  },
  {
    Id: 30817,
    Code: '9251',
    Name: 'Foreman, warehouse'
  },
  {
    Id: 30818,
    Code: '9251',
    Name: 'Foreman, wharf'
  },
  {
    Id: 30819,
    Code: '9251',
    Name: 'Foreman, yard, steel'
  },
  {
    Id: 30820,
    Code: '9251',
    Name: 'Foreman, yard, stock'
  },
  {
    Id: 30821,
    Code: '9251',
    Name: 'Foreman, yard, timber'
  },
  {
    Id: 30822,
    Code: '9251',
    Name: 'Foreman, yard, auctioneers'
  },
  {
    Id: 30823,
    Code: '9251',
    Name: 'Foreman, auctioneers'
  },
  {
    Id: 30824,
    Code: '9251',
    Name: 'Foreman, docks'
  },
  {
    Id: 30825,
    Code: '9251',
    Name: 'Foreman, ordnance depot'
  },
  {
    Id: 30826,
    Code: '9251',
    Name: 'Foreman, PLA'
  },
  {
    Id: 30827,
    Code: '9251',
    Name: 'Foreman, removal contracting'
  },
  {
    Id: 30828,
    Code: '9251',
    Name: 'Foreman, repository'
  },
  {
    Id: 30829,
    Code: '9251',
    Name: 'Foreman, timber merchants'
  },
  {
    Id: 30830,
    Code: '9251',
    Name: 'Foreman, warehousing'
  },
  {
    Id: 30831,
    Code: '9251',
    Name: 'Foreman-ganger, docks'
  },
  {
    Id: 30832,
    Code: '9251',
    Name: 'Ganger, docks'
  },
  {
    Id: 30833,
    Code: '9251',
    Name: 'Keeper, house, ware'
  },
  {
    Id: 30834,
    Code: '9251',
    Name: 'Keeper, store, head'
  },
  {
    Id: 30835,
    Code: '9251',
    Name: 'Keeper, warehouse'
  },
  {
    Id: 30836,
    Code: '9251',
    Name: 'Leader, team, stockhandlers, warehousing'
  },
  {
    Id: 30837,
    Code: '9251',
    Name: 'Leader, team, warehouse'
  },
  {
    Id: 30838,
    Code: '9251',
    Name: 'Stevedore, superintendent'
  },
  {
    Id: 30839,
    Code: '9251',
    Name: 'Stocktaker, steel smelting'
  },
  {
    Id: 30840,
    Code: '9251',
    Name: 'Storeman, chief'
  },
  {
    Id: 30841,
    Code: '9251',
    Name: 'Storeman, head'
  },
  {
    Id: 30842,
    Code: '9251',
    Name: 'Storewoman, superintendent, PO'
  },
  {
    Id: 30843,
    Code: '9251',
    Name: 'Supervisor, despatch'
  },
  {
    Id: 30844,
    Code: '9251',
    Name: 'Supervisor, store'
  },
  {
    Id: 30845,
    Code: '9251',
    Name: 'Supervisor, stores'
  },
  {
    Id: 30846,
    Code: '9251',
    Name: 'Supervisor, warehouse'
  },
  {
    Id: 30847,
    Code: '9251',
    Name: 'Supervisor, mail order house'
  },
  {
    Id: 30848,
    Code: '9251',
    Name: 'Taker, stock, steel smelting'
  },
  {
    Id: 30849,
    Code: '9251',
    Name: 'Warehouseman, chief'
  },
  {
    Id: 30850,
    Code: '9251',
    Name: 'Wharfinger'
  },
  {
    Id: 30851,
    Code: '9252',
    Name: "Assembler, grocer's"
  },
  {
    Id: 30852,
    Code: '9252',
    Name: 'Assembler, load'
  },
  {
    Id: 30853,
    Code: '9252',
    Name: 'Assembler, order'
  },
  {
    Id: 30854,
    Code: '9252',
    Name: 'Assembler, warehouse'
  },
  {
    Id: 30855,
    Code: '9252',
    Name: 'Assembler, wholesale, retail trade'
  },
  {
    Id: 30856,
    Code: '9252',
    Name: 'Assistant, bay, loading'
  },
  {
    Id: 30857,
    Code: '9252',
    Name: 'Assistant, despatch'
  },
  {
    Id: 30858,
    Code: '9252',
    Name: "Assistant, keeper's, store"
  },
  {
    Id: 30859,
    Code: '9252',
    Name: 'Assistant, operations, warehousing'
  },
  {
    Id: 30860,
    Code: '9252',
    Name: 'Assistant, stevedore'
  },
  {
    Id: 30861,
    Code: '9252',
    Name: 'Assistant, store'
  },
  {
    Id: 30862,
    Code: '9252',
    Name: 'Assistant, stores'
  },
  {
    Id: 30863,
    Code: '9252',
    Name: 'Assistant, supplies'
  },
  {
    Id: 30864,
    Code: '9252',
    Name: 'Assistant, warehouse'
  },
  {
    Id: 30865,
    Code: '9252',
    Name: "Assistant, warehouseman's"
  },
  {
    Id: 30866,
    Code: '9252',
    Name: 'Assistant, yard'
  },
  {
    Id: 30867,
    Code: '9252',
    Name: 'Associate, Amazon, warehouse'
  },
  {
    Id: 30868,
    Code: '9252',
    Name: 'Associate, fulfilment'
  },
  {
    Id: 30869,
    Code: '9252',
    Name: 'Associate, sortation, warehousing'
  },
  {
    Id: 30870,
    Code: '9252',
    Name: 'Associate, warehouse'
  },
  {
    Id: 30871,
    Code: '9252',
    Name: 'Attendant, elevator, goods'
  },
  {
    Id: 30872,
    Code: '9252',
    Name: 'Attendant, loading, barge'
  },
  {
    Id: 30873,
    Code: '9252',
    Name: 'Attendant, room, stock'
  },
  {
    Id: 30874,
    Code: '9252',
    Name: 'Attendant, silo'
  },
  {
    Id: 30875,
    Code: '9252',
    Name: 'Attendant, store'
  },
  {
    Id: 30876,
    Code: '9252',
    Name: 'Attendant, stores'
  },
  {
    Id: 30877,
    Code: '9252',
    Name: 'Caller-over, glass mfr'
  },
  {
    Id: 30878,
    Code: '9252',
    Name: 'Carrier, set'
  },
  {
    Id: 30879,
    Code: '9252',
    Name: 'Cellarman, oil'
  },
  {
    Id: 30880,
    Code: '9252',
    Name: 'Cellarman, textile mfr'
  },
  {
    Id: 30881,
    Code: '9252',
    Name: 'Cellarman, wine merchants'
  },
  {
    Id: 30882,
    Code: '9252',
    Name: 'Cellarman, wine mfr'
  },
  {
    Id: 30883,
    Code: '9252',
    Name: 'Chamberman, cold storage'
  },
  {
    Id: 30884,
    Code: '9252',
    Name: 'Checker, goods'
  },
  {
    Id: 30885,
    Code: '9252',
    Name: 'Checker, inwards, goods'
  },
  {
    Id: 30886,
    Code: '9252',
    Name: 'Checker, load'
  },
  {
    Id: 30887,
    Code: '9252',
    Name: 'Checker, stock'
  },
  {
    Id: 30888,
    Code: '9252',
    Name: 'Checker, stores'
  },
  {
    Id: 30889,
    Code: '9252',
    Name: 'Checker, supports'
  },
  {
    Id: 30890,
    Code: '9252',
    Name: 'Checker, timber'
  },
  {
    Id: 30891,
    Code: '9252',
    Name: 'Checker, warehousing'
  },
  {
    Id: 30892,
    Code: '9252',
    Name: 'Checker and weigher'
  },
  {
    Id: 30893,
    Code: '9252',
    Name: 'Colleague, warehouse'
  },
  {
    Id: 30894,
    Code: '9252',
    Name: 'Collector of parts'
  },
  {
    Id: 30895,
    Code: '9252',
    Name: 'Counter, bobbin'
  },
  {
    Id: 30896,
    Code: '9252',
    Name: 'Counter, printing'
  },
  {
    Id: 30897,
    Code: '9252',
    Name: 'Counter, textile mfr'
  },
  {
    Id: 30898,
    Code: '9252',
    Name: 'Counter-off'
  },
  {
    Id: 30899,
    Code: '9252',
    Name: 'Counterman, chemicals'
  },
  {
    Id: 30900,
    Code: '9252',
    Name: 'Counterman, drugs'
  },
  {
    Id: 30901,
    Code: '9252',
    Name: 'Counterman, wool warehouse'
  },
  {
    Id: 30902,
    Code: '9252',
    Name: 'Despatch, goods'
  },
  {
    Id: 30903,
    Code: '9252',
    Name: 'Despatcher, goods'
  },
  {
    Id: 30904,
    Code: '9252',
    Name: 'Despatcher'
  },
  {
    Id: 30905,
    Code: '9252',
    Name: 'Discharger, docks'
  },
  {
    Id: 30906,
    Code: '9252',
    Name: 'Dispatcher, goods'
  },
  {
    Id: 30907,
    Code: '9252',
    Name: 'Dispatcher'
  },
  {
    Id: 30908,
    Code: '9252',
    Name: 'Feeder, stock'
  },
  {
    Id: 30909,
    Code: '9252',
    Name: 'Filler, order'
  },
  {
    Id: 30910,
    Code: '9252',
    Name: 'Filler, stock'
  },
  {
    Id: 30911,
    Code: '9252',
    Name: 'Giver-out'
  },
  {
    Id: 30912,
    Code: '9252',
    Name: 'Hand, despatch'
  },
  {
    Id: 30913,
    Code: '9252',
    Name: 'Hand, dispatch'
  },
  {
    Id: 30914,
    Code: '9252',
    Name: 'Hand, leading, warehousing'
  },
  {
    Id: 30915,
    Code: '9252',
    Name: 'Hand, room, grey'
  },
  {
    Id: 30916,
    Code: '9252',
    Name: 'Hand, room, order'
  },
  {
    Id: 30917,
    Code: '9252',
    Name: 'Hand, room, pattern'
  },
  {
    Id: 30918,
    Code: '9252',
    Name: 'Hand, storage, cold'
  },
  {
    Id: 30919,
    Code: '9252',
    Name: 'Hand, warehouse'
  },
  {
    Id: 30920,
    Code: '9252',
    Name: 'Handler, cask'
  },
  {
    Id: 30921,
    Code: '9252',
    Name: 'Handler, freight, warehousing'
  },
  {
    Id: 30922,
    Code: '9252',
    Name: 'Handler, stock, transport'
  },
  {
    Id: 30923,
    Code: '9252',
    Name: 'Handler, stock, warehousing'
  },
  {
    Id: 30924,
    Code: '9252',
    Name: 'Holder, store'
  },
  {
    Id: 30925,
    Code: '9252',
    Name: 'Issuer'
  },
  {
    Id: 30926,
    Code: '9252',
    Name: 'Keeper, die'
  },
  {
    Id: 30927,
    Code: '9252',
    Name: 'Keeper, granary'
  },
  {
    Id: 30928,
    Code: '9252',
    Name: 'Keeper, linen'
  },
  {
    Id: 30929,
    Code: '9252',
    Name: 'Keeper, magazine'
  },
  {
    Id: 30930,
    Code: '9252',
    Name: 'Keeper, pattern'
  },
  {
    Id: 30931,
    Code: '9252',
    Name: 'Keeper, repository, furniture'
  },
  {
    Id: 30932,
    Code: '9252',
    Name: 'Keeper, stationery'
  },
  {
    Id: 30933,
    Code: '9252',
    Name: 'Keeper, stock'
  },
  {
    Id: 30934,
    Code: '9252',
    Name: 'Keeper, store'
  },
  {
    Id: 30935,
    Code: '9252',
    Name: 'Keeper, store and vault, bonded'
  },
  {
    Id: 30936,
    Code: '9252',
    Name: 'Keeper, tool'
  },
  {
    Id: 30937,
    Code: '9252',
    Name: 'Keeper, vault'
  },
  {
    Id: 30938,
    Code: '9252',
    Name: 'Keeper-clerk, store'
  },
  {
    Id: 30939,
    Code: '9252',
    Name: "Labourer, storekeeper's"
  },
  {
    Id: 30940,
    Code: '9252',
    Name: 'Labourer, stores'
  },
  {
    Id: 30941,
    Code: '9252',
    Name: 'Labourer, warehouse'
  },
  {
    Id: 30942,
    Code: '9252',
    Name: "Labourer, builders' merchants"
  },
  {
    Id: 30943,
    Code: '9252',
    Name: 'Labourer, warehousing'
  },
  {
    Id: 30944,
    Code: '9252',
    Name: 'Lifter, warehousing'
  },
  {
    Id: 30945,
    Code: '9252',
    Name: 'Loader, warehouse'
  },
  {
    Id: 30946,
    Code: '9252',
    Name: 'Loader'
  },
  {
    Id: 30947,
    Code: '9252',
    Name: 'Loader and unloader'
  },
  {
    Id: 30948,
    Code: '9252',
    Name: 'Looker-out, ophthalmic lenses'
  },
  {
    Id: 30949,
    Code: '9252',
    Name: 'Looker-up, footwear'
  },
  {
    Id: 30950,
    Code: '9252',
    Name: 'Maid, linen'
  },
  {
    Id: 30951,
    Code: '9252',
    Name: 'Man, despatch, bakery'
  },
  {
    Id: 30952,
    Code: '9252',
    Name: 'Man, dispatch, bakery'
  },
  {
    Id: 30953,
    Code: '9252',
    Name: 'Man, elevator, goods'
  },
  {
    Id: 30954,
    Code: '9252',
    Name: 'Man, intake'
  },
  {
    Id: 30955,
    Code: '9252',
    Name: 'Man, material'
  },
  {
    Id: 30956,
    Code: '9252',
    Name: 'Man, order'
  },
  {
    Id: 30957,
    Code: '9252',
    Name: 'Man, powder'
  },
  {
    Id: 30958,
    Code: '9252',
    Name: 'Man, room, cotton'
  },
  {
    Id: 30959,
    Code: '9252',
    Name: 'Man, room, drug'
  },
  {
    Id: 30960,
    Code: '9252',
    Name: 'Man, room, grey'
  },
  {
    Id: 30961,
    Code: '9252',
    Name: 'Man, room, pattern'
  },
  {
    Id: 30962,
    Code: '9252',
    Name: 'Man, room, stock'
  },
  {
    Id: 30963,
    Code: '9252',
    Name: 'Man, room, store'
  },
  {
    Id: 30964,
    Code: '9252',
    Name: 'Man, room, weft'
  },
  {
    Id: 30965,
    Code: '9252',
    Name: 'Man, stockroom'
  },
  {
    Id: 30966,
    Code: '9252',
    Name: 'Man, storeroom'
  },
  {
    Id: 30967,
    Code: '9252',
    Name: 'Man, warehouse'
  },
  {
    Id: 30968,
    Code: '9252',
    Name: 'Man, weft'
  },
  {
    Id: 30969,
    Code: '9252',
    Name: 'Man, yard'
  },
  {
    Id: 30970,
    Code: '9252',
    Name: 'Master, baggage'
  },
  {
    Id: 30971,
    Code: '9252',
    Name: 'Member, team, warehouse'
  },
  {
    Id: 30972,
    Code: '9252',
    Name: 'Member, team, warehousing'
  },
  {
    Id: 30973,
    Code: '9252',
    Name: 'Minder, tool'
  },
  {
    Id: 30974,
    Code: '9252',
    Name: 'Numberer, piece'
  },
  {
    Id: 30975,
    Code: '9252',
    Name: 'Operative, bay, loading'
  },
  {
    Id: 30976,
    Code: '9252',
    Name: 'Operative, despatch'
  },
  {
    Id: 30977,
    Code: '9252',
    Name: 'Operative, dispatch'
  },
  {
    Id: 30978,
    Code: '9252',
    Name: 'Operative, logistics'
  },
  {
    Id: 30979,
    Code: '9252',
    Name: 'Operative, store, cold'
  },
  {
    Id: 30980,
    Code: '9252',
    Name: 'Operative, warehouse'
  },
  {
    Id: 30981,
    Code: '9252',
    Name: 'Operative, yard'
  },
  {
    Id: 30982,
    Code: '9252',
    Name: 'Operator, control, stock'
  },
  {
    Id: 30983,
    Code: '9252',
    Name: 'Operator, despatch'
  },
  {
    Id: 30984,
    Code: '9252',
    Name: 'Operator, logistics'
  },
  {
    Id: 30985,
    Code: '9252',
    Name: 'Operator, store, cold'
  },
  {
    Id: 30986,
    Code: '9252',
    Name: 'Operator, warehouse'
  },
  {
    Id: 30987,
    Code: '9252',
    Name: 'Partsman'
  },
  {
    Id: 30988,
    Code: '9252',
    Name: 'Picker, order'
  },
  {
    Id: 30989,
    Code: '9252',
    Name: 'Picker, slate'
  },
  {
    Id: 30990,
    Code: '9252',
    Name: 'Picker, stock'
  },
  {
    Id: 30991,
    Code: '9252',
    Name: 'Picker, warehouse'
  },
  {
    Id: 30992,
    Code: '9252',
    Name: 'Picker, engineering'
  },
  {
    Id: 30993,
    Code: '9252',
    Name: 'Picker, warehousing'
  },
  {
    Id: 30994,
    Code: '9252',
    Name: 'Picker-packer, warehousing'
  },
  {
    Id: 30995,
    Code: '9252',
    Name: 'Porter, warehouse'
  },
  {
    Id: 30996,
    Code: '9252',
    Name: 'Racker'
  },
  {
    Id: 30997,
    Code: '9252',
    Name: 'Receiver'
  },
  {
    Id: 30998,
    Code: '9252',
    Name: 'Selector, spares, vehicle mfr'
  },
  {
    Id: 30999,
    Code: '9252',
    Name: 'Selector, stores'
  },
  {
    Id: 31000,
    Code: '9252',
    Name: 'Selector, government'
  },
  {
    Id: 31001,
    Code: '9252',
    Name: 'Sorter, order, mail order house'
  },
  {
    Id: 31002,
    Code: '9252',
    Name: 'Sorter, parcel, warehousing'
  },
  {
    Id: 31003,
    Code: '9252',
    Name: 'Stacker and packer'
  },
  {
    Id: 31004,
    Code: '9252',
    Name: 'Staff, warehouse'
  },
  {
    Id: 31005,
    Code: '9252',
    Name: 'Stocker, tinplate mfr'
  },
  {
    Id: 31006,
    Code: '9252',
    Name: 'Stockman, manufacturing'
  },
  {
    Id: 31007,
    Code: '9252',
    Name: 'Stocktaker, rolling mill'
  },
  {
    Id: 31008,
    Code: '9252',
    Name: 'Storehand'
  },
  {
    Id: 31009,
    Code: '9252',
    Name: 'Storehouseman'
  },
  {
    Id: 31010,
    Code: '9252',
    Name: 'Storeman, ordnance'
  },
  {
    Id: 31011,
    Code: '9252',
    Name: 'Storeman'
  },
  {
    Id: 31012,
    Code: '9252',
    Name: 'Storer'
  },
  {
    Id: 31013,
    Code: '9252',
    Name: 'Storesman'
  },
  {
    Id: 31014,
    Code: '9252',
    Name: 'Storesperson'
  },
  {
    Id: 31015,
    Code: '9252',
    Name: 'Stower, warehousing'
  },
  {
    Id: 31016,
    Code: '9252',
    Name: 'Taker, number'
  },
  {
    Id: 31017,
    Code: '9252',
    Name: 'Taker, stock, rolling mill'
  },
  {
    Id: 31018,
    Code: '9252',
    Name: 'Taker-in, piece, textile mfr'
  },
  {
    Id: 31019,
    Code: '9252',
    Name: 'Tallyman, timber'
  },
  {
    Id: 31020,
    Code: '9252',
    Name: 'Warehouseman'
  },
  {
    Id: 31021,
    Code: '9252',
    Name: 'Warehouser'
  },
  {
    Id: 31022,
    Code: '9252',
    Name: 'Worker, bond'
  },
  {
    Id: 31023,
    Code: '9252',
    Name: 'Worker, depot'
  },
  {
    Id: 31024,
    Code: '9252',
    Name: 'Worker, despatch'
  },
  {
    Id: 31025,
    Code: '9252',
    Name: 'Worker, dispatch'
  },
  {
    Id: 31026,
    Code: '9252',
    Name: 'Worker, room, cotton'
  },
  {
    Id: 31027,
    Code: '9252',
    Name: 'Worker, room, pattern'
  },
  {
    Id: 31028,
    Code: '9252',
    Name: 'Worker, room, stock'
  },
  {
    Id: 31029,
    Code: '9252',
    Name: 'Worker, silo'
  },
  {
    Id: 31030,
    Code: '9252',
    Name: 'Worker, store, cold'
  },
  {
    Id: 31031,
    Code: '9252',
    Name: 'Worker, supply, retail trade'
  },
  {
    Id: 31032,
    Code: '9252',
    Name: 'Worker, warehouse'
  },
  {
    Id: 31033,
    Code: '9252',
    Name: 'Worker, yard'
  },
  {
    Id: 31034,
    Code: '9252',
    Name: 'Yardman'
  },
  {
    Id: 31035,
    Code: '9252',
    Name: 'Yardsman'
  },
  {
    Id: 31036,
    Code: '9253',
    Name: 'Assistant, delivery'
  },
  {
    Id: 31037,
    Code: '9253',
    Name: "Assistant, drayman's"
  },
  {
    Id: 31038,
    Code: '9253',
    Name: "Assistant, driver's, crane"
  },
  {
    Id: 31039,
    Code: '9253',
    Name: "Assistant, driver's, dumper"
  },
  {
    Id: 31040,
    Code: '9253',
    Name: "Assistant, driver's, lorry"
  },
  {
    Id: 31041,
    Code: '9253',
    Name: "Assistant, driver's, van"
  },
  {
    Id: 31042,
    Code: '9253',
    Name: "Assistant, driver's, road transport"
  },
  {
    Id: 31043,
    Code: '9253',
    Name: "Assistant, remover's, furniture"
  },
  {
    Id: 31044,
    Code: '9253',
    Name: 'Attendant, crane'
  },
  {
    Id: 31045,
    Code: '9253',
    Name: 'Attendant, van'
  },
  {
    Id: 31046,
    Code: '9253',
    Name: 'Attendant, road goods vehicles'
  },
  {
    Id: 31047,
    Code: '9253',
    Name: 'Bummaree'
  },
  {
    Id: 31048,
    Code: '9253',
    Name: 'Contractor, removal'
  },
  {
    Id: 31049,
    Code: '9253',
    Name: 'Depotman'
  },
  {
    Id: 31050,
    Code: '9253',
    Name: 'Follower, crane'
  },
  {
    Id: 31051,
    Code: '9253',
    Name: 'Hand, removal'
  },
  {
    Id: 31052,
    Code: '9253',
    Name: 'Humper, meat market'
  },
  {
    Id: 31053,
    Code: '9253',
    Name: 'Humper, slaughterhouse'
  },
  {
    Id: 31054,
    Code: '9253',
    Name: 'Labourer, haulage contractor'
  },
  {
    Id: 31055,
    Code: '9253',
    Name: 'Lifter, freight'
  },
  {
    Id: 31056,
    Code: '9253',
    Name: 'Loader, lorry'
  },
  {
    Id: 31057,
    Code: '9253',
    Name: 'Man, baggage'
  },
  {
    Id: 31058,
    Code: '9253',
    Name: 'Man, removal'
  },
  {
    Id: 31059,
    Code: '9253',
    Name: 'Man, transport, docks'
  },
  {
    Id: 31060,
    Code: '9253',
    Name: 'Man on lorry, second'
  },
  {
    Id: 31061,
    Code: '9253',
    Name: "Mate, driver's, crane"
  },
  {
    Id: 31062,
    Code: '9253',
    Name: "Mate, driver's, grab"
  },
  {
    Id: 31063,
    Code: '9253',
    Name: "Mate, driver's, lorry"
  },
  {
    Id: 31064,
    Code: '9253',
    Name: "Mate, driver's"
  },
  {
    Id: 31065,
    Code: '9253',
    Name: "Mate, emptier's, gulley"
  },
  {
    Id: 31066,
    Code: '9253',
    Name: 'Operative, hub'
  },
  {
    Id: 31067,
    Code: '9253',
    Name: 'Operative, removals'
  },
  {
    Id: 31068,
    Code: '9253',
    Name: 'Packer, removals'
  },
  {
    Id: 31069,
    Code: '9253',
    Name: 'Pitcher, meat market'
  },
  {
    Id: 31070,
    Code: '9253',
    Name: 'Porter, coal'
  },
  {
    Id: 31071,
    Code: '9253',
    Name: 'Porter, despatch'
  },
  {
    Id: 31072,
    Code: '9253',
    Name: 'Porter, furniture'
  },
  {
    Id: 31073,
    Code: '9253',
    Name: 'Porter, general'
  },
  {
    Id: 31074,
    Code: '9253',
    Name: 'Porter, goods'
  },
  {
    Id: 31075,
    Code: '9253',
    Name: 'Porter, laundry'
  },
  {
    Id: 31076,
    Code: '9253',
    Name: 'Porter, meat'
  },
  {
    Id: 31077,
    Code: '9253',
    Name: 'Porter, motor'
  },
  {
    Id: 31078,
    Code: '9253',
    Name: 'Porter, night, market'
  },
  {
    Id: 31079,
    Code: '9253',
    Name: 'Porter, store'
  },
  {
    Id: 31080,
    Code: '9253',
    Name: 'Porter, stores'
  },
  {
    Id: 31081,
    Code: '9253',
    Name: 'Porter, timber, furniture mfr'
  },
  {
    Id: 31082,
    Code: '9253',
    Name: 'Porter, timber, timber yard'
  },
  {
    Id: 31083,
    Code: '9253',
    Name: 'Porter, timber'
  },
  {
    Id: 31084,
    Code: '9253',
    Name: 'Porter, van'
  },
  {
    Id: 31085,
    Code: '9253',
    Name: 'Porter, food'
  },
  {
    Id: 31086,
    Code: '9253',
    Name: 'Porter, market'
  },
  {
    Id: 31087,
    Code: '9253',
    Name: 'Porter, office'
  },
  {
    Id: 31088,
    Code: '9253',
    Name: 'Porter, timber, timber yard'
  },
  {
    Id: 31089,
    Code: '9253',
    Name: 'Porter, timber'
  },
  {
    Id: 31090,
    Code: '9253',
    Name: 'Porter, manufacturing'
  },
  {
    Id: 31091,
    Code: '9253',
    Name: 'Porter, transport'
  },
  {
    Id: 31092,
    Code: '9253',
    Name: 'Porter'
  },
  {
    Id: 31093,
    Code: '9253',
    Name: 'Porter-messenger'
  },
  {
    Id: 31094,
    Code: '9253',
    Name: 'Porter-packer'
  },
  {
    Id: 31095,
    Code: '9253',
    Name: 'Porter-storeman'
  },
  {
    Id: 31096,
    Code: '9253',
    Name: 'Puller-back, meat market'
  },
  {
    Id: 31097,
    Code: '9253',
    Name: 'Removalman'
  },
  {
    Id: 31098,
    Code: '9253',
    Name: 'Remover, furniture'
  },
  {
    Id: 31099,
    Code: '9253',
    Name: 'Unloader'
  },
  {
    Id: 31100,
    Code: '9253',
    Name: 'Worker, transport, docks'
  },
  {
    Id: 31101,
    Code: '9253',
    Name: 'Worker, transport, waterways'
  },
  {
    Id: 31102,
    Code: '9259',
    Name: "Assistant, auctioneer's"
  },
  {
    Id: 31103,
    Code: '9259',
    Name: 'Assistant, general, wholesale trade'
  },
  {
    Id: 31104,
    Code: '9259',
    Name: 'Assistant, room, grey'
  },
  {
    Id: 31105,
    Code: '9259',
    Name: 'Assistant, room, linen'
  },
  {
    Id: 31106,
    Code: '9259',
    Name: 'Assistant, room, pattern, textile mfr'
  },
  {
    Id: 31107,
    Code: '9259',
    Name: 'Assistant, room, sale, auctioneers'
  },
  {
    Id: 31108,
    Code: '9259',
    Name: 'Assistant, room, sample'
  },
  {
    Id: 31109,
    Code: '9259',
    Name: 'Attendant, customs'
  },
  {
    Id: 31110,
    Code: '9259',
    Name: 'Attendant, explosive, coal mine'
  },
  {
    Id: 31111,
    Code: '9259',
    Name: 'Attendant, explosives, coal mine'
  },
  {
    Id: 31112,
    Code: '9259',
    Name: 'Attendant, house, powder'
  },
  {
    Id: 31113,
    Code: '9259',
    Name: 'Attendant, lamp, coal mine'
  },
  {
    Id: 31114,
    Code: '9259',
    Name: 'Attendant, magazine'
  },
  {
    Id: 31115,
    Code: '9259',
    Name: 'Attendant, plan'
  },
  {
    Id: 31116,
    Code: '9259',
    Name: 'Attendant, room, grey'
  },
  {
    Id: 31117,
    Code: '9259',
    Name: 'Attendant, room, lamp'
  },
  {
    Id: 31118,
    Code: '9259',
    Name: 'Attendant, room, linen'
  },
  {
    Id: 31119,
    Code: '9259',
    Name: 'Attendant, room, plan'
  },
  {
    Id: 31120,
    Code: '9259',
    Name: 'Attendant, room, tool'
  },
  {
    Id: 31121,
    Code: '9259',
    Name: 'Attendant, statutory'
  },
  {
    Id: 31122,
    Code: '9259',
    Name: 'Attendant, wharf, oil'
  },
  {
    Id: 31123,
    Code: '9259',
    Name: 'Banksman, crane'
  },
  {
    Id: 31124,
    Code: '9259',
    Name: 'Banksman, civil engineering'
  },
  {
    Id: 31125,
    Code: '9259',
    Name: 'Banksman, manufacturing'
  },
  {
    Id: 31126,
    Code: '9259',
    Name: 'Bobber, fish'
  },
  {
    Id: 31127,
    Code: '9259',
    Name: 'Bobber, fish dock'
  },
  {
    Id: 31128,
    Code: '9259',
    Name: 'Bunker, docks'
  },
  {
    Id: 31129,
    Code: '9259',
    Name: 'Carrier, bag, docks'
  },
  {
    Id: 31130,
    Code: '9259',
    Name: 'Carrier, box, fish'
  },
  {
    Id: 31131,
    Code: '9259',
    Name: 'Carrier, deal, docks'
  },
  {
    Id: 31132,
    Code: '9259',
    Name: 'Carrier, deal'
  },
  {
    Id: 31133,
    Code: '9259',
    Name: 'Carrier, pitwood'
  },
  {
    Id: 31134,
    Code: '9259',
    Name: 'Carrier, prop'
  },
  {
    Id: 31135,
    Code: '9259',
    Name: 'Carrier, timber, docks'
  },
  {
    Id: 31136,
    Code: '9259',
    Name: 'Carrier, docks'
  },
  {
    Id: 31137,
    Code: '9259',
    Name: 'Chainman, docks'
  },
  {
    Id: 31138,
    Code: '9259',
    Name: 'Checker, dock'
  },
  {
    Id: 31139,
    Code: '9259',
    Name: 'Checker, linen, hotels, catering, public houses'
  },
  {
    Id: 31140,
    Code: '9259',
    Name: 'Checker, steel, coal mine'
  },
  {
    Id: 31141,
    Code: '9259',
    Name: 'Checker, coal mine'
  },
  {
    Id: 31142,
    Code: '9259',
    Name: 'Collier, boat, barge'
  },
  {
    Id: 31143,
    Code: '9259',
    Name: 'Crew, dock'
  },
  {
    Id: 31144,
    Code: '9259',
    Name: 'Crewman'
  },
  {
    Id: 31145,
    Code: '9259',
    Name: 'Docker'
  },
  {
    Id: 31146,
    Code: '9259',
    Name: 'Filler, docks'
  },
  {
    Id: 31147,
    Code: '9259',
    Name: 'Finder, tool'
  },
  {
    Id: 31148,
    Code: '9259',
    Name: 'Gangwayman'
  },
  {
    Id: 31149,
    Code: '9259',
    Name: 'Hand, jetty'
  },
  {
    Id: 31150,
    Code: '9259',
    Name: 'Handler, art'
  },
  {
    Id: 31151,
    Code: '9259',
    Name: 'Handler, cargo, docks'
  },
  {
    Id: 31152,
    Code: '9259',
    Name: 'Handler, cargo'
  },
  {
    Id: 31153,
    Code: '9259',
    Name: 'Hanger-on'
  },
  {
    Id: 31154,
    Code: '9259',
    Name: 'Hatchman'
  },
  {
    Id: 31155,
    Code: '9259',
    Name: 'Hatchwayman'
  },
  {
    Id: 31156,
    Code: '9259',
    Name: 'Heaver, coal'
  },
  {
    Id: 31157,
    Code: '9259',
    Name: 'Helper, ladle'
  },
  {
    Id: 31158,
    Code: '9259',
    Name: 'Holdsman'
  },
  {
    Id: 31159,
    Code: '9259',
    Name: 'Jettyman'
  },
  {
    Id: 31160,
    Code: '9259',
    Name: 'Keeper, plan, railways'
  },
  {
    Id: 31161,
    Code: '9259',
    Name: 'Labourer, dock'
  },
  {
    Id: 31162,
    Code: '9259',
    Name: 'Labourer, fish'
  },
  {
    Id: 31163,
    Code: '9259',
    Name: 'Labourer, jetty'
  },
  {
    Id: 31164,
    Code: '9259',
    Name: 'Labourer, pitwood'
  },
  {
    Id: 31165,
    Code: '9259',
    Name: 'Labourer, pontoon'
  },
  {
    Id: 31166,
    Code: '9259',
    Name: 'Labourer, quay'
  },
  {
    Id: 31167,
    Code: '9259',
    Name: 'Labourer, quayside'
  },
  {
    Id: 31168,
    Code: '9259',
    Name: 'Labourer, riverside'
  },
  {
    Id: 31169,
    Code: '9259',
    Name: 'Labourer, ship'
  },
  {
    Id: 31170,
    Code: '9259',
    Name: "Labourer, stevedore's"
  },
  {
    Id: 31171,
    Code: '9259',
    Name: 'Labourer, waterside'
  },
  {
    Id: 31172,
    Code: '9259',
    Name: 'Labourer, wharf'
  },
  {
    Id: 31173,
    Code: '9259',
    Name: 'Labourer, docks'
  },
  {
    Id: 31174,
    Code: '9259',
    Name: 'Labourer, fish processing'
  },
  {
    Id: 31175,
    Code: '9259',
    Name: 'Lampman, coal mine'
  },
  {
    Id: 31176,
    Code: '9259',
    Name: 'Lampman, mine: not coal'
  },
  {
    Id: 31177,
    Code: '9259',
    Name: 'Lander, fish'
  },
  {
    Id: 31178,
    Code: '9259',
    Name: 'Lasher, car'
  },
  {
    Id: 31179,
    Code: '9259',
    Name: 'Latcher, crane, steelworks'
  },
  {
    Id: 31180,
    Code: '9259',
    Name: 'Latcher, steelworks'
  },
  {
    Id: 31181,
    Code: '9259',
    Name: 'Loader, barge'
  },
  {
    Id: 31182,
    Code: '9259',
    Name: 'Loader, boat'
  },
  {
    Id: 31183,
    Code: '9259',
    Name: 'Loader, dockside'
  },
  {
    Id: 31184,
    Code: '9259',
    Name: 'Loader, fish'
  },
  {
    Id: 31185,
    Code: '9259',
    Name: 'Loader, timber, docks'
  },
  {
    Id: 31186,
    Code: '9259',
    Name: 'Loader, docks'
  },
  {
    Id: 31187,
    Code: '9259',
    Name: 'Loaderman, ship'
  },
  {
    Id: 31188,
    Code: '9259',
    Name: 'Longshoreman'
  },
  {
    Id: 31189,
    Code: '9259',
    Name: 'Lumper, fish'
  },
  {
    Id: 31190,
    Code: '9259',
    Name: 'Lumper, docks'
  },
  {
    Id: 31191,
    Code: '9259',
    Name: 'Man, ballast'
  },
  {
    Id: 31192,
    Code: '9259',
    Name: 'Man, dock'
  },
  {
    Id: 31193,
    Code: '9259',
    Name: 'Man, explosives, mining'
  },
  {
    Id: 31194,
    Code: '9259',
    Name: 'Man, gear, docks'
  },
  {
    Id: 31195,
    Code: '9259',
    Name: 'Man, wharf'
  },
  {
    Id: 31196,
    Code: '9259',
    Name: 'Minder, hatch'
  },
  {
    Id: 31197,
    Code: '9259',
    Name: 'Operative, jetty'
  },
  {
    Id: 31198,
    Code: '9259',
    Name: 'Operative, port'
  },
  {
    Id: 31199,
    Code: '9259',
    Name: 'Operative, x-ray, cargo/freight, warehousing'
  },
  {
    Id: 31200,
    Code: '9259',
    Name: 'Operator, jetty'
  },
  {
    Id: 31201,
    Code: '9259',
    Name: 'Operator, port'
  },
  {
    Id: 31202,
    Code: '9259',
    Name: 'Pickman, coal mine'
  },
  {
    Id: 31203,
    Code: '9259',
    Name: 'Porter, dock'
  },
  {
    Id: 31204,
    Code: '9259',
    Name: 'Porter, food, docks'
  },
  {
    Id: 31205,
    Code: '9259',
    Name: 'Porter, goods, canals'
  },
  {
    Id: 31206,
    Code: '9259',
    Name: 'Porter, food, docks'
  },
  {
    Id: 31207,
    Code: '9259',
    Name: 'Quayman'
  },
  {
    Id: 31208,
    Code: '9259',
    Name: 'Rafter'
  },
  {
    Id: 31209,
    Code: '9259',
    Name: 'Railman, docks'
  },
  {
    Id: 31210,
    Code: '9259',
    Name: 'Ripener, banana'
  },
  {
    Id: 31211,
    Code: '9259',
    Name: 'Runner, deal, timber merchants'
  },
  {
    Id: 31212,
    Code: '9259',
    Name: 'Runner, docks'
  },
  {
    Id: 31213,
    Code: '9259',
    Name: 'Screener, cargo, x-ray, warehousing'
  },
  {
    Id: 31214,
    Code: '9259',
    Name: 'Shedman, docks'
  },
  {
    Id: 31215,
    Code: '9259',
    Name: 'Sheeter, docks'
  },
  {
    Id: 31216,
    Code: '9259',
    Name: 'Shipper, docks'
  },
  {
    Id: 31217,
    Code: '9259',
    Name: 'Signalman, steelworks'
  },
  {
    Id: 31218,
    Code: '9259',
    Name: 'Slinger'
  },
  {
    Id: 31219,
    Code: '9259',
    Name: 'Staithman'
  },
  {
    Id: 31220,
    Code: '9259',
    Name: 'Stevedore'
  },
  {
    Id: 31221,
    Code: '9259',
    Name: 'Steward, shore'
  },
  {
    Id: 31222,
    Code: '9259',
    Name: 'Stower, ship'
  },
  {
    Id: 31223,
    Code: '9259',
    Name: 'Tackleman, docks'
  },
  {
    Id: 31224,
    Code: '9259',
    Name: 'Teemer, coal'
  },
  {
    Id: 31225,
    Code: '9259',
    Name: 'Tipper, ballast'
  },
  {
    Id: 31226,
    Code: '9259',
    Name: 'Tipper, coal, docks'
  },
  {
    Id: 31227,
    Code: '9259',
    Name: 'Tipper, docks'
  },
  {
    Id: 31228,
    Code: '9259',
    Name: 'Trimmer, ballast'
  },
  {
    Id: 31229,
    Code: '9259',
    Name: 'Trimmer, barge'
  },
  {
    Id: 31230,
    Code: '9259',
    Name: 'Trimmer, boat'
  },
  {
    Id: 31231,
    Code: '9259',
    Name: 'Trimmer, coal, docks'
  },
  {
    Id: 31232,
    Code: '9259',
    Name: 'Trimmer, docks'
  },
  {
    Id: 31233,
    Code: '9259',
    Name: 'Unloader, docks'
  },
  {
    Id: 31234,
    Code: '9259',
    Name: 'Waterman, dock'
  },
  {
    Id: 31235,
    Code: '9259',
    Name: 'Worker, cargo'
  },
  {
    Id: 31236,
    Code: '9259',
    Name: 'Worker, dock'
  },
  {
    Id: 31237,
    Code: '9259',
    Name: 'Worker, dockside'
  },
  {
    Id: 31238,
    Code: '9259',
    Name: 'Worker, fish, docks'
  },
  {
    Id: 31239,
    Code: '9259',
    Name: 'Worker, port'
  },
  {
    Id: 31240,
    Code: '9259',
    Name: 'Worker, room, grey, textile mfr'
  },
  {
    Id: 31241,
    Code: '9259',
    Name: 'Worker, ship'
  },
  {
    Id: 31242,
    Code: '9261',
    Name: 'Barmaid, head'
  },
  {
    Id: 31243,
    Code: '9261',
    Name: 'Barman, head'
  },
  {
    Id: 31244,
    Code: '9261',
    Name: 'Barperson, head'
  },
  {
    Id: 31245,
    Code: '9261',
    Name: 'Barstaff, head'
  },
  {
    Id: 31246,
    Code: '9261',
    Name: 'Barworker, head'
  },
  {
    Id: 31247,
    Code: '9261',
    Name: 'Chef de rang'
  },
  {
    Id: 31248,
    Code: '9261',
    Name: 'Foreman, catering'
  },
  {
    Id: 31249,
    Code: '9261',
    Name: 'Inspector, car, dining car'
  },
  {
    Id: 31250,
    Code: '9261',
    Name: 'Leader, shift, public house'
  },
  {
    Id: 31251,
    Code: '9261',
    Name: 'Leader, team, bar'
  },
  {
    Id: 31252,
    Code: '9261',
    Name: 'Leader, team, catering'
  },
  {
    Id: 31253,
    Code: '9261',
    Name: "Maitre d'hotel"
  },
  {
    Id: 31254,
    Code: '9261',
    Name: 'Manager, area, fast food outlet'
  },
  {
    Id: 31255,
    Code: '9261',
    Name: 'Steward, chief'
  },
  {
    Id: 31256,
    Code: '9261',
    Name: 'Steward, kitchen'
  },
  {
    Id: 31257,
    Code: '9261',
    Name: 'Supervisor, bar'
  },
  {
    Id: 31258,
    Code: '9261',
    Name: 'Supervisor, café'
  },
  {
    Id: 31259,
    Code: '9261',
    Name: 'Supervisor, canteen'
  },
  {
    Id: 31260,
    Code: '9261',
    Name: 'Supervisor, catering'
  },
  {
    Id: 31261,
    Code: '9261',
    Name: 'Supervisor, food and beverage'
  },
  {
    Id: 31262,
    Code: '9261',
    Name: 'Supervisor, hospitality'
  },
  {
    Id: 31263,
    Code: '9261',
    Name: 'Supervisor, kitchen'
  },
  {
    Id: 31264,
    Code: '9261',
    Name: 'Supervisor, restaurant'
  },
  {
    Id: 31265,
    Code: '9261',
    Name: 'Supervisor, room, dining, hospital service'
  },
  {
    Id: 31266,
    Code: '9261',
    Name: 'Supervisor, shop, coffee'
  },
  {
    Id: 31267,
    Code: '9261',
    Name: 'Supervisor, unit, catering'
  },
  {
    Id: 31268,
    Code: '9261',
    Name: 'Waiter, head'
  },
  {
    Id: 31269,
    Code: '9261',
    Name: 'Waitress, head'
  },
  {
    Id: 31270,
    Code: '9262',
    Name: 'Porter, domestic, hospital service'
  },
  {
    Id: 31271,
    Code: '9262',
    Name: 'Porter, general, hospital service'
  },
  {
    Id: 31272,
    Code: '9262',
    Name: 'Porter, hall, hospital service'
  },
  {
    Id: 31273,
    Code: '9262',
    Name: 'Porter, head, hospital service'
  },
  {
    Id: 31274,
    Code: '9262',
    Name: 'Porter, hospital'
  },
  {
    Id: 31275,
    Code: '9262',
    Name: 'Porter, night, hospital service'
  },
  {
    Id: 31276,
    Code: '9262',
    Name: 'Porter, ward'
  },
  {
    Id: 31277,
    Code: '9262',
    Name: 'Porter, hospital service'
  },
  {
    Id: 31278,
    Code: '9262',
    Name: 'Supervisor, portering, hospital service'
  },
  {
    Id: 31279,
    Code: '9263',
    Name: 'Artist, sandwich'
  },
  {
    Id: 31280,
    Code: '9263',
    Name: 'Assistant, bar, snack'
  },
  {
    Id: 31281,
    Code: '9263',
    Name: 'Assistant, bar, non-alcoholic'
  },
  {
    Id: 31282,
    Code: '9263',
    Name: 'Assistant, buttery'
  },
  {
    Id: 31283,
    Code: '9263',
    Name: 'Assistant, cafeteria'
  },
  {
    Id: 31284,
    Code: '9263',
    Name: 'Assistant, café'
  },
  {
    Id: 31285,
    Code: '9263',
    Name: 'Assistant, canteen'
  },
  {
    Id: 31286,
    Code: '9263',
    Name: 'Assistant, care, customer, fast food outlet'
  },
  {
    Id: 31287,
    Code: '9263',
    Name: "Assistant, caterer's"
  },
  {
    Id: 31288,
    Code: '9263',
    Name: 'Assistant, catering'
  },
  {
    Id: 31289,
    Code: '9263',
    Name: 'Assistant, chef’s'
  },
  {
    Id: 31290,
    Code: '9263',
    Name: "Assistant, cook's"
  },
  {
    Id: 31291,
    Code: '9263',
    Name: 'Assistant, cookery'
  },
  {
    Id: 31292,
    Code: '9263',
    Name: 'Assistant, counter, catering'
  },
  {
    Id: 31293,
    Code: '9263',
    Name: 'Assistant, counter, café'
  },
  {
    Id: 31294,
    Code: '9263',
    Name: 'Assistant, counter, fast food outlet'
  },
  {
    Id: 31295,
    Code: '9263',
    Name: 'Assistant, customer, catering'
  },
  {
    Id: 31296,
    Code: '9263',
    Name: 'Assistant, food, fast'
  },
  {
    Id: 31297,
    Code: '9263',
    Name: 'Assistant, food, catering'
  },
  {
    Id: 31298,
    Code: '9263',
    Name: 'Assistant, food, fast food'
  },
  {
    Id: 31299,
    Code: '9263',
    Name: "Assistant, frier's, fish"
  },
  {
    Id: 31300,
    Code: '9263',
    Name: 'Assistant, front of house, catering'
  },
  {
    Id: 31301,
    Code: '9263',
    Name: 'Assistant, general, catering'
  },
  {
    Id: 31302,
    Code: '9263',
    Name: 'Assistant, general, school meals'
  },
  {
    Id: 31303,
    Code: '9263',
    Name: 'Assistant, hall, dining'
  },
  {
    Id: 31304,
    Code: '9263',
    Name: 'Assistant, house, cook'
  },
  {
    Id: 31305,
    Code: '9263',
    Name: 'Assistant, kitchen'
  },
  {
    Id: 31306,
    Code: '9263',
    Name: 'Assistant, lunchtime, preparing, serving food'
  },
  {
    Id: 31307,
    Code: '9263',
    Name: 'Assistant, meals, school'
  },
  {
    Id: 31308,
    Code: '9263',
    Name: 'Assistant, mealtime, preparing, serving food'
  },
  {
    Id: 31309,
    Code: '9263',
    Name: 'Assistant, preparation, food'
  },
  {
    Id: 31310,
    Code: '9263',
    Name: 'Assistant, production, catering'
  },
  {
    Id: 31311,
    Code: '9263',
    Name: 'Assistant, refectory'
  },
  {
    Id: 31312,
    Code: '9263',
    Name: 'Assistant, restaurant'
  },
  {
    Id: 31313,
    Code: '9263',
    Name: 'Assistant, room, dining'
  },
  {
    Id: 31314,
    Code: '9263',
    Name: 'Assistant, room, still'
  },
  {
    Id: 31315,
    Code: '9263',
    Name: 'Assistant, room, tea'
  },
  {
    Id: 31316,
    Code: '9263',
    Name: 'Assistant, servery, preparing, serving food'
  },
  {
    Id: 31317,
    Code: '9263',
    Name: 'Assistant, service, catering'
  },
  {
    Id: 31318,
    Code: '9263',
    Name: 'Assistant, service, counter, catering'
  },
  {
    Id: 31319,
    Code: '9263',
    Name: 'Assistant, service, customer, fast food outlet'
  },
  {
    Id: 31320,
    Code: '9263',
    Name: 'Assistant, service, food'
  },
  {
    Id: 31321,
    Code: '9263',
    Name: 'Assistant, services, catering'
  },
  {
    Id: 31322,
    Code: '9263',
    Name: 'Assistant, serving, catering'
  },
  {
    Id: 31323,
    Code: '9263',
    Name: 'Assistant, shop, fast food outlet'
  },
  {
    Id: 31324,
    Code: '9263',
    Name: 'Assistant, staff, canteen'
  },
  {
    Id: 31325,
    Code: '9263',
    Name: 'Assistant, store, fast food outlet'
  },
  {
    Id: 31326,
    Code: '9263',
    Name: 'Assistant, tearoom'
  },
  {
    Id: 31327,
    Code: '9263',
    Name: 'Assistant, catering'
  },
  {
    Id: 31328,
    Code: '9263',
    Name: 'Associate, kitchen'
  },
  {
    Id: 31329,
    Code: '9263',
    Name: 'Attendant, bar, snack'
  },
  {
    Id: 31330,
    Code: '9263',
    Name: 'Attendant, bar, non-alcoholic'
  },
  {
    Id: 31331,
    Code: '9263',
    Name: 'Attendant, buffet'
  },
  {
    Id: 31332,
    Code: '9263',
    Name: 'Attendant, canteen'
  },
  {
    Id: 31333,
    Code: '9263',
    Name: 'Attendant, hall, dining'
  },
  {
    Id: 31334,
    Code: '9263',
    Name: 'Attendant, kitchen'
  },
  {
    Id: 31335,
    Code: '9263',
    Name: 'Attendant, meals, school'
  },
  {
    Id: 31336,
    Code: '9263',
    Name: 'Attendant, mess'
  },
  {
    Id: 31337,
    Code: '9263',
    Name: 'Attendant, room, dining'
  },
  {
    Id: 31338,
    Code: '9263',
    Name: 'Attendant, room, mess'
  },
  {
    Id: 31339,
    Code: '9263',
    Name: 'Attendant, room, refreshment'
  },
  {
    Id: 31340,
    Code: '9263',
    Name: 'Attendant, room, still'
  },
  {
    Id: 31341,
    Code: '9263',
    Name: 'Attendant, catering'
  },
  {
    Id: 31342,
    Code: '9263',
    Name: 'Barmaid, tea'
  },
  {
    Id: 31343,
    Code: '9263',
    Name: 'Barman, tea'
  },
  {
    Id: 31344,
    Code: '9263',
    Name: 'Boy, messroom'
  },
  {
    Id: 31345,
    Code: '9263',
    Name: 'Boy, tea'
  },
  {
    Id: 31346,
    Code: '9263',
    Name: 'Boy, trolley, catering'
  },
  {
    Id: 31347,
    Code: '9263',
    Name: 'Carver, food'
  },
  {
    Id: 31348,
    Code: '9263',
    Name: 'Cleaner, plate, catering'
  },
  {
    Id: 31349,
    Code: '9263',
    Name: 'Cleaner, silver, domestic service'
  },
  {
    Id: 31350,
    Code: '9263',
    Name: 'Cleaner, silver, hotels, catering, public houses'
  },
  {
    Id: 31351,
    Code: '9263',
    Name: 'Clearer, table'
  },
  {
    Id: 31352,
    Code: '9263',
    Name: 'Counterhand'
  },
  {
    Id: 31353,
    Code: '9263',
    Name: 'Counterman, catering'
  },
  {
    Id: 31354,
    Code: '9263',
    Name: 'Dinnerlady, preparing, serving food, schools'
  },
  {
    Id: 31355,
    Code: '9263',
    Name: 'Dishwasher'
  },
  {
    Id: 31356,
    Code: '9263',
    Name: 'Dispenser, food and beverages'
  },
  {
    Id: 31357,
    Code: '9263',
    Name: 'Domestic, kitchen'
  },
  {
    Id: 31358,
    Code: '9263',
    Name: 'Glassman'
  },
  {
    Id: 31359,
    Code: '9263',
    Name: 'Hand, cafeteria'
  },
  {
    Id: 31360,
    Code: '9263',
    Name: 'Hand, canteen'
  },
  {
    Id: 31361,
    Code: '9263',
    Name: 'Hand, galley'
  },
  {
    Id: 31362,
    Code: '9263',
    Name: 'Hand, kitchen'
  },
  {
    Id: 31363,
    Code: '9263',
    Name: 'Hand, mess'
  },
  {
    Id: 31364,
    Code: '9263',
    Name: 'Hand, room, service'
  },
  {
    Id: 31365,
    Code: '9263',
    Name: 'Hand, servery'
  },
  {
    Id: 31366,
    Code: '9263',
    Name: 'Hand, service, catering'
  },
  {
    Id: 31367,
    Code: '9263',
    Name: 'Hand, steam, catering'
  },
  {
    Id: 31368,
    Code: '9263',
    Name: 'Help, canteen'
  },
  {
    Id: 31369,
    Code: '9263',
    Name: 'Help, kitchen'
  },
  {
    Id: 31370,
    Code: '9263',
    Name: 'Help, meals, school'
  },
  {
    Id: 31371,
    Code: '9263',
    Name: 'Help, room, dining'
  },
  {
    Id: 31372,
    Code: '9263',
    Name: 'Helper, canteen'
  },
  {
    Id: 31373,
    Code: '9263',
    Name: 'Helper, kitchen'
  },
  {
    Id: 31374,
    Code: '9263',
    Name: 'Helper, meals, school'
  },
  {
    Id: 31375,
    Code: '9263',
    Name: 'Helper, room, dining'
  },
  {
    Id: 31376,
    Code: '9263',
    Name: 'Helper, catering'
  },
  {
    Id: 31377,
    Code: '9263',
    Name: 'Host, ward, hospital service'
  },
  {
    Id: 31378,
    Code: '9263',
    Name: 'Hostess, hospital service'
  },
  {
    Id: 31379,
    Code: '9263',
    Name: 'KP'
  },
  {
    Id: 31380,
    Code: '9263',
    Name: 'Lady, dinner, preparing, serving food, schools'
  },
  {
    Id: 31381,
    Code: '9263',
    Name: 'Lady, tea'
  },
  {
    Id: 31382,
    Code: '9263',
    Name: 'Leader, experience, customer, fast food outlet'
  },
  {
    Id: 31383,
    Code: '9263',
    Name: 'Maid, buffet'
  },
  {
    Id: 31384,
    Code: '9263',
    Name: 'Maid, canteen, schools'
  },
  {
    Id: 31385,
    Code: '9263',
    Name: 'Maid, hall, dining'
  },
  {
    Id: 31386,
    Code: '9263',
    Name: 'Maid, kitchen'
  },
  {
    Id: 31387,
    Code: '9263',
    Name: 'Maid, pantry'
  },
  {
    Id: 31388,
    Code: '9263',
    Name: 'Maid, room, dining'
  },
  {
    Id: 31389,
    Code: '9263',
    Name: 'Maid, room, mess'
  },
  {
    Id: 31390,
    Code: '9263',
    Name: 'Maid, room, still'
  },
  {
    Id: 31391,
    Code: '9263',
    Name: 'Maid, table'
  },
  {
    Id: 31392,
    Code: '9263',
    Name: 'Maker, pizza, fast food'
  },
  {
    Id: 31393,
    Code: '9263',
    Name: 'Maker, sandwich'
  },
  {
    Id: 31394,
    Code: '9263',
    Name: 'Maker, tea'
  },
  {
    Id: 31395,
    Code: '9263',
    Name: 'Man, despatch, catering'
  },
  {
    Id: 31396,
    Code: '9263',
    Name: 'Man, dispatch, catering'
  },
  {
    Id: 31397,
    Code: '9263',
    Name: 'Man, kitchen'
  },
  {
    Id: 31398,
    Code: '9263',
    Name: 'Man, pan, hotels, catering, public houses'
  },
  {
    Id: 31399,
    Code: '9263',
    Name: 'Man, pantry'
  },
  {
    Id: 31400,
    Code: '9263',
    Name: 'Man, plate, hotels, catering, public houses'
  },
  {
    Id: 31401,
    Code: '9263',
    Name: 'Man, room, still, hotels, catering, public houses'
  },
  {
    Id: 31402,
    Code: '9263',
    Name: 'Man, still, hotels, catering, public houses'
  },
  {
    Id: 31403,
    Code: '9263',
    Name: 'Man, stillroom, hotels, catering, public houses'
  },
  {
    Id: 31404,
    Code: '9263',
    Name: 'Masher, tea'
  },
  {
    Id: 31405,
    Code: '9263',
    Name: "Mate, cook's"
  },
  {
    Id: 31406,
    Code: '9263',
    Name: 'Member, crew, fast food outlet'
  },
  {
    Id: 31407,
    Code: '9263',
    Name: 'Member, squad, training'
  },
  {
    Id: 31408,
    Code: '9263',
    Name: 'Member, team, front of house, catering'
  },
  {
    Id: 31409,
    Code: '9263',
    Name: 'Member, team, fast food outlet'
  },
  {
    Id: 31410,
    Code: '9263',
    Name: 'Messman'
  },
  {
    Id: 31411,
    Code: '9263',
    Name: 'Official, NAAFI'
  },
  {
    Id: 31412,
    Code: '9263',
    Name: 'Orderly, kitchen'
  },
  {
    Id: 31413,
    Code: '9263',
    Name: 'Orderly, mess'
  },
  {
    Id: 31414,
    Code: '9263',
    Name: 'Orderly, room, dining'
  },
  {
    Id: 31415,
    Code: '9263',
    Name: 'Organiser, meals, school'
  },
  {
    Id: 31416,
    Code: '9263',
    Name: 'Polisher, silver, hotels, catering, public houses'
  },
  {
    Id: 31417,
    Code: '9263',
    Name: 'Porter, kitchen'
  },
  {
    Id: 31418,
    Code: '9263',
    Name: 'Porter, catering'
  },
  {
    Id: 31419,
    Code: '9263',
    Name: 'Potman, hotels, catering, public houses'
  },
  {
    Id: 31420,
    Code: '9263',
    Name: 'Preparer, sandwich'
  },
  {
    Id: 31421,
    Code: '9263',
    Name: 'Server, canteen'
  },
  {
    Id: 31422,
    Code: '9263',
    Name: 'Server, dinner'
  },
  {
    Id: 31423,
    Code: '9263',
    Name: 'Server, food'
  },
  {
    Id: 31424,
    Code: '9263',
    Name: 'Server, meal'
  },
  {
    Id: 31425,
    Code: '9263',
    Name: 'Server, meals'
  },
  {
    Id: 31426,
    Code: '9263',
    Name: 'Server, school meals'
  },
  {
    Id: 31427,
    Code: '9263',
    Name: 'Silverman'
  },
  {
    Id: 31428,
    Code: '9263',
    Name: 'Staff, café'
  },
  {
    Id: 31429,
    Code: '9263',
    Name: 'Staff, canteen'
  },
  {
    Id: 31430,
    Code: '9263',
    Name: 'Staff, catering'
  },
  {
    Id: 31431,
    Code: '9263',
    Name: 'Staff, counter, fast food outlet'
  },
  {
    Id: 31432,
    Code: '9263',
    Name: 'Staff, front of house, catering'
  },
  {
    Id: 31433,
    Code: '9263',
    Name: 'Staff, kitchen'
  },
  {
    Id: 31434,
    Code: '9263',
    Name: 'Steward, offshore'
  },
  {
    Id: 31435,
    Code: '9263',
    Name: 'Steward, pantry'
  },
  {
    Id: 31436,
    Code: '9263',
    Name: 'Technician, catering'
  },
  {
    Id: 31437,
    Code: '9263',
    Name: 'Technician, sandwich'
  },
  {
    Id: 31438,
    Code: '9263',
    Name: 'Trainer, crew, fast food outlet'
  },
  {
    Id: 31439,
    Code: '9263',
    Name: 'Trolleyman, refreshments'
  },
  {
    Id: 31440,
    Code: '9263',
    Name: 'Washer, dish'
  },
  {
    Id: 31441,
    Code: '9263',
    Name: 'Washer, glass, hotels, catering, public houses'
  },
  {
    Id: 31442,
    Code: '9263',
    Name: 'Washer, plate'
  },
  {
    Id: 31443,
    Code: '9263',
    Name: 'Washer, pot, hotels, catering, public houses'
  },
  {
    Id: 31444,
    Code: '9263',
    Name: 'Washer-up, hotels, catering, public houses'
  },
  {
    Id: 31445,
    Code: '9263',
    Name: 'Worker, café'
  },
  {
    Id: 31446,
    Code: '9263',
    Name: 'Worker, canteen'
  },
  {
    Id: 31447,
    Code: '9263',
    Name: 'Worker, catering'
  },
  {
    Id: 31448,
    Code: '9263',
    Name: 'Worker, kitchen'
  },
  {
    Id: 31449,
    Code: '9263',
    Name: 'Worker, restaurant'
  },
  {
    Id: 31450,
    Code: '9263',
    Name: 'Worker, room, still'
  },
  {
    Id: 31451,
    Code: '9263',
    Name: 'Worker, service, school meals'
  },
  {
    Id: 31452,
    Code: '9264',
    Name: 'Assistant, conference and banqueting'
  },
  {
    Id: 31453,
    Code: '9264',
    Name: 'Assistant, food and beverage'
  },
  {
    Id: 31454,
    Code: '9264',
    Name: 'Assistant, front of house, public house'
  },
  {
    Id: 31455,
    Code: '9264',
    Name: 'Assistant, front of house, restaurant'
  },
  {
    Id: 31456,
    Code: '9264',
    Name: 'Assistant, hospitality'
  },
  {
    Id: 31457,
    Code: '9264',
    Name: 'Assistant, service, room, hotel'
  },
  {
    Id: 31458,
    Code: '9264',
    Name: 'Associate, food and beverage'
  },
  {
    Id: 31459,
    Code: '9264',
    Name: 'Attendant, car, dining'
  },
  {
    Id: 31460,
    Code: '9264',
    Name: 'Attendant, car, restaurant'
  },
  {
    Id: 31461,
    Code: '9264',
    Name: 'Attendant, room, ward'
  },
  {
    Id: 31462,
    Code: '9264',
    Name: 'Attendant, service, room'
  },
  {
    Id: 31463,
    Code: '9264',
    Name: 'Boy, saloon'
  },
  {
    Id: 31464,
    Code: '9264',
    Name: 'Busser'
  },
  {
    Id: 31465,
    Code: '9264',
    Name: 'Butler, wine'
  },
  {
    Id: 31466,
    Code: '9264',
    Name: 'Hostess, party, fast food outlet'
  },
  {
    Id: 31467,
    Code: '9264',
    Name: 'Hostess, fast food outlet'
  },
  {
    Id: 31468,
    Code: '9264',
    Name: 'Hostess, public house, restaurant'
  },
  {
    Id: 31469,
    Code: '9264',
    Name: 'Hostess, railways'
  },
  {
    Id: 31470,
    Code: '9264',
    Name: 'Maid, tea'
  },
  {
    Id: 31471,
    Code: '9264',
    Name: 'Member, team, front of house, public house'
  },
  {
    Id: 31472,
    Code: '9264',
    Name: 'Member, team, front of house, restaurant'
  },
  {
    Id: 31473,
    Code: '9264',
    Name: 'Organiser, party, fast food outlet'
  },
  {
    Id: 31474,
    Code: '9264',
    Name: 'Runner, food'
  },
  {
    Id: 31475,
    Code: '9264',
    Name: 'Runner, shift, fast food outlet'
  },
  {
    Id: 31476,
    Code: '9264',
    Name: 'Runner, catering'
  },
  {
    Id: 31477,
    Code: '9264',
    Name: 'Runner, public house'
  },
  {
    Id: 31478,
    Code: '9264',
    Name: 'Runner, restaurant'
  },
  {
    Id: 31479,
    Code: '9264',
    Name: 'Seater, catering'
  },
  {
    Id: 31480,
    Code: '9264',
    Name: 'Sommelier'
  },
  {
    Id: 31481,
    Code: '9264',
    Name: 'Staff, front of house, public house'
  },
  {
    Id: 31482,
    Code: '9264',
    Name: 'Staff, front of house, restaurant'
  },
  {
    Id: 31483,
    Code: '9264',
    Name: 'Staff, waiting'
  },
  {
    Id: 31484,
    Code: '9264',
    Name: 'Steward, canteen'
  },
  {
    Id: 31485,
    Code: '9264',
    Name: 'Steward, car, dining'
  },
  {
    Id: 31486,
    Code: '9264',
    Name: 'Steward, catering'
  },
  {
    Id: 31487,
    Code: '9264',
    Name: 'Steward, mess'
  },
  {
    Id: 31488,
    Code: '9264',
    Name: 'Steward, messroom'
  },
  {
    Id: 31489,
    Code: '9264',
    Name: 'Steward, ship, catering'
  },
  {
    Id: 31490,
    Code: '9264',
    Name: 'Steward, wine'
  },
  {
    Id: 31491,
    Code: '9264',
    Name: 'Steward, catering'
  },
  {
    Id: 31492,
    Code: '9264',
    Name: 'Steward, canteen'
  },
  {
    Id: 31493,
    Code: '9264',
    Name: 'Steward, MOD'
  },
  {
    Id: 31494,
    Code: '9264',
    Name: 'Steward, railways'
  },
  {
    Id: 31495,
    Code: '9264',
    Name: 'Steward, shipping: catering'
  },
  {
    Id: 31496,
    Code: '9264',
    Name: 'Steward, university: catering'
  },
  {
    Id: 31497,
    Code: '9264',
    Name: 'Waiter, restaurant'
  },
  {
    Id: 31498,
    Code: '9264',
    Name: 'Waiter, service, silver'
  },
  {
    Id: 31499,
    Code: '9264',
    Name: 'Waiter, ward'
  },
  {
    Id: 31500,
    Code: '9264',
    Name: 'Waiter, wine'
  },
  {
    Id: 31501,
    Code: '9264',
    Name: 'Waiter'
  },
  {
    Id: 31502,
    Code: '9264',
    Name: 'Waitress, service, silver'
  },
  {
    Id: 31503,
    Code: '9264',
    Name: 'Waitress, ward'
  },
  {
    Id: 31504,
    Code: '9264',
    Name: 'Waitress'
  },
  {
    Id: 31505,
    Code: '9264',
    Name: 'Waitress-barista'
  },
  {
    Id: 31506,
    Code: '9264',
    Name: 'Worker, factory, fun'
  },
  {
    Id: 31507,
    Code: '9265',
    Name: 'Assistant, bar'
  },
  {
    Id: 31508,
    Code: '9265',
    Name: 'Assistant, front of house, beverages'
  },
  {
    Id: 31509,
    Code: '9265',
    Name: "Assistant, licensee's"
  },
  {
    Id: 31510,
    Code: '9265',
    Name: 'Associate, bar, public house'
  },
  {
    Id: 31511,
    Code: '9265',
    Name: 'Attendant, bar'
  },
  {
    Id: 31512,
    Code: '9265',
    Name: 'Attendant, saloon'
  },
  {
    Id: 31513,
    Code: '9265',
    Name: 'Bar-cellarman'
  },
  {
    Id: 31514,
    Code: '9265',
    Name: 'Barmaid'
  },
  {
    Id: 31515,
    Code: '9265',
    Name: 'Barmaid-waitress'
  },
  {
    Id: 31516,
    Code: '9265',
    Name: 'Barman'
  },
  {
    Id: 31517,
    Code: '9265',
    Name: 'Barperson'
  },
  {
    Id: 31518,
    Code: '9265',
    Name: 'Barstaff'
  },
  {
    Id: 31519,
    Code: '9265',
    Name: 'Bartender'
  },
  {
    Id: 31520,
    Code: '9265',
    Name: 'Barworker'
  },
  {
    Id: 31521,
    Code: '9265',
    Name: 'Cellarman, bar'
  },
  {
    Id: 31522,
    Code: '9265',
    Name: 'Cellarman, catering'
  },
  {
    Id: 31523,
    Code: '9265',
    Name: 'Cellarman, hotel'
  },
  {
    Id: 31524,
    Code: '9265',
    Name: 'Cellarman, public house'
  },
  {
    Id: 31525,
    Code: '9265',
    Name: 'Collector, glass, catering'
  },
  {
    Id: 31526,
    Code: '9265',
    Name: 'Collector, glass, public house'
  },
  {
    Id: 31527,
    Code: '9265',
    Name: 'Dispenser, drink'
  },
  {
    Id: 31528,
    Code: '9265',
    Name: 'Dispenser, licensed trade'
  },
  {
    Id: 31529,
    Code: '9265',
    Name: 'Handyman, bar'
  },
  {
    Id: 31530,
    Code: '9265',
    Name: 'Help, bar'
  },
  {
    Id: 31531,
    Code: '9265',
    Name: 'Helper, bar'
  },
  {
    Id: 31532,
    Code: '9265',
    Name: 'Maid, bar'
  },
  {
    Id: 31533,
    Code: '9265',
    Name: 'Member, team, front of house, beverages'
  },
  {
    Id: 31534,
    Code: '9265',
    Name: 'Mixologist, beverages'
  },
  {
    Id: 31535,
    Code: '9265',
    Name: 'Operative, bar'
  },
  {
    Id: 31536,
    Code: '9265',
    Name: 'Person, bar'
  },
  {
    Id: 31537,
    Code: '9265',
    Name: 'Staff, bar'
  },
  {
    Id: 31538,
    Code: '9265',
    Name: 'Staff, front of house, beverages'
  },
  {
    Id: 31539,
    Code: '9265',
    Name: 'Steward, bar'
  },
  {
    Id: 31540,
    Code: '9265',
    Name: 'Tender, bar'
  },
  {
    Id: 31541,
    Code: '9265',
    Name: 'Waiter, cocktail'
  },
  {
    Id: 31542,
    Code: '9265',
    Name: 'Waitress, cocktail'
  },
  {
    Id: 31543,
    Code: '9265',
    Name: 'Worker, bar'
  },
  {
    Id: 31544,
    Code: '9266',
    Name: 'Assistant, shop, coffee'
  },
  {
    Id: 31545,
    Code: '9266',
    Name: 'Attendant, stall, coffee'
  },
  {
    Id: 31546,
    Code: '9266',
    Name: 'Barista'
  },
  {
    Id: 31547,
    Code: '9266',
    Name: 'Barmaid, coffee'
  },
  {
    Id: 31548,
    Code: '9266',
    Name: 'Barman, coffee'
  },
  {
    Id: 31549,
    Code: '9266',
    Name: 'Maestro, barista'
  },
  {
    Id: 31550,
    Code: '9266',
    Name: 'Maid, coffee'
  },
  {
    Id: 31551,
    Code: '9266',
    Name: 'Maid, room, coffee'
  },
  {
    Id: 31552,
    Code: '9267',
    Name: 'Assistant, arcade'
  },
  {
    Id: 31553,
    Code: '9267',
    Name: 'Assistant, cinema'
  },
  {
    Id: 31554,
    Code: '9267',
    Name: 'Assistant, customer, cinema'
  },
  {
    Id: 31555,
    Code: '9267',
    Name: 'Assistant, fairground'
  },
  {
    Id: 31556,
    Code: '9267',
    Name: 'Assistant, front of house, leisure services'
  },
  {
    Id: 31557,
    Code: '9267',
    Name: 'Assistant, funfair'
  },
  {
    Id: 31558,
    Code: '9267',
    Name: 'Assistant, gallery, art'
  },
  {
    Id: 31559,
    Code: '9267',
    Name: 'Assistant, general, leisure services'
  },
  {
    Id: 31560,
    Code: '9267',
    Name: 'Attendant, amusement'
  },
  {
    Id: 31561,
    Code: '9267',
    Name: 'Attendant, arcade'
  },
  {
    Id: 31562,
    Code: '9267',
    Name: 'Attendant, camp'
  },
  {
    Id: 31563,
    Code: '9267',
    Name: 'Attendant, cinema'
  },
  {
    Id: 31564,
    Code: '9267',
    Name: 'Attendant, club'
  },
  {
    Id: 31565,
    Code: '9267',
    Name: 'Attendant, field, playing'
  },
  {
    Id: 31566,
    Code: '9267',
    Name: 'Attendant, funfair'
  },
  {
    Id: 31567,
    Code: '9267',
    Name: 'Attendant, ground, fair'
  },
  {
    Id: 31568,
    Code: '9267',
    Name: 'Attendant, hall, billiard'
  },
  {
    Id: 31569,
    Code: '9267',
    Name: 'Attendant, hall, dance'
  },
  {
    Id: 31570,
    Code: '9267',
    Name: 'Attendant, hotel'
  },
  {
    Id: 31571,
    Code: '9267',
    Name: 'Attendant, lane'
  },
  {
    Id: 31572,
    Code: '9267',
    Name: 'Attendant, museum'
  },
  {
    Id: 31573,
    Code: '9267',
    Name: 'Attendant, park, amusement'
  },
  {
    Id: 31574,
    Code: '9267',
    Name: 'Attendant, park, theme'
  },
  {
    Id: 31575,
    Code: '9267',
    Name: 'Attendant, pier, entertainment'
  },
  {
    Id: 31576,
    Code: '9267',
    Name: 'Attendant, rides'
  },
  {
    Id: 31577,
    Code: '9267',
    Name: 'Attendant, room, ball'
  },
  {
    Id: 31578,
    Code: '9267',
    Name: 'Attendant, stall, amusements'
  },
  {
    Id: 31579,
    Code: '9267',
    Name: 'Attendant, studio'
  },
  {
    Id: 31580,
    Code: '9267',
    Name: 'Attendant, theatre'
  },
  {
    Id: 31581,
    Code: '9267',
    Name: 'Attendant, entertainment'
  },
  {
    Id: 31582,
    Code: '9267',
    Name: 'Attendant, art gallery'
  },
  {
    Id: 31583,
    Code: '9267',
    Name: 'Attendant, cinema'
  },
  {
    Id: 31584,
    Code: '9267',
    Name: 'Attendant, museum'
  },
  {
    Id: 31585,
    Code: '9267',
    Name: 'Balloonist'
  },
  {
    Id: 31586,
    Code: '9267',
    Name: 'Boy, call, entertainment'
  },
  {
    Id: 31587,
    Code: '9267',
    Name: 'Coat, blue, holiday camp'
  },
  {
    Id: 31588,
    Code: '9267',
    Name: 'Coat, red, holiday camp'
  },
  {
    Id: 31589,
    Code: '9267',
    Name: 'Crew, balloon'
  },
  {
    Id: 31590,
    Code: '9267',
    Name: 'Decorator, balloon'
  },
  {
    Id: 31591,
    Code: '9267',
    Name: 'Decorator, party'
  },
  {
    Id: 31592,
    Code: '9267',
    Name: 'Decorator, wedding'
  },
  {
    Id: 31593,
    Code: '9267',
    Name: 'Foreman, entertainment'
  },
  {
    Id: 31594,
    Code: '9267',
    Name: 'Foreman, cinema'
  },
  {
    Id: 31595,
    Code: '9267',
    Name: 'Gateman, entertainment'
  },
  {
    Id: 31596,
    Code: '9267',
    Name: 'Hand, circus'
  },
  {
    Id: 31597,
    Code: '9267',
    Name: 'Hand, fairground'
  },
  {
    Id: 31598,
    Code: '9267',
    Name: 'Holder, stall, entertainment'
  },
  {
    Id: 31599,
    Code: '9267',
    Name: 'Host, cinema'
  },
  {
    Id: 31600,
    Code: '9267',
    Name: 'Marshall, lane'
  },
  {
    Id: 31601,
    Code: '9267',
    Name: 'Member, team, cinema'
  },
  {
    Id: 31602,
    Code: '9267',
    Name: 'Multifunctional, cinema'
  },
  {
    Id: 31603,
    Code: '9267',
    Name: 'Operative, cinema'
  },
  {
    Id: 31604,
    Code: '9267',
    Name: 'Operator, ride'
  },
  {
    Id: 31605,
    Code: '9267',
    Name: 'Operator, tow, ski'
  },
  {
    Id: 31606,
    Code: '9267',
    Name: 'Painter, face'
  },
  {
    Id: 31607,
    Code: '9267',
    Name: 'Porter, entertainment'
  },
  {
    Id: 31608,
    Code: '9267',
    Name: 'Roadie'
  },
  {
    Id: 31609,
    Code: '9267',
    Name: 'Starter, golf'
  },
  {
    Id: 31610,
    Code: '9267',
    Name: 'Steward, concert'
  },
  {
    Id: 31611,
    Code: '9267',
    Name: 'Steward, event'
  },
  {
    Id: 31612,
    Code: '9267',
    Name: 'Steward, ground, sports'
  },
  {
    Id: 31613,
    Code: '9267',
    Name: 'Steward, room, billiard'
  },
  {
    Id: 31614,
    Code: '9267',
    Name: 'Steward, safety'
  },
  {
    Id: 31615,
    Code: '9267',
    Name: 'Steward, events'
  },
  {
    Id: 31616,
    Code: '9267',
    Name: 'Steward, sports ground'
  },
  {
    Id: 31617,
    Code: '9267',
    Name: 'Superintendent, range'
  },
  {
    Id: 31618,
    Code: '9267',
    Name: 'Supervisor, arcade, amusement'
  },
  {
    Id: 31619,
    Code: '9267',
    Name: 'Usher'
  },
  {
    Id: 31620,
    Code: '9267',
    Name: 'Usherette'
  },
  {
    Id: 31621,
    Code: '9267',
    Name: 'Worker, arcade, amusement'
  },
  {
    Id: 31622,
    Code: '9267',
    Name: 'Worker, camp, holiday'
  },
  {
    Id: 31623,
    Code: '9267',
    Name: 'Worker, centre, holiday'
  },
  {
    Id: 31624,
    Code: '9267',
    Name: 'Worker, centre, leisure'
  },
  {
    Id: 31625,
    Code: '9267',
    Name: 'Worker, cinema'
  },
  {
    Id: 31626,
    Code: '9267',
    Name: 'Worker, fairground'
  },
  {
    Id: 31627,
    Code: '9267',
    Name: 'Worker, park, theme'
  },
  {
    Id: 31628,
    Code: '9267',
    Name: 'Worker, range, driving'
  },
  {
    Id: 31629,
    Code: '9267',
    Name: 'Worker, range, firing'
  },
  {
    Id: 31630,
    Code: '9267',
    Name: 'Worker, range, shooting'
  },
  {
    Id: 31631,
    Code: '9269',
    Name: 'Artist, striptease'
  },
  {
    Id: 31632,
    Code: '9269',
    Name: 'Assistant, bingo'
  },
  {
    Id: 31633,
    Code: '9269',
    Name: 'Assistant, general, hotel'
  },
  {
    Id: 31634,
    Code: '9269',
    Name: 'Assistant, hotel'
  },
  {
    Id: 31635,
    Code: '9269',
    Name: 'Assistant, sales, book, bingo hall'
  },
  {
    Id: 31636,
    Code: '9269',
    Name: 'Assistant, spa'
  },
  {
    Id: 31637,
    Code: '9269',
    Name: 'Astrologer'
  },
  {
    Id: 31638,
    Code: '9269',
    Name: 'Attendant, bath'
  },
  {
    Id: 31639,
    Code: '9269',
    Name: 'Attendant, baths'
  },
  {
    Id: 31640,
    Code: '9269',
    Name: 'Attendant, bingo'
  },
  {
    Id: 31641,
    Code: '9269',
    Name: 'Attendant, car, airport'
  },
  {
    Id: 31642,
    Code: '9269',
    Name: 'Attendant, chair'
  },
  {
    Id: 31643,
    Code: '9269',
    Name: 'Attendant, cloakroom'
  },
  {
    Id: 31644,
    Code: '9269',
    Name: 'Attendant, coach'
  },
  {
    Id: 31645,
    Code: '9269',
    Name: 'Attendant, door'
  },
  {
    Id: 31646,
    Code: '9269',
    Name: 'Attendant, hall, bingo'
  },
  {
    Id: 31647,
    Code: '9269',
    Name: 'Attendant, hostel'
  },
  {
    Id: 31648,
    Code: '9269',
    Name: 'Attendant, house, bath'
  },
  {
    Id: 31649,
    Code: '9269',
    Name: 'Attendant, house, local government'
  },
  {
    Id: 31650,
    Code: '9269',
    Name: 'Attendant, hut, beach'
  },
  {
    Id: 31651,
    Code: '9269',
    Name: 'Attendant, lamp, railways'
  },
  {
    Id: 31652,
    Code: '9269',
    Name: 'Attendant, lamp'
  },
  {
    Id: 31653,
    Code: '9269',
    Name: 'Attendant, lift'
  },
  {
    Id: 31654,
    Code: '9269',
    Name: 'Attendant, lobby'
  },
  {
    Id: 31655,
    Code: '9269',
    Name: 'Attendant, luggage, left'
  },
  {
    Id: 31656,
    Code: '9269',
    Name: "Attendant, mayor's"
  },
  {
    Id: 31657,
    Code: '9269',
    Name: 'Attendant, property, lost'
  },
  {
    Id: 31658,
    Code: '9269',
    Name: 'Attendant, room, bath'
  },
  {
    Id: 31659,
    Code: '9269',
    Name: 'Attendant, room, changing'
  },
  {
    Id: 31660,
    Code: '9269',
    Name: 'Attendant, room, cloak'
  },
  {
    Id: 31661,
    Code: '9269',
    Name: 'Attendant, room, dressing'
  },
  {
    Id: 31662,
    Code: '9269',
    Name: 'Attendant, room, locker'
  },
  {
    Id: 31663,
    Code: '9269',
    Name: 'Attendant, room, luggage'
  },
  {
    Id: 31664,
    Code: '9269',
    Name: 'Attendant, room, rest'
  },
  {
    Id: 31665,
    Code: '9269',
    Name: 'Attendant, room, waiting'
  },
  {
    Id: 31666,
    Code: '9269',
    Name: 'Attendant, seat'
  },
  {
    Id: 31667,
    Code: '9269',
    Name: 'Attendant, baths'
  },
  {
    Id: 31668,
    Code: '9269',
    Name: 'Bathman, baths'
  },
  {
    Id: 31669,
    Code: '9269',
    Name: 'Bearer, mace'
  },
  {
    Id: 31670,
    Code: '9269',
    Name: 'Boy, clapper'
  },
  {
    Id: 31671,
    Code: '9269',
    Name: 'Boy, door, hotels, catering, public houses'
  },
  {
    Id: 31672,
    Code: '9269',
    Name: 'Caller, bingo'
  },
  {
    Id: 31673,
    Code: '9269',
    Name: 'Checker, ticket, entertainment'
  },
  {
    Id: 31674,
    Code: '9269',
    Name: 'Checker, bingo hall'
  },
  {
    Id: 31675,
    Code: '9269',
    Name: 'Clairvoyant'
  },
  {
    Id: 31676,
    Code: '9269',
    Name: 'Clapper-loader'
  },
  {
    Id: 31677,
    Code: '9269',
    Name: 'Clerk, cloakroom'
  },
  {
    Id: 31678,
    Code: '9269',
    Name: 'Clerk, luggage, left'
  },
  {
    Id: 31679,
    Code: '9269',
    Name: 'Collector, ticket'
  },
  {
    Id: 31680,
    Code: '9269',
    Name: 'Commissionaire'
  },
  {
    Id: 31681,
    Code: '9269',
    Name: 'Consultant, shui, feng'
  },
  {
    Id: 31682,
    Code: '9269',
    Name: 'Dancer, lap'
  },
  {
    Id: 31683,
    Code: '9269',
    Name: 'Dancer, pole'
  },
  {
    Id: 31684,
    Code: '9269',
    Name: 'Dancer, table'
  },
  {
    Id: 31685,
    Code: '9269',
    Name: 'Dayman, theatre'
  },
  {
    Id: 31686,
    Code: '9269',
    Name: 'Diviner, water'
  },
  {
    Id: 31687,
    Code: '9269',
    Name: 'Escort, entertainment'
  },
  {
    Id: 31688,
    Code: '9269',
    Name: 'Examiner, ticket, entertainment'
  },
  {
    Id: 31689,
    Code: '9269',
    Name: 'Foreman, baths'
  },
  {
    Id: 31690,
    Code: '9269',
    Name: 'Foreman, baths'
  },
  {
    Id: 31691,
    Code: '9269',
    Name: 'Greeter, security services'
  },
  {
    Id: 31692,
    Code: '9269',
    Name: 'Grip, key'
  },
  {
    Id: 31693,
    Code: '9269',
    Name: 'Grip'
  },
  {
    Id: 31694,
    Code: '9269',
    Name: 'Hand, scene'
  },
  {
    Id: 31695,
    Code: '9269',
    Name: 'Hand, stage, entertainment'
  },
  {
    Id: 31696,
    Code: '9269',
    Name: 'Helper, club, youth'
  },
  {
    Id: 31697,
    Code: '9269',
    Name: 'Houseman, museum'
  },
  {
    Id: 31698,
    Code: '9269',
    Name: 'Inspector, chair, deck'
  },
  {
    Id: 31699,
    Code: '9269',
    Name: 'Inspector, ticket, chair'
  },
  {
    Id: 31700,
    Code: '9269',
    Name: 'Jockey, car'
  },
  {
    Id: 31701,
    Code: '9269',
    Name: 'Keeper, floor, Bank of England'
  },
  {
    Id: 31702,
    Code: '9269',
    Name: 'Kissogram'
  },
  {
    Id: 31703,
    Code: '9269',
    Name: 'Liftman, service'
  },
  {
    Id: 31704,
    Code: '9269',
    Name: 'Liftman'
  },
  {
    Id: 31705,
    Code: '9269',
    Name: 'Loader, clapper'
  },
  {
    Id: 31706,
    Code: '9269',
    Name: 'Macer'
  },
  {
    Id: 31707,
    Code: '9269',
    Name: 'Man, lobby'
  },
  {
    Id: 31708,
    Code: '9269',
    Name: 'Man, property'
  },
  {
    Id: 31709,
    Code: '9269',
    Name: 'Marker, billiard'
  },
  {
    Id: 31710,
    Code: '9269',
    Name: 'Marker, board, bookmakers, turf accountants'
  },
  {
    Id: 31711,
    Code: '9269',
    Name: 'Medium'
  },
  {
    Id: 31712,
    Code: '9269',
    Name: 'Member, team, betting and gambling'
  },
  {
    Id: 31713,
    Code: '9269',
    Name: 'Operative, bingo'
  },
  {
    Id: 31714,
    Code: '9269',
    Name: 'Operator, bingo'
  },
  {
    Id: 31715,
    Code: '9269',
    Name: 'Operator, cam, web'
  },
  {
    Id: 31716,
    Code: '9269',
    Name: 'Operator, chat-line'
  },
  {
    Id: 31717,
    Code: '9269',
    Name: 'Operator, lift'
  },
  {
    Id: 31718,
    Code: '9269',
    Name: 'Operator, scoreboard'
  },
  {
    Id: 31719,
    Code: '9269',
    Name: 'Operator, turnstile'
  },
  {
    Id: 31720,
    Code: '9269',
    Name: 'Page, hotel'
  },
  {
    Id: 31721,
    Code: '9269',
    Name: 'Palmist'
  },
  {
    Id: 31722,
    Code: '9269',
    Name: 'Performer, web-cam'
  },
  {
    Id: 31723,
    Code: '9269',
    Name: 'Phrenologist'
  },
  {
    Id: 31724,
    Code: '9269',
    Name: 'Porter, hall'
  },
  {
    Id: 31725,
    Code: '9269',
    Name: 'Porter, head, residential buildings'
  },
  {
    Id: 31726,
    Code: '9269',
    Name: 'Porter, head, hotel'
  },
  {
    Id: 31727,
    Code: '9269',
    Name: 'Porter, hotel'
  },
  {
    Id: 31728,
    Code: '9269',
    Name: 'Porter, house'
  },
  {
    Id: 31729,
    Code: '9269',
    Name: 'Porter, night'
  },
  {
    Id: 31730,
    Code: '9269',
    Name: 'Porter, resident'
  },
  {
    Id: 31731,
    Code: '9269',
    Name: 'Porter, residential buildings'
  },
  {
    Id: 31732,
    Code: '9269',
    Name: 'Porter, club'
  },
  {
    Id: 31733,
    Code: '9269',
    Name: 'Porter, hostel'
  },
  {
    Id: 31734,
    Code: '9269',
    Name: 'Porter, hotel'
  },
  {
    Id: 31735,
    Code: '9269',
    Name: 'Porter and liftman'
  },
  {
    Id: 31736,
    Code: '9269',
    Name: 'Practitioner, shui, feng'
  },
  {
    Id: 31737,
    Code: '9269',
    Name: 'Propman'
  },
  {
    Id: 31738,
    Code: '9269',
    Name: 'Propsman'
  },
  {
    Id: 31739,
    Code: '9269',
    Name: 'Psychologist, astrological'
  },
  {
    Id: 31740,
    Code: '9269',
    Name: 'Reader, tarot'
  },
  {
    Id: 31741,
    Code: '9269',
    Name: 'Receptionist, cinema, theatre'
  },
  {
    Id: 31742,
    Code: '9269',
    Name: 'Searcher, manufacturing'
  },
  {
    Id: 31743,
    Code: '9269',
    Name: 'Seller, bingo hall'
  },
  {
    Id: 31744,
    Code: '9269',
    Name: 'Shifter, scene'
  },
  {
    Id: 31745,
    Code: '9269',
    Name: 'Sitter'
  },
  {
    Id: 31746,
    Code: '9269',
    Name: 'Superintendent, bath'
  },
  {
    Id: 31747,
    Code: '9269',
    Name: 'Superintendent, baths'
  },
  {
    Id: 31748,
    Code: '9269',
    Name: 'Superintendent, baths'
  },
  {
    Id: 31749,
    Code: '9269',
    Name: 'Supervisor, field, playing'
  },
  {
    Id: 31750,
    Code: '9269',
    Name: 'Teller, fortune'
  },
  {
    Id: 31751,
    Code: '9269',
    Name: 'Washhouseman, baths'
  },
  {
    Id: 31752,
    Code: '9269',
    Name: 'Winder, clock'
  },
  {
    Id: 31753,
    Code: '9269',
    Name: 'Worker, hostel'
  },
  {
    Id: 31754,
    Code: '9269',
    Name: 'Worker, hotel'
  },
  {
    Id: 31755,
    Code: '9269',
    Name: 'Worker, lift'
  },
  {
    Id: 31756,
    Code: '9270',
    Name: 'Unemployed'
  },
  {
    Id: 31757,
    Code: '9271',
    Name: 'N/A'
  }
];
