import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import setMargin from '../../helpers/set-margin';

const weights = {
  bold: 'bold !important',
  regular: 'normal !important'
};

const fontSizes = {
  extraLarge: '80px !important',
  xxLarge: '48px !important',
  larger: '40px !important',
  xLarge: '30px !important',
  large: '28px !important',
  medLarge: '24px !important',
  med: '20px !important',
  regular: '16px !important',
  small: '14px !important'
};

const lineHeights = {
  extraLarge: '120% !important',
  larger: '120% !important',
  large: '120% !important',
  medLarge: '120% !important',
  med: '120% !important',
  regular: '150% !important',
  small: '150% !important'
};

const commonStyle = ({ theme, color, caps, ta, fontFamily }) => `
font-style: normal !important;
letter-spacing: 0.2px !important;
font-family: ${fontFamily === 'garamond' ? 'EB Garamond' : ''};
color: ${theme.colors[color] || color || theme.colors.black} !important;
text-transform: ${caps ? 'uppercase' : 'initial'} !important;
text-align: ${ta || 'left'} !important;
`;

const Head1 = styled('h1')`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '28px !important')};
  line-height: ${({ size }) =>
    size ? lineHeights[size] : '33.6px !important'};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '700 !important')};
`;
export const H1 = (props) => <Head1 {...props} level={1} />;

const Head2 = styled('h2')`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '20px !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '30px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
`;
export const H2 = (props) => <Head2 {...props} level={2} />;

const Head3 = styled('h3')`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '16px !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
  text-decoration: ${({ td }) => td || 'none'};
`;
export const H3 = (props) => <Head3 {...props} level={3} />;

export const P = styled((props) => <p {...props} />)`
  ${setMargin};
  ${commonStyle};
  color: ${({ color, theme }) => (color ? theme.colors[color] : '')};
  font-size: ${({ size }) => (size ? fontSizes[size] : '16px !important')};
  font-style: ${({ fontStyle }) =>
    fontStyle ? fontStyle : 'normal !important'};
  line-height: ${({ size, lh }) =>
    lh || (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
  display: ${({ display }) => display || 'block'};
  /* width: 100%; */
  word-wrap: break-word;
  padding: ${({ padding }) => padding || 0};
  pre {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

export const Pre = styled((props) => <pre {...props} />)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '16px !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
  display: ${({ display }) => display || 'block'};
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`;
export const Ul = styled((props) => <ul {...props} />)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '16px !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
`;

export const Li = styled((props) => <li {...props} />)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '16px !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
  list-style: ${({ listStyle }) => (listStyle ? listStyle : 'none')};
`;

const AntdLink = ({ to, external = false, underline, ...props }) => {
  return external ? (
    <a target="_blank" href={to} underline={underline} {...props} />
  ) : (
    <RouterLink to={to} {...props}>
      {props.children}
    </RouterLink>
  );
};
export const Link = styled(AntdLink)`
  ${setMargin};
  ${commonStyle};
  font-size: 16px !important;
  line-height: 24px !important;
  word-break: break-word;
  font-weight: ${({ weight }) =>
    weight ? weights[weight] : 'bold !important'};
  text-decoration: ${({ underline }) =>
    underline ? 'underline' : 'none'} !important;

  :hover {
    color: ${({ theme, hoverColor }) =>
      hoverColor
        ? theme.colors[hoverColor] + '!important'
        : theme.colors.primaryMain + '!important'};
  }

  :focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 0.25em;
  }
`;
