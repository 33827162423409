import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryMain};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  padding: ${({ theme }) => theme.spacings[7]};

  ${({ theme }) => theme.media.mobile} {
    padding-bottom: ${({ theme }) => ` ${theme.spacings[6]} `};
  }
`;

export const Header = styled.div`
  width: 100%;
`;

export const SchoolAddressWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  padding-left: ${({ theme }) => theme.spacings[3]};
`;

export const ModalSchoolDetailsWrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  display: none;
  width: 120px;
  height: 75px;
  margin-bottom: ${({ theme }) => theme.spacings[4]};

  ${({ theme }) => theme.media.iframe} {
    display: inline-block;
  }
`;

export const Logo = styled.img`
  height: 100%;
  width: 100%;
`;
