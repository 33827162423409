/* eslint @typescript-eslint/default-param-last: 0 */

import styled from '@emotion/styled';
import setMargin from './../../helpers/set-margin';

export const decideBackgroundBasic = (
  variant,
  theme,
  bgColor,
  opacity = ''
) => {
  if (bgColor) {
    return `${theme.colors[bgColor]}${opacity}` || `${bgColor}${opacity}`;
  }
  switch (variant) {
    case 'primary':
      return `${theme.colors.primarySecondary}${opacity}`;
    case 'secondary':
      return `${theme.colors.blue}${opacity}`;
    case 'tertiary':
      return `${theme.colors.neutralLight}${opacity}`;
    default:
      return 'transparent';
  }
};

export const decideBackgroundHoverBasic = (
  variant,
  theme,
  opacity = '',
  disabled
) => {
  if (disabled) {
    return null;
  }
  switch (variant) {
    case 'primary':
      return `${theme.colors.primarySecondary}${opacity}`;
    case 'secondary':
      return `${theme.colors.neutralMain80}${opacity}`;
    case 'tertiary':
      return `${theme.colors.neutralLight}${opacity}`;

    default:
      return 'transparent';
  }
};

export const BasicButton = styled.button`
  ${setMargin};
  height: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: ${({ ta }) => ta || 'left'} !important;
  width: ${({ width }) => width || '100%'};
  color: ${({ theme, customColor }) =>
    customColor
      ? theme.colors[customColor] || customColor
      : theme.colors.white};
  position: relative;
  background: ${({ variant, theme, bgColor, opacity, overlayColor }) =>
    decideBackgroundBasic(variant, theme, bgColor, opacity, overlayColor)};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius || '16px'};
  font-size: 1rem;
  letter-spacing: 0.04rem;
  padding: ${({ theme, padding }) => padding || `20px ${theme.spacings[5]}`};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  transition:
    background-color 300ms linear,
    color 300ms linear;

  /* clicking style */
  :active {
    opacity: 0.9;
    // transform: translateY(1px) scale(0.99);
  }

  :hover {
    color: ${({ variant, theme }) =>
      variant === 'tertiary' ? theme.colors.primaryMain : ''};
    background: ${({ variant, theme, opacity, disabled }) =>
      decideBackgroundHoverBasic(variant, theme, opacity, disabled)};
  }

  :focus {
    box-shadow: ${({ noOutline, theme }) =>
      noOutline
        ? theme.shadows.elevation1
        : 'inset 0px 0px 0px 2px rgba(5, 23, 48, 1)'};
    outline-style: ${({ noOutline }) => (noOutline ? 'none' : 'solid')};
    outline-width: ${({ noOutline }) => (noOutline ? 'none' : '3px')};
    outline-color: ${({
      theme,
      focusOutlineColor = 'primaryMain',
      noOutline
    }) => (noOutline ? 'none' : theme.colors[focusOutlineColor])};
    outline-offset: 3px;
  }

  /* for disabled style */
  opacity: ${({ disabled, loading }) => (disabled || loading ? 0.5 : 1)};

  cursor: ${({ disabled, loading }) =>
    disabled || loading ? 'not-allowed' : 'pointer'};
`;

export const InnerTextAlign = styled.span`
  padding-top: 1px;
  padding-left: 7px;
`;
