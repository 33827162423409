import moment from 'moment';
import { appFormFields } from '../../constants';

/*
calculates the age based on the dob
takes reference date (default is set to today)
*/
function getAge(dateString: string, referenceDate: Date = new Date()): number {
  const birthDate = moment.utc(dateString, 'YYYY-MM-DD');
  const refDate = moment.utc(referenceDate);
  return refDate.diff(birthDate, 'years');
}

export function getFirstOfMarchNextYear(): Date {
  const today: moment.Moment = moment.utc();
  const nextYear: number = today.month() >= 2 ? today.year() + 1 : today.year();
  const firstOfMarchNextYear: moment.Moment = moment.utc(
    `${nextYear}-03-01T00:00:00.000Z`
  );

  return firstOfMarchNextYear.toDate();
}
/*
criteria 1: age
if >= 16 or <= 20 by 01.March -> pass
if >= 15 or <= 20 by 01.March and current school is in Scotland -> pass

criteria 2: Admissions Policy and Country of selected schools
  - if a student selects two schools located in Northern Ireland -> eligible
  - if they select two schools with admission policy "State non-selective school" -> eligible
  - if they select one school admission policy as "State non-selective school" and the other choice is located in Northern Ireland -> eligible
  - if the school choice has no admissionPolicy this then counts as "State non-selective school"
  - any other combination -> ineligible
*/
interface SchoolDetail {
  type: string;
  admissionPolicies: string | undefined;
  isInNorthernIreland: boolean;
  isInScotland?: boolean;
  isHoldingSchool?: boolean;
  Target_City_Schools__c?: string;
}

interface EligibilityResult {
  eligible: boolean;
  reason: string;
}

const isAgeEligible = (dob, ageRange) => {
  const age = getAge(dob, getFirstOfMarchNextYear());
  return age >= Number(ageRange.min) && age <= Number(ageRange.max);
};

function calculateEligibility({
  dob,
  selectedSchools
}: {
  dob: string;
  selectedSchools: SchoolDetail[];
}): EligibilityResult {
  const currentSchoolInScotland = selectedSchools[0]?.isInScotland;

  const northernIrelandCount = selectedSchools.reduce(
    (count, school) => count + (school.isInNorthernIreland ? 1 : 0),
    0
  );

  const nonSelectiveCount = selectedSchools.reduce(
    (count, school) =>
      count +
      (school.admissionPolicies ===
        appFormFields.admissionsPolicies.STATE_NON_SELECTIVE_SCHOOL ||
      school?.isHoldingSchool // Treat custom schools as "State non-selective school"
        ? 1
        : 0),
    0
  );

  const minAge = process.env.REACT_APP_ELIGIBILITY_MIN_AGE || 16;
  const minAgeScotland =
    process.env.REACT_APP_ELIGIBILITY_MIN_AGE_SCOTLAND || 15;
  const maxAge = process.env.REACT_APP_ELIGIBILITY_MAX_AGE || 20;

  const ageRange = currentSchoolInScotland
    ? { min: minAgeScotland, max: maxAge }
    : { min: minAge, max: maxAge };

  const ageEligible = isAgeEligible(dob, ageRange);

  const eligible =
    ageEligible &&
    (northernIrelandCount === 2 ||
      nonSelectiveCount === 2 ||
      (nonSelectiveCount === 1 && northernIrelandCount === 1));

  let reason = '';

  if (!ageEligible) {
    reason = 'ineligible_age';
  } else if (northernIrelandCount === 2) {
    reason = 'eligible_northernIreland';
  } else if (nonSelectiveCount === 2) {
    reason = 'eligible_nonSelective';
  } else if (nonSelectiveCount === 1 && northernIrelandCount === 1) {
    reason = 'eligible_nonSelectiveAndNorthernIreland';
  } else {
    reason = 'ineligible_other';
  }

  return { eligible, reason };
}

export function createFaValues({
  dob,
  schoolDetails,
  gradSchoolDetails,
  parent1JobDetails,
  parent2JobDetails
}) {
  return {
    dob: moment(dob).format('DD/MM/YYYY'),
    orgId1: schoolDetails?.Id,
    orgName1: schoolDetails?.Name,
    orgCountry1: schoolDetails?.BillingAddress?.country,
    orgId2: gradSchoolDetails?.Id,
    orgName2: gradSchoolDetails?.Name,
    orgCountry2: gradSchoolDetails?.BillingAddress?.country,
    parent1JobTitle: parent1JobDetails?.Name,
    parent1JobCode: parent1JobDetails?.Code,
    parent2JobTitle: parent2JobDetails?.Name,
    parent2JobCode: parent2JobDetails?.Code
  };
}

export function isCurrentSchoolInTempLocation({
  schoolDetails,
  cities
}: {
  schoolDetails: SchoolDetail;
  cities: string[];
}): boolean {
  if (!schoolDetails?.Target_City_Schools__c || !cities) return false;

  const targetCity = schoolDetails.Target_City_Schools__c.toLowerCase();
  const lowerCaseCities = cities.map((city) => city.toLowerCase());

  return lowerCaseCities.includes(targetCity);
}

export const isHoldingSchool = (Id) =>
  Id === process.env.REACT_APP_SF_HOLDING_ID;

export function mapEligibilityValues({
  dob,
  schoolDetails,
  gradSchoolDetails
}) {
  return {
    dob,
    selectedSchools: [
      {
        name: schoolDetails?.Name,
        type: 'currentSchool',
        admissionPolicies: schoolDetails?.Admissions_Policy_New__c,
        isInNorthernIreland:
          schoolDetails?.BillingAddress?.country === 'Northern Ireland',
        isInScotland: schoolDetails?.BillingAddress?.country === 'Scotland',
        isHoldingSchool: isHoldingSchool(schoolDetails?.Id)
      },
      {
        name: gradSchoolDetails?.Name,
        type: 'gradSchool',
        admissionPolicies: gradSchoolDetails?.Admissions_Policy_New__c,
        isInNorthernIreland:
          gradSchoolDetails?.BillingAddress?.country === 'Northern Ireland',
        isHoldingSchool: isHoldingSchool(gradSchoolDetails?.Id)
      }
    ]
  };
}

export function mapFaValuesToParams(faValues) {
  return {
    [appFormFields?.firstForm?.dob]: faValues?.dob,
    [appFormFields?.firstForm?.orgId1]: faValues?.orgId1,
    [appFormFields?.firstForm?.orgName1]: faValues?.orgName1,
    [appFormFields?.firstForm?.orgCountry1]: faValues?.orgCountry1 || 'N/A',
    [appFormFields?.firstForm?.orgId2]: faValues?.orgId2,
    [appFormFields?.firstForm?.orgName2]: faValues?.orgName2,
    [appFormFields?.firstForm?.orgCountry2]: faValues?.orgCountry2 || 'N/A',
    [appFormFields?.firstForm?.parent1JobTitle]:
      faValues?.parent1JobTitle || 'N/A',
    [appFormFields?.firstForm?.parent1JobCode]:
      faValues?.parent1JobCode || 'N/A',
    [appFormFields?.firstForm?.parent2JobTitle]:
      faValues?.parent2JobTitle || 'N/A',
    [appFormFields?.firstForm?.parent2JobCode]:
      faValues?.parent2JobCode || 'N/A'
  };
}

export { getAge, calculateEligibility };
