import styled from '@emotion/styled';

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;

  .date-picker input {
    width: 300px;
    height: 50px;
    border: ${({ theme, error }) =>
      `2px solid ${error ? theme.colors.error : theme.colors.white}`};
    border-radius: 8px;
    background: none;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1rem;
    padding-left: 1rem;

    ::placeholder {
      color: ${({ theme }) => theme.colors.white};
      opacity: 1;
    }

    ${({ theme }) => theme.media.mobile} {
      width: 100%;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    background-color: ${({ theme }) => theme.colors.primaryMain};
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__month-select {
    margin-top: 0.5rem;
    border-radius: 4px;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.primaryMain};
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.white};
    :hover {
      background-color: ${({ theme }) => theme.colors.primarySecondary};
    }
    &.react-datepicker__day--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
