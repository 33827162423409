import * as CS from './style';
import * as T from '../Typography';

const InputWrapper = (props) => {
  const { label, error, helper, w, disabled, m, optional, children } = props;

  return (
    <CS.Field w={w} disabled={disabled} {...m}>
      {label && (
        <CS.Label htmlFor={label}>
          <T.P color="white" weight="bold" ml={2}>
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <CS.HelperWrapper>
          <T.P
            size="small"
            fontStyle="italic"
            color="white"
            weight="light"
            ml={2}
          >
            {helper}
          </T.P>
        </CS.HelperWrapper>
      )}
      {children}
      {error && (
        <T.P color="error" m="0" ml="3" mt="2">
          {error}
        </T.P>
      )}
    </CS.Field>
  );
};

export default InputWrapper;
