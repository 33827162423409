import { string, boolean, object, date } from 'yup';
import * as errMsgs from './err-msgs';

const URLregex =
  /^((https?):\/\/)?(www.)?[a-z0-9-_]+(\.[a-z-_]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_.-]+=[a-zA-Z0-9-%?&=.-_]+&?)?$/;

// const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
// const URLSlugRegex = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;

export const requiredText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const password = string()
  .matches(
    /^(?=[^A-Z\s]*[A-Z])(?=[^a-z\s]*[a-z])(?=[^\d\s]*\d)(?=\w*[\W_])\S{8,}$/gm,
    errMsgs.SHORT_PASSWORD
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const agreedOnTerms = boolean()
  .oneOf([true], errMsgs.SHOULD_AGREE_ON_TERMS)
  .required(errMsgs.DEFAULT_REQUIRED);

export const link = string().matches(URLregex, {
  message: errMsgs.INVALID_LINK
});

export const schoolDetailsObj = object().shape({
  Name: string().required(errMsgs.DEFAULT_REQUIRED),
  Id: string().required(errMsgs.DEFAULT_REQUIRED)
});

export const jobDetailsObj = object().shape({
  Name: string().required(errMsgs.DEFAULT_REQUIRED),
  Id: string().required(errMsgs.DEFAULT_REQUIRED),
  Code: string().required(errMsgs.DEFAULT_REQUIRED)
});

export const jobDetailsObjOptional = object().shape({
  Name: string(),
  Id: string(),
  Code: string()
});

const minDate = new Date(1998, 1, 1);
const maxDate = new Date(2012, 1, 1);

export const dob = date()
  .required()
  .min(minDate, `Please select a later date`)
  .max(maxDate, `Please select an earlier date`);
