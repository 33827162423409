import { default as ReactDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import * as S from './style';
import InputWrapper from '../InputWrapper';

const DatePicker = (props) => {
  const { error, handleChange, minDate, maxDate, value } = props;

  return (
    <InputWrapper {...props}>
      <S.DatePickerWrapper error={error}>
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          selected={value}
          onChange={handleChange}
          wrapperClassName="date-picker"
          placeholderText="DD / MM / YYYY"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          minDate={minDate}
          maxDate={maxDate}
        />
      </S.DatePickerWrapper>
    </InputWrapper>
  );
};

export default DatePicker;
