import { useNavigate } from 'react-router-dom';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CircularProgress from '@mui/material/CircularProgress';

import * as S from './style';
import theme from '../../theme';

export const antIcon = (
  <HourglassBottomIcon color="white" style={{ fontSize: 24 }} spin />
);

const BasicButton = ({
  variant = 'primary',
  icon,
  loading,
  handleClick,
  disabled,
  to,
  customColor,
  bgColor,
  external,
  iconProps,
  children,
  iconMR,
  id,
  dataTestId,
  ...props
}) => {
  const history = useNavigate();

  const onClick = (e) => {
    if (external) return;
    if (to) history.push(to);
    if (handleClick instanceof Function) handleClick(e);
  };

  if (external) {
    props.href = to;
    props.target = '_blank';
  }

  return (
    <S.BasicButton
      type="button"
      id={id}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      as={external ? 'a' : 'button'}
      icon={icon}
      customColor={customColor}
      bgColor={bgColor}
      loading={loading}
      aria-disabled={disabled ? 'true' : 'false'}
      aria-label={icon && icon}
      data-testId={dataTestId}
      {...props}
    >
      <S.InnerTextAlign>{children}</S.InnerTextAlign>
      {loading && (
        <CircularProgress
          style={{ color: theme.colors.white, marginLeft: '0.5rem' }}
        />
      )}
    </S.BasicButton>
  );
};

export default BasicButton;
