import * as T from '../../components/Typography';
import * as S from './style';
import { AutoComplete } from '../../components/Inputs';
import { Col, Row } from '../../components/Grid';
import { jobRecords } from '../../constants/job-records';

const HelperParagraph = () => (
  <>
    <T.P size="small">
      <ul>
        <li>
          Is your guardian recently retired or searching for work? Enter their
          latest job.
        </li>
        <li>
          If they have more than one job? Enter the one they’ve done the
          longest.
        </li>
        <li>They haven’t been in paid work? Enter 'Unemployed'.</li>

        <li>You're care experienced? Enter 'N/A'.</li>
      </ul>
    </T.P>
    <T.P size="small" mt={4}>
      Don’t worry about finding the exact job title, as long as you find an
      option which roughly describes their occupation, you're good to go!
    </T.P>
  </>
);

const JobSection = ({
  validationErrors,
  setFormData,
  parent1JobDetails,
  parent2JobDetails
}) => {
  return (
    <>
      <S.Card>
        <T.H2 color="white">3. PARENT / GUARDIAN OCCUPATION DETAILS</T.H2>
        <Row>
          <div key={`parent-job-1`} style={{ width: '100%' }}>
            <Col w={[4, 6, 6]}>
              <AutoComplete
                label="What is the main occupation of your highest paid parent or guardian? *"
                helper={<HelperParagraph />}
                placeholder="Start typing..."
                id="parent1-job-autocomplete"
                dataTestId="parent1-job-autocomplete"
                options={jobRecords}
                handleChange={(value) => {
                  setFormData({ parent1JobDetails: { ...(value || null) } });
                }}
                listItemProps={(option) => ({
                  key: `option-${option.Id}`,
                  label: option.Name
                })}
                getOptionLabel={(option) => (option.Name ? option.Name : '')}
                valueProps={{ data: parent1JobDetails, key: 'Name' }}
                error={
                  validationErrors.parent1JobDetails
                    ? 'Please select a job title'
                    : null
                }
              />
            </Col>
          </div>
        </Row>

        <Row mt={4}>
          <div key={`parent-job-2`} style={{ width: '100%' }}>
            <Col w={[4, 6, 6]}>
              <AutoComplete
                label="If applicable, what is the occupation of your second parent or guardian? (optional)"
                placeholder="Start typing..."
                id="parent2-job-autocomplete"
                dataTestId="parent2-job-autocomplete"
                options={jobRecords}
                handleChange={(value) => {
                  setFormData({ parent2JobDetails: { ...(value || null) } });
                }}
                listItemProps={(option) => ({
                  key: `option-${option.Id}`,
                  label: option.Name
                })}
                getOptionLabel={(option) => (option.Name ? option.Name : '')}
                valueProps={{ data: parent2JobDetails, key: 'Name' }}
                error={
                  validationErrors.parent2JobDetails
                    ? 'Please select a job title'
                    : null
                }
              />
            </Col>
          </div>
        </Row>
      </S.Card>
    </>
  );
};

export default JobSection;
