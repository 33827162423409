import * as CS from '../style';

import InputWrapper from '../InputWrapper';

const Textfield = (props) => {
  const { error, placeholder, disabled, handleChange, dataTestId = '' } = props;

  const onChange = (e) => {
    handleChange(e.target.value, e);
  };

  return (
    <InputWrapper {...props}>
      <CS.StyledTextField
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        error={error}
        data-testid={dataTestId}
      />
    </InputWrapper>
  );
};

export default Textfield;
