import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-input {
    color: ${({ theme }) => theme.colors.white};
    ::placeholder {
      opacity: 1;
    }
  }
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.white};
  }
  .MuiButtonBase-root {
    color: ${({ theme }) => theme.colors.white};
  }
  .Mui-disabled {
    border: ${({ theme }) => `2px solid ${theme.colors.white}`};

    .MuiInputBase-input {
      border: none;
    }
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
  }
`;
