import * as S from './style';

// interface RowProps {
//   jc?: string | number;
//   jcT?: string | number;
//   jcM?: string | number;
//   children?: any;
//   inner?: boolean;
// }

// interface ColProps {
//   w?: [] | number[];
//   children?: any;
//   display?: string;
// }

const Col = ({ w = [], children, display = 'flex', ...props }) => (
  <S.Col c1={w[0]} c2={w[1]} c3={w[2]} display={display} {...props}>
    {children}
  </S.Col>
);

const Row = ({ jc, jcT, jcM, children, inner, ...props }) => (
  <S.Row jc={jc} jcT={jcT} jcM={jcM} inner={inner} {...props}>
    {children}
  </S.Row>
);

export { Row, Col };
