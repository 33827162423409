// handles the redirection when the first form was submitted
// receives the responseId in URL
// queries SF to get back the response object incl. eligibility status and 2nd form link
// handles redirect based on eligibility
import { useParams } from 'react-router-dom';
import { useGetFirstFormRecord } from '../../api-calls/salesforce.queries';
import Loading from '../../components/Loading';
import * as T from '../../components/Typography';
import { navRoutes } from '../../constants';
import { goToFormPage, goToTopPage } from '../../utils/goToPage';
import theme from '../../theme';

let tryRefetch = 1;

const renderError = (responseId) => (
  <div
    style={{
      width: '100%',
      height: '100vh',
      top: '0',
      left: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <T.H1 mb={7} mbM={3} size="xxLarge" color="white" ta="center">
      Oops! Something went wrong. Please don't close this page but wait a little
      while and then hit refresh.
    </T.H1>
    <T.P mb={7} mbM={3} color="white" size="large" ta="center">
      If you're experiencing another error, please contact{' '}
      <a
        href="mailto:app@socialmobility.org.uk"
        style={{ color: theme.colors.primarySecondary }}
      >
        app@socialmobility.org.uk
      </a>{' '}
      with the following request ID: {responseId} and we will reach out to
      support you as soon as possible.
    </T.P>
  </div>
);

const renderLoading = () => (
  <Loading>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <T.H1 mb={7} mbM={3} size="xxLarge" color="white" ta="center">
        Thank you for starting your application!
      </T.H1>
      <T.P mb={7} mbM={3} color="white" size="large" ta="center">
        Stay on this page while we process your eligibility for the next stage,
        or check your inbox, we will send you an email to confirm your next
        steps soon. Please get in touch with{' '}
        <a
          href="mailto:app@socialmobility.org.uk"
          style={{ color: theme.colors.primarySecondary }}
        >
          app@socialmobility.org.uk
        </a>{' '}
        if you have any questions.
      </T.P>
    </div>
  </Loading>
);

const FirstForm = () => {
  const { responseId } = useParams();

  const { data, isError, isLoading, isSuccess, refetch } =
    useGetFirstFormRecord({
      responseId
    });

  if (isLoading) {
    return renderLoading();
  }

  if (isSuccess && data) {
    const {
      Form_2_Eligibility__c: eligibilityStatus,
      Form_2_Link__c: form2Link
    } = data;

    if (typeof eligibilityStatus !== 'boolean' || !form2Link.length) {
      return renderError(responseId);
    }
    if (eligibilityStatus) {
      // redirection to second form with pre-filled fields
      goToFormPage(form2Link);
    } else {
      // redirect to unsuccessful page
      goToTopPage(navRoutes.WEBSITE.APP_UNSUCCESSFUL);
    }
  }
  // retry once
  if (isError && tryRefetch < 2) {
    tryRefetch = tryRefetch + 1;
    refetch({ cancelRefetch: false });
  } else if (isError) {
    return renderError(responseId);
  }
};

export default FirstForm;
