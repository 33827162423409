import colors from './colors';
const borders = {
  inputs: `1px solid ${colors.neutralMid};`,
  error: `1px solid ${colors.error};`,
  section: `2px solid ${colors.primaryMain}`,
  dashed: `1.5px dashed ${colors.neutralMid};`,
  card: `1px solid ${colors.neutralLight};`,
  radius: '6px',
  radiusXLarge: '35.3074px',
  radiusLarge: '16px',
  numberRadius: '14px',
  imageRadius: '10px',
  tagRadius: '46px',
  emptySpaceBR: '12.63px',
  circularRadius: '50%'
};

export default borders;
