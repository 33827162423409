import ReactGA4 from 'react-ga4';

const isProduction = process.env.NODE_ENV === 'production';

const InitializeGoogleAnalytics = () => {
  if (!isProduction) {
    return;
  }
  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize(process.env.REACT_APP_ANALYTICS_ID);

  console.log('GA INITIALIZED');
};

interface TrackGoogleAnalyticsEventProps {
  category: string;
  eventName: string;
  action: string;
  data?: Record<string, unknown>;
}

const TrackGoogleAnalyticsEvent = ({
  category,
  eventName,
  data = {}
}: TrackGoogleAnalyticsEventProps) => {
  if (!isProduction) {
    return;
  }

  console.log('GA event:', category, ':', eventName, ':');

  const eventParams = {
    category,
    ...data
  };
  // Send GA4 Event
  ReactGA4.event(eventName, eventParams);
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
