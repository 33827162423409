// takes object of fields
// sets and returns form state
import React from 'react';

function formReducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const useFormData = ({ initialFormState }: { initialFormState: unknown }) => {
  const [formState, setFormState] = React.useReducer(
    formReducer,
    initialFormState
  );

  const setFormData = (data) =>
    setFormState((prevFormState) => ({ ...prevFormState, ...data }));

  return { formState, setFormData };
};
export default useFormData;
