import { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Global } from '@emotion/react';
import { Navigate, useRoutes } from 'react-router-dom';
import theme, { globalStyle } from './theme';
import { PreForm, FirstForm, SecondForm } from './pages';
import { navRoutes } from './constants';
import * as T from './components/Typography';
import RouteWrapper from './components/RouteWrapper';

import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import InitializeGoogleAnalytics from './utils/googleAnalytics';

const muiTheme = createTheme(theme as unknown as ThemeOptions);

const AppRoutes = () =>
  useRoutes([
    {
      path: navRoutes.GENERAL.HOME,
      element: (
        <RouteWrapper layout="general">
          <PreForm />
        </RouteWrapper>
      )
    },
    {
      path: navRoutes.FORM.PRE_FORM,
      element: (
        <RouteWrapper layout="general">
          <PreForm />
        </RouteWrapper>
      )
    },
    {
      path: navRoutes.FORM.FIRST_FORM,
      element: (
        <RouteWrapper layout="general">
          <FirstForm />
        </RouteWrapper>
      )
    },
    {
      path: navRoutes.FORM.SECOND_FORM,
      element: (
        <RouteWrapper layout="general">
          <SecondForm />
        </RouteWrapper>
      )
    },
    {
      path: navRoutes.GENERAL.NOT_FOUND,
      element: (
        <RouteWrapper layout="general">
          <T.H1 color="white">
            Sorry we can&apos;t find what you&apos;re looking for
          </T.H1>
        </RouteWrapper>
      )
    },
    // FALLBACK ROUTE
    {
      path: '*',
      element: <Navigate to={navRoutes.GENERAL.NOT_FOUND} />
    }
  ]);

function App() {
  useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);

  return (
    <div className="App">
      <Global styles={globalStyle} />
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <AppRoutes />
        </MuiThemeProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
