import styled from '@emotion/styled';

export const Wrapper = styled.main`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme, overlayColor }) =>
    !overlayColor ? theme.colors.neutralLight : theme.colors[overlayColor]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;
