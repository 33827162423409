// takes formdata, validator fn and submit attempt (boolean)
// returns validation errors or validated data for final submission
// runs validation when updates to form state are happening
import React from 'react';
import isDeepEqual from 'fast-deep-equal/react';

const useValidateFormData = ({
  formState,
  validateFn,
  submitAttempt
}: {
  formState: unknown;
  createFormSubmissionData?: (formState) => unknown;
  validateFn: ({ ..._formData }) => boolean;
  submitAttempt: { current: boolean };
}) => {
  const [validationErrors, setValidationErrors] = React.useState({});
  const formStateRef = React.useRef(formState);
  const finalSubmissionData = React.useRef(null);

  const validateForm = React.useCallback(() => {
    try {
      validateFn(formState);
      // set the 'clean' data for final submission
      finalSubmissionData.current = formState;
      setValidationErrors({ hasError: false });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrors({
          ...error.inner,
          hasError: true
        });
      }
      return false;
    }
  }, [validateFn, formState]);

  //   check if form state attributes have changed and run validation if so
  if (!isDeepEqual(formStateRef.current, formState)) {
    formStateRef.current = formState;
  }

  React.useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStateRef.current]);

  return { validateForm, validationErrors, finalSubmissionData };
};
export default useValidateFormData;
