import { fields, createSchema, validate as _validate } from '..';

const { dob, schoolDetailsObj, jobDetailsObj, jobDetailsObjOptional } = fields;

const schema = createSchema({
  schoolDetails: schoolDetailsObj,
  gradSchoolDetails: schoolDetailsObj,
  parent1JobDetails: jobDetailsObj,
  parent2JobDetails: jobDetailsObjOptional,
  dob
});

const validate = (data) => _validate(schema, data);

export default validate;
