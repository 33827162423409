const { REACT_APP_WEBSITE_DOMAIN: websiteDomain } = process.env;

const GENERAL = {
  HOME: '/',
  UNAUTHORIZED: '/unauthorized',
  NOT_FOUND: '/not-found'
};

const FORM = {
  PRE_FORM: '/pre-form',
  FIRST_FORM: '/first-form/:responseId',
  SECOND_FORM: '/second-form'
};

const WEBSITE = {
  APP_HOME: `${websiteDomain}`,
  APP_UNSUCCESSFUL: `${websiteDomain}app-unsuccessful-links`,
  APP_UNSUCCESSFUL_AGE: `${websiteDomain}app-unsuccessful-age`,
  APP_UNSUCCESSFUL_SCHOOL: `${websiteDomain}app-unsuccessful-school`,
  APP_UNSUCCESSFUL_NOT_IN_TEMP_LOCATION: `${websiteDomain}app-closed`,
  APP_SUCCESSFUL: `${websiteDomain}app-success`
};

export { GENERAL, FORM, WEBSITE };
