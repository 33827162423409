import React from 'react';
import * as T from '../../components/Typography';
import * as S from './style';
import { AutoComplete, Textfield } from '../../components/Inputs';
import { Col, Row } from '../../components/Grid';

import VerifySchoolModal from './VerifySchoolModal';
const HoldingSchoolId = process.env.REACT_APP_SF_HOLDING_ID;
const isHoldingSchool = (Id) => Id === HoldingSchoolId;

const SchoolAddress = ({ schoolDetails = {} }) => {
  const { Name: name, BillingAddress: address } = schoolDetails;

  return (
    <S.SchoolAddressWrapper>
      <T.P weight="bold">
        Please check the address and make sure you have selected the correct
        school:
      </T.P>
      {name ? <T.P>{name},</T.P> : null}
      {address?.street ? <T.P>{address.street},</T.P> : null}
      {address?.postalCode ? <T.P>{address.postalCode},</T.P> : null}
      {address?.city ? <T.P>{address.city},</T.P> : null}
      {address?.state ? <T.P>{address.state},</T.P> : null}
      {address?.country ? <T.P>{address.country}</T.P> : null}
    </S.SchoolAddressWrapper>
  );
};

const SchoolInputSection = ({
  label,
  placeholder,
  id,
  dataTestId,
  options,
  schoolDetails,
  validationError,
  setFormData,
  selectedSchoolType,
  setSelectedSchoolType,
  holdingSchoolId,
  textfieldLabel,
  textfieldPlaceholder,
  textFieldDataTestId
}) => {
  const isSelectOtherSelected = schoolDetails?.Id === holdingSchoolId;

  return (
    <Row mb={6}>
      <div style={{ width: '100%' }}>
        <Col w={[4, 6, 6]}>
          <AutoComplete
            label={label}
            placeholder={placeholder}
            id={id}
            dataTestId={dataTestId}
            options={options}
            handleChange={(value) => {
              setFormData({ [selectedSchoolType]: { ...(value || null) } });
              setSelectedSchoolType(selectedSchoolType);
            }}
            listItemProps={(option) => ({
              key: `option-${option.Id}`,
              label: option.Name
            })}
            getOptionLabel={(option) => (option.Name ? option.Name : '')}
            disabled={schoolDetails?.Id && schoolDetails.Id === holdingSchoolId}
            valueProps={{ data: schoolDetails, key: 'Name' }}
            error={validationError ? 'Please select a school' : null}
          />
          {!isSelectOtherSelected && (
            <T.P color="white" mt={2}>
              Can’t find your school? Search 'Other School' and you can enter
              your school manually!
            </T.P>
          )}
        </Col>
      </div>
      {isSelectOtherSelected && (
        <Col w={[4, 6, 6]}>
          <Textfield
            label={textfieldLabel}
            placeholder={textfieldPlaceholder}
            id={`${id}-textfield`}
            dataTestId={textFieldDataTestId}
            handleChange={(name) => {
              setFormData({
                [selectedSchoolType]: {
                  Name: name,
                  Id: name.length ? holdingSchoolId : null
                }
              });
              setSelectedSchoolType(null);
            }}
            disabled={schoolDetails?.Id && schoolDetails.Id !== holdingSchoolId}
            error={validationError ? 'Please select a school' : null}
          />
        </Col>
      )}
    </Row>
  );
};

const SchoolSection = ({
  schoolDetails,
  gradSchoolDetails,
  validationErrors,
  setFormData,
  initialFormState,
  schools
}) => {
  const [selectedSchoolType, setSelectedSchoolType] = React.useState(null);

  const schoolsDataIncludingHolding = [
    ...(schools?.data || []),
    {
      Id: HoldingSchoolId,
      Name: 'Other School'
    }
  ];

  return (
    <>
      <S.Card>
        <T.H2 color="white">2. SCHOOL DETAILS</T.H2>
        <SchoolInputSection
          label="Please select your school *"
          placeholder="Start typing..."
          id="school-autocomplete"
          dataTestId="school-autocomplete"
          options={schoolsDataIncludingHolding}
          schoolDetails={schoolDetails}
          validationError={validationErrors.schoolDetails}
          setFormData={setFormData}
          selectedSchoolType="schoolDetails"
          setSelectedSchoolType={setSelectedSchoolType}
          holdingSchoolId={HoldingSchoolId}
          textfieldLabel="Can’t find your school? Write it down"
          textfieldPlaceholder="Type the name of your school..."
          textFieldDataTestId="school-custom-textfield"
        />

        {schoolDetails.Id && !isHoldingSchool(schoolDetails.Id) ? (
          <Row mt={4}>
            <SchoolAddress schoolDetails={schoolDetails} />
          </Row>
        ) : null}

        <SchoolInputSection
          label="Please select where you did your GCSEs, National 5s or equivalent *"
          placeholder="Start typing..."
          id="grad-school-autocomplete"
          dataTestId="grad-school-autocomplete"
          options={schoolsDataIncludingHolding}
          schoolDetails={gradSchoolDetails}
          validationError={validationErrors.gradSchoolDetails}
          setFormData={setFormData}
          selectedSchoolType="gradSchoolDetails"
          setSelectedSchoolType={setSelectedSchoolType}
          holdingSchoolId={HoldingSchoolId}
          textfieldLabel="Can’t find your school? Write it down"
          textfieldPlaceholder="Type the name of your school..."
          textFieldDataTestId="grad-school-custom-textfield"
        />
        {gradSchoolDetails.Id && !isHoldingSchool(gradSchoolDetails.Id) ? (
          <Row mt={4}>
            <SchoolAddress schoolDetails={gradSchoolDetails} />
          </Row>
        ) : null}
      </S.Card>

      <VerifySchoolModal
        selectedSchoolType={selectedSchoolType}
        setSelectedSchoolType={setSelectedSchoolType}
        schoolDetails={
          selectedSchoolType === 'gradSchoolDetails'
            ? gradSchoolDetails
            : schoolDetails
        }
        setFormData={setFormData}
        initialFormState={initialFormState}
      />
    </>
  );
};

export default SchoolSection;
