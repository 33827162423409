import styled from '@emotion/styled';

export const Wrapper = styled.article`
  width: 100%;
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding-top: ${({ theme: { constants } }) =>
    constants.layout.general.top.desktop};

  padding-left: ${({ theme: { constants } }) =>
    constants.layout.general.sidePadding.desktop};
  padding-right: ${({ theme: { constants } }) =>
    constants.layout.general.sidePadding.desktop};

  padding-bottom: ${({ theme: { spacings } }) => spacings[8]};

  ${({ theme }) => theme.media.tablet} {
    align-items: stretch;

    padding-top: ${({ theme: { constants } }) =>
      constants.layout.general.top.tablet};

    padding-left: ${({ theme: { constants } }) =>
      constants.layout.general.sidePadding.tablet};
    padding-right: ${({ theme: { constants } }) =>
      constants.layout.general.sidePadding.tablet};

    padding-bottom: ${({ theme: { spacings } }) => spacings[7]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-bottom: ${({ theme: { spacings } }) => spacings[6]};
    padding-top: ${({ theme: { constants } }) =>
      constants.layout.general.top.mobile};

    padding-left: ${({ theme: { constants } }) =>
      constants.layout.general.sidePadding.mobile};
    padding-right: ${({ theme: { constants } }) =>
      constants.layout.general.sidePadding.mobile};
  }
`;
