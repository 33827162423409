import styled from '@emotion/styled';
import MuiModal from '@mui/material/Modal';

export const Modal = styled(MuiModal)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primaryMain};

  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: ${({ theme }) => theme.colors.primaryMain};
  }
`;

export const Container = styled.div`
  width: 50%;
  border: ${({ theme }) => `2px solid ${theme.colors.white}`};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings[7]};

  ${({ theme }) => theme.media.tablet} {
    width: 80%;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    border: none;
  }
`;
