const shadows = [
  {
    elevation1:
      '0px 1px 3px -1px rgba(4, 13, 38, 0.1), 0px 2px 5px -1px rgba(4, 13, 38, 0.11)'
  },
  {
    elevation2:
      '0px 1px 3px -1px rgba(4, 13, 38, 0.1), 0px 2px 5px -1px rgba(4, 13, 38, 0.11)'
  }
];

export default shadows;
