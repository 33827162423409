export const firstForm: {
  dob: string;
  orgId1: string;
  orgId2: string;
  orgName1: string;
  orgName2: string;
  orgCountry1: string;
  orgCountry2: string;
  parent1JobTitle: string;
  parent2JobTitle: string;
  parent1JobCode: string;
  parent2JobCode: string;
} = {
  dob: 'tfa_3026',
  orgId1: 'tfa_3024',
  orgId2: 'tfa_7916',
  orgName1: 'tfa_3041',
  orgName2: 'tfa_3043',
  orgCountry1: 'tfa_7918',
  orgCountry2: 'tfa_7920',
  parent1JobTitle: 'tfa_3040',
  parent2JobTitle: 'tfa_3073',
  parent1JobCode: 'tfa_3067',
  parent2JobCode: 'tfa_3071'
};

export const admissionsPolicies = {
  STATE_NON_SELECTIVE_SCHOOL: 'State non-selective school',
  STATE_SELECTIVE_GRAMMAR_SCHOOL: 'State selective/Grammar school',
  INDEPENDENT_FEE_PAYING_SCHOOL: 'Independent/Fee paying school'
};
