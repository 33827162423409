// COMMON INPUT STYLES
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const commonStyle = `
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

export const commonBorderStyle = ({ theme }) => `
  border: none;
  border-radius: ${theme.borders.radius};
`;

export const placeholderStyle = ({ theme, placeholderColor }) => `

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color: ${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
  ::-moz-placeholder { /* Firefox 19+ */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color: ${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
  :-ms-input-placeholder { /* IE 10+ */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color: ${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
  :-moz-placeholder { /* Firefox 18- */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color:${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
`;

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  padding-bottom: ${({ theme }) => theme.spacings[2]};
`;

export const Label = styled.label`
  ${setMargin};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 70px;
  align-items: flex-end;
  padding-bottom: ${({ theme }) => theme.spacings[1]};
`;

export const HelperWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings[2]};
`;

export const OptionalTag = styled.span`
  ${setMargin};
  display: inline-block;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.neutral80};
`;

export const StyledTextField = styled(TextField)`
  width: 300px;
  height: 50px;
  background: none;
  font-size: 1rem;

  & .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.colors.white};

    & fieldset {
      border: ${({ theme, error }) =>
        `2px solid ${error ? theme.colors.error : theme.colors.white}`};
      border-radius: 8px;
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.colors.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.colors.white};
    }
  }

  & .MuiOutlinedInput-input {
    ::placeholder {
      opacity: 1;
      -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
    }
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled
    .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
