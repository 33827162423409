import * as S from './style';
import CircularProgress from '@mui/material/CircularProgress';
import theme from '../../theme';

const Loading = ({ children }) => {
  return (
    <S.Wrapper>
      {children}
      <CircularProgress
        size="5rem"
        style={{ color: theme.colors.primarySecondary }}
      />
    </S.Wrapper>
  );
};

export default Loading;
