import { useFetch, formatQueryError } from '../utils/queries';

const SALESFORCE_BASE = '/salesforce';

function useGetSchools() {
  const context = useFetch(`${SALESFORCE_BASE}/schools/`);

  return {
    ...context,
    data: context.data,
    error: formatQueryError(context)
  };
}

function useGetFirstFormRecord({ responseId }) {
  const context = useFetch(`${SALESFORCE_BASE}/first-form/`, {
    responseId
  });

  return {
    ...context,
    data: context.data,
    error: formatQueryError(context)
  };
}

export { useGetSchools, useGetFirstFormRecord };
