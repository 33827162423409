import * as S from './style';
import * as CS from '../style';
import InputWrapper from '../InputWrapper';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';

const AutoComplete = (props) => {
  const {
    error,
    placeholder,
    disabled,
    handleChange,
    id,
    options,
    getOptionLabel,
    listItemProps,
    dataTestId,
    valueProps
  } = props;

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    limit: 100,
    stringify: (option) => {
      // remove and include commas in the stringified option so that the filter can match on them
      const formattedOption = option?.Name?.replace(/,/g, '') + option?.Name;

      return formattedOption;
    }
  });

  const [isFocused, setIsFocused] = useState(false); // State to track input focus
  const [inputValue, setInputValue] = useState(''); // State to track input value

  const onChange = (e, _value) => {
    handleChange(_value);
    setInputValue(_value); // Update input value
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value); // Update input value
    setIsFocused(true); // Set focus when typing starts
  };

  return (
    <InputWrapper {...props}>
      <S.StyledAutocomplete
        id={id}
        options={options}
        isOptionEqualToValue={(option, value) => option?.key === value?.key}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={(_props, option) => (
          <li {..._props} key={listItemProps(option)?.key}>
            {listItemProps(option)?.label}
          </li>
        )}
        renderInput={(params) => (
          <CS.StyledTextField
            {...params}
            error={error}
            placeholder={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={onInputChange}
            value={inputValue}
          />
        )}
        onChange={onChange}
        open={isFocused && inputValue.length > 0} // Show options when focused and input has value
        value={
          disabled
            ? null
            : valueProps?.data[valueProps?.key]
            ? valueProps.data
            : null
        }
        disabled={disabled}
        disableClearable
        data-testid={dataTestId}
      />
    </InputWrapper>
  );
};

export default AutoComplete;
